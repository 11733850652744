//
// Product Configurator
// --------------------------------------------------
//

.product-configurator-block {
  background-color: $white;
}

.product-configurator-inline {
  background-color: $white;
  overflow: hidden;

  @include mobile-only {
    height: 667px;
  }

  @include tablet-only {
    height: 926px;
  }

  @include desktop {
    height: 720px;
    margin: 0 auto;
    max-width: $desktop-large-width;
  }

  h3 {
    text-transform: initial;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 1.2px;
      line-height: 30px;
    }

    @include tablet-only {
      font-size: 40px;
      letter-spacing: 1.5px;
      line-height: 50px;
    }
  }

  .fragrance-header,
  .configurator-slider {
    .icon {
      color: $rit-copper-dark;
      line-height: 1;

      @include mobile-and-tablet {
        font-size: 24px;
      }

      @include desktop {
        font-size: 30px;
      }
    }
  }

  .error-badge {
    background: url('../images/error-badge.svg') left top no-repeat;
    background-size: 100% 100%;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  .error-message {
    color: $red-error;
    font-size: 11px;
    line-height: 13px;
    padding: 4px 0;

    .error-badge {
      display: inline-block;
      height: 16px;
      position: relative;
      top: 4px;
      width: 16px;
    }
  }

  .part-name {
    font-family: $font-header-italic;
    font-size: 14px;
    font-style: italic;
  }

  .overlay {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 100;

    .overlay-slot {
      align-items: center;
      display: flex;
    }
  }

  .product-image {
    img {
      height: 100%;
      transition: opacity $tertiary-duration $navigation-ease;
    }

    &.loading {
      img {
        opacity: 0;
      }
    }
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    @include desktop {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: 40px;
    display: flex !important;
    left: 50%;
    list-style: none;
    position: absolute;
    transform: translateX(-50%);
    width: 400px;
    z-index: 10;

    @include mobile-and-tablet {
      bottom: -20px;
    }

    @include mobile-only {
      width: 280px;
    }

    @include tablet-only {
      width: 440px;
    }

    @include desktop {
      bottom: 40px;
      width: 400px;
    }

    li {
      background-color: $slider-bar-gray;
      display: flex;

      @include mobile-and-tablet {
        height: 2px;
      }

      @include desktop {
        height: 4px;
      }

      &:first-child {
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border-radius: 0 2px 2px 0;
      }

      &:nth-last-child(n+2), &:nth-last-child(n+2) ~ li { width: 50%; }
      &:nth-last-child(n+3), &:nth-last-child(n+3) ~ li { width: 33.33%; }
      &:nth-last-child(n+4), &:nth-last-child(n+4) ~ li { width: 25%; }
      &:nth-last-child(n+5), &:nth-last-child(n+5) ~ li { width: 20%; }
      &:nth-last-child(n+6), &:nth-last-child(n+6) ~ li { width: 16.67%; }
      &:nth-last-child(n+7), &:nth-last-child(n+7) ~ li { width: 14.29%; }
      &:nth-last-child(n+8), &:nth-last-child(n+8) ~ li { width: 12.5%; }
      &:nth-last-child(n+9), &:nth-last-child(n+9) ~ li { width: 11.11%; }
      &:nth-last-child(n+10), &:nth-last-child(n+10) ~ li { width: 10%; }

      button {
        height: 100%;
        text-indent: -9999px;
        width: 100%;
      }
    }
  }
}

.error-message-transition-enter,
.error-message-transition-leave-to {
  opacity: 0;
}

.error-message-transition-enter-active,
.error-message-transition-leave-active {
  transition: opacity 0.8s $navigation-ease;
}


//
// Screen
// --------------------------------------------------
//

.screen {
  display: flex;
  height: 100%;
  position: relative;

  &::before {
    background-color: $white;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  @include mobile-and-tablet {
    flex-direction: column-reverse;
  }
}

.screen-transition-enter {
  &:not(.reverted) {
    transform: translate3d(0, 100px, 0);
  }

  &.reverted {
    transform: translate3d(0, -70px, 0);
  }

  &::before {
    opacity: 1 !important;
  }
}

.screen-transition-leave-to {
  &:not(.reverted) {
    transform: translate3d(0, -70px, 0);
  }

  &.reverted {
    transform: translate3d(0, 100px, 0);
  }

  &::before {
    opacity: 1 !important;
  }
}

.screen-transition-enter-active,
.screen-transition-leave-active {
  transition: transform 0.6s $navigation-ease;

  &::before {
    transition: opacity 0.6s $navigation-ease;
  }
}


//
// Product Showcase
// --------------------------------------------------
//

.product-showcase {
  overflow: hidden;
  position: relative;

  @include mobile-and-tablet {
    padding: 0 20px;
    position: absolute;
    width: 100%;
  }

  @include mobile-only {
    bottom: 134px;
    height: 260px;
  }

  @include tablet-only {
    bottom: 150px;
    height: 480px;
  }

  @include desktop {
    height: 100%;
    margin: auto 0;
    max-height: 368px;
    padding: 0 30px;
    width: 50%;
  }

  @include desktop-medium {
    padding: 0 64px;
  }

  figure {
    @include mobile-and-tablet {
      height: 100%;
      position: relative;
      width: 100%;
    }
  }

  .product-image {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &:nth-child(1) {
      z-index: 1;

      @include mobile-and-tablet {
        left: 25%;
      }

      @include desktop {
        left: 26%;
      }

      @include desktop-medium {
        left: 23%;
      }
    }

    &:nth-child(2) {
      z-index: 2;

      @include mobile-and-tablet {
        left: 35%;
      }

      @include desktop {
        left: 41%;
      }

      @include desktop-medium {
        left: 37%;
      }
    }

    &:nth-child(3) {
      z-index: 3;
    }

    &:nth-child(4) {
      z-index: 2;

      @include mobile-and-tablet {
        left: 65%;
      }

      @include desktop {
        left: 61%;
      }

      @include desktop-medium {
        left: 63%;
      }
    }

    &:nth-child(5) {
      z-index: 1;

      @include mobile-and-tablet {
        left: 75%;
      }

      @include desktop {
        left: 75%;
      }

      @include desktop-medium {
        left: 76%;
      }
    }

    img {
      height: 100%;
    }
  }
}

.product-showcase-transition-enter,
.product-showcase-transition-leave-to {
  left: 50% !important;
}

.product-showcase-transition-enter-active,
.product-showcase-transition-leave-active {
  transition: left 0.8s $navigation-ease;
}


//
// Product Description
// --------------------------------------------------
//

.product-description {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobile-and-tablet {
    height: 100%;
  }

  @include desktop {
    width: 50%;
  }

  .product-info-column-details {
    @include mobile-and-tablet {
      height: 100%;
      padding: 20px 15px 15px 15px;
    }

    .product-info-content {
      @include mobile-and-tablet {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: none;
        padding: 0;
      }

      @include desktop {
        padding: 0 42px;
      }

      @include desktop-medium {
        padding: 0 48px;
      }

      @include desktop-large {
        padding: 0 146px;
      }

      h3 {
        .screen-intro & {
          @include tablet-only {
            height: 100px;
            margin: 0 auto 10px auto;
            overflow: hidden;
            width: 508px;
          }
        }

        .screen-result & {
          @include mobile-and-tablet {
            margin: 0 48px;
          }
        }
      }

      p {
        @include mobile-only {
          margin-bottom: 0;
        }

        @include tablet-only {
          margin: 0 auto;
          width: 508px;
        }

        @include desktop {
          margin-bottom: 0;
        }
      }

      .result-label {
        color: $rit-copper-dark;
        display: block;
        font-family: $font-header;
        line-height: 23px;
        text-transform: uppercase;

        @include mobile-only {
          font-size: 11px;
          letter-spacing: 1.5px;
          margin-top: 20px;
        }

        @include tablet-only {
          font-size: 15px;
          letter-spacing: 2.5px;
          margin-top: 23px;
        }

        @include desktop {
          font-size: 15px;
          letter-spacing: 2.5px;
        }
      }

      .product-info-icons {
        display: inline-flex;

        @include mobile-and-tablet {
          margin: 20px auto 0 auto;
        }

        @include desktop {
          margin: 20px 0 0 0;
        }

        .screen-result & {
          @include mobile-and-tablet {
            margin-top: auto;
          }
        }

        .item {
          width: auto;

          @include mobile-and-tablet {
            margin: 0 10px;
            display: block;
          }

          & + .item {
            @include desktop {
              margin-left: 40px;
            }
          }
        }

        .product-info-icon {
          width: auto;

          @include mobile-and-tablet {
            display: inline-block;
            height: auto;
            margin: 0;
          }
        }

        .info-text {
          @include mobile-and-tablet {
            display: inline-block;
          }
        }
      }

      .product-info-footer {
        @include mobile-and-tablet {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }

        .screen-intro & {
          @include mobile-and-tablet {
            margin-top: auto;
          }
        }

        .screen-result & {
          @include mobile-and-tablet {
            flex-direction: row-reverse;
            flex-wrap: wrap;
          }

          @include tablet-only {
            margin: 0 auto;
            width: 440px;
          }
        }
      }

      .product-price {
        @include mobile-and-tablet {
          width: 100%;
        }

        @include desktop {
          margin: 60px 0 10px 0;
        }

        .screen-intro & {
          @include mobile-and-tablet {
            width: 50%;
            font-size: 24px;
          }
        }

        .screen-result & {
          @include mobile-only {
            font-size: 18px;
            line-height: 21px;
            margin: 15px 0 30px 0;
          }

          @include tablet-only {
            font-size: 24px;
            line-height: 32px;
            margin: 30px 0;
          }
        }
      }
    }
  }

  .btn {
    @include desktop {
      display: block;
      margin: 0 auto 10px auto;
    }

    .screen-intro & {
      @include mobile-and-tablet {
        margin: 0 auto;
      }
    }

    .screen-result & {
      @include mobile-and-tablet {
        min-width: calc(50% - 5px);
      }
    }
  }

  .btn-customize {
    .icon-pencil-configurable {
      @include pencil-black-icon(16px, 16px);
      transition: .2s ease-in-out;
      margin: 0 -24px -4px 2px;
    }
  }
}


//
// Configurator Slider
// --------------------------------------------------
//

.configurator-slider {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  @include mobile-only {
    padding: 16px 0;
  }

  @include tablet-only {
    padding: 30px 0;
  }

  @include desktop {
    padding: 30px 0;
  }

  h3 {
    @include mobile-only {
      margin: 8px 0 96px 0;
    }

    @include tablet-only {
      margin: 0;
    }

    @include desktop {
      margin-bottom: 96px;
    }
  }

  .part-details {
    position: relative;
    text-align: center;

    @include mobile-only {
      height: 96px;
    }

    @include tablet-only {
      height: 214px;
    }

    @include desktop {
      height: 96px;
    }

    .part-name {
      left: 50%;
      position: absolute;
      top: 20px;
      transform: translateX(-50%);
    }
  }
}

.configurator-slider-body {
  width: 100%;

  @include tablet-only {
    margin: 200px 0 0 0;
  }

  .product-image {
    cursor: pointer;
    height: 320px;

    &:hover,
    &.selected {
      img {
        opacity: 1;
      }
    }

    img {
      margin: 0 auto;
      opacity: 0.3;
      transition: opacity 0.8s $navigation-ease;
    }
  }
}

.configurator-slider-controls {
  @include mobile-and-tablet {
    display: none;
  }

  button {
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $tertiary-duration $navigation-ease;
    z-index: 1;

    &:first-child {
      left: 40px;
    }

    &:last-child:not(:only-child) {
      right: 40px;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &.has-background {
      background-color: $white;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.configurator-slider-indicator {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 11;

  @include mobile-and-tablet {
    bottom: -20px;
    height: 2px;
  }

  @include mobile-only {
    width: 280px;
  }

  @include tablet-only {
    width: 500px;
  }

  @include desktop {
    bottom: 40px;
    height: 4px;
    width: 400px;
  }

  .configurator-current-slide {
    background-color: $slider-indicator-gray;
    border-radius: 2px;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    transition: left 0.4s $navigation-ease;
  }
}


//
// Selected Product
// --------------------------------------------------
//

.selected-product {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include mobile-and-tablet {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @include mobile-only {
    padding-top: 128px;
  }

  @include tablet-only {
    padding-top: 160px;
  }

  @include desktop {
    margin-top: 30px;
    padding: 0 48px;
    width: calc(100% - 782px);
  }

  .part-name {
    margin: 10px 0 44px 0;

    @include desktop {
      height: 56px;
      overflow: hidden;
      text-align: center;
    }
  }

  .product-image {
    @include mobile-only {
      height: 186px;
    }

    @include tablet-only {
      height: 260px;
    }

    @include desktop {
      display: flex;
      height: 508px;
    }

    img {
      @include mobile-and-tablet {
        height: 100%;
      }

      @include desktop {
        height: auto;
        margin-top: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}


//
// Fragrance Slider
// --------------------------------------------------
//

.fragrance-slider {
  position: relative;

  @include mobile-and-tablet {
    height: 100%;
  }

  @include desktop {
    width: 782px;
  }

  .configurator-slider-controls {
    button {
      bottom: 220px;
      top: auto;
      z-index: 11;

      @include mobile-and-tablet {
        display: none;
      }

      &:first-child {
        left: -48px;
      }

      &:last-child:not(:only-child) {
        right: 10px;
      }
    }
  }

  .overlay {
    .icon {
      color: $white;
    }
  }

  .options {
    display: flex;
    list-style: none;

    li {
      border: 1px solid $white-smoke;
      margin: 0 4px;
      position: relative;
      text-align: center;

      @include mobile-only {
        height: 146px;
        width: 280px;
      }

      @include tablet {
        height: 260px;
        width: 174px;
      }

      &::before {
        background-color: $rit-copper-dark;
        content: '';
        display: inline-block;
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s $navigation-ease;
        width: 100%;
        z-index: 2;
      }

      &:hover,
      &.selected {
        box-shadow: 2px 4px 10px 0 rgba($black, 0.06);
      }

      &.selected::before {
        opacity: 1;
      }

      a {
        display: flex;
        flex-flow: column;
        height: 100%;
        letter-spacing: normal;
        text-decoration: none;

        @include mobile-only {
          padding: 30px 15px 20px 15px;
        }

        @include tablet {
          padding: 35px 15px 20px 15px;
        }
      }

      span {
        &.error-message {
          background-color: $rit-sandy-bg;
          color: $rit-black;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1;

          .error-badge {
            height: 12px;
            top: 2px;
            width: 12px;
          }
        }

        &.option-name {
          color: $rit-black;
          font-family: $font-header;
          font-size: 18px;
          line-height: 21px;
        }

        &.option-excerpt {
          color: rgba($rit-black, .5);
          flex-grow: 1;
          font-family: $font-header;
          font-size: 15px;
          line-height: 20px;
          margin-top: 10px;
        }

        &.option-more {
          font-size: 12px;
          letter-spacing: 2px;
          line-height: normal;
          text-decoration: underline;
          text-transform: uppercase;
        }

        &.icon-seal {
          @include private-collection-seal-icon(39px, 39px);
          margin: 0 auto 10px auto;

          @include mobile-only {
            display: none;
          }
        }
      }
    }
  }
}

.fragrance-header {
  text-align: center;

  @include mobile-only {
    height: 92px;
    margin: 16px 0 255px 0;
    overflow: hidden;
  }

  @include tablet-only {
    height: 130px;
    margin: 16px 0 336px 0;
    overflow: hidden;
  }

  @include desktop {
    margin: 30px 0 70px 0;
  }

  h3 {
    font-size: 30px;
    letter-spacing: 1.2px;
    text-transform: none;

    @include tablet-only {
      height: 100px;
      margin: 0 auto 10px auto;
      overflow: hidden;
      width: 508px;
    }

    @include desktop {
      height: 80px;
      margin: 0 auto;
      overflow: hidden;
      width: 480px;
    }
  }
}

.fragrance-categories {
  border-bottom: 1px solid $gray-gainsboro-light;
  display: flex;
  justify-content: center;
  list-style: none;

  @include desktop {
    margin: 0 54px 0 0;
  }

  li {
    display: flex;
    margin: 0 30px;
    padding: 0 4px;

    &.selected span.category-name {
      color: $rit-copper-dark;
    }

    a {
      padding-bottom: 17px;
      text-decoration: none;
    }

    span {
      &.category-name {
        color: $rit-black;
        font-family: $font-header;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.category-fragrances {
  position: relative;

  @include mobile-only {
    height: 146px;
    margin: 20px 0 15px 0;
  }

  @include tablet {
    height: 304px;
    margin: 20px 0 0 20px;
  }

  @include desktop {
    height: 304px;
    margin: 40px 0 48px 0;
  }

  &::before,
  &::after {
    content: '';
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 0.6s $navigation-ease;
    top: 0;
    width: 40px;
    z-index: 10;
  }

  &.gradient-left::before {
    background: linear-gradient(to right, $white, rgba($white, 0));
    left: 0;
    opacity: 1;
  }

  &.gradient-right::after {
    background: linear-gradient(to left, $white, rgba($white, 0));
    opacity: 1;
    right: 0;
  }

  .fragrances-scroll-body {
    display: flex;
    height: 100%;
    scroll-behavior: smooth;
    overflow-x: auto;
    overflow-y: hidden;

    // Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none; // sass-lint:disable-line no-misspelled-properties
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
}

.category-indicator {
  background-color: $rit-copper-dark;
  height: 1px;
  left: 55px;
  margin-top: -1px;
  position: absolute;
  width: 100px;
  z-index: 2;

  &:not(.skip-animation) {
    transition: left $tertiary-duration $navigation-ease,
      width $tertiary-duration $navigation-ease;
  }
}

.fragrance-options {
  &:first-child {
    @include mobile-and-tablet {
      margin-left: 10px;
    }
  }

  h4 {
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 3px;
    margin: 0 0 10px 4px;

    @include mobile-only {
      display: none;
    }
  }
}

.fragrance-footer {
  @include mobile-and-tablet {
    bottom: 20px;
    display: flex;
    position: absolute;
    width: 100%;
  }

  @include tablet-only {
    justify-content: center;
  }

  .btn {
    @include mobile-only {
      display: inline-block;
      max-width: none;
      min-width: auto;
      width: calc(50% - 21px);
    }

    @include desktop {
      display: block;
      margin: 0 auto;
    }

    &.btn--secondary {
      @include mobile-and-tablet {
        margin: 0 7px 0 15px;
        padding: 0;
      }

      @include tablet-only {
        margin: 0 10px 0 0;
      }

      .icon {
        @include mobile-and-tablet {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }

    &:not(.btn--secondary) {
      @include mobile-only {
        margin: 0 15px 0 auto;
      }
    }
  }
}


//
// Result Slider
// --------------------------------------------------
//

.product-configurator-inline { // sass-lint:disable-line no-mergeable-selectors
  .result-slider {
    @include mobile-and-tablet {
      position: absolute;
      width: 100%;
    }

    @include mobile-only {
      top: 154px;
    }

    @include tablet-only {
      top: 244px;
    }

    @include desktop {
      height: 100%;
      position: relative;
      width: 50%;
    }

    .restart-button {
      align-items: center;
      background-color: $black;
      border-radius: 50%;
      color: $black;
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: 10;

      &::before {
        transition: transform $tertiary-duration $navigation-ease;
      }

      &:hover::before {
        transform: rotate(-180deg);
      }

      @include mobile-and-tablet {
        height: 44px;
        left: 20px;
        width: 44px;
      }

      @include mobile-only {
        top: -134px;
      }

      @include tablet-only {
        top: -220px;
      }

      @include desktop {
        height: 50px;
        left: 40px;
        top: 40px;
        width: 50px;
      }
    }

    .icon-restart {
      color: $white;
      font-size: 22px;

      @include mobile-and-tablet {
        font-size: 17px;
      }
    }

    .configured-product {
      height: 100%;

      .product-image {
        @include mobile-only {
          height: 280px;
          width: 280px;
        }

        @include tablet-only {
          height: 440px;
          width: 440px;
        }

        @include desktop {
          height: 100%;
          width: 100%;
        }

        &.selected {
          img {
            @include mobile-and-tablet {
              opacity: 1;
            }
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;

          @include mobile-and-tablet {
            margin: 0 auto;
            opacity: 0.3;
            transition: opacity 0.8s $navigation-ease;
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}
