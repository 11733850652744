.approaching-promo-header {
  margin: 33px 0 9px;
  letter-spacing: 3px;
  font-size: 12px;
  color: $rit-dark;
  text-transform: uppercase;
}

.approaching-promos-wrapper,
.approaching-promo {
  margin: 0 auto;

  .promo-product-details {
    background: $rit-sandy-light;
    padding: 25px 10px;
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .col-text {
    text-align: left;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .product-name {
    max-width: 100%;

    .ecom-title {
      font-size: 11px;
      letter-spacing: 1.65px;
      line-height: 24px;
      color: $rit-copper;
      margin-bottom: 0;
      text-transform: uppercase;

      @include tablet {
        font-size: 12px;
        letter-spacing: 1.7px;
        line-height: 16px;
        margin-bottom: 4px;
      }
    }

    .gift-quantity {
      font-size: 12px;
      letter-spacing: 1px;
      color: #453f3f;
      font-family: $font-body;
      margin-top: 20px;
    }

    .bonus-item-actions {
      .change-bonus-product-btn {
        color: $rit-copper;
      }
    }
  }

  .approaching-promo-callout {
    font-size: 14px;
    letter-spacing: 0.88px;
    color: $rit-dark;
    line-height: 20px;
    display: block;
    max-width: 100%;

    @include tablet {
      font-size: 16px;
      letter-spacing: 1px;
    }
  }

  &.tiered-gwp-choose {
    .product-image {
      .dm-responsive-image {
        filter: grayscale(1);
      }
    }

    .promo-product-name {
      .ecom-title {
        color: $grey-medium-light;
      }

      .change-bonus-product-btn {
        color: $grey-medium-light;
      }
    }

    &::after {
      filter: grayscale(1);
    }
  }
}

// empty minicart
.mini-cart-empty {
  .approaching-promos-wrapper {
    .ecom-title {
      @include tablet {
        font-size: 11px;
        letter-spacing: 1.65px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }

    .product-image {
      width: 100px;
      height: 100px;
      position: relative;

      img {
        padding: 0 10px;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.single-promo {
      .approaching-promo-empty-cart {
        text-align: center;
      }

      .promo-product-details {
        padding: 30px;
        flex-direction: column;
        justify-content: center;

        @include tablet {
          min-height: 280px;
        }
      }

      .product-image {
        width: auto;
        height: auto;
        margin-bottom: 20px;
        flex-grow: 2;

        img {
          max-width: 100%;
          position: relative;
          top: 0;
          transform: none;
        }
      }

      .col-text {
        text-align: center;
        margin-top: auto;
        flex-grow: 1;
      }

      .product-name {
        margin-top: auto;
      }

      .ecom-title {
        font-size: 15px;
        letter-spacing: 5px;
        line-height: 23px;
        margin-bottom: 10px;
      }
    }
  }
}

// minicart promos - not empty
.wrapper--minicart__list {
  .approaching-promo {
    margin-top: 20px;

    .promo-product-details {
      padding-left: 0;
      margin-bottom: 0;

      @include tablet {
        padding: 25px 60px 25px 0;
      }
    }

    .ecom-title {
      font-size: 11px;
      color: $rit-copper-dark;
      letter-spacing: 1.65px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .gift-label {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 2.63px;
      color: $rit-black;
    }

    .approaching-promo-callout {
      margin-top: 10px;
      @include tablet {
        font-size: 14px;
        letter-spacing: .88px;
        line-height: 20px;
      }
    }

    .col-radio {
      display: none;
    }
  }

  .tiered-gwp {
    &.unselected {
      display: none;

      ~.tiered-gwp {
        margin-top: 20px;
        border-top: 0;
      }
    }
  }

  .premium-gift {
    margin-top: 0;
    border-top: 1px solid rgba($rit-copper-dark, 0.2);
  }
}

// empty cart promos
.approaching-promo-empty-cart {
  background-color: $rit-sandy-light;
  margin-bottom: 20px;

  .product-image {

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.cart-empty {
  .gwp-cart-banner {
    margin-bottom: 20px;
    padding: 30px 20px;
    width: 100%;

    @include tablet {
      padding: 20px 20px 20px 36px;
    }
  }

  .approaching-promos-wrapper {
    .product-image {
      width: 100%;
      max-width: 130px;
      height: auto;
    }

    &.single-promo {
      @include mobile-only {
        .approaching-promo-empty-cart {
          text-align: center;
        }

        .promo-product-details {
          padding: 30px;
          flex-direction: column;
          justify-content: center;
        }

        .product-image {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
          flex-grow: 2;
        }

        .col-text {
          text-align: center;
          margin-top: auto;
          flex-grow: 1;
        }

        .product-name {
          margin-top: auto;
        }

        .ecom-title {
          font-size: 15px;
          letter-spacing: 5px;
          line-height: 23px;
          margin-bottom: 10px;
        }
      }
    }
  }

  @include tablet {
    .approaching-promos-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .approaching-promo-empty-cart {
        flex-basis: 400px;
        margin: 20px;
      }

      .promo-product-details {
        min-height: 280px;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
      }

      .product-image {
        width: 100%;
        max-width: none;
        height: auto;
        margin-bottom: 20px;
        flex-grow: 2;
      }

      .col-text {
        text-align: center;
        flex-grow: 1;
      }

      .product-name {
        margin-top: auto;
      }

      .approaching-promo-callout {
        font-size: 16px;
      }
    }
  }
}

// GWP - cart with items
.cart-items-form {
  position: relative;

  fieldset {
    @include mobile-only {
      min-width: 0;
    }
  }

  .approaching-promo {
    min-height: 135px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 0;
    background-color: $rit-sandy-light;
    margin-top: 20px;

    @include tablet {
      padding-right: 20px;
      min-height: 160px;
    }

    @include desktop {
      padding-right: 30px;
    }

    &.premium-gift {
      margin-top: 0;
      border-top: 1px solid rgba($rit-copper-dark, 0.2);
    }
  }

  .promo-product-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    .col-text {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-wrap: wrap;
      position: relative;

      @include mobile-only {
        padding: 0 8px 0 16px;
        max-width: 44%;
      }

      @include mobile-small {
        padding: 0 10px 0 16px;
        max-width: 41%;
      }

      @include tablet-only {
        padding: 0 0 0 35px;
        max-width: 33%;
      }

      @include tablet-landscape {
        padding: 0 0 0 23px;
        max-width: 43%;
      }

      @include desktop {
        padding: 0 0 0 13px;
        max-width: 35%;
      }

      @include desktop-medium {
        padding: 0 0 0 37px;
        max-width: 49%;

        @include ie-styles {
          width: 345px !important;
        }
      }
    }

    .col-image {
      align-items: center;
      display: flex;
      width: 38%;

      @include mobile-extra-small {
        width: 34%;
      }

      @include tablet {
        width: 26%;
      }

      @include desktop {
        height: 100px;
        min-width: 100px;
        width: 100px;
      }

      img {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
      }

    }

    .ecom-title {
      font-size: 11px;
      color: $rit-copper;
      letter-spacing: 1.65px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 0;

      @include mobile-only {
        display: none;
      }

      .checkout-v3 & {
        @include mobile-only {
          display: block;
        }
      }
    }

    .gift-label {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: normal;
      color: $rit-black;

      @include tablet {
        font-size: 20px;
      }
    }

    .error-form {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      color: $red-error;
      text-transform: none;
      margin-top: 10px;
      font-family: $font-body;
    }

    .approaching-promo-callout {
      color: $rit-black;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      margin-left: 0;
      margin-top: 10px;
      width: 100%;

      @include tablet {
        margin-top: 0;
        margin-left: auto;
        width: auto;
        max-width: 230px;
        text-align: right;
      }
    }

    .col-radio {
      align-items: center;
      display: flex;
    }
  }

  .tiered-gwp-block {
    margin-bottom: 0;
  }

  .change-bonus-product-btn {
    display: inline-block;
    line-height: 18px;
    margin-top: 10px;
  }
}

.gwp-radio--hidden {
  display: none;

  &:checked {
    ~.gwp-select-box {
      border-radius: 50%;
      background-color: $rit-copper;
      border: 1px solid $rit-copper;

      &::after {
        color: $white;
        content: $icon-check;
        display: block;
        font-family: 'iconfont';
        font-size: 8px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.gwp-select-box {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $gray-gainsboro;
  cursor: pointer;
}

.gwp-wrapper {
  width: 100%;
  text-align: center;
  padding: 25px 0;

  @include tablet {
    padding: 40px 0 35px;
  }

  .text-wrapper {
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 23px;
    }

    & + .link {
      margin-top: 7px;
    }
  }

  .link {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    margin-bottom: 30px;

    @include tablet {
      background-color: transparent;
      border: 1px solid $gray-disabled;
      color: $rit-black;
      min-width: 220px;
      display: inline-block;
      text-decoration: none;
      padding: 15px 20px 14px;
      text-align: center;
      transition: .2s ease-in-out;
      margin-bottom: 27px;
    }

    @include desktop {
      &:hover,
      &:active {
        border: 1px solid $rit-black;
      }
    }
  }

  .heading-h10 {
    letter-spacing: 1.5px;
    line-height: 18px;
    margin-bottom: 9px;

    @include tablet {
      margin-bottom: 14px;
    }
  }

  .heading-h7 {
    max-width: 300px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;

    @include tablet {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .icon {
    display: block;
    font-size: 74px;
  }

  //GWP in cart and minicart
  &.gwp-minicart,
  &.gwp-cart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $white;

    .image-text-wrapper {
      display: flex;

      .text-wrapper {
        text-align: left;
        padding-left: 26px;
      }
    }
  }

    //GWP in cart
    &.gwp-cart {
      justify-content: space-between;
      flex-direction: column;
      padding: 25px 20px;

      @include tablet {
        flex-direction: row;
        padding: 25px 20px 9px;
      }

      .btn {
        width: 100%;

        @include tablet {
          width: auto;
          margin-bottom: 18px;
        }
      }

      .image-text-wrapper {
        margin-bottom: 14px;
        padding-left: 10px;
        align-items: flex-end;

        @include tablet {
          margin-bottom: 0;
          align-items: flex-start;
        }

        .text-wrapper {
          padding-left: 20px;

          @include tablet {
            padding-left: 30px;
          }
        }
      }
    }

    //GWP in minicart
    &.gwp-minicart {
      padding: 30px 0;

      .image-text-wrapper {
        align-items: flex-end;
      }
    }
}

.gwp-minicart-banner {
  align-items: flex-start;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  padding: 22px 40px 20px 15px;
  position: relative;
  width: 100%;

  .gwp-minicart-banner-title {
    padding-left: 115px;
    width: 100%;

    .heading-h10 {
      letter-spacing: 1.5px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .gwp-minicart-banner-image {
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
    width: 90px;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  .gwp-minicart-banner-content {
    padding: 10px 0 0 25px;
    width: calc(100% - 90px);

    .heading-h7 {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .btn {
      width: 100%;
    }

    .link {
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .gwp-minicart-banner-cta {
    margin-bottom: 10px;
    padding-left: 115px;
    width: 100%;

    .link {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: .5px;
    }

    .btn {
      width: 100%;
    }
  }
}

.gwp-banner-progress-holder {
  background-color: #ebe5e1;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .gwp-banner-progress-bar {
    background-color: $rit-copper;
    display: block;
    height: 3px;
    transition: width $tertiary-duration $default-ease-out;
    width: 0;
  }
}

.gwp-toast-banner {
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba($black, .5);
  padding: 20px;
  position: relative;
  text-align: center;

  @include tablet {
    box-shadow: none;
    width: 100%;
  }

  .gwp-toast-banner-title {
    .heading-h10 {
      margin-bottom: 6px;
    }
  }

  .gwp-toast-banner-content {
    margin-bottom: 25px;
    padding: 0 10px;
    width: 100%;

    .heading-h7 {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
    }
  }

  .gwp-toast-banner-image {
    margin: 0 auto 25px;
    max-width: 80px;

    img {
      max-width: 100%;
    }
  }

  .gwp-toast-banner-cta {
    .link {
      font-size: 12px;
      letter-spacing: 1.6px;
      line-height: 20px;
      text-transform: uppercase;
    }

    .btn {
      margin-top: -5px;
      width: 100%;

      @include tablet {
        width: auto;
      }
    }
  }
}

.generic-toaster-container .gwp-toast-banner {
  margin: -40px -30px;

  @include tablet {
    margin: -40px 0;
  }
}

.gwp-cart-banner {
  background-color: $white;
  display: flex;
  padding: 24px 20px 20px;
  position: relative;

  .gwp-banner-progress-holder {
    left: auto;
    margin: -20px -20px 25px;
    position: relative;
    top: auto;
    width: calc(100% + 60px);
  }

  .gwp-cart-banner-details {
    text-align: left;
  }

  .gwp-cart-banner-title {
    width: 100%;
  }

  .gwp-cart-banner-image {
    float: left;
    width: 80px;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  .gwp-cart-banner-content {
    margin-bottom: 15px;

    .benefits-list {
      margin-bottom: 16px;
      max-width: none;

      li {
        @include mobile-only {
          display: flex;
        }

        span {
          width: auto;
        }

        .icon-check {
          color: $copper;
        }
      }
    }
  }

  .gwp-cart-banner-cta {
    .btn {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .link {
      display: inline-block;
      font-size: 14px;
      letter-spacing: 0.5px;
      margin: 0 auto;
    }

    p {
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 18px;
      color: $rit-black;
      max-width: 204px;
      margin: 10px auto 0;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include mobile-only {
    flex-direction: column;

    .gwp-banner-progress-holder {
      margin: -24px -20px 21px;
      width: calc(100% + 40px);
    }

    .gwp-cart-banner-title {
      padding-left: 100px;

      .heading-h10 {
        margin-bottom: 4px;
      }
    }

    .gwp-cart-banner-details {
      width: 100%;
    }

    .gwp-cart-banner-image {
      margin-top: -13px;
    }

    .gwp-cart-banner-content {
      float: right;
      width: calc(100% - 100px);

      .heading-h7 {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
      }
    }
  }

  @include tablet {
    flex-wrap: wrap;
    padding: 20px;

    .gwp-banner-progress-holder {
      margin: -20px -40px 22px -20px;
    }

    .gwp-cart-banner-title {
      padding-left: 104px;

      .heading-h10 {
        margin-bottom: 8px;
      }
    }

    .gwp-cart-banner-details {
      width: 100%;
    }

    .gwp-cart-banner-image {
      margin: -17px 0 10px;
    }

    .gwp-cart-banner-content {
      float: left;
      margin: 0;
      padding: 0 25px;
      width: calc(100% - 80px);

      .heading-h7 {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 6px;
      }
    }

    .gwp-cart-banner-cta {
      margin-left: auto;
      min-width: 190px;
      padding-top: 8px;
      width: calc(50% - 85px);

      .link {
        margin: 20px 0 0 13px;
      }

      p {
        margin: 10px 0 0 12px;
        text-align: left;
      }
    }

    &.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) {
      padding-bottom: 26px;

      .gwp-cart-banner-image {
        margin-bottom: 0;
      }

      .gwp-cart-banner-content {
        .heading-h7 {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .gwp-cart-banner-cta {
        padding-top: 0;
      }
    }
  }

  @include desktop {
    .gwp-cart-banner-details {
      width: calc(50% + 63px);
    }

    &.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) {
      .gwp-cart-banner-details {
        border-right: 1px solid $gray-lines;
      }
    }
  }

  // Checkout V3 overrides
  .checkout-v3 & {
    .gwp-cart-banner-title {
      .heading-h10 {
        margin-bottom: 16px;
      }
    }

    .gwp-cart-banner-content {
      .benefits-list {
        li {
          display: flex;
        }
      }
    }

    .gwp-cart-banner-cta {
      @include tablet {
        min-width: 0;
        width: auto;
      }
    }

    .gwp-cart-banner-details {
      @include desktop {
        width: auto;
      }
    }

    &.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) {
      .gwp-cart-banner-details {
        @include desktop {
          border: 0;
        }
      }
    }
  }
}

.pg-gwp-cart-banner {
  background-color: $white;
  display: flex;
  margin: 20px 0 0 -20px;
  padding: 24px 25px 20px;
  position: relative;
  width: calc(100% + 40px);

  .gwp-cart-banner-image {
    width: 90px;

    img {
      display: block;
      width: 100%;
    }
  }

  .gwp-cart-banner-details {
    padding-left: 20px;
    text-align: left;
    width: calc(100% - 90px);
  }

  .heading-h10 {
    margin-bottom: 6px;
  }

  .heading-h7 {
    font-size: 15px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include tablet {
    align-items: center;
    margin: 30px 0 0;
    width: 100%;

    .gwp-cart-banner-image {
      width: 140px;
    }

    .gwp-cart-banner-details {
      padding-left: 40px;
      width: calc(100% - 140px);
    }

    .heading-h10 {
      margin-bottom: 5px;
    }

    .heading-h7 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.gwp-welcome-banner {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  .gwp-welcome-banner-inner {
    background-color: $white;
    padding: 91px 30px 78px;
    position: relative;
    width: 100%;
  }

  .icon-close {
    font-size: 18px;
    position: absolute;
    right: 18px;
    top: 15px;
  }

  .heading-h10 {
    letter-spacing: 1.5px;
    margin-bottom: 0;
  }

  .heading-h7 {
    letter-spacing: .63px;
    line-height: 30px;
  }

  .gwp-welcome-banner-content {
    margin: 0 auto;
    max-width: 310px;
    width: 100%;
  }
}

.gwp-minicart-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;

  .gwp-welcome-banner {
    bottom: 195px;
    opacity: 0;
    position: fixed;
    right: 40px;
    transition: opacity $tertiary-duration $filters-ease;
    visibility: hidden;
    width: 440px;
    z-index: 10;

    .icon-close {
      display: none;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .approaching-header {
    padding: 30px 0 2px;
  }

  .approaching-promo.approaching-free-gift {
    border-bottom: 1px solid rgba($rit-copper-dark, 0.2);
    margin: 0 0 20px;
    position: relative;
    transition: margin $primary-duration $filters-ease;
    width: 100%;

    &:last-of-type {
      border-bottom: 0;
    }

    &.slide-gift {
      left: 100%;
    }

    .promo-product-details {
      background-color: rgba($white, 0);
      padding: 10px 0 20px;
    }

    .col-image {
      flex-shrink: 0;
      margin-right: 10px;
      width: 100px;

      img {
        max-width: 100%;
      }
    }

    .col-text {
      padding-left: 0;
    }

    .ecom-title {
      color: $rit-copper;
      font-size: 12px;
      letter-spacing: 1.7px;
      line-height: 16px;
      margin-bottom: 7px;
    }

    .gift-label {
      font-size: 20px;
      letter-spacing: .5px;

      @include tablet {
        line-height: 32px;
      }
    }

    .gift-price {
      color: $gray-dark;
      font-size: 14px;
      letter-spacing: .4px;
      line-height: 24px;
      margin-top: 10px;
    }
  }

  .gwp-cart-banner {
    margin-top: 20px;
    padding-right: 40px;

    .gwp-cart-banner-details {
      width: 100%;
    }

    .gwp-cart-banner-content {
      padding: 0 7px 0 19px;
    }

    .gwp-cart-banner-cta {
      padding-top: 0;
      width: calc(100% - 102px);

      .link {
        font-size: 14px;
        letter-spacing: 0.5px;
        margin: 0 auto;
      }
    }

    .gwp-cart-banner-title {
      padding-left: 99px;
    }

    &.gwp-cart-banner-guest {
      @include desktop {
        .gwp-cart-banner-details {
          border: 0;
        }
      }

      .gwp-cart-banner-cta {
        p {
          display: none;
        }
      }
    }

    &.gwp-cart-banner-empty {
      .gwp-cart-banner-cta {
        margin-top: -9px;
      }
    }
  }

  .gwp-wrapper.gwp-minicart {
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: flex-end;
    padding: 30px;

    .image-text-wrapper {
      width: 100%;

      .icon {
        width: 80px;
      }
    }

    .link {
      margin: 10px 0 0;
      width: calc(100% - 100px);
    }
  }
}

.gwp-shoppingcart-wrapper {
  width: 100%;
  padding-top: 7px;

  .gwp-welcome-banner {
    left: 0;
    position: absolute;
    top: 34px;
    z-index: 6;
  }

  .gwp-cart-banner {
    margin-top: 20px;

    .gwp-cart-banner-cta {
      text-align: center;

      .link {
        display: block;
        font-size: 14px;
        letter-spacing: 0.5px;
      }

      p {
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 18px;
        color: $rit-black;
        max-width: 204px;
        margin: 10px auto 0;
        text-align: center;
      }
    }

    .gwp-cart-banner-title {
      padding-left: 100px;
    }

    @include tablet {
      padding: 20px 20px 20px 36px;

      .gwp-cart-banner-content {
        padding: 0 20px 0 25px;
      }

      .gwp-cart-banner-cta {
        text-align: left;
        width: calc(50% - 77px);

        .link {
          margin: 24px 0 0 18px;
        }

        p {
          text-align: left;
          margin: 10px 0 0 16px;
        }

        .btn--secondary + .link {
          margin: 0;
        }
      }

      .gwp-cart-banner-title {
        padding-left: 104px;
        margin-top: -2px;
      }

      .heading-h7 {
        margin-top: -2px;
      }

      .gwp-banner-progress-holder {
        margin: -20px -20px 25px -36px;
        width: calc(100% + 56px);
      }
    }

    @include tablet-portrait {
      &.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) {
        .gwp-cart-banner-cta {
          .link {
            margin-top: 20px;
          }
        }
      }
    }

    // Checkout V3 overrides
    .checkout-v3 & {
      .gwp-cart-banner-cta {
        text-align: left;

        @include tablet {
          width: auto;
        }

        .link {
          @include tablet {
            margin: 0;
          }
        }
      }
    }
  }

  .approaching-header {
    margin-bottom: 10px;
  }

  .bonus-item-actions {
    line-height: 15px;

    .change-bonus-product-btn {
      margin-top: 0;
    }
  }

  .approaching-promo.approaching-free-gift {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid $gray-lines;
    position: relative;
    min-height: 10px;
    margin: 0 0 20px;
    padding: 15px 0;

    @include tablet {
      padding: 15px 0 15px 20px;
    }

    &.premium-gift {
      border-top: 0;
    }

    &.slide-gift {
      left: 100%;
    }

    .promo-product-details {
      background-color: rgba(255, 255, 255, 0);
      padding: 0;

      .col-text {
        padding-left: 16px;

        @include tablet {
          padding-left: 20px;
        }

        @include desktop {
          padding-left: 37px;
        }
      }

      .ecom-title {
        margin-bottom: 6px;
      }

      .gift-label {
        letter-spacing: .5px;
        line-height: 22px;
        margin-bottom: 5px;
      }

      .gift-price {
        color: $gray-dark;
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 22px;
      }

      .col-radio-send-button {
        z-index: 1;
      }

      .col-radio {
        margin-top: -10px;
        margin-left: 10px;
      }

      .btn-wrapper-send {
        margin: 20px 0 0 5px;

        @include mobile-only {
          margin: 10px 0 0 5px;
        }
      }
    }
  }

  @include tablet-portrait {
    .gwp-cart-banner {
      align-items: center;
      position: relative;

      &::before {
        background-color: $gray-lines;
        bottom: 30px;
        content: '';
        display: inline-block;
        left: 59%;
        position: absolute;
        top: 30px;
        width: 1px;
      }

      .gwp-cart-banner-details {
        width: 59.5%;
      }

      .gwp-cart-banner-title {
        padding-left: 96px;
      }

      .gwp-cart-banner-image {
        width: 70px;
      }

      .gwp-cart-banner-content {
        width: calc(100% - 70px);
      }

      &:not(.gwp-cart-banner-empty) {
        .gwp-cart-banner-details {
          border-right: 0;
        }

        .gwp-cart-banner-content {
          padding: 0 20px 0 10px;
        }

        .gwp-cart-banner-cta {
          padding-top: 0;
        }
      }
    }
  }
}

.gwp-cart-banner-cta.gwp-cart-banner-link-cta {
  .btn {
    min-width: initial;
    padding: 0;
    font-size: 14px;
    border: 0;
    text-align: left;
    text-decoration: underline;
    text-transform: initial;
    letter-spacing: 0.4px;
    background-color: transparent;
    color: $copper;

    &:hover {
      text-decoration: none;
    }
  }
}

.gwp-welcome-overlay {
  background-color: rgba($rit-sandy-bg, .85);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.verification-message {
  color: $rit-copper;
  font-size: 14px;
  font-style: italic;
  letter-spacing: .6px;
  line-height: 22px;
  margin: 25px auto 10px;
  max-width: 330px;
  text-align: center;
}

.approaching-header-icon {
  color: $rit-gold-dark;
  display: none;
  margin-bottom: 3px;
  margin-right: 12px;

  .checkout-v3 & {
    display: inline-block;
  }
}

// gwp popups
.gwp-dialog {
  background-color: $white;
  z-index: $modal-window-index;
  max-width: calc(100% - 20px) !important;
  left: 0 !important;
  right: 0;
  margin: 0 auto;

  &.no-padding {
    .ui-dialog-titlebar-close {
      right: 25px;

      .ui-icon-closethick {
        font-size: 24px;
        color: $rit-black;
      }
    }
  }

  .ui-dialog-titlebar {
    position: absolute;
    right: -15px;
    z-index: 2;
  }

  .ui-dialog-content {
    padding: 18px 20px 0;
  }

  .ui-icon-closethick {
    @include icon(close);
    font-size: 20px;
    text-indent: 0;
    left: 0;
    top: 0;
    margin-left: 0;
  }

  .ui-dialog-titlebar-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 1px;
  }
}

.gwp-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $rit-black;
  max-width: 100%;

  .free-product-removal-images {
    width: 100%;
  }

  .popup-products-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 520px;
    width: 100%;
    flex-wrap: wrap;
    margin: 20px auto;
    -webkit-overflow-scrolling: touch;

    @include tablet {
      margin: 0 auto;
    }
  }

  .product-image {
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .popup-text {
    color: $rit-dark;
    text-align: center;

    p {
      font-family: $font-header;
    }
  }

  .popup-buttons {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @include tablet {
      justify-content: center;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile-only {
        min-width: 0;
        width: calc(50% - 5px);

        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          margin-left: 5px;
        }
      }

      @include tablet {
        margin: 0 10px;
      }
    }

    .link {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }
  }

  &.popup-wrapper {
    margin: -20px 0 -11px;

    @include tablet {
      margin: 20px 0 40px;
    }

    @include tablet-portrait {
      width: 100%;
    }

    .popup-header {
      color: $rit-copper-dark;
      font-size: 11px;
      letter-spacing: 1.5px;
      line-height: 24px;
      margin-bottom: 4px;
      text-align: center;
    }

    .popup-text {
      color: $rit-dark;
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 30px;
      max-width: 210px;
      text-align: center;

      @include desktop {
        max-width: none;
      }
    }

    .bonus-product-list {
      width: 100%;
    }

    .popup-product {
      margin: 5px;
      max-width: calc(50% - 10px);

      &.popup-bonus-choice-product {
        background-color: $white;
        margin: 5px;
        max-width: 200px;
        width: 100%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

        img {
          max-width: 100%;
          max-height: 100%;
        }

        label {
          display: flex;
          flex-direction: column;
          height: 100%;
          cursor: pointer;
          padding: 17px 35px 5px;

          @include desktop {
            padding: 32px 15px 5px;
          }
        }
      }

      // Hide the selection radio button when only 1 bonus product is available
      &.popup-bonus-choice-product:only-child .select-box-wrapper {
        display: none;
      }
    }

    .slick-slide {
      @include mobile-small {
        padding: 5px;
      }

      & > div {
        @include tablet-portrait {
          display: flex;
          justify-content: center;
        }
      }

      // if the products are in a slider, don't hide the checkbox
      .popup-bonus-choice-product:only-child .select-box-wrapper {
        display: block;
      }
    }

    .slick-dots {
      font-size: 0;
      list-style: none;
      text-align: center;
      margin-top: 8px;

      li {
        display: inline-block;
        margin: 0 3px;
      }

      button {
        background-color: $gray-concrete;
        border-radius: 50%;
        display: inline-block;
        font-size: 0;
        height: 6px;
        width: 6px;
      }

      .slick-active button {
        background-color: $rit-dark;
      }
    }

    .slick-list {
      overflow: visible;
    }

    .product-name {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 22px;
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 11px;

      @include desktop {
        margin-bottom: 22px;
      }
    }

    .product-image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;

      @include desktop {
        padding: 0 30px;
      }

      img {
        max-width: 100%;
      }
    }

    .gwp-select-box {
      height: 30px;
      width: 30px;
      margin: 0 auto;
    }

    .gwp-radio--hidden {
      &:checked {
        position: relative;
        ~.product-image {
          background-color: $white;
        }

        ~.select-box-wrapper {
          background-color: $white;

          .gwp-select-box {
            border-radius: 50%;
            background-color: $rit-copper-dark;
            border: 1px solid $rit-copper-dark;

            &::after {
              @include icon-styles;
              background-color: transparent;
              color: $white;
              content: icon-char(check);
              display: block;
              font-size: 13px;
              height: 6px;
              left: 9px;
              position: absolute;
              top: 4px;
              transform: translate(-50%, -50%);
              width: 6px;
            }
          }
        }
      }
    }

    .select-box-wrapper {
      width: 100%;
      padding: 10px 0;
      flex-grow: 0;
      display: flex;
      align-items: flex-end;
    }

    .verification-message {
      margin: 25px auto 0;

      @include tablet {
        margin: 30px auto -13px;
        max-width: 100%;
      }
    }

    .popup-buttons {
      @include desktop {
        margin-top: 8px;
      }

      .btn {
        width: 100%;

        @include tablet {
          width: auto;
        }
      }
    }

    .approaching-promo-callout {
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.75px;
      line-height: 21px;
      max-width: 264px;
      margin-top: 20px;

      @include tablet {
        margin-top: 40px;
        max-width: 400px;
      }
    }
  }

  &.remove-gwp-popup {
    width: 100%;
    margin: -10px 0;

    @include tablet {
      margin: 30px 0 40px;
    }

    .product-wrapper {
      display: flex;

      @include desktop {
        display: block;
      }
    }

    &.gwp-popup-padding {
      @include mobile-only {
        padding: 35px 0 30px;
      }
    }

    .free-product-removal-images {
      max-width: 90px;

      @include desktop {
        max-width: none;
      }

      @include mobile-only {
        display: none;
      }

      @include tablet-only {
        display: none;
      }
    }

    .popup-product {
      max-width: 130px;
      margin: 0;

      @include desktop {
        margin: 5px;
      }
    }

    .approaching-free-gift {
      border-bottom: 1px solid rgba(136, 102, 85, 0.2);
      border-top: 1px solid rgba(136, 102, 85, 0.2);
      margin: 0 0 16px;

      &:last-of-type {
        border-bottom: 1px solid rgba(136, 102, 85, 0.2);
      }

      .col-image {
        height: 80px;
        width: 95px;

        @include mobile-only {
          height: 64px;
          width: 70px;
        }

        img {
          height: 80px;

          @include mobile-only {
            height: 64px;
          }
        }
      }

      .promo-product-details {
        margin-bottom: 0;
        padding: 16px 0 16px 40px;

        @include mobile-only {
          padding: 16px 0 16px 25px;
        }
      }

      .ecom-title {
        font-size: 12px;
        letter-spacing: 1.7px;
        line-height: 16px;
        margin-bottom: 0;

        @include mobile-only {
          padding-right: 40px;
        }
      }

      .gift-label {
        color: $rit-black;
        font-size: 20px;
        letter-spacing: 0.7px;
        line-height: 32px;
        margin-bottom: 0;

        @include mobile-only {
          font-size: 16px;
          letter-spacing: 0.4px;
          line-height: 24px;
          padding-right: 20px;
        }
      }

      .gift-price {
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
      }
    }

    .product-gift-ribbon {
      &::after {
        opacity: 1;

        @include tablet {
          height: 88px;
          width: 82px;
        }
      }
    }

    .popup-buttons {
      align-items: center;
      flex-direction: column;

      .remove-gwp-button {
        margin-bottom: 16px;
        min-width: unset;
        padding: 16px 40px;

        @include mobile-only {
          max-width: 100%;
          width: calc(100% - 48px);
        }

        &:first-child {
          @include mobile-only {
            margin-right: 0;
          }
        }
      }

      .keep-gwp-button {
        color: $rit-copper;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        text-decoration: underline;
      }

      a {
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
      }
    }

    .popup-text {
      margin: -30px 0 16px;

      @include mobile-only {
        margin: 0 0 16px;
        padding: 0 40px;
      }

      @include tablet-only {
        margin: 0 0 16px;
      }

      .popup-text-main {
        color: $rit-black;
        font-family: $font-header;
        font-size: 20px;
        letter-spacing: 0.7px;
        line-height: 32px;
        margin-bottom: 8px;

        @include mobile-only {
          font-size: 16px;
          letter-spacing: 0.4px;
          line-height: 24px;
          text-align: center;
        }

        @include tablet-only {
          text-align: center;
        }
      }

      p {
        color: $rit-black;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin-bottom: 0;
        max-width: 500px;
        padding: 0 10px;
        text-align: left;

        @include mobile-only {
          padding: 0;
          text-align: center;
        }

        @include tablet {
          text-align: center;
        }

        @include desktop {
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
        }
      }
    }
  }
}

.bonus-product-list-slider.drag-list {
  @include mobile-and-tablet {
    margin-bottom: 40px;
    width: calc(100% + 60px) !important;
  }

  .drag-list-scroll {
    @include mobile-and-tablet {
      // sass-lint:disable-block no-misspelled-properties
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include tablet {
      visibility: visible;
    }

    @include tablet-portrait {
      max-width: none;
    }

    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 520px;
      width: 100%;
      flex-wrap: wrap;
      margin: 25px auto 20px;
      padding-bottom: 5px;
    }
  }

  .drag-list-body {
    @include tablet-only {
      margin: 0 auto;
      min-width: 100%;
      justify-content: center;
    }
  }

  .bonus-product-list-slider-item {
    @include mobile-only {
      width: calc(100vw - 80px);
    }
  }
}

.bonus-product-list-slider-item {
  padding: 5px;
  width: 210px;

  @include mobile-only {
    align-items: center;
    display: flex;
    justify-content: center;
    width: calc(100vw - 85px);
  }

  @include mobile-and-tablet {
    padding: 20px 5px 10px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  label {
    background-color: $white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    padding: 17px 35px 5px;

    @include desktop {
      padding: 32px 15px 5px;
    }
  }
}

// PG minicart banner
.pg-minicart-banner {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 26px 20px 20px;
  width: 100%;

  .pg-minicart-banner-image {
    width: 186px;

    img {
      width: 100%;
    }
  }

  .gwp-minicart-banner-content {
    padding-left: 20px;
  }

  .heading-h10 {
    margin-bottom: 6px;
  }

  .heading-h8 {
    line-height: 20px;
  }
}

// advent non-gwp banner
.advent-non-gwp-banner {
  margin-top: 30px;
  padding: 24px 30px 20px;
  text-align: center;
  width: 100%;

  .heading-h11 {
    color: $rit-copper-dark;
    letter-spacing: 1.5px;
    margin-bottom: 6px;
  }

  .heading-h7 {
    letter-spacing: 0.5px;
    margin-bottom: 18px;
  }

  .advent-usp-minicart-list {
    width: 100%;
  }

  .shipping-info-point {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    list-style: none;
    margin-bottom: 7px;
    padding-right: 20px;
  }

  .icon-check {
    color: $rit-copper-dark;
    font-size: 10px;
    line-height: 16px;
    margin-right: 7px;
  }
}

// giftset fastlane non-gwp block
.free-gifting-info-block-wrapper {
  width: 100%;

  @include mobile-only {
    padding-top: 24px;
  }

  @include tablet {
    margin-bottom: 20px;
    padding-top: 13px;
  }
}

.free-gifting-info-block {
  background-color: $rit-sandy-bg;

  @include mobile-only {
    border-bottom: 1px solid $rit-copper-dark;
    border-top: 1px solid $rit-copper-dark;
    margin-left: -20px;
    padding: 8px 40px;
    width: calc(100% + 40px);
  }

  @include tablet {
    border: 1px solid $rit-copper-dark;
    margin: 0 auto;
    max-width: 360px;
    padding: 8px 30px;
    width: 100%;
  }

  .inner {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .image-holder {
    flex-shrink: 0;
    width: 70px;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .text-holder {
    text-align: left;

    @include mobile-only {
      padding-left: 10px;
    }

    @include tablet {
      padding-left: 15px;
    }
  }

  h4 {
    color: $rit-copper-dark;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  p {
    color: $rit-black;
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.7px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Default product gift ribbon
.product-gift-ribbon {
  &::after {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

    // Checkout V3 overrides
    .checkout-v3 & {
      opacity: 1;
    }


    @include mobile-only {
      background-image: url('../images/crm/ribbon-gold-corner-mobile.svg');
      height: 72px;
      width: 64px;
    }

    @include tablet {
      background-image: url('../images/crm/ribbon-gold-corner-desktop.svg');
      height: 96px;
      width: 90px;
    }
  }

  .mini-cart-price-wrapper {
    // Checkout V3 overrides
    .checkout-v3 & {
      display: none;
    }
  }

  .cart-product-quantity {
    @include tablet {
      margin-left: 30px;
    }

    .quantity-block {
      input {
        pointer-events: none;
      }
    }
  }

  .mini-cart-details-bottom {
    .mini-cart-pricing {
      color: $ritual-samurai;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin-top: 5px;
    }
  }
}

// Overrides product gift ribbon

.crm-gift-checked {
  .product-image {
    .dm-responsive-image {
      filter: unset;
    }
  }

  .promo-product-name {
    .ecom-title {
      color: $rit-copper-dark;
    }

    .change-bonus-product-btn {
      color: $rit-copper-dark;
    }
  }

  &::after {
    filter: unset;
  }
}

.gwp-choose-gift {
  border-top: 1px solid $gray-gainsboro;
  padding: 32px 0 16px 40px;

  @include mobile-only {
    padding: 24px 0 16px 16px;
  }
}

.gwp-choose-gift-text {
  text-align: left;
  text-transform: uppercase;
  color: $rit-copper;
  line-height: 16px;
  margin: 0;
  letter-spacing: 1.7px;
  font-size: 12px;
  font-family: $font-header;
}
