.filter-module {
  background-color: $white;

  &__wrapper {
    margin: 0 auto;
    max-width: 1320px;
    padding: 48px 0;

    @include mobile-and-tablet {
      padding: 42px 0;
    }
  }

  &__box {
    width: calc(33.33% - 16px);
  }

  &__container {
    display: flex;
    justify-content: space-between;

    &.two-tiles {
      .filter-module__box {
        width: calc(50% - 16px);
      }
    }
  }

  &__title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 48px;
    text-transform: none;

    @include mobile-and-tablet {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 22px;
      text-align: center;
    }
  }

  &__box-title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 8px;
    text-transform: none;

    @include mobile-and-tablet {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 0;
    }
  }

  &__box-link {
    border: 1px solid rgba($rit-black, 0.16);
    color: $rit-black;
    cursor: pointer;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    margin-top: 48px;
    padding: 16px 32px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    @include mobile-and-tablet {
      border: none;
      margin-top: 0;
    }

    &::after {
      @include icon-styles;
      content: icon-char(arrow3--right);
      display: none;
      font-size: 26px;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);

      @include mobile-and-tablet {
        display: block;
      }
    }
  }

  &__box-paragraph {
    color: $gray-dark;
    font-family: $font-body;
    font-size: 14px;
    letter-spacing: .4;
    line-height: 24px;
    margin: 0 auto;
    width: 65%;

    @include mobile-and-tablet {
      width: 100%;
    }
  }

  &__image {
    height: 480px;

    @include mobile-and-tablet {
      height: 200px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__box-content {
    margin-top: 22px;
    text-align: center;

    @include mobile-and-tablet {
      margin-top: 8px;
    }
  }

  .slick-list {
    @include mobile-and-tablet {
      margin: 0 -5px;
      padding: 0 25px 0 25px;
    }
  }

  .slick-slide {
    @include mobile-and-tablet {
      margin: 0 5px;
    }
  }
}
