.product-experience-block {
  width: 100%;

  @include desktop {
    min-height: 700px;
    padding: 30px 0;
  }

  &.reverse {
    .inner-wrapper {
      justify-content: flex-start;
      padding: 0 0 0 20px;

      @include mobile-only {
        flex-direction: column;
        padding: 0;
      }
    }

    .product-experience-media {
      left: auto;
      right: 0;
    }
  }

  &.full-width {
    .product-experience-media {
      width: 100%;

      @include mobile-only {
        // sass-lint:disable-block no-duplicate-properties
        height: 100vh;  // Fallback for browsers that do not support Custom Properties
        height: calc(var(--vh, 1vh) * 100); // sass-lint:disable-line no-duplicate-properties
      }

      .video-holder::after {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.fixed {
    @include mobile-only {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 990;
    }
  }

  .inner-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 0 0;

    @include mobile-only {
      flex-direction: column;
      padding: 0;
    }
  }

  .close-video-overlay {
    top: 40px;
    left: 40px;
    background-color: rgba($white, 0.5);
    border-radius: 50%;
    color: $white;
    width: 48px;
    height: 48px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.6s $navigation-ease;
    opacity: 0;
    pointer-events: none;
    z-index: 3;

    @include mobile-only {
      position: fixed;
      transform: translateZ(0);
    }

    @include tablet {
      position: absolute;
    }

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  .popup-wrapper {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
    z-index: 4;
    pointer-events: none;
    box-shadow: 4px 6px 12px 3px rgba($black, 0.5);

    &.open {
      opacity: 1;
      pointer-events: all;
    }

    @include desktop {
      &.left {
        left: 11%;
        transform: translateX(0) translateY(-50%);
      }

      &.right {
        left: auto;
        right: 11%;
        transform: translateX(0) translateY(-50%);
      }
    }

    @include mobile-small {
      max-width: 90%;
    }

    @include mobile-only {
      width: 100%;
      max-width: 335px;
      position: fixed;
      transform: translate3d(-50%, -50%, 0);
      max-height: 80vh;
    }

    @include tablet {
      min-width: 355px;
      position: absolute;
    }

    .popup {
      max-width: 355px;
      padding: 15px 20px 40px;
      text-align: center;

      @include mobile-only {
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 20px 20px 35px;
      }
    }

    .ico {
      font-size: 17px;
      color: #dbc48f;

      @include tablet {
        font-size: 20px;
      }
    }

    .subtitle {
      display: block;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 3px;
      font-family: $font-header;
      text-transform: uppercase;
      margin: 6px 0 5px;
    }

    .dividers {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        span:nth-child(2) {
          margin-top: 5px;
        }
      }

      &:nth-of-type(2) {
        flex-direction: column-reverse;

        span:first-child {
          margin-top: 5px;
        }
      }

      span {
        display: block;
        background-color: #dbc48f;
        height: 1px;

        &:first-child {
          width: 50px;
        }

        &:nth-child(2) {
          width: 100px;
        }
      }
    }

    .title {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.5px;
      text-transform: none;
      margin: 20px 0;
    }

    .image-wrapper {
      position: relative;

      img {
        width: 100%;
      }

      .product-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }

    .description {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.2px;
      font-family: $font-header;
      margin: 16px 0 0;

      @include mobile-only {
        margin-top: 10px;
      }
    }

    .close-popup {
      position: absolute;
      top: 5px;
      left: 7px;
      border-radius: 50%;
      color: $rit-black;
      width: 48px;
      height: 48px;
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile-only {
        background-color: $rit-black;
        color: $white;
        position: absolute;
        top: auto;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .icon-tap-mobile {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 4.2px;
    font-family: $font-body;
    text-transform: uppercase;
    transition: opacity 1s $navigation-ease;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    z-index: 3;

    @include desktop {
      display: none;
    }

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .icon-text {
      max-width: 165px;
    }

    .svg-wrapper {
      position: relative;
      margin-bottom: 10px;
    }

    .dashed-circle {
      position: absolute;
      top: 0;
      left: 0;
      animation: circle-spin 6s linear infinite;
      z-index: -1;
    }
  }
}

.product-experience-media {
  z-index: 2;
  transition: 1.4s $navigation-ease;

  @include mobile-only {
    height: 75vh;
    overflow-x: auto;
    overflow-y: hidden;
    transform: translateZ(0);
    transition: height 1s $navigation-ease;
    width: 100%;
  }

  @include tablet {
    position: absolute;
    top: 0;
    left: 0;
    width: 62%;
    height: 100%;
  }

  @include tablet-only {
    width: 48%;
  }

  .product-experience-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    color: $white;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 4.2px;
    font-family: $font-body;
    text-transform: uppercase;
    transition: 1s $navigation-ease;
    opacity: 0;
    pointer-events: none;

    &.fade-in {
      opacity: 1;
      pointer-events: all;

      .svg-wrapper {
        transform: scale(1);

        svg {
          opacity: 1;
        }

        .arrow {
          transform: rotate(0) translate(20px, 17px);
        }
      }
    }

    @include mobile-only {
      display: none;
    }

    @keyframes circle-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .svg-wrapper {
      position: relative;
      transition: transform 0.8s $navigation-ease;
      margin-bottom: 10px;
      transform: scale(0.8);

      &:hover {
        transform: scale(1.1);

        svg {
          .arrow {
            transform: translate(34px, 12px) rotate(50deg);
          }
        }
      }
    }

    svg {
      opacity: 0;
      transition: transform 0.8s $navigation-ease, opacity 0.8s $navigation-ease;

      .arrow {
        transform: translate(35px, 14px) rotate(60deg);
        transition: transform 1.6s $navigation-ease;
      }
    }

    .dashed-circle {
      position: absolute;
      top: 0;
      left: 0;
      animation: circle-spin 6s linear infinite;
      z-index: -1;
    }

    .btn-text-desktop {
      display: none;

      @include desktop-only {
        display: block;
      }
    }

    .btn-text-mobile {
      display: none;

      @include mobile-and-tablet {
        display: block;
      }
    }
  }

  .video-holder {
    position: relative;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      height: 200px;
      width: 100%;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(#383636, 0), $rit-black);
      z-index: 1;
      opacity: 1;
      transition: opacity 1s $navigation-ease;

      @include mobile-only {
        display: none;
      }
    }
  }

  .media-holder {
    position: relative;
    height: 100%;

    .experience-video-container {
      position: relative;
      z-index: 1;
    }

    .s7container {
      height: 100%;
      overflow: hidden;

      video {
        height: 130% !important;
        width: auto !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);

        @include mobile-only {
          object-fit: cover;
          height: 100% !important;
        }
      }
    }

    .s7videoplayer {
      @include mobile-only {
        transform: translateZ(0);
      }
    }

    .hidden-images-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      z-index: 0;

      img {
        position: absolute;
        height: 130% !important;
        width: auto !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);

        @include mobile-only {
          height: 100% !important;
        }

        &:first-child {
          z-index: 1;
        }
      }
    }
  }
}

.product-experience-slides {
  position: relative;
  width: 35%;
  border: 1px solid $white;
  padding: 74px 20px 70px;

  @include tablet-only {
    width: 48%;
  }

  @include mobile-only {
    width: 100%;
    padding: 0 20px 55px;
    border: 0;
  }

  .drag-list-body {
    padding: 0 0 19px;

    @include mobile-only {
      padding-top: 30px;
    }
  }

  .product-experience-slide {
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile-only {
      width: calc(100vw - 40px);
    }

    h3 {
      font-size: 40px;
      text-transform: none;
      letter-spacing: 2px;
      margin-bottom: 26px;
      text-align: center;

      @include mobile-only {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1.5px;
        margin-bottom: 20px;
      }
    }

    p {
      color: $white;
      font-size: 14px;
      line-height: 24px;
      max-width: 310px;
      letter-spacing: 0.5px;
      text-align: center;

      &.description {
        margin-bottom: 47px;

        @include mobile-only {
          margin-bottom: 30px;
        }
      }
    }

    .product-price {
      color: $white;
      font-size: 24px;
      letter-spacing: 1.5px;
      display: block;
      margin-bottom: 26px;

      @include mobile-only {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: none;
      font-size: 14px;
      letter-spacing: 0.5px;
      text-align: center;
      margin-bottom: 26px;

      @include mobile-only {
        margin-bottom: 32px;
      }

      .icon-check {
        font-size: 9px;
        color: #e4d6b5;
      }
    }

    .btn {
      margin-bottom: 28px;

      &.btn-add-to-cart {
        margin-bottom: 12px;
        background-color: $white;
        border: 1px solid $white;
        color: $rit-black;

        &:hover,
        &:active {
          background-color: transparent;
          color: $white;
        }
      }

      @include mobile-only {
        margin-bottom: 30px;
      }
    }

    .disclaimer {
      line-height: 18px;
    }

    .explanation-list {
      max-width: 85vw;
      padding-top: 8px;
      margin-bottom: 8px;
      justify-content: space-between;
      border: 0;

      @include tablet {
        max-width: 314px;
      }

      span {
        margin-bottom: 5px;
      }

      li {
        background-color: $dark-blue;
        border: 0;
        min-height: 76px;
        padding: 8px 12px;
        width: calc(50% - 5px);
        margin-bottom: 12px;

        @include tablet {
          padding: 5px 20px;
        }
      }

      .key-copy {
        color: $white;
      }

      .explanation-copy {
        color: #d0a880;
      }

      .btn-content-video  {
        padding: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  .simplebar-track.simplebar-horizontal {
    background-color: $gray-dark;

    .simplebar-scrollbar::before {
      background-color: $white;
    }
  }

  .slider-button {
    color: $white;
    border: 1px solid $white;

    &:hover {
      color: $rit-black;
    }

    &:first-child {
      left: 20px;
    }

    &:last-child:not(:only-child) {
      right: 20px;
    }
  }
}

.hotspot-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 1s $navigation-ease;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @include mobile-only {
    display: none;
  }

  &.fade-in {
    z-index: 1;

    .hotspot-button-wrapper {
      .btn {
        opacity: 1;
      }
    }
  }

  &.hidden {
    opacity: 0;
  }

  .hotspot-button-wrapper {
    display: flex;
    margin-bottom: 40px;
    max-width: 100%;

    @include mobile-only {
      position: fixed;
      bottom: 0;
      width: 100vw;
    }

    .btn {
      color: $white;
      opacity: 0;
      transition: .2s ease-in-out, opacity 1s $navigation-ease;
      min-height: 60px;
      background-color: $dark-navy;
      margin: 0 15px;
      min-width: 225px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover,
      &:active {
        background-color: $white;
        color: $dark-navy;
        border-color: $dark-navy;
      }

      @include mobile-only {
        min-width: 0;
        width: 30vw;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 10px;
        padding: 18px 16px 9px;
      }

      .ico {
        font-size: 23px;
        margin-right: 24px;

        @include mobile-only {
          margin: 0 0 10px 0;
        }
      }

      .text {
        letter-spacing: 4.2px;

        @include mobile-only {
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 3px;
        }
      }
    }
  }
}
