.product-exhibition-block {
  padding: 20px 4px;
  width: 100%;

  @include tablet {
    padding: 20px 0;
  }

  .inner-wrapper {
    @include tablet {
      display: flex;
      justify-content: space-between;
      max-width: 1360px;
    }
  }
}

.product-exhibition-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 24px;
  position: relative;

  @include mobile-only {
    height: 420px;
    margin-bottom: 64px;
    width: 100%;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  @include tablet {
    height: 628px;
    padding: 24px 24px 40px;
    width: calc(50% - 32px);

    @include tablet {
      &:hover {
        .image-holder {
          transform: translate(-50%, -50%) scale(1.01);
        }

        .btn-white {
          background-color: transparent;
          border-color: $white;
          color: $white;
        }
      }
    }

    @include ie-styles {
      &:hover .image-holder {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
  }

  &.product-exhibition-oos {
    &::before {
      background-color: $black;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .image-holder {
      opacity: 0.4;
    }

    .btn-white {
      display: none;
    }

    .btn-oos {
      display: inline-block;
    }
  }

  .image-holder {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.3s $navigation-ease;
    width: 100%;
  }

  .product-price {
    background-color: #041a2c;
    color: $white;
    font-size: 16px;
    letter-spacing: 1px;
    margin-right: auto;
    padding: 19px 17px 18px;
    position: relative;
  }

  .product-exhibition-button {
    margin-top: auto;
    position: relative;
    text-align: center;
    width: 100%;

    .btn {
      width: 100%;

      @include tablet {
        max-width: 320px;
      }
    }
  }

  .btn-white {
    background-color: $white;
    border-color: $white;
    color: $rit-black;
  }

  .btn-oos {
    background-color: $grey-medium-light;
    border-color: $grey-medium-light;
    display: none;
  }
}

.product-exhibition-link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}
