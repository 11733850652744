// --------------------------
//  ANCIENT RITUALS BG COLORS
// --------------------------
.ritual-bg-full-color {
  background-color: $rit-dark;
}

.ritual-bg-color {
  background-color: $rit-sandy-dark;
}

.white-bg-color {
  background-color: $white;
}

.black-bg-color {
  background-color: $rit-black;
}

.cardinal-bg-color {
  background-color: $cardinal;
}

.yellowshade-bg-color {
  background-color: $yellow-shade;
}

.pinklight-bg-color {
  background-color: $pink-light;
}

.dark-blue-bg-color {
  background-color: $dark-blue;
}

.sandy-bg-color {
  background-color: $rit-sandy-bg;
}

.sandy-light-bg-color {
  background-color: $rit-sandy-light;
}

.red-bg-color {
  background-color: $red-warning;
}

.dark-grey-bg-color {
  background-color: $ritual-easter-dark-grey;
}

.hommes-bg-color {
  background-color: $ritual-hommes-bg;
}

.hommes-bg-light-color {
  background-color: $ritual-hommes-light-bg;
}

.dao-bg-color {
  background-color: $ritual-dao-bg;
}

.hammam-bg-color {
  background-color: $ritual-hammam-bg;
}

.karma-bg-color {
  background-color: $ritual-karma-bg;
}

.sakura-bg-color {
  background-color: $ritual-sakura-bg;
}

.ayurveda-bg-color {
  background-color: $ritual-ayurveda-bg;
}

.primary-content > .mehr-bg-full-color {
  background-color: $easter-mehr-bg;
}

.primary-content > .karma-bg-full-color {
  background-color: $easter-karma-bg;
}

.primary-content > .sakura-bg-full-color {
  background-color: $easter-sakura-bg;
}

.flower-bg {
  background-image: url('../images/flower-bg-mobile.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;

  @include tablet {
    background-image: url('../images/flower-bg-desktop.png');
  }
}

// class for a block with responsive background
.responsive-bg {
  flex: 1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include tablet {
    height: auto;
  }
}
