.pt_account_loggedin {
  .page-content {
    left: 0;
    position: relative;
    transition: left .6s ease-in-out;
    width: 100%;

    .inner-wrapper {
      max-width: 1180px;
    }
  }

  @include mobile-only {
    .page-content .inner-wrapper {
      padding: 0;
    }
  }
}

.address-flyout {
  background-color: $rit-sandy-light;

  .address-flyout-inner {
    padding: 37px 23px 30px;
    position: relative;
  }

  > .side--close {
    display: none;
  }

  .side--close {
    top: 11px;
    left: 17px;
  }

  .edit-address-title {
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 30px;
    margin: 0 auto 40px;
    max-width: 240px;
    text-align: center;
    text-transform: none;
  }

  .account-address-list {
    .addresslist-fieldset-inner {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .pac-container {
      top: 100% !important;
    }
  }

  .form-row-button {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;

    .btn {
      margin: 0 auto;
    }
  }

  .selectric-wrapper {
    width: 100%;

    .selectric-items {
      width: 100% !important;
    }
  }

  .error-message {
    color: $gray-dark;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 20px;
  }

  @include mobile-only {
    .form-row-button {
      .cancel-button {
        margin-top: 20px;
        order: 2;
      }

      .delete-button {
        margin-top: 20px;
        order: 3;
      }
    }
  }

  @include tablet {
    &.generic-dialog-flyout {
      max-width: 720px;
    }

    .address-flyout-inner {
      padding: 60px 70px 30px;
    }

    .edit-address-title {
      font-size: 30px;
      letter-spacing: 1.2px;
      line-height: 40px;
      max-width: 400px;
    }

    .full-width-row.three-inline {
      width: calc(33% - 10px);
    }

    .form-row-button {
      flex-wrap: nowrap;
      font-size: 0;
      justify-content: center;
      width: 100%;

      .btn {
        margin: 0 10px;
        min-width: 10px;
        width: calc(34.2% - 20px);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.account-wrapper {
  display: flex;
  padding: 44px 0 113px;
  width: 100%;

  .account-title {
    border-bottom: 1px solid $gray-lines;
    font-size: 24px;
    letter-spacing: 6px;
    line-height: 30px;
    padding: 0 0 46px 60px;
    position: relative;

    .icon {
      font-size: 46px;
      left: -3px;
      line-height: 1;
      position: absolute;
      top: -7px;
    }

    &.account-title-mobile {
      display: none;
    }
  }

  @include tablet {
    flex-wrap: wrap;
    padding-top: 60px;

    .account-title {
      border-bottom: 0;
      cursor: pointer;
      font-size: 30px;
      letter-spacing: 8px;
      line-height: 40px;
      margin-bottom: 0;
      padding: 0 0 39px 70px;
      width: 100%;

      .icon {
        top: -3px;
      }

      &.account-title-mobile {
        display: block;
      }
    }
  }
}

.app-block {
  a {
    text-decoration: none;
  }

  .image {
    width: 100%;

    @include tablet {
      max-width: 360px;
    }
  }

  img {
    display: block;
    margin-bottom: 40px;
    max-width: 100%;
  }

  .block-title {
    font-size: 15px;
    letter-spacing: 5px;
    line-height: 23px;
    margin-bottom: 6px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .store__btns {
    padding-top: 21px;

    a {
      float: left;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include tablet {
    img {
      margin-bottom: 36px;
    }
  }
}

.account-left-content {
  width: 100%;

  .account-menu-wrap {
    border-bottom: 1px solid $gray-lines;
    margin-bottom: 30px;
    padding: 3px 0 12px;
    width: 100%;

    .account-logout {
      color: $gray-dark;
      display: block;
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 19px;
      padding: 10px 0 10px 45px;
      position: relative;
      text-decoration: none;
      text-transform: none;

      &:hover {
        color: $rit-copper;
      }

      &.active {
        color: $rit-black;

        .icon {
          color: $rit-black;
        }
      }

      .icon-arrow5--left {
        color: rgba($black, .6);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 27px;
        left: 2px;
        margin-top: 1px;
      }
    }
  }

  @include mobile-only {
    flex: 0 0 100%;
    padding: 0 20px;
  }

  @include tablet {
    width: 31.6%;

    .account-title {
      display: none;
    }

    .account-menu-wrap {
      border-top: 1px solid $gray-lines;
      margin-bottom: 40px;
      padding: 33px 0 32px;
    }

    .account-membership-cart {
      display: none;
    }
  }
}

.account-menu {
  list-style: none;
  width: 100%;

  li {
    display: block;
    margin-bottom: 15px;
  }

  a {
    color: $gray-dark;
    display: block;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    padding: 10px 0 10px 45px;
    position: relative;
    text-decoration: none;
    text-transform: none;

    &:hover {
      color: $rit-copper;
    }

    &.active {
      color: $rit-black;

      .icon {
        color: $rit-black;
      }
    }

    .icon {
      color: rgba($black, .6);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-data {
      font-size: 18px;
      left: 3px;
    }

    .icon-cart {
      font-size: 19px;
      left: 2px;
      margin-top: 1px;
    }

    .icon-cities {
      font-size: 24px;
      margin-top: 1px;
    }

    .icon-newsletter {
      font-size: 22px;
      left: 2px;
      margin-top: 2px;
    }
  }
}

.create-account-wrapper {
  padding: 40px 0 0;

  @include tablet {
    padding: 108px 0 80px;
  }

  .inner-wrapper {
    padding: 0;
    max-width: 1180px;

    @include tablet {
      padding: 0 20px;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include tablet {
        padding: 0 50px;
        max-width: 1440px;
      }
    }
  }

  .create-account-container {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  .create-account-left-content {
    max-width: 653px;
    padding: 0 20px;
    flex: 1;

    @include tablet {
      margin-right: 70px;
      padding: 0;
    }

    @include desktop {
      margin-right: 130px;
    }

    h2.h4 {
      border-bottom: 1px solid $gray-gainsboro;
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 24px;
      text-transform: none;
      padding-bottom: 15px;
      margin-bottom: 20px;
    }

    .form-row {
      position: relative;
    }

    .form-caption {
      font-size: 12px;
      color: $gray-medium;
    }

    .label-inline {
      margin-top: 15px;

      @include tablet {
        margin-top: 32px;
      }

      &.required {
        margin-top: 38px;

        @include tablet {
          margin-top: 38px;
        }

        &:not(.hide) + .label-inline.required {
          margin-top: 15px;
        }
      }

      .input-row {
        input[type=checkbox].focus-visible + label.floating {
          &::before {
            top: 2px;

            @include tablet {
              top: -1px;
            }
          }
        }

        label.floating {
          line-height: 28px;
          padding-left: 28px;

          &::before,
          &::after {
            top: 5px;

            @include tablet {
              top: 2px;
            }
          }

          @include tablet {
            line-height: 26px;

            a {
              line-height: 22px;
            }
          }
        }
      }
    }

    .btn-container,
    .form-row-button {
      margin-top: 40px;
      margin-bottom: 40px;

      @include tablet {
        margin-bottom: 0;
      }
    }

    .selectric-wrapper:not(.selectric-inputfield-select) {
      .selectric {
        .label {
          padding-left: 0;
          font-family: $font-header;
        }

        .button {
          top: 9px;
          right: -7px;
        }
      }

      .selectric-items {
        li {
          font-family: $font-header;
        }
      }
    }
  }

  .create-account-right-content {
    .secondary-navigation {

      .content-asset {
        background-color: $white;
        padding: 37px 40px 57px 20px;

        @include tablet {
          max-width: 360px;
          padding: 37px 39px 9px 42px;
        }
      }

      ul {
        list-style: none;

        li {
          margin-bottom: -5px;

          a {
            color: $rit-copper;
          }

          &:last-of-type {
            margin-bottom: 4px;
          }
        }
      }

      h3 {
        font-size: 16px;
        letter-spacing: 2.63px;
        line-height: 16px;
        color: $rit-black;
        text-transform: uppercase;

        &:nth-of-type(3) {
          margin-top: 0;
          margin-bottom: 17px;

          @include tablet {
            margin-top: -3px;
            margin-bottom: 16px;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 1px;

        @include tablet {
          padding-right: 20px;
        }
      }

      b {
        font-weight: 700;
      }

      span {
        font-size: 14px;
        letter-spacing: 0.88px;
        line-height: 17px;
        display: inline-block;
        width: calc(100% - 30px);
        margin-bottom: 17px;
        vertical-align: top;
      }

      .icon-check {
        font-size: 10px;
        color: $rit-copper-dark;
        margin-right: 15px;
        width: 14px;
      }
    }
  }

  .label-inline {

    .input-row,
    .inputfield-row {
      position: relative;
      width: 100%;
      margin-top: 11px;
    }

    .input-row {
      label.floating {
        display: block;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
        padding-left: 25px;
        position: relative;
        color: $rit-dark;
        cursor: pointer;
      }
    }
  }
}

.account-wrapper .account-right-content {
  width: 100%;

  .create-account-wrapper {
    background-image: none;
    padding: 0;
  }

  .create-account-container {
    display: block;

    .create-account-left-content {
      max-width: none;
      width: 100%;
      padding: 0;
    }
  }

  .btn-container {
    margin-top: 30px;
    max-width: none;
  }

  .block-member-card {
    display: none;
    margin-bottom: 44px;

    .icon-user {
      font-size: 28px;
      top: -11px;
      left: -1px;
    }

    .account-membership-cart {
      margin: 0;
    }
  }

  .dashboard-row {
    width: 100%;

    .dashboard-block.loyalty-card {
      margin-bottom: 44px;

      @include tablet {
        margin-bottom: 87px;
      }

      .section-title {
        margin-bottom: 32px;
        padding-left: 47px;

        .icon-user {
          font-size: 27px;
          left: 2px;
          top: -12px;
        }
      }

      .account-membership-cart {
        @include mobile-only {
          display: none;
        }
      }
    }
  }

  .section-title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 2.6px;
    line-height: 1;
    margin-bottom: 22px;
    padding-left: 44px;
    position: relative;
    text-transform: uppercase;

    .icon {
      position: absolute;
    }

    .icon-cart-basket {
      font-size: 18px;
      left: 2px;
      top: -1px;
    }

    .icon-data {
      font-size: 18px;
      left: 1px;
      top: -2px;
    }

    .icon-cities {
      font-size: 22px;
      left: 0;
      top: -4px;
    }

    .icon-newsletter {
      font-size: 22px;
      left: 4px;
      top: -4px;
    }

    h2 {
      font-size: 16px;
      letter-spacing: 2.6px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }

  .newsletter-subsection {
    h2 {
      font-size: 16px;
      letter-spacing: 2.6px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }

  .newsletter-country-selector,
  .country-selector,
  .language-selector {
    margin-bottom: 20px;

    select {
      width: 100%;
      color: $rit-dark;
      background-position: left;
      padding-left: 24px;
      border-bottom: 1px solid $rit-dark;

      &.language {
        padding-left: 5px;
      }
    }

    .arrow-down {
      float: right;
      top: -30px;
      position: relative;

      i {
        @include icon(arrow3--down);
        color: $rit-dark;
        font-size: 28px;
      }
    }
  }

  .policyCheckbox {
    display: none;
  }

  .recent-order {
    margin-bottom: 45px;
  }

  .order-block {
    background-color: $rit-sandy-light;
    position: relative;
    width: 100%;

    .block-inner-wrapper {
      display: flex;
      padding: 14px;
      position: relative;

      &::before {
        background-color: $crimson;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }

    .order-image {
      text-align: center;
      width: 70px;

      img {
        max-width: 100%;
      }
    }

    .order-details {
      padding: 0 5px 0 15px;
      width: calc(100% - 114px);
    }

    .order-detail-item {
      color: $rit-dark;
      display: block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
    }

    .date {
      color: $rit-black;
      font-family: $font-header;
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 29px;
      margin-bottom: 5px;
    }

    .number {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      margin-bottom: 18px;
    }

    .order-arrow {
      border-left: 1px solid $gray-lines;
      margin: 5px 0;
      position: relative;
      width: 44px;

      span {
        color: $rit-black;
        font-size: 42px;
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);

        @include tablet {
          right: -12px;
        }
      }
    }
  }

  .personal-details {
    margin-bottom: 85px;

    .block-inner-wrapper {
      span {
        color: $rit-dark;
        display: block;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 12px;
      }
    }
  }

  .address-book {
    margin-bottom: 70px;

    .mini-address-location {
      color: $rit-dark;
      display: block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 32px;
      margin-top: -3px;
    }

    a {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  .back-button {
    position: absolute;
    top: -33px;
    left: 8px;
    display: none;

    @include mobile-only {
      display: block;
    }
  }

  .pt_recommended-products {
    overflow: hidden;

    .recommendations-block-wrapper {
      width: 100%;
    }
  }

  @include mobile-only {
    display: none;
    flex: 0 0 100%;
    padding: 34px 20px;
    position: relative;

    .mobile-back-link {
      display: block;
    }
  }

  @include tablet {
    border-top: 1px solid $gray-lines;
    margin-left: auto;
    padding-top: 44px;
    width: 66%;

    .personal-details-container {
      margin-left: 23px;

      .section-title {
        margin-left: -47px;
      }
    }

    .section-title {
      margin-bottom: 23px;
      padding-left: 47px;

      .icon-data {
        left: 6px;
        top: -1px;
      }

      .icon-cities {
        font-size: 24px;
        left: 3px;
      }
    }

    .recent-order {
      margin-bottom: 84px;

      .order-block .order-arrow {
        opacity: 0;
      }
    }

    .order-block {
      margin-left: 46px;
      width: calc(100% - 46px);

      .block-inner-wrapper {
        padding: 26px 24px;
      }

      .order-image {
        width: 100px;
      }

      .order-details {
        padding: 0 5px 0 36px;
        width: calc(100% - 144px);
      }

      .date {
        margin-bottom: 10px;
      }

      .number {
        margin-bottom: 17px;
      }

      .size,
      .price,
      .status {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        vertical-align: top;

        &::after {
          content: '|';
          margin-left: 5px;
        }

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }

    .personal-details,
    .address-book {
      float: left;
      width: 50%;

      .section-title {
        margin-bottom: 26px;
      }

      .block-inner-wrapper {
        padding-left: 47px;
      }
    }

    .address-book {
      padding-left: 13px;
    }

    .account-usp {
      display: none;
    }
  }
}

.personal-details-container {
  width: 100%;
}

.address-list-container {
  padding: 16px 0 80px;
  width: 100%;

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .btn-container {
    padding-top: 24px;
  }

  .address-list {
    list-style: none;
    width: 100%;

    .address-tile {
      border-bottom: 1px solid $gray-lines;
      margin-bottom: 38px;
      padding-bottom: 36px;

      &.default {
        border-bottom: 0;
        margin-bottom: 37px;
        padding-bottom: 0;

        .address-wrapper {
          background-color: $rit-sandy-light;
          padding: 20px;
        }

        &:last-child {
          .secondary-title {
            display: none;
          }

          .address-wrapper {
            background-color: transparent;
            padding: 0;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 15px;
        padding-bottom: 0;
      }
    }

    .secondary-title {
      color: $rit-dark;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .address-wrapper {
      color: $rit-dark;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;

      .mini-address-name + .mini-address-name {
        display: none;
      }
    }

    .mini-address-name {
      margin-bottom: 6px;
    }

    .mini-address-location {
      margin-bottom: 25px;

      address {
        font-size: 16px;
        line-height: 32px;
      }
    }

    .address-actions a {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      margin-right: 15px;
      position: relative;
      text-transform: uppercase;

      &::after {
        color: $gray-lines;
        content: '|';
        font-size: 13px;
        position: absolute;
        right: -13px;
        top: 0;
        text-decoration: underline;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }

    .address-actions a::after {
      text-decoration: none;
    }
  }

  @include tablet {
    padding: 17px 0 47px 47px;

    .btn-container {
      padding-top: 20px;

      .btn {
        width: auto;
      }
    }

    .address-list {
      padding-left: 47px;

      &.account-address-list {
        padding-left: 0;
      }

      .address-tile {
        padding-bottom: 28px;

        &.default {
          padding-bottom: 0;

          .address-wrapper {
            padding: 42px 40px 28px;
          }
        }
      }

      .address-wrapper {
        display: flex;
        flex-wrap: wrap;

        .mini-address-name {
          width: 45%;
        }

        .mini-address-location {
          margin-bottom: 0;
          order: 3;
          width: 45%;
        }

        .address-actions {
          margin-top: -4px;
          order: 2;
          text-align: right;
          width: 55%;
        }
      }

      + .btn-container {
        padding: 53px 0 0 47px;
      }
    }
  }
}

.subscriptions {
  .newsletter {
    padding: 9px 0 78px;

    p,
    .success {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }

    a {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }

    .newsletter-checkbox {
      left: 0;
      position: absolute;
      top: 1px;

      input {
        &.focus-visible + label {
          &::before {
            top: -3px;
          }

          &::after {
            top: 0;
          }
        }
      }

      label {
        display: block;
        height: 16px;
        position: relative;
        width: 16px;

        &::before,
        &::after {
          top: 0;
        }
      }
    }

    &.optin-wrapper {
      .signup-label {
        display: block;
        margin: 29px 0 34px;
        padding-left: 32px;
        position: relative;
      }

      .newsletter-label {
        display: block;
        line-height: 20px;
        cursor: pointer;
      }
    }


    .btn-container {
      .btn {
        margin-bottom: 20px;
      }

      .linked-email-container {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @include tablet {
    .newsletter {
      margin-top: -4px;
      padding: 0 0 0 46px;

      .btn-container {
        align-items: center;
        display: flex;

        .btn {
          margin: 0 40px 0 0;
          width: auto;
        }
      }
    }
  }
}

.orders-container {
  padding: 15px 0 60px;

  .order-block {
    margin-bottom: 20px;
  }

  .order-dropdown-details {
    display: none;

    .product-row {
      align-items: flex-start;
      border-top: 1px solid $gray-lines;
      display: flex;
      margin: 0 0 20px 20px;
      padding-top: 30px;
      width: calc(100% - 40px);

      &:first-child {
        border-top: 0;
        margin-top: 35px;
        padding-top: 0;
      }

      .product-image {
        text-align: center;
        width: 60px;

        img {
          max-width: 100%;
        }
      }

      .orders-details-right {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;
        width: calc(100% - 60px);
      }

      .the-ritual-of-title {
        color: $rit-copper-dark;
        font-size: 11px;
        letter-spacing: 1.65px;
        line-height: 24px;
        width: 100%;
      }

      .product-name {
        font-family: $font-header;
        font-size: 16px;
        letter-spacing: 2.6px;
        line-height: 20px;
        margin-bottom: 5px;
        text-transform: uppercase;
        width: 100%;
      }

      .hairtemple-product-options {
        color: $rit-dark;
        display: block;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: .5px;
        margin: 9px 0 0;
        text-transform: none;
      }

      .product-variation {
        color: $rit-dark;
        display: none;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 5px;
        width: 100%;
      }

      .product-amount {
        font-size: 20px;
        line-height: 24px;
      }

      .product-price {
        font-family: $font-header;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        margin-left: auto;
        text-align: right;

        .price-regular {
          display: block;
        }

        .price-value-unadjusted {
          display: block;
          color: $gray-suva;
          font-size: 12px;
          letter-spacing: 1.8px;
          line-height: normal;
          text-decoration: line-through;
        }
      }
    }

    .totals-block {
      background-color: $white;
      padding: 20px;
      width: 100%;

      .price-row {
        color: $rit-copper-dark;
        display: flex;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 10px;
        width: 100%;

        .value {
          margin-left: auto;
        }

        .item {
          margin-right: 5px;
        }
      }

      .summary-row {
        border-bottom: 1px solid $gray-lines;
        color: $rit-black;
        font-family: $font-header;
        font-size: 13px;
        line-height: 30px;
        margin-bottom: 27px;
        padding-bottom: 21px;
        text-transform: uppercase;
      }

      .total {
        border-top: 1px solid $rit-dark;
        color: $rit-black;
        font-family: $font-header-bold;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        margin: 33px 0 20px;
        padding-top: 30px;

        .item {
          text-transform: uppercase;
        }
      }

      .download-invoice {
        margin: 23px 0 0 2px;

        a {
          font-size: 14px;
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .search-result-options {
    display: block;
    height: auto;
    margin-left: 0;
    padding-top: 14px;
    text-align: center;
    width: 100%;

    ul {
      width: 100%;

      li {
        color: $rit-dark;
        display: inline-block;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
        margin: 0 -2px;
        padding: 0 12px;
        position: relative;

        &::after {
          background-color: $gray-gainsboro;
          content: '';
          height: 16px;
          position: absolute;
          right: 0;
          top: 6px;
          width: 1px;
        }

        &:last-child::after {
          display: none;
        }
      }

      a {
        color: $rit-copper;
        text-decoration: underline;
      }
    }
  }

  @include tablet {
    padding: 18px 35px 0 46px;

    .order-block {
      .block-inner-wrapper {
        cursor: pointer;
      }

      .order-arrow .icon {
        transition: transform .3s ease;
      }

      &.active .order-arrow .icon {
        transform: translateY(-50%) rotate(-180deg);
      }
    }

    .order-dropdown-details {
      padding: 40px 60px 0 40px;

      .product-row {
        padding: 25px 0 15px 15px;
        margin: 0 0 20px;
        width: 100%;

        &:first-child {
          margin: 0;
        }

        .product-image {
          width: 70px;
        }

        .orders-details-right {
          padding: 10px 0 0 35px;
          width: calc(100% - 70px);
        }

        .product-name,
        .product-variation,
        .the-ritual-of-title {
          order: 1;
          width: calc(100% - 180px);
        }

        .product-amount {
          margin-left: auto;
          order: 2;
          text-align: center;
          width: 60px;
        }

        .product-price {
          margin-left: 0;
          order: 2;
          width: 90px;

          .price-value-unadjusted {
            line-height: 24px;
          }
        }
      }

      .totals-block {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin: 30px 0 0 -40px;
        padding: 40px 50px 30px;
        width: calc(100% + 100px);

        .price-row {
          max-width: 286px;
        }

        .total {
          margin: 23px 0 20px;
        }

        .download-invoice {
          align-self: flex-start;
          margin: -40px 0 0 -10px;
        }
      }
    }

    .search-result-options {
      padding-left: 46px;
    }
  }
}

.recommendations-block-wrapper {
  .product-tile {
    @include ie-styles {
      display: block;
    }
  }

  .product-image {
    @include ie-styles {
      width: 100%;

      a {
        display: block;
        width: 100%;
      }
    }
  }
}

// Newsletter styles
.newsletter-subscribe-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include mobile-only {
    margin-bottom: 30px;
  }

  @include tablet {
    flex-direction: row;
  }

  .newsletter-left-content,
  .newsletter-right-content {
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }

  .newsletter-right-content {
    padding: 30px 30px 60px;

    @media screen and (min-width: 376px) {
      padding: 40px 50px;
    }

    @include tablet {
      padding: 60px 30px;
    }

    @include desktop {
      padding: 80px 60px;
    }

    .inner-wrapper {
      max-width: 600px;
      margin: 0;
      padding: 0;
    }
  }

  .newsletter-left-content {
    @include tablet {
      flex: 1;
      display: flex;
      height: auto;
    }
  }

  .newsletter-heading {
    font-size: 30px;
    line-height: 40px;
    color: $black;
    letter-spacing: 4px;
    margin-bottom: 11px;
    text-transform: none;

    @include tablet {
      font-size: 40px;
      letter-spacing: 1.6px;
      line-height: 50px;
      margin-bottom: 18px;
    }
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: -4px;

    @media screen and (min-width: 376px) {
      margin-bottom: 9px;
      max-width: 525px;
    }

    @include tablet {
      margin-bottom: 3px;
    }

    @include desktop {
      margin-bottom: 25px;
    }
  }

  .form-caption {
    font-size: 12px;
    color: $gray-medium;
  }

  .form-row {
    .input-row {
      margin-top: 30px;

      @include tablet {
        margin-top: 24px;
      }

      input {
        background-color: transparent;
      }
    }

    &:nth-of-type(7) {
      margin-bottom: 39px;

      @media screen and (min-width: 376px) {
        margin-bottom: 21px;
      }

      @include desktop {
        margin-bottom: 32px;
      }
    }
  }

  .form-row.label-inline {
    .input-row,
    .inputfield-row {
      position: relative;
      width: 100%;
      margin-top: 11px;

      // input {
      //   @include checkbox-input;
      // }
    }

    .input-row .error {
      padding-left: 25px;
      display: block;
    }
  }

  .form-row-button {
    margin-top: 40px;

    @include mobile-only {
      text-align: center;
    }

    .btn {
      width: 100%;

      @media screen and (min-width: 376px) {
        width: auto;
      }
    }
  }

  .newsletter-error {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $font-body;
  }
}

// sample page form
.product-sample-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include tablet {
    flex-direction: row;
  }

  .inner-wrapper {
    margin: 0;
    max-width: 600px;
    padding: 0;
  }

  .newsletter-left-content,
  .newsletter-right-content {
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }

  .newsletter-left-content {
    height: 180px;

    @include tablet {
      flex: 1;
      display: flex;
      height: auto;
    }
  }

  .newsletter-right-content {
    padding: 30px 30px 60px;

    @include tablet {
      padding: 60px 30px;
    }

    @include desktop {
      padding: 80px 60px;
    }
  }

  h1.h3 {
    font-size: 20px;
    line-height: 1.3;
    color: $black;
    letter-spacing: 3.3px;
    margin-bottom: 11px;

    @include tablet {
      font-size: 28px;
      letter-spacing: 7.5px;
      margin-bottom: 5px;
    }

    @include desktop {
      font-size: 30px;
      letter-spacing: 8px;
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 0;

    @include tablet {
      margin-bottom: 3px;
    }

    @include desktop {
      margin-bottom: 13px;
    }
  }

  fieldset .form-row {
    &:first-child {
      margin: 20px 0 40px;
    }

    label {
      cursor: pointer;
    }
  }

  .form-row.label-inline .error {
    padding-left: 25px;
    display: block;
  }

  .form-row-button {
    margin-top: 40px;

    .btn {
      @include mobile-only {
        width: 100%;
      }
    }
  }

  .newsletter-error {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $font-body;
  }
}

.checkout-register-wrapper {
  .personal-details-container {
    text-align: left;
    margin-top: 27px;

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      background-color: $white;

      @include mobile-only {
        margin-top: 0;
        padding: 24px;
      }

      @include tablet {
        margin-top: 0;
        padding: 40px;
      }
    }

    .rituals-form {
      .form-row-button {
        margin-top: 42px;
        text-align: center;
      }

      .btn {
        // Checkout V3 Overrides
        .login-checkout-v3 & {
          max-width: none;
          width: 100%;
        }
      }
    }
  }

  .page-layout-right {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include mobile-only {
        margin-bottom: 30px;
      }
    }
  }

  .inner-wrapper-small {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include tablet {
        max-width: none;
      }
    }
  }

  .label-inline.required {
    margin-top: 40px;

    &:not(.hide) + .label-inline.required {
      margin-top: 15px;
    }
  }

  @include mobile-only {
    padding: 57px 20px 100px;

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      padding: 57px 0 0 0;
    }

    .form-row-button {
      min-height: 45px;
    }

    .btn-register-submit {
      transition: background .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out;
    }
  }

  @include tablet {
    padding: 61px 0 80px;

    .personal-details-container {
      margin: 34px auto 0;
      max-width: 620px;

      // Checkout V3 Overrides
      .login-checkout-v3 & {
        max-width: none;
      }
    }
  }
}

.account-verification {
  text-align: center;

  p {
    font-size: 16px;
    line-height: 28px;
  }

  .btn {
    margin-top: 15px;
  }

  @include tablet {
    padding-bottom: 25px;
  }
}

.form-content,
.form-content-disclaimer {
  padding: 0 24px 0 33px;
  margin-top: 20px;

  @include tablet {
    margin-top: 15px;
    padding-right: 33px;
  }

  p, a {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 0;
  }
}

.privacy-policy {
  text-align: left;
}

.form-content-disclaimer + .form-content {
  margin-top: 20px;
}

//SMS onboarding Thank you page
.sms-thank-you-page-top {
  padding: 40px 0 15px;
  text-align: center;

  @include tablet {
    padding-top: 50px;
  }

  .thank-you-heading {
    font-size: 25px;
    letter-spacing: 0.7px;
    line-height: 30px;
    max-width: 290px;
    margin: 0 auto;
    text-transform: none;

    @include tablet {
      font-size: 30px;
      letter-spacing: 1.2px;
      line-height: 40px;
      max-width: 365px;
    }
  }
}

.loyalty-card-wrapper {
  padding: 15px 0;

  .loyalty-card-content {
    max-width: 315px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba($black, 0.2);

    .loyalty-card {
      padding-top: 20px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .image-holder {
      background-color: $white;
      padding: 16px 0;
    }

    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }

    .loyalty-card-heading {
      display: block;
      text-align: right;
      padding-right: 34px;
      color: $rit-copper;
      font-size: 10px;
      letter-spacing: 1.6px;
      line-height: 24px;
      text-transform: uppercase;
      font-family: $font-header;
    }

    .text-holder {
      text-align: right;
      padding-right: 36px;
      margin-top: 7px;
      padding-bottom: 28px;
    }

    .customer-name {
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 24px;
      display: block;
      font-family: $font-header-bold;
      margin-bottom: 1px;
      font-weight: bold;
    }

    .customer-id {
      display: block;
      font-size: 12px;
      letter-spacing: 2px;
    }
  }
}

.account-membership-cart {
  background-color: $rit-sandy-light;
  display: flex;
  justify-content: space-between;

  @include mobile-only {
    align-items: flex-start;
    background-color: transparent;
    flex-direction: column;
    margin: 20px 0;
    padding: 15px 20px 20px;
    width: 100%;
  }

  @include tablet {
    align-items: center;
    flex-direction: row;
    margin-left: 46px;
    padding: 28px 40px 38px;
    width: calc(100% - 46px);
  }

  .user-status {
    color: $black;
    display: block;
    font-family: $font-header;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 29px;
    margin-bottom: 10px;
  }

  .membercard-number {
    color: $rit-dark;
    display: block;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
  }

  .account-membership-cart-right {
    display: flex;
    height: 48px;

    @include mobile-only {
      margin-top: 40px;
      max-width: 200px;
    }

    @include tablet {
      margin-top: 0;
      max-width: none;
    }
  }

  img {
    max-width: 100%;
  }

  .account-discount-banner {
    background-color: $white;
    margin-top: 33px;
    padding: 28px 26px 24px;

    &::before {
      background-color: $gray-lines;
      content: '';
      display: inline-block;
      height: 1px;
      left: -10px;
      position: absolute;
      right: -10px;
      top: -20px;
    }

    .heading-h10 {
      font-size: 9px;
      letter-spacing: 1px;
      line-height: 12px;
      margin-bottom: 0;
    }

    p {
      font-size: 11px;
      line-height: 19px;
    }
  }
}

.account-pagination {
  padding-top: 14px;
  text-align: center;
  width: 100%;

  @include tablet {
    padding-left: 46px;
  }

  .account-pagination-numbers {
    display: inline-block;
  }

  .account-pagination-item {
    color: $rit-copper;
    display: inline-block;
    font-family: $font-body;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 12px;
    position: relative;
    text-decoration: underline;

    &.hide,
    &.account-pagination-item-next::after {
      display: none;
    }

    &::after {
      background-color: $gray-lines;
      content: '';
      height: 16px;
      position: absolute;
      right: 0;
      top: 6px;
      width: 1px;
    }

    &.selected {
      color: $rit-dark;
      pointer-events: none;
      text-decoration: none;
    }
  }
}

//employee discount
.employee-discount {
  margin: -40px 0 40px;
  width: 100%;

  .employee-discount-block {
    background-color: $white;
    padding: 20px;
    width: 100%;

    @include tablet {
      margin-left: 46px;
      width: calc(100% - 46px);
    }
  }
}

.account-discount-banner {
  background-color: $rit-sandy-bg;
  padding: 24px 26px 20px;
  position: relative;
  width: 100%;

  .account-discount-progress-holder {
    background-color: $gray-lines;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .account-discount-progress-bar {
      background-color: $copper;
      height: 2px;
      left: 0;
      position: absolute;
      width: 0;
    }
  }

  .heading-h10 {
    font-size: 12px;
    letter-spacing: 1.9px;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// CRM My Rituals 2.0
.member-card-wrapper {
  background-color: $rit-black;
  padding: 40px 0 24px;
  position: relative;

  &::after {
    background-color: $white;
    bottom: 0;
    content: '';
    height: 200px;
    position: absolute;
    width: 100%;
  }

  .store-google-play {
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
}

.member-card-header {
  margin-bottom: 24px;
  padding: 0 24px;
  text-align: center;

  .heading-h7 {
    color: $white;
  }

  p {
    color: $white;
    line-height: 24px;
  }
}

.member-card {
  background-color: $rit-dark;
  background-image: url('../images/my-rituals/my-rituals-member-card-mountains-bg@4x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 200px;
  padding: 16px;
  position: relative;
  margin: 0 auto 24px;
  text-align: center;
  max-width: 327px;
  z-index: 1;

  @include tablet {
    margin: 0 0 24px;
  }

  span {
    display: block;

    &.member-phase {
      color: $rit-copper;
      font-family: $font-header;
      font-size: 10px;
      letter-spacing: 1.42px;
      line-height: 16px;
      margin-top: 4px;
      text-transform: uppercase;
    }

    &.member-name {
      color: $white;
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: 0.8px;
      line-height: 24px;
      margin: 2px 0;
    }

    &.member-number {
      color: $white;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 0.34px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    &.member-barcode {
      background-color: $white;
      height: 44px;
      padding: 4px;

      svg {
        max-width: 100%;
      }
    }
  }
}

.personalised-promotions-wrapper {
  .product-list-slider-intro {
    background-color: $rit-sandy-bg;
    margin: 0;

    @include mobile-only {
      background-image: url('../images/crm-envelope-top-inverted-dark.svg');
      background-position: center top;
      background-repeat: no-repeat;
      background-size: 45%;
      padding: 64px 16px 20px 16px;
    }

    @include tablet {
      padding: 64px 0 20px 0;
    }

    h1 {
      color: $copper;
      font-size: 12px;
      letter-spacing: 1.7px;
      line-height: 16px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    h2 {
      color: $rit-black;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 32px;
      margin-bottom: 24px;
      text-transform: none;

      @include desktop {
        font-size: 36px;
        line-height: 40px;
      }
    }

    p {
      margin: 0;

      @include mobile-only {
        font-size: 16px;
        letter-spacing: 0.6px;
        line-height: 24px;
      }
    }
  }

  .product-list-slider,
  .redeem-product {

    .product-tile .promotional-labels,
    .promotional-labels {
      display: flex;
      flex-direction: column;
      gap: 8px;
      left: 0;
      position: absolute;
      top: -150px;
      z-index: 1;

      @include tablet {
        top: -326px;
      }

      .promotional-label {
        background-color: $copper;
        bottom: unset;
        font-size: 14px;
        left: unset;
        margin: 0;
        position: relative;
        width: fit-content;

        &:nth-of-type(2) {
          background-color: $white;
          font-size: 16px;
        }
      }
    }
  }

  .redeem-product {
    .promotional-labels {
      top: 8px;

      @include tablet {
        top: 16px;
      }
    }
  }
}

.phase-icon {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  height: 48px;
  margin: 0 auto;
  width: 46px;

  &.phase-general { background-image: url('../images/my-rituals/my-rituals-phase-logo-general.svg'); }
  &.phase-general-dark { background-image: url('../images/my-rituals/my-rituals-phase-logo-general-dark.svg'); }
  &.phase-general-light { background-image: url('../images/my-rituals/my-rituals-phase-logo-general-dark.svg'); }
  &.phase-gratitude { background-image: url('../images/my-rituals/my-rituals-phase-logo-graditute.svg'); }
  &.phase-gratitude-dark { background-image: url('../images/my-rituals/my-rituals-phase-logo-graditute-dark.svg'); }
  &.phase-gratitude-light { background-image: url('../images/my-rituals/my-rituals-phase-logo-graditute-dark.svg'); }
  &.phase-joy { background-image: url('../images/my-rituals/my-rituals-phase-logo-joy.svg'); }
  &.phase-joy-dark { background-image: url('../images/my-rituals/my-rituals-phase-logo-joy-dark.svg'); }
  &.phase-joy-light { background-image: url('../images/my-rituals/my-rituals-phase-logo-joy-dark.svg'); }
  &.phase-purpose { background-image: url('../images/my-rituals/my-rituals-phase-logo-purpose.svg'); }
  &.phase-purpose-dark { background-image: url('../images/my-rituals/my-rituals-phase-logo-purpose-dark.svg'); }
  &.phase-purpose-light { background-image: url('../images/my-rituals/my-rituals-phase-logo-purpose-dark.svg'); }
  &.phase-secret { background-image: url('../images/my-rituals/my-rituals-phase-logo-secret.svg'); }
  &.phase-secret-dark { background-image: url('../images/my-rituals/my-rituals-phase-logo-secret-dark.svg'); }
  &.phase-secret-light { background-image: url('../images/my-rituals/my-rituals-phase-logo-secret-dark.svg'); }
  &.phase-soulmate { background-image: url('../images/my-rituals/my-rituals-phase-logo-soulmate.svg'); }
  &.phase-soulmate-dark { background-image: url('../images/my-rituals/my-rituals-phase-logo-soulmate-dark.svg'); }
  &.phase-soulmate-light { background-image: url('../images/my-rituals/my-rituals-phase-logo-soulmate-dark.svg'); }
}
