.header-404 {
  background-color: $rit-black;
  color: $white;

  @include desktop {
    padding: 20px 70px 20px;
    position: relative;
  }

  nav {
    width: 100%;
    display: flex;

    ul {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      justify-content: space-between;
      list-style-type: none;

      @include desktop {
        flex-direction: row;
      }

      li {
        padding: 30px 0 5px 10px;

        @include desktop {
          padding: 0;
        }

        &:first-of-type {
          border-bottom: 1px solid $white;

          @include desktop {
            border-bottom: 0;
          }
        }

        a {
          color: $white;
          font-family: $font-body;
          letter-spacing: 0.5px;
          text-underline-offset: 2px; // sass-lint:disable-line no-misspelled-properties

          @include desktop {
            align-items: center;
            display: flex;
            text-decoration: none;
          }

          span {
            display: none;

            @include desktop {
              display: block;
              margin-right: 15px;
              margin-top: 5px;
            }
          }
        }

        &:nth-child(2) {
          @include desktop {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          a {
            width: 140px;
          }
        }
      }
    }
  }
}

.error-404-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 73px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.error-404 {
  align-items: center;
  color: $black;
  display: flex;
  flex-direction: column;

  p,
  h3,
  h4,
  a {
    font-family: inherit;
    color: $black;
  }

  &--video {
    display: none;
    height: 100%;
    left: -1px;
    object-fit: fill;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &.desktop {
      @include desktop {
        display: block;
      }
    }

    &.mobile {
      @include mobile-and-tablet {
        display: block;
      }
    }
  }

  &--img-container {
    max-width: 230px;
    margin-top: 100px;

    @include desktop {
      max-width: 400px;
      max-height: 200px;
    }

    img {
      width: 100%;
    }
  }

  &--message {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 25px 0;

    p {
      font-family: 'inherit';
      font-size: 14px;
      margin-bottom: 0;
      padding: 0 25px;
      text-align: center;

      &:first-of-type {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 70px;
      }
    }
  }

  &--links-container {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 170px;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style-type: none;

      @include desktop {
        flex-direction: row;
      }

      li {
        font-size: 24px;
        padding-bottom: 10px;
        height: 70px;

        &:last-child {
          margin-bottom: 0;
        }

        @include desktop {
          padding: 0 10px 0 0;
        }

        .btn.error404-btn {
          color: $white;
          display: block;
          font-family: $font-body;
          font-size: 12px;
          padding: 16px 32px;
          text-decoration: none;
          line-height: unset;
          width: 350px;
          max-width: unset;

          @include desktop-medium {
            width: 300px;
          }

          @include desktop-large {
            width: 350px;
          }

          &:hover {
            color: $black;
          }
        }
      }
    }
  }
}

.columnTwoBody {
  display: flex;
  justify-content: center;

  &--customer-service {
    font-size: 12px;
    max-width: 510px;
    text-align: center;
    padding: 0 15px;

    a {
      text-decoration: underline;
    }
  }
}

.pt_error {
  .page-content.main {
    background-color: $ritual-green-elliott;
    .primary-content {
      background-color: transparent;
    }
  }
}
