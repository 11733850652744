.bullet-list-block {
  background-color: $rit-sandy-dark;
  padding: 80px 0;

  @include mobile-only {
    padding: 60px 0;
  }

  .bullet-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mobile-only {
      flex-direction: column;
    }

    @include mobile-and-tablet {
      padding: 0 20px;
    }
  }

  .bullet-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    width: 32%;

    @include mobile-only {
      align-items: center;
      flex-direction: column;
      margin-bottom: 36px;
      width: 100%;
    }
  }

  .bullet-img {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    height: 96px;
    justify-content: center;
    position: relative;
    width: 96px;

    @include mobile-only {
      margin: 0 0 13px 0;
    }
  }

  .bullet-title {
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 40px;
    margin: 16px 0;
    text-transform: initial;

    @include mobile-only {
      margin-bottom: 16px;
    }
  }

  .bullet-text {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 24px;
    margin: 0 auto;
    text-align: center;
    width: 90%;
  }

  .bullet-textbox {
    max-width: 380px;
    text-align: center;

    p, h3 {
        color: $black
      }

    @include mobile-only {
      margin-right: 0;
    }
  }

  .icon {
    color: $rit-copper-dark;
    font-size: 40px;
    line-height: unset;
  }

  .bullet-list-header {
    color: $black;
    font-size: 40px;
    letter-spacing: 1.8px;
    line-height: 48px;
    margin-bottom: 80px;
    text-align: center;
    text-transform: initial;

    @include mobile-only {
      font-size: 32px;
      letter-spacing: 1.4px;
      line-height: 40px;
      margin-bottom: 48px;
    }
  }
}
