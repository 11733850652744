.collection-description-block {
  background-color: $rit-sandy-bg;
  padding: 60px 0 30px;
  text-align: center;

  img {
    margin-bottom: 25px;
  }

  h2 {
    font-size: 30px;
    letter-spacing: 8px;
  }

  p {
    line-height: 28px;
    margin-bottom: 30px;
  }

  @include tablet {
    padding: 101px 0 50px;

    .inner-wrapper {
      opacity: 0;
      transition: opacity 1s ease;
    }

    h2 {
      margin: 0 auto 20px;
      max-width: 800px;
    }

    p {
      margin: 0 auto 30px;
      max-width: 650px;
    }

    &.fadein .inner-wrapper {
      opacity: 1;
    }
  }
}
