// header
.header {
  background-color: $rit-black;
  height: 88px;
  left: 0;
  position: sticky;
  top: 0;
  transition: transform $tertiary-duration $navigation-ease;
  width: 100%;
  z-index: 981;

  @include tablet {
    display: flex;
    flex-direction: column;
  }

  &:not(.header-checkout-v3):not(.header--empty-checkout) {
    @include desktop {
      height: 112px;
    }

    @include desktop-ultra {
      height: 136px;
    }
  }

  &.nav-active {
    z-index: $modal-window-index + 100;

    .nav-toggle {
      height: 24px;

      @include mobile-only {
        margin-top: -2px;
      }
    }
  }

  &.nav-hide {
      transform: translateY(-100%);
  }

  &.fastlane-header {
    @include nav-tablet {
      height: 64px;

      + .header-spacer {
        height: 64px;
      }
    }
  }

  &.personalised-gifting-header {
    @include mobile-only {
      height: 64px;

      .header--bottom,
      .navigation-inner {
        height: 64px;
      }

      .header-logo {
        left: 24px;
        margin-top: 0;
      }
    }

    @include nav-tablet {
      .header-logo {
        margin-top: -6px;
      }
    }

    @include tablet {
      .header-logo {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
    }

    @include desktop {
      position: fixed;
    }
  }

  .header-inline-icon {
    height: 24px !important;
    vertical-align: middle;
    width: 24px !important;

    @include desktop-ultra{
      height: 32px !important;
      width: 32px !important;
    }
  }
}

.header-spacer {
  display: none;

  body:not(.personalised-gifting-page) & {
    @include desktop {
      display: block;
      height: 95px;
    }
  }
}

// skip to navigation link
.skip-to-content {
  background: $rit-black-lighter;
  border: 0;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;

  &:focus {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
    color: $white;
    font-size: 12px;
    height: 30px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    z-index: $menu-index + 2;
  }
}

// top bar
.header--top {
  background-color: $rit-black-lighter;
  flex-shrink: 0;
  width: 100%;

  @include tablet {
    position: relative;
    top: 0;
  }

  &.header--top-pg {
    display: none;

    @include desktop-only {
      display: block;
    }
  }

  .inner-wrapper {
    display: flex;
    height: 32px;
    justify-content: space-between;
    padding: 8px 30px;

    @include desktop {
      height: 48px;
      max-width: 1512px;
      padding: 16px 96px;
    }

    @include desktop-ultra {
      height: 56px;
    }
  }

  .menu-top {
    display: none;
    font-size: 0;

    @include tablet {
      display: flex;
    }
  }

  .menu-top--item {
    display: inline-block;
    line-height: normal;
    list-style: none;
    margin-left: 8px;
    padding-left: 12px;
    position: relative;

    &::before {
      background-color: $gray-dark;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 2px;
      left: 2px;
      position: absolute;
      top: 8px;
      width: 2px;
    }

    &:first-child {
      margin-left: 0;
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    &.menu-top--item-desktop {
      @include nav-mobile-tablet {
        display: none;

        + .menu-top--item {
          margin-left: 0;
          padding-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    a {
      color: $white;
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 16px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @include desktop-ultra {
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
      }
    }
  }
}

.header-exclude-content {
  .inner-wrapper {
    align-items: center;
    height: 64px;
    padding: 0 24px;

    @include tablet {
      padding: 0 40px;
    }

    @include desktop {
      height: 64px;
      padding: 0 40px;
    }

    @include desktop-large {
      max-width: 1320px;
      padding: 0;
    }
  }

  .header-logo-desktop {
    display: block;
    left: 0;
    position: relative;
    top: 0;
    transform: none;
  }
}

.header-usp {
  max-width: 100%;
  text-align: center;

  @include tablet {
    max-width: 30%;
    text-align: start;
  }
}

.header-usp--item {
  color: $white;
  font-family: $font-body;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  opacity: 0;
  transition: opacity 500ms linear;
  vertical-align: top;

  @include mobile-only {
    -webkit-text-size-adjust: 100%;
  }

  .slick-active & {
    opacity: 1;
    transition: opacity 500ms linear 500ms;
  }

  @include desktop-ultra {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  a {
    color: $rit-copper-lighter;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }
}

// main header content
.header--bottom {
  align-items: center;
  background-color: $rit-black;
  display: flex;
  height: 56px;
  justify-content: space-between;
  position: relative;
  top: 0;
  width: 100%;

  @include desktop-only {
    height: 64px;
  }

  @include desktop-ultra {
    height: 80px;
  }
}

// navigation inner
.navigation-inner {
  display: flex;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;

  &.active {
    z-index: $menu-index;

    @include mobile-only {
      background-color: $rit-black-lighter;
    }

    .header--logo {
      @include mobile-only {
        display: none;
      }
    }

    .header--search {
      @include mobile-only {
        background-color: $rit-black-lighter;
      }

      .search-input-wrapper {
        @include mobile-only {
          margin-left: 50px;
          width: calc(100% - 50px);
        }
      }

      .icon-close.search-icon-close {
        @include mobile-only {
          left: -54px;
        }
      }
    }
  }

  .nav-inner-wrapper {
    display: flex;

    @include mobile-only {
      padding: 0 14px;
    }

    @include nav-tablet {
      padding: 0 40px;
    }

    @include desktop-only {
      align-items: center;
      max-width: 1512px;
      padding: 0 96px;
    }
  }

  .nav-additional-text {
    color: $gray-medium;
    font-family: $font-body;
    font-size: 11px;
    letter-spacing: 1px;
    margin-left: 2px;
    text-transform: uppercase;
  }
}

// hamburger menu
.nav-toggle {
  height: 13px;
  left: 27px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: $menu-index + 1;

  @include mobile-only {
    left: 17px;
  }

  @include nav-tablet {
    left: 43px;
    margin-top: 0;
  }

  @include desktop-only {
    display: none;
  }

  &.fade-out {
    @include mobile-only {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.active {
    .toggle-nav-bar {
      opacity: 0;
      visibility: hidden;
    }

    .icon-close {
      opacity: 1;
      visibility: visible;
    }
  }

  .toggle-nav-bar {
    background-color: $white;
    display: block;
    height: 1px;
    margin: 6px 0;
    width: 100%;

    &::before,
    &::after {
      background-color: $white;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      width: 100%;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  .icon-close {
    color: $white;
    display: inline-block;
    font-size: 22px;
    left: -2px;
    line-height: 1;
    opacity: 0;
    position: absolute;
    top: 3px;
    transition: opacity $navigation-default $navigation-ease;
    visibility: hidden;

    @include nav-tablet {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// header logo
.header-logo {
  color: $white;
  opacity: 1;

  &-mobile-tablet {
    display: block;
  }

  &-desktop {
    display: none;
  }

  @include nav-mobile-tablet {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @include mobile-only {
    left: 65px;
    width: 128px;
  }

  @include nav-tablet {
    left: 105px;
    width: 173px;
  }

  @include desktop-only {
    display: inline-block;
    flex-shrink: 0;
    width: 140px;

    &-mobile-tablet {
      display: none;
    }

    &-desktop {
      display: block;
    }
  }

  @include desktop-large {
    width: 173px;
  }

  @include desktop-ultra {
    width: 267px;
  }

  &.fade-out {
    @include mobile-only {
      transition-delay: 0s;
      opacity: 0;
    }
  }

  &.header-custom-logo {
    @include tablet {
      height: 19px;
      width: auto;

      .custom-logo {
        height: 100%;
      }

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  svg {
    display: block;
    height: 16px;
    width: 100%;
  }

  &.header-logo-b2b {
    width: 235px;

    @include mobile-only {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: -44px;
      scale: .8;
    }
  }
}

// header action links
.header-action-links {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 15px 0;
  width: 100%;

  @include tablet {
    gap: 32px;
    height: 56px;
    padding: 0;
  }

  @include desktop{
    gap: 32px;
  }

  @include desktop-ultra {
    height: 80px;
  }

  li {
    display: inline-block;
    list-style: none;
  }

  a {
    color: $white;
    line-height: 28px;
    text-decoration: none;

    span {
      position: relative;
    }

    .sr-only {
      position: absolute;
    }
  }
}

// header search
.header--search {
  flex: 0;
  width: auto;
  z-index: 2;

  @include desktop-only {
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    min-width: 152px;
    position: relative;
    transition: flex $quaternary-duration $default-ease-in-out;
  }

  @include desktop-medium {
    min-width: 240px;
  }

  @include ie-styles {
    flex-basis: 200px;
  }

  .search-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include tablet {
      padding: 8px;
    }
  }

  .search-input {
    background-color: transparent; // the bg and border color should be transparent because header background color can be changed
    border-color: transparent;
    color: $gray-gainsboro;
    font-family: $font-body-italic;
    font-size: 16px;
    letter-spacing: 2px;
    margin: 0;
    overflow: auto;
    padding: 0;
    width: 100%;


    &::placeholder {
      color: $gray-gainsboro;
    }

    @include desktop-only {
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
      padding-left: 16px;
    }
  }

  .icon-search-magnifier {
    svg {
      @include desktop-only {
        height: 16px !important;
        width: 16px !important;
      }
    }

    &::before {
      display: none;
    }
  }

  .search-clear {
    display: none;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 7px;
  }

  .icon-close {
    color: $white;
    cursor: pointer;
    display: none;
    font-size: 20px;
    padding-right: 8px;
  }
}

.search-expand {
  @include mobile-only {
    background-color: $rit-black-lighter;
    height: 56px;
    position: relative;

    &::before,
    &::after {
      background-color: $rit-black-lighter;
      bottom: 0;
      content: '';
      position: absolute;
      top: 0;
      width: 24px;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }

  .header--user-account,
  .header--cart {
    @include mobile-only {
      display: none;
    }
  }

  .header--search {
    border: none;
    flex: 1;
    margin-left: 0;
    z-index: 3;

    @include nav-tablet {
      max-width: 247px;
      position: relative;
    }

    @include desktop-only {
      max-width: 356px;
    }

    .search-input-wrapper {
      padding: 0;
      width: 100%;

      @include nav-mobile-tablet {
        display: block;
        margin-left: 30px;
        position: relative;
        width: calc(100% - 30px);
      }

      @include nav-tablet {
        height: 40px;
        right: -5px;
      }
    }

    .search-input {
      margin-right: 20px;

      @include mobile-only {
        background-color: $rit-black;
        border-radius: 24px;
        color: $gray-disabled;
        height: 48px;
        letter-spacing: 0.4px;
        line-height: 20px;
        padding: 3px 45px 0 22px;
      }

      @include nav-tablet {
        background-color: #2e2d2d;
        border-radius: 20px;
        font-size: 14px;
        height: 40px;
        letter-spacing: 0;
        line-height: 20px;
        padding: 3px 30px 0 20px;
      }

      @include tablet {
        margin-right: 20px;
      }

      @include desktop-only {
        font-size: 14px;
      }
    }

    .icon-search-magnifier {
      @include nav-mobile-tablet {
        position: absolute;
        transform: scale(0.9);
      }

      @include mobile-only {
        right: 18px;
        top: 14px;
      }

      @include nav-tablet {
        right: 7px;
        top: 11px;
      }

      @include desktop-only {
        margin-right: 9px;
      }

      .header-inline-icon {
        @include mobile-only {
          display: block;
        }
      }
    }

    .icon-close.search-icon-close {
      @include nav-mobile-tablet {
        font-size: 12px;
        margin: 0;
        padding: 10px;
        position: absolute;
      }

      @include mobile-only {
        left: -41px;
        top: 7px;
      }

      @include nav-tablet {
        display: inline-block;
        left: -30px;
        top: 4px;
      }

      &::before {
        content: icon-char(close);

        @include mobile-only {
          content: icon-char(arrow--back);
        }
      }
    }

    .search-clear {
      font-size: 16px;
      right: 13px;
      top: 13px;
    }
  }

  .search-suggestions-container {
    @include nav-tablet {
      height: auto;
      left: 0;
      margin-top: 48px;
      max-height: calc(100vh - 130px);
      overflow: auto;
      right: -70px;
    }
  }
}

// user account link
.header--user-account {
  transition: opacity $quaternary-duration $default-ease-in-out;

  .header--user-account-link {
    @include desktop-only {
      display: inline-block;
    }
  }
}

// cart link
.header--cart {
  transition: opacity $quaternary-duration $default-ease-in-out;

  .header--cart-link {
    display: inline-block;
    position: relative;

    @include desktop-only {
      top: -1px;
    }
  }

  .cart-number {
    align-items: center;
    background-color: $copper;
    border-radius: 50%;
    bottom: -7px;
    color: $white;
    display: flex;
    font-size: 12px !important;
    font-family: $font-body-bold;
    font-variant-numeric: lining-nums;
    height: 20px;
    justify-content: center;
    line-height: 16px !important;
    margin: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    width: 20px;

  }

  .cart-number-hidden {
    display: none;
  }

  .cart-number-show {
    display: flex;
  }
}

//
// mobile toast message
// ----------------------------------------------------------------------------
//

.mobile-cart-confirmation {
  background-color: $rit-sandy-light;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  color: $rit-black !important;
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  opacity: 0;
  padding: 40px 0;
  position: fixed;
  text-align: center;
  top: 0;
  transform: translateY(-110%);
  transition: transform $tertiary-duration $default-ease-out-fast;
  width: 100%;
  z-index: 999; // needs to be on top of darker overlay

  @include tablet {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 40px;
    justify-content: space-between;
  }

  @include desktop {
    display: none;
  }

  &.open {
    transform: translateY(0);
  }

  &.page-loaded {
    opacity: 1;
  }

  .mobile-cart-confirmation-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $modal-overlay-index + 2;
  }

  .close-toaster {
    z-index: $modal-overlay-index + 3;
  }

  .text-container {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  .icon-cart {
    margin-right: 18px;
  }

  .go-to-cart-link {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
  }

  .image-holder {
    @include mobile-and-tablet {
      max-width: 100px;
    }

    img {
      max-width: 100%;
    }
  }

  @include tablet-only {
    &.checkout-mobile-cart {
      padding: 20px 40px;
    }
  }

  @include mobile-only {
    &.checkout-mobile-cart {
      align-items: center;
      display: flex;
      height: auto;
      padding: 26px 20px 20px;
      top: 0;
      transform: translateY(-110%);
      transition: transform $tertiary-duration $default-ease-out-fast;
      z-index: $modal-overlay-index + 3;

      &.open {
        transform: translateY(0);
      }

      .image-holder {
        flex-shrink: 0;
        margin-right: 20px;
        width: 100px;

        img {
          display: block;
          max-width: 100%;
        }
      }

      .mini-cart-image {
        img {
          width: 100%;
        }
      }

      .text-holder {
        text-align: left;
      }

      .text-container {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 14px;
      }

      .go-to-cart-link {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}

//
// empty header
// ----------------------------------------------------------------------------
//

.header.header--empty-checkout {
  background-color: $rit-black;
  height: 40px;

  .cs-number--top {
    display: none;
  }

  .header-empty--logo {
    right: 16px;
    position: absolute;
    top: 9px;
    width: 84px;

    img {
      width: 100%;
    }

    svg {
      height: 16px;
      width: 100%;
    }
  }

  .header-empty--secure-checkout {
    color: $white;
    float: left;
    letter-spacing: 1px;
    margin: 13px 0 0 -3px;
    font-size: 14px;
    line-height: 20px;

    .icon-secure {
      display: inline-block;
      font-size: 20px;
      margin: -4px 6px 0 0;
      vertical-align: middle;
    }
  }

  @include tablet {
    height: 70px;

    .header-empty--secure-checkout {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1.14px;
      margin: 26px 0 0;

      .icon-secure {
        margin-right: 10px;
      }
    }

    .header-empty--logo {
      display: block;
      left: 44%;
      top: 25px;
      transform: translateX(-50%);
      width: 120px;
    }

    .cs-number--top {
      display: block;

      a {
        color: $white;
        float: right;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 14px;
        margin: 31px 0 0;
        text-decoration: none;
      }

      strong {
        font-weight: bold;
      }
    }
  }

  @include desktop {
    .inner-wrapper {
      padding: 0 40px 0 54px;
    }

    .header-empty--logo {
      height: 26px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 160px;

      .custom-logo {
        height: 100%;
        transform: translateX(-50%);
      }

      img {
        height: 100%;
        width: auto;
      }
    }
  }
}

.close-popup-text,
.filter-label-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

//
// Checkout V3 header
// ----------------------------------------------------------------------------
//

.header-checkout-v3 {
  position: relative;

  @include mobile-only {
    height: 56px;
  }

  @include tablet {
    height: 80px;
  }

  .inner-wrapper {
    @include desktop {
      padding: 0 40px 0 54px;
      height: 100%;
    }
  }

  .header-empty--logo {
    display: block;
    position: absolute;

    @include mobile-only {
      top: 19px;
      width: 84px;
    }

    @include tablet {
      top: 30px;
      width: 120px;
    }

    @include desktop {
      width: 160px;
    }

    img {
      width: 100%;
    }

    svg {
      height: 16px;
      width: 100%;
    }
  }

  .header-action-links {
    height: 100%;
  }

  .header--cart {
    height: 100%;
    margin: 0;

    @include mobile-only {
      margin-left: 16px;
      padding: 14px;
    }

    @include tablet {
      margin-left: 40px;
      padding: 24px;
    }

    .header--cart-link {
      height: 100%;
    }

    .header-inline-icon {
      @include mobile-only {
        height: 16px !important;
        width: 16px !important;
      }
    }

    .cart-number {
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 16px;
      text-align: left;

      @include mobile-only {
        bottom: 20px;
        left: 16px;
      }

      @include desktop {
        bottom: 26px;
        left: 22px;
      }
    }
  }

  .cs-number--top {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    a {
      color: $white;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;

      @include mobile-only {
        height: 30px;
        overflow: hidden;
        padding-left: 30px;
        white-space: nowrap;
        width: 30px;
      }

      @include tablet {
        padding-left: 38px;
      }

      &::before {
        color: $white;
        content: $icon-cs-info-icon;
        font-family: 'iconfont';
        font-size: 24px;
        left: 0;
        line-height: 30px;
        position: absolute;

        @include mobile-only {
          top: 0;
        }

        @include tablet {
          top: -6px;
        }
      }
    }

    strong {
      font-weight: bold;
    }
  }
}

//
// header on maintenance page
// ----------------------------------------------------------------------------
//

.header-maintenance {
  align-items: center;
  background-color: $rit-black;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;

  @include desktop {
    height: 70px;
  }
}

//
// Fastlane overrides / specific styling
// ----------------------------------------------------------------------------
//

.fastlane-header {
  &.header {
    @include mobile-only {
      height: 104px;
    }

    @include nav-tablet {
      height: 64px;
    }
  }

  .navigation-inner .nav-inner-wrapper {
    @include nav-tablet {
      padding: 0 20px;
    }
  }

  .header-logo {
    &.header--logo-pg {
      @include mobile-only {
        height: 22px;
        left: 22px;
        margin-top: 0;
        top: 68px;
        transform: translateY(0);
        width: auto;
      }

      @include tablet {
        height: 26px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
      }

      @include desktop-only {
        margin-right: 0;
        position: absolute;
      }

      svg {
        width: auto;
      }
    }

    .custom-logo {
      height: 100%;

      @include tablet {
        display: inline-block;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .header--bottom {
    @include mobile-only {
      height: 104px;
    }
  }
}

//
// Magazine page overrides
// ----------------------------------------------------------------------------
//

.pt_magazine {
  line-height: 28px;
  font-family: $font-body;

  .mag-landing .heading-block h1 {
    font-family: $font-header;
  }
}

//
// Header Shop Overlay
// ----------------------------------------------------------------------------
//

.header-shop-overlay ~ .header {
  .nav-desktop-layer {
    top: 136px;
  }
  .nav-mobile,
  .nav-mobile-inner,
  .nav-mobile-layer {
    top: 120px;
  }
  .nav-desktop-layer .nav-desktop-overlay {
    top: 136px;
  }
}

.header-shop-overlay {
  align-items: center;
  background-color: $red-warning;
  display: flex;
  justify-content: center;
  min-height: 40px;
  position: relative;
  width: 100%;
  z-index: 999;

  @include mobile-only {
    display: block;
    text-align: center;
  }
}

.header-shop-text {
  color: $white;
  font-size: 14px;
  margin: 0;

  @include mobile-only {
    padding: 10px;
  }
}

.header-shop-mail {
  font-weight: bold;
}

// Giftsets fastlane header
// ----------------------------------------------------------------------------
//

.header-gs {
  .fastlane-backtomain {
    a {
      color: $rit-black;
    }
  }

  .menu-top {
    .menu-top--item a {
      color: $rit-black;
    }
  }

  .st0 {
    fill: $rit-black;
  }
}
