// Inline alerts
.inline-alert {
  border: 1px solid $gray-disabled;
  color: $black;
  font-family: $font-body;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 16px;
  width: 100%;

  & + & {
    margin-top: 16px;
  }

  a {
    font-size: 14px;
  }

  span {
    display: block;
    padding-left: 32px;
    position: relative;

    &::before {
      border-radius: 50%;
      height: 16px;
      left: 0;
      line-height: 19px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
    }
  }

  &.notify {
    span {
      &::before {
        background-color: $black;
        color: $white;
        content: 'i';
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -1px;
      }
    }
  }

  &.notify-on-gray {
    background-color: $gray-gainsboro;
    border: 0;
    color: $rit-dark;
    display: inline-block;
    font-size: 14px;
    width: auto;

    & + p {
      margin-top: 24px;
    }

    a {
      color: $rit-black;
    }

    span {
      &::before {
        color: $rit-dark;
        content: $icon-circle-info;
        font-family: 'iconfont';
        font-size: 24px;
      }
    }
  }

  &.error {
    border-color: $red-error;
    color: $red-error;

    span {
      &::before {
        background-color: $red-error;
        color: $white;
        content: '!';
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -1px;
      }
    }
  }

  &.confirmation {
    border-color: $green;

    span {
      &::before {
        background-color: $green;
        color: $white;
        content: 'i';
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -1px;
      }
    }
  }
}

.tax-warning-block {
  margin-top: 16px;

  .inline-alert-message {
    display: inline-block;
    width: 100%;

      span {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 25px;
        color: #626262 !important;
      }
  }
}

// Notifications
.inline-notification {
  border-radius: 4px;
  margin: 16px 0;

  p {
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 16px;
    margin: 8px 24px;
  }

  &.white {
    background-color: $white;

    p {
      color: $rit-black;
    }
  }
}
