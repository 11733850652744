//
// Fonts needed at the initial page load.
// These fonts are also preloaded in the head (htmlhead.isml)
// --------------------------------------------------
//

@font-face {
  font-family: 'ClassGarmnd BT';
  src: local('ClassGarmnd BT'),
      url('../fonts/ClassGarmndBTW05-Roman.woff2') format('woff2'),
      url('../fonts/ClassGarmndBTW05-Roman.woff') format('woff'),
      url('../fonts/ClassGarmndBT-Roman.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Scala Sans Pro';
  src: local('Scala Sans Pro'),
      url('../fonts/ScalaSansW05-Light.woff2') format('woff2'),
      url('../fonts/ScalaSansW05-Light.woff') format('woff'),
      url('../fonts/ScalaSansPro-Light.otf') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 350;
}
