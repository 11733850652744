.advent-content-blocks-wrapper {
  background-color: $white;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $rit-sandy-bg;

  @include tablet {
    border-bottom: 0;

    &.advent-content-block-reverted {
      .advent-cols-holder {
        .advent-col-text {
          justify-content: flex-start;
          margin-left: auto;
        }

        .advent-col-image {
          left: 0;
          right: 0;
        }
      }

      .advent-overlay {
        left: auto;
        right: 0;

        .advent-overlay-inner {
          margin-left: 100px;
        }
      }
    }
  }
}

.advent-gradient {
  background: $advent-bg;

  &.primary-content {
    background: linear-gradient($advent-dark-blue 20%, $advent-bg-pdp-light);
  }

  .simple-text-block {
    padding: 30px 0;

    @include tablet {
      padding: 30px 0;
    }
  }

  .home-page  {
    .simple-text-block {
      padding: 30px 0;

      @include tablet {
        padding: 30px 0;
      }
    }
  }
}

.advent-cols-holder {
  display: flex;
  flex-direction: column;
  width: 100%;

  .advent-col {
    width: 100%;
  }

  .text-holder {
    color: $black;
    padding: 37px 30px 28px;
    text-align: center;
    width: 100%;

    .advent-description-wrapper {
      display: none;
    }

    .advent-subtitle {
      display: inline-block;
      font-family: $font-header;
      font-size: 15px;
      letter-spacing: 2px;
      line-height: 23px;
      text-transform: uppercase;
    }

    .advent-title {
      font-size: 30px;
      text-transform: none;
      letter-spacing: 4px;
      line-height: 50px;
      margin: -4px 0 5px;
    }

    .advent-description {
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: .4px;
      line-height: 35px;
      margin-bottom: 22px;
    }

    .advent-disclaimer {
      color: #232121;
      font-size: 12px;
      letter-spacing: .3px;
      line-height: 14px;
      opacity: .5;
    }
  }

  .product-tile {
    margin-bottom: 38px;

    .product-details-content {
      .product-add-to-cart {
        margin-bottom: 23px;
      }
    }

    .base-price-block + .price-discount-text {
      margin-top: 13px;
    }

    .price-discount-text {
      color: rgba($rit-black, .5);
      display: block;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: .2px;
      line-height: 16px;
      margin: 8px 0 -4px;
    }

    .product-title {
      display: none;
    }
  }

  .responsive-bg {
    height: 350px;
    position: relative;
    max-height: 100vh;

    @include tablet {
      height: 668px;
    }
  }

  .advent-image-button {
    bottom: 60px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);

    .pulsating-button-wrapper {
      align-items: center;
      bottom: auto;
      background-color: $white;
      border-radius: 25px;
      height: 44px;
      padding-right: 25px;
      overflow: visible;
    }

    .pulsating-button {
      bottom: auto;
      height: 44px;
      width: 44px;

      &::before {
        background-size: 30px 30px;
      }

      &::after {
        @include icon-styles;
        content: icon-char(arrow3--right);
        font-size: 22px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .advent-btn-text {
      color: $rit-black;
      display: inline-block;
      font-size: 12px;
      font-family: $font-body;
      letter-spacing: 2px;
      line-height: 14px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  @include tablet {
    flex-direction: row;
    overflow: hidden;
    position: relative;

    .advent-col {
      align-items: center;
      display: flex;
      min-height: 760px;
    }

    .advent-col-text {
      justify-content: flex-end;
      width: 50%;

      &.advent-col-text-mobile {
        display: none;
      }
    }

    .text-holder {
      max-width: 720px;
      padding: 62px 50px 50px;

      .advent-description-wrapper {
        display: block;
      }

      .advent-title {
        font-size: 40px;
        letter-spacing: 11px;
        line-height: 54px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      .advent-subtitle {
        letter-spacing: 5px;
      }

      .advent-description {
        max-width: 500px;
        margin: 0 auto 22px;
      }
    }

    .product-tile {
      margin-bottom: 32px;

      .product-details-content {
        margin: 0 auto;
      }
    }

    .advent-col-image {
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
    }

    .responsive-bg {
      height: 100%;
      max-height: none;
    }

    .advent-image-button {
      .pulsating-button-wrapper {
        padding-right: 20px;
      }
    }
  }
}

.advent-overlay {
  background-color: $white;
  bottom: 0;
  overflow: hidden;
  top: 0;
  width: 0;

  .advent-nav {
    display: none;
  }

  .advent-arrow {
    background-color: rgba($rit-black, .4);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    font-size: 44px;
    height: 51px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background $quaternary-duration $default-ease-out;
    width: 51px;
    z-index: 6;

    &:hover {
      background-color: rgba($rit-black, .6);
    }

    &::before {
      @include icon-styles;
      color: $white;
      display: inline-block;
    }
  }

  .advent-arrow-prev {
    left: 20px;

    &::before {
      content: icon-char(arrow3--left);
    }
  }

  .advent-arrow-next {
    right: 20px;

    &::before {
      content: icon-char(arrow3--right);
    }
  }

  .advent-overlay-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    width: 100%;
  }

  .advent-overlay-header {
    padding: 84px 30px 0;
    position: relative;
    text-align: center;
    width: 100%;

    .advent-close {
      font-size: 20px;
      left: 18px;
      position: absolute;
      top: 13px;

      &::before {
        @include icon-styles;
        content: icon-char(close);
      }
    }

    .advent-overlay-title {
      color: $rit-black;
      font-size: 13px;
      letter-spacing: 4px;
      line-height: 20px;
    }
  }

  @include mobile-only {
    left: -100%;
    position: fixed;
    width: 100%;
    z-index: $sticky-bar-index + 1;

    .advent-overlay-inner {
      margin-left: -50px;
      overflow: auto;
    }
  }

  @include tablet {
    display: none;
    left: 0;
    position: absolute;
    width: 50%;

    .advent-overlay-inner {
      margin-left: -100px;
    }

    .advent-overlay-header {
      padding: 55px 20px 28px;

      .advent-close {
        font-size: 30px;
        left: auto;
        right: 30px;
        top: 22px;
      }

      .advent-overlay-title {
        font-size: 15px;
        letter-spacing: 5px;
        line-height: 23px;
        margin: 0 auto 10px;
        max-width: 500px;
      }
    }
  }
}

.advent-overlay-slider {
  position: relative;
  width: 100%;
  max-height: 70vh;

  .advent-slider-image-holder {
    width: 100%;
    text-align: center;
  }

  .slider-advent {
    max-height: 90vh;

    .slick-track {
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .slider-dots {
    bottom: 37px;
  }

  @include tablet {
    .slider-dots {
      bottom: 30px;
    }
  }
}

.advent-overlay-slider-bar {
  background-color: #e5e5e5;
  border-radius: 2px;
  display: none;
  height: 2px;
  margin: auto auto 37px;
  max-width: 200px;
  position: relative;
  width: 100%;

  .indicator {
    display: block;
    position: absolute;
    background-color: $rit-dark;
    height: 100%;
    border-radius: 4px;
    left: 0;
    transition: left .2s ease-out;
  }

  @include tablet {
    height: 4px;
    margin: 15px auto 30px;
    max-width: 300px;
  }
}

.zoomed-in {
  @include mobile-only {
    .advent-overlay-slider .slider-dots {
      bottom: -2px;
    }
  }
}

.advent-overlay-slider .drag-list {
  .slider-advent {
    @include tablet {
      padding-bottom: 15px;
    }

    .drag-list-body {
      background-position: center top;
      background-repeat: no-repeat;
      padding: 0;
    }
  }

  .simplebar-track.simplebar-horizontal {
    left: 50%;
    margin: 0;
    right: auto;
    transform: translateX(-50%);
    width: 300px;
  }
}

.advent-shimmer-text {
  display: inline-block;
  background: linear-gradient(45deg, $advent-shimmer-text 10%, $advent-shimmer-text2 48%, $advent-shimmer-text3 51%, $advent-shimmer-text4 52%, $advent-shimmer-text3 54%, $advent-shimmer-text2 57%, $advent-shimmer-text 90%);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s ease infinite;

  @keyframes shine {
    0% {
      background-position: 200% 0%;
    }
    50% {
      background-position: 0% 50%;
    }
  }
}

.advent-out-of-stock-tape-wrapper,
.ticker-tape-wrapper {
  display: block;
  height: 16px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.advent-out-of-stock-tape-wrapper {
  background-color: $gray-gainsboro;
}

.advent-out-of-stock-tape,
.ticker-tape {
  display: block;
  height: 20px;
  margin: auto auto;
  margin-top: -7px;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;

  span {
    animation: ticker-animation 10s linear infinite;
    color: $white;
    display: inline-block;
    font-family: $font-header;
    font-size: 10px;
    height: 100%;
    letter-spacing: 1.6px;
    line-height: 16px;
    margin-right: 15px;
    min-width: 100%;
    padding-left: 90px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.advent-out-of-stock2-tape span,
.ticker-tape2 span {
  animation-delay: 5s;
}

.grayScaled {
  filter: grayscale(0.5);
}

@keyframes ticker-animation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.advent-comparison-block {
  padding: 50px 0;

  @include mobile-only {
    padding: 20px 0;
  }

  @include desktop {
    margin-top: 250px;
  }

  .advent-comparison-header {
    color: $white;
    font-size: 56px;
    letter-spacing: 2px;
    line-height: 64px;
    margin-bottom: 60px;
    text-align: center;
    text-transform: initial;
    opacity: 0;

    @include mobile-only {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }

  .advent-comparison-desc {
    color: $white;
    margin: 0 25px 10px;
    opacity: 0;
  }

  .dm-responsive-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .advent-comparison-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.advent-desktop-sequence-wrapper {
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 1920px;

  .video-wrapper {
    width: 50%;

    .video-element {
      width: 100%;
      height: 130%;
      transform: translateY(-13%);
    }
  }

  .advent-desktop-sequence-textbox {
    padding: 0 20px 0 9%;
    position: relative;
    width: 50%;

    @include mobile-only {
      padding: 20px 30px;
    }

    @include tablet-only {
      padding: 0 20px 0 40px;
    }

    @include tablet-small {
      padding: 0 20px 0 30px;
    }

    @include desktop-large {
      padding: 0 20px 0 13%;
    }

    .text-holder {
      margin-bottom: 50px;
      width: auto;
      padding: 0;
    }

    .advent-desktop-sequence-pos {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .advent-product-reveal {
    position: initial;
    pointer-events: initial;

    .advent-product-btn {
      opacity: 1;
    }
  }
}

.advent-comparison-box {
  display: flex;
  justify-content: space-between;
  opacity: 0;
  height: 85%;

  @include mobile-only {
    margin-bottom: 50px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    margin: 0 15px;
    opacity: 0.5;
    transition: opacity 0.2s $default-ease-in-out;

    @include mobile-only {
      margin: 0 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .slick-current {
    opacity: 1;
  }
}
.advent-comparison-t {
  width: 100%;
}
.advent-comparison-item {
  margin: 0 25px;
  width: 33%;
  opacity: 0;

  @include mobile-only {
    margin: 0;
  }

  @include mobile-large {
    width: 300px !important;
  }

  .subhead-wrapper {
    align-items: center;
    display: flex;
    max-width: 370px;
    flex-wrap: wrap;
    justify-content: space-between;

    @include desktop {
      width: 100%;
      max-width: none;
    }

    span {
      padding: 5px 18px;
      background: linear-gradient(95.69deg, rgba(255, 255, 255, 1) 20%, rgba(243, 243, 243, 1) 40%, rgba(255, 255, 255, 1) 60%, rgba(235, 237, 241, 1) 80%, rgba(250, 250, 255, 1) 100%);
      color: $rit-black;
      text-transform: uppercase;
      font-family: $font-header;
      font-size: 12px;
      margin-bottom: 10px;

    }
  }

  .advent-comparison-out-of-stock-label {
    text-align: center;
    font-size: 12px;
    color: $advent-light-gold;
    transform: translateX(-25px);
    margin-top: 20px;
    cursor: pointer;
  }
}



.advent-comparison-item.advent-premium-item {
  .subhead-wrapper {
    span {
      background: linear-gradient(45deg, rgba(228, 214, 181, 1) 20%, rgba(231, 208, 177, 1) 55%, rgba(255, 255, 255, 1) 60%, rgba(231, 208, 177, 1) 65%, rgba(228, 214, 181, 1) 100%);
      background-size: 500% 500%;
      animation: gradient-animation 5s ease infinite;
    }
  }
}

.disable-event {
  opacity: 40%;
  pointer-events: none;
}

.advent-comparison-subhead {
  color: $white;
  font-family: $font-header;
  font-size: 32px;
  letter-spacing: 1.2px;
  line-height: 40px;
  text-decoration: none;
  text-transform: initial;

  @include mobile-only {
    margin: 15px 0;
  }

  @include tablet-only {
    font-size: 28px;
    letter-spacing: 0.5;
  }

  @include tablet {
    margin: 15px 0 0 0;
  }
}

.advent-comparison-text {
  color: rgba($white, .7);
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 6px;

  @include mobile-only {
    max-width: 230px;
  }

  @include tablet-only {
    font-size: 14px;
    line-height: 22px;
  }
}

.advent-comparison-price {
  color: $white;
  font-family: $font-header;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.03rem;
  margin: 0;

  &.has-discount {
    @include tablet {
      bottom: 24px;
    }
  }

  &:not(.has-discount) {
    @include tablet {
      bottom: 0;
    }
  }

  @include tablet {
    position: absolute;
  }

  @include desktop-small {
    margin-top: 15px;
  }
}

.advent-comparison-beforediscount-price {
  color: $white;
  line-height: 20px;
  margin-bottom: 0;
  text-decoration-line: line-through;

  @include mobile-only {
    font-size: 16px;
    letter-spacing: 0.4px;
  }

  @include tablet {
    bottom: 0;
    font-size: 20px;
    letter-spacing: 0.6px;
    position: absolute;
  }
}

.advent-comparison-calendar {
  border: 1px solid $white;
  margin-bottom: 28px;
  position: relative;
  vertical-align: middle;
  width: 100%;
  display: flex;

  @include tablet-only {
    max-width: 550px;
  }

  @include mobile-only {
    max-width: 350px;
    margin: 0 5px;
  }
}

.advent-comparison-redir {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.advent-comparison-value {
  @include callout;
  border-radius: 0 2px 2px 0;
  left: 0;
  position: absolute;
  top: 25px;
}


.advent-comparion-worth {
  color: $rit-black;
  letter-spacing: 0.06em;
  margin: 0;

  @include mobile-only {
    font-size: 10px;
    line-height: 16px;
  }

  @include tablet {
    font-size: 12px;
    line-height: 19px;
  }
}

.advent-calendar-wrapper {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 60px;
  width: 100%;
  height: 100%;

  @include mobile-and-tablet {
    padding: 0;
  }
}

.advent-comparison-info {
  display: flex;
  justify-content: space-between;
  position: relative;

  @include tablet-only {
    max-width: 550px;
  }

  @include mobile-only {
    align-items: flex-end;
    margin: 0 5px;
  }

  @include tablet {
    align-items: flex-start;
    min-height: 200px;
  }

  form {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .btn {
    @include icon(cart--plus);
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid $gray-disabled;
    color: $white;
    font-size: 0;
    height: 56px;
    letter-spacing: 0;
    line-height: 0;
    max-width: none;
    min-width: auto;
    padding: 0;
    text-transform: none;
    transition: border .2s ease-in-out;
    width: 56px;

    &::before {
      display: block;
      font-size: 20px;
      line-height: 20px;
      margin-top: 17px;
    }

    &:hover {
      color: $white;
    }

    &.btn-out-of-stock {
      &::before {
        margin-top: 0;
      }
    }
  }
}

.advent-comparison-position {
  position: relative;
  height: 2px;
  background-color: rgba($color: $white, $alpha: 0.2);
  margin: 0 25px;

  @include tablet {
    display: none;
  }
}

.advent-comparison-line {
  position: absolute;
  left: 0;
  top: 0;
  width: 33.33%;
  height: 2px;
  background-color: $white;
  transition: left 0.2s $default-ease-in-out;
}

.advent-shimmer-frame {
  $border-width: 2px;
  border-radius: $border-width;
  z-index: 10;

  &::after {
    position: absolute;
    content: '';
    top: calc(-1 * #{$border-width});
    left: calc(-1 * #{$border-width});
    z-index: -1;
    width: calc(100% + #{$border-width} *2);
    height: calc(100% + #{$border-width} * 2);
    background: linear-gradient(45deg, $advent-shimmer-light 20%, $advent-shimmer 50%, $white 57% 61%, $advent-shimmer 65%, $advent-shimmer-light 100%);
    background-size: 300% 300%;
    border-radius: calc(2 * #{$border-width});
    animation: shimmering 6s ease infinite;
  }

  &.advent-swatch-item {
    &::after {
      background: linear-gradient(90deg, $copper 5%, $advent-shimmer-swatch, $advent-shimmer-swatch2, $advent-shimmer-swatch2, $advent-shimmer-swatch, $copper 95%);
      background-size: 300% 300%;
    }
  }
}

@keyframes shimmering {
  0% {
    background-position: 150% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.advent-block-snap {
  width: 100%;
  overflow: hidden;

  &.advent-comparison-block {
    height: 100%;
  }

  &.christmas-tree-mobile {
    @include tablet-only {
      height: 700px;
    }
  }

  @include desktop {
    height: 100vh;
  }
}

.summer-box-product-full.christmas-tree-mobile {
  background-color: $ahoy-blue;

  .text-holder {
    margin-bottom: unset;
    max-width: none;

    @include mobile-only {
      max-width: unset;
      position: absolute;
      bottom: 130px;
      text-align: center;
      padding: 0 30px;
    }

    .headline {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 32px;

      @include mobile-only {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
        max-width: 100%;
        text-align: center;
      }

      @include tablet {
        padding-right: 20px;
      }
    }
  }

  .advent-product-reveal {
    bottom: unset;
    left: unset;
    position: relative;
    transform: unset;

    @include mobile-only {
      bottom: unset;
      left: unset;
      position: relative;
      transform: unset;
      padding: 80px 0 72px;
    }

    .advent-product-btn {
      box-shadow: unset;
      max-width: 280px;

      &:hover {
        box-shadow: unset;
      }

      &::after {
        background: unset;
      }

      @include mobile-only {
        min-width: 280px;
      }
    }

    .btn-flip {
      &::before {
        color: $rit-black;
        margin-left: unset;
      }

      &::after {
        color: $rit-black;
        left: 0;
      }

      .icon-key-skeleton-thin {
        display: inline-block;
        font-size: 22px;
        position: absolute;
        left: 45px;
        top: 50%;
        transform: scaleX(-1) translateY(-50%);
      }
    }
  }

  .gifts-container {
    .text-holder {
      position: relative;
      bottom: unset;
      padding: 0 45px 0 30px;
    }
  }

  .image-holder {
    @include mobile-only {
      &::before {
        content: '';
        display: block;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 128, 161, 0) 0%, #0180A1 100%);
        bottom: 0;
        height: 300px;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .advent-desktop-sequence-wrapper {
    @include mobile-only {
      flex-direction: column-reverse;
    }

    .advent-desktop-sequence-textbox {
      @include tablet {
        order: -1;
      }

      .advent-desktop-sequence-pos {
        @include mobile-only {
          position: relative;
          top: unset;
          transform: unset;
        }
      }
    }
  }
}

.advent-tile {
  .inner-wrapper {
    position: relative;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;

    @include mobile-only {
      flex-direction: column;
      padding: 50px 0 0;
    }

    @include tablet-small {
      align-items: unset;
      flex-direction: column;
      padding: 50px 0 0;
    }
  }

  &.specification-block,
  &.advent-ar-block,
  &.gift-wrap-block {
    .inner-wrapper {
      max-width: 1920px;
      padding: 0;

      .text-holder {
        margin: 0 20px 0 9%;

        @include mobile-only {
          margin: 0;
          padding: 20px 30px;
        }

        @include tablet-only {
          margin: 0 20px 0 40px;
        }

        @include tablet-small {
          margin: 0;
        }

        @include desktop-large {
          margin: 0 20px 0 13%;
        }
      }
    }
  }

  .image-holder {
    position: relative;
    width: 50%;

    @include mobile-only {
      width: 100%;
    }

    @include tablet-small {
      width: 100%;
    }

    .scrollmagic-pin-spacer {
      padding-bottom: 0 !important;
    }
  }

  .text-holder {
    position: relative;
    max-width: 450px;
    width: 50%;
    z-index: 10;

    @include mobile-only {
      padding: 0 30px;
      width: 100%;
    }

    @include tablet-small {
      width: 100%;
      max-width: 500px;
      margin: 0;
      padding: 40px 30px;
    }
  }

  .headline {
    color: $white;
    font-size: 32px;
    letter-spacing: 0.6px;
    line-height: 40px;
    margin-bottom: 25px;
    text-transform: initial;
    opacity: 0;

    @include mobile-only {
      margin-bottom: 12px;
      max-width: 250px;
    }

    @include tablet-only {
      font-size: 48px;
      letter-spacing: 2px;
      line-height: 56px;
    }

    @include tablet {
      font-size: 56px;
      letter-spacing: 2px;
      line-height: 64px;
    }
  }

  .responsive-bg {
    height: 100vh;

    @include mobile-only {
      height: 500px;
    }

    @include tablet-small {
      height: 700px;
    }
  }
}

.specification-block {
  .specification-text {
    color: rgba($color: $white, $alpha: .8);
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 24px;
    margin-bottom: 20px;
    opacity: 0;

    @include tablet {
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 35px;
    }
  }

  .image-holder {
    height: 100%;
  }

  .specification-box {
    display: flex;
    flex-wrap: wrap;
    max-width: 420px;
    opacity: 0;

    @include mobile-only {
      margin-bottom: 10px;
    }
  }

  .specification-fact {
    background-color: $dark-blue;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 18px;
    margin: 0 10px 10px 0;
    padding: 16px 26px;
    opacity: 0;
  }

  .item {
    position: relative;
    overflow: hidden;

    @include tablet {
      height: 100vh;
    }

    @include tablet-small {
      height: 100%;
    }
  }
  .responsive-bg {
    transform: scale(1.3);
  }
}

.advent-ar-block {
  .btn {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
    min-width: 315px;
    background-color: transparent;
    border-color: $white;
    margin-bottom: 20px;
    opacity: 0;
    transition: none;

    &:hover {
      color: $white;
    }

    .advent-ar-icon {
      position: absolute;
      left: 20px;
      font-size: 25px;
      transform: rotate(-90deg)  scale(0);
      margin-right: 30px;
      opacity: 0;
    }
  }

  .item {
    position: relative;
    overflow: hidden;

    @include tablet {
      height: 100vh;
    }

    @include tablet-small {
      height: 100%;
    }
  }

  .responsive-bg {
    transform: scale(1.3);
  }

  .advent-ar-text {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: rgba($color: $white, $alpha: .8);
    max-width: 320px;
    opacity: 0;
  }
}

.advent-tile-reverse {
  &.specification-block,
  &.advent-ar-block,
  &.gift-wrap-block {
    .inner-wrapper {
      .text-holder {
        margin: 0 9% 0 20px;

        @include mobile-only {
          margin: 0;
        }

        @include tablet-only {
          margin: 0 40px 0 20px;
        }

        @include desktop-large {
          margin: 0 13% 0 20px;
        }
      }
    }
  }

  .image-holder {
    @include tablet {
      order: -1;
    }
  }
}

.gift-wrap-block {
  .headline {
    opacity: 1;
  }

  .gift-wrap-text {
    color: rgba($color: $white, $alpha: .8);
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 24px;
    margin-bottom: 20px;
    opacity: 0;

    @include tablet {
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 35px;
    }
  }

  .gift-wrap-play {
    align-items: center;
    display: flex;
    opacity: 0;
    text-decoration: none;

    @include mobile-only {
      margin-bottom: 30px;
    }
  }

  .gift-wrap-video-text {
    font-family: $font-header;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 0;
  }

  .gift-wrap-time {
    font-size: 14px;
    letter-spacing: 0.245em;
    color: $white;
    margin-bottom: 0;
  }

  .gift-wrap-button {
    width: 64px;
    height: 64px;
    border: 1px solid $white;
    border-radius: 50%;
    margin-right: 20px;

    span {
      margin-left: 2px;
      font-size: 16px;
      line-height: 20px;
      color: $white;
    }
  }
}

.advent-show {
  @include mobile-only {
    .specification-text,
    .specification-fact,
    .specification-box,
    .advent-ar-text,
    .btn {
      opacity: 1;
    }

    .specification-box {
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .9s;
    }

    .specification-text {
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .7s;
    }

    .btn {
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .9s;
    }

    .btn .advent-ar-icon {
      transform: rotate(0) scale(1);
    }

    &.advent-ar-block {
      .image-holder {
        position: relative;
      }
      .headline {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .5s;
      }

      .advent-ar-text {
        transition: opacity 1s $default-ease-in-out;
        transition-delay: 1.5s;
      }
    }

    &.specification-block {
      .headline {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .5s;
      }
    }
  }

  @include tablet-small {
    .specification-text,
    .specification-fact,
    .specification-box,
    .advent-ar-text,
    .btn {
      opacity: 1;
    }

    .specification-box {
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .9s;
    }

    .specification-text {
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .7s;
    }

    .btn {
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .9s;
    }

    .btn .advent-ar-icon {
      transform: rotate(0) scale(1);
    }

    &.advent-ar-block {
      .image-holder {
        position: relative;
      }
      .headline {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .5s;
      }

      .advent-ar-text {
        transition: opacity 1s $default-ease-in-out;
        transition-delay: 1.5s;
      }
    }

    &.specification-block {
      .headline {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .5s;
      }
    }
  }

  @include mobile-and-tablet {
    &.calendar-overview-block {
      .calendar-overview-header  {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .5s;
      }

      .calendar-overview-text {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .7s;
      }

      .calender-overview-box {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .9s;
      }
    }
  }

  @include mobile-only {
    .content-list-wrapper {
      opacity: 1;
      transition: opacity 1s $default-ease-in-out;
      transition-delay: 1.1s;
    }

    .calendar-overview-disclamer {
      opacity: 1;
      transition: opacity 1s $default-ease-in-out;
      transition-delay: 1.3s;
    }
  }

  @include tablet-only {
    .content-list-wrapper,
    .calendar-overview-disclamer {
      opacity: 1;
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .7s;
    }

    &.christmas-tree-mobile {
      .headline {
        opacity: 1;
        transition: opacity 1s $default-ease-in-out;
        transition-delay: .5s;
      }
    }
  }

  &.gift-wrap-block {
    .headline {
      opacity: 1;
      transition: opacity 0.5s $default-ease-in-out;
    }

    .gift-wrap-text {
      opacity: 1;
      transition: opacity 0.5s $default-ease-in-out;
      transition-delay: 0.3s;
    }

    .gift-wrap-play {
      opacity: 1;
      transition: opacity 0.5s $default-ease-in-out;
      transition-delay: 0.5s;
    }
  }

  .specification-fact {
    opacity: 1;
    transition: opacity .9s $default-ease-in-out;
  }

  @for $i from 1 through 10 {
    .specification-fact:nth-child(#{$i}n) {
      transition-delay: #{$i * 0.4}s;
    }
  }

  .responsive-bg {
    transform: scale(1);
    transition: transform 10s $default-ease-in-out;
  }

  .advent-comparison-header {
    opacity: 1;
    transition: opacity 0.5s $default-ease-in-out;
  }

  .advent-comparison-desc {
    opacity: 1;
    transition: opacity 0.5s $default-ease-in-out;
  }

  .advent-comparison-box {
    opacity: 1;
    transition: opacity 0.5s $default-ease-in-out;
  }

  .advent-comparison-item {
    opacity: 1;
  }

  .advent-comparison-item:nth-child(n+2) {
    transition: opacity .9s $default-ease-in-out;
  }

  .btn .advent-ar-icon {
    transform: rotate(0) scale(1);
    opacity: 1;
  }

  @for $i from 2 through 3 {
    .advent-comparison-item:nth-child(#{$i}n) {
      transition-delay: #{($i - 1) * 0.2}s;
    }
  }

  &.christmas-tree-mobile {
    .headline {
      opacity: 1;
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .5s;
    }

    .advent-product-reveal {
      opacity: 1;
      transition: opacity 1s $default-ease-in-out;
      transition-delay: .7s;
    }
  }
}

.scroll-magic-trigger2 {
  position: absolute;
  top: 0;
  width: 100%;
}

.moving-title-wrapper {
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
  min-height: 270px;
  padding: 160px 0 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  @include mobile-only {
    padding: 110px 0 90px;
  }

  @include tablet {
    padding: 192px 0 24px;
  }

  .moving-title {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    overflow: hidden;
    padding: 0 30px;
    position: absolute;
    white-space: nowrap;
    width: unset;

    h2 {
      color: $white;
      font-family: $font-header;
      font-size: 40px;
      letter-spacing: 0.5rem;
      opacity: 0.26;
      text-transform: uppercase;

      @include tablet {
        font-size: 55px;
        letter-spacing: 0.6rem;
      }
    }
  }
}

.calendar-overview-block {
  position: relative;
  background: $advent-bg;

  @include mobile-only {
    margin-bottom: 20px;
  }

  .inner-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: 100vw;
    padding: 0;
    position: relative;

    @include mobile-and-tablet {
      flex-direction: column;
    }
  }

  &.calendar-overview-noanimation {
    .calendar-overview-header,
    .calendar-overview-text,
    .calender-overview-box,
    .content-list-wrapper,
    .calendar-overview-disclamer {
      opacity: 1;
    }
  }

  .video-wrapper {
    position: relative;
    width: 50%;
    height: 100%;

    @include mobile-and-tablet {
      width: 100%;
      height: 500px;
      margin-bottom: 300px;
      mask-image: linear-gradient($rit-black, transparent);
    }

    .scrollmagic-pin-spacer {
      padding-bottom: 0 !important;
    }

    .video-element {
      @include mobile-only {
        height: 100vh;
      }

      @include tablet-only {
        height: 150vh;
        top: 60%;
      }
    }
  }

  .text-wrapper {
    padding: 0 20px 0 80px;
    width: 50%;
    max-width: 600px;

    @include mobile-and-tablet {
      width: 100%;
      padding: 0 32px;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }

    @include mobile-only {
      left: 0;
    }

    @include tablet-only {
      align-items: flex-end;
      align-self: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      left: 50%;
      margin-bottom: 100px;
      max-width: 100%;
      transform: translateX(-50%);
    }

    @include desktop-medium {
      padding: 0 20px 0 80px;
    }

    @include desktop-large {
      padding: 0 20px 0 120px;
    }

    .content-asset {
      @include tablet-only {
        width: 42%;
      }
    }
  }

  .calendar-overview-header {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1.4px;
    color: $white;
    text-transform: initial;
    opacity: 0;

    @include mobile-and-tablet {
      font-size: 32px;
      letter-spacing: 0.6px;
      line-height: 40px;
      min-width: 51%;
    }

    @include tablet {
      transform: translateY(30px);
    }

    @include tablet-only {
      margin-bottom: 0;
    }
  }

  .calendar-overview-subhead {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3em;
    color: rgba($color: $advent-shimmer-dark, $alpha: .64);
    opacity: 0;
    display: none;


    @include tablet {
      transform: translateY(30px);
    }
  }

  .calendar-overview-text {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: rgba($color: $white, $alpha: .72);
    opacity: 0;

    @include tablet {
      transform: translateY(30px);
    }

    @include mobile-and-tablet {
      font-size: 16px;
      letter-spacing: 0.6px;
      line-height: 24px;
    }

    @include tablet-only {
      padding-right: 30px;
      width: 50%;
    }
  }

  .promotional-label {
    @include callout;
    background-color: $white;
    bottom: 16px;
    display: inline-block;
    margin-bottom: 16px;
  }

  .calender-overview-box {
    opacity: 0;

    @include tablet {
      transform: translateY(30px);
    }

    @include tablet-only {
      order: 5;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .calendar-price-text {
    display: flex;
    align-items: center;

    @include tablet-only {
      width: 51%;
    }
  }

  .calendar-price {
    font-family: $font-header;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.6px;
    color: $white;
    margin-right: 20px;

    @include mobile-and-tablet {
      font-size: 24px;
      letter-spacing: 0.4px;
      line-height: 32px;
      margin-bottom: 17px;
      margin-right: 10px;
    }
  }

  .calendar-beforediscount-price {
    color: $white;
    font-size: 20px;
    letter-spacing: 0.6px;
    text-decoration-line: line-through;

    @include mobile-only {
      margin-bottom: 17px;
      line-height: 32px;
    }

    @include tablet {
      line-height: 40px;
    }
  }

  .calendar-text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 4px;
    color: rgba($color: $white, $alpha: .8);
    text-transform: uppercase;
    font-family: $font-header;

    @include mobile-and-tablet {
      font-size: 12px;
      letter-spacing: 1.8px;
      line-height: 16px;
      margin-bottom: 17px;
    }
  }

  .btn {
    display: block;
    margin-bottom: 10px;
    min-width: 360px;

    @include mobile-only {
      min-width: 100%;
    }

    @include tablet-only {
      min-width: 320px;
      width: 50%;
    }
  }

  .content-list-wrapper {
    padding: 10px 0;
    opacity: 0;

    @include tablet {
      transform: translateY(30px);
    }

    .content-list {
      margin: 0;
      padding-left: 0;
      max-width: 350px;
      color: rgba($color: $white, $alpha: .72);

      @include mobile-and-tablet {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .calendar-overview-disclamer {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: rgba($color: $white, $alpha: .64);
    opacity: 0;

    @include tablet {
      transform: translateY(30px);
    }
  }

  .rituals-form {
    padding: 0;
    border: 0;

    @include tablet-only {
      width: 50%;
    }

    .btn {
      background-color: $white;
      border: 1px solid $white;
      color: $rit-black;

      @include tablet {
        &:hover {
          background-color: transparent;
          color: $white;
        }
      }
    }
  }

  .out-of-stock-block {
    justify-content: flex-start;
  }
}

.advent-ar-text-hero {
  color: $advent-lighter-blue;
}

.advent-split {
  align-items: center;
  background: linear-gradient(rgb(12, 25, 51), rgb(14, 30, 60));
  display: flex;
  min-height: 700px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  z-index: 5;

  @include tablet {
    padding: 50px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.start-textscroll {
    overflow-y: visible;
  }
}

.advent-split[data-adventsection='advent-introduction'] {
  height: 100%;
}

.advent-split-text {
  background-clip: text;
  background-position: center 45%;
  color: transparent;
  font-family: $font-header;
  font-size: 44px;
  height: auto;
  line-height: 90px;
  margin: 0 auto;
  max-width: 1120px;
  padding: 0 20px;
  position: sticky;
  text-align: center;
  top: 30%;

  @include mobile-only {
    font-size: 30px;
    height: auto;
    line-height: 70px;
    top: 50px;
  }

  span {
    transition: color 0.2s ease-in, text-shadow 0.2s ease-in;
  }
}

.advent-content {
  position: relative;
  width: 100%;
}

.advent-done-animation {
  height: 100vh;
}

.circle-advent {
  background: transparent;
  border-radius: 50%;
  height: 500px;
  position: fixed;
  width: 500px;
  z-index: -1;
}

.advent-text-white {
  color: $white;
}

.advent-golden {
  text-shadow: 0 0 10px $advent-shimmer-orange,
    0 0 20px $advent-shimmer-orange,
    0 0 40px $advent-shimmer-orange,
    0 0 80px $advent-shimmer-orange;
}

.advent-snap-wrap {
  overflow-x: hidden;
}

.advent-viewer-model {
  height: 50px;
  width: 319px;
  margin-bottom: 20px;

  .btn {
    background-color: $advent-bg;
    z-index: 10;
  }
}

.christmas-tree-bottom-mask {
  &::after {
    background: linear-gradient(transparent, $advent-bg 96%);
    bottom: 0;
    content: '';
    height: 200px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @include mobile-only {
    &::after {
      background: linear-gradient(transparent, $advent-bg 79%);
      height: 400px;
    }
  }
}

.christmas-tree-scroll {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.png-animation-cta {
  .christmas-tree-scroll {
    margin-top: -65px;
  }
}

.all-advent-gifts {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s $default-ease-in-out;

  @include mobile-only {
    bottom: unset;
    position: absolute;
    overflow-y: auto;
    padding-top: 0;
  }

  .gifts-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;

    @include mobile-only {
     height: 100vh;
     padding-top: 24px;
  }
}

  .text-holder {
    margin-bottom: 50px;
    text-align: center;

    @include desktop-large {
      margin-bottom: 100px;
    }

    @include mobile-only {
      margin-bottom: 0;
    }

    h2 {
      @include mobile-only {
        font-size: 24px;
        line-height: 32px;
      }

      @include tablet {
        font-size: 32px;
        line-height: 40px;
      }

      @include desktop {
        font-size: 50px;
        line-height: 72px;
        margin-bottom: 50px;
      }
    }

    h3 {
      font-size: 56px;
      letter-spacing: 2px;
      line-height: 64px;

      @include mobile-and-tablet {
        font-size: 32px;
        letter-spacing: 0.6px;
        line-height: 40px;
      }
    }

    h3,
    h2 {
      opacity: 0;
      color: $rit-black;
      text-transform: capitalize;
    }

    span {
      opacity: 0;
      color: $rit-copper;
      letter-spacing: 2px;
      line-height: 16px;
      text-transform: uppercase;
      font-family: $font-header;

      @include mobile-only {
        font-size: 10px;
      }

      @include tablet {
        font-size: 12px;
      }

      @include desktop {
        font-size: 14px;
      }
    }
  }

  .all-advent-gifts-slider {
    display: flex;
    justify-content: center;
    opacity: 0;
    width: 100%;

    @include mobile-only {
      align-items: center;
      flex-direction: column;
      opacity: 1;
      padding-bottom: 170px;

      &::before,
      &::after {
        content: '';
        background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, 0.45) 28%, rgba(247, 247, 247, 1) 100%);
        bottom: 0;
        height: 75px;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 4;
      }

      &::before {
        background: linear-gradient(0deg, rgba($white, 0) 0%, rgba($white, 0.45) 28%, rgba(247, 247, 247, 1) 100%);
        bottom: unset;
        top: 0;
      }
    }

    .all-advent-gifts-item {
      align-items: center;
      display: flex;

      @include mobile-only {
        margin-top: 30px;
        width: 100%;
      }

      .product-holder {
        display: flex;
        position: relative;

        @include mobile-and-tablet {
          width: 100%;
        }

        .product-image {
          height: 280px;
          width: 300px;
          min-width: 300px;

          @include mobile-and-tablet {
            height: 260px;
            width: 230px;
            min-width: 230px;
          }

          @include desktop-large {
            height: 369px;
            width: 327px;
            min-width: 311px;
          }
          @include desktop-extra-large {
            height: 347px;
            width: 367px;
            min-width: 367px;
          }

          @include tablet-only {
            min-height: 290px;
          }

          img {
            width: 100%;

            @include mobile-and-tablet {
              position: relative;
              z-index: 2;
            }

            @include desktop-small {
              height: 100%;
            }
          }
        }

        .product-content {
          position: relative;

          @include mobile-only {
            left: 50%;
            max-width: 50%;
            padding-bottom: 10px;
            padding-right: 38px;
            position: absolute;
            top: 90px;
          }

          @include mobile-only {
            padding-bottom: 15px;
          }

          @include tablet {
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
          }

          .full-size-tape {
            align-items: center;
            background-image: linear-gradient(135.76deg, rgba($advent-tape, 0.639) 16.86%, rgba($advent-tape-light, 0.639) 88%);
            display: inline-block;
            justify-content: center;
            left: 0;
            overflow: hidden;
            padding: 0 10px;
            white-space: nowrap;
            width: auto;

            span {
              color: $white;
              text-align: center;
              font-size: 10px;
              line-height: 16px;
              letter-spacing: 1.6px;
              text-transform: uppercase;
              font-family: $font-header;
            }
          }

          p {
            color: $rit-black;
            font-family: $font-header;
            line-height: 32px;
            margin-bottom: 0;

            @include mobile-and-tablet {
              font-size: 20px;
            }

            @include tablet {
              order: 3;
            }

            @include desktop {
              font-size: 24px;
            }

            & + p {
              @include mobile-and-tablet {
                margin-top: 4px;
              }

              @include desktop {
                margin-top: 6px;
              }
            }

            &:first-of-type {
              color: $copper;
              font-family: $font-header;
              line-height: 16px;
              text-transform: uppercase;

              @include mobile-and-tablet {
                font-size: 10px;
              }

              @include tablet {
                order: 2;
              }

              @include desktop {
                font-size: 12px;
              }
            }

            &.product-price {
              color: $ritual-samurai;
              font-family: $font-body-italic;
              font-style: italic;

              @include mobile-and-tablet {
                font-size: 12px;
                line-height: 16px;
              }

              @include desktop {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.4px;
              }
            }
          }
        }

        .big-number {
          align-items: center;
          display: flex;
          font-family: $font-header;
          position: absolute;
          top: 0;

          @include mobile-only {
            font-size: 72px;
            height: 72px;
            left: 50%;
            line-height: 88px;
            margin-top: 0;
            top: 5%;
            z-index: 1;
          }

          @include tablet {
            font-size: 72px;
            height: 72px;
            line-height: 88px;
            margin-left: -20px;
            margin-top: 0;
            order: 1;
            position: relative;
          }

          @include desktop {
            font-size: 88px;
            height: 96px;
            margin-left: -30px;
            line-height: 96px;
          }

          span {
            background-clip: text;
            background-image: linear-gradient(135.76deg, $advent-tape 16.86%, $advent-tape-light 88%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .all-advent-gifts-item,
    .slick-slide {
      &:nth-of-type(even) {
        .product-holder {
          @include mobile-and-tablet {
            justify-content: flex-end;
          }

          .product-content {
            @include mobile-only {
              padding-bottom: 15px;
              left: 10%;
            }
          }

          .big-number {
            @include mobile-only {
              left: 38px;
              top: 5%;
            }
          }
        }
      }
    }

    .slick-arrow {
      opacity: 0;
      z-index: 10;
      position: absolute;

      .slider-button {
        align-items: center;
        border-radius: 50%;
        border: $rit-dark;
        box-shadow: 0 4px 16px rgba($rit-black, 0.12);
        cursor: pointer;
        display: flex;
        font-size: 45px;
        height: 65px;
        justify-content: center;
        transition: opacity $tertiary-duration ease-in-out;
        width: 65px;
      }
    }

    .slick-next {
      right: 50px;
    }

    .slick-prev {
      left: 50px;
    }

    .slick-slide {
      opacity: 0.5;

      &.slick-active {
        opacity: 1;
      }
    }

    &:hover .slick-arrow {
      opacity: 1;
      transition: opacity $tertiary-duration ease-in-out;

        &.slick-disabled {
          opacity: 0;
        }
    }

    .slick-slide:nth-of-type(1),
    .slick-slide:nth-of-type(2),
    .slick-slide:nth-of-type(3),
    .slick-slide:nth-of-type(4) {
      opacity: 0;
    }
  }

  .all-advent-gifts-slider.not-fixed {
    &::after,
    &::before {
      display: none;
    }
  }

  @keyframes slide-into {
    0% {
      opacity: 0;
      transform: translateX(30%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes item-slide-in-mob {
    0% {
      opacity: 0;
      transform: translateY(40%) scale(0.6);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes item-slide-in-mob-no-scale {
    0% {
      transform: translateY(20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .gift-slide-position {
    background: rgba(26, 25, 25, 0.1);
    height: 2px;
    margin-top: 30px;
    width: 480px;

    @include mobile-only {
      display: none;
    }

    .current-position {
      background-color: $rit-dark;
      height: 2px;
      transition: transform 0.5s ease;
      width: 60px;
    }
  }
}

.advent-product-reveal {
  position: absolute;
  transition: transform 0.5s $default-ease-out;
  z-index: 1;
  pointer-events: none;

  @include mobile-only {
    bottom: 100px;
    left: 50%;
    text-align: center;
    top: auto;
    transform: translate(-50%, 90px);

    .advent-product-tap {
      display: block;
    }

    .advent-product-btn-grad {
      align-items: center;
      background: linear-gradient(124deg, #9b7c6a 50%, #e4d7b6);
      border-radius: 50%;
      display: flex;
      height: 64px;
      justify-content: center;
      margin: auto;
      margin-bottom: 10px;
      opacity: 0;
      position: relative;
      width: 64px;
      transition: opacity 0.4s $default-ease-in-out;
    }

    &.advent-pr-full {
      .advent-product-btn {
        opacity: 0;
      }
    }

    .advent-product-btn {
      opacity: 1;
    }

    .btn-flip {
      background-color: $white;
      border-radius: 50%;
      border: 0;
      bottom: auto;
      height: 62px;
      left: auto;
      right: auto;
      top: auto;
      transform: translateX(1px);
      width: 62px;
    }
  }

  @include tablet {
    left: 150px;
    top: 50%;
    transform: translateY(-20px);
    transition: transform 0.5s $default-ease-out, opacity 0.5s $default-ease-in-out 0.5s;
    z-index: 1;
  }

  @include desktop-small-height {
    top: 35%;
  }

  .advent-product-tap {
    color: $white;
    display: none;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    opacity: 0;
    text-transform: uppercase;
    transition-delay: 0.5s;
    transition: opacity 0.5s $default-ease-out;
  }

  &.advent-pr-full {
    .advent-product-header {
      @include mobile-only {
        display: none;
      }
    }

    .advent-product-btn {
      @include mobile-only {
        border-radius: 30px;
        height: auto;
        min-width: 80vw;
      }
      .icon-advent-key {
        @include mobile-only {
          left: 70px;
        }
      }
    }

    .btn-flip {
      @include mobile-only {
        &::after {
          content: attr(data-back);
        }

        &::before {
          content: attr(data-front);
        }
      }
    }
  }

  &.advent-product-reveal-reverse {
    left: auto;
    right: 20%;

    @include mobile-only {
      bottom: 100px;
      left: 50%;
      right: auto;
      top: auto;
      transform: translateX(-50%, 90px);
    }
  }

  .advent-product-header {
    color: $white;
    font-size: 55px;
    letter-spacing: 1.8px;
    line-height: 70px;
    margin-bottom: 30px;
    max-width: 340px;
    opacity: 0;
    text-transform: initial;
    transition-delay: 0.5s;
    transition: opacity 0.5s $default-ease-out;

    @include mobile-only {
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 10px;
      min-width: 250px;
      text-transform: uppercase;
    }
  }

  .advent-product-btn {
    background: $white;
    opacity: 0;
    outline: 0;
    overflow: hidden;
    text-transform: uppercase;
    transition-delay: 0.5s;
    transition: opacity 0.5s $default-ease-in-out, box-shadow 0.5s $default-ease-in-out;
    box-shadow: 0 0 35px 0 rgba($rit-copper, 0.3);
    z-index: 1;

    @include mobile-only {
      border-radius: 50%;
      height: 64px;
      margin: 0 auto;
      width: 64px;

      &::after {
        border-radius: 50%;
        content: '';
        height: 64px;
        width: 64px;
      }
    }

    @include tablet {
      border-radius: 30px;
      max-width: 300px;
      position: relative;

      &::after {
        background: linear-gradient(45deg, $advent-shimmer-light2, $advent-shimmer-darker);
        border-radius: 50%;
        bottom: -200%;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: -200%;
        transform: scale(0, 0);
        transition: transform 0.4s $default-ease-in-out;
        z-index: -1;
      }

      &:hover {
        box-shadow: 0 0 5px rgba($color: $advent-shimmer-light2, $alpha: 1), 0 0 20px rgba($color: $advent-shimmer-light2, $alpha: 0.3);

        &::after {
          transform: scale(1, 1);
        }
      }
    }
  }

  .btn-flip {
    color: $rit-black;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 40px;
    opacity: 1;
    outline: 0;
    padding: 8px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &::after {
      color: $white;
      content: attr(data-back);
      display: block;
      left: 12px;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: translateY(50%) rotateX(90deg);
      transition: 0.5s $default-ease-in-out;
      width: 100%;
    }

    &::before {
      color: $rit-copper;
      content: attr(data-front);
      display: block;
      left: 0;
      line-height: 40px;
      opacity: 1;
      padding: 0 30px;
      margin-left: 25px;
      position: relative;
      top: 0;
      transform: translateY(0) rotateX(0);
      transition: 0.5s $default-ease-in-out;
    }

    @include mobile-only {
      height: 100%;
      width: 100%;

      &::after {
        content: '';
      }

      &::before {
        content: '';
      }
    }

    @include tablet {
      &:hover {
        &::after {
          opacity: 1;
          transform: translateY(8px) rotateX(0);
        }

        &::before {
          opacity: 0;
          transform: translateY(-50%) rotateX(90deg);
        }
      }
    }

    .icon-advent-key {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50px;
      font-size: 25px;
      transform: translateY(-50%);
      background: linear-gradient(154deg, $advent-tape-darker 1.41%, $advent-tape-light 83.61%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 0.4s $default-ease-in-out;

      @include mobile-only {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 44px;
      }
    }

    .icon-close {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50px;
      font-size: 25px;
      transform: translateY(-50%) rotate(-90deg);
      background-color: $rit-copper;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0;
      transition: opacity 0.5s $default-ease-out, transform 0.9s $default-ease-in-out;

      @include mobile-only {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        height: 44px;
      }
    }

    @keyframes button-flip-reveal {
      0% {
        transform: scale(1);
      }

      20% {
        transform: scale(0.9);
      }

      60% {
        transform: scale(1.1);
      }

      100% {
        transform:  scale(1);
      }
    }
  }

  &.advent-product-reveal-fadein {
    z-index: 5;
    pointer-events: auto;

    @include mobile-only {
      transform: translate(-50%, 0);
      z-index: 20;
    }

    @include tablet {
      transform: translateY(-50%);
    }

    .advent-product-header {
      opacity: 1;
    }

    .advent-product-tap {
      opacity: 1;
    }

    .advent-product-btn,
    .advent-product-btn-grad {
      opacity: 1;
    }

    &.advent-pr-full {
      .advent-product-btn {
        opacity: 1;
      }
    }
  }

  &.advent-toggle-animation {
    opacity: 0;
    transition-delay: 0.3s;

    .advent-product-btn {
      animation: button-flip-reveal 0.7s $default-ease-in-out;
    }
  }

  &.advent-toggle-mobile-animation {
    .advent-product-header {
      @include mobile-only {
        opacity: 0;
        transition-delay: 0.4s;
      }
    }

    .advent-product-tap {
      @include mobile-only {
        opacity: 0;
        transition-delay: 0.4s;
      }
    }

    .advent-product-btn-grad {
      @include mobile-only {
        animation: button-flip-reveal 0.7s $default-ease-in-out;
        height: 66px;
        transition: width 0.5s $default-ease-in-out, height 0.5s $default-ease-in-out;
        width: 66px;
      }
    }

    .btn-flip {
      .icon-advent-key {
        opacity: 0;
        transition-delay: 0.2s;
      }
      .icon-close {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(0);
        transition-delay: 0.25s;
      }
    }
  }
}

.advent-block-reveal-product {
  background-color: $white;
  border-radius: 2px;
  height: 375px;
  left: 150px;
  opacity: 0;
  padding: 9px;
  position: absolute;
  top: 50%;
   transform: translateY(-30%) scale(0.5);
  transition: opacity 0.5s $default-ease-in-out, transform 0.5s $default-ease-in-out;
  width: 345px;
  z-index: 1;

  @include mobile-only {
    bottom: 0;
    left: 50%;
    top: auto;
    transform: translate(-50%, 100px) scale(0.5);
    width: 90%;
    border-radius: 8px;
    transition-delay: 0.3s;
  }

  @include tablet {
    transition-delay: 0.3s;
  }

  &.advent-product-reveal-reverse {
    left: auto;
    right: 20%;

    @include mobile-only {
      bottom: 0;
      left: 50%;
      right: auto;
      top: auto;
      width: 90%;
    }
  }

  .all-gifts-close-btn {
    animation: button-flip 1.7s ease-in-out;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $rit-copper;
    bottom: 40px;
    box-shadow: 0 0 35px 0 rgba($rit-copper, 0.3);
    color: $rit-copper;
    display: block;
    font-size: 24px;
    height: 55px;
    left: 50%;
    position: absolute;
    right: auto;
    top: auto;
    transform: translateX(-50%);
    width: 55px;
    z-index: 6;

    &::after {
      line-height: 26px;
    }
  }

  .advent-reveal-img {
    width: 130px;
    height: 150px;
  }
}

.advent-reveal-gradient-wrap {
  background: linear-gradient(45deg, $advent-shimmer-light, $advent-shimmer-darkest, $advent-shimmer-orange2, $advent-shimmer-darkest, $advent-shimmer-light);
  border-radius: 1px;
  height: 100%;
  max-width: 100%;
  padding: 2px;
  position: relative;

  @include mobile-only {
    border-radius: 4px;
  }
}

.advent-reveal-main-box {
  background-color: $white;
  border-radius: 1px;
  height: 100%;
  padding: 10px;
  text-align: center;

  @include mobile-only {
    border-radius: 4px;
  }

  .advent-reveal-btn-grad {
    align-items: center;
    background: linear-gradient(124deg, $advent-sandy-dark 50%, $advent-shimmer-light2);
    border-radius: 50%;
    display: flex;
    height: 56px;
    justify-content: center;
    margin: auto;
    opacity: 0;
    position: relative;
    top: 10px;
    transition: opacity 1.5s $default-ease-in-out;
    width: 56px;

    @include mobile-only {
      display: none;
    }

    .all-gifts-close-btn  {
      border: 0;
      bottom: auto;
      height: 54px;
      left: auto;
      right: auto;
      top: auto;
      transform: rotate(-60deg);
      transition: transform 0.6s $default-ease-in-out;
      width: 54px;
    }

    .icon-close {
      &::before {
        line-height: 29px;
      }
    }
  }
}

.advent-reveal-headline {
  color: $rit-copper;
  font-size: 14px;
  letter-spacing: 6px;
  line-height: 20px;
  margin-bottom: 3px;
  opacity: 0;
  transition: opacity 1.5s $default-ease-in-out;

  @include mobile-only {
    transition: opacity 0.6s $default-ease-in-out;
  }
}

.advent-reveal-text {
  color: $rit-black;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  opacity: 0;
  transition: opacity 1.5s $default-ease-in-out;

  @include mobile-only {
    transition: opacity 0.6s $default-ease-in-out;
  }

  .advent-reveal-size {
    color: rgba($color: $rit-black, $alpha: 0.72);
  }
}

.advent-product-reveal-open {
  opacity: 1;
  transform: scale(1);
  z-index: 10;

  @include mobile-only {
    transform: translate(-50%, -90px) scale(1);

    &.advent-product-reveal-reverse {
      transform: translate(-50%, -90px) scale(1);
    }
  }

  @include tablet {
    transform: translateY(-50%) scale(1);
  }

  .advent-reveal-img {
    animation: item-slide-in-mob 1s ease-in-out;
    animation-delay: 0.1s;

    @include mobile-only {
      animation-delay: 0.2s;
    }
  }

  .advent-reveal-main-box {
    .icon-close {
      transform: rotate(0);
      transition-delay: 0.5s;
    }
  }

  .advent-reveal-headline {
    animation: item-slide-in-mob-no-scale 1.1s ease-in-out;
    opacity: 1;

    @include mobile-only {
      animation: item-slide-in-mob-no-scale 1.5s ease-in-out;
    }
  }

  .advent-reveal-text {
    animation: item-slide-in-mob-no-scale 1.1s ease-in-out;
    opacity: 1;

    @include mobile-only {
       animation: item-slide-in-mob-no-scale 1.5s ease-in-out;
    }
  }

  .advent-reveal-btn-grad {
    animation: item-slide-in-mob-no-scale 1.5s ease-in-out;
    opacity: 1;
  }

  &.all-advent-gifts {
    position: fixed;
    transform: none;
    z-index: 1000;
    pointer-events: initial;

    .text-holder {
      span {
        animation: subtitle 0.8s ease-out 0.3s;
        display: block;
        opacity: 1;
        transition: opacity 2s ease 0.4s;

        @include mobile-and-tablet {
          animation-delay: 0s;
          transition-delay: 0s;
        }
      }

      h3,
      h2 {
        animation: subtitle 1s ease-out 0.5s;
        opacity: 1;
        transition: opacity 2s ease 0.4s;

        @include mobile-and-tablet {
          animation-delay: 0s;
          transition-delay: 0s;
        }
      }
    }

    .all-advent-gifts-slider {
      opacity: 1;
      transition: opacity 1.2s ease-in-out;
    }

    .slick-slide:nth-of-type(1) {
      animation: item-slide-in 1s cubic-bezier(0, 0.29, 0.02, 0.94) 0.6s;
      opacity: 1;
      transition: opacity 1.1s ease-out 0.7s;
    }

    .slick-slide:nth-of-type(2) {
      animation: item-slide-in 1.2s cubic-bezier(0, 0.29, 0.02, 0.94) 0.7s;
      opacity: 1;
      transition: opacity 1.4s ease-out 0.8s;
    }

    .slick-slide:nth-of-type(3) {
      animation: item-slide-in 1.4s cubic-bezier(0, 0.29, 0.02, 0.94) 0.8s;
      opacity: 1;
      transition: opacity 1.7s ease-out 0.9s;
    }

    .slick-slide:nth-of-type(4) {
      animation: item-slide-in 1.6s cubic-bezier(0, 0.29, 0.02, 0.94) 0.9s;
      opacity: 0.5;
      transition: opacity 2s ease-out 1s;

      &.slick-active {
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.3s;
      }
    }

    //Animation for mobile
    .all-advent-gifts-item {

      &:nth-of-type(1) {
        @include mobile-and-tablet {
          animation: item-slide-in-mob 0.8s ease-in-out;
        }
      }

      &:nth-of-type(2) {
        @include mobile-and-tablet {
          animation: item-slide-in-mob 1s ease-in-out;
        }
      }

      &:nth-of-type(3) {
        @include mobile-and-tablet {
          animation: item-slide-in-mob 1.2s ease-in-out;
        }
      }
    }

    @keyframes item-slide-in {
      0% {
        transform: translateX(120%) scale(0);
      }

      100% {
        transform: translateX(0) scale(1);
      }
    }

    @keyframes subtitle {
      0% {
        transform: translateY(50px);
      }

      100% {
        transform: translateY(0);
      }
    }
  }
}

.all-gifts-close-btn {
  position: absolute;
  top: 70px;
  right: 70px;
  font-size: 20px;
  color: $rit-black;

  @include mobile-only {
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $gray-light;
    color: $gray-dark;
    display: block;
    font-size: 24px;
    height: 46px;
    position: fixed;
    right: 24px;
    top: 24px;
    width: 46px;
    z-index: 11;
  }

  @keyframes button-flip {
    0% {
      transform: translate(-50%, 200%) rotate(90deg);
    }

    50% {
      transform: translate(-50%, 200%) rotate(90deg);

    }

    75% {
      transform: translate(-50%, -20px) rotate(0deg);

    }

    100% {
      transform: translate(-50%, 0);
    }
  }

  @keyframes button-flip-revert {
    0% {
      transform: translate(-50%, 0) scale(0.8);
    }

    50% {
      transform: translate(-50%, -20px) rotate(0deg) scale(1);
    }

    75% {
      transform: translate(-50%, 200%) rotate(90deg);
    }

    100% {
      transform: translate(-50%, 200%) rotate(90deg);
    }
  }

}

.all-gifts-close-btn.not-fixed {
  display: none;
}

.advent-navigation-dots {
  opacity: 0;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;

  @include mobile-only {
    display: none;
  }
}

.advent-navigation-dot {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $white;
  display: block;
  height: 6px;
  margin-bottom: 12px;
  transition: background-color 0.5s $default-ease-in-out;
  width: 6px;

  &::after {
    background-color: $white;
    border-radius: 2px;
    color: $rit-black;
    content: attr(data-description);
    display: block;
    font-family: $font-header;
    font-size: 12px;
    height: 35px;
    letter-spacing: 4px;
    line-height: 35px;
    min-width: 150px;
    opacity: 0;
    padding: 0 15px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    text-transform: uppercase;
    top: -15px;
    transform: scale(0.4) translateX(70px);
    transition-delay: 0.2s;
    transition: opacity 0.5s $default-ease-in-out, transform 0.5s $default-ease-in-out;
    white-space: nowrap;
  }

  &:hover {
    &::after {
      pointer-events: initial;
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }
}

.advent-horizontal-menu {
  background: linear-gradient(transparent -80%, $advent-bg 50%);
  border-top: 1px solid rgba($color: $white, $alpha: .1);
  bottom: 0;
  cursor: grab;
  height: 80px;
  opacity: 0;
  position: fixed;
  width: 100%;
  z-index: 20;

  .inner-wrapper {
    display: flex;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0;
    width: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .advent-menu-items {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;
      white-space: nowrap;

      @include mobile-and-tablet {
        justify-content: space-between;
        max-width: 1440px;
        padding: 0 10px;
      }

      @include desktop {
        max-width: 1500px;
      }

      &.full-width-menu {
        width: 100%;
      }
    }
  }

  .advent-menu-item {
    color: $white;
    display: inline-block;
    font-family: $font-header;
    font-size: 15px;
    letter-spacing: 0.3em;
    line-height: 40px;
    margin: 0 32px;
    min-width: fit-content;
    position: relative;
    text-transform: uppercase;

    &::after {
      background-color: $white;
      bottom: 0;
      content: '';
      display: block;
      height: 0.5px;
      left: 0;
      position: absolute;
      transform-origin: 0% 0%;
      transform: scaleX(0);
      transition: transform 0.5s ease-in-out;
      transition-delay: 0.4s;
      width: 100%;
    }

    @include mobile-and-tablet {
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 5px;

      &::after {
        transition-delay: 0.1s;
      }
    }

    &.advent-shimmer-text {
      &::after {
        background: linear-gradient(45deg, $advent-shimmer-light, $rit-copper);
      }
    }

  }

  .advent-current-section {
    &::after {
      transform-origin:  0% 50%;
      transform: scale(1);
    }
  }
}

.advent-navigation-dot-filled {
  background-color: $white;
}

.advent-menu-visible {
  opacity: 1;
}

.usabilla-btn-position {
  top: 75% !important;
}

.advent-navigation-hidden {
  pointer-events: none;
}

.advent-landing-page,
.product-type-adventcalendar {

  .simple-text-block {
    .inner-wrapper {
      br {
        display: none;
      }

      p {
        font-size: 16px;
        line-height: 28px;
      }

      .heading-intro {
        font-size: 24px;
        line-height: 32px;
      }

      .acc-content-head {
        line-height: 14px;
        padding-left: 0;

        @include desktop {
          line-height: 28px;
        }

        &::before {
          left: unset;
          right: -40px;
        }
      }
    }
  }

  .faq-block {
    h2 {
      line-height: 48px;
    }

    .faq-accordion-content {
      p {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }
  }
}

.advent-landing-page {
  section:nth-of-type(3).two-tile-text-block.full-width {

    .headline {
      @include desktop {
        font-size: 40px;
      }
    }
  }

  .advent-comparison-block {
    @include desktop {
      padding-top: 100px;
    }
    .advent-comparison-header {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 1.2px;

      @include desktop {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 1.4px;
      }
    }
  }

  .two-tile-text-block.full-width {
    .headline {
      @include desktop {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1.6px;
      }
    }
  }
}

.christmas-tree-mobile {
  position: relative;

  .advent-product-reveal {
    opacity: 0;

    @include mobile-only {
      bottom: 60px;
      left: 50%;
      pointer-events: initial;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .advent-pr-full {
    .advent-product-btn {
      @include mobile-only {
        opacity: 1;
      }
    }
  }

  .text-holder {
    @include mobile-only {
      padding: 0 45px 0 30px;
    }
  }
}

.chrismas-tree-mobile-video {
  margin-top: -130px;
  width: 100%;
}

.chrismas-tree-mobile-headline {
  color: $white;
  font-size: 32px;
  letter-spacing: 0.6px;
  line-height: 40px;
  text-transform: initial;
}
