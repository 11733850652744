.out-of-stock-block {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.flyout-out-of-stock {
  max-width: 327px;

  @include tablet {
    max-width: 720px;
  }

  @include desktop {
    max-width: 720px;
  }

  .flyout-out-of-stock-content {
    padding: 97px 24px 60px;
    position: relative;

    @include tablet {
      padding: 73px 40px 40px;
      text-align: center;
    }
  }

  & > .side--close {
    display: none;
  }

  .side--close {
    top: 15px;

    @include tablet {
      top: 15px;
    }
  }

  .out-of-stock-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    text-transform: none;
    margin-bottom: 17px;

    @include tablet {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 2px;
      margin-bottom: 22px;
    }
  }

  .message,
  .subscribed-email {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .image-container {
    margin-top: 55px;

    img {
      max-width: 240px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  .out-of-stock-form-container {
    margin-top: 26px;

    @include tablet {
      margin-top: 44px;
    }

    .email-input {
      margin-top: 8px;
      order: 3;
    }

    .email-submit-btn {
      margin: 43px auto 0;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 2px;
      width: 100%;

      &.button-loading {
        font-size: 0;
        min-height: 54px;
      }

      .spinner {
        margin-top: -20px;
      }

      @include tablet {
        min-width: 320px;
        width: auto;
      }
    }

    label.error {
      order: 2;
      color: $red-error;
      font-size: 11px;
      font-weight: 700;
      line-height: 13px;
      letter-spacing: 1px;
      margin: 3px 0 -2px;
    }

    .thank-you-msg {
      pointer-events: none;
    }
  }
}

.flyout-out-of-stock.advent-out-of-stock {

  @include tablet {
    width: 330px;
  }

    @include desktop {
      width: 450px;
    }

    .flyout-out-of-stock-content {
      padding-top: 70px;

      h3 {
        text-align: center;
        font-size: 24px;
        line-height: 32px;
        @include desktop {
          margin-bottom: 10px;
        }
      }

      .image-container {
        margin-top: 20px;
      }

      .out-of-stock-form-container {
        @include desktop {
          margin-top: 20px;
        }

        .email-submit-btn {
          margin-top: 20px;

          @include tablet {
            width: 100%;
          }
          @include desktop {
            width: 220px;
            min-width: 220px;
          }
        }
      }

    }

}
