// class for hidden info in templates
.visually-hidden {
  border: 0 none;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  min-width: 1px;
}

// class to wrap content in - gives max width and centers in page
.inner-wrapper,
.wrapper__inner { // support for older content assets
  @include inner-wrapper;
}

.inner-wrapper-small {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

// modal overlay available globally
.modal-overlay {
  z-index: $modal-overlay-index;
  background-color: $rit-black;
  opacity: .8;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: all;

  &:not(.flyin-component) {
    display: none;
  }

  &.cookie-modal {
    z-index: $modal-window-index + 2;
  }
}

.linked-block {
  cursor: pointer;
}

.page-fullheight {
  @include desktop {
    min-height: 100vh;
    padding-bottom: 80px;
    position: relative;
    width: 100%;

    .footer {
      bottom: 0;
      left: 0;
      min-height: 80px;
      position: absolute;
    }
  }
}

.not-clickable {
  pointer-events: none;
  cursor: default;
}

// flyin component transition
.backdrop-transition-enter,
.backdrop-transition-leave-to {
  &.modal-overlay {
    opacity: 0;
  }
}

.backdrop-transition-enter-active,
.backdrop-transition-leave-active {
  &.modal-overlay {
    transition: opacity 0.4s $navigation-ease;
  }
}

.no-padding {
  padding: 0 !important;

  .close-toaster {
    color: $rit-black;
    font-size: 24px;
    right: 16px;
    top: 14px;
  }
}
