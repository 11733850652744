.pt_error {
  .main {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .error-wrapper {
    display: block;
    margin: 0 auto;
    padding: 60px 20px;
    text-align: center;

    @include tablet {
      padding: 107px 0 106px;
      max-width: 600px;
    }

    h1, h2 {
      color: $rit-dark;
      text-transform: none;
    }

    h1 {
      font-size: 30px;
      letter-spacing: 1.5px;
      line-height: 46px;
      margin-bottom: 10px;

      @include tablet {
        font-size: 50px;
        letter-spacing: 2px;
        line-height: 60px;
        margin-bottom: 20px;
      }
    }

    h2 {
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 32px;
      max-width: 245px;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;

      @include tablet {
        font-size: 30px;
        letter-spacing: 1.1px;
        line-height: 45px;
        max-width: none;
      }
    }

    .error-page-message p {
      max-width: 535px;
      display: block;
      margin: 0 auto;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;

      a {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
      }
    }
  }

  .primary-content {
    width: 100%;
    background-color: $rit-sandy-bg;
  }

  .error-page-bottom {
    .no-hits-search {
      padding: 73px 0 65px;

      @include tablet {
        padding-top: 76px;
      }
    }

    .error-page-footer {
      margin-top: 69px;
      padding: 0 50px;

      @include tablet {
        margin-top: 35px;
        padding: 0;
      }
    }

    .error-page-footer-wrapper {
      max-width: 495px;
      display: block;
      margin: 0 auto;
      text-align: center;

      p {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;

        &:first-of-type {
          margin-bottom: 28px;
        }
      }
    }

    .error-page-search {
      h3 {
        display: block;
        font-size: 24px;
        letter-spacing: 0.46px;
        line-height: 44px;
        font-family: $font-header;
        margin-bottom: 30px;
        text-transform: none;
      }

      .btn {
        margin: 0 0 0 20px;
        width: auto;
        flex-shrink: 0;
      }
    }
  }

  .error-page-search {
    input {
      width: 100%;
      margin-right: 20px;

      @include tablet {
        width: 50%;
      }
    }
  }
}

//styles for maintenance page

.maintenance-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: $rit-sandy-bg;
  padding: 0 20px;

  .wrapper-maintenance {
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translate(-50%, 0);
    text-align: center;
  }

  .splash-rituals-logo {
    width: 48px;
    margin-bottom: 30px;
  }

  .maintenance-text {
    max-width: 600px;
    min-width: 300px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    color: $rit-dark;
    font-family: $font-body;
  }

  @include tablet {

    .splash-rituals-logo {
      width: 60px;
      margin-bottom: 70px;
    }

    .wrapper-maintenance {
      top: 120px;
    }
  }
}

.browser-error {
  margin-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  color: $red-error;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;

  @include tablet {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%);
    max-width: 600px;
  }
}
