//search
.search-suggestions-container {
  display: none;
  background-color: $white;
  height: calc(100vh - 45px);
  left: -24px;
  margin-top: 43px;
  overflow-y: scroll;
  padding: 30px;
  position: absolute;
  right: -24px;
  min-width: 350px;

  @supports (display: -ms-grid) {
    margin-top: 19px;
  }

  @include tablet {
    height: auto;
    overflow-y: visible;
    margin-top: 11px;
  }

  @include nav-mobile-tablet {
    height: calc(100vh - 56px);
    margin-top: 56px;
    padding: 28px 20px;
    top: 0;
  }

  @include desktop-only {
    left: 0;
    padding: 20px 20px 17px;
    right: 0;
    top: calc(100% + 4px);
    width: 350px;
  }

  @include ie-styles {
    margin-top: 18px;
  }

  .quick-links {
    .heading-h11 {
      display: block;
    }
    a {
      font-size: 15px;
      color: $rit-dark;
      margin-top: 14px;
      display: block;
      line-height: normal;

      &:hover {
        text-decoration: underline;
      }

      &:first-of-type {
        margin-top: 22px;
      }
    }
  }

  .suggestions-title {
    margin-bottom: 17px;

    @include nav-mobile-tablet {
      margin-bottom: 8px;
    }
  }

  .phrase-suggestions {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-gainsboro;

    @include nav-mobile-tablet {
      margin-bottom: 23px;
      padding-bottom: 19px;
    }
  }

  .hit {
    color: $rit-dark;
    display: block;
    font-size: 15px;
    letter-spacing: 0.75px;
    margin-top: 14px;
    line-height: normal;

    @include nav-mobile-tablet {
      margin-top: 0;
    }

    .child-category {
      font-weight: bold;
    }

    .hits-number {
      color: $rit-copper-dark;
      font-size: 12px;
      font-family: $font-header;
      letter-spacing: 0.75px;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .product-suggestions {
    .product-link {
      display: block;
      color: $rit-black;

      @include nav-mobile-tablet {
        margin-bottom: 15px;
      }
    }

    .suggestions-title {
      @include nav-mobile-tablet {
        margin-bottom: 10px;
      }
    }

    .product-suggestion {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      margin: 10px 0;

      .product-add-to-cart-sugest {
        display: flex;
        justify-content: flex-end;
      }

      .btn-wrapper-send {
        margin: 5px 0 0 5px;

        .btn {
          display: block;
          margin-left: auto;
          min-width: 0;
          background-color: $white;
          border: 1px solid $rit-dark;
          border-radius: 50%;
          font-size: 0;
          letter-spacing: 1.5px;
          height: 36px;
          padding: 0;
          width: 36px;
          color: $rit-black;

          .icon-send {
            display: none;
          }

          .spinner-visible {
            transform: translate(2px, 1px);

            :nth-child(1) {
              margin-right: 7px;
            }

            :nth-child(2) {
              margin-right: 0;
            }

            :nth-child(3) {
              margin-right: -7px;
            }

            span {
              width: 5px;
              height: 5px;
            }
          }

          &:not(.button-loading) {
            &::before {
              @include icon-styles;
              content: icon-char(send);
              display: block;
              font-size: 18px;
              margin-top: 2px;
            }
          }

          @include desktop {
            &:hover,
            &:active {
              color: $white;
              background-color: $rit-black;
            }
          }
        }
      }

      @include nav-mobile-tablet {
        margin: 0;
      }
    }

    .product-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $rit-sandy-bg;
      width: 60px;
      height: 60px;
      flex-shrink: 0;

      img {
        max-width: 50px;
        max-height: 50px;
      }

      @include nav-mobile-tablet {
        height: 50px;
        width: 50px;

        img {
          max-height: 40px;
          max-width: 40px;
        }
      }
    }

    .product-details {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      width: 100%;
    }

    .ritual-title,
    .name {
      text-transform: uppercase;
      font-family: $font-header;
    }

    .ritual-title {
      font-size: 10px;
      letter-spacing: 1.5px;
      color: $rit-copper-dark;
      line-height: 12px;
      margin-bottom: 2px;

      @include nav-mobile-tablet {
        margin-bottom: 4px;
      }
    }

    .name {
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 20px;

      @include nav-mobile-tablet {
        line-height: 18px;
      }
    }

    .short-description {
      font-size: 14px;
      line-height: 20px;
      color: $rit-black;
      opacity: 0.5;
      margin-top: 2px;

      @include nav-mobile-tablet {
        letter-spacing: .5px;
        margin-top: 5px;
      }
    }

    .product-price {
      align-self: flex-start;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 18px;
      margin-left: auto;
      margin-top: 13px;
      padding-left: 10px;
      text-align: right;
      white-space: nowrap;

      s {
        color: $rit-black;
        display: block;
        font-size: 13px;
        opacity: 0.5;
      }

      .product-discount-no-strikethrough {
        display: none;
      }

      @include nav-mobile-tablet {
        margin-top: 17px;
        padding-left: 20px;
      }
    }
  }

  .search-view-all-link {
    color: $rit-copper-dark;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 12px;
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }
  }
}

.search-overlay {
  z-index: 1;
  display: none;
  background-color: $rit-black;
  opacity: .5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 80px;
  left: 0;
  pointer-events: none;

  @include tablet {
    pointer-events: auto;
  }

  @include nav-tablet {
    &.header-overlay {
      top: 88px;
    }
  }
}

//search page
.pt_product-search-result {
  background-color: $rit-sandy-dark;

  .main {
    margin-bottom: 30px;
  }

  .slot-grid-header {
    min-height: 180px;
    display: flex;
    align-items: center;

    &.cop-banner-container {
      align-items: flex-start;
      min-height: 10px;
    }

    .content-banner {
      @include tablet {
        margin-bottom: -110px;
      }
    }
  }

  .content-banner .text-holder {
    padding: 70px 0 120px;
  }

  @include mobile-only {
    .content-banner {
      align-items: flex-end;

      &.banner-small {
        min-height: 180px;
        padding-bottom: 12px;
      }

      .image-holder::after {
        background: linear-gradient(to bottom, rgba(35, 31, 32, 0), rgba(35, 31, 32, .8));
        bottom: 0;
        content: '';
        display: inline-block;
        left: 0;
        opacity: .8;
        position: absolute;
        right: 0;
        top: 0;
      }

      .text-holder {
        margin: 0;
        padding: 0;
      }

      .heading-intro {
        display: none;
      }

      .heading-h1 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0;
      }
    }

    .search-result-options {
      .mobile-filter-trigger .filter-trigger-btn {
        &::after {
          display: none;
        }

        .filter-count {
          display: inline-block;
        }
      }

      &.sticky-filters .filter-cart {
        display: inline-block;
      }
    }
  }

  @include tablet {
    .content-banner .text-holder {
      padding: 90px 0 120px;
    }

    .search-result-options.sticky-filters .mobile-filter-trigger .filter-trigger-btn {
      &::after {
        display: none;
      }

      .filter-count {
        display: inline-block;
      }
    }

    .simple-text-block {
      &.drag-list {
        margin-top: 110px;
      }
    }
  }
}

.search-grid-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;

  .filter-trigger-btn {
    color: $white;
    font-family: $font-body;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    padding-right: 19px;
    position: relative;
    white-space: nowrap;

    @include tablet {
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    &::after {
      @include icon-styles;
      color: $white;
      content: icon-char(arrow3--down-white);
      font-size: 4px;
      position: absolute;
      right: 0;
      top: -1px;
    }

    .filter-count {
      color: $rit-copper-dark;
      letter-spacing: 0.5px;

      @include tablet {
        display: none;
      }
    }
  }

  .search-result-content {
    margin-bottom: 30px;
    position: relative;
  }

  .grid-load-more {
    text-align: center;
    width: 100%;
  }

  .grid-product-number {
    align-items: center;
    color: $gray-disabled;
    display: flex;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;
    justify-content: center;
    padding-bottom: 23px;
    position: relative;

    @include tablet {
      padding-bottom: 30px;
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      margin-bottom: 1px;
      width: 100px;

      @include tablet {
        margin-bottom: 3px;
        width: 130px;
      }
    }

    &::before {
      background: linear-gradient(to left, rgba($gray-disabled, 1), rgba($gray-disabled, 0));
    }

    &::after {
      background: linear-gradient(to right, rgba($gray-disabled, 1), rgba($gray-disabled, 0));
    }

    .grid-product-number-inner {
      flex-shrink: 0;
      padding: 0 8px;
    }

    &.grid-number-hidden {
      visibility: hidden;
    }
  }

  @include mobile-only {
    .inner-wrapper {
      padding: 0 10px;
    }

    .search-result-content {
      padding-top: 10px;
    }
  }

  @include tablet {
    .inner-wrapper {
      max-width: 1360px;
    }
  }
}

// navigation 2.0 - new styles for "search-result-options"
.search-results-header {
  margin-left: -10px;
  width: calc(100% + 20px);

  .search-result-inner {
    padding-top: 13px;
    width: 100%;

    .inner-wrapper {
      justify-content: flex-end;
      display: flex;

      @include tablet {
        padding: 0;
      }

      @include desktop-only {
        padding: 0 10px;
      }
    }
  }

  .filter-trigger {
    margin-right: auto;
  }

  .filter-trigger-btn {
    color: $rit-black;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 0;
    vertical-align: middle;

    &::before {
      @include icon-styles;
      color: $rit-black;
      content: icon-char(filter-icon);
      display: inline-block;
      font-size: 10px;
      margin: -3px 0 0 6px;
    }

    &::after {
      display: none;
    }
  }

  .custom-select {
    // sass-lint:disable-block no-duplicate-properties
    background-image: url('../images/icons/arrow3--down.svg');
    background-size: 18px auto;
    color: $rit-black;
    padding: 0 17px 0 0;
    text-align: right;
    text-align-last: right;
    text-align: -webkit-right;
    text-align: -moz-right;
  }

  .search-summary-holder {
    color: $rit-black;
    display: none;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    margin: 0 5px 0 21px;
    padding: 0 20px;
    position: relative;
    text-transform: uppercase;

    &::before,
    &::after {
      background-color: $rit-black;
      bottom: 11px;
      content: '';
      position: absolute;
      top: 10px;
      width: 1px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .selectric-wrapper {
    .selectric {
      background-color: $rit-black;
      border: 0;
      border-bottom: 0;
      height: 32px;
      padding: 0 34px 0 16px;

      .label {
        color: $white;
        display: inline-block;
        font-size: 12px;
        height: 32px;
        letter-spacing: 2px;
        line-height: 34px;
        padding: 0;
        text-transform: uppercase;
      }

      .button {
        display: inline-block;
        height: 14px;
        line-height: 14px;
        right: 11px;
        top: 1px;

        &::before,
        &::after {
          border-color: $white;
        }

        &::before {
          color: $white;
          font-size: 18px;
          line-height: 1;
        }
      }
    }

    .selectric-items {
      left: auto;
      right: 0;
      top: 100%;
      width: 320px !important;
    }
  }

  .filter-cart {
    display: none;
    margin-left: 20px;

    .header--cart-link {
      color: $white;
      text-decoration: none;
    }

    .header-inline-icon {
      margin-bottom: -3px;
    }

    .cart-number {
      //temporary solution, will need to come back to this
      display: none;
      bottom: 1px;
    }
  }

  &.sticky-filters {
    .search-result-inner {
      background-color: $rit-black;
      left: 0;
      max-height: 44px;
      min-height: 44px;
      position: fixed;
      transition:
        top 0.4s cubic-bezier(0.56, 0.88, 0.15, 1),
        transform $tertiary-duration $navigation-ease;
      z-index: 980;
    }

    .search-summary-holder,
    .filter-product-number {
      display: none;
    }

    .filter-count {
      color: $rit-copper-on-black;
    }
  }

  @include mobile-only {
    .search-result-inner {
      top: 0;
      transition:
        top $tertiary-duration ease-in-out,
        background $primary-delay ease-in-out;

      .inner-wrapper {
        align-items: center;
        position: relative;
      }
    }

    .filter-trigger-btn {
      color: $rit-black;
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .filter-product-number {
      display: inline-block;
      font-size: 12px;
      left: 50%;
      letter-spacing: 1px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }

    .sorting-options {
      position: relative;

      .mobile-sorting-label {
        color: $rit-black;
        display: inline-block;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        pointer-events: none;
        position: relative;
        text-transform: uppercase;
        z-index: 3;

        &::after {
          @include icon-styles;
          content: icon-char(arrow3--down);
          display: inline-block;
          font-size: 22px;
          margin: 0 -6px 0 -1px;
        }
      }

      .sort-by {
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      .custom-select {
        background: none;
        color: transparent;
        overflow: hidden;
        width: 100%;
      }
    }

    &.sticky-filters {
      height: 48px;

      .nav-visible & {
        .search-result-inner {
          padding: 11px 0 8px;
          top: 80px;
          transition-duration: 0.6s;
          transition-timing-function: cubic-bezier(0.48, 0.12, 0.15, 1);
        }
      }

      .filter-trigger-btn,
      .filter-trigger-btn::before,
      .custom-select {
        color: $white;
      }

      .filter-cart {
        display: inline-block;
      }

      .mobile-sorting-label {
        color: $white;
      }

      .custom-select {
        color: transparent;
      }
    }
  }

  @include tablet {
    .search-result-inner {
      padding: 0 0 15px;

      .sorting-options .selectric-items ul .disabled {
        display: none;
      }
    }

    .filter-trigger {
      margin-right: 0;
    }

    .filter-trigger-btn {
      background-color: $rit-black;
      color: $white;
      display: inline-block;
      height: 32px;
      line-height: 34px;
      padding: 0 19px 0 14px;
      vertical-align: top;

      &::before {
        color: $white;
      }
    }

    .filter-product-number,
    .mobile-sorting-label {
      display: none;
    }

    .search-summary-holder {
      align-items: center;
      display: flex;
    }

    .sorting-options {
      margin-left: 16px;
    }

    .custom-select {
      background: $rit-black url('../images/icons/arrow3--down-white.svg');
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: 10px auto;
      color: $white;
      font-size: 12px;
      letter-spacing: 2px;
      height: 32px;
      padding: 0 19px;
      text-align: left;
      text-align-last: left;
      text-transform: uppercase;

      @include desktop-only {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.sticky-filters {
      .search-result-inner {
        top: -44px;
      }

      .nav-visible & {
        .search-result-inner {
          transition-duration: 0.6s;
          transition-timing-function: cubic-bezier(0.48, 0.12, 0.15, 1);

          @include tablet-only {
            top: 44px;
          }

          @include desktop-only {
            top: 68px;
          }
        }
      }

      .search-result-inner-wrapper {
        align-items: center;
        justify-content: flex-start;
        margin: 0 auto;
        max-width: 1360px;
        padding: 0 20px;
      }

      .mobile-filter-trigger {
        display: block;
        float: left;

        .filter-trigger-btn {
          display: inline-block;
          padding: 17px 19px 13px 0;

          &::after {
            top: 16px;
          }
        }
      }

      .filter-trigger-btn {
        background-color: $white;
        color: $rit-black;
        height: 44px;
        line-height: 46px;
        padding-left: 10px;

        &::before {
          color: $rit-black;
        }
      }

      .sorting-options {
        margin: 0 -6px 0 2px;
      }

      .selectric-wrapper {
        .selectric {
          height: 44px;

          .label {
            height: 44px;
            line-height: 46px;
          }

          .button {
            top: 7px;
          }
        }
      }

      .custom-select {
        height: 44px;
      }

      &.sticky-filters-visible .search-result-inner {
        max-height: 44px;
        min-height: 44px;
        padding: 0;
        transform: translateY(44px);
      }
    }

    .sorting-options.sorting-options-dark {
      .custom-select {
        background-image: url('../images/icons/arrow3--down.svg');
        background-size: 18px auto;
        color: $rit-black;
      }
    }
  }
}

.search-filters-mobile {
  @include tablet {
    display: none;
  }
}

.cop-filters-wrapper {
  .cop-filters-heading {
    display: none;
  }

  .filter-show-more {
    color: $white;
    display: none;
    flex-shrink: 0;
    font-family: $font-body;
    letter-spacing: 1px;
    margin-left: auto;
    position: relative;
    padding: 8px 18px 5px 0;
    text-transform: uppercase;

    &::after {
      @include icon-styles;
      content: icon-char(arrow3--down);
      display: inline-block;
      font-size: 22px;
      line-height: 1;
      position: absolute;
      right: -4px;
      top: 5px;
    }

    .less {
      display: none;
    }
  }

  .cop-filters-inner {
    // sass-lint:disable-block no-misspelled-properties
    overflow: auto;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cop-filters-list {
    li {
      border: 1px solid #ccc;
      border-radius: 16px;
      display: inline-block;
      height: 30px;
      margin: 0 8px 10px 0;
      vertical-align: top;

      &.selected {
        background-color: $rit-dark;
        border-color: $rit-dark;

        .text {
          color: $white;
          pointer-events: none;
        }

        .remove-filter {
          display: inline-block;
        }
      }
    }

    .text {
      color: $rit-black;
      cursor: pointer;
      display: inline-block;
      font-family: $font-body;
      font-size: 12px;
      height: 28px;
      letter-spacing: 1px;
      line-height: 30px;
      padding: 0 17px;
      text-decoration: none;
      vertical-align: middle;
      text-transform: none;
    }

    .remove-filter {
      color: $white;
      display: none;
      font-size: 10px;
      line-height: 28px;
      margin: 0 10px 0 -10px;
      vertical-align: middle;
    }
  }

  @include mobile-only {
    height: 50px;
    margin-left: -10px;
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    width: calc(100% + 20px);

    &::before,
    &::after {
      bottom: 0;
      content: '';
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity $quaternary-duration $default-ease-in-out;
      visibility: hidden;
      width: 34px;
      z-index: 5;
    }

    &.left-fade::before {
      left: 0;
      opacity: 1;
      visibility: visible;
    }

    &.right-fade::after {
      opacity: 1;
      right: 0;
      visibility: visible;
    }

    .cop-filters-list {
      font-size: 0;
      padding: 0 10px;
      width: auto;

      &.no-wrap {
        white-space: nowrap;
      }

      sup {
        display: none;
      }
    }

    &.cop-subcategories-wrapper {
      background-color: $rit-black;

      &::before {
        background: linear-gradient(to left, rgba($rit-black, 0), rgba($rit-black, 1));
      }

      &::after {
        background: linear-gradient(to right, rgba($rit-black, 0), rgba($rit-black, 1));
      }

      .cop-filters-list {
        padding: 0 15px;

        li {
          border: 0;
          border-radius: 0;
          margin: 0 5px 10px 0;
        }

        .text {
            color: $white;
            font-family: $font-header;
            font-size: 14px;
            letter-spacing: 0.2px;
            padding: 0 10px;
        }
      }
    }

    &.cop-product-types-wrapper {
      background-color: $white;

      &::before {
        background: linear-gradient(to left, rgba($white, 0), rgba($white, .8));
      }

      &::after {
        background: linear-gradient(to right, rgba($white, 0), rgba($white, .8));
      }
    }
  }



  @include tablet {
    .cop-filters-inner {
      overflow: visible;
    }

    .cop-filters-list-subcategories {
      list-style: none;
      margin-bottom: 25px;
      width: 100% !important;

      li {
        border: 0;
        border-radius: 0;
        display: flex;
        height: auto;
        margin: 0 0 25px;

        &:last-child {
          margin: 0;
        }

        sup {
          color: $rit-copper-light;
          display: inline;
          font-size: 16px;
          opacity: 0;
          position: relative;
          top: -20px;
          transition: opacity $tertiary-duration $default-ease-out;
        }
      }

      .text {
        color: $white;
        font-family: $font-header;
        font-size: 24px;
        letter-spacing: 0.9px;
        line-height: 30px;
        overflow: hidden;
        padding: 0 0 1px;
        position: relative;
        text-decoration: none;
        text-overflow: ellipsis;
        text-transform: none;
        white-space: nowrap;

        &::after {
          background-color: $white;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: -1px;
          position: absolute;
          transition: width $tertiary-duration $default-ease-out;
          width: 0;
        }

        &:hover {
          &::after {
            width: 100%;
          }

          + sup {
            opacity: 1;
          }
        }
      }
    }

    .cop-filters-heading {
      color: $white;
      display: block;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      margin: 6px 0 24px;
    }

    .cop-filters-list-product-types {
      margin-bottom: 20px;
      width: 100% !important;

      li {
        border-color: $gray-dark;
        height: 32px;

        &.selected {
          background-color: #353132;
          border-color: #353132;

          .remove-filter {
            opacity: .36;
          }
        }
      }

      .text {
        color: $white;
        height: 30px;
        letter-spacing: 0.75px;
        line-height: 32px;
      }
    }

    .filter-show-more {
      display: none !important;
    }

    ~ .active-refinements-list .active-refinements-inner {
      margin: 20px 0;

      &.empty {
        margin: 0;
      }
    }
  }

  @include tablet-only {
    ul.cop-filters-list-subcategories {
      li {
        margin-bottom: 15px;
      }

      .text {
        font-size: 20px;
      }
    }
  }
}

.zoomed-in {
  @include mobile-only {
    .cop-filters-wrapper {
      align-items: flex-start;
      display: flex;
      height: auto;
      padding: 10px;

      &::before,
      &::after {
        display: none;
      }

      .cop-filters-inner {
        overflow: hidden;
      }

      .cop-filters-list {
        max-height: 30px;
        overflow: hidden;
        padding: 0;
        width: 100%;

        .hidden {
          display: none;
        }
      }

      .filter-show-more {
        display: block;
      }

      &.expanded {
        padding-bottom: 0;

        .cop-filters-list {
          max-height: none;
        }

        .filter-show-more {
          &::after {
            top: 3px;
            transform: rotate(-180deg);
          }

          .more {
            display: none;
          }

          .less {
            display: block;
          }
        }
      }
    }

    .search-results-header {
      .sorting-options .sort-by {
        overflow: visible;
      }

      .selectric-wrapper {
        background-color: $rit-sandy-bg;

        .selectric {
          background-color: transparent;
          height: 26px;

          .label {
            color: $rit-black;
            height: 26px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.search-result-items.grid-wrap {
  float: none;
  list-style: none;
  overflow: visible;

  .fixed-item {
    background-color: transparent;
    width: calc(33.3333% - 64px);
    margin-bottom: 20px;
  }

  .product-title {
    .the-rituals-of-title {
      margin-bottom: 21px;
    }
  }

  .filter-no-hits {
    background-color: $rit-sandy-bg;
    overflow: hidden;
    padding: 51px 30px;
    text-align: center;
    width: 100%;

    h2 {
      margin-bottom: 4px;
      text-transform: none;
    }
    p {
      font-family: $font-header;
      font-size: 18px;
      line-height: 32px;
    }
  }

  @include mobile-only {
    &.no-hits-grid {
      background-color: $rit-sandy-bg;
      margin-left: -10px;
      padding: 20px;
      width: calc(100% + 20px);
    }

    .fixed-item {
      display: none;
    }

    .item {
      background-color: transparent;

      .product-tile {
        background-color: $white;
      }
    }
  }

  @include tablet {
    .filter-no-hits {
      background-color: transparent;
      float: right;
      margin-right: 64px;
      padding: 190px 30px;
      width: calc(66.6666% - 64px);

      h2 {
        font-size: 50px;
        line-height: 1.2;
        margin-bottom: 9px;
      }

      p {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }

  @include desktop {
    margin: -20px 0 0 -30px;
    padding: 20px 0 0 30px;
    width: calc(100% + 100px);
  }
}

ul.search-result-items.grid-wrap {
  @media (min-width: 768px) and (max-width: 1024px) {
    margin: -20px 0 0 -10px;
    padding: 20px 0 0 10px;
    width: calc(100% + 20px);

    .item {
      width: calc(33.3333% - 20px);
    }
  }

  @include mobile-only {
    .item {
      margin-bottom: 8px;
    }
  }

  @include tablet-portrait {
    .item {
      margin-bottom: 24px;
    }
  }

  @include tablet-landscape {
    .item {
      margin-bottom: 18px;
    }
  }
}

// navigation 2.0 - new styles for "search-filter-box"
.search-filter-control-box {
  background-color: #231f20;
  box-shadow: 0 0 30px rgba($black, .5);
  display: none;
  min-height: 162px;
  padding: 32px 40px 30px;
  text-align: left;
  width: 100%;

  .breadcrumb {
    margin-bottom: 20px;

    ol {
      margin: 0 0 10px;
      padding: 0;
      text-align: left;
    }

    .breadcrumb-list-item:last-child .breadcrumb-element::after {
      display: none;
    }

    .breadcrumb-element {
      color: $rit-copper-light;
      font-family: $font-header;
      font-size: 13px;
      letter-spacing: .9px;
      line-height: 16px;
      text-transform: none;

      &::after {
        content: '/';
        padding: 0;
      }
    }
  }

  .filter-box-categories {
    list-style: none;

    li {
      display: flex;
      margin-bottom: 15px;
    }

    .category-title {
      color: $white;
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: .9px;
      line-height: 26px;
      overflow: hidden;
      padding-bottom: 1px;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;

      &::after {
        background-color: $white;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: -1px;
        position: absolute;
        transition: width $tertiary-duration $default-ease-out;
        width: 0;
      }

      &:hover,
      &.selected {
        &::after {
          width: 100%;
        }

        + sup {
          opacity: 1;
        }
      }
    }

    sup {
      color: $rit-copper-light;
      display: inline;
      font-size: 16px;
      opacity: 0;
      position: relative;
      top: -20px;
      transition: opacity $tertiary-duration $default-ease-out;
    }
  }

  .filter-box-popular {
    list-style: none;
    margin-bottom: 35px;

    li {
      line-height: 20px;
      margin-bottom: 5px;
      text-transform: none;
    }

    .popular-title {
      color: $rit-copper-light;
      display: inline-block;
      font-family: $font-header;
      font-size: 13px;
      letter-spacing: .9px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    .popular-item {
      color: #f7f4ef;
      font-size: 14px;
      letter-spacing: 1.7px;
      line-height: 20px;
      text-decoration: none;
      position: relative;

      &::after {
        background-color: $white;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: -1px;
        overflow: hidden;
        padding-bottom: 1px;
        position: absolute;
        text-overflow: ellipsis;
        transition: width $tertiary-duration $default-ease-out;
        white-space: nowrap;
        width: 0;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  .active-refinements-list {
    .active-refinements-inner {
      margin-bottom: 0;
      overflow: hidden;

      .filter-tags-title {
        color: $white;
        font-family: $font-body;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 14px;
        margin: 10px 0 24px;
        text-transform: uppercase;
      }
    }

    .filter-tag {
      padding: 10px 15px 8px 18px;
    }
  }

  @include tablet-only {
    padding: 20px;
  }

  @include tablet {
    display: flex;
    flex-direction: column;
  }

  @include desktop {
    .breadcrumb {
      margin-bottom: 27px;
    }

    .filter-box-categories {
      li {
        margin-bottom: 25px;
      }

      .category-title {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .filter-box-popular {
      margin-bottom: 43px;
    }
  }
}

.search-summary-mobile {
  margin: -7px 0 8px;

  span {
    display: inline-block;
    color: $white;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
  }

  @include tablet {
    display: none;
  }
}

.search-slot-holder {
  padding-top: 20px;
  width: 100%;
}

.search-grid-asset {
  padding: 20px 0;
  text-align: center;
  width: 100%;
  text-transform: none;

  .image-holder {
    margin-bottom: 0;
    padding-top: 10px;
  }

  img {
    max-width: 280px;
    width: 100%;
    margin: 0 auto 10px;
  }

  p {
    max-width: 265px;
    margin: 0 auto 10px;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 22px;
  }

  .heading-h6 {
    font-size: 30px;
    line-height: 36px;
    max-width: none;
    letter-spacing: 1px;
    margin: -3px auto 23px;
  }

  .payment-icon {
    text-decoration: none;
    padding: 0 3px;

    @include tablet {
      &.i-minDesktop,
      &.i-minTablet {
        display: inline-block !important;
      }
    }
  }

  &.cop-grid-asset {
    padding: 35px 10px 52px;

    .gwpbanner-img-desktop {
      display: none;

      &.desktop-only {
        display: block;
      }
    }

    img {
      margin: 0 auto 20px;
      max-width: 147px;
    }

    .heading-h10 {
      font-size: 11px;
      letter-spacing: 1.5px;
      margin-bottom: 12px;
    }

    i {
      font-style: italic;
    }

    .promotion-alert {
      color: $rit-black;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: none;
      max-width: 200px;
      margin: 17px auto 0;
    }

    @include mobile-only {
      .heading-h6 {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin-bottom: 10px;
      }

      .btn--secondary {
        border: 0;
        color: $rit-copper;
        padding: 0;
        text-decoration: underline;
        letter-spacing: 1.6px;
        line-height: 20px;
      }
    }

    @include tablet {
      padding: 40px 40px 60px;

      .heading-h10 {
        margin-bottom: 7px;
      }

      .heading-h6 {
        font-size: 20px;
        letter-spacing: .5px;
        line-height: 24px;
        margin-bottom: 22px;
      }

      img {
        margin: 15px auto 20px;
        max-width: 250px;
      }

      .promotion-alert {
        margin-top: 0;
      }

      .btn--secondary {
        margin-bottom: 21px;
      }

      .gwpbanner-img-desktop {
        display: block;
      }

      .gwpbanner-img-mobile {
        display: none;
      }
    }

    @include desktop-large {
      .btn--secondary {
        min-width: 300px;
      }
    }
  }

  @include mobile-only {
    img {
      max-width: 150px;
    }

    .heading-h10 {
      font-size: 11px;
      line-height: 18px;
      margin-bottom: 12px;
      letter-spacing: 1.5px;
    }

    .heading-h6 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1px;
      margin: 0 auto 9px;
    }

    p {
      font-size: 12px;
      max-width: 150px;
      letter-spacing: 1px;
      line-height: 18px;
      margin: 0 auto;
    }
  }

  @include tablet {
    padding: 0;
  }
}

.search-results-summary {
  padding: 20px 0;
  text-align: center;
  width: 100%;

  .heading-h1 {
    color: $rit-dark;
    margin-bottom: 4px;
  }

  p {
    color: $rit-dark;
    font-family: $font-header;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;

    strong {
      font-family: $font-header-bold;
      font-weight: bold;
    }
  }

  @include tablet {
    padding: 108px 0 166px;

    .heading-h1 {
      margin-bottom: 7px;
    }

    p {
      font-size: 30px;
      line-height: 45px;
    }
  }
}

.no-hits-header {
  text-align: center;
  width: 100%;

  .inner-indented {
    margin: 0 auto;
    padding: 85px 20px 58px;
    width: 100%;
  }

  h2 {
    font-size: 30px;
    letter-spacing: 1.5px;
    line-height: 46px;
    margin-bottom: 0;
    text-transform: none;
  }

  p {
    font-family: $font-header;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;
  }

  .no-hits-search-suggestion {
    font-family: $font-body;
    font-size: 16px;
    line-height: 28px;
    padding-top: 10px;
  }

  @include tablet {
    .inner-wrapper {
      max-width: 1180px;
    }

    .inner-indented {
      padding: 107px 0 55px;
    }

    h2 {
      font-size: 50px;
      letter-spacing: 2px;
      line-height: 60px;
      margin-bottom: 6px;
    }

    p {
      font-size: 30px;
      line-height: 45px;
      margin: 0 auto 10px;
      max-width: 570px;
    }
  }
}

.no-hits-help {
  width: 100%;

  .ho-hits-asset-holder {
    padding: 56px 0 26px;
  }

  .simple-text-block.simple-text-block-small {
    margin-bottom: 57px;

    .inner-wrapper {
      min-height: 10px;
      padding: 0 30px;
    }

    .heading-h7 {
      margin-bottom: 21px;
    }

    a {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  @include tablet {
    .inner-wrapper {
      max-width: 1180px;
    }

    .ho-hits-asset-holder {
      display: flex;
      padding: 68px 0 80px;
    }

    .content-asset {
      width: 33.333%;
    }

    .simple-text-block.simple-text-block-small {
      margin-bottom: 0;

      .inner-wrapper {
        justify-content: space-between;
        min-height: 111px;
        padding: 0 10px;
      }
    }
  }
}

.no-hits-search {
  background-color: $white;
  padding: 79px 0 48px;
  text-align: center;
  width: 100%;

  h3 {
    font-size: 24px;
    letter-spacing: .4px;
    line-height: 30px;
    margin-bottom: 30px;
    text-transform: none;
  }
  .inner-wrapper {
    .inner-indented {
      h2 {
        color: $black;
        font-size: 32px;
        line-height: 33px;
        letter-spacing: 1px;
        text-transform: initial;
        max-width: 600px;
        margin: 0 auto;

        &:last-of-type {
          margin-bottom: 30px;
        }

        @include mobile-only {
          font-size: 20px;
          line-height: 20.78px;
          max-width: 310px;

          &:last-of-type {
            margin-bottom: 20px;
          }
        }
      }
    }

    h3 {
      color: $black;
      font-size: 16px;
      font-family: $font-body;
      line-height: 16.62px;
      letter-spacing: 1px;
      max-width: 310px;
      margin: 0 auto 40px;
      text-transform: initial;

      @include mobile-only {
        font-size: 14px;
        line-height: 14.55px;
        margin: 0 auto 30px;
        max-width: 310px;
      }
    }
  }

  form {
    padding: 0 28px;
    width: 100%;

    @include mobile-only {
      padding: 0 8px;
    }

    .form-inner {
      display: flex;
      align-items: flex-end;

      @include mobile-only {
        padding: 0 8px;
      }
    }

    .btn {
      font-size: 20px;
      margin-top: 20px;
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include mobile-only {
        border-radius: 0 3px 3px 0;
        font-size: 20px;
        height: 47px;
        min-width: 50px;
      }
    }

  .inputfield-row {
    label {
      font-size: 12px;
      line-height: 18px;
      color: $black;
      font-weight: bold;
    }

    input {
      border: 1px solid $gray-oopsform;
      border-radius: 3px;
      margin-top: 3px;
      padding: 13px 21px;

      @include mobile-only {
        border-radius: 3px 0 0 3px;
        height: 47px;
      }
    }

    button {
      margin: 0;
    }
  }
}

  @include tablet {
    padding: 147px 0 107px;

    .inner-wrapper {
      max-width: 1180px;
    }

    form {
      margin: 0 auto;
      max-width: 560px;
      padding: 0;

      .form-inner {
        display: flex;
        align-items: flex-end;
      }

      .inputfield-row {
        flex-grow: 1;
        width: auto;
      }

      .btn {
        flex-shrink: 0;
        margin: 0;
        min-width: 100px;
        width: auto;
        height: 45px;
        margin-bottom: 1px;
      }
    }
  }
}

.no-hits-footer {
  background-color: $white;
  display: flex;
  padding: 33px 20px 70px;
  text-align: center;
  width: 100%;

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
  }

  @include tablet {
    padding: 14px 0 70px;

    .inner-wrapper {
      max-width: 560px;
    }
  }
}

.btn-wrapper-agent {
  margin-top: 5px;

  .btn {
    display: block;
    margin-left: auto;
    min-width: 0;
    background-color: $rit-black;
    border: 1px solid $rit-black;
    border-radius: 50%;
    font-size: 0;
    letter-spacing: 1.5px;
    height: 36px;
    padding: 0;
    width: 36px;
    color: $white;

    &::before {
      @include icon-styles;
      content: icon-char(cart-plus);
      display: block;
      font-size: 16px;
      margin-left: 1px;
    }

    @include desktop {
      &:hover,
      &:active {
        border: 1px solid $rit-black;
        color: $rit-black;
      }
    }
  }
}

.nohits-btn {
  display: block;
  margin: 0 auto 50px;
  max-width: 250px;

  @include mobile-only {
    margin: 0 auto 30px;
  }
}

.social-commerce-product-info {
  padding: 30px 20px;
  max-width: 400px;
  text-align: center;

  img {
    width: 100%;
  }

  .price-standard {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-decoration: line-through;
    color: #8c8c8c;
    display: block;
    margin-top: 5px;
  }
}

// social-commerce-cart for template screenshot

.social-commerce-cart-info {
  width: 400px;

  .checkout-summary-inner {
    @include mobile-only {
      width: 100%;
      margin: 0;
    }
  }

  .section-header {
    font-weight: bold;
  }

  .order-subtotal {
    .cart-footer__item--name {
      font-weight: bold;
    }
  }
}

.social-commerce-cart-info-v3 {
  width: 400px;

  @include mobile-only {
    padding: 15px 40px;
  }
}

.body--no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
