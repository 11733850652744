.pt_product-details.animate-scroll {

  .product-details-container {
    position: relative;

    .opaque-overlay {
      height: 100%;
      left: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: background $primary-duration $default-ease-out;
      width: 100%;
      z-index: 1;

      &.cover-main-block-only {
        position: absolute;
      }
    }

    &.opaque {
      .opaque-overlay {
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }

    .column-images {
      transition: filter $secondary-duration $default-ease-out;

      &.show-image-only {
        .promotional-label,
        .slider-bar,
        .slick-arrow {
          visibility: hidden;
        }
      }

      &.blurred {
        filter: blur(10px);
      }
    }

    .column-details {
      z-index: 1;
    }

    .product-ingredients-images { // product-hero-ingredients
      height: calc(100% + 20px);
      margin: 0 -20px;
      overflow: hidden;
      position: absolute;
      top: -20px;
      width: calc(100% + 20px);

      @include tablet {
        height: 100%;
        margin: 0;
        overflow: visible;
        top: -10%;
        width: 100%;
      }

      img {
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity $secondary-duration $default-ease-out-fast,
                    left $secondary-duration $default-ease-out-fast,
                    transform $secondary-duration $default-ease-out-fast;
        width: 100%;

        &:first-child,
        &:last-child {
          left: 0;
          max-width: 370px;

          @include tablet {
            max-width: 700px;
            max-height: 700px;
            transform: scale(0.1);
          }
        }
      }

      &.show {
        img {
          opacity: 1;
          &:first-child {
            left: 10%;
            transform: translateX(-80px) scale(1);

            @include tablet {
              left: -17%;
              transform: scale(1);
            }
          }
          &:last-child {
            left: -10%;
            transform: translateX(80px) scale(1);

            @include tablet {
              left: 17%;
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  .product-ingredients-container {
    .column-image {
      height: 110vw;

      @include tablet {
        height: auto;
      }
    }

    .product-ingredients-content {
      @include tablet {
        transform: translateY(40px);
        transition: opacity $secondary-duration $default-ease-out-fast,
                    transform $secondary-duration $default-ease-out-fast;

        &.show {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

.video-fullscreen .pt_product-details.animate-scroll .product-details-container .column-details {
  z-index: -1;
}
