.product-grid-block {
  display: flex;
  overflow: hidden;
  padding: 40px 0 85px;

  &.amsterdam-grid {
    .grid-heading {
      color: $white;
      font-size: 14px;
      letter-spacing: 2.4px;
      line-height: 18px;

      @include tablet {
        font-size: 40px;
        letter-spacing: 10.6px;
        line-height: 50px;
      }
    }
  }

  &.product-grid-block-dark,
  &.hommes-bg-color,
  &.hommes-bg-light-color {
    .grid-heading {
      color: $white;
    }

    .heading-h3,
    .heading-h10,
    .heading-h6,
    .promotion-alert {
      color: $white;
    }

    .btn:not(.btn-add-to-cart):not(.btn-send-to-custom) {
      @include tablet {
        border: 1px solid $white;
        color: $white;
      }

      &:hover,
      &:active {
        background-color: $white;
        color: $rit-dark;
      }

      &.btn-out-of-stock-disabled {
        color: $gray-medium;
      }
    }
  }

  &.collection-grid-primary-btn {
    .grid-load-more {
      .btn {
        background-color: $rit-black;
        border: 1px solid $rit-black;
        color: $white;

        &:hover,
        &:active {
          background-color: transparent;
          color: $rit-dark;
        }
      }
    }
  }

  &.collection-grid-secondary-btn {
    .grid-load-more {
      .btn {
        background-color: transparent;
        border: 1px solid $gray-disabled;
        color: $rit-black;

        &:hover,
        &:active {
          border: 1px solid $rit-black;
          color: $rit-dark;
          background-color: transparent;
        }
      }
    }
  }

  &.collection-grid-white-btn {
    .grid-load-more {
      .btn {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;

        &:hover,
        &:active {
          background-color: $white;
          color: $rit-dark;
        }
      }
    }
  }

  &.collection-grid-white-fill-btn {
    .grid-load-more {
      .btn {
        background-color: $white;
        border: 1px solid $white;
        color: $rit-black;

        &:hover,
        &:active {
          background-color: transparent;
          color: $white;
        }
      }
    }
  }

  &.collection-grid-banner-btn {
    .grid-load-more {
      .btn {
        background-color: $rit-black;
        border: 1px solid $rit-black;
        color: $white;

        &:hover,
        &:active {
          background-color: transparent;
          border: 1px solid $white;
        }
      }
    }
  }

  //Einstein block on PDP of customisable products of the Signature and Mansion collection
  &.product-grid-block-configurable {
    .grid-heading,
    .grid-subheading {
      text-transform: none;
      color: $white;
    }

    .grid-heading {
      margin-bottom: 12px;

      @include mobile-only {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 40px;
        max-width: 250px;
        margin: 0 auto 21px;
      }
    }

    .grid-subheading {
      margin: 0 auto 35px;
      max-width: 650px;
      align-self: center;

      @include mobile-only {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
        max-width: 255px;
        margin: 0 auto 38px;
      }
    }
  }

  &.gwp-white-content .item.gwp-grid-item {
    color: $white;

    .heading-h10,
    .heading-h6,
    .btn,
    .promotion-alert {
      color: $white;
    }

    .btn {
      @include tablet {
        border: 1px solid $white;
      }

      &:hover,
      &:active {
        background-color: $white;
        color: $rit-dark;
      }
    }
  }

  .inner-wrapper {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }

  .grid-heading {
    letter-spacing: 1.2px;
    text-transform: none;
    text-align: center;
    margin-bottom: 40px;
  }

  .grid-subheading {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    font-family: $font-body;
    max-width: 650px;
    margin: -28px auto 35px;
    text-transform: none;
    color: $white;
    text-align: center;
    align-self: center;
  }

  .grid-load-more {
    text-align: center;
    width: 100%;
  }

  h2.white,
  .grid-heading.white {
    color: $white;
  }

  @include mobile-only {
    .grid-heading {
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 24px;
      margin-bottom: 22px;
      padding: 0 20px;
    }

    .grid-subheading {
      margin-top: -1px;
      padding: 0 20px;
    }
  }

  @include tablet {
    padding: 60px 0 80px;

    .two-tile-horizontal {
      min-height: 680px;
    }
  }

  @include tablet-portrait {
    .grid-wrap {
      margin-left: 20px;
      width: calc(100% - 10px);

      .item {
        margin-bottom: 30px;
        width: calc(33.3333% - 30px);
      }
    }
  }

  @include tablet-landscape {
    .grid-wrap {
      margin-left: 30px;
      width: calc(100% - 20px);

      .item {
        margin-bottom: 40px;
        width: calc(33.3333% - 40px);
      }
    }
  }
}

// for ajax'ed grids in collection pages
.grid-placeholder {
  flex-direction: column;
  display: flex;
}

.grid-wrap {
  float: left;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include tablet {
    margin-bottom: 0;
    margin-left: 50px;
  }

  .product-giftcard-gif {
    height: 100%;
    width: 100%;
  }

  .item {
    background-color: $white;
    float: left;
    margin: 0 0 10px;
    text-align: center;
    text-transform: uppercase;
    width: calc(50% - 5px);
    z-index: 1;

    @include tablet {
      margin-bottom: 60px;
      width: calc(33.3333% - 74px);
    }

    // if tile will be revealed with js, then hide it on load
    &.reveal-tile {
      visibility: hidden;
    }

    &.transparent-bg {
      background-color: rgba($white, 0);
    }
  }

  .product-giftcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $rit-sandy-dark;
    border: 5px solid $white;

    @include mobile-only {
      min-height: 400px;
    }

    @include tablet {
      min-height: 500px;
    }
  }

  .product-giftcard-textbox {
    position: absolute;
    top: 70%;
    text-align: center;

    @include mobile-only {
      top: 65%;
      bottom: 15%;
    }

    @include tablet-only {
      top: 60%;
    }
  }

  .product-giftcard-text {
    color: $rit-black;
    text-transform: uppercase;
    line-height: 25px;
    font-size: 16px;
    letter-spacing: 5px;
    max-width: 144px;
    margin: 0 auto 5px;

    @include mobile-only {
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 22px;
    }

    @include desktop {
      max-width: 230px;
    }
  }

  .product-giftcard-subtext {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: $rit-black;
    text-transform: none;
    max-width: 230px;
    padding: 0 10px;

    @include mobile-only {
      font-size: 12px;
      line-height: 15px;
    }

    @include desktop {
      max-width: 250px;
    }
  }

  .item-spotlight {
    width: 100%;
  }

  li.item {
    list-style: none;
  }

  .btn {
    min-width: 10px;
  }

  .product-tile {
    .product-image {
      background-color: $snow-white;
      position: relative;
      padding: 20px 12px;

      @include desktop {
        padding: 40px;
      }

      &.product-image-fullwidth {
        padding: 0;

        img {
          width: 100%;
        }
      }

      img {
        display: block;
        max-width: 100%;
      }
    }

    .promotional-label {
      background-color: $white;
      bottom: 8px;
      font-size: 12px;
      left: 0;
      letter-spacing: 0.3px;
      line-height: 16px;
      text-transform: none;
      padding: 8px 16px;
      position: absolute;
      width: auto;

      @include tablet {
        bottom: 0;
      }
    }

    .product-details-content {
      position: relative;
      padding: 20px 12px;

      @include mobile-only {
        align-items: flex-start;
        text-align: left;
        flex-direction: row;
        flex-wrap: wrap;

        > .base-price-block {
          display: block;
        }
      }

      @include tablet {
        padding: 20px 12px;
      }

      @include desktop {
        padding: 32px 40px;
      }

      .product-title {
        max-width: 100%;
      }

      .product-variations ~ form {
        .btn-select-option {
          margin-top: 0;
        }
      }
    }

    .short-description {
      color: $gray-medium;
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: 20px;
      opacity: 1;
      margin-bottom: 10px;

      @include mobile-only {
        width: 100%;
      }

      @include tablet {
        letter-spacing: 0.5px;
        line-height: 25px;
        margin-bottom: 12px;
        padding: 0 10px;
      }
    }

    .base-price-block {
      @include mobile-only {
        border-top: 1px solid rgba($gray-suva, 0.3);
        margin-top: 20px;
        text-align: left;
        width: 100%;

        .unit-base-price {
          color: $gray-suva;
          font-size: 11px;
          letter-spacing: 0;
          text-transform: none;

          &.legal-note {
            margin-top: 8px;
          }
        }
      }

      @include tablet {
        display: none;
        margin-bottom: 3px;

        .unit-base-price {
          font-size: 11px;
          margin-top: 13px;
          text-transform: none;

          &.legal-note {
            margin-top: 9px;
          }
        }
      }
    }

    &.product-configurable {
      .add-to-cart-flexed {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .product-add-to-cart {
        margin-left: 16px;
        margin-bottom: 0;

        @include mobile-only {
          display: none;
        }
      }

      .btn-add-to-cart {
        font-size: 0;
        height: 43px;
        padding: 0;
        position: relative;
        text-align: center;
        width: 43px;

        &::before {
          @include icon-styles;
          content: icon-char(cart-plus);
          display: flex;
          font-size: 16px;
          justify-content: center;
          margin-left: 2px;
          margin-top: -5px;
        }

        @include desktop {
          min-width: 10px;
        }
      }
    }

    .add-to-cart-inner {
      @include mobile-only {
        display: flex;
      }
    }

    .btn-wrapper-send {
      margin-top: 20px;

      .btn {
        @include mobile-only {
          display: block;
          margin-left: auto;
          min-width: 0;
          background-color: $white;
          border: 1px solid $gray-disabled;
          border-radius: 50%;
          font-size: 0;
          letter-spacing: 1.5px;
          height: 36px;
          padding: 0;
          width: 36px;
          color: $rit-black;

          &.send-icon-visible {
            @include mobile-only {
              background-color: $rit-black;
              color: $white;
            }
          }

          .icon-send {
            display: none;
          }

          .spinner-visible {
            transform: translate(2px, 1px);

            :nth-child(1) {
              margin-right: 7px;
            }

            :nth-child(2) {
              margin-right: 0;
            }

            :nth-child(3) {
              margin-right: -7px;
            }

            span {
              width: 5px;
              height: 5px;
            }
          }

          &:not(.button-loading) {
            &::before {
              @include icon-styles;
              content: icon-char(send);
              display: block;
              font-size: 18px;
              margin-top: 2px;
            }
          }
        }

        @include mobile-only {
          margin: 0 0 0 5px;
        }

        @include desktop {
          min-width: 220px;
        }
      }

      @include mobile-only {
        margin: 0;
      }
    }
  }

  .giftcard-grid-asset {
    .product-price {
      @include mobile-only {
        font-size: 14px;
        letter-spacing: 1.3px;
        line-height: 18px;
        text-align: left;
        width: calc(100% - 36px);
      }

      @include tablet {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 19px;
        margin-bottom: 28px;
        width: 100%;
      }
    }
  }

  .promotion-wrapper {
    max-width: 100%;

    @include mobile-only {
      margin: -4px auto 10px auto;
    }

    @include tablet {
      margin: -4px 0 12px 0;
    }

    .promotion-callout .callout-message {
      @include callout;

      background-color: $red-warning;
      border-radius: 0;
      color: $white;
      font-size: 12px;
      left: 0;
      letter-spacing: 0.3px;
      line-height: 16px;
      padding: 8px 16px;
      position: absolute;
      width: auto;
  }

    .callout-message-with-new-label {
      bottom: 48px;
  }

    .callout-message-no-new-label{
      bottom: 8px;
  }

}

  .product-title {
    @include mobile-only {
      width: 100%;
    }

    .product-name-link {
      display: block;
      margin-bottom: 13px;
      pointer-events: none;
      text-decoration: none;

      @include tablet {
        margin-bottom: 6px;
      }
    }

    .the-ritual-of-title {
      color: $rit-copper;
      font-size: 10px;
      letter-spacing: 1.5px;
      line-height: 14px;
      margin-bottom: 9px;

      @include tablet {
        font-size: 11px;
        letter-spacing: 1.5px;
        line-height: 18px;
        margin-bottom: 11px;
      }
    }

    .product-name {
      color: $rit-black;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
      margin: 0;

      @include tablet {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
        margin: 0;
      }
    }
  }

  .promotion-discount-text {
    background-color: #fcecef;
    color: $crimson;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.4px;
    line-height: 13px;
    margin: 5px 0 6px;
    padding: 4px 9px 3px;

    @include tablet {
      margin: -4px 0 8px;
    }
  }

  .product-user-actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include mobile-only {
      padding-top: 3px;
      flex-wrap: nowrap;
    }

    .product-price {
      margin: 0 auto 0 0;

      @include mobile-only {
        font-size: 14px;
        letter-spacing: 1.3px;
        line-height: 18px;
        max-width: 100px;
        text-align: left;
        width: calc(100% - 36px);

        .base-price-block {
          display: none;
        }
      }

      @include tablet {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 19px;
        margin-bottom: 28px;
        width: 100%;

        .base-price-block {
          display: block;
        }
      }
    }

    .product-add-to-cart {
      margin: 0;
    }

    .out-of-stock-block {
      margin-top: 5px;
      width: 100%;

      @include mobile-only {
        justify-content: flex-start;
      }

      @include tablet {
        margin-top: 10px;
      }
    }

    .btn-out-of-stock {
      border: 0;
      color: $rit-copper-dark;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 26px;
      padding: 0;
      text-align: left;
      text-decoration: underline;
      text-transform: none;

      @include mobile-only {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.88px;
      }

      @include tablet {
        &:hover {
          border: 0;
          text-decoration: none;
        }
      }
    }

    .btn-out-of-stock-disabled {
      background-color: transparent;
      border: 0;
      color: $gray-medium;
      letter-spacing: 0;
      padding: 0;
      text-align: left;
      text-transform: none;
      width: 100%;

      &::before,
      &::after {
        content: '';
        clear: both;
        display: table;
      }

      @include tablet {
        font-size: 14px;
        letter-spacing: 1px;
        margin: 3px 0 14px;
        text-align: center;
      }
    }

    &.form-wide {
      @include mobile-only {
        .rituals-form,
        form {
          width: 100%;
        }
      }

      @include tablet {
        .add-to-cart-inner {
          flex-wrap: wrap;
        }
      }
    }

    &.product-user-actions-pg {
      .btn-customize {
        @include desktop {
          .icon-pencil-configurable {
            display: none;
          }
        }
      }
    }
  }

  .form-buttons {

    &:not(.btn--disabled) {
      .btn-add-to-cart {
        background-color: transparent;
        border: 1px solid $gray-disabled;
        color: $rit-black;

        @include tablet {
          padding: 16px 23px 12px;
        }

        &:hover,
        &:active {
          @include desktop {
            border: 1px solid $rit-black;
          }
        }
      }
    }

    &.btn--disabled {
      .btn-add-to-cart {
        cursor: pointer;
      }
    }

    .btn-add-to-cart {
      @include mobile-only {
        border-color: $gray-suva;
        border-radius: 50%;
        font-size: 0;
        letter-spacing: 1.5px;
        height: 36px;
        padding: 0;
        width: 36px;

        &:not(.btn-select-size)::before {
          @include icon-styles;
          content: icon-char(cart-plus);
          display: block;
          font-size: 16px;
          margin-left: 2px;
        }
      }

      @include tablet {
        font-size: 12px;
        letter-spacing: 2px;
      }

      @include desktop {
        min-width: 220px;
      }
    }
  }

  a.btn-add-to-cart {
    @include mobile-only {
      line-height: 32px;
    }
  }

  .btn-customize {
    background-color: transparent;
    border: 1px solid $gray-disabled;
    color: $rit-black;
    position: relative;

    &:not(.keep-width) {
      @include mobile-only {
        border: 0;
        height: 32px;
        width: 32px;
        padding: 0;

        .text,
        span:not(.icon-pencil-configurable) {
          display: none;
        }

        .icon-pencil-configurable {
          @include pencil-black-icon(32px, 32px);
        }
      }

      @include tablet {
        height: 43px;
        line-height: 45px;
        padding: 0 20px;

        .text,
        span:not(.icon-pencil-configurable) {
          display: none;
        }

        .icon-pencil-configurable {
          @include pencil-black-icon(20px, 20px);
          margin: 0 0 -6px;
        }
      }

      @include desktop {
        min-width: 60%;

        &:hover,
        &:active {
          border: 1px solid $rit-black;
        }

        .text,
        span:not(.icon-pencil-configurable) {
          display: inline-block;
          position: relative;
          top: -1px;
        }
      }
    }

    &.keep-width {
      min-width: 60%;

      &:hover,
      &:active {
        border: 1px solid $rit-black;
      }

      .text,
      span:not(.icon-pencil-configurable) {
        display: inline-block;
        position: relative;
        top: -1px;
      }

      .icon-pencil-configurable {
        @include pencil-black-icon(20px, 20px);
        margin: 0 0 -6px;
      }
    }
  }

  .btn-select-size {
    @include mobile-only {
      vertical-align: top;

      .icon-ruler {
        display: block;
        font-size: 20px;
        line-height: 34px;
        width: 34px;

        &::before {
          @include icon-styles;
          content: icon-char(ruler);
        }
      }
    }
    @include tablet {
      .icon-ruler {
        display: none;
      }
    }
  }

  .rituals-form,
  form {
    padding: 0 7px;
    width: 100%;

    @include mobile-only {
      margin: 0 0 0 auto;
      padding: 0;
      width: auto;
    }

    @include desktop {
      margin-left: -20px;
      margin-right: -20px;
      padding: 0;
      width: calc(100% + 40px);
    }
  }

  .view-full-details-link {
    display: none;
  }
}

// collection grid
.collection-grid-block {
  background-color: $rit-sandy-bg;
  display: flex;
  padding: 20px 0;
  position: relative;

  .slick-track {
    display: flex;
  }

  .inner-wrapper {
    padding: 0 10px;
  }

  .grid-wrap {
    margin-bottom: 40px;

    .item {
      padding: 0;
      text-transform: none;
      width: calc(50% - 5px);
      margin-bottom: 10px;
    }

    .text-holder {
      padding: 24px 25px 17px;
      width: 100%;

      &.black-bg-color {
        background-color: $rit-black;
      }

      h3,
      h2:not(.heading-h10) {
        font-size: 24px;
        letter-spacing: 1.2px;
        line-height: 30px;
        text-transform: none;
        margin-bottom: 12px;
      }

      p {
        line-height: 24px;
        margin-bottom: 11px;
        letter-spacing: 0.5px;
      }

      .btn {
        margin: 0 8px 8px;
      }
    }

    .image-holder {
      display: block;
      margin-bottom: 0;

      img {
        display: block;
        width: 100%;
      }
    }

    .image-link-wrapper {
      position: relative;

      .image-holder-pd {
        @include mobile-only {
          height: 100%;
        }

        img {
          @include mobile-only {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .image-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  @include mobile-only {
    overflow: hidden;

    .image-link-wrapper {
      height: 230px;
      margin-top: auto;
      overflow: hidden;
      width: 100%;
    }

    .slider-bar {
      height: 4px;
      margin: 0 auto;
      max-width: 300px;
      overflow: hidden;
    }

    .slider-dots {
      left: auto;
      max-width: none;
      transform: none;
      width: 300px;

      ul {
        padding-left: 0;
      }
    }

    .grid-wrap {
      .item {
        left: auto !important;
        opacity: 1 !important;
        position: relative !important;
        top: auto !important;
        transform: translateY(0) scale(1) !important;
      }
    }

    &:not(.drag-list) .grid-wrap {
      overflow: visible;

      &.grid-cards {
        width: calc(100% + 40px);
        margin: 0 -20px;

        .item {
          margin-bottom: 40px;
          text-transform: none;
          width: 100%;
        }
      }
    }

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      display: flex;
      height: auto;
      padding: 0 5px;

      > div {
        display: flex;
        width: 100%;
      }
    }

    .grid-item-inner {
      padding-bottom: 230px;

      &.grid-item-content {
        padding: 0;
      }
    }

    .image-holder {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 230px;
      overflow: hidden;
      width: 100%;

      img {
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @include tablet {
    padding: 30px 0 0;

    .inner-wrapper {
      max-width: 1360px;
    }

    .slider-bar {
      display: none !important;
    }

    .grid-wrap {
      margin: 0;

      .item {
        margin-bottom: 30px;
        width: calc(50% - 30px);
      }

      .text-holder {
        padding: 41px 40px 27px;

        h3,
        h2:not(.heading-h10) {
          font-size: 40px;
          letter-spacing: 1.6px;
          line-height: 50px;
          margin-bottom: 21px;
        }

        p {
          line-height: 28px;
          margin: 0 auto 16px;
          max-width: 388px;
          letter-spacing: 1px;
        }
      }
    }

    .slider-dots {
      display: none;
    }
  }
}

.collection-grid-block.drag-list {
  @include mobile-only {
    margin-bottom: 0;
    padding: 0;

    .inner-wrapper {
      padding: 0;
    }

    .grid-wrap {
      float: none;
      height: auto !important;
    }

    .drag-list-body {
      padding: 31px 25px 35px;

      .item {
        opacity: 1 !important;
      }
    }

    .item {
      background-color: transparent;
      float: none;
      left: auto !important;
      opacity: 0 !important;
      padding: 0 5px;
      top: auto !important;
      width: calc(100vw - 15px);
    }

    .grid-item-inner {
      background-color: $white;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .image-holder {
      left: 5px;
      right: 5px;
      width: auto;

      img {
        width: auto;
      }
    }
  }
}

.product-grid-block + .collection-grid-block {
  padding-top: 0;
  margin-top: -100px;

  @include tablet {
    margin-top: -30px;
  }
}

// subcollection grid
.subcollection-grid-section,
.collection-grid-section {
  padding-bottom: 30px;

  .grid-heading {
    color: $white;
  }

  .product-grid-block {
    background: none;

    &.product-filter-block .product-filters li .filter-item {
      color: $white;

      &::after {
        background-color: $white;
      }

      &:hover,
      &.selected {
        color: $white;
      }
    }
  }
}

.zoomed-in {
  @include mobile-only {
    .collection-grid-block .slider-dots {
      bottom: 20px;
      display: block;
      max-width: 300px;

      .slick-dots,
      button {
        height: 4px;
      }
    }
  }
}

.general-grid-block {
  overflow: hidden;
  padding: 50px 0 20px;

  .grid-heading {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 2px;
      margin-bottom: 40px;
    text-align: center;
    text-transform: initial;

    @include mobile-only {
      font-size: 20px;
      line-height: 20.78px;
      letter-spacing: 1px;
      margin-bottom: 40px;
    }
  }

  @include tablet {
    padding: 80px 0 20px;

    .grid-heading {
      margin-bottom: 60px;
    }
  }
}

.grid-decorative-tile {
  padding: 0;
  position: relative;
  width: 100%;
  color: $white;

  .background-video {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .media-holder,
  .image-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .media-holder {
    display: none;
    min-height: 278px;
    width: 100%;
  }

  .image-holder {
    margin-bottom: 0;
  }

  .text-holder {
    min-height: 278px;
    position: relative;
    z-index: 1;
    padding: 40px 20px 38px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .decorative-tile-subtitle {
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 1.5px;
    }

    .icon-play {
      display: block;
      margin: 25px 0 6px;
      font-size: 30px;
    }

    .decorative-tile-title {
      text-transform: none;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 22px;
      margin-bottom: 5px;
    }

    .decorative-tile-description {
      text-transform: none;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $white;
      margin-bottom: 4px;

      @include mobile-only {
        display: none;
      }
    }

    .time {
      font-size: 12px;
      font-family: $font-header;
      text-transform: uppercase;

      .icon-ico-clock {
        font-size: 13px;
        margin-right: 12px;
      }
    }

    .video-link {
      color: $white;
      font-family: $font-body;
      display: block;
      margin: 14px auto 0;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      text-transform: none;
      text-decoration: underline;
    }
  }

  .decorative-tile-link,
  .decorative-tile-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }

  @include tablet {
    .media-holder {
      min-height: 412px;
      display: block;

      + .image-holder {
        display: none;
      }
    }

    .text-holder {
      min-height: 412px;
      padding: 40px;

      .decorative-tile-subtitle {
        font-size: 16px;
        letter-spacing: 5px;
      }

      .icon-play {
        margin: 28px 0 6px;
        font-size: 40px;
      }

      .decorative-tile-title {
        font-size: 24px;
        letter-spacing: 0.5px;
        line-height: 33px;
        margin-bottom: 2px;
      }

      .video-link {
        margin-top: 14px;
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 28px;
        text-transform: uppercase;

        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  @include desktop {
    .text-holder {
      padding: 63px 52px 55px;
    }
  }
}

.collection-fifth-template .collection-grid-section {
  padding-bottom: 0;

  .product-filter-block {
    padding-top: 80px;

    .grid-heading {
      margin-bottom: 56px;
    }

    .product-filters li {
      margin-bottom: 30px;
      padding: 0 25px;

      .filter-item {
        font-family: $font-header;
        font-size: 16px;
        letter-spacing: 4.3px;
        line-height: 20px;
      }
    }
  }
}

.jing-bg-color {
  .ritual-bg-color {
    background-color: $ritual-jing;

    .grid-heading {
      color: $white;
    }

    &.product-filter-block .product-filters li .filter-item {
      color: $white;

      &:hover,
      &.selected {
        color: $white;
      }

      &::after {
        background-color: $white;
      }
    }
  }
}

// Horizontal grid block
.horizontal-grid-block {
  padding: 20px 0;

  &.black-bg {
    background-color: $rit-black;
  }

  &.white-bg {
    background-color: $white;
  }
}

.horizontal-grid-block-inner {
  @include mobile-only {
    .btn:first-of-type {
      margin-bottom: 16px;
    }
  }

  @include tablet {
    display: flex;
  }

  &.reversed {
    @include tablet {
      flex-direction: row-reverse;
    }
  }

  &.white-bg {
    .item-text {
      background-color: $white;
    }

    p {
      color: $rit-black;
    }
  }

  &.black-bg {
    .item-text {
      background-color: $rit-black;
    }

    p,
    .heading-h2 {
      color: $white;
    }
  }

  .item {
    @include tablet {
      flex: 1;
    }

    .image-holder {
      height: 100%;
    }
  }

  .item-text {
    &:not(.no-mobile-overlap) {
      @include mobile-only {
        margin: -70px auto 0;
        position: relative;
        text-align: center;
        width: 90%;
      }
    }

    &.centered {
      text-align: center;

      @include tablet {
        align-content: center;
        align-items: center;
        display: flex;
      }
    }

    .text-holder {
      @include mobile-only {
        padding: 24px;
      }

      @include tablet {
        padding: 64px;
      }
    }

    .icon {
      color: $rit-copper;
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  .heading-h2 {
    font-size: 30px;
    letter-spacing: 1.4px;
    margin-bottom: 30px;
    text-transform: initial;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  p {
    margin-bottom: 25px;
  }

  .image-link-wrapper {
    height: 100%;

    .image-holder {
      height: 100%;
      position: relative;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;

        @include mobile-only {
          display: block;
        }
      }
    }
  }

  .btn {
    @include tablet {
      margin-right: 15px;
    }
  }
}

// talisman grid block
.talisman-grid-block {
  background-color: $rit-sandy-bg;
  display: flex;
  flex-direction: column;

  .grid-title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin: 60px auto 40px;
    max-width: 372px;
    text-align: center;
    text-transform: none;

    @include mobile-and-tablet {
      font-size: 24px;
      letter-spacing: 0.96px;
      margin: 34px auto 40px;
      max-width: 260px;
    }
  }

  .grid-card-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 80px;

    @include mobile-and-tablet {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  .grid-card {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 760px;
    width: 620px;

    &:last-child {
      margin-left: 30px;
    }

    @include mobile-and-tablet {
      width: 346px;
      height: auto;
      margin: 0 auto 30px;

      &:last-child {
        margin: auto;
      }
    }
  }

  .grid-img-holder {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    max-height: 360px;
    padding-top: 20px;

    @include mobile-and-tablet {
      margin-bottom: 30px;
      max-height: 270px;
    }

    .grid-img {
      height: -webkit-fill-available;
      max-height: inherit;
      max-width: 100%;
    }
  }

  .grid-card-title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 28px;
    letter-spacing: 1.4px;
    line-height: 32px;
    margin: 0 auto 20px;
    max-width: 280px;
    text-align: center;
    text-transform: none;

    @include mobile-and-tablet {
      font-size: 22px;
      letter-spacing: 0.55px;
      line-height: 30px;
      margin: 0 35px 20px;
    }
  }

  .grid-card-text {
    color: $rit-black;
    font-family: $font-body;
    font-size: 16px;
    letter-spacing: 0.57px;
    line-height: 26px;
    margin: 0 auto 30px;
    max-width: 450px;
    text-align: center;

    @include mobile-and-tablet {
      font-size: 14px;
      letter-spacing: 0.56px;
      line-height: 22px;
      margin: 0 30px 32px;
    }
  }

  .grid-card-btn {
    color: $white;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    margin: 0 auto 60px;
    max-width: 240px;
    padding: 15px 45px;
    text-align: center;

    @include mobile-and-tablet {
      letter-spacing: 3.14px;
      line-height: 14px;
      margin: 0 auto 40px;
    }
  }

  .grid-card-link {
    color: $rit-black;
    font-family: $font-body;
    letter-spacing: 2px;
    margin: 0 auto 60px;

    @include mobile-and-tablet {
      margin: 0 auto 40px;
    }
  }
}
