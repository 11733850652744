.karma-usp-block {
  padding: 80px 0 30px;

  .usp-header {
    font-size: 40px;
    letter-spacing: 1.8px;
    line-height: 48px;
    margin-bottom: 28px;
    text-align: center;
    text-transform: initial;

    @include mobile-only {
      font-size: 32px;
      letter-spacing: 1.4px;
      line-height: 40px;
      margin-bottom: 16px;
    }
  }

  .usp-subheader {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    margin: 0 auto 80px;
    max-width: 890px;
    text-align: center;

    @include mobile-only {
      margin-bottom: 50px;
    }
  }

  .usp-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mobile-only {
      align-items: center;
      flex-direction: column;
    }
  }

  .usp-component {
    margin-bottom: 30px;
    padding: 0 20px;
    text-align: center;
    width: 33%;

    @include mobile-only {
      width: 300px;
    }

    &:nth-last-child(n+4), &:nth-last-child(n+4) ~ .usp-component {
      padding: 0 10px;
      width: 25%;

      @include mobile-only {
        width: 300px;
      }

      @include tablet-only {
        margin-bottom: 40px;
        padding: 0 20px;
        width: 50%;
      }
    }
  }

  .usp-img-box {
    border-radius: 50%;
    height: 146px;
    margin: 0 auto 40px;
    overflow: hidden;
    width: 146px;
  }

  .usp-img {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .usp-text {
    font-size: 16px;
    letter-spacing: 0.5;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .usp-link {
    color: $rit-copper;
    font-size: 14px;
  }
}
