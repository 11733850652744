.generic-modal-container {
  align-items: center;
  background: $rit-sandy-light;
  box-shadow: 0 4px 6px -3px $gray-medium;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: center;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 24px;
  min-height: 150px;
  opacity: 1;
  padding: 35px 20px 25px;
  position: fixed;
  text-align: center;
  top: -1px;
  width: 100%;
  z-index: $modal-window-index;

  @include desktop {
    box-shadow: none;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 16px;
    height: auto;
    justify-content: flex-start;
    left: 50%;
    letter-spacing: 1px;
    line-height: 28px;
    max-width: 480px;
    overflow: auto;
    padding: 60px 70px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: none;
  }

  &.generic-modal-container-small {
    padding: 45px 50px;
  }

  &.modal-large.boxberry-widget-wrapper {
    max-width: initial;

    .content-wrapper {
      width: 100%;
    }
  }

  &.modal-large {
    background-color: $white;
    padding: 40px 30px;
    max-height: 90%;

    @include tablet-landscape {
      max-width: 600px;
      width: 90% !important;
      padding: 40px 35px 50px;
    }

    @include desktop {
      max-width: 600px;
      width: 90% !important;
      padding: 40px 35px 50px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
    }

    h2 {
      font-size: 24px;
    }
  }

  &.easter-modal {
    max-width: 440px;
    padding: 56px 38px;
    background-color: $white;

    @include mobile-and-tablet {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .close-modal {
      font-size: 25px;
    }

    .icon-AR {
      font-size: 26px;
      color: $rit-copper-dark;
    }

    .heading-h4 {
      font-size: 24px;
      text-transform: none;
      margin: 14px 0 20px;
    }

    .qr-code-phara {
      color: $rit-black;
      margin-bottom: 0;
    }
  }

  &.centered-on-mobile {
    @include mobile-only {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100vw - 40px);
    }
  }

  &.modal-full-width {
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    padding: 35px 25px;

    @include mobile-and-tablet {
      &::before {
        content: '';
        position: absolute;
        top: 17px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 48px;
        background: $gray-gainsboro;
      }
    }

    @include tablet-landscape {
      max-width: none;
      width: 100% !important;
    }

    @include desktop {
      max-width: none;
      width: 100% !important;
      flex-direction: row;
      padding: 30px 35px;
    }

    .text-holder {
      @include desktop {
        text-align: left;
      }

      h2.heading-large {
        font-size: 20px;
        letter-spacing: 1.2px;
        line-height: 24px;
        margin: 6px auto 18px;

        @include desktop {
          max-width: none;
          font-size: 25px;
          letter-spacing: 2px;
          line-height: 35px;
          margin: 0 auto 10px;
        }
      }

      p {
        @include mobile-only {
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 24px;
          margin: 0 auto 22px;
        }

        @include desktop {
          text-align: left;
          max-width: none;
          margin-bottom: 10px;
        }
      }
    }

    .row {
      @include mobile-only {
        width: 100%;
      }
    }

    .cta-holder {
      @include desktop {
        width: 40%;
      }
    }

    .btn {
      @include mobile-only {
        width: 100%;
        max-width: none;
      }
    }

    .close-modal {
      display: none;
    }
  }

  &.threshold-warning {
    .heading-h4 {
      margin-bottom: 15px;
      font-size: 20px;
      text-transform: initial;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &.talisman-certificate {
    max-width: 315px;
    background-color: $white;
    padding: 14px;
    overflow: visible;
    left: 50%;
    position: fixed;
    top: 45%;
    transform: translate(-50%, -50%);
    z-index: 100001;

    & ~ .generic-modal-overlay {
      z-index: 100000;
    }

    @include mobile-small {
      max-width: 300px;
    }

    @include tablet {
      top: 50%;
      max-width: 440px;
      padding: 20px;
    }

    .close-modal {
      top: auto;
      bottom: -68px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      background: $white;
      border-radius: 50%;
      height: 48px;
      width: 48px;

      @include mobile-small {
        bottom: -75px;
      }

      @include tablet {
        height: 60px;
        width: 60px;
        bottom: -95px;
      }

      @include desktop {
        bottom: -110px;
      }
    }

    .content-wrapper {
      border: 1px solid #b09043;
      padding: 20px 37px;
      max-width: 100%;

      @include mobile-only {
        padding: 20px 0 16px;
      }

      @include mobile-small {
        padding: 5px 0;
      }
    }

    .ico {
      margin: 9px 0 0;

      @include tablet {
        margin-bottom: 12px;
      }
    }

    .text-holder {
      @include mobile-only {
        padding: 0 22px;
      }

      h2.heading-large {
        letter-spacing: 1.5px;

        @include mobile-only {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1.28px;
          margin-bottom: 8px;
        }
      }
    }

    .talisman-certificate-content {
      padding: 0 22px;

      @include tablet {
        padding: 0;
      }

      & > p {
        margin-bottom: 23px;
      }

      label {
        display: block;
        text-align: left;
        font-size: 14px;
        margin-bottom: -4px;

        @include mobile-only {
          margin-bottom: -2px;
        }
      }

      input {
        margin: 0 0 10px;

        @include mobile-only {
          margin: 0 0 25px;
        }

        @include mobile-small {
          margin: 0 0 10px;
        }
      }

      p {
        @include mobile-only {
          font-size: 12px;
          line-height: 17px;
        }
      }

      .disclaimer {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 1.3px;

        @include mobile-small {
          margin-bottom: 0;
        }
      }

      .error {
        font-size: 11px;
        font-weight: 700;
        line-height: 13px;
        letter-spacing: 1px;
        margin: -3px 0 4px 0;
        display: block;
        text-align: left;

        & + input {
          border-top: 1px solid $red-error;
        }
      }
    }

    .cta-holder {
      margin-top: 28px;

      @include mobile-only {
        margin-top: 16px;
      }

      .row:nth-of-type(2) {
        .btn {
          border: 0;
          margin-top: -18px;

          @include mobile-only {
            margin-top: -24px;
          }
        }
      }
    }
  }

  &.flyout-open {
    z-index: 1000;

    & ~ .generic-modal-overlay {
      z-index: $modal-window-index;
    }
  }

  .close-modal {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 16px;
    font-size: 16px;
    z-index: 901;
  }

  .modal-close-icon {
    color: $black;
    font-size: 25px;
    top: 20px;
    right: 21px;
  }

  .modal-overlay-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;

    @include tablet {
      display: none;
    }
  }

  .image-holder {
    max-width: 90px;
    margin: 0 auto;

    @include desktop {
      max-width: 130px;
    }

    img {
      max-width: 100%;
    }

    & + .text-holder {
      @include mobile-and-tablet {
        width: calc(100% - 100px);
        padding-left: 21px;
        margin: -8px 0 0;
        max-width: 368px;

        .text-large {
          text-align: left;
        }
      }
    }
  }

  .text-holder {
    width: 100%;

    p {
      max-width: 438px;
      margin: 0 auto 10px;
      text-align: center;

      @include tablet {
        margin-bottom: 19px;
      }

      &.text-width-limiter {
        width: 270px;
      }

      &.text-color-black {
        color: $black;
      }

      &.text-large {
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 22px;
        margin-bottom: 0;
        max-width: 450px;
        font-family: $font-header;

        &:first-of-type {
          margin-bottom: 3px;
        }

        @include desktop {
          font-size: 20px;
          margin-bottom: 18px;
          text-align: center;
          line-height: 30px;
        }
      }
    }

    h2 {
      &.heading-large {
        font-size: 30px;
        letter-spacing: 4px;
        line-height: 40px;
        max-width: 310px;
        color: $black;
        text-transform: none;
        width: 100%;
        font-family: $font-header;
        margin: 6px auto 20px;

        @include tablet {
          max-width: 400px;
          margin: 0 auto 12px;
        }
      }

      &.heading-large-narrow {
        letter-spacing: 0.5px;
      }

      &.heading-small {
        color: $rit-copper-dark;
        font-size: 11px;
        letter-spacing: 1.5px;
        line-height: 24px;
        margin-bottom: 4px;
        text-align: center;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 282px;
    margin: 0 auto;

    @include tablet {
      width: 100%;
    }

    &:first-of-type:not(:only-child) {
      .link,
      .link--large,
      .btn {
        margin: 27px 0;

        @include tablet {
          margin: 20px 0 18px;
        }
      }
    }

    &:nth-of-type(2) {
      @include mobile-only {
        .flag-wrapper {
          align-items: flex-start;
        }
      }
    }

    &.row-full-width {
      max-width: none;
      width: 100%;
    }

    &.row-custom-width {
      width: 350px;
      @include mobile-only {
        width: 100%;
      }
    }

    &.center-align {
      justify-content: center;
    }

    &.left-align {
      justify-content: flex-start;
    }
  }

  .flag-wrapper {
    display: flex;
    align-items: center;

    .flag {
      display: flex;
      align-items: center;
      margin-right: 12px;

      @include tablet {
        margin-right: 11px;
      }

      img {
        width: 24px;
        height: 18px;
      }
    }
  }

  .cta-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.cta-holder-row {
      flex-direction: row;

      .row {
        margin: 0 5px;
        width: calc(50% - 5px);

        &:first-of-type:not(:only-child) {
          .link,
          .link--large,
          .btn {
            margin: 20px 0 0;

            @include tablet {
              margin: 20px 0 0;
            }

            @include desktop-only {
              margin: 20px 0;
            }
          }
        }

        .link,
        .link--large,
        .btn {
          margin: 20px 0 0;

          @include tablet {
            margin: 20px 0 0;
          }

          @include desktop-only {
            margin: 20px 0;
          }
        }

        @include tablet {
          margin: 0 10px;
          width: auto;
        }
      }

      button {
        width: 100%;
        min-width: 0;

        @include tablet {
          min-width: 220px;
        }
      }
    }
  }

  .hide-on-mobile {
    @include mobile-only {
      display: none;
    }
  }

  .hide-on-mobile-and-tablet {
    @include mobile-and-tablet {
      display: none;
    }
  }

  .btn {
    min-width: 180px;

    &:first-of-type:not(:only-child) {
      margin-bottom: 15px;
    }
  }

  .link {
    font-size: 10px;
    line-height: 14px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .link--large {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: none;
    line-height: 20px;
    text-align: left;

    @include tablet-landscape {
      font-size: 14px;
      letter-spacing: 0.5px;
    }

    @include desktop {
      font-size: 14px;
      letter-spacing: 0.5px;

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  .select-box-wrapper {
    width: 100%;
    padding: 10px 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-end;
  }

  .gwp-select-box {
    margin: 0 auto;
    height: 30px;
    width: 30px;
  }

  .verification-message {
    margin: 25px auto 0;

    @include tablet {
      margin: 30px auto -13px;
      max-width: 100%;
    }
  }

  .gwp-radio--hidden {
    &:checked {
      position: relative;

      ~.product-image {
        background-color: $white;
      }

      ~.select-box-wrapper {
        background-color: $white;

        .gwp-select-box {
          border-radius: 50%;
          background-color: $rit-copper-dark;
          border: 1px solid $rit-copper-dark;

          &::after {
            @include icon-styles;
            background-color: transparent;
            color: $white;
            content: icon-char(check);
            display: block;
            font-size: 13px;
            height: 6px;
            left: 9px;
            position: absolute;
            top: 5px;
            transform: translate(-50%, -50%);
            width: 6px;

            @include desktop-only {
              top: 3px;
            }
          }
        }
      }
    }
  }

  .bonus-product-list-slider.drag-list {
    @include mobile-only {
      max-width: 100vw;
    }

    @include mobile-and-tablet {
      margin-bottom: 40px;
      width: 100% !important;
    }

    .drag-list-scroll {
      @include mobile-and-tablet {
        // sass-lint:disable-block no-misspelled-properties
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @include tablet {
        visibility: visible;
      }

      @include tablet-portrait {
        max-width: none;
      }

      @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 520px;
        width: 100%;
        flex-wrap: wrap;
        margin: 25px auto 20px;
        padding-bottom: 5px;
      }
    }

    .drag-list-body {
      @include tablet-only {
        margin: 0 auto;
        min-width: 100%;
        justify-content: center;
      }
    }
  }

  .bonus-product-list-slider-item {
    padding: 5px;
    width: 210px;

    @include mobile-only {
      align-items: center;
      display: flex;
      justify-content: center;
      width: calc(100vw - 120px);
    }

    @include mobile-and-tablet {
      padding: 20px 5px 10px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    label {
      background-color: $white;
      box-shadow: 0 5px 10px rgba($rit-black, 0.1);
      display: flex;
      flex-direction: column;
      height: 100%;
      cursor: pointer;
      padding: 17px 35px 5px;

      @include desktop {
        padding: 32px 15px 5px;
      }
    }

    .product-name {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 22px;
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 11px;

      @include desktop {
        margin-bottom: 22px;
      }
    }

    .product-image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;

      img {
        max-width: 100%;

        @include desktop {
          max-width: 110px;
        }
      }
    }
  }

  &.modal-couponcode {
    @include mobile-only {
      padding: 60px 22px 40px;
    }

    @include desktop {
      padding: 60px 35px;
    }

    .close-modal {
      top: 23px;
      font-size: 28px;

      @include mobile-only {
        right: 23px;
      }

      @include tablet {
        right: 43px;
      }
    }

    .text-holder {
      @include tablet {
        max-width: 410px;
      }
    }

    .heading-h4 {
      font-size: 24px;
      letter-spacing: 1.5px;
      line-height: 32px;
      margin-bottom: 20px;
      text-transform: none;
    }

    p {
      color: $rit-black;
      letter-spacing: 0.5px;
    }

    .btn {
      max-width: 100%;
      width: 100%;
    }

    .row {
      @include mobile-only {
        width: 100%;
      }

      @include tablet {
        width: 340px;
      }

      &:first-of-type:not(:only-child) {
        .link,
        .link--large,
        .btn {
          @include mobile-only {
            margin: 20px 0 10px;
          }

          @include tablet {
            margin: 10px 0;
          }
        }
      }
    }
  }
}

.generic-modal-overlay {
  z-index: $modal-overlay-index;
  background-color: rgba($rit-black, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: all;
}

//
// Transitions
// --------------------------------------------------
//

.generic-modal-transition-enter,
.generic-modal-transition-leave-to {
  .generic-modal-container {
    &:not(.centered-on-mobile) {
      @include mobile-and-tablet {
        transform: translateY(-110%);
      }
    }

    &.centered-on-mobile {
      @include mobile-and-tablet {
        opacity: 0;
      }
    }

    @include desktop {
      opacity: 0;
    }
  }
}

.generic-modal-transition-enter-active,
.generic-modal-transition-leave-active {
  .generic-modal-container {
    &:not(.centered-on-mobile) {
      @include mobile-and-tablet {
        transition: transform 0.6s $navigation-ease;
      }
    }

    &.centered-on-mobile {
      @include mobile-and-tablet {
        transition: opacity 0.6s $navigation-ease;
      }
    }

    @include desktop {
      transition: opacity 0.6s $navigation-ease;
    }
  }
}

.generic-modal-overlay-transition-enter,
.generic-modal-overlay-transition-leave-to {
   opacity: 0;
}

.generic-modal-overlay-transition-enter-active,
.generic-modal-overlay-transition-leave-active {
  transition: opacity 0.6s $navigation-ease;
}
