.content-list-wrapper {
  padding: 16px 0 87px;
  text-align: center;

  .inner-wrapper {
    max-width: 455px;
  }

  .content-list-heading {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
  }

  .content-list {
    list-style: none;
    text-align: left;
    max-width: 250px;
    margin: 20px auto 0;
    padding-left: 20px;

    li {
      span {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 25px;
        width: calc(100% - 40px);
        display: inline-block;
      }

      .icon-check {
        font-size: 11px;
        color: $rit-copper-lighter;
        margin-right: 9px;
        vertical-align: top;
        display: inline-block;
        width: 15px;
      }
    }
  }
}
