.seo-wrapper {
  display: flex;
  margin-bottom: 20px;

}

.wrapper__seo-block {
  margin-bottom: 65px;
  margin-top: -20px;
  padding: 30px 0 28px;
  position: relative;
  width: 100%;

  &::after {
    background: linear-gradient(rgba(246, 244, 242, 0), rgba(239, 237, 234, 1));
    bottom: 28px;
    content: '';
    display: inline-block;
    height: 75px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &.white-bg-color::after {
    background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  }

  &.expanded {
    .seo-block__icon {
			transform: rotate(360deg);
			&::after {
				opacity: 0;
			}
		}
    &::after {
      display: none;
    }

    .text__container {
      max-height: 2000em;
    }

    .text-cutoff__line .text-cutoff__toggle::after {
      content: icon-char(minus2);
    }
  }

  .text__container {
    overflow: hidden;
    max-height: 192px;
    transition: max-height $primary-delay $default-ease-out;

    h1 {
      font-size: 20px;
      letter-spacing: 1.3px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 16px;
      letter-spacing: 1.7px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 20px;
      margin-bottom: 19px;

      a {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .text-cutoff__line {
    border-bottom: 1px solid $gray-lines;
    bottom: 0;
    cursor: pointer;
    left: 0;
    padding-bottom: 6px;
    position: absolute;
    width: 100%;

    .text-cutoff__toggle {
      cursor: pointer;
      display: block;
      font-size: 12px;
      line-height: 1;
      position: relative;
      text-transform: uppercase;
      width: 100%;

      &::after {
        @include icon-styles;
        content: icon-char(plus2);
        display: inline-block;
        line-height: 1;
        margin-top: -3px;
      }
    }
  }

  @include mobile-only {
    &.expanded .text-cutoff__line .text-cutoff__toggle {
      .more {
        display: none;
      }

      .less {
        display: inline-block;
      }
    }

    .text-cutoff__line {
      padding: 0;
      text-align: center;

      .text-cutoff__toggle {
        padding: 8px 0;

        .more {
          display: inline-block;
        }

        .less {
          display: none;
        }

        &::after {
          font-weight: bold;
        }
      }
    }
  }

  @include tablet {
    margin: 0 auto 55px;
    max-width: 720px;
    padding-bottom: 44px;

    &::after {
      bottom: 43px;
      height: 68px;
    }

    .text__container {
      max-height: 170px;

      h1 {
        margin-bottom: 28px;
      }
    }

    .text-cutoff__line {
      padding-bottom: 13px;
      text-align: right;

      .text-cutoff__toggle {
        font-size: 27px;

        span {
          display: none;
        }
      }
    }
  }
}

.seo-block {
  position: relative;
  background-color: $advent-bg;
  padding: 0 5px 65px;
}

.wrapper__seo-advent {
  margin-bottom: 0;

  .seo-block__icon-div {
		position: absolute;
		width: 100%;
		min-height: 70px;
		bottom: 0;
		cursor: pointer;
		z-index: 1;
		border-bottom: 1px solid rgba($white, 0.15);
	}

	.seo-block__icon {
		position: absolute;
		height: 48px;
		right: 24px;
		width: 48px;
    background: url('../images/oval-gradient-grey-80x80.png') no-repeat left top;
		z-index: 100;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		transition: transform 0.3s ease, opacity 0.3s ease;

		&::before,
		&::after {
			background-color: $white;
			content: '';
			display: inline-block;
			height: 1px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: transform 0.3s ease, opacity 0.3s ease;
			width: 11px;
		}

		&::after {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(90deg);
		}

		&.open {
			transform: rotate(360deg);
			&::after {
				opacity: 0;
			}
		}
	}

  .text__container {
    padding: 0 120px 0 20px;

    h2 {
      font-size: 16px;
      letter-spacing: .5px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    p {
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: .5px;
      line-height: 24px;
      margin-bottom: 20px;

      a {
        font-family: $font-header;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &::after {
    background: linear-gradient(180deg,  rgba(28, 44, 76, 0) 0%,  rgba($advent-bg, 0.86) 85.3%,  $advent-bg 99.99%);
    bottom: 28px;
    content: '';
    display: inline-block;
    height: 50px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .text-cutoff__line {
    border-bottom: 1px solid $gray-lines;
    bottom: 0;
    color: $white;
    cursor: pointer;
    left: 0;
    position: absolute;
    width: 100%;
    min-height: 50px;

    .text-cutoff__toggle {
      min-height: 50px;
    }
  }

  @include tablet {
    max-width: 920px;
    margin: 0 auto;
    padding-bottom: 20px;
    .text-cutoff__toggle {
      z-index: 10;
    }
    .text-cutoff__line  {
      padding-bottom: 0;
    }
    &::after {
      bottom: 20px;
      height: 188px;
    }
  }
  @include mobile-only {
    padding-top: 0;

    .seo-block__icon-div {
      min-height: 100%;
    }
    .seo-block__icon {
      height: 40px;
      width: 40px;
      right: 0;
    }
    .text__container {
      padding: 0 45px 0 0;
      p {
        font-size: 14px;
        a {
          font-size: 14px;
        }
      }
    }
    &::after {
      height: 188px;
    }
  }
}

.personalised-gifting-bg .wrapper__seo-block {
  &::after {
    background: linear-gradient(rgba(246, 244, 242, 0), rgba(239, 237, 234, 1));
  }
}

.seo-wrapper-redesign {
  background-color: $rit-black;
  display: flex;
  padding-top: 60px;

  @include mobile-only {
    padding-top: 30px;
  }

  .wrapper__seo-block {
    margin-bottom: 30px;
    padding: 10px 0 28px;
    position: relative;
    width: 100%;

    &::after {
      display: none;
    }

    &.expanded {
      .text__container {
        max-height: 2000em;
        &::after {
          display: none;
        }
      }
    }

    .text__container {
      overflow: hidden;
      max-height: 192px;
      transition: max-height $primary-delay $default-ease-out;
      position: relative;
      margin-bottom: 20px;

      &::after {
        background: linear-gradient(0deg, rgba($rit-black, 1) 0%, rgba($rit-black, 1) 20%, rgba($rit-black, 0) 100%);
        bottom: 0;
        content: '';
        display: inline-block;
        height: 75px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      h1 {
        font-size: 20px;
        letter-spacing: 1.3px;
        line-height: 24px;
        margin-bottom: 20px;
        text-transform: initial;
      }

      h2 {
        font-size: 18px;
        letter-spacing: 1.7px;
        line-height: 20px;
        margin-bottom: 20px;
        text-transform: initial;
      }

      h3 {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 20px;
        text-transform: initial;
      }

      p {
        font-size: 14px;
        letter-spacing: .5px;
        line-height: 20px;
        margin-bottom: 19px;

        a {
          font-size: 14px;
          line-height: 20px;
          color: $white;
        }
      }
    }

    .text-cutoff__toggle {
      cursor: pointer;
      display: block;
      font-size: 12px;
      line-height: 1;
      position: relative;
      margin: 0 auto;
      text-transform: uppercase;
      border: 1px solid $white;
      max-width: 200px;
      min-width: 150px;
      z-index: 10;

      .more {
        display: inline-block;
      }

      .less {
        display: none;
      }
    }

    &.expanded .text-cutoff__toggle {
      .more {
        display: none;
      }

      .less {
        display: inline-block;
      }
    }

    @include tablet {
      padding-bottom: 30px;
      max-width: initial;

      .text__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 170px;

        &::after {
          height: 80px;
        }

        .text__container-side {
          width: 48%;
        }

        h1 {
          margin-bottom: 28px;
        }
      }

      .text-cutoff__line {
        padding-bottom: 13px;
        text-align: right;

        .text-cutoff__toggle {
          font-size: 27px;

          span {
            display: none;
          }
        }
      }
    }
  }

  .category-pills {
    margin-bottom: 10px;

    .cop-filters-wrapper.cop-product-types-wrapper {
      background-color: $rit-black;

      @include mobile-only {
        background-color: $rit-black;
        height: auto;
        padding: 10px 0 0;

        &::before,
        &::after {
          visibility: hidden;
        }
      }

      .cop-filters-list {
        margin-bottom: 0;

        li {
          border-color: $gray-dark;
          cursor: pointer;

          a {
            color: $white;
            font-size: 12px;
            padding: 0 17px;
            text-decoration: none;
          }

          @include tablet {
            &:hover {
              border-color: $white;
            }
          }
        }

        @include mobile-only {
          display: flex;
          flex-wrap: wrap;

          li {
            display: block;
          }
        }
      }
    }

    .category-pills-header {
      color: $white;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
      text-transform: initial;

      @include tablet {
        margin-bottom: 20px;
      }
    }
  }
}
