.timeline-component {
  padding: 57px 0 38px;

  .timeline-wrapper {
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
  }

  .timeline-label {
    color: $copper-medium;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 16px;
    margin-bottom: 11px;
    text-align: center;
    text-transform: uppercase;
  }

  .timeline-header {
    color: $rit-black;
    font-size: 40px;
    letter-spacing: 1.8px;
    line-height: 48px;
    margin-bottom: 62px;
    text-align: center;
    text-transform: initial;
  }

  .timeline-block {
    display: flex;
    min-height: 650px;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;

    &::before {
      content: '';
      background-color: $rit-black;
      height: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    @include mobile-only {
      min-height: 700px;
      margin-bottom: 20px;

      &::before {
        left: 20px;
        width: calc(100% - 40px);
      }
    }
  }

  .timeline-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px;
    padding: 30px 24px 40px;
    position: relative;
    text-align: center;

    @include mobile-only {
      padding: 30px 10px 40px;
    }

    @include tablet {
      min-width: 300px;
    }

    &:nth-child(even) {
      .timeline-imgbox {
        order: -1;
      }
    }

    &:last-child {
      &::after {
        background: radial-gradient(circle at center, $white 30%, $black 40%);
        height: 22px;
        width: 22px;
      }

      &::before {
        content: '';
        background-color: $rit-sandy-bg;
        height: 2px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        z-index: 2;
      }
    }

    &:first-child {
      &::before {
        content: '';
        background-color: $rit-sandy-bg;
        height: 2px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        z-index: 2;
      }
    }

    &::after {
      content: '';
      background-color: $rit-black;
      border-radius: 50%;
      height: 11px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 11px;
      z-index: 3;
    }
  }

  .timeline-imgbox {
    max-width: 260px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .timeline-img {
    width: 100%;

    @include mobile-only {
      width: 100%;
    }
  }

  .timeline-year {
    color: $rit-black;
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .timeline-heading {
    color: $rit-black;
    font-family: $font-header;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 7px;
  }

  .timeline-text {
    color: $gray-medium;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 38px;

    @include mobile-only {
      margin-bottom: 18px;
    }
  }

  .timeline-link {
    color: $rit-copper-lighter;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
  }

  .slider-button {
    background-color: $white;
    position: relative;
    z-index: 3;

    @include mobile-only {
      background-color: $white;
    }
  }

  .slick-disabled {
    position: relative;

    &::after {
      content: '';
      background-color: $rit-sandy-bg;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .inner-wrapper {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50px;
      background-image: linear-gradient(to right, $rit-sandy-bg , rgba($rit-sandy-bg, 0));
      z-index: 4;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      background-image: linear-gradient(to left, $rit-sandy-bg , rgba($rit-sandy-bg, 0));
      z-index: 4;
    }

    @include mobile-only {
      padding: 0;
    }
  }

  .slick-prev,
  .slick-next {
    top: 50%;
    position: absolute;
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    z-index: 5;
  }

  .slick-prev {
    left: 20px;

    @include desktop-large {
      left: 0;
    }
  }

  .slick-next {
    right: 20px;

    @include desktop-large {
      right: 0;
    }
  }

  .timeline-progress {
    background-color: $slider-bar-gray;
    background-image: linear-gradient(to right, $black, $black);
    background-repeat: no-repeat;
    background-size: 0 100%;
    border-radius: 4px;
    height: 4px;
    margin: 0 20px 40px;
    overflow: hidden;
    transition: background-size .4s ease-in-out;
    max-width: 1440px;

    @include desktop-large {
      margin: 0 auto 40px;
    }
  }
}
