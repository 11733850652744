// Scrub Bar PDP overrides
.product-type-body-scrub-bar {
  .pdp-inner-wrapper {
    max-width: none;
    padding: 0;

    @include mobile-and-tablet {
      background-color: $rit-black;
      display: block;
    }

    @include desktop {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .pdp-breadcrumbs-wrapper {
    position: absolute;
  }

  .pdp-images-holder {
    @include mobile-only {
      min-height: auto;
    }
  }

  .product-details-container {
    padding: 0;

    @include desktop {
      background: linear-gradient(90deg, $rit-black-lighter 0%, $rit-black-lighter 62.5%, $rit-sandy-bg 62.5%, $rit-sandy-bg 100%);
    }
  }

  .images-wrapper {
    @include tablet-only {
      width: 100%;
    }

    img {
      @include mobile-only {
        height: auto;
        width: auto;
      }
    }
  }

  .images-slider,
  .images-slider-fullwidth {
    .slick-slide {
      > div {
        @include mobile-only {
          overflow: visible;
          height: auto;
          position: static;
          width: auto;
        }
      }
    }
  }

  .images-slider {
    img {
      @include tablet-landscape {
        max-height: none;
      }

      @include tablet-portrait {
        max-height: none;
      }

      @include desktop {
        max-width: none;
      }

      @include desktop-large {
        max-width: none;
      }
    }
  }

  .full-width-image {
    @include mobile-only {
      margin: 0;
      max-width: none;
      width: auto;
    }
  }

  .product-details-column {
    @include tablet {
      width: auto;
    }

    &.column-images {
      background-color: $rit-black-lighter;

      @include desktop {
        max-height: 900px;
        width: 62.5%;
      }
    }

    &.column-details {
      @include desktop {
        align-items: flex-start;
        width: 37.5%;
      }
    }
  }

  .product-title {
    @include mobile-and-tablet {
      background-color: $rit-sandy-dark;
      padding: 40px 24px;
    }

    .ritual-icon {
      height: 38px;
    }
  }

  .product-details-content {
    max-width: none;

    @include tablet {
      padding: 0;
    }

    @include desktop {
      padding: 84px 30px 30px 30px;
    }

    .product-type-icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 38px;

      &.scrub {
        background-image: url('../images/product-type-scrub.svg');
      }
    }

    .product-name {
      @include mobile-only {
        margin-bottom: 10px;
      }

      @include tablet-only {
        margin-left: auto;
        margin-right: auto;
        max-width: 380px;
      }

      span {
        &:last-child:not(:only-child) {
          @include mobile-and-tablet {
            display: none;
          }
        }
      }
    }

    .product-content {
      @include mobile-and-tablet {
        background-color: $rit-black-lighter;
        padding: 20px 24px 40px 24px;
      }

      .check-availability-button-hor {
        display: none;
      }
    }

    .product-short-info {
      @include tablet-only {
        margin-left: auto;
        margin-right: auto;
        max-width: 380px;
      }
    }

    .shipping-info {
      @include mobile-and-tablet {
        color: $white;
        margin: 0 auto;
      }

      @include desktop {
        color: $rit-black;
        margin: 30px auto 0;
      }
    }

    .short-description,
    .previously-known-as {
      margin-bottom: 10px;
    }

    .long-description {
      margin-bottom: 0;

      @include tablet-only {
        margin-left: auto;
        margin-right: auto;
      }

      .previously-known-as {
        margin-bottom: 10px;
      }
    }

    .product-price,
    .product-add-to-cart {
      margin-bottom: 30px;
    }

    .product-price {
      @include mobile-and-tablet {
        color: $white;
      }
    }

    .product-add-to-cart {
      .btn-wrapper {
        .btn {
          @include mobile-and-tablet {
            background-color: $white;
            color: $rit-black;
          }
        }
      }
    }
  }

  .pdp-swatches-wrapper {
    margin: 0;

    .pdp-swatches-list {
      margin-bottom: 27px;
      max-width: 260px;

      .last-two-items {
        display: inline-block;
      }
    }

    .pdp-swatches-text {
      display: none;
    }

    .pdp-swatches-item {
      height: 32px;
      margin: 0 10px 10px 10px;
      width: 32px;
    }

    .pdp-swatches-button {
      border-width: 1px;
      height: 32px;
      width: 32px;
    }

    .selected .pdp-swatches-button {
      @include mobile-and-tablet {
        border-color: $white;
      }

      @include desktop {
        border-color: $rit-black;
      }
    }
  }
}
