$swatch-width: 48px;

.product-variations,
.product-options {
  margin-bottom: 50px;
  width: 100%;
  max-width: 252px;
  margin: 0 auto;

  @include tablet {
    max-width: none;
  }

  ul {
    list-style: none;
  }

  .swatches-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;

    @include tablet {
      max-width: none;
      justify-content: center;
    }
  }

  .swatches {
    display: flex;
    margin-top: 30px;
  }

  .swatch {
    @include mobile-only {
      width: 25%;
      flex-basis: 25%;
    }
    .swatch__anchor {
      align-items: center;
      background-color: $white;
      border: 1px solid #b4b4b4;
      color: $black;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      height: $swatch-width;
      justify-content: center;
      margin: 0 10px 20px;
      padding: 4px 0 0 2px;
      text-decoration: none;
      transition: $quaternary-duration $default-ease-out;
      width: $swatch-width;

      @include mobile-only {
        margin-bottom: 15px;
      }

      &:hover {
        background-color: $rit-copper;
        border-color: $rit-copper;
        color: $white;
        text-decoration: none;
      }
    }

    &.unselectable,
    &.selectable--out-of-stock {
      .swatch__anchor {
        border-color: $gray-gainsboro;
        color: $gray-disabled;
      }
    }

    &.unselectable .swatch__anchor {
      cursor: not-allowed;

      &:hover {
        background-color: $white;
        border-color: $gray-gainsboro;
        color: $gray-disabled;
      }
    }

    &.selectable--out-of-stock .swatch__anchor:hover {
      color: $white;
    }

    &:not(.unselectable).selected {
      .swatch__anchor {
        border: 2px solid $rit-copper;
      }
    }
  }
}

.product-options {
  margin: 0 auto 30px;
  .swatches-wrapper {
    @include tablet {
      margin: 0 auto;
      max-width: ($swatch-width + 6) * 4; // only 4 option blocks per line
      justify-content: flex-start;
    }
  }

  .currency-code {
    color: $gray-medium;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
}

.variation-dropdown-mobile,
.option-dropdown-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.cart-page-wrapper {
  .product-variations  {
    width: auto;
    margin: 0 0 15px;
    min-height: 44px;
    max-width: 215px;

    @include tablet {
      max-width: none;
    }

    @include desktop-only {
      margin: 0 20px 0 0;
    }

    .swatches-wrapper {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }

    .swatch {
      &.selected {
        .swatch__anchor {
          border: 1px solid $rit-copper-dark;
        }
      }

      &.selectable--out-of-stock {
        background-color: transparent;

        .swatch__anchor {
          color: $gray-disabled;
        }
      }

      .swatch__anchor {
        margin: 0 10px 0 0;
        border: 1px solid $gray-gainsboro;
        color: $rit-black;
        height: 44px;
        width: 44px;
      }
    }
  }
}
