// --------------------------
//      VISIBILITY HELPERS
// --------------------------
.show {
  display: block;
}

.hide {
  display: none;
}

.show-on-mobile {
	display: block;

	@include tablet {
		display: none;
	}
}

.hide-on-mobile {
	@include mobile-only {
		display: none !important;
	}
}

.hide-on-tablet {
	@include tablet {
		display: none !important;
	}
}

.show-on-mobile-tablet {
  @include mobile-and-tablet {
    display: block;
  }

  @include desktop {
    display: none !important;
  }
}

.hide-on-mobile-tablet {
  @include mobile-and-tablet {
    display: none !important;
  }
}

.i-minMobile {
  display: none !important;

  @include mobile-only {
    display: block !important;
  }
}

.i-minTablet {
  display: none !important;

  @include tablet-portrait {
    display: block !important;
  }

  @include tablet-landscape {
    display: block !important;
  }
}

.i-minDesktop {
  display: none !important;

  @include desktop {
    display: block !important;
  }
}

// --------------------------
//      SCREEN READERS
//--------------------------
.sr-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.flyout-sr-only {
  left: -10000px; // fixes Safari white space to the right bug when focusing on the flyout anchors
}

// --------------------------
//      ALIGNMENT HELPERS
// --------------------------
.align-center {
  text-align: center;
}

// --------------------------
//      TYPOGRAPHY HELPERS
// --------------------------

.txt--bold,
.bold-text {
  font-weight: 700;
}

.txt--italic {
  font-style: italic;
}

// ------- Colors -------
.txt--white {
  color: $white !important;
}

.txt--black {
  color: $rit-black !important;
}

.txt--rit-dark {
  color: $rit-dark !important;
}

.txt--gray-medium {
  color: $gray-medium !important;
}

.txt--reset-color {
  color: currentColor !important;
}

.txt--rit-copper {
  color: $rit-copper !important;
}

.txt--rit-copper-light {
  color: $rit-copper-lighter !important;
}

.txt--red {
  color: $crimson !important;
}

.txt--gray  {
  color: $gray-medium;
}

//------- Sizes -------
.txt--large {
  @include text-size('l');
}

.txt--small {
  @include text-size('s');
}

// --------------------------
//      ERROR style
// --------------------------
.error {
	color: $crimson;
}

// --------------------------
//      inline SVG in header
// --------------------------
.st0 {
  fill: $white;
}

// --------------------------
//      PP OOS Indicator
// --------------------------

.pp-out-of-stock {
  &::after {
    background-color: $snow-white;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .oos-icon {
    border-radius: 50px;
    border: 2px solid $white;
    display: block;
    height: 68px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 68px;
    z-index: 1;

    &::after {
      background-color: $white;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 31px;
      transform: rotate(45deg);
      width: 100%;
    }
  }
}
