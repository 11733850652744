.cart-banner {
  margin-bottom: 16px;

  @include mobile-only {
    margin-bottom: 24px;
  }

  .content-asset {
    align-items: center;
    background-color: $white;
    display: flex;
    width: 100%;
  }

  .cart-banner-img-container {
    flex: 0 0 auto;
    height: 100px;
    width: 104px;

    @include mobile-only {
      height: 148px
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .cart-banner-text-content {
    margin-top: 14px;
    padding: 0 24px;
    text-align: left;

    @include mobile-only {
      margin-top: 8px;
      padding: 0 26px;
    }
  }
  .cart-banner-title,
  .cart-banner-paragraph,
  .cart-banner-link {
    color: $rit-black;
  }

  .cart-banner-title {
    font-family: $font-header;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .cart-banner-paragraph,
  .cart-banner-link {
    font-family: $font-body;
    font-size: 14px;
    letter-spacing: .4px;
    line-height: 24px;
  }

  &--mini-cart,
  &--mini-cart-toaster {
    margin: -18px 24px 24px;

    @include mobile-only {
      margin: 16px 24px;
    }

    .content-asset {
      background-color: $snow-white;
    }

    .cart-banner-img-container {
      height: 124px;

      @include mobile-only {
        height: 148px;
      }
    }
  }

  &--mini-cart-toaster {
    .cart-banner-text-content {
      @include mobile-only {
        padding: 0 22px;
      }
    }
  }
}

.minimum-amount-banner {
  background-color: $snow-white;
  margin: 8px 24px;
  padding: 16px;

  .minimum-amount {
    align-items: center;
    color: $rit-black;
    display: flex;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 28px;
    margin-bottom: 0;
    text-align: left;

    &::before {
      @include icon-styles;
      content: icon-char(circle-info);
      color: $rit-black;
      display: block;
      font-size: 24px;
      margin-right: 16px;
    }
  }
}
