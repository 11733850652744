.write-a-card-column {
  width: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  @include desktop {
    margin-left: 50%;
    padding-top: 95px;
    min-height: 100vh;
    width: 50%;
  }
}

.write-a-card-content {
  padding: 40px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .write-a-card-heading {
    color: $black;
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 20px;
    margin-bottom: 15px;
    text-transform: none;

    @include tablet {
      font-size: 30px;
      letter-spacing: 0.6px;
      line-height: 40px;
      margin-bottom: 8px;
    }
  }

  .pg-form {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }

  .write-a-card-description {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 330px;
    text-align: center;
  }

  .card-slider {
    max-width: 200px;
    margin: 22px 0 41px;

    @include tablet {
      margin: 28px 0 37px;
    }

    &:not(.drag-list) {
      .card-slide + .card-slide {
        display: none;
      }
    }
  }

  .card-slide {
    position: relative;

    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .slick-slide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out,
    visibility 0.4s ease-out;

    &.slick-active {
      opacity: 1;
      visibility: visible;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: -18px;
    width: 100%;
    font-size: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 4px;
      width: 4px;

      &.slick-active {
        button {
          background: $rit-dark;
        }
      }

      button {
        border-radius: 50%;
        display: inline-block;
        background: $gray-concrete;
        height: 4px;
        width: 4px;
        padding: 3px;
        line-height: 0;
        font-size: 0;

        &::before {
          content: '';
          border-radius: 50%;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    display: block;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: 0;
    outline: none;

    &.slick-next {
      right: -50px;
    }

    &.slick-prev {
      left: -50px;
    }

    &.slick-disabled {
      .slider-button {
        opacity: 0.1;
        visibility: visible;
      }
    }
  }

  .slider-button {
    display: flex;
    align-items: center;
    border-radius: 50%;
    color: $black;
    font-size: 44px;
    height: 50px;
    justify-content: center;
    width: 50px;

    &::before {
      font-size: 40px;
    }
  }

  .form-wrapper {
    width: 100%;
    position: relative;

    @include tablet {
      max-width: 460px;
    }
  }

  .cancel-giftcard-edit {
    text-transform: uppercase;
    margin: 27px auto 0;
    display: block;
    font-size: 12px;
    letter-spacing: 1.5px;
  }

  .textarea-holder {
    position: relative;
    overflow: hidden;
  }

  .card-message {
    padding: 7px 8px;
    resize: none;
    overflow: auto;
    border: 1px solid $gray-gainsboro;
    width: 100%;
    height: 176px;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 23px;
    font-family: $font-body;
    background-color: transparent;
    -webkit-appearance: none;
    border-radius: 0;

    &:focus,
    &:active {
      background-color: $rit-sandy-bg;
    }

    @include tablet {
      height: 120px;
      letter-spacing: 0.5px;
      line-height: 25px;
      padding: 10px;
    }

    @include desktop-only {
      font-size: 14px;
    }
  }

  .character-counter {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 10px;
    font-size: 9px;
    letter-spacing: 1px;
    color: $gray-dark;

    @include tablet {
      bottom: 7px;
    }
  }

  .characters-checkbox-wrapper {
    margin: 5px 0 12px;
    text-align: left;

    @include tablet {
      margin: 0 0 24px;
    }
  }

  .checkbox-wrapper {
    label {
      color: $rit-dark;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.75px;
      line-height: 14px;
      padding-left: 36px;
      position: relative;

      &::before,
      &::after {
        top: -2px;
      }
    }
  }

  .btn {
    display: block;
    margin: 0 auto;
  }

  .drag-list {
    .drag-list-body {
      padding: 0;
    }

    .card-slide {
      width: 200px;
    }

    .drag-list-controls {
      .slider-button.hidden {
        opacity: 0.1;
      }
    }

    @include mobile-only {
      .drag-list-controls {
        display: block;

        .icon-arrow3--left {
          left: -60px;
        }

        .icon-arrow3--right {
          right: -60px;
        }
      }
    }

    @include tablet {
      .drag-list-controls {
        .icon-arrow3--left {
          left: -50px;
        }

        .icon-arrow3--right {
          right: -50px;
        }
      }
    }
  }
}

