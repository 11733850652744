.checkout-opening-hours {
  border-top: 1px solid $gray-gainsboro;
  border-bottom: 1px solid $gray-gainsboro;
  margin: 0 0 20px -20px;
  padding: 0 30px;
  width: calc(100% + 40px);
}

.checkout-opening-hours-link {
  color: $rit-black;
  display: block;
  font-family: $font-body-bold;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .5px;
  line-height: 20px;
  padding: 14px 0 15px;
  position: relative;
  text-align: left;
  width: 100%;

  &:not(.single-opening-hours)::after {
    @include icon-styles;
    content: icon-char(arrow3--down);
    display: inline-block;
    font-size: 38px;
    line-height: 20px;
    position: absolute;
    right: -14px;
    top: 14px;
    transition: transform $quaternary-duration $filters-ease;
  }

  &.open::after {
    transform: rotate(-180deg);
  }
}

.checkout-opening-hours-list {
  display: none;
  list-style: none;
  margin: 0 0 18px;
  width: 100%;

  li {
    color: $rit-black;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 20px;
    display: flex;
    width: 100%;
  }

  .time {
    margin-left: auto;
  }
}
