
.product-custom-giftset {
  display: flex;

  @include desktop {
    display: flex;
    padding-top: 95px;
    min-height: calc(100vh - 95px);
    position: relative;
  }
}

.product-custom-giftset-column {
  background-color: $white;
  padding: 21px 30px 20px;
  width: 100%;

  @include tablet-only {
    margin: 0 auto;
    width: 80%;
  }

  .giftset-next-btn {
    display: none;
  }

  @include desktop {
    align-items: center;
    display: flex;
    height: calc(100vh - 95px);
    justify-content: center;
    margin-left: 50%;
    overflow: hidden;
    padding: 50px;
    width: 50%;

    .inner-content-column {
      max-width: 400px;
      text-align: center;
    }

    .giftset-next-btn {
      display: inline-block;
      min-width: 240px;
    }
  }
}

.product-custom-giftset-intro {
  margin-bottom: 28px;
  text-align: center;

  h2 {
    color: $black;
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
    margin: 0 0 10px;
    text-align: center;
    text-transform: none;
  }

  p {
    color: $rit-black;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
  }

  @include tablet {
    margin-bottom: 33px;

    h2 {
      font-size: 30px;
      letter-spacing: .6px;
      line-height: 40px;
      margin: 0 0 7px;
    }
  }
}

.custom-giftset-list-wrap {
  border-bottom: 1px solid #d9d7d8;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 16px;
  max-width: 290px;
  width: 100%;

  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .edit-button {
    background-color: $white;
    border: 0;
    border-radius: 32px;
    box-shadow: 0 0 6px 0 rgba($rit-black, .1);
    color: $rit-copper-dark;
    cursor: pointer;
    font-family: $font-body;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 34px;
    height: 30px;
    margin-bottom: 11px;
    padding: 0 12px 0 37px;
    position: relative;

    .repeat-label {
      display: none;
    }

    &.repeat {
      .repeat-label {
        display: inline-block;
      }

      .default-label {
        display: none;
      }
    }

    .circle {
      background-color: $white;
      border-radius: 50%;
      box-shadow: 0 0 8px 0 rgba($rit-black, .1);
      display: inline-block;
      height: 30px;
      left: 0;
      position: absolute;
      width: 30px;

      &::before {
        content: '';
        border-radius: 100%;
        background-image: url('../images/pulsating-button-rainbow.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  .undo-button {
    background-color: rgba($white, 0);
    border: 0;
    color: $rit-copper;
    display: none;
    font-size: 11px;
    letter-spacing: 0.5px;
    margin: 11px 0 30px;
    order: 2;
    padding: 0;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .giftset-product-list {
    display: flex;
    margin-bottom: 15px;
    padding-left: 5px;
    width: 100%;

    .list-holder {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    span {
      color: $rit-black;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include tablet {
    margin-bottom: 17px;
    max-width: 320px;
    text-align: left;

    &:last-of-type {
      margin-bottom: 10px;
    }

    .edit-button {
      font-size: 12px;
      letter-spacing: .4px;
      outline: none;
      margin-left: -5px;
      padding: 0 12px 0 35px;

      .circle {
        transition: transform .4s cubic-bezier(.18, .74, .36, .99), opacity .6s cubic-bezier(.33, .16, .15, .7), visibility .6s cubic-bezier(.33, .16, .15, .7);
      }

      &.animating .circle {
        animation: pulse 1s infinite;
        transform-origin: center;
      }

      &:hover .circle {
        transform: scale(1.2);
      }
    }

    .undo-button {
      margin: 1px 0 6px auto;
      order: 0;
    }

    .giftset-product-list {
      padding-left: 0;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($black, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($black, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($black, 0);
  }
}

.giftset-lightbox {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .3s ease;
  visibility: hidden;
  z-index: -1;

  &.visible {
    opacity: 1;
    visibility: visible;
    z-index: $modal-overlay-index;
  }

  @include tablet {
    position: absolute;
    right: auto;
    width: 50%;
  }
}

.giftset-lightbox-overlay {
  background-color: rgba($black, .1);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  @include tablet {
    background-color: rgba($black, .2);
  }
}

.giftset-lightbox-close {
  background-color: rgba($white, 0);
  border: 0;
  font-size: 0;
  padding: 0;
  position: fixed;
  right: 15px;
  top: 0;
  z-index: 2;

  span {
    color: $rit-black;
    display: inline-block;
    font-size: 26px;
    line-height: 1;
  }

  @include tablet {
    cursor: pointer;
    position: absolute;
    right: 27px;
    top: 26px;
  }
}

.giftset-lightbox-box {
  left: 0;
  max-height: 100%;
  padding-top: 37px;
  position: fixed;
  right: 0;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  z-index: 2;

  @include tablet {
    background-color: $white;
    box-shadow: 0 10px 40px rgba($black, .2);
    left: 50%;
    margin-left: auto;
    max-height: none;
    max-width: 520px;
    min-height: 602px;
    padding: 0 0 40px;
    position: absolute;
    right: auto;
    transform: translate(-50%, -50%);
    width: calc(100% - 100px);
  }
}

.giftset-lightbox-slider {
  width: 100%;

  @include mobile-only {
    padding-bottom: 12px;

    .drag-list-body {
      padding: 0 24px;
    }
  }

  @include tablet {
    padding: 0 24px 12px;

    .drag-list-body {
      padding: 0;
    }
  }

  .slider-item-inner {
    background-color: $white;
    border-radius: 4px;
    padding: 20px 20px 40px;
    width: 100%;
  }

  .lightbox-product-list {
    display: none;
  }

  @include tablet {
    padding: 0;

    .slider-item-inner {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 40px 55px 30px;
    }
  }
}

.lightbox-slider-item {
  border-radius: 5px;
  display: flex !important;
  outline: none;

  @include mobile-only {
    padding: 0 7px;
    width: calc(100vw - 10px);
  }

  @include tablet {
    overflow: hidden;
    position: relative;
  }
}

.lightbox-product-image {
  margin-bottom: 54px;
  text-align: center;
  width: 100%;

  img {
    display: inline-block;
    max-height: 340px;
    max-width: 100%;
  }

  @include tablet {
    margin-bottom: 70px;
    max-width: 313px;

    img {
      max-height: 390px;
    }
  }
}

.lightbox-product-details {
  box-sizing: border-box;
  text-align: center;
  width: 100%;

  .btn {
    .selected-label {
      display: none;
    }

    &.selected {
      background-color: rgba($white, 0);
      border-color: rgba($white, 0);
      color: $rit-black;
      min-width: 10px;
      padding: 15px 10px 14px;
      pointer-events: none;
      text-align: left;

      .selected-label {
        display: flex;

        &::before {
          @include icon-styles;
          color: $green;
          content: icon-char(check-thin);
          display: inline-block;
          font-size: 12px;
          font-weight: bold;
          margin: -1px 10px 0 0;
        }
      }

      .desktop-label,
      .mobile-label {
        display: none;
      }
    }
  }

  @include mobile-only {
    .btn {
      &:not(.selected) {
        .desktop-label {
          display: none;
        }
      }
    }
  }

  @include tablet {
    max-width: 315px;

    .btn {
      &:not(.selected) {
        .mobile-label {
          display: none;
        }
      }
    }
  }
}

.lightbox-product-title {
  color: $black;
  font-family: $font-header;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0 0 19px;
  text-transform: none;

  @include tablet {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 0 30px;
  }
}

.visible-lightbox {
  @include mobile-only {
    .giftset-pdp-main-container,
    .product-custom-giftset .inner-content-column,
    .write-a-card-column {
      filter: blur(10px);
    }
  }
}

.giftset-replace-dialog-wrapper {
  background-color: $white !important;
  display: none;
  margin: 0 20px;
  padding: 80px 20px 40px !important;
  text-align: center;

  @include tablet {
    margin: 0;
    padding: 120px 100px 70px !important;
  }

  p {
    margin-bottom: 30px;
  }

  button {
    min-width: 100%;

    @include tablet {
      min-width: 300px;
    }

    & + button {
      margin-top: 20px;
    }
  }
}
