.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.footer--empty {
  .footer-bottom-elements {
    .accessibility-statement-link {
      display: block;
    }
  }
}

.main-footer {
  position: relative;
  background-color: $rit-black;
}

.footer-small {
  .footer-left-side {
    @include mobile-only {
      padding-top: 33px;
    }
  }

  .accordion-item:first-of-type {
    .accordion-head {
      @include mobile-only {
        border-top: 1px solid $gray-light;
      }
    }
  }

  .footer-middle-elements {
    .inner-wrapper::before {
      display: none;
    }
  }
}

.footer-top-elements {
  background-color: $rit-black;
  padding: 33px 0 0;

  @include tablet {
    padding: 25px 40px 0;
  }

  @include desktop {
    padding: 29px 96px 0;
  }

  .footer-accordion {
    @include tablet {
      display: inline-block;
      width: auto;

      & > .accordion-item {
        width: auto;

        .accordion-content {
          display: block;
          max-width: 1000px;
        }
      }
    }
  }

  .accordion-head {
    display: none;

    @include mobile-only {
      display: block;
    }
  }

  .accordion-inner {
    @include tablet {
      width: 145px;
    }

    @include desktop {
      width: 195px;
    }
  }
}

.footer-title {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1px;
  text-transform: none;
  color: $white;
  margin-bottom: 20px;

  @include mobile-only {
    display: none;
  }
}

.footer-accordion {
  &::before {
    left: 15px;
    right: 15px;
  }

  @include tablet {
    padding-bottom: 12px;

    &::before {
      display: none;
    }
  }

  .accordion-item,
  .accordion-inner {
    @include tablet {
      display: inline-block;
      padding: 0 20px 0 0;
      margin-bottom: 6px;
    }

    @include desktop {
      padding: 0 40px 0 0;
    }
  }

  .accordion-item {
    padding: 0 15px;

    @include tablet {
      width: auto;
    }
  }

  .accordion-inner {
    @include tablet {
      margin-top: 10px;
      vertical-align: top;

      &:nth-child(n+6) {
        max-height: 0;
        visibility: hidden;
        transition: 0.5s ease-in-out;
        margin: 0;

        &.visible {
          max-height: 500px;
          visibility: visible;
          margin: 10px 0 6px;

          .accordion-head-inner,
          .accordion-content-inner {
            opacity: 1;
          }
        }

        .accordion-head-inner,
        .accordion-content-inner {
          opacity: 0;
          transition: 0.6s;
        }
      }
    }
  }

  .accordion-head {
    &.open {
      border-bottom-color: $gray-light;
    }

    @include tablet {
      pointer-events: none;
      font-size: 15px;
      letter-spacing: 0.75px;
      line-height: 18px;
      color: $white;
      font-family: $font-body;
      text-transform: none;
      border: 0;
      padding: 0;
      margin-bottom: 16px;

      &::after {
        display: none;
      }
    }
  }

  .accordion-head-inner {
    margin: 15px 0 5px;
    font-size: 15px;
    letter-spacing: 0.75px;
    line-height: 18px;
    color: $white;
    font-family: $font-body;

    @include tablet {
      pointer-events: none;
      margin: 0 0 13px;
    }
  }

  .accordion-content {
    padding: 21px 0 23px;

    @include tablet {
      display: flex;
      border: 0;
      padding: 0;
    }

    li {
      padding: 6px 0;
    }
  }

  .accordion-content-inner {
    border: 0;
    padding: 0;

    @include tablet {
      display: block;
      padding: 5px 0 25px;
    }

    ul {
      list-style: none;
    }

    li {
      &:nth-child(n+4) {
        max-height: 0;
        visibility: hidden;
        transition: 0.5s ease-in-out;
        padding: 0;

        &.visible {
          max-height: 500px;
          visibility: visible;
          padding: 6px 0;

          a {
            opacity: 1;
          }
        }

        a {
          opacity: 0;
          transition: 0.6s ease;
        }
      }
    }
  }

  .accordion-link {
    font-size: 15px;
    letter-spacing: 0.75px;
    line-height: 18px;
    color: $white;
    font-family: $font-body;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    @include tablet {
      font-size: 14px;
      letter-spacing: 0.7px;
      line-height: 15px;
      color: $gray-lighter;
    }
  }

  .accordion-link-inner {
    display: block;
    color: $gray-lighter;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.7px;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.social--icons {
  z-index: 10;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
  display: none;

  @include tablet {
    display: block;
  }

  @include mobile-only {
    max-width: 255px;
    margin: 30px auto 0;
  }

  &.social--icons-mobile {
    display: none;

    @include mobile-only {
      display: block;
    }

    .social__icons {
      padding-top: 37px;
    }
  }
}

.social__icons {
  display: flex;
  flex-direction: row;
  padding-top: 22px;
  list-style: none;
  background-color: $rit-black;

  &:has(.icon-enlarge) {
    padding-top: 36px;
  }

  @include mobile-only {
    justify-content: center;
  }

  li:not(:last-of-type) {
    margin-right: 29px;

    &:has(.icon-enlarge) {
      margin-right: 10px;
    }
  }

  a {
    font-size: 14px;
    letter-spacing: 0.7px;
    text-decoration: none;
    color: $gray-lighter;

    span {
      font-size: 15px;
      line-height: 15px;

      &.icon-enlarge {
        font-size: 40px;
      }
    }
  }
}

.contact-column-b2b {
  @include tablet {
    padding-top: 100px;
  }
}

.footer-middle-elements {
  background-color: $rit-black;
  padding: 0 0 41px;
  margin-top: -1px;
  transition: 0.6s ease;

  @include tablet {
    padding: 0 40px 55px;
    margin-top: -29px;

    &.slide {
      margin-top: 0;
    }
  }

  @include desktop {
    padding: 0 96px 55px;
  }

  .accordion-wrapper {
    @include mobile-only {
      &::before {
        display: none;
      }
    }
  }

  // CRM 2.3 footer
  .onboarding-page-wrapper {
    @include mobile-only {
      padding-top: 48px;
    }

    .accordion-wrapper {
      display: none;
    }

    .footer-contact {
      margin: 0 auto;
    }

    .footer-logo {
      @include tablet {
        align-items: flex-end;
        bottom: unset;
        justify-content: unset;
        margin-top: 28px;
        position: relative;
        right: unset;
      }
    }

    .inner-wrapper {
      &::before {
        @include tablet {
          height: 0;
        }
      }
    }
  }

  .accordion-content {
    li {
      margin: 0;
      padding: 0;

      @include mobile-only {
        padding: 5px 0;
      }
    }
  }

  .accordion-item {
    @include tablet {
      width: 145px;
      vertical-align: top;
    }

    @include desktop {
      width: 195px;
    }
  }

  .column-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .inner-wrapper {
    @include tablet {
      padding: 35px 20px 0;
      display: flex;
      position: relative;

      &::before {
        content: '';
        width: calc(100% - 40px);
        height: 1px;
        position: absolute;
        left: 20px;
        top: 0;
        background-color: $spanish-gray;
        display: block;
      }
    }
  }

  .wrapper__country-selector {
    display: flex;
    flex-direction: column;

    label {
      color: $white;
      font-size: 14px;
      font-weight: 350;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: left;
      text-transform: lowercase;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .country-selector {
      position: relative;

      .arrow-down {
        pointer-events: none;
        position: absolute;
        right: 16px;
        top: 12px;

        i {
          @include icon(arrow3--down);
          color: $rit-black;
          font-size: 24px;
        }

        @include desktop {
          display: none;
        }
      }
    }

    a {
      color: $rit-copper-lighter;
      display: inline-block;
      font-size: 12px;
      font-weight: 350;
      letter-spacing: 2px;
      line-height: 16px;
      margin-top: 32px;
      text-transform: uppercase;
    }

    .select-box {
      appearance: none;
      background-color: $rit-black-lighter;
      background-position: 16px 15px;
      background-size: 24px 18px;
      color: $white;
      font-family: $font-body;
      font-size: 16px;
      font-weight: 350;
      letter-spacing: 0.6px;
      line-height: 24px;
      overflow: hidden;
      padding: 12px 48px 12px 56px;
      width: 100%;

      &.language {
        padding-left: 16px;
      }
    }

    .select-box__container:first-of-type {
      margin-bottom: 16px;
    }

    .selectric-wrapper {
      & ~ .arrow-down {
        display: none;
      }

      .selectric {
        background: $rit-black-lighter;
        border: 0;
        padding: 12px 48px 12px 56px;

        .label {
          color: $white;
          display: block;
          font-family: $font-body;
          font-size: 16px;
          font-weight: 350;
          letter-spacing: 0.6px;
          line-height: 24px;
          padding: 0;
          text-transform: none;
        }

        .button {
          &::before {
            display: none;
          }

          &::after {
            border-color: $rit-black;
            top: 12px;
          }
        }
      }

      .selectric-hide-select {
        height: auto;
        left: 16px;
        pointer-events: none;
        position: absolute;
        top: 15px;
        width: 24px;
        z-index: 1;

        & .language {
          display: none;
        }

        .select-box {
          background-position: left top;
          background-size: 24px auto;
          border-bottom: 0;
          float: left;
          font-size: 0;
          height: 18px;
          padding: 0;
        }
      }

      .selectric-items ul li {
        padding: 17px 38px 17px 20px;

        &.selected,
        &:hover {
          position: relative;

          &::after {
            @include icon-styles;
            color: $rit-copper-dark;
            content: icon-char(check);
            display: inline-block;
            font-size: 12px;
            position: absolute;
            right: 17px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &.selectric-language {
        .selectric {
          padding-left: 16px;
        }
      }
    }
  }

  .footer-right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 57px;
    position: relative;

    @include tablet {
      margin: 0 0 0 auto;
      max-width: 30%;
    }

    .footer-column {
      @include mobile-only {
        margin: 0 15px;
      }
    }
  }

  .footer-logo {
    align-items: flex-start;
    align-self: center;
    display: flex;
    margin-top: 28px;

    @include tablet {
	    justify-content: flex-end;
      bottom: 5px;
      margin-top: 0;
      position: absolute;
      right: 7px;
    }

    & > img {
      height: 88px;
      width: 92px;
    }

    .b-corp-logo {
      display: flex;
      flex-direction: column;
      margin-left: 40px;

      a {
        color: $white;
        font-family: 'Helvetica Neue, sans-serif';
        font-size: 6.5px;
        line-height: 8.45px;
        margin: 2px auto 0;
        text-align: center;
        text-decoration: underline;
        width: 64px;
      }

      img {
        margin: 0 auto;
        width: 45px;
      }
    }
  }

  .footer-contact {
    margin: 48px auto 0;
    max-width: 255px;

    @include mobile-only {
      width: 100%;
    }

    @include tablet {
      margin: 10px 0 0;
      max-width: none;
    }

    h3 {
      margin-bottom: 22px;
    }
  }
}

.footer-column {
  h3,
  .h3 {
    color: $white;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 0.75px;
    font-family: $font-body;
    line-height: 20px;
  }

  .h3 {
    margin-bottom: 16px;
  }

  li {
    list-style: none;

    a {
      font-family: $font-body;
      color: $gray-lighter;
      font-size: 14px;
      letter-spacing: 0.7px;
      line-height: 27px;
      text-decoration: none;
      text-transform: none;

      @include tablet {
        line-height: 7.5px;

        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  table {
    td {
      color: $gray-lighter;
      font-size: 14px;
      letter-spacing: 0.7px;
    }

    .secondary {
      color: rgba($gray-lighter, 0.8);
      padding-left: 23px;
    }
  }

  .contact-line {
    display: flex;

    span {
      color: $gray-lighter;
      font-size: 14px;
      letter-spacing: 0.7px;

      &:nth-child(even) {
        color: rgba($gray-lighter, 0.8);
        padding-left: 23px;
      }
    }
  }

  &.two-columns {
    align-items: flex-start;
    display: flex;

    @include mobile-only {
      flex-direction: column;
    }

    @include tablet {
      padding-top: 20px;
    }
  }

  .footer-information {
    margin: 10px 0 0 auto;

    @include mobile-only {
      margin: 48px auto 0;
      max-width: 255px;
      width: 100%;
    }

    h3 {
      margin-bottom: 22px;
    }
  }
}

.footer-bottom-elements {
  background-color: $white;
  padding: 24px 0;
  text-align: center;
  width: 100%;

  .inner-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .copyright {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin-bottom: 2px;
    color: $gray-medium;
    order: 2;
  }

  @include tablet {
    align-content: center;
    display: flex;
    padding: 20px 0;

    .inner-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .copyright {
      flex-shrink: 0;
      margin: 5px 0;
      width: 100%;
    }
  }

  @include desktop {
    min-height: 80px;
    padding: 20px 0;

    .inner-wrapper {
      padding: 0 40px;
    }

    .copyright  {
      margin: 0 42px 0 0;
      width: auto;
    }
  }

  @include desktop-small {
    .copyright {
      margin: 0 20px 0 0;
    }
  }

  .accessibility-statement-link {
    display: none;
  }
}

.bottom-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  list-style: none;
  margin: 5px 0 10px;
  order: 3;
  width: 100%;

  li {
    color: $rit-copper;
    display: inline-block;
    margin-right: 12px;
    position: relative;

    &::after {
      content: '|';
      position: absolute;
      right: -11px;
      top: 0;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  a {
    font-size: 12px;
    letter-spacing: .8px;
    line-height: 16px;
    text-decoration: none;
    color: $gray-medium;
  }

  @include tablet {
    width: auto;
    margin: 0;

    li {
      margin-right: 15px;
    }

    a {
      letter-spacing: .5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include desktop {
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.payment-links {
  list-style: none;
  margin-bottom: -6px;
  order: 1;
  width: 100%;
  padding: 10px 0;

  li {
    display: inline-block;
    margin: 0 6px 10px;
    vertical-align: middle;

    &.big-ico-wrapper {
      display: none;
    }

    &.small-ico-wrapper {
      display: inline-block;
    }
  }

  .pay-apple-pay-footer {
    display: none;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media, _:future, :root, .pay-apple-pay-footer {
      display: inline-block;
    }
  }

  @include tablet {
    margin: 0 5px 0 0;
    width: 100%;
    text-align: center;
    padding: 22px 0;

    li:not(:first-child) {
      margin: 0 0 0 20px;

      &.big-ico-wrapper {
        display: inline-block;
      }

      &.small-ico-wrapper {
        display: none;
      }
    }

    .ico {
      float: left;
    }
  }

  @include desktop {
    li:not(:first-child) {
      margin: 0 0 0 30px;
    }
  }

  @include desktop-small {
    li {
      margin: 0 0 0 15px;
    }
  }
}

//footer on order confirmation page
.footer--contact {
  display: flex;
  background-color: $rit-black;
  min-height: 88px;
  padding: 30px 0 50px;

  @include tablet-landscape {
    justify-content: space-between;
    padding: 20px 0;
  }

  @include desktop {
    justify-content: space-between;
    padding: 20px 0;
  }

  .footer-content-wrapper {
    width: 100%;
  }

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;

    @include tablet-landscape {
      padding: 0 60px 0 30px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    @include desktop {
      padding: 0 60px 0 30px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .footer-contact-info-wrapper {
    margin-bottom: 41px;

    @include tablet-landscape {
      margin-bottom: 0;
    }

    @include desktop {
      margin-bottom: 0;
    }
  }

  .contact-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include tablet-landscape {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }

    @include desktop {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }

    img {
      max-width: 32px;
      display: inline-block;
      margin-bottom: 22px;

      @include tablet-landscape {
        margin: 0 24px 0 0;
      }

      @include desktop {
        margin: 0 24px 0 0;
      }
    }

    .contact-message {
      display: inline-block;
      color: $white;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 24px;

      @include tablet-landscape {
        margin-top: 2px;
      }

      @include desktop {
        margin-top: 2px;
      }

      b {
        display: block;
      }
    }
  }

  .footer-contact-links-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-links {
    display: flex;
    margin-top: -7px;

    .contact-link {
      width: 50%;
      text-align: center;

      @include tablet-landscape {
        width: auto;
        text-align: left;
        margin-right: 0;
      }

      @include desktop {
        width: auto;
        text-align: left;
        margin-right: 0;
      }

      &:first-of-type {
        margin-right: 16px;

        @include tablet-landscape {
          margin-right: 114px;
        }

        @include desktop {
          margin-right: 114px;
        }
      }

      a,
      .contact-phone {
        display: flex;
        flex-direction: column;
        color: $gray-lighter;
        text-decoration: none;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 14px;

        @include tablet-landscape {
          display: inline-block;
        }

        @include desktop {
          display: inline-block;
        }

        .icon {
          font-size: 30px;
          margin: 0 0 19px;

          @include tablet-landscape {
            font-size: 20px;
            margin: 0 15px 0 0;
          }

          @include desktop {
            font-size: 20px;
            margin: 0 15px 0 0;
          }
        }
      }
    }
  }
}

//add top padding to empty footer if the customer service block content
//asset is offline or doesn't exist
.footer--empty-position {
  padding-top: 50px;
}

// newsletter block
.stay-informed {
  padding: 53px 40px 40px;
  position: relative;
  background-color: rgba($rit-black, 0.96);

  @include tablet {
    padding: 48px 40px 53px;
  }

  @include desktop {
    padding: 48px 96px 53px;
  }

  .newsletter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include tablet {
      align-items: stretch;

      > div {
        flex-shrink: 1;
      }
    }

    @include mobile-only {
      padding: 0;
    }

    .text-form-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @include tablet {
        flex-direction: row;
      }
    }

    h3 {
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 36px;
      margin-bottom: 15px;
      text-transform: none;
      color: $white;

      @include tablet {
        margin-bottom: 8px;
        max-width: 350px;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      color: $spanish-gray;
      margin-bottom: 0;

      @include tablet {
        max-width: 700px;
      }
    }

    a {
      color: $rit-copper-lighter;
    }

    .emailform {
      width: 100%;

      @include tablet {
        flex-shrink: 0;
        width: auto;
      }

      @include tablet-portrait {
          margin-top: -7px;
      }

      @include desktop {
        flex-shrink: 1;
        width: 100%;
      }

      .input-row.floating-label {
        margin-top: 13px;

        @include tablet-portrait {
          margin-top: 0;
        }
      }

      input.error {
        border-color: $red-error-on-black;
      }

      form {
        margin: 0 auto;
        margin-top: 20px;
        width: 100%;

        @include tablet {
          padding-left: 20px;
          margin-top: 0;
        }

        .form-row {
          display: flex;
          flex-direction: column;
          max-width: none;

          @include tablet-portrait {
            flex-direction: column;
          }

          @include tablet-landscape {
            flex-direction: row;
          }

          @include desktop {
            flex-direction: row;
          }

          .form-row {
            width: 100%;
            margin-bottom: 20px;

            @include tablet-portrait {
              margin-bottom: 20px;
            }

            @include tablet-landscape {
              margin-bottom: 0;
            }

            @include tablet {
              width: 100%;
              max-width: 400px;
              min-width: 250px;
              margin-right: 20px;
            }

            @include desktop {
              margin-bottom: 0;
            }
          }

          .field-wrapper {
            width: 100%;
          }

          input {
            background-color: transparent;
            color: $white;
          }

          input:not(.inputfield-text) {
            @include desktop {
              &:hover,
              &:focus {
                border-bottom: 1px solid $rit-copper-dark;
              }
            }

            &.input-text {
              font-size: 14px;
              padding-right: 50px;
              width: 100%;
            }
          }

          .inputfield-row {
            position: relative;

            span.error {
              top: 0;

              @include desktop {
                bottom: -16px;
                position: absolute;
                right: 0;
                top: auto;
              }
            }
          }

          .input-row {
            span {
              &.error {
                position: absolute;
                color: $red-error-on-black;
              }
            }
          }
        }

        .inputfield-row label {
          color: $white;
        }

        .input-row.floating-label label {
          color: $gray-lighter;
        }
      }
    }

    .button-wrapper {
      display: flex;
      align-items: flex-end;

      @include tablet-portrait {
        justify-content: flex-end;
      }
    }

    button.last {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 2px;
      width: auto;
    }
  }
}

.wrapper__inner.simple-page-include {
  padding: 85px 65px 91px;
  text-align: center;

  @include tablet {
    padding: 107px 0;
  }

  h1 {
    font-size: 30px;
    letter-spacing: 1.5px;
    line-height: 46px;
    color: $rit-dark;
    text-transform: none;
    margin-bottom: 0;

    @include tablet {
      font-size: 50px;
      letter-spacing: 2px;
      line-height: 60px;
      margin-bottom: 6px;
    }
  }

  span {
    display: block;
    margin: 0 auto;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;
    color: $rit-dark;
    font-family: $font-header;

    @include tablet {
      max-width: 565px;
      font-size: 30px;
      letter-spacing: 0.9px;
      line-height: 45px;
    }
  }
}

//country selector
.country-selector-dialog {
  position: fixed !important;
  background-color: $white;
  padding: 33px 15px 37px;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  text-align: center;
  max-height: 90%;
  overflow: auto;

  @include tablet-landscape {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 90% !important;
    padding: 40px 35px 50px;
  }

  @include desktop {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50.1%); //add 0.1% beacause of blurriness on Chrome
    max-width: 600px;
    width: 90% !important;
    padding: 40px 35px 50px;
  }

  .store-selector-edit-address,
  .locale-store-selector__wrapper {
    opacity: 1;
  }

  .ui-dialog-titlebar {
    padding: 0;
  }

  .ui-dialog-title {
    width: 100%;
    float: none;
    white-space: normal;

    .title {
      font-size: 30px;
      letter-spacing: 4px;
      line-height: 40px;
      max-width: 310px;
      color: $black;
      text-transform: none;
      width: 100%;
      font-family: $font-header;
      margin: 6px auto 13px;

      @include tablet {
        max-width: 400px;
        margin-top: 0;
      }
    }
  }

  .description {
    margin-bottom: 35px;

    @include tablet {
      padding: 0 30px;
      margin-bottom: 40px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
    }
  }

  &.account-country-dialog {
    .ui-dialog-title {
      display: none;
    }

    .description {
      padding: 0;
      margin-bottom: 25px;

      @include tablet-landscape {
        padding: 32px 88px 0;
        margin-bottom: 41px;
      }

      @include desktop {
        padding: 32px 88px 0;
        margin-bottom: 41px;
      }

      p {
        font-family: $font-header;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.6px;
      }
    }
  }

  .locale-links-container {
    .row {
      display: flex;
      flex-direction: row;
      max-width: 282px;
      margin: 0 auto;

      &:first-of-type {
        margin-bottom: 27px;
      }

      @include tablet {
        max-width: 268px;

        &:first-of-type {
          margin-bottom: 18px;
        }
      }
    }
  }

  .link-to-locale {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: none;
    line-height: 20px;
    text-align: left;

    @include tablet-landscape {
      font-size: 14px;
      letter-spacing: 0.5px;
    }

    @include desktop {
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .flag {
    display: flex;
    align-items: flex-start;
    padding-top: 3px;
    margin-top: -2px;
    margin-right: 12px;

    @include tablet {
      align-items: center;
      margin-right: 11px;
      padding-top: 0;
    }

    img {
      width: 24px;
      height: 18px;
    }
  }

  .ui-dialog-titlebar-close {
    position: absolute;
    right: -2px;
    top: -18px;
    margin: 0;
    width: auto;
    height: auto;

    @include tablet {
      right: -2px;
      top: -20px;
    }

    @include desktop {
      right: -20px;
      top: -25px;
    }

    span {
      @include icon(close);
      display: inline-block;
      font-size: 18px;
      line-height: 1;
    }
  }

  .title-placeholder-hidden {
    display: none;
  }
}

.store-selector-edit-address {
  display: none;
}

.locale-store-selector__wrapper {
  opacity: 0;
}

//Back to top button
.scroll-to-top-link {
  position: fixed;
  background-color: rgba($white, 0);
  text-transform: uppercase;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  text-align: right;
  z-index: 10;
  max-width: 1215px;
  width: 100%;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  transition: opacity $quaternary-duration $filters-ease;

  &.not-fixed {
    position: absolute;
    bottom: 100%;
    z-index: 100;
  }

  &.not-fixed:not(.shown),
  &.shown {
    opacity: 1;
    visibility: visible;
  }

  @include mobile-only {
    &.hide-on-mobile.shown:not(.not-fixed) {
      opacity: 0;
      visibility: hidden;
    }
  }

  .scroll-to-top-wrapper {
    background-color: rgba($white, 0.9);
    width: 101px;
    height: 60px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    margin-top: -59px;

    &:hover {
      background-color: $white;
      transition: $primary-delay ease-in-out;
    }

    a {
      display: inline-block;
      position: relative;

      span {
        font-size: 13px;
        color: $gray-dark;
      }
    }
  }
}

//footer on maintenance page
.footer-maintenance {
  display: flex;
  align-items: center;
  padding: 50px 0 85px;
  background-color: $rit-black;

  @include tablet {
    padding: 26px 0 50px;
  }

  .footer-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    max-width: 1115px;

    @include tablet {
      flex-direction: row;
    }
  }

  .social-icons-wrapper {
    .social-icons {
      display: flex;
      justify-content: center;

      @include tablet {
        justify-content: space-between;
      }
    }

    .text {
      display: block;
      font-size: 15px;
      letter-spacing: 0.75px;
      color: $white;
      margin-bottom: 17px;
      text-align: center;

      @include tablet {
        margin-bottom: 12px;
      }
    }

    a {
      text-decoration: none;
      margin: 0 14px;

      @include tablet {
        margin: 0 -3px;
      }

      span {
        color: #737373;
        font-size: 15px;
      }
    }
  }

  .ico {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;

    @include tablet {
      position: absolute;
      top: 50%;
      right: 0;
      left: auto;
      transform: translate(0, -50%);
    }
  }
}
