// **************************
//        SLICK SLIDER
// **************************
.slick-slider {
  .slick-arrow {
    align-self: center;
  }
}

.slider-button {
  text-decoration: none;
  align-items: center;
  border-radius: 50%;
  color: $black;
  display: flex;
  font-size: 44px;
  height: 50px;
  justify-content: center;
  transition: background $quaternary-duration $default-ease-out;
  width: 50px;

  &:hover {
    background-color: $white;

    @media (hover: none) {
      background-color: transparent;
    }
  }

  &:focus:not(.focus-visible) {
    outline: 0;
  }
}

.slick-disabled {

  .slider-button {
    color: $gray-disabled;
    opacity: 0;
    visibility: hidden;
  }
}

// **************************
//        SLIDER BAR
// **************************
.slider-bar {
  display: none; // will be displayed with js, on slider init
  width: 100%;
  max-width: 300px;
  height: 2px;
  border-radius: 2px;
  background-color: $slider-bar-gray;
  position: relative;
  margin: 20px auto;

  @include tablet {
    height: 4px;
    border-radius: 4px;
  }

  .indicator {
    display: block;
    position: absolute;
    background-color: $slider-indicator-gray;
    height: 100%;
    border-radius: 4px;
    left: 0;
    transition: left .2s ease-out;
  }
}

.slider-dots {
  bottom: 0;
  left: 50%;
  max-width: 300px;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;

  .slick-dots {
    display: flex;
    height: 2px;
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  li {
    display: block;
    flex-grow: 1;
  }

  button {
    background-color: transparent;
    display: block;
    font-size: 0;
    height: 2px;
    width: 100%;
  }

  @include mobile-only {
    pointer-events: none;
  }

  @include tablet {
    .slick-dots {
      height: 4px;
    }

    button {
      height: 4px;
    }
  }
}
