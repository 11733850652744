// ************************
// ======== COLORS ========
// *************************

// — General colors —
$rit-copper: #9a7b69;
$rit-copper-dark: #886655;
$rit-copper-light: #968977;
$rit-copper-lighter: #b8a188;
$rit-copper-medium: #b8a388;
$rit-copper-on-black: #807463;
$rit-black: #1a1919;
$rit-black-medium: #1a1819;
$rit-black-lighter: #242222;
$rit-dark: #453f3f;
$rit-dark-crm: #3a383a;
$rit-sandy-bg: #f6f4f2;
$rit-sandy-light: #fcfaf7;
$rit-sandy-dark: #efedea;
$rit-gold: #deca87;
$rit-gold-dark: #a7884d;
$pink-light: #cca1a6;
$yellow-shade: #d29f13;
$cardinal: #ba0c2f;
$terrazzo-brown: #a58673;
$snow-white: #faf9f7;
$voodoo: #452f3f;

$white: #ffffff;
$black: #000000;

$crimson: #d6122d;
$green: #649b26;
$green-dark: #437d00;
$light-green: #e2ead7;
$dark-blue: #1c2c4c;
$navy-blue: #02192b;
$dark-navy: #031a2b;
$red-warning: #ad4141;
$dark-purple: #1c0826;
$refill-green: #636e46;
$refill-white: #f3f5f1;
$ahoy-blue: #0181A1;
$amazon-gold1: #a88734;
$amazon-gold2: #9c7e31;
$amazon-gold3: #846a29;
$amazon-gold-gradient1: #f7dfa5;
$amazon-gold-gradient2: #f0c14b;
$amazon-gold-hover1: #f5d78e;
$amazon-gold-hover2: #eeb933;
$amazon-grey1: #adb1b8;
$amazon-grey2: #a2a6ac;
$amazon-grey3: #8d9096;
$amazon-grey-gradient1: #f7f8fa;
$amazon-grey-gradient2: #e7e9ec;
$amazon-grey-hover1: #f0f4fc;
$amazon-grey-hover2: #dde2e9;

// — All shades of gray —
// for help with naming check http://www.color-blindness.com/color-name-hue/
$gray-dark: #626262;
$gray-medium: #767676;
$grey-medium-light: #7f7f7f;
$gray-very-light: #cfcfcf;
$gray-light: #999999;
$gray-lighter: #a4a4a4;
$grey-lightest: #edeae7;
$gray-lightest: #d2d2d2;
$gray-gainsboro: #d8d8d8;
$gray-suva: #8c8c8c;
$gray-gainsboro-light: #e8e8e8;
$gray-porcelain: #eae9e8;
$gray-silver: #aeaeae;
$white-smoke: #eaeaea;
$gray-concrete: #d1d0ce;
$gray-sand: #f5f4f2;
$gray-amber: #cabab0;
$gray-oopsform: #575757;
$gray-pills: #f3f3f3;
$spanish-gray: #979797;

// — Functional colors —
$gray-disabled: #b4b4b4;
$gray-lines: $gray-gainsboro;
$copper: #9a7b69;
$copper-medium: #896754;
$copper-medium-darker: #876957;
$red-error: #d6122d;
$red-error-on-black: #ef3d56;
$red-notification: #de4a4a;
$orange-warning: #b15930;

// — Ancient Rituals colors —
$ritual-ayurveda: #ac0a3d;
$ritual-dao: #485f1e;
$ritual-hammam: #004e70;
$ritual-karma: #72bdad;
$ritual-laughing-buddha: #d66a37;
$ritual-private: #453f3f;
$ritual-sakura: #e0969d;
$ritual-sakura-dark: #b43844;
$ritual-samurai: #4383d5;
$ritual-namaste: #726969;
$ritual-yalda: #64273e;
$ritual-holi: #c90e4d;
$ritual-karma: #227563;
$ritual-tiny-rituals: #427458;
$ritual-happy-buddha: #aa4b1e;
$ritual-tsuru: #ba0020;
$ritual-edp: #97745c;
$ritual-army: #474d40;
$ritual-jing: #3f6361;
$ritual-samurai: #626262;
$ritual-amsterdam-collection: #1c2c4c;
$ritual-oriental-essences: #97745c;
$ritual-green-elliott: #f7f4f3;

// — Easter colors —
$ritual-easter-yellow: #f5e9d3;
$ritual-easter-pink: #fae0dc;
$ritual-easter-blue: #e1f7fa;
$ritual-easter-dark-grey: #262424;
$ritual-easter-grey: #211f1f;
$ritual-easter-grey-sport: #e7e7e7;
$sakura: #fae0dc;
$karma: #e1f7fa;
$mehr: #f5e9d3;
$easter-karma-bg: #226e75;
$easter-sakura-bg: #c75f5f;
$easter-mehr-bg: #bd7a09;

//— Advent colors —
$advent-dark-blue: #0a152b;
$advent-light-gold: #a88b6b;
$advent-medium-gold: #a18762;
$advent-lighter-blue: #153163;
$advent-shimmer: #e7d0b1;
$advent-shimmer-light: #e4d6b5;
$advent-tape: #a88c77;
$advent-tape-darker: #aa8e79;
$advent-tape-light: #dfd1b1;
$advent-sandy: #e4d7b6;
$advent-sandy-dark: #9b7c6a;
$advent-shimmer-light2: #e4d7b6;
$advent-shimmer-dark: #deb9a3;
$advent-shimmer-darker: #9b7c6a;
$advent-shimmer-darkest: #a18762;
$advent-shimmer-orange: #e6be8a;
$advent-shimmer-orange2: #e5c3a7;
$advent-shimmer-swatch: #cdbda4;
$advent-shimmer-swatch2: #e2d4b3;
$advent-shimmer-text: #c4b799;
$advent-shimmer-text2: #eeccaf;
$advent-shimmer-text3: #fce9c6;
$advent-shimmer-text4: #fce9c6;
$advent-bg: #122343;
$advent-bg-pdp-light: #263762;

//— Ancient Rituals background colors —
$ritual-default-bg: #f6f4f2;
$ritual-ayurveda-bg: #f6f4f2;
$ritual-dao-bg: #eff2e9;
$ritual-hammam-bg: #e4e9eb;
$ritual-hommes-bg: #1d1c24;
$ritual-hommes-light-bg: #24232b;
$ritual-karma-bg: #e6f1ee;
$ritual-laughing-buddha-bg: #f6f4f2;
$ritual-private-bg: #f6f4f2;
$ritual-sakura-bg: #fbf3f4;
$ritual-samurai-bg: #f6f4f2;

// — Sustainability Colors —
$sustainability-green: #8c9476;

// — Advent Colors —
$advent-gray: #eeede9;

// — Component colors —
$slider-bar-gray: #d1d1d1;
$slider-indicator-gray: $rit-dark;

// - Callout colors -
$callout-red: #f3e1e3;
$callout-grey: #d8d8d8;
$callout-green: #dfe4d5;
$callout-green-light: #e2ead7;

// ***************************
// ======== TYPOGRAPHY ========
// ****************************

$font-body: 'Scala Sans Pro', 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
$font-body-bold: 'Scala Sans Pro Bold', 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
$font-body-italic: 'Scala Sans Pro Italic', 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
$font-body-regular: 'Scala Sans Pro Regular', 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
$font-header: 'ClassGarmnd BT', 'Times New Roman', serif;
$font-header-bold: 'ClassGarmnd BT Bold', 'Times New Roman', serif;
$font-header-italic: 'ClassGarmnd BT Italic', 'Times New Roman', serif;


// ******************************
// ======== DEVICE WIDTHS ========
// *******************************

$tablet-width: 768px;
$tablet-small: 850px;
$desktop-width: 1024px;
$desktop-medium-width: 1160px;
$desktop-large-width: 1440px;
$desktop-extra-large-width: 1920px;
$desktop-ultra-wide: 2300px;


// ******************************
// ======== DEVICE HEIGHTS =======
// *******************************

$device-height-normal: 475px;
$desktop-height-small: 750px;


// ******************************
// =========== Z-INDEX ===========
// *******************************
$menu-index: 50;
$sticky-bar-index: 890;
$modal-overlay-index: 991;
$modal-window-index: 999;
$modal-close-index: 20;


// *********************************
// =========== ANIMATIONS ===========
// **********************************

$primary-duration: 2s;
$secondary-duration: 1.3s;
$tertiary-duration: 0.6s;
$quaternary-duration: 0.4s;
$primary-delay: 0.2s;
$navigation-default: 0.3s;
$default-ease-out: cubic-bezier(0.05, 0.5, 0.3, 1);
$default-ease-out-fast: cubic-bezier(0.18, 0.74, 0.36, 0.99);
$default-ease-out-faster: cubic-bezier(0.01, 1.11, 0.36, 0.99);
$default-ease-in-out: cubic-bezier(0.33, 0.16, 0.15, 0.7);
$filters-ease: cubic-bezier(0.645, 0.045, 0.355, 1);
$navigation-ease: cubic-bezier(0.32, 0.24, 0.15, 1);
