// Skeleton loader animation class
.skeleton-loader {
  border: 1px solid $gray-gainsboro;
  height: 100%;
  padding: 24px;
  width: 100%;
}

.skeleton-mask {
  animation: skeletonAnimation 1s infinite linear;
  background-image: linear-gradient(to right,
    rgba($gray-disabled, 0.5) 0%,
    rgba($gray-disabled, 0.15) 20%,
    rgba($gray-disabled, 0.15) 25%,
    rgba($gray-disabled, 0.1) 30%,
    rgba($gray-disabled, 0.05) 40%,
    rgba($gray-disabled, 0) 45%,
    rgba($gray-disabled, 0) 50%,
    rgba($gray-disabled, 0) 55%,
    rgba($gray-disabled, 0.05) 60%,
    rgba($gray-disabled, 0.1) 70%,
    rgba($gray-disabled, 0.15) 75%,
    rgba($gray-disabled, 0.15) 80%,
    rgba($gray-disabled, 0.5) 100%);
  background-size: 200% 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:not(.square) {
    min-height: 104px;
    -webkit-mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzI5cHgiIGhlaWdodD0iMTI4cHgiIHZpZXdCb3g9IjAgMCA3MjkgMTI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPl9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfbGFyZ2VfaW52ZXJ0ZWQ8L3RpdGxlPgogICAgPGcgaWQ9IvCfmqstZXJyb3JzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iX3JpdHVhbHNfZ3JhZGllbnRfd2luZG93X2xvYWRlcl9sYXJnZV9pbnZlcnRlZCI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLUNvcHktOSIgcG9pbnRzPSI3MjkgMTA0IDAgMTA0IDAgMTI4IDcyOSAxMjgiPjwvcG9seWdvbj4KICAgICAgICAgICAgPHBhdGggZD0iTTY0OSw4MCBMNjQ5LDEwNCBMMCwxMDQgTDAsODAgTDY0OSw4MCBaIE03MjksODAgTDcyOSwxMDQgTDY2NiwxMDQgTDY2Niw4MCBMNzI5LDgwIFogTTY0OSw0MCBMNjQ5LDY0IEwwLDY0IEwwLDQwIEw2NDksNDAgWiBNNzI5LDQwIEw3MjksNjQgTDY2Niw2NCBMNjY2LDQwIEw3MjksNDAgWiBNMjMsMCBMMjMsMjQgTDAsMjQgTDAsMCBMMjMsMCBaIE03MjksMCBMNzI5LDI0IEw3MDYsMjQgTDcwNiwwIEw3MjksMCBaIE02ODksMCBMNjg5LDI0IEw0MCwyNCBMNDAsMCBMNjg5LDAgWiIgaWQ9Il9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfbGFyZ2VfaW52ZXJ0ZWQtY29weSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
    -webkit-mask-repeat: repeat-y;
  }

  &.square {
    min-height: 128px;
    -webkit-mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTUycHgiIHZpZXdCb3g9IjAgMCAyMDAgMTUyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPl9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfc21hbGxfaW52ZXJ0ZWQ8L3RpdGxlPgogICAgPGcgaWQ9IvCfmqstZXJyb3JzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iX3JpdHVhbHNfZ3JhZGllbnRfd2luZG93X2xvYWRlcl9zbWFsbF9pbnZlcnRlZCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNjgsMTA0IEwxNjgsMTI4IEwwLDEyOCBMMCwxMDQgTDE2OCwxMDQgWiBNMTY4LDcyIEwxNjgsOTYgTDAsOTYgTDAsNzIgTDE2OCw3MiBaIE0xNjgsNDAgTDE2OCw2NCBMMCw2NCBMMCw0MCBMMTY4LDQwIFogTTE2OCwwIEwxNjgsMjQgTDAsMjQgTDAsMCBMMTY4LDAgWiBNMjAwLDAgTDIwMCwyNCBMMTc2LDI0IEwxNzYsMCBMMjAwLDAgWiIgaWQ9Il9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfc21hbGxfaW52ZXJ0ZWQtY29weSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC1Db3B5LTciIHBvaW50cz0iMjAwIDEyOCAwIDEyOCAwIDE1MiAyMDAgMTUyIj48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    -webkit-mask-repeat: repeat-y;
  }
}

@keyframes skeletonAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
