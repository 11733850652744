// thank you page
.thank-you-page {
  background-color: $rit-sandy-dark;
  position: relative;
  width: 100%;

  h1 {
    text-align: center;
    text-transform: none;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 34px;
      margin: 40px 0;
    }

    @include tablet {
      letter-spacing: 0.75px;
      margin: 60px 0;
    }
  }

  .thank-you-inner {
    background-color: $rit-sandy-bg;
    width: 100%;

    @include tablet {
      padding: 60px 0;
    }

    .inner-wrapper {
      display: flex;

      @include mobile-only {
        flex-direction: column;
        padding: 0;
      }

      @include tablet {
        max-width: 1360px;
      }
    }
  }

  .thank-you-left-col {
    width: 100%;

    @include tablet {
      margin-right: 3%;
      width: 36.3%;
    }
  }

  .thank-you-right-col {
    width: 100%;

    @include tablet {
      width: 60.7%;
    }
  }
}

// sticky top message
.sticky-top-message-wrap {
  position: relative;
  width: 100%;

  .sticky-top-message-bar-placeholder {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.in-place {
      left: auto;
      position: relative;
      top: auto;
    }
  }
}

.static-info-banner  {
  width: 100%;

  @include desktop  {
    left: 0;
    position: fixed;
    z-index: 981;
  }

  @include tablet-landscape {
    top: 69px;
  }

  @include desktop-only {
    top: 95px;
  }

  + .header-spacer {
    @include desktop {
      height: 144px !important;
    }

    @include tablet-landscape {
      height: 117px !important;
    }
  }
}

.sticky-top-message-bar {
  background-color: $rit-copper;
  padding: 14px 0 10px;
  position: relative;
  transform: translateY(-100%);
  transition: $tertiary-duration transform $navigation-ease;
  width: 100%;
  z-index: 10;

  @include tablet {
    padding: 16px 0 12px;
  }

  &.loaded {
    transform: translateY(0);
  }

  &.sticky {
    left: 0;
    position: fixed;
    top: 0;
  }

  &.not-animated {
    transform: translateY(0);
  }

  .inner-wrapper {
    align-items: center;
    display: flex;

    @include mobile-only {
      padding: 0 26px;
    }

    @include tablet {
      justify-content: center;
    }
  }

  .icon {
    color: $white;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 10px;
    margin: -2px 16px 0 0;

    @include mobile-only {
      margin: -3px 15px 0 0;
    }
  }

  p {
    color: $white;

    @include mobile-only {
      font-size: 11px;
      letter-spacing: 0.75px;
      line-height: 16px;
    }

    @include tablet {
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      line-height: inherit;
    }
  }
}

// steps block
.steps-content-block {
  padding: 40px 24px 24px;
  width: 100%;

  @include tablet {
    max-width: 450px;
    padding: 0;
  }

  h3 {
    letter-spacing: 0.6px;
    margin-bottom: 26px;
    text-transform: none;

    @include mobile-only {
      font-size: 20px;
      line-height: 30px;
    }

    @include tablet {
      font-size: 28px;
      line-height: 40px;
    }
  }

  .items-list {
    margin-bottom: 38px;
    width: 100%;
  }

  .item {
    margin-bottom: 24px;
    padding-left: 72px;
    position: relative;
    width: 100%;

    @include tablet {
      padding-left: 74px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .icon {
    font-size: 56px;
    color: $rit-copper;
    left: -4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include tablet {
      left: -3px;
    }
  }

  p {
    font-size: 13px;
    line-height: 24px;

    @include tablet {
      letter-spacing: 0.8px;
    }
  }

  .btn-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .btn {
    margin-bottom: 16px;
    @include mobile-only {
      max-width: 100%;
      width: 100%;
    }
  }
}

// magazine article block
.magazine-article-content-block {
  background-color: $white;
  padding: 40px 24px 60px;
  width: 100%;

  @include tablet {
    padding: 65px 63px 64px;
  }

  .mag-article-block-title {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 34px;
    margin-bottom: 8px;
    text-transform: none;

    @include tablet {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 19px;
    }
  }

  .mag-article-block-description {
    font-size: 13px;
    line-height: 24px;

    @include tablet {
      letter-spacing: 0.75px;
    }
  }
}

.mag-article-preview {
  padding-top: 12px;
  text-align: center;
  width: 100%;

  @include tablet {
    padding-top: 20px;
  }

  .image-holder {
    display: block;
    margin-bottom: 18px;
    width: 100%;

    @include tablet {
      margin-bottom: 23px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .text-holder {
    width: 100%;

    @include tablet {
      margin: 0 auto;
      max-width: 500px;
    }

    p {
      font-size: 16px;
      letter-spacing: 0.7px;
      line-height: 27px;
      margin-bottom: 14px;

      @include tablet {
        font-size: 15px;
        letter-spacing: 0.75px;
        line-height: 26px;
        margin-bottom: 21px;
      }
    }
  }

  .mag-article-categories {
    font-size: 0;
    margin-bottom: 4px;
    text-align: center;
    width: 100%;

    @include tablet {
      margin-bottom: 12px;
    }

    li {
      display: inline-block;
      list-style: none;
      margin-right: 15px;
      padding-right: 15px;
      position: relative;

      &::after {
        background-color: $gray-lines;
        content: '';
        display: inline-block;
        height: 18px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;

        &::after {
          display: none;
        }
      }
    }

    a {
      display: inline-block;
      font-family: $font-header;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 4px;
      text-decoration: none;
      text-transform: uppercase;

      @include tablet {
        letter-spacing: 1.5px;

        &:hover {
          color: $rit-black;
        }
      }
    }
  }

  .mag-article-title {
    margin-bottom: 18px;
    text-align: center;

    @include tablet {
      margin-bottom: 15px;
    }

    a {
      color: $rit-black;
      font-family: $font-header;
      font-size: 30px;
      line-height: 32px;
      text-decoration: none;
      text-transform: none;

      @include tablet {
        line-height: 37px;
        letter-spacing: 1.7px;
      }
    }
  }

  .mag-article-reading-time {
    font-family: $font-header;
    margin-bottom: 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @include tablet {
      font-size: 11px;
      margin-bottom: 15px;
    }

    .icon-ico-clock::before {
      margin-right: 12px;
      position: relative;
      top: -1px;
    }
  }

  .mag-article-read-more {
    font-family: $font-header;
    font-size: 11px;
    letter-spacing: 1.7px;
    text-decoration: underline;
    text-transform: uppercase;

    @include tablet {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .mag-article-read-all {
    padding-top: 30px;

    @include tablet {
      padding-top: 23px;
    }

    .btn {
      @include mobile-only {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
