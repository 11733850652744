.two-tile-horizontal {
  width: 100%;

  &.white {
    background-color: $white;
  }

  &.two-tile-pagedesigner {
    .image-holder {
      position: relative;
    }

    .image-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .image-holder {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .text-holder {
    padding: 35px 20px 40px;
    text-align: center;
    width: 100%;
  }

  .video-wrapper {
    margin-top: 25px;
  }

  .video-holder {
    display: none;
  }

  .video-image-holder {
    width: 100%;
  }

  .long-description {
    margin: 0 auto 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn-wrapper {
    @include mobile-only {
      margin: 24px 0;
    }

    @include tablet {
      margin: 30px 0;
    }
  }

  @include tablet {
    align-items: center;
    display: flex;
    min-height: 520px;
    padding: 30px 0;
    height: auto;

    .inner-wrapper {
      align-items: center;
      flex-direction: row;
      max-width: 1480px;
      padding: 0 20px;
    }

    .video-image-holder {
      width: 50%;

      .image-holder {
        display: none;
      }
    }

    .image-holder {
      flex-shrink: 0;
      width: 50%;

      img {
        display: block;
        margin: 0 auto;
        width: 72%;
        max-width: 520px;
      }
    }

    .video-holder {
      display: block;
      flex-shrink: 0;
      height: 0;
      position: relative;
      padding-bottom: 72%;
      width: 72%;
      margin: 0 auto;
    }

    .text-holder {
      width: 50%;
      padding: 30px;
    }

    .text-inner:not(.wide) {
      margin: 0 auto;
      max-width: 450px;

      .long-description {
        max-width: 380px;
        width: 100%;
      }
    }

    .long-description {
      line-height: 28px;
    }

    &.invert {
      .text-holder {
        order: -1;
        padding: 30px 70px 30px 20px;
      }
    }

    &.two-tile-horizontal-medium {
      min-height: 540px;

      .inner-wrapper {
        max-width: 1440px;
      }

      .two-tile-narrow-col {
        flex-shrink: 0;
        width: 27.4%;
      }

      .image-holder {
        width: 34.3%;
      }

      .text-holder {
        flex-shrink: 0;
        width: 38%;
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 760px;
    padding: 120px 0;

    &.two-tile-horizontal-medium {
      height: 540px;
      padding: 80px 0;
    }
  }
}
