.concept-highlight-block {
  padding: 26px 0 48px;
  width: 100%;

  .slick-dots {
    font-size: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 3px;
    }

    button {
      background-color: $gray-concrete;
      border-radius: 50%;
      display: inline-block;
      font-size: 0;
      height: 4px;
      width: 4px;
    }

    .slick-active button {
      background-color: $rit-dark;
    }
  }

  .col {
    margin-bottom: 50px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 100%;

    &:last-child {
      margin-bottom: 0;

      .text-holder {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .concept-highlight-heading {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 30px;
    text-align: center;
    text-transform: none;
    margin-bottom: 32px;

    + .col-wrapper img {
      margin-top: 0;
    }
  }

  .media-holder {

    img,
    .ico {
      display: block;
    }

    img {
      margin: 20px auto 35px;
      max-width: 250px;
    }

    .ico {
      font-size: 60px;
      line-height: 1;
      margin: 0 auto;
    }
  }

  .text-holder {
    padding-bottom: 8px;
    position: relative;
  }

  h4 {
    text-align: center;
  }

  h3 {
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 25px;
    font-family: $font-body;
  }

  p {
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
    color: $gray-medium;
    margin-bottom: 0;
  }

  @include mobile-only {
    .media-holder ~ .text-holder {
      padding-bottom: 0;
    }
  }

  @include tablet {
    padding: 40px 0;

    .inner-wrapper {
      max-width: 1140px;
    }

    .col-wrapper,
    .simplebar-content {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }

    .col {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
      max-width: 333px;
      opacity: 0;
      transition: opacity $tertiary-duration $filters-ease;
      padding: 0 10px;

      &.visible {
        opacity: 1;
      }
    }

    .concept-highlight-heading {
      font-size: 24px;
      letter-spacing: 0.46px;
      line-height: 44px;
      margin-bottom: 30px;
    }

    .media-holder {
      align-items: center;
      display: flex;
      justify-content: center;

      .media-inner {
        display: block;

          .ico {
            margin: 0 0 3px;
          }
      }
    }

    .text-holder {
      padding-bottom: 0;
      width: 100%;
    }
  }

  @include tablet-portrait {
    .col {
      max-width: 29%;
    }

    .text-holder::after {
      right: -18%;
    }
  }
}

.concept-highlight-block.drag-list {
  @include mobile-only {
    margin-bottom: 0;
    padding: 26px 0 55px;

    .inner-wrapper {
      padding: 0;
    }

    .concept-highlight-heading {
      padding: 0 20px;
    }

    .drag-list-body {
      padding: 0;
    }

    .col {
      flex-shrink: 0;
      margin-bottom: 20px;
      padding: 0 40px;
      width: 100vw;
    }
  }
}

// styling for easter concept highlight block

.concept-highlight-block-easter {
  @include mobile-only {
    padding: 30px 0;

    .slick-slide {
      text-align: center;
    }

    .col {
      max-width: 240px;
    }
  }

  @include tablet {
    .inner-wrapper {
      max-width: 820px;
    }

    .col {
      max-width: 240px;
    }

    .media-holder {
      .media-inner {
        .ico {
          margin: 0 0 10px;
        }
      }
    }
  }

  .media-holder {
    img {
      margin: 0 auto 10px;
      max-width: 70px;
    }

    .ico {
      font-size: 50px;
      line-height: 1;
      margin: 0 auto 10px;
    }
  }

  .text-holder {
    p {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }
  }
}

// styling for advent 2022 concept highlight block

.concept-highlight-block-advent {
  background-color: transparent;

  @include mobile-only {
    padding: 50px 0 30px;

    .slick-slide {
      text-align: center;
    }

    .col {
      max-width: 240px;
    }
  }

  @include tablet {
    opacity: 0;
    padding: 100px 0;

    .inner-wrapper {
      max-width: 990px;
    }

    .col {
      max-width: 240px;
    }

    .media-holder {
      .media-inner {
        .ico {
          margin: 0 0 10px;
        }
      }
    }
  }

  @include tablet-only {
    .inner-wrapper {
      max-width: 830px;
    }
  }

  .media-holder {
    img {
      margin: 0 auto 10px;
      max-width: 90px;
    }

    .ico {
      font-size: 50px;
      line-height: 1;
      margin: 0 auto 10px;
    }
  }

  .text-holder {
    p {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }
}

//styling for CRM 2.3 concept highlight block

.crm-in-store {
  .concept-highlight-block {
    padding: 20px 0 40px;

    .inner-wrapper {
      padding: 0 24px;
    }

    .col-wrapper {
      display: block;
    }

    .col {
      margin: 0;
      opacity: 1;
      padding: 0;

      h1 {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 24px;
        margin-bottom: 8px;
        text-transform: initial;
      }

      p {
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin-bottom: 40px;
      }
    }

    .media-inner {
      img {
        margin: 16px auto 16px;
        max-width: 90px;
      }
    }
  }
}

// Styling for images aside
.concept-highlight-block-img-aside {
  background-color: $rit-sandy-bg;

  .media-holder {
    .ico {
      font-size: 40px;
    }

    @include mobile-only {
      margin-bottom: 10px;
    }

    @include tablet {
      margin-right: 20px;
    }
  }

  .text-holder {
    p {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }

    @include mobile-only {
      margin: 0 auto;
      width: 70%;
    }

    @include tablet {
      display: flex;
      align-items: center;
    }
  }

  @include tablet {
    .col {
      align-items: center;
      display: flex;
      flex-wrap: initial;
      justify-content: initial;
      text-align: initial;
    }
  }
}
