.talisman-usp-block {
  background-color: $rit-black;
  padding: 60px 70px 80px;

  @include mobile-only {
    padding: 60px 0;
  }

  .usp-list {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
    }
  }

  .usp-list-inner {
    display: block;
  }

  .usp-component {
    margin: 0 auto;
    max-width: 360px;
    text-align: center;
  }

  .usp-text {
    color: $white;
    font-family: $font-body;
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .usp-header {
    color: $white;
    font-family: $font-header;
    font-size: 32px;
    letter-spacing: 1.28px;
    line-height: 39px;
    margin-bottom: 20px;
    text-align: center;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 0.96px;
      line-height: 29px;
    }
  }

  .usp-subheader {
    color: $white;
    font-family: $font-body;
    font-size: 16px;
    letter-spacing: 0.64px;
    line-height: 26px;
    margin: 0 auto;
    max-width: 330px;
    text-align: center;
  }

  .slick-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    padding-left: 25px;
    width: 100%;

    li {
      color: $rit-dark;
      height: 4px;
      margin: 0 4px;
      width: 4px;
    }

    .slick-active {
      color: $white;
    }

    button {
      display: none;
    }
  }
}
