//
// Snow Class
// Can be used on the body or any other element
// Source: https://codepen.io/keithclark/pen/yBcsr
// ----------------------------------------------------------------------------
//

// Density
$d: 10;

// Grid size
$w: 900;

// Speed
$s: 15s;

// Generate the snow image using radial gradients
$grad: ();

@for $i from 0 to $d {
  // Width of snowflake
  $v: random(4) + 2;

  // Alpha of snowflake
  $a: random(5) * .1 + .5;

  $grad: $grad, radial-gradient(
      $v+px $v+px at
      (random($w - $v * 2) + $v)+px
      (random($w - $v * 2) + $v)+px,
      rgba($white, 1) 50%,
      rgba($black, 0) 50%
  ) !global;
}

.snow:not(.snow-fixed),
.snow:not(.snow-fixed)::before,
.snow:not(.snow-fixed)::after {
  position: absolute;
}

.snow-fixed,
.snow-fixed::before,
.snow-fixed::after {
  position: fixed;
}

.snow,
.snow::before,
.snow::after {
  top: -$w + px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: $grad;
  background-size: $w + px $w + px;
  animation: snowing $s linear infinite;
  content: '';
}

.snow::after {
  animation-duration: $s*2;
  animation-direction: reverse;
  margin-left: calc(-#{$w} / 3) + px;
}

.snow::before {
  animation-duration: $s*3;
  animation-direction: reverse;
  margin-left: calc(-#{w} / 2) + px;
}

.snow-desktop {
  display: none;

  @include desktop-only {
    display: block;
  }
}

@keyframes snowing {
  to {
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, $w + px, 0);
  }
}
