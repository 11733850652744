.product-info-container {
  display: flex;

  @include mobile-only {
    flex-direction: column;
  }

  .product-info-column {
    width: 100%;

    @include tablet {
      width: 50%;
    }

    .responsive-video video {
      @include mobile-only {
        width: 100%;
        height: auto;
      }
    }
  }
}

.product-info-column-image {
  position: relative;
  min-height: 350px;

  @include tablet {
    min-height: 760px;
  }

  .image-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.product-info-column-details {
  padding: 40px 40px 60px;
  text-align: center;

  @include mobile-only {
    order: 2;
  }

  @include tablet {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }

  .product-info-content {
    @include tablet {
      max-width: 715px;
      padding: 60px 40px;
      width: 100%;
    }

    @include desktop {
      padding: 80px 110px;
    }

    @include desktop-large {
      padding: 80px 140px;
    }
  }

  h4 {
    color: $rit-copper-on-black;
    font-size: 10px;
    letter-spacing: 1.5px;
    margin-bottom: 16px;

    @include tablet {
      font-size: 12px;
      letter-spacing: 2px;
    }
  }

  h3 {
    color: $rit-black;
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
    margin-bottom: 21px;
    text-transform: none;

    @include desktop {
      font-size: 32px;
      letter-spacing: 1.5px;
      line-height: 50px;
      margin-bottom: 28px;
    }
  }

  p {
    color: $rit-black;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 24px;

    @include desktop {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 28px;
      margin-bottom: 18px;
    }
  }
}

.product-info-icons {
  display: flex;
  margin: 20px -20px 0;

  @include tablet {
    margin: 20px -30px 0;
  }

  @include desktop {
    margin: 62px -50px 0;
  }

  .item {
    width: 100%;

    @include tablet {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: left;
    }
  }

  .product-info-icon {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    color: $rit-copper-dark;
    display: flex;
    flex-shrink: 0;
    font-size: 24px;
    height: 40px;
    justify-content: center;
    margin: 0 auto 12px;
    width: 40px;

    @include tablet {
      margin: 0 5px 0 0;
    }

    @include desktop {
      margin: 0 10px 0 0;
    }
  }

  .icon-duration {
    font-size: 22px;
  }

  .info-text {
    color: $rit-black;
    display: block;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;

    @include tablet {
      display: inline-block;
    }
  }
}

.product-info-container-advent {
  .product-info-column-image {
    @include mobile-only {
      min-height: 290px;
      order: 2;
    }

    @include tablet {
      min-height: 700px;
    }
  }

  .product-info-column-details {
    @include mobile-only {
      order: 1;
      padding: 60px 20px 50px;
    }

    h3 {
      margin-bottom: 16px;

      @include mobile-only {
        letter-spacing: 1.5px;
        line-height: 32px;
        margin-bottom: 17px;
      }
    }

    p {
      @include mobile-only {
        margin-bottom: 30px;
      }

      @include tablet {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
    }
  }

  .product-info-icons {
    .product-info-icon {
      height: 50px;
      width: 50px;
    }

    .info-text {
      color: $grey-medium-light;
    }

    @include tablet {
      margin: 30px -50px 0;
    }
  }

  .btn-content-video {
    color: $white;
    left: 50%;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);

    @include mobile-only {
      padding: 72px 0 0;
    }

    @include tablet {
      margin-top: 26px;
      padding: 87px 0 0;
    }

    &::before {
      left: 50%;
      line-height: 1;
      transform: translateX(-50%);

      @include mobile-only {
        font-size: 48px;
      }

      @include tablet {
        font-size: 64px;
      }
    }
  }
}

.product-info-container-easter {
  .product-info-column-details {
    @include mobile-only {
      align-items: center;
      display: flex;
      min-height: 330px;
      padding: 46px 30px 40px;
      position: relative;
      z-index: 4;
    }

    .product-info-content {
      width: 100%;
    }

    h4 {
      @include mobile-only {
        margin-bottom: 18px;
      }

      @include tablet {
        margin-bottom: 25px;
      }
    }

    h3 {
      @include mobile-only {
        font-size: 32px;
        letter-spacing: 1px;
        line-height: 40px;
        margin-bottom: 10px;
      }

      @include tablet {
        margin-bottom: 23px;
      }
    }

    p {
      white-space: pre-line;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
