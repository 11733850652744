.s7videoplayer {
  background-color: transparent !important;

  video {
    box-sizing: border-box;
    height: 58vw !important;
    left: 50% !important;
    min-height: calc(100% + 5px); // fix weird bg peekthrough
    min-width: calc(100% + 5px); // fix weird bg peekthrough
    position: absolute;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 177.8vh !important;

    @include mobile-only {
      height: 100% !important;
      width: auto !important;
    }
  }
}

.s7socialshare,
.s7controlbar {
  display: none !important;
}

.s7controlbar,
.s7playpausebutton[selected][state],
.s7playpausebutton[selected][state][replay],
.s7fullscreenbutton[state][selected],
.s7closedcaptionbutton[state][selected],
.s7videoscrubber .s7videotime,
.s7videoscrubber .s7navigation[state],
.s7videoscrubber .s7knob,
.s7mutablevolume .s7mutebutton[state][selected],
.s7mutablevolume .s7verticalvolume .s7knob,
.s7videoplayer .s7iconeffect[state] {
  background-image: none !important;
}
