// navigation 2.0
// --------------

// mobile navigation
.nav-mobile {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 88px;

  .nav-mobile-inner {
    -ms-overflow-style: none;
    background-color: $rit-black;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    scrollbar-width: none;
    top: 88px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-mobile-main-list,
  .nav-mobile-additional-list {
    list-style: none;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }

  .nav-item {
    display: block;
    position: relative;
    width: 100%;
  }

  .nav-item-link {
    color: $gray-gainsboro;
    display: block;
    font-family: $font-header;
    opacity: 0;
    padding: 5px 30px 5px 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-indent: 20px;
    transform: translateX(0);
    transition: text-indent $navigation-default $navigation-ease, opacity $navigation-default $navigation-ease, transform $navigation-default $navigation-ease;
    width: 100%;

    &::after {
      @include icon-styles;
      content: icon-char(arrow3--right);
      display: inline-block;
      font-size: 28px;
      line-height: 1;
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .flyin .nav-item-link {
    opacity: 1;
    text-indent: 0;
  }

  .flyout .nav-item-link {
    opacity: 0;
    transform: translateX(-20px);
  }

  @include tablet {
    display: none;
  }
}

// main navigation items
.nav-mobile-main-list {
  padding-top: 37px;

  .nav-item {
    margin-bottom: 22px;
  }

  .nav-item-link {
    font-size: 24px;
    letter-spacing: 1.1px;
    line-height: 32px;
  }
}

// additional navigation items
.nav-mobile-additional-list {
  margin-top: auto;
  padding-bottom: 39px;

  .nav-item {
    margin-top: 14px;

    &:first-child {
      margin-top: 31px;
    }
  }

  .nav-item-link {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}

// nav mobile layers
.nav-mobile-layer {
  // sass-lint:disable-block no-misspelled-properties
  background-color: $rit-black;
  bottom: 0;
  display: none;
  left: 0;
  overflow: auto;
  padding: 30px 0 0;
  position: fixed;
  right: 0;
  top: 80px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include nav-tablet {
    top: 96px;
  }

  &.visible {
    z-index: 3;
  }

  &.nav-mobile-first-layer {
    .nav-mobile-layer-inner {
      height: auto;
    }
  }

  .nav-back {
    display: block;
    font-size: 16px;
    margin: 0 0 10px 24px;
    padding: 11px 4px;
    text-align: left;
    width: calc(100% - 48px);

    &::before {
      @include icon-styles;
      color: $gray-gainsboro;
      content: icon-char(arrow-back);
      display: inline-block;
      line-height: 1;
    }
  }

  .nav-mobile-layer-inner {
    // sass-lint:disable-block no-misspelled-properties
    height: calc(100% - 50px);
    overflow: auto;
    padding: 11px 24px 0;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-mobile-subnav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    padding-top: 60px;

    li {
      width: 100%;
    }

    .nav-mobile-image-item-narow {
      width: calc(50% - 12px);

      .nav-mobile-subnav-image {
        padding-bottom: 100%;
      }

      .nav-mobile-subnav-title {
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 24px;
        padding: 0 10px;
      }
    }
  }

  .nav-content-links + li:not(.nav-content-links) {
    margin-top: 40px;
  }

  .nav-mobile-subnav-item-shop-all {
    min-height: 24px;

    @include mobile-only {
      margin-top: -62px;
    }

    .nav-mobile-section-title {
      margin-top: 15px;
    }
  }

  .nav-mobile-subnav-link,
  .mobile-third-level a {
    color: $gray-gainsboro;
    display: block;
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    opacity: 0;
    padding: 18px 0 19px;
    text-align: left;
    text-decoration: none;
    text-indent: 20px;
    transition:
      text-indent $navigation-default $navigation-ease,
      opacity $navigation-default $navigation-ease,
      transform $navigation-default $navigation-ease;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }

  .nav-mobile-subnav-link {
    position: relative;

    &::after {
      @include icon-styles;
      color: $gray-lines;
      content: icon-char(arrow3--right);
      display: inline-block;
      font-size: 30px;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .nav-mobile-subnav-shop-all,
  .nav-mobile-subnav-shop-link {
    color: $gray-silver;
    font-family: $font-body;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
    padding: 0;

    &::after {
      @include icon-styles;
      content: icon-char(arrow3--right);
      font-size: 17px;
      position: relative;
      top: auto;
      transform: none;
    }
  }

  .nav-mobile-subnav-image-wrap {
    display: block;
    margin-bottom: 38px;
    opacity: 0;
    text-align: center;
    text-decoration: none;
    transition:
      opacity $navigation-default $navigation-ease,
      transform $navigation-default $navigation-ease;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }

  .flyin > .nav-mobile-subnav-link,
  .flyin > .nav-mobile-subnav-image-wrap,
  .mobile-third-level .flyin > a,
  .flyin > .nav-mobile-section-title {
    opacity: 1;
    text-indent: 0;

    .nav-mobile-subnav-image,
    .nav-mobile-subnav-title,
    .nav-mobile-subnav-shop-link {
      transform: translateX(0);
      visibility: visible;
    }
  }

  .flyout > .nav-mobile-subnav-link,
  .flyout > .nav-mobile-subnav-image-wrap,
  .mobile-third-level .flyout > a,
  .flyout > .nav-mobile-section-title {
    opacity: 0;
    transform: translateX(-20px);

    .nav-mobile-subnav-image {
      transform: translateX(20px);
    }

    .nav-mobile-subnav-title,
    .nav-mobile-subnav-shop-link {
      transform: translateX(-20px);
    }
  }

  .nav-mobile-subnav-image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 0;
    transform: translateX(20px);
    padding-bottom: 56.27%;
    position: relative;
    transition: transform $navigation-default $navigation-ease;
    width: 100%;
    visibility: hidden;

    &::after {
      background: linear-gradient(180deg, rgba(26, 25, 25, 0) 0%, $rit-black 100%), linear-gradient(180deg, rgba(26, 25, 25, 0) 0%, $rit-black 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 39px;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  .nav-mobile-subnav-title {
    color: $white;
    display: block;
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 32px;
    margin-bottom: 2px;
    text-decoration: none;
    transform: translateX(20px);
    transition: transform $navigation-default $navigation-ease;
    visibility: hidden;
  }

  .nav-mobile-subnav-shop-link {
    display: block;
    transform: translateX(20px);
    transition: transform $navigation-default $navigation-ease;
    visibility: hidden;

    &::after {
      margin-left: 5px;
    }
  }

  .nav-mobile-section-title {
    color: $rit-copper-lighter;
    display: block;
    font-family: $font-header;
    font-size: 11px;
    letter-spacing: 1.6px;
    line-height: 16px;
    margin: 27px 0 23px;
    opacity: 0;
    text-indent: 20px;
    text-transform: uppercase;
    transition:
      text-indent $navigation-default $navigation-ease,
      opacity $navigation-default $navigation-ease,
      transform $navigation-default $navigation-ease;
    transform: translate3d(0, 0, 0);
  }

  .nav-mobile-text-item {
    .nav-mobile-subnav-link {
      padding: 16px 0;

      &::after {
        margin-top: 2px;
        right: -4px;
      }
    }

    + li .nav-mobile-section-title {
      margin: 54px 0 10px;
    }
  }

  .nav-additional-text {
    color: $gray-medium;
    font-family: $font-body;
    font-size: 11px;
    letter-spacing: 1px;
    margin-left: 2px;
    text-transform: uppercase;
  }

  .mobile-third-level {
    ul {
      list-style: none;
    }
  }

  .navigation-banner-item {
    margin: 20px 0;

    .nav-content-banner {
      transform: translateX(20px);

      &.nav-content-banner-cta {
        .nav-content-banner-inner {
          padding: 22px 50px 20px 90px;
        }

        .icon {
          top: 30px;
        }
      }
    }

    &.flyin .nav-content-banner {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .nav-content-links {
    button {
      display: none;
    }

    .nav-desktop-subnav-wrap {
      display: none !important;
    }
  }
}

// nav mobile collections
.nav-mobile-collections {
  list-style: none;
  padding-right: 10px;
  width: 100%;

  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;

    &.flyin .nav-mobile-collections-link {
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }
  }

  .nav-mobile-collections-link {
    color: $white;
    display: block;
    font-family: $font-header;
    opacity: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transform: translateX(20px);
    transition: transform $navigation-default $navigation-ease, opacity $navigation-default $navigation-ease;
    width: calc(50% - 10px);
  }

  .image-holder {
    display: block;
    margin-bottom: 12px;
  }

  img {
    display: block;
    width: 100%;
  }

  .nav-collection-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 0;
    padding-bottom: 100%;
  }

  .nav-mobile-ritual-title {
    display: block;
    font-size: 8px;
    letter-spacing: 2px;
    line-height: 10px;
    margin-bottom: 5px;
  }

  .nav-mobile-collection-title {
    display: block;
    font-size: 13px;
    letter-spacing: 2.8px;
    line-height: 16px;
    margin-bottom: 7px;
  }

  .nav-mobile-label {
    color: #666;
    display: block;
    font-family: $font-body;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 12px;
  }
}

// desktop navigation
.nav-desktop {
  flex-shrink: 0;
  transition: opacity $navigation-default $navigation-ease;

  @include nav-mobile-tablet {
    display: none;
  }

  &.fade-nav {
    opacity: 0;
    left: 190px;
    position: absolute;

    @include desktop-large {
      left: 340px;
    }
  }

  &-list {
    @include desktop-large {
      display: flex;
    }
  }

  .nav-item {
    display: inline-block;
    list-style-type: none;
    
    &:first-of-type {
      .nav-item-link {
        padding-left: 0;
      }
    }

    &:first-child .nav-item-link::after {
      @include desktop-large {
          left: 0;
          width: calc(100% - 15px); 
      }
    }

    &:not(:first-child) .nav-item-link::after {
      @include desktop-large {
          left: 16px;
          width: calc(100% - 30px); 
      }
    }
  }

  .nav-item-link {
    color: $gray-gainsboro;
    display: block;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    position: relative;
    padding: 0 12px;
    height: 100%;
    text-decoration: none;
    z-index: 4;

    @include desktop-large {
        padding: 0 16px;
      }

    &.disabled {
      pointer-events: none;
    }
  }
  .nav-item-link-name {
    background-image: linear-gradient(transparent calc(100% - 1px), white 1px);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    display: block;
    height: 100%;
    padding: 20px 0;
    position: relative;
    transition: background-size 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  
    @include desktop-large {
      padding: 20px 0;
    }
  
    &.selected,
    &:hover {
      background-size: 100% 100%;
      color: $white;
  
      &::after {
        background-color: $white;
        width: calc(100% - 19px);
      }
    }
  }
}

// nav desktop layers
.nav-desktop-layer {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  top: 112px;
  width: 100%;
  z-index: 3;

  @include desktop-ultra {
    top: 136px;
  }

  &.visible .nav-first-layer {
    left: 0;

    @include desktop-large {
      left: auto;
      right: calc(50% + 413px);
    }
  }

  &.opened .nav-first-layer {
    transition: left 0.4s $navigation-ease;

    @include desktop-large {
      transition: right 0.6s $navigation-ease;
    }
  }

  &.top-gradient > .nav-first-layer::before,
  &.bottom-gradient > .nav-first-layer > .bottom-gradient-el {
    opacity: 1;
    visibility: visible;
  }

  .nav-desktop-overlay {
    background-color: rgba($rit-black, 0.6);
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 112px;
    z-index: 1;

    @include desktop-ultra {
      top: 136px;
    }
  }

  .close-desktop-nav {
    color: $white;
    display: none;
    font-size: 22px;
    position: absolute;
    right: 24px;
    top: 22px;
    z-index: 4;
  }

  .nav-first-layer {
    background-color: $rit-black;
    bottom: 0;
    left: -50%;
    position: absolute;
    top: 0;
    transition: left 0.8s $navigation-ease;
    width: 307px;
    z-index: 2;

    @include desktop-large {
      left: auto;
      right: 100%;
      transition: right 1s $navigation-ease;
    }

    &::before,
    .bottom-gradient-el {
      content: '';
      display: inline-block;
      height: 100px;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      transition: opacity $navigation-default $navigation-ease;
      visibility: hidden;
      z-index: 2;
    }

    &::before {
      background: linear-gradient(to top, rgba(26, 25, 25, 0) 0%, $rit-black 100%);
      top: 0;
    }

    .bottom-gradient-el {
      background: linear-gradient(to bottom, rgba(26, 25, 25, 0) 0%, $rit-black 100%);
      bottom: 0;
    }

    &::after {
      @include desktop-large {
        background-color: $rit-black;
        bottom: 0;
        content: '';
        display: inline-block;
        position: absolute;
        right: 100%;
        top: 0;
        width: 2000em;
      }
    }

    &.first-opening {
      .nav-desktop-subnav-slide-in {
        right: 100%;
        transition: right 1s $navigation-ease;
      }

      .slide-in {
        .nav-desktop-subnav-slide-in {
          right: 0;
        }
      }

      .nav-desktop-subnav-wrap.opened .nav-desktop-subnav-slide-in {
        transition: right 0.5s $navigation-ease;
      }
    }
  }

  .nav-desktop-layer-inner {
    // sass-lint:disable-block no-misspelled-properties
    max-height: 100%;
    overflow: auto;
    padding: 0 40px 0 11px;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-inner-subnav,
  .nav-mobile-subnav {
    padding: 124px 0 60px 20px;

    &.nav-inner-subnav-no-title {
      padding-top: 60px;
    }

    li {
      display: block;
      list-style-type: none;
      margin-bottom: 8px;

      &:first-child .nav-desktop-section-title {
        margin-top: 0;
      }
    }

    .nav-desktop-shopall-item {
      margin-top: -60px;

      + li .nav-desktop-section-title {
        margin-top: 0;
      }
    }
  }

  .nav-content-links {
    .nav-mobile-subnav-link {
      &::after {
        background-color: $white;
        bottom: 3px;
        content: '';
        display: inline-block;
        height: 1px;
        left: 0;
        position: absolute;
        transition: width $navigation-default $navigation-ease;
        width: 0;
      }

      .nav-additional-text {
        bottom: 2px;
        left: 100%;
        margin-left: 8px;
        position: absolute;

        &::after {
          display: none;
        }
      }
    }

    + li:not(.nav-content-links) {
      margin-top: 16px;
    }
  }

  .nav-mobile-subnav-link {
    color: $gray-silver;
    display: inline-block;
    line-height: 24px;
    opacity: 0;
    text-align: left;
    text-decoration: none;
    transform: translateX(-20px);
    transition: opacity $navigation-default $navigation-ease, transform $navigation-default $navigation-ease, color $navigation-default $navigation-ease;
    visibility: hidden;

    &:not(.nav-desktop-shopall-link) {
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: 0.3px;
    }

    &.nav-desktop-shopall-link {
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: 0.8px;
      margin-bottom: 29px;

      &::after {
        @include icon-styles;
        content: icon-char(arrow3--right);
        display: inline-block;
        font-size: 20px;
        line-height: 1;
        margin: -1px 0 0 0;
      }
    }

    &.nav-desktop-section-title {
      color: $rit-copper-lighter;
      display: block;
      font-size: 11px;
      letter-spacing: 1.6px;
      line-height: 16px;
      margin: 40px 0 16px;
      text-transform: uppercase;

      &:hover {
        color: $rit-copper-lighter;
      }
    }

    span {
      display: inline-block;
      position: relative;

      &::after {
        background-color: $white;
        bottom: 3px;
        content: '';
        display: inline-block;
        height: 1px;
        left: 0;
        overflow: hidden;
        position: absolute;
        transition: width $navigation-default $navigation-ease;
        width: 0;
      }
    }

    &.flyin {
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }

    &.active {
      color: $white;

      span::after {
        width: 100%;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .nav-content-links .nav-mobile-subnav-link:hover,
  .nav-mobile-subnav-link:hover {
    color: $white;

    span::after {
      width: 100%;
    }
  }

  .nav-additional-text {
    line-height: 26px;
  }
}

// nav desktop second layer
.nav-desktop-subnav-wrap {
  bottom: 0;
  display: none;
  left: 307px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 448px;
  z-index: 3;

  &.opened .nav-desktop-subnav-slide-in {
    transition: right 0.4s $navigation-ease;
  }

  .nav-desktop-subnav-slide-in {
    background-color: #222;
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    .nav-desktop-subnav-arrow {
      bottom: 20px;
      font-size: 32px;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transform: translateX(-50%);
      transition: opacity $navigation-default $navigation-ease;
      visibility: visible;

      &::before {
        @include icon-styles;
        color: $white;
        content: icon-char(arrow3--down);
      }
    }

    &.bottom-gradient .nav-desktop-subnav-arrow {
      opacity: 1;
      visibility: visible;
    }

    &.sticky-content-banner {
      .nav-desktop-subnav {
        padding-bottom: 116px;
      }
      .navigation-banner-item {
        bottom: 0;
        left: 0;
        margin: 0;
        position: absolute;
        width: 100%;
      }

      .nav-content-banner {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .nav-desktop-subnav-inner {
    // sass-lint:disable-block no-misspelled-properties
    height: 100%;
    overflow: auto;
    padding: 60px 60px 20px;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-desktop-subpanel-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-top: 65px;

    li {
      margin-bottom: 38px;
      width: 100%;
    }

    .nav-desktop-shopall-item {
      left: auto;
      margin: -65px 0 8px 0;
      min-height: 56px;
      position: relative;
      top: auto;
    }

    .nav-desktop-subpanel-narow {
      margin-bottom: 38px;
      width: 46.4%;

      .nav-desktop-subpanel-image {
        padding-bottom: 100%;

        @include tablet {
          width: 152px;
        }
      }

      .nav-desktop-subpanel-title {
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 24px;
      }
    }
  }

  .nav-desktop-shop-all {
    color: $gray-light;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 17px;
    margin-bottom: 37px;
    text-decoration: none;

    &::after {
      @include icon-styles;
      content: icon-char(arrow4--left);
      display: inline-block;
      font-size: 12px;
      margin: -2px 0 0 20px;
      transform: rotate(-180deg);
    }

    .lowercase {
      text-transform: lowercase;
    }
  }

  .nav-desktop-subnav-link {
    color: $gray-light;
    display: inline-block;
    font-family: $font-header;
    font-size: 18px;
    letter-spacing: 0.7px;
    line-height: 21px;
    margin-bottom: 14px;
    text-decoration: none;
  }

  .nav-desktop-subtitle {
    color: $gray-disabled;
    display: block;
    font-family: $font-body;
    font-size: 10px;
    letter-spacing: 3px;
    line-height: 12px;
    margin: 38px 0 8px;
    text-transform: uppercase;
  }

  .nav-desktop-shop-all,
  .nav-desktop-subnav-link,
  .nav-desktop-subtitle {
    opacity: 0;
    transform: translateX(-20px);
    transition: transform $navigation-default $navigation-ease, opacity $navigation-default $navigation-ease;
    visibility: hidden;

    span:not(.lowercase) {
      position: relative;

      &::after {
        background-color: $white;
        bottom: -6px;
        content: '';
        display: inline-block;
        height: 1px;
        left: 0;
        overflow: hidden;
        position: absolute;
        transition: width $navigation-default $navigation-ease;
        width: 0;
      }
    }

    &.flyin {
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }
  }

  .navigation-banner-item {
    margin: 26px 0 -20px -40px;
    width: calc(100% + 70px);

    &.flyin .nav-content-banner {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .nav-desktop-subpanel-image-wrap {
    display: block;
    width: 100%;

    &:hover + .nav-subpanel-flyin-wrap {
      .nav-desktop-subpanel-link {
        color: $white;

        span::after {
          width: 100%;
        }
      }

      .nav-desktop-subpanel-title {
        color: $white;

        span {
          background-size: 100% 100%;
        }
      }
    }
  }

  .nav-desktop-subpanel-image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 0;
    padding-bottom: 66.467%;
    position: relative;

    @include tablet {
      width: 328px;
    }

    &::after {
      background: linear-gradient(180deg, rgba(36, 34, 34, 0) 0.1%, $rit-black-lighter 100%), linear-gradient(180deg, rgba(36, 34, 34, 0) 0.1%, $rit-black-lighter 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 40px;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  .nav-subpanel-flyin-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    .nav-desktop-subpanel-title {
      color: $gray-silver;
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: 0.4px;
      line-height: 32px;
      margin-bottom: 2px;
      text-decoration: none;

      span {
        background-image: linear-gradient(transparent calc(100% - 1px), $white 1px);
        background-repeat: no-repeat;
        background-size: 0% 100%;
        transition: background-size $navigation-default $navigation-ease;
        width: calc(100%);
      }

      &:hover {
        color: $white;

        span {
          background-size: 100% 100%;
        }

        ~ .nav-desktop-subpanel-link {
          color: $white;

          span::after {
            width: 100%;
          }
        }
      }
    }

    .nav-desktop-subpanel-text {
      color: $gray-silver;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin-bottom: 16px;
      text-decoration: none;

      &:hover {
        ~ .nav-desktop-subpanel-link {
          color: $white;

          span::after {
            width: 100%;
          }
        }
      }
    }

    .nav-desktop-subpanel-link {
      color: $gray-silver;
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: 24px;
      text-decoration: none;

      &::after {
        @include icon-styles;
        content: icon-char(arrow3--right);
        font-size: 17px;
        margin-left: 3px;
        position: relative;
        top: auto;
        transform: none;
      }

      span {
        display: inline-block;
        position: relative;

        &::after {
          background-color: $white;
          bottom: 3px;
          content: '';
          display: inline-block;
          height: 1px;
          left: 0;
          overflow: hidden;
          position: absolute;
          transition: width $navigation-default $navigation-ease;
          width: 0;
        }
      }

      &:hover {
        color: $white;

        span::after {
          width: 100%;
        }
      }
    }
  }

  .nav-desktop-subnav-flyin {
    max-width: 100%;
    opacity: 0;
    transform: translateX(-20px);
    transition: color $navigation-default $navigation-ease, transform $quaternary-duration $navigation-ease, opacity $quaternary-duration $navigation-ease;

    &.flyin {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @include desktop-only {
    .nav-dekstop-subnav-link {
      margin-bottom: 18px;
    }

    .nav-desktop-shop-all,
    .nav-desktop-subnav-link {
      &:hover span::after {
        width: 100%;
      }
    }

    .navigation-banner-item {
      margin-left: 0;
      width: 100;
    }
  }
}

// nav content banner
.nav-content-banner-wrapper {
  display: flex;
  flex-direction: row;
}

.nav-content-banner {
  background-color: #292828;
  opacity: 0;
  transform: translateX(-20px);
  transition: transform $navigation-default $navigation-ease, opacity $navigation-default $navigation-ease;
  width: 100%;

  @include desktop-only {
    margin-left: -60px;
    width: calc(100% + 120px);
  }

  .nav-content-banner-inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    min-height: 104px;
    padding: 25px 25px 22px 80px;
    position: relative;
    text-decoration: none;
    width: 100%;

    &:hover .link::before {
      width: 100%;
    }
  }

  .icon-holder {
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  .text {
    color: $white;
    display: block;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    margin-bottom: 9px;
    width: 100%;
  }

  .link {
    color: $rit-copper-lighter;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
    margin-right: 22px;
    position: relative;
    text-decoration: none;

    &::before {
      background-color: $rit-copper-lighter;
      bottom: 3px;
      content: '';
      display: inline-block;
      height: 1px;
      left: 0;
      position: absolute;
      transition: width $navigation-default $navigation-ease;
      width: 0;
    }

    &::after {
      @include icon-styles;
      content: icon-char(arrow3--right);
      font-size: 20px;
      margin-left: 2px;
      position: absolute;
      right: -23px;
      top: 0;
    }
  }
}

.navigation-content-links-placeholder {
  display: none;
}


//
// Fastlane overrides / specific styling
// ----------------------------------------------------------------------------
//

.fastlane-header {
  .navigation-wrapper {
    .navigation-inner {
      @include mobile-only {
        display: block;
        height: 104px;
        padding-top: 56px;
      }

      .nav-inner-wrapper {
        @include mobile-only {
          border-bottom: 1px solid #363636;
          border-top: 1px solid #363636;
          height: 48px;
          padding-left: 0;
        }
      }
    }
  }
}

.fastlane-backtomain {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobile-only {
    height: 30px;
    margin-top: -31px;
    padding-left: 20px;
    width: 100%;
  }

  @include nav-tablet {
    height: 80px;
  }

  @include desktop-only {
    margin-right: 20px;
  }

  a {
    color: $white;
    font-size: 16px;
    font-family: $font-header;
    line-height: 20px;
    position: relative;
    white-space: nowrap;

    @include mobile-only {
      font-size: 12px;
      letter-spacing: 0.4px;
    }

    @include tablet {
      text-decoration: none;
    }

    &::before {
      @include tablet {
        @include icon-styles;
        content: icon-char(arrow3--left);
        display: inline-block;
        font-size: 32px;
        line-height: 19px;
        width: 30px;
      }
    }
  }

  em {
    font-style: italic;
  }
}
