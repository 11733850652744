//TO DO: remove after Checkout redesign is done
.toaster-container {
  background-color: $rit-sandy-light;
  display: none;
  flex-direction: column;
  justify-content: center;
  left: 0;
  max-width: 100%;
  min-height: 200px;
  opacity: 0;
  padding: 40px 30px;
  position: fixed;
  top: -600px;
  transition: top $quaternary-duration $default-ease-in-out;
  width: 100%;
  z-index: $modal-window-index;

  &.open {
    top: 0;
  }

  // To prevent the element visibilty while the page is loading we initially
  // set the opacity 0. We set it to 1 by adding this class on page load.
  &.page-loaded {
    opacity: 1;
  }

  .toaster-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;

    @include desktop {
      @include inner-wrapper;
      flex-wrap: nowrap;
    }
  }

  .toaster-text {
    text-align: left;
    margin-bottom: 20px;

    @include mobile-only {
      p,
      a {
        font-size: 13px;
        letter-spacing: 1.18px;
        line-height: 18px;
      }
    }

    @include desktop {
      margin-bottom: 0;
    }
  }

  .toaster-buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-left: auto;
    text-align: center;
    width: 100%;

    @include tablet {
      margin: 0 auto;
      width: auto;
    }

    @include desktop {
      flex-direction: row;
      padding-left: 30px;
      text-align: left;

      .link.link--large {
        background-color: transparent;
        border: 1px solid $gray-disabled;
        color: $rit-black;
        display: inline-block;
        font-family: $font-body;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 2px;
        line-height: 1.2;
        min-width: 200px;
        padding: 15px 20px 14px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;

        &:hover,
        &:active {
          border: 1px solid $rit-black;
        }
      }
    }

    .btn {
      min-width: 280px;
      margin: 0 auto 30px;

      &:only-child {
        margin-bottom: 0;
      }

      @include desktop {
        min-width: 200px;
        margin: 0 0 0 20px;
      }
    }
  }
}

.generic-toaster-container {
  background-color: $white;
  display: block;
  left: 0;
  max-width: 100%;
  min-height: 200px;
  opacity: 0;
  padding: 40px 30px;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: transform 1s $default-ease-out-fast;
  width: 100%;
  z-index: $modal-window-index;

  &.open {
    transform: translateY(0);
  }

  // To prevent the element visibilty while the page is loading we initially
  // set the opacity 0. We set it to 1 by adding this class on page load.
  &.page-loaded {
    opacity: 1;
  }

  .toaster-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;

    @include desktop {
      @include inner-wrapper;
      flex-wrap: nowrap;
    }
  }

  .toaster-text {
    text-align: left;
    margin-bottom: 20px;

    @include mobile-only {
      p,
      a {
        font-size: 13px;
        letter-spacing: 1.18px;
        line-height: 18px;
      }
    }

    @include desktop {
      margin-bottom: 0;
    }
  }

  .toaster-buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-left: auto;
    text-align: center;
    width: 100%;

    @include tablet {
      margin: 0 auto;
      width: auto;
    }

    @include desktop {
      flex-direction: row;
      padding-left: 30px;
      text-align: left;

      .link.link--large {
        background-color: transparent;
        border: 1px solid $gray-disabled;
        color: $rit-black;
        display: inline-block;
        font-family: $font-body;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 2px;
        line-height: 1.2;
        min-width: 200px;
        padding: 15px 20px 14px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;

        &:hover,
        &:active {
          border: 1px solid $rit-black;
        }
      }
    }

    .btn {
      min-width: 280px;
      margin: 0 auto 30px;

      &:only-child {
        margin-bottom: 0;
      }

      @include desktop {
        min-width: 200px;
        margin: 0 0 0 20px;
      }
    }
  }
}

// CRM toast on phone
.mobile-crm-toast-wrapper {
  &.mobile-cart-confirmation.checkout-mobile-cart {
    background-color: $white;

    @include mobile-only {
      padding: 26px 0 20px;
    }

    .product-gift-ribbon {
      &::after {
        opacity: 1;
      }
    }
  }

  &.open {
    @include mobile-only {
      form {
        .btn.btn--submit,
        .spinner-custom-mobile {
          position: absolute;
        }
      }
    }
  }
}

.mobile-crm-toast {
  @include mobile-only {
    width: 100%;

    .spinner-custom-mobile {
      align-items: center;
      background-color: $rit-black;
      display: flex;
      justify-content: center;
      left: 50%;
      margin: 0 auto;
      max-width: 342px;
      min-height: 58px;
      padding: 20px 32px;
      position: relative;
      transform: translateX(-50%);

      span {
        background-color: $white;
      }

      &.show {
        background-color: $rit-black;
        display: flex;

        span {
          background-color: $white;
        }
      }
    }

    .mobile-toaster-header {
      display: flex;
      border-bottom: 1px solid $gray-gainsboro;
      padding: 0 24px 8px 24px;
      justify-content: space-between;

      button {
        color: $rit-black;
        font-size: 24px;
        z-index: 994;
      }
    }

    p {
      text-align: left;
    }

    form {
      margin-bottom: 74px;
      margin-top: 16px;
      position: relative;
      width: 100%;

      .btn {
        left: 50%;
        margin: 0 auto;
        max-width: 342px;
        min-height: 58px;
        padding: 20px 32px;
        position: relative;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: 994;
      }
    }

    .crm-toast-p {
      font-weight: bold;
      margin-bottom: 0;
    }

    .mini-cart-product {
      border-bottom: 1px solid $gray-gainsboro;
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      position: relative;

      .mini-cart-inner-container {
        .mini-cart-price-wrapper {
          display: flex;
          flex-direction: column;
          text-align: right;

          span {
            font-family: $font-header;
            display: block;
            font-size: 16px;
            line-height: 24px;
            width: 80px;
          }

          .mini-cart-b2b-taxes {
            span {
              font-family: $font-body;
              font-size: 12px;
              line-height: 16px;
            }
          }

          .price-before-discount {
            color: $grey-medium-light;
            font-size: 14px;
            text-decoration: line-through;
          }

          .legal-note {
            margin-top: 0;

            span {
              color: $gray-medium;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.3px;
            }
          }
        }
      }

      .refill-container {
        background-color: $light-green;
        border-radius: 0;
        margin: 16px 0 0;
        max-width: none;
        padding: 16px 24px 16px 16px;
        width: 100%;

        &.member-only {
          background-color: $rit-sandy-bg;
          padding: 16px;
        }

        .plant-a-tree {
          &::before {
            color: $rit-black;
          }
        }

        .refill-inner {
          display: flex;
          justify-content: center;

          .for-every-refill {
            color: $rit-black;
            margin: 0;
          }
        }
      }
    }

    .mini-cart-image {
      max-width: 100px;

      img {
        width: 64px;
      }
    }

    .mini-cart-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      text-align: left;
      width: 100%;

      .unit-base-price-content {
        .legal-note {
          display: none;
        }
      }
    }

    .mini-cart-small-title {
      color: $rit-copper;
      font-family: $font-header;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .mini-cart-name {
      max-width: 100%;
      z-index: 994;

      a {
        color: $rit-black;
        font-family: $font-header;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-decoration: none;
      }
    }

    .mini-cart-details-bottom {
      .mini-cart-pricing {
        display: none;
      }

      .mini-cart-b2b-taxes {
        span {
          color: $ritual-samurai;
          display: block;
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
          width: 13ch;
        }

        & + .unit-base-price-content {
          display: none;
        }
      }
    }

    .flyout-progress-bar {
      margin-bottom: 0;
      margin-top: 10px;
    }

    .promotion-wrapper {
      left: 0;
      position: absolute;
      top: 16px;

      .callout-message {
        @include callout;
        border-radius: 0 2px 2px 0;
        display: block;
        max-width: 120px;
      }
    }
  }

  .gwp-minicart-wrapper {
    .approaching-header {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      padding-left: 20px;

      & + .product-gift-ribbon {
        border-top: 1px solid $gray-gainsboro;

        @include mobile-only {
          margin-top: 10px !important;
        }
      }
    }

    .approaching-promo,
    .approaching-promo.approaching-free-gift {
      border-bottom: 1px solid $gray-gainsboro !important;
      margin: 0 !important;
      padding: 0 20px;

      .gift-label {
        font-size: 16px;
      }

      .gift-price {
        font-family: $font-body;
        font-size: 14px;
        margin-top: 6px;
      }
    }

    .promo-product-details {
      padding-top: 0;
    }

    .premium-gift {
      &::after {
        opacity: 1;
      }
    }
  }

  .cart-banner--mini-cart {
    margin-top: 16px;
  }
}
