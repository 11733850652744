.product-grid-block.product-filter-block {
  display: block;
  padding: 40px 0 85px;

  @include tablet {
    padding: 60px 0 40px;
  }

  .grid-heading {
    margin-bottom: 22px;

    @include tablet {
      margin-bottom: 40px;
    }
  }

  .heading-wrapper {
    max-width: 1440px;
    margin: 0 auto 21px;
    position: relative;
    text-align: center;
    padding: 0 20px;

    @include mobile-only {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include tablet {
      margin: 0 auto 28px;
    }
  }

  .product-filters {
    list-style: none;

    @include mobile-only {
      white-space: nowrap;
    }

    @include tablet {
      font-size: 0;
    }

    li {
      padding: 0 12px;
      margin-bottom: 12px;
      display: inline-block;

      .filter-item {
        font-family: $font-body;
        display: block;
        text-transform: uppercase;
        text-decoration: none;
        color: $gray-dark;
        padding: 0 0 7px;
        position: relative;
        font-size: 12px;
        letter-spacing: 2.08px;
        line-height: 14px;

        &::after {
          background-color: $rit-dark;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          transition: width .3s cubic-bezier(.05, .5, .3, 1);
          width: 0;
        }

        &:hover,
        &.selected {
          color: $rit-dark;

          &::after {
            width: 100%;
          }
        }

        @include tablet {
          letter-spacing: 1.7px;
        }
      }

      @include mobile-only {
        min-width: 40%;
      }

      @include tablet {
        margin-bottom: 25px;
        padding: 0 25px;
      }
    }
  }
}
