.sticky-bar {
  width: 100%;
  min-height: 61px; // plus one pixel to compensate the hidden pixel in the bottom
  background-color: $white;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.08);
  visibility: hidden; // hidden by default
  bottom: -90px; // more than height
  opacity: 1;
  transition: bottom .3s ease-in-out, visibility .1s ease-in-out .5s;
  z-index: $sticky-bar-index;
  position: fixed;
  left: 0;
  display: flex;

  @include tablet {
    min-height: 71px; // plus one pixel to compensate the hidden pixel in the bottom
  }

  &.show-bar {
    transition: bottom .3s ease-in-out, visibility .1s ease-in-out 0s;
    visibility: visible;
    bottom: 0;
  }

  .variation-dropdown-mobile,
  .option-dropdown-mobile {
    display: block;

    @include desktop {
      display: none;
    }
  }

  .variation-dropdown-mobile {
    font-size: 50px; //prevent page to zoom in after tap on select
  }

  .image-container {
    max-height: 100%;
    align-items: center;
    display: flex;

    .image {
      width: 60px;
    }
  }

  .btn {
    max-width: none;

    @include desktop {
      &:hover,
      &:active {
        background: $voodoo;
        border-color: $voodoo;
        color: $white;
      }
    }
  }
}

.sticky-bar-crm-cta {
  @include tablet {
    justify-content: flex-end;
  }

  .btn {
    @include mobile-only {
      line-height: 30px;
      min-width: auto;
      width: 50%;
    }

    @include tablet {
      line-height: 40px;
    }
  }
}

.sticky-bar-configurable {
  .details-container {
    @include tablet {
      max-width: 200px;
    }

    @include desktop {
      max-width: none;
    }
  }

  .sticky-button-container {
    width: auto;
    min-width: 0;
    display: flex;

    form {
      width: 100%;
    }

    .btn-wrapper {
      display: flex;
      height: 100%;
    }

    .btn-customize {
      border: 0;
      box-shadow: 0 2px 4px 0 rgba($black, 0.5);

      @include mobile-only {
        letter-spacing: 0;
        font-size: 0;
        line-height: 0;
      }

      @include desktop-only {
        &:hover {
          .icon-pencil-configurable {
            @include pencil-white-icon(20px, 20px);
          }
        }
      }

      .icon-pencil-configurable {
        @include pencil-black-icon(20px, 20px);
        vertical-align: middle;
        transition: .2s ease-in-out;

        @include mobile-only {
          @include pencil-black-icon(32px, 32px);
        }

        @include tablet {
          margin-top: -3px;
        }
      }

      &.btn--primary {
        .icon-pencil-configurable {
          @include pencil-white-icon(20px, 20px);

          @include mobile-only {
            @include pencil-white-icon(32px, 32px);
          }
        }
      }
    }

    .btn {
      @include mobile-only {
        min-width: 0;
        width: 80px;
      }

      @include tablet {
        min-width: 200px;
      }

      @include desktop {
        min-width: 280px;
      }

      @include desktop-medium {
        min-width: 375px;
      }
    }

    .btn-add-to-cart {
      @include mobile-only {
        @include icon(cart-plus);
        letter-spacing: 0;
        font-size: 0;
        line-height: 0;

        &::before {
          display: block;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  .sticky-product-container .price-container {
    @include mobile-only {
      margin-left: 0;

      .product-price {
        margin: 0 10px 0 0;
      }
    }
  }
}

.sticky-product-container {
  display: flex;
  flex: 75%;
  padding: 5px 30px 5px 20px;

  .details-container {
    display: none;

    @include tablet {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
    }

    .the-ritual-of-title {
      font-size: 12px;
      letter-spacing: 1.8px;
      line-height: 16px;
      margin-bottom: 4px;
    }
  }

  .price-container {
    display: flex;
    align-items: center;
    margin-left: auto;

    .product-price {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-left: 10px;
      position: relative;

      &.has-unit-base-price {
        margin-bottom: 12px;
      }

      .prices-container {
        text-align: right;

        .product-discount-no-strikethrough {
          color: $gray-suva;
          font-size: 12px;
          letter-spacing: 0.3px;
          line-height: 16px;
        }
      }
    }

    .price-sales {
      white-space: nowrap;
      font-size: 16px;
      letter-spacing: 1.07px;
      line-height: 19px;
      display: block;
      margin-bottom: 3px;
    }

    .base-price-block {
      text-align: right;
      width: 100%;
    }

    .unit-base-price {
      &:not(.legal-note) {
        position: absolute;
        width: 100%;

        @include mobile-and-tablet {
          left: 0;
        }

        @include desktop {
          right: 0;
        }
      }

      &.legal-note {
        margin: 0;
      }
    }

    .price-standard {
      color: $gray-suva;
      font-size: 11px;
      letter-spacing: 1.65px;
      line-height: normal;
      text-decoration: line-through;
      display: block;
    }
  }
}

.sticky-button-container {
  flex: 25%;

  form,
  fieldset,
  .product-add-to-cart,
  .btn-wrapper,
  .btn-add-to-cart,
  .btn-select-option {
    height: 100%;
    width: 100%;
    min-width: 1px;
  }

  form > div {
    height: 100%;
  }

  fieldset {
    .add-to-cart-inner {
      height: 100%;
    }
  }

  .product-add-to-cart {
    position: relative;

    .btn {
      @include tablet {
        min-height: 71px;
      }
    }

    .availability-web {
      background-color: $rit-black;
      height: 100%;
      position : absolute;
      top: 0;
      width: 100%;

      button {
        color: $white;
        border: 0;
        width: 100%;

        @include mobile-only {
          @include icon(bell);
            font-size: 0;
            letter-spacing: 0;
            line-height: 0;

          &::before {
            font-size: 24px;
            line-height: 24px;
            text-indent: 0;
          }
        }
      }

      .btn-out-of-stock-disabled {
        @include mobile-only {
          @include icon(cart-plus);
            font-size: 0;
            letter-spacing: 0;
            line-height: 0;
        }

        &::before {
          @include mobile-only{
            font-size: 24px;
            line-height: 24px;
            text-indent: 0;
          }
        }
      }
    }
  }

  .btn-add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile-only {
      @include icon(cart-plus);
      letter-spacing: 0;
      font-size: 0;
      line-height: 0;

      &::before {
        text-indent: 0;
        font-size: 24px;
        line-height: 24px;
      }
    }

    &.checked {
      @include icon(check);
      letter-spacing: 0;
      font-size: 0;

      &::before {
        display: inline-block !important;
        text-indent: 0;
        font-size: 14px;
        animation-name: fadeIn;
        animation-duration: .5s;
      }
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  .btn-select-option {
    cursor: pointer;
    pointer-events: auto;
    background-color: $white;
    color: $copper;
    border: 0;
    border-left: 2px solid $rit-sandy-bg;
  }

  .product-options {
    display: none; // hide the options in the sticky bar
  }
}

.checkout-sticky-bar {
  min-height: 50px;

  @include tablet {
    min-height: 71px; // plus one pixel to compensate the hidden pixel in the bottom
  }

  .sticky-order-container {
    display: flex;
    flex-grow: 1;
    min-height: 71px;
    padding: 5px 20px;

    .details-container {
      display: none;
      align-items: center;
      text-transform: uppercase;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 2.88px;
      color: $rit-dark;

      @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 20px;
      }
    }

    .price-container {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      @include tablet {
        margin-right: 0;
      }

      .order-total {
        align-items: center;
        display: flex;
        margin-left: 10px;
      }
    }
  }

  .products-number,
  .order-total-label {
    margin-right: 15px;

    @include tablet {
      margin-right: 7px;
    }
  }

  .order-total-label {
    font-size: 13px;
    color: $gray-medium;
    letter-spacing: 0.33px;
  }

  .order-total-amount {
    @include price-text('s');
      white-space: nowrap;
  }

  .sticky-button-container {
    width: 50%;

    @include tablet {
      width: auto;
      min-width: 200px;
    }

    @include desktop {
      width: 380px;
    }

    form,
    fieldset,
    .btn {
      height: 100%;
      width: 100%;
      min-width: 1px;
    }

    .btn {
      height: 71px;
    }
  }
}

_:-ms-input-placeholder, :root .checkout-sticky-bar {
  .order-total-label {
    padding-top: 4px;
  }
}

.sticky-bar-spacer {
  height: 0;
  visibility: hidden;
  transition: height .3s ease-in-out;

  &.show-bar {
    height: 50px;
    visibility: visible;
  }

  @include tablet {
    &.show-bar {
      height: 71px;
    }
  }
}
