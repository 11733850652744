.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;

  &.mCS_no_scrollbar,
  &.mCS_touch_action {
    -ms-touch-action: auto;
    touch-action: auto;
  }
}

.mCustomScrollBox {
  direction: ltr;
  height: 100%;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
}

.mCSB_container {
  height: auto;
  overflow: hidden;
  width: auto;

  .mCSB_container {
    &.mCS_no_scrollbar_y.mCS_y_hidden {
      margin-right: 0;
    }
  }
}

.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCS-dir-rtl {
  > .mCSB_inside {
    > .mCSB_container {
      margin-left: 30px;
      margin-right: 0;

      &.mCS_no_scrollbar_y.mCS_y_hidden {
        margin-left: 0;
      }
    }

    > .mCSB_scrollTools {
      left: 0;
      right: auto;
    }
  }

  .mCSB_outside + .mCSB_scrollTools {
    left: -26px;
    right: auto;
  }
}

.mCSB_scrollTools {
  bottom: 0;
  height: auto;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;

  .mCSB_draggerContainer {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  a + .mCSB_draggerContainer {
    margin: 20px 0;
  }

  .mCSB_draggerRail {
    border-radius: 16px;
    height: 100%;
    margin: 0 auto;
    width: 2px;
  }

  .mCSB_dragger{
    cursor: pointer;
    height: 30px;
    width: 100%;
    z-index: 1;

    .mCSB_dragger_bar {
      border-radius: 4px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: 4px;
    }
  }

  .mCSB_buttonUp,
  .mCSB_buttonDown {
    cursor: pointer;
    display: block;
    height: 20px;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .mCSB_buttonDown {
    bottom: 0;
  }

    &.mCSB_scrollTools_horizontal {
      width: auto;
      height: 16px;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;

      a + .mCSB_draggerContainer {
        margin: 0 20px;
      }

      .mCSB_draggerRail {
        width: 100%;
        height: 2px;
        margin: 7px 0;
      }

      .mCSB_dragger {
        width: 30px;
        height: 100%;
        left: 0;
      }

      .mCSB_dragger .mCSB_dragger_bar {
        width: 100%;
        height: 4px;
        margin: 6px auto;
      }
    }
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
  .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
  .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    width: 12px;
  }

  .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
  .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 8px;
  }
}

.mCSB_horizontal {
  &.mCSB_inside > .mCSB_container {
    margin-bottom: 30px;
    margin-right: 0;
  }

  &.mCSB_outside > .mCSB_container {
    min-height: 100%;
  }

  > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
  }
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;

  > .mCSB_container {
    padding-right: 30px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  transition: width .2s ease-out .2s, height .2s ease-out .2s,
              margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,
              margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
              opacity .2s ease-in-out, background-color .2s ease-in-out;
}