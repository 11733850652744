.refill-jar-wrapper,
.scroll-animation-wrapper {
  position: relative;

  @include mobile-only {
    padding-top: 95px;
  }
}

.refill-jar-wrapper {
  .refill-jar-lid,
  .refill-jar-content,
  .refill-jar-container {
    position: relative;
    text-align: center;
    width: 100%;

    img {
      display: inline-block;
      max-width: 200px;

      @include tablet {
        max-width: 300px;
      }
    }
  }

  .refill-jar-lid {
    margin-bottom: -40px;
    z-index: 1;

    @include tablet {
      margin-bottom: -90px;
    }
  }

  .refill-jar-content {
    margin-bottom: -120px;
    z-index: 2;

    @include tablet {
      margin-bottom: -250px;
    }
  }

  .refill-jar-container {
    z-index: 3;

    @include tablet {
      margin-bottom: -130px;
    }
  }
}

.scroll-animation-wrapper {
  @include mobile-only {
    min-height: 309px;
  }

  @include tablet {
    min-height: 368px;
  }

  .candle-holder {
    left: 50%;
    position: absolute;
    transform: translateX(0);
    transition: transform 0.05s $default-ease-in-out;
    will-change: transition, transform;

    &.first,
    &.fifth {
      z-index: 1;
    }

    &.second,
    &.fourth {
      z-index: 2;
    }

    &.first {
      @include mobile-only {
        transform: translateX(-110%);
      }
    }

    &.second {
      @include mobile-only {
        transform: translateX(-80%);
      }
    }

    &.third {
      transform: translateX(-50%);
      z-index: 3;
    }

    &.fourth {
      @include mobile-only {
        transform: translateX(-20%);
      }
    }

    &.fifth {
      @include mobile-only {
        transform: translateX(10%);
      }
    }

    img {
      max-width: none !important;

      @include mobile-only {
        width: 168px !important;
      }

      @include tablet {
        width: 288px !important;
      }
    }
  }
}
