.popup-generic-holder {
  left: 50% !important;
  max-width: 100%;
  position: fixed;
  top: 50% !important;
  transform: translate(-50%, -50%);
  z-index: $modal-window-index;

  .ui-dialog-titlebar {
    padding: 0;

    .ui-dialog-titlebar-close {
      height: auto;
      margin: 0;
      padding: 0;
      right: 42px;
      top: calc(100% + 31px);
      width: auto;
      z-index: 5;
    }

    .ui-button-icon {
      font-size: 30px;
      left: auto;
      line-height: 1;
      margin-left: 0;
      position: relative;
      text-indent: 0;

      &::before {
        @include icon-styles;
        content: icon-char(close);
        display: inline-block;
      }
    }
  }

  .popup-generic {
    padding: 0 20px;
  }

  .pdp-main-quickview {
    padding: 40px 40px 20px;

    .product-options {
      padding-top: 10px;

      .currency-code {
        display: block;
        margin-top: -14px;
      }
    }

    .swatches-wrapper {
      justify-content: center;
    }
  }

  @include mobile-only {
    .pdp-main-quickview {
      padding: 50px 0 20px;

      .product-options {
        max-width: 280px;
      }
    }

    .product-name {
      font-size: 20px;
      letter-spacing: 4px;
      line-height: 30px;
    }
  }

  @include tablet {
    .pdp-main-quickview .swatches-wrapper {
      margin: 0 auto -20px;
      max-width: 280px;
    }
  }
}
