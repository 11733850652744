@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont.woff2") format("woff2"), url("../fonts/iconfont.woff") format("woff"), url("../fonts/iconfont.ttf") format("truetype");
}
.icon-AR:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea01";
}

.icon-account:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea02";
}

.icon-activated-charcoal:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea03";
}

.icon-advent-calendar:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea04";
}

.icon-advent-key:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea05";
}

.icon-advent-tree:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea06";
}

.icon-ar-button:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea07";
}

.icon-arrow--back:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea08";
}

.icon-arrow--copper:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea09";
}

.icon-arrow--down:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea0a";
}

.icon-arrow--left:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea0b";
}

.icon-arrow--link:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea0c";
}

.icon-arrow--right:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea0d";
}

.icon-arrow--up:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea0e";
}

.icon-arrow-back:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea0f";
}

.icon-arrow-diap:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea10";
}

.icon-arrow-track-and-trace:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea11";
}

.icon-arrow-wider-down:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea12";
}

.icon-arrow2--down:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea13";
}

.icon-arrow2--left:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea14";
}

.icon-arrow2--right:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea15";
}

.icon-arrow2--up:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea16";
}

.icon-arrow3--down-rit-dark:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea17";
}

.icon-arrow3--down-white:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea18";
}

.icon-arrow3--down:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
}

.icon-arrow3--left:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1a";
}

.icon-arrow3--right:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
}

.icon-arrow3--up:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1c";
}

.icon-arrow4--down:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1d";
}

.icon-arrow4--left:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1e";
}

.icon-arrow4--right:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1f";
}

.icon-arrow4--up:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea20";
}

.icon-arrow5--left:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea21";
}

.icon-arrow5--up:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea22";
}

.icon-bell:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea23";
}

.icon-box:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea24";
}

.icon-bring-delivery:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea25";
}

.icon-business-related-questions:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea26";
}

.icon-call:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea27";
}

.icon-candle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea28";
}

.icon-card-payment:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea29";
}

.icon-cart--plus:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2a";
}

.icon-cart-basket:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2b";
}

.icon-cart-plus:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2c";
}

.icon-cart:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2d";
}

.icon-check-thin:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2e";
}

.icon-check:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
}

.icon-circle-exclamation:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea30";
}

.icon-circle-info:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea31";
}

.icon-cities:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea32";
}

.icon-clean-safe:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea33";
}

.icon-click-and-collect:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea34";
}

.icon-close-thick:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea35";
}

.icon-close:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea36";
}

.icon-close2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea37";
}

.icon-cloud:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea38";
}

.icon-credit-card-payment:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea39";
}

.icon-cruelty-free:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea3a";
}

.icon-cs-info-icon:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea3b";
}

.icon-current-location-outline:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea3c";
}

.icon-current-location:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea3d";
}

.icon-data:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea3e";
}

.icon-deliveries:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea3f";
}

.icon-delivery-day:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea40";
}

.icon-delivery-fast:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea41";
}

.icon-delivery-time:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea42";
}

.icon-delivery-truck-full:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea43";
}

.icon-delivery:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea44";
}

.icon-dots:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea45";
}

.icon-download-report:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea46";
}

.icon-dropdown:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea47";
}

.icon-duration:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea48";
}

.icon-earthweek-logo:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea49";
}

.icon-easter-bow:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4a";
}

.icon-edp-tool:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4b";
}

.icon-email-circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4c";
}

.icon-email:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4d";
}

.icon-env-leaf:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4e";
}

.icon-env-waste:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4f";
}

.icon-env-water:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea50";
}

.icon-exclusive-partnership:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea51";
}

.icon-fabric:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea52";
}

.icon-favourite:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea53";
}

.icon-filter-icon:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea54";
}

.icon-foam:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea55";
}

.icon-gift-filled:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea56";
}

.icon-gift:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea57";
}

.icon-giftcard-promotions:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea58";
}

.icon-giftcard:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea59";
}

.icon-giving-back:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5a";
}

.icon-hair-test:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5b";
}

.icon-hand-gift:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5c";
}

.icon-hand-heart:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5d";
}

.icon-heart:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5e";
}

.icon-home:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5f";
}

.icon-hor-bottle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea60";
}

.icon-hor-candleholder:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea61";
}

.icon-hor-fragrance:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea62";
}

.icon-hor-sticks:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea63";
}

.icon-hor-wax:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea64";
}

.icon-hydra-boost-complex-logo-white:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea65";
}

.icon-hydra-boost-icon:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea66";
}

.icon-ico-clock:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea67";
}

.icon-icon_system_min_circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea68";
}

.icon-icon_system_plus_circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea69";
}

.icon-key-skeleton-thin:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea6a";
}

.icon-key:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea6b";
}

.icon-leaf:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea6c";
}

.icon-lightning:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea6d";
}

.icon-limiting-waste:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea6e";
}

.icon-location-alt:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea6f";
}

.icon-location:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea70";
}

.icon-magazine:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea71";
}

.icon-magnifying-glass:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea72";
}

.icon-mail:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea73";
}

.icon-man:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea74";
}

.icon-menu:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea75";
}

.icon-menu2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea76";
}

.icon-minus:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea77";
}

.icon-minus2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea78";
}

.icon-minus3:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea79";
}

.icon-minus3invert:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea7a";
}

.icon-my-account:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea7b";
}

.icon-my-order:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea7c";
}

.icon-my-rituals-dark:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea7d";
}

.icon-natural-origin-90:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea7e";
}

.icon-natural-origin-95-fr:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea7f";
}

.icon-natural-origin-95:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea80";
}

.icon-natural-origin-97:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea81";
}

.icon-newsletter:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea82";
}

.icon-online-advice:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea83";
}

.icon-online:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea84";
}

.icon-our-stores:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea85";
}

.icon-package:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea86";
}

.icon-password-not-visible:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea87";
}

.icon-password-visible:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea88";
}

.icon-pause-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea89";
}

.icon-pause:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea8a";
}

.icon-payment:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea8b";
}

.icon-pencil:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea8c";
}

.icon-perfume-genie-bottle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea8d";
}

.icon-perfume-genie:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea8e";
}

.icon-plant-a-tree:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea8f";
}

.icon-play-advent:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea90";
}

.icon-play-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea91";
}

.icon-play:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea92";
}

.icon-plus:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea93";
}

.icon-plus2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea94";
}

.icon-plus3:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea95";
}

.icon-plus3invert:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea96";
}

.icon-powerrechargeformula:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea97";
}

.icon-product-info:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea98";
}

.icon-product-quality-feather:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea99";
}

.icon-product-quality-lotus:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea9a";
}

.icon-product-quality-sparkle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea9b";
}

.icon-product-quality-sun:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea9c";
}

.icon-product-quality-water-drop:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea9d";
}

.icon-product-quality-waves:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea9e";
}

.icon-rating:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea9f";
}

.icon-refill-tree-counter:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa0";
}

.icon-refill-trees-protected:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa1";
}

.icon-restart:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa2";
}

.icon-returns:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa3";
}

.icon-ritual-quality:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa4";
}

.icon-rituals-easter-egg:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa5";
}

.icon-rituals-logo:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa6";
}

.icon-ruler:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa7";
}

.icon-sale:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa8";
}

.icon-scan:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaa9";
}

.icon-search-dotless:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaaa";
}

.icon-search-magnifier:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaab";
}

.icon-search:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaac";
}

.icon-secure-dotless:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaad";
}

.icon-secure:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaae";
}

.icon-send:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaaf";
}

.icon-service:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab0";
}

.icon-share:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab1";
}

.icon-shop:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab2";
}

.icon-shopping:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab3";
}

.icon-size:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab4";
}

.icon-skincare-test:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab5";
}

.icon-social--facebook-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab6";
}

.icon-social--facebook:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab7";
}

.icon-social--facebook2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab8";
}

.icon-social--fbmessenger:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab9";
}

.icon-social--google:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaba";
}

.icon-social--instagram-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eabb";
}

.icon-social--instagram:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eabc";
}

.icon-social--instagram2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eabd";
}

.icon-social--linkedin-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eabe";
}

.icon-social--messenger:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eabf";
}

.icon-social--pinterest-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac0";
}

.icon-social--pinterest:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac1";
}

.icon-social--pinterest2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac2";
}

.icon-social--snapchat-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac3";
}

.icon-social--tiktok-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac4";
}

.icon-social--twitter-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac5";
}

.icon-social--twitter:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac6";
}

.icon-social--twitter2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac7";
}

.icon-social--whatsapp-filled:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac8";
}

.icon-social--whatsapp:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eac9";
}

.icon-social--youtube-large:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaca";
}

.icon-social--youtube:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eacb";
}

.icon-social--youtube2:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eacc";
}

.icon-sos:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eacd";
}

.icon-star:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eace";
}

.icon-store:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eacf";
}

.icon-super-soft:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead0";
}

.icon-supplements:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead1";
}

.icon-sustainability-report:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead2";
}

.icon-sustainability:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead3";
}

.icon-system-danger:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead4";
}

.icon-system-genie-larger-outline-dark:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead5";
}

.icon-system-lock:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead6";
}

.icon-talisman-bottle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead7";
}

.icon-talisman-cap:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead8";
}

.icon-talisman-talisman:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ead9";
}

.icon-tao-logo:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eada";
}

.icon-thread-count:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eadb";
}

.icon-thread:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eadc";
}

.icon-timer:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eadd";
}

.icon-tranquility:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eade";
}

.icon-user:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eadf";
}

.icon-vegan:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae0";
}

.icon-video:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae1";
}

.icon-view--list:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae2";
}

.icon-view--tiles:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae3";
}

.icon-waste:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae4";
}

.icon-water-and-drop:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae5";
}

.icon-water-drop:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae6";
}

.icon-water:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae7";
}

.icon-weight:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae8";
}

.icon-well-being:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eae9";
}

.icon-woman:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaea";
}

.icon-yoga-fill:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaeb";
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ul {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:focus:not(.focus-visible) {
  outline: none;
}

/* ******************************
* ======== INNER WRAPPER ========
******************************* */
/* ***************************
* ======== HEADERS ========
**************************** */
/* ******************************
* ======== MEDIA QUERIES ========
******************************* */
/* *********************************
* ======== BROWSER SPECIFIC ========
********************************** */
/* IE10+ specific styles */
/* ******************************
* ======== TEXT MIXINS ========
******************************* */
@font-face {
  font-family: "ClassGarmnd BT";
  src: local("ClassGarmnd BT"), url("../fonts/ClassGarmndBTW05-Roman.woff2") format("woff2"), url("../fonts/ClassGarmndBTW05-Roman.woff") format("woff"), url("../fonts/ClassGarmndBT-Roman.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Scala Sans Pro";
  src: local("Scala Sans Pro"), url("../fonts/ScalaSansW05-Light.woff2") format("woff2"), url("../fonts/ScalaSansW05-Light.woff") format("woff"), url("../fonts/ScalaSansPro-Light.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 350;
}
html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  background-color: #efedea;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  color: #453f3f;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 26px;
  }
}
body.no-scroll {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
}
@media (max-width: 767px) {
  body.no-scroll .sticky-bar {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
}
body.personalised-gifting-bg {
  background-color: #efedea;
}
body.personalised-gifting-page {
  background-color: #ffffff;
}
body.personalised-gifting-page .pt_product-details {
  overflow: hidden;
  position: relative;
  width: 100%;
}

p {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  color: #453f3f;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  p {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  p a {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 26px;
  }
}

a,
.link {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #886655;
  line-height: 28px;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
a.link--large,
.link.link--large {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}
a:hover,
.link:hover {
  text-decoration: none;
}

.content ul {
  list-style-type: disc;
}

h1 {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 3.3px;
}

h2,
h3 {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 29px;
  line-height: 40px;
  letter-spacing: 2.4px;
}

h4,
h5,
h6 {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 4px;
}

.headline {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 54px;
  letter-spacing: 8px;
  line-height: 58px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .headline {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
  }
}

.heading-h1 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 50px;
  letter-spacing: 2px;
  line-height: 58px;
  text-transform: none;
}
@media (max-width: 767px) {
  .heading-h1 {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 46px;
  }
}

.heading-h2 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 40px;
  letter-spacing: 10.7px;
  line-height: 50px;
  text-transform: uppercase;
}
.heading-h3 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 8px;
  text-transform: uppercase;
}
.heading-intro {
  color: #453f3f;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 28px;
  letter-spacing: 0.5px;
  line-height: 40px;
  text-transform: none;
}
.heading-subtitle {
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 5px;
  line-height: 25px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .heading-subtitle {
    font-size: 12px;
    letter-spacing: 3.75px;
    line-height: 20px;
  }
}

.heading-h4 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 2.6px;
  line-height: 20px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .heading-h4 {
    font-size: 12px;
    letter-spacing: 1.9px;
    line-height: 18px;
  }
}

.heading-h5 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .heading-h5 {
    font-size: 11px;
    letter-spacing: 3.6px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.heading-h6 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 2.5px;
  line-height: 36px;
  text-transform: none;
}
.heading-h7 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-transform: none;
}
.heading-h8 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  text-transform: none;
}
@media (max-width: 767px) {
  .heading-h8 {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
  }
}

.heading-h9 {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
.heading-h10 {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 11px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-transform: uppercase;
}
.heading-h11 {
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2.8px;
  line-height: 14px;
  text-transform: uppercase;
}
.promotional-label {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding: 8px 16px;
}
.promotional-label::first-letter {
  text-transform: capitalize;
}

.the-ritual-of-title {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  color: #000000;
  display: block;
  line-height: 23px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.the-ritual-of-title.small {
  font-size: 11px;
  line-height: 18px;
  color: #9a7b69;
  letter-spacing: 1.5px;
}

.product-name {
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  color: #000000;
  text-transform: none;
}
@media (min-width: 768px) {
  .product-name {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
  }
}
.product-name.small {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.body-copy-medium {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 33px;
}
.body-copy-medium a {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 33px;
}

.body-copy-large {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 0.6px;
  line-height: 40px;
}
.body-copy-large a {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 0.6px;
  line-height: 40px;
}

.body-copy-tiles {
  color: #1a1919;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 13px;
}

.body-copy-desc-info {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
.body-copy-desc-info a {
  font-size: 12px;
}

.body-copy-desc {
  color: rgba(26, 25, 25, 0.5);
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 13px;
}

.visually-hidden {
  border: 0 none;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  min-width: 1px;
}

.inner-wrapper,
.wrapper__inner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

.inner-wrapper-small {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .inner-wrapper-small {
    flex-direction: row;
  }
}

.modal-overlay {
  z-index: 991;
  background-color: #1a1919;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: all;
}
.modal-overlay:not(.flyin-component) {
  display: none;
}
.modal-overlay.cookie-modal {
  z-index: 1001;
}

.linked-block {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .page-fullheight {
    min-height: 100vh;
    padding-bottom: 80px;
    position: relative;
    width: 100%;
  }
  .page-fullheight .footer {
    bottom: 0;
    left: 0;
    min-height: 80px;
    position: absolute;
  }
}

.not-clickable {
  pointer-events: none;
  cursor: default;
}

.backdrop-transition-enter.modal-overlay,
.backdrop-transition-leave-to.modal-overlay {
  opacity: 0;
}

.backdrop-transition-enter-active.modal-overlay,
.backdrop-transition-leave-active.modal-overlay {
  transition: opacity 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.no-padding {
  padding: 0 !important;
}
.no-padding .close-toaster {
  color: #1a1919;
  font-size: 24px;
  right: 16px;
  top: 14px;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.btn {
  background-color: #1a1919;
  border: 1px solid #1a1919;
  color: #ffffff;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 2px;
  line-height: 1.2;
  min-width: 220px;
  max-width: 280px;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
}
.btn.btn--primary, .btn--primary .btn {
  background-color: #1a1919;
  border: 1px solid #1a1919;
}
.btn.btn--secondary, .btn--secondary .btn {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
}
.btn.btn--tertiary, .btn--tertiary .btn {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
}
.btn.btn--cancel-order {
  color: #ffffff;
  margin-right: 60px;
  max-height: 30px;
  min-width: 180px;
  padding: 8px;
  position: absolute;
  right: 0;
}
@media (max-width: 767px) {
  .btn.btn--cancel-order {
    margin: 0 0 20px 0;
    position: relative;
    width: 90%;
  }
}
.btn.btn--white, .btn--white .btn {
  background-color: transparent;
  border: 1px solid #ffffff;
}
.btn.btn--white-filled {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #1a1919;
}
.btn.btn--white-black {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.btn.btn--banner {
  background-color: #1a1919;
  border: 1px solid #1a1919;
}
.btn.btn--disabled, .btn--disabled .btn, .btn[disabled] {
  background-color: #b4b4b4;
  border: 1px solid #b4b4b4;
  color: #ffffff;
  cursor: default;
  pointer-events: none;
}
.btn.btn--stripped {
  background-color: transparent;
  border: 0;
  color: #453f3f;
  text-transform: none;
  min-width: 1px;
  min-height: 1px;
  padding: 0;
}
@media (max-width: 767px) {
  .btn.btn-secondary-on-mobile {
    background-color: transparent;
    border-color: #b4b4b4;
    color: #1a1919;
  }
}
.btn.btn-full-wdth {
  max-width: none;
  padding: 20px 20px;
  width: 100%;
}
.btn.btn-add span {
  padding-left: 22px;
  position: relative;
}
.btn.btn-add span::before {
  color: #ffffff;
  content: "\ea95";
  font-family: "iconfont";
  font-size: 14px;
  height: 16px;
  left: 0;
  position: absolute;
  top: -3px;
  transition: color 0.2s ease-in-out;
  width: 16px;
}
.btn.btn--sandy {
  background-color: #ffffff;
  border: 1px solid #efedea;
  color: #efedea;
}
@media (min-width: 1024px) {
  .btn:hover, .btn:active {
    background-color: transparent;
    color: #453f3f;
  }
  .btn:hover.btn-add span::before, .btn:active.btn-add span::before {
    color: #453f3f;
  }
  .btn.btn--secondary:hover, .btn.btn--secondary:active, .btn--secondary .btn:hover, .btn--secondary .btn:active {
    border: 1px solid #1a1919;
  }
  .btn.btn--secondary.btn--secondary-hover:hover, .btn.btn--secondary.btn--secondary-hover:active, .btn--secondary .btn.btn--secondary-hover:hover, .btn--secondary .btn.btn--secondary-hover:active {
    background-color: #1a1919;
  }
  .btn.btn--tertiary:hover, .btn.btn--tertiary:active, .btn--tertiary .btn:hover, .btn--tertiary .btn:active {
    background-color: #1a1919;
    color: #ffffff;
  }
  .btn.btn--banner:hover, .btn.btn--banner:active {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  .btn.btn--white:hover, .btn.btn--white:active, .btn--white .btn:hover, .btn--white .btn:active {
    background-color: #ffffff;
    color: #453f3f;
  }
  .btn.btn--white-filled:hover, .btn.btn--white-filled:active {
    background-color: transparent;
    color: #ffffff;
  }
  .btn.btn--white-black:hover, .btn.btn--white-black:active {
    border: 1px solid #1a1919;
    color: #ffffff;
  }
  .btn.btn--sandy:hover, .btn.btn--sandy:active {
    border: 1px solid #1a1919;
    color: #1a1919;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn {
    padding: 17px 20px 12px;
  }
}
.btn .send-icon-visible {
  background-color: #1a1919;
  color: #ffffff;
  display: inline-block;
  position: relative;
  z-index: 10;
}
.btn .send-icon-visible::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .btn .send-icon-visible::before {
    font-size: 10px;
  }
}

.pulsating-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  bottom: 100px;
  height: 32px;
  padding-right: 10px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
}
.pulsating-button-wrapper.hidden {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
}
.pulsating-button-wrapper .pulsating-button-label {
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 28px;
  position: relative;
}

.pulsating-button {
  display: inline-block;
  position: relative;
  bottom: 0;
  left: -5px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  animation: pulse 2s infinite;
  transform-origin: center;
  transition: transform 0.4s cubic-bezier(0.18, 0.74, 0.36, 0.99), opacity 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7), visibility 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.pulsating-button::before {
  content: "";
  border-radius: 100%;
  background-image: url("../images/pulsating-button-gradient.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.pulsating-button::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea09";
  font-size: 8px;
  color: #a68674;
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pulsating-button:hover {
  transform: scale(1.2);
}
body.video-fullscreen .pulsating-button {
  animation: none;
  box-shadow: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.back-button {
  font-size: 12px;
  letter-spacing: 1.5px;
  position: relative;
  text-transform: uppercase;
  color: #9a7b69;
  text-decoration: underline;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.login-checkout-v3 .back-button {
  color: #000000;
  text-decoration: none;
}
.back-button:hover, .back-button:active {
  text-decoration: none;
}
.login-checkout-v3 .back-button:hover, .login-checkout-v3 .back-button:active {
  text-decoration: underline;
}
.back-button .icon {
  font-size: 26px;
  position: relative;
  top: 3px;
  left: 0;
}

.btn-configure {
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
  z-index: 10;
}

input:not(.vue-input) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-top: 8px;
  order: 2;
  padding: 12px 21px;
  width: 100%;
}
input:not(.vue-input):-webkit-autofill, input:not(.vue-input):-webkit-autofill:hover, input:not(.vue-input):-webkit-autofill:focus, input:not(.vue-input):-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #fcf8de inset;
}
input:not(.vue-input)[type=number]::-webkit-inner-spin-button, input:not(.vue-input)[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:not(.vue-input)[type=number] {
  -moz-appearance: textfield;
}
input:not(.vue-input)[type=text]::-ms-clear {
  display: none;
}
input:not(.vue-input)::placeholder {
  color: #aeaeae;
}
input:not(.vue-input):read-only, input:not(.vue-input):disabled {
  background-color: #efedea;
  cursor: not-allowed;
}
input:not(.vue-input):read-only + .icon-read-only, input:not(.vue-input):disabled + .icon-read-only {
  position: absolute;
  transform: translateY(36px);
  width: 100%;
}
.giftcard-number-wrapper input:not(.vue-input):read-only + .icon-read-only, .giftcard-number-wrapper input:not(.vue-input):disabled + .icon-read-only {
  display: none;
}
input:not(.vue-input):read-only + .icon-read-only::before, input:not(.vue-input):disabled + .icon-read-only::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #453f3f;
  content: "\ead6";
  display: inline-block;
  position: absolute;
  right: 16px;
}
input:not(.vue-input).error {
  border: 1px solid #d6122d;
}
input:not(.vue-input).error ~ .password-reveal {
  bottom: 35px;
}
input:not(.vue-input).error ~ .selectric-items {
  top: calc(100% - 22px);
}
input:not(.vue-input).postal-code-change {
  border-top: 1px solid #886655;
}

input[type=radio],
input[type=checkbox] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
input[type=radio]:checked + label::before,
input[type=checkbox]:checked + label::before {
  background-color: #9a7b69;
  border-color: #9a7b69;
  content: "\ea2f";
}
input[type=radio] + label,
input[type=checkbox] + label {
  display: block;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 4px 0 0 36px;
  position: relative;
}
input[type=radio] + label::before,
input[type=checkbox] + label::before {
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  color: #ffffff;
  content: "";
  font-family: "iconfont";
  font-size: 8px;
  height: 24px;
  left: 0;
  letter-spacing: -1px;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 24px;
}

.postal-code-warning-message {
  font-size: 12px;
  letter-spacing: 0.4px;
  margin: 2px 0 5px;
  line-height: 19px;
  color: #767676;
}
@media (min-width: 768px) {
  .postal-code-warning-message {
    margin: -2px 0 2px;
    line-height: 18px;
  }
}
.postal-code-warning-message + .error ~ .inputfield-text {
  border-top: 1px solid #d6122d;
}

.generic-dialog-flyout .postal-code-warning-message {
  margin: -2px 0 4px;
  line-height: 18px;
}

.inputfield-row {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  text-align: left;
  width: 100%;
}
.inputfield-row .password-reveal {
  bottom: 14px;
  position: absolute;
  right: 18px;
}
.inputfield-row .password-reveal.visible .password-visible {
  display: block;
}
.inputfield-row .password-reveal.visible .password-not-visible {
  display: none;
}
.inputfield-row .password-reveal .password-reveal-icon {
  font-size: 20px;
}
.inputfield-row .password-reveal .password-visible {
  display: none;
  position: relative;
  top: -2px;
  font-size: 11px;
}
.inputfield-row .password-reveal .password-not-visible {
  font-size: 14px;
}
.inputfield-row .error-message {
  order: 2;
}
.inputfield-row .selectric-inputfield-select {
  display: flex;
  flex-direction: column;
  order: 2;
}
.inputfield-row label {
  color: #1a1919;
  display: flex;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  justify-content: space-between;
  letter-spacing: 0.5px;
  line-height: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .inputfield-row label {
    order: 1;
  }
}
.inputfield-row label .optional-indicator {
  color: #767676;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-left: 5px;
}
.inputfield-row label .field-help {
  margin-left: 8px;
  position: relative;
}
.inputfield-row label .field-help::before {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
  content: "i";
  font-size: 11px;
  height: 12px;
  left: 0;
  line-height: 15px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 12px;
}
.inputfield-row label .field-help:hover span {
  opacity: 1;
  transition-delay: 0s;
}
.inputfield-row label .field-help span {
  background-color: #1a1919;
  bottom: 34px;
  color: #ffffff;
  left: -95px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: opacity 0.6s cubic-bezier(0.05, 0.5, 0.3, 1) 1.5s;
  width: 210px;
  z-index: 11;
}
.inputfield-row label .field-help span::before {
  background-color: #1a1919;
  bottom: -17px;
  content: "";
  height: 20px;
  left: 98px;
  position: absolute;
  transform-origin: center;
  transform: rotate(45deg) translateX(-50%);
  width: 20px;
  z-index: 10;
}
.inputfield-row .label-disabled {
  color: rgba(26, 25, 25, 0.5);
}
.inputfield-row span.error:not(.hide) {
  display: block;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 13px;
  margin-top: 8px;
  order: 4;
  text-align: right;
}
.inputfield-row span.error:not(.hide) a {
  color: #d6122d;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 13px;
}
.inputfield-row span.error:not(.hide) ~ .selectric-items {
  top: calc(100% - 22px);
}
.inputfield-row .ssn-loading-indicator:not(.hide) {
  display: block;
  width: 100%;
}

.select-row.floating-label .error-text ~ label {
  position: absolute;
  visibility: hidden;
}

.breadcrumb {
  display: block;
  width: 100%;
}
.breadcrumb ol {
  margin: 0 auto;
  padding: 2px 0;
}
@media (min-width: 1024px) {
  .breadcrumb ol {
    padding: 2px 40px;
  }
}
.breadcrumb .breadcrumb-list-item {
  display: inline-block;
}
@media (max-width: 767px) {
  .breadcrumb .breadcrumb-list-item.back-button-home-wrapper .breadcrumb-element span {
    text-decoration: underline;
  }
}
.breadcrumb .breadcrumb-list-item.back-button-home-wrapper:nth-last-child(2):before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1a";
}
.breadcrumb .breadcrumb-list-item.back-button-home-wrapper::before {
  font-size: 23px;
  margin: 0 -4px 0 -10px;
}
@media (min-width: 768px) {
  .breadcrumb .breadcrumb-list-item.back-button-home-wrapper::before {
    display: none;
  }
}
@media (max-width: 767px) {
  .breadcrumb .breadcrumb-list-item:not(:nth-last-child(2)) {
    display: none;
  }
}
.breadcrumb .breadcrumb-list-item:nth-last-child(2):before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1e";
}
.breadcrumb .breadcrumb-list-item::before {
  font-size: 9px;
  margin-right: 0;
}
@media (min-width: 768px) {
  .breadcrumb .breadcrumb-list-item::before {
    display: none;
  }
}
.breadcrumb .breadcrumb-list-item:last-child .breadcrumb-element:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1e";
}
.breadcrumb .breadcrumb-list-item:last-child .breadcrumb-element::after {
  content: "";
}
@media (min-width: 768px) {
  .breadcrumb .breadcrumb-list-item:last-child .breadcrumb-element::before {
    display: none;
  }
}
.breadcrumb .breadcrumb-list-item:last-child .breadcrumb-element.last {
  pointer-events: none;
}
.breadcrumb .breadcrumb-list-item a span {
  display: inline-block;
  position: relative;
}
.breadcrumb .breadcrumb-list-item a span::after {
  content: "";
  background-color: #968977;
  bottom: 0;
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: width 0.3s ease;
  width: 0;
}
.breadcrumb .breadcrumb-list-item a:hover {
  text-decoration: none;
}
.breadcrumb .breadcrumb-list-item a:hover span::after {
  width: 100%;
}
.breadcrumb .breadcrumb-element {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  color: #453f3f;
  text-transform: uppercase;
  text-decoration: none;
}
@media (min-width: 768px) {
  .breadcrumb .breadcrumb-element::after {
    content: "-";
    display: inline-block;
    padding: 0;
    line-height: normal;
  }
}

@media (min-width: 1024px) and (max-width: 1249px) {
  div.breadcrumb ol {
    padding: 2px 7px;
  }
}

.breadcrumb-collection-page {
  position: absolute;
  top: 91px;
  left: 0;
  z-index: 4;
}
@media (min-width: 768px) {
  .breadcrumb-collection-page {
    top: 105px;
  }
}
@media (min-width: 1024px) {
  .breadcrumb-collection-page {
    top: 120px;
  }
}
.breadcrumb-collection-page .breadcrumb-list-item,
.breadcrumb-collection-page .breadcrumb-element {
  color: #ffffff;
}
.breadcrumb-collection-page .breadcrumb-list-item a span::after {
  background-color: #ffffff;
}
.breadcrumb-collection-page.breadcrumb-subcollection .breadcrumb-element span.breadcrumb-mobile-label {
  display: none;
}
@media (max-width: 767px) {
  .breadcrumb-collection-page.breadcrumb-subcollection .breadcrumb-element span {
    display: none;
  }
  .breadcrumb-collection-page.breadcrumb-subcollection .breadcrumb-element span.breadcrumb-mobile-label {
    display: inline-block;
  }
}

#wrapper .header-shop-overlay ~ .full-width .primary-content .breadcrumb-collection-page {
  top: 140px;
}
@media (min-width: 1024px) {
  #wrapper .header-shop-overlay ~ .full-width .primary-content .breadcrumb-collection-page {
    top: 160px;
  }
}

.refill-impact-container {
  background-color: #f6f4f2;
  min-height: 723px;
  padding: 80px 0;
  width: 100%;
}
.refill-impact-container .refill-impact-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: center;
}
.refill-impact-container .refill-impact-top .refill-imapct-main-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 1.2px;
  line-height: 40px;
  margin: 0 auto 16px;
  max-width: 858px;
  min-height: 40px;
  text-align: center;
  text-transform: none;
}
.refill-impact-container .refill-impact-top .refill-impact-main-description {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin: auto;
  max-width: 666px;
  min-height: 65px;
  text-align: center;
}
@media (max-width: 767px) {
  .refill-impact-container .refill-impact-top .refill-impact-main-description {
    padding: 0 18px;
  }
}
.refill-impact-container .refill-inner-wrapper {
  min-height: 354px;
  position: relative;
}
.refill-impact-container .slick-prev,
.refill-impact-container .slick-next {
  align-items: center;
  display: none;
  height: 100%;
  position: absolute;
  z-index: 1;
}
@media (max-width: 767px) {
  .refill-impact-container .slick-prev,
  .refill-impact-container .slick-next {
    display: flex;
  }
}
.refill-impact-container .slick-prev {
  background: linear-gradient(to right, #f6f4f2 0%, rgba(255, 255, 255, 0) 50%);
  left: 0;
  padding-left: 20px;
}
.refill-impact-container .slick-next {
  background: linear-gradient(to left, #f6f4f2 0%, rgba(255, 255, 255, 0) 50%);
  padding-right: 20px;
  right: 0;
}
.refill-impact-container .refill-impact-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  min-height: 354px;
}
@media (max-width: 767px) {
  .refill-impact-container .refill-impact-bottom {
    flex-wrap: nowrap;
  }
}
.refill-impact-container .refill-impact-bottom .slick-list {
  padding-left: 50px !important;
}
.refill-impact-container .refill-impact-bottom .refill-impact-slider-item {
  min-height: 354px;
  min-width: 178px;
}
.refill-impact-container .refill-impact-bottom .refill-impact-img-box {
  background-color: #efedea;
  border-radius: 50%;
  height: 130px;
  margin: 20px auto;
  overflow: hidden;
  width: 130px;
}
.refill-impact-container .refill-impact-bottom .refill-impact-img-box .refill-impact-img {
  background-position: center;
  background-size: cover;
  height: 80px;
  margin: 25px 28px;
  width: 73.3px;
}
.refill-impact-container .refill-impact-bottom .refill-impact-item-name {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  height: 24px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 0 auto 17px;
  text-align: center;
  max-width: 183.86px;
}
.refill-impact-container .refill-impact-bottom .refill-imapct-items-group {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  margin: auto;
  width: 150.59px;
}
.refill-impact-container .refill-impact-bottom .refill-imapct-items-group .refill-impact-group-description {
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 150.59px;
}
.refill-impact-container .refill-impact-bottom .refill-imapct-items-group .refill-impact-group-description .refill-impact-group-left {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  height: 24px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-align: right;
  width: 64.79px;
}
.refill-impact-container .refill-impact-bottom .refill-imapct-items-group .refill-impact-group-description .refill-impact-group-middle {
  border-left: 1px solid #626262;
  box-sizing: border-box;
  height: 13.86px;
  margin: 5px 10px;
  width: 0.88px;
}
.refill-impact-container .refill-impact-bottom .refill-imapct-items-group .refill-impact-group-description .refill-impact-group-right {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  height: 24px;
  letter-spacing: 0.4px;
  line-height: 24px;
  width: 64.79px;
}
.refill-impact-container .refill-impact-progress {
  background-color: #d1d1d1;
  background-image: linear-gradient(to right, #000000, #000000);
  background-repeat: no-repeat;
  background-size: 0 100%;
  border-radius: 4px;
  display: none;
  height: 4px;
  margin: 0 auto 40px;
  overflow: hidden;
  transition: background-size 0.4s ease-in-out;
  width: 80%;
}
@media (max-width: 767px) {
  .refill-impact-container .refill-impact-progress {
    display: block;
  }
}
.refill-impact-container .refill-impact-btn-wrapper {
  display: flex;
  justify-content: center;
}
.refill-impact-container .refill-impact-btn-wrapper .refill-impact-btn {
  border: 1px solid #b4b4b4;
  box-sizing: border-box;
  margin: auto;
  min-height: 48px;
  padding: 9px 40px;
  text-decoration: none;
}
.refill-impact-container .refill-impact-btn-wrapper .refill-impact-btn .refill-impact-btn-text {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  height: 16px;
  letter-spacing: 2px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  width: 189px;
}

.product-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-style: normal;
  line-height: normal;
  color: #000000;
  font-size: 30px;
  letter-spacing: 2.5px;
}

.small-price .product-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-style: normal;
  line-height: normal;
  color: #000000;
  font-size: 16px;
  letter-spacing: 1px;
}

.unit-base-price {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.31px;
  color: #767676;
}
.unit-base-price.legal-note {
  margin-top: 13px;
  text-transform: none;
}

.product-variations,
.product-options {
  margin-bottom: 50px;
  width: 100%;
  max-width: 252px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .product-variations,
  .product-options {
    max-width: none;
  }
}
.product-variations ul,
.product-options ul {
  list-style: none;
}
.product-variations .swatches-wrapper,
.product-options .swatches-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
}
@media (min-width: 768px) {
  .product-variations .swatches-wrapper,
  .product-options .swatches-wrapper {
    max-width: none;
    justify-content: center;
  }
}
.product-variations .swatches,
.product-options .swatches {
  display: flex;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .product-variations .swatch,
  .product-options .swatch {
    width: 25%;
    flex-basis: 25%;
  }
}
.product-variations .swatch .swatch__anchor,
.product-options .swatch .swatch__anchor {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #b4b4b4;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  margin: 0 10px 20px;
  padding: 4px 0 0 2px;
  text-decoration: none;
  transition: 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 48px;
}
@media (max-width: 767px) {
  .product-variations .swatch .swatch__anchor,
  .product-options .swatch .swatch__anchor {
    margin-bottom: 15px;
  }
}
.product-variations .swatch .swatch__anchor:hover,
.product-options .swatch .swatch__anchor:hover {
  background-color: #9a7b69;
  border-color: #9a7b69;
  color: #ffffff;
  text-decoration: none;
}
.product-variations .swatch.unselectable .swatch__anchor, .product-variations .swatch.selectable--out-of-stock .swatch__anchor,
.product-options .swatch.unselectable .swatch__anchor,
.product-options .swatch.selectable--out-of-stock .swatch__anchor {
  border-color: #d8d8d8;
  color: #b4b4b4;
}
.product-variations .swatch.unselectable .swatch__anchor,
.product-options .swatch.unselectable .swatch__anchor {
  cursor: not-allowed;
}
.product-variations .swatch.unselectable .swatch__anchor:hover,
.product-options .swatch.unselectable .swatch__anchor:hover {
  background-color: #ffffff;
  border-color: #d8d8d8;
  color: #b4b4b4;
}
.product-variations .swatch.selectable--out-of-stock .swatch__anchor:hover,
.product-options .swatch.selectable--out-of-stock .swatch__anchor:hover {
  color: #ffffff;
}
.product-variations .swatch:not(.unselectable).selected .swatch__anchor,
.product-options .swatch:not(.unselectable).selected .swatch__anchor {
  border: 2px solid #9a7b69;
}

.product-options {
  margin: 0 auto 30px;
}
@media (min-width: 768px) {
  .product-options .swatches-wrapper {
    margin: 0 auto;
    max-width: 216px;
    justify-content: flex-start;
  }
}
.product-options .currency-code {
  color: #767676;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.variation-dropdown-mobile,
.option-dropdown-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.cart-page-wrapper .product-variations {
  width: auto;
  margin: 0 0 15px;
  min-height: 44px;
  max-width: 215px;
}
@media (min-width: 768px) {
  .cart-page-wrapper .product-variations {
    max-width: none;
  }
}
@media (min-width: 1025px) {
  .cart-page-wrapper .product-variations {
    margin: 0 20px 0 0;
  }
}
.cart-page-wrapper .product-variations .swatches-wrapper {
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.cart-page-wrapper .product-variations .swatch.selected .swatch__anchor {
  border: 1px solid #886655;
}
.cart-page-wrapper .product-variations .swatch.selectable--out-of-stock {
  background-color: transparent;
}
.cart-page-wrapper .product-variations .swatch.selectable--out-of-stock .swatch__anchor {
  color: #b4b4b4;
}
.cart-page-wrapper .product-variations .swatch .swatch__anchor {
  margin: 0 10px 0 0;
  border: 1px solid #d8d8d8;
  color: #1a1919;
  height: 44px;
  width: 44px;
}

.slick-slider .slick-arrow {
  align-self: center;
}

.slider-button {
  text-decoration: none;
  align-items: center;
  border-radius: 50%;
  color: #000000;
  display: flex;
  font-size: 44px;
  height: 50px;
  justify-content: center;
  transition: background 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 50px;
}
.slider-button:hover {
  background-color: #ffffff;
}
@media (hover: none) {
  .slider-button:hover {
    background-color: transparent;
  }
}
.slider-button:focus:not(.focus-visible) {
  outline: 0;
}

.slick-disabled .slider-button {
  color: #b4b4b4;
  opacity: 0;
  visibility: hidden;
}

.slider-bar {
  display: none;
  width: 100%;
  max-width: 300px;
  height: 2px;
  border-radius: 2px;
  background-color: #d1d1d1;
  position: relative;
  margin: 20px auto;
}
@media (min-width: 768px) {
  .slider-bar {
    height: 4px;
    border-radius: 4px;
  }
}
.slider-bar .indicator {
  display: block;
  position: absolute;
  background-color: #453f3f;
  height: 100%;
  border-radius: 4px;
  left: 0;
  transition: left 0.2s ease-out;
}

.slider-dots {
  bottom: 0;
  left: 50%;
  max-width: 300px;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}
.slider-dots .slick-dots {
  display: flex;
  height: 2px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.slider-dots li {
  display: block;
  flex-grow: 1;
}
.slider-dots button {
  background-color: transparent;
  display: block;
  font-size: 0;
  height: 2px;
  width: 100%;
}
@media (max-width: 767px) {
  .slider-dots {
    pointer-events: none;
  }
}
@media (min-width: 768px) {
  .slider-dots .slick-dots {
    height: 4px;
  }
  .slider-dots button {
    height: 4px;
  }
}

.sticky-bar {
  width: 100%;
  min-height: 61px;
  background-color: #ffffff;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.08);
  visibility: hidden;
  bottom: -90px;
  opacity: 1;
  transition: bottom 0.3s ease-in-out, visibility 0.1s ease-in-out 0.5s;
  z-index: 890;
  position: fixed;
  left: 0;
  display: flex;
}
@media (min-width: 768px) {
  .sticky-bar {
    min-height: 71px;
  }
}
.sticky-bar.show-bar {
  transition: bottom 0.3s ease-in-out, visibility 0.1s ease-in-out 0s;
  visibility: visible;
  bottom: 0;
}
.sticky-bar .variation-dropdown-mobile,
.sticky-bar .option-dropdown-mobile {
  display: block;
}
@media (min-width: 1024px) {
  .sticky-bar .variation-dropdown-mobile,
  .sticky-bar .option-dropdown-mobile {
    display: none;
  }
}
.sticky-bar .variation-dropdown-mobile {
  font-size: 50px;
}
.sticky-bar .image-container {
  max-height: 100%;
  align-items: center;
  display: flex;
}
.sticky-bar .image-container .image {
  width: 60px;
}
.sticky-bar .btn {
  max-width: none;
}
@media (min-width: 1024px) {
  .sticky-bar .btn:hover, .sticky-bar .btn:active {
    background: #452f3f;
    border-color: #452f3f;
    color: #ffffff;
  }
}

@media (min-width: 768px) {
  .sticky-bar-crm-cta {
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .sticky-bar-crm-cta .btn {
    line-height: 30px;
    min-width: auto;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .sticky-bar-crm-cta .btn {
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .sticky-bar-configurable .details-container {
    max-width: 200px;
  }
}
@media (min-width: 1024px) {
  .sticky-bar-configurable .details-container {
    max-width: none;
  }
}
.sticky-bar-configurable .sticky-button-container {
  width: auto;
  min-width: 0;
  display: flex;
}
.sticky-bar-configurable .sticky-button-container form {
  width: 100%;
}
.sticky-bar-configurable .sticky-button-container .btn-wrapper {
  display: flex;
  height: 100%;
}
.sticky-bar-configurable .sticky-button-container .btn-customize {
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px) {
  .sticky-bar-configurable .sticky-button-container .btn-customize {
    letter-spacing: 0;
    font-size: 0;
    line-height: 0;
  }
}
@media (min-width: 1025px) {
  .sticky-bar-configurable .sticky-button-container .btn-customize:hover .icon-pencil-configurable {
    background: url("../images/pencil-white-icon.svg") left top no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}
.sticky-bar-configurable .sticky-button-container .btn-customize .icon-pencil-configurable {
  background: url("../images/pencil-black-icon.svg") left top no-repeat;
  background-size: 20px 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  transition: 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .sticky-bar-configurable .sticky-button-container .btn-customize .icon-pencil-configurable {
    background: url("../images/pencil-black-icon.svg") left top no-repeat;
    background-size: 32px 32px;
    display: inline-block;
    height: 32px;
    width: 32px;
  }
}
@media (min-width: 768px) {
  .sticky-bar-configurable .sticky-button-container .btn-customize .icon-pencil-configurable {
    margin-top: -3px;
  }
}
.sticky-bar-configurable .sticky-button-container .btn-customize.btn--primary .icon-pencil-configurable {
  background: url("../images/pencil-white-icon.svg") left top no-repeat;
  background-size: 20px 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
}
@media (max-width: 767px) {
  .sticky-bar-configurable .sticky-button-container .btn-customize.btn--primary .icon-pencil-configurable {
    background: url("../images/pencil-white-icon.svg") left top no-repeat;
    background-size: 32px 32px;
    display: inline-block;
    height: 32px;
    width: 32px;
  }
}
@media (max-width: 767px) {
  .sticky-bar-configurable .sticky-button-container .btn {
    min-width: 0;
    width: 80px;
  }
}
@media (min-width: 768px) {
  .sticky-bar-configurable .sticky-button-container .btn {
    min-width: 200px;
  }
}
@media (min-width: 1024px) {
  .sticky-bar-configurable .sticky-button-container .btn {
    min-width: 280px;
  }
}
@media (min-width: 1160px) {
  .sticky-bar-configurable .sticky-button-container .btn {
    min-width: 375px;
  }
}
@media (max-width: 767px) {
  .sticky-bar-configurable .sticky-button-container .btn-add-to-cart {
    letter-spacing: 0;
    font-size: 0;
    line-height: 0;
  }
  .sticky-bar-configurable .sticky-button-container .btn-add-to-cart:before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea2c";
  }
  .sticky-bar-configurable .sticky-button-container .btn-add-to-cart::before {
    display: block;
    font-size: 20px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .sticky-bar-configurable .sticky-product-container .price-container {
    margin-left: 0;
  }
  .sticky-bar-configurable .sticky-product-container .price-container .product-price {
    margin: 0 10px 0 0;
  }
}

.sticky-product-container {
  display: flex;
  flex: 75%;
  padding: 5px 30px 5px 20px;
}
.sticky-product-container .details-container {
  display: none;
}
@media (min-width: 768px) {
  .sticky-product-container .details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }
}
.sticky-product-container .details-container .the-ritual-of-title {
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
  margin-bottom: 4px;
}
.sticky-product-container .price-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.sticky-product-container .price-container .product-price {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-left: 10px;
  position: relative;
}
.sticky-product-container .price-container .product-price.has-unit-base-price {
  margin-bottom: 12px;
}
.sticky-product-container .price-container .product-price .prices-container {
  text-align: right;
}
.sticky-product-container .price-container .product-price .prices-container .product-discount-no-strikethrough {
  color: #8c8c8c;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
.sticky-product-container .price-container .price-sales {
  white-space: nowrap;
  font-size: 16px;
  letter-spacing: 1.07px;
  line-height: 19px;
  display: block;
  margin-bottom: 3px;
}
.sticky-product-container .price-container .base-price-block {
  text-align: right;
  width: 100%;
}
.sticky-product-container .price-container .unit-base-price:not(.legal-note) {
  position: absolute;
  width: 100%;
}
@media (max-width: 1023px) {
  .sticky-product-container .price-container .unit-base-price:not(.legal-note) {
    left: 0;
  }
}
@media (min-width: 1024px) {
  .sticky-product-container .price-container .unit-base-price:not(.legal-note) {
    right: 0;
  }
}
.sticky-product-container .price-container .unit-base-price.legal-note {
  margin: 0;
}
.sticky-product-container .price-container .price-standard {
  color: #8c8c8c;
  font-size: 11px;
  letter-spacing: 1.65px;
  line-height: normal;
  text-decoration: line-through;
  display: block;
}

.sticky-button-container {
  flex: 25%;
}
.sticky-button-container form,
.sticky-button-container fieldset,
.sticky-button-container .product-add-to-cart,
.sticky-button-container .btn-wrapper,
.sticky-button-container .btn-add-to-cart,
.sticky-button-container .btn-select-option {
  height: 100%;
  width: 100%;
  min-width: 1px;
}
.sticky-button-container form > div {
  height: 100%;
}
.sticky-button-container fieldset .add-to-cart-inner {
  height: 100%;
}
.sticky-button-container .product-add-to-cart {
  position: relative;
}
@media (min-width: 768px) {
  .sticky-button-container .product-add-to-cart .btn {
    min-height: 71px;
  }
}
.sticky-button-container .product-add-to-cart .availability-web {
  background-color: #1a1919;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.sticky-button-container .product-add-to-cart .availability-web button {
  color: #ffffff;
  border: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .sticky-button-container .product-add-to-cart .availability-web button {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
  }
  .sticky-button-container .product-add-to-cart .availability-web button:before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea23";
  }
  .sticky-button-container .product-add-to-cart .availability-web button::before {
    font-size: 24px;
    line-height: 24px;
    text-indent: 0;
  }
}
@media (max-width: 767px) {
  .sticky-button-container .product-add-to-cart .availability-web .btn-out-of-stock-disabled {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
  }
  .sticky-button-container .product-add-to-cart .availability-web .btn-out-of-stock-disabled:before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea2c";
  }
}
@media (max-width: 767px) {
  .sticky-button-container .product-add-to-cart .availability-web .btn-out-of-stock-disabled::before {
    font-size: 24px;
    line-height: 24px;
    text-indent: 0;
  }
}
.sticky-button-container .btn-add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .sticky-button-container .btn-add-to-cart {
    letter-spacing: 0;
    font-size: 0;
    line-height: 0;
  }
  .sticky-button-container .btn-add-to-cart:before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea2c";
  }
  .sticky-button-container .btn-add-to-cart::before {
    text-indent: 0;
    font-size: 24px;
    line-height: 24px;
  }
}
.sticky-button-container .btn-add-to-cart.checked {
  letter-spacing: 0;
  font-size: 0;
}
.sticky-button-container .btn-add-to-cart.checked:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
}
.sticky-button-container .btn-add-to-cart.checked::before {
  display: inline-block !important;
  text-indent: 0;
  font-size: 14px;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sticky-button-container .btn-select-option {
  cursor: pointer;
  pointer-events: auto;
  background-color: #ffffff;
  color: #9a7b69;
  border: 0;
  border-left: 2px solid #f6f4f2;
}
.sticky-button-container .product-options {
  display: none;
}

.checkout-sticky-bar {
  min-height: 50px;
}
@media (min-width: 768px) {
  .checkout-sticky-bar {
    min-height: 71px;
  }
}
.checkout-sticky-bar .sticky-order-container {
  display: flex;
  flex-grow: 1;
  min-height: 71px;
  padding: 5px 20px;
}
.checkout-sticky-bar .sticky-order-container .details-container {
  display: none;
  align-items: center;
  text-transform: uppercase;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2.88px;
  color: #453f3f;
}
@media (min-width: 768px) {
  .checkout-sticky-bar .sticky-order-container .details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
  }
}
.checkout-sticky-bar .sticky-order-container .price-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .checkout-sticky-bar .sticky-order-container .price-container {
    margin-right: 0;
  }
}
.checkout-sticky-bar .sticky-order-container .price-container .order-total {
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.checkout-sticky-bar .products-number,
.checkout-sticky-bar .order-total-label {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .checkout-sticky-bar .products-number,
  .checkout-sticky-bar .order-total-label {
    margin-right: 7px;
  }
}
.checkout-sticky-bar .order-total-label {
  font-size: 13px;
  color: #767676;
  letter-spacing: 0.33px;
}
.checkout-sticky-bar .order-total-amount {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-style: normal;
  line-height: normal;
  color: #000000;
  font-size: 16px;
  letter-spacing: 1px;
  white-space: nowrap;
}
.checkout-sticky-bar .sticky-button-container {
  width: 50%;
}
@media (min-width: 768px) {
  .checkout-sticky-bar .sticky-button-container {
    width: auto;
    min-width: 200px;
  }
}
@media (min-width: 1024px) {
  .checkout-sticky-bar .sticky-button-container {
    width: 380px;
  }
}
.checkout-sticky-bar .sticky-button-container form,
.checkout-sticky-bar .sticky-button-container fieldset,
.checkout-sticky-bar .sticky-button-container .btn {
  height: 100%;
  width: 100%;
  min-width: 1px;
}
.checkout-sticky-bar .sticky-button-container .btn {
  height: 71px;
}

_:-ms-input-placeholder .order-total-label, :root .checkout-sticky-bar .order-total-label {
  padding-top: 4px;
}

.sticky-bar-spacer {
  height: 0;
  visibility: hidden;
  transition: height 0.3s ease-in-out;
}
.sticky-bar-spacer.show-bar {
  height: 50px;
  visibility: visible;
}
@media (min-width: 768px) {
  .sticky-bar-spacer.show-bar {
    height: 71px;
  }
}

.flyout-content {
  position: fixed;
  display: inline-block;
  width: 100%;
  top: 0;
  left: auto;
  bottom: 0;
  background-color: #ffffff;
  z-index: 999;
}
.flyout-content.minicart-flyout-container {
  width: 100%;
  max-width: 100%;
  right: -100%;
  color: #000000;
  transform: translateZ(0);
  transition: right 0.5s ease-in-out;
}
.flyout-content.minicart-flyout-container.open {
  right: 0;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container {
    width: 480px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .flyout-content.minicart-flyout-container {
    width: 400px;
  }
}
@media (min-width: 1024px) {
  .flyout-content.minicart-flyout-container {
    max-width: 520px;
    width: 50%;
    right: -50%;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content {
  background-color: #f6f4f2;
  overflow: hidden;
  padding-bottom: 206px;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content.mini-cart-flyout-empty {
  padding-bottom: 0;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper {
  overflow: hidden;
  padding: 40px;
}
@media screen and (max-width: 520px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper {
    padding: 40px 20px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .minicart-flyout-header {
  padding: 23px 0 16px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .minicart-flyout-header {
    padding-bottom: 10px;
    padding-left: 40px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .flyout-title {
  color: #1a1919;
  font-size: 30px;
  letter-spacing: 0.7px;
  line-height: 34px;
  text-transform: none;
  margin-bottom: 0;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .flyout-title-overview {
  color: #453f3f;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin: 16px 0 27px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .flyout-progress-bar {
    align-items: unset;
    margin: 40px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .mini-cart-empty-text {
  color: #767676;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  padding: 0 0 53px;
  border-bottom: 1px solid #d8d8d8;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .mini-cart-empty-text {
    padding-left: 40px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .empty-minicart-bottom {
  text-align: center;
  margin-top: 98px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .empty-minicart-bottom a {
  margin-bottom: 20px;
  background-color: #1a1919;
  border: 1px solid #1a1919;
  min-width: 280px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .empty-minicart-bottom a {
    margin-bottom: 34px;
    min-width: 200px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .empty-minicart-bottom a:hover, .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .empty-minicart-bottom a:active {
  background-color: transparent;
  color: #453f3f;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .empty-minicart-bottom p {
  font-size: 14px;
  color: #767676;
  letter-spacing: 0.88px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .mini-cart-pricing .link {
  margin-right: 23px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .product-availability-list {
  list-style: none;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-wrapper .product-availability-list .notavailable {
  margin: 19px 0 0;
  text-transform: none;
  color: #d6122d;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .flyout-section {
  position: relative;
  height: 100%;
  overflow-y: auto;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product {
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 1024px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product {
    padding: 16px 24px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product:first-of-type {
  border-top: 1px solid #d8d8d8;
}
@media (min-width: 1024px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product:first-of-type {
    margin-top: unset;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-image {
  flex-shrink: 0;
  margin-right: 10px;
  padding-top: 2px;
  width: 100px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-image {
    min-height: 118px;
    padding-top: 8px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-image a {
  display: block;
  text-align: center;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-image img {
  max-width: 100%;
  height: auto;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info {
  color: #9a7b69;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 12px;
  max-width: calc(100% - 120px);
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info {
    color: #1a1919;
    font-size: 16px;
    letter-spacing: 1.07px;
    line-height: 24px;
    margin: 24px 0;
    text-transform: none;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-small-title {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-name {
  padding-right: 70px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-name {
    padding-right: 100px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-name a {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1.6px;
  line-height: 16px;
  color: #1a1919;
  text-decoration: none;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  max-width: 180px;
  padding-top: 2px;
  text-transform: none;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-name a {
    max-width: 250px;
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 24px;
    padding-top: 0;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-pricing {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  margin-top: 5px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-info .mini-cart-pricing .unit-base-price {
  display: none;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper {
  position: absolute;
  right: 0;
  text-align: right;
}
@media (max-width: 767px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper {
    top: 27px;
  }
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper {
    top: 56px;
  }
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper.no-ancient-ritual {
    top: 36px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper.is-multiline {
    top: 73px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .mini-cart-price {
  font-size: 12px;
  letter-spacing: 0.86px;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .mini-cart-price {
    font-size: 15px;
    letter-spacing: 1.88px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .mini-cart-b2b-taxes span {
  color: #626262;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  width: 13ch;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .price-after-discount {
  font-size: 12px;
  letter-spacing: 0.86px;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  display: block;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .price-after-discount {
    font-size: 15px;
    letter-spacing: 1.88px;
    line-height: 24px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .price-before-discount {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1.65px;
  color: #8c8c8c;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-decoration: line-through;
  display: block;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .product-discount-no-strikethrough {
  display: none;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-price-wrapper .unit-base-price {
  display: none;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .mini-cart-attributes {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper {
  left: 0;
  position: absolute;
  top: 16px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
  border-radius: 0 2px 2px 0;
  display: block;
  max-width: 140px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .promotion-wrapper .callout-message.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .refill-container {
  margin-bottom: 24px;
  margin-top: 10px;
  max-width: none;
  width: 100%;
}
@media (min-width: 1024px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .mini-cart-product .refill-container {
    border-radius: unset;
    margin-bottom: 0;
    margin-top: -8px;
    padding: 16px;
    gap: 16px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .centered {
  text-align: center;
  max-width: 380px;
  margin: 0 auto;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout {
  position: fixed;
  bottom: 0;
  padding-bottom: 30px;
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout.has-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0);
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout {
    max-width: 520px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total {
  padding-bottom: 27px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total .minicart-slot {
  font-size: 14px;
  letter-spacing: 1px;
  color: #453f3f;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line,
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout form {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line,
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout form {
    padding: 0 40px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line {
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line {
    padding: 0;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line .total-label {
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  display: flex;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  justify-content: space-between;
  letter-spacing: 0.4px;
  line-height: 1;
  padding: 15px 20px;
  text-transform: capitalize;
  width: 100%;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line .total-label {
    padding: 16px 40px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .total-line .total-value {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 1;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .free-delivery-message {
  font-size: 14px;
  max-width: 300px;
  display: block;
  margin-top: 20px;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #453f3f;
  text-transform: none;
  padding-left: 20px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .free-delivery-message {
    padding-left: 40px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .minicart-continueshopping {
  display: inline-block;
  width: 47%;
  height: auto;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .minicart-continueshopping .continue-shopping-btn {
  color: #1a1919;
  min-width: 0;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .minicart-shopmore {
  margin-top: 27px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .minicart-shopmore .link {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout form {
  align-items: stretch;
  padding-top: 30px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout form .btn--submit {
  width: 47%;
  min-width: 0;
  z-index: 1;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout.b2b-minicart .total-value {
  display: flex;
  gap: 8px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout.b2b-minicart .total-value .unit-base-price {
  margin-top: 0;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .spinner-custom-desktop {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 0;
  position: absolute;
  right: 40px;
  width: 40%;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .spinner-custom-desktop span {
  background-color: transparent;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .spinner-custom-desktop.show {
  background-color: #1a1919;
  display: flex;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .minicart-flyout-checkout .spinner-custom-desktop.show span {
  background-color: #ffffff;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .wrapper--minicart__list {
  width: 100%;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .wrapper--minicart__list .premium-gift {
  border-top: 0;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .gwp-welcome-banner-title {
  display: none;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .show-more-btn {
  margin-top: 20px;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .show-more-btn {
    padding: 0 40px;
  }
}
@media (min-width: 1024px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .show-more-btn {
    padding: 0 78px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .product-name {
  font-size: 15px;
  color: #000000;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  letter-spacing: 5px;
  line-height: 23px;
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .side--close {
  position: absolute;
  cursor: pointer;
  top: 34px;
  left: 27px;
  font-size: 22px;
}
@media (min-width: 768px) {
  .flyout-content.minicart-flyout-container .checkout-flyout-inner-content .side--close {
    left: 36px;
  }
}
.flyout-content.minicart-flyout-container .checkout-flyout-inner-content .side--close::before {
  color: #1a1919;
}
.flyout-content.minicart-flyout-container .rituals-form {
  position: relative;
}
.flyout-content .side--close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 20px;
  font-size: 25px;
}
.flyout-content .flyout-inner-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}
.flyout-content .inner-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flyout-content .flyout-header {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  text-align: center;
  padding: 20px;
}
@media (min-width: 768px) {
  .flyout-content .flyout-header {
    padding: 30px;
  }
}
.flyout-content .flyout-header span[class^=icon-] {
  display: inline-block;
  margin-bottom: 15px;
  font-size: 44px;
}
.flyout-content .flyout-header p {
  width: 60%;
  margin: 0 auto;
}
.flyout-content .flyout-header .flyout-header-body {
  max-width: 380px;
  padding-top: 40px;
  text-align: left;
}
.flyout-content .flyout-body {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
}
.flyout-content .error,
.flyout-content .size-not-selected-message {
  display: block;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .flyout-content .error,
  .flyout-content .size-not-selected-message {
    padding: 0 35px;
  }
}
@media (min-width: 1024px) {
  .flyout-content .error,
  .flyout-content .size-not-selected-message {
    padding: 0 68px;
  }
}
.flyout-content .size-not-selected-message {
  color: #626262;
}
.flyout-content .availability-disclaimer {
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding: 20px;
  color: #767676;
  font-size: 12px;
  line-height: normal;
}

.flyout-section-crm {
  background-color: #ffffff;
}
.minicart-flyout-container.flyout-content .flyout-section-crm .minicart-flyout-wrapper {
  padding: 40px 0;
}
.minicart-flyout-container.flyout-content .flyout-section-crm .product-gift-ribbon::after {
  opacity: 1;
}
.minicart-flyout-container.flyout-content .flyout-section-crm .mini-cart-price-wrapper {
  right: 20px !important;
}
@media (min-width: 768px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .mini-cart-price-wrapper {
    right: 40px !important;
  }
}
.minicart-flyout-container.flyout-content .flyout-section-crm .approaching-header,
.minicart-flyout-container.flyout-content .flyout-section-crm .mini-cart-product {
  padding: 16px 24px;
}
.minicart-flyout-container.flyout-content .flyout-section-crm .approaching-header {
  border-bottom: 1px solid #d8d8d8;
}
@media (min-width: 768px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .approaching-header {
    display: none;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .has-promotions .approaching-header {
    display: block;
  }
}
@media (max-width: 767px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .gwp-cart-banner {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .gwp-cart-banner {
    margin-left: 40px;
    margin-right: 40px;
  }
}
.minicart-flyout-container.flyout-content .flyout-section-crm .gwp-minicart-wrapper .approaching-promo.approaching-free-gift {
  margin: 0;
}
@media (max-width: 767px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .gwp-minicart-wrapper .approaching-promo.approaching-free-gift .promo-product-details {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .gwp-minicart-wrapper .approaching-promo.approaching-free-gift .promo-product-details {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.minicart-flyout-container.flyout-content .flyout-section-crm .gwp-minicart-wrapper .approaching-promo.approaching-free-gift:last-of-type {
  border-bottom: 1px solid #d8d8d8;
  margin-top: 0;
}
@media (max-width: 1023px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .wrapper--minicart__list > span {
    padding-left: 24px;
  }
}
@media (min-width: 1024px) {
  .minicart-flyout-container.flyout-content .flyout-section-crm .wrapper--minicart__list > span {
    padding-left: 40px;
  }
}

.flyout-progress-bar {
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  max-width: 560px;
  padding: 24px;
  position: relative;
}
@media (max-width: 767px) {
  .flyout-progress-bar {
    margin: 0 24px 40px;
  }
}
@media (min-width: 768px) {
  .flyout-progress-bar {
    align-items: center;
    margin: 0 40px;
  }
}
.flyout-progress-bar p {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .flyout-progress-bar p {
    margin-bottom: 24px;
  }
}
.gwp-shoppingcart-wrapper .flyout-progress-bar .flyout-scroll-progress {
  position: initial;
  width: 100%;
}
@media (max-width: 767px) {
  .gwp-shoppingcart-wrapper .flyout-progress-bar {
    padding-bottom: 84px;
  }
  .gwp-shoppingcart-wrapper .flyout-progress-bar .btn {
    bottom: 16px;
    left: 23px;
    position: absolute;
    width: calc(100% - 46px);
  }
}

.flyout-scroll-progress {
  appearance: none;
  border-radius: 2px;
  border: 0;
  height: 8px;
  position: absolute;
  width: 100%;
}
@media (max-width: 767px) {
  .flyout-scroll-progress {
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
  }
}
@media (min-width: 768px) {
  .flyout-scroll-progress {
    bottom: 24px;
    left: 24px;
    width: calc(100% - 148px);
  }
}
.flyout-scroll-progress::-webkit-progress-bar {
  background: #efedea;
  border-radius: 4px;
}
.flyout-scroll-progress::-webkit-progress-value {
  background-image: linear-gradient(to right, #9a7b69, #deca87);
  border-radius: 4px;
}
.flyout-scroll-progress::-moz-progress-bar {
  background-image: linear-gradient(to right, #9a7b69, #deca87);
  border-radius: 4px;
}

.flyout-progress-text {
  padding-right: 20px;
  text-align: left;
}
.flyout-progress-text .btn {
  margin-top: 9px;
}

.flyout-progress-img {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  width: 72px;
}
.flyout-progress-img img {
  max-width: 100%;
}
@media (min-width: 768px) {
  .flyout-progress-img {
    height: 100%;
  }
}

.generic-dialog-flyout {
  position: fixed;
  display: inline-block;
  top: 0;
  left: auto;
  bottom: 0;
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  z-index: 999;
  width: 100%;
  max-width: 95%;
  right: -100%;
  color: #000000;
  transition: right 0.5s ease-in-out;
}
.generic-dialog-flyout::before, .generic-dialog-flyout::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
}
.generic-dialog-flyout::before {
  top: 0;
  height: 60px;
  box-shadow: 0 8px 5px -2px #ffffff;
}
.generic-dialog-flyout::after {
  bottom: 0;
  height: 14px;
  box-shadow: 0 -8px 5px -2px #ffffff;
}
@media (min-width: 768px) {
  .generic-dialog-flyout {
    max-width: 520px;
    width: 80%;
    right: -80%;
  }
}
@media (min-width: 1024px) {
  .generic-dialog-flyout {
    max-width: 520px;
    width: 50%;
    right: -50%;
  }
}
.generic-dialog-flyout.open {
  right: 0;
}
.generic-dialog-flyout .side--close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 15px;
  font-size: 25px;
  z-index: 20;
  color: #000000;
}
@media (min-width: 768px) {
  .generic-dialog-flyout .side--close {
    top: 33px;
    left: 36px;
    font-size: 23px;
  }
}
.generic-dialog-flyout .generic-flyout-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 767px) {
  .generic-dialog-flyout .generic-flyout-content.flyin-component {
    padding: 40px 4px;
  }
  .generic-dialog-flyout .generic-flyout-content.flyin-component .section-header,
  .generic-dialog-flyout .generic-flyout-content.flyin-component .section-row {
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .generic-dialog-flyout .generic-flyout-content.flyin-component {
    padding: 40px 0;
  }
  .generic-dialog-flyout .generic-flyout-content.flyin-component .section-header,
  .generic-dialog-flyout .generic-flyout-content.flyin-component .section-row {
    padding: 0 20px 0 40px;
  }
}
.generic-dialog-flyout .generic-flyout-content .vue-form-row .vue-form-element.half-width {
  width: 100%;
  max-width: initial;
}
@media (max-width: 767px) {
  .generic-dialog-flyout .generic-flyout-header {
    padding-top: 25px;
  }
}
@media (min-width: 768px) {
  .generic-dialog-flyout .generic-flyout-header {
    padding-top: 35px;
  }
}
@media (max-width: 767px) {
  #flyinbonusProductChoice .generic-dialog-flyout .flyin-component {
    padding: 0;
  }
}
@media (min-width: 768px) {
  #flyinbonusProductChoice .generic-dialog-flyout .flyin-component {
    padding: 0;
  }
}

.inputfield-row.instore-address .pac-container {
  top: 68px !important;
  left: 0 !important;
}
@media (min-width: 768px) {
  .inputfield-row.instore-address .pac-container {
    top: 68px !important;
  }
}

.account-address-list .pac-container {
  top: 40px !important;
  left: 0 !important;
}

.shopping-cart-info-banner {
  padding-top: 40px;
  text-align: left;
  width: 100%;
}
.shopping-cart-info-banner .cart-info-banner-title {
  color: #1a1919;
  display: block;
  font-family: "ClassGarmnd BT Bold", "Times New Roman", serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-bottom: 16px;
  text-transform: none;
}

.cart-info-banner-inline {
  display: inline-flex;
  align-items: center;
  padding: 14px 20px;
  background-color: #ffffff;
}
.cart-info-banner-inline span {
  font-size: 28px;
  color: #9a7b69;
}
@media (max-width: 767px) {
  .cart-info-banner-inline span {
    min-width: 34px;
  }
}

.cart-info-banner-inline-text {
  margin-left: 20px;
}
.cart-info-banner-inline-text h3 {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.7px;
  line-height: 16px;
  color: #9a7b69;
}
.cart-info-banner-inline-text p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.free-gift-banner {
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  display: flex;
  padding: 15px 0;
  width: 100%;
}
@media (min-width: 768px) {
  .free-gift-banner {
    padding: 15px 7px;
  }
}
.free-gift-banner .free-gift-image {
  flex-shrink: 0;
  width: 75px;
}
@media (min-width: 768px) {
  .free-gift-banner .free-gift-image {
    width: 95px;
  }
}
.free-gift-banner img {
  display: block;
  max-width: 100%;
}
.free-gift-banner .free-gift-text {
  padding-left: 20px;
}
@media (min-width: 768px) {
  .free-gift-banner .free-gift-text {
    padding: 13px 0 0 35px;
  }
}
.free-gift-banner h3 {
  color: #886655;
  font-size: 10px;
  letter-spacing: 1.8px;
  line-height: 16px;
}
@media (min-width: 768px) {
  .free-gift-banner h3 {
    font-size: 12px;
  }
}
.free-gift-banner p {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.free-gift-banner p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .free-gift-banner p {
    letter-spacing: 0.3px;
  }
}

.payment-wait-flyin {
  height: calc(100vh - 80px);
  position: relative;
}
.payment-wait-flyin h3 {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  padding-left: 15px;
  padding-top: 55px;
  text-transform: capitalize;
}
@media (min-width: 768px) {
  .payment-wait-flyin h3 {
    padding-left: 40px;
  }
}
.payment-wait-flyin .payment-wait-text {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 20px 15px;
}
@media (min-width: 768px) {
  .payment-wait-flyin .payment-wait-text {
    padding: 20px 40px;
  }
}
.payment-wait-flyin .payment-wait-text span {
  display: block;
  margin-top: 50px;
}
.payment-wait-flyin .payment-wait-footer {
  bottom: 0;
  left: 0;
  padding: 0 15px;
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  .payment-wait-flyin .payment-wait-footer {
    padding: 0 40px;
  }
}
.payment-wait-flyin .payment-wait-footer .btn {
  max-width: none;
  width: 100%;
}

.refill-container.personalised-promotions-label {
  background-color: #f6f4f2;
  border-radius: unset;
  padding: 16px;
  gap: 16px;
}
@media (max-width: 767px) {
  .refill-container.personalised-promotions-label {
    margin: 8px 0 0 0;
    order: 99;
    position: relative;
  }
}
@media (min-width: 1024px) {
  .refill-container.personalised-promotions-label .refill-inner {
    margin-left: 0;
  }
}
.refill-container.personalised-promotions-label .refill-inner .for-every-refill {
  color: #1a1919;
}
@media (min-width: 1024px) {
  .refill-container.personalised-promotions-label .refill-inner .for-every-refill {
    margin: 0;
  }
}
.refill-container.personalised-promotions-label .personalised-promotions-label-icon {
  color: #a7884d;
  font-size: 24px;
  margin: auto 16px;
  position: relative;
  width: 24px;
}
@media (min-width: 1024px) {
  .refill-container.personalised-promotions-label .personalised-promotions-label-icon {
    margin: unset;
  }
}
.refill-container.personalised-promotions-label .personalised-promotions-label-icon::before {
  background-image: url("../images/icons/icon-members-rewards.svg");
  background-size: 24px 24px;
  content: "";
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}

.refill-container.personalised-promotions-label.member-only {
  background-color: #f6f4f2;
}
@media (max-width: 1023px) {
  .refill-container.personalised-promotions-label.member-only {
    gap: 6px;
  }
}
.refill-container.personalised-promotions-label.member-only .refill-inner {
  margin-left: 0;
  text-align: left;
}
.refill-container.personalised-promotions-label.member-only .refill-inner .for-every-refill, .refill-container.personalised-promotions-label.member-only .refill-inner a {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 24px;
}
.refill-container.personalised-promotions-label.member-only .refill-inner .for-every-refill span, .refill-container.personalised-promotions-label.member-only .refill-inner a span {
  font-weight: bold;
}
@media (max-width: 1023px) {
  .refill-container.personalised-promotions-label.member-only .personalised-promotions-label-icon {
    margin: 0 4px;
  }
}
.refill-container.personalised-promotions-label.member-only .personalised-promotions-label-icon::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  background-image: none;
  color: #1a1919;
  content: "\ea31";
  display: block;
  font-size: 24px;
  margin-right: 16px;
}
.refill-container.personalised-promotions-label.member-only.order {
  max-width: 100%;
  order: 2;
  width: 100%;
}

.toaster-container {
  background-color: #fcfaf7;
  display: none;
  flex-direction: column;
  justify-content: center;
  left: 0;
  max-width: 100%;
  min-height: 200px;
  opacity: 0;
  padding: 40px 30px;
  position: fixed;
  top: -600px;
  transition: top 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  width: 100%;
  z-index: 999;
}
.toaster-container.open {
  top: 0;
}
.toaster-container.page-loaded {
  opacity: 1;
}
.toaster-container .toaster-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .toaster-container .toaster-content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    flex-wrap: nowrap;
  }
}
.toaster-container .toaster-text {
  text-align: left;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .toaster-container .toaster-text p,
  .toaster-container .toaster-text a {
    font-size: 13px;
    letter-spacing: 1.18px;
    line-height: 18px;
  }
}
@media (min-width: 1024px) {
  .toaster-container .toaster-text {
    margin-bottom: 0;
  }
}
.toaster-container .toaster-buttons {
  display: flex;
  flex-direction: column-reverse;
  margin-left: auto;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .toaster-container .toaster-buttons {
    margin: 0 auto;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .toaster-container .toaster-buttons {
    flex-direction: row;
    padding-left: 30px;
    text-align: left;
  }
  .toaster-container .toaster-buttons .link.link--large {
    background-color: transparent;
    border: 1px solid #b4b4b4;
    color: #1a1919;
    display: inline-block;
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 2px;
    line-height: 1.2;
    min-width: 200px;
    padding: 15px 20px 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
  }
  .toaster-container .toaster-buttons .link.link--large:hover, .toaster-container .toaster-buttons .link.link--large:active {
    border: 1px solid #1a1919;
  }
}
.toaster-container .toaster-buttons .btn {
  min-width: 280px;
  margin: 0 auto 30px;
}
.toaster-container .toaster-buttons .btn:only-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .toaster-container .toaster-buttons .btn {
    min-width: 200px;
    margin: 0 0 0 20px;
  }
}

.generic-toaster-container {
  background-color: #ffffff;
  display: block;
  left: 0;
  max-width: 100%;
  min-height: 200px;
  opacity: 0;
  padding: 40px 30px;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: transform 1s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  width: 100%;
  z-index: 999;
}
.generic-toaster-container.open {
  transform: translateY(0);
}
.generic-toaster-container.page-loaded {
  opacity: 1;
}
.generic-toaster-container .toaster-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}
@media (min-width: 1024px) {
  .generic-toaster-container .toaster-content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    flex-wrap: nowrap;
  }
}
.generic-toaster-container .toaster-text {
  text-align: left;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .generic-toaster-container .toaster-text p,
  .generic-toaster-container .toaster-text a {
    font-size: 13px;
    letter-spacing: 1.18px;
    line-height: 18px;
  }
}
@media (min-width: 1024px) {
  .generic-toaster-container .toaster-text {
    margin-bottom: 0;
  }
}
.generic-toaster-container .toaster-buttons {
  display: flex;
  flex-direction: column-reverse;
  margin-left: auto;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .generic-toaster-container .toaster-buttons {
    margin: 0 auto;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .generic-toaster-container .toaster-buttons {
    flex-direction: row;
    padding-left: 30px;
    text-align: left;
  }
  .generic-toaster-container .toaster-buttons .link.link--large {
    background-color: transparent;
    border: 1px solid #b4b4b4;
    color: #1a1919;
    display: inline-block;
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 2px;
    line-height: 1.2;
    min-width: 200px;
    padding: 15px 20px 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
  }
  .generic-toaster-container .toaster-buttons .link.link--large:hover, .generic-toaster-container .toaster-buttons .link.link--large:active {
    border: 1px solid #1a1919;
  }
}
.generic-toaster-container .toaster-buttons .btn {
  min-width: 280px;
  margin: 0 auto 30px;
}
.generic-toaster-container .toaster-buttons .btn:only-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .generic-toaster-container .toaster-buttons .btn {
    min-width: 200px;
    margin: 0 0 0 20px;
  }
}

.mobile-crm-toast-wrapper.mobile-cart-confirmation.checkout-mobile-cart {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .mobile-crm-toast-wrapper.mobile-cart-confirmation.checkout-mobile-cart {
    padding: 26px 0 20px;
  }
}
.mobile-crm-toast-wrapper.mobile-cart-confirmation.checkout-mobile-cart .product-gift-ribbon::after {
  opacity: 1;
}
@media (max-width: 767px) {
  .mobile-crm-toast-wrapper.open form .btn.btn--submit,
  .mobile-crm-toast-wrapper.open form .spinner-custom-mobile {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .mobile-crm-toast {
    width: 100%;
  }
  .mobile-crm-toast .spinner-custom-mobile {
    align-items: center;
    background-color: #1a1919;
    display: flex;
    justify-content: center;
    left: 50%;
    margin: 0 auto;
    max-width: 342px;
    min-height: 58px;
    padding: 20px 32px;
    position: relative;
    transform: translateX(-50%);
  }
  .mobile-crm-toast .spinner-custom-mobile span {
    background-color: #ffffff;
  }
  .mobile-crm-toast .spinner-custom-mobile.show {
    background-color: #1a1919;
    display: flex;
  }
  .mobile-crm-toast .spinner-custom-mobile.show span {
    background-color: #ffffff;
  }
  .mobile-crm-toast .mobile-toaster-header {
    display: flex;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 24px 8px 24px;
    justify-content: space-between;
  }
  .mobile-crm-toast .mobile-toaster-header button {
    color: #1a1919;
    font-size: 24px;
    z-index: 994;
  }
  .mobile-crm-toast p {
    text-align: left;
  }
  .mobile-crm-toast form {
    margin-bottom: 74px;
    margin-top: 16px;
    position: relative;
    width: 100%;
  }
  .mobile-crm-toast form .btn {
    left: 50%;
    margin: 0 auto;
    max-width: 342px;
    min-height: 58px;
    padding: 20px 32px;
    position: relative;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 994;
  }
  .mobile-crm-toast .crm-toast-p {
    font-weight: bold;
    margin-bottom: 0;
  }
  .mobile-crm-toast .mini-cart-product {
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    position: relative;
  }
  .mobile-crm-toast .mini-cart-product .mini-cart-inner-container .mini-cart-price-wrapper {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  .mobile-crm-toast .mini-cart-product .mini-cart-inner-container .mini-cart-price-wrapper span {
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    display: block;
    font-size: 16px;
    line-height: 24px;
    width: 80px;
  }
  .mobile-crm-toast .mini-cart-product .mini-cart-inner-container .mini-cart-price-wrapper .mini-cart-b2b-taxes span {
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
  .mobile-crm-toast .mini-cart-product .mini-cart-inner-container .mini-cart-price-wrapper .price-before-discount {
    color: #7f7f7f;
    font-size: 14px;
    text-decoration: line-through;
  }
  .mobile-crm-toast .mini-cart-product .mini-cart-inner-container .mini-cart-price-wrapper .legal-note {
    margin-top: 0;
  }
  .mobile-crm-toast .mini-cart-product .mini-cart-inner-container .mini-cart-price-wrapper .legal-note span {
    color: #767676;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }
  .mobile-crm-toast .mini-cart-product .refill-container {
    background-color: #e2ead7;
    border-radius: 0;
    margin: 16px 0 0;
    max-width: none;
    padding: 16px 24px 16px 16px;
    width: 100%;
  }
  .mobile-crm-toast .mini-cart-product .refill-container.member-only {
    background-color: #f6f4f2;
    padding: 16px;
  }
  .mobile-crm-toast .mini-cart-product .refill-container .plant-a-tree::before {
    color: #1a1919;
  }
  .mobile-crm-toast .mini-cart-product .refill-container .refill-inner {
    display: flex;
    justify-content: center;
  }
  .mobile-crm-toast .mini-cart-product .refill-container .refill-inner .for-every-refill {
    color: #1a1919;
    margin: 0;
  }
  .mobile-crm-toast .mini-cart-image {
    max-width: 100px;
  }
  .mobile-crm-toast .mini-cart-image img {
    width: 64px;
  }
  .mobile-crm-toast .mini-cart-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    text-align: left;
    width: 100%;
  }
  .mobile-crm-toast .mini-cart-info .unit-base-price-content .legal-note {
    display: none;
  }
  .mobile-crm-toast .mini-cart-small-title {
    color: #9a7b69;
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
  }
  .mobile-crm-toast .mini-cart-name {
    max-width: 100%;
    z-index: 994;
  }
  .mobile-crm-toast .mini-cart-name a {
    color: #1a1919;
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-decoration: none;
  }
  .mobile-crm-toast .mini-cart-details-bottom .mini-cart-pricing {
    display: none;
  }
  .mobile-crm-toast .mini-cart-details-bottom .mini-cart-b2b-taxes span {
    color: #626262;
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    width: 13ch;
  }
  .mobile-crm-toast .mini-cart-details-bottom .mini-cart-b2b-taxes + .unit-base-price-content {
    display: none;
  }
  .mobile-crm-toast .flyout-progress-bar {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .mobile-crm-toast .promotion-wrapper {
    left: 0;
    position: absolute;
    top: 16px;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message {
    background-color: #ffffff;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 16px;
    margin: 0;
    padding: 8px 16px;
    text-transform: none;
    width: auto;
    border-radius: 0 2px 2px 0;
    display: block;
    max-width: 120px;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message.callout-red {
    background-color: #ad4141;
    color: #ffffff;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message.callout-grey {
    background-color: #626262;
    color: #ffffff;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message.callout-green {
    background-color: #649b26;
    color: #ffffff;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message.callout-green-light {
    background-color: #e2ead7;
    color: #1a1919;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message.callout-gold {
    background-color: #9a7b69;
    color: #ffffff;
  }
  .mobile-crm-toast .promotion-wrapper .callout-message.callout-white {
    background-color: #ffffff;
    color: #1a1919;
  }
}
.mobile-crm-toast .gwp-minicart-wrapper .approaching-header {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
}
.mobile-crm-toast .gwp-minicart-wrapper .approaching-header + .product-gift-ribbon {
  border-top: 1px solid #d8d8d8;
}
@media (max-width: 767px) {
  .mobile-crm-toast .gwp-minicart-wrapper .approaching-header + .product-gift-ribbon {
    margin-top: 10px !important;
  }
}
.mobile-crm-toast .gwp-minicart-wrapper .approaching-promo,
.mobile-crm-toast .gwp-minicart-wrapper .approaching-promo.approaching-free-gift {
  border-bottom: 1px solid #d8d8d8 !important;
  margin: 0 !important;
  padding: 0 20px;
}
.mobile-crm-toast .gwp-minicart-wrapper .approaching-promo .gift-label,
.mobile-crm-toast .gwp-minicart-wrapper .approaching-promo.approaching-free-gift .gift-label {
  font-size: 16px;
}
.mobile-crm-toast .gwp-minicart-wrapper .approaching-promo .gift-price,
.mobile-crm-toast .gwp-minicart-wrapper .approaching-promo.approaching-free-gift .gift-price {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  margin-top: 6px;
}
.mobile-crm-toast .gwp-minicart-wrapper .promo-product-details {
  padding-top: 0;
}
.mobile-crm-toast .gwp-minicart-wrapper .premium-gift::after {
  opacity: 1;
}
.mobile-crm-toast .cart-banner--mini-cart {
  margin-top: 16px;
}

.ui-tooltip {
  background-color: #ffffff;
  color: #000000;
  outline: 1px solid #d8d8d8;
  border: 0;
  font-size: 14px;
  line-height: normal;
  padding: 5px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.ui-tooltip::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg) translateX(-50%);
  left: 50%;
  bottom: -7px;
  background-color: #ffffff;
  transform-origin: center;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.tooltip-content {
  display: none;
}

.out-of-stock-block {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.flyout-out-of-stock {
  max-width: 327px;
}
@media (min-width: 768px) {
  .flyout-out-of-stock {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .flyout-out-of-stock {
    max-width: 720px;
  }
}
.flyout-out-of-stock .flyout-out-of-stock-content {
  padding: 97px 24px 60px;
  position: relative;
}
@media (min-width: 768px) {
  .flyout-out-of-stock .flyout-out-of-stock-content {
    padding: 73px 40px 40px;
    text-align: center;
  }
}
.flyout-out-of-stock > .side--close {
  display: none;
}
.flyout-out-of-stock .side--close {
  top: 15px;
}
@media (min-width: 768px) {
  .flyout-out-of-stock .side--close {
    top: 15px;
  }
}
.flyout-out-of-stock .out-of-stock-title {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.3px;
  text-transform: none;
  margin-bottom: 17px;
}
@media (min-width: 768px) {
  .flyout-out-of-stock .out-of-stock-title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 2px;
    margin-bottom: 22px;
  }
}
.flyout-out-of-stock .message,
.flyout-out-of-stock .subscribed-email {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.flyout-out-of-stock .image-container {
  margin-top: 55px;
}
.flyout-out-of-stock .image-container img {
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.flyout-out-of-stock .out-of-stock-form-container {
  margin-top: 26px;
}
@media (min-width: 768px) {
  .flyout-out-of-stock .out-of-stock-form-container {
    margin-top: 44px;
  }
}
.flyout-out-of-stock .out-of-stock-form-container .email-input {
  margin-top: 8px;
  order: 3;
}
.flyout-out-of-stock .out-of-stock-form-container .email-submit-btn {
  margin: 43px auto 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  width: 100%;
}
.flyout-out-of-stock .out-of-stock-form-container .email-submit-btn.button-loading {
  font-size: 0;
  min-height: 54px;
}
.flyout-out-of-stock .out-of-stock-form-container .email-submit-btn .spinner {
  margin-top: -20px;
}
@media (min-width: 768px) {
  .flyout-out-of-stock .out-of-stock-form-container .email-submit-btn {
    min-width: 320px;
    width: auto;
  }
}
.flyout-out-of-stock .out-of-stock-form-container label.error {
  order: 2;
  color: #d6122d;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 1px;
  margin: 3px 0 -2px;
}
.flyout-out-of-stock .out-of-stock-form-container .thank-you-msg {
  pointer-events: none;
}

@media (min-width: 768px) {
  .flyout-out-of-stock.advent-out-of-stock {
    width: 330px;
  }
}
@media (min-width: 1024px) {
  .flyout-out-of-stock.advent-out-of-stock {
    width: 450px;
  }
}
.flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content {
  padding-top: 70px;
}
.flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content h3 {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
}
@media (min-width: 1024px) {
  .flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content h3 {
    margin-bottom: 10px;
  }
}
.flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content .image-container {
  margin-top: 20px;
}
@media (min-width: 1024px) {
  .flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content .out-of-stock-form-container {
    margin-top: 20px;
  }
}
.flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content .out-of-stock-form-container .email-submit-btn {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content .out-of-stock-form-container .email-submit-btn {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .flyout-out-of-stock.advent-out-of-stock .flyout-out-of-stock-content .out-of-stock-form-container .email-submit-btn {
    width: 220px;
    min-width: 220px;
  }
}

.product-hero-container {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.product-hero-container .product-hero-content.product-hero-box-list {
  justify-content: center;
}
@media (min-width: 768px) {
  .product-hero-container {
    min-height: 660px;
    max-height: 760px;
  }
}

.product-hero-media {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  width: 100%;
}
@media (min-width: 768px) {
  .product-hero-media {
    height: 100%;
  }
}
.in-viewport .product-hero-media {
  transform: scale(1);
}
.not-in-viewport .product-hero-media {
  transform: scale(1.2);
}

.product-hero-content {
  margin: 332px 0 30px;
  padding: 0 20px;
  position: relative;
  width: 100%;
}
.product-hero-content .product-hero-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #ffffff;
  min-height: 370px;
  padding: 40px 20px 44px;
  text-align: center;
}
.product-hero-content .product-hero-box .ritual-icon {
  color: #9a7b69;
  font-size: 25px;
  line-height: 1;
  margin-bottom: 20px;
}
.product-hero-content .product-hero-box .giftset-valueprice {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: #1a1919;
}
.product-hero-content .product-hero-box h3 {
  font-size: 22px;
  letter-spacing: 1.2px;
  line-height: 30px;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.product-hero-content .product-hero-box p {
  line-height: 28px;
  margin-bottom: 18px;
}
.product-hero-content .product-hero-box p:last-child {
  margin-bottom: 0;
}
.product-hero-content.product-hero-box-list .product-hero-box {
  width: 100%;
}
.product-hero-content.product-hero-box-list .product-hero-box .product-hero-box-title {
  margin-bottom: 50px;
}
.product-hero-content.product-hero-box-list .heading-h9 {
  margin-bottom: 25px;
  color: #896754;
}
.product-hero-content .product-hero-list {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  list-style: none;
  color: #1a1919;
}
.product-hero-content .product-hero-list li {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.product-hero-content .product-hero-list li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  width: 70px;
  background-color: #d8d8d8;
}
.product-hero-content .product-hero-list li.no-divider {
  margin: 0;
  padding: 0;
}
.product-hero-content .product-hero-list li.no-divider::after {
  content: none;
  background-color: transparent;
}
.product-hero-content .product-hero-list ul {
  list-style: none;
}
.product-hero-content .product-hero-list span {
  display: block;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .product-hero-content .product-hero-box {
    height: 460px;
  }
  .product-hero-content .product-hero-box * {
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .product-hero-content {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }
  .product-hero-content .product-hero-box {
    min-height: 460px;
    margin-right: 10%;
    max-width: 100%;
    padding: 80px 60px;
    transition: padding 0.3s ease;
    width: 480px;
  }
  .product-hero-content .product-hero-box h3 {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin-bottom: 20px;
    text-transform: none;
  }
}

@media (max-width: 767px) {
  .product-hero-container-table {
    flex-direction: column-reverse;
  }
}

.product-hero-box-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: #474d40;
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.product-hero-box-table .product-hero-box {
  max-width: 400px;
}
.product-hero-box-table h3 {
  margin-bottom: 20px;
  text-transform: initial;
}
.product-hero-box-table p {
  color: #ffffff;
}
@media (max-width: 767px) {
  .product-hero-box-table {
    width: 88%;
    margin: -40px auto 0;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .product-hero-box-table {
    width: 50%;
  }
}

.product-hero-table {
  display: flex;
  justify-content: space-around;
  margin-top: 48px;
  list-style: none;
}

.product-hero-table-col {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.product-hero-table-col::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #aeaeae;
}
.product-hero-table-col:last-child::after {
  display: none;
}
.product-hero-table-col .ico {
  font-size: 20px;
}
.product-hero-table-col .product-hero-table-type {
  margin-bottom: 10px;
  font-size: 15px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.product-hero-table-col .product-hero-table-perc {
  font-size: 24px;
}
@media (max-width: 767px) {
  .product-hero-table-col .product-hero-table-type {
    font-size: 10px;
    letter-spacing: 1.6px;
  }
  .product-hero-table-col .product-hero-table-perc {
    font-size: 18px;
  }
}

.product-disclaimer {
  max-width: 480px;
  margin: 30px auto;
  padding: 0 20px;
}
.product-disclaimer p {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
}

@media (min-width: 768px) {
  .content-right-aligned {
    flex-direction: row !important;
  }
}

@media (min-width: 768px) {
  .content-left-aligned {
    flex-direction: row-reverse !important;
  }
}

.product-hero-container-customisable {
  flex-direction: column;
}
@media (min-width: 768px) {
  .product-hero-container-customisable {
    min-height: 405px;
    max-height: none;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-customisable {
    min-height: 760px;
  }
}
.product-hero-container-customisable .product-hero-media {
  position: relative;
  width: 100%;
  height: 395px;
}
@media (min-width: 768px) {
  .product-hero-container-customisable .product-hero-media {
    width: 50%;
    min-height: 405px;
    height: auto;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-customisable .product-hero-media {
    min-height: 760px;
  }
}
.product-hero-container-customisable .product-hero-content {
  position: relative;
  transform: translateY(0);
  padding: 0;
  justify-content: flex-start;
  background-color: #ffffff;
  margin: 0;
  width: 100%;
  display: flex;
}
@media (min-width: 768px) {
  .product-hero-container-customisable .product-hero-content {
    width: 50%;
  }
}
.product-hero-container-customisable .product-hero-content .product-hero-box {
  width: auto;
  max-width: 720px;
  margin-right: 0;
  min-height: 0;
  padding: 60px 25px 59px;
}
@media (min-width: 768px) {
  .product-hero-container-customisable .product-hero-content .product-hero-box {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-customisable .product-hero-content .product-hero-box {
    padding: 80px 135px;
  }
}
.product-hero-container-customisable .product-hero-content .product-hero-box h3 {
  text-transform: none;
  letter-spacing: 1.2px;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .product-hero-container-customisable .product-hero-content .product-hero-box h3 {
    letter-spacing: 0.46px;
    line-height: 44px;
    margin-bottom: 12px;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-customisable .product-hero-content .product-hero-box h3 {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin-bottom: 22px;
  }
}
.product-hero-container-customisable .product-hero-content .product-hero-box p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .product-hero-container-customisable .product-hero-content .product-hero-box p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-customisable .product-hero-content .product-hero-box p {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
  }
}
.product-hero-container-customisable.how-to-use-block-amsterdam .product-hero-content {
  margin: 0;
}
@media (max-width: 767px) {
  .product-hero-container-customisable.how-to-use-block-reversed .product-hero-media {
    order: -1;
  }
}

.product-hero-container-giftset {
  flex-direction: column;
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .product-hero-container-giftset {
    min-height: 524px;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .product-hero-container-giftset {
    min-height: 722px;
    padding-bottom: 30px;
    max-height: none;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-giftset {
    min-height: 760px;
  }
}
.product-hero-container-giftset .product-hero-media {
  position: relative;
  max-width: 900px;
  min-height: 280px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .product-hero-container-giftset .product-hero-media {
    height: 300px;
  }
}
@media (min-width: 768px) {
  .product-hero-container-giftset .product-hero-media {
    min-height: 405px;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-giftset .product-hero-media {
    min-height: 522px;
  }
}
.product-hero-container-giftset .product-hero-media-zoomed {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-hero-container-giftset .image-zoomed {
  display: block;
}
.product-hero-container-giftset .product-hero-media-close {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000000;
  top: 10px;
  right: 10px;
}
.product-hero-container-giftset .product-hero-media-close span {
  color: #ffffff;
  padding: 6px;
  font-size: 19px;
}
.product-hero-container-giftset .product-hero-media-close:hover {
  background-color: #ffffff;
}
.product-hero-container-giftset .product-hero-media-close:hover span {
  color: #000000;
}
@media (max-width: 767px) {
  .product-hero-container-giftset .product-hero-media-close {
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
  }
  .product-hero-container-giftset .product-hero-media-close span {
    font-size: 16px;
    padding-bottom: 7px;
  }
}
.product-hero-container-giftset .product-hero-media-zoom {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.product-hero-container-giftset .product-hero-media-text {
  position: absolute;
  right: 20%;
  top: 11%;
  color: #876957;
  max-width: 80px;
  text-align: center;
}
.product-hero-container-giftset .product-hero-media-text p {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1.08px;
}
@media (max-width: 320px) {
  .product-hero-container-giftset .product-hero-media-text {
    max-width: 60px;
  }
}
@media (max-width: 767px) {
  .product-hero-container-giftset .product-hero-media-text {
    right: 0;
    top: 2%;
  }
}
.product-hero-container-giftset .zoom {
  background: url("../images/zoom.svg") center top no-repeat;
  background-size: 49px 49px;
  height: 49px;
  width: 49px;
}
.product-hero-container-giftset .product-hero-content {
  position: relative;
  transform: translateY(0);
  padding: 0;
  justify-content: center;
  background-color: #fcfaf7;
  margin: 0;
  width: 100%;
  display: flex;
}
.product-hero-container-giftset .product-hero-content .product-hero-box {
  width: auto;
  background-color: #fcfaf7;
  max-width: 720px;
  margin-right: 0;
  min-height: 0;
  padding: 66.5px 160px;
}
@media (max-width: 767px) {
  .product-hero-container-giftset .product-hero-content .product-hero-box {
    padding: 60px 0 20px;
  }
}
.product-hero-container-giftset .product-hero-content .product-hero-box h4 {
  font-size: 14px;
  letter-spacing: 3px;
  line-height: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #876957;
}
@media (max-width: 767px) {
  .product-hero-container-giftset .product-hero-content .product-hero-box h4 {
    font-size: 12px;
    letter-spacing: 1.7px;
    margin-bottom: 25px;
  }
}
.product-hero-container-giftset .product-hero-content .product-hero-box h3 {
  text-transform: none;
  letter-spacing: 1.2px;
  color: #1a1919;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .product-hero-container-giftset .product-hero-content .product-hero-box h3 {
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .product-hero-container-giftset .product-hero-content .product-hero-box h3 {
    letter-spacing: 0.46px;
    line-height: 44px;
    margin-bottom: 12px;
  }
}
@media (min-width: 1024px) {
  .product-hero-container-giftset .product-hero-content .product-hero-box h3 {
    font-size: 32px;
    letter-spacing: 1.4px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.product-hero-container-giftset .product-hero-content .product-hero-box p {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1a1919;
}

.how-to-use-block-amsterdam .product-hero-content {
  margin: 218px 0 100px;
}
@media (min-width: 768px) {
  .how-to-use-block-amsterdam .product-hero-content {
    margin: 0;
  }
}
.how-to-use-block-amsterdam .product-hero-box {
  padding: 43px 30px 30px;
}
@media (min-width: 768px) {
  .how-to-use-block-amsterdam .product-hero-box {
    padding: 20px 30px;
    height: 480px;
  }
}
@media (min-width: 1024px) {
  .how-to-use-block-amsterdam .product-hero-box {
    padding: 20px 50px;
  }
}
.how-to-use-block-amsterdam .product-hero-box h3 {
  text-transform: none;
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .how-to-use-block-amsterdam .product-hero-box h3 {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
  }
}
.how-to-use-block-amsterdam .product-hero-box p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}

@media (max-width: 767px) {
  .how-to-use-luxury {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .how-to-use-luxury {
    min-height: 700px;
  }
}
@media (max-width: 767px) {
  .how-to-use-luxury .product-hero-media {
    height: 288px;
    left: auto;
    order: 2;
    position: relative;
    top: auto;
  }
}
@media (min-width: 768px) {
  .how-to-use-luxury .product-hero-media {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .how-to-use-luxury .product-hero-content {
    margin: 0;
    order: 1;
  }
}
@media (min-width: 768px) {
  .how-to-use-luxury .product-hero-content {
    left: auto;
    justify-content: flex-start;
    margin-left: 50%;
    padding: 0;
    position: relative;
    top: auto;
    transform: none;
    width: 50%;
  }
}
.how-to-use-luxury .product-hero-content .product-hero-box {
  background-color: transparent;
  min-height: 256px;
  padding: 65px 5px 37px;
}
@media (min-width: 768px) {
  .how-to-use-luxury .product-hero-content .product-hero-box {
    margin-right: 0;
    max-width: 720px;
    min-height: 0;
    padding: 60px;
    width: 100%;
  }
}
.how-to-use-luxury .product-hero-content .product-hero-box h3 {
  text-transform: none;
}
@media (max-width: 767px) {
  .how-to-use-luxury .product-hero-content .product-hero-box h3 {
    font-size: 24px;
    letter-spacing: 1.5px;
    line-height: 32px;
    margin-bottom: 18px;
  }
}
@media (min-width: 768px) {
  .how-to-use-luxury .product-hero-content .product-hero-box h3 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 2px;
    margin-bottom: 18px;
    max-width: 500px;
  }
}
.how-to-use-luxury .product-hero-content .product-hero-box p {
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .how-to-use-luxury .product-hero-content .product-hero-box p {
    font-size: 14px;
    max-width: 500px;
  }
}
@media (max-width: 767px) {
  .how-to-use-luxury.how-to-use-easter .product-hero-media {
    order: 1;
  }
}
@media (min-width: 768px) {
  .how-to-use-luxury.how-to-use-easter .product-hero-box h3 {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.2px;
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .how-to-use-luxury.how-to-use-easter .product-hero-box p {
    font-size: 16px;
    line-height: 28px;
  }
}

.product-tile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.product-tile .product-details-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-tile .product-details-content .short-description {
  color: #1a1919;
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0.31px;
  line-height: 14px;
  text-transform: none;
  margin: 0;
}
@media (min-width: 768px) {
  .product-tile .product-details-content .short-description {
    font-size: 14px;
    letter-spacing: 0.36px;
    line-height: 17px;
  }
}
.product-tile .product-details-content .out-of-stock-block {
  margin-top: 0;
}
.product-tile .promotional-label {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .product-tile .promotional-label {
    margin-bottom: 10px;
  }
}
.product-tile .product-image {
  width: 100%;
  margin: 0;
}
.product-tile .product-image img {
  margin-left: auto;
  margin-right: auto;
}
.product-tile .product-image .thumb-link img {
  width: 100%;
}
@media (max-width: 767px) {
  .product-tile .product-image .thumb-link img {
    max-height: 265px;
  }
}
.product-tile .stock-indicator-wrapper {
  background-color: #d8d8d8;
  border-radius: 24px;
  min-height: 24px;
  min-width: 84px;
  margin: 10px auto;
  padding: 0 12px;
  text-align: center;
}
@media (min-width: 768px) {
  .product-tile .stock-indicator-wrapper {
    border-radius: 32px;
    min-height: 32px;
    min-width: 105px;
    margin: 12px auto;
  }
}
.product-tile .stock-indicator-wrapper .stock-indicator-label {
  font-size: 8px;
  line-height: 24px;
  letter-spacing: 1.2px;
}
@media (min-width: 768px) {
  .product-tile .stock-indicator-wrapper .stock-indicator-label {
    font-size: 10px;
    line-height: 32px;
    letter-spacing: 1.5px;
  }
}
.product-tile .base-price-block {
  display: none;
}
.product-tile .product-price {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.product-tile .product-price .product-beforediscount-price {
  color: #8c8c8c;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: normal;
  text-decoration: line-through;
}
@media (min-width: 768px) {
  .product-tile .product-price .product-beforediscount-price {
    line-height: 24px;
  }
}
.product-tile .product-price .product-discount-no-strikethrough {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: none;
}
.product-tile .product-price .base-price-block {
  display: block;
}
.product-tile .availability-web {
  flex-shrink: 1;
  width: 100%;
}
.product-tile.promotion-product-tile {
  position: relative;
}
.product-tile.promotion-product-tile .thumb-link,
.product-tile.promotion-product-tile .product-name-link {
  pointer-events: none;
}
.product-tile.promotion-product-tile .product-name-link .product-name {
  font-size: 24px;
}
@media (max-width: 767px) {
  .product-tile.promotion-product-tile .product-name-link .product-name {
    font-size: 18px;
  }
}
.product-tile.promotion-product-tile.out-of-stock::after {
  left: 10px;
  width: calc(100% - 20px);
}
@media (max-width: 1023px) {
  .product-tile.promotion-product-tile.out-of-stock::after {
    left: 5px;
    width: calc(100% - 10px);
  }
}

.shopping-cart-block .product-tile.promotion-product-tile .product-name-link .product-name {
  font-size: 18px;
}
.shopping-cart-block .product-tile.promotion-product-tile.out-of-stock::after {
  left: 4px;
  width: calc(100% - 8px);
}
.shopping-cart-block .product-list-slider-block.personalised-promotions .product-list-slider .product-price .product-sales-price {
  font-size: 16px;
}

.easter-eggs {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .easter-eggs {
    padding: 100px 0;
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs {
    padding: 138px 0;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .easter-eggs {
    padding: 180px 0;
  }
}
@media (min-width: 1160px) {
  .easter-eggs {
    padding: 180px 0;
    max-width: 1440px;
  }
}
.easter-eggs .easter-egg-tile {
  background-color: #f6f4f2;
  position: relative;
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile {
    width: 308px;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .easter-eggs .easter-egg-tile {
    width: 396px;
  }
}
@media (min-width: 1160px) {
  .easter-eggs .easter-egg-tile {
    width: 440px;
  }
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile:nth-child(even) {
    margin-top: 150px;
  }
}
@media (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure {
    height: 350px;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .easter-eggs .easter-egg-tile figure {
    height: 428px;
  }
}
@media (min-width: 1160px) {
  .easter-eggs .easter-egg-tile figure {
    height: 475px;
  }
}
.easter-eggs .easter-egg-tile figure .egg-top,
.easter-eggs .easter-egg-tile figure .egg-bottom {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.easter-eggs .easter-egg-tile figure .egg-top {
  top: 30px;
  z-index: 3;
}
@media (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .egg-top {
    width: 222px;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .easter-eggs .easter-egg-tile figure .egg-top {
    width: 288px;
  }
}
@media (min-width: 1160px) {
  .easter-eggs .easter-egg-tile figure .egg-top {
    width: 320px;
  }
}
.easter-eggs .easter-egg-tile figure .egg-bottom {
  top: 72px;
  z-index: 2;
}
@media (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .egg-bottom {
    width: 204px;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .easter-eggs .easter-egg-tile figure .egg-bottom {
    width: 262px;
  }
}
@media (min-width: 1160px) {
  .easter-eggs .easter-egg-tile figure .egg-bottom {
    width: 290px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon1,
.easter-eggs .easter-egg-tile figure .ribbon2,
.easter-eggs .easter-egg-tile figure .ribbon3,
.easter-eggs .easter-egg-tile figure .ribbon4,
.easter-eggs .easter-egg-tile figure .ribbon5,
.easter-eggs .easter-egg-tile figure .ribbon6,
.easter-eggs .easter-egg-tile figure .ribbon7,
.easter-eggs .easter-egg-tile figure .ribbon8 {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  position: absolute;
  z-index: 1;
}
.easter-eggs .easter-egg-tile figure .ribbon1 {
  background-image: url("../images/ribbons/ribbon1.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon1 {
    height: 254px;
    left: -20px;
    top: -46px;
    width: 76px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon1 {
    height: 266px;
    left: -50px;
    top: -30px;
    width: 80px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon1 {
    height: 380px;
    left: -70px;
    top: -30px;
    width: 114px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon2 {
  background-image: url("../images/ribbons/ribbon2.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon2 {
    height: 254px;
    right: -20px;
    top: -46px;
    width: 54px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon2 {
    height: 268px;
    right: -26px;
    top: -98px;
    width: 56px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon2 {
    height: 382px;
    right: -36px;
    top: -98px;
    width: 80px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon3 {
  background-image: url("../images/ribbons/ribbon3.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon3 {
    bottom: 52px;
    height: 118px;
    left: -20px;
    width: 52px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon3 {
    bottom: 54px;
    height: 124px;
    left: -28px;
    width: 54px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon3 {
    bottom: 54px;
    height: 176px;
    left: -40px;
    width: 76px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon4 {
  background-image: url("../images/ribbons/ribbon4.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon4 {
    bottom: 52px;
    height: 102px;
    right: -20px;
    width: 50px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon4 {
    bottom: 56px;
    height: 108px;
    right: -26px;
    width: 52px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon4 {
    bottom: 56px;
    height: 152px;
    right: -38px;
    width: 74px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon5 {
  background-image: url("../images/ribbons/ribbon5.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon5 {
    height: 240px;
    left: -20px;
    top: 26px;
    width: 62px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon5 {
    height: 252px;
    left: -38px;
    top: -8px;
    width: 66px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon5 {
    height: 358px;
    left: -56px;
    top: -8px;
    width: 92px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon6 {
  background-image: url("../images/ribbons/ribbon6.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon6 {
    height: 264px;
    right: -20px;
    top: -46px;
    width: 66px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon6 {
    height: 382px;
    right: -36px;
    top: -102px;
    width: 70px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon6 {
    height: 544px;
    right: -50px;
    top: -102px;
    width: 100px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon7 {
  background-image: url("../images/ribbons/ribbon7.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon7 {
    bottom: 44px;
    height: 96px;
    left: -20px;
    width: 54px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile figure .ribbon7 {
    bottom: 56px;
    height: 100px;
    left: -28px;
    width: 56px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon7 {
    bottom: 56px;
    height: 142px;
    left: -40px;
    width: 80px;
  }
}
.easter-eggs .easter-egg-tile figure .ribbon8 {
  background-image: url("../images/ribbons/ribbon8.svg");
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile figure .ribbon8 {
    bottom: 54px;
    height: 128px;
    right: -20px;
    width: 52px;
  }
}
@media (min-width: 768px) {
  .easter-eggs .easter-egg-tile figure .ribbon8 {
    bottom: 56px;
    height: 90px;
    right: -36px;
    width: 54px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile figure .ribbon8 {
    bottom: 56px;
    height: 128px;
    right: -50px;
    width: 76px;
  }
}
.easter-eggs .easter-egg-tile footer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 767px) {
  .easter-eggs .easter-egg-tile footer {
    padding: 0 30px 55px 30px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-eggs .easter-egg-tile footer {
    padding: 0 20px 55px 20px;
  }
}
@media (min-width: 1024px) {
  .easter-eggs .easter-egg-tile footer {
    padding: 0 30px 55px 30px;
  }
}
.easter-eggs .easter-egg-tile footer .btn {
  margin: 0 auto;
}
.easter-eggs .easter-egg-tile footer .the-ritual-of-title {
  color: #886655;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
}
.easter-eggs .easter-egg-tile footer .product-title {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 1.2px;
  line-height: 40px;
  margin: 10px 0 20px 0;
}

.product-swatches-list {
  font-size: 0;
  padding-top: 4px;
  width: 100%;
}
.product-swatches-list .product-swatches-item {
  display: inline-block;
  height: 20px;
  vertical-align: top;
  width: 20px;
  margin-bottom: 18px;
}
.product-swatches-list .product-swatches-button {
  height: 22px;
  overflow: hidden;
  width: 22px;
}
.product-swatches-list .product-swatch-wrapper {
  width: 100%;
  max-width: 22px;
  height: 22px;
  border: 1px solid transparent;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}
.product-swatches-list .product-swatches-image {
  width: 100%;
}
.product-swatches-list .product-swatches-all-items {
  font-size: 14px;
  display: inline-block;
  height: 20px;
  line-height: 24px;
  margin-left: 2px;
  vertical-align: top;
}
@media (max-width: 767px) {
  .product-swatches-list .product-swatches-item:not(:first-child) {
    margin-left: -8px;
  }
  .product-swatches-list .product-swatches-button {
    pointer-events: none;
  }
}
@media (min-width: 768px) {
  .product-swatches-list {
    margin: -2px 0 0;
    padding: 0;
  }
  .product-swatches-list .product-swatches-item {
    height: 40px;
    margin: 0 0 12px;
    width: 40px;
  }
  .product-swatches-list .product-swatches-button {
    height: 40px;
    width: 40px;
  }
  .product-swatches-list .product-swatch-wrapper {
    max-width: 32px;
    height: 32px;
  }
  .product-swatches-list .selected .product-swatch-wrapper {
    border-color: #886655;
    padding: 2px;
  }
  .product-swatches-list .product-swatches-all-items {
    height: 32px;
    line-height: 36px;
    margin-left: 5px;
  }
}

.pt_product-details.animate-scroll .product-details-container {
  position: relative;
}
.pt_product-details.animate-scroll .product-details-container .opaque-overlay {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: background 2s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 100%;
  z-index: 1;
}
.pt_product-details.animate-scroll .product-details-container .opaque-overlay.cover-main-block-only {
  position: absolute;
}
.pt_product-details.animate-scroll .product-details-container.opaque .opaque-overlay {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}
.pt_product-details.animate-scroll .product-details-container .column-images {
  transition: filter 1.3s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.pt_product-details.animate-scroll .product-details-container .column-images.show-image-only .promotional-label,
.pt_product-details.animate-scroll .product-details-container .column-images.show-image-only .slider-bar,
.pt_product-details.animate-scroll .product-details-container .column-images.show-image-only .slick-arrow {
  visibility: hidden;
}
.pt_product-details.animate-scroll .product-details-container .column-images.blurred {
  filter: blur(10px);
}
.pt_product-details.animate-scroll .product-details-container .column-details {
  z-index: 1;
}
.pt_product-details.animate-scroll .product-details-container .product-ingredients-images {
  height: calc(100% + 20px);
  margin: 0 -20px;
  overflow: hidden;
  position: absolute;
  top: -20px;
  width: calc(100% + 20px);
}
@media (min-width: 768px) {
  .pt_product-details.animate-scroll .product-details-container .product-ingredients-images {
    height: 100%;
    margin: 0;
    overflow: visible;
    top: -10%;
    width: 100%;
  }
}
.pt_product-details.animate-scroll .product-details-container .product-ingredients-images img {
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99), left 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99), transform 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  width: 100%;
}
.pt_product-details.animate-scroll .product-details-container .product-ingredients-images img:first-child, .pt_product-details.animate-scroll .product-details-container .product-ingredients-images img:last-child {
  left: 0;
  max-width: 370px;
}
@media (min-width: 768px) {
  .pt_product-details.animate-scroll .product-details-container .product-ingredients-images img:first-child, .pt_product-details.animate-scroll .product-details-container .product-ingredients-images img:last-child {
    max-width: 700px;
    max-height: 700px;
    transform: scale(0.1);
  }
}
.pt_product-details.animate-scroll .product-details-container .product-ingredients-images.show img {
  opacity: 1;
}
.pt_product-details.animate-scroll .product-details-container .product-ingredients-images.show img:first-child {
  left: 10%;
  transform: translateX(-80px) scale(1);
}
@media (min-width: 768px) {
  .pt_product-details.animate-scroll .product-details-container .product-ingredients-images.show img:first-child {
    left: -17%;
    transform: scale(1);
  }
}
.pt_product-details.animate-scroll .product-details-container .product-ingredients-images.show img:last-child {
  left: -10%;
  transform: translateX(80px) scale(1);
}
@media (min-width: 768px) {
  .pt_product-details.animate-scroll .product-details-container .product-ingredients-images.show img:last-child {
    left: 17%;
    transform: scale(1);
  }
}
.pt_product-details.animate-scroll .product-ingredients-container .column-image {
  height: 110vw;
}
@media (min-width: 768px) {
  .pt_product-details.animate-scroll .product-ingredients-container .column-image {
    height: auto;
  }
}
@media (min-width: 768px) {
  .pt_product-details.animate-scroll .product-ingredients-container .product-ingredients-content {
    transform: translateY(40px);
    transition: opacity 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99), transform 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  }
  .pt_product-details.animate-scroll .product-ingredients-container .product-ingredients-content.show {
    opacity: 1;
    transform: translateY(0);
  }
}

.video-fullscreen .pt_product-details.animate-scroll .product-details-container .column-details {
  z-index: -1;
}

.product-ingredients-container {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .product-ingredients-container.product-ingredients-configurable {
    position: relative;
  }
}
.product-ingredients-container.product-ingredients-configurable .column-image {
  position: relative;
}
.product-ingredients-container.product-ingredients-configurable .product-ingredients-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.product-ingredients-container.product-ingredients-configurable .product-ingredients-content {
  opacity: 1;
  transform: translateY(0);
}
.product-ingredients-container.product-ingredients-talisman .product-ingredients-column.column-image {
  display: block;
}
.product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item {
  display: block;
  border: 0;
  position: relative;
}
.product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item::before {
  display: block;
  content: "";
  height: 1px;
  width: 152px;
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0;
  left: 0;
}
.product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item:first-child::before {
  display: none;
}
@media (min-width: 768px) {
  .product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item {
    padding-top: 32px;
  }
}
.product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item h3 {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 4.6px;
  margin-bottom: 0;
}
.product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item p {
  font-size: 18px;
  line-height: 41px;
  letter-spacing: 0.6px;
  font-style: italic;
}
@media (min-width: 768px) {
  .product-ingredients-container.product-ingredients-talisman .product-ingredients-column .ingredient-item p {
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 0.7px;
  }
}
@media (min-width: 768px) {
  body:not(.personalised-gifting-page) .product-ingredients-container {
    flex-direction: row;
    min-height: 760px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  body.personalised-gifting-page .product-ingredients-container {
    min-height: 0;
  }
}

.product-ingredients-column {
  width: 100%;
}
.product-ingredients-column.column-image {
  background-color: #ffffff;
  text-align: center;
}
.product-ingredients-column.column-image img {
  max-width: 100%;
  display: none;
}
@media (min-width: 768px) {
  .product-ingredients-column.column-image img {
    display: block;
  }
}
.product-ingredients-column.column-details {
  padding: 42px 30px 40px;
  transition: height 0.3s ease;
}
.product-ingredients-column .product-ingredients-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  opacity: 1;
  overflow: auto;
  text-align: center;
  width: 100%;
}
.product-ingredients-column .ingredient-item {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  display: none;
  padding-top: 32px;
  text-align: left;
}
.product-ingredients-column .ingredient-item:first-child {
  border-top: 0;
  display: block;
  margin: 0 0 13px;
  padding-top: 0;
}
.product-ingredients-column .ingredient-item h3 {
  color: #ffffff;
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
  margin-bottom: 17px;
}
.product-ingredients-column .ingredient-item p {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 30px;
  margin-bottom: 14px;
}
.product-ingredients-column .ingredient-item-exposed {
  display: block;
}
.product-ingredients-column .btn-show-more {
  display: none;
  margin: 14px 0;
  text-decoration: none;
}
.product-ingredients-column button.btn span {
  text-align: center;
  width: 100%;
}
.product-ingredients-column button.btn .less {
  display: none;
}
.product-ingredients-column.open {
  height: auto;
}
.product-ingredients-column.open .btn-show-more {
  display: inline-block;
}
.product-ingredients-column.open button.btn:hover {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
}
.product-ingredients-column.open button.btn .more {
  display: none;
}
.product-ingredients-column.open button.btn .less {
  display: inline-block;
}
@media (min-width: 768px) {
  .product-ingredients-column {
    width: 50%;
  }
  .product-ingredients-column.column-image {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 760px;
  }
  .product-ingredients-column.column-details {
    padding: 80px 50px;
    transition: padding 0.3s ease;
  }
  .product-ingredients-column .product-ingredients-content {
    display: block;
    max-width: 480px;
    text-align: left;
    opacity: 0;
  }
  .product-ingredients-column .ingredient-item {
    display: block;
    width: 100%;
    padding-top: 51px;
  }
  .product-ingredients-column .ingredient-item h3 {
    letter-spacing: 2px;
    line-height: 14px;
    margin-bottom: 22px;
  }
  .product-ingredients-column .ingredient-item p {
    letter-spacing: 0.4px;
    line-height: 43px;
    margin-bottom: 26px;
  }
  .product-ingredients-column .btn-show-more {
    display: inline-block;
    margin: 22px 0 0;
  }
  .product-ingredients-column button.btn {
    display: none;
  }
}
@media (min-width: 1024px) {
  .product-ingredients-column.column-details {
    padding: 130px 90px 130px 150px;
  }
}

.single-ingredient .product-ingredients-column .product-ingredients-content h3 {
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
}
.single-ingredient .product-ingredients-column .product-ingredients-content p {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 40px;
}
@media (max-width: 767px) {
  .single-ingredient .product-ingredients-column.column-details {
    padding: 40px 30px 16px;
  }
  .single-ingredient .product-ingredients-column .product-ingredients-content h3 {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .single-ingredient .product-ingredients-column.column-details {
    align-items: center;
    display: flex;
  }
  .single-ingredient .product-ingredients-column .product-ingredients-content {
    margin-top: -45px;
    opacity: 1;
  }
}

.product-info-container {
  display: flex;
}
@media (max-width: 767px) {
  .product-info-container {
    flex-direction: column;
  }
}
.product-info-container .product-info-column {
  width: 100%;
}
@media (min-width: 768px) {
  .product-info-container .product-info-column {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .product-info-container .product-info-column .responsive-video video {
    width: 100%;
    height: auto;
  }
}

.product-info-column-image {
  position: relative;
  min-height: 350px;
}
@media (min-width: 768px) {
  .product-info-column-image {
    min-height: 760px;
  }
}
.product-info-column-image .image-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.product-info-column-details {
  padding: 40px 40px 60px;
  text-align: center;
}
@media (max-width: 767px) {
  .product-info-column-details {
    order: 2;
  }
}
@media (min-width: 768px) {
  .product-info-column-details {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .product-info-column-details .product-info-content {
    max-width: 715px;
    padding: 60px 40px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .product-info-column-details .product-info-content {
    padding: 80px 110px;
  }
}
@media (min-width: 1440px) {
  .product-info-column-details .product-info-content {
    padding: 80px 140px;
  }
}
.product-info-column-details h4 {
  color: #807463;
  font-size: 10px;
  letter-spacing: 1.5px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .product-info-column-details h4 {
    font-size: 12px;
    letter-spacing: 2px;
  }
}
.product-info-column-details h3 {
  color: #1a1919;
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  margin-bottom: 21px;
  text-transform: none;
}
@media (min-width: 1024px) {
  .product-info-column-details h3 {
    font-size: 32px;
    letter-spacing: 1.5px;
    line-height: 50px;
    margin-bottom: 28px;
  }
}
.product-info-column-details p {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .product-info-column-details p {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
    margin-bottom: 18px;
  }
}

.product-info-icons {
  display: flex;
  margin: 20px -20px 0;
}
@media (min-width: 768px) {
  .product-info-icons {
    margin: 20px -30px 0;
  }
}
@media (min-width: 1024px) {
  .product-info-icons {
    margin: 62px -50px 0;
  }
}
.product-info-icons .item {
  width: 100%;
}
@media (min-width: 768px) {
  .product-info-icons .item {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: left;
  }
}
.product-info-icons .product-info-icon {
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  color: #886655;
  display: flex;
  flex-shrink: 0;
  font-size: 24px;
  height: 40px;
  justify-content: center;
  margin: 0 auto 12px;
  width: 40px;
}
@media (min-width: 768px) {
  .product-info-icons .product-info-icon {
    margin: 0 5px 0 0;
  }
}
@media (min-width: 1024px) {
  .product-info-icons .product-info-icon {
    margin: 0 10px 0 0;
  }
}
.product-info-icons .icon-duration {
  font-size: 22px;
}
.product-info-icons .info-text {
  color: #1a1919;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .product-info-icons .info-text {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .product-info-container-advent .product-info-column-image {
    min-height: 290px;
    order: 2;
  }
}
@media (min-width: 768px) {
  .product-info-container-advent .product-info-column-image {
    min-height: 700px;
  }
}
@media (max-width: 767px) {
  .product-info-container-advent .product-info-column-details {
    order: 1;
    padding: 60px 20px 50px;
  }
}
.product-info-container-advent .product-info-column-details h3 {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .product-info-container-advent .product-info-column-details h3 {
    letter-spacing: 1.5px;
    line-height: 32px;
    margin-bottom: 17px;
  }
}
@media (max-width: 767px) {
  .product-info-container-advent .product-info-column-details p {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .product-info-container-advent .product-info-column-details p {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
}
.product-info-container-advent .product-info-icons .product-info-icon {
  height: 50px;
  width: 50px;
}
.product-info-container-advent .product-info-icons .info-text {
  color: #7f7f7f;
}
@media (min-width: 768px) {
  .product-info-container-advent .product-info-icons {
    margin: 30px -50px 0;
  }
}
.product-info-container-advent .btn-content-video {
  color: #ffffff;
  left: 50%;
  position: absolute;
  text-decoration: none;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .product-info-container-advent .btn-content-video {
    padding: 72px 0 0;
  }
}
@media (min-width: 768px) {
  .product-info-container-advent .btn-content-video {
    margin-top: 26px;
    padding: 87px 0 0;
  }
}
.product-info-container-advent .btn-content-video::before {
  left: 50%;
  line-height: 1;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .product-info-container-advent .btn-content-video::before {
    font-size: 48px;
  }
}
@media (min-width: 768px) {
  .product-info-container-advent .btn-content-video::before {
    font-size: 64px;
  }
}

@media (max-width: 767px) {
  .product-info-container-easter .product-info-column-details {
    align-items: center;
    display: flex;
    min-height: 330px;
    padding: 46px 30px 40px;
    position: relative;
    z-index: 4;
  }
}
.product-info-container-easter .product-info-column-details .product-info-content {
  width: 100%;
}
@media (max-width: 767px) {
  .product-info-container-easter .product-info-column-details h4 {
    margin-bottom: 18px;
  }
}
@media (min-width: 768px) {
  .product-info-container-easter .product-info-column-details h4 {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .product-info-container-easter .product-info-column-details h3 {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .product-info-container-easter .product-info-column-details h3 {
    margin-bottom: 23px;
  }
}
.product-info-container-easter .product-info-column-details p {
  white-space: pre-line;
}
.product-info-container-easter .product-info-column-details p:last-child {
  margin-bottom: 0;
}

.accordion {
  width: 100%;
}
.accordion .accordion--item {
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}

.accordion--head {
  color: #000000;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 3.3px;
  line-height: 24px;
  margin: 0;
  padding: 34px 55px 34px 30px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
.accordion--head::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #000000;
  content: "\ea94";
  display: inline-block;
  font-size: 25px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.accordion--head.open::after {
  content: "\ea78";
}
.accordion--head:hover {
  background-color: transparent;
  color: #000000;
}
@media (min-width: 768px) {
  .accordion--head {
    padding: 30px 55px 30px 5px;
  }
}
@media (min-width: 1024px) {
  .accordion--head:hover {
    background-color: transparent;
    color: #9a7b69;
  }
  .accordion--head:hover::after {
    color: #9a7b69;
  }
}

.accordion--content {
  display: none;
  padding: 0 30px 30px;
  width: 100%;
}
.accordion--content ul {
  padding-left: 15px;
}
.accordion--content ul li {
  margin-bottom: 5px;
  padding-left: 10px;
}
@media (min-width: 768px) {
  .accordion--content {
    padding: 0 70px 33px 5px;
  }
}

.accordion-content.tab-content-formatted {
  white-space: pre-line;
}
.accordion-content .acc-content-item {
  margin-bottom: 14px;
  width: 100%;
}
.accordion-content .acc-content-head {
  color: #9a7b69;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  padding-left: 30px;
  position: relative;
  text-align: left;
  text-decoration: underline;
  text-transform: uppercase;
}
.accordion-content .acc-content-head::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
  display: inline-block;
  font-size: 28px;
  left: -6px;
  position: absolute;
  transition: transform 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  top: 0;
}
.accordion-content .acc-content-head:hover {
  text-decoration: none;
}
.accordion-content .acc-content-head.open::before {
  transform: rotate(-180deg);
}
.accordion-content .acc-content-body {
  display: none;
  overflow: hidden;
  padding: 25px 0 0 30px;
  width: 100%;
}
.accordion-content .acc-content-body.disclaimer {
  color: #ffffff;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 13px;
}

.accordion-wrapper {
  position: relative;
  width: 100%;
}
.accordion-wrapper.accordion-light::before {
  background-color: #999999;
}
.accordion-wrapper.accordion-light .accordion-head,
.accordion-wrapper.accordion-light .accordion-content {
  color: #ffffff;
  border-color: #999999;
}
.accordion-wrapper.accordion-light .accordion-head::after {
  color: #a4a4a4;
}
.accordion-wrapper.pdp-accordion-wrapper {
  margin-top: -6px;
}
@media (min-width: 768px) {
  .accordion-wrapper.pdp-accordion-wrapper {
    display: none;
  }
}
.accordion-wrapper::before {
  background-color: #d8d8d8;
  content: "";
  display: inline-block;
  height: 1px;
  left: 35px;
  position: absolute;
  right: 35px;
  top: 0;
}
@media (min-width: 768px) {
  .accordion-wrapper::before {
    left: 0;
    right: 0;
  }
}
.accordion-wrapper .accordion-item {
  padding: 0 35px;
  width: 100%;
}
@media (min-width: 768px) {
  .accordion-wrapper .accordion-item {
    padding: 0;
  }
}
.accordion-wrapper .accordion-head {
  border-bottom: 1px solid #d8d8d8;
  color: #1a1919;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 15px;
  padding: 23px 0 17px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
.accordion-wrapper .accordion-head::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
  display: inline-block;
  font-size: 32px;
  position: absolute;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.accordion-wrapper .accordion-head.open {
  border-bottom-color: rgba(255, 255, 255, 0);
}
.accordion-wrapper .accordion-head.open::after {
  transform: translateY(-50%) rotate(-180deg);
}
.accordion-wrapper .accordion-content {
  border-bottom: 1px solid #d8d8d8;
  color: #1a1919;
  display: none;
  font-size: 16px;
  line-height: 28px;
  padding: 10px 0 30px;
  width: 100%;
}
.accordion-wrapper .accordion-content p {
  color: #1a1919;
  font-size: 16px;
  line-height: 28px;
}
.accordion-wrapper .accordion-content p a {
  text-decoration: none;
}
.accordion-wrapper .accordion-content ul {
  list-style: none;
}

.product-grid-block {
  display: flex;
  overflow: hidden;
  padding: 40px 0 85px;
}
.product-grid-block.amsterdam-grid .grid-heading {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 2.4px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .product-grid-block.amsterdam-grid .grid-heading {
    font-size: 40px;
    letter-spacing: 10.6px;
    line-height: 50px;
  }
}
.product-grid-block.product-grid-block-dark .grid-heading, .product-grid-block.hommes-bg-color .grid-heading, .product-grid-block.hommes-bg-light-color .grid-heading {
  color: #ffffff;
}
.product-grid-block.product-grid-block-dark .heading-h3,
.product-grid-block.product-grid-block-dark .heading-h10,
.product-grid-block.product-grid-block-dark .heading-h6,
.product-grid-block.product-grid-block-dark .promotion-alert, .product-grid-block.hommes-bg-color .heading-h3,
.product-grid-block.hommes-bg-color .heading-h10,
.product-grid-block.hommes-bg-color .heading-h6,
.product-grid-block.hommes-bg-color .promotion-alert, .product-grid-block.hommes-bg-light-color .heading-h3,
.product-grid-block.hommes-bg-light-color .heading-h10,
.product-grid-block.hommes-bg-light-color .heading-h6,
.product-grid-block.hommes-bg-light-color .promotion-alert {
  color: #ffffff;
}
@media (min-width: 768px) {
  .product-grid-block.product-grid-block-dark .btn:not(.btn-add-to-cart):not(.btn-send-to-custom), .product-grid-block.hommes-bg-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom), .product-grid-block.hommes-bg-light-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom) {
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.product-grid-block.product-grid-block-dark .btn:not(.btn-add-to-cart):not(.btn-send-to-custom):hover, .product-grid-block.product-grid-block-dark .btn:not(.btn-add-to-cart):not(.btn-send-to-custom):active, .product-grid-block.hommes-bg-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom):hover, .product-grid-block.hommes-bg-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom):active, .product-grid-block.hommes-bg-light-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom):hover, .product-grid-block.hommes-bg-light-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom):active {
  background-color: #ffffff;
  color: #453f3f;
}
.product-grid-block.product-grid-block-dark .btn:not(.btn-add-to-cart):not(.btn-send-to-custom).btn-out-of-stock-disabled, .product-grid-block.hommes-bg-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom).btn-out-of-stock-disabled, .product-grid-block.hommes-bg-light-color .btn:not(.btn-add-to-cart):not(.btn-send-to-custom).btn-out-of-stock-disabled {
  color: #767676;
}
.product-grid-block.collection-grid-primary-btn .grid-load-more .btn {
  background-color: #1a1919;
  border: 1px solid #1a1919;
  color: #ffffff;
}
.product-grid-block.collection-grid-primary-btn .grid-load-more .btn:hover, .product-grid-block.collection-grid-primary-btn .grid-load-more .btn:active {
  background-color: transparent;
  color: #453f3f;
}
.product-grid-block.collection-grid-secondary-btn .grid-load-more .btn {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
}
.product-grid-block.collection-grid-secondary-btn .grid-load-more .btn:hover, .product-grid-block.collection-grid-secondary-btn .grid-load-more .btn:active {
  border: 1px solid #1a1919;
  color: #453f3f;
  background-color: transparent;
}
.product-grid-block.collection-grid-white-btn .grid-load-more .btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.product-grid-block.collection-grid-white-btn .grid-load-more .btn:hover, .product-grid-block.collection-grid-white-btn .grid-load-more .btn:active {
  background-color: #ffffff;
  color: #453f3f;
}
.product-grid-block.collection-grid-white-fill-btn .grid-load-more .btn {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #1a1919;
}
.product-grid-block.collection-grid-white-fill-btn .grid-load-more .btn:hover, .product-grid-block.collection-grid-white-fill-btn .grid-load-more .btn:active {
  background-color: transparent;
  color: #ffffff;
}
.product-grid-block.collection-grid-banner-btn .grid-load-more .btn {
  background-color: #1a1919;
  border: 1px solid #1a1919;
  color: #ffffff;
}
.product-grid-block.collection-grid-banner-btn .grid-load-more .btn:hover, .product-grid-block.collection-grid-banner-btn .grid-load-more .btn:active {
  background-color: transparent;
  border: 1px solid #ffffff;
}
.product-grid-block.product-grid-block-configurable .grid-heading,
.product-grid-block.product-grid-block-configurable .grid-subheading {
  text-transform: none;
  color: #ffffff;
}
.product-grid-block.product-grid-block-configurable .grid-heading {
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .product-grid-block.product-grid-block-configurable .grid-heading {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 40px;
    max-width: 250px;
    margin: 0 auto 21px;
  }
}
.product-grid-block.product-grid-block-configurable .grid-subheading {
  margin: 0 auto 35px;
  max-width: 650px;
  align-self: center;
}
@media (max-width: 767px) {
  .product-grid-block.product-grid-block-configurable .grid-subheading {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    max-width: 255px;
    margin: 0 auto 38px;
  }
}
.product-grid-block.gwp-white-content .item.gwp-grid-item {
  color: #ffffff;
}
.product-grid-block.gwp-white-content .item.gwp-grid-item .heading-h10,
.product-grid-block.gwp-white-content .item.gwp-grid-item .heading-h6,
.product-grid-block.gwp-white-content .item.gwp-grid-item .btn,
.product-grid-block.gwp-white-content .item.gwp-grid-item .promotion-alert {
  color: #ffffff;
}
@media (min-width: 768px) {
  .product-grid-block.gwp-white-content .item.gwp-grid-item .btn {
    border: 1px solid #ffffff;
  }
}
.product-grid-block.gwp-white-content .item.gwp-grid-item .btn:hover, .product-grid-block.gwp-white-content .item.gwp-grid-item .btn:active {
  background-color: #ffffff;
  color: #453f3f;
}
.product-grid-block .inner-wrapper {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}
.product-grid-block .grid-heading {
  letter-spacing: 1.2px;
  text-transform: none;
  text-align: center;
  margin-bottom: 40px;
}
.product-grid-block .grid-subheading {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  max-width: 650px;
  margin: -28px auto 35px;
  text-transform: none;
  color: #ffffff;
  text-align: center;
  align-self: center;
}
.product-grid-block .grid-load-more {
  text-align: center;
  width: 100%;
}
.product-grid-block h2.white,
.product-grid-block .grid-heading.white {
  color: #ffffff;
}
@media (max-width: 767px) {
  .product-grid-block .grid-heading {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 22px;
    padding: 0 20px;
  }
  .product-grid-block .grid-subheading {
    margin-top: -1px;
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .product-grid-block {
    padding: 60px 0 80px;
  }
  .product-grid-block .two-tile-horizontal {
    min-height: 680px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .product-grid-block .grid-wrap {
    margin-left: 20px;
    width: calc(100% - 10px);
  }
  .product-grid-block .grid-wrap .item {
    margin-bottom: 30px;
    width: calc(33.3333% - 30px);
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-grid-block .grid-wrap {
    margin-left: 30px;
    width: calc(100% - 20px);
  }
  .product-grid-block .grid-wrap .item {
    margin-bottom: 40px;
    width: calc(33.3333% - 40px);
  }
}

.grid-placeholder {
  flex-direction: column;
  display: flex;
}

.grid-wrap {
  float: left;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .grid-wrap {
    margin-bottom: 0;
    margin-left: 50px;
  }
}
.grid-wrap .product-giftcard-gif {
  height: 100%;
  width: 100%;
}
.grid-wrap .item {
  background-color: #ffffff;
  float: left;
  margin: 0 0 10px;
  text-align: center;
  text-transform: uppercase;
  width: calc(50% - 5px);
  z-index: 1;
}
@media (min-width: 768px) {
  .grid-wrap .item {
    margin-bottom: 60px;
    width: calc(33.3333% - 74px);
  }
}
.grid-wrap .item.reveal-tile {
  visibility: hidden;
}
.grid-wrap .item.transparent-bg {
  background-color: rgba(255, 255, 255, 0);
}
.grid-wrap .product-giftcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #efedea;
  border: 5px solid #ffffff;
}
@media (max-width: 767px) {
  .grid-wrap .product-giftcard {
    min-height: 400px;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-giftcard {
    min-height: 500px;
  }
}
.grid-wrap .product-giftcard-textbox {
  position: absolute;
  top: 70%;
  text-align: center;
}
@media (max-width: 767px) {
  .grid-wrap .product-giftcard-textbox {
    top: 65%;
    bottom: 15%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-wrap .product-giftcard-textbox {
    top: 60%;
  }
}
.grid-wrap .product-giftcard-text {
  color: #1a1919;
  text-transform: uppercase;
  line-height: 25px;
  font-size: 16px;
  letter-spacing: 5px;
  max-width: 144px;
  margin: 0 auto 5px;
}
@media (max-width: 767px) {
  .grid-wrap .product-giftcard-text {
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 22px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .product-giftcard-text {
    max-width: 230px;
  }
}
.grid-wrap .product-giftcard-subtext {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #1a1919;
  text-transform: none;
  max-width: 230px;
  padding: 0 10px;
}
@media (max-width: 767px) {
  .grid-wrap .product-giftcard-subtext {
    font-size: 12px;
    line-height: 15px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .product-giftcard-subtext {
    max-width: 250px;
  }
}
.grid-wrap .item-spotlight {
  width: 100%;
}
.grid-wrap li.item {
  list-style: none;
}
.grid-wrap .btn {
  min-width: 10px;
}
.grid-wrap .product-tile .product-image {
  background-color: #faf9f7;
  position: relative;
  padding: 20px 12px;
}
@media (min-width: 1024px) {
  .grid-wrap .product-tile .product-image {
    padding: 40px;
  }
}
.grid-wrap .product-tile .product-image.product-image-fullwidth {
  padding: 0;
}
.grid-wrap .product-tile .product-image.product-image-fullwidth img {
  width: 100%;
}
.grid-wrap .product-tile .product-image img {
  display: block;
  max-width: 100%;
}
.grid-wrap .product-tile .promotional-label {
  background-color: #ffffff;
  bottom: 8px;
  font-size: 12px;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-transform: none;
  padding: 8px 16px;
  position: absolute;
  width: auto;
}
@media (min-width: 768px) {
  .grid-wrap .product-tile .promotional-label {
    bottom: 0;
  }
}
.grid-wrap .product-tile .product-details-content {
  position: relative;
  padding: 20px 12px;
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .product-details-content {
    align-items: flex-start;
    text-align: left;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .grid-wrap .product-tile .product-details-content > .base-price-block {
    display: block;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-tile .product-details-content {
    padding: 20px 12px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .product-tile .product-details-content {
    padding: 32px 40px;
  }
}
.grid-wrap .product-tile .product-details-content .product-title {
  max-width: 100%;
}
.grid-wrap .product-tile .product-details-content .product-variations ~ form .btn-select-option {
  margin-top: 0;
}
.grid-wrap .product-tile .short-description {
  color: #767676;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 20px;
  opacity: 1;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .short-description {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-tile .short-description {
    letter-spacing: 0.5px;
    line-height: 25px;
    margin-bottom: 12px;
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .base-price-block {
    border-top: 1px solid rgba(140, 140, 140, 0.3);
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  .grid-wrap .product-tile .base-price-block .unit-base-price {
    color: #8c8c8c;
    font-size: 11px;
    letter-spacing: 0;
    text-transform: none;
  }
  .grid-wrap .product-tile .base-price-block .unit-base-price.legal-note {
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-tile .base-price-block {
    display: none;
    margin-bottom: 3px;
  }
  .grid-wrap .product-tile .base-price-block .unit-base-price {
    font-size: 11px;
    margin-top: 13px;
    text-transform: none;
  }
  .grid-wrap .product-tile .base-price-block .unit-base-price.legal-note {
    margin-top: 9px;
  }
}
.grid-wrap .product-tile.product-configurable .add-to-cart-flexed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.grid-wrap .product-tile.product-configurable .product-add-to-cart {
  margin-left: 16px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .grid-wrap .product-tile.product-configurable .product-add-to-cart {
    display: none;
  }
}
.grid-wrap .product-tile.product-configurable .btn-add-to-cart {
  font-size: 0;
  height: 43px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 43px;
}
.grid-wrap .product-tile.product-configurable .btn-add-to-cart::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2c";
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin-left: 2px;
  margin-top: -5px;
}
@media (min-width: 1024px) {
  .grid-wrap .product-tile.product-configurable .btn-add-to-cart {
    min-width: 10px;
  }
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .add-to-cart-inner {
    display: flex;
  }
}
.grid-wrap .product-tile .btn-wrapper-send {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .btn-wrapper-send .btn {
    display: block;
    margin-left: auto;
    min-width: 0;
    background-color: #ffffff;
    border: 1px solid #b4b4b4;
    border-radius: 50%;
    font-size: 0;
    letter-spacing: 1.5px;
    height: 36px;
    padding: 0;
    width: 36px;
    color: #1a1919;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .grid-wrap .product-tile .btn-wrapper-send .btn.send-icon-visible {
    background-color: #1a1919;
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .btn-wrapper-send .btn .icon-send {
    display: none;
  }
  .grid-wrap .product-tile .btn-wrapper-send .btn .spinner-visible {
    transform: translate(2px, 1px);
  }
  .grid-wrap .product-tile .btn-wrapper-send .btn .spinner-visible :nth-child(1) {
    margin-right: 7px;
  }
  .grid-wrap .product-tile .btn-wrapper-send .btn .spinner-visible :nth-child(2) {
    margin-right: 0;
  }
  .grid-wrap .product-tile .btn-wrapper-send .btn .spinner-visible :nth-child(3) {
    margin-right: -7px;
  }
  .grid-wrap .product-tile .btn-wrapper-send .btn .spinner-visible span {
    width: 5px;
    height: 5px;
  }
  .grid-wrap .product-tile .btn-wrapper-send .btn:not(.button-loading)::before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\eaaf";
    display: block;
    font-size: 18px;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .btn-wrapper-send .btn {
    margin: 0 0 0 5px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .product-tile .btn-wrapper-send .btn {
    min-width: 220px;
  }
}
@media (max-width: 767px) {
  .grid-wrap .product-tile .btn-wrapper-send {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .grid-wrap .giftcard-grid-asset .product-price {
    font-size: 14px;
    letter-spacing: 1.3px;
    line-height: 18px;
    text-align: left;
    width: calc(100% - 36px);
  }
}
@media (min-width: 768px) {
  .grid-wrap .giftcard-grid-asset .product-price {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    margin-bottom: 28px;
    width: 100%;
  }
}
.grid-wrap .promotion-wrapper {
  max-width: 100%;
}
@media (max-width: 767px) {
  .grid-wrap .promotion-wrapper {
    margin: -4px auto 10px auto;
  }
}
@media (min-width: 768px) {
  .grid-wrap .promotion-wrapper {
    margin: -4px 0 12px 0;
  }
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
  background-color: #ad4141;
  border-radius: 0;
  color: #ffffff;
  font-size: 12px;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding: 8px 16px;
  position: absolute;
  width: auto;
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.grid-wrap .promotion-wrapper .promotion-callout .callout-message.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}
.grid-wrap .promotion-wrapper .callout-message-with-new-label {
  bottom: 48px;
}
.grid-wrap .promotion-wrapper .callout-message-no-new-label {
  bottom: 8px;
}
@media (max-width: 767px) {
  .grid-wrap .product-title {
    width: 100%;
  }
}
.grid-wrap .product-title .product-name-link {
  display: block;
  margin-bottom: 13px;
  pointer-events: none;
  text-decoration: none;
}
@media (min-width: 768px) {
  .grid-wrap .product-title .product-name-link {
    margin-bottom: 6px;
  }
}
.grid-wrap .product-title .the-ritual-of-title {
  color: #9a7b69;
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 14px;
  margin-bottom: 9px;
}
@media (min-width: 768px) {
  .grid-wrap .product-title .the-ritual-of-title {
    font-size: 11px;
    letter-spacing: 1.5px;
    line-height: 18px;
    margin-bottom: 11px;
  }
}
.grid-wrap .product-title .product-name {
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0;
}
@media (min-width: 768px) {
  .grid-wrap .product-title .product-name {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0;
  }
}
.grid-wrap .promotion-discount-text {
  background-color: #fcecef;
  color: #d6122d;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1.4px;
  line-height: 13px;
  margin: 5px 0 6px;
  padding: 4px 9px 3px;
}
@media (min-width: 768px) {
  .grid-wrap .promotion-discount-text {
    margin: -4px 0 8px;
  }
}
.grid-wrap .product-user-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 767px) {
  .grid-wrap .product-user-actions {
    padding-top: 3px;
    flex-wrap: nowrap;
  }
}
.grid-wrap .product-user-actions .product-price {
  margin: 0 auto 0 0;
}
@media (max-width: 767px) {
  .grid-wrap .product-user-actions .product-price {
    font-size: 14px;
    letter-spacing: 1.3px;
    line-height: 18px;
    max-width: 100px;
    text-align: left;
    width: calc(100% - 36px);
  }
  .grid-wrap .product-user-actions .product-price .base-price-block {
    display: none;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-user-actions .product-price {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    margin-bottom: 28px;
    width: 100%;
  }
  .grid-wrap .product-user-actions .product-price .base-price-block {
    display: block;
  }
}
.grid-wrap .product-user-actions .product-add-to-cart {
  margin: 0;
}
.grid-wrap .product-user-actions .out-of-stock-block {
  margin-top: 5px;
  width: 100%;
}
@media (max-width: 767px) {
  .grid-wrap .product-user-actions .out-of-stock-block {
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-user-actions .out-of-stock-block {
    margin-top: 10px;
  }
}
.grid-wrap .product-user-actions .btn-out-of-stock {
  border: 0;
  color: #886655;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
  padding: 0;
  text-align: left;
  text-decoration: underline;
  text-transform: none;
}
@media (max-width: 767px) {
  .grid-wrap .product-user-actions .btn-out-of-stock {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.88px;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-user-actions .btn-out-of-stock:hover {
    border: 0;
    text-decoration: none;
  }
}
.grid-wrap .product-user-actions .btn-out-of-stock-disabled {
  background-color: transparent;
  border: 0;
  color: #767676;
  letter-spacing: 0;
  padding: 0;
  text-align: left;
  text-transform: none;
  width: 100%;
}
.grid-wrap .product-user-actions .btn-out-of-stock-disabled::before, .grid-wrap .product-user-actions .btn-out-of-stock-disabled::after {
  content: "";
  clear: both;
  display: table;
}
@media (min-width: 768px) {
  .grid-wrap .product-user-actions .btn-out-of-stock-disabled {
    font-size: 14px;
    letter-spacing: 1px;
    margin: 3px 0 14px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .grid-wrap .product-user-actions.form-wide .rituals-form,
  .grid-wrap .product-user-actions.form-wide form {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .grid-wrap .product-user-actions.form-wide .add-to-cart-inner {
    flex-wrap: wrap;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .product-user-actions.product-user-actions-pg .btn-customize .icon-pencil-configurable {
    display: none;
  }
}
.grid-wrap .form-buttons:not(.btn--disabled) .btn-add-to-cart {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
}
@media (min-width: 768px) {
  .grid-wrap .form-buttons:not(.btn--disabled) .btn-add-to-cart {
    padding: 16px 23px 12px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .form-buttons:not(.btn--disabled) .btn-add-to-cart:hover, .grid-wrap .form-buttons:not(.btn--disabled) .btn-add-to-cart:active {
    border: 1px solid #1a1919;
  }
}
.grid-wrap .form-buttons.btn--disabled .btn-add-to-cart {
  cursor: pointer;
}
@media (max-width: 767px) {
  .grid-wrap .form-buttons .btn-add-to-cart {
    border-color: #8c8c8c;
    border-radius: 50%;
    font-size: 0;
    letter-spacing: 1.5px;
    height: 36px;
    padding: 0;
    width: 36px;
  }
  .grid-wrap .form-buttons .btn-add-to-cart:not(.btn-select-size)::before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea2c";
    display: block;
    font-size: 16px;
    margin-left: 2px;
  }
}
@media (min-width: 768px) {
  .grid-wrap .form-buttons .btn-add-to-cart {
    font-size: 12px;
    letter-spacing: 2px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .form-buttons .btn-add-to-cart {
    min-width: 220px;
  }
}
@media (max-width: 767px) {
  .grid-wrap a.btn-add-to-cart {
    line-height: 32px;
  }
}
.grid-wrap .btn-customize {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
  position: relative;
}
@media (max-width: 767px) {
  .grid-wrap .btn-customize:not(.keep-width) {
    border: 0;
    height: 32px;
    width: 32px;
    padding: 0;
  }
  .grid-wrap .btn-customize:not(.keep-width) .text,
  .grid-wrap .btn-customize:not(.keep-width) span:not(.icon-pencil-configurable) {
    display: none;
  }
  .grid-wrap .btn-customize:not(.keep-width) .icon-pencil-configurable {
    background: url("../images/pencil-black-icon.svg") left top no-repeat;
    background-size: 32px 32px;
    display: inline-block;
    height: 32px;
    width: 32px;
  }
}
@media (min-width: 768px) {
  .grid-wrap .btn-customize:not(.keep-width) {
    height: 43px;
    line-height: 45px;
    padding: 0 20px;
  }
  .grid-wrap .btn-customize:not(.keep-width) .text,
  .grid-wrap .btn-customize:not(.keep-width) span:not(.icon-pencil-configurable) {
    display: none;
  }
  .grid-wrap .btn-customize:not(.keep-width) .icon-pencil-configurable {
    background: url("../images/pencil-black-icon.svg") left top no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 0 -6px;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .btn-customize:not(.keep-width) {
    min-width: 60%;
  }
  .grid-wrap .btn-customize:not(.keep-width):hover, .grid-wrap .btn-customize:not(.keep-width):active {
    border: 1px solid #1a1919;
  }
  .grid-wrap .btn-customize:not(.keep-width) .text,
  .grid-wrap .btn-customize:not(.keep-width) span:not(.icon-pencil-configurable) {
    display: inline-block;
    position: relative;
    top: -1px;
  }
}
.grid-wrap .btn-customize.keep-width {
  min-width: 60%;
}
.grid-wrap .btn-customize.keep-width:hover, .grid-wrap .btn-customize.keep-width:active {
  border: 1px solid #1a1919;
}
.grid-wrap .btn-customize.keep-width .text,
.grid-wrap .btn-customize.keep-width span:not(.icon-pencil-configurable) {
  display: inline-block;
  position: relative;
  top: -1px;
}
.grid-wrap .btn-customize.keep-width .icon-pencil-configurable {
  background: url("../images/pencil-black-icon.svg") left top no-repeat;
  background-size: 20px 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 0 -6px;
}
@media (max-width: 767px) {
  .grid-wrap .btn-select-size {
    vertical-align: top;
  }
  .grid-wrap .btn-select-size .icon-ruler {
    display: block;
    font-size: 20px;
    line-height: 34px;
    width: 34px;
  }
  .grid-wrap .btn-select-size .icon-ruler::before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\eaa7";
  }
}
@media (min-width: 768px) {
  .grid-wrap .btn-select-size .icon-ruler {
    display: none;
  }
}
.grid-wrap .rituals-form,
.grid-wrap form {
  padding: 0 7px;
  width: 100%;
}
@media (max-width: 767px) {
  .grid-wrap .rituals-form,
  .grid-wrap form {
    margin: 0 0 0 auto;
    padding: 0;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .grid-wrap .rituals-form,
  .grid-wrap form {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0;
    width: calc(100% + 40px);
  }
}
.grid-wrap .view-full-details-link {
  display: none;
}

.collection-grid-block {
  background-color: #f6f4f2;
  display: flex;
  padding: 20px 0;
  position: relative;
}
.collection-grid-block .slick-track {
  display: flex;
}
.collection-grid-block .inner-wrapper {
  padding: 0 10px;
}
.collection-grid-block .grid-wrap {
  margin-bottom: 40px;
}
.collection-grid-block .grid-wrap .item {
  padding: 0;
  text-transform: none;
  width: calc(50% - 5px);
  margin-bottom: 10px;
}
.collection-grid-block .grid-wrap .text-holder {
  padding: 24px 25px 17px;
  width: 100%;
}
.collection-grid-block .grid-wrap .text-holder.black-bg-color {
  background-color: #1a1919;
}
.collection-grid-block .grid-wrap .text-holder h3,
.collection-grid-block .grid-wrap .text-holder h2:not(.heading-h10) {
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  text-transform: none;
  margin-bottom: 12px;
}
.collection-grid-block .grid-wrap .text-holder p {
  line-height: 24px;
  margin-bottom: 11px;
  letter-spacing: 0.5px;
}
.collection-grid-block .grid-wrap .text-holder .btn {
  margin: 0 8px 8px;
}
.collection-grid-block .grid-wrap .image-holder {
  display: block;
  margin-bottom: 0;
}
.collection-grid-block .grid-wrap .image-holder img {
  display: block;
  width: 100%;
}
.collection-grid-block .grid-wrap .image-link-wrapper {
  position: relative;
}
@media (max-width: 767px) {
  .collection-grid-block .grid-wrap .image-link-wrapper .image-holder-pd {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .collection-grid-block .grid-wrap .image-link-wrapper .image-holder-pd img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.collection-grid-block .grid-wrap .image-link-wrapper .image-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.collection-grid-block .grid-wrap .image-link-wrapper img {
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .collection-grid-block {
    overflow: hidden;
  }
  .collection-grid-block .image-link-wrapper {
    height: 230px;
    margin-top: auto;
    overflow: hidden;
    width: 100%;
  }
  .collection-grid-block .slider-bar {
    height: 4px;
    margin: 0 auto;
    max-width: 300px;
    overflow: hidden;
  }
  .collection-grid-block .slider-dots {
    left: auto;
    max-width: none;
    transform: none;
    width: 300px;
  }
  .collection-grid-block .slider-dots ul {
    padding-left: 0;
  }
  .collection-grid-block .grid-wrap .item {
    left: auto !important;
    opacity: 1 !important;
    position: relative !important;
    top: auto !important;
    transform: translateY(0) scale(1) !important;
  }
  .collection-grid-block:not(.drag-list) .grid-wrap {
    overflow: visible;
  }
  .collection-grid-block:not(.drag-list) .grid-wrap.grid-cards {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
  .collection-grid-block:not(.drag-list) .grid-wrap.grid-cards .item {
    margin-bottom: 40px;
    text-transform: none;
    width: 100%;
  }
  .collection-grid-block .slick-list {
    overflow: visible;
  }
  .collection-grid-block .slick-slide {
    display: flex;
    height: auto;
    padding: 0 5px;
  }
  .collection-grid-block .slick-slide > div {
    display: flex;
    width: 100%;
  }
  .collection-grid-block .grid-item-inner {
    padding-bottom: 230px;
  }
  .collection-grid-block .grid-item-inner.grid-item-content {
    padding: 0;
  }
  .collection-grid-block .image-holder {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 230px;
    overflow: hidden;
    width: 100%;
  }
  .collection-grid-block .image-holder img {
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 768px) {
  .collection-grid-block {
    padding: 30px 0 0;
  }
  .collection-grid-block .inner-wrapper {
    max-width: 1360px;
  }
  .collection-grid-block .slider-bar {
    display: none !important;
  }
  .collection-grid-block .grid-wrap {
    margin: 0;
  }
  .collection-grid-block .grid-wrap .item {
    margin-bottom: 30px;
    width: calc(50% - 30px);
  }
  .collection-grid-block .grid-wrap .text-holder {
    padding: 41px 40px 27px;
  }
  .collection-grid-block .grid-wrap .text-holder h3,
  .collection-grid-block .grid-wrap .text-holder h2:not(.heading-h10) {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin-bottom: 21px;
  }
  .collection-grid-block .grid-wrap .text-holder p {
    line-height: 28px;
    margin: 0 auto 16px;
    max-width: 388px;
    letter-spacing: 1px;
  }
  .collection-grid-block .slider-dots {
    display: none;
  }
}

@media (max-width: 767px) {
  .collection-grid-block.drag-list {
    margin-bottom: 0;
    padding: 0;
  }
  .collection-grid-block.drag-list .inner-wrapper {
    padding: 0;
  }
  .collection-grid-block.drag-list .grid-wrap {
    float: none;
    height: auto !important;
  }
  .collection-grid-block.drag-list .drag-list-body {
    padding: 31px 25px 35px;
  }
  .collection-grid-block.drag-list .drag-list-body .item {
    opacity: 1 !important;
  }
  .collection-grid-block.drag-list .item {
    background-color: transparent;
    float: none;
    left: auto !important;
    opacity: 0 !important;
    padding: 0 5px;
    top: auto !important;
    width: calc(100vw - 15px);
  }
  .collection-grid-block.drag-list .grid-item-inner {
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .collection-grid-block.drag-list .image-holder {
    left: 5px;
    right: 5px;
    width: auto;
  }
  .collection-grid-block.drag-list .image-holder img {
    width: auto;
  }
}

.product-grid-block + .collection-grid-block {
  padding-top: 0;
  margin-top: -100px;
}
@media (min-width: 768px) {
  .product-grid-block + .collection-grid-block {
    margin-top: -30px;
  }
}

.subcollection-grid-section,
.collection-grid-section {
  padding-bottom: 30px;
}
.subcollection-grid-section .grid-heading,
.collection-grid-section .grid-heading {
  color: #ffffff;
}
.subcollection-grid-section .product-grid-block,
.collection-grid-section .product-grid-block {
  background: none;
}
.subcollection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item,
.collection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item {
  color: #ffffff;
}
.subcollection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item::after,
.collection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item::after {
  background-color: #ffffff;
}
.subcollection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item:hover, .subcollection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item.selected,
.collection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item:hover,
.collection-grid-section .product-grid-block.product-filter-block .product-filters li .filter-item.selected {
  color: #ffffff;
}

@media (max-width: 767px) {
  .zoomed-in .collection-grid-block .slider-dots {
    bottom: 20px;
    display: block;
    max-width: 300px;
  }
  .zoomed-in .collection-grid-block .slider-dots .slick-dots,
  .zoomed-in .collection-grid-block .slider-dots button {
    height: 4px;
  }
}

.general-grid-block {
  overflow: hidden;
  padding: 50px 0 20px;
}
.general-grid-block .grid-heading {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 2px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: initial;
}
@media (max-width: 767px) {
  .general-grid-block .grid-heading {
    font-size: 20px;
    line-height: 20.78px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .general-grid-block {
    padding: 80px 0 20px;
  }
  .general-grid-block .grid-heading {
    margin-bottom: 60px;
  }
}

.grid-decorative-tile {
  padding: 0;
  position: relative;
  width: 100%;
  color: #ffffff;
}
.grid-decorative-tile .background-video {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.grid-decorative-tile .media-holder,
.grid-decorative-tile .image-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.grid-decorative-tile .media-holder {
  display: none;
  min-height: 278px;
  width: 100%;
}
.grid-decorative-tile .image-holder {
  margin-bottom: 0;
}
.grid-decorative-tile .text-holder {
  min-height: 278px;
  position: relative;
  z-index: 1;
  padding: 40px 20px 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid-decorative-tile .text-holder .decorative-tile-subtitle {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 1.5px;
}
.grid-decorative-tile .text-holder .icon-play {
  display: block;
  margin: 25px 0 6px;
  font-size: 30px;
}
.grid-decorative-tile .text-holder .decorative-tile-title {
  text-transform: none;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 5px;
}
.grid-decorative-tile .text-holder .decorative-tile-description {
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 4px;
}
@media (max-width: 767px) {
  .grid-decorative-tile .text-holder .decorative-tile-description {
    display: none;
  }
}
.grid-decorative-tile .text-holder .time {
  font-size: 12px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: uppercase;
}
.grid-decorative-tile .text-holder .time .icon-ico-clock {
  font-size: 13px;
  margin-right: 12px;
}
.grid-decorative-tile .text-holder .video-link {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  display: block;
  margin: 14px auto 0;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: none;
  text-decoration: underline;
}
.grid-decorative-tile .decorative-tile-link,
.grid-decorative-tile .decorative-tile-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) {
  .grid-decorative-tile .media-holder {
    min-height: 412px;
    display: block;
  }
  .grid-decorative-tile .media-holder + .image-holder {
    display: none;
  }
  .grid-decorative-tile .text-holder {
    min-height: 412px;
    padding: 40px;
  }
  .grid-decorative-tile .text-holder .decorative-tile-subtitle {
    font-size: 16px;
    letter-spacing: 5px;
  }
  .grid-decorative-tile .text-holder .icon-play {
    margin: 28px 0 6px;
    font-size: 40px;
  }
  .grid-decorative-tile .text-holder .decorative-tile-title {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 33px;
    margin-bottom: 2px;
  }
  .grid-decorative-tile .text-holder .video-link {
    margin-top: 14px;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 28px;
    text-transform: uppercase;
  }
  .grid-decorative-tile .text-holder .video-link:hover, .grid-decorative-tile .text-holder .video-link:active {
    text-decoration: none;
  }
}
@media (min-width: 1024px) {
  .grid-decorative-tile .text-holder {
    padding: 63px 52px 55px;
  }
}

.collection-fifth-template .collection-grid-section {
  padding-bottom: 0;
}
.collection-fifth-template .collection-grid-section .product-filter-block {
  padding-top: 80px;
}
.collection-fifth-template .collection-grid-section .product-filter-block .grid-heading {
  margin-bottom: 56px;
}
.collection-fifth-template .collection-grid-section .product-filter-block .product-filters li {
  margin-bottom: 30px;
  padding: 0 25px;
}
.collection-fifth-template .collection-grid-section .product-filter-block .product-filters li .filter-item {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 4.3px;
  line-height: 20px;
}

.jing-bg-color .ritual-bg-color {
  background-color: #3f6361;
}
.jing-bg-color .ritual-bg-color .grid-heading {
  color: #ffffff;
}
.jing-bg-color .ritual-bg-color.product-filter-block .product-filters li .filter-item {
  color: #ffffff;
}
.jing-bg-color .ritual-bg-color.product-filter-block .product-filters li .filter-item:hover, .jing-bg-color .ritual-bg-color.product-filter-block .product-filters li .filter-item.selected {
  color: #ffffff;
}
.jing-bg-color .ritual-bg-color.product-filter-block .product-filters li .filter-item::after {
  background-color: #ffffff;
}

.horizontal-grid-block {
  padding: 20px 0;
}
.horizontal-grid-block.black-bg {
  background-color: #1a1919;
}
.horizontal-grid-block.white-bg {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .horizontal-grid-block-inner .btn:first-of-type {
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) {
  .horizontal-grid-block-inner {
    display: flex;
  }
}
@media (min-width: 768px) {
  .horizontal-grid-block-inner.reversed {
    flex-direction: row-reverse;
  }
}
.horizontal-grid-block-inner.white-bg .item-text {
  background-color: #ffffff;
}
.horizontal-grid-block-inner.white-bg p {
  color: #1a1919;
}
.horizontal-grid-block-inner.black-bg .item-text {
  background-color: #1a1919;
}
.horizontal-grid-block-inner.black-bg p,
.horizontal-grid-block-inner.black-bg .heading-h2 {
  color: #ffffff;
}
@media (min-width: 768px) {
  .horizontal-grid-block-inner .item {
    flex: 1;
  }
}
.horizontal-grid-block-inner .item .image-holder {
  height: 100%;
}
@media (max-width: 767px) {
  .horizontal-grid-block-inner .item-text:not(.no-mobile-overlap) {
    margin: -70px auto 0;
    position: relative;
    text-align: center;
    width: 90%;
  }
}
.horizontal-grid-block-inner .item-text.centered {
  text-align: center;
}
@media (min-width: 768px) {
  .horizontal-grid-block-inner .item-text.centered {
    align-content: center;
    align-items: center;
    display: flex;
  }
}
@media (max-width: 767px) {
  .horizontal-grid-block-inner .item-text .text-holder {
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .horizontal-grid-block-inner .item-text .text-holder {
    padding: 64px;
  }
}
.horizontal-grid-block-inner .item-text .icon {
  color: #9a7b69;
  font-size: 24px;
  margin-bottom: 40px;
}
.horizontal-grid-block-inner .heading-h2 {
  font-size: 30px;
  letter-spacing: 1.4px;
  margin-bottom: 30px;
  text-transform: initial;
}
@media (max-width: 767px) {
  .horizontal-grid-block-inner .heading-h2 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
.horizontal-grid-block-inner p {
  margin-bottom: 25px;
}
.horizontal-grid-block-inner .image-link-wrapper {
  height: 100%;
}
.horizontal-grid-block-inner .image-link-wrapper .image-holder {
  height: 100%;
  position: relative;
}
.horizontal-grid-block-inner .image-link-wrapper .image-holder img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 767px) {
  .horizontal-grid-block-inner .image-link-wrapper .image-holder img {
    display: block;
  }
}
@media (min-width: 768px) {
  .horizontal-grid-block-inner .btn {
    margin-right: 15px;
  }
}

.talisman-grid-block {
  background-color: #f6f4f2;
  display: flex;
  flex-direction: column;
}
.talisman-grid-block .grid-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 1.2px;
  line-height: 40px;
  margin: 60px auto 40px;
  max-width: 372px;
  text-align: center;
  text-transform: none;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-title {
    font-size: 24px;
    letter-spacing: 0.96px;
    margin: 34px auto 40px;
    max-width: 260px;
  }
}
.talisman-grid-block .grid-card-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 80px;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-card-holder {
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.talisman-grid-block .grid-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 760px;
  width: 620px;
}
.talisman-grid-block .grid-card:last-child {
  margin-left: 30px;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-card {
    width: 346px;
    height: auto;
    margin: 0 auto 30px;
  }
  .talisman-grid-block .grid-card:last-child {
    margin: auto;
  }
}
.talisman-grid-block .grid-img-holder {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  max-height: 360px;
  padding-top: 20px;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-img-holder {
    margin-bottom: 30px;
    max-height: 270px;
  }
}
.talisman-grid-block .grid-img-holder .grid-img {
  height: -webkit-fill-available;
  max-height: inherit;
  max-width: 100%;
}
.talisman-grid-block .grid-card-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 28px;
  letter-spacing: 1.4px;
  line-height: 32px;
  margin: 0 auto 20px;
  max-width: 280px;
  text-align: center;
  text-transform: none;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-card-title {
    font-size: 22px;
    letter-spacing: 0.55px;
    line-height: 30px;
    margin: 0 35px 20px;
  }
}
.talisman-grid-block .grid-card-text {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 26px;
  margin: 0 auto 30px;
  max-width: 450px;
  text-align: center;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-card-text {
    font-size: 14px;
    letter-spacing: 0.56px;
    line-height: 22px;
    margin: 0 30px 32px;
  }
}
.talisman-grid-block .grid-card-btn {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  margin: 0 auto 60px;
  max-width: 240px;
  padding: 15px 45px;
  text-align: center;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-card-btn {
    letter-spacing: 3.14px;
    line-height: 14px;
    margin: 0 auto 40px;
  }
}
.talisman-grid-block .grid-card-link {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: 2px;
  margin: 0 auto 60px;
}
@media (max-width: 1023px) {
  .talisman-grid-block .grid-card-link {
    margin: 0 auto 40px;
  }
}

.gst-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.gst-wrapper .quiz-container {
  width: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 543px;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .gst-wrapper .quiz-container {
    flex-direction: row;
  }
}
.gst-wrapper .quiz-container.question {
  text-align: center;
}
.gst-wrapper .row {
  width: 100%;
}
.gst-wrapper .btn {
  cursor: pointer;
}
@media (min-width: 768px) {
  .gst-wrapper .btn {
    margin: 20px 20px 15px;
    min-width: 220px;
  }
}
.gst-wrapper .btn-small {
  min-width: 150px;
}
@media screen and (max-width: 350px) {
  .gst-wrapper .btn-small {
    min-width: 100px;
  }
}
@media (min-width: 768px) {
  .gst-wrapper .btn-small {
    min-width: 220px;
  }
}
.gst-wrapper .btn--secondary {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .gst-wrapper .btn--secondary {
    margin-right: 0;
  }
}
.gst-wrapper .content-banner-start,
.gst-wrapper .content-banner {
  min-height: calc(100vh - 60px);
  height: auto;
  max-height: none;
}
@media (min-width: 1024px) {
  .gst-wrapper .content-banner-start,
  .gst-wrapper .content-banner {
    min-height: calc(100vh - 95px);
  }
}
@media (max-width: 767px) {
  .gst-wrapper .content-banner-start .text-holder,
  .gst-wrapper .content-banner .text-holder {
    padding: 50px 0 0;
  }
}
.gst-wrapper .content-banner {
  overflow: hidden;
}
.gst-wrapper .content-banner .image-holder .icon {
  color: #1a1919;
  display: inline-block;
  font-size: 15px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 42%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (min-width: 768px) {
  .gst-wrapper .content-banner .image-holder .icon {
    display: none;
  }
}
.gst-wrapper .content-banner.showing-more-info .more-info-content {
  display: flex;
}
.gst-wrapper .content-banner.showing-more-info .quiz-main-content {
  display: none;
}
.gst-wrapper .content-banner .quiz-main-content {
  width: 100%;
}
.gst-wrapper .content-banner-start .content-inner-wrapper {
  min-height: 0;
}
@media (min-width: 768px) {
  .gst-wrapper .content-banner-start .options-wrapper.questions-wrapper {
    margin: 32px auto 5px;
  }
}
.gst-wrapper .content-banner .image-holder,
.gst-wrapper .question-image-holder .image-holder {
  bottom: -20px;
  left: -20px;
  right: -20px;
  top: -20px;
  transform: scale(1.02);
  transition: filter 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.gst-wrapper .content-banner .image-holder.blurred,
.gst-wrapper .question-image-holder .image-holder.blurred {
  filter: blur(12px);
}
.gst-wrapper .content-banner .image-holder.blurred .icon,
.gst-wrapper .question-image-holder .image-holder.blurred .icon {
  opacity: 0;
}
.gst-wrapper .quiz-main-content {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  visibility: hidden;
}
.gst-wrapper .quiz-main-content.visible {
  opacity: 1;
  visibility: visible;
}
.gst-wrapper .more-info-content {
  display: none;
  color: #ffffff;
  letter-spacing: 0.8px;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  padding: 50px 0;
  align-items: center;
}
.gst-wrapper .more-info-content .text {
  display: flex;
  align-items: center;
  padding: 0 10%;
  max-width: 100%;
}
.gst-wrapper .more-info-content .back-to-question-btn {
  cursor: pointer;
  margin-top: auto;
  display: inline-block;
  text-decoration: underline;
  font-size: 12px;
  letter-spacing: 2px;
}
@media (min-width: 1024px) {
  .gst-wrapper .more-info-content .back-to-question-btn:hover {
    text-decoration: none;
  }
}
.gst-wrapper .content-inner-wrapper {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  padding-bottom: 70px;
}
@media (min-width: 768px) {
  .gst-wrapper .content-inner-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .gst-wrapper .content-inner-wrapper {
    min-height: calc(100vh - 135px);
  }
}
.gst-wrapper .icon {
  vertical-align: middle;
}
.gst-wrapper .estimated-time {
  color: #ffffff;
  font-size: 16px;
  margin-top: 20px;
}
.gst-wrapper .heading-h3,
.gst-wrapper .description,
.gst-wrapper .heading-subtitle {
  z-index: 2;
}
.gst-wrapper .heading-h3 {
  margin-top: 81px;
  margin-bottom: -2px;
  padding: 0 10px;
  font-size: 20px;
  letter-spacing: 5.33px;
  line-height: 40px;
}
@media (min-width: 768px) {
  .gst-wrapper .heading-h3 {
    font-size: 30px;
    letter-spacing: 8px;
    margin: 0;
  }
}
.gst-wrapper .heading-mobile {
  font-size: 18px;
}
@media (min-width: 768px) {
  .gst-wrapper .heading-mobile {
    font-size: 30px;
  }
}
.gst-wrapper .headline {
  margin-bottom: 7px;
  color: #ffffff;
  letter-spacing: 5.04px;
  font-size: 34px;
  line-height: 38px;
}
@media (min-width: 768px) {
  .gst-wrapper .headline {
    margin-bottom: 26px;
    letter-spacing: 8px;
    font-size: 54px;
    line-height: 58px;
  }
}
.gst-wrapper .heading-subtitle {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 22px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-transform: none;
  margin: 15px 0 0;
}
@media (min-width: 768px) {
  .gst-wrapper .heading-subtitle {
    margin: -15px 0 16px;
  }
}
.gst-wrapper .subtitle {
  font-size: 18px;
  letter-spacing: 4.8px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 3px;
  margin-top: 96px;
}
@media (min-width: 768px) {
  .gst-wrapper .subtitle {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
    margin-bottom: 15px;
    margin-top: 0;
  }
}
.gst-wrapper .description {
  font-size: 20px;
  line-height: 24px;
  color: #1a1919;
  letter-spacing: 2.5px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .gst-wrapper .description {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 3px;
    max-width: 685px;
    margin: 0 auto;
    margin-bottom: 59px;
  }
}
.gst-wrapper .question-image-holder {
  bottom: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}
.gst-wrapper .question-image-holder .image-holder {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
}
@media (min-width: 768px) {
  .gst-wrapper .question-image-holder .image-holder {
    background-position: center center;
  }
}
.gst-wrapper .question-image-holder ~ .progress-bar-container {
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}
.gst-wrapper .question-image-holder ~ .progress-bar-container .active,
.gst-wrapper .question-image-holder ~ .progress-bar-container .step.completed {
  color: #453f3f;
}
.gst-wrapper .question-image-holder ~ .progress-bar-container .step {
  color: rgba(69, 63, 63, 0.2);
}
.gst-wrapper .top-wrapper {
  position: relative;
  width: 100%;
  padding: 0 30px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .gst-wrapper .top-wrapper {
    padding-top: 82px;
    margin-bottom: 0;
  }
}
.gst-wrapper .bottom-wrapper {
  margin-top: 30px;
  max-width: 100%;
}
@media (min-width: 768px) {
  .gst-wrapper .bottom-wrapper {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.gst-wrapper .bottom-wrapper .image-text-wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .gst-wrapper .bottom-wrapper .image-text-wrapper {
    flex-direction: row;
    max-width: 900px;
    margin: 0 auto;
  }
}
.gst-wrapper .main-image-wrapper {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  padding: 0 30px;
  margin-top: 25px;
}
.gst-wrapper .main-image-wrapper img {
  width: 100%;
}
@media (min-width: 768px) {
  .gst-wrapper .main-image-wrapper {
    max-width: 33.3333%;
    min-width: 300px;
    margin-top: 20px;
    padding-left: 10px;
  }
}
.gst-wrapper .main-image-wrapper ~ .options-wrapper {
  padding: 0 30px;
}
.gst-wrapper .main-image-wrapper ~ .options-wrapper .option-block {
  width: 100%;
  padding: 10px 0;
}
@media (min-width: 768px) {
  .gst-wrapper .main-image-wrapper ~ .options-wrapper {
    flex-basis: 66.666%;
    width: 66.666%;
    margin-top: 10px;
    padding: 0 10px;
  }
  .gst-wrapper .main-image-wrapper ~ .options-wrapper .option-block {
    width: 50%;
    padding: 10px;
  }
}
.gst-wrapper .more-info {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 2px;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .gst-wrapper .more-info {
    padding-top: 20px;
  }
}
@media (min-width: 1024px) {
  .gst-wrapper .more-info:hover {
    text-decoration: none;
  }
}
.gst-wrapper .options-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: 100%;
  padding: 0 30px;
  max-width: 640px;
  margin: 40px auto 0;
}
@media (min-width: 768px) {
  .gst-wrapper .options-wrapper {
    margin-top: 10px;
    padding: 0;
  }
}
.gst-wrapper .options-wrapper.questions-wrapper {
  max-width: 1020px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .gst-wrapper .options-wrapper.questions-wrapper {
    margin: 9px auto 65px;
    padding: 0 20px;
  }
}
@media (min-width: 1024px) {
  .gst-wrapper .options-wrapper.questions-wrapper {
    padding: 0;
  }
}
.gst-wrapper .option-block {
  width: 147px;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
}
.gst-wrapper .option-block:focus {
  outline: none;
}
.gst-wrapper .option-block:focus .option-content-wrapper {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
  border: 1px solid #886655;
  border-radius: 2px;
}
@media screen and (min-width: 376px) {
  .gst-wrapper .option-block {
    width: 167px;
  }
}
@media (min-width: 768px) {
  .gst-wrapper .option-block {
    width: 200px;
  }
}
.gst-wrapper .option-block .option-content-wrapper,
.gst-wrapper .option-block .option-text {
  border-radius: 2px;
}
.gst-wrapper .option-block .option-content-wrapper {
  text-align: center;
  transition: all 0.15s ease-in-out;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid transparent;
}
.gst-wrapper .option-block .option-text {
  background-color: #ffffff;
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 18px;
  padding: 30px;
  transition: all 0.15s ease-in-out;
  width: 100%;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block .option-text {
    letter-spacing: 2px;
  }
}
.gst-wrapper .option-block .ritual-scent-text {
  padding: 40px 27px 20px;
}
.gst-wrapper .option-block .ritual-scent-text h3 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  line-height: 20px;
  margin-bottom: 7px;
}
.gst-wrapper .option-block .ritual-scent-text p {
  font-size: 13px;
  letter-spacing: 1.62px;
  line-height: 16px;
  color: #626262;
}
.gst-wrapper .option-block .select-box-row {
  width: 100%;
  position: relative;
  top: -16px;
}
.gst-wrapper .option-block .text-only-select-row + .option-text {
  padding: 22px 20px;
  text-align: left;
}
.gst-wrapper .option-block .text-only-wrapper {
  flex-direction: column-reverse;
  justify-content: flex-start;
  min-height: 71px;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block .text-only-wrapper {
    min-height: 150px;
    height: 100%;
  }
}
.gst-wrapper .option-block .text-only-wrapper .select-box-row {
  width: auto;
  position: static;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block .text-only-wrapper .select-box-row {
    margin-bottom: 25px;
  }
}
.gst-wrapper .option-block .text-only-wrapper .select-box-row .round-select-box {
  position: static;
  transform: none;
}
.gst-wrapper .option-block .round-select-box {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  border: 1px solid #886655;
  transition: all 0.15s ease-in-out;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block .round-select-box {
    height: 25px;
    margin-top: 0;
    width: 25px;
  }
}
.gst-wrapper .option-block .round-select-box .icon-check {
  display: none;
}
.gst-wrapper .option-block .image-holder {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.gst-wrapper .option-block.selected .option-content-wrapper {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
  border: 1px solid #886655;
}
.gst-wrapper .option-block.selected .round-select-box {
  background-color: #886655;
}
.gst-wrapper .option-block.selected .round-select-box .icon-check {
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  vertical-align: bottom;
}
.gst-wrapper .option-block.option-rituals .option-content-wrapper {
  position: relative;
}
.gst-wrapper .option-block.option-rituals .option-content-wrapper::after {
  bottom: -1px;
  content: "";
  height: 4px;
  left: -1px;
  position: absolute;
  right: -1px;
  transition: height 0.4s cubic-bezier(0.05, 0.5, 0.3, 1), bottom 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.gst-wrapper .option-block.option-rituals .option-content-wrapper.selected .option-content-wrapper::after {
  bottom: -2px;
  height: 5px;
}
.gst-wrapper .option-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.gst-wrapper .option-extra-info {
  display: none;
}
.gst-wrapper .option-extra-info-target {
  text-align: center;
  padding: 0 33px;
  display: block;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 10px;
  max-width: 640px;
}
.gst-wrapper .option-extra-info-target strong {
  font-weight: 600;
}
.gst-wrapper .nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
  padding-top: 19px;
}
.gst-wrapper .nav-container .nav-container-inner {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 767px) {
  .gst-wrapper .nav-container.fullwidth-nav-container {
    flex-wrap: wrap;
    margin: 0 auto 40px;
    max-width: 268px;
  }
  .gst-wrapper .nav-container.fullwidth-nav-container .btn {
    margin: 0 0 10px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .gst-wrapper .nav-container .btn {
    transition: none;
  }
  .gst-wrapper .nav-container .btn--secondary {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .gst-wrapper .nav-container.sticky-buttons {
    height: 75px;
  }
  .gst-wrapper .nav-container.sticky-buttons .nav-container-inner {
    box-shadow: 0 0 15px rgba(26, 25, 25, 0.2);
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    padding: 0;
    position: fixed;
    z-index: 999;
  }
  .gst-wrapper .nav-container.sticky-buttons .nav-container-inner .btn {
    margin: 0;
    padding: 20px 10px;
    width: 50%;
  }
  .gst-wrapper .nav-container.sticky-buttons .nav-container-inner .btn.btn--secondary {
    background-color: #ffffff;
    border-color: rgba(255, 255, 255, 0);
  }
}
@media (min-width: 768px) {
  .gst-wrapper .nav-container {
    padding: 0 20px;
  }
  .gst-wrapper .nav-container .btn--secondary {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .gst-wrapper .nav-container .btn--secondary:hover, .gst-wrapper .nav-container .btn--secondary:active {
    background-color: rgba(255, 255, 255, 0);
  }
  .gst-wrapper .nav-container .btn:not(.btn-hover-light):hover, .gst-wrapper .nav-container .btn:not(.btn-hover-light):active {
    color: #1a1919;
  }
  .gst-wrapper .nav-container .btn.btn-hover-light:hover, .gst-wrapper .nav-container .btn.btn-hover-light:active {
    border-color: #ffffff;
    color: #ffffff;
  }
}
.gst-wrapper .retake-quiz-link {
  text-decoration: none;
}
.gst-wrapper .progress-bar-container {
  padding: 0 20px;
  display: none;
  width: 100%;
  background-color: #fcfaf7;
  margin: 0 auto;
}
.gst-wrapper .progress-bar-container.progress-bar-hairtemple {
  background-color: rgba(255, 255, 255, 0.1);
}
.gst-wrapper .progress-bar-container.progress-bar-hairtemple .active,
.gst-wrapper .progress-bar-container.progress-bar-hairtemple .step.completed {
  color: #453f3f;
}
.gst-wrapper .progress-bar-container.progress-bar-hairtemple .step {
  color: rgba(69, 63, 63, 0.2);
}
.gst-wrapper .progress-bar-container .progress-bar {
  width: 100%;
  margin: 10px auto 13px;
  display: flex;
  justify-content: center;
}
.gst-wrapper .progress-bar-container .start,
.gst-wrapper .progress-bar-container .finish,
.gst-wrapper .progress-bar-container .steps-container {
  align-self: center;
  display: flex;
}
.gst-wrapper .progress-bar-container .start,
.gst-wrapper .progress-bar-container .finish {
  display: flex;
  flex: 0;
  margin: 0 8px;
  font-size: 10px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #453f3f;
}
@media (min-width: 768px) {
  .gst-wrapper .progress-bar-container .start,
  .gst-wrapper .progress-bar-container .finish {
    margin: 0 20px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .gst-wrapper .progress-bar-container .start,
  .gst-wrapper .progress-bar-container .finish {
    flex: 0 1 auto;
  }
}
.gst-wrapper .progress-bar-container .active {
  text-decoration: underline;
}
@media (min-width: 1024px) {
  .gst-wrapper .progress-bar-container .active:hover {
    text-decoration: none;
  }
}
.gst-wrapper .progress-bar-container .finish {
  margin-left: 0;
}
.gst-wrapper .progress-bar-container .steps-container {
  align-items: center;
  flex: 1;
}
.gst-wrapper .progress-bar-container .step {
  color: rgba(69, 63, 63, 0.2);
  position: relative;
}
.gst-wrapper .progress-bar-container .step:not(:last-of-type) {
  margin-right: 8px;
}
@media (min-width: 768px) {
  .gst-wrapper .progress-bar-container .step:not(:last-of-type) {
    margin-right: 20px;
  }
}
.gst-wrapper .progress-bar-container .step::after {
  content: "";
  display: inline-block;
  margin: 0 5px;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 50%;
}
@media (min-width: 768px) {
  .gst-wrapper .progress-bar-container .step::after {
    width: 5px;
    height: 5px;
  }
}
.gst-wrapper .progress-bar-container .step.completed {
  color: #453f3f;
}
.gst-wrapper .progress-bar-container .step .current-step-label {
  display: none;
}
.gst-wrapper .question-number {
  top: 92px;
  left: 50%;
  transform: translate(-50%);
  font-size: 160px;
  color: rgba(136, 102, 85, 0.15);
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  position: absolute;
}
@media (min-width: 768px) {
  .gst-wrapper .question-number {
    top: 90px;
  }
}
.gst-wrapper .option-block.option-text-image {
  width: 315px;
  display: flex;
  margin: 0 auto;
  padding: 10px 0;
}
.gst-wrapper .option-block.option-text-image:nth-last-of-type(2):first-of-type, .gst-wrapper .option-block.option-text-image:nth-last-of-type(2):first-of-type ~ .option-block.option-text-image {
  width: 50%;
  padding: 5px;
}
.gst-wrapper .option-block.option-text-image:nth-last-of-type(2):first-of-type .option-text, .gst-wrapper .option-block.option-text-image:nth-last-of-type(2):first-of-type ~ .option-block.option-text-image .option-text {
  padding: 30px 15px 25px;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-text-image {
    width: 33.333%;
    padding: 20px;
  }
  .gst-wrapper .option-block.option-text-image:nth-last-of-type(2):first-of-type, .gst-wrapper .option-block.option-text-image:nth-last-of-type(2):first-of-type ~ .option-block.option-text-image {
    width: 33.333%;
    padding: 20px;
    margin: 0;
  }
  .gst-wrapper .option-block.option-text-image:nth-last-of-type(4):first-of-type, .gst-wrapper .option-block.option-text-image:nth-last-of-type(4):first-of-type ~ .option-block.option-text-image, .gst-wrapper .option-block.option-text-image:nth-last-of-type(5):first-of-type, .gst-wrapper .option-block.option-text-image:nth-last-of-type(5):first-of-type ~ .option-block.option-text-image {
    width: 25%;
    padding: 10px 10px 20px;
  }
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper.text-and-image-wrapper .option-image {
  min-height: 0;
  background-color: transparent;
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper {
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;
  flex-shrink: 0;
  background-color: #fcfaf7;
  min-height: 200px;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image {
    min-height: 240px;
  }
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image.ritual-scent-image {
  min-height: 262px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image.ritual-scent-image .top-text {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  letter-spacing: 1.25px;
  line-height: 28px;
  display: block;
  color: #626262;
  text-transform: uppercase;
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image.ritual-scent-image img {
  margin-top: 10px;
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image img {
  width: 100%;
  display: block;
}
.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-text {
  padding: 40px 27px 35px;
  max-width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-text-image .option-content-wrapper .option-text {
    padding: 40px 27px;
  }
}
.gst-wrapper .option-block.option-text-image .extra-info-text {
  color: #886655;
  height: 0;
  opacity: 0;
  padding-top: 4px;
  transition: opacity 0.15s ease-in-out;
  visibility: hidden;
}
.gst-wrapper .option-block.option-text-image.selected .extra-info-text {
  height: auto;
  opacity: 1;
  visibility: visible;
}
.gst-wrapper .option-block.option-text-image .text-over-image {
  font-size: 30px;
  letter-spacing: 8px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: auto;
  left: auto;
  background-color: #fcfaf7;
  width: 100%;
  height: 95%;
  padding: 20px;
}
.gst-wrapper .option-block.option-text-image .text-over-image::after {
  content: "";
  padding-bottom: 80%;
  display: inline-block;
}
.gst-wrapper .option-block.option-text-only {
  display: block;
  min-height: 150px;
}
.gst-wrapper .option-block.option-text-only .option-text {
  padding: 20px 15px;
  text-transform: none;
}
@media (max-width: 767px) {
  .gst-wrapper .option-block.option-text-only {
    margin-bottom: 1px;
    min-height: 10px;
    padding: 0;
    width: 100%;
  }
  .gst-wrapper .option-block.option-text-only .text-only-wrapper {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
    flex-direction: row;
    min-height: 10px;
    padding: 0 17px;
    text-align: left;
  }
  .gst-wrapper .option-block.option-text-only .select-box-row {
    margin-bottom: 0;
  }
  .gst-wrapper .option-block.option-text-only .option-text {
    background-color: rgba(255, 255, 255, 0);
    font-size: 14px;
    padding: 21px 17px 19px;
  }
  .gst-wrapper .option-block.option-text-only.selected .text-only-wrapper {
    border-color: transparent;
  }
}
.gst-wrapper .option-block.option-name-block {
  cursor: default;
  margin-bottom: 65px;
  padding: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-name-block {
    margin-bottom: -40px;
    width: 584px;
  }
  .gst-wrapper .option-block.option-name-block.selected .option-content-wrapper, .gst-wrapper .option-block.option-name-block:focus .option-content-wrapper {
    box-shadow: none;
    border: 1px solid #f6f4f2;
  }
}
.gst-wrapper .option-block.option-name-block .option-content-wrapper {
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .gst-wrapper .option-block.option-name-block .option-content-wrapper {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    margin: 0 auto;
    max-width: 315px;
  }
}
.gst-wrapper .option-block.option-name-block .option-image {
  display: none;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-name-block .option-image {
    display: block;
    padding-top: 36px;
    width: 100%;
  }
}
.gst-wrapper .option-block.option-name-block .option-image img {
  max-width: 100%;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper {
  padding: 17px 20px 5px;
  width: 100%;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-name-block .option-name-wrapper {
    padding: 6px 20px 28px;
    max-width: 320px;
  }
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper .input-holder {
  width: 100%;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input {
  background-color: transparent;
  border-bottom-color: rgba(26, 25, 25, 0.4);
  color: #1a1919;
  font-size: 20px;
  letter-spacing: 1.25px;
  line-height: 28px;
  margin-bottom: 7px;
  text-align: center;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input::-webkit-input-placeholder {
  color: #1a1919;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input::-moz-placeholder {
  color: #1a1919;
  opacity: 1;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input:-ms-input-placeholder {
  color: #1a1919;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input:-moz-placeholder {
  color: #1a1919;
  opacity: 1;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input.error {
  border-color: #d6122d;
  color: #d6122d;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input:focus::-moz-placeholder {
  opacity: 0;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input:focus:-ms-input-placeholder {
  opacity: 0;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input:focus:-moz-placeholder {
  opacity: 0;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper input::-ms-clear {
  display: none;
}
@media (min-width: 768px) {
  .gst-wrapper .option-block.option-name-block .option-name-wrapper input {
    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 44px;
    margin-bottom: 9px;
  }
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper span {
  color: #717171;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 17px;
}
.gst-wrapper .option-block.option-name-block .option-name-wrapper label.error,
.gst-wrapper .option-block.option-name-block .option-name-wrapper span.error {
  color: #d6122d;
  display: block;
  margin-left: -40px;
  width: calc(100% + 80px);
}
.gst-wrapper .quiz-container-result {
  flex-direction: column !important;
  min-height: calc(100vh - 60px);
}
@media (min-width: 1024px) {
  .gst-wrapper .quiz-container-result {
    min-height: calc(100vh - 95px);
  }
}
.gst-wrapper .quiz-container-result .content-left {
  padding: 50px;
  height: auto;
  min-height: 200px;
}
@media (min-width: 768px) {
  .gst-wrapper .quiz-container-result .content-left {
    height: 480px;
    min-height: 480px;
  }
}
.gst-wrapper .quiz-container-result .content-left .result-heading {
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .gst-wrapper .quiz-container-result .content-left .result-heading {
    line-height: 40px;
  }
}
.gst-wrapper .result-container {
  text-align: center;
  background-color: #f6f4f2;
  padding: 50px 30px;
  height: 100%;
}
.gst-wrapper .result-container .result-description {
  max-width: 800px;
  display: block;
  margin: 0 auto;
}
.gst-wrapper .result-container h3 {
  margin-bottom: 20px;
  margin-top: 0;
}
.gst-wrapper .result-container .row {
  margin-top: 12px;
}
.gst-wrapper .result-container .btn--secondary {
  margin-bottom: 30px;
  margin-top: 15px;
}
@media (min-width: 768px) {
  .gst-wrapper .result-container .btn--secondary {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.gst-wrapper .result-extended-heading {
  display: block;
  max-width: 400px;
  margin: 0 auto;
}

.hairtemple-quiz.gst-wrapper {
  position: relative;
}
@media screen and (min-width: 768px) and (max-height: 900px) {
  .hairtemple-quiz.gst-wrapper .option-block.option-text-image .option-content-wrapper .option-image {
    min-height: 150px;
  }
  .hairtemple-quiz.gst-wrapper .options-wrapper.questions-wrapper {
    margin-bottom: 0;
  }
  .hairtemple-quiz.gst-wrapper .btn {
    margin-top: 0;
  }
  .hairtemple-quiz.gst-wrapper .top-wrapper {
    padding-top: 70px;
  }
  .hairtemple-quiz.gst-wrapper .question-number {
    top: 76px;
  }
  .hairtemple-quiz.gst-wrapper .option-block.option-name-block .option-content-wrapper {
    margin-bottom: 80px;
  }
}
.hairtemple-quiz.gst-wrapper .content-banner {
  overflow: hidden;
}
.hairtemple-quiz.gst-wrapper .content-banner .quiz-main-content {
  margin-top: 40px;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  visibility: hidden;
}
.hairtemple-quiz.gst-wrapper .content-banner .quiz-main-content.visible {
  opacity: 1;
  visibility: visible;
}
.hairtemple-quiz.gst-wrapper .content-banner .headline {
  color: #1a1919;
  font-size: 30px;
  letter-spacing: 4.4px;
  line-height: 40px;
}
@media (min-width: 768px) {
  .hairtemple-quiz.gst-wrapper .content-banner .headline {
    font-size: 40px;
    letter-spacing: 10.6px;
    line-height: 50px;
  }
}
.hairtemple-quiz.gst-wrapper .content-banner .heading-subtitle {
  color: #1a1919;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .hairtemple-quiz.gst-wrapper .content-banner .heading-subtitle {
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 25px;
  }
}
.hairtemple-quiz.gst-wrapper .content-banner .btn {
  margin-top: 20px;
}
.hairtemple-quiz.gst-wrapper .content-banner .estimated-time {
  color: #1a1919;
}
.hairtemple-quiz.gst-wrapper .progress-bar-container {
  position: relative;
}
.hairtemple-quiz.gst-wrapper .quiz-container {
  position: relative;
}
@media (min-width: 768px) {
  .hairtemple-quiz.gst-wrapper .quiz-container {
    padding-bottom: 0;
  }
}
.hairtemple-quiz.gst-wrapper .quiz-loader .image-holder {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.hairtemple-quiz.gst-wrapper .quiz-loader .image-holder.blurred {
  filter: blur(0);
}
.hairtemple-quiz.gst-wrapper .option-block.option-text-image.option-hairtemple-text-image .option-content-wrapper {
  background-color: transparent;
  border: 1px solid transparent;
}
.hairtemple-quiz.gst-wrapper .option-block.option-text-image.option-hairtemple-text-image .option-image {
  background-color: transparent;
  min-height: 0 !important;
}
.hairtemple-quiz.gst-wrapper .option-block.option-text-image.option-hairtemple-text-image .option-image img {
  display: block;
}
.hairtemple-quiz.gst-wrapper .option-block.option-text-image.option-hairtemple-text-image .option-text {
  background: #ffffff;
  width: 100%;
}
.hairtemple-quiz.gst-wrapper .option-block.option-text-image.option-hairtemple-text-image.selected .option-content-wrapper {
  border: 1px solid #865;
}

.quiz-landing-page {
  position: relative;
}
.quiz-landing-page .quiz-start-over {
  left: 0;
  position: absolute;
  top: 13px;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .quiz-landing-page .quiz-start-over {
    top: 30px;
  }
}
.quiz-landing-page .quiz-start-over .link {
  float: right;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
}
.quiz-landing-page .product-wrapper-hairtemple .product-image img {
  width: 100%;
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner {
  min-height: 320px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .quiz-landing-page .content-banner.banner-small.hairtemple-banner {
    min-height: 480px;
  }
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .responsive-bg {
  filter: blur(12px);
  transform: scale(1.02);
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .text-holder {
  padding: 53px 0 37px;
}
@media (min-width: 768px) {
  .quiz-landing-page .content-banner.banner-small.hairtemple-banner .text-holder {
    padding: 129px 0 87px;
  }
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .text-holder h1 {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .quiz-landing-page .content-banner.banner-small.hairtemple-banner .text-holder h1 {
    margin-bottom: 20px;
  }
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .text-holder .inner-wrapper {
  padding: 0 40px;
}
@media (min-width: 768px) {
  .quiz-landing-page .content-banner.banner-small.hairtemple-banner .text-holder .inner-wrapper {
    padding: 0 20px;
  }
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .inner-indented {
  max-width: 940px;
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .heading-intro {
  font-size: 16px;
  letter-spacing: 1.33px;
  line-height: 23px;
}
.quiz-landing-page .content-banner.banner-small.hairtemple-banner .heading-intro:first-of-type {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .quiz-landing-page .content-banner.banner-small.hairtemple-banner .heading-intro {
    font-size: 24px;
    letter-spacing: 0.46px;
    line-height: 44px;
  }
  .quiz-landing-page .content-banner.banner-small.hairtemple-banner .heading-intro:first-of-type {
    margin-bottom: 45px;
  }
}
.quiz-landing-page .product-wrapper-hairtemple {
  padding-bottom: 114px;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-wrapper-hairtemple {
    padding-bottom: 65px;
  }
}
.quiz-landing-page .product-cointainer-hairtemple {
  padding-top: 45px;
}
.quiz-landing-page .product-cointainer-hairtemple:nth-of-type(2) {
  padding-top: 80px;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple {
    padding-top: 90px;
    max-width: 1045px;
    margin: 0 auto;
  }
  .quiz-landing-page .product-cointainer-hairtemple:nth-of-type(2) {
    padding-top: 90px;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-image {
  text-align: center;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .product-image {
    width: 50%;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-details-content {
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .product-details-content {
    width: 50%;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-details-content .product-name-link {
  text-decoration: none;
}
.quiz-landing-page .product-cointainer-hairtemple .product-details-content .product-name {
  font-size: 20px;
  letter-spacing: 3px;
  line-height: 28px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .product-details-content .product-name {
    font-size: 30px;
    letter-spacing: 4.5px;
    line-height: 36px;
    margin-bottom: 10px;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-details-content .the-ritual-of-title {
  font-size: 11px;
  letter-spacing: 1.65px;
  line-height: 24px;
  color: #886655;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .product-details-content .the-ritual-of-title {
    margin-bottom: 14px;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-tile {
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .product-tile {
    flex-direction: row;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .long-description {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .long-description {
    margin-bottom: 25px;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-price {
  margin-bottom: 21px;
}
@media (min-width: 768px) {
  .quiz-landing-page .product-cointainer-hairtemple .product-price {
    margin-bottom: 26px;
  }
}
.quiz-landing-page .product-cointainer-hairtemple .product-add-to-cart {
  margin-bottom: 18px;
}

.elixirs-list {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 15px 0 1px;
}
@media (min-width: 768px) {
  .elixirs-list {
    margin: 21px 0 0;
  }
}

.quiz-results-grid {
  overflow: hidden;
  text-align: center;
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .quiz-results-grid {
    padding-bottom: 40px;
  }
}
.quiz-results-grid .grid-heading {
  font-size: 24px;
  letter-spacing: 1.6px;
  line-height: 30px;
  text-transform: none;
  margin-bottom: 24px;
  padding: 0 40px;
}
@media (min-width: 768px) {
  .quiz-results-grid .grid-heading {
    font-size: 30px;
    letter-spacing: 0.58px;
    line-height: 44px;
    margin-bottom: 32px;
    padding: 0;
  }
}

.mini-cart-product .mini-cart-inner-container,
.mini-cart-product .mobile-cart-confirmation-inner,
.mobile-cart-confirmation .mini-cart-inner-container,
.mobile-cart-confirmation .mobile-cart-confirmation-inner {
  display: flex;
  gap: 8px;
}
.mini-cart-product .mini-cart-inner-container,
.mobile-cart-confirmation .mini-cart-inner-container {
  align-items: center;
  display: flex;
}

.hide {
  display: none;
}

.refill-container {
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-top: 28px;
  max-width: 315px;
  min-height: 41px;
}
.refill-container.refill-cart-item {
  margin-bottom: 7.5px;
  margin-top: 10px;
  max-width: 100%;
}
.refill-container.refill-cart-item .refill-inner {
  margin: 0;
}
.refill-container.refill-cart {
  background-color: #f3f5f1;
  bottom: 0;
  margin-right: 40px;
  position: absolute;
  right: 0;
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .refill-container.refill-cart {
    margin-top: 13px;
    margin: 0;
    order: 99;
    position: relative;
  }
}
@media (max-width: 767px) {
  .refill-container.refill-cart {
    margin-top: 13px;
    margin: 0;
    order: 99;
    position: relative;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .refill-container.refill-desktop {
    display: none;
  }
}
@media (max-width: 767px) {
  .refill-container.refill-desktop {
    display: none;
  }
}
.refill-container.refill-mobile {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .refill-container.refill-mobile {
    display: flex;
    height: auto;
    max-width: 100%;
    text-align: flex-start;
    width: 100%;
  }
  .refill-container.refill-mobile .refill-inner {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .refill-container.refill-mobile {
    display: flex;
    height: auto;
    max-width: 100%;
    text-align: flex-start;
    width: 100%;
  }
  .refill-container.refill-mobile .refill-inner {
    margin: 0;
  }
}
.refill-container.refill-mobile-popup {
  background-color: #f3f5f1;
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
}
.refill-container.refill-gift-selection {
  margin-top: 16px;
}
.refill-container .plant-a-tree {
  height: 24px;
  margin: 5px 8px 8px 16px;
  width: 24px;
}
.refill-container .plant-a-tree::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #636e46;
  content: "\ea8f";
  font-size: 24px;
}
.refill-container .refill-inner {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.refill-container .refill-inner .for-every-refill {
  color: #636e46;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 9px 21.5px 8px 0;
}

.refill-container-large {
  background-color: #f3f5f1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 74px;
  margin: 25px 25px 0 25px;
  position: relative;
  width: 808px;
}
@media (max-width: 767px) {
  .refill-container-large {
    height: auto;
    width: auto;
  }
}
.refill-container-large .plant-a-tree {
  height: 35px;
  margin: 22px 24px;
  width: 32px;
}
@media (max-width: 767px) {
  .refill-container-large .plant-a-tree {
    height: 24px;
    margin: 18px 20px auto 24px;
    width: 21.94px;
  }
}
.refill-container-large .plant-a-tree::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #636e46;
  content: "\ea8f";
  font-size: 32px;
}
@media (max-width: 767px) {
  .refill-container-large .plant-a-tree::before {
    font-size: 24px;
  }
}
.refill-container-large .refill-inner {
  display: flex;
  flex-direction: column;
  margin: 14px 0 13px 0;
}
.refill-container-large .refill-inner .refill-thank-you {
  color: #636e46;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 0;
}
.refill-container-large .refill-inner .refill-large-inner {
  display: flex;
  flex-direction: row;
}
@media (max-width: 767px) {
  .refill-container-large .refill-inner .refill-large-inner {
    display: block;
  }
}
.refill-container-large .refill-inner .refill-large-inner .for-every-refill {
  color: #636e46;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .refill-container-large .refill-inner .refill-large-inner .for-every-refill {
    text-align: unset;
  }
}
.refill-container-large .refill-inner .refill-large-inner .refill-learn-more {
  bottom: 0;
  color: #9a7b69;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 35px 23px 15px 0;
  position: absolute;
  right: 0;
  text-align: right;
}
@media (max-width: 767px) {
  .refill-container-large .refill-inner .refill-large-inner .refill-learn-more {
    position: relative;
  }
}

.form {
  width: 100%;
}
.form .form-row {
  padding-top: 20px;
  position: relative;
}
.form .form-row.label-moved label {
  top: 24px;
}
.form .form-row.label-visible label {
  opacity: 1;
}
.form .input-select {
  background: url("../images/icons/arrow3--down.svg") no-repeat right 7px center;
  background-size: 30px 30px;
  padding: 5px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form .input-select::-ms-expand {
  display: none;
}
.form .error {
  font-size: 13px;
}
.form input.error {
  font-size: 16px;
  border-color: #d6122d;
}
.form .btn-submit {
  width: 100%;
}
.form .label-inline,
.form .label-radio {
  padding-top: 16px;
  position: relative;
}
.form .label-inline label,
.form .label-radio label {
  color: #453f3f;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  left: auto;
  letter-spacing: 1px;
  line-height: 28px;
  opacity: 1;
  padding-left: 37px;
  position: relative;
  text-transform: none;
  top: auto;
}
.form .label-inline .field-wrapper {
  left: 0;
  position: absolute;
  top: 0;
}
.form .label-inline label::before {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  content: "";
  display: inline-block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 16px;
}
.form .label-inline label::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea2f";
  display: none;
  font-size: 8px;
  left: 3px;
  line-height: 16px;
  position: absolute;
  top: 4px;
}
.form .label-inline label.checked::before {
  background-color: #886655;
  border-color: #886655;
}
.form .label-inline label.checked::after {
  display: inline-block;
}
.form .form-caption {
  font-size: 12px;
  color: #767676;
}
@media (min-width: 768px) {
  .form .form-row {
    padding-top: 0;
    position: relative;
  }
  .form .btn-submit {
    padding: 15px 34px 14px;
    width: auto;
  }
  .form .label-inline {
    padding-top: 25px;
  }
}

fieldset {
  min-width: 0;
}
fieldset + fieldset {
  margin-top: 40px;
}

.recaptcha-container div {
  margin: 0 auto;
  z-index: 1;
}

textarea {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  padding: 12px 19px;
  width: 100%;
  resize: none;
  overflow: auto;
}
textarea::placeholder {
  color: #aeaeae;
}

.input-textarea {
  resize: none;
  overflow: auto;
}
.input-textarea::placeholder {
  color: #aeaeae;
}

.error-form {
  color: #d6122d;
  text-align: left;
}

.in-form-button {
  margin-top: 10px;
}

.in-form-error {
  font-size: 13px;
}

.avatax-address-validation-error {
  width: 100%;
  margin-top: 15px;
}
@media (min-width: 768px) {
  .avatax-address-validation-error {
    text-align: center;
    margin-top: 26px;
  }
}
.avatax-address-validation-error .error {
  line-height: 17px;
}

.register-snn-wrapper {
  margin-bottom: 15px;
}
.register-snn-wrapper .field-wrapper {
  flex-direction: row;
  flex-wrap: wrap;
}
.register-snn-wrapper .form-caption,
.register-snn-wrapper .error {
  width: 100%;
}
.register-snn-wrapper .inputfield-text {
  flex-grow: 1;
  height: 45px;
  width: auto;
}
@media (max-width: 767px) {
  .register-snn-wrapper .inputfield-text {
    padding: 12px 15px;
  }
}
.register-snn-wrapper .in-form-button {
  margin: 4px 0 0 10px;
  width: auto;
}
.register-snn-wrapper .in-form-button .btn {
  border-color: #d8d8d8;
  min-width: 160px;
}
@media (max-width: 767px) {
  .register-snn-wrapper .in-form-button .btn {
    min-width: 10px;
  }
}

.inputfield-select {
  background: #ffffff url("../images/icons/arrow3--down.svg") no-repeat right 10px center;
  background-size: 30px 30px;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-top: 4px;
  padding: 12px 35px 12px 21px;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
}
.inputfield-select::-ms-expand {
  display: none;
}
.inputfield-select option:disabled {
  display: none;
}

.input-character-counter {
  bottom: 0;
  color: #999999;
  display: inline-block;
  line-height: 45px;
  position: absolute;
  right: 18px;
}

.places-autocomplete.pac-container {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.places-autocomplete.pac-container::after {
  border-top: 1px solid #d8d8d8;
}
.places-autocomplete.pac-container .pac-item {
  border-color: #d8d8d8;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 12px 17px 8px;
}
.places-autocomplete.pac-container .pac-item .pac-icon-marker {
  display: none;
}
.places-autocomplete.pac-container .pac-item .pac-item-query {
  font-size: 15px;
}

.rituals-form .inputfield-select.select-readonly {
  background: transparent;
  border: 0;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
  line-height: 19px;
  padding: 5px 0 7px;
  pointer-events: none;
}
.rituals-form .inputfield-select:disabled {
  cursor: not-allowed;
  color: rgba(174, 174, 174, 0.5);
  border: 1px solid rgba(216, 216, 216, 0.5);
}
.rituals-form .selectric-wrapper .selectric-items ul li {
  font-size: 16px;
  letter-spacing: 0.5px;
}
.rituals-form .selectric-wrapper.selectric-readonly, .rituals-form .selectric-wrapper.selectric-custom-disabled {
  cursor: not-allowed;
}
.rituals-form .selectric-wrapper.selectric-readonly .button, .rituals-form .selectric-wrapper.selectric-custom-disabled .button {
  display: none;
}
.rituals-form .selectric-wrapper.selectric-readonly .selectric {
  background-color: transparent;
  border: 0;
  padding: 5px 0 7px;
  pointer-events: none;
}
.rituals-form .selectric-wrapper.selectric-readonly .selectric .label {
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
  line-height: 19px;
  padding-left: 0;
}
.rituals-form .selectric-wrapper.selectric-custom-disabled .selectric {
  background-color: #efedea;
  border: 1px solid #b4b4b4;
  color: #1a1919;
  pointer-events: none;
}
.rituals-form .selectric-wrapper.selectric-custom-disabled .selectric::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #453f3f;
  content: "\ead6";
  font-size: 16px;
  position: absolute;
  right: 16px;
}
.rituals-form select.custom-disabled {
  background-color: #efedea;
  border: 1px solid #b4b4b4;
  color: #1a1919;
  pointer-events: none;
  touch-action: none;
}

.form-readonly-info {
  cursor: not-allowed;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
  margin-bottom: -10px;
  padding-top: 4px;
}

.button-loading {
  pointer-events: none;
  font-size: 0;
  height: 44px;
}

.form-row {
  max-width: 600px;
  margin: 0 auto;
}

.full-width-row {
  height: 100%;
  max-width: none;
  width: 100%;
}
.full-width-row:not(.label-inline) + .full-width-row:not(.label-inline) {
  margin-top: 32px;
}
.full-width-row.inline-row {
  width: calc(50% - 10px);
}

.full-width-row.three-inline {
  margin-bottom: 20px;
}
.full-width-row.three-inline span.error:not(.hide) {
  bottom: -30px;
  position: absolute;
}

.form-row-button {
  width: 100%;
  max-width: none;
}
.form-row-button:not(.full-width-row) {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .form-row-button:not(.full-width-row) {
    margin-top: 83px;
  }
  .form-row-button:not(.full-width-row).password-btn {
    margin-top: 40px;
  }
}
.form-row-button:not(.full-width-row).checkout-guest {
  margin-top: 0;
}

.quote-heading {
  padding: 100px 0 10px;
}

.quote-link {
  padding: 60px 0 60px;
}

.order-confirmation-wrapper {
  margin-bottom: 60px;
  margin-top: 47px;
}
@media (min-width: 768px) {
  .order-confirmation-wrapper {
    margin-top: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .order-confirmation-wrapper .inner-wrapper-small {
    flex-direction: column;
  }
  .order-confirmation-wrapper .page-layout-left {
    width: 100%;
    padding-right: 0;
  }
  .order-confirmation-wrapper .page-layout-right {
    margin: 40px auto 0;
  }
}

.confirmation {
  z-index: 60;
}

.confirmation-message {
  background-color: #f5f4f2;
  text-align: center;
  padding: 50px 20px 53px;
}
@media (min-width: 768px) {
  .confirmation-message {
    padding: 120px 20px 115px;
  }
}
.confirmation-message h3 {
  font-size: 16px;
  color: #453f3f;
  letter-spacing: 2.67px;
  line-height: 14px;
}
.confirmation-message h1 {
  font-size: 30px;
  letter-spacing: 8px;
  line-height: 40px;
  color: #1a1919;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .confirmation-message h1 {
    font-size: 54px;
    line-height: 58px;
  }
}
.confirmation-message p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #453f3f;
  max-width: 380px;
  display: block;
  margin: 0 auto;
}

.delivery-info-top {
  margin-top: 25px;
}
.delivery-info-top span {
  font-weight: 700;
}

.order-confirmation {
  max-width: 616px;
  margin: 0 auto;
}
.checkout-v3 .order-confirmation {
  max-width: 856px;
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation {
    margin: 0 -20px;
  }
}
.order-confirmation h1 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.25px;
  text-transform: none;
  text-align: center;
}
@media (min-width: 768px) {
  .order-confirmation h1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 2px;
  }
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation h1 {
    font-size: 32px;
    letter-spacing: 1.2px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .order-confirmation h1 {
    font-size: 40px;
    letter-spacing: 1.8px;
    line-height: 48px;
  }
}
.order-confirmation h2 {
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  margin-bottom: 8px;
  text-transform: none;
}
.order-confirmation .pup-map-wrapper {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .order-confirmation .pup-map-wrapper {
    flex-direction: row;
    margin-bottom: 30px;
  }
}
.order-confirmation .pup-info {
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) {
  .order-confirmation .pup-info {
    width: 50%;
  }
}
.order-confirmation .pup-info .pup-address {
  padding: 20px 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid #eaeaea;
  text-align: left;
}
.order-confirmation .pup-info .pup-place {
  font-size: 12px;
  letter-spacing: 1.6px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin-bottom: 3px;
}
.order-confirmation .pup-info .address {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.order-confirmation .pup-info .address span {
  display: block;
}
.order-confirmation .pup-info .opening-times {
  padding: 12px 0;
}
@media (min-width: 768px) {
  .order-confirmation .pup-info .opening-times {
    padding: 21px 0;
  }
}
.order-confirmation .opening-times .accordion-content .acc-content-item {
  position: relative;
  margin-bottom: 0;
}
.order-confirmation .opening-times .accordion-content .acc-content-head {
  padding: 0 30px;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: none;
  font-weight: bold;
  z-index: 3;
}
.order-confirmation .opening-times .accordion-content .acc-content-head::before {
  right: 24px;
}
.order-confirmation .opening-times .accordion-content .acc-content-body {
  position: absolute;
  top: 16px;
  left: 0;
  padding: 20px 20px 63px 30px;
  background: #ffffff;
  z-index: 2;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .order-confirmation .opening-times .accordion-content .acc-content-body {
    padding-bottom: 20px;
  }
}
.order-confirmation .opening-times .accordion-content .acc-content-body ul {
  list-style: none;
}
.order-confirmation .opening-times .accordion-content .acc-content-body li {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.order-confirmation .opening-times .accordion-content .acc-content-body li span {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.order-confirmation .opening-times .accordion-content .acc-content-body li .time {
  margin-left: auto;
}
.order-confirmation .google-map {
  height: 192px;
  width: 100%;
}
@media (min-width: 768px) {
  .order-confirmation .google-map {
    height: 207px;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation .google-map {
    height: 280px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .order-confirmation .google-map {
    height: 100%;
    min-height: 220px;
    width: 100%;
  }
}
.order-confirmation .checkout-summary-minicart {
  text-align: left;
}
.order-confirmation .checkout-summary-minicart .minicart-image {
  padding-right: 10px;
}
.order-confirmation .checkout-summary-minicart .mini-cart-price {
  font-weight: bold;
}
.order-confirmation .checkout-summary-order-totals .cart-footer__item--val {
  font-weight: bold;
}
.order-confirmation .accordion-content .acc-content-head {
  color: #453f3f;
  display: block;
  padding-left: 0;
  text-decoration: none;
  width: 100%;
}
.checkout-v3 .order-confirmation .accordion-content .acc-content-head {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 0 35px 0 24px;
  text-transform: none;
}
.order-confirmation .accordion-content .acc-content-head::before {
  left: auto;
  right: -6px;
}
.checkout-v3 .order-confirmation .accordion-content .acc-content-head::before {
  content: "";
  display: none;
}
.order-confirmation .accordion-content .acc-content-head span:last-child:not(:only-child) {
  color: #9a7b69;
  text-decoration: underline;
}
.order-confirmation .accordion-content .giftcount-header {
  display: none;
}
.checkout-v3 .order-confirmation .accordion-content .giftcount-header {
  border-bottom: 1px solid #eaeaea;
  display: block;
  font-size: 14px;
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation .accordion-content .giftcount-header {
    padding: 16px 24px 24px 24px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .order-confirmation .accordion-content .giftcount-header {
    padding: 24px;
  }
}
.order-confirmation .accordion-content .acc-content-body {
  padding-left: 0;
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation .accordion-content .acc-content-body {
    padding-top: 16px;
  }
}

.order-steps {
  background-color: transparent;
  padding: 40px 0;
  text-align: center;
}
.checkout-v3 .order-steps {
  padding: 40px 0 64px 0;
}
.order-steps .txt--center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.order-steps.order-steps-collect {
  padding: 30px 0 45px;
}
.order-steps.order-steps-collect .step-tile {
  width: 135px;
}
.order-steps.order-steps-collect .step-tile:not(:last-of-type)::before {
  font-size: 42px;
  letter-spacing: -1.5px;
}
.order-steps.order-steps-collect .step-tile:first-child .step__number .circle::before {
  font-size: 10px;
}
.order-steps.order-steps-collect .step-tile:nth-child(2) .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea24";
}
.order-steps.order-steps-collect .step-tile:nth-child(2) .step__number .circle::before {
  font-size: 18px;
}
.order-steps.order-steps-collect .step-tile:last-child .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eab2";
}
.order-steps.order-steps-collect .step-tile:last-child .step__number .circle::before {
  font-size: 18px;
  margin: 0;
}
.order-steps.order-steps-collect .step-tile .step__number .circle {
  height: 40px;
  width: 40px;
  border-color: #d8d8d8;
}
.order-steps.order-steps-collect .step-tile .step__text p {
  margin: 15px 0 0;
}

.step-tile {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  outline: none;
  position: relative;
  margin-bottom: 0;
  width: 120px;
}
@media (max-width: 767px) {
  .checkout-v3 .step-tile {
    width: 120px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .step-tile {
    width: 170px;
  }
}
.step-tile:not(:last-of-type)::before {
  background-color: transparent;
  color: #d8d8d8;
  content: "...";
  font-size: 45px;
  height: auto;
  left: auto;
  position: absolute;
  right: -17px;
  top: -3px;
  transform: none;
  width: auto;
  z-index: 0;
}
.checkout-v3 .step-tile:not(:last-of-type)::before {
  letter-spacing: -2px;
  top: 2px;
}
.step-tile:first-child .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2e";
}
.step-tile:first-child .step__number .circle::before {
  font-size: 12px;
}
.checkout-v3 .step-tile:first-child .step__number .circle::before {
  font-size: 10px;
  margin-left: 3px;
}
.step-tile:nth-child(2) .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea41";
}
.checkout-v3 .step-tile:nth-child(2) .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea44";
}
.step-tile:nth-child(2) .step__number .circle::before {
  font-size: 14px;
}
.checkout-v3 .step-tile:nth-child(2) .step__number .circle::before {
  font-size: 18px;
  margin-left: 3px;
}
.step-tile:last-child .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea5f";
}
.checkout-v3 .step-tile:last-child .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea86";
}
.step-tile:last-child .step__number .circle::before {
  font-size: 16px;
  margin-left: 2px;
  margin-bottom: 2px;
}
.checkout-v3 .step-tile:last-child .step__number .circle::before {
  margin-bottom: 0;
}
.step-tile .step__number.active + .step__text p {
  color: #886655;
  font-weight: bold;
}
.checkout-v3 .step-tile .step__number.active + .step__text p {
  font-weight: normal;
}
.step-tile .step__number.active .circle {
  background-color: #886655;
  border-color: #886655;
}
.step-tile .step__number.active .circle::before {
  color: #ffffff;
}
.checkout-v3 .step-tile .step__number:not(.active) .circle {
  background-color: #ffffff;
  border-color: #ffffff;
}
.step-tile .step__number span {
  display: none;
}
.step-tile .step__number .circle {
  align-items: center;
  border-radius: 50%;
  border: 1px solid #626262;
  display: flex;
  height: 37px;
  justify-content: center;
  margin: 0 auto;
  width: 37px;
}
.checkout-v3 .step-tile .step__number .circle {
  height: 48px;
  width: 48px;
}
.step-tile .step__number .circle::before {
  color: #626262;
  font-weight: bold;
}
.step-tile .step__text {
  max-width: 100%;
}
.step-tile .step__text p {
  font-size: 12px;
  line-height: 14px;
  margin: 10px 0 0;
}
.checkout-v3 .step-tile .step__text p {
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-top: 16px;
}

.order-confirmation-details {
  margin-bottom: 32px;
  padding-bottom: 5px;
  position: relative;
  width: 100%;
}
.order-confirmation-details::after {
  background-color: #d8d8d8;
  bottom: 0;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 305px;
}
.order-confirmation-details p {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0 auto 24px;
  max-width: 80%;
}
.order-confirmation-details.order-confirmation-pickup-point {
  margin-bottom: 15px;
  padding-bottom: 0;
}
.order-confirmation-details.order-confirmation-pickup-point::after {
  display: none;
}
.order-confirmation-details.order-confirmation-pickup-point p {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .order-confirmation-details {
    padding-bottom: 2px;
  }
  .order-confirmation-details::after {
    left: 50%;
    transform: translateX(-50%);
    width: 540px;
  }
  .order-confirmation-details p {
    margin-bottom: 10px;
  }
}

.order-confirmation-email {
  margin: 0 auto 28px;
  max-width: 90%;
  width: 100%;
}
.order-confirmation-email p {
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 24px;
}
.order-confirmation-email .read-only-billing-address span {
  display: block;
}
@media (min-width: 768px) {
  .order-confirmation-email {
    margin-bottom: 20px;
    max-width: 320px;
  }
  .order-confirmation-email p {
    font-size: 14px;
  }
}

.order-confirmation-info {
  margin: 31px 20px 40px;
  padding: 33px 0 0;
  position: relative;
}
@media (min-width: 768px) {
  .order-confirmation-info {
    padding: 30px 15px 0 43px;
    margin-bottom: 45px;
  }
}
.order-confirmation-info::before {
  content: "";
  height: 1px;
  width: 305px;
  display: block;
  background-color: #d8d8d8;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .order-confirmation-info::before {
    width: 540px;
  }
}
.order-confirmation-info.order-collect {
  margin: 15px 20px 20px;
  padding: 26px 0 0;
}
@media (min-width: 768px) {
  .order-confirmation-info.order-collect {
    padding: 40px 15px 0 34px;
    margin: 39px 20px 36px;
  }
}
@media (min-width: 768px) {
  .order-confirmation-info.order-collect::before {
    width: 760px;
  }
}
@media (min-width: 768px) {
  .order-confirmation-info.order-collect .icon {
    margin: 0 26px 0 0;
    font-size: 19px;
  }
  .order-confirmation-info.order-collect .icon::before {
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.order-confirmation-info ul {
  list-style: none;
}
.order-confirmation-info li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 17px;
  text-align: left;
}
@media (min-width: 768px) {
  .order-confirmation-info li {
    margin-bottom: 10px;
    align-items: center;
  }
}
.order-confirmation-info li,
.order-confirmation-info li a {
  font-size: 14px;
  letter-spacing: 0.5px;
}
@media (max-width: 767px) {
  .order-confirmation-info .text {
    line-height: 24px;
  }
}
.order-confirmation-info .icon {
  color: #ffffff;
  text-align: center;
  margin: 5px 14px 0 0;
  position: relative;
  z-index: 1;
}
.order-confirmation-info .icon::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #9a7b69;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (min-width: 768px) {
  .order-confirmation-info .icon {
    margin-top: 0;
  }
}

.order-confirmation-footer .get-directions-button {
  display: none;
}
@media (max-width: 767px) {
  .order-confirmation-footer .get-directions-button {
    display: inline-block;
    margin-bottom: 23px;
    width: auto;
  }
  .order-confirmation-footer .get-directions-button + .btn {
    line-height: normal;
    color: #886655;
    text-decoration: underline;
    cursor: pointer;
    border: 0;
  }
}

.order-confirmation-summary {
  background-color: #ffffff;
  margin: 0 0 40px -20px;
  padding: 20px;
  width: calc(100% + 40px);
}
@media (max-width: 767px) {
  .order-confirmation-summary {
    margin: 0 0 24px 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .order-confirmation-summary {
    padding: 20px 30px;
    margin-left: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation-summary {
    padding: 20px 0;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .order-confirmation-summary {
    margin: 0 0 24px 0;
    padding: 24px 0;
  }
}
.checkout-v3 .order-confirmation-summary h2 {
  padding: 0 24px;
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart {
  border-top: 0;
  margin-bottom: 16px;
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart .unit-base-price {
  display: block;
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart .mini-cart-inner-container {
  flex-wrap: nowrap;
  width: 100%;
}
.order-confirmation-summary__footer {
  border-top: 1px solid #453f3f;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 15px;
}
.checkout-v3 .order-confirmation-summary__footer {
  border-color: #d8d8d8;
  margin-top: 0;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.order-confirmation-summary__footer span {
  font-weight: bold;
}
.order-confirmation-summary__footer span:first-child {
  font-size: 12px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.checkout-v3 .order-confirmation-summary__footer span:first-child {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  text-transform: none;
}
.order-confirmation-summary__footer span:last-child {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
}
.checkout-v3 .order-confirmation-summary__footer span:last-child {
  color: #1a1919;
  font-size: 16px;
  font-weight: unset;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.checkout-v3 .order-confirmation-summary__footer span span:first-child {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: none;
}
.order-confirmation-summary .mini-cart-details-bottom .mini-cart-pricing .unit-base-price {
  display: none;
}

.order-confirmation-aside {
  width: 100%;
  text-align: center;
  padding: 32px 20px 20px;
  background-color: #ffffff;
}
.order-confirmation-aside .order-confirmation-subtitle {
  text-transform: uppercase;
  color: #886655;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  display: block;
  margin-bottom: 12px;
}
.order-confirmation-aside .order-confirmation-title {
  text-transform: none;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 20px;
}
.order-confirmation-aside .image-holder {
  width: 100%;
  margin-bottom: 10px;
}
.order-confirmation-aside .image-holder img {
  max-width: 100%;
}
.order-confirmation-aside .btn.btn-mobile {
  padding: 9px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.order-confirmation-aside .btn:not(:first-of-type) {
  margin-top: 10px;
}
.order-confirmation-aside .btn span {
  margin-right: 10px;
}

.order-confirmation-refer-a-friend {
  background-color: #ffffff;
  margin-top: 24px;
  padding: 40px;
  text-align: left;
  width: 49%;
}
@media (max-width: 767px) {
  .order-confirmation-refer-a-friend {
    width: 100%;
  }
}
.order-confirmation-refer-a-friend .refer-a-friend-heading {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  color: #1a1919;
  max-width: 200px;
  font-size: 28px;
  letter-spacing: 0.6px;
  line-height: 40px;
}
.order-confirmation-refer-a-friend .refer-a-friend-text,
.order-confirmation-refer-a-friend .refer-a-friend-subtext {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
}
.order-confirmation-refer-a-friend .refer-a-friend-text {
  margin-bottom: 20px;
}
.order-confirmation-refer-a-friend .refer-a-friend-subtext {
  margin-bottom: 10px;
}
.order-confirmation-refer-a-friend .refer-a-friend-text-small {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  color: #1a1919;
  font-size: 14px;
  margin-top: 5px;
  letter-spacing: 0.3px;
  line-height: 24px;
}
.order-confirmation-refer-a-friend .benefits-list {
  max-width: 100%;
  margin-bottom: 20px;
}
.order-confirmation-refer-a-friend .benefits-list li span {
  color: #1a1919;
}
.order-confirmation-refer-a-friend .benefits-list li .icon-check {
  font-size: 9px;
  color: #649b26;
}
.order-confirmation-refer-a-friend .refer-a-friend-image-block {
  display: flex;
}
.order-confirmation-refer-a-friend .image-holders {
  margin-right: 16px;
}
.order-confirmation-refer-a-friend .image-holders img {
  max-width: 80px;
}
.order-confirmation-refer-a-friend .btn {
  min-width: 180px;
  padding: 16px 20px;
}
.order-confirmation-refer-a-friend .btn.btn-mobile {
  padding: 9px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.order-confirmation-refer-a-friend .btn:not(:first-of-type) {
  margin-top: 10px;
}
.order-confirmation-refer-a-friend .btn span {
  margin-right: 10px;
}

.order-confirmation-notifications {
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .order-confirmation-notifications {
    padding: 0 20px;
  }
}

.order-confirmation-section {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .order-confirmation-section {
    flex-flow: column;
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .order-confirmation-section {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .order-confirmation-section + .order-confirmation-section {
    margin-top: 24px;
  }
}
@media (min-width: 768px) {
  .order-confirmation-section + .order-confirmation-section {
    margin-top: 40px;
  }
}
.content-asset .order-confirmation-section {
  margin-top: 24px;
}
@media (max-width: 767px) {
  .authenticated .order-confirmation-section {
    width: 100%;
  }
}
.order-confirmation-section.order-confirmation-section-float {
  float: right;
  width: 49%;
}
@media (max-width: 767px) {
  .order-confirmation-section.order-confirmation-section-float {
    width: 100%;
  }
}
.order-confirmation-section h2 {
  margin-bottom: 24px;
}
.order-confirmation-section p {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 0;
}
.order-confirmation-section p + p {
  margin-top: 16px;
}
.order-confirmation-section .btn {
  margin-top: 24px;
  max-width: none;
  width: 100%;
}
.order-confirmation-section .order-confirmation-section-col:not(:only-child) {
  width: calc(50% - 40px);
}
@media (max-width: 767px) {
  .order-confirmation-section .order-confirmation-section-col:not(:only-child) {
    width: 100%;
  }
}
.order-confirmation-section .order-confirmation-section-col:only-child {
  width: 100%;
}
.order-confirmation-section .order-confirmation-section-col + .order-confirmation-section-col {
  display: flex;
}
@media (max-width: 767px) {
  .order-confirmation-section .order-confirmation-section-col + .order-confirmation-section-col {
    margin-top: 24px;
  }
}
.order-confirmation-section .order-confirmation-section-col figure img {
  width: 100%;
}
.order-confirmation-section .download-app-tile {
  background-color: #f6f4f2;
  text-align: center;
}
@media (max-width: 767px) {
  .order-confirmation-section .download-app-tile {
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .order-confirmation-section .download-app-tile {
    padding: 40px;
  }
}
.order-confirmation-section .download-app-tile a {
  display: inline-block;
  margin: 24px 12px 0 12px;
}
.order-confirmation-section .download-app-tile a + a {
  margin-top: 0;
}
.order-confirmation-section .vertical-tile-list {
  margin-top: 24px;
}
.order-confirmation-section .vertical-tile-list label {
  height: auto;
}

.pdp-main-container.giftset-pdp-main-container {
  background-color: #ffffff;
}

.giftset-pdp-main-container .giftset-details-container {
  min-height: calc(100vh - 60px);
  padding: 0;
}
@media (min-width: 1024px) {
  .giftset-pdp-main-container .giftset-details-container {
    padding-top: 95px;
    min-height: calc(100vh - 95px);
  }
}
.giftset-pdp-main-container .giftset-details-container .pdp-back-button-wrapper {
  left: 20px;
  position: absolute;
  top: 11px;
  z-index: 20;
}
@media (min-width: 1024px) {
  .giftset-pdp-main-container .giftset-details-container .pdp-back-button-wrapper {
    position: fixed;
    top: 108px;
  }
}
.giftset-pdp-main-container .giftset-details-container .pdp-back-button-wrapper .back-button {
  color: #453f3f;
}
@media (min-width: 1024px) {
  .giftset-pdp-main-container .giftset-details-container .pdp-inner-wrapper {
    padding: 0;
  }
}
@media (max-width: 1023px) {
  .giftset-pdp-main-container .giftset-details-container .scrollmagic-pin-spacer {
    width: 100% !important;
  }
}
.giftset-pdp-main-container .images-wrapper .images-slider img {
  max-height: none;
  max-width: none;
  opacity: 1;
  width: 100% !important;
}
@media (max-width: 1023px) {
  .giftset-pdp-main-container .pdp-inner-wrapper {
    padding-top: 0;
  }
  .giftset-pdp-main-container .images-wrapper .product-images {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
  .giftset-pdp-main-container .images-wrapper .product-images img {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .giftset-pdp-main-container .giftset-column-images {
    height: calc(100vh - 95px);
    text-align: center;
    width: 50%;
    z-index: 2;
  }
  .giftset-pdp-main-container .images-wrapper {
    width: 100%;
  }
  .giftset-pdp-main-container .images-wrapper .images-slider {
    height: calc(100vh - 95px);
    max-width: 100%;
    width: 100%;
  }
  .giftset-pdp-main-container .images-wrapper .images-slider img {
    height: 100%;
    max-height: none;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .giftset-pdp-main-container .giftset-column-details {
    padding: 31px 0 520px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-details-content {
    padding: 0 20px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-title {
    margin-bottom: 14px;
    padding: 0 5px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-title .the-ritual-of-title {
    font-size: 10px;
    letter-spacing: 1.5px;
    line-height: 14px;
    margin-bottom: 5px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-title .product-name {
    letter-spacing: 1.2px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-content .long-description {
    color: #1a1919;
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-content .read-more-link {
    font-size: 16px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-content .product-price {
    margin-bottom: 29px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-content .btn {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .giftset-pdp-main-container .giftset-column-details {
    padding-bottom: 922px;
  }
}
@media (min-width: 1024px) {
  .giftset-pdp-main-container .giftset-column-details {
    height: calc(100vh - 95px);
    padding: 0;
  }
  .giftset-pdp-main-container .giftset-column-details .product-details-content {
    max-width: 460px;
    padding: 50px 20px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-title .the-ritual-of-title {
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-title .product-name {
    margin-bottom: 12px;
  }
  .giftset-pdp-main-container .giftset-column-details .product-content .long-description {
    margin-bottom: 21px;
    max-width: none;
  }
  .giftset-pdp-main-container .giftset-column-details .product-content .btn {
    margin-bottom: 11px;
    min-width: 256px;
  }
  .giftset-pdp-main-container .giftset-column-details .personalised-gifting-shipping-info {
    margin: 20px auto 0;
    max-width: 335px;
    padding-top: 26px;
  }
}
.giftset-pdp-main-container .giftset-column-details .personalised-gifting-shipping-info {
  border-top: 1px solid #eaeaea;
  margin-top: 29px;
  padding-top: 36px;
  width: 100%;
}

.product-custom-giftset {
  display: flex;
}
@media (min-width: 1024px) {
  .product-custom-giftset {
    display: flex;
    padding-top: 95px;
    min-height: calc(100vh - 95px);
    position: relative;
  }
}

.product-custom-giftset-column {
  background-color: #ffffff;
  padding: 21px 30px 20px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-custom-giftset-column {
    margin: 0 auto;
    width: 80%;
  }
}
.product-custom-giftset-column .giftset-next-btn {
  display: none;
}
@media (min-width: 1024px) {
  .product-custom-giftset-column {
    align-items: center;
    display: flex;
    height: calc(100vh - 95px);
    justify-content: center;
    margin-left: 50%;
    overflow: hidden;
    padding: 50px;
    width: 50%;
  }
  .product-custom-giftset-column .inner-content-column {
    max-width: 400px;
    text-align: center;
  }
  .product-custom-giftset-column .giftset-next-btn {
    display: inline-block;
    min-width: 240px;
  }
}

.product-custom-giftset-intro {
  margin-bottom: 28px;
  text-align: center;
}
.product-custom-giftset-intro h2 {
  color: #000000;
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  margin: 0 0 10px;
  text-align: center;
  text-transform: none;
}
.product-custom-giftset-intro p {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .product-custom-giftset-intro {
    margin-bottom: 33px;
  }
  .product-custom-giftset-intro h2 {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
    margin: 0 0 7px;
  }
}

.custom-giftset-list-wrap {
  border-bottom: 1px solid #d9d7d8;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 16px;
  max-width: 290px;
  width: 100%;
}
.custom-giftset-list-wrap:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.custom-giftset-list-wrap .edit-button {
  background-color: #ffffff;
  border: 0;
  border-radius: 32px;
  box-shadow: 0 0 6px 0 rgba(26, 25, 25, 0.1);
  color: #886655;
  cursor: pointer;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 34px;
  height: 30px;
  margin-bottom: 11px;
  padding: 0 12px 0 37px;
  position: relative;
}
.custom-giftset-list-wrap .edit-button .repeat-label {
  display: none;
}
.custom-giftset-list-wrap .edit-button.repeat .repeat-label {
  display: inline-block;
}
.custom-giftset-list-wrap .edit-button.repeat .default-label {
  display: none;
}
.custom-giftset-list-wrap .edit-button .circle {
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(26, 25, 25, 0.1);
  display: inline-block;
  height: 30px;
  left: 0;
  position: absolute;
  width: 30px;
}
.custom-giftset-list-wrap .edit-button .circle::before {
  content: "";
  border-radius: 100%;
  background-image: url("../images/pulsating-button-rainbow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.custom-giftset-list-wrap .undo-button {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  color: #9a7b69;
  display: none;
  font-size: 11px;
  letter-spacing: 0.5px;
  margin: 11px 0 30px;
  order: 2;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
}
.custom-giftset-list-wrap .giftset-product-list {
  display: flex;
  margin-bottom: 15px;
  padding-left: 5px;
  width: 100%;
}
.custom-giftset-list-wrap .giftset-product-list .list-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.custom-giftset-list-wrap .giftset-product-list span {
  color: #1a1919;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 28px;
}
.custom-giftset-list-wrap .giftset-product-list span:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .custom-giftset-list-wrap {
    margin-bottom: 17px;
    max-width: 320px;
    text-align: left;
  }
  .custom-giftset-list-wrap:last-of-type {
    margin-bottom: 10px;
  }
  .custom-giftset-list-wrap .edit-button {
    font-size: 12px;
    letter-spacing: 0.4px;
    outline: none;
    margin-left: -5px;
    padding: 0 12px 0 35px;
  }
  .custom-giftset-list-wrap .edit-button .circle {
    transition: transform 0.4s cubic-bezier(0.18, 0.74, 0.36, 0.99), opacity 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7), visibility 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  }
  .custom-giftset-list-wrap .edit-button.animating .circle {
    animation: pulse 1s infinite;
    transform-origin: center;
  }
  .custom-giftset-list-wrap .edit-button:hover .circle {
    transform: scale(1.2);
  }
  .custom-giftset-list-wrap .undo-button {
    margin: 1px 0 6px auto;
    order: 0;
  }
  .custom-giftset-list-wrap .giftset-product-list {
    padding-left: 0;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.giftset-lightbox {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;
  z-index: -1;
}
.giftset-lightbox.visible {
  opacity: 1;
  visibility: visible;
  z-index: 991;
}
@media (min-width: 768px) {
  .giftset-lightbox {
    position: absolute;
    right: auto;
    width: 50%;
  }
}

.giftset-lightbox-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .giftset-lightbox-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.giftset-lightbox-close {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  font-size: 0;
  padding: 0;
  position: fixed;
  right: 15px;
  top: 0;
  z-index: 2;
}
.giftset-lightbox-close span {
  color: #1a1919;
  display: inline-block;
  font-size: 26px;
  line-height: 1;
}
@media (min-width: 768px) {
  .giftset-lightbox-close {
    cursor: pointer;
    position: absolute;
    right: 27px;
    top: 26px;
  }
}

.giftset-lightbox-box {
  left: 0;
  max-height: 100%;
  padding-top: 37px;
  position: fixed;
  right: 0;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  z-index: 2;
}
@media (min-width: 768px) {
  .giftset-lightbox-box {
    background-color: #ffffff;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    left: 50%;
    margin-left: auto;
    max-height: none;
    max-width: 520px;
    min-height: 602px;
    padding: 0 0 40px;
    position: absolute;
    right: auto;
    transform: translate(-50%, -50%);
    width: calc(100% - 100px);
  }
}

.giftset-lightbox-slider {
  width: 100%;
}
@media (max-width: 767px) {
  .giftset-lightbox-slider {
    padding-bottom: 12px;
  }
  .giftset-lightbox-slider .drag-list-body {
    padding: 0 24px;
  }
}
@media (min-width: 768px) {
  .giftset-lightbox-slider {
    padding: 0 24px 12px;
  }
  .giftset-lightbox-slider .drag-list-body {
    padding: 0;
  }
}
.giftset-lightbox-slider .slider-item-inner {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px 20px 40px;
  width: 100%;
}
.giftset-lightbox-slider .lightbox-product-list {
  display: none;
}
@media (min-width: 768px) {
  .giftset-lightbox-slider {
    padding: 0;
  }
  .giftset-lightbox-slider .slider-item-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 55px 30px;
  }
}

.lightbox-slider-item {
  border-radius: 5px;
  display: flex !important;
  outline: none;
}
@media (max-width: 767px) {
  .lightbox-slider-item {
    padding: 0 7px;
    width: calc(100vw - 10px);
  }
}
@media (min-width: 768px) {
  .lightbox-slider-item {
    overflow: hidden;
    position: relative;
  }
}

.lightbox-product-image {
  margin-bottom: 54px;
  text-align: center;
  width: 100%;
}
.lightbox-product-image img {
  display: inline-block;
  max-height: 340px;
  max-width: 100%;
}
@media (min-width: 768px) {
  .lightbox-product-image {
    margin-bottom: 70px;
    max-width: 313px;
  }
  .lightbox-product-image img {
    max-height: 390px;
  }
}

.lightbox-product-details {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
}
.lightbox-product-details .btn .selected-label {
  display: none;
}
.lightbox-product-details .btn.selected {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  color: #1a1919;
  min-width: 10px;
  padding: 15px 10px 14px;
  pointer-events: none;
  text-align: left;
}
.lightbox-product-details .btn.selected .selected-label {
  display: flex;
}
.lightbox-product-details .btn.selected .selected-label::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #649b26;
  content: "\ea2e";
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin: -1px 10px 0 0;
}
.lightbox-product-details .btn.selected .desktop-label,
.lightbox-product-details .btn.selected .mobile-label {
  display: none;
}
@media (max-width: 767px) {
  .lightbox-product-details .btn:not(.selected) .desktop-label {
    display: none;
  }
}
@media (min-width: 768px) {
  .lightbox-product-details {
    max-width: 315px;
  }
  .lightbox-product-details .btn:not(.selected) .mobile-label {
    display: none;
  }
}

.lightbox-product-title {
  color: #000000;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0 0 19px;
  text-transform: none;
}
@media (min-width: 768px) {
  .lightbox-product-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 0 30px;
  }
}

@media (max-width: 767px) {
  .visible-lightbox .giftset-pdp-main-container,
  .visible-lightbox .product-custom-giftset .inner-content-column,
  .visible-lightbox .write-a-card-column {
    filter: blur(10px);
  }
}

.giftset-replace-dialog-wrapper {
  background-color: #ffffff !important;
  display: none;
  margin: 0 20px;
  padding: 80px 20px 40px !important;
  text-align: center;
}
@media (min-width: 768px) {
  .giftset-replace-dialog-wrapper {
    margin: 0;
    padding: 120px 100px 70px !important;
  }
}
.giftset-replace-dialog-wrapper p {
  margin-bottom: 30px;
}
.giftset-replace-dialog-wrapper button {
  min-width: 100%;
}
@media (min-width: 768px) {
  .giftset-replace-dialog-wrapper button {
    min-width: 300px;
  }
}
.giftset-replace-dialog-wrapper button + button {
  margin-top: 20px;
}

.giftwrap-block {
  background-color: #f6f4f2;
  margin-top: 24px;
  padding: 18px 15px 14px 20px;
  text-align: left;
  position: relative;
}
.checkout-v3 .giftwrap-block {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 16px;
}
@media (min-width: 768px) {
  .checkout-v3 .giftwrap-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 16px;
  }
}
.checkout-v3 .giftwrap-block:last-of-type {
  margin-bottom: 24px;
}
.checkout-v3 .giftwrap-block .giftwrap-block-toggle label {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.checkout-v3 .giftwrap-block .giftwrap-block-toggle label::before {
  border-radius: 0;
}
@media (min-width: 768px) {
  .giftwrap-block {
    padding-right: 23px;
  }
}
.checkout-v3 .giftwrap-block.checked, .checkout-v3 .giftwrap-block.enabled, .checkout-v3 .giftwrap-block:hover {
  background-color: #efedea;
  border-color: #9a7b69;
}
.giftwrap-block:not(.enabled):hover input[type=radio],
.giftwrap-block:not(.enabled):hover input[type=checkbox] + label::before {
  background-color: #efedea;
  border-color: #9a7b69;
}
.checkout-v3 .giftwrap-block.enabled .edit-products-link,
.checkout-v3 .giftwrap-block.enabled .giftwrap-block-options {
  display: block;
}
.giftwrap-block .gift-wrap-price-wrapper {
  position: absolute;
  top: 15px;
  right: 18px;
  z-index: 2;
}
@media (max-width: 767px) {
  .checkout-v3 .giftwrap-block .gift-wrap-price-wrapper {
    top: 23px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .giftwrap-block .gift-wrap-price-wrapper {
    top: 26px;
    right: 24px;
  }
}
.giftwrap-block .gift-wrap-price-wrapper a {
  padding: 0 5px;
}
.giftwrap-block .gift-wrap-price {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-left: auto;
}
.giftwrap-block .gift-wrap-price.disabled {
  color: #b4b4b4;
}
.giftwrap-block .edit-products-link {
  display: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-left: 36px;
  margin-top: 5px;
}

.checkout-v3 .shopping-cart-block .giftwrap-block-greeting {
  margin-bottom: 40px;
  margin-top: 16px;
}

.giftwrap-block-toggle {
  position: relative;
  width: 100%;
  z-index: 1;
}
.giftwrap-block-toggle input {
  margin: 0;
}
.giftwrap-block-toggle label {
  color: #453f3f;
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding-left: 36px;
  position: relative;
}
.checkout-v3 .giftwrap-block-toggle label {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding-top: 2px;
}
.giftwrap-block-toggle label::before, .giftwrap-block-toggle label::after {
  top: 1px;
}

.giftwrap-block-options {
  display: none;
  width: 100%;
}
.giftwrap-block-options.enabled {
  display: block;
}
.giftwrap-block-options .gift-set-message {
  margin-top: 20px;
}
.giftwrap-block-options .gift-set-message + .giftwrap-message-sibling .wrap-single-item {
  margin: 0;
}
@media (min-width: 768px) {
  .giftwrap-block-options .gift-set-message + .giftwrap-message-sibling .wrap-single-item {
    margin: -104px 0 57px;
  }
}
.giftwrap-block-options .gift-set-message + .giftwrap-message-sibling .adjust-position {
  margin: 15px 0 -67px;
}
@media (min-width: 768px) {
  .giftwrap-block-options .gift-set-message + .giftwrap-message-sibling .adjust-position {
    margin: 45px 0 -67px;
  }
}
.giftwrap-block-options .gift-set-message .error {
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  letter-spacing: 1px;
}

.giftwrap-block-radio-wrapper:not(.giftwrap-message-sibling) {
  margin-top: 14px;
  border-top: 1px solid #d8d8d8;
}
.checkout-v3 .giftwrap-block-radio-wrapper:not(.giftwrap-message-sibling) {
  margin-top: 24px;
}

.giftwrap-message-sibling {
  margin-top: -34px;
}
@media (min-width: 768px) {
  .giftwrap-message-sibling {
    margin-top: -24px;
  }
}
.giftwrap-message-sibling:only-child .wrap-single-item {
  padding-top: 10px;
  margin-bottom: 0;
  margin: -20px 0 0;
}
@media (min-width: 768px) {
  .giftwrap-message-sibling:only-child .wrap-single-item {
    padding-top: 0;
  }
}
.giftwrap-message-sibling:only-child .gift-wrap-price {
  margin-left: auto;
}
.giftwrap-message-sibling + .giftwrap-block-personal-message {
  padding-top: 48px;
}

.giftwrap-block-radio {
  position: relative;
  margin-bottom: 25px;
}
.giftwrap-block-radio:first-of-type:not(:only-child) {
  padding-top: 25px;
}
.giftwrap-block-radio:only-child {
  margin: 20px 0 -67px;
}
.giftwrap-block-radio:only-child label {
  padding-left: 0;
}
.giftwrap-block-radio:only-child label .gift-wrap-text {
  display: none;
}
.giftwrap-block-radio:only-child label::before, .giftwrap-block-radio:only-child label::after {
  display: none;
}
.giftwrap-block-radio:only-child input:checked + label::after {
  display: none;
}
.giftwrap-block-radio:last-of-type:not(:only-child) {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .giftwrap-block-radio:last-of-type:not(:only-child) {
    margin-bottom: 15px;
  }
}
.giftwrap-block-radio.wrap-single-item label {
  cursor: default;
}
.giftwrap-block-radio input {
  height: 1px;
  left: 2px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 2px;
  width: 1px;
}
.giftwrap-block-radio label {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding-left: 45px;
  position: relative;
}
.checkout-v3 .giftwrap-block-radio label {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  padding-top: 2px;
}
.checkout-v3 .giftwrap-block-radio label::after {
  background-color: transparent;
}
.giftwrap-block-radio label::before, .giftwrap-block-radio label::after {
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  position: absolute;
}
.giftwrap-block-radio label::before {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  display: inline-block;
  height: 24px;
  left: 4px;
  top: -2px;
  width: 24px;
}
.giftwrap-block-radio label::after {
  background-color: #ffffff;
  display: none;
  height: 6px;
  left: 13px;
  top: 7px;
  width: 6px;
}
.giftwrap-block-radio .label-disabled {
  color: #b4b4b4;
  cursor: not-allowed;
}
.giftwrap-block-radio .gift-wrap-price {
  color: #1a1919;
  padding-left: 5px;
}
@media (min-width: 768px) {
  .giftwrap-block-radio:first-of-type:not(:only-child) {
    padding-top: 34px;
  }
  .checkout-v3 .giftwrap-block-radio:first-of-type:not(:only-child) {
    padding-top: 24px;
  }
  .giftwrap-block-radio:only-child {
    margin: 45px 0 -67px;
  }
}

.giftwrap-block-personal-message {
  border-top: 1px solid #d8d8d8;
  margin-top: 14px;
}
.checkout-v3 .giftwrap-block-personal-message {
  margin-top: 24px;
}
.giftwrap-block-personal-message .giftwrap-block-label {
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  margin-bottom: 4px;
}
.giftwrap-block-personal-message .giftwrap-block-label .optional-text {
  color: #767676;
  letter-spacing: 0.5px;
}
@media (max-width: 767px) {
  .giftwrap-block-personal-message .giftwrap-block-label.label-width {
    max-width: 150px;
  }
}
.giftwrap-block-personal-message span.error {
  display: block;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  letter-spacing: 1px;
  margin: 10px 0 8px;
}
.giftwrap-block-personal-message .giftwrap-block-textarea {
  height: 215px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap {
  bottom: 32px;
  left: 20px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 1;
}
.giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap .line {
  background-color: #d8d8d8;
  float: left;
  height: 1px;
  margin: 32px 0 31px;
  position: relative;
  width: 100%;
}
.giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap .line::before, .giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap .line::after {
  background-color: #d8d8d8;
  display: inline-block;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap .line::before {
  top: -32px;
}
.giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap .line::after {
  bottom: -32px;
}
.giftwrap-block-personal-message .giftwrap-block-textarea textarea {
  background-color: #ffffff;
  border: 0;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 32px;
  height: 215px;
  padding: 24px 20px 32px;
  position: relative;
  resize: none;
  overflow: auto;
  width: 100%;
}
.giftwrap-block-personal-message .giftwrap-block-textarea textarea::placeholder {
  color: #b4b4b4;
  font-family: "ClassGarmnd BT Italic", "Times New Roman", serif;
  font-style: italic;
  font-size: 16px;
}
.giftwrap-block-personal-message .character-counter {
  color: #7f7f7f;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: right;
}
.giftwrap-block-personal-message .buttons {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 17px;
}
@media (max-width: 767px) {
  .checkout-v3 .giftwrap-block-personal-message .buttons {
    flex-direction: column-reverse;
  }
}
.giftwrap-block-personal-message .buttons .btn {
  width: 48%;
  max-width: 292px;
}
@media (max-width: 767px) {
  .giftwrap-block-personal-message .buttons .btn {
    min-width: 0;
  }
  .checkout-v3 .giftwrap-block-personal-message .buttons .btn {
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .giftwrap-block-personal-message .buttons .btn--secondary {
    border: 0;
    color: #9a7b69;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-decoration: underline;
    text-transform: none;
  }
}
.giftwrap-block-personal-message .gift-wrapping-card-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 1.5px;
  line-height: 32px;
  margin-bottom: 24px;
  width: 100%;
  text-transform: none;
  display: none;
}
.checkout-v3 .giftwrap-block-personal-message .gift-wrapping-card-title {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .giftwrap-block-personal-message {
    padding: 24px 12px 0;
  }
  .giftwrap-block-personal-message .giftwrap-block-label {
    margin-bottom: 8px;
  }
  .giftwrap-block-personal-message .giftwrap-block-textarea textarea {
    padding: 24px 34px 32px;
  }
  .giftwrap-block-personal-message .giftwrap-block-textarea .lines-wrap {
    left: 30px;
    right: 30px;
  }
  .giftwrap-block-personal-message .gift-wrapping-card-title {
    display: block;
  }
}

.gift-wrapping-card-selection {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;
  padding-top: 17px;
  width: 100%;
}
.gift-wrapping-card-selection .content-asset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.gift-wrapping-card-selection .gift-wrapping-card-option {
  margin-bottom: 10px;
  max-width: none;
  width: 47.5%;
}
.gift-wrapping-card-selection .gift-wrapping-card-option .gift-wrapping-card-image {
  min-height: 30px;
}
.gift-wrapping-card-selection .gift-wrapping-card-option .gift-wrapping-card-name {
  color: #000000;
}
@media (min-width: 768px) {
  .gift-wrapping-card-selection {
    margin-bottom: 0;
    padding-top: 9px;
  }
  .gift-wrapping-card-selection .content-asset {
    justify-content: flex-start;
  }
  .gift-wrapping-card-selection .gift-wrapping-card-option {
    margin: 0 2.6% 25px 0;
    width: 23%;
  }
}

.gift-wrapping-card-option {
  margin-bottom: 15px;
  max-width: 45%;
}
.gift-wrapping-card-option:last-of-type {
  margin-right: 0;
}
.gift-wrapping-card-option:focus .gift-wrapping-card-image {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.gift-wrapping-card-option .gift-wrapping-card-image {
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 100%;
}
.gift-wrapping-card-option .gift-wrapping-card-image img {
  display: block;
  width: 100%;
}
.gift-wrapping-card-option .select-box {
  background-color: transparent;
  border: 0;
  display: block;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 4px 0 0 36px;
  border-radius: 50%;
  left: 50%;
  margin-left: -12px;
  position: absolute;
  top: calc(100% - 12px);
  transition: 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.gift-wrapping-card-option .select-box::before {
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  color: #ffffff;
  content: "";
  font-family: "iconfont";
  font-size: 8px;
  height: 24px;
  left: 0;
  letter-spacing: -1px;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 24px;
}
.gift-wrapping-card-option .gift-wrapping-card-description {
  padding: 26px 10px 0;
  text-align: center;
  width: 100%;
}
.gift-wrapping-card-option .gift-wrapping-card-name {
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-bottom: 0;
  text-transform: none;
}
.gift-wrapping-card-option.selected .select-box {
  background-color: transparent;
  border-color: transparent;
}
.gift-wrapping-card-option.selected .select-box::before {
  background-color: #9a7b69;
  border-color: #9a7b69;
  content: "\ea2f";
}
.gift-wrapping-card-option.selected .select-box .checkmark {
  opacity: 1;
}
@media (min-width: 768px) {
  .gift-wrapping-card-option {
    margin: 0 16px 15px 0;
    max-width: 22%;
  }
  .gift-wrapping-card-option:nth-of-type(4n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .gift-wrapping-card-option {
    max-width: 23%;
  }
}

.generic-dialog-flyout.giftwrap-flyout {
  max-width: 87%;
}
@media (min-width: 768px) {
  .generic-dialog-flyout.giftwrap-flyout {
    max-width: 720px;
  }
  .generic-dialog-flyout.giftwrap-flyout.checkout-v3 {
    max-width: 504px;
    width: auto;
  }
}

.giftwrap-container {
  background-color: #ffffff;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 96px 24px 30px;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .giftwrap-container {
    text-align: center;
    padding: 74px 40px 30px;
  }
  .checkout-v3 .giftwrap-container {
    text-align: left;
  }
  .checkout-v3 .giftwrap-container .giftwrap-title {
    margin-bottom: 24px;
  }
  .checkout-v3 .giftwrap-container .giftwrap-title h2 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
  }
  .giftwrap-container .giftwrap-title {
    margin-bottom: 40px;
  }
  .giftwrap-container .giftwrap-title .side--close {
    left: -4px;
    top: -41px;
  }
  .giftwrap-container .giftwrap-title h2 {
    font-size: 32px;
    letter-spacing: 2px;
    line-height: 40px;
    margin-bottom: 26px;
  }
  .giftwrap-container .warning-message {
    padding: 16px 16px 16px 55px;
  }
  .giftwrap-container .warning-message-icon {
    right: auto;
    left: 14px;
    top: 16px;
    width: 24px;
    height: 24px;
  }
  .giftwrap-container .warning-message-icon::before {
    width: 24px;
    height: 24px;
  }
  .giftwrap-container .warning-message-icon::after {
    font-size: 20px;
  }
  .giftwrap-container .checkall {
    margin-bottom: 38px;
  }
  .giftwrap-container .giftwrap-products {
    margin: 0 -5px 10px;
    padding-bottom: 80px;
  }
  .giftwrap-container .giftwrap-products li {
    margin-bottom: 40px;
    width: 33.333%;
    padding: 0 20px;
    max-width: 227px;
  }
  .giftwrap-container .giftwrap-products li label {
    padding-top: 15px;
  }
  .giftwrap-container .giftwrap-products li label img {
    max-width: 100px;
  }
  .giftwrap-container .giftwrap-bottom-controls {
    left: auto;
    margin-left: -40px;
    padding: 20px 40px 40px;
  }
  .giftwrap-container .giftwrap-bottom-controls .btn {
    width: 49%;
    max-width: none;
  }
}
.giftwrap-container .giftwrap-title {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}
.giftwrap-container .giftwrap-title .side--close {
  left: 0;
  top: -53px;
}
.giftwrap-container .giftwrap-title h2 {
  font-size: 20px;
  letter-spacing: 1.3px;
  line-height: 24px;
  text-transform: none;
  margin-bottom: 18px;
}
.giftwrap-container .giftwrap-title p {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.giftwrap-container .warning-message {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1a1919;
  background-color: #f6f4f2;
  padding: 20px 36px 16px 23px;
  text-align: left;
  position: relative;
  margin-top: 19px;
}
.giftwrap-container .warning-message .product-list {
  font-weight: bold;
}
.giftwrap-container .warning-message-icon {
  position: absolute;
  left: auto;
  right: 15px;
  top: 19px;
  width: 16px;
  height: 16px;
}
.giftwrap-container .warning-message-icon::before {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: block;
  content: "";
  background-color: #7f7f7f;
}
.giftwrap-container .warning-message-icon::after {
  content: "i";
  display: block;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);
}
.giftwrap-container .checkall {
  margin-bottom: 28px;
  position: relative;
  width: 100%;
}
.giftwrap-container .checkall input:focus + label::before {
  box-shadow: none;
}
.giftwrap-container .checkall label {
  display: inline-block;
  cursor: pointer;
}
.checkout-v3 .giftwrap-container .checkall label {
  padding-left: 42px;
}
.giftwrap-container .giftwrap-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 -8px;
  padding-bottom: 50px;
}
.giftwrap-container .giftwrap-products li {
  margin-bottom: 16px;
  position: relative;
  width: 50%;
  text-align: center;
  padding: 0 7px;
}
.giftwrap-container .giftwrap-products li input.focus-visible + label::before, .giftwrap-container .giftwrap-products li input.focus-visible + label::after {
  left: 50%;
  top: auto;
}
.giftwrap-container .giftwrap-products li input.focus-visible + label::before {
  bottom: 17px;
}
.giftwrap-container .giftwrap-products li label {
  background-color: #f6f4f2;
  display: block;
  padding: 20px 0 0;
  position: relative;
  cursor: pointer;
}
.giftwrap-container .giftwrap-products li label img {
  max-width: 80px;
}
.giftwrap-container .giftwrap-products li label::before {
  bottom: 20px;
  left: 50%;
  top: auto;
  transform: translateX(-50%);
}
.giftwrap-container .giftwrap-products li label .checkmark-bg {
  display: block;
  background-color: #efedea;
  height: 56px;
  width: 100%;
}
.giftwrap-container .giftwrap-bottom-controls {
  bottom: 0;
  left: 0;
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
  padding: 20px 24px;
  position: absolute;
  width: 100%;
}
.checkout-v3 .giftwrap-container .giftwrap-bottom-controls {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .checkout-v3 .giftwrap-container .giftwrap-bottom-controls {
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .giftwrap-container .giftwrap-bottom-controls {
    margin: 0;
    padding: 0 0 40px 0;
    width: calc(100% - 80px);
  }
}
.checkout-v3 .giftwrap-container .giftwrap-bottom-controls::before {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
}
@media (max-width: 767px) {
  .checkout-v3 .giftwrap-container .giftwrap-bottom-controls::before {
    height: 40px;
    top: -40px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .giftwrap-container .giftwrap-bottom-controls::before {
    height: 40px;
    top: -40px;
  }
}
.giftwrap-container .giftwrap-bottom-controls .btn {
  min-width: 10px;
  width: 47.5%;
}
@media (max-width: 767px) {
  .checkout-v3 .giftwrap-container .giftwrap-bottom-controls .btn:not(.btn--secondary) {
    flex-grow: 1;
  }
}
@media (max-width: 767px) {
  .checkout-v3 .giftwrap-container .giftwrap-bottom-controls .btn--secondary {
    border: 0;
    color: #9a7b69;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-decoration: underline;
    text-transform: none;
    width: auto;
  }
}
.giftwrap-container .vertical-tile-list {
  margin-bottom: 90px;
}
@media (max-width: 767px) {
  .giftwrap-container .checkout-tile {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .giftwrap-container .checkout-tile {
    height: 120px;
    width: 424px;
  }
}
.giftwrap-container .checkout-tile + .checkout-tile {
  margin-top: 16px;
}
.giftwrap-container .checkout-tile input:checked + label,
.giftwrap-container .checkout-tile label:hover {
  background-color: #efedea;
  border-color: #9a7b69;
}
@media (max-width: 767px) {
  .giftwrap-container .checkout-tile label {
    padding: 16px !important;
  }
}
.giftwrap-container .checkout-tile figure {
  position: absolute;
  text-align: center;
  left: 0;
}
@media (max-width: 767px) {
  .giftwrap-container .checkout-tile figure {
    height: calc(100% - 32px);
    width: 88px;
  }
}
@media (min-width: 768px) {
  .giftwrap-container .checkout-tile figure {
    height: calc(100% - 48px);
    width: 96px;
  }
}
.giftwrap-container .checkout-tile figure img {
  max-height: 100%;
  max-width: 100%;
}
.giftwrap-container .checkout-tile .tile-title {
  align-items: flex-start;
  flex-direction: column;
  margin-left: 72px;
  margin-right: 36px;
}
.giftwrap-container .checkout-tile .product-ritual {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .giftwrap-container .checkout-tile .product-title {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  .giftwrap-container .checkout-tile .product-title {
    font-size: 20px;
    letter-spacing: 0.7px;
    line-height: 32px;
  }
}

.giftwrapping-disabled {
  background-color: #f6f4f2;
  padding: 15px 20px 13px;
  text-align: left;
}
@media (max-width: 767px) {
  .giftwrapping-disabled {
    line-height: 25px;
  }
}

.giftwrapping-unavailable {
  margin: -26px 0 -40px -16px;
  padding: 18px 50px 5px 24px;
  position: relative;
  width: calc(100% + 32px);
}
@media (min-width: 768px) {
  .giftwrapping-unavailable {
    align-items: center;
    display: flex;
    margin: -18px 0 -30px -22px;
    min-height: 84px;
    padding: 18px 20px 18px 71px;
    width: calc(100% + 40px);
  }
}
.giftwrapping-unavailable .info-icon {
  position: absolute;
  top: 16px;
  right: 15px;
}
@media (min-width: 768px) {
  .giftwrapping-unavailable .info-icon {
    left: 27px;
    right: auto;
    top: 33px;
    transform: scale(1.5);
  }
}
.giftwrapping-unavailable p {
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .giftwrapping-unavailable p {
    font-size: 14px;
    max-width: 100%;
  }
  .giftwrapping-unavailable p:last-child {
    margin-bottom: 0;
  }
}

.phase-section-wrapper {
  background-image: url("../images/my-rituals/envelope-gift-reveal-bckg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.phase-section-wrapper::before {
  background: linear-gradient(to bottom, #3a383a 1%, #3a383a 90%, rgba(58, 56, 58, 0) 100%);
  content: "";
  height: 330px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.pt_order-confirmation .phase-section-wrapper::before {
  background: linear-gradient(to bottom, #3a383a 1%, #3a383a 70%, rgba(58, 56, 58, 0) 100%);
  height: 70%;
}
.phase-section-wrapper .product-grid-block {
  background-color: #efedea;
}
.phase-section-wrapper .product-grid-block .inner-wrapper h1 {
  font-size: 12px;
  letter-spacing: 1.7px;
  color: #9a7b69;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .phase-section-wrapper .product-grid-block .inner-wrapper h1 {
    margin-bottom: 20px;
  }
}
.phase-section-wrapper .product-grid-block .inner-wrapper h2 {
  font-size: 24px;
  line-height: 32px;
  color: #1a1919;
  letter-spacing: 0.5px;
}
@media (min-width: 1024px) {
  .phase-section-wrapper .product-grid-block .inner-wrapper h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.phase-section-wrapper .product-grid-block .inner-wrapper .simple-text-block .btn {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
  padding: 16px 40px;
}
@media (max-width: 1023px) {
  .phase-section-wrapper .product-grid-block .inner-wrapper .simple-text-block .btn {
    width: 100%;
    max-width: 100%;
  }
}

.phase-bck-button {
  margin: 0 auto -20px auto;
  max-width: 1400px;
  padding-top: 20px;
}
.phase-bck-button .back-button {
  color: #ffffff;
  text-decoration: initial;
  z-index: 1;
}
.phase-bck-button .back-button:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .phase-bck-button {
    margin-bottom: -10px;
    padding: 10px 20px;
    position: absolute;
  }
}

.phase-section {
  position: relative;
  padding: 88px 0 40px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .phase-section {
    padding-top: 41px;
    transition: padding-top 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
.pt_order-confirmation .phase-section {
  padding: 40px 0;
}
.phase-section.is-loading > div, .phase-section.is-loading > header {
  visibility: hidden;
}
.phase-section h2 {
  color: #ffffff;
  font-size: 40px;
  letter-spacing: 1.8px;
  line-height: 48px;
  margin-top: 10px;
  text-transform: initial;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .phase-section h2 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}
.phase-section p {
  color: #ffffff;
}
.phase-section .heading-h9 {
  color: #9a7b69;
  display: block;
}
.phase-section .reveal-base {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}
.phase-section .reveal-base.reveal {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0) !important;
  transition: opacity 2s cubic-bezier(0.32, 0.24, 0.15, 1), transform 2s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.phase-section .giftopen-image.reveal-base {
  transform: translate3d(0, 20px, 0);
}
.phase-section .giftopen-image.reveal-base.reveal {
  transform: translate3d(0, 0, 0) !important;
}

.phase-header {
  text-align: center;
}
.phase-header .phase-video-mask {
  height: 176px;
  margin: auto;
  position: relative;
  width: 176px;
}
.phase-header .phase-video-mask::after {
  background: radial-gradient(circle, rgba(58, 56, 58, 0) 0%, rgba(58, 56, 58, 0) 50%, #3a383a 70%, #3a383a 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.pt_order-confirmation .phase-header .phase-video-mask {
  margin: -20px auto 0 auto;
}
.phase-header .phase-icon {
  height: 176px;
  width: 176px;
}
.phase-header .phase-video {
  height: 100%;
  width: 100%;
}

.phase-section-open {
  z-index: 2;
}
.phase-section-open .phase-header,
.phase-section-open .phase-journey {
  filter: blur(6px);
}
@media (max-width: 767px) {
  .phase-section-open:not(.phase-section-double) .slick-list {
    padding: 0 !important;
  }
}
@media (max-width: 420px) {
  .phase-section-open:not(.phase-section-double) .giftopen-boxes .slick-list,
  .phase-section-open:not(.phase-section-double) .giftopen-boxes .slick-track,
  .phase-section-open:not(.phase-section-double) .giftopen-boxes .slick-slide,
  .phase-section-open:not(.phase-section-double) .giftopen-boxes .giftopen-single-box {
    max-width: 420px;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .phase-section-open.phase-section-double .slick-list {
    left: -10% !important;
  }
}
@media (max-width: 420px) {
  .phase-section-open .giftopen-boxes {
    padding-top: 113px;
  }
}
@media (max-width: 767px) {
  .phase-section-open .giftopen-boxes {
    padding-top: 191px;
  }
}
.phase-section-open .slick-list {
  overflow: visible;
}
@media (max-width: 767px) {
  .phase-section-open .slick-slide:not(.slick-current) .giftopen-single-box {
    opacity: 0;
  }
}
@media (max-width: 767px) {
  .phase-section-open {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .phase-section-double .phase-header {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
@media (max-width: 767px) {
  .phase-section-double .giftopen-boxes {
    padding-top: 190px;
  }
}
@media (max-width: 767px) {
  .phase-section-double .giftopen-boxes-inner {
    display: initial !important;
  }
}
@media (min-width: 768px) {
  .phase-section-double .giftopen-boxes-inner > div {
    margin: 0 20px;
    width: calc(50% - 40px);
  }
}
.phase-section-double .slick-slide {
  margin: 0 8px;
}
@media (max-width: 767px) {
  .phase-section-double .slick-list {
    left: 0;
    transition: left 1.2s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (max-width: 420px) {
  .phase-section-double.phase-section-open .giftopen-boxes {
    padding-top: 289px;
  }
}
@media (max-width: 767px) {
  .phase-section-double.phase-section-open .giftopen-boxes {
    padding-top: 367px;
  }
  .phase-section-double.phase-section-open .phase-journey {
    margin-top: 570px;
  }
}
.phase-section-double.phase-section-open .slick-track {
  transform: initial !important;
}
@media (max-width: 767px) {
  .phase-section-double .giftopen-single-box {
    height: 100%;
    margin-right: 16px;
    max-width: initial;
    transition: width 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 100%;
  }
}
@media (max-width: 420px) {
  .phase-section-double .giftopen-single-box-open {
    max-width: 100vw;
  }
}
@media (max-width: 767px) {
  .phase-section-double .giftopen-single-box-open {
    margin-left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    z-index: 5;
  }
}
@media (min-width: 768px) {
  .phase-section-double .giftopen-boxes-line::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate3d(-50%, 0, 0);
    height: 94%;
    width: 1px;
    background-color: #deca87;
  }
}

.giftopen-boxes {
  position: relative;
}
.giftopen-boxes::-webkit-scrollbar {
  display: none;
}
.giftopen-boxes .giftopen-single-box {
  display: flex !important;
  flex-direction: column;
  height: auto;
}
@media (max-width: 767px) {
  .giftopen-boxes .giftopen-single-box {
    max-width: 327px;
  }
}
@media (min-width: 768px) {
  .giftopen-boxes .giftopen-single-box {
    flex: 1;
    height: 550px;
    transition: height 0.5s ease-in-out;
  }
}
@media (min-width: 768px) {
  .giftopen-boxes .giftopen-single-box.giftopen-single-box-open {
    height: 811px;
  }
}
.giftopen-boxes .giftopen-single-box-open .giftopen-heading {
  filter: blur(6px);
}
@media (max-width: 767px) {
  .giftopen-boxes .giftopen-single-box-open .giftopen-heading {
    position: absolute;
  }
}
.giftopen-boxes .giftopen-single-box-open .giftopen-box {
  transform: scale(1.1);
}
@media (max-width: 420px) {
  .giftopen-boxes .giftopen-single-box-open .giftopen-box {
    margin-top: 0;
    max-width: 420px;
    transform: scale(1.01);
  }
}
.giftopen-boxes .giftopen-single-box-open .giftopen-image {
  transform: translate3d(-5%, -265px, 0);
  width: 100%;
}
.giftopen-boxes .giftopen-single-box-open .giftopen-envelope-border {
  opacity: 0;
  visibility: hidden;
}
.giftopen-boxes .giftopen-single-box-open .giftopen-icon.icon-gift-filled {
  transform: translate3d(-50%, -150px, 0);
  visibility: hidden;
  opacity: 0;
}
.giftopen-boxes .giftopen-single-box-open .giftopen-ribbon-single-1 {
  transform: translate3d(50%, 0, 0);
}
.giftopen-boxes .giftopen-single-box-open .giftopen-ribbon-single-2 {
  transform: translate3d(-150%, 0, 0) rotate(-6deg);
}
.giftopen-boxes .giftopen-single-box-open .giftopen-ribbon-single-3 {
  transform: translate3d(50%, 0, 0) rotate(6deg);
}
.giftopen-boxes .giftopen-single-box-open .giftopen-envelope-image {
  filter: brightness(0) invert(1);
}
@media (min-width: 768px) {
  .giftopen-boxes .giftopen-single-box-open .gift-reveal-body {
    height: unset;
    z-index: 1;
  }
}
.giftopen-boxes .giftopen-boxes-inner {
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .giftopen-boxes .giftopen-boxes-inner .slick-track {
    display: flex !important;
  }
  .giftopen-boxes .giftopen-boxes-inner .slick-slide {
    height: inherit !important;
  }
  .giftopen-boxes .giftopen-boxes-inner .slick-slide > div {
    height: 100%;
  }
}
.giftopen-boxes .giftopen-heading {
  max-width: 640px;
  margin: 0 auto 47px;
  text-align: center;
}
.giftopen-boxes .giftopen-heading .heading-h9 {
  color: #deca87;
}
.giftopen-boxes .giftopen-box {
  position: relative;
}
@media (max-width: 767px) {
  .giftopen-boxes .giftopen-box {
    background-color: #ffffff;
    margin: 0;
    transition: transform 0.8s cubic-bezier(0.32, 0.24, 0.15, 1), margin-top 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 100%;
  }
}
@media (min-width: 768px) {
  .giftopen-boxes .giftopen-box {
    margin: auto auto 0;
    transition: transform 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 327px;
  }
}
.giftopen-boxes .giftopen-box .giftopen-icon {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  padding: 18px 8.1px;
  font-size: 23px;
  color: #a7884d;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 4;
  width: 64px;
  height: 64px;
  transition: visibility 1.5s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 1.5s cubic-bezier(0.32, 0.24, 0.15, 1), transform 1.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.giftopen-boxes .giftopen-box .giftopen-icon::before {
  border: 1px solid #a7884d;
  border-radius: 50%;
  padding: 11px 12px;
}
.giftopen-boxes .giftopen-box .giftopen-btn {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  transition: visibility 1.2s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 1.2s cubic-bezier(0.32, 0.24, 0.15, 1);
  z-index: 1;
}
.giftopen-boxes .giftopen-envelope {
  position: relative;
  min-height: 344px;
}
.giftopen-boxes .giftopen-envelope img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.giftopen-boxes .giftopen-envelope .giftopen-envelope-image {
  width: 435px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  clip-path: inset(0 54px 0 54px);
}
@media (max-width: 420px) {
  .giftopen-boxes .giftopen-envelope .giftopen-envelope-image {
    width: 100%;
    clip-path: initial;
    object-fit: cover;
  }
}
@media screen and (max-width: 350px) {
  .giftopen-boxes .giftopen-envelope {
    min-height: 310px;
  }
}
.giftopen-boxes .giftopen-envelope-border {
  transition: visibility 1s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.giftopen-boxes .giftopen-image {
  position: absolute;
  top: -16px;
  left: 5%;
  width: 90%;
  height: 100%;
  overflow: hidden;
  background-color: #453f3f;
  transition: transform 1.2s cubic-bezier(0.32, 0.24, 0.15, 1), width 1.2s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.giftopen-boxes .giftopen-image img {
  object-fit: cover;
}
.giftopen-boxes .giftopen-close-btn {
  position: absolute;
  left: 24px;
  top: 24px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  background-color: #ffffff;
  transition: visibility 1.2s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 1.2s cubic-bezier(0.32, 0.24, 0.15, 1);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  width: 43.65px;
  height: 43.65px;
}
.giftopen-boxes .giftopen-close-btn.icon-close::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 34px;
  width: 20px;
}
.giftopen-boxes .giftopen-btn-show {
  visibility: visible;
  opacity: 1;
}
.giftopen-boxes .giftopen-btn-hide {
  visibility: hidden;
  opacity: 0;
}
.giftopen-boxes .giftopen-timer {
  position: absolute;
  top: 69%;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  padding: 8px 10px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 1;
  background-color: #ffffff;
  z-index: 1;
  transition: transform 1.2s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.giftopen-boxes .giftopen-timer .icon-duration {
  margin-right: 8px;
  font-size: 10.61px;
  width: 12px;
  height: 12px;
}
.giftopen-boxes .giftopen-timer-show {
  transform: translate3d(0, 0, 0);
}
.giftopen-boxes .giftopen-no-timer {
  display: none;
}
.giftopen-boxes .giftopen-ribbon {
  position: absolute;
  top: 81px;
  width: 100%;
  height: 200px;
  overflow: hidden;
  z-index: 1;
}
.giftopen-boxes .giftopen-ribbon-single {
  position: absolute;
  height: 24px;
  width: 408px;
  left: 50%;
  background-image: url("../images/my-rituals/envelope-track.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 1px 8px -4px rgba(0, 0, 0, 0.5);
  transition: transform 1.7s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.giftopen-boxes .giftopen-ribbon-single-1 {
  transform: translate3d(-50%, 0, 0);
  z-index: 3;
}
.giftopen-boxes .giftopen-ribbon-single-2 {
  top: 25px;
  transform: translate3d(-50%, 0, 0) rotate(-6deg);
  z-index: 2;
}
.giftopen-boxes .giftopen-ribbon-single-3 {
  top: 32px;
  transform: translate3d(-50%, 0, 0) rotate(6deg);
  z-index: 1;
}

.gift-reveal-body {
  display: none;
  background-color: #ffffff;
  margin-bottom: -300px;
  opacity: 0;
  padding: 0 24px 24px;
  position: relative;
  text-align: center;
  top: 20px;
  transform: translate3d(0, -300px, 0);
  z-index: 1;
}
@media (max-width: 420px) {
  .gift-reveal-body {
    transform: translate3d(0, -270px, 0);
    margin-bottom: -270px;
  }
}
@media (min-width: 768px) {
  .gift-reveal-body {
    display: block;
    height: 300px;
    margin-bottom: unset;
    transition: height 0.5s ease-in-out;
    z-index: -1;
  }
}
.gift-reveal-body .gift-reveal-inner p {
  color: #453f3f;
}
.gift-reveal-body .gift-reveal-text {
  margin-bottom: 40px;
}
.gift-reveal-body .gift-reveal-title {
  margin: 5px 0;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
}
.gift-reveal-body .gift-reveal-product-info {
  display: block;
  margin-bottom: 20px;
  font-size: 12px;
}
.gift-reveal-body h3 {
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
}
.gift-reveal-body p {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
}
.gift-reveal-body hr {
  position: relative;
  width: 144px;
  height: 1px;
  display: block;
  margin: -12px auto 24px;
  padding: 12px 0;
  border: 0;
  background-color: #ffffff;
}
.gift-reveal-body hr::before {
  background-image: linear-gradient(90deg, rgba(26, 25, 25, 0) 0%, rgba(26, 25, 25, 0.4) 50%, rgba(26, 25, 25, 0) 100%);
  content: "";
  height: 1px;
  left: 50%;
  top: 11px;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  z-index: 1;
}
.gift-reveal-body hr::after {
  background-color: #ffffff;
  content: attr(data-text);
  font-size: 14px;
  left: 50%;
  letter-spacing: 0.4px;
  padding: 0 14px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.gift-reveal-body .member-card {
  width: initial;
}
.gift-reveal-body .store-google-play {
  margin: 0 auto 24px auto;
}
.gift-reveal-body .inline-alert {
  text-align: left;
}

.phase-journey {
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .phase-journey {
    padding: 24px 0;
    text-align: center;
  }
}
.giftopen-boxes + .phase-journey {
  margin-top: 40px;
}
.phase-journey .phase-timeline {
  height: 128px;
  position: relative;
  width: 100%;
}
.phase-journey .phase-timeline .timeline-markers {
  position: relative;
}
.phase-journey .phase-timeline .timeline-markers.phase-gratitude span.prev-phase {
  display: none;
}
.phase-journey .phase-timeline .timeline-markers.phase-soulmate span.next-phase {
  display: none;
}
.phase-journey .phase-timeline .timeline-markers span {
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  z-index: 10;
}
.phase-journey .phase-timeline .timeline-markers span.current-phase {
  background-color: #deca87;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}
@media (max-width: 767px) {
  .phase-journey .phase-timeline .timeline-markers span.current-phase {
    top: 78px;
  }
}
.phase-journey .phase-timeline .timeline-markers span.current-phase::before, .phase-journey .phase-timeline .timeline-markers span.current-phase::after {
  border-radius: 50%;
  border: 1px solid #deca87;
  content: "";
  height: 18px;
  left: -1px;
  position: absolute;
  top: -1px;
  transform-origin: center;
  width: 18px;
}
.phase-journey .phase-timeline .timeline-markers span.current-phase::before {
  animation: pulsating-border 3s linear infinite;
}
.phase-journey .phase-timeline .timeline-markers span.current-phase::after {
  animation: pulsating-border 3s linear infinite 0.8s;
}
.phase-journey .phase-timeline .timeline-markers span.next-phase {
  background-color: #1a1919;
  border: 1px solid #b4b4b4;
  left: calc(50% + 300px);
  top: 14px;
}
@media (max-width: 767px) {
  .phase-journey .phase-timeline .timeline-markers span.next-phase {
    display: none;
  }
}
@media (min-width: 1440px) {
  .phase-journey .phase-timeline .timeline-markers span.next-phase {
    left: calc(50% + 440px);
    top: 88px;
  }
}
.phase-journey .phase-timeline .timeline-markers span.prev-phase {
  background-color: #deca87;
  left: calc(50% - 330px);
  top: 40px;
}
@media (max-width: 767px) {
  .phase-journey .phase-timeline .timeline-markers span.prev-phase {
    display: none;
  }
}
@media (min-width: 1440px) {
  .phase-journey .phase-timeline .timeline-markers span.prev-phase {
    left: calc(50% - 440px);
    top: -2px;
  }
}
.phase-journey .phase-timeline .timeline-progress,
.phase-journey .phase-timeline .timeline-path {
  background-position: center bottom;
  background-repeat: repeat-x;
  display: block;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  width: 100%;
}
.phase-journey .phase-timeline .timeline-progress-mask {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 50%;
}
.phase-journey .phase-timeline .timeline-progress-mask .timeline-progress {
  z-index: 2;
  width: 200%;
}
@media (max-width: 767px) {
  .phase-journey .phase-timeline .timeline-progress-mask .timeline-progress {
    background-image: url("../images/my-rituals/my-rituals-phase-progress-line-solid-mobile.svg");
  }
}
@media (min-width: 768px) {
  .phase-journey .phase-timeline .timeline-progress-mask .timeline-progress {
    background-image: url("../images/my-rituals/my-rituals-phase-progress-line-solid-desktop.svg");
  }
}
.phase-journey .phase-timeline .timeline-path {
  z-index: 1;
}
@media (max-width: 767px) {
  .phase-journey .phase-timeline .timeline-path {
    background-image: url("../images/my-rituals/my-rituals-phase-progress-line-dashed-mobile.svg");
  }
}
@media (min-width: 768px) {
  .phase-journey .phase-timeline .timeline-path {
    background-image: url("../images/my-rituals/my-rituals-phase-progress-line-dashed-desktop.svg");
  }
}
.phase-journey .heading-h9 {
  color: #deca87;
}
@media (max-width: 767px) {
  .phase-journey .heading-h9,
  .phase-journey h2,
  .phase-journey p {
    padding: 0 24px;
  }
}
.phase-journey p + .btn {
  margin-top: 14px;
}
@media (max-width: 767px) {
  .phase-journey .heading-h9 + h2 {
    margin-top: 8px;
  }
}

@keyframes pulsating-border {
  0% {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
  35% {
    opacity: 0.6;
    transform: scale3d(1.6, 1.6, 1);
  }
  100% {
    opacity: 0;
    transform: scale3d(2.5, 2.5, 1);
  }
}
.three-tile-phase.three-tile-block {
  display: block;
  margin-bottom: 0;
  background-color: initial;
}
@media (max-width: 767px) {
  .three-tile-phase.three-tile-block {
    padding: 40px 0;
  }
}
@media (min-width: 768px) {
  .three-tile-phase.three-tile-block {
    padding: 50px 0 80px;
  }
}
.three-tile-phase.three-tile-block h2 {
  margin-bottom: 24px;
  padding: 0 20px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: initial;
  color: #ffffff;
}
@media (max-width: 767px) {
  .three-tile-phase.three-tile-block h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.8px;
  }
}
@media (min-width: 768px) {
  .three-tile-phase.three-tile-block .inner-wrapper {
    justify-content: center;
  }
}
.three-tile-phase.three-tile-block .three-tile-box {
  max-width: 274px;
  margin-right: 16px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .three-tile-phase.three-tile-block .three-tile-box {
    height: 100%;
  }
}
.three-tile-phase.three-tile-block .three-tile-box .text-holder {
  justify-content: unset;
}
.three-tile-phase.three-tile-block .image-holder {
  padding-bottom: 54.75%;
}
.three-tile-phase.three-tile-block .text-holder {
  padding: 24px;
}
.three-tile-phase.three-tile-block .text-holder .heading-h8 {
  letter-spacing: 0.4px;
}
@media (max-width: 767px) {
  .three-tile-phase.three-tile-block .text-holder .heading-h8 {
    font-size: 16px;
    line-height: 24px;
  }
}
.three-tile-phase.three-tile-block .text-holder p {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.three-tile-phase.three-tile-block .text-holder a {
  font-size: 14px;
}
.three-tile-phase.three-tile-block .slick-list {
  padding: 0 20% 0 24px !important;
}
.three-tile-phase.three-tile-block .slick-track {
  display: flex !important;
}
.three-tile-phase.three-tile-block .slick-slide {
  height: inherit !important;
  margin-right: 16px;
}
.three-tile-phase.three-tile-block .slick-slide > div {
  height: 100%;
}
.three-tile-phase.three-tile-block.three-tile-phase-bckg {
  background-color: #242222;
}

.redeem-wrapper {
  align-items: center;
  background-color: #f6f4f2;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}
.redeem-wrapper .redeem-product {
  align-items: center;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto 24px;
  padding: 56px 24px 24px;
  position: relative;
  width: 780px;
}
.redeem-wrapper .redeem-product.pp-out-of-stock .oos-icon {
  height: 32px;
  width: 32px;
}
.redeem-wrapper .redeem-product.pp-out-of-stock .oos-icon::after {
  top: 13px;
}
@media (max-width: 1023px) {
  .redeem-wrapper .redeem-product {
    align-items: flex-end;
    border-left-color: transparent;
    border-right-color: transparent;
    padding: 16px;
    width: auto;
  }
}
.redeem-wrapper .redeem-product .product-info {
  align-items: center;
  display: flex;
  flex: 1;
  margin-left: 24px;
}
@media (max-width: 1023px) {
  .redeem-wrapper .redeem-product .product-info {
    align-items: flex-start;
    flex-direction: column;
    margin-left: 16px;
    max-width: 70%;
  }
}
.redeem-wrapper .redeem-product .product-info .product-info-inner {
  margin-right: 24px;
  text-align: left;
}
@media (max-width: 1023px) {
  .redeem-wrapper .redeem-product .product-info .product-info-inner {
    margin-right: 0;
  }
}
.redeem-wrapper .redeem-product .product-name {
  color: #1a1919;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  pointer-events: none;
}
.redeem-wrapper .redeem-product .product-name a {
  text-decoration: none;
}
.redeem-wrapper .redeem-product .product-collection {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
  text-transform: uppercase;
}
.redeem-wrapper .redeem-product .short-description {
  color: #626262;
  font-size: 14px;
  margin-bottom: 0;
}
.redeem-wrapper .redeem-product .product-price {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
@media (max-width: 1023px) {
  .redeem-wrapper .redeem-product .product-price {
    margin: 0;
  }
}
.redeem-wrapper .redeem-product .product-price .product-sales-price {
  color: #1a1919;
  font-size: 18px;
}
.redeem-wrapper .redeem-product .product-price .product-beforediscount-price {
  color: #7f7f7f;
  font-size: 14px;
  text-decoration: line-through;
}
.redeem-wrapper .redeem-product .product-price .base-price-block {
  margin-top: 8px;
}
.redeem-wrapper .redeem-product .product-image {
  height: 64px;
  margin-right: 16px;
  position: relative;
  width: 64px;
}
@media (max-width: 1023px) {
  .redeem-wrapper .redeem-product .product-image {
    margin-right: 16px;
  }
}
.redeem-wrapper .redeem-product .product-image img {
  width: 100%;
}
.shopping-cart-block .redeem-wrapper .redeem-product {
  border-left: none;
  border-right: none;
  padding: 56px 40px 16px;
  width: 100%;
}
@media (max-width: 1023px) {
  .shopping-cart-block .redeem-wrapper .redeem-product {
    padding: 16px;
  }
}
.redeem-wrapper .btn {
  min-width: 0;
  padding: 16px 32px;
}
.redeem-wrapper .btn-out-of-stock {
  background-color: #d8d8d8;
  border: none;
}

.personal-promotion-content {
  padding: 48px 0 24px 0;
}
.personal-promotion-content .ui-dialog-titlebar .ui-dialog-titlebar-close {
  right: 60px;
  top: -18px;
}
.personal-promotion-content .ui-dialog-titlebar .ui-icon-closethick {
  border-radius: 50px;
  border: 1px solid rgba(26, 25, 25, 0.16);
  padding: 10px 13px;
}
.personal-promotion-content .ui-dialog-titlebar .ui-icon-closethick::before {
  color: #1a1919;
  display: block;
  margin: -3px 0 0 1px;
}
.personal-promotion-content .redeem-wrapper {
  background-color: transparent;
  padding-bottom: 16px;
}
.personal-promotion-content .redeem-wrapper .redeem-product {
  margin-bottom: 0;
  padding: 21px 24px 15px;
  width: 100%;
}
.personal-promotion-content .redeem-wrapper .product-collection {
  margin-bottom: 6px;
}
.personal-promotion-content .redeem-wrapper .product-image {
  height: 64px;
  margin-right: 15px;
  width: 64px;
}
.personal-promotion-content .redeem-wrapper .product-name {
  font-size: 20px;
  margin-bottom: 2px;
}
.personal-promotion-content .redeem-wrapper .product-price {
  align-items: flex-end;
  flex-direction: column-reverse;
}
.personal-promotion-content .redeem-wrapper .product-price .product-sales-price,
.personal-promotion-content .redeem-wrapper .product-price .product-beforediscount-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.personal-promotion-content .redeem-wrapper .product-price .product-beforediscount-price {
  font-size: 16px;
}
.personal-promotion-content .redeem-wrapper .product-price .product-sales-price {
  font-size: 20px;
  line-height: 24px;
}
.personal-promotion-content .redeem-wrapper .short-description {
  line-height: 24px;
  margin-bottom: 0;
}
.personal-promotion-content .redeem-wrapper .mini-cart-pricing {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 24px;
}
.personal-promotion-content .popup-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.personal-promotion-content .popup-buttons .remove-gwp-button {
  line-height: 1.5;
}
.personal-promotion-content .popup-buttons .keep-gwp-button {
  color: #9a7b69;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: lighter;
  margin-top: 30px;
  text-decoration: underline;
}
.personal-promotion-content .popup-text {
  text-align: center;
}
.personal-promotion-content .popup-text-main,
.personal-promotion-content .popup-text-description {
  color: #1a1919;
}
.personal-promotion-content .popup-text-main {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
.personal-promotion-content .popup-text-description {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 24px;
  margin: 0 auto;
  padding-bottom: 38px;
  width: 75%;
}

.personal-promotion-content {
  padding: 48px 0 24px 0;
}
@media (max-width: 1023px) {
  .personal-promotion-content {
    padding: 40px 0 16px 0;
  }
}
.personal-promotion-content .close-toaster::before {
  color: #1a1919;
}
.personal-promotion-content .toaster-content {
  flex-direction: column;
}
.personal-promotion-content .ui-dialog-titlebar .ui-dialog-titlebar-close {
  right: 60px;
  top: -18px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .ui-dialog-titlebar .ui-dialog-titlebar-close {
    right: 35px;
    top: -25px;
  }
}
.personal-promotion-content .ui-dialog-titlebar .ui-icon-closethick {
  border-radius: 50px;
  border: 1px solid rgba(26, 25, 25, 0.16);
  height: 48px;
  padding: 16px 12px;
  width: 48px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .ui-dialog-titlebar .ui-icon-closethick {
    border: none;
  }
}
.personal-promotion-content .redeem-wrapper {
  background-color: transparent;
  padding-bottom: 16px;
}
.personal-promotion-content .redeem-wrapper .redeem-product {
  margin-bottom: 0;
  padding: 21px 24px 15px;
  width: 100%;
}
.personal-promotion-content .redeem-wrapper .product-info {
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .personal-promotion-content .redeem-wrapper .product-info {
    flex-direction: row;
  }
}
.personal-promotion-content .redeem-wrapper .product-collection {
  margin-bottom: 6px;
}
.personal-promotion-content .redeem-wrapper .product-image {
  height: 64px;
  margin-right: 15px;
  width: 64px;
}
.personal-promotion-content .redeem-wrapper .product-name {
  font-size: 20px;
  margin-bottom: 2px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .redeem-wrapper .product-name {
    font-size: 18px;
  }
}
.personal-promotion-content .redeem-wrapper .product-price {
  align-items: flex-end;
  flex-direction: column-reverse;
}
.personal-promotion-content .redeem-wrapper .product-price .product-sales-price,
.personal-promotion-content .redeem-wrapper .product-price .product-beforediscount-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.personal-promotion-content .redeem-wrapper .product-price .product-beforediscount-price {
  font-size: 16px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .redeem-wrapper .product-price .product-beforediscount-price {
    font-size: 14px;
  }
}
.personal-promotion-content .redeem-wrapper .product-price .product-sales-price {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .redeem-wrapper .product-price .product-sales-price {
    font-size: 18px;
  }
}
.personal-promotion-content .redeem-wrapper .short-description {
  line-height: 24px;
  margin-bottom: 0;
}
.personal-promotion-content .redeem-wrapper .mini-cart-pricing {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 24px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .redeem-wrapper .mini-cart-pricing {
    font-size: 12px;
    line-height: 16px;
  }
}
.personal-promotion-content .popup-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .personal-promotion-content .popup-buttons {
    margin: 0 auto;
  }
}
.personal-promotion-content .popup-buttons .remove-gwp-button {
  line-height: 1.5;
  padding: 16px 32px;
}
.personal-promotion-content .popup-buttons .keep-gwp-button {
  color: #9a7b69;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 24px;
  margin-top: 30px;
  text-decoration: underline;
}
@media (max-width: 1023px) {
  .personal-promotion-content .popup-buttons .keep-gwp-button {
    line-height: 16px;
    margin-top: 16px;
  }
}
.personal-promotion-content .popup-text {
  text-align: center;
}
@media (max-width: 1023px) {
  .personal-promotion-content .popup-text {
    margin: 0 auto;
  }
}
.personal-promotion-content .popup-text-main,
.personal-promotion-content .popup-text-description {
  color: #1a1919;
}
.personal-promotion-content .popup-text-main {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
@media (max-width: 1023px) {
  .personal-promotion-content .popup-text-main {
    font-size: 18px;
  }
}
.personal-promotion-content .popup-text-description {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 24px;
  margin: 0 auto;
  padding-bottom: 38px;
  width: 75%;
}
@media (max-width: 1023px) {
  .personal-promotion-content .popup-text-description {
    font-weight: normal;
    padding-bottom: 8px;
  }
}

.approaching-promo-header {
  margin: 33px 0 9px;
  letter-spacing: 3px;
  font-size: 12px;
  color: #453f3f;
  text-transform: uppercase;
}

.approaching-promos-wrapper,
.approaching-promo {
  margin: 0 auto;
}
.approaching-promos-wrapper .promo-product-details,
.approaching-promo .promo-product-details {
  background: #fcfaf7;
  padding: 25px 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.approaching-promos-wrapper .col-text,
.approaching-promo .col-text {
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.approaching-promos-wrapper .product-name,
.approaching-promo .product-name {
  max-width: 100%;
}
.approaching-promos-wrapper .product-name .ecom-title,
.approaching-promo .product-name .ecom-title {
  font-size: 11px;
  letter-spacing: 1.65px;
  line-height: 24px;
  color: #9a7b69;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .approaching-promos-wrapper .product-name .ecom-title,
  .approaching-promo .product-name .ecom-title {
    font-size: 12px;
    letter-spacing: 1.7px;
    line-height: 16px;
    margin-bottom: 4px;
  }
}
.approaching-promos-wrapper .product-name .gift-quantity,
.approaching-promo .product-name .gift-quantity {
  font-size: 12px;
  letter-spacing: 1px;
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  margin-top: 20px;
}
.approaching-promos-wrapper .product-name .bonus-item-actions .change-bonus-product-btn,
.approaching-promo .product-name .bonus-item-actions .change-bonus-product-btn {
  color: #9a7b69;
}
.approaching-promos-wrapper .approaching-promo-callout,
.approaching-promo .approaching-promo-callout {
  font-size: 14px;
  letter-spacing: 0.88px;
  color: #453f3f;
  line-height: 20px;
  display: block;
  max-width: 100%;
}
@media (min-width: 768px) {
  .approaching-promos-wrapper .approaching-promo-callout,
  .approaching-promo .approaching-promo-callout {
    font-size: 16px;
    letter-spacing: 1px;
  }
}
.approaching-promos-wrapper.tiered-gwp-choose .product-image .dm-responsive-image,
.approaching-promo.tiered-gwp-choose .product-image .dm-responsive-image {
  filter: grayscale(1);
}
.approaching-promos-wrapper.tiered-gwp-choose .promo-product-name .ecom-title,
.approaching-promo.tiered-gwp-choose .promo-product-name .ecom-title {
  color: #7f7f7f;
}
.approaching-promos-wrapper.tiered-gwp-choose .promo-product-name .change-bonus-product-btn,
.approaching-promo.tiered-gwp-choose .promo-product-name .change-bonus-product-btn {
  color: #7f7f7f;
}
.approaching-promos-wrapper.tiered-gwp-choose::after,
.approaching-promo.tiered-gwp-choose::after {
  filter: grayscale(1);
}

@media (min-width: 768px) {
  .mini-cart-empty .approaching-promos-wrapper .ecom-title {
    font-size: 11px;
    letter-spacing: 1.65px;
    line-height: 24px;
    margin-bottom: 0;
  }
}
.mini-cart-empty .approaching-promos-wrapper .product-image {
  width: 100px;
  height: 100px;
  position: relative;
}
.mini-cart-empty .approaching-promos-wrapper .product-image img {
  padding: 0 10px;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .approaching-promo-empty-cart {
  text-align: center;
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .promo-product-details {
  padding: 30px;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .mini-cart-empty .approaching-promos-wrapper.single-promo .promo-product-details {
    min-height: 280px;
  }
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .product-image {
  width: auto;
  height: auto;
  margin-bottom: 20px;
  flex-grow: 2;
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .product-image img {
  max-width: 100%;
  position: relative;
  top: 0;
  transform: none;
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .col-text {
  text-align: center;
  margin-top: auto;
  flex-grow: 1;
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .product-name {
  margin-top: auto;
}
.mini-cart-empty .approaching-promos-wrapper.single-promo .ecom-title {
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
  margin-bottom: 10px;
}

.wrapper--minicart__list .approaching-promo {
  margin-top: 20px;
}
.wrapper--minicart__list .approaching-promo .promo-product-details {
  padding-left: 0;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .wrapper--minicart__list .approaching-promo .promo-product-details {
    padding: 25px 60px 25px 0;
  }
}
.wrapper--minicart__list .approaching-promo .ecom-title {
  font-size: 11px;
  color: #886655;
  letter-spacing: 1.65px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.wrapper--minicart__list .approaching-promo .gift-label {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 2.63px;
  color: #1a1919;
}
.wrapper--minicart__list .approaching-promo .approaching-promo-callout {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .wrapper--minicart__list .approaching-promo .approaching-promo-callout {
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 20px;
  }
}
.wrapper--minicart__list .approaching-promo .col-radio {
  display: none;
}
.wrapper--minicart__list .tiered-gwp.unselected {
  display: none;
}
.wrapper--minicart__list .tiered-gwp.unselected ~ .tiered-gwp {
  margin-top: 20px;
  border-top: 0;
}
.wrapper--minicart__list .premium-gift {
  margin-top: 0;
  border-top: 1px solid rgba(136, 102, 85, 0.2);
}

.approaching-promo-empty-cart {
  background-color: #fcfaf7;
  margin-bottom: 20px;
}
.approaching-promo-empty-cart .product-image img {
  max-width: 100%;
  max-height: 100%;
}

.cart-empty .gwp-cart-banner {
  margin-bottom: 20px;
  padding: 30px 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .cart-empty .gwp-cart-banner {
    padding: 20px 20px 20px 36px;
  }
}
.cart-empty .approaching-promos-wrapper .product-image {
  width: 100%;
  max-width: 130px;
  height: auto;
}
@media (max-width: 767px) {
  .cart-empty .approaching-promos-wrapper.single-promo .approaching-promo-empty-cart {
    text-align: center;
  }
  .cart-empty .approaching-promos-wrapper.single-promo .promo-product-details {
    padding: 30px;
    flex-direction: column;
    justify-content: center;
  }
  .cart-empty .approaching-promos-wrapper.single-promo .product-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    flex-grow: 2;
  }
  .cart-empty .approaching-promos-wrapper.single-promo .col-text {
    text-align: center;
    margin-top: auto;
    flex-grow: 1;
  }
  .cart-empty .approaching-promos-wrapper.single-promo .product-name {
    margin-top: auto;
  }
  .cart-empty .approaching-promos-wrapper.single-promo .ecom-title {
    font-size: 15px;
    letter-spacing: 5px;
    line-height: 23px;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .cart-empty .approaching-promos-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .cart-empty .approaching-promos-wrapper .approaching-promo-empty-cart {
    flex-basis: 400px;
    margin: 20px;
  }
  .cart-empty .approaching-promos-wrapper .promo-product-details {
    min-height: 280px;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }
  .cart-empty .approaching-promos-wrapper .product-image {
    width: 100%;
    max-width: none;
    height: auto;
    margin-bottom: 20px;
    flex-grow: 2;
  }
  .cart-empty .approaching-promos-wrapper .col-text {
    text-align: center;
    flex-grow: 1;
  }
  .cart-empty .approaching-promos-wrapper .product-name {
    margin-top: auto;
  }
  .cart-empty .approaching-promos-wrapper .approaching-promo-callout {
    font-size: 16px;
  }
}

.cart-items-form {
  position: relative;
}
@media (max-width: 767px) {
  .cart-items-form fieldset {
    min-width: 0;
  }
}
.cart-items-form .approaching-promo {
  min-height: 135px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
  background-color: #fcfaf7;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .cart-items-form .approaching-promo {
    padding-right: 20px;
    min-height: 160px;
  }
}
@media (min-width: 1024px) {
  .cart-items-form .approaching-promo {
    padding-right: 30px;
  }
}
.cart-items-form .approaching-promo.premium-gift {
  margin-top: 0;
  border-top: 1px solid rgba(136, 102, 85, 0.2);
}
.cart-items-form .promo-product-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
}
.cart-items-form .promo-product-details .col-text {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 767px) {
  .cart-items-form .promo-product-details .col-text {
    padding: 0 8px 0 16px;
    max-width: 44%;
  }
}
@media (max-width: 374px) {
  .cart-items-form .promo-product-details .col-text {
    padding: 0 10px 0 16px;
    max-width: 41%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cart-items-form .promo-product-details .col-text {
    padding: 0 0 0 35px;
    max-width: 33%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .cart-items-form .promo-product-details .col-text {
    padding: 0 0 0 23px;
    max-width: 43%;
  }
}
@media (min-width: 1024px) {
  .cart-items-form .promo-product-details .col-text {
    padding: 0 0 0 13px;
    max-width: 35%;
  }
}
@media (min-width: 1160px) {
  .cart-items-form .promo-product-details .col-text {
    padding: 0 0 0 37px;
    max-width: 49%;
  }
}
@media screen and (min-width: 1160px) and (-ms-high-contrast: active), (min-width: 1160px) and (-ms-high-contrast: none) {
  .cart-items-form .promo-product-details .col-text {
    width: 345px !important;
  }
}
.cart-items-form .promo-product-details .col-image {
  align-items: center;
  display: flex;
  width: 38%;
}
@media (max-width: 320px) {
  .cart-items-form .promo-product-details .col-image {
    width: 34%;
  }
}
@media (min-width: 768px) {
  .cart-items-form .promo-product-details .col-image {
    width: 26%;
  }
}
@media (min-width: 1024px) {
  .cart-items-form .promo-product-details .col-image {
    height: 100px;
    min-width: 100px;
    width: 100px;
  }
}
.cart-items-form .promo-product-details .col-image img {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}
.cart-items-form .promo-product-details .ecom-title {
  font-size: 11px;
  color: #9a7b69;
  letter-spacing: 1.65px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .cart-items-form .promo-product-details .ecom-title {
    display: none;
  }
}
@media (max-width: 767px) {
  .checkout-v3 .cart-items-form .promo-product-details .ecom-title {
    display: block;
  }
}
.cart-items-form .promo-product-details .gift-label {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: normal;
  color: #1a1919;
}
@media (min-width: 768px) {
  .cart-items-form .promo-product-details .gift-label {
    font-size: 20px;
  }
}
.cart-items-form .promo-product-details .error-form {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #d6122d;
  text-transform: none;
  margin-top: 10px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.cart-items-form .promo-product-details .approaching-promo-callout {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-left: 0;
  margin-top: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .cart-items-form .promo-product-details .approaching-promo-callout {
    margin-top: 0;
    margin-left: auto;
    width: auto;
    max-width: 230px;
    text-align: right;
  }
}
.cart-items-form .promo-product-details .col-radio {
  align-items: center;
  display: flex;
}
.cart-items-form .tiered-gwp-block {
  margin-bottom: 0;
}
.cart-items-form .change-bonus-product-btn {
  display: inline-block;
  line-height: 18px;
  margin-top: 10px;
}

.gwp-radio--hidden {
  display: none;
}
.gwp-radio--hidden:checked ~ .gwp-select-box {
  border-radius: 50%;
  background-color: #9a7b69;
  border: 1px solid #9a7b69;
}
.gwp-radio--hidden:checked ~ .gwp-select-box::after {
  color: #ffffff;
  content: "\ea2f";
  display: block;
  font-family: "iconfont";
  font-size: 8px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.gwp-select-box {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}

.gwp-wrapper {
  width: 100%;
  text-align: center;
  padding: 25px 0;
}
@media (min-width: 768px) {
  .gwp-wrapper {
    padding: 40px 0 35px;
  }
}
.gwp-wrapper .text-wrapper {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .gwp-wrapper .text-wrapper {
    margin-bottom: 23px;
  }
}
.gwp-wrapper .text-wrapper + .link {
  margin-top: 7px;
}
.gwp-wrapper .link {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .gwp-wrapper .link {
    background-color: transparent;
    border: 1px solid #b4b4b4;
    color: #1a1919;
    min-width: 220px;
    display: inline-block;
    text-decoration: none;
    padding: 15px 20px 14px;
    text-align: center;
    transition: 0.2s ease-in-out;
    margin-bottom: 27px;
  }
}
@media (min-width: 1024px) {
  .gwp-wrapper .link:hover, .gwp-wrapper .link:active {
    border: 1px solid #1a1919;
  }
}
.gwp-wrapper .heading-h10 {
  letter-spacing: 1.5px;
  line-height: 18px;
  margin-bottom: 9px;
}
@media (min-width: 768px) {
  .gwp-wrapper .heading-h10 {
    margin-bottom: 14px;
  }
}
.gwp-wrapper .heading-h7 {
  max-width: 300px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .gwp-wrapper .heading-h7 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}
.gwp-wrapper .icon {
  display: block;
  font-size: 74px;
}
.gwp-wrapper.gwp-minicart, .gwp-wrapper.gwp-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.gwp-wrapper.gwp-minicart .image-text-wrapper, .gwp-wrapper.gwp-cart .image-text-wrapper {
  display: flex;
}
.gwp-wrapper.gwp-minicart .image-text-wrapper .text-wrapper, .gwp-wrapper.gwp-cart .image-text-wrapper .text-wrapper {
  text-align: left;
  padding-left: 26px;
}
.gwp-wrapper.gwp-cart {
  justify-content: space-between;
  flex-direction: column;
  padding: 25px 20px;
}
@media (min-width: 768px) {
  .gwp-wrapper.gwp-cart {
    flex-direction: row;
    padding: 25px 20px 9px;
  }
}
.gwp-wrapper.gwp-cart .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .gwp-wrapper.gwp-cart .btn {
    width: auto;
    margin-bottom: 18px;
  }
}
.gwp-wrapper.gwp-cart .image-text-wrapper {
  margin-bottom: 14px;
  padding-left: 10px;
  align-items: flex-end;
}
@media (min-width: 768px) {
  .gwp-wrapper.gwp-cart .image-text-wrapper {
    margin-bottom: 0;
    align-items: flex-start;
  }
}
.gwp-wrapper.gwp-cart .image-text-wrapper .text-wrapper {
  padding-left: 20px;
}
@media (min-width: 768px) {
  .gwp-wrapper.gwp-cart .image-text-wrapper .text-wrapper {
    padding-left: 30px;
  }
}
.gwp-wrapper.gwp-minicart {
  padding: 30px 0;
}
.gwp-wrapper.gwp-minicart .image-text-wrapper {
  align-items: flex-end;
}

.gwp-minicart-banner {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 22px 40px 20px 15px;
  position: relative;
  width: 100%;
}
.gwp-minicart-banner .gwp-minicart-banner-title {
  padding-left: 115px;
  width: 100%;
}
.gwp-minicart-banner .gwp-minicart-banner-title .heading-h10 {
  letter-spacing: 1.5px;
  line-height: 18px;
  margin-bottom: 0;
}
.gwp-minicart-banner .gwp-minicart-banner-image {
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  width: 90px;
}
.gwp-minicart-banner .gwp-minicart-banner-image img {
  display: inline-block;
  max-width: 100%;
}
.gwp-minicart-banner .gwp-minicart-banner-content {
  padding: 10px 0 0 25px;
  width: calc(100% - 90px);
}
.gwp-minicart-banner .gwp-minicart-banner-content .heading-h7 {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}
.gwp-minicart-banner .gwp-minicart-banner-content .btn {
  width: 100%;
}
.gwp-minicart-banner .gwp-minicart-banner-content .link {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.gwp-minicart-banner .gwp-minicart-banner-cta {
  margin-bottom: 10px;
  padding-left: 115px;
  width: 100%;
}
.gwp-minicart-banner .gwp-minicart-banner-cta .link {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.gwp-minicart-banner .gwp-minicart-banner-cta .btn {
  width: 100%;
}

.gwp-banner-progress-holder {
  background-color: #ebe5e1;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.gwp-banner-progress-holder .gwp-banner-progress-bar {
  background-color: #9a7b69;
  display: block;
  height: 3px;
  transition: width 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 0;
}

.gwp-toast-banner {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .gwp-toast-banner {
    box-shadow: none;
    width: 100%;
  }
}
.gwp-toast-banner .gwp-toast-banner-title .heading-h10 {
  margin-bottom: 6px;
}
.gwp-toast-banner .gwp-toast-banner-content {
  margin-bottom: 25px;
  padding: 0 10px;
  width: 100%;
}
.gwp-toast-banner .gwp-toast-banner-content .heading-h7 {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
}
.gwp-toast-banner .gwp-toast-banner-image {
  margin: 0 auto 25px;
  max-width: 80px;
}
.gwp-toast-banner .gwp-toast-banner-image img {
  max-width: 100%;
}
.gwp-toast-banner .gwp-toast-banner-cta .link {
  font-size: 12px;
  letter-spacing: 1.6px;
  line-height: 20px;
  text-transform: uppercase;
}
.gwp-toast-banner .gwp-toast-banner-cta .btn {
  margin-top: -5px;
  width: 100%;
}
@media (min-width: 768px) {
  .gwp-toast-banner .gwp-toast-banner-cta .btn {
    width: auto;
  }
}

.generic-toaster-container .gwp-toast-banner {
  margin: -40px -30px;
}
@media (min-width: 768px) {
  .generic-toaster-container .gwp-toast-banner {
    margin: -40px 0;
  }
}

.gwp-cart-banner {
  background-color: #ffffff;
  display: flex;
  padding: 24px 20px 20px;
  position: relative;
}
.gwp-cart-banner .gwp-banner-progress-holder {
  left: auto;
  margin: -20px -20px 25px;
  position: relative;
  top: auto;
  width: calc(100% + 60px);
}
.gwp-cart-banner .gwp-cart-banner-details {
  text-align: left;
}
.gwp-cart-banner .gwp-cart-banner-title {
  width: 100%;
}
.gwp-cart-banner .gwp-cart-banner-image {
  float: left;
  width: 80px;
}
.gwp-cart-banner .gwp-cart-banner-image img {
  display: inline-block;
  max-width: 100%;
}
.gwp-cart-banner .gwp-cart-banner-content {
  margin-bottom: 15px;
}
.gwp-cart-banner .gwp-cart-banner-content .benefits-list {
  margin-bottom: 16px;
  max-width: none;
}
@media (max-width: 767px) {
  .gwp-cart-banner .gwp-cart-banner-content .benefits-list li {
    display: flex;
  }
}
.gwp-cart-banner .gwp-cart-banner-content .benefits-list li span {
  width: auto;
}
.gwp-cart-banner .gwp-cart-banner-content .benefits-list li .icon-check {
  color: #9a7b69;
}
.gwp-cart-banner .gwp-cart-banner-cta .btn {
  margin-bottom: 16px;
}
.gwp-cart-banner .gwp-cart-banner-cta .btn:last-child {
  margin-bottom: 0;
}
.gwp-cart-banner .gwp-cart-banner-cta .link {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0 auto;
}
.gwp-cart-banner .gwp-cart-banner-cta p {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  color: #1a1919;
  max-width: 204px;
  margin: 10px auto 0;
  text-align: center;
}
.gwp-cart-banner .gwp-cart-banner-cta p:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .gwp-cart-banner {
    flex-direction: column;
  }
  .gwp-cart-banner .gwp-banner-progress-holder {
    margin: -24px -20px 21px;
    width: calc(100% + 40px);
  }
  .gwp-cart-banner .gwp-cart-banner-title {
    padding-left: 100px;
  }
  .gwp-cart-banner .gwp-cart-banner-title .heading-h10 {
    margin-bottom: 4px;
  }
  .gwp-cart-banner .gwp-cart-banner-details {
    width: 100%;
  }
  .gwp-cart-banner .gwp-cart-banner-image {
    margin-top: -13px;
  }
  .gwp-cart-banner .gwp-cart-banner-content {
    float: right;
    width: calc(100% - 100px);
  }
  .gwp-cart-banner .gwp-cart-banner-content .heading-h7 {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
  }
}
@media (min-width: 768px) {
  .gwp-cart-banner {
    flex-wrap: wrap;
    padding: 20px;
  }
  .gwp-cart-banner .gwp-banner-progress-holder {
    margin: -20px -40px 22px -20px;
  }
  .gwp-cart-banner .gwp-cart-banner-title {
    padding-left: 104px;
  }
  .gwp-cart-banner .gwp-cart-banner-title .heading-h10 {
    margin-bottom: 8px;
  }
  .gwp-cart-banner .gwp-cart-banner-details {
    width: 100%;
  }
  .gwp-cart-banner .gwp-cart-banner-image {
    margin: -17px 0 10px;
  }
  .gwp-cart-banner .gwp-cart-banner-content {
    float: left;
    margin: 0;
    padding: 0 25px;
    width: calc(100% - 80px);
  }
  .gwp-cart-banner .gwp-cart-banner-content .heading-h7 {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 6px;
  }
  .gwp-cart-banner .gwp-cart-banner-cta {
    margin-left: auto;
    min-width: 190px;
    padding-top: 8px;
    width: calc(50% - 85px);
  }
  .gwp-cart-banner .gwp-cart-banner-cta .link {
    margin: 20px 0 0 13px;
  }
  .gwp-cart-banner .gwp-cart-banner-cta p {
    margin: 10px 0 0 12px;
    text-align: left;
  }
  .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) {
    padding-bottom: 26px;
  }
  .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) .gwp-cart-banner-image {
    margin-bottom: 0;
  }
  .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) .gwp-cart-banner-content .heading-h7:last-child {
    margin-bottom: 0;
  }
  .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) .gwp-cart-banner-cta {
    padding-top: 0;
  }
}
@media (min-width: 1024px) {
  .gwp-cart-banner .gwp-cart-banner-details {
    width: calc(50% + 63px);
  }
  .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) .gwp-cart-banner-details {
    border-right: 1px solid #d8d8d8;
  }
}
.checkout-v3 .gwp-cart-banner .gwp-cart-banner-title .heading-h10 {
  margin-bottom: 16px;
}
.checkout-v3 .gwp-cart-banner .gwp-cart-banner-content .benefits-list li {
  display: flex;
}
@media (min-width: 768px) {
  .checkout-v3 .gwp-cart-banner .gwp-cart-banner-cta {
    min-width: 0;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .checkout-v3 .gwp-cart-banner .gwp-cart-banner-details {
    width: auto;
  }
}
@media (min-width: 1024px) {
  .checkout-v3 .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) .gwp-cart-banner-details {
    border: 0;
  }
}

.pg-gwp-cart-banner {
  background-color: #ffffff;
  display: flex;
  margin: 20px 0 0 -20px;
  padding: 24px 25px 20px;
  position: relative;
  width: calc(100% + 40px);
}
.pg-gwp-cart-banner .gwp-cart-banner-image {
  width: 90px;
}
.pg-gwp-cart-banner .gwp-cart-banner-image img {
  display: block;
  width: 100%;
}
.pg-gwp-cart-banner .gwp-cart-banner-details {
  padding-left: 20px;
  text-align: left;
  width: calc(100% - 90px);
}
.pg-gwp-cart-banner .heading-h10 {
  margin-bottom: 6px;
}
.pg-gwp-cart-banner .heading-h7 {
  font-size: 15px;
  line-height: 22px;
}
.pg-gwp-cart-banner .heading-h7:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .pg-gwp-cart-banner {
    align-items: center;
    margin: 30px 0 0;
    width: 100%;
  }
  .pg-gwp-cart-banner .gwp-cart-banner-image {
    width: 140px;
  }
  .pg-gwp-cart-banner .gwp-cart-banner-details {
    padding-left: 40px;
    width: calc(100% - 140px);
  }
  .pg-gwp-cart-banner .heading-h10 {
    margin-bottom: 5px;
  }
  .pg-gwp-cart-banner .heading-h7 {
    font-size: 18px;
    line-height: 24px;
  }
}

.gwp-welcome-banner {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}
.gwp-welcome-banner .gwp-welcome-banner-inner {
  background-color: #ffffff;
  padding: 91px 30px 78px;
  position: relative;
  width: 100%;
}
.gwp-welcome-banner .icon-close {
  font-size: 18px;
  position: absolute;
  right: 18px;
  top: 15px;
}
.gwp-welcome-banner .heading-h10 {
  letter-spacing: 1.5px;
  margin-bottom: 0;
}
.gwp-welcome-banner .heading-h7 {
  letter-spacing: 0.63px;
  line-height: 30px;
}
.gwp-welcome-banner .gwp-welcome-banner-content {
  margin: 0 auto;
  max-width: 310px;
  width: 100%;
}

.gwp-minicart-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.gwp-minicart-wrapper .gwp-welcome-banner {
  bottom: 195px;
  opacity: 0;
  position: fixed;
  right: 40px;
  transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: hidden;
  width: 440px;
  z-index: 10;
}
.gwp-minicart-wrapper .gwp-welcome-banner .icon-close {
  display: none;
}
.gwp-minicart-wrapper .gwp-welcome-banner.visible {
  opacity: 1;
  visibility: visible;
}
.gwp-minicart-wrapper .approaching-header {
  padding: 30px 0 2px;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift {
  border-bottom: 1px solid rgba(136, 102, 85, 0.2);
  margin: 0 0 20px;
  position: relative;
  transition: margin 2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift:last-of-type {
  border-bottom: 0;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift.slide-gift {
  left: 100%;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .promo-product-details {
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 0 20px;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .col-image {
  flex-shrink: 0;
  margin-right: 10px;
  width: 100px;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .col-image img {
  max-width: 100%;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .col-text {
  padding-left: 0;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .ecom-title {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  margin-bottom: 7px;
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .gift-label {
  font-size: 20px;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  .gwp-minicart-wrapper .approaching-promo.approaching-free-gift .gift-label {
    line-height: 32px;
  }
}
.gwp-minicart-wrapper .approaching-promo.approaching-free-gift .gift-price {
  color: #626262;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-top: 10px;
}
.gwp-minicart-wrapper .gwp-cart-banner {
  margin-top: 20px;
  padding-right: 40px;
}
.gwp-minicart-wrapper .gwp-cart-banner .gwp-cart-banner-details {
  width: 100%;
}
.gwp-minicart-wrapper .gwp-cart-banner .gwp-cart-banner-content {
  padding: 0 7px 0 19px;
}
.gwp-minicart-wrapper .gwp-cart-banner .gwp-cart-banner-cta {
  padding-top: 0;
  width: calc(100% - 102px);
}
.gwp-minicart-wrapper .gwp-cart-banner .gwp-cart-banner-cta .link {
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0 auto;
}
.gwp-minicart-wrapper .gwp-cart-banner .gwp-cart-banner-title {
  padding-left: 99px;
}
@media (min-width: 1024px) {
  .gwp-minicart-wrapper .gwp-cart-banner.gwp-cart-banner-guest .gwp-cart-banner-details {
    border: 0;
  }
}
.gwp-minicart-wrapper .gwp-cart-banner.gwp-cart-banner-guest .gwp-cart-banner-cta p {
  display: none;
}
.gwp-minicart-wrapper .gwp-cart-banner.gwp-cart-banner-empty .gwp-cart-banner-cta {
  margin-top: -9px;
}
.gwp-minicart-wrapper .gwp-wrapper.gwp-minicart {
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: flex-end;
  padding: 30px;
}
.gwp-minicart-wrapper .gwp-wrapper.gwp-minicart .image-text-wrapper {
  width: 100%;
}
.gwp-minicart-wrapper .gwp-wrapper.gwp-minicart .image-text-wrapper .icon {
  width: 80px;
}
.gwp-minicart-wrapper .gwp-wrapper.gwp-minicart .link {
  margin: 10px 0 0;
  width: calc(100% - 100px);
}

.gwp-shoppingcart-wrapper {
  width: 100%;
  padding-top: 7px;
}
.gwp-shoppingcart-wrapper .gwp-welcome-banner {
  left: 0;
  position: absolute;
  top: 34px;
  z-index: 6;
}
.gwp-shoppingcart-wrapper .gwp-cart-banner {
  margin-top: 20px;
}
.gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta {
  text-align: center;
}
.gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta .link {
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta p {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  color: #1a1919;
  max-width: 204px;
  margin: 10px auto 0;
  text-align: center;
}
.gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-title {
  padding-left: 100px;
}
@media (min-width: 768px) {
  .gwp-shoppingcart-wrapper .gwp-cart-banner {
    padding: 20px 20px 20px 36px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-content {
    padding: 0 20px 0 25px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta {
    text-align: left;
    width: calc(50% - 77px);
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta .link {
    margin: 24px 0 0 18px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta p {
    text-align: left;
    margin: 10px 0 0 16px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta .btn--secondary + .link {
    margin: 0;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-title {
    padding-left: 104px;
    margin-top: -2px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .heading-h7 {
    margin-top: -2px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-banner-progress-holder {
    margin: -20px -20px 25px -36px;
    width: calc(100% + 56px);
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .gwp-shoppingcart-wrapper .gwp-cart-banner.gwp-cart-banner-guest:not(.gwp-cart-banner-empty) .gwp-cart-banner-cta .link {
    margin-top: 20px;
  }
}
.checkout-v3 .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta {
  text-align: left;
}
@media (min-width: 768px) {
  .checkout-v3 .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta {
    width: auto;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-cta .link {
    margin: 0;
  }
}
.gwp-shoppingcart-wrapper .approaching-header {
  margin-bottom: 10px;
}
.gwp-shoppingcart-wrapper .bonus-item-actions {
  line-height: 15px;
}
.gwp-shoppingcart-wrapper .bonus-item-actions .change-bonus-product-btn {
  margin-top: 0;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid #d8d8d8;
  position: relative;
  min-height: 10px;
  margin: 0 0 20px;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift {
    padding: 15px 0 15px 20px;
  }
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift.premium-gift {
  border-top: 0;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift.slide-gift {
  left: 100%;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details {
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .col-text {
  padding-left: 16px;
}
@media (min-width: 768px) {
  .gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .col-text {
    padding-left: 20px;
  }
}
@media (min-width: 1024px) {
  .gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .col-text {
    padding-left: 37px;
  }
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .ecom-title {
  margin-bottom: 6px;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .gift-label {
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-bottom: 5px;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .gift-price {
  color: #626262;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .col-radio-send-button {
  z-index: 1;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .col-radio {
  margin-top: -10px;
  margin-left: 10px;
}
.gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .btn-wrapper-send {
  margin: 20px 0 0 5px;
}
@media (max-width: 767px) {
  .gwp-shoppingcart-wrapper .approaching-promo.approaching-free-gift .promo-product-details .btn-wrapper-send {
    margin: 10px 0 0 5px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .gwp-shoppingcart-wrapper .gwp-cart-banner {
    align-items: center;
    position: relative;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner::before {
    background-color: #d8d8d8;
    bottom: 30px;
    content: "";
    display: inline-block;
    left: 59%;
    position: absolute;
    top: 30px;
    width: 1px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-details {
    width: 59.5%;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-title {
    padding-left: 96px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-image {
    width: 70px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner .gwp-cart-banner-content {
    width: calc(100% - 70px);
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner:not(.gwp-cart-banner-empty) .gwp-cart-banner-details {
    border-right: 0;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner:not(.gwp-cart-banner-empty) .gwp-cart-banner-content {
    padding: 0 20px 0 10px;
  }
  .gwp-shoppingcart-wrapper .gwp-cart-banner:not(.gwp-cart-banner-empty) .gwp-cart-banner-cta {
    padding-top: 0;
  }
}

.gwp-cart-banner-cta.gwp-cart-banner-link-cta .btn {
  min-width: initial;
  padding: 0;
  font-size: 14px;
  border: 0;
  text-align: left;
  text-decoration: underline;
  text-transform: initial;
  letter-spacing: 0.4px;
  background-color: transparent;
  color: #9a7b69;
}
.gwp-cart-banner-cta.gwp-cart-banner-link-cta .btn:hover {
  text-decoration: none;
}

.gwp-welcome-overlay {
  background-color: rgba(246, 244, 242, 0.85);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.verification-message {
  color: #9a7b69;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.6px;
  line-height: 22px;
  margin: 25px auto 10px;
  max-width: 330px;
  text-align: center;
}

.approaching-header-icon {
  color: #a7884d;
  display: none;
  margin-bottom: 3px;
  margin-right: 12px;
}
.checkout-v3 .approaching-header-icon {
  display: inline-block;
}

.gwp-dialog {
  background-color: #ffffff;
  z-index: 999;
  max-width: calc(100% - 20px) !important;
  left: 0 !important;
  right: 0;
  margin: 0 auto;
}
.gwp-dialog.no-padding .ui-dialog-titlebar-close {
  right: 25px;
}
.gwp-dialog.no-padding .ui-dialog-titlebar-close .ui-icon-closethick {
  font-size: 24px;
  color: #1a1919;
}
.gwp-dialog .ui-dialog-titlebar {
  position: absolute;
  right: -15px;
  z-index: 2;
}
.gwp-dialog .ui-dialog-content {
  padding: 18px 20px 0;
}
.gwp-dialog .ui-icon-closethick {
  font-size: 20px;
  text-indent: 0;
  left: 0;
  top: 0;
  margin-left: 0;
}
.gwp-dialog .ui-icon-closethick:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea36";
}
.gwp-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 1px;
}

.gwp-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1a1919;
  max-width: 100%;
}
.gwp-popup .free-product-removal-images {
  width: 100%;
}
.gwp-popup .popup-products-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 520px;
  width: 100%;
  flex-wrap: wrap;
  margin: 20px auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .gwp-popup .popup-products-wrapper {
    margin: 0 auto;
  }
}
.gwp-popup .product-image {
  width: 100%;
}
.gwp-popup .product-image img {
  max-width: 100%;
}
.gwp-popup .popup-text {
  color: #453f3f;
  text-align: center;
}
.gwp-popup .popup-text p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.gwp-popup .popup-buttons {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .gwp-popup .popup-buttons {
    justify-content: center;
  }
}
.gwp-popup .popup-buttons .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .gwp-popup .popup-buttons .btn {
    min-width: 0;
    width: calc(50% - 5px);
  }
  .gwp-popup .popup-buttons .btn:first-child {
    margin-right: 5px;
  }
  .gwp-popup .popup-buttons .btn:last-child {
    margin-left: 5px;
  }
}
@media (min-width: 768px) {
  .gwp-popup .popup-buttons .btn {
    margin: 0 10px;
  }
}
.gwp-popup .popup-buttons .link {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.gwp-popup.popup-wrapper {
  margin: -20px 0 -11px;
}
@media (min-width: 768px) {
  .gwp-popup.popup-wrapper {
    margin: 20px 0 40px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .gwp-popup.popup-wrapper {
    width: 100%;
  }
}
.gwp-popup.popup-wrapper .popup-header {
  color: #886655;
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: center;
}
.gwp-popup.popup-wrapper .popup-text {
  color: #453f3f;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 30px;
  max-width: 210px;
  text-align: center;
}
@media (min-width: 1024px) {
  .gwp-popup.popup-wrapper .popup-text {
    max-width: none;
  }
}
.gwp-popup.popup-wrapper .bonus-product-list {
  width: 100%;
}
.gwp-popup.popup-wrapper .popup-product {
  margin: 5px;
  max-width: calc(50% - 10px);
}
.gwp-popup.popup-wrapper .popup-product.popup-bonus-choice-product {
  background-color: #ffffff;
  margin: 5px;
  max-width: 200px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.gwp-popup.popup-wrapper .popup-product.popup-bonus-choice-product img {
  max-width: 100%;
  max-height: 100%;
}
.gwp-popup.popup-wrapper .popup-product.popup-bonus-choice-product label {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding: 17px 35px 5px;
}
@media (min-width: 1024px) {
  .gwp-popup.popup-wrapper .popup-product.popup-bonus-choice-product label {
    padding: 32px 15px 5px;
  }
}
.gwp-popup.popup-wrapper .popup-product.popup-bonus-choice-product:only-child .select-box-wrapper {
  display: none;
}
@media (max-width: 374px) {
  .gwp-popup.popup-wrapper .slick-slide {
    padding: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .gwp-popup.popup-wrapper .slick-slide > div {
    display: flex;
    justify-content: center;
  }
}
.gwp-popup.popup-wrapper .slick-slide .popup-bonus-choice-product:only-child .select-box-wrapper {
  display: block;
}
.gwp-popup.popup-wrapper .slick-dots {
  font-size: 0;
  list-style: none;
  text-align: center;
  margin-top: 8px;
}
.gwp-popup.popup-wrapper .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.gwp-popup.popup-wrapper .slick-dots button {
  background-color: #d1d0ce;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 6px;
  width: 6px;
}
.gwp-popup.popup-wrapper .slick-dots .slick-active button {
  background-color: #453f3f;
}
.gwp-popup.popup-wrapper .slick-list {
  overflow: visible;
}
.gwp-popup.popup-wrapper .product-name {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 11px;
}
@media (min-width: 1024px) {
  .gwp-popup.popup-wrapper .product-name {
    margin-bottom: 22px;
  }
}
.gwp-popup.popup-wrapper .product-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}
@media (min-width: 1024px) {
  .gwp-popup.popup-wrapper .product-image {
    padding: 0 30px;
  }
}
.gwp-popup.popup-wrapper .product-image img {
  max-width: 100%;
}
.gwp-popup.popup-wrapper .gwp-select-box {
  height: 30px;
  width: 30px;
  margin: 0 auto;
}
.gwp-popup.popup-wrapper .gwp-radio--hidden:checked {
  position: relative;
}
.gwp-popup.popup-wrapper .gwp-radio--hidden:checked ~ .product-image {
  background-color: #ffffff;
}
.gwp-popup.popup-wrapper .gwp-radio--hidden:checked ~ .select-box-wrapper {
  background-color: #ffffff;
}
.gwp-popup.popup-wrapper .gwp-radio--hidden:checked ~ .select-box-wrapper .gwp-select-box {
  border-radius: 50%;
  background-color: #886655;
  border: 1px solid #886655;
}
.gwp-popup.popup-wrapper .gwp-radio--hidden:checked ~ .select-box-wrapper .gwp-select-box::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  background-color: transparent;
  color: #ffffff;
  content: "\ea2f";
  display: block;
  font-size: 13px;
  height: 6px;
  left: 9px;
  position: absolute;
  top: 4px;
  transform: translate(-50%, -50%);
  width: 6px;
}
.gwp-popup.popup-wrapper .select-box-wrapper {
  width: 100%;
  padding: 10px 0;
  flex-grow: 0;
  display: flex;
  align-items: flex-end;
}
.gwp-popup.popup-wrapper .verification-message {
  margin: 25px auto 0;
}
@media (min-width: 768px) {
  .gwp-popup.popup-wrapper .verification-message {
    margin: 30px auto -13px;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .gwp-popup.popup-wrapper .popup-buttons {
    margin-top: 8px;
  }
}
.gwp-popup.popup-wrapper .popup-buttons .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .gwp-popup.popup-wrapper .popup-buttons .btn {
    width: auto;
  }
}
.gwp-popup.popup-wrapper .approaching-promo-callout {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.75px;
  line-height: 21px;
  max-width: 264px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .gwp-popup.popup-wrapper .approaching-promo-callout {
    margin-top: 40px;
    max-width: 400px;
  }
}
.gwp-popup.remove-gwp-popup {
  width: 100%;
  margin: -10px 0;
}
@media (min-width: 768px) {
  .gwp-popup.remove-gwp-popup {
    margin: 30px 0 40px;
  }
}
.gwp-popup.remove-gwp-popup .product-wrapper {
  display: flex;
}
@media (min-width: 1024px) {
  .gwp-popup.remove-gwp-popup .product-wrapper {
    display: block;
  }
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup.gwp-popup-padding {
    padding: 35px 0 30px;
  }
}
.gwp-popup.remove-gwp-popup .free-product-removal-images {
  max-width: 90px;
}
@media (min-width: 1024px) {
  .gwp-popup.remove-gwp-popup .free-product-removal-images {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .free-product-removal-images {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .gwp-popup.remove-gwp-popup .free-product-removal-images {
    display: none;
  }
}
.gwp-popup.remove-gwp-popup .popup-product {
  max-width: 130px;
  margin: 0;
}
@media (min-width: 1024px) {
  .gwp-popup.remove-gwp-popup .popup-product {
    margin: 5px;
  }
}
.gwp-popup.remove-gwp-popup .approaching-free-gift {
  border-bottom: 1px solid rgba(136, 102, 85, 0.2);
  border-top: 1px solid rgba(136, 102, 85, 0.2);
  margin: 0 0 16px;
}
.gwp-popup.remove-gwp-popup .approaching-free-gift:last-of-type {
  border-bottom: 1px solid rgba(136, 102, 85, 0.2);
}
.gwp-popup.remove-gwp-popup .approaching-free-gift .col-image {
  height: 80px;
  width: 95px;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .approaching-free-gift .col-image {
    height: 64px;
    width: 70px;
  }
}
.gwp-popup.remove-gwp-popup .approaching-free-gift .col-image img {
  height: 80px;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .approaching-free-gift .col-image img {
    height: 64px;
  }
}
.gwp-popup.remove-gwp-popup .approaching-free-gift .promo-product-details {
  margin-bottom: 0;
  padding: 16px 0 16px 40px;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .approaching-free-gift .promo-product-details {
    padding: 16px 0 16px 25px;
  }
}
.gwp-popup.remove-gwp-popup .approaching-free-gift .ecom-title {
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .approaching-free-gift .ecom-title {
    padding-right: 40px;
  }
}
.gwp-popup.remove-gwp-popup .approaching-free-gift .gift-label {
  color: #1a1919;
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 32px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .approaching-free-gift .gift-label {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-right: 20px;
  }
}
.gwp-popup.remove-gwp-popup .approaching-free-gift .gift-price {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.gwp-popup.remove-gwp-popup .product-gift-ribbon::after {
  opacity: 1;
}
@media (min-width: 768px) {
  .gwp-popup.remove-gwp-popup .product-gift-ribbon::after {
    height: 88px;
    width: 82px;
  }
}
.gwp-popup.remove-gwp-popup .popup-buttons {
  align-items: center;
  flex-direction: column;
}
.gwp-popup.remove-gwp-popup .popup-buttons .remove-gwp-button {
  margin-bottom: 16px;
  min-width: unset;
  padding: 16px 40px;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .popup-buttons .remove-gwp-button {
    max-width: 100%;
    width: calc(100% - 48px);
  }
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .popup-buttons .remove-gwp-button:first-child {
    margin-right: 0;
  }
}
.gwp-popup.remove-gwp-popup .popup-buttons .keep-gwp-button {
  color: #9a7b69;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-decoration: underline;
}
.gwp-popup.remove-gwp-popup .popup-buttons a {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.gwp-popup.remove-gwp-popup .popup-text {
  margin: -30px 0 16px;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .popup-text {
    margin: 0 0 16px;
    padding: 0 40px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .gwp-popup.remove-gwp-popup .popup-text {
    margin: 0 0 16px;
  }
}
.gwp-popup.remove-gwp-popup .popup-text .popup-text-main {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 32px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .popup-text .popup-text-main {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .gwp-popup.remove-gwp-popup .popup-text .popup-text-main {
    text-align: center;
  }
}
.gwp-popup.remove-gwp-popup .popup-text p {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 0;
  max-width: 500px;
  padding: 0 10px;
  text-align: left;
}
@media (max-width: 767px) {
  .gwp-popup.remove-gwp-popup .popup-text p {
    padding: 0;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .gwp-popup.remove-gwp-popup .popup-text p {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .gwp-popup.remove-gwp-popup .popup-text p {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}

@media (max-width: 1023px) {
  .bonus-product-list-slider.drag-list {
    margin-bottom: 40px;
    width: calc(100% + 60px) !important;
  }
}
@media (max-width: 1023px) {
  .bonus-product-list-slider.drag-list .drag-list-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .bonus-product-list-slider.drag-list .drag-list-scroll::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 768px) {
  .bonus-product-list-slider.drag-list .drag-list-scroll {
    visibility: visible;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .bonus-product-list-slider.drag-list .drag-list-scroll {
    max-width: none;
  }
}
@media (min-width: 1024px) {
  .bonus-product-list-slider.drag-list .drag-list-scroll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 520px;
    width: 100%;
    flex-wrap: wrap;
    margin: 25px auto 20px;
    padding-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .bonus-product-list-slider.drag-list .drag-list-body {
    margin: 0 auto;
    min-width: 100%;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .bonus-product-list-slider.drag-list .bonus-product-list-slider-item {
    width: calc(100vw - 80px);
  }
}

.bonus-product-list-slider-item {
  padding: 5px;
  width: 210px;
}
@media (max-width: 767px) {
  .bonus-product-list-slider-item {
    align-items: center;
    display: flex;
    justify-content: center;
    width: calc(100vw - 85px);
  }
}
@media (max-width: 1023px) {
  .bonus-product-list-slider-item {
    padding: 20px 5px 10px;
  }
}
.bonus-product-list-slider-item img {
  max-width: 100%;
  max-height: 100%;
}
.bonus-product-list-slider-item label {
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding: 17px 35px 5px;
}
@media (min-width: 1024px) {
  .bonus-product-list-slider-item label {
    padding: 32px 15px 5px;
  }
}

.pg-minicart-banner {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 26px 20px 20px;
  width: 100%;
}
.pg-minicart-banner .pg-minicart-banner-image {
  width: 186px;
}
.pg-minicart-banner .pg-minicart-banner-image img {
  width: 100%;
}
.pg-minicart-banner .gwp-minicart-banner-content {
  padding-left: 20px;
}
.pg-minicart-banner .heading-h10 {
  margin-bottom: 6px;
}
.pg-minicart-banner .heading-h8 {
  line-height: 20px;
}

.advent-non-gwp-banner {
  margin-top: 30px;
  padding: 24px 30px 20px;
  text-align: center;
  width: 100%;
}
.advent-non-gwp-banner .heading-h11 {
  color: #886655;
  letter-spacing: 1.5px;
  margin-bottom: 6px;
}
.advent-non-gwp-banner .heading-h7 {
  letter-spacing: 0.5px;
  margin-bottom: 18px;
}
.advent-non-gwp-banner .advent-usp-minicart-list {
  width: 100%;
}
.advent-non-gwp-banner .shipping-info-point {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  list-style: none;
  margin-bottom: 7px;
  padding-right: 20px;
}
.advent-non-gwp-banner .icon-check {
  color: #886655;
  font-size: 10px;
  line-height: 16px;
  margin-right: 7px;
}

.free-gifting-info-block-wrapper {
  width: 100%;
}
@media (max-width: 767px) {
  .free-gifting-info-block-wrapper {
    padding-top: 24px;
  }
}
@media (min-width: 768px) {
  .free-gifting-info-block-wrapper {
    margin-bottom: 20px;
    padding-top: 13px;
  }
}

.free-gifting-info-block {
  background-color: #f6f4f2;
}
@media (max-width: 767px) {
  .free-gifting-info-block {
    border-bottom: 1px solid #886655;
    border-top: 1px solid #886655;
    margin-left: -20px;
    padding: 8px 40px;
    width: calc(100% + 40px);
  }
}
@media (min-width: 768px) {
  .free-gifting-info-block {
    border: 1px solid #886655;
    margin: 0 auto;
    max-width: 360px;
    padding: 8px 30px;
    width: 100%;
  }
}
.free-gifting-info-block .inner {
  align-items: center;
  display: flex;
  width: 100%;
}
.free-gifting-info-block .image-holder {
  flex-shrink: 0;
  width: 70px;
}
.free-gifting-info-block img {
  display: block;
  max-width: 100%;
}
.free-gifting-info-block .text-holder {
  text-align: left;
}
@media (max-width: 767px) {
  .free-gifting-info-block .text-holder {
    padding-left: 10px;
  }
}
@media (min-width: 768px) {
  .free-gifting-info-block .text-holder {
    padding-left: 15px;
  }
}
.free-gifting-info-block h4 {
  color: #886655;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.free-gifting-info-block p {
  color: #1a1919;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.7px;
  line-height: 22px;
}
.free-gifting-info-block p:last-child {
  margin-bottom: 0;
}

.product-gift-ribbon::after {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.checkout-v3 .product-gift-ribbon::after {
  opacity: 1;
}
@media (max-width: 767px) {
  .product-gift-ribbon::after {
    background-image: url("../images/crm/ribbon-gold-corner-mobile.svg");
    height: 72px;
    width: 64px;
  }
}
@media (min-width: 768px) {
  .product-gift-ribbon::after {
    background-image: url("../images/crm/ribbon-gold-corner-desktop.svg");
    height: 96px;
    width: 90px;
  }
}
.checkout-v3 .product-gift-ribbon .mini-cart-price-wrapper {
  display: none;
}
@media (min-width: 768px) {
  .product-gift-ribbon .cart-product-quantity {
    margin-left: 30px;
  }
}
.product-gift-ribbon .cart-product-quantity .quantity-block input {
  pointer-events: none;
}
.product-gift-ribbon .mini-cart-details-bottom .mini-cart-pricing {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-top: 5px;
}

.crm-gift-checked .product-image .dm-responsive-image {
  filter: unset;
}
.crm-gift-checked .promo-product-name .ecom-title {
  color: #886655;
}
.crm-gift-checked .promo-product-name .change-bonus-product-btn {
  color: #886655;
}
.crm-gift-checked::after {
  filter: unset;
}

.gwp-choose-gift {
  border-top: 1px solid #d8d8d8;
  padding: 32px 0 16px 40px;
}
@media (max-width: 767px) {
  .gwp-choose-gift {
    padding: 24px 0 16px 16px;
  }
}

.gwp-choose-gift-text {
  text-align: left;
  text-transform: uppercase;
  color: #9a7b69;
  line-height: 16px;
  margin: 0;
  letter-spacing: 1.7px;
  font-size: 12px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}

.video-fullscreen-container {
  bottom: 0;
  visibility: hidden;
  left: 0;
  padding: 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
@media (min-width: 768px) {
  .video-fullscreen-container {
    padding: 30px;
  }
}
.video-fullscreen-container.visible {
  visibility: visible;
  z-index: 999;
  transform: translateZ(0);
}
.video-fullscreen-container.active .fullscreen-video-popup {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.video-fullscreen-container.active .product-experience-backdrop {
  opacity: 1;
}
.video-fullscreen-container.active .video-controls_centered {
  display: block;
}
.video-fullscreen-container .fullscreen-video-popup {
  width: 100%;
  height: 60vw;
  max-height: calc(100% - 100px);
  overflow: hidden;
  max-width: 1400px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  opacity: 0;
  transform: translateY(-50%) scale(0.95);
  transition: transform 0.6s cubic-bezier(0.18, 0.74, 0.36, 0.99), opacity 0.6s cubic-bezier(0.18, 0.74, 0.36, 0.99);
}
.video-fullscreen-container .fullscreen-video-popup iframe,
.video-fullscreen-container .fullscreen-video-popup object,
.video-fullscreen-container .fullscreen-video-popup embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-fullscreen-container .fullscreen-video-popup .video-controls_bottom-right {
  bottom: 20px;
  position: absolute;
  right: 20px;
  z-index: 1;
}
.video-fullscreen-container .fullscreen-video-popup .video-controls_bottom-right.is-playing a {
  transform: translateX(-28px);
}
.video-fullscreen-container .fullscreen-video-popup .video-controls_bottom-right a {
  font-size: 26px;
  text-decoration: none;
}
.video-fullscreen-container .fullscreen-video-popup .video-controls_bottom-right li {
  display: flex;
  height: 27px;
  overflow: hidden;
  padding: 1px;
  white-space: nowrap;
  width: 26px;
}
.video-fullscreen-container .fullscreen-video-popup .video-background {
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}
.video-fullscreen-container .product-experience-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.4s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  z-index: -1;
}

.background-video {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  display: none;
}
@media (min-width: 768px) {
  .background-video {
    display: block;
  }
}
.background-video.show, .background-video.is-dam {
  opacity: 1;
  display: block;
}
.background-video.is-dam video::-webkit-media-controls {
  display: none !important;
}
.background-video .responsive-video {
  max-width: 100%;
}
.background-video .responsive-video iframe,
.background-video .responsive-video object,
.background-video .responsive-video embed,
.background-video .responsive-video .video-element {
  box-sizing: border-box;
  height: 58vw;
  left: 50%;
  min-height: calc(100% + 5px);
  min-width: calc(100% + 5px);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.8vh;
}

.video-controls_centered {
  bottom: 30px;
  display: none;
  opacity: 1;
  position: absolute;
  text-align: center;
  transition: opacity 2s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  white-space: nowrap;
  width: calc(100% - 20px);
  z-index: 1;
}
@media (min-width: 768px) {
  .video-controls_centered {
    bottom: 0;
    left: 50%;
    margin: 0 auto;
    opacity: 0;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
.video-controls_centered .video-close {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-decoration: none;
  transition: transform 0.4s cubic-bezier(0.18, 0.74, 0.36, 0.99);
}
.video-controls_centered .video-close .icon {
  font-size: 26px;
}
@media (min-width: 768px) {
  .video-controls_centered.active {
    transition: opacity 0.6s cubic-bezier(0.18, 0.74, 0.36, 0.99);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .video-controls_centered.active .video-close {
    cursor: pointer;
    transform: translateY(0);
  }
}

.video-controls_bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  overflow: hidden;
  z-index: 5;
  opacity: 0.6;
}
.video-controls_bottom-right .buttons-wrapper {
  position: relative;
}
.video-controls_bottom-right .video-control-button {
  font-size: 26px;
  margin: 5px;
  display: inline-block;
  color: #ffffff;
}
.video-controls_bottom-right .video-control-button span::before {
  display: block;
}
.video-controls_bottom-right .icon-play,
.video-controls_bottom-right .icon-play-large {
  display: block;
}
.video-controls_bottom-right .icon-pause,
.video-controls_bottom-right .icon-pause-large {
  display: none;
}
.video-controls_bottom-right.is-playing .icon-play,
.video-controls_bottom-right.is-playing .icon-play-large {
  display: none;
}
.video-controls_bottom-right.is-playing .icon-pause,
.video-controls_bottom-right.is-playing .icon-pause-large {
  display: block;
}

.btn-content-video {
  color: #9a7b69;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding-left: 36px;
  position: relative;
  text-decoration: underline;
}
.btn-content-video::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea92";
  font-size: 25px;
  left: 0;
  position: absolute;
  text-decoration: underline;
  top: 0;
}
.btn-content-video:hover {
  text-decoration: none;
}
.btn-content-video ~ .btn-content-video {
  margin-left: 15px;
}

.btn-content-video::before {
  text-decoration: none;
}

.custom-select {
  background: transparent url("../images/icons/arrow3--down.svg") no-repeat right center;
  background-size: 18px 20px;
  border: 0;
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 5px 17px 5px 0;
  text-align: left;
  text-align-last: left;
  text-transform: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select.language {
  background: none;
}

.selectric-wrapper {
  position: relative;
  z-index: 15;
}
.selectric-wrapper .selectric-hide-select {
  height: 0;
  overflow: hidden;
  position: relative;
  width: 0;
}
.selectric-wrapper .selectric {
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  padding: 11px 21px 7px 10px;
  position: relative;
  text-align: left;
}
.selectric-wrapper .selectric .label {
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  width: 100%;
}
.selectric-wrapper .selectric .button {
  font-size: 0;
  position: absolute;
  right: -1px;
  top: 9px;
}
@media (min-width: 768px) {
  .selectric-wrapper .selectric .button {
    right: 19px;
  }
}
.selectric-wrapper .selectric .button::before, .selectric-wrapper .selectric .button::after {
  content: "";
  display: block;
  height: 6px;
  position: absolute;
  right: 0;
  transform: rotateZ(45deg);
  transition: top 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 6px;
}
.selectric-wrapper .selectric .button::before {
  border-left: 1px solid #453f3f;
  border-top: 1px solid #453f3f;
  top: 9px;
}
.selectric-wrapper .selectric .button::after {
  border-bottom: 1px solid #453f3f;
  border-right: 1px solid #453f3f;
  top: 14px;
}
.selectric-wrapper .selectric-items {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  display: none;
  left: 0;
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
}
.selectric-wrapper .selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
  border: 1px solid #d8d8d8;
}
.selectric-wrapper .selectric-items ul {
  background-color: #ffffff;
  list-style: none;
  padding: 0;
}
.selectric-wrapper .selectric-items ul li {
  color: #1a1919;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  padding: 17px 20px;
}
.selectric-wrapper .selectric-items ul li:hover, .selectric-wrapper .selectric-items ul li.selected {
  background-color: rgba(245, 244, 242, 0.6);
}
.selectric-wrapper .selectric-items ul li.disabled {
  display: none;
}
.selectric-wrapper .selectric-input {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  outline: none;
  border: 0;
  background: none;
}
.selectric-wrapper.selectric-open {
  z-index: 16;
}
.selectric-wrapper.selectric-open .selectric .button::before {
  top: 16px;
}
.selectric-wrapper.selectric-open .selectric .button::after {
  top: 7px;
}
.selectric-wrapper.selectric-open .selectric-items {
  display: block;
}
.selectric-wrapper .error ~ .selectric {
  border: 1px solid #d6122d;
}

.seo-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.wrapper__seo-block {
  margin-bottom: 65px;
  margin-top: -20px;
  padding: 30px 0 28px;
  position: relative;
  width: 100%;
}
.wrapper__seo-block::after {
  background: linear-gradient(rgba(246, 244, 242, 0), rgb(239, 237, 234));
  bottom: 28px;
  content: "";
  display: inline-block;
  height: 75px;
  left: 0;
  position: absolute;
  width: 100%;
}
.wrapper__seo-block.white-bg-color::after {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
}
.wrapper__seo-block.expanded .seo-block__icon {
  transform: rotate(360deg);
}
.wrapper__seo-block.expanded .seo-block__icon::after {
  opacity: 0;
}
.wrapper__seo-block.expanded::after {
  display: none;
}
.wrapper__seo-block.expanded .text__container {
  max-height: 2000em;
}
.wrapper__seo-block.expanded .text-cutoff__line .text-cutoff__toggle::after {
  content: "\ea78";
}
.wrapper__seo-block .text__container {
  overflow: hidden;
  max-height: 192px;
  transition: max-height 0.2s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.wrapper__seo-block .text__container h1 {
  font-size: 20px;
  letter-spacing: 1.3px;
  line-height: 24px;
  margin-bottom: 20px;
}
.wrapper__seo-block .text__container h2 {
  font-size: 16px;
  letter-spacing: 1.7px;
  line-height: 20px;
  margin-bottom: 12px;
}
.wrapper__seo-block .text__container p {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 19px;
}
.wrapper__seo-block .text__container p a {
  font-size: 14px;
  line-height: 20px;
}
.wrapper__seo-block .text-cutoff__line {
  border-bottom: 1px solid #d8d8d8;
  bottom: 0;
  cursor: pointer;
  left: 0;
  padding-bottom: 6px;
  position: absolute;
  width: 100%;
}
.wrapper__seo-block .text-cutoff__line .text-cutoff__toggle {
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  width: 100%;
}
.wrapper__seo-block .text-cutoff__line .text-cutoff__toggle::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea94";
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
}
@media (max-width: 767px) {
  .wrapper__seo-block.expanded .text-cutoff__line .text-cutoff__toggle .more {
    display: none;
  }
  .wrapper__seo-block.expanded .text-cutoff__line .text-cutoff__toggle .less {
    display: inline-block;
  }
  .wrapper__seo-block .text-cutoff__line {
    padding: 0;
    text-align: center;
  }
  .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle {
    padding: 8px 0;
  }
  .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle .more {
    display: inline-block;
  }
  .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle .less {
    display: none;
  }
  .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle::after {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  .wrapper__seo-block {
    margin: 0 auto 55px;
    max-width: 720px;
    padding-bottom: 44px;
  }
  .wrapper__seo-block::after {
    bottom: 43px;
    height: 68px;
  }
  .wrapper__seo-block .text__container {
    max-height: 170px;
  }
  .wrapper__seo-block .text__container h1 {
    margin-bottom: 28px;
  }
  .wrapper__seo-block .text-cutoff__line {
    padding-bottom: 13px;
    text-align: right;
  }
  .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle {
    font-size: 27px;
  }
  .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle span {
    display: none;
  }
}

.seo-block {
  position: relative;
  background-color: #122343;
  padding: 0 5px 65px;
}

.wrapper__seo-advent {
  margin-bottom: 0;
}
.wrapper__seo-advent .seo-block__icon-div {
  position: absolute;
  width: 100%;
  min-height: 70px;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.wrapper__seo-advent .seo-block__icon {
  position: absolute;
  height: 48px;
  right: 24px;
  width: 48px;
  background: url("../images/oval-gradient-grey-80x80.png") no-repeat left top;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.wrapper__seo-advent .seo-block__icon::before, .wrapper__seo-advent .seo-block__icon::after {
  background-color: #ffffff;
  content: "";
  display: inline-block;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 11px;
}
.wrapper__seo-advent .seo-block__icon::after {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(90deg);
}
.wrapper__seo-advent .seo-block__icon.open {
  transform: rotate(360deg);
}
.wrapper__seo-advent .seo-block__icon.open::after {
  opacity: 0;
}
.wrapper__seo-advent .text__container {
  padding: 0 120px 0 20px;
}
.wrapper__seo-advent .text__container h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 20px;
}
.wrapper__seo-advent .text__container p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 20px;
}
.wrapper__seo-advent .text__container p a {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 24px;
}
.wrapper__seo-advent::after {
  background: linear-gradient(180deg, rgba(28, 44, 76, 0) 0%, rgba(18, 35, 67, 0.86) 85.3%, #122343 99.99%);
  bottom: 28px;
  content: "";
  display: inline-block;
  height: 50px;
  left: 0;
  position: absolute;
  width: 100%;
}
.wrapper__seo-advent .text-cutoff__line {
  border-bottom: 1px solid #d8d8d8;
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  left: 0;
  position: absolute;
  width: 100%;
  min-height: 50px;
}
.wrapper__seo-advent .text-cutoff__line .text-cutoff__toggle {
  min-height: 50px;
}
@media (min-width: 768px) {
  .wrapper__seo-advent {
    max-width: 920px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .wrapper__seo-advent .text-cutoff__toggle {
    z-index: 10;
  }
  .wrapper__seo-advent .text-cutoff__line {
    padding-bottom: 0;
  }
  .wrapper__seo-advent::after {
    bottom: 20px;
    height: 188px;
  }
}
@media (max-width: 767px) {
  .wrapper__seo-advent {
    padding-top: 0;
  }
  .wrapper__seo-advent .seo-block__icon-div {
    min-height: 100%;
  }
  .wrapper__seo-advent .seo-block__icon {
    height: 40px;
    width: 40px;
    right: 0;
  }
  .wrapper__seo-advent .text__container {
    padding: 0 45px 0 0;
  }
  .wrapper__seo-advent .text__container p {
    font-size: 14px;
  }
  .wrapper__seo-advent .text__container p a {
    font-size: 14px;
  }
  .wrapper__seo-advent::after {
    height: 188px;
  }
}

.personalised-gifting-bg .wrapper__seo-block::after {
  background: linear-gradient(rgba(246, 244, 242, 0), rgb(239, 237, 234));
}

.seo-wrapper-redesign {
  background-color: #1a1919;
  display: flex;
  padding-top: 60px;
}
@media (max-width: 767px) {
  .seo-wrapper-redesign {
    padding-top: 30px;
  }
}
.seo-wrapper-redesign .wrapper__seo-block {
  margin-bottom: 30px;
  padding: 10px 0 28px;
  position: relative;
  width: 100%;
}
.seo-wrapper-redesign .wrapper__seo-block::after {
  display: none;
}
.seo-wrapper-redesign .wrapper__seo-block.expanded .text__container {
  max-height: 2000em;
}
.seo-wrapper-redesign .wrapper__seo-block.expanded .text__container::after {
  display: none;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container {
  overflow: hidden;
  max-height: 192px;
  transition: max-height 0.2s cubic-bezier(0.05, 0.5, 0.3, 1);
  position: relative;
  margin-bottom: 20px;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container::after {
  background: linear-gradient(0deg, #1a1919 0%, #1a1919 20%, rgba(26, 25, 25, 0) 100%);
  bottom: 0;
  content: "";
  display: inline-block;
  height: 75px;
  left: 0;
  position: absolute;
  width: 100%;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container h1 {
  font-size: 20px;
  letter-spacing: 1.3px;
  line-height: 24px;
  margin-bottom: 20px;
  text-transform: initial;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container h2 {
  font-size: 18px;
  letter-spacing: 1.7px;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: initial;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container h3 {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: initial;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container p {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 19px;
}
.seo-wrapper-redesign .wrapper__seo-block .text__container p a {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.seo-wrapper-redesign .wrapper__seo-block .text-cutoff__toggle {
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 1;
  position: relative;
  margin: 0 auto;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  max-width: 200px;
  min-width: 150px;
  z-index: 10;
}
.seo-wrapper-redesign .wrapper__seo-block .text-cutoff__toggle .more {
  display: inline-block;
}
.seo-wrapper-redesign .wrapper__seo-block .text-cutoff__toggle .less {
  display: none;
}
.seo-wrapper-redesign .wrapper__seo-block.expanded .text-cutoff__toggle .more {
  display: none;
}
.seo-wrapper-redesign .wrapper__seo-block.expanded .text-cutoff__toggle .less {
  display: inline-block;
}
@media (min-width: 768px) {
  .seo-wrapper-redesign .wrapper__seo-block {
    padding-bottom: 30px;
    max-width: initial;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 170px;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text__container::after {
    height: 80px;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text__container .text__container-side {
    width: 48%;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text__container h1 {
    margin-bottom: 28px;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text-cutoff__line {
    padding-bottom: 13px;
    text-align: right;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle {
    font-size: 27px;
  }
  .seo-wrapper-redesign .wrapper__seo-block .text-cutoff__line .text-cutoff__toggle span {
    display: none;
  }
}
.seo-wrapper-redesign .category-pills {
  margin-bottom: 10px;
}
.seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper {
  background-color: #1a1919;
}
@media (max-width: 767px) {
  .seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper {
    background-color: #1a1919;
    height: auto;
    padding: 10px 0 0;
  }
  .seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper::before, .seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper::after {
    visibility: hidden;
  }
}
.seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper .cop-filters-list {
  margin-bottom: 0;
}
.seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper .cop-filters-list li {
  border-color: #626262;
  cursor: pointer;
}
.seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper .cop-filters-list li a {
  color: #ffffff;
  font-size: 12px;
  padding: 0 17px;
  text-decoration: none;
}
@media (min-width: 768px) {
  .seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper .cop-filters-list li:hover {
    border-color: #ffffff;
  }
}
@media (max-width: 767px) {
  .seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper .cop-filters-list {
    display: flex;
    flex-wrap: wrap;
  }
  .seo-wrapper-redesign .category-pills .cop-filters-wrapper.cop-product-types-wrapper .cop-filters-list li {
    display: block;
  }
}
.seo-wrapper-redesign .category-pills .category-pills-header {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: initial;
}
@media (min-width: 768px) {
  .seo-wrapper-redesign .category-pills .category-pills-header {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .filter-page {
    background-color: #f6f4f2;
  }
}

.filter-flyin {
  background-color: #ffffff;
  bottom: 0;
  display: none;
  left: -100%;
  position: fixed;
  top: 0;
  transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  z-index: 982;
}
.filter-flyin .filter-inner {
  height: 100%;
  overflow: auto;
  padding: 104px 0 48px;
  width: 100%;
}
.filter-flyin .filter-header {
  background-color: #ffffff;
  border-bottom-width: 2px;
  padding: 42px 20px 37px;
}
.filter-flyin .filter-header .filter-x-close {
  right: 23px;
  top: 43px;
}
.filter-flyin .filter-header h3 {
  font-size: 20px;
  letter-spacing: 3.4px;
  line-height: 24px;
  margin-bottom: 0;
}
.filter-flyin.refinements-visible {
  left: 0;
}
.filter-flyin.refinements-visible .filter-header,
.filter-flyin.refinements-visible .filter-user-actions {
  left: 0;
}
.filter-flyin.refinements-visible .filter-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .filter-flyin .filter-holder .filter-block-title {
    border-bottom: 2px solid #d1d1d1;
    min-height: 77px;
  }
  .filter-flyin .filter-holder .filter-block-title::after {
    font-size: 30px;
    right: -5px;
  }
  .filter-flyin.refinements-visible .filter-holder .filter-options.visible {
    right: 0;
  }
  .filter-flyin.refinements-visible.moved-right {
    left: 100%;
  }
  .filter-flyin.refinements-visible.moved-right .filter-header,
  .filter-flyin.refinements-visible.moved-right .filter-user-actions {
    left: 100%;
  }
  .filter-flyin.refinements-visible.moved-left {
    left: -100%;
  }
  .filter-flyin.refinements-visible.moved-left .filter-header {
    left: -100%;
  }
}
@media (min-width: 768px) {
  .filter-flyin {
    width: 360px;
  }
  .filter-flyin .filter-header {
    padding: 31px 80px 30px 60px;
  }
  .filter-flyin .filter-header .filter-x-close {
    right: 56px;
    top: 39px;
  }
  .filter-flyin .filter-header h3 {
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 38px;
  }
  .filter-flyin .filter-inner {
    padding: 100px 0 48px;
  }
  .filter-flyin .filters-container {
    padding: 4px 60px;
  }
}

.filter-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  left: 360px;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ease-in-out;
  visibility: hidden;
}
@media (min-width: 768px) {
  .filter-overlay {
    display: block;
  }
}

.filter-header {
  background-color: #f6f4f2;
  border-bottom: 1px solid #d8d8d8;
  left: -100%;
  padding: 25px 20px 33px;
  position: fixed;
  top: 0;
  transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  z-index: 20;
}
.filter-header .filter-x-close {
  font-size: 22px;
  position: absolute;
  right: 16px;
  top: 19px;
}
.filter-header h3 {
  color: #1a1919;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 1.2;
  margin-bottom: 21px;
  text-transform: none;
}
@media (min-width: 768px) {
  .filter-header {
    border-bottom: 0;
    padding: 30px 60px 33px;
    text-align: left;
    width: 360px;
  }
  .filter-header .filter-x-close {
    right: 56px;
    top: 39px;
  }
  .filter-header h3 {
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 40px;
    margin-bottom: 35px;
    padding-right: 20px;
  }
}

.filter-categories {
  position: relative;
  width: 100%;
}
.filter-categories .filter-cateogry-title {
  display: none;
  font-size: 12px;
  letter-spacing: 2.5px;
  line-height: 15px;
  margin-bottom: 9px;
}
.filter-categories .filter-box-categories {
  position: relative;
  width: 100%;
}
.filter-categories .filter-box-categories li {
  display: block;
  margin-bottom: 3px;
  position: relative;
}
.filter-categories .filter-box-categories a {
  color: #1a1919;
  display: inline-block;
  text-decoration: none;
}
.filter-categories .filter-box-categories span {
  display: inline-block;
  position: relative;
}
.filter-categories .filter-box-categories span::after {
  background-color: #1a1919;
  bottom: 4px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  transition: width 0.3s ease;
  width: 0;
  z-index: 2;
}
.filter-categories .filter-box-categories sup {
  color: #886655;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  left: 2px;
  position: relative;
}
.filter-categories .filter-box-categories .selected span::after {
  width: 100%;
}
@media (min-width: 768px) {
  .filter-categories .filter-cateogry-title {
    display: block;
  }
  .filter-categories .filter-box-categories {
    padding: 0;
  }
  .filter-categories .filter-box-categories li {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .filter-categories .filter-box-categories li::after {
    display: none;
  }
  .filter-categories .filter-box-categories li:first-child, .filter-categories .filter-box-categories li:last-child {
    padding: 0;
  }
  .filter-categories .filter-box-categories a {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 26px;
  }
  .filter-categories .filter-box-categories a:hover span::after {
    width: 100%;
  }
  .filter-categories .filter-box-categories sup {
    margin-left: 4px;
  }
}

.filter-holder {
  overflow: hidden;
  width: 100%;
}
.filter-holder .filters-container {
  padding: 0 20px 20px;
  width: 100%;
}
.filter-holder .filter-block {
  width: 100%;
}
@media (min-width: 768px) {
  .filter-holder .filter-block.filter-block-refinement input {
    padding: 0;
    position: relative;
  }
  .filter-holder .filter-block.filter-block-refinement .text {
    padding-left: 11px;
  }
}
.filter-holder .filter-block-title {
  border-bottom: 1px solid #d8d8d8;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  line-height: 15px;
  padding: 21px 30px 23px 0;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
.filter-holder .filter-block-title::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  display: inline-block;
  font-size: 40px;
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
}
.filter-holder .filter-block-title .filter-selected-item {
  color: #807463;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 14px;
  margin: 6px 0 -3px;
  text-transform: none;
}
.filter-holder .filter-option-title {
  background-color: #f6f4f2;
  left: 0;
  padding: 20px 40px 13px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 5;
}
.filter-holder .filter-option-title .filter-option-back {
  font-size: 40px;
  left: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
}
.filter-holder .filter-option-title .text {
  color: #453f3f;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 22px;
  letter-spacing: 0.6px;
  line-height: 21px;
}
.filter-holder .filter-option-title .filter-option-close {
  font-size: 22px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-45%);
}
.filter-holder .filter-options-container {
  max-height: 100%;
  overflow: auto;
  padding: 80px 0 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.filter-holder .filter-options-container .filter-item {
  padding: 0 20px;
}
.filter-holder .filter-options-container label {
  border-bottom: 1px solid #d8d8d8;
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 16px 0 14px;
  position: relative;
  width: 100%;
}
.filter-holder .filter-options-container label input {
  height: 2px;
  left: 2px;
  opacity: 0;
  position: absolute;
  top: 22px;
  width: 2px;
}
.filter-holder .filter-options-container label input.focus-visible + .text::before {
  border-color: #886655;
  height: 28px;
  left: -3px;
  top: 13px;
  width: 28px;
}
.filter-holder .filter-options-container label input.focus-visible + .text::after {
  font-size: 14px;
  left: 3px;
}
.filter-holder .filter-options-container label input:checked + .text::before {
  background-color: #886655;
  border-color: #886655;
}
.filter-holder .filter-options-container label input:checked + .text::after {
  display: block;
}
.filter-holder .filter-options-container label input:disabled + .text, .filter-holder .filter-options-container label input[disabled=disabled] + .text {
  cursor: not-allowed;
  opacity: 0.26;
}
.filter-holder .filter-options-container label sup {
  color: #807463;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-left: 5px;
}
.filter-holder .filter-options-container label .text {
  background: transparent;
  cursor: pointer;
  padding-left: 43px;
}
.filter-holder .filter-options-container label .text::before {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  content: "";
  display: inline-block;
  height: 22px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 22px;
}
.filter-holder .filter-options-container label .text::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea2f";
  display: none;
  font-size: 10px;
  left: 5px;
  position: absolute;
  top: 15px;
}
.filter-holder .filter-options-container .filter-item-circle label .text {
  color: #626262;
}
@media (min-width: 768px) {
  .filter-holder .filter-options-container .filter-item-circle label .text {
    padding-left: 26px;
  }
}
.filter-holder .filter-options-container .filter-item-circle label .text::before {
  border-radius: 50%;
}
@media (min-width: 768px) {
  .filter-holder .filter-options-container .filter-item-circle label .text::before {
    height: 12px;
    width: 12px;
  }
}
.filter-holder .filter-options-container .filter-item-circle label .text:not(.white-product-color)::before {
  border-color: transparent;
}
.filter-holder .filter-options-container .filter-item-circle label .text.white-product-color::before {
  background-color: #ededed;
  border-color: #b4b4b4;
}
.filter-holder .filter-options-container .filter-item-circle label .text.multi-color-product-color::before {
  background: url("../images/filter-multicolor.png") left top no-repeat;
  background-size: 100% 100%;
  border: 0;
}
@media (max-width: 767px) {
  .filter-holder .filter-options-container .filter-item-circle label .text.multi-color-product-color::before {
    height: 24px;
    width: 24px;
  }
}
@media (min-width: 768px) {
  .filter-holder .filter-options-container .filter-item-circle label .text.multi-color-product-color::before {
    height: 14px;
    width: 14px;
  }
}
.filter-holder .filter-options-container .filter-item-circle label sup {
  color: #886655;
}
.filter-holder .filter-options-container .filter-item-circle label .text-checkbox {
  color: #626262;
  display: none;
  font-size: 8px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.filter-holder .filter-options-container .filter-item-circle label .text-checkbox::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
}
.filter-holder .filter-options-container .filter-item-circle label input:checked + .text {
  font-weight: bold;
}
@media (max-width: 767px) {
  .filter-holder .filter-options-container .filter-item-circle label input:checked + .text .text-checkbox {
    display: inline-block;
  }
}
.filter-holder .filter-options-container .filter-item-circle label input:checked + .text:not(.white-product-color)::before {
  border-color: transparent;
}
@media (min-width: 768px) {
  .filter-holder .filter-options-container .filter-item-circle label input:checked + .text:not(.multi-color-product-color)::before {
    height: 12px;
    width: 12px;
  }
}
.filter-holder .filter-options-container .filter-item-circle label input:checked + .text::after {
  border: 2px solid #ffffff;
  border-radius: 50%;
  content: "";
  left: 1px;
}
@media (max-width: 767px) {
  .filter-holder .filter-options-container .filter-item-circle label input:checked + .text::after {
    height: 18px;
    top: 17px;
    width: 18px;
  }
}
@media (min-width: 768px) {
  .filter-holder .filter-options-container .filter-item-circle label input:checked + .text::after {
    height: 8px;
    top: 3px;
    width: 8px;
  }
}
.filter-holder .filter-options-container .filter-item-circle label input:checked + .text sup {
  font-weight: normal;
}
.filter-holder .price-filter-wrapper {
  padding: 80px 0 20px;
}
.filter-holder .price-filter-wrapper .price-holder {
  display: flex;
  padding: 0 20px;
  width: 100%;
}
.filter-holder .price-filter-wrapper .price-holder .price-item-input {
  margin-right: 9px;
}
.filter-holder .price-filter-wrapper .price-holder .price-item-btn {
  margin-left: 21px;
}
.filter-holder .price-filter-wrapper .price-holder label {
  color: #1a1919;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-right: 5px;
}
.filter-holder .price-filter-wrapper .price-holder input {
  color: #1a1919;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 5px 10px 6px;
  width: 50px;
}
.filter-holder .price-filter-wrapper .price-holder input:focus, .filter-holder .price-filter-wrapper .price-holder input:active {
  border-color: #886655;
}
.filter-holder .price-filter-wrapper .price-holder input::-webkit-input-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.filter-holder .price-filter-wrapper .price-holder input::-moz-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.filter-holder .price-filter-wrapper .price-holder input:-ms-input-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.filter-holder .price-filter-wrapper .price-holder input:-moz-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.filter-holder .price-filter-wrapper .price-holder .btn {
  min-width: 63px;
  padding: 9px 20px 7px;
}
.filter-holder .price-filter-wrapper .price-holder .btn.btn--disabled {
  background-color: transparent;
  border-color: #d8d8d8;
  color: #d8d8d8;
}
@media (max-width: 767px) {
  .filter-holder .filter-block-title::after {
    content: "\ea1b";
  }
  .filter-holder .filter-options {
    background-color: #ffffff;
    bottom: 46px;
    display: none;
    position: fixed;
    right: -100%;
    top: 0;
    transition: right 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    z-index: 21;
  }
  .filter-holder .filter-options.visible.moved-left {
    right: 100%;
  }
}
@media (min-width: 768px) {
  .filter-holder .filters-container {
    padding: 27px 60px 27px 57px;
  }
  .filter-holder .filter-block {
    margin-bottom: 18px;
  }
  .filter-holder .filter-block-title {
    border-bottom: 0;
    padding: 13px 30px 13px 0;
  }
  .filter-holder .filter-block-title .filter-selected-item {
    display: none;
  }
  .filter-holder .filter-block-title::after {
    content: "\ea94";
    font-size: 27px;
  }
  .filter-holder .filter-block-title.open::after {
    content: "\ea78";
  }
  .filter-holder .filter-options {
    display: none;
    width: 100%;
  }
  .filter-holder .filter-options-container {
    margin-bottom: -11px;
    max-height: none;
    overflow: hidden;
    padding: 1px 0 0 3px;
    width: calc(100% + 20px);
  }
  .filter-holder .filter-options-container .filter-item {
    margin-bottom: 8px;
    padding: 0;
  }
  .filter-holder .filter-options-container label {
    border-bottom: 0;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 18px;
    padding: 0;
  }
  .filter-holder .filter-options-container label input {
    top: 6px;
  }
  .filter-holder .filter-options-container label input.focus-visible + .text::before {
    height: 16px;
    left: -3px;
    top: -1px;
    width: 16px;
  }
  .filter-holder .filter-options-container label input.focus-visible + .text::after {
    font-size: 8px;
    left: 1px;
  }
  .filter-holder .filter-options-container label .text {
    padding-left: 23px;
    line-height: 16px;
  }
  .filter-holder .filter-options-container label .text::before {
    height: 10px;
    top: 2px;
    width: 10px;
  }
  .filter-holder .filter-options-container label .text::after {
    font-size: 6px;
    left: 2px;
    top: 0;
  }
  .filter-holder .filter-option-title {
    display: none;
  }
  .filter-holder .price-filter-wrapper {
    padding: 0;
  }
  .filter-holder .price-filter-wrapper .price-holder {
    padding: 0;
  }
  .filter-holder .price-filter-wrapper .price-holder .price-item-btn {
    margin-left: auto;
  }
}

.filter-user-actions {
  bottom: -2px;
  display: flex;
  left: -100%;
  position: fixed;
  transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.filter-user-actions .btn {
  min-width: 10px;
  padding: 17px 7px 15px;
  width: 50%;
}
.filter-user-actions .btn:not(.btn-clear-all):hover {
  background: #626262;
  border-color: #626262;
  color: #ffffff;
}
.filter-user-actions .btn-clear-all {
  background-color: #f6f4f2;
  border-color: #f6f4f2;
  color: #111;
}
.filter-user-actions .btn-clear-all:hover {
  background-color: #ffffff;
  border-color: #1a1919;
  color: #453f3f;
}
@media (min-width: 768px) {
  .filter-user-actions {
    width: 360px;
  }
}
@media (min-width: 1024px) {
  .filter-user-actions {
    bottom: 0;
  }
}

.active-refinements-list {
  width: 100%;
}
.active-refinements-list .active-refinements-inner {
  margin-bottom: 21px;
  text-transform: none;
}
.active-refinements-list .filter-tags-title {
  color: #968977;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  letter-spacing: 0.9px;
  line-height: 16px;
  margin-bottom: 21px;
}
.active-refinements-list .filter-tag {
  background-color: rgba(216, 216, 216, 0.1);
  border-radius: 16px;
  color: #ffffff;
  float: left;
  font-size: 12px;
  letter-spacing: 0.75px;
  line-height: 14px;
  margin: 0 10px 10px 0;
  padding: 10px 9px 8px 12px;
}
.active-refinements-list .remove-filter-button {
  color: rgba(255, 255, 255, 0.36);
  display: inline-block;
  margin: -1px 0 0 6px;
  text-decoration: none;
  vertical-align: middle;
}
.active-refinements-list .remove-filter-button .icon {
  line-height: 1;
}

.tabs-holder {
  text-align: center;
  width: 100%;
}
@media (max-width: 767px) {
  .tabs-holder {
    display: none;
  }
}
.tabs-holder .tabs-items-holder {
  width: 100%;
}
.tabs-holder .tabs {
  font-size: 0;
  list-style: none;
  margin-bottom: 9px;
  width: 100%;
}
.tabs-holder .tabs li {
  display: inline-block;
  padding: 0 25px;
  position: relative;
}
.tabs-holder .tabs .tab-item {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2.08px;
  line-height: 14px;
  padding: 14px 0 7px;
  position: relative;
  text-transform: uppercase;
}
.tabs-holder .tabs .tab-item::after {
  background-color: #453f3f;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 0;
}
.tabs-holder .tabs .tab-item.selected, .tabs-holder .tabs .tab-item:focus, .tabs-holder .tabs .tab-item:hover {
  color: #1a1919;
}
.tabs-holder .tabs .tab-item.selected::after, .tabs-holder .tabs .tab-item:focus::after, .tabs-holder .tabs .tab-item:hover::after {
  width: 100%;
}
.tabs-holder .tabs-content {
  width: 100%;
}
.tabs-holder .tab-content {
  position: absolute;
  opacity: 0;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  max-width: 640px;
  z-index: -1;
  color: #453f3f;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 33px 0 0;
  width: 100%;
}
.tabs-holder .tab-content.active {
  position: static;
  opacity: 1;
  transform: none;
  transition: opacity 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  z-index: 0;
}
.tabs-holder .tab-content p, .tabs-holder .tab-content h4 {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 20px;
}
.tabs-holder .tab-content h4 {
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: none;
}
.tabs-holder .tab-content-formatted {
  white-space: pre-line;
}
.tabs-holder .shippingPromotionTable {
  margin: 0 auto 10px;
}
.tabs-holder .shippingPromotionTable p {
  margin-bottom: 16px;
}
.tabs-holder .ingredients-tab .tab-content-inner {
  margin-bottom: 36px;
  max-height: 170px;
  overflow: hidden;
  transition: 0.8s ease;
  width: 100%;
}
.tabs-holder .ingredients-tab .btn {
  display: none;
  min-width: 200px;
}
.tabs-holder .ingredients-tab .btn .less {
  display: none;
}
.tabs-holder .ingredients-tab .btn.active .more {
  display: none;
}
.tabs-holder .ingredients-tab .btn.active .less {
  display: block;
}
.tabs-holder .ingredients-tab.expanded .tab-content-inner {
  max-height: 5000px;
}
.tabs-holder .ingredients-tab.hidden-content .btn {
  display: inline-block;
}
@media (min-width: 1024px) {
  .tabs-holder .tab-content {
    padding: 30px 0;
  }
  .tabs-holder .shippingPromotionTable {
    margin-bottom: 15px;
  }
}

.popup-generic-holder {
  left: 50% !important;
  max-width: 100%;
  position: fixed;
  top: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.popup-generic-holder .ui-dialog-titlebar {
  padding: 0;
}
.popup-generic-holder .ui-dialog-titlebar .ui-dialog-titlebar-close {
  height: auto;
  margin: 0;
  padding: 0;
  right: 42px;
  top: calc(100% + 31px);
  width: auto;
  z-index: 5;
}
.popup-generic-holder .ui-dialog-titlebar .ui-button-icon {
  font-size: 30px;
  left: auto;
  line-height: 1;
  margin-left: 0;
  position: relative;
  text-indent: 0;
}
.popup-generic-holder .ui-dialog-titlebar .ui-button-icon::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea36";
  display: inline-block;
}
.popup-generic-holder .popup-generic {
  padding: 0 20px;
}
.popup-generic-holder .pdp-main-quickview {
  padding: 40px 40px 20px;
}
.popup-generic-holder .pdp-main-quickview .product-options {
  padding-top: 10px;
}
.popup-generic-holder .pdp-main-quickview .product-options .currency-code {
  display: block;
  margin-top: -14px;
}
.popup-generic-holder .pdp-main-quickview .swatches-wrapper {
  justify-content: center;
}
@media (max-width: 767px) {
  .popup-generic-holder .pdp-main-quickview {
    padding: 50px 0 20px;
  }
  .popup-generic-holder .pdp-main-quickview .product-options {
    max-width: 280px;
  }
  .popup-generic-holder .product-name {
    font-size: 20px;
    letter-spacing: 4px;
    line-height: 30px;
  }
}
@media (min-width: 768px) {
  .popup-generic-holder .pdp-main-quickview .swatches-wrapper {
    margin: 0 auto -20px;
    max-width: 280px;
  }
}

.ui-autocomplete.ui-menu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.ui-autocomplete.ui-menu .ui-menu-item {
  color: #1a1919;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  list-style: none;
  padding: 0;
}
.ui-autocomplete.ui-menu .ui-menu-item:not(:last-of-type) {
  border-bottom: 1px solid #d8d8d8;
}
.ui-autocomplete.ui-menu .ui-menu-item-wrapper {
  padding: 12px 17px 8px;
}
.ui-autocomplete.ui-menu .ui-menu-item-wrapper.ui-state-active {
  margin: 0;
}
.ui-autocomplete.ui-menu .ui-menu-item-wrapper:hover, .ui-autocomplete.ui-menu .ui-menu-item-wrapper.ui-state-focus {
  background-color: #f6f4f2;
}

.advent-content-blocks-wrapper {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #f6f4f2;
}
@media (min-width: 768px) {
  .advent-content-blocks-wrapper {
    border-bottom: 0;
  }
  .advent-content-blocks-wrapper.advent-content-block-reverted .advent-cols-holder .advent-col-text {
    justify-content: flex-start;
    margin-left: auto;
  }
  .advent-content-blocks-wrapper.advent-content-block-reverted .advent-cols-holder .advent-col-image {
    left: 0;
    right: 0;
  }
  .advent-content-blocks-wrapper.advent-content-block-reverted .advent-overlay {
    left: auto;
    right: 0;
  }
  .advent-content-blocks-wrapper.advent-content-block-reverted .advent-overlay .advent-overlay-inner {
    margin-left: 100px;
  }
}

.advent-gradient {
  background: #122343;
}
.advent-gradient.primary-content {
  background: linear-gradient(#0a152b 20%, #263762);
}
.advent-gradient .simple-text-block {
  padding: 30px 0;
}
@media (min-width: 768px) {
  .advent-gradient .simple-text-block {
    padding: 30px 0;
  }
}
.advent-gradient .home-page .simple-text-block {
  padding: 30px 0;
}
@media (min-width: 768px) {
  .advent-gradient .home-page .simple-text-block {
    padding: 30px 0;
  }
}

.advent-cols-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.advent-cols-holder .advent-col {
  width: 100%;
}
.advent-cols-holder .text-holder {
  color: #000000;
  padding: 37px 30px 28px;
  text-align: center;
  width: 100%;
}
.advent-cols-holder .text-holder .advent-description-wrapper {
  display: none;
}
.advent-cols-holder .text-holder .advent-subtitle {
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 23px;
  text-transform: uppercase;
}
.advent-cols-holder .text-holder .advent-title {
  font-size: 30px;
  text-transform: none;
  letter-spacing: 4px;
  line-height: 50px;
  margin: -4px 0 5px;
}
.advent-cols-holder .text-holder .advent-description {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 35px;
  margin-bottom: 22px;
}
.advent-cols-holder .text-holder .advent-disclaimer {
  color: #232121;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  opacity: 0.5;
}
.advent-cols-holder .product-tile {
  margin-bottom: 38px;
}
.advent-cols-holder .product-tile .product-details-content .product-add-to-cart {
  margin-bottom: 23px;
}
.advent-cols-holder .product-tile .base-price-block + .price-discount-text {
  margin-top: 13px;
}
.advent-cols-holder .product-tile .price-discount-text {
  color: rgba(26, 25, 25, 0.5);
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin: 8px 0 -4px;
}
.advent-cols-holder .product-tile .product-title {
  display: none;
}
.advent-cols-holder .responsive-bg {
  height: 350px;
  position: relative;
  max-height: 100vh;
}
@media (min-width: 768px) {
  .advent-cols-holder .responsive-bg {
    height: 668px;
  }
}
.advent-cols-holder .advent-image-button {
  bottom: 60px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
}
.advent-cols-holder .advent-image-button .pulsating-button-wrapper {
  align-items: center;
  bottom: auto;
  background-color: #ffffff;
  border-radius: 25px;
  height: 44px;
  padding-right: 25px;
  overflow: visible;
}
.advent-cols-holder .advent-image-button .pulsating-button {
  bottom: auto;
  height: 44px;
  width: 44px;
}
.advent-cols-holder .advent-image-button .pulsating-button::before {
  background-size: 30px 30px;
}
.advent-cols-holder .advent-image-button .pulsating-button::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  font-size: 22px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.advent-cols-holder .advent-image-button .advent-btn-text {
  color: #1a1919;
  display: inline-block;
  font-size: 12px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .advent-cols-holder {
    flex-direction: row;
    overflow: hidden;
    position: relative;
  }
  .advent-cols-holder .advent-col {
    align-items: center;
    display: flex;
    min-height: 760px;
  }
  .advent-cols-holder .advent-col-text {
    justify-content: flex-end;
    width: 50%;
  }
  .advent-cols-holder .advent-col-text.advent-col-text-mobile {
    display: none;
  }
  .advent-cols-holder .text-holder {
    max-width: 720px;
    padding: 62px 50px 50px;
  }
  .advent-cols-holder .text-holder .advent-description-wrapper {
    display: block;
  }
  .advent-cols-holder .text-holder .advent-title {
    font-size: 40px;
    letter-spacing: 11px;
    line-height: 54px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .advent-cols-holder .text-holder .advent-subtitle {
    letter-spacing: 5px;
  }
  .advent-cols-holder .text-holder .advent-description {
    max-width: 500px;
    margin: 0 auto 22px;
  }
  .advent-cols-holder .product-tile {
    margin-bottom: 32px;
  }
  .advent-cols-holder .product-tile .product-details-content {
    margin: 0 auto;
  }
  .advent-cols-holder .advent-col-image {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }
  .advent-cols-holder .responsive-bg {
    height: 100%;
    max-height: none;
  }
  .advent-cols-holder .advent-image-button .pulsating-button-wrapper {
    padding-right: 20px;
  }
}

.advent-overlay {
  background-color: #ffffff;
  bottom: 0;
  overflow: hidden;
  top: 0;
  width: 0;
}
.advent-overlay .advent-nav {
  display: none;
}
.advent-overlay .advent-arrow {
  background-color: rgba(26, 25, 25, 0.4);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  font-size: 44px;
  height: 51px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 51px;
  z-index: 6;
}
.advent-overlay .advent-arrow:hover {
  background-color: rgba(26, 25, 25, 0.6);
}
.advent-overlay .advent-arrow::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  display: inline-block;
}
.advent-overlay .advent-arrow-prev {
  left: 20px;
}
.advent-overlay .advent-arrow-prev::before {
  content: "\ea1a";
}
.advent-overlay .advent-arrow-next {
  right: 20px;
}
.advent-overlay .advent-arrow-next::before {
  content: "\ea1b";
}
.advent-overlay .advent-overlay-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  width: 100%;
}
.advent-overlay .advent-overlay-header {
  padding: 84px 30px 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.advent-overlay .advent-overlay-header .advent-close {
  font-size: 20px;
  left: 18px;
  position: absolute;
  top: 13px;
}
.advent-overlay .advent-overlay-header .advent-close::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea36";
}
.advent-overlay .advent-overlay-header .advent-overlay-title {
  color: #1a1919;
  font-size: 13px;
  letter-spacing: 4px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .advent-overlay {
    left: -100%;
    position: fixed;
    width: 100%;
    z-index: 891;
  }
  .advent-overlay .advent-overlay-inner {
    margin-left: -50px;
    overflow: auto;
  }
}
@media (min-width: 768px) {
  .advent-overlay {
    display: none;
    left: 0;
    position: absolute;
    width: 50%;
  }
  .advent-overlay .advent-overlay-inner {
    margin-left: -100px;
  }
  .advent-overlay .advent-overlay-header {
    padding: 55px 20px 28px;
  }
  .advent-overlay .advent-overlay-header .advent-close {
    font-size: 30px;
    left: auto;
    right: 30px;
    top: 22px;
  }
  .advent-overlay .advent-overlay-header .advent-overlay-title {
    font-size: 15px;
    letter-spacing: 5px;
    line-height: 23px;
    margin: 0 auto 10px;
    max-width: 500px;
  }
}

.advent-overlay-slider {
  position: relative;
  width: 100%;
  max-height: 70vh;
}
.advent-overlay-slider .advent-slider-image-holder {
  width: 100%;
  text-align: center;
}
.advent-overlay-slider .slider-advent {
  max-height: 90vh;
}
.advent-overlay-slider .slider-advent .slick-track {
  background-repeat: no-repeat;
  background-size: contain;
}
.advent-overlay-slider .slider-dots {
  bottom: 37px;
}
@media (min-width: 768px) {
  .advent-overlay-slider .slider-dots {
    bottom: 30px;
  }
}

.advent-overlay-slider-bar {
  background-color: #e5e5e5;
  border-radius: 2px;
  display: none;
  height: 2px;
  margin: auto auto 37px;
  max-width: 200px;
  position: relative;
  width: 100%;
}
.advent-overlay-slider-bar .indicator {
  display: block;
  position: absolute;
  background-color: #453f3f;
  height: 100%;
  border-radius: 4px;
  left: 0;
  transition: left 0.2s ease-out;
}
@media (min-width: 768px) {
  .advent-overlay-slider-bar {
    height: 4px;
    margin: 15px auto 30px;
    max-width: 300px;
  }
}

@media (max-width: 767px) {
  .zoomed-in .advent-overlay-slider .slider-dots {
    bottom: -2px;
  }
}

@media (min-width: 768px) {
  .advent-overlay-slider .drag-list .slider-advent {
    padding-bottom: 15px;
  }
}
.advent-overlay-slider .drag-list .slider-advent .drag-list-body {
  background-position: center top;
  background-repeat: no-repeat;
  padding: 0;
}
.advent-overlay-slider .drag-list .simplebar-track.simplebar-horizontal {
  left: 50%;
  margin: 0;
  right: auto;
  transform: translateX(-50%);
  width: 300px;
}

.advent-shimmer-text {
  display: inline-block;
  background: linear-gradient(45deg, #c4b799 10%, #eeccaf 48%, #fce9c6 51%, #fce9c6 52%, #fce9c6 54%, #eeccaf 57%, #c4b799 90%);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s ease infinite;
}
@keyframes shine {
  0% {
    background-position: 200% 0%;
  }
  50% {
    background-position: 0% 50%;
  }
}

.advent-out-of-stock-tape-wrapper,
.ticker-tape-wrapper {
  display: block;
  height: 16px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.advent-out-of-stock-tape-wrapper {
  background-color: #d8d8d8;
}

.advent-out-of-stock-tape,
.ticker-tape {
  display: block;
  height: 20px;
  margin: auto auto;
  margin-top: -7px;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
.advent-out-of-stock-tape span,
.ticker-tape span {
  animation: ticker-animation 10s linear infinite;
  color: #ffffff;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 10px;
  height: 100%;
  letter-spacing: 1.6px;
  line-height: 16px;
  margin-right: 15px;
  min-width: 100%;
  padding-left: 90px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.advent-out-of-stock2-tape span,
.ticker-tape2 span {
  animation-delay: 5s;
}

.grayScaled {
  filter: grayscale(0.5);
}

@keyframes ticker-animation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.advent-comparison-block {
  padding: 50px 0;
}
@media (max-width: 767px) {
  .advent-comparison-block {
    padding: 20px 0;
  }
}
@media (min-width: 1024px) {
  .advent-comparison-block {
    margin-top: 250px;
  }
}
.advent-comparison-block .advent-comparison-header {
  color: #ffffff;
  font-size: 56px;
  letter-spacing: 2px;
  line-height: 64px;
  margin-bottom: 60px;
  text-align: center;
  text-transform: initial;
  opacity: 0;
}
@media (max-width: 767px) {
  .advent-comparison-block .advent-comparison-header {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}
.advent-comparison-block .advent-comparison-desc {
  color: #ffffff;
  margin: 0 25px 10px;
  opacity: 0;
}
.advent-comparison-block .dm-responsive-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.advent-comparison-block .advent-comparison-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.advent-desktop-sequence-wrapper {
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 1920px;
}
.advent-desktop-sequence-wrapper .video-wrapper {
  width: 50%;
}
.advent-desktop-sequence-wrapper .video-wrapper .video-element {
  width: 100%;
  height: 130%;
  transform: translateY(-13%);
}
.advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox {
  padding: 0 20px 0 9%;
  position: relative;
  width: 50%;
}
@media (max-width: 767px) {
  .advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox {
    padding: 20px 30px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox {
    padding: 0 20px 0 40px;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox {
    padding: 0 20px 0 30px;
  }
}
@media (min-width: 1440px) {
  .advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox {
    padding: 0 20px 0 13%;
  }
}
.advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox .text-holder {
  margin-bottom: 50px;
  width: auto;
  padding: 0;
}
.advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox .advent-desktop-sequence-pos {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.advent-desktop-sequence-wrapper .advent-product-reveal {
  position: initial;
  pointer-events: initial;
}
.advent-desktop-sequence-wrapper .advent-product-reveal .advent-product-btn {
  opacity: 1;
}

.advent-comparison-box {
  display: flex;
  justify-content: space-between;
  opacity: 0;
  height: 85%;
}
@media (max-width: 767px) {
  .advent-comparison-box {
    margin-bottom: 50px;
  }
}
.advent-comparison-box .slick-track {
  display: flex;
}
.advent-comparison-box .slick-slide {
  margin: 0 15px;
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-comparison-box .slick-slide {
    margin: 0 10px;
  }
  .advent-comparison-box .slick-slide:first-of-type {
    margin-left: 0;
  }
  .advent-comparison-box .slick-slide:last-of-type {
    margin-right: 0;
  }
}
.advent-comparison-box .slick-current {
  opacity: 1;
}

.advent-comparison-t {
  width: 100%;
}

.advent-comparison-item {
  margin: 0 25px;
  width: 33%;
  opacity: 0;
}
@media (max-width: 767px) {
  .advent-comparison-item {
    margin: 0;
  }
}
@media (max-width: 420px) {
  .advent-comparison-item {
    width: 300px !important;
  }
}
.advent-comparison-item .subhead-wrapper {
  align-items: center;
  display: flex;
  max-width: 370px;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .advent-comparison-item .subhead-wrapper {
    width: 100%;
    max-width: none;
  }
}
.advent-comparison-item .subhead-wrapper span {
  padding: 5px 18px;
  background: linear-gradient(95.69deg, rgb(255, 255, 255) 20%, rgb(243, 243, 243) 40%, rgb(255, 255, 255) 60%, rgb(235, 237, 241) 80%, rgb(250, 250, 255) 100%);
  color: #1a1919;
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  margin-bottom: 10px;
}
.advent-comparison-item .advent-comparison-out-of-stock-label {
  text-align: center;
  font-size: 12px;
  color: #a88b6b;
  transform: translateX(-25px);
  margin-top: 20px;
  cursor: pointer;
}

.advent-comparison-item.advent-premium-item .subhead-wrapper span {
  background: linear-gradient(45deg, rgb(228, 214, 181) 20%, rgb(231, 208, 177) 55%, rgb(255, 255, 255) 60%, rgb(231, 208, 177) 65%, rgb(228, 214, 181) 100%);
  background-size: 500% 500%;
  animation: gradient-animation 5s ease infinite;
}

.disable-event {
  opacity: 40%;
  pointer-events: none;
}

.advent-comparison-subhead {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 32px;
  letter-spacing: 1.2px;
  line-height: 40px;
  text-decoration: none;
  text-transform: initial;
}
@media (max-width: 767px) {
  .advent-comparison-subhead {
    margin: 15px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-comparison-subhead {
    font-size: 28px;
    letter-spacing: 0.5;
  }
}
@media (min-width: 768px) {
  .advent-comparison-subhead {
    margin: 15px 0 0 0;
  }
}

.advent-comparison-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 6px;
}
@media (max-width: 767px) {
  .advent-comparison-text {
    max-width: 230px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-comparison-text {
    font-size: 14px;
    line-height: 22px;
  }
}

.advent-comparison-price {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.03rem;
  margin: 0;
}
@media (min-width: 768px) {
  .advent-comparison-price.has-discount {
    bottom: 24px;
  }
}
@media (min-width: 768px) {
  .advent-comparison-price:not(.has-discount) {
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .advent-comparison-price {
    position: absolute;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .advent-comparison-price {
    margin-top: 15px;
  }
}

.advent-comparison-beforediscount-price {
  color: #ffffff;
  line-height: 20px;
  margin-bottom: 0;
  text-decoration-line: line-through;
}
@media (max-width: 767px) {
  .advent-comparison-beforediscount-price {
    font-size: 16px;
    letter-spacing: 0.4px;
  }
}
@media (min-width: 768px) {
  .advent-comparison-beforediscount-price {
    bottom: 0;
    font-size: 20px;
    letter-spacing: 0.6px;
    position: absolute;
  }
}

.advent-comparison-calendar {
  border: 1px solid #ffffff;
  margin-bottom: 28px;
  position: relative;
  vertical-align: middle;
  width: 100%;
  display: flex;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-comparison-calendar {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .advent-comparison-calendar {
    max-width: 350px;
    margin: 0 5px;
  }
}

.advent-comparison-redir {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.advent-comparison-value {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
  border-radius: 0 2px 2px 0;
  left: 0;
  position: absolute;
  top: 25px;
}
.advent-comparison-value.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.advent-comparison-value.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.advent-comparison-value.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.advent-comparison-value.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.advent-comparison-value.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.advent-comparison-value.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}

.advent-comparion-worth {
  color: #1a1919;
  letter-spacing: 0.06em;
  margin: 0;
}
@media (max-width: 767px) {
  .advent-comparion-worth {
    font-size: 10px;
    line-height: 16px;
  }
}
@media (min-width: 768px) {
  .advent-comparion-worth {
    font-size: 12px;
    line-height: 19px;
  }
}

.advent-calendar-wrapper {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 60px;
  width: 100%;
  height: 100%;
}
@media (max-width: 1023px) {
  .advent-calendar-wrapper {
    padding: 0;
  }
}

.advent-comparison-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-comparison-info {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .advent-comparison-info {
    align-items: flex-end;
    margin: 0 5px;
  }
}
@media (min-width: 768px) {
  .advent-comparison-info {
    align-items: flex-start;
    min-height: 200px;
  }
}
.advent-comparison-info form {
  position: absolute;
  bottom: 0;
  right: 0;
}
.advent-comparison-info .btn {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #b4b4b4;
  color: #ffffff;
  font-size: 0;
  height: 56px;
  letter-spacing: 0;
  line-height: 0;
  max-width: none;
  min-width: auto;
  padding: 0;
  text-transform: none;
  transition: border 0.2s ease-in-out;
  width: 56px;
}
.advent-comparison-info .btn:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2a";
}
.advent-comparison-info .btn::before {
  display: block;
  font-size: 20px;
  line-height: 20px;
  margin-top: 17px;
}
.advent-comparison-info .btn:hover {
  color: #ffffff;
}
.advent-comparison-info .btn.btn-out-of-stock::before {
  margin-top: 0;
}

.advent-comparison-position {
  position: relative;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 25px;
}
@media (min-width: 768px) {
  .advent-comparison-position {
    display: none;
  }
}

.advent-comparison-line {
  position: absolute;
  left: 0;
  top: 0;
  width: 33.33%;
  height: 2px;
  background-color: #ffffff;
  transition: left 0.2s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}

.advent-shimmer-frame {
  border-radius: 2px;
  z-index: 10;
}
.advent-shimmer-frame::after {
  position: absolute;
  content: "";
  top: calc(-1 * 2px);
  left: calc(-1 * 2px);
  z-index: -1;
  width: calc(100% + 2px *2);
  height: calc(100% + 2px * 2);
  background: linear-gradient(45deg, #e4d6b5 20%, #e7d0b1 50%, #ffffff 57% 61%, #e7d0b1 65%, #e4d6b5 100%);
  background-size: 300% 300%;
  border-radius: calc(2 * 2px);
  animation: shimmering 6s ease infinite;
}
.advent-shimmer-frame.advent-swatch-item::after {
  background: linear-gradient(90deg, #9a7b69 5%, #cdbda4, #e2d4b3, #e2d4b3, #cdbda4, #9a7b69 95%);
  background-size: 300% 300%;
}

@keyframes shimmering {
  0% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.advent-block-snap {
  width: 100%;
  overflow: hidden;
}
.advent-block-snap.advent-comparison-block {
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-block-snap.christmas-tree-mobile {
    height: 700px;
  }
}
@media (min-width: 1024px) {
  .advent-block-snap {
    height: 100vh;
  }
}

.summer-box-product-full.christmas-tree-mobile {
  background-color: #0181A1;
}
.summer-box-product-full.christmas-tree-mobile .text-holder {
  margin-bottom: unset;
  max-width: none;
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .text-holder {
    max-width: unset;
    position: absolute;
    bottom: 130px;
    text-align: center;
    padding: 0 30px;
  }
}
.summer-box-product-full.christmas-tree-mobile .text-holder .headline {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .text-holder .headline {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    max-width: 100%;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .summer-box-product-full.christmas-tree-mobile .text-holder .headline {
    padding-right: 20px;
  }
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal {
  bottom: unset;
  left: unset;
  position: relative;
  transform: unset;
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .advent-product-reveal {
    bottom: unset;
    left: unset;
    position: relative;
    transform: unset;
    padding: 80px 0 72px;
  }
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal .advent-product-btn {
  box-shadow: unset;
  max-width: 280px;
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal .advent-product-btn:hover {
  box-shadow: unset;
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal .advent-product-btn::after {
  background: unset;
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .advent-product-reveal .advent-product-btn {
    min-width: 280px;
  }
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal .btn-flip::before {
  color: #1a1919;
  margin-left: unset;
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal .btn-flip::after {
  color: #1a1919;
  left: 0;
}
.summer-box-product-full.christmas-tree-mobile .advent-product-reveal .btn-flip .icon-key-skeleton-thin {
  display: inline-block;
  font-size: 22px;
  position: absolute;
  left: 45px;
  top: 50%;
  transform: scaleX(-1) translateY(-50%);
}
.summer-box-product-full.christmas-tree-mobile .gifts-container .text-holder {
  position: relative;
  bottom: unset;
  padding: 0 45px 0 30px;
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .image-holder::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(180deg, rgba(1, 128, 161, 0) 0%, #0180A1 100%);
    bottom: 0;
    height: 300px;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .advent-desktop-sequence-wrapper {
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) {
  .summer-box-product-full.christmas-tree-mobile .advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox {
    order: -1;
  }
}
@media (max-width: 767px) {
  .summer-box-product-full.christmas-tree-mobile .advent-desktop-sequence-wrapper .advent-desktop-sequence-textbox .advent-desktop-sequence-pos {
    position: relative;
    top: unset;
    transform: unset;
  }
}

.advent-tile .inner-wrapper {
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .advent-tile .inner-wrapper {
    flex-direction: column;
    padding: 50px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-tile .inner-wrapper {
    align-items: unset;
    flex-direction: column;
    padding: 50px 0 0;
  }
}
.advent-tile.specification-block .inner-wrapper, .advent-tile.advent-ar-block .inner-wrapper, .advent-tile.gift-wrap-block .inner-wrapper {
  max-width: 1920px;
  padding: 0;
}
.advent-tile.specification-block .inner-wrapper .text-holder, .advent-tile.advent-ar-block .inner-wrapper .text-holder, .advent-tile.gift-wrap-block .inner-wrapper .text-holder {
  margin: 0 20px 0 9%;
}
@media (max-width: 767px) {
  .advent-tile.specification-block .inner-wrapper .text-holder, .advent-tile.advent-ar-block .inner-wrapper .text-holder, .advent-tile.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0;
    padding: 20px 30px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-tile.specification-block .inner-wrapper .text-holder, .advent-tile.advent-ar-block .inner-wrapper .text-holder, .advent-tile.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0 20px 0 40px;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-tile.specification-block .inner-wrapper .text-holder, .advent-tile.advent-ar-block .inner-wrapper .text-holder, .advent-tile.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0;
  }
}
@media (min-width: 1440px) {
  .advent-tile.specification-block .inner-wrapper .text-holder, .advent-tile.advent-ar-block .inner-wrapper .text-holder, .advent-tile.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0 20px 0 13%;
  }
}
.advent-tile .image-holder {
  position: relative;
  width: 50%;
}
@media (max-width: 767px) {
  .advent-tile .image-holder {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-tile .image-holder {
    width: 100%;
  }
}
.advent-tile .image-holder .scrollmagic-pin-spacer {
  padding-bottom: 0 !important;
}
.advent-tile .text-holder {
  position: relative;
  max-width: 450px;
  width: 50%;
  z-index: 10;
}
@media (max-width: 767px) {
  .advent-tile .text-holder {
    padding: 0 30px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-tile .text-holder {
    width: 100%;
    max-width: 500px;
    margin: 0;
    padding: 40px 30px;
  }
}
.advent-tile .headline {
  color: #ffffff;
  font-size: 32px;
  letter-spacing: 0.6px;
  line-height: 40px;
  margin-bottom: 25px;
  text-transform: initial;
  opacity: 0;
}
@media (max-width: 767px) {
  .advent-tile .headline {
    margin-bottom: 12px;
    max-width: 250px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-tile .headline {
    font-size: 48px;
    letter-spacing: 2px;
    line-height: 56px;
  }
}
@media (min-width: 768px) {
  .advent-tile .headline {
    font-size: 56px;
    letter-spacing: 2px;
    line-height: 64px;
  }
}
.advent-tile .responsive-bg {
  height: 100vh;
}
@media (max-width: 767px) {
  .advent-tile .responsive-bg {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-tile .responsive-bg {
    height: 700px;
  }
}

.specification-block .specification-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-bottom: 20px;
  opacity: 0;
}
@media (min-width: 768px) {
  .specification-block .specification-text {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 35px;
  }
}
.specification-block .image-holder {
  height: 100%;
}
.specification-block .specification-box {
  display: flex;
  flex-wrap: wrap;
  max-width: 420px;
  opacity: 0;
}
@media (max-width: 767px) {
  .specification-block .specification-box {
    margin-bottom: 10px;
  }
}
.specification-block .specification-fact {
  background-color: #1c2c4c;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 18px;
  margin: 0 10px 10px 0;
  padding: 16px 26px;
  opacity: 0;
}
.specification-block .item {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .specification-block .item {
    height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .specification-block .item {
    height: 100%;
  }
}
.specification-block .responsive-bg {
  transform: scale(1.3);
}

.advent-ar-block .btn {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  min-width: 315px;
  background-color: transparent;
  border-color: #ffffff;
  margin-bottom: 20px;
  opacity: 0;
  transition: none;
}
.advent-ar-block .btn:hover {
  color: #ffffff;
}
.advent-ar-block .btn .advent-ar-icon {
  position: absolute;
  left: 20px;
  font-size: 25px;
  transform: rotate(-90deg) scale(0);
  margin-right: 30px;
  opacity: 0;
}
.advent-ar-block .item {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .advent-ar-block .item {
    height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-ar-block .item {
    height: 100%;
  }
}
.advent-ar-block .responsive-bg {
  transform: scale(1.3);
}
.advent-ar-block .advent-ar-text {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  max-width: 320px;
  opacity: 0;
}

.advent-tile-reverse.specification-block .inner-wrapper .text-holder, .advent-tile-reverse.advent-ar-block .inner-wrapper .text-holder, .advent-tile-reverse.gift-wrap-block .inner-wrapper .text-holder {
  margin: 0 9% 0 20px;
}
@media (max-width: 767px) {
  .advent-tile-reverse.specification-block .inner-wrapper .text-holder, .advent-tile-reverse.advent-ar-block .inner-wrapper .text-holder, .advent-tile-reverse.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-tile-reverse.specification-block .inner-wrapper .text-holder, .advent-tile-reverse.advent-ar-block .inner-wrapper .text-holder, .advent-tile-reverse.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0 40px 0 20px;
  }
}
@media (min-width: 1440px) {
  .advent-tile-reverse.specification-block .inner-wrapper .text-holder, .advent-tile-reverse.advent-ar-block .inner-wrapper .text-holder, .advent-tile-reverse.gift-wrap-block .inner-wrapper .text-holder {
    margin: 0 13% 0 20px;
  }
}
@media (min-width: 768px) {
  .advent-tile-reverse .image-holder {
    order: -1;
  }
}

.gift-wrap-block .headline {
  opacity: 1;
}
.gift-wrap-block .gift-wrap-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-bottom: 20px;
  opacity: 0;
}
@media (min-width: 768px) {
  .gift-wrap-block .gift-wrap-text {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 35px;
  }
}
.gift-wrap-block .gift-wrap-play {
  align-items: center;
  display: flex;
  opacity: 0;
  text-decoration: none;
}
@media (max-width: 767px) {
  .gift-wrap-block .gift-wrap-play {
    margin-bottom: 30px;
  }
}
.gift-wrap-block .gift-wrap-video-text {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0;
}
.gift-wrap-block .gift-wrap-time {
  font-size: 14px;
  letter-spacing: 0.245em;
  color: #ffffff;
  margin-bottom: 0;
}
.gift-wrap-block .gift-wrap-button {
  width: 64px;
  height: 64px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-right: 20px;
}
.gift-wrap-block .gift-wrap-button span {
  margin-left: 2px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .advent-show .specification-text,
  .advent-show .specification-fact,
  .advent-show .specification-box,
  .advent-show .advent-ar-text,
  .advent-show .btn {
    opacity: 1;
  }
  .advent-show .specification-box {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.9s;
  }
  .advent-show .specification-text {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.7s;
  }
  .advent-show .btn {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.9s;
  }
  .advent-show .btn .advent-ar-icon {
    transform: rotate(0) scale(1);
  }
  .advent-show.advent-ar-block .image-holder {
    position: relative;
  }
  .advent-show.advent-ar-block .headline {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.5s;
  }
  .advent-show.advent-ar-block .advent-ar-text {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 1.5s;
  }
  .advent-show.specification-block .headline {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.5s;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .advent-show .specification-text,
  .advent-show .specification-fact,
  .advent-show .specification-box,
  .advent-show .advent-ar-text,
  .advent-show .btn {
    opacity: 1;
  }
  .advent-show .specification-box {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.9s;
  }
  .advent-show .specification-text {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.7s;
  }
  .advent-show .btn {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.9s;
  }
  .advent-show .btn .advent-ar-icon {
    transform: rotate(0) scale(1);
  }
  .advent-show.advent-ar-block .image-holder {
    position: relative;
  }
  .advent-show.advent-ar-block .headline {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.5s;
  }
  .advent-show.advent-ar-block .advent-ar-text {
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 1.5s;
  }
  .advent-show.specification-block .headline {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.5s;
  }
}
@media (max-width: 1023px) {
  .advent-show.calendar-overview-block .calendar-overview-header {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.5s;
  }
  .advent-show.calendar-overview-block .calendar-overview-text {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.7s;
  }
  .advent-show.calendar-overview-block .calender-overview-box {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.9s;
  }
}
@media (max-width: 767px) {
  .advent-show .content-list-wrapper {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 1.1s;
  }
  .advent-show .calendar-overview-disclamer {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 1.3s;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .advent-show .content-list-wrapper,
  .advent-show .calendar-overview-disclamer {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.7s;
  }
  .advent-show.christmas-tree-mobile .headline {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    transition-delay: 0.5s;
  }
}
.advent-show.gift-wrap-block .headline {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show.gift-wrap-block .gift-wrap-text {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  transition-delay: 0.3s;
}
.advent-show.gift-wrap-block .gift-wrap-play {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  transition-delay: 0.5s;
}
.advent-show .specification-fact {
  opacity: 1;
  transition: opacity 0.9s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show .specification-fact:nth-child(1n) {
  transition-delay: 0.4s;
}
.advent-show .specification-fact:nth-child(2n) {
  transition-delay: 0.8s;
}
.advent-show .specification-fact:nth-child(3n) {
  transition-delay: 1.2s;
}
.advent-show .specification-fact:nth-child(4n) {
  transition-delay: 1.6s;
}
.advent-show .specification-fact:nth-child(5n) {
  transition-delay: 2s;
}
.advent-show .specification-fact:nth-child(6n) {
  transition-delay: 2.4s;
}
.advent-show .specification-fact:nth-child(7n) {
  transition-delay: 2.8s;
}
.advent-show .specification-fact:nth-child(8n) {
  transition-delay: 3.2s;
}
.advent-show .specification-fact:nth-child(9n) {
  transition-delay: 3.6s;
}
.advent-show .specification-fact:nth-child(10n) {
  transition-delay: 4s;
}
.advent-show .responsive-bg {
  transform: scale(1);
  transition: transform 10s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show .advent-comparison-header {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show .advent-comparison-desc {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show .advent-comparison-box {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show .advent-comparison-item {
  opacity: 1;
}
.advent-show .advent-comparison-item:nth-child(n+2) {
  transition: opacity 0.9s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.advent-show .btn .advent-ar-icon {
  transform: rotate(0) scale(1);
  opacity: 1;
}
.advent-show .advent-comparison-item:nth-child(2n) {
  transition-delay: 0.2s;
}
.advent-show .advent-comparison-item:nth-child(3n) {
  transition-delay: 0.4s;
}
.advent-show.christmas-tree-mobile .headline {
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  transition-delay: 0.5s;
}
.advent-show.christmas-tree-mobile .advent-product-reveal {
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  transition-delay: 0.7s;
}

.scroll-magic-trigger2 {
  position: absolute;
  top: 0;
  width: 100%;
}

.moving-title-wrapper {
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
  min-height: 270px;
  padding: 160px 0 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .moving-title-wrapper {
    padding: 110px 0 90px;
  }
}
@media (min-width: 768px) {
  .moving-title-wrapper {
    padding: 192px 0 24px;
  }
}
.moving-title-wrapper .moving-title {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  padding: 0 30px;
  position: absolute;
  white-space: nowrap;
  width: unset;
}
.moving-title-wrapper .moving-title h2 {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 40px;
  letter-spacing: 0.5rem;
  opacity: 0.26;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .moving-title-wrapper .moving-title h2 {
    font-size: 55px;
    letter-spacing: 0.6rem;
  }
}

.calendar-overview-block {
  position: relative;
  background: #122343;
}
@media (max-width: 767px) {
  .calendar-overview-block {
    margin-bottom: 20px;
  }
}
.calendar-overview-block .inner-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  max-width: 100vw;
  padding: 0;
  position: relative;
}
@media (max-width: 1023px) {
  .calendar-overview-block .inner-wrapper {
    flex-direction: column;
  }
}
.calendar-overview-block.calendar-overview-noanimation .calendar-overview-header,
.calendar-overview-block.calendar-overview-noanimation .calendar-overview-text,
.calendar-overview-block.calendar-overview-noanimation .calender-overview-box,
.calendar-overview-block.calendar-overview-noanimation .content-list-wrapper,
.calendar-overview-block.calendar-overview-noanimation .calendar-overview-disclamer {
  opacity: 1;
}
.calendar-overview-block .video-wrapper {
  position: relative;
  width: 50%;
  height: 100%;
}
@media (max-width: 1023px) {
  .calendar-overview-block .video-wrapper {
    width: 100%;
    height: 500px;
    margin-bottom: 300px;
    mask-image: linear-gradient(#1a1919, transparent);
  }
}
.calendar-overview-block .video-wrapper .scrollmagic-pin-spacer {
  padding-bottom: 0 !important;
}
@media (max-width: 767px) {
  .calendar-overview-block .video-wrapper .video-element {
    height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .video-wrapper .video-element {
    height: 150vh;
    top: 60%;
  }
}
.calendar-overview-block .text-wrapper {
  padding: 0 20px 0 80px;
  width: 50%;
  max-width: 600px;
}
@media (max-width: 1023px) {
  .calendar-overview-block .text-wrapper {
    width: 100%;
    padding: 0 32px;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }
}
@media (max-width: 767px) {
  .calendar-overview-block .text-wrapper {
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .text-wrapper {
    align-items: flex-end;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    left: 50%;
    margin-bottom: 100px;
    max-width: 100%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1160px) {
  .calendar-overview-block .text-wrapper {
    padding: 0 20px 0 80px;
  }
}
@media (min-width: 1440px) {
  .calendar-overview-block .text-wrapper {
    padding: 0 20px 0 120px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .text-wrapper .content-asset {
    width: 42%;
  }
}
.calendar-overview-block .calendar-overview-header {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 1.4px;
  color: #ffffff;
  text-transform: initial;
  opacity: 0;
}
@media (max-width: 1023px) {
  .calendar-overview-block .calendar-overview-header {
    font-size: 32px;
    letter-spacing: 0.6px;
    line-height: 40px;
    min-width: 51%;
  }
}
@media (min-width: 768px) {
  .calendar-overview-block .calendar-overview-header {
    transform: translateY(30px);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .calendar-overview-header {
    margin-bottom: 0;
  }
}
.calendar-overview-block .calendar-overview-subhead {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3em;
  color: rgba(222, 185, 163, 0.64);
  opacity: 0;
  display: none;
}
@media (min-width: 768px) {
  .calendar-overview-block .calendar-overview-subhead {
    transform: translateY(30px);
  }
}
.calendar-overview-block .calendar-overview-text {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.72);
  opacity: 0;
}
@media (min-width: 768px) {
  .calendar-overview-block .calendar-overview-text {
    transform: translateY(30px);
  }
}
@media (max-width: 1023px) {
  .calendar-overview-block .calendar-overview-text {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .calendar-overview-text {
    padding-right: 30px;
    width: 50%;
  }
}
.calendar-overview-block .promotional-label {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
  background-color: #ffffff;
  bottom: 16px;
  display: inline-block;
  margin-bottom: 16px;
}
.calendar-overview-block .promotional-label.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.calendar-overview-block .promotional-label.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.calendar-overview-block .promotional-label.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.calendar-overview-block .promotional-label.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.calendar-overview-block .promotional-label.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.calendar-overview-block .promotional-label.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}
.calendar-overview-block .calender-overview-box {
  opacity: 0;
}
@media (min-width: 768px) {
  .calendar-overview-block .calender-overview-box {
    transform: translateY(30px);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .calender-overview-box {
    order: 5;
    display: flex;
    flex-wrap: wrap;
  }
}
.calendar-overview-block .calendar-price-text {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .calendar-price-text {
    width: 51%;
  }
}
.calendar-overview-block .calendar-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.6px;
  color: #ffffff;
  margin-right: 20px;
}
@media (max-width: 1023px) {
  .calendar-overview-block .calendar-price {
    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 32px;
    margin-bottom: 17px;
    margin-right: 10px;
  }
}
.calendar-overview-block .calendar-beforediscount-price {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.6px;
  text-decoration-line: line-through;
}
@media (max-width: 767px) {
  .calendar-overview-block .calendar-beforediscount-price {
    margin-bottom: 17px;
    line-height: 32px;
  }
}
@media (min-width: 768px) {
  .calendar-overview-block .calendar-beforediscount-price {
    line-height: 40px;
  }
}
.calendar-overview-block .calendar-text {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 4px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 1023px) {
  .calendar-overview-block .calendar-text {
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 16px;
    margin-bottom: 17px;
  }
}
.calendar-overview-block .btn {
  display: block;
  margin-bottom: 10px;
  min-width: 360px;
}
@media (max-width: 767px) {
  .calendar-overview-block .btn {
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .btn {
    min-width: 320px;
    width: 50%;
  }
}
.calendar-overview-block .content-list-wrapper {
  padding: 10px 0;
  opacity: 0;
}
@media (min-width: 768px) {
  .calendar-overview-block .content-list-wrapper {
    transform: translateY(30px);
  }
}
.calendar-overview-block .content-list-wrapper .content-list {
  margin: 0;
  padding-left: 0;
  max-width: 350px;
  color: rgba(255, 255, 255, 0.72);
}
@media (max-width: 1023px) {
  .calendar-overview-block .content-list-wrapper .content-list {
    font-size: 12px;
    line-height: 18px;
  }
}
.calendar-overview-block .calendar-overview-disclamer {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.64);
  opacity: 0;
}
@media (min-width: 768px) {
  .calendar-overview-block .calendar-overview-disclamer {
    transform: translateY(30px);
  }
}
.calendar-overview-block .rituals-form {
  padding: 0;
  border: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .calendar-overview-block .rituals-form {
    width: 50%;
  }
}
.calendar-overview-block .rituals-form .btn {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #1a1919;
}
@media (min-width: 768px) {
  .calendar-overview-block .rituals-form .btn:hover {
    background-color: transparent;
    color: #ffffff;
  }
}
.calendar-overview-block .out-of-stock-block {
  justify-content: flex-start;
}

.advent-ar-text-hero {
  color: #153163;
}

.advent-split {
  align-items: center;
  background: linear-gradient(rgb(12, 25, 51), rgb(14, 30, 60));
  display: flex;
  min-height: 700px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  z-index: 5;
}
@media (min-width: 768px) {
  .advent-split {
    padding: 50px 0;
  }
}
.advent-split::-webkit-scrollbar {
  display: none;
}
.advent-split.start-textscroll {
  overflow-y: visible;
}

.advent-split[data-adventsection=advent-introduction] {
  height: 100%;
}

.advent-split-text {
  background-clip: text;
  background-position: center 45%;
  color: transparent;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 44px;
  height: auto;
  line-height: 90px;
  margin: 0 auto;
  max-width: 1120px;
  padding: 0 20px;
  position: sticky;
  text-align: center;
  top: 30%;
}
@media (max-width: 767px) {
  .advent-split-text {
    font-size: 30px;
    height: auto;
    line-height: 70px;
    top: 50px;
  }
}
.advent-split-text span {
  transition: color 0.2s ease-in, text-shadow 0.2s ease-in;
}

.advent-content {
  position: relative;
  width: 100%;
}

.advent-done-animation {
  height: 100vh;
}

.circle-advent {
  background: transparent;
  border-radius: 50%;
  height: 500px;
  position: fixed;
  width: 500px;
  z-index: -1;
}

.advent-text-white {
  color: #ffffff;
}

.advent-golden {
  text-shadow: 0 0 10px #e6be8a, 0 0 20px #e6be8a, 0 0 40px #e6be8a, 0 0 80px #e6be8a;
}

.advent-snap-wrap {
  overflow-x: hidden;
}

.advent-viewer-model {
  height: 50px;
  width: 319px;
  margin-bottom: 20px;
}
.advent-viewer-model .btn {
  background-color: #122343;
  z-index: 10;
}

.christmas-tree-bottom-mask::after {
  background: linear-gradient(transparent, #122343 96%);
  bottom: 0;
  content: "";
  height: 200px;
  left: 0;
  position: absolute;
  width: 100%;
}
@media (max-width: 767px) {
  .christmas-tree-bottom-mask::after {
    background: linear-gradient(transparent, #122343 79%);
    height: 400px;
  }
}

.christmas-tree-scroll {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.png-animation-cta .christmas-tree-scroll {
  margin-top: -65px;
}

.all-advent-gifts {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .all-advent-gifts {
    bottom: unset;
    position: absolute;
    overflow-y: auto;
    padding-top: 0;
  }
}
.all-advent-gifts .gifts-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 767px) {
  .all-advent-gifts .gifts-container {
    height: 100vh;
    padding-top: 24px;
  }
}
.all-advent-gifts .text-holder {
  margin-bottom: 50px;
  text-align: center;
}
@media (min-width: 1440px) {
  .all-advent-gifts .text-holder {
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .all-advent-gifts .text-holder {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .all-advent-gifts .text-holder h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 768px) {
  .all-advent-gifts .text-holder h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .text-holder h2 {
    font-size: 50px;
    line-height: 72px;
    margin-bottom: 50px;
  }
}
.all-advent-gifts .text-holder h3 {
  font-size: 56px;
  letter-spacing: 2px;
  line-height: 64px;
}
@media (max-width: 1023px) {
  .all-advent-gifts .text-holder h3 {
    font-size: 32px;
    letter-spacing: 0.6px;
    line-height: 40px;
  }
}
.all-advent-gifts .text-holder h3,
.all-advent-gifts .text-holder h2 {
  opacity: 0;
  color: #1a1919;
  text-transform: capitalize;
}
.all-advent-gifts .text-holder span {
  opacity: 0;
  color: #9a7b69;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 767px) {
  .all-advent-gifts .text-holder span {
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .all-advent-gifts .text-holder span {
    font-size: 12px;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .text-holder span {
    font-size: 14px;
  }
}
.all-advent-gifts .all-advent-gifts-slider {
  display: flex;
  justify-content: center;
  opacity: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider {
    align-items: center;
    flex-direction: column;
    opacity: 1;
    padding-bottom: 170px;
  }
  .all-advent-gifts .all-advent-gifts-slider::before, .all-advent-gifts .all-advent-gifts-slider::after {
    content: "";
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.45) 28%, rgb(247, 247, 247) 100%);
    bottom: 0;
    height: 75px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 4;
  }
  .all-advent-gifts .all-advent-gifts-slider::before {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.45) 28%, rgb(247, 247, 247) 100%);
    bottom: unset;
    top: 0;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item {
  align-items: center;
  display: flex;
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item {
    margin-top: 30px;
    width: 100%;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder {
  display: flex;
  position: relative;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder {
    width: 100%;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image {
  height: 280px;
  width: 300px;
  min-width: 300px;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image {
    height: 260px;
    width: 230px;
    min-width: 230px;
  }
}
@media (min-width: 1440px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image {
    height: 369px;
    width: 327px;
    min-width: 311px;
  }
}
@media (min-width: 1920px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image {
    height: 347px;
    width: 367px;
    min-width: 367px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image {
    min-height: 290px;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image img {
  width: 100%;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image img {
    position: relative;
    z-index: 2;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-image img {
    height: 100%;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content {
  position: relative;
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content {
    left: 50%;
    max-width: 50%;
    padding-bottom: 10px;
    padding-right: 38px;
    position: absolute;
    top: 90px;
  }
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content {
    padding-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content .full-size-tape {
  align-items: center;
  background-image: linear-gradient(135.76deg, rgba(168, 140, 119, 0.639) 16.86%, rgba(223, 209, 177, 0.639) 88%);
  display: inline-block;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0 10px;
  white-space: nowrap;
  width: auto;
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content .full-size-tape span {
  color: #ffffff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  line-height: 32px;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p {
    order: 3;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p {
    font-size: 24px;
  }
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p + p {
    margin-top: 4px;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p + p {
    margin-top: 6px;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p:first-of-type {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  line-height: 16px;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p:first-of-type {
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p:first-of-type {
    order: 2;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p:first-of-type {
    font-size: 12px;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p.product-price {
  color: #626262;
  font-family: "Scala Sans Pro Italic", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-style: italic;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p.product-price {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .product-content p.product-price {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .big-number {
  align-items: center;
  display: flex;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  position: absolute;
  top: 0;
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .big-number {
    font-size: 72px;
    height: 72px;
    left: 50%;
    line-height: 88px;
    margin-top: 0;
    top: 5%;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .big-number {
    font-size: 72px;
    height: 72px;
    line-height: 88px;
    margin-left: -20px;
    margin-top: 0;
    order: 1;
    position: relative;
  }
}
@media (min-width: 1024px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .big-number {
    font-size: 88px;
    height: 96px;
    margin-left: -30px;
    line-height: 96px;
  }
}
.all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item .product-holder .big-number span {
  background-clip: text;
  background-image: linear-gradient(135.76deg, #a88c77 16.86%, #dfd1b1 88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1023px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item:nth-of-type(even) .product-holder,
  .all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(even) .product-holder {
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item:nth-of-type(even) .product-holder .product-content,
  .all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(even) .product-holder .product-content {
    padding-bottom: 15px;
    left: 10%;
  }
}
@media (max-width: 767px) {
  .all-advent-gifts .all-advent-gifts-slider .all-advent-gifts-item:nth-of-type(even) .product-holder .big-number,
  .all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(even) .product-holder .big-number {
    left: 38px;
    top: 5%;
  }
}
.all-advent-gifts .all-advent-gifts-slider .slick-arrow {
  opacity: 0;
  z-index: 10;
  position: absolute;
}
.all-advent-gifts .all-advent-gifts-slider .slick-arrow .slider-button {
  align-items: center;
  border-radius: 50%;
  border: #453f3f;
  box-shadow: 0 4px 16px rgba(26, 25, 25, 0.12);
  cursor: pointer;
  display: flex;
  font-size: 45px;
  height: 65px;
  justify-content: center;
  transition: opacity 0.6s ease-in-out;
  width: 65px;
}
.all-advent-gifts .all-advent-gifts-slider .slick-next {
  right: 50px;
}
.all-advent-gifts .all-advent-gifts-slider .slick-prev {
  left: 50px;
}
.all-advent-gifts .all-advent-gifts-slider .slick-slide {
  opacity: 0.5;
}
.all-advent-gifts .all-advent-gifts-slider .slick-slide.slick-active {
  opacity: 1;
}
.all-advent-gifts .all-advent-gifts-slider:hover .slick-arrow {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}
.all-advent-gifts .all-advent-gifts-slider:hover .slick-arrow.slick-disabled {
  opacity: 0;
}
.all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(1),
.all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(2),
.all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(3),
.all-advent-gifts .all-advent-gifts-slider .slick-slide:nth-of-type(4) {
  opacity: 0;
}
.all-advent-gifts .all-advent-gifts-slider.not-fixed::after, .all-advent-gifts .all-advent-gifts-slider.not-fixed::before {
  display: none;
}
@keyframes slide-into {
  0% {
    opacity: 0;
    transform: translateX(30%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes item-slide-in-mob {
  0% {
    opacity: 0;
    transform: translateY(40%) scale(0.6);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@keyframes item-slide-in-mob-no-scale {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
.all-advent-gifts .gift-slide-position {
  background: rgba(26, 25, 25, 0.1);
  height: 2px;
  margin-top: 30px;
  width: 480px;
}
@media (max-width: 767px) {
  .all-advent-gifts .gift-slide-position {
    display: none;
  }
}
.all-advent-gifts .gift-slide-position .current-position {
  background-color: #453f3f;
  height: 2px;
  transition: transform 0.5s ease;
  width: 60px;
}

.advent-product-reveal {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.05, 0.5, 0.3, 1);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 767px) {
  .advent-product-reveal {
    bottom: 100px;
    left: 50%;
    text-align: center;
    top: auto;
    transform: translate(-50%, 90px);
  }
  .advent-product-reveal .advent-product-tap {
    display: block;
  }
  .advent-product-reveal .advent-product-btn-grad {
    align-items: center;
    background: linear-gradient(124deg, #9b7c6a 50%, #e4d7b6);
    border-radius: 50%;
    display: flex;
    height: 64px;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
    opacity: 0;
    position: relative;
    width: 64px;
    transition: opacity 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  }
  .advent-product-reveal.advent-pr-full .advent-product-btn {
    opacity: 0;
  }
  .advent-product-reveal .advent-product-btn {
    opacity: 1;
  }
  .advent-product-reveal .btn-flip {
    background-color: #ffffff;
    border-radius: 50%;
    border: 0;
    bottom: auto;
    height: 62px;
    left: auto;
    right: auto;
    top: auto;
    transform: translateX(1px);
    width: 62px;
  }
}
@media (min-width: 768px) {
  .advent-product-reveal {
    left: 150px;
    top: 50%;
    transform: translateY(-20px);
    transition: transform 0.5s cubic-bezier(0.05, 0.5, 0.3, 1), opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7) 0.5s;
    z-index: 1;
  }
}
@media (min-width: 1160px) and (max-height: 750px) {
  .advent-product-reveal {
    top: 35%;
  }
}
.advent-product-reveal .advent-product-tap {
  color: #ffffff;
  display: none;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  opacity: 0;
  text-transform: uppercase;
  transition-delay: 0.5s;
  transition: opacity 0.5s cubic-bezier(0.05, 0.5, 0.3, 1);
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-pr-full .advent-product-header {
    display: none;
  }
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-pr-full .advent-product-btn {
    border-radius: 30px;
    height: auto;
    min-width: 80vw;
  }
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-pr-full .advent-product-btn .icon-advent-key {
    left: 70px;
  }
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-pr-full .btn-flip::after {
    content: attr(data-back);
  }
  .advent-product-reveal.advent-pr-full .btn-flip::before {
    content: attr(data-front);
  }
}
.advent-product-reveal.advent-product-reveal-reverse {
  left: auto;
  right: 20%;
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-product-reveal-reverse {
    bottom: 100px;
    left: 50%;
    right: auto;
    top: auto;
    transform: translateX(-50%, 90px);
  }
}
.advent-product-reveal .advent-product-header {
  color: #ffffff;
  font-size: 55px;
  letter-spacing: 1.8px;
  line-height: 70px;
  margin-bottom: 30px;
  max-width: 340px;
  opacity: 0;
  text-transform: initial;
  transition-delay: 0.5s;
  transition: opacity 0.5s cubic-bezier(0.05, 0.5, 0.3, 1);
}
@media (max-width: 767px) {
  .advent-product-reveal .advent-product-header {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 16px;
    margin-bottom: 10px;
    min-width: 250px;
    text-transform: uppercase;
  }
}
.advent-product-reveal .advent-product-btn {
  background: #ffffff;
  opacity: 0;
  outline: 0;
  overflow: hidden;
  text-transform: uppercase;
  transition-delay: 0.5s;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7), box-shadow 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  box-shadow: 0 0 35px 0 rgba(154, 123, 105, 0.3);
  z-index: 1;
}
@media (max-width: 767px) {
  .advent-product-reveal .advent-product-btn {
    border-radius: 50%;
    height: 64px;
    margin: 0 auto;
    width: 64px;
  }
  .advent-product-reveal .advent-product-btn::after {
    border-radius: 50%;
    content: "";
    height: 64px;
    width: 64px;
  }
}
@media (min-width: 768px) {
  .advent-product-reveal .advent-product-btn {
    border-radius: 30px;
    max-width: 300px;
    position: relative;
  }
  .advent-product-reveal .advent-product-btn::after {
    background: linear-gradient(45deg, #e4d7b6, #9b7c6a);
    border-radius: 50%;
    bottom: -200%;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: -200%;
    transform: scale(0, 0);
    transition: transform 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    z-index: -1;
  }
  .advent-product-reveal .advent-product-btn:hover {
    box-shadow: 0 0 5px #e4d7b6, 0 0 20px rgba(228, 215, 182, 0.3);
  }
  .advent-product-reveal .advent-product-btn:hover::after {
    transform: scale(1, 1);
  }
}
.advent-product-reveal .btn-flip {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 40px;
  opacity: 1;
  outline: 0;
  padding: 8px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
.advent-product-reveal .btn-flip::after {
  color: #ffffff;
  content: attr(data-back);
  display: block;
  left: 12px;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateY(50%) rotateX(90deg);
  transition: 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  width: 100%;
}
.advent-product-reveal .btn-flip::before {
  color: #9a7b69;
  content: attr(data-front);
  display: block;
  left: 0;
  line-height: 40px;
  opacity: 1;
  padding: 0 30px;
  margin-left: 25px;
  position: relative;
  top: 0;
  transform: translateY(0) rotateX(0);
  transition: 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-product-reveal .btn-flip {
    height: 100%;
    width: 100%;
  }
  .advent-product-reveal .btn-flip::after {
    content: "";
  }
  .advent-product-reveal .btn-flip::before {
    content: "";
  }
}
@media (min-width: 768px) {
  .advent-product-reveal .btn-flip:hover::after {
    opacity: 1;
    transform: translateY(8px) rotateX(0);
  }
  .advent-product-reveal .btn-flip:hover::before {
    opacity: 0;
    transform: translateY(-50%) rotateX(90deg);
  }
}
.advent-product-reveal .btn-flip .icon-advent-key {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50px;
  font-size: 25px;
  transform: translateY(-50%);
  background: linear-gradient(154deg, #aa8e79 1.41%, #dfd1b1 83.61%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-product-reveal .btn-flip .icon-advent-key {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 44px;
  }
}
.advent-product-reveal .btn-flip .icon-close {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50px;
  font-size: 25px;
  transform: translateY(-50%) rotate(-90deg);
  background-color: #9a7b69;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.05, 0.5, 0.3, 1), transform 0.9s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-product-reveal .btn-flip .icon-close {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    height: 44px;
  }
}
@keyframes button-flip-reveal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.advent-product-reveal.advent-product-reveal-fadein {
  z-index: 5;
  pointer-events: auto;
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-product-reveal-fadein {
    transform: translate(-50%, 0);
    z-index: 20;
  }
}
@media (min-width: 768px) {
  .advent-product-reveal.advent-product-reveal-fadein {
    transform: translateY(-50%);
  }
}
.advent-product-reveal.advent-product-reveal-fadein .advent-product-header {
  opacity: 1;
}
.advent-product-reveal.advent-product-reveal-fadein .advent-product-tap {
  opacity: 1;
}
.advent-product-reveal.advent-product-reveal-fadein .advent-product-btn,
.advent-product-reveal.advent-product-reveal-fadein .advent-product-btn-grad {
  opacity: 1;
}
.advent-product-reveal.advent-product-reveal-fadein.advent-pr-full .advent-product-btn {
  opacity: 1;
}
.advent-product-reveal.advent-toggle-animation {
  opacity: 0;
  transition-delay: 0.3s;
}
.advent-product-reveal.advent-toggle-animation .advent-product-btn {
  animation: button-flip-reveal 0.7s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-toggle-mobile-animation .advent-product-header {
    opacity: 0;
    transition-delay: 0.4s;
  }
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-toggle-mobile-animation .advent-product-tap {
    opacity: 0;
    transition-delay: 0.4s;
  }
}
@media (max-width: 767px) {
  .advent-product-reveal.advent-toggle-mobile-animation .advent-product-btn-grad {
    animation: button-flip-reveal 0.7s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    height: 66px;
    transition: width 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7), height 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    width: 66px;
  }
}
.advent-product-reveal.advent-toggle-mobile-animation .btn-flip .icon-advent-key {
  opacity: 0;
  transition-delay: 0.2s;
}
.advent-product-reveal.advent-toggle-mobile-animation .btn-flip .icon-close {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(0);
  transition-delay: 0.25s;
}

.advent-block-reveal-product {
  background-color: #ffffff;
  border-radius: 2px;
  height: 375px;
  left: 150px;
  opacity: 0;
  padding: 9px;
  position: absolute;
  top: 50%;
  transform: translateY(-30%) scale(0.5);
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7), transform 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  width: 345px;
  z-index: 1;
}
@media (max-width: 767px) {
  .advent-block-reveal-product {
    bottom: 0;
    left: 50%;
    top: auto;
    transform: translate(-50%, 100px) scale(0.5);
    width: 90%;
    border-radius: 8px;
    transition-delay: 0.3s;
  }
}
@media (min-width: 768px) {
  .advent-block-reveal-product {
    transition-delay: 0.3s;
  }
}
.advent-block-reveal-product.advent-product-reveal-reverse {
  left: auto;
  right: 20%;
}
@media (max-width: 767px) {
  .advent-block-reveal-product.advent-product-reveal-reverse {
    bottom: 0;
    left: 50%;
    right: auto;
    top: auto;
    width: 90%;
  }
}
.advent-block-reveal-product .all-gifts-close-btn {
  animation: button-flip 1.7s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #9a7b69;
  bottom: 40px;
  box-shadow: 0 0 35px 0 rgba(154, 123, 105, 0.3);
  color: #9a7b69;
  display: block;
  font-size: 24px;
  height: 55px;
  left: 50%;
  position: absolute;
  right: auto;
  top: auto;
  transform: translateX(-50%);
  width: 55px;
  z-index: 6;
}
.advent-block-reveal-product .all-gifts-close-btn::after {
  line-height: 26px;
}
.advent-block-reveal-product .advent-reveal-img {
  width: 130px;
  height: 150px;
}

.advent-reveal-gradient-wrap {
  background: linear-gradient(45deg, #e4d6b5, #a18762, #e5c3a7, #a18762, #e4d6b5);
  border-radius: 1px;
  height: 100%;
  max-width: 100%;
  padding: 2px;
  position: relative;
}
@media (max-width: 767px) {
  .advent-reveal-gradient-wrap {
    border-radius: 4px;
  }
}

.advent-reveal-main-box {
  background-color: #ffffff;
  border-radius: 1px;
  height: 100%;
  padding: 10px;
  text-align: center;
}
@media (max-width: 767px) {
  .advent-reveal-main-box {
    border-radius: 4px;
  }
}
.advent-reveal-main-box .advent-reveal-btn-grad {
  align-items: center;
  background: linear-gradient(124deg, #9b7c6a 50%, #e4d7b6);
  border-radius: 50%;
  display: flex;
  height: 56px;
  justify-content: center;
  margin: auto;
  opacity: 0;
  position: relative;
  top: 10px;
  transition: opacity 1.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  width: 56px;
}
@media (max-width: 767px) {
  .advent-reveal-main-box .advent-reveal-btn-grad {
    display: none;
  }
}
.advent-reveal-main-box .advent-reveal-btn-grad .all-gifts-close-btn {
  border: 0;
  bottom: auto;
  height: 54px;
  left: auto;
  right: auto;
  top: auto;
  transform: rotate(-60deg);
  transition: transform 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  width: 54px;
}
.advent-reveal-main-box .advent-reveal-btn-grad .icon-close::before {
  line-height: 29px;
}

.advent-reveal-headline {
  color: #9a7b69;
  font-size: 14px;
  letter-spacing: 6px;
  line-height: 20px;
  margin-bottom: 3px;
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-reveal-headline {
    transition: opacity 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  }
}

.advent-reveal-text {
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (max-width: 767px) {
  .advent-reveal-text {
    transition: opacity 0.6s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  }
}
.advent-reveal-text .advent-reveal-size {
  color: rgba(26, 25, 25, 0.72);
}

.advent-product-reveal-open {
  opacity: 1;
  transform: scale(1);
  z-index: 10;
}
@media (max-width: 767px) {
  .advent-product-reveal-open {
    transform: translate(-50%, -90px) scale(1);
  }
  .advent-product-reveal-open.advent-product-reveal-reverse {
    transform: translate(-50%, -90px) scale(1);
  }
}
@media (min-width: 768px) {
  .advent-product-reveal-open {
    transform: translateY(-50%) scale(1);
  }
}
.advent-product-reveal-open .advent-reveal-img {
  animation: item-slide-in-mob 1s ease-in-out;
  animation-delay: 0.1s;
}
@media (max-width: 767px) {
  .advent-product-reveal-open .advent-reveal-img {
    animation-delay: 0.2s;
  }
}
.advent-product-reveal-open .advent-reveal-main-box .icon-close {
  transform: rotate(0);
  transition-delay: 0.5s;
}
.advent-product-reveal-open .advent-reveal-headline {
  animation: item-slide-in-mob-no-scale 1.1s ease-in-out;
  opacity: 1;
}
@media (max-width: 767px) {
  .advent-product-reveal-open .advent-reveal-headline {
    animation: item-slide-in-mob-no-scale 1.5s ease-in-out;
  }
}
.advent-product-reveal-open .advent-reveal-text {
  animation: item-slide-in-mob-no-scale 1.1s ease-in-out;
  opacity: 1;
}
@media (max-width: 767px) {
  .advent-product-reveal-open .advent-reveal-text {
    animation: item-slide-in-mob-no-scale 1.5s ease-in-out;
  }
}
.advent-product-reveal-open .advent-reveal-btn-grad {
  animation: item-slide-in-mob-no-scale 1.5s ease-in-out;
  opacity: 1;
}
.advent-product-reveal-open.all-advent-gifts {
  position: fixed;
  transform: none;
  z-index: 1000;
  pointer-events: initial;
}
.advent-product-reveal-open.all-advent-gifts .text-holder span {
  animation: subtitle 0.8s ease-out 0.3s;
  display: block;
  opacity: 1;
  transition: opacity 2s ease 0.4s;
}
@media (max-width: 1023px) {
  .advent-product-reveal-open.all-advent-gifts .text-holder span {
    animation-delay: 0s;
    transition-delay: 0s;
  }
}
.advent-product-reveal-open.all-advent-gifts .text-holder h3,
.advent-product-reveal-open.all-advent-gifts .text-holder h2 {
  animation: subtitle 1s ease-out 0.5s;
  opacity: 1;
  transition: opacity 2s ease 0.4s;
}
@media (max-width: 1023px) {
  .advent-product-reveal-open.all-advent-gifts .text-holder h3,
  .advent-product-reveal-open.all-advent-gifts .text-holder h2 {
    animation-delay: 0s;
    transition-delay: 0s;
  }
}
.advent-product-reveal-open.all-advent-gifts .all-advent-gifts-slider {
  opacity: 1;
  transition: opacity 1.2s ease-in-out;
}
.advent-product-reveal-open.all-advent-gifts .slick-slide:nth-of-type(1) {
  animation: item-slide-in 1s cubic-bezier(0, 0.29, 0.02, 0.94) 0.6s;
  opacity: 1;
  transition: opacity 1.1s ease-out 0.7s;
}
.advent-product-reveal-open.all-advent-gifts .slick-slide:nth-of-type(2) {
  animation: item-slide-in 1.2s cubic-bezier(0, 0.29, 0.02, 0.94) 0.7s;
  opacity: 1;
  transition: opacity 1.4s ease-out 0.8s;
}
.advent-product-reveal-open.all-advent-gifts .slick-slide:nth-of-type(3) {
  animation: item-slide-in 1.4s cubic-bezier(0, 0.29, 0.02, 0.94) 0.8s;
  opacity: 1;
  transition: opacity 1.7s ease-out 0.9s;
}
.advent-product-reveal-open.all-advent-gifts .slick-slide:nth-of-type(4) {
  animation: item-slide-in 1.6s cubic-bezier(0, 0.29, 0.02, 0.94) 0.9s;
  opacity: 0.5;
  transition: opacity 2s ease-out 1s;
}
.advent-product-reveal-open.all-advent-gifts .slick-slide:nth-of-type(4).slick-active {
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.3s;
}
@media (max-width: 1023px) {
  .advent-product-reveal-open.all-advent-gifts .all-advent-gifts-item:nth-of-type(1) {
    animation: item-slide-in-mob 0.8s ease-in-out;
  }
}
@media (max-width: 1023px) {
  .advent-product-reveal-open.all-advent-gifts .all-advent-gifts-item:nth-of-type(2) {
    animation: item-slide-in-mob 1s ease-in-out;
  }
}
@media (max-width: 1023px) {
  .advent-product-reveal-open.all-advent-gifts .all-advent-gifts-item:nth-of-type(3) {
    animation: item-slide-in-mob 1.2s ease-in-out;
  }
}
@keyframes item-slide-in {
  0% {
    transform: translateX(120%) scale(0);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
@keyframes subtitle {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

.all-gifts-close-btn {
  position: absolute;
  top: 70px;
  right: 70px;
  font-size: 20px;
  color: #1a1919;
}
@media (max-width: 767px) {
  .all-gifts-close-btn {
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #999999;
    color: #626262;
    display: block;
    font-size: 24px;
    height: 46px;
    position: fixed;
    right: 24px;
    top: 24px;
    width: 46px;
    z-index: 11;
  }
}
@keyframes button-flip {
  0% {
    transform: translate(-50%, 200%) rotate(90deg);
  }
  50% {
    transform: translate(-50%, 200%) rotate(90deg);
  }
  75% {
    transform: translate(-50%, -20px) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@keyframes button-flip-revert {
  0% {
    transform: translate(-50%, 0) scale(0.8);
  }
  50% {
    transform: translate(-50%, -20px) rotate(0deg) scale(1);
  }
  75% {
    transform: translate(-50%, 200%) rotate(90deg);
  }
  100% {
    transform: translate(-50%, 200%) rotate(90deg);
  }
}

.all-gifts-close-btn.not-fixed {
  display: none;
}

.advent-navigation-dots {
  opacity: 0;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
}
@media (max-width: 767px) {
  .advent-navigation-dots {
    display: none;
  }
}

.advent-navigation-dot {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #ffffff;
  display: block;
  height: 6px;
  margin-bottom: 12px;
  transition: background-color 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  width: 6px;
}
.advent-navigation-dot::after {
  background-color: #ffffff;
  border-radius: 2px;
  color: #1a1919;
  content: attr(data-description);
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  height: 35px;
  letter-spacing: 4px;
  line-height: 35px;
  min-width: 150px;
  opacity: 0;
  padding: 0 15px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  text-transform: uppercase;
  top: -15px;
  transform: scale(0.4) translateX(70px);
  transition-delay: 0.2s;
  transition: opacity 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7), transform 0.5s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  white-space: nowrap;
}
.advent-navigation-dot:hover::after {
  pointer-events: initial;
  opacity: 1;
  transform: scale(1) translateX(0);
}

.advent-horizontal-menu {
  background: linear-gradient(transparent -80%, #122343 50%);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  bottom: 0;
  cursor: grab;
  height: 80px;
  opacity: 0;
  position: fixed;
  width: 100%;
  z-index: 20;
}
.advent-horizontal-menu .inner-wrapper {
  display: flex;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  width: 100%;
  scrollbar-width: none;
}
.advent-horizontal-menu .inner-wrapper::-webkit-scrollbar {
  display: none;
}
.advent-horizontal-menu .inner-wrapper .advent-menu-items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .advent-horizontal-menu .inner-wrapper .advent-menu-items {
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 10px;
  }
}
@media (min-width: 1024px) {
  .advent-horizontal-menu .inner-wrapper .advent-menu-items {
    max-width: 1500px;
  }
}
.advent-horizontal-menu .inner-wrapper .advent-menu-items.full-width-menu {
  width: 100%;
}
.advent-horizontal-menu .advent-menu-item {
  color: #ffffff;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  letter-spacing: 0.3em;
  line-height: 40px;
  margin: 0 32px;
  min-width: fit-content;
  position: relative;
  text-transform: uppercase;
}
.advent-horizontal-menu .advent-menu-item::after {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  display: block;
  height: 0.5px;
  left: 0;
  position: absolute;
  transform-origin: 0% 0%;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
  transition-delay: 0.4s;
  width: 100%;
}
@media (max-width: 1023px) {
  .advent-horizontal-menu .advent-menu-item {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .advent-horizontal-menu .advent-menu-item::after {
    transition-delay: 0.1s;
  }
}
.advent-horizontal-menu .advent-menu-item.advent-shimmer-text::after {
  background: linear-gradient(45deg, #e4d6b5, #9a7b69);
}
.advent-horizontal-menu .advent-current-section::after {
  transform-origin: 0% 50%;
  transform: scale(1);
}

.advent-navigation-dot-filled {
  background-color: #ffffff;
}

.advent-menu-visible {
  opacity: 1;
}

.usabilla-btn-position {
  top: 75% !important;
}

.advent-navigation-hidden {
  pointer-events: none;
}

.advent-landing-page .simple-text-block .inner-wrapper br,
.product-type-adventcalendar .simple-text-block .inner-wrapper br {
  display: none;
}
.advent-landing-page .simple-text-block .inner-wrapper p,
.product-type-adventcalendar .simple-text-block .inner-wrapper p {
  font-size: 16px;
  line-height: 28px;
}
.advent-landing-page .simple-text-block .inner-wrapper .heading-intro,
.product-type-adventcalendar .simple-text-block .inner-wrapper .heading-intro {
  font-size: 24px;
  line-height: 32px;
}
.advent-landing-page .simple-text-block .inner-wrapper .acc-content-head,
.product-type-adventcalendar .simple-text-block .inner-wrapper .acc-content-head {
  line-height: 14px;
  padding-left: 0;
}
@media (min-width: 1024px) {
  .advent-landing-page .simple-text-block .inner-wrapper .acc-content-head,
  .product-type-adventcalendar .simple-text-block .inner-wrapper .acc-content-head {
    line-height: 28px;
  }
}
.advent-landing-page .simple-text-block .inner-wrapper .acc-content-head::before,
.product-type-adventcalendar .simple-text-block .inner-wrapper .acc-content-head::before {
  left: unset;
  right: -40px;
}
.advent-landing-page .faq-block h2,
.product-type-adventcalendar .faq-block h2 {
  line-height: 48px;
}
.advent-landing-page .faq-block .faq-accordion-content p,
.product-type-adventcalendar .faq-block .faq-accordion-content p {
  font-size: 14px;
  letter-spacing: 0.5px;
}

@media (min-width: 1024px) {
  .advent-landing-page section:nth-of-type(3).two-tile-text-block.full-width .headline {
    font-size: 40px;
  }
}
@media (min-width: 1024px) {
  .advent-landing-page .advent-comparison-block {
    padding-top: 100px;
  }
}
.advent-landing-page .advent-comparison-block .advent-comparison-header {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 1.2px;
}
@media (min-width: 1024px) {
  .advent-landing-page .advent-comparison-block .advent-comparison-header {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1.4px;
  }
}
@media (min-width: 1024px) {
  .advent-landing-page .two-tile-text-block.full-width .headline {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1.6px;
  }
}

.christmas-tree-mobile {
  position: relative;
}
.christmas-tree-mobile .advent-product-reveal {
  opacity: 0;
}
@media (max-width: 767px) {
  .christmas-tree-mobile .advent-product-reveal {
    bottom: 60px;
    left: 50%;
    pointer-events: initial;
    position: absolute;
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .christmas-tree-mobile .advent-pr-full .advent-product-btn {
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .christmas-tree-mobile .text-holder {
    padding: 0 45px 0 30px;
  }
}

.chrismas-tree-mobile-video {
  margin-top: -130px;
  width: 100%;
}

.chrismas-tree-mobile-headline {
  color: #ffffff;
  font-size: 32px;
  letter-spacing: 0.6px;
  line-height: 40px;
  text-transform: initial;
}

.refill-jar-wrapper,
.scroll-animation-wrapper {
  position: relative;
}
@media (max-width: 767px) {
  .refill-jar-wrapper,
  .scroll-animation-wrapper {
    padding-top: 95px;
  }
}

.refill-jar-wrapper .refill-jar-lid,
.refill-jar-wrapper .refill-jar-content,
.refill-jar-wrapper .refill-jar-container {
  position: relative;
  text-align: center;
  width: 100%;
}
.refill-jar-wrapper .refill-jar-lid img,
.refill-jar-wrapper .refill-jar-content img,
.refill-jar-wrapper .refill-jar-container img {
  display: inline-block;
  max-width: 200px;
}
@media (min-width: 768px) {
  .refill-jar-wrapper .refill-jar-lid img,
  .refill-jar-wrapper .refill-jar-content img,
  .refill-jar-wrapper .refill-jar-container img {
    max-width: 300px;
  }
}
.refill-jar-wrapper .refill-jar-lid {
  margin-bottom: -40px;
  z-index: 1;
}
@media (min-width: 768px) {
  .refill-jar-wrapper .refill-jar-lid {
    margin-bottom: -90px;
  }
}
.refill-jar-wrapper .refill-jar-content {
  margin-bottom: -120px;
  z-index: 2;
}
@media (min-width: 768px) {
  .refill-jar-wrapper .refill-jar-content {
    margin-bottom: -250px;
  }
}
.refill-jar-wrapper .refill-jar-container {
  z-index: 3;
}
@media (min-width: 768px) {
  .refill-jar-wrapper .refill-jar-container {
    margin-bottom: -130px;
  }
}

@media (max-width: 767px) {
  .scroll-animation-wrapper {
    min-height: 309px;
  }
}
@media (min-width: 768px) {
  .scroll-animation-wrapper {
    min-height: 368px;
  }
}
.scroll-animation-wrapper .candle-holder {
  left: 50%;
  position: absolute;
  transform: translateX(0);
  transition: transform 0.05s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  will-change: transition, transform;
}
.scroll-animation-wrapper .candle-holder.first, .scroll-animation-wrapper .candle-holder.fifth {
  z-index: 1;
}
.scroll-animation-wrapper .candle-holder.second, .scroll-animation-wrapper .candle-holder.fourth {
  z-index: 2;
}
@media (max-width: 767px) {
  .scroll-animation-wrapper .candle-holder.first {
    transform: translateX(-110%);
  }
}
@media (max-width: 767px) {
  .scroll-animation-wrapper .candle-holder.second {
    transform: translateX(-80%);
  }
}
.scroll-animation-wrapper .candle-holder.third {
  transform: translateX(-50%);
  z-index: 3;
}
@media (max-width: 767px) {
  .scroll-animation-wrapper .candle-holder.fourth {
    transform: translateX(-20%);
  }
}
@media (max-width: 767px) {
  .scroll-animation-wrapper .candle-holder.fifth {
    transform: translateX(10%);
  }
}
.scroll-animation-wrapper .candle-holder img {
  max-width: none !important;
}
@media (max-width: 767px) {
  .scroll-animation-wrapper .candle-holder img {
    width: 168px !important;
  }
}
@media (min-width: 768px) {
  .scroll-animation-wrapper .candle-holder img {
    width: 288px !important;
  }
}

.product-grid-block.product-filter-block {
  display: block;
  padding: 40px 0 85px;
}
@media (min-width: 768px) {
  .product-grid-block.product-filter-block {
    padding: 60px 0 40px;
  }
}
.product-grid-block.product-filter-block .grid-heading {
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .product-grid-block.product-filter-block .grid-heading {
    margin-bottom: 40px;
  }
}
.product-grid-block.product-filter-block .heading-wrapper {
  max-width: 1440px;
  margin: 0 auto 21px;
  position: relative;
  text-align: center;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .product-grid-block.product-filter-block .heading-wrapper {
    overflow: auto;
  }
  .product-grid-block.product-filter-block .heading-wrapper::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 768px) {
  .product-grid-block.product-filter-block .heading-wrapper {
    margin: 0 auto 28px;
  }
}
.product-grid-block.product-filter-block .product-filters {
  list-style: none;
}
@media (max-width: 767px) {
  .product-grid-block.product-filter-block .product-filters {
    white-space: nowrap;
  }
}
@media (min-width: 768px) {
  .product-grid-block.product-filter-block .product-filters {
    font-size: 0;
  }
}
.product-grid-block.product-filter-block .product-filters li {
  padding: 0 12px;
  margin-bottom: 12px;
  display: inline-block;
}
.product-grid-block.product-filter-block .product-filters li .filter-item {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: #626262;
  padding: 0 0 7px;
  position: relative;
  font-size: 12px;
  letter-spacing: 2.08px;
  line-height: 14px;
}
.product-grid-block.product-filter-block .product-filters li .filter-item::after {
  background-color: #453f3f;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 0;
}
.product-grid-block.product-filter-block .product-filters li .filter-item:hover, .product-grid-block.product-filter-block .product-filters li .filter-item.selected {
  color: #453f3f;
}
.product-grid-block.product-filter-block .product-filters li .filter-item:hover::after, .product-grid-block.product-filter-block .product-filters li .filter-item.selected::after {
  width: 100%;
}
@media (min-width: 768px) {
  .product-grid-block.product-filter-block .product-filters li .filter-item {
    letter-spacing: 1.7px;
  }
}
@media (max-width: 767px) {
  .product-grid-block.product-filter-block .product-filters li {
    min-width: 40%;
  }
}
@media (min-width: 768px) {
  .product-grid-block.product-filter-block .product-filters li {
    margin-bottom: 25px;
    padding: 0 25px;
  }
}

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  direction: ltr;
  height: 100%;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
}

.mCSB_container {
  height: auto;
  overflow: hidden;
  width: auto;
}
.mCSB_container .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-left: 30px;
  margin-right: 0;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools {
  left: 0;
  right: auto;
}
.mCS-dir-rtl .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
  right: auto;
}

.mCSB_scrollTools {
  bottom: 0;
  height: auto;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}
.mCSB_scrollTools .mCSB_draggerContainer {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}
.mCSB_scrollTools .mCSB_draggerRail {
  border-radius: 16px;
  height: 100%;
  margin: 0 auto;
  width: 2px;
}
.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  height: 30px;
  width: 100%;
  z-index: 1;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 4px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 4px;
}
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  cursor: pointer;
  display: block;
  height: 20px;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 30px;
  margin-right: 0;
}
.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}
.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}
.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.checkout-opening-hours {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin: 0 0 20px -20px;
  padding: 0 30px;
  width: calc(100% + 40px);
}

.checkout-opening-hours-link {
  color: #1a1919;
  display: block;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 14px 0 15px;
  position: relative;
  text-align: left;
  width: 100%;
}
.checkout-opening-hours-link:not(.single-opening-hours)::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
  display: inline-block;
  font-size: 38px;
  line-height: 20px;
  position: absolute;
  right: -14px;
  top: 14px;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.checkout-opening-hours-link.open::after {
  transform: rotate(-180deg);
}

.checkout-opening-hours-list {
  display: none;
  list-style: none;
  margin: 0 0 18px;
  width: 100%;
}
.checkout-opening-hours-list li {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  display: flex;
  width: 100%;
}
.checkout-opening-hours-list .time {
  margin-left: auto;
}

.write-a-card-column {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .write-a-card-column {
    margin-left: 50%;
    padding-top: 95px;
    min-height: 100vh;
    width: 50%;
  }
}

.write-a-card-content {
  padding: 40px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.write-a-card-content .write-a-card-heading {
  color: #000000;
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: none;
}
@media (min-width: 768px) {
  .write-a-card-content .write-a-card-heading {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
    margin-bottom: 8px;
  }
}
.write-a-card-content .pg-form {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
}
.write-a-card-content .write-a-card-description {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0 auto;
  max-width: 330px;
  text-align: center;
}
.write-a-card-content .card-slider {
  max-width: 200px;
  margin: 22px 0 41px;
}
@media (min-width: 768px) {
  .write-a-card-content .card-slider {
    margin: 28px 0 37px;
  }
}
.write-a-card-content .card-slider:not(.drag-list) .card-slide + .card-slide {
  display: none;
}
.write-a-card-content .card-slide {
  position: relative;
}
.write-a-card-content .card-slide img {
  max-width: 100%;
  margin: 0 auto;
}
.write-a-card-content .slick-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
}
.write-a-card-content .slick-slide.slick-active {
  opacity: 1;
  visibility: visible;
}
.write-a-card-content .slick-dots {
  position: absolute;
  bottom: -18px;
  width: 100%;
  font-size: 0;
  list-style: none;
  text-align: center;
}
.write-a-card-content .slick-dots li {
  display: inline-block;
  margin: 0 4px;
  width: 4px;
}
.write-a-card-content .slick-dots li.slick-active button {
  background: #453f3f;
}
.write-a-card-content .slick-dots li button {
  border-radius: 50%;
  display: inline-block;
  background: #d1d0ce;
  height: 4px;
  width: 4px;
  padding: 3px;
  line-height: 0;
  font-size: 0;
}
.write-a-card-content .slick-dots li button::before {
  content: "";
  border-radius: 50%;
}
.write-a-card-content .slick-arrow {
  position: absolute;
  display: block;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: 0;
  outline: none;
}
.write-a-card-content .slick-arrow.slick-next {
  right: -50px;
}
.write-a-card-content .slick-arrow.slick-prev {
  left: -50px;
}
.write-a-card-content .slick-arrow.slick-disabled .slider-button {
  opacity: 0.1;
  visibility: visible;
}
.write-a-card-content .slider-button {
  display: flex;
  align-items: center;
  border-radius: 50%;
  color: #000000;
  font-size: 44px;
  height: 50px;
  justify-content: center;
  width: 50px;
}
.write-a-card-content .slider-button::before {
  font-size: 40px;
}
.write-a-card-content .form-wrapper {
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .write-a-card-content .form-wrapper {
    max-width: 460px;
  }
}
.write-a-card-content .cancel-giftcard-edit {
  text-transform: uppercase;
  margin: 27px auto 0;
  display: block;
  font-size: 12px;
  letter-spacing: 1.5px;
}
.write-a-card-content .textarea-holder {
  position: relative;
  overflow: hidden;
}
.write-a-card-content .card-message {
  padding: 7px 8px;
  resize: none;
  overflow: auto;
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 176px;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 23px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  background-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
}
.write-a-card-content .card-message:focus, .write-a-card-content .card-message:active {
  background-color: #f6f4f2;
}
@media (min-width: 768px) {
  .write-a-card-content .card-message {
    height: 120px;
    letter-spacing: 0.5px;
    line-height: 25px;
    padding: 10px;
  }
}
@media (min-width: 1025px) {
  .write-a-card-content .card-message {
    font-size: 14px;
  }
}
.write-a-card-content .character-counter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 10px;
  font-size: 9px;
  letter-spacing: 1px;
  color: #626262;
}
@media (min-width: 768px) {
  .write-a-card-content .character-counter {
    bottom: 7px;
  }
}
.write-a-card-content .characters-checkbox-wrapper {
  margin: 5px 0 12px;
  text-align: left;
}
@media (min-width: 768px) {
  .write-a-card-content .characters-checkbox-wrapper {
    margin: 0 0 24px;
  }
}
.write-a-card-content .checkbox-wrapper label {
  color: #453f3f;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.75px;
  line-height: 14px;
  padding-left: 36px;
  position: relative;
}
.write-a-card-content .checkbox-wrapper label::before, .write-a-card-content .checkbox-wrapper label::after {
  top: -2px;
}
.write-a-card-content .btn {
  display: block;
  margin: 0 auto;
}
.write-a-card-content .drag-list .drag-list-body {
  padding: 0;
}
.write-a-card-content .drag-list .card-slide {
  width: 200px;
}
.write-a-card-content .drag-list .drag-list-controls .slider-button.hidden {
  opacity: 0.1;
}
@media (max-width: 767px) {
  .write-a-card-content .drag-list .drag-list-controls {
    display: block;
  }
  .write-a-card-content .drag-list .drag-list-controls .icon-arrow3--left {
    left: -60px;
  }
  .write-a-card-content .drag-list .drag-list-controls .icon-arrow3--right {
    right: -60px;
  }
}
@media (min-width: 768px) {
  .write-a-card-content .drag-list .drag-list-controls .icon-arrow3--left {
    left: -50px;
  }
  .write-a-card-content .drag-list .drag-list-controls .icon-arrow3--right {
    right: -50px;
  }
}

.content-list-wrapper {
  padding: 16px 0 87px;
  text-align: center;
}
.content-list-wrapper .inner-wrapper {
  max-width: 455px;
}
.content-list-wrapper .content-list-heading {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
.content-list-wrapper .content-list {
  list-style: none;
  text-align: left;
  max-width: 250px;
  margin: 20px auto 0;
  padding-left: 20px;
}
.content-list-wrapper .content-list li span {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 25px;
  width: calc(100% - 40px);
  display: inline-block;
}
.content-list-wrapper .content-list li .icon-check {
  font-size: 11px;
  color: #b8a188;
  margin-right: 9px;
  vertical-align: top;
  display: inline-block;
  width: 15px;
}

.spinner {
  display: none;
  text-align: center;
  width: 100%;
  font-size: 0;
}
.spinner.spinner-visible {
  display: block;
}
.spinner.black-spinner span {
  background-color: #453f3f;
}
.spinner.white-spinner span {
  background-color: #ffffff;
}
.spinner.grid-spinner {
  margin-top: -23px;
}
.spinner span {
  background-color: #1a1919;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-top: -1px;
  opacity: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .spinner span {
    margin-top: -3px;
  }
}
.spinner span:not(:last-of-type) {
  margin-right: 10px;
}
.spinner span:nth-child(1) {
  animation: loaderPulse 1.6s infinite 0.2s;
}
.spinner span:nth-child(2) {
  animation: loaderPulse 1.6s infinite 0.4s;
}
.spinner span:nth-child(3) {
  animation: loaderPulse 1.6s infinite 0.6s;
}
.spinner.grid-loading-spinner {
  position: absolute;
  top: 170px;
}
.spinner.grid-loading-spinner span {
  background-color: #453f3f;
}
.spinner.flyout-spinner {
  margin-top: 20px;
}
.spinner.stores-results-spinner {
  margin-top: 40px;
}
.spinner.more-stores-spinner {
  margin-top: 30px;
}
.spinner.step-1-spinner span, .spinner.eurobonus-spinner span {
  transform: none;
}
.spinner.update-delivery-time-spinner span {
  top: auto;
  transform: translate(0, 0);
}

@keyframes loaderPulse {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.drag-list {
  position: relative;
  width: 100%;
}
@media (max-width: 767px) {
  .drag-list {
    margin-bottom: 20px;
  }
}
.drag-list .drag-list-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.drag-list .drag-list-scroll::-webkit-scrollbar {
  display: none;
}
.drag-list .drag-list-scroll.init {
  visibility: visible;
}
@media (max-width: 767px) {
  .drag-list .drag-list-scroll.effect-magnify .drag-list-body > * {
    margin-top: 20px !important;
  }
}
.drag-list .drag-list-controls {
  display: none;
}
@media (min-width: 768px) {
  .drag-list .drag-list-controls {
    display: block;
  }
}
.drag-list .drag-list-controls button {
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  z-index: 1;
}
.drag-list .drag-list-controls button:first-child {
  left: 60px;
}
.drag-list .drag-list-controls button:last-child:not(:only-child) {
  right: 60px;
}
.drag-list .drag-list-controls button.hidden {
  opacity: 0;
  pointer-events: none;
}
.drag-list .drag-list-body {
  display: flex;
}
@media (max-width: 767px) {
  .drag-list .drag-list-body {
    padding: 0 25px;
  }
}
@media (min-width: 768px) {
  .drag-list .drag-list-body {
    padding: 0 60px;
  }
}

.product-configurator {
  flex-wrap: wrap;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.product-configurator .intro {
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1;
}
.product-configurator .intro p {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-bottom: 0;
  max-width: 350px;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .product-configurator .intro p {
    font-size: 21px;
    line-height: 30px;
  }
}
@media (min-width: 1024px) {
  .product-configurator .intro p {
    font-size: 28px;
    line-height: 40px;
  }
}
.product-configurator .intro em {
  font-style: italic;
}
.product-configurator .intro .intro-text {
  padding: 0 50px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 2;
}
@media (max-width: 1023px) {
  .product-configurator .intro .intro-text {
    transform: translateY(-50%);
  }
}
@media (min-width: 1024px) {
  .product-configurator .intro .intro-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.product-configurator .backdrop {
  background-color: #efedea;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.product-configurator .scrim {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -2;
}
@media (min-width: 1024px) {
  .product-configurator .slick-slider,
  .product-configurator .slick-list,
  .product-configurator .slick-track,
  .product-configurator .slick-slide > div {
    height: 100%;
  }
}
.product-configurator .slick-dots {
  bottom: 40px;
  display: flex !important;
  left: 50%;
  list-style: none;
  position: absolute;
  transform: translateX(-50%);
  width: 400px;
  z-index: 10;
}
@media (max-width: 1023px) {
  .product-configurator .slick-dots {
    bottom: -20px;
  }
}
@media (max-width: 767px) {
  .product-configurator .slick-dots {
    width: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator .slick-dots {
    width: 440px;
  }
}
@media (min-width: 1024px) {
  .product-configurator .slick-dots {
    bottom: 40px;
    width: 400px;
  }
}
.product-configurator .slick-dots li {
  background-color: #d1d1d1;
  display: flex;
}
@media (max-width: 1023px) {
  .product-configurator .slick-dots li {
    height: 2px;
  }
}
@media (min-width: 1024px) {
  .product-configurator .slick-dots li {
    height: 4px;
  }
}
.product-configurator .slick-dots li:first-child {
  border-radius: 2px 0 0 2px;
}
.product-configurator .slick-dots li:last-child {
  border-radius: 0 2px 2px 0;
}
.product-configurator .slick-dots li:nth-last-child(n+2), .product-configurator .slick-dots li:nth-last-child(n+2) ~ li {
  width: 50%;
}
.product-configurator .slick-dots li:nth-last-child(n+3), .product-configurator .slick-dots li:nth-last-child(n+3) ~ li {
  width: 33.33%;
}
.product-configurator .slick-dots li:nth-last-child(n+4), .product-configurator .slick-dots li:nth-last-child(n+4) ~ li {
  width: 25%;
}
.product-configurator .slick-dots li:nth-last-child(n+5), .product-configurator .slick-dots li:nth-last-child(n+5) ~ li {
  width: 20%;
}
.product-configurator .slick-dots li:nth-last-child(n+6), .product-configurator .slick-dots li:nth-last-child(n+6) ~ li {
  width: 16.67%;
}
.product-configurator .slick-dots li:nth-last-child(n+7), .product-configurator .slick-dots li:nth-last-child(n+7) ~ li {
  width: 14.29%;
}
.product-configurator .slick-dots li:nth-last-child(n+8), .product-configurator .slick-dots li:nth-last-child(n+8) ~ li {
  width: 12.5%;
}
.product-configurator .slick-dots li:nth-last-child(n+9), .product-configurator .slick-dots li:nth-last-child(n+9) ~ li {
  width: 11.11%;
}
.product-configurator .slick-dots li:nth-last-child(n+10), .product-configurator .slick-dots li:nth-last-child(n+10) ~ li {
  width: 10%;
}
.product-configurator .slick-dots li button {
  height: 100%;
  text-indent: -9999px;
  width: 100%;
}

.product-configurator-transition-leave-to {
  overflow: visible;
}
@media (max-width: 1023px) {
  .product-configurator-transition-leave-to:not(.has-dialog) {
    transform: translateY(100vh);
  }
}
@media (min-width: 1024px) {
  .product-configurator-transition-leave-to:not(.has-dialog) {
    transform: translateX(100%);
  }
}

.product-configurator-transition-leave-active {
  overflow: visible;
  transition: transform 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.scrim-transition-enter {
  opacity: 0;
}

.scrim-transition-leave-to {
  opacity: 0;
}
@media (max-width: 1023px) {
  .scrim-transition-leave-to {
    transform: translateY(-100vh) !important;
  }
}
@media (min-width: 1024px) {
  .scrim-transition-leave-to {
    left: -100% !important;
  }
}
@media (min-width: 1024px) {
  .scrim-transition-leave-to _:-ms-fullscreen, :root .scrim-transition-leave-to {
    left: 0 !important;
  }
}

@media (max-width: 1023px) {
  .scrim-transition-leave-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.4s;
  }
}
@media (min-width: 1024px) {
  .scrim-transition-leave-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.4s, left 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

.scrim-transition-enter-active {
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.intro-transition-enter-active,
.intro-transition-leave-active {
  z-index: 1;
}

.backdrop-transition-enter,
.intro-backdrop-transition-enter {
  opacity: 0;
}
@media (max-width: 1023px) {
  .backdrop-transition-enter,
  .intro-backdrop-transition-enter {
    top: 100vh !important;
  }
}
@media (min-width: 1024px) {
  .backdrop-transition-enter,
  .intro-backdrop-transition-enter {
    left: 100% !important;
  }
}

.backdrop-transition-leave-to {
  opacity: 0;
}
@media (max-width: 1023px) {
  .backdrop-transition-leave-to {
    top: 100vh !important;
  }
}

.intro-backdrop-transition-leave-to {
  opacity: 0;
}
@media (max-width: 1023px) {
  .intro-backdrop-transition-leave-to {
    top: 0 !important;
  }
}
@media (min-width: 1024px) {
  .intro-backdrop-transition-leave-to {
    left: 0 !important;
  }
}

@media (max-width: 1023px) {
  .backdrop-transition-enter-active,
  .backdrop-transition-leave-active,
  .intro-backdrop-transition-enter-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s, top 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
  }
}
@media (min-width: 1024px) {
  .backdrop-transition-enter-active,
  .backdrop-transition-leave-active,
  .intro-backdrop-transition-enter-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s, left 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
  }
}

.intro-backdrop-transition-leave-active {
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
}

.intro-text-transition-enter {
  opacity: 0;
}
@media (max-width: 1023px) {
  .intro-text-transition-enter {
    top: 150vh !important;
  }
}
@media (min-width: 1024px) {
  .intro-text-transition-enter {
    left: 150% !important;
  }
}

.intro-text-transition-leave-to {
  opacity: 0;
}
@media (max-width: 1023px) {
  .intro-text-transition-leave-to {
    top: 50vh !important;
  }
}
@media (min-width: 1024px) {
  .intro-text-transition-leave-to {
    left: 50% !important;
  }
}

@media (max-width: 1023px) {
  .intro-text-transition-enter-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s, top 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
  }
}
@media (min-width: 1024px) {
  .intro-text-transition-enter-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s, left 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
  }
}

.intro-text-transition-leave-active {
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
}

@media (max-width: 1023px) {
  .assembled-product.zoom-top .layer img, .assembled-product.start-with-zoom .layer img {
    padding: 0;
  }
}
.assembled-product.zoom-top {
  transform: scale3d(2, 2, 1) translate3d(0, -10%, 0);
}
@media (min-width: 768px) {
  .assembled-product.zoom-top {
    transform: scale3d(1.8, 1.8, 1) translate3d(0, -2%, 0);
  }
}
@media (min-width: 1024px) {
  .assembled-product.zoom-top {
    transform: scale3d(2, 2, 1) translate3d(0, -15%, 0);
  }
}
.assembled-product.zoom-middle {
  transform: scale3d(2, 2, 1) translate3d(0, -27%, 0);
}
@media (min-width: 768px) {
  .assembled-product.zoom-middle {
    transform: scale3d(1.3, 1.3, 1) translate3d(0, 1%, 0);
  }
}
@media (min-width: 1024px) {
  .assembled-product.zoom-middle {
    transform: scale3d(2, 2, 1) translate3d(0, -15%, 0);
  }
}
.product-configurator .assembled-product {
  height: 100%;
  transform-origin: top;
}
@media (max-width: 1023px) {
  .product-configurator .assembled-product {
    position: relative;
    transition: height 0.5s cubic-bezier(0.32, 0.24, 0.15, 1), margin 0.5s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
  }
}
.product-configurator .assembled-product.is-product-zoom {
  position: absolute;
  top: 0;
  transition: transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1), top 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 2;
}
@media (min-width: 1024px) {
  .product-configurator .assembled-product:not(.is-product-zoom) {
    position: relative;
    transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.3s;
    width: 50%;
  }
}
.product-configurator .assembled-product.move-active {
  transition: transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.assembled-product .layer {
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  align-items: center;
}
.assembled-product .layer img {
  left: 50%;
  margin-top: auto;
  max-width: 100%;
  position: absolute;
  transition: padding 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-height: 80%;
}
@media (min-width: 1024px) {
  .assembled-product .layer img {
    max-height: 75vh;
  }
}
.assembled-product .fragrance-label {
  bottom: 10px;
  color: #453f3f;
  font-family: "ClassGarmnd BT Italic", "Times New Roman", serif;
  font-size: 14px;
  font-style: italic;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
}
@media (max-width: 1023px) {
  .assembled-product .fragrance-label {
    bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .assembled-product .fragrance-label {
    bottom: 65px;
  }
}
.assembled-product .fragrance-label::before {
  background-color: #453f3f;
  content: "";
  height: 1px;
  left: calc(50% - 14px);
  position: absolute;
  top: -6px;
  width: 28px;
}

.assembled-product-zoom {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
}
@media (max-width: 1023px) {
  .assembled-product-zoom {
    overflow: hidden;
    top: 0;
    transition: height 0.8s cubic-bezier(0.32, 0.24, 0.15, 1), margin 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .assembled-product-zoom {
    height: 100%;
    transition: width 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .assembled-product-zoom.active {
    height: 100%;
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .assembled-product-zoom.active {
    width: 100%;
  }
}
.assembled-product-zoom.active .zoom-close {
  opacity: 1;
}
.assembled-product-zoom.active .fragrance-label {
  opacity: 0;
}
.assembled-product-zoom .zoom-trigger {
  height: 33.33%;
  position: relative;
  width: 100%;
  z-index: 3;
}
.assembled-product-zoom .zoom-close {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
  height: 50px;
  opacity: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 50px;
  z-index: 4;
}
.assembled-product-zoom .zoom-backdrop {
  background-color: #efedea;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.assembled-product-transition-enter,
.assembled-product-transition-leave-to {
  opacity: 0;
}

.assembled-product-transition-enter-active,
.assembled-product-transition-leave-active {
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.product-configurator .result-slider {
  position: absolute;
  z-index: 3;
}
@media (max-width: 1023px) {
  .product-configurator .result-slider {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 1024px) {
  .product-configurator .result-slider {
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.product-configurator .result-slider .configured-product {
  height: 100%;
}
@media (max-width: 767px) {
  .product-configurator .result-slider .result-slider-image {
    height: 280px;
    width: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator .result-slider .result-slider-image {
    height: 500px;
    width: 500px;
  }
}
@media (min-width: 1024px) {
  .product-configurator .result-slider .result-slider-image {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .product-configurator .result-slider .result-slider-image.selected img {
    opacity: 1;
  }
}
.product-configurator .result-slider .result-slider-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 1023px) {
  .product-configurator .result-slider .result-slider-image img {
    margin: 0 auto;
    opacity: 0.3;
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: calc(100% - 20px);
  }
}
.product-configurator .restart-button {
  align-items: center;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 10;
  color: #ffffff;
  font-size: 44px;
}
.product-configurator .restart-button:not(.fixed) {
  position: absolute;
}
.product-configurator .restart-button.fixed {
  position: fixed;
  z-index: 110;
}
@media (max-width: 767px) {
  .product-configurator .restart-button {
    height: 32px;
    left: 20px;
    top: 20px;
    width: 32px;
    font-size: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator .restart-button {
    height: 48px;
    left: 40px;
    top: 40px;
    width: 48px;
  }
}
@media (min-width: 1024px) {
  .product-configurator .restart-button {
    height: 50px;
    left: 40px;
    top: 40px;
    width: 50px;
  }
}

.parts-footer,
.options-footer,
.result-footer {
  position: absolute;
  z-index: 2;
}
@media (max-width: 1023px) {
  .parts-footer,
  .options-footer,
  .result-footer {
    bottom: 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .parts-footer,
  .options-footer,
  .result-footer {
    display: flex;
    flex-flow: column;
    height: 100%;
    right: 0;
    top: 0;
    width: 50%;
  }
}

@media (max-width: 1023px) {
  .parts-footer,
  .options-footer {
    background-color: #ffffff;
  }
}
@media (max-width: 767px) {
  .parts-footer.upsell-footer .footer-header,
  .options-footer.upsell-footer .footer-header {
    padding-top: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .parts-footer.upsell-footer .footer-header,
  .options-footer.upsell-footer .footer-header {
    padding: 95px 100px 40px 100px;
  }
}
@media (max-width: 767px) {
  .parts-footer.upsell-footer .footer-header h3,
  .options-footer.upsell-footer .footer-header h3 {
    font-size: 20px;
    letter-spacing: 0.57px;
    line-height: 24px;
    margin: 0 60px;
  }
}
.parts-footer .error-badge,
.options-footer .error-badge {
  background: url("../images/error-badge.svg") left top no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.parts-footer .footer-header,
.options-footer .footer-header {
  text-align: center;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .parts-footer .footer-header,
  .options-footer .footer-header {
    opacity: 1;
    padding-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}
.parts-footer .footer-header h3,
.options-footer .footer-header h3 {
  font-size: 30px;
  letter-spacing: 1.2px;
  text-transform: none;
}
.parts-footer .footer-header p,
.options-footer .footer-header p {
  color: #1a1919;
}
.parts-footer .footer-header .icon,
.options-footer .footer-header .icon {
  color: #886655;
  font-size: 30px;
  left: 50%;
  line-height: 1;
  position: absolute;
  top: 55px;
  transform: translateX(-50%);
}
@media (min-width: 1024px) and (max-height: 730px) {
  .parts-footer .footer-header .icon,
  .options-footer .footer-header .icon {
    top: 20px;
  }
}
@media (max-width: 767px) {
  .parts-footer .button-row,
  .options-footer .button-row {
    display: flex;
    flex-flow: row;
    height: 65px;
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .parts-footer .button-row,
  .options-footer .button-row {
    height: 84px;
    padding-top: 20px;
    text-align: center;
  }
}
.parts-footer .button-row.single-button,
.options-footer .button-row.single-button {
  display: flex;
  justify-content: center;
}
.parts-footer .button-row .btn,
.options-footer .button-row .btn {
  min-width: 0;
}
@media (max-width: 1023px) {
  .parts-footer .button-row .btn,
  .options-footer .button-row .btn {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .parts-footer .button-row .btn,
  .options-footer .button-row .btn {
    max-width: 190px;
  }
}
@media (min-width: 1024px) {
  .parts-footer .button-row .btn,
  .options-footer .button-row .btn {
    display: inline-block;
    height: 44px;
    width: calc(50% - 10px);
  }
}
@media (min-width: 768px) {
  .parts-footer .button-row .btn + .btn,
  .options-footer .button-row .btn + .btn {
    margin-left: 15px;
  }
}

@media (min-width: 1024px) {
  .parts-footer > div:not(.upsell-options) {
    width: 400px;
  }
}
@media (min-width: 1024px) {
  .parts-footer > div:not(.upsell-options):first-child {
    margin: 95px auto 65px;
  }
}
@media (min-width: 1024px) and (max-height: 768px) {
  .parts-footer > div:not(.upsell-options):first-child {
    margin: 47px auto 30px;
  }
}
@media (min-width: 1024px) {
  .parts-footer > div:not(.upsell-options):not(:first-child) {
    margin: 0 auto;
  }
}
.parts-footer .upsell-options {
  padding: 0 65px;
  width: 100%;
}
.parts-footer .status-bar p {
  color: #1a1919;
}
@media (max-width: 1023px) {
  .parts-footer .status-bar p {
    background-color: #f6f4f2;
  }
}
@media (min-width: 1024px) {
  .parts-footer .button-row {
    height: 156px;
    padding-top: 20px;
  }
}
@media (min-width: 1024px) and (max-height: 682px) {
  .parts-footer .button-row {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: auto !important;
    padding: 0 !important;
    background-color: #ffffff;
  }
  .parts-footer .button-row::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 50px;
    width: 100%;
    background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  }
}
@media (max-width: 767px) {
  .parts-footer .button-row .btn {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }
}

.options-footer {
  opacity: 1;
}
@media (max-width: 1023px) {
  .options-footer {
    z-index: 9;
  }
}
@media (max-width: 1023px) {
  .options-footer.has-overlay, .options-footer.is-zoom-image {
    z-index: 100;
  }
}
.options-footer.full-height-footer .overlay p {
  max-width: 335px;
}
@media (max-width: 1023px) {
  .options-footer.full-height-footer:not(.upsell-footer) {
    top: 0;
  }
}
@media (max-width: 1023px) {
  .options-footer.full-height-footer:not(.upsell-footer) .button-row {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (max-height: 580px) {
  .options-footer.full-height-footer.upsell-footer .footer-header {
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  .options-footer.full-height-footer.upsell-footer {
    top: 0;
  }
}
@media (max-width: 767px) {
  .options-footer.full-height-footer.upsell-footer .button-row {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 1024px) {
  .options-footer > div:first-child {
    margin: 0 auto;
    width: 400px;
  }
}
.options-footer > div:not(:first-child):not(.overlay) {
  margin: 0 auto;
}
@media (min-width: 1025px) {
  .options-footer > div:not(:first-child):not(.overlay) {
    width: 470px;
  }
}
@media (min-width: 1160px) {
  .options-footer > div:not(:first-child):not(.overlay) {
    width: 560px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .options-footer > div:not(:first-child):not(.overlay).part-options-fragrance-slider {
    margin-top: 240px;
  }
}
@media (min-width: 1160px) {
  .options-footer > div:not(:first-child):not(.overlay).part-options-fragrance-slider {
    width: 540px;
  }
}
.options-footer .footer-header:not(.has-categories) {
  padding: 95px 0 40px;
}
@media (min-width: 1024px) and (max-height: 730px) {
  .options-footer .footer-header:not(.has-categories) {
    padding-top: 55px;
  }
}
.options-footer .footer-header.has-categories {
  padding: 95px 0 90px;
}
@media (min-width: 1024px) and (max-height: 730px) {
  .options-footer .footer-header.has-categories {
    padding-top: 55px;
  }
}
.options-footer .status-bar {
  bottom: 71px;
  position: absolute;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .options-footer .status-bar {
    bottom: 82px;
  }
}
@media (max-width: 1023px) {
  .options-footer .status-bar {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .options-footer .status-bar {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1024px) {
  .options-footer .status-bar + .button-row .btn {
    margin-top: 35px;
    margin-bottom: 20px;
  }
}
.options-footer .status-bar p {
  color: #d6122d;
}
@media (min-width: 1024px) {
  .options-footer .button-row {
    align-items: center;
    flex-grow: 1;
    max-height: 101px;
  }
}
@media (max-width: 767px) {
  .options-footer .button-row .btn {
    border-color: #e8e8e8;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }
}

@media (max-width: 767px) {
  .upsell-footer {
    background-color: #f6f4f2;
  }
}
@media (min-width: 1024px) {
  .upsell-footer {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .upsell-footer .button-row {
    height: 54px;
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .upsell-footer .button-row {
    padding-bottom: 30px;
    padding-top: 10px;
  }
}

@media (max-width: 1023px) {
  .result-footer {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .result-footer .product-description {
    height: 100%;
    width: 100%;
  }
}
.result-footer .product-description .product-info-column-details {
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .result-footer .product-description .product-info-column-details {
    padding: 20px 15px 20px 15px;
  }
}
@media (min-width: 1024px) {
  .result-footer .product-description .product-info-column-details .product-info-content {
    padding: 0 42px;
  }
}
@media (min-width: 1160px) {
  .result-footer .product-description .product-info-column-details .product-info-content {
    padding: 0 48px;
  }
}
@media (min-width: 1440px) {
  .result-footer .product-description .product-info-column-details .product-info-content {
    padding: 0 146px;
  }
}
.result-footer .product-description .product-info-column-details .product-info-content h3 {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1.28px;
}
@media (min-width: 768px) {
  .result-footer .product-description .product-info-column-details .product-info-content h3 {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    max-width: 350px;
    margin: 0 auto 10px;
  }
}
.result-footer .product-description .product-info-column-details .product-info-content p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .result-footer .product-description .product-info-column-details .product-info-content p {
    letter-spacing: 0.5px;
    line-height: 24px;
    max-width: 400px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .result-footer .product-description .product-info-column-details .product-info-content .result-label {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .result-footer .product-description .product-info-column-details .product-info-content .result-label {
    margin: 63px 0 10px;
  }
}
@media (max-width: 767px) {
  .result-footer .product-description .product-info-column-details .product-info-content .product-price {
    font-size: 18px;
    line-height: 21px;
    margin: 15px 0 30px 0;
    width: 100%;
  }
}
@media (max-width: 374px) {
  .result-footer .product-description .product-info-column-details .product-info-content .product-price {
    margin: 15px 0 12px 0;
  }
}
@media (min-width: 768px) {
  .result-footer .product-description .product-info-column-details .product-info-content .product-price {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    margin: 30px 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .result-footer .product-description .product-info-column-details .product-info-content .product-price {
    margin: 50px 0 10px 0;
  }
}
.result-footer .product-description .product-info-column-details .product-info-content .price-sales {
  display: block;
}
@media (max-width: 1023px) {
  .result-footer .product-description .btn {
    min-width: calc(50% - 5px);
    transform: translate3d(0, 60px, 0);
    transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
.result-footer .product-description .btn.active {
  transform: translate3d(0, 0, 0);
}
@media (max-width: 1023px) {
  .result-footer .product-info-footer {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-top: auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .result-footer .product-info-footer {
    margin: 0 auto;
    width: 440px;
  }
}

@media (min-width: 1024px) {
  .product-parts {
    flex-grow: 1;
  }
}
@media (min-width: 1024px) and (max-height: 682px) {
  .product-parts {
    position: relative;
    height: 59vh;
    overflow: hidden;
    flex-grow: 0 !important;
  }
}
.product-parts ul {
  list-style: none;
  margin: 0;
  position: relative;
}
@media (max-width: 1023px) {
  .product-parts ul {
    display: flex;
    min-height: 97px;
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-parts ul {
    max-width: 400px;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) and (max-height: 682px) {
  .product-parts ul {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 80px;
  }
}
@media (max-width: 1023px) {
  .product-parts li {
    display: flex;
    text-align: center;
    width: 33.33%;
  }
}
@media (min-width: 1024px) {
  .product-parts li {
    border-top: 1px solid #d8d8d8;
    height: 99px;
  }
}
@media (min-width: 1024px) {
  .product-parts li:last-child {
    border-bottom: 1px solid #d8d8d8;
  }
}
.product-parts li a {
  position: relative;
  text-decoration: none;
  width: 100%;
}
@media (max-width: 1023px) {
  .product-parts li a {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: 20px 0;
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .product-parts li a {
    display: flex;
    flex-flow: column;
    min-height: 97px;
    padding: 24px 45px 10px 94px;
  }
}
@media (max-width: 1023px) {
  .product-parts li a.icon-selected {
    border-top: 1px solid #886655;
  }
}
.product-parts li a.icon-selected .icon {
  color: #886655;
}
@media (min-width: 1024px) {
  .product-parts li a::after {
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    content: "";
    height: 15px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: rotate(45deg) translateY(-10px);
    width: 15px;
  }
}
.product-parts li span.part-name {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  letter-spacing: 0.4px;
}
@media (max-width: 1023px) {
  .product-parts li span.part-name {
    font-size: 12px;
    line-height: 15px;
  }
}
@media (min-width: 1024px) {
  .product-parts li span.part-name {
    font-size: 18px;
    line-height: 21px;
  }
}
.product-parts li span.error-badge {
  bottom: 9px;
  left: calc(50% + 3px);
  position: absolute;
}
.product-parts li span.error-message {
  color: #d6122d;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 13px;
}
.product-parts li span.selected-part {
  color: #767676;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.product-parts li .icon-holder {
  display: block;
}
@media (max-width: 1023px) {
  .product-parts li .icon-holder {
    position: relative;
  }
}
@media (min-width: 1024px) {
  .product-parts li .icon-holder {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}
.product-parts li .icon {
  margin-bottom: 5px;
  color: #1a1919;
}
@media (max-width: 1023px) {
  .product-parts li .icon {
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .product-parts li .icon {
    font-size: 28px;
    left: 27px;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.part-options {
  position: relative;
}
@media (min-width: 1024px) {
  .part-options {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    transition: border-color 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) !important;
  }
}
@media (min-width: 1024px) {
  .part-options::before, .part-options ~ .button-row::before {
    content: "";
    height: 57px;
    opacity: 0;
    width: 540px;
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
    z-index: 1;
  }
}
@media (min-width: 1024px) {
  .part-options::before {
    background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
    position: fixed;
  }
}
@media (min-width: 1024px) {
  .part-options ~ .button-row {
    position: relative;
  }
}
@media (min-width: 1024px) {
  .part-options ~ .button-row::before {
    background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
    left: 0;
    position: absolute;
    top: -58px;
    z-index: -1;
  }
}
@media (min-width: 1024px) {
  .part-options.top-gradient::before, .part-options.bottom-gradient ~ .button-row::before {
    opacity: 1;
    z-index: 0;
  }
}
.part-options.part-options-fragrance-slider {
  overflow: visible;
}
@media (max-width: 767px) {
  .part-options.part-options-fragrance-slider {
    height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .part-options.part-options-fragrance-slider {
    width: 540px;
    height: calc(100% - 240px);
  }
}
.part-options.part-options-fragrance-slider .categories {
  overflow: auto;
  white-space: nowrap;
  margin-top: 40px;
  justify-content: flex-start;
  border: 0;
}
@media (max-width: 767px) {
  .part-options.part-options-fragrance-slider .categories {
    padding-left: 40px;
  }
}
@media (min-width: 1024px) {
  .part-options.part-options-fragrance-slider .categories {
    margin-top: -36px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .part-options.part-options-fragrance-slider .categories::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 1160px) {
  .part-options.part-options-fragrance-slider .categories {
    width: 540px;
  }
}
.part-options.part-options-fragrance-slider .categories li {
  margin: 0 10px;
}
.part-options.part-options-fragrance-slider .categories li:first-child {
  margin-left: 0;
}
.part-options.part-options-fragrance-slider .categories li:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .part-options.part-options-fragrance-slider .categories li {
    margin: 0 7px;
  }
}
@media (min-width: 1024px) {
  .part-options.part-options-fragrance-slider .categories li {
    margin: 0 16px;
    padding: 0;
  }
}
.part-options.part-options-fragrance-slider .category-indicator-scrim {
  background-color: #d8d8d8;
  height: 1px;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .part-options.part-options-fragrance-slider .category-indicator-scrim {
    margin-top: 11px;
  }
}
@media (min-width: 1160px) {
  .part-options.part-options-fragrance-slider .category-indicator-scrim {
    width: 540px;
  }
}
.part-options.part-options-fragrance-slider .category-indicator-scrim .category-indicator {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .part-options.part-options-fragrance-slider .categories,
  .part-options.part-options-fragrance-slider .category-indicator-scrim {
    transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1) 0.2s;
  }
}
@media (max-width: 1023px) {
  .part-options.part-options-fragrance-slider ~ .button-row {
    bottom: 0;
    opacity: 1;
    transition: bottom 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
.part-options ul.categories {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 1023px) {
  .part-options ul.categories {
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-evenly;
  }
}
@media (min-width: 1024px) {
  .part-options ul.categories {
    background-color: #ffffff;
    justify-content: center;
    margin-top: -75px;
    position: fixed;
    z-index: 1;
  }
}
@media (min-width: 1025px) {
  .part-options ul.categories {
    width: 470px;
  }
}
@media (min-width: 1160px) {
  .part-options ul.categories {
    width: 560px;
  }
}
.part-options ul.categories li {
  display: flex;
  padding: 0 6px;
  text-align: center;
}
@media (min-width: 1024px) {
  .part-options ul.categories li {
    margin: 0 20px;
  }
}
.part-options ul.categories li.selected span.category-name {
  color: #886655;
}
.part-options ul.categories li a {
  letter-spacing: normal;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.part-options ul.categories li span.category-name {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 20px;
}
.part-options ul.options {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .part-options ul.options {
    flex-flow: wrap;
  }
}
@media (max-width: 1023px) {
  .part-options ul.options:first-child li:first-child {
    margin-left: 30px;
  }
}
@media (max-width: 1023px) {
  .part-options ul.options:last-child li:last-child {
    margin-right: 30px;
  }
}
.part-options ul.options li {
  border: 1px solid #eaeaea;
  position: relative;
  text-align: center;
}
@media (max-width: 1023px) {
  .part-options ul.options li {
    margin: 10px 5px;
  }
}
@media (min-width: 1024px) {
  .part-options ul.options li {
    margin: 8px;
  }
}
@media (max-width: 1023px) {
  .part-options ul.options li:not(.has-thumb) a {
    padding: 25px 15px;
  }
}
@media (min-width: 1024px) {
  .part-options ul.options li:not(.has-thumb) a {
    padding: 35px 15px;
  }
}
.part-options ul.options li.has-thumb a {
  padding: 33px 10px 28px 10px;
}
@media (min-width: 1024px) {
  .part-options ul.options li.has-thumb a {
    padding: 37px 15px 28px 15px;
  }
}
.part-options ul.options li::before {
  background-color: #886655;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 2;
}
.part-options ul.options li:hover, .part-options ul.options li.selected {
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.06);
}
.part-options ul.options li.selected::before {
  opacity: 1;
}
.part-options ul.options li a {
  display: flex;
  flex-flow: column;
  height: 100%;
  letter-spacing: normal;
  text-decoration: none;
}
@media (max-width: 1023px) {
  .part-options ul.options li a {
    justify-content: space-between;
  }
}
.part-options ul.options li a.bottle-wrapper img {
  width: 40px;
}
@media (min-width: 1024px) {
  .part-options ul.options li a.bottle-wrapper img {
    width: 50px;
  }
}
.part-options ul.options li a.bottle-cap-wrapper img {
  width: 70px;
}
@media (max-width: 1023px) {
  .part-options ul.options li a.bottle-cap-wrapper img {
    margin: -5px 0 0;
  }
}
@media (min-width: 1024px) {
  .part-options ul.options li a.bottle-cap-wrapper img {
    margin-bottom: 4px;
  }
}
.part-options ul.options li span.error-badge {
  left: calc(50% + 35px);
  position: absolute;
  top: calc(50% + 15px);
}
@media (max-width: 1023px) {
  .part-options ul.options li span.error-badge {
    height: 18px;
    width: 18px;
  }
}
@media (min-width: 1024px) {
  .part-options ul.options li span.error-badge {
    height: 20px;
    width: 20px;
  }
}
.part-options ul.options li span.error-message {
  background-color: #f6f4f2;
  color: #1a1919;
  font-size: 11px;
  left: 0;
  letter-spacing: 1px;
  line-height: 13px;
  padding: 4px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.part-options ul.options li span.error-message .error-badge {
  display: inline-block;
  height: 12px;
  left: auto;
  position: relative;
  top: 2px;
  width: 12px;
}
.part-options ul.options li span.error-message .error-badge::before {
  bottom: 1px;
}
.part-options ul.options li span.error-message .error-badge::after {
  top: 1px;
}
.part-options ul.options li span.option-name {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  line-height: 21px;
}
.part-options ul.options li span.option-excerpt {
  color: rgba(26, 25, 25, 0.5);
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  line-height: 20px;
}
@media (min-width: 1024px) {
  .part-options ul.options li span.option-excerpt {
    flex-grow: 1;
    margin-top: 20px;
  }
}
.part-options ul.options li span.option-more {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: normal;
  text-decoration: underline;
  text-transform: uppercase;
}
.part-options ul.options li .option-thumb {
  height: 100%;
  width: 100%;
}
.part-options ul.options li .option-thumb img {
  width: 70px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .part-options ul.options li .option-thumb img {
    margin-bottom: 4px;
  }
}
@media (min-width: 1024px) {
  .part-options ul.options li .option-thumb img {
    margin-bottom: 22px;
  }
}
.part-options ul.options li .option-thumb + .option-name {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
}
@media (min-width: 1024px) {
  .part-options .category-indicator-scrim {
    height: 2px;
    margin-top: -25px;
    position: fixed;
    z-index: 2;
  }
}
@media (min-width: 1025px) {
  .part-options .category-indicator-scrim {
    width: 470px;
  }
}
@media (min-width: 1160px) {
  .part-options .category-indicator-scrim {
    width: 560px;
  }
}
.part-options .category-indicator {
  background-color: #886655;
  left: 0;
}
@media (max-width: 1023px) {
  .part-options .category-indicator {
    height: 1px;
    position: absolute;
    top: 48px;
  }
}
@media (min-width: 1024px) {
  .part-options .category-indicator {
    height: 100%;
    position: absolute;
  }
}
.part-options .category-indicator.allow-animation {
  transition: left 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.part-options .part-options-slider {
  position: relative;
  scroll-behavior: smooth;
}
@media (max-width: 1023px) {
  .part-options .part-options-slider {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
@media (max-width: 1023px) {
  .part-options .part-options-slider.has-statusbar {
    padding-bottom: 25px;
  }
}
.part-options .part-options-slider.has-wide-tiles ul.options li:not(.has-thumb) a {
  padding: 25px 15px;
}
.part-options .part-options-slider.has-wide-tiles .option-thumb {
  height: auto;
}
.part-options .part-options-slider.has-wide-tiles .option-thumb + .option-name {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: normal;
}
.part-options .part-options-slider.has-wide-tiles .option-thumb img {
  max-width: 41px;
  padding-bottom: 9px;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .part-options .part-options-slider.has-wide-tiles .option-thumb img {
    max-width: 30px;
    padding-bottom: 0;
  }
}
.part-options .part-options-slider.has-wide-tiles .option-name {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: normal;
}
@media (max-width: 1023px) {
  .part-options .part-options-slider.has-wide-tiles .option-name {
    letter-spacing: 0.5px;
  }
}
.part-options .part-options-slider.has-wide-tiles .option-excerpt {
  margin-top: 10px;
}
@media (max-width: 1023px) {
  .part-options .part-options-slider.has-wide-tiles .option-excerpt {
    margin-top: 2px;
  }
}
.part-options .part-options-slider.has-wide-tiles .option-more {
  margin-top: 20px;
}
@media (max-width: 1023px) {
  .part-options .part-options-slider.has-wide-tiles .option-more {
    margin-top: 12px;
  }
}
.part-options .part-options-slider h4.category-name {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 14px;
  margin: 0 6px;
  padding-top: 20px;
}
.part-options .fragrance-options-slider {
  position: relative;
  height: calc(100% - 11px);
  margin-top: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .part-options .fragrance-options-slider {
    height: calc(100% - 154px);
  }
}
@media (max-width: 1023px) {
  .part-options .fragrance-options-slider {
    left: 0;
    opacity: 1;
    transition: left 0.6s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .part-options .fragrance-options-slider {
    width: 120%;
    margin-left: -10%;
    height: calc(100% - 114px);
  }
}
@media (min-width: 1440px) {
  .part-options .fragrance-options-slider {
    width: 120%;
    margin-left: -10%;
  }
}
.part-options .fragrance-options-slider .fragrance-slider-body {
  position: relative;
}
.part-options .fragrance-options-slider .fragrance-slider-body::before, .part-options .fragrance-options-slider .fragrance-slider-body::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 1;
  display: none;
}
@media (min-width: 1025px) {
  .part-options .fragrance-options-slider .fragrance-slider-body::before, .part-options .fragrance-options-slider .fragrance-slider-body::after {
    display: block;
  }
}
.part-options .fragrance-options-slider .fragrance-slider-body::before {
  left: 0;
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
.part-options .fragrance-options-slider .fragrance-slider-body::after {
  right: 0;
  background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
}
.part-options .fragrance-options-slider .slick-slide {
  padding: 0 5px;
}
.part-options .fragrance-options-slider .slider-gap {
  display: none;
}
@media (min-width: 768px) {
  .part-options .fragrance-options-slider .slider-gap {
    display: block;
  }
}
.part-options .fragrance-options-slider .configurator-slider-controls {
  display: block;
}
@media (max-width: 767px) {
  .part-options .fragrance-options-slider .configurator-slider-controls {
    display: none;
  }
}
.part-options .fragrance-options-slider .configurator-slider-controls button {
  z-index: 2;
}
@media (min-width: 768px) {
  .part-options .fragrance-options-slider .configurator-slider-controls button:first-child {
    left: -4px;
  }
}
@media (min-width: 768px) {
  .part-options .fragrance-options-slider .configurator-slider-controls button:last-child:not(:only-child) {
    right: -4px;
  }
}
.part-options .fragrance-options-slider .slider-item {
  border: 1px solid #eaeaea;
  opacity: 0.2;
  pointer-events: none;
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.part-options .fragrance-options-slider .slider-item.selected {
  opacity: 1;
  pointer-events: auto;
}
.part-options .fragrance-options-slider .slider-item .content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-decoration: none;
  padding: 24px 27px 18px;
  cursor: pointer;
}
.part-options .fragrance-options-slider .slider-item .error-message {
  color: #d6122d;
  font-size: 11px;
  left: 0;
  line-height: 13px;
  padding: 4px 0;
  position: absolute;
  top: -4px;
  width: 100%;
  z-index: 1;
  text-align: center;
}
.part-options .fragrance-options-slider .slider-item .error-message .error-badge {
  background: url("../images/error-badge.svg") left top no-repeat;
  background-size: 100% 100%;
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  left: auto;
  position: relative;
  top: 4px;
  width: 16px;
  margin-right: 6px;
}
.part-options .fragrance-options-slider .slider-item .option-image {
  margin-bottom: 18px;
  max-width: 100%;
}
.part-options .fragrance-options-slider .slider-item .option-image img {
  display: block;
  max-width: 100%;
  max-height: 160px;
  margin: 0 auto;
}
.part-options .fragrance-options-slider .slider-item .option-category {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  color: #1a1919;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-bottom: 5px;
  text-align: center;
}
.part-options .fragrance-options-slider .slider-item .option-name {
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 32px;
  line-height: 41px;
  color: #1a1919;
  text-align: center;
  margin-bottom: 21px;
  letter-spacing: 0.5px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .part-options .fragrance-options-slider .slider-item .option-name {
    max-width: 150px;
  }
}
.part-options .fragrance-options-slider .slider-item .option-more-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}
.part-options .fragrance-options-slider .slider-item .option-more {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #886655;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .upsell-options {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.upsell-options ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .upsell-options ul {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .upsell-options ul li {
    height: 73vh;
    flex: 0 0 80%;
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .upsell-options ul li {
    min-height: 255px;
    width: 40%;
  }
}
@media (min-width: 1024px) {
  .upsell-options ul li {
    width: 50%;
  }
}
.upsell-options ul li:hover .upsell-option, .upsell-options ul li.selected .upsell-option {
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.06);
}
@media (max-width: 767px) {
  .upsell-options ul li:first-child .upsell-option {
    margin-left: 45px;
  }
}
@media (max-width: 767px) {
  .upsell-options ul li:last-child .upsell-option {
    margin-right: 45px;
  }
}
.upsell-options ul span.error-message {
  bottom: 5px;
  color: #d6122d;
  font-size: 11px;
  left: 0;
  line-height: 13px;
  padding: 4px 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .upsell-options ul figure {
    height: calc(50% + 30px);
    background-color: #f6f4f2;
    margin: -30px 0 20px -20px;
    padding: 10px 0;
    width: calc(100% + 40px);
  }
}
@media (min-width: 768px) {
  .upsell-options ul figure {
    height: 220px;
    width: 100%;
  }
}
.upsell-options ul figure img {
  max-height: 100%;
}
.upsell-options .upsell-option {
  border: 1px solid #eaeaea;
  padding: 30px 20px;
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .upsell-options .upsell-option {
    background-color: #ffffff;
    height: calc(100% - 20px);
    margin: 10px 5px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .upsell-options .upsell-option {
    height: calc(100% - 60px);
    margin: 20px 8px;
  }
}
@media (min-width: 1024px) {
  .upsell-options .upsell-option {
    height: calc(100% - 40px);
    margin: 20px 8px;
  }
}
.upsell-options .upsell-option input[type=radio] {
  opacity: 0;
}
@media (min-width: 768px) {
  .upsell-options .upsell-option label {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.upsell-options .upsell-option label.selected::before {
  background-color: #9a7b69;
  border-color: #9a7b69;
  content: "\ea2f";
}
.upsell-options .upsell-option label::before {
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  bottom: 30px;
  color: #ffffff;
  content: "";
  font-family: iconfont;
  font-size: 8px;
  height: 24px;
  left: 50%;
  letter-spacing: -1px;
  line-height: 26px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 24px;
}
.upsell-options .upsell-product-title {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 767px) {
  .upsell-options .upsell-product-title {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 19px;
  }
}
@media (min-width: 768px) {
  .upsell-options .upsell-product-title {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
  }
}
@media (min-width: 1024px) {
  .upsell-options .upsell-product-title {
    font-size: 22px;
    letter-spacing: 0.6px;
    line-height: 30px;
  }
}
.upsell-options .upsell-product-title + .upsell-product-description {
  margin-top: 10px;
}
.upsell-options .upsell-product-description {
  display: block;
}
@media (max-width: 767px) {
  .upsell-options .upsell-product-description {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 17px;
  }
}
@media (min-width: 768px) {
  .upsell-options .upsell-product-description {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 28px;
  }
}
@media (min-width: 1024px) {
  .upsell-options .upsell-product-description {
    font-size: 16px;
    letter-spacing: 1.14px;
    line-height: 28px;
  }
}
.upsell-options .upsell-product-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 767px) {
  .upsell-options .upsell-product-price {
    align-items: center;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    height: 50px;
    justify-content: flex-end;
    letter-spacing: 2px;
    line-height: 21px;
    position: absolute;
    width: calc(100% - 40px);
  }
}
@media (min-width: 768px) {
  .upsell-options .upsell-product-price {
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 21px;
    margin-bottom: 40px;
    margin-top: auto;
  }
}
@media (min-width: 1024px) {
  .upsell-options .upsell-product-price {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 40px;
    margin-top: auto;
  }
}

.status-bar {
  opacity: 1;
  text-align: center;
}
.status-bar p {
  font-size: 11px;
  line-height: 13px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 4px 8px;
  max-width: 400px;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .status-bar p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.overlay {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}
@media (max-width: 1023px) {
  .overlay {
    left: 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .overlay {
    left: 50%;
    width: 50%;
  }
}
.overlay h1,
.overlay p {
  color: #1a1919;
}
.overlay h1 {
  font-size: 32px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 32px;
  text-transform: none;
}
.overlay p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 24px;
}
.overlay .overlay-slot {
  height: 100%;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  text-align: center;
}
.overlay .overlay-slot::before, .overlay .overlay-slot::after {
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 1;
}
@media (max-width: 1023px) {
  .overlay .overlay-slot::before, .overlay .overlay-slot::after {
    position: fixed;
  }
}
@media (min-width: 1024px) {
  .overlay .overlay-slot::before, .overlay .overlay-slot::after {
    position: absolute;
  }
}
.overlay .overlay-slot::before {
  height: 60px;
  top: 0;
}
.overlay .overlay-slot::after {
  bottom: 0;
  height: 180px;
}
.overlay .overlay-slot.top-gradient::before, .overlay .overlay-slot.bottom-gradient::after {
  opacity: 1;
}
.overlay .overlay-slot.top-gradient::before {
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
}
.overlay .overlay-slot.bottom-gradient::after {
  background: linear-gradient(to top, #ffffff, #ffffff 68%, rgba(255, 255, 255, 0));
}
.overlay .overlay-slot .simplebar-offset {
  -webkit-overflow-scrolling: auto;
}
.overlay .overlay-body {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .overlay .overlay-body {
    min-height: calc(100% - 135px);
    padding: 20px 0 115px;
  }
}
@media (max-width: 1023px) {
  .overlay .overlay-body {
    width: 75%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .overlay .overlay-body p {
    margin-bottom: 110px;
  }
}
@media (min-width: 1024px) {
  .overlay .overlay-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 100vh;
    width: 66.67%;
  }
}
.overlay .overlay-body.fragrance-overlay .fragrance-image {
  max-width: 135px;
}
@media (max-width: 1023px) {
  .overlay .overlay-body.has-scrollbar {
    min-height: calc(100% - 215px);
    padding: 100px 0 115px;
  }
}
@media (max-width: 1023px) {
  .overlay .overlay-body:not(.has-scrollbar) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  }
}
.overlay .overlay-close {
  background-color: #1a1919;
  border-radius: 50%;
  bottom: 40px;
  color: #ffffff;
  height: 64px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 64px;
  z-index: 2;
}
.overlay .simplebar-track {
  z-index: 3;
}
.overlay .fragrance-image {
  max-width: 50px;
  margin: 0 auto;
}
.overlay .fragrance-image img {
  max-width: 100%;
}
.overlay .fragrance-image + h1 {
  padding-top: 9px;
  margin-bottom: 20px;
  max-width: 335px;
}
.overlay .fragrance-image ~ p {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .overlay .fragrance-image ~ p {
    font-size: 16px;
  }
}
.overlay .fragrance-note {
  position: relative;
  max-width: 335px;
  margin: 0 auto 24px;
}
.overlay .fragrance-note:not(:first-child)::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 80px;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eaeaea;
}
.overlay .fragrance-note .fragrance-title,
.overlay .fragrance-note .fragrance-text {
  display: block;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.overlay .fragrance-note .fragrance-title {
  font-size: 18px;
}
.overlay .fragrance-note .fragrance-text {
  font-size: 16px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footer-header-transition-enter,
  .footer-header-transition-leave-to {
    opacity: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footer-header-transition-enter-active {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.6s;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footer-header-transition-leave-active {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media (max-width: 1023px) {
  .full-height-footer-transition-enter-active:not(.upsell-footer) {
    transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (max-width: 767px) {
  .full-height-footer-transition-enter-active.upsell-footer {
    transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media (max-width: 1023px) {
  .full-height-footer-transition-leave-active:not(.upsell-footer) {
    transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1) 0.2s;
  }
}
@media (max-width: 767px) {
  .full-height-footer-transition-leave-active.upsell-footer {
    transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1) 0.2s;
  }
}

@media (max-width: 1023px) {
  .full-height-footer-transition-enter:not(.upsell-footer),
  .full-height-footer-transition-leave-to:not(.upsell-footer) {
    opacity: 0;
  }
  .full-height-footer-transition-enter:not(.upsell-footer) .categories,
  .full-height-footer-transition-enter:not(.upsell-footer) .category-indicator-scrim,
  .full-height-footer-transition-leave-to:not(.upsell-footer) .categories,
  .full-height-footer-transition-leave-to:not(.upsell-footer) .category-indicator-scrim {
    opacity: 0;
  }
  .full-height-footer-transition-enter:not(.upsell-footer) .fragrance-options-slider,
  .full-height-footer-transition-leave-to:not(.upsell-footer) .fragrance-options-slider {
    left: 50px;
    opacity: 0;
  }
  .full-height-footer-transition-enter:not(.upsell-footer) .button-row,
  .full-height-footer-transition-leave-to:not(.upsell-footer) .button-row {
    bottom: -100px !important;
  }
  .full-height-footer-transition-enter:not(.upsell-footer).parts-footer:not(.invert-direction), .full-height-footer-transition-enter:not(.upsell-footer).parts-footer.invert-direction,
  .full-height-footer-transition-leave-to:not(.upsell-footer).parts-footer:not(.invert-direction),
  .full-height-footer-transition-leave-to:not(.upsell-footer).parts-footer.invert-direction {
    bottom: -415px;
    opacity: 1;
  }
  .full-height-footer-transition-enter:not(.upsell-footer).options-footer,
  .full-height-footer-transition-leave-to:not(.upsell-footer).options-footer {
    opacity: 0;
  }
}
@media (max-width: 767px) {
  .full-height-footer-transition-enter.upsell-footer,
  .full-height-footer-transition-leave-to.upsell-footer {
    opacity: 0;
  }
  .full-height-footer-transition-enter.upsell-footer .button-row,
  .full-height-footer-transition-leave-to.upsell-footer .button-row {
    bottom: -100px !important;
  }
  .full-height-footer-transition-enter.upsell-footer.options-footer,
  .full-height-footer-transition-leave-to.upsell-footer.options-footer {
    opacity: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .full-height-footer-transition-enter.upsell-footer.options-footer:not(.invert-direction), .full-height-footer-transition-enter.upsell-footer.options-footer.invert-direction,
  .full-height-footer-transition-leave-to.upsell-footer.options-footer:not(.invert-direction),
  .full-height-footer-transition-leave-to.upsell-footer.options-footer.invert-direction {
    bottom: -415px;
    opacity: 1;
  }
}

@media (max-width: 1023px) {
  .full-height-footer-transition-enter-active.parts-footer:not(.invert-direction), .full-height-footer-transition-enter-active.parts-footer.invert-direction,
  .full-height-footer-transition-leave-active.parts-footer:not(.invert-direction),
  .full-height-footer-transition-leave-active.parts-footer.invert-direction {
    transition: bottom 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (max-width: 1023px) {
  .full-height-footer-transition-enter-active.options-footer:not(.upsell-footer),
  .full-height-footer-transition-leave-active.options-footer:not(.upsell-footer) {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (max-width: 767px) {
  .full-height-footer-transition-enter-active.options-footer.upsell-footer,
  .full-height-footer-transition-leave-active.options-footer.upsell-footer {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .full-height-footer-transition-enter-active.options-footer.upsell-footer,
  .full-height-footer-transition-leave-active.options-footer.upsell-footer {
    transition: bottom 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media (max-width: 1023px) {
  .switch-footers-transition-enter.parts-footer, .switch-footers-transition-enter.options-footer,
  .switch-footers-transition-leave-to.parts-footer,
  .switch-footers-transition-leave-to.options-footer {
    bottom: -415px;
  }
}
@media (max-width: 1023px) {
  .switch-footers-transition-enter.result-footer,
  .switch-footers-transition-leave-to.result-footer {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .switch-footers-transition-enter.parts-footer:not(.invert-direction) {
    opacity: 0;
    right: -200px;
    z-index: 3 !important;
  }
}
@media (min-width: 1024px) {
  .switch-footers-transition-enter.parts-footer.invert-direction {
    opacity: 0;
    right: 100px;
    z-index: 3 !important;
  }
}
@media (min-width: 1024px) {
  .switch-footers-transition-enter.options-footer, .switch-footers-transition-enter.result-footer {
    opacity: 0;
    right: -100px;
    z-index: 3 !important;
  }
}

@media (min-width: 1024px) {
  .switch-footers-transition-leave-to.parts-footer:not(.invert-direction), .switch-footers-transition-leave-to.parts-footer.invert-direction {
    opacity: 0;
    right: 100px;
    z-index: 3 !important;
  }
}
@media (min-width: 1024px) {
  .switch-footers-transition-leave-to.options-footer, .switch-footers-transition-leave-to.result-footer {
    opacity: 0;
    right: -100px;
    z-index: 3 !important;
  }
}

@media (max-width: 1023px) {
  .switch-footers-transition-enter-active.parts-footer, .switch-footers-transition-enter-active.options-footer,
  .switch-footers-transition-leave-active.parts-footer,
  .switch-footers-transition-leave-active.options-footer {
    transition: bottom 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (max-width: 1023px) {
  .switch-footers-transition-enter-active.result-footer,
  .switch-footers-transition-leave-active.result-footer {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media (min-width: 1024px) {
  .switch-footers-transition-enter-active.parts-footer:not(.invert-direction) {
    transition: right 0.6s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (min-width: 1024px) {
  .switch-footers-transition-enter-active.options-footer, .switch-footers-transition-enter-active.result-footer, .switch-footers-transition-enter-active.parts-footer.invert-direction {
    transition: right 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.4s, opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.4s;
  }
}

@media (min-width: 1024px) {
  .switch-footers-transition-leave-active {
    transition: right 0.6s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media (min-width: 1024px) {
  .footer-backdrop-transition-enter,
  .footer-backdrop-transition-leave-to {
    margin-left: 200px;
  }
}

@media (min-width: 1024px) {
  .footer-backdrop-transition-enter-active,
  .footer-backdrop-transition-leave-active {
    transition: margin 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media (max-width: 1023px) {
  .part-options-transition-enter,
  .part-options-transition-leave-to {
    margin-left: 200px;
  }
}

@media (max-width: 1023px) {
  .part-options-transition-enter-active,
  .part-options-transition-leave-active {
    transition: margin 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

.overlay-transition-enter,
.overlay-transition-leave-to {
  opacity: 0;
}
.overlay-transition-enter .overlay-slot,
.overlay-transition-leave-to .overlay-slot {
  opacity: 0 !important;
}

@media (max-width: 1023px) {
  .overlay-transition-enter .overlay-slot {
    margin-top: 190px !important;
  }
}
@media (min-width: 1024px) {
  .overlay-transition-enter .overlay-slot {
    margin-top: 250px !important;
  }
}

.overlay-transition-enter-active,
.overlay-transition-leave-active {
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.overlay-transition-enter-active .overlay-slot {
  transition: margin 0.8s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.status-bar-transition-enter p,
.status-bar-transition-leave-to p {
  opacity: 0;
}

.status-bar-transition-enter-active p,
.status-bar-transition-leave-active p {
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.scene {
  height: 100%;
}
@media (min-width: 1024px) {
  .scene {
    position: relative;
  }
}
@media (min-width: 1024px) {
  .scene .backdrop {
    background-color: #ffffff;
    left: 50%;
    z-index: 1;
  }
}

.dialog {
  background: #fcfaf7;
  letter-spacing: 1px;
  line-height: 28px;
  text-align: center;
  z-index: 999;
}
@media (max-width: 1023px) {
  .dialog {
    box-shadow: 0 4px 6px -3px #767676;
    min-height: 149px;
    padding: 30px;
    position: fixed;
    top: 0;
    width: 101%;
  }
}
@media (min-width: 1024px) {
  .dialog {
    left: 50%;
    max-width: 480px;
    padding: 60px 70px;
    position: absolute;
    top: 35%;
    transform: translate(-50%);
  }
}
@media (max-width: 1023px) {
  .dialog a {
    font-size: 14px;
  }
}
.dialog .icon-close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 16px;
  font-size: 16px;
}

@media (max-width: 1023px) {
  .dialog-transition-enter,
  .dialog-transition-leave-to {
    transform: translateY(-110%);
  }
}

@media (max-width: 1023px) {
  .dialog-transition-enter-active,
  .dialog-transition-leave-active {
    transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

.scrim-dialog-transition-enter,
.scrim-dialog-transition-leave-to {
  opacity: 0;
}

@media (max-width: 1023px) {
  .scrim-dialog-transition-leave-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.4s;
  }
}
@media (min-width: 1024px) {
  .scrim-dialog-transition-leave-active {
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

.scrim-dialog-transition-enter-active {
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.product-configurator-block {
  background-color: #ffffff;
}

.product-configurator-inline {
  background-color: #ffffff;
  overflow: hidden;
}
@media (max-width: 767px) {
  .product-configurator-inline {
    height: 667px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator-inline {
    height: 926px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline {
    height: 720px;
    margin: 0 auto;
    max-width: 1440px;
  }
}
.product-configurator-inline h3 {
  text-transform: initial;
}
@media (max-width: 767px) {
  .product-configurator-inline h3 {
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator-inline h3 {
    font-size: 40px;
    letter-spacing: 1.5px;
    line-height: 50px;
  }
}
.product-configurator-inline .fragrance-header .icon,
.product-configurator-inline .configurator-slider .icon {
  color: #886655;
  line-height: 1;
}
@media (max-width: 1023px) {
  .product-configurator-inline .fragrance-header .icon,
  .product-configurator-inline .configurator-slider .icon {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline .fragrance-header .icon,
  .product-configurator-inline .configurator-slider .icon {
    font-size: 30px;
  }
}
.product-configurator-inline .error-badge {
  background: url("../images/error-badge.svg") left top no-repeat;
  background-size: 100% 100%;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.product-configurator-inline .error-message {
  color: #d6122d;
  font-size: 11px;
  line-height: 13px;
  padding: 4px 0;
}
.product-configurator-inline .error-message .error-badge {
  display: inline-block;
  height: 16px;
  position: relative;
  top: 4px;
  width: 16px;
}
.product-configurator-inline .part-name {
  font-family: "ClassGarmnd BT Italic", "Times New Roman", serif;
  font-size: 14px;
  font-style: italic;
}
.product-configurator-inline .overlay {
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 100;
}
.product-configurator-inline .overlay .overlay-slot {
  align-items: center;
  display: flex;
}
.product-configurator-inline .product-image img {
  height: 100%;
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.product-configurator-inline .product-image.loading img {
  opacity: 0;
}
@media (min-width: 1024px) {
  .product-configurator-inline .slick-slider,
  .product-configurator-inline .slick-list,
  .product-configurator-inline .slick-track,
  .product-configurator-inline .slick-slide > div {
    height: 100%;
  }
}
.product-configurator-inline .slick-dots {
  bottom: 40px;
  display: flex !important;
  left: 50%;
  list-style: none;
  position: absolute;
  transform: translateX(-50%);
  width: 400px;
  z-index: 10;
}
@media (max-width: 1023px) {
  .product-configurator-inline .slick-dots {
    bottom: -20px;
  }
}
@media (max-width: 767px) {
  .product-configurator-inline .slick-dots {
    width: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator-inline .slick-dots {
    width: 440px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline .slick-dots {
    bottom: 40px;
    width: 400px;
  }
}
.product-configurator-inline .slick-dots li {
  background-color: #d1d1d1;
  display: flex;
}
@media (max-width: 1023px) {
  .product-configurator-inline .slick-dots li {
    height: 2px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline .slick-dots li {
    height: 4px;
  }
}
.product-configurator-inline .slick-dots li:first-child {
  border-radius: 2px 0 0 2px;
}
.product-configurator-inline .slick-dots li:last-child {
  border-radius: 0 2px 2px 0;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+2), .product-configurator-inline .slick-dots li:nth-last-child(n+2) ~ li {
  width: 50%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+3), .product-configurator-inline .slick-dots li:nth-last-child(n+3) ~ li {
  width: 33.33%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+4), .product-configurator-inline .slick-dots li:nth-last-child(n+4) ~ li {
  width: 25%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+5), .product-configurator-inline .slick-dots li:nth-last-child(n+5) ~ li {
  width: 20%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+6), .product-configurator-inline .slick-dots li:nth-last-child(n+6) ~ li {
  width: 16.67%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+7), .product-configurator-inline .slick-dots li:nth-last-child(n+7) ~ li {
  width: 14.29%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+8), .product-configurator-inline .slick-dots li:nth-last-child(n+8) ~ li {
  width: 12.5%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+9), .product-configurator-inline .slick-dots li:nth-last-child(n+9) ~ li {
  width: 11.11%;
}
.product-configurator-inline .slick-dots li:nth-last-child(n+10), .product-configurator-inline .slick-dots li:nth-last-child(n+10) ~ li {
  width: 10%;
}
.product-configurator-inline .slick-dots li button {
  height: 100%;
  text-indent: -9999px;
  width: 100%;
}

.error-message-transition-enter,
.error-message-transition-leave-to {
  opacity: 0;
}

.error-message-transition-enter-active,
.error-message-transition-leave-active {
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.screen {
  display: flex;
  height: 100%;
  position: relative;
}
.screen::before {
  background-color: #ffffff;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
@media (max-width: 1023px) {
  .screen {
    flex-direction: column-reverse;
  }
}

.screen-transition-enter:not(.reverted) {
  transform: translate3d(0, 100px, 0);
}
.screen-transition-enter.reverted {
  transform: translate3d(0, -70px, 0);
}
.screen-transition-enter::before {
  opacity: 1 !important;
}

.screen-transition-leave-to:not(.reverted) {
  transform: translate3d(0, -70px, 0);
}
.screen-transition-leave-to.reverted {
  transform: translate3d(0, 100px, 0);
}
.screen-transition-leave-to::before {
  opacity: 1 !important;
}

.screen-transition-enter-active,
.screen-transition-leave-active {
  transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.screen-transition-enter-active::before,
.screen-transition-leave-active::before {
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.product-showcase {
  overflow: hidden;
  position: relative;
}
@media (max-width: 1023px) {
  .product-showcase {
    padding: 0 20px;
    position: absolute;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .product-showcase {
    bottom: 134px;
    height: 260px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-showcase {
    bottom: 150px;
    height: 480px;
  }
}
@media (min-width: 1024px) {
  .product-showcase {
    height: 100%;
    margin: auto 0;
    max-height: 368px;
    padding: 0 30px;
    width: 50%;
  }
}
@media (min-width: 1160px) {
  .product-showcase {
    padding: 0 64px;
  }
}
@media (max-width: 1023px) {
  .product-showcase figure {
    height: 100%;
    position: relative;
    width: 100%;
  }
}
.product-showcase .product-image {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product-showcase .product-image:nth-child(1) {
  z-index: 1;
}
@media (max-width: 1023px) {
  .product-showcase .product-image:nth-child(1) {
    left: 25%;
  }
}
@media (min-width: 1024px) {
  .product-showcase .product-image:nth-child(1) {
    left: 26%;
  }
}
@media (min-width: 1160px) {
  .product-showcase .product-image:nth-child(1) {
    left: 23%;
  }
}
.product-showcase .product-image:nth-child(2) {
  z-index: 2;
}
@media (max-width: 1023px) {
  .product-showcase .product-image:nth-child(2) {
    left: 35%;
  }
}
@media (min-width: 1024px) {
  .product-showcase .product-image:nth-child(2) {
    left: 41%;
  }
}
@media (min-width: 1160px) {
  .product-showcase .product-image:nth-child(2) {
    left: 37%;
  }
}
.product-showcase .product-image:nth-child(3) {
  z-index: 3;
}
.product-showcase .product-image:nth-child(4) {
  z-index: 2;
}
@media (max-width: 1023px) {
  .product-showcase .product-image:nth-child(4) {
    left: 65%;
  }
}
@media (min-width: 1024px) {
  .product-showcase .product-image:nth-child(4) {
    left: 61%;
  }
}
@media (min-width: 1160px) {
  .product-showcase .product-image:nth-child(4) {
    left: 63%;
  }
}
.product-showcase .product-image:nth-child(5) {
  z-index: 1;
}
@media (max-width: 1023px) {
  .product-showcase .product-image:nth-child(5) {
    left: 75%;
  }
}
@media (min-width: 1024px) {
  .product-showcase .product-image:nth-child(5) {
    left: 75%;
  }
}
@media (min-width: 1160px) {
  .product-showcase .product-image:nth-child(5) {
    left: 76%;
  }
}
.product-showcase .product-image img {
  height: 100%;
}

.product-showcase-transition-enter,
.product-showcase-transition-leave-to {
  left: 50% !important;
}

.product-showcase-transition-enter-active,
.product-showcase-transition-leave-active {
  transition: left 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.product-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1023px) {
  .product-description {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .product-description {
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details {
    height: 100%;
    padding: 20px 15px 15px 15px;
  }
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: none;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .product-description .product-info-column-details .product-info-content {
    padding: 0 42px;
  }
}
@media (min-width: 1160px) {
  .product-description .product-info-column-details .product-info-content {
    padding: 0 48px;
  }
}
@media (min-width: 1440px) {
  .product-description .product-info-column-details .product-info-content {
    padding: 0 146px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .screen-intro .product-description .product-info-column-details .product-info-content h3 {
    height: 100px;
    margin: 0 auto 10px auto;
    overflow: hidden;
    width: 508px;
  }
}
@media (max-width: 1023px) {
  .screen-result .product-description .product-info-column-details .product-info-content h3 {
    margin: 0 48px;
  }
}
@media (max-width: 767px) {
  .product-description .product-info-column-details .product-info-content p {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content p {
    margin: 0 auto;
    width: 508px;
  }
}
@media (min-width: 1024px) {
  .product-description .product-info-column-details .product-info-content p {
    margin-bottom: 0;
  }
}
.product-description .product-info-column-details .product-info-content .result-label {
  color: #886655;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  line-height: 23px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .product-description .product-info-column-details .product-info-content .result-label {
    font-size: 11px;
    letter-spacing: 1.5px;
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .result-label {
    font-size: 15px;
    letter-spacing: 2.5px;
    margin-top: 23px;
  }
}
@media (min-width: 1024px) {
  .product-description .product-info-column-details .product-info-content .result-label {
    font-size: 15px;
    letter-spacing: 2.5px;
  }
}
.product-description .product-info-column-details .product-info-content .product-info-icons {
  display: inline-flex;
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .product-info-icons {
    margin: 20px auto 0 auto;
  }
}
@media (min-width: 1024px) {
  .product-description .product-info-column-details .product-info-content .product-info-icons {
    margin: 20px 0 0 0;
  }
}
@media (max-width: 1023px) {
  .screen-result .product-description .product-info-column-details .product-info-content .product-info-icons {
    margin-top: auto;
  }
}
.product-description .product-info-column-details .product-info-content .product-info-icons .item {
  width: auto;
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .product-info-icons .item {
    margin: 0 10px;
    display: block;
  }
}
@media (min-width: 1024px) {
  .product-description .product-info-column-details .product-info-content .product-info-icons .item + .item {
    margin-left: 40px;
  }
}
.product-description .product-info-column-details .product-info-content .product-info-icons .product-info-icon {
  width: auto;
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .product-info-icons .product-info-icon {
    display: inline-block;
    height: auto;
    margin: 0;
  }
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .product-info-icons .info-text {
    display: inline-block;
  }
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .product-info-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .screen-intro .product-description .product-info-column-details .product-info-content .product-info-footer {
    margin-top: auto;
  }
}
@media (max-width: 1023px) {
  .screen-result .product-description .product-info-column-details .product-info-content .product-info-footer {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .screen-result .product-description .product-info-column-details .product-info-content .product-info-footer {
    margin: 0 auto;
    width: 440px;
  }
}
@media (max-width: 1023px) {
  .product-description .product-info-column-details .product-info-content .product-price {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .product-description .product-info-column-details .product-info-content .product-price {
    margin: 60px 0 10px 0;
  }
}
@media (max-width: 1023px) {
  .screen-intro .product-description .product-info-column-details .product-info-content .product-price {
    width: 50%;
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .screen-result .product-description .product-info-column-details .product-info-content .product-price {
    font-size: 18px;
    line-height: 21px;
    margin: 15px 0 30px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .screen-result .product-description .product-info-column-details .product-info-content .product-price {
    font-size: 24px;
    line-height: 32px;
    margin: 30px 0;
  }
}
@media (min-width: 1024px) {
  .product-description .btn {
    display: block;
    margin: 0 auto 10px auto;
  }
}
@media (max-width: 1023px) {
  .screen-intro .product-description .btn {
    margin: 0 auto;
  }
}
@media (max-width: 1023px) {
  .screen-result .product-description .btn {
    min-width: calc(50% - 5px);
  }
}
.product-description .btn-customize .icon-pencil-configurable {
  background: url("../images/pencil-black-icon.svg") left top no-repeat;
  background-size: 16px 16px;
  display: inline-block;
  height: 16px;
  width: 16px;
  transition: 0.2s ease-in-out;
  margin: 0 -24px -4px 2px;
}

.configurator-slider {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 767px) {
  .configurator-slider {
    padding: 16px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .configurator-slider {
    padding: 30px 0;
  }
}
@media (min-width: 1024px) {
  .configurator-slider {
    padding: 30px 0;
  }
}
@media (max-width: 767px) {
  .configurator-slider h3 {
    margin: 8px 0 96px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .configurator-slider h3 {
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .configurator-slider h3 {
    margin-bottom: 96px;
  }
}
.configurator-slider .part-details {
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .configurator-slider .part-details {
    height: 96px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .configurator-slider .part-details {
    height: 214px;
  }
}
@media (min-width: 1024px) {
  .configurator-slider .part-details {
    height: 96px;
  }
}
.configurator-slider .part-details .part-name {
  left: 50%;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
}

.configurator-slider-body {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .configurator-slider-body {
    margin: 200px 0 0 0;
  }
}
.configurator-slider-body .product-image {
  cursor: pointer;
  height: 320px;
}
.configurator-slider-body .product-image:hover img, .configurator-slider-body .product-image.selected img {
  opacity: 1;
}
.configurator-slider-body .product-image img {
  margin: 0 auto;
  opacity: 0.3;
  transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media (max-width: 1023px) {
  .configurator-slider-controls {
    display: none;
  }
}
.configurator-slider-controls button {
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  z-index: 1;
}
.configurator-slider-controls button:first-child {
  left: 40px;
}
.configurator-slider-controls button:last-child:not(:only-child) {
  right: 40px;
}
.configurator-slider-controls button.hidden {
  opacity: 0;
  pointer-events: none;
}
.configurator-slider-controls button.has-background {
  background-color: #ffffff;
  opacity: 0.5;
}
.configurator-slider-controls button.has-background:hover {
  opacity: 1;
}

.configurator-slider-indicator {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 11;
}
@media (max-width: 1023px) {
  .configurator-slider-indicator {
    bottom: -20px;
    height: 2px;
  }
}
@media (max-width: 767px) {
  .configurator-slider-indicator {
    width: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .configurator-slider-indicator {
    width: 500px;
  }
}
@media (min-width: 1024px) {
  .configurator-slider-indicator {
    bottom: 40px;
    height: 4px;
    width: 400px;
  }
}
.configurator-slider-indicator .configurator-current-slide {
  background-color: #453f3f;
  border-radius: 2px;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  transition: left 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.selected-product {
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .selected-product {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .selected-product {
    padding-top: 128px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .selected-product {
    padding-top: 160px;
  }
}
@media (min-width: 1024px) {
  .selected-product {
    margin-top: 30px;
    padding: 0 48px;
    width: calc(100% - 782px);
  }
}
.selected-product .part-name {
  margin: 10px 0 44px 0;
}
@media (min-width: 1024px) {
  .selected-product .part-name {
    height: 56px;
    overflow: hidden;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .selected-product .product-image {
    height: 186px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .selected-product .product-image {
    height: 260px;
  }
}
@media (min-width: 1024px) {
  .selected-product .product-image {
    display: flex;
    height: 508px;
  }
}
@media (max-width: 1023px) {
  .selected-product .product-image img {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .selected-product .product-image img {
    height: auto;
    margin-top: auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.fragrance-slider {
  position: relative;
}
@media (max-width: 1023px) {
  .fragrance-slider {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .fragrance-slider {
    width: 782px;
  }
}
.fragrance-slider .configurator-slider-controls button {
  bottom: 220px;
  top: auto;
  z-index: 11;
}
@media (max-width: 1023px) {
  .fragrance-slider .configurator-slider-controls button {
    display: none;
  }
}
.fragrance-slider .configurator-slider-controls button:first-child {
  left: -48px;
}
.fragrance-slider .configurator-slider-controls button:last-child:not(:only-child) {
  right: 10px;
}
.fragrance-slider .overlay .icon {
  color: #ffffff;
}
.fragrance-slider .options {
  display: flex;
  list-style: none;
}
.fragrance-slider .options li {
  border: 1px solid #eaeaea;
  margin: 0 4px;
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .fragrance-slider .options li {
    height: 146px;
    width: 280px;
  }
}
@media (min-width: 768px) {
  .fragrance-slider .options li {
    height: 260px;
    width: 174px;
  }
}
.fragrance-slider .options li::before {
  background-color: #886655;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 2;
}
.fragrance-slider .options li:hover, .fragrance-slider .options li.selected {
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.06);
}
.fragrance-slider .options li.selected::before {
  opacity: 1;
}
.fragrance-slider .options li a {
  display: flex;
  flex-flow: column;
  height: 100%;
  letter-spacing: normal;
  text-decoration: none;
}
@media (max-width: 767px) {
  .fragrance-slider .options li a {
    padding: 30px 15px 20px 15px;
  }
}
@media (min-width: 768px) {
  .fragrance-slider .options li a {
    padding: 35px 15px 20px 15px;
  }
}
.fragrance-slider .options li span.error-message {
  background-color: #f6f4f2;
  color: #1a1919;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.fragrance-slider .options li span.error-message .error-badge {
  height: 12px;
  top: 2px;
  width: 12px;
}
.fragrance-slider .options li span.option-name {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  line-height: 21px;
}
.fragrance-slider .options li span.option-excerpt {
  color: rgba(26, 25, 25, 0.5);
  flex-grow: 1;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
}
.fragrance-slider .options li span.option-more {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: normal;
  text-decoration: underline;
  text-transform: uppercase;
}
.fragrance-slider .options li span.icon-seal {
  background: url("../images/private-collection-seal.svg") left top no-repeat;
  background-size: 39px 39px;
  display: inline-block;
  height: 39px;
  width: 39px;
  margin: 0 auto 10px auto;
}
@media (max-width: 767px) {
  .fragrance-slider .options li span.icon-seal {
    display: none;
  }
}

.fragrance-header {
  text-align: center;
}
@media (max-width: 767px) {
  .fragrance-header {
    height: 92px;
    margin: 16px 0 255px 0;
    overflow: hidden;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .fragrance-header {
    height: 130px;
    margin: 16px 0 336px 0;
    overflow: hidden;
  }
}
@media (min-width: 1024px) {
  .fragrance-header {
    margin: 30px 0 70px 0;
  }
}
.fragrance-header h3 {
  font-size: 30px;
  letter-spacing: 1.2px;
  text-transform: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .fragrance-header h3 {
    height: 100px;
    margin: 0 auto 10px auto;
    overflow: hidden;
    width: 508px;
  }
}
@media (min-width: 1024px) {
  .fragrance-header h3 {
    height: 80px;
    margin: 0 auto;
    overflow: hidden;
    width: 480px;
  }
}

.fragrance-categories {
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  list-style: none;
}
@media (min-width: 1024px) {
  .fragrance-categories {
    margin: 0 54px 0 0;
  }
}
.fragrance-categories li {
  display: flex;
  margin: 0 30px;
  padding: 0 4px;
}
.fragrance-categories li.selected span.category-name {
  color: #886655;
}
.fragrance-categories li a {
  padding-bottom: 17px;
  text-decoration: none;
}
.fragrance-categories li span.category-name {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 20px;
}

.category-fragrances {
  position: relative;
}
@media (max-width: 767px) {
  .category-fragrances {
    height: 146px;
    margin: 20px 0 15px 0;
  }
}
@media (min-width: 768px) {
  .category-fragrances {
    height: 304px;
    margin: 20px 0 0 20px;
  }
}
@media (min-width: 1024px) {
  .category-fragrances {
    height: 304px;
    margin: 40px 0 48px 0;
  }
}
.category-fragrances::before, .category-fragrances::after {
  content: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  top: 0;
  width: 40px;
  z-index: 10;
}
.category-fragrances.gradient-left::before {
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  left: 0;
  opacity: 1;
}
.category-fragrances.gradient-right::after {
  background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
  opacity: 1;
  right: 0;
}
.category-fragrances .fragrances-scroll-body {
  display: flex;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.category-fragrances .fragrances-scroll-body::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.category-indicator {
  background-color: #886655;
  height: 1px;
  left: 55px;
  margin-top: -1px;
  position: absolute;
  width: 100px;
  z-index: 2;
}
.category-indicator:not(.skip-animation) {
  transition: left 0.6s cubic-bezier(0.32, 0.24, 0.15, 1), width 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media (max-width: 1023px) {
  .fragrance-options:first-child {
    margin-left: 10px;
  }
}
.fragrance-options h4 {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 3px;
  margin: 0 0 10px 4px;
}
@media (max-width: 767px) {
  .fragrance-options h4 {
    display: none;
  }
}

@media (max-width: 1023px) {
  .fragrance-footer {
    bottom: 20px;
    display: flex;
    position: absolute;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .fragrance-footer {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .fragrance-footer .btn {
    display: inline-block;
    max-width: none;
    min-width: auto;
    width: calc(50% - 21px);
  }
}
@media (min-width: 1024px) {
  .fragrance-footer .btn {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 1023px) {
  .fragrance-footer .btn.btn--secondary {
    margin: 0 7px 0 15px;
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .fragrance-footer .btn.btn--secondary {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 1023px) {
  .fragrance-footer .btn.btn--secondary .icon {
    font-size: 40px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .fragrance-footer .btn:not(.btn--secondary) {
    margin: 0 15px 0 auto;
  }
}

@media (max-width: 1023px) {
  .product-configurator-inline .result-slider {
    position: absolute;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .product-configurator-inline .result-slider {
    top: 154px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator-inline .result-slider {
    top: 244px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline .result-slider {
    height: 100%;
    position: relative;
    width: 50%;
  }
}
.product-configurator-inline .result-slider .restart-button {
  align-items: center;
  background-color: #000000;
  border-radius: 50%;
  color: #000000;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
}
.product-configurator-inline .result-slider .restart-button::before {
  transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.product-configurator-inline .result-slider .restart-button:hover::before {
  transform: rotate(-180deg);
}
@media (max-width: 1023px) {
  .product-configurator-inline .result-slider .restart-button {
    height: 44px;
    left: 20px;
    width: 44px;
  }
}
@media (max-width: 767px) {
  .product-configurator-inline .result-slider .restart-button {
    top: -134px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator-inline .result-slider .restart-button {
    top: -220px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline .result-slider .restart-button {
    height: 50px;
    left: 40px;
    top: 40px;
    width: 50px;
  }
}
.product-configurator-inline .result-slider .icon-restart {
  color: #ffffff;
  font-size: 22px;
}
@media (max-width: 1023px) {
  .product-configurator-inline .result-slider .icon-restart {
    font-size: 17px;
  }
}
.product-configurator-inline .result-slider .configured-product {
  height: 100%;
}
@media (max-width: 767px) {
  .product-configurator-inline .result-slider .configured-product .product-image {
    height: 280px;
    width: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-configurator-inline .result-slider .configured-product .product-image {
    height: 440px;
    width: 440px;
  }
}
@media (min-width: 1024px) {
  .product-configurator-inline .result-slider .configured-product .product-image {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .product-configurator-inline .result-slider .configured-product .product-image.selected img {
    opacity: 1;
  }
}
.product-configurator-inline .result-slider .configured-product .product-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 1023px) {
  .product-configurator-inline .result-slider .configured-product .product-image img {
    margin: 0 auto;
    opacity: 0.3;
    transition: opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: calc(100% - 20px);
  }
}

.customer-service-block {
  padding: 0 20px 38px;
  margin-top: -45px;
}
.customer-service-block .text-wrapper {
  text-align: center;
}
.customer-service-block .text-wrapper span,
.customer-service-block .text-wrapper a {
  display: block;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.46px;
  color: #626262;
}
@media (min-width: 768px) {
  .customer-service-block {
    display: none;
  }
}

.cs-service-block {
  display: none;
  margin-bottom: 64px;
}
@media (min-width: 1024px) {
  .cs-service-block {
    display: block;
  }
}
.cs-service-block .customer-service-block {
  display: block;
  margin: 0;
  padding: 0;
}
.cs-service-block .customer-service-block .text-wrapper {
  text-align: right;
  padding-right: 4px;
}
.cs-service-block .customer-service-block .text-wrapper a,
.cs-service-block .customer-service-block .text-wrapper span {
  color: #886655;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
}
.cs-service-block .customer-service-block .text-wrapper span {
  font-weight: 700;
}
.cs-service-block .customer-service-block .text-wrapper a {
  text-decoration: none;
  pointer-events: none;
}

.pt_checkout ~ .customer-service-block,
.pt_privacy ~ .disable-clicks .customer-service-block {
  margin-top: 0;
}

.customer-service-element {
  margin-top: 40px;
}
.customer-service-element p, .customer-service-element a {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.customer-service-element p {
  max-width: 610px;
  margin: 0 auto 10px;
  color: #767676;
}
@media (max-width: 767px) {
  .customer-service-element p {
    padding: 0 20px;
  }
}

.generic-modal-container {
  align-items: center;
  background: #fcfaf7;
  box-shadow: 0 4px 6px -3px #767676;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: center;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 24px;
  min-height: 150px;
  opacity: 1;
  padding: 35px 20px 25px;
  position: fixed;
  text-align: center;
  top: -1px;
  width: 100%;
  z-index: 999;
}
@media (min-width: 1024px) {
  .generic-modal-container {
    box-shadow: none;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 16px;
    height: auto;
    justify-content: flex-start;
    left: 50%;
    letter-spacing: 1px;
    line-height: 28px;
    max-width: 480px;
    overflow: auto;
    padding: 60px 70px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: none;
  }
}
.generic-modal-container.generic-modal-container-small {
  padding: 45px 50px;
}
.generic-modal-container.modal-large.boxberry-widget-wrapper {
  max-width: initial;
}
.generic-modal-container.modal-large.boxberry-widget-wrapper .content-wrapper {
  width: 100%;
}
.generic-modal-container.modal-large {
  background-color: #ffffff;
  padding: 40px 30px;
  max-height: 90%;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .generic-modal-container.modal-large {
    max-width: 600px;
    width: 90% !important;
    padding: 40px 35px 50px;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-large {
    max-width: 600px;
    width: 90% !important;
    padding: 40px 35px 50px;
  }
}
.generic-modal-container.modal-large p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
.generic-modal-container.modal-large h2 {
  font-size: 24px;
}
.generic-modal-container.easter-modal {
  max-width: 440px;
  padding: 56px 38px;
  background-color: #ffffff;
}
@media (max-width: 1023px) {
  .generic-modal-container.easter-modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.generic-modal-container.easter-modal .close-modal {
  font-size: 25px;
}
.generic-modal-container.easter-modal .icon-AR {
  font-size: 26px;
  color: #886655;
}
.generic-modal-container.easter-modal .heading-h4 {
  font-size: 24px;
  text-transform: none;
  margin: 14px 0 20px;
}
.generic-modal-container.easter-modal .qr-code-phara {
  color: #1a1919;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .generic-modal-container.centered-on-mobile {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);
  }
}
.generic-modal-container.modal-full-width {
  top: auto;
  bottom: 0;
  left: 0;
  transform: none;
  padding: 35px 25px;
}
@media (max-width: 1023px) {
  .generic-modal-container.modal-full-width::before {
    content: "";
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 48px;
    background: #d8d8d8;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .generic-modal-container.modal-full-width {
    max-width: none;
    width: 100% !important;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-full-width {
    max-width: none;
    width: 100% !important;
    flex-direction: row;
    padding: 30px 35px;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-full-width .text-holder {
    text-align: left;
  }
}
.generic-modal-container.modal-full-width .text-holder h2.heading-large {
  font-size: 20px;
  letter-spacing: 1.2px;
  line-height: 24px;
  margin: 6px auto 18px;
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-full-width .text-holder h2.heading-large {
    max-width: none;
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 35px;
    margin: 0 auto 10px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.modal-full-width .text-holder p {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin: 0 auto 22px;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-full-width .text-holder p {
    text-align: left;
    max-width: none;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.modal-full-width .row {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-full-width .cta-holder {
    width: 40%;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.modal-full-width .btn {
    width: 100%;
    max-width: none;
  }
}
.generic-modal-container.modal-full-width .close-modal {
  display: none;
}
.generic-modal-container.threshold-warning .heading-h4 {
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: initial;
}
.generic-modal-container.threshold-warning p {
  font-size: 14px;
  line-height: 24px;
}
.generic-modal-container.talisman-certificate {
  max-width: 315px;
  background-color: #ffffff;
  padding: 14px;
  overflow: visible;
  left: 50%;
  position: fixed;
  top: 45%;
  transform: translate(-50%, -50%);
  z-index: 100001;
}
.generic-modal-container.talisman-certificate ~ .generic-modal-overlay {
  z-index: 100000;
}
@media (max-width: 374px) {
  .generic-modal-container.talisman-certificate {
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .generic-modal-container.talisman-certificate {
    top: 50%;
    max-width: 440px;
    padding: 20px;
  }
}
.generic-modal-container.talisman-certificate .close-modal {
  top: auto;
  bottom: -68px;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 50%;
  height: 48px;
  width: 48px;
}
@media (max-width: 374px) {
  .generic-modal-container.talisman-certificate .close-modal {
    bottom: -75px;
  }
}
@media (min-width: 768px) {
  .generic-modal-container.talisman-certificate .close-modal {
    height: 60px;
    width: 60px;
    bottom: -95px;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.talisman-certificate .close-modal {
    bottom: -110px;
  }
}
.generic-modal-container.talisman-certificate .content-wrapper {
  border: 1px solid #b09043;
  padding: 20px 37px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .content-wrapper {
    padding: 20px 0 16px;
  }
}
@media (max-width: 374px) {
  .generic-modal-container.talisman-certificate .content-wrapper {
    padding: 5px 0;
  }
}
.generic-modal-container.talisman-certificate .ico {
  margin: 9px 0 0;
}
@media (min-width: 768px) {
  .generic-modal-container.talisman-certificate .ico {
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .text-holder {
    padding: 0 22px;
  }
}
.generic-modal-container.talisman-certificate .text-holder h2.heading-large {
  letter-spacing: 1.5px;
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .text-holder h2.heading-large {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.28px;
    margin-bottom: 8px;
  }
}
.generic-modal-container.talisman-certificate .talisman-certificate-content {
  padding: 0 22px;
}
@media (min-width: 768px) {
  .generic-modal-container.talisman-certificate .talisman-certificate-content {
    padding: 0;
  }
}
.generic-modal-container.talisman-certificate .talisman-certificate-content > p {
  margin-bottom: 23px;
}
.generic-modal-container.talisman-certificate .talisman-certificate-content label {
  display: block;
  text-align: left;
  font-size: 14px;
  margin-bottom: -4px;
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .talisman-certificate-content label {
    margin-bottom: -2px;
  }
}
.generic-modal-container.talisman-certificate .talisman-certificate-content input {
  margin: 0 0 10px;
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .talisman-certificate-content input {
    margin: 0 0 25px;
  }
}
@media (max-width: 374px) {
  .generic-modal-container.talisman-certificate .talisman-certificate-content input {
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .talisman-certificate-content p {
    font-size: 12px;
    line-height: 17px;
  }
}
.generic-modal-container.talisman-certificate .talisman-certificate-content .disclaimer {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.3px;
}
@media (max-width: 374px) {
  .generic-modal-container.talisman-certificate .talisman-certificate-content .disclaimer {
    margin-bottom: 0;
  }
}
.generic-modal-container.talisman-certificate .talisman-certificate-content .error {
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 1px;
  margin: -3px 0 4px 0;
  display: block;
  text-align: left;
}
.generic-modal-container.talisman-certificate .talisman-certificate-content .error + input {
  border-top: 1px solid #d6122d;
}
.generic-modal-container.talisman-certificate .cta-holder {
  margin-top: 28px;
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .cta-holder {
    margin-top: 16px;
  }
}
.generic-modal-container.talisman-certificate .cta-holder .row:nth-of-type(2) .btn {
  border: 0;
  margin-top: -18px;
}
@media (max-width: 767px) {
  .generic-modal-container.talisman-certificate .cta-holder .row:nth-of-type(2) .btn {
    margin-top: -24px;
  }
}
.generic-modal-container.flyout-open {
  z-index: 1000;
}
.generic-modal-container.flyout-open ~ .generic-modal-overlay {
  z-index: 999;
}
.generic-modal-container .close-modal {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 16px;
  font-size: 16px;
  z-index: 901;
}
.generic-modal-container .modal-close-icon {
  color: #000000;
  font-size: 25px;
  top: 20px;
  right: 21px;
}
.generic-modal-container .modal-overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
}
@media (min-width: 768px) {
  .generic-modal-container .modal-overlay-link {
    display: none;
  }
}
.generic-modal-container .image-holder {
  max-width: 90px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .generic-modal-container .image-holder {
    max-width: 130px;
  }
}
.generic-modal-container .image-holder img {
  max-width: 100%;
}
@media (max-width: 1023px) {
  .generic-modal-container .image-holder + .text-holder {
    width: calc(100% - 100px);
    padding-left: 21px;
    margin: -8px 0 0;
    max-width: 368px;
  }
  .generic-modal-container .image-holder + .text-holder .text-large {
    text-align: left;
  }
}
.generic-modal-container .text-holder {
  width: 100%;
}
.generic-modal-container .text-holder p {
  max-width: 438px;
  margin: 0 auto 10px;
  text-align: center;
}
@media (min-width: 768px) {
  .generic-modal-container .text-holder p {
    margin-bottom: 19px;
  }
}
.generic-modal-container .text-holder p.text-width-limiter {
  width: 270px;
}
.generic-modal-container .text-holder p.text-color-black {
  color: #000000;
}
.generic-modal-container .text-holder p.text-large {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-bottom: 0;
  max-width: 450px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.generic-modal-container .text-holder p.text-large:first-of-type {
  margin-bottom: 3px;
}
@media (min-width: 1024px) {
  .generic-modal-container .text-holder p.text-large {
    font-size: 20px;
    margin-bottom: 18px;
    text-align: center;
    line-height: 30px;
  }
}
.generic-modal-container .text-holder h2.heading-large {
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  max-width: 310px;
  color: #000000;
  text-transform: none;
  width: 100%;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin: 6px auto 20px;
}
@media (min-width: 768px) {
  .generic-modal-container .text-holder h2.heading-large {
    max-width: 400px;
    margin: 0 auto 12px;
  }
}
.generic-modal-container .text-holder h2.heading-large-narrow {
  letter-spacing: 0.5px;
}
.generic-modal-container .text-holder h2.heading-small {
  color: #886655;
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: center;
}
.generic-modal-container .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 282px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .generic-modal-container .row {
    width: 100%;
  }
}
.generic-modal-container .row:first-of-type:not(:only-child) .link,
.generic-modal-container .row:first-of-type:not(:only-child) .link--large,
.generic-modal-container .row:first-of-type:not(:only-child) .btn {
  margin: 27px 0;
}
@media (min-width: 768px) {
  .generic-modal-container .row:first-of-type:not(:only-child) .link,
  .generic-modal-container .row:first-of-type:not(:only-child) .link--large,
  .generic-modal-container .row:first-of-type:not(:only-child) .btn {
    margin: 20px 0 18px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container .row:nth-of-type(2) .flag-wrapper {
    align-items: flex-start;
  }
}
.generic-modal-container .row.row-full-width {
  max-width: none;
  width: 100%;
}
.generic-modal-container .row.row-custom-width {
  width: 350px;
}
@media (max-width: 767px) {
  .generic-modal-container .row.row-custom-width {
    width: 100%;
  }
}
.generic-modal-container .row.center-align {
  justify-content: center;
}
.generic-modal-container .row.left-align {
  justify-content: flex-start;
}
.generic-modal-container .flag-wrapper {
  display: flex;
  align-items: center;
}
.generic-modal-container .flag-wrapper .flag {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
@media (min-width: 768px) {
  .generic-modal-container .flag-wrapper .flag {
    margin-right: 11px;
  }
}
.generic-modal-container .flag-wrapper .flag img {
  width: 24px;
  height: 18px;
}
.generic-modal-container .cta-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.generic-modal-container .cta-holder.cta-holder-row {
  flex-direction: row;
}
.generic-modal-container .cta-holder.cta-holder-row .row {
  margin: 0 5px;
  width: calc(50% - 5px);
}
.generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .link,
.generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .link--large,
.generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .btn {
  margin: 20px 0 0;
}
@media (min-width: 768px) {
  .generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .link,
  .generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .link--large,
  .generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .btn {
    margin: 20px 0 0;
  }
}
@media (min-width: 1025px) {
  .generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .link,
  .generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .link--large,
  .generic-modal-container .cta-holder.cta-holder-row .row:first-of-type:not(:only-child) .btn {
    margin: 20px 0;
  }
}
.generic-modal-container .cta-holder.cta-holder-row .row .link,
.generic-modal-container .cta-holder.cta-holder-row .row .link--large,
.generic-modal-container .cta-holder.cta-holder-row .row .btn {
  margin: 20px 0 0;
}
@media (min-width: 768px) {
  .generic-modal-container .cta-holder.cta-holder-row .row .link,
  .generic-modal-container .cta-holder.cta-holder-row .row .link--large,
  .generic-modal-container .cta-holder.cta-holder-row .row .btn {
    margin: 20px 0 0;
  }
}
@media (min-width: 1025px) {
  .generic-modal-container .cta-holder.cta-holder-row .row .link,
  .generic-modal-container .cta-holder.cta-holder-row .row .link--large,
  .generic-modal-container .cta-holder.cta-holder-row .row .btn {
    margin: 20px 0;
  }
}
@media (min-width: 768px) {
  .generic-modal-container .cta-holder.cta-holder-row .row {
    margin: 0 10px;
    width: auto;
  }
}
.generic-modal-container .cta-holder.cta-holder-row button {
  width: 100%;
  min-width: 0;
}
@media (min-width: 768px) {
  .generic-modal-container .cta-holder.cta-holder-row button {
    min-width: 220px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container .hide-on-mobile {
    display: none;
  }
}
@media (max-width: 1023px) {
  .generic-modal-container .hide-on-mobile-and-tablet {
    display: none;
  }
}
.generic-modal-container .btn {
  min-width: 180px;
}
.generic-modal-container .btn:first-of-type:not(:only-child) {
  margin-bottom: 15px;
}
.generic-modal-container .link {
  font-size: 10px;
  line-height: 14px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.generic-modal-container .link--large {
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: none;
  line-height: 20px;
  text-align: left;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .generic-modal-container .link--large {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container .link--large {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  .generic-modal-container .link--large:hover, .generic-modal-container .link--large:active {
    text-decoration: none;
  }
}
.generic-modal-container .select-box-wrapper {
  width: 100%;
  padding: 10px 0;
  flex-grow: 0;
  display: flex;
  align-items: flex-end;
}
.generic-modal-container .gwp-select-box {
  margin: 0 auto;
  height: 30px;
  width: 30px;
}
.generic-modal-container .verification-message {
  margin: 25px auto 0;
}
@media (min-width: 768px) {
  .generic-modal-container .verification-message {
    margin: 30px auto -13px;
    max-width: 100%;
  }
}
.generic-modal-container .gwp-radio--hidden:checked {
  position: relative;
}
.generic-modal-container .gwp-radio--hidden:checked ~ .product-image {
  background-color: #ffffff;
}
.generic-modal-container .gwp-radio--hidden:checked ~ .select-box-wrapper {
  background-color: #ffffff;
}
.generic-modal-container .gwp-radio--hidden:checked ~ .select-box-wrapper .gwp-select-box {
  border-radius: 50%;
  background-color: #886655;
  border: 1px solid #886655;
}
.generic-modal-container .gwp-radio--hidden:checked ~ .select-box-wrapper .gwp-select-box::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  background-color: transparent;
  color: #ffffff;
  content: "\ea2f";
  display: block;
  font-size: 13px;
  height: 6px;
  left: 9px;
  position: absolute;
  top: 5px;
  transform: translate(-50%, -50%);
  width: 6px;
}
@media (min-width: 1025px) {
  .generic-modal-container .gwp-radio--hidden:checked ~ .select-box-wrapper .gwp-select-box::after {
    top: 3px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container .bonus-product-list-slider.drag-list {
    max-width: 100vw;
  }
}
@media (max-width: 1023px) {
  .generic-modal-container .bonus-product-list-slider.drag-list {
    margin-bottom: 40px;
    width: 100% !important;
  }
}
@media (max-width: 1023px) {
  .generic-modal-container .bonus-product-list-slider.drag-list .drag-list-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .generic-modal-container .bonus-product-list-slider.drag-list .drag-list-scroll::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 768px) {
  .generic-modal-container .bonus-product-list-slider.drag-list .drag-list-scroll {
    visibility: visible;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .generic-modal-container .bonus-product-list-slider.drag-list .drag-list-scroll {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .generic-modal-container .bonus-product-list-slider.drag-list .drag-list-scroll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 520px;
    width: 100%;
    flex-wrap: wrap;
    margin: 25px auto 20px;
    padding-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .generic-modal-container .bonus-product-list-slider.drag-list .drag-list-body {
    margin: 0 auto;
    min-width: 100%;
    justify-content: center;
  }
}
.generic-modal-container .bonus-product-list-slider-item {
  padding: 5px;
  width: 210px;
}
@media (max-width: 767px) {
  .generic-modal-container .bonus-product-list-slider-item {
    align-items: center;
    display: flex;
    justify-content: center;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 1023px) {
  .generic-modal-container .bonus-product-list-slider-item {
    padding: 20px 5px 10px;
  }
}
.generic-modal-container .bonus-product-list-slider-item img {
  max-width: 100%;
  max-height: 100%;
}
.generic-modal-container .bonus-product-list-slider-item label {
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(26, 25, 25, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding: 17px 35px 5px;
}
@media (min-width: 1024px) {
  .generic-modal-container .bonus-product-list-slider-item label {
    padding: 32px 15px 5px;
  }
}
.generic-modal-container .bonus-product-list-slider-item .product-name {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 11px;
}
@media (min-width: 1024px) {
  .generic-modal-container .bonus-product-list-slider-item .product-name {
    margin-bottom: 22px;
  }
}
.generic-modal-container .bonus-product-list-slider-item .product-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}
.generic-modal-container .bonus-product-list-slider-item .product-image img {
  max-width: 100%;
}
@media (min-width: 1024px) {
  .generic-modal-container .bonus-product-list-slider-item .product-image img {
    max-width: 110px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.modal-couponcode {
    padding: 60px 22px 40px;
  }
}
@media (min-width: 1024px) {
  .generic-modal-container.modal-couponcode {
    padding: 60px 35px;
  }
}
.generic-modal-container.modal-couponcode .close-modal {
  top: 23px;
  font-size: 28px;
}
@media (max-width: 767px) {
  .generic-modal-container.modal-couponcode .close-modal {
    right: 23px;
  }
}
@media (min-width: 768px) {
  .generic-modal-container.modal-couponcode .close-modal {
    right: 43px;
  }
}
@media (min-width: 768px) {
  .generic-modal-container.modal-couponcode .text-holder {
    max-width: 410px;
  }
}
.generic-modal-container.modal-couponcode .heading-h4 {
  font-size: 24px;
  letter-spacing: 1.5px;
  line-height: 32px;
  margin-bottom: 20px;
  text-transform: none;
}
.generic-modal-container.modal-couponcode p {
  color: #1a1919;
  letter-spacing: 0.5px;
}
.generic-modal-container.modal-couponcode .btn {
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767px) {
  .generic-modal-container.modal-couponcode .row {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .generic-modal-container.modal-couponcode .row {
    width: 340px;
  }
}
@media (max-width: 767px) {
  .generic-modal-container.modal-couponcode .row:first-of-type:not(:only-child) .link,
  .generic-modal-container.modal-couponcode .row:first-of-type:not(:only-child) .link--large,
  .generic-modal-container.modal-couponcode .row:first-of-type:not(:only-child) .btn {
    margin: 20px 0 10px;
  }
}
@media (min-width: 768px) {
  .generic-modal-container.modal-couponcode .row:first-of-type:not(:only-child) .link,
  .generic-modal-container.modal-couponcode .row:first-of-type:not(:only-child) .link--large,
  .generic-modal-container.modal-couponcode .row:first-of-type:not(:only-child) .btn {
    margin: 10px 0;
  }
}

.generic-modal-overlay {
  z-index: 991;
  background-color: rgba(26, 25, 25, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: all;
}

@media (max-width: 1023px) {
  .generic-modal-transition-enter .generic-modal-container:not(.centered-on-mobile),
  .generic-modal-transition-leave-to .generic-modal-container:not(.centered-on-mobile) {
    transform: translateY(-110%);
  }
}
@media (max-width: 1023px) {
  .generic-modal-transition-enter .generic-modal-container.centered-on-mobile,
  .generic-modal-transition-leave-to .generic-modal-container.centered-on-mobile {
    opacity: 0;
  }
}
@media (min-width: 1024px) {
  .generic-modal-transition-enter .generic-modal-container,
  .generic-modal-transition-leave-to .generic-modal-container {
    opacity: 0;
  }
}

@media (max-width: 1023px) {
  .generic-modal-transition-enter-active .generic-modal-container:not(.centered-on-mobile),
  .generic-modal-transition-leave-active .generic-modal-container:not(.centered-on-mobile) {
    transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (max-width: 1023px) {
  .generic-modal-transition-enter-active .generic-modal-container.centered-on-mobile,
  .generic-modal-transition-leave-active .generic-modal-container.centered-on-mobile {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
@media (min-width: 1024px) {
  .generic-modal-transition-enter-active .generic-modal-container,
  .generic-modal-transition-leave-active .generic-modal-container {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

.generic-modal-overlay-transition-enter,
.generic-modal-overlay-transition-leave-to {
  opacity: 0;
}

.generic-modal-overlay-transition-enter-active,
.generic-modal-overlay-transition-leave-active {
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.advent-reveal {
  counter-reset: reveal-counter;
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .advent-reveal {
    max-height: 576px;
  }
}
@media (min-width: 768px) {
  .advent-reveal {
    height: 528px;
  }
}
@media (max-width: 320px) {
  .advent-reveal.reveal {
    height: 1280px;
  }
}
@media (max-width: 767px) {
  .advent-reveal.reveal {
    max-height: 3000px;
  }
}
@media (min-width: 768px) {
  .advent-reveal.reveal {
    height: 700px;
  }
}
.advent-reveal.reveal .reveal-intro {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 767px) {
  .advent-reveal.reveal .reveal-intro {
    height: 375px;
  }
}
@media (min-width: 768px) {
  .advent-reveal.reveal .reveal-intro {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .advent-reveal.reveal .reveal-intro::after {
    height: 715px;
  }
}
.advent-reveal.reveal .reveal-intro p,
.advent-reveal.reveal .reveal-intro button {
  opacity: 0;
}
@media (max-width: 767px) {
  .advent-reveal.reveal .reveal-content-slides {
    margin-top: -48px;
  }
}
@media (max-width: 767px) {
  .advent-reveal:not(.revealed) {
    transition: max-height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.6s;
  }
}
@media (min-width: 768px) {
  .advent-reveal:not(.revealed) {
    transition: height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 1.2s;
  }
}
@media (max-width: 767px) {
  .advent-reveal:not(.revealed) .reveal-intro {
    transition: height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.4s, opacity 1.2s cubic-bezier(0.32, 0.24, 0.15, 1) 0.8s;
  }
}
@media (min-width: 768px) {
  .advent-reveal:not(.revealed) .reveal-intro {
    transition: width 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.6s, opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 1.8s;
  }
}
.advent-reveal:not(.revealed) .reveal-intro p,
.advent-reveal:not(.revealed) .reveal-intro .btn {
  transition: 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (max-width: 767px) {
  .advent-reveal:not(.revealed) .reveal-content-slides {
    transition: margin 1.2s cubic-bezier(0.32, 0.24, 0.15, 1) 0.8s;
  }
}
@media (max-width: 767px) {
  .advent-reveal.revealed {
    transition: max-height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.6s;
  }
}
@media (min-width: 768px) {
  .advent-reveal.revealed {
    transition: height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 0.6s;
  }
}
@media (max-width: 767px) {
  .advent-reveal.revealed .reveal-intro {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1), height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 1s;
  }
}
@media (min-width: 768px) {
  .advent-reveal.revealed .reveal-intro {
    transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1), width 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 1.2s;
  }
}
@media (max-width: 767px) {
  .advent-reveal.revealed .reveal-intro::after {
    transition: height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 1s;
  }
}
.advent-reveal.revealed .reveal-intro p,
.advent-reveal.revealed .reveal-intro .btn {
  transition: 0.6s cubic-bezier(0.32, 0.24, 0.15, 1) 1.8s;
}
@media (max-width: 767px) {
  .advent-reveal.revealed .reveal-content-slides {
    transition: margin 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
.advent-reveal h1 {
  margin-bottom: 20px;
  margin-top: 5px;
  text-transform: none;
}
@media (max-width: 767px) {
  .advent-reveal h1 {
    font-size: 24px;
    letter-spacing: 1.5px;
    line-height: 32px;
  }
}
.advent-reveal ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.advent-reveal ol li {
  counter-increment: reveal-counter;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  position: relative;
  text-align: left;
}
@media (max-width: 767px) {
  .advent-reveal ol li {
    padding-left: 30px;
  }
}
@media (min-width: 768px) {
  .advent-reveal ol li {
    padding-left: 40px;
  }
}
.advent-reveal ol li::before {
  content: counter(reveal-counter) ". ";
  left: 0;
  position: absolute;
}
.advent-reveal ol + span {
  color: #886655;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 14px;
  margin-top: 24px;
}
.advent-reveal .reveal-intro {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.advent-reveal .reveal-intro::after {
  background-color: #f6f4f2;
  content: "";
  position: absolute;
  width: 100%;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-intro::after {
    height: 0;
    left: 0;
    top: 100%;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-intro::after {
    height: 100%;
    left: 100%;
    width: 100%;
  }
}
.advent-reveal .reveal-intro p,
.advent-reveal .reveal-intro .btn {
  transition: 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.advent-reveal .reveal-intro p {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin: 0;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-intro p {
    font-size: 20px;
    line-height: 24px;
    padding-top: 60px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-intro p {
    font-size: 24px;
    line-height: 32px;
    padding-top: 70px;
  }
}
.advent-reveal .reveal-intro p::before {
  background-image: url("../images/icons/system-lock.svg");
  background-size: 40px 40px;
  content: "";
  height: 40px;
  position: absolute;
  left: calc(50% - 20px);
  width: 40px;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-intro p::before {
    top: 190px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-intro p::before {
    top: 150px;
  }
}
.advent-reveal .reveal-intro p::after {
  animation: linear rotation 15s infinite;
  backface-visibility: hidden;
  content: "";
  perspective: 1000;
  position: absolute;
  transform: translateZ(0);
  z-index: -1;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-intro p::after {
    background-image: url("../images/oval-gradient-327x327.png");
    background-size: 327px 327px;
    height: 327px;
    left: calc(50% - 164px);
    top: calc(50% - 164px);
    width: 327px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-intro p::after {
    background-image: url("../images/oval-gradient-400x400.png");
    background-size: 400px 400px;
    height: 400px;
    left: calc(50% - 200px);
    top: calc(50% - 200px);
    width: 400px;
  }
}
.advent-reveal .reveal-intro .btn {
  margin-top: 24px;
}
.advent-reveal .reveal-intro .snow {
  pointer-events: none;
}
.advent-reveal .reveal-intro .reveal-intro-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-intro .reveal-intro-image {
    background-position: top center;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-intro .reveal-intro-image {
    background-position: center center;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-intro .reveal-intro-image.mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .advent-reveal .reveal-intro .reveal-intro-image.tablet {
    display: none;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content {
    display: flex;
    height: 100%;
  }
}
.advent-reveal .reveal-content .btn {
  bottom: 40px;
  position: absolute;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content .btn {
    background-color: transparent;
    border: 1px solid #b4b4b4;
    color: #1a1919;
    left: 50vw;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content .btn {
    right: calc(50% + 40px);
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content-image,
  .advent-reveal .reveal-content-slides {
    width: 50%;
  }
}
.advent-reveal .reveal-content-image {
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-image {
    background-position: top center;
    height: 375px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content-image.mobile {
    background-position: center center;
    display: none;
  }
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-image.tablet {
    display: none;
  }
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-slides {
    margin: 0 24px 110px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content-slides {
    padding: 126px 120px 64px;
  }
}
.advent-reveal .reveal-content-slides > span:first-child {
  color: #807463;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-slides > span:first-child {
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content-slides > span:first-child {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-slides .reveal-content-slide {
    background-color: #ffffff;
    padding: 24px;
    text-align: center;
    width: calc(100vw + 12px);
  }
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-slides .reveal-content-slide + .reveal-content-slide {
    margin-left: 24px;
  }
}
@media (max-width: 767px) {
  .advent-reveal .reveal-content-slides .drag-list-body {
    padding: 0 0 40px;
  }
}
@media (min-width: 768px) {
  .advent-reveal .reveal-content-slides .drag-list-body {
    padding: 0 0 62px;
  }
}
.advent-reveal .reveal-content-slides .drag-list-controls button:first-child {
  left: calc(50% + 40px);
}
.advent-reveal .reveal-content-slides .drag-list-controls button:last-child:not(:only-child) {
  right: 40px;
}

.product-type-adventcalendar {
  background-color: #eeede9;
}
.product-type-adventcalendar .product-details-container .inner-wrapper .product-details-column.column-details {
  padding-top: 20px;
}
.product-type-adventcalendar .product-details-container .inner-wrapper .product-details-column.column-images {
  padding-top: 80px;
}
.product-type-adventcalendar .product-details-content .product-price {
  margin: 20px 0;
}
.product-type-adventcalendar .product-info-container .product-info-column-details {
  justify-content: center;
  order: 2;
  padding: 60px 40px;
}
.product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content h3 {
  font-size: 40px;
  letter-spacing: 1.8px;
  line-height: 48px;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content h3 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}
.product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content div {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content .product-info-icons {
  justify-content: center;
  margin: 32px -50px 0;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content .product-info-icons {
    margin: 40px -20px 0;
  }
}
.product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content .product-info-icons .item {
  width: 35%;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content .product-info-icons .item {
    align-items: baseline;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content .product-info-icons .item .product-info-icon {
  height: 48px;
  width: 48px;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-info-container .product-info-column-details .product-info-content .product-info-icons .item .product-info-icon {
    margin: 0 12px;
  }
}
.product-type-adventcalendar .product-info-container .product-info-column-image {
  order: 1;
}
.product-type-adventcalendar .product-hero-container .product-hero-media {
  left: auto;
  right: 0;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-hero-container .product-hero-media {
    order: 1;
  }
}
.product-type-adventcalendar .product-hero-container .product-hero-content {
  justify-content: center;
  margin-left: 0;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-hero-container .product-hero-content {
    order: 2;
  }
}
.product-type-adventcalendar .product-hero-container .product-hero-content .product-hero-box {
  padding: 60px 5px;
}
.product-type-adventcalendar .product-hero-container .product-hero-content .product-hero-box h3 {
  color: #1a1919;
  font-size: 40px;
  letter-spacing: 1.8px;
  line-height: 48px;
}
@media (max-width: 767px) {
  .product-type-adventcalendar .product-hero-container .product-hero-content .product-hero-box h3 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}
.product-type-adventcalendar .product-hero-container .product-hero-content .product-hero-box p {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.4px;
}

.advent-reveal-with-video {
  overflow: hidden;
  position: relative;
  background-color: #02192b;
}
.advent-reveal-with-video.reveal .text-holder {
  transform: translateX(24px);
}
.advent-reveal-with-video.reveal .reveal-intro {
  opacity: 0;
  pointer-events: none;
}
.advent-reveal-with-video.reveal .reveal-content {
  opacity: 1;
}
.advent-reveal-with-video.reveal .product-slider-block {
  transform: translateX(0);
}
.advent-reveal-with-video.revealed .text-holder {
  transition: transform 0.5s cubic-bezier(0.32, 0.24, 0.15, 1) 0.5s;
}
.advent-reveal-with-video.revealed .reveal-intro {
  transition: opacity 0.5s cubic-bezier(0.32, 0.24, 0.15, 1) 0.5s;
}
.advent-reveal-with-video.revealed .reveal-content {
  transition: opacity 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.advent-reveal-with-video.revealed .product-slider-block {
  transition: transform 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.advent-reveal-with-video .video-holder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.advent-reveal-with-video .reveal-intro {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 5;
  transition: opacity 0.5s cubic-bezier(0.32, 0.24, 0.15, 1) 0.5s;
}
@media (max-width: 767px) {
  .advent-reveal-with-video .reveal-intro {
    justify-content: flex-start;
    padding: 90px 0 40px;
  }
}
.advent-reveal-with-video .text-holder {
  padding: 0 20px;
  text-align: left;
  color: #ffffff;
  transition: transform 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (max-width: 767px) {
  .advent-reveal-with-video .text-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .advent-reveal-with-video .text-holder {
    padding: 0 100px;
  }
}
.advent-reveal-with-video h1 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 2px;
  margin-bottom: 31px;
  text-transform: none;
}
@media (max-width: 767px) {
  .advent-reveal-with-video h1 {
    display: none;
  }
}
.advent-reveal-with-video h2 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 1.8px;
  text-transform: none;
}
@media (max-width: 767px) {
  .advent-reveal-with-video h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: center;
  }
}
.advent-reveal-with-video ol {
  max-width: 300px;
  padding: 0;
  margin: 0 auto;
  text-align: left;
  color: #ffffff;
  list-style: none;
}
@media (max-width: 767px) {
  .advent-reveal-with-video ol {
    padding-left: 20px;
  }
}
.advent-reveal-with-video ol li {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  padding-left: 25px;
  position: relative;
}
.advent-reveal-with-video ol li .order-number {
  left: 0;
  position: absolute;
}
.advent-reveal-with-video .reveal-content-btn {
  background-color: rgba(255, 255, 255, 0.1);
  min-height: 60px;
  margin-top: 35px;
  letter-spacing: 4.2px;
  padding: 14px 31px 14px 20px;
}
@media (max-width: 767px) {
  .advent-reveal-with-video .reveal-content-btn {
    margin: 0 auto;
  }
}
.advent-reveal-with-video .reveal-content-btn .ico {
  font-size: 23px;
  margin-right: 9px;
}
.advent-reveal-with-video .reveal-content {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.32, 0.24, 0.15, 1) 0.5s;
}
@media (min-width: 768px) {
  .advent-reveal-with-video .reveal-content {
    display: flex;
    height: 100%;
    transition-delay: 0.7s;
  }
}
.advent-reveal-with-video .reveal-content .hide-content-btn {
  position: static;
  margin: 31px auto 0;
  display: block;
  width: auto;
  min-height: 48px;
  min-width: 1px;
  color: #ffffff;
}
.advent-reveal-with-video .product-slider-block {
  height: 100%;
  transform: translateX(-30px);
  transition: transform 0.5s cubic-bezier(0.32, 0.24, 0.15, 1) 0.5s;
}
@media (min-width: 768px) {
  .advent-reveal-with-video .product-slider-block {
    transition-delay: 0.7s;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-slider-steps {
  height: 100%;
}
@media (max-width: 767px) {
  .advent-reveal-with-video .product-explanation-slider .product-slider-step {
    padding: 0 25px;
  }
}
@media (min-width: 768px) {
  .advent-reveal-with-video .product-explanation-slider .product-slider-holder {
    max-width: 720px;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-slider-holder .slider-bar {
  transform: translate(0);
}
@media (max-width: 767px) {
  .advent-reveal-with-video .product-explanation-slider .product-slider-holder .slider-bar {
    margin: 20px auto 0;
    width: calc(100% - 60px);
  }
}
@media (min-width: 768px) {
  .advent-reveal-with-video .product-explanation-slider .product-slider-holder .slider-bar {
    margin: 19px auto 0;
  }
}
@media (max-width: 767px) {
  .advent-reveal-with-video .product-explanation-slider .product-main-slider {
    padding: 0;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .slider-inner-wrap {
  background-color: transparent;
}
@media (max-width: 767px) {
  .advent-reveal-with-video .product-explanation-slider .product-main-slider .slider-inner-wrap .image-holder {
    height: 276px;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .step-intro {
  align-items: center;
}
@media (max-width: 767px) {
  .advent-reveal-with-video .product-explanation-slider .product-main-slider .step-intro {
    padding: 10px 0 0;
  }
}
@media (min-width: 768px) {
  .advent-reveal-with-video .product-explanation-slider .product-main-slider .step-intro {
    padding-top: 88px;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow {
  transform: translate(0);
  top: 250px;
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow.slick-next {
  right: 10px;
  left: auto;
}
@media (min-width: 1025px) {
  .advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow.slick-next {
    right: 97px;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow.slick-prev {
  left: 10px;
}
@media (min-width: 1025px) {
  .advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow.slick-prev {
    left: 97px;
  }
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow .slider-button {
  background-color: transparent;
  color: #ffffff;
}
.advent-reveal-with-video .product-explanation-slider .product-main-slider .slick-arrow .slider-button:hover {
  background-color: #ffffff;
  color: #000000;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
.snow:not(.snow-fixed),
.snow:not(.snow-fixed)::before,
.snow:not(.snow-fixed)::after {
  position: absolute;
}

.snow-fixed,
.snow-fixed::before,
.snow-fixed::after {
  position: fixed;
}

.snow,
.snow::before,
.snow::after {
  top: -900px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: radial-gradient(3px 3px at 544px 61px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(3px 3px at 590px 96px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(6px 6px at 199px 542px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(3px 3px at 203px 136px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(6px 6px at 86px 534px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(4px 4px at 469px 232px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(3px 3px at 69px 690px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(5px 5px at 354px 476px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(3px 3px at 759px 114px, white 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(6px 6px at 644px 333px, white 50%, rgba(0, 0, 0, 0) 50%);
  background-size: 900px 900px;
  animation: snowing 15s linear infinite;
  content: "";
}

.snow::after {
  animation-duration: 30s;
  animation-direction: reverse;
  margin-left: calc(-900 / 3)px;
}

.snow::before {
  animation-duration: 45s;
  animation-direction: reverse;
  margin-left: calc(-w / 2)px;
}

.snow-desktop {
  display: none;
}
@media (min-width: 1025px) {
  .snow-desktop {
    display: block;
  }
}

@keyframes snowing {
  to {
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 900px, 0);
  }
}
.vue-form + .vue-form {
  margin-top: 40px;
}
.vue-form label {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.vue-form label:not(.vue-form-group-label) {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.vue-form label .vue-form-group-lebel {
  cursor: pointer;
}
.vue-form label .field-label {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.vue-form label .field-label.has-focus {
  color: #626262;
}
.vue-form label .field-label.disabled {
  color: #b4b4b4;
}
.vue-form label .field-info {
  color: #626262;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
.vue-form label .field-help {
  margin-left: 8px;
  position: relative;
}
.vue-form label .field-help:hover span {
  opacity: 1;
  transition-delay: 0s;
}
.vue-form label .field-help::before {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
  content: "i";
  font-size: 11px;
  height: 12px;
  left: 0;
  line-height: 15px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 12px;
}
.vue-form label .field-help span {
  background-color: #1a1919;
  bottom: 34px;
  color: #ffffff;
  left: -95px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: opacity 0.6s cubic-bezier(0.05, 0.5, 0.3, 1) 1.5s;
  width: 210px;
  z-index: 11;
}
.vue-form label .field-help span::before {
  background-color: #1a1919;
  bottom: -17px;
  content: "";
  height: 20px;
  left: 98px;
  position: absolute;
  transform-origin: center;
  transform: rotate(45deg) translateX(-50%);
  width: 20px;
  z-index: 10;
}
.vue-form label ~ .vue-input, .vue-form label ~ .vue-input-select {
  margin-top: 8px;
}
.vue-form .vue-input {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.vue-form .vue-input[type=text], .vue-form .vue-input[type=tel], .vue-form .vue-input[type=email] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  background-color: #ffffff;
  border: 1px solid #b4b4b4;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding: 11px 40px 11px 16px;
  width: 100%;
}
.vue-form .vue-input[disabled], .vue-form .vue-input[readonly] {
  background-color: #efedea;
}
.vue-form .vue-input[disabled]::before, .vue-form .vue-input[disabled]::after, .vue-form .vue-input[readonly]::before, .vue-form .vue-input[readonly]::after {
  display: none;
}
.vue-form .vue-input-select {
  position: relative;
}
.vue-form .vue-input-select button {
  align-items: flex-end;
  background-color: #ffffff;
  border: 1px solid #b4b4b4;
  display: flex;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding: 12px 40px 12px 16px;
  width: 100%;
}
.vue-form .vue-input-select button span.icon {
  margin-right: 5px;
}
.vue-form .vue-input-select button::before, .vue-form .vue-input-select button::after {
  content: "";
  display: block;
  height: 6px;
  position: absolute;
  right: 18px;
  transform: rotateZ(45deg);
  transition: top 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 6px;
}
.vue-form .vue-input-select button::before {
  border-left: 1px solid #453f3f;
  border-top: 1px solid #453f3f;
  top: 19px;
}
.vue-form .vue-input-select button::after {
  border-bottom: 1px solid #453f3f;
  border-right: 1px solid #453f3f;
  top: 25px;
}
.vue-form .vue-input-select button.open::before {
  top: 26px;
}
.vue-form .vue-input-select button.open::after {
  top: 17px;
}
.vue-form .vue-input-select ul {
  background-color: #ffffff;
  border-bottom: 1px solid #b4b4b4;
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  position: absolute;
  top: 49px;
  width: 100%;
  z-index: 1;
}
.vue-form .vue-input-select ul li {
  align-items: center;
  background-color: #ffffff;
  border-top: 1px solid #b4b4b4;
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
}
.vue-form .vue-input-select ul li .icon {
  margin-right: 18px;
}
.vue-form .vue-input-toggle {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.vue-form .vue-input-toggle:checked + label span.toggle {
  background-color: #9a7b69;
}
.vue-form .vue-input-toggle:checked + label span.toggle::after {
  transform: translate3d(26px, 4px, 0);
}
.vue-form .vue-input-toggle + label {
  display: block;
  font-size: 14px;
  height: 32px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 3px 0 0 80px;
  position: relative;
}
.vue-form .vue-input-toggle + label span.toggle {
  background-color: #b4b4b4;
  border-radius: 28px;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: -3px;
  transition: background-color 0.3s linear;
  vertical-align: text-bottom;
  width: 56px;
}
.vue-form .vue-input-toggle + label span.toggle::after {
  background-color: #ffffff;
  border-radius: 12px;
  content: "";
  height: 24px;
  left: 0;
  position: absolute;
  transform: translate3d(5px, 4px, 0);
  transition: transform 0.2s ease-in-out;
  width: 24px;
}
.vue-form .vue-input-radio,
.vue-form .vue-input-checkbox {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.vue-form .vue-input-radio:checked + label::before,
.vue-form .vue-input-checkbox:checked + label::before {
  background-color: #9a7b69;
  border-color: #9a7b69;
  content: "\ea2f";
}
.vue-form .vue-input-radio + label,
.vue-form .vue-input-checkbox + label {
  display: block;
  font-size: 14px;
  height: 32px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 4px 0 0 36px;
  position: relative;
}
.vue-form .vue-input-radio + label::before,
.vue-form .vue-input-checkbox + label::before {
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  color: #ffffff;
  content: "";
  font-family: "iconfont";
  font-size: 8px;
  height: 24px;
  left: 0;
  letter-spacing: -1px;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 24px;
}
.vue-form .vue-input-error {
  color: #ad4141;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin-top: 8px;
  text-align: right;
}
.vue-form .vue-input-warning {
  color: #b15930;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin-top: 8px;
  text-align: right;
}
.vue-form .reveal-hidden {
  color: #886655;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 10px 0 0;
  padding-top: 5px;
  position: relative;
  text-align: left;
}
.vue-form .vue-button.reveal-hidden {
  padding-left: 20px;
}
.vue-form .vue-button.reveal-hidden::before {
  background-size: 100% 100%;
  background: transparent url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiM5QTdCNjk7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik04LDEuM2MzLjcsMCw2LjcsMyw2LjcsNi43cy0zLDYuNy02LjcsNi43cy02LjctMy02LjctNi43UzQuMywxLjMsOCwxLjN6IE04LDIuMUM0LjgsMi4xLDIuMSw0LjgsMi4xLDgNCglzMi42LDUuOSw1LjksNS45czUuOS0yLjYsNS45LTUuOVMxMS4yLDIuMSw4LDIuMXogTTguNCw1LjRsMCwyLjJsMi4yLDB2MC43bC0yLjIsMGwwLDIuMkg3LjZsMC0yLjJsLTIuMiwwVjcuNmwyLjIsMGwwLTIuMkg4LjR6Ii8+DQo8L3N2Zz4NCg==") no-repeat;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 16px;
}

.vue-form-row + .vue-form-row {
  margin-top: 16px;
}
.vue-form-row.has-multiple-elements {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .vue-form-row.has-multiple-elements {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .vue-form-row.has-multiple-elements .vue-form-element.no-label {
    padding-top: 32px;
  }
}
.vue-form-row .vue-form-element:nth-last-child(n+2),
.vue-form-row .vue-form-element:nth-last-child(n+2) ~ .vue-form-element {
  width: calc(50% - 20px);
}
@media (max-width: 1023px) {
  .vue-form-row .vue-form-element:nth-last-child(n+2),
  .vue-form-row .vue-form-element:nth-last-child(n+2) ~ .vue-form-element {
    width: 100%;
  }
  .vue-form-row .vue-form-element:nth-last-child(n+2):not(:first-child),
  .vue-form-row .vue-form-element:nth-last-child(n+2) ~ .vue-form-element:not(:first-child) {
    margin-top: 16px;
  }
}
.vue-form-row .vue-form-element:nth-last-child(n+3),
.vue-form-row .vue-form-element:nth-last-child(n+3) ~ .vue-form-element {
  width: calc(33% - 20px);
}
@media (max-width: 1023px) {
  .vue-form-row .vue-form-element:nth-last-child(n+3),
  .vue-form-row .vue-form-element:nth-last-child(n+3) ~ .vue-form-element {
    width: 100%;
  }
  .vue-form-row .vue-form-element:nth-last-child(n+3):not(:first-child),
  .vue-form-row .vue-form-element:nth-last-child(n+3) ~ .vue-form-element:not(:first-child) {
    margin-top: 16px;
  }
}

.vue-form-element {
  position: relative;
}
.vue-form-element.has-error .vue-input {
  border-color: #ad4141;
}
.vue-form-element.has-warning .vue-input {
  border-color: #b15930;
}
@media (min-width: 768px) {
  .vue-form-row:not(.has-multiple-elements) .vue-form-element.half-width {
    max-width: 50%;
    width: calc(50% - 20px);
  }
}
.vue-form-element.hide-field {
  display: none;
}
.vue-form-element.at-end, .vue-form-element.icon-read-only {
  margin-left: auto;
}
.vue-form-element .icon {
  line-height: 24px;
}
.vue-form-element .icon-before,
.vue-form-element .icon-after,
.vue-form-element .icon-read-only {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.vue-form-element .icon-before::before,
.vue-form-element .icon-after::before,
.vue-form-element .icon-read-only::before {
  position: absolute;
}
.vue-form-element:not(.no-label) .icon-before,
.vue-form-element:not(.no-label) .icon-after,
.vue-form-element:not(.no-label) .icon-read-only {
  transform: translateY(20px);
}
.vue-form-element.no-label .icon-before,
.vue-form-element.no-label .icon-after,
.vue-form-element.no-label .icon-read-only {
  transform: translateY(12px);
}
.vue-form-element .icon-before::before {
  left: 16px;
}
.vue-form-element .icon-before + .vue-input, .vue-form-element .icon-before + .vue-input-select button {
  padding-left: 48px;
}
.vue-form-element .icon-after::before,
.vue-form-element .icon-read-only::before {
  right: 16px;
}
.vue-form-element .icon-after + .vue-input,
.vue-form-element .icon-read-only + .vue-input {
  padding-right: 48px;
}
.vue-form-element .icon-read-only::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #453f3f;
  content: "\ead6";
  display: inline-block;
}
.vue-form-element .icon-read-only + .vue-input {
  padding-right: 48px;
}

.inline-alert {
  border: 1px solid #b4b4b4;
  color: #000000;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 16px;
  width: 100%;
}
.inline-alert + .inline-alert {
  margin-top: 16px;
}
.inline-alert a {
  font-size: 14px;
}
.inline-alert span {
  display: block;
  padding-left: 32px;
  position: relative;
}
.inline-alert span::before {
  border-radius: 50%;
  height: 16px;
  left: 0;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}
.inline-alert.notify span::before {
  background-color: #000000;
  color: #ffffff;
  content: "i";
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -1px;
}
.inline-alert.notify-on-gray {
  background-color: #d8d8d8;
  border: 0;
  color: #453f3f;
  display: inline-block;
  font-size: 14px;
  width: auto;
}
.inline-alert.notify-on-gray + p {
  margin-top: 24px;
}
.inline-alert.notify-on-gray a {
  color: #1a1919;
}
.inline-alert.notify-on-gray span::before {
  color: #453f3f;
  content: "\ea31";
  font-family: "iconfont";
  font-size: 24px;
}
.inline-alert.error {
  border-color: #d6122d;
  color: #d6122d;
}
.inline-alert.error span::before {
  background-color: #d6122d;
  color: #ffffff;
  content: "!";
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -1px;
}
.inline-alert.confirmation {
  border-color: #649b26;
}
.inline-alert.confirmation span::before {
  background-color: #649b26;
  color: #ffffff;
  content: "i";
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -1px;
}

.tax-warning-block {
  margin-top: 16px;
}
.tax-warning-block .inline-alert-message {
  display: inline-block;
  width: 100%;
}
.tax-warning-block .inline-alert-message span {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 25px;
  color: #626262 !important;
}

.inline-notification {
  border-radius: 4px;
  margin: 16px 0;
}
.inline-notification p {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 8px 24px;
}
.inline-notification.white {
  background-color: #ffffff;
}
.inline-notification.white p {
  color: #1a1919;
}

.skeleton-loader {
  border: 1px solid #d8d8d8;
  height: 100%;
  padding: 24px;
  width: 100%;
}

.skeleton-mask {
  animation: skeletonAnimation 1s infinite linear;
  background-image: linear-gradient(to right, rgba(180, 180, 180, 0.5) 0%, rgba(180, 180, 180, 0.15) 20%, rgba(180, 180, 180, 0.15) 25%, rgba(180, 180, 180, 0.1) 30%, rgba(180, 180, 180, 0.05) 40%, rgba(180, 180, 180, 0) 45%, rgba(180, 180, 180, 0) 50%, rgba(180, 180, 180, 0) 55%, rgba(180, 180, 180, 0.05) 60%, rgba(180, 180, 180, 0.1) 70%, rgba(180, 180, 180, 0.15) 75%, rgba(180, 180, 180, 0.15) 80%, rgba(180, 180, 180, 0.5) 100%);
  background-size: 200% 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.skeleton-mask:not(.square) {
  min-height: 104px;
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzI5cHgiIGhlaWdodD0iMTI4cHgiIHZpZXdCb3g9IjAgMCA3MjkgMTI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPl9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfbGFyZ2VfaW52ZXJ0ZWQ8L3RpdGxlPgogICAgPGcgaWQ9IvCfmqstZXJyb3JzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iX3JpdHVhbHNfZ3JhZGllbnRfd2luZG93X2xvYWRlcl9sYXJnZV9pbnZlcnRlZCI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLUNvcHktOSIgcG9pbnRzPSI3MjkgMTA0IDAgMTA0IDAgMTI4IDcyOSAxMjgiPjwvcG9seWdvbj4KICAgICAgICAgICAgPHBhdGggZD0iTTY0OSw4MCBMNjQ5LDEwNCBMMCwxMDQgTDAsODAgTDY0OSw4MCBaIE03MjksODAgTDcyOSwxMDQgTDY2NiwxMDQgTDY2Niw4MCBMNzI5LDgwIFogTTY0OSw0MCBMNjQ5LDY0IEwwLDY0IEwwLDQwIEw2NDksNDAgWiBNNzI5LDQwIEw3MjksNjQgTDY2Niw2NCBMNjY2LDQwIEw3MjksNDAgWiBNMjMsMCBMMjMsMjQgTDAsMjQgTDAsMCBMMjMsMCBaIE03MjksMCBMNzI5LDI0IEw3MDYsMjQgTDcwNiwwIEw3MjksMCBaIE02ODksMCBMNjg5LDI0IEw0MCwyNCBMNDAsMCBMNjg5LDAgWiIgaWQ9Il9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfbGFyZ2VfaW52ZXJ0ZWQtY29weSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  -webkit-mask-repeat: repeat-y;
}
.skeleton-mask.square {
  min-height: 128px;
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTUycHgiIHZpZXdCb3g9IjAgMCAyMDAgMTUyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPl9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfc21hbGxfaW52ZXJ0ZWQ8L3RpdGxlPgogICAgPGcgaWQ9IvCfmqstZXJyb3JzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iX3JpdHVhbHNfZ3JhZGllbnRfd2luZG93X2xvYWRlcl9zbWFsbF9pbnZlcnRlZCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNjgsMTA0IEwxNjgsMTI4IEwwLDEyOCBMMCwxMDQgTDE2OCwxMDQgWiBNMTY4LDcyIEwxNjgsOTYgTDAsOTYgTDAsNzIgTDE2OCw3MiBaIE0xNjgsNDAgTDE2OCw2NCBMMCw2NCBMMCw0MCBMMTY4LDQwIFogTTE2OCwwIEwxNjgsMjQgTDAsMjQgTDAsMCBMMTY4LDAgWiBNMjAwLDAgTDIwMCwyNCBMMTc2LDI0IEwxNzYsMCBMMjAwLDAgWiIgaWQ9Il9yaXR1YWxzX2dyYWRpZW50X3dpbmRvd19sb2FkZXJfc21hbGxfaW52ZXJ0ZWQtY29weSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC1Db3B5LTciIHBvaW50cz0iMjAwIDEyOCAwIDEyOCAwIDE1MiAyMDAgMTUyIj48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
  -webkit-mask-repeat: repeat-y;
}

@keyframes skeletonAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.s7videoplayer {
  background-color: transparent !important;
}
.s7videoplayer video {
  box-sizing: border-box;
  height: 58vw !important;
  left: 50% !important;
  min-height: calc(100% + 5px);
  min-width: calc(100% + 5px);
  position: absolute;
  top: 50% !important;
  transform: translate(-50%, -50%);
  width: 177.8vh !important;
}
@media (max-width: 767px) {
  .s7videoplayer video {
    height: 100% !important;
    width: auto !important;
  }
}

.s7socialshare,
.s7controlbar {
  display: none !important;
}

.s7controlbar,
.s7playpausebutton[selected][state],
.s7playpausebutton[selected][state][replay],
.s7fullscreenbutton[state][selected],
.s7closedcaptionbutton[state][selected],
.s7videoscrubber .s7videotime,
.s7videoscrubber .s7navigation[state],
.s7videoscrubber .s7knob,
.s7mutablevolume .s7mutebutton[state][selected],
.s7mutablevolume .s7verticalvolume .s7knob,
.s7videoplayer .s7iconeffect[state] {
  background-image: none !important;
}

.product-choice-list {
  height: 100%;
  padding: 60px 0 90px 0;
  position: relative;
}
.product-choice-list .product-choice-scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 767px) {
  .product-choice-list .product-choice-header {
    padding: 4px 24px 24px 24px;
  }
}
@media (min-width: 768px) {
  .product-choice-list .product-choice-header {
    padding: 40px 24px 24px 24px;
  }
}
.product-choice-list .product-choice-header h2 {
  margin-bottom: 8px;
  text-transform: none;
}
@media (max-width: 767px) {
  .product-choice-list .product-choice-header h2 {
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  .product-choice-list .product-choice-header h2 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}
.product-choice-list .product-choice-header span {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.product-choice-list .product-choice-options {
  border-top: 1px solid #d8d8d8;
}
.product-choice-list .product-choice-options .input-type-radio {
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}
@media (max-width: 767px) {
  .product-choice-list .product-choice-options .input-type-radio {
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .product-choice-list .product-choice-options .input-type-radio {
    padding: 24px 40px;
  }
}
.product-choice-list .product-choice-options .input-type-radio.chosen {
  background-color: #efedea;
}
.product-choice-list .product-choice-options .input-type-radio label::before {
  top: calc(50% - 12px);
}
.product-choice-list .product-choice-options .input-type-radio img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .product-choice-list .product-choice-options .input-type-radio img {
    width: 56px;
  }
}
@media (min-width: 768px) {
  .product-choice-list .product-choice-options .input-type-radio img {
    width: 64px;
  }
}
.product-choice-list .product-choice-options .option-ritual,
.product-choice-list .product-choice-options .option-title {
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 767px) {
  .product-choice-list .product-choice-options .option-ritual,
  .product-choice-list .product-choice-options .option-title {
    max-width: calc(100% - 80px);
  }
}
@media (min-width: 768px) {
  .product-choice-list .product-choice-options .option-ritual,
  .product-choice-list .product-choice-options .option-title {
    max-width: calc(100% - 88px);
  }
}
.product-choice-list .product-choice-options .option-ritual {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .product-choice-list .product-choice-options .option-title {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  .product-choice-list .product-choice-options .option-title {
    font-size: 20px;
    letter-spacing: 0.7px;
    line-height: 32px;
  }
}
.product-choice-list .product-choice-footer {
  background-color: #ffffff;
  bottom: 0;
  padding: 24px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.product-choice-list .product-choice-footer::before {
  background-color: #ffffff;
  box-shadow: 0 -8px 5px -2px #ffffff;
  content: "";
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.product-choice-list .product-choice-footer .btn {
  max-width: none;
  width: 100%;
}

.world {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: #ffffff;
}
.world .gradient-background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.world .gradient-background.sakura {
  background: linear-gradient(0deg, #f5c7c2, #f7d0ce 50%, #fae3de 80%);
  opacity: 0;
}
.world .gradient-background.mehr {
  background: linear-gradient(0deg, #fae2ba, #fae5c5 50%, #fefada 80%);
  opacity: 0;
}
.world .gradient-background.karma {
  background: linear-gradient(0deg, #d4e5df, #c6e8ed 50%, #e9fcfc 80%);
  opacity: 0;
}
.world #app {
  position: relative;
  transition: background-color 3s ease;
  z-index: 1;
}
.world canvas {
  position: relative;
}
.world .preloader {
  background-color: #211f1f;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 2s ease;
  width: 100%;
  z-index: 13;
}
@media (max-width: 767px) {
  .world .preloader {
    height: calc(100% + 80px);
  }
}
.world .new {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
}
.world .egg-num {
  height: 208px;
  margin: 0 auto 40px;
  position: relative;
  width: 167px;
}
.world .counter {
  left: 50%;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: -50px;
  transform: translateX(-50%);
  transition: opacity 1s ease;
  z-index: 21;
}
.world .counter span {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
}
.world .counter span::after {
  content: "%";
}
.world .egg-loader-mask {
  background-color: #211f1f;
  height: 220px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.5s ease-in-out;
  width: 200px;
  z-index: 20;
}
.world .egg-loader {
  background-color: #211f1f;
  border-radius: 50% 50% 30% 30%/70% 70% 28% 28%;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  position: relative;
  transform: scale(1);
  transition: transform 2s ease, opacity 2s ease;
  width: 100%;
  z-index: 12;
}
.world .egg-loader span {
  background-repeat: no-repeat;
  bottom: -30%;
  display: block;
  height: 80px;
  opacity: 1;
  position: absolute;
  transition: bottom 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 400px;
}
.world .egg-loader span::after {
  content: "";
  height: 210px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}
.world .egg-loader span:nth-child(1)::after {
  background-color: #81c2d9;
}
.world .egg-loader span:nth-child(2)::after {
  background-color: #f8a2a1;
}
.world .egg-loader span:nth-child(3)::after {
  background-color: #ffd252;
}
.world .text-content {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
@media (max-width: 1023px) {
  .world .text-content {
    pointer-events: none;
  }
}
.world .overlay-to-pdp {
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 12;
}
.world .overlay-to-pdp.sakura {
  background-color: #fae0dc;
}
.world .overlay-to-pdp.mehr {
  background-color: #f5e9d3;
}
.world .overlay-to-pdp.karma {
  background-color: #e1f7fa;
}
.world .text-box {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  opacity: 1;
  text-align: center;
  transition: opacity 0.5s ease;
}
.world .egg-header {
  font-size: 54px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 30px;
  opacity: 0;
  text-transform: initial;
}
@media (max-width: 767px) {
  .world .egg-header {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.world .egg-header span {
  display: inline-block;
  opacity: 0;
  transform: translateY(6px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.world .egg-header span.fade {
  opacity: 1;
  transform: translateY(0);
}
.world .egg-text {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  margin-bottom: 20px;
  opacity: 0;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .world .egg-text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
}
.world .egg-text span {
  display: inline-block;
  opacity: 0;
  transform: translateY(6px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.world .egg-text span.fade {
  opacity: 1;
  transform: translateY(0);
}
.world .intro-box {
  text-align: center;
}
.world .egg-intro {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 2.42px;
  margin-bottom: 20px;
  opacity: 0;
  text-transform: uppercase;
  transform: translateY(10px);
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .world .egg-intro {
    font-size: 14px;
  }
}
.world .egg-intro span {
  display: inline-block;
  opacity: 0;
  transform: translateY(6px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.world .egg-intro span.fade {
  opacity: 1;
  transform: translateY(0);
}
.world .egg-title {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 58px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 20px;
  opacity: 0;
  text-transform: initial;
  transform: translateY(10px);
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .world .egg-title {
    font-size: 30px;
  }
}
.world .egg-title span {
  display: inline-block;
  opacity: 0;
  transform: translateY(6px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.world .egg-title span.fade {
  opacity: 1;
  transform: translateY(0);
}
.world .swipe-container {
  height: 85%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
@media (max-width: 1023px) {
  .world .swipe-container {
    display: none;
  }
}
.world .container {
  bottom: 0;
  height: 200px;
  left: 50%;
  perspective: 500px;
  position: absolute;
  transform: translateX(-50%);
  min-width: 1000px;
}
@media (max-width: 767px) {
  .world .container {
    perspective: 300px;
    min-width: 300px;
    height: 170px;
  }
}
.world .egg-button {
  background-color: #ffffff;
  border: 0;
  color: #000000;
  cursor: pointer;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14.4px;
  letter-spacing: 2.4px;
  line-height: 19.2px;
  min-width: 262px;
  opacity: 0;
  padding: 18px 28px;
  text-transform: uppercase;
}
.world .open-button {
  position: absolute;
  left: 50%;
  top: 40%;
  background-color: transparent;
  cursor: pointer;
  height: 650px;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 11;
}
@media (max-width: 1023px) {
  .world .open-button {
    display: none;
  }
}
.world .move-btn {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 100%;
  outline: 0;
  position: absolute;
  top: 0;
  width: 15%;
  z-index: 11;
}
.world .btn-prev {
  left: 0;
}
@media (min-width: 1024px) {
  .world .btn-prev:hover .circle {
    transform: scale(1.2) translateY(-50%);
  }
}
.world .btn-next {
  right: 0;
}
@media (min-width: 1024px) {
  .world .btn-next:hover .circle {
    transform: scale(1.2) translateY(-50%);
  }
}
.world .circle {
  background-color: #ffffff;
  border-radius: 50%;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.5s ease;
  width: 50px;
}
.world .circle::after {
  content: "";
  border: solid #000000;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  top: 19px;
}
.world .circle-prev {
  left: 30px;
}
.world .circle-prev::after {
  right: -1px;
  transform: rotate(135deg);
}
@media (max-width: 767px) {
  .world .circle-prev {
    left: 20px;
  }
}
.world .circle-next {
  right: 30px;
}
.world .circle-next::after {
  right: 1px;
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .world .circle-next {
    right: 20px;
  }
}

model-viewer {
  height: 100%;
  width: 100%;
}

.bullet-list-block {
  background-color: #efedea;
  padding: 80px 0;
}
@media (max-width: 767px) {
  .bullet-list-block {
    padding: 60px 0;
  }
}
.bullet-list-block .bullet-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 767px) {
  .bullet-list-block .bullet-block {
    flex-direction: column;
  }
}
@media (max-width: 1023px) {
  .bullet-list-block .bullet-block {
    padding: 0 20px;
  }
}
.bullet-list-block .bullet-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  width: 32%;
}
@media (max-width: 767px) {
  .bullet-list-block .bullet-item {
    align-items: center;
    flex-direction: column;
    margin-bottom: 36px;
    width: 100%;
  }
}
.bullet-list-block .bullet-img {
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  height: 96px;
  justify-content: center;
  position: relative;
  width: 96px;
}
@media (max-width: 767px) {
  .bullet-list-block .bullet-img {
    margin: 0 0 13px 0;
  }
}
.bullet-list-block .bullet-title {
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 40px;
  margin: 16px 0;
  text-transform: initial;
}
@media (max-width: 767px) {
  .bullet-list-block .bullet-title {
    margin-bottom: 16px;
  }
}
.bullet-list-block .bullet-text {
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  width: 90%;
}
.bullet-list-block .bullet-textbox {
  max-width: 380px;
  text-align: center;
}
.bullet-list-block .bullet-textbox p, .bullet-list-block .bullet-textbox h3 {
  color: #000000;
}
@media (max-width: 767px) {
  .bullet-list-block .bullet-textbox {
    margin-right: 0;
  }
}
.bullet-list-block .icon {
  color: #886655;
  font-size: 40px;
  line-height: unset;
}
.bullet-list-block .bullet-list-header {
  color: #000000;
  font-size: 40px;
  letter-spacing: 1.8px;
  line-height: 48px;
  margin-bottom: 80px;
  text-align: center;
  text-transform: initial;
}
@media (max-width: 767px) {
  .bullet-list-block .bullet-list-header {
    font-size: 32px;
    letter-spacing: 1.4px;
    line-height: 40px;
    margin-bottom: 48px;
  }
}

.karma-usp-block {
  padding: 80px 0 30px;
}
.karma-usp-block .usp-header {
  font-size: 40px;
  letter-spacing: 1.8px;
  line-height: 48px;
  margin-bottom: 28px;
  text-align: center;
  text-transform: initial;
}
@media (max-width: 767px) {
  .karma-usp-block .usp-header {
    font-size: 32px;
    letter-spacing: 1.4px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}
.karma-usp-block .usp-subheader {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 0 auto 80px;
  max-width: 890px;
  text-align: center;
}
@media (max-width: 767px) {
  .karma-usp-block .usp-subheader {
    margin-bottom: 50px;
  }
}
.karma-usp-block .usp-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .karma-usp-block .usp-list {
    align-items: center;
    flex-direction: column;
  }
}
.karma-usp-block .usp-component {
  margin-bottom: 30px;
  padding: 0 20px;
  text-align: center;
  width: 33%;
}
@media (max-width: 767px) {
  .karma-usp-block .usp-component {
    width: 300px;
  }
}
.karma-usp-block .usp-component:nth-last-child(n+4), .karma-usp-block .usp-component:nth-last-child(n+4) ~ .usp-component {
  padding: 0 10px;
  width: 25%;
}
@media (max-width: 767px) {
  .karma-usp-block .usp-component:nth-last-child(n+4), .karma-usp-block .usp-component:nth-last-child(n+4) ~ .usp-component {
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .karma-usp-block .usp-component:nth-last-child(n+4), .karma-usp-block .usp-component:nth-last-child(n+4) ~ .usp-component {
    margin-bottom: 40px;
    padding: 0 20px;
    width: 50%;
  }
}
.karma-usp-block .usp-img-box {
  border-radius: 50%;
  height: 146px;
  margin: 0 auto 40px;
  overflow: hidden;
  width: 146px;
}
.karma-usp-block .usp-img {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.karma-usp-block .usp-text {
  font-size: 16px;
  letter-spacing: 0.5;
  line-height: 24px;
  margin-bottom: 16px;
}
.karma-usp-block .usp-link {
  color: #9a7b69;
  font-size: 14px;
}

.talisman-usp-block {
  background-color: #1a1919;
  padding: 60px 70px 80px;
}
@media (max-width: 767px) {
  .talisman-usp-block {
    padding: 60px 0;
  }
}
@media (min-width: 1024px) {
  .talisman-usp-block .usp-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
  }
}
.talisman-usp-block .usp-list-inner {
  display: block;
}
.talisman-usp-block .usp-component {
  margin: 0 auto;
  max-width: 360px;
  text-align: center;
}
.talisman-usp-block .usp-text {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 5px;
  line-height: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.talisman-usp-block .usp-header {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 32px;
  letter-spacing: 1.28px;
  line-height: 39px;
  margin-bottom: 20px;
  text-align: center;
}
@media (max-width: 767px) {
  .talisman-usp-block .usp-header {
    font-size: 24px;
    letter-spacing: 0.96px;
    line-height: 29px;
  }
}
.talisman-usp-block .usp-subheader {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.64px;
  line-height: 26px;
  margin: 0 auto;
  max-width: 330px;
  text-align: center;
}
.talisman-usp-block .slick-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  padding-left: 25px;
  width: 100%;
}
.talisman-usp-block .slick-dots li {
  color: #453f3f;
  height: 4px;
  margin: 0 4px;
  width: 4px;
}
.talisman-usp-block .slick-dots .slick-active {
  color: #ffffff;
}
.talisman-usp-block .slick-dots button {
  display: none;
}

.sustainability-counter {
  padding: 20px;
}
@media (min-width: 1024px) {
  .sustainability-counter {
    margin: 0 auto;
    max-width: 1440px;
  }
}
.sustainability-counter .sustainability-tile.hero {
  display: flex;
}
@media (max-width: 767px) {
  .sustainability-counter .sustainability-tile.hero {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .sustainability-counter .sustainability-tile.hero {
    height: 352px;
  }
}
.sustainability-counter .sustainability-tile.hero .cover-image {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.sustainability-counter .sustainability-tile.hero .cover-image.darken {
  filter: brightness(70%);
}
.sustainability-counter .sustainability-tile.hero h3 {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 32px;
  text-transform: none;
}
.sustainability-counter .sustainability-tile.hero p {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-top: 0;
  text-align: left;
}
.sustainability-counter .sustainability-tile.hero .icon.icon-arrow-back {
  margin-left: 20px;
  transform: rotate(180deg);
}
.sustainability-counter .sustainability-tile.hero .icon:not(.icon-arrow-back) {
  background-color: rgba(36, 34, 34, 0.3);
  -webkit-backdrop-filter: blur(10px) saturate(50%);
  backdrop-filter: blur(10px) saturate(50%);
  border-radius: 50%;
  color: #ffffff;
  font-size: 40px;
  height: 64px;
  line-height: 64px;
  margin-bottom: 5px;
  text-align: center;
  width: 64px;
}
.sustainability-counter .sustainability-tile.hero .blurred-overlay {
  background-color: rgba(36, 34, 34, 0.3);
  -webkit-backdrop-filter: blur(10px) saturate(50%);
  backdrop-filter: blur(10px) saturate(50%);
  bottom: 0;
  left: 0;
  padding: 26px 10px 20px 10px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.sustainability-counter .sustainability-tile.hero .blurred-overlay span {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.46px;
  line-height: 24px;
}
.sustainability-counter .sustainability-tile .animated-counter {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 0.2px;
  line-height: 32px;
  position: relative;
  text-align: center;
  width: 80%;
}
.sustainability-counter .sustainability-tile .animated-counter.large {
  color: #ffffff;
  font-size: 56px;
  letter-spacing: 1px;
  line-height: 64px;
}
.sustainability-counter .sustainability-tile p {
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-top: 16px;
  text-align: center;
  width: 90%;
}
.sustainability-counter .sustainability-tile .learn-more {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
}
.sustainability-counter .sustainability-tile .cover-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .sustainability-counter .sustainability-tile .sustainability-column _::-webkit-full-page-media, .sustainability-counter .sustainability-tile .sustainability-column _:future, .sustainability-counter .sustainability-tile .sustainability-column :root, .sustainability-counter .sustainability-tile .sustainability-column .safari-only {
    line-height: 60px;
    padding-left: 2px;
  }
}
.sustainability-counter .sustainability-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.sustainability-counter .sustainability-column:first-child:not(:only-child) {
  align-items: flex-start;
  background-color: #8c9476;
  padding: 0 70px;
}
@media (max-width: 767px) {
  .sustainability-counter .sustainability-column:first-child:not(:only-child) {
    padding: 38px 45px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .sustainability-counter .sustainability-column:first-child:not(:only-child) {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .sustainability-counter .sustainability-column:first-child:not(:only-child) {
    width: 492px;
  }
}
.sustainability-counter .sustainability-column:first-child:not(:only-child) p {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .sustainability-counter .sustainability-column:last-child:not(:only-child) {
    height: 372px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .sustainability-counter .sustainability-column:last-child:not(:only-child) {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .sustainability-counter .sustainability-column:last-child:not(:only-child) {
    width: calc(100% - 492px);
  }
}
.sustainability-counter .sustainability-column:last-child:not(:only-child) p {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 0 20px 74px;
  text-align: center;
}
.sustainability-counter .sustainability-list {
  display: flex;
  padding-bottom: 40px;
}
@media (max-width: 767px) {
  .sustainability-counter .sustainability-list {
    justify-content: center;
    margin: 0 -20px;
  }
}
@media (max-width: 1023px) {
  .sustainability-counter .sustainability-list {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .sustainability-counter .sustainability-list {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .sustainability-counter .sustainability-list .sustainability-tile {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .sustainability-counter .sustainability-list .sustainability-tile {
    margin-bottom: 20px;
    width: calc(50% - 10px);
  }
}
@media (min-width: 1024px) {
  .sustainability-counter .sustainability-list .sustainability-tile {
    width: calc(25% - 20px);
  }
}
.sustainability-counter .sustainability-list .sustainability-tile .sustainability-column .animated-counter {
  margin-top: 40px;
  padding-top: 16px;
}
.sustainability-counter .sustainability-list .sustainability-tile .sustainability-column .animated-counter::before {
  content: "";
  background: #9a7b69;
  display: inline-block;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 16px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .sustainability-counter .sustainability-list .sustainability-tile .sustainability-column _::-webkit-full-page-media, .sustainability-counter .sustainability-list .sustainability-tile .sustainability-column _:future, .sustainability-counter .sustainability-list .sustainability-tile .sustainability-column :root, .sustainability-counter .sustainability-list .sustainability-tile .sustainability-column .safari-only {
    line-height: 74px;
  }
}
.sustainability-counter .sustainability-achievement-title {
  color: #9a7b69;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .sustainability-counter .slider-bar,
  .sustainability-counter .slider-dots {
    display: none !important;
  }
}
.sustainability-counter .slider-bar {
  margin: -10px auto 20px auto;
}
.sustainability-counter .slick-slide {
  margin: 0 10px;
  width: 240px;
}
@media (max-width: 1023px) {
  .sustainability-counter .slick-slide .sustainability-column {
    margin: auto;
  }
}

.timeline-component {
  padding: 57px 0 38px;
}
.timeline-component .timeline-wrapper {
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}
.timeline-component .timeline-label {
  color: #896754;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
  margin-bottom: 11px;
  text-align: center;
  text-transform: uppercase;
}
.timeline-component .timeline-header {
  color: #1a1919;
  font-size: 40px;
  letter-spacing: 1.8px;
  line-height: 48px;
  margin-bottom: 62px;
  text-align: center;
  text-transform: initial;
}
.timeline-component .timeline-block {
  display: flex;
  min-height: 650px;
  justify-content: space-between;
  position: relative;
  margin-bottom: 50px;
}
.timeline-component .timeline-block::before {
  content: "";
  background-color: #1a1919;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
@media (max-width: 767px) {
  .timeline-component .timeline-block {
    min-height: 700px;
    margin-bottom: 20px;
  }
  .timeline-component .timeline-block::before {
    left: 20px;
    width: calc(100% - 40px);
  }
}
.timeline-component .timeline-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
  padding: 30px 24px 40px;
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .timeline-component .timeline-item {
    padding: 30px 10px 40px;
  }
}
@media (min-width: 768px) {
  .timeline-component .timeline-item {
    min-width: 300px;
  }
}
.timeline-component .timeline-item:nth-child(even) .timeline-imgbox {
  order: -1;
}
.timeline-component .timeline-item:last-child::after {
  background: radial-gradient(circle at center, #ffffff 30%, #000000 40%);
  height: 22px;
  width: 22px;
}
.timeline-component .timeline-item:last-child::before {
  content: "";
  background-color: #f6f4f2;
  height: 2px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  z-index: 2;
}
.timeline-component .timeline-item:first-child::before {
  content: "";
  background-color: #f6f4f2;
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  z-index: 2;
}
.timeline-component .timeline-item::after {
  content: "";
  background-color: #1a1919;
  border-radius: 50%;
  height: 11px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 11px;
  z-index: 3;
}
.timeline-component .timeline-imgbox {
  max-width: 260px;
  margin: 0 auto;
}
.timeline-component .timeline-imgbox img {
  width: 100%;
}
.timeline-component .timeline-img {
  width: 100%;
}
@media (max-width: 767px) {
  .timeline-component .timeline-img {
    width: 100%;
  }
}
.timeline-component .timeline-year {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 32px;
  margin-bottom: 5px;
}
.timeline-component .timeline-heading {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  margin-bottom: 7px;
}
.timeline-component .timeline-text {
  color: #767676;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  .timeline-component .timeline-text {
    margin-bottom: 18px;
  }
}
.timeline-component .timeline-link {
  color: #b8a188;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.timeline-component .slick-track {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}
.timeline-component .slider-button {
  background-color: #ffffff;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .timeline-component .slider-button {
    background-color: #ffffff;
  }
}
.timeline-component .slick-disabled {
  position: relative;
}
.timeline-component .slick-disabled::after {
  content: "";
  background-color: #f6f4f2;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.timeline-component .inner-wrapper {
  position: relative;
}
.timeline-component .inner-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  background-image: linear-gradient(to right, #f6f4f2, rgba(246, 244, 242, 0));
  z-index: 4;
}
.timeline-component .inner-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  background-image: linear-gradient(to left, #f6f4f2, rgba(246, 244, 242, 0));
  z-index: 4;
}
@media (max-width: 767px) {
  .timeline-component .inner-wrapper {
    padding: 0;
  }
}
.timeline-component .slick-prev,
.timeline-component .slick-next {
  top: 50%;
  position: absolute;
  height: 50px;
  width: 50px;
  transform: translateY(-50%);
  z-index: 5;
}
.timeline-component .slick-prev {
  left: 20px;
}
@media (min-width: 1440px) {
  .timeline-component .slick-prev {
    left: 0;
  }
}
.timeline-component .slick-next {
  right: 20px;
}
@media (min-width: 1440px) {
  .timeline-component .slick-next {
    right: 0;
  }
}
.timeline-component .timeline-progress {
  background-color: #d1d1d1;
  background-image: linear-gradient(to right, #000000, #000000);
  background-repeat: no-repeat;
  background-size: 0 100%;
  border-radius: 4px;
  height: 4px;
  margin: 0 20px 40px;
  overflow: hidden;
  transition: background-size 0.4s ease-in-out;
  max-width: 1440px;
}
@media (min-width: 1440px) {
  .timeline-component .timeline-progress {
    margin: 0 auto 40px;
  }
}

.tree-counter .tree-count-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0 60px;
  text-align: center;
}
@media (max-width: 767px) {
  .tree-counter .tree-count-box {
    padding: 20px 0 40px;
  }
}
.tree-counter .tree-count-num {
  color: #000000;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 50px;
  letter-spacing: 1.88px;
  line-height: 50px;
  margin-bottom: 15px;
}
.tree-counter .tree-count-text {
  color: #626262;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  max-width: 240px;
}
.tree-counter .icon {
  color: #b8a188;
  font-size: 50px;
  margin-bottom: 15px;
}

.herobanner-wrapper {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1023px) {
  .herobanner-wrapper {
    flex-direction: column;
  }
}
.herobanner-wrapper .back-button {
  filter: invert(1);
  left: 24px;
  mix-blend-mode: difference;
  position: absolute;
  text-decoration: none;
  top: 24px;
}
.herobanner-wrapper .carousel-images-wrapper {
  background-color: #ffffff;
  flex: 60%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.herobanner-wrapper .carousel-images-wrapper:hover > .slick-arrow {
  display: block !important;
}
.herobanner-wrapper .carousel-images-wrapper .slick-list {
  align-items: center;
  display: flex;
  height: 100%;
}
.herobanner-wrapper .carousel-images-wrapper .slick-track {
  display: flex;
  flex-direction: row;
}
.herobanner-wrapper .carousel-images-wrapper .carousel-image {
  cursor: pointer;
  display: block !important;
  height: 100vh;
  margin: 0 auto;
  width: auto !important;
}
@media (max-width: 767px) {
  .herobanner-wrapper .carousel-images-wrapper .carousel-image {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .herobanner-wrapper .carousel-images-wrapper .carousel-image {
    height: 600px;
  }
}
.herobanner-wrapper .carousel-images-wrapper .slick-arrow {
  border-radius: 50%;
  display: none !important;
  font-size: 30px;
  position: absolute;
  top: 50%;
  transition: 300ms;
  width: 35px;
  z-index: 1;
}
.herobanner-wrapper .carousel-images-wrapper .slick-arrow:hover {
  background-color: #cfcfcf;
}
.herobanner-wrapper .carousel-images-wrapper .slick-arrow:active {
  background-color: #999999;
}
.herobanner-wrapper .carousel-images-wrapper .slick-prev {
  left: 20px;
}
.herobanner-wrapper .carousel-images-wrapper .slick-next {
  right: 20px;
}
.herobanner-wrapper .carousel-images-wrapper .slick-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
  margin-top: -30px;
}
.herobanner-wrapper .carousel-images-wrapper .slick-dots li {
  color: #999999;
  margin: 0 4px;
  width: 4px;
}
.herobanner-wrapper .carousel-images-wrapper .slick-dots .slick-active {
  color: #1a1919;
}
.herobanner-wrapper .carousel-images-wrapper .slick-dots button {
  font-size: 0;
}
.herobanner-wrapper .carousel-zoom {
  background-color: #ffffff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.herobanner-wrapper .close-btn {
  color: #1a1919;
  display: none;
  font-size: 30px;
  padding: 6px;
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 3;
}
.herobanner-wrapper .close-btn:hover {
  background-color: #1a1919;
  color: #ffffff;
}
.herobanner-wrapper .show {
  display: block;
}
.herobanner-wrapper .product-details {
  display: flex;
  flex-direction: column;
  flex: 40%;
  justify-content: center;
  max-height: 100vh;
}
.herobanner-wrapper .product-details .packshot-image {
  margin: 0 auto;
  max-height: 584px;
}
@media (max-width: 1023px) {
  .herobanner-wrapper .product-details .packshot-image {
    display: none;
  }
}
@media (min-width: 1440px) {
  .herobanner-wrapper .product-details .packshot-image {
    height: 584px;
  }
}
.herobanner-wrapper .product-details .product-properties {
  margin: 0 40px;
}
@media (max-width: 767px) {
  .herobanner-wrapper .product-details .product-properties {
    margin: 0 24px;
  }
}
@media (min-width: 1440px) {
  .herobanner-wrapper .product-details .product-properties {
    margin: 0 64px;
  }
}
.herobanner-wrapper .product-details .product-description-wrapper {
  margin: 24px auto;
}
.herobanner-wrapper .product-details .product-ancient-ritual {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
}
.herobanner-wrapper .product-details .product-title {
  font-size: 32px;
  letter-spacing: 0.6px;
  line-height: 40px;
}
@media (max-width: 767px) {
  .herobanner-wrapper .product-details .product-title {
    font-size: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .herobanner-wrapper .product-details .product-title {
    font-size: 28px;
  }
}
.herobanner-wrapper .product-details .product-sale-price {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 0.2px;
  line-height: 32px;
}
@media (max-width: 1023px) {
  .herobanner-wrapper .product-details .product-sale-price {
    font-size: 20px;
  }
}
.herobanner-wrapper .product-details .product-more-information {
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
}
.herobanner-wrapper .product-details .button-wrapper {
  display: flex;
  flex-direction: row;
  margin: 24px auto;
}
@media (max-width: 767px) {
  .herobanner-wrapper .product-details .button-wrapper {
    flex-direction: column;
  }
}
.herobanner-wrapper .product-details .button-wrapper .availability {
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 18px;
  margin: auto 32px;
}
@media (max-width: 767px) {
  .herobanner-wrapper .product-details .button-wrapper .availability {
    margin: 16px 0;
  }
}

.show-notification-sandy,
.show-notification-black {
  position: relative;
}
.show-notification-sandy::after,
.show-notification-black::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #de4a4a;
  border: 2px solid #efedea;
}

.show-notification-sandy::after {
  border: 2px solid #efedea;
}

.show-notification-black::after {
  border: 2px solid #000000;
}

.membership {
  background-color: transparent;
  width: 100%;
}
@media (min-width: 1024px) {
  .membership {
    display: flex;
    justify-content: center;
  }
}
.membership__inner-wrapper {
  width: 100%;
}
@media (min-width: 1024px) {
  .membership__inner-wrapper {
    display: flex;
  }
}
.membership .membership__inner-wrapper {
  max-width: 1320px;
  background-color: #ffffff;
}
.membership .membership__image-holder {
  height: 40vh;
  width: 100%;
}
@media (min-width: 1024px) {
  .membership .membership__image-holder {
    height: 100%;
    width: 50%;
    flex: 1;
  }
}
.membership .membership__image-holder .image-link {
  display: block;
  width: 100%;
  height: 100%;
}
.membership .membership__image-holder > a:not(.image-link) {
  display: block;
  width: 320px;
  margin: 0 auto;
}
.membership .membership__image-holder .image-holder {
  height: 100%;
  background-position: center;
  background-size: cover;
}
.membership .membership__content-wrapper {
  position: relative;
}
@media (min-width: 1024px) {
  .membership .membership__content-wrapper {
    flex: 1;
  }
}
.membership .membership__content-wrapper .membership__text-holder {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1a1919;
}
@media (min-width: 1024px) {
  .membership .membership__content-wrapper .membership__text-holder {
    align-items: flex-start;
    padding-left: 40px;
  }
}
@media (max-width: 1023px) {
  .membership .membership__content-wrapper .membership__text-holder {
    text-align: center;
  }
}
.membership .membership__content-wrapper .membership__text-holder span {
  text-transform: uppercase;
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.membership .membership__content-wrapper .membership__text-holder h3 {
  text-transform: none;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
}
@media (min-width: 1024px) {
  .membership .membership__content-wrapper .membership__text-holder h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
.membership .membership__content-wrapper .membership__text-holder p {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
  width: 65%;
}
@media (min-width: 1024px) {
  .membership .membership__content-wrapper .membership__text-holder p {
    text-align: left;
  }
}
.membership .membership__content-wrapper .membership__gwp {
  display: flex;
  justify-content: center;
}
@media (min-width: 1160px) {
  .membership .membership__content-wrapper .membership__gwp {
    justify-content: flex-start;
  }
}
@media (max-width: 1023px) {
  .membership .membership__content-wrapper .membership__gwp .gwp-minicart-wrapper {
    display: flex;
    justify-content: center;
  }
}
.membership .membership__content-wrapper .membership__gwp .flyout-progress-bar {
  margin-bottom: 0;
  margin-top: 0;
}
@media (max-width: 1023px) {
  .membership .membership__content-wrapper .membership__gwp .flyout-progress-bar {
    margin: 0 24px;
  }
}
.membership .membership__content-wrapper .membership__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}
@media (min-width: 1024px) {
  .membership .membership__content-wrapper .membership__buttons {
    flex-direction: row;
    align-items: center;
    padding-left: 40px;
  }
}
@media (max-width: 1023px) {
  .membership .membership__content-wrapper .membership__buttons .btn {
    max-width: 560px;
    width: 100%;
  }
}
.membership .membership__content-wrapper .membership__buttons .learn-more {
  color: #9a7b69;
  margin-top: 24px;
  font-size: 14px;
}
@media (min-width: 1024px) {
  .membership .membership__content-wrapper .membership__buttons .learn-more {
    margin-top: 0;
    margin-left: 40px;
  }
}
@media (min-width: 1024px) {
  .membership .product-gift-ribbon::after {
    opacity: 1;
  }
}

.crm-in-store-phara {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.crm-in-store {
  background-color: #1a1919;
  padding: 0 0 24px;
}
.crm-in-store .member-card-wrapper {
  padding-top: 0;
}
.crm-in-store .member-card-wrapper::after {
  display: none;
}
.crm-in-store .member-card-wrapper .member-phase {
  color: #deca87;
}
.crm-in-store .apple-add-to-wallet-link {
  display: block;
  margin: 24px auto 0;
}
.crm-in-store .member-cart-add {
  color: #ffffff;
  display: inline;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 24px;
  position: relative;
}
.crm-in-store .member-cart-add::before {
  content: "";
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  height: 1px;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
}
.crm-in-store .member-cart-add::after {
  content: "";
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  height: 1px;
  left: -60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 48px;
}
.crm-in-store .reveal-base {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0) !important;
  transition: opacity 2s cubic-bezier(0.32, 0.24, 0.15, 1), transform 2s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (max-width: 767px) {
  .crm-in-store .phase-section-wrapper .phase-section {
    padding-bottom: 0;
  }
}
.crm-in-store .phase-section-wrapper .gift-benefits-block {
  background-color: transparent !important;
}
@media (max-width: 767px) {
  .crm-in-store .phase-section-wrapper .gift-benefits-block {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .crm-in-store .phase-section-wrapper .gift-benefits-block .three-tile-block-phone-slider {
    padding: 0;
  }
}
.crm-in-store .phase-section-wrapper .three-tile-phase.three-tile-block .three-tile-text p {
  color: #1a1919;
}

.crm-in-store-register {
  padding: 80px 0 40px;
}
.crm-in-store-register .crm-in-store-wrapper .field-help {
  display: none;
}
.crm-in-store-register .crm-in-store-wrapper .label-birthday {
  width: 100%;
}
.crm-in-store-register .crm-in-store-wrapper .additional-text-birthday {
  float: right;
}
.crm-in-store-register .crm-in-store-wrapper .icon-read-only::before {
  color: #ffffff !important;
  top: 8px;
}
.crm-in-store-register .crm-in-store-wrapper input[type=checkbox]:checked + label::before {
  background-color: #ffffff;
  color: #1a1919;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
}
.crm-in-store-register .crm-in-store-wrapper .form-content {
  margin-top: 24px;
  padding: 0;
}
.crm-in-store-register .crm-in-store-wrapper .form-content p {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.crm-in-store-register .crm-in-store-wrapper .form-content p a {
  color: #b8a188;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.crm-in-store-register .crm-in-store-wrapper .form-row-button {
  margin-top: 24px;
}
.crm-in-store-register .crm-in-store-wrapper .form-row-button button {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #1a1919;
}
.crm-in-store-register .crm-in-store-wrapper .form-row-button .btn {
  min-width: 100%;
}
.crm-in-store-register .crm-in-store-wrapper .crm-in-store-header {
  margin-bottom: 40px;
}
.crm-in-store-register .crm-in-store-wrapper .inputfield-row label {
  align-items: center;
}
.crm-in-store-register .crm-in-store-wrapper .inputfield-select {
  background: transparent url("../images/icons/arrow3--down-white.svg") no-repeat right 16px center;
  background-size: 10px 10px;
  background-color: transparent;
  color: #ffffff;
  padding: 16px 24px;
  border: 1px solid #b4b4b4;
}
.crm-in-store-register .crm-in-store-wrapper .optional-indicator {
  color: #b4b4b4;
}
.crm-in-store-register .crm-in-store-wrapper fieldset > .form-indent {
  margin-top: 48px;
}
.crm-in-store-register .crm-in-store-wrapper fieldset > .form-indent ~ .form-indent {
  margin-top: 16px;
}
.crm-in-store-register .crm-in-store-wrapper .label-inline.form-indent {
  border: 1px solid #b4b4b4;
  padding: 16px 16px 16px 24px;
}
.crm-in-store-register .crm-in-store-wrapper .label-inline.form-indent .required-indicator {
  display: none;
}
.crm-in-store-register .crm-in-store-wrapper .label-inline.form-indent label {
  padding: 0 25px 0 0;
}
.crm-in-store-register .crm-in-store-wrapper .label-inline.form-indent label::before {
  background-color: unset;
  border-radius: unset;
  border: 1px solid #b4b4b4;
  left: unset;
  right: 0;
  top: 0;
}
.crm-in-store-register .crm-in-store-wrapper .label-inline.form-indent span {
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  max-width: 250px;
}
.crm-in-store-register .crm-in-store-wrapper .label-inline.form-indent .error {
  color: #d6122d;
}
.crm-in-store-register .crm-in-store-wrapper .selectric-wrapper .selectric {
  background-color: unset;
  border: 1px solid #b4b4b4;
  display: flex;
  margin-top: 8px;
  padding: 16px 24px;
}
.crm-in-store-register .crm-in-store-wrapper .selectric-wrapper .selectric .label {
  color: #ffffff;
  padding: 0;
  line-height: 16px;
}
.crm-in-store-register .crm-in-store-wrapper .selectric-wrapper .selectric .button::before {
  border-left: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
}
.crm-in-store-register .crm-in-store-wrapper .selectric-wrapper .selectric .button::after {
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.crm-in-store-register .crm-in-store-wrapper .selectric-wrapper .error ~ .selectric {
  border: 1px solid #d6122d;
}
.crm-in-store-register .form-number-visible {
  margin-top: 24px;
}

.crm-last-nudge {
  background-color: #242222;
  padding: 40px 0;
}
.crm-last-nudge .crm-in-store-wrapper .crm-in-store-header {
  margin-bottom: 16px;
}

.crm-in-store-pad {
  padding: 0 24px;
}

.crm-in-store-wrapper {
  margin: 0 auto;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .crm-in-store-wrapper {
    max-width: 375px;
  }
}
.crm-in-store-wrapper .ico {
  margin-bottom: 14px;
}
.crm-in-store-wrapper .ico.apple-add-to-wallet {
  transform: scale(1.45);
}
.crm-in-store-wrapper .back-button {
  color: #ffffff;
  font-size: 12px;
  left: 24px;
  letter-spacing: 2px;
  line-height: 16px;
  position: absolute;
  text-decoration: none;
  top: 20px;
}
.crm-in-store-wrapper .image-holder {
  height: 100%;
  position: relative;
  width: 100%;
}
.crm-in-store-wrapper .image-holder .responsive-bg {
  height: 400px;
}
.crm-in-store-wrapper .image-holder::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, transparent 0%, transparent 80%, #1a1919 100%), linear-gradient(0deg, #1a1919 0%, #1a1919 20%, rgba(26, 25, 25, 0.8) 40%, rgba(26, 25, 25, 0.5) 60%, rgba(26, 25, 25, 0) 100%);
  z-index: 1;
}
.crm-in-store-wrapper .crm-in-store-block {
  margin-top: -260px;
  position: relative;
  z-index: 2;
}
.crm-in-store-wrapper .crm-in-store-block .member-card .member-phase {
  color: #deca87;
}
.crm-in-store-wrapper .crm-in-store-subhead {
  color: #deca87;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 8px;
}
.crm-in-store-wrapper .crm-in-store-header {
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 1.1px;
  line-height: 32px;
  margin-bottom: 8px;
  text-transform: unset;
}
.crm-in-store-wrapper .crm-in-store-text {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 24px;
}
.crm-in-store-wrapper .crm-in-store-text a {
  color: #b8a188;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.crm-in-store-wrapper .crm-in-store-text.crm-in-store-link {
  margin: 0;
}
.crm-in-store-wrapper .inputfield-row label {
  color: #b4b4b4;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.crm-in-store-wrapper .inputfield-row .inputfield-text {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #ffffff;
  padding: 16px 24px;
}
.crm-in-store-wrapper .inputfield-row .inputfield-text:-webkit-autofill, .crm-in-store-wrapper .inputfield-row .inputfield-text:-webkit-autofill:hover, .crm-in-store-wrapper .inputfield-row .inputfield-text:-webkit-autofill:focus, .crm-in-store-wrapper .inputfield-row .inputfield-text:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #1a1919 inset;
  -webkit-text-fill-color: #ffffff;
}
.crm-in-store-wrapper .inputfield-row .inputfield-text.error {
  border: 1px solid #d6122d;
}
.crm-in-store-wrapper .button-wrapper {
  margin: 32px 0 24px 0;
}
.crm-in-store-wrapper .button-wrapper .btn {
  min-width: 100%;
  padding: 16px 20px;
}
.crm-in-store-wrapper .phase-page-gwp {
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp {
    max-width: 375px;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts {
    flex-direction: column;
  }
}
.crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .product-gift-ribbon::after {
    background-image: url("../images/crm/ribbon-gold-corner-mobile.svg");
    height: 72px;
    width: 64px;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift {
    margin: 0 0 20px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift:last-child {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-label {
    font-size: 16px;
  }
}
.crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-price {
  padding-right: 20px;
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-price {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .col-text {
    padding-left: 16px;
  }
}
@media (min-width: 1160px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__text h2 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 10px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .crm-in-store-wrapper .phase-page-gwp .phase-page-gwp__text p {
    font-size: 14px;
    margin: 0;
    width: 100%;
  }
}

.onboarding-section-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift {
  padding: 10px 0;
}
.onboarding-section-wrapper .phase-page-gwp .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .gift-price {
  padding-right: 32px;
}

.crm-in-store-login {
  padding: 80px 0 40px;
}
.crm-in-store-login .crm-in-store-wrapper .icon-read-only::before {
  color: #ffffff !important;
  top: 8px;
}
.crm-in-store-login .crm-in-store-wrapper .crm-in-store-forgot {
  margin-bottom: 24px;
}
.crm-in-store-login .crm-in-store-wrapper .crm-in-store-header {
  margin-bottom: 24px;
}
.crm-in-store-login .crm-in-store-wrapper .button-wrapper-send-mail {
  margin: 16px 0 0;
}
.crm-in-store-login .crm-in-store-wrapper .button-wrapper .btn--primary.btn {
  border: 1px solid #ffffff;
}
.crm-in-store-login .crm-in-store-wrapper .password-reveal {
  color: #b8a188;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-decoration: underline;
}
.crm-in-store-login .crm-in-store-wrapper .password-visible {
  font-size: 14px;
  top: 0;
}
.crm-in-store-login .crm-in-store-wrapper .password-not-visible {
  position: relative;
  top: 0;
}

.phase-page-gwp {
  align-items: center;
  background-color: #242222;
  display: flex;
  flex-direction: column;
  padding: 45px 24px;
  width: 100%;
}
@media (min-width: 1160px) {
  .phase-page-gwp {
    padding: 65px 60px;
  }
}
.phase-page-gwp__text {
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
}
.phase-page-gwp__text h2,
.phase-page-gwp__text p {
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .phase-page-gwp__text h2,
  .phase-page-gwp__text p {
    width: 60%;
  }
}
@media (min-width: 1160px) {
  .phase-page-gwp__text h2,
  .phase-page-gwp__text p {
    width: 90%;
  }
}
.phase-page-gwp__text h2 {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  margin-bottom: 10px;
  text-transform: none;
}
@media (min-width: 1160px) {
  .phase-page-gwp__text h2 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}
.phase-page-gwp__text p {
  letter-spacing: 0.4px;
  line-height: 24px;
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  max-width: 1340px;
  width: 100%;
}
@media (min-width: 1024px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper {
    flex-direction: row;
    justify-content: center;
  }
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift {
  background-color: #ffffff;
  width: 100%;
  padding: 0;
}
@media (min-width: 1024px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift {
    margin: 0 10px 0 10px;
    max-width: 315px;
  }
}
@media (min-width: 1160px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift {
    max-width: 350px;
  }
}
@media (min-width: 1440px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift {
    max-width: 420px;
  }
}
@media (min-width: 1024px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift:first-child {
    margin-left: 0;
  }
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift:last-child {
    margin-right: 0;
  }
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .product-image {
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
  width: 120px;
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .product-image img {
  width: 100%;
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .col-text {
    padding-left: 15px;
  }
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .ecom-title {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.7px;
  padding-right: 37px;
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-label {
  color: #1a1919;
  font-size: 16px;
  line-height: 24px;
  padding-right: 37px;
}
@media (min-width: 1024px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-label {
    font-size: 20px;
  }
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-price {
  color: #626262;
  font-size: 14px;
  line-height: 24px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
@media (min-width: 1440px) {
  .phase-page-gwp__gifts.gwp-shoppingcart-wrapper .approaching-free-gift .promo-product-details .product-name .gift-price {
    width: 80%;
  }
}
.phase-page-gwp__gifts.gwp-shoppingcart-wrapper .product-gift-ribbon::after {
  opacity: 1;
}
.phase-page-gwp__buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.phase-page-gwp__buttons--terms {
  color: #b8a188;
  margin-top: 16px;
}

.cart-banner {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .cart-banner {
    margin-bottom: 24px;
  }
}
.cart-banner .content-asset {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  width: 100%;
}
.cart-banner .cart-banner-img-container {
  flex: 0 0 auto;
  height: 100px;
  width: 104px;
}
@media (max-width: 767px) {
  .cart-banner .cart-banner-img-container {
    height: 148px;
  }
}
.cart-banner .cart-banner-img-container img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.cart-banner .cart-banner-text-content {
  margin-top: 14px;
  padding: 0 24px;
  text-align: left;
}
@media (max-width: 767px) {
  .cart-banner .cart-banner-text-content {
    margin-top: 8px;
    padding: 0 26px;
  }
}
.cart-banner .cart-banner-title,
.cart-banner .cart-banner-paragraph,
.cart-banner .cart-banner-link {
  color: #1a1919;
}
.cart-banner .cart-banner-title {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}
.cart-banner .cart-banner-paragraph,
.cart-banner .cart-banner-link {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.cart-banner--mini-cart, .cart-banner--mini-cart-toaster {
  margin: -18px 24px 24px;
}
@media (max-width: 767px) {
  .cart-banner--mini-cart, .cart-banner--mini-cart-toaster {
    margin: 16px 24px;
  }
}
.cart-banner--mini-cart .content-asset, .cart-banner--mini-cart-toaster .content-asset {
  background-color: #faf9f7;
}
.cart-banner--mini-cart .cart-banner-img-container, .cart-banner--mini-cart-toaster .cart-banner-img-container {
  height: 124px;
}
@media (max-width: 767px) {
  .cart-banner--mini-cart .cart-banner-img-container, .cart-banner--mini-cart-toaster .cart-banner-img-container {
    height: 148px;
  }
}
@media (max-width: 767px) {
  .cart-banner--mini-cart-toaster .cart-banner-text-content {
    padding: 0 22px;
  }
}

.minimum-amount-banner {
  background-color: #faf9f7;
  margin: 8px 24px;
  padding: 16px;
}
.minimum-amount-banner .minimum-amount {
  align-items: center;
  color: #1a1919;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 28px;
  margin-bottom: 0;
  text-align: left;
}
.minimum-amount-banner .minimum-amount::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea31";
  color: #1a1919;
  display: block;
  font-size: 24px;
  margin-right: 16px;
}

.pdp-main-container {
  background-color: #efedea;
  position: relative;
}
@media (max-width: 767px) {
  .pdp-main-container.no-ingredients:not(.product-configurable) {
    margin-bottom: 520px;
  }
}

.pdp-hospitality-exclusive-banner {
  align-items: center;
  background-color: #ad4141;
  color: #ffffff;
  display: flex;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0.6px;
  line-height: 24px;
  padding: 14px 24px;
}
@media (max-width: 767px) {
  .pdp-hospitality-exclusive-banner {
    font-size: 14px;
    letter-spacing: 0.4px;
  }
}
.pdp-hospitality-exclusive-banner span {
  position: relative;
}
.pdp-hospitality-exclusive-banner a {
  color: #ffffff;
}
@media (max-width: 767px) {
  .pdp-hospitality-exclusive-banner a {
    font-size: 14px;
  }
}

.pdp-hospitality-exclusive-banner-circle::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea30";
  display: inline-block;
  font-size: 24px;
  margin-right: 18px;
}

.pdp-inner-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.pdp-inner-wrapper .product-details-content .availability-web .btn {
  background-color: #1a1919;
  color: #ffffff;
}
.pdp-inner-wrapper .product-details-content .availability-web .btn:hover {
  background-color: #452f3f;
}

.pdp-breadcrumbs-wrapper {
  padding: 1px 0 0;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper {
    z-index: 1;
  }
}
.pdp-breadcrumbs-wrapper .breadcrumb {
  display: none;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper .breadcrumb {
    display: block;
  }
  .pdp-breadcrumbs-wrapper .breadcrumb + .pdp-back-button-wrapper {
    display: none;
  }
}
.pdp-breadcrumbs-wrapper .pdp-back-button-wrapper {
  padding: 2px 0;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper .pdp-back-button-wrapper {
    padding: 7px 0 0;
  }
}
.pdp-breadcrumbs-wrapper .pdp-back-button-wrapper .back-button {
  margin-left: -8px;
}
.pdp-breadcrumbs-wrapper .pdp-back-button {
  cursor: pointer;
  position: relative;
}
.pdp-breadcrumbs-wrapper .pdp-back-button .pdp-backbutton::after {
  content: "";
  background-color: #968977;
  bottom: 0;
  display: inline-block;
  height: 1px;
  left: 17px;
  position: absolute;
  transition: width 0.3s ease;
  width: 0;
}
.pdp-breadcrumbs-wrapper .pdp-back-button:hover .pdp-backbutton::after {
  width: 70%;
}
.pdp-breadcrumbs-wrapper .pdp-back-button .icon {
  font-size: 9px;
  margin-right: 0;
  vertical-align: middle;
}
.pdp-breadcrumbs-wrapper .pdp-back-button .text {
  font-size: 12px;
  letter-spacing: 2px;
  color: #453f3f;
  text-transform: uppercase;
}

.pdp-images-holder {
  max-width: 100%;
  transition: opacity 0.9s ease-in-out;
  opacity: 1;
}
@media (max-width: 767px) {
  .pdp-images-holder {
    min-height: 400px;
  }
}
.pdp-images-holder.fade-out {
  opacity: 0;
}

.product-details-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
@media (min-width: 768px) {
  .product-details-container .breadcrumb {
    margin-top: 5px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .product-details-container .breadcrumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .product-details-container.is-asset .column-images {
    display: none;
  }
}
.product-details-container .images-wrapper {
  max-width: 100%;
}
@media (max-width: 1023px) {
  .product-details-container .images-wrapper {
    width: 100%;
  }
}
.product-details-container .images-wrapper .slider-bar {
  margin: 30px auto;
}
.product-details-container .images-wrapper .slider-dots {
  bottom: 60px;
}
.product-details-container .images-wrapper .promotional-label {
  background: #ffffff;
  margin: 20px auto;
  width: fit-content;
}
.product-details-container .pulsating-button-wrapper {
  margin-bottom: 20px;
  position: fixed;
}
@media (min-height: 475px) {
  .product-details-container .pulsating-button-wrapper {
    position: static;
  }
}
.product-details-container .images-slider,
.product-details-container .images-slider-fullwidth {
  display: flex;
  overflow: hidden;
}
.product-details-container .images-slider.slick-initialized img,
.product-details-container .images-slider-fullwidth.slick-initialized img {
  opacity: 1;
}
.product-details-container .images-slider.slick-initialized img:hover,
.product-details-container .images-slider-fullwidth.slick-initialized img:hover {
  cursor: pointer;
}
.product-details-container .images-slider .slick-slide > div,
.product-details-container .images-slider-fullwidth .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .product-details-container .images-slider .slick-slide > div,
  .product-details-container .images-slider-fullwidth .slick-slide > div {
    overflow: hidden;
    height: calc(100vw - 40px);
    position: relative;
    width: 100%;
  }
}
.product-details-container .images-slider .slick-arrow {
  position: absolute;
  z-index: 1;
}
.product-details-container .images-slider .slick-arrow.slick-next {
  right: 0;
}
.product-details-container .images-slider img {
  opacity: 0;
  width: auto !important;
}
@media (max-height: 474px) {
  .product-details-container .images-slider img {
    height: auto;
  }
}
@media (max-width: 767px) {
  .product-details-container .images-slider img {
    height: calc(100vw - 40px);
    max-height: 100%;
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider img {
    align-self: flex-start;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-details-container .images-slider img {
    max-height: 340px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .product-details-container .images-slider img {
    max-height: 264px;
  }
}
@media (min-width: 1024px) {
  .product-details-container .images-slider img {
    max-width: 400px;
  }
}
@media (min-width: 1440px) {
  .product-details-container .images-slider img {
    max-width: 440px;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider.slick-initialized {
    max-width: 540px;
  }
}
.product-details-container .images-slider-fullwidth {
  position: relative;
}
.product-details-container .images-slider-fullwidth.slick-initialized img {
  height: 100%;
  object-fit: cover;
}
.product-details-container .images-slider-fullwidth .slick-slide {
  opacity: 0.35;
  transition: opacity 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.product-details-container .images-slider-fullwidth .slick-slide:not(.slick-current):hover {
  opacity: 0.55;
}
.product-details-container .images-slider-fullwidth .slick-slide.slick-current {
  opacity: 1;
  transition-duration: 1.2s;
}
.product-details-container .images-slider-fullwidth .slick-slide.slick-current img {
  transition: transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (min-width: 1024px) {
  .product-details-container .images-slider-fullwidth .slick-slide.slick-current:hover img {
    transform: scale(1.2);
    transition-duration: 0.8s;
  }
}
.product-details-container .images-slider-fullwidth .slick-slide > div {
  align-items: center;
  overflow: hidden;
}
@media (max-width: 767px) {
  .product-details-container .images-slider-fullwidth .slick-slide > div {
    height: 340px;
    margin: 0 8px;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider-fullwidth .slick-slide > div {
    height: 500px;
    margin: 0 30px;
  }
}
.product-details-container .images-slider-fullwidth .slick-arrow {
  position: absolute;
  z-index: 1;
}
@media (max-width: 767px) {
  .product-details-container .images-slider-fullwidth .slick-arrow.slick-prev {
    left: 4px;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider-fullwidth .slick-arrow.slick-prev {
    left: 58px;
  }
}
@media (max-width: 767px) {
  .product-details-container .images-slider-fullwidth .slick-arrow.slick-next {
    right: 4px;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider-fullwidth .slick-arrow.slick-next {
    right: 58px;
  }
}
@media (max-width: 767px) {
  .product-details-container .images-slider-fullwidth .slick-arrow .slider-button {
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 36px;
    height: 36px;
    width: 36px;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider-fullwidth .slick-arrow .slider-button {
    color: #ffffff;
    transition: none;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider-fullwidth .slick-arrow .slider-button:hover {
    background-color: transparent;
  }
}
.product-details-container .images-slider-header span,
.product-details-container .images-slider-footer span {
  color: #ffffff;
  display: block;
}
.product-details-container .images-slider-header {
  align-items: flex-end;
  display: flex;
}
@media (max-width: 1023px) {
  .product-details-container .images-slider-header {
    padding: 50px 0 30px 0;
  }
}
@media (min-width: 1024px) {
  .product-details-container .images-slider-header {
    height: 160px;
    padding-bottom: 40px;
  }
}
.product-details-container .images-slider-header .scrub-collection {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
}
@media (max-width: 1023px) {
  .product-details-container .images-slider-footer {
    padding: 30px 40px 20px 40px;
  }
}
@media (min-width: 1024px) {
  .product-details-container .images-slider-footer {
    height: 240px;
    padding-top: 40px;
  }
}
.product-details-container .images-slider-footer .scrub-variant {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 3px;
  line-height: 19px;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .product-details-container .images-slider-footer .scrub-variant {
    margin-bottom: 16px;
  }
}
@media (min-width: 1024px) {
  .product-details-container .images-slider-footer .scrub-variant {
    margin-bottom: 30px;
  }
}
.product-details-container .images-slider-footer .scrub-variant-number {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 19px;
  margin-bottom: 8px;
}
.product-details-container .images-slider-footer .scrub-variant-props {
  display: flex;
  list-style: none;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.product-details-container .images-slider-footer .scrub-variant-props li {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
@media (max-width: 767px) {
  .product-details-container .images-slider-footer .scrub-variant-props li {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .product-details-container .images-slider-footer .scrub-variant-props li {
    width: 140px;
  }
}
.product-details-container .images-slider-footer .scrub-variant-props li + li {
  margin-left: 10px;
}
.product-details-container .images-slider-footer .scrub-variant-props li .icon {
  font-size: 22px;
}
.product-details-container .product-images {
  position: relative;
}
.product-details-container .product-ingredients-images {
  display: none;
}
.product-details-container .product-ingredients-images img {
  max-height: 100%;
}
.product-details-container .pdp-images-spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .full-width-image .pdp-breadcrumbs-wrapper {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 1;
  }
  .full-width-image .product-details-container {
    padding-top: 0;
  }
  .full-width-image .pdp-images-holder {
    margin: -20px -20px 0;
    max-width: calc(100% + 40px);
    width: calc(100% + 40px);
  }
  .full-width-image .images-slider {
    display: block;
    width: 100%;
  }
  .full-width-image .images-slider .slick-slide > div {
    height: auto;
  }
  .full-width-image .images-wrapper img {
    height: auto;
    width: 100% !important;
  }
}

.configurable-details-container .btn-configure {
  position: absolute;
  right: 0;
  top: -4px;
}
.configurable-details-container .btn-configure .icon-pencil-configurable {
  background: url("../images/pencil-black-icon.svg") left top no-repeat;
  background-size: 34px 34px;
  display: inline-block;
  height: 34px;
  width: 34px;
  vertical-align: middle;
}
.configurable-details-container .check-availability-button {
  display: none;
}
.configurable-details-container .product-details-content .product-add-to-cart {
  margin-bottom: 10px !important;
}
@media (max-width: 767px) {
  .configurable-details-container {
    padding-top: 0;
  }
  .configurable-details-container .column-images {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 !important;
    position: relative;
  }
  .configurable-details-container .pdp-images-holder {
    margin: -20px 0 0 -20px;
  }
}
@media (min-width: 768px) {
  .configurable-details-container .pdp-inner-wrapper {
    position: relative;
  }
  .configurable-details-container .btn-configure {
    top: 30px;
    left: 30px;
    right: auto;
  }
}

@media (min-width: 768px) {
  .column-images body:not(.personalised-gifting-page) {
    padding-top: 120px;
  }
}
.column-images body:not(.personalised-gifting-page) .promotional-label {
  margin-top: 20px;
  margin-bottom: 30px;
}
.column-images body:not(.personalised-gifting-page) img {
  max-width: 100%;
}

.product-details-column {
  width: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  body:not(.personalised-gifting-page) .product-details-column {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  body.personalised-gifting-page .product-details-column {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .product-details-column .column-images {
    z-index: 2;
  }
}

.product-title .ritual-icon {
  color: #9a7b69;
  font-size: 25px;
  height: 44px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .product-title .ritual-icon {
    font-size: 35px;
    margin-bottom: 16px;
  }
}
@media (max-width: 1023px) {
  .product-title .product-name span:last-child:not(:only-child) {
    display: none;
  }
}
.product-title .the-ritual-of-title {
  margin-bottom: 10px;
}

.product-details-content {
  max-width: 520px;
  width: 100%;
}
@media (min-width: 768px) {
  .product-details-content {
    padding: 20px;
  }
}
.product-details-content .product-preview-image {
  height: 180px;
  margin: 10px auto;
  width: 180px;
}
.product-details-content .product-preview-image img {
  max-height: 100%;
  max-width: 100%;
}
.product-details-content .product-name {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .product-details-content .product-name {
    letter-spacing: 0.6px;
  }
}
@media (min-width: 768px) {
  .product-details-content .product-name {
    margin-bottom: 18px;
  }
}
.product-details-content .product-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-details-content .product-content .promotion-wrapper {
  margin: 32px 0 32px 0;
}
.product-details-content .product-content .promotion-wrapper .callout-message {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
  border-radius: 0;
}
.product-details-content .product-content .promotion-wrapper .callout-message.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.product-details-content .product-content .promotion-wrapper .callout-message.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.product-details-content .product-content .promotion-wrapper .callout-message.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.product-details-content .product-content .promotion-wrapper .callout-message.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.product-details-content .product-content .promotion-wrapper .callout-message.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.product-details-content .product-content .promotion-wrapper .callout-message.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}
.product-details-content .product-content .btn-wrapper-send {
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-details-content .product-content .btn-wrapper-send .btn {
    min-width: 220px;
  }
}
.product-details-content .product-short-info {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.36px;
  color: #767676;
}
.product-details-content .shipping-info {
  font-size: 14px;
  letter-spacing: 0.36px;
  line-height: 24px;
  margin-top: 22px;
  max-width: 100%;
}
.product-details-content .shipping-info ul {
  list-style: none;
  text-align: center;
}
.product-details-content .shipping-info ul .shipping-info-point {
  color: #1a1919;
  display: block;
  margin-bottom: 4px;
  max-width: 400px;
}
.product-details-content .shipping-info ul .shipping-info-point strong {
  font-weight: 700;
}
.product-details-content .shipping-info ul .shipping-info-point span {
  display: inline-block;
}
.product-details-content .shipping-info ul .shipping-info-point .icon-check {
  font-size: 10px;
  margin-right: 10px;
  color: #886655;
  vertical-align: top;
  width: 15px;
}
.product-details-content .shipping-info ul .shipping-info-point,
.product-details-content .shipping-info ul a {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.36px;
}
.product-details-content .shipping-info .shipping-info-slider .slick-dots {
  font-size: 0;
  list-style: none;
  text-align: center;
}
.product-details-content .shipping-info .shipping-info-slider .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.product-details-content .shipping-info .shipping-info-slider .slick-dots button {
  background-color: #d1d0ce;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 4px;
  width: 4px;
}
.product-details-content .shipping-info .shipping-info-slider .slick-dots .slick-active button {
  background-color: #453f3f;
}
.product-details-content .shipping-info .pdp-shipping-info-container {
  margin-top: -8px;
}
.product-details-content .shipping-info .pdp-shipping-info-container .ico {
  font-size: 32px;
  margin-bottom: 24px;
  color: #b8a188;
}
.product-details-content .shipping-info .pdp-shipping-info-container .usp-text-slider {
  max-width: 250px;
}
.product-details-content .shipping-info .pdp-shipping-info-container .usp-text-slider::before {
  background: linear-gradient(to right, #efedea 0%, rgba(239, 237, 234, 0) 100%);
}
.product-details-content .shipping-info .pdp-shipping-info-container .usp-text-slider::after {
  background: linear-gradient(to right, rgba(239, 237, 234, 0) 0%, #efedea 100%);
}
.product-details-content .shipping-info .pdp-shipping-info-container .usp-text-slider .highlighted {
  display: inline-block;
}
.product-details-content .shipping-info .pdp-shipping-info-container .usp-text-slider p {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.product-details-content .shipping-info .pdp-shipping-info-container .usp-text-slider .slick-dots {
  margin: 0;
}
.product-details-content .pdp-disclaimer {
  color: #1a1919;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 30px;
  padding-top: 13px;
}
@media (min-width: 768px) {
  .product-details-content .pdp-disclaimer {
    margin: 0 auto 30px;
    max-width: 300px;
  }
}
.product-details-content .short-description,
.product-details-content .previously-known-as {
  margin-bottom: 27px;
}
.product-details-content .long-description {
  line-height: 28px;
  max-width: 380px;
  width: 100%;
}
.product-details-content .long-description .previously-known-as {
  margin-bottom: 30px;
}
.product-details-content .value-price {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #7f7f7f;
  margin: -7px 0 19px 0;
  width: 100%;
  max-width: 380px;
}
.product-details-content .giftset-valueprice {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: #1a1919;
  margin: 3px 0 20px;
}
.product-details-content .unit-base-price {
  margin-top: 10px;
}
.product-details-content .unit-base-price.legal-note {
  margin-top: 13px;
}
.product-details-content .product-price,
.product-details-content .product-add-to-cart {
  margin-bottom: 20px;
}
.product-details-content .product-price.b2b-price {
  display: flex;
  gap: 8px;
  margin: 16px 0;
}
@media (max-width: 767px) {
  .product-details-content .product-price.b2b-price {
    align-items: center;
  }
}
.product-details-content .product-price.b2b-price .unit-base-price {
  color: #626262;
}
@media (max-width: 767px) {
  .product-details-content .product-price.b2b-price .unit-base-price.legal-note {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .product-details-content .product-price.b2b-price .unit-base-price span {
    display: flex;
    text-align: left;
    width: 10ch;
  }
}
@media (min-width: 1024px) {
  .product-details-content .product-price .base-price-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }
}
.product-details-content .price-standard {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2.4px;
  text-decoration: line-through;
  color: #8c8c8c;
  display: block;
  margin-top: 5px;
}
.product-details-content .product-discount-no-strikethrough {
  color: #000000;
  display: block;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .product-details-content .product-discount-no-strikethrough {
    font-size: 14px;
  }
}
.product-details-content .product-add-to-cart {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.product-details-content .product-add-to-cart .btn-select-option ~ .availability-web {
  display: none;
}
.product-details-content .product-add-to-cart .btn-wrapper:not(.hide) {
  display: flex;
  flex-direction: column;
}
.product-details-content .product-add-to-cart .btn-wrapper .btn-customize {
  margin-bottom: 10px;
  padding: 13px 20px;
}
.product-details-content .product-add-to-cart .btn-wrapper .btn-customize .text {
  display: inline-block;
}
.product-details-content .product-add-to-cart .btn-wrapper .btn-customize .icon-pencil-configurable {
  background: url("../images/pencil-black-icon.svg") left top no-repeat;
  background-size: 16px 16px;
  display: inline-block;
  height: 16px;
  width: 16px;
  transition: 0.2s ease-in-out;
  margin: 0 -24px -4px 2px;
}
.product-details-content .product-add-to-cart .btn-wrapper .btn-customize.btn--primary .icon-pencil-configurable {
  background: url("../images/pencil-white-icon.svg") left top no-repeat;
  background-size: 16px 16px;
  display: inline-block;
  height: 16px;
  width: 16px;
}
@media (min-width: 1025px) {
  .product-details-content .product-add-to-cart .btn-wrapper .btn-customize.btn--primary:hover .icon-pencil-configurable {
    background: url("../images/pencil-black-icon.svg") left top no-repeat;
    background-size: 16px 16px;
    display: inline-block;
    height: 16px;
    width: 16px;
  }
}
.product-details-content .product-variations {
  margin-bottom: 30px;
}
.product-details-content .product-variations ~ form .btn-add-to-cart,
.product-details-content .product-variations ~ form .btn-select-option {
  margin-top: 20px;
}
.product-details-content .out-of-stock-text {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #000000;
  margin: -8px auto 10px;
}
@media (min-width: 768px) {
  .product-details-content .out-of-stock-text {
    max-width: 384px;
  }
}
.product-details-content .read-more-link {
  border-width: 1px;
}
.product-details-content .pdpForm {
  width: 100%;
}
.product-details-content .product-info-warning-message {
  display: block;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin: 6px auto 25px;
  max-width: 315px;
  padding-left: 23px;
  position: relative;
  text-align: left;
}
@media (min-width: 768px) {
  .product-details-content .product-info-warning-message {
    letter-spacing: 0.6px;
    max-width: 380px;
  }
}
.product-details-content .product-info-warning-message .info-icon {
  display: inline-block;
  left: 0;
  position: absolute;
  top: 5px;
}
.product-details-content.b2b-price {
  display: flex;
}

.read-more-link {
  display: inline-block;
  margin: 5px 0;
  line-height: 13px;
  text-decoration: none;
  border-bottom: 2px solid currentColor;
}
.read-more-link:hover {
  border-color: transparent;
}
@media (max-width: 767px) {
  .read-more-link {
    font-size: 14px;
  }
}

.box-contains-block {
  position: relative;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #efedea;
}
@media (min-width: 768px) {
  .box-contains-block {
    min-height: 740px;
    position: static;
  }
}
.box-contains-block .box-contains-heading {
  margin-bottom: 30px;
  letter-spacing: 1.2px;
  text-transform: none;
}
@media (min-width: 768px) {
  .box-contains-block .box-contains-heading {
    letter-spacing: 0.6px;
  }
}
.box-contains-block .box-content-wrapper {
  margin-left: 0;
  max-width: 100%;
}
@media (min-width: 768px) {
  .box-contains-block .box-content-wrapper {
    margin-left: 50%;
    max-width: 50%;
    padding-right: 40px;
  }
}
.box-contains-block .box-content-wrapper .box-item {
  padding: 20px 0;
  border-bottom: 1px solid #cfcfcf;
}
.box-contains-block .box-content-wrapper .box-item:last-child {
  border-bottom: 0;
}
.box-contains-block .box-content-wrapper .item-ritual,
.box-contains-block .box-content-wrapper .item-text {
  line-height: 24px;
  margin-bottom: 0;
}

.product-tabs-container {
  background-color: #efedea;
  position: relative;
}
@media (max-width: 767px) {
  .product-tabs-container {
    padding: 36px 0 60px;
  }
  .product-tabs-container .inner-wrapper {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .product-tabs-container {
    padding: 30px 0;
  }
}
@media (min-width: 1024px) {
  .product-tabs-container {
    padding: 57px 0 80px;
  }
  .product-tabs-container .inner-wrapper {
    max-width: 1200px;
  }
}
.product-tabs-container.product-advent-tabs {
  background-color: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 90px 0 80px;
}
@media (max-width: 767px) {
  .product-tabs-container.product-advent-tabs {
    padding: 70px 0 60px;
  }
}
.product-tabs-container.product-advent-tabs .btn--secondary {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
@media (min-width: 768px) {
  .product-tabs-container.product-advent-tabs .btn--secondary:hover {
    background-color: #ffffff;
    color: #1a1919;
  }
}
.product-tabs-container.product-advent-tabs .accordion-wrapper::before {
  left: 25px;
  right: 25px;
}
@media (max-width: 767px) {
  .product-tabs-container.product-advent-tabs .accordion-wrapper .accordion-head {
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .product-tabs-container.product-advent-tabs .accordion-wrapper .accordion-content {
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .product-tabs-container.product-advent-tabs .accordion-wrapper .accordion-content a {
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .product-tabs-container.product-advent-tabs .accordion-wrapper .accordion-content .shippingPromotionTable p {
    color: #ffffff;
  }
}
.product-tabs-container.product-advent-tabs .tab-content .tab-content-inner a {
  color: #ffffff;
}
.product-tabs-container.product-advent-tabs .accordion-item {
  padding: 0 25px;
}
.product-tabs-container.product-advent-tabs .tabs-holder .tabs .tab-item {
  color: #ffffff;
}
.product-tabs-container.product-advent-tabs .tabs-holder .tabs .tab-item:hover {
  color: #ffffff;
}
.product-tabs-container.product-advent-tabs .tabs-holder .tabs .tab-item::after {
  background-color: #ffffff;
}
.product-tabs-container.product-advent-tabs .tabs-holder .tabs-content .tab-content {
  color: #ffffff;
}

.pdp-main-quickview {
  height: 100%;
}
body:not(.personalised-gifting-page) .pdp-main-quickview .product-details-container,
body:not(.personalised-gifting-page) .pdp-main-quickview .product-details-column {
  width: 100%;
}
.pdp-main-quickview .product-details-container {
  height: 100%;
  justify-content: center;
}
.pdp-main-quickview .product-details-column .ritual-icon {
  display: none;
}
.pdp-main-quickview .product-variations .unselectable a {
  pointer-events: none;
  cursor: default;
}

.product-background-video.show {
  opacity: 0;
}
@media (min-height: 475px) {
  .product-background-video.show {
    opacity: 0.4;
  }
}

.how-to-use-block {
  background-color: #efedea;
}
.how-to-use-block .video-controls_bottom-right {
  opacity: 1;
  margin: 28px;
}
.how-to-use-block .video-controls_bottom-right .video-control-button {
  font-size: 65px;
}

@media (min-width: 768px) {
  .how-to-use-block-homme {
    flex-direction: row-reverse;
  }
  .how-to-use-block-homme .product-hero-box {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .how-to-use-block-hydra .product-hero-media {
    order: -1;
  }
}
.how-to-use-block-hydra .product-hero-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.how-to-use-block-hydra .product-hero-content {
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .how-to-use-block-hydra .product-hero-content {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .how-to-use-block-hydra.product-hero-container-customisable .product-hero-content .product-hero-box {
    padding: 24px 40px;
  }
}
.how-to-use-block-hydra.product-hero-container-customisable .product-hero-content .product-hero-box .hydra-title {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.how-to-use-block-hydra .hydra-logo {
  margin-bottom: 16px;
  width: 50px;
  height: 50px;
}

.pdp-swatches-wrapper {
  margin: 20px auto 10px;
}
.pdp-swatches-wrapper.wide {
  max-width: 250px;
}
.pdp-swatches-wrapper .pdp-swatches-list {
  font-size: 0;
  max-width: 205px;
  margin: 0 auto;
}
.pdp-swatches-wrapper .pdp-swatches-text {
  color: #1a1919;
  display: block;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
.pdp-swatches-wrapper .pdp-swatches-text .pdp-swatches-name {
  font-weight: 400;
}
.pdp-swatches-wrapper .pdp-swatches-item {
  display: inline-block;
  height: 28px;
  width: 28px;
  vertical-align: top;
  margin: 0 7px 13px;
}
.pdp-swatches-wrapper .pdp-swatches-item.hidden {
  display: none;
}
.pdp-swatches-wrapper .pdp-swatches-item.visible {
  display: inline-block;
}
.pdp-swatches-wrapper .pdp-swatches-button {
  position: relative;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  overflow: hidden;
  border: 0.5px solid transparent;
}
.pdp-swatches-wrapper .pdp-swatches-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.pdp-swatches-wrapper .selected .pdp-swatches-button {
  border-color: #1a1919;
  padding: 4px;
}
.pdp-swatches-wrapper .out-of-stock .pdp-swatches-button {
  opacity: 0.8;
}
.pdp-swatches-wrapper .out-of-stock .pdp-swatches-button::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 17px;
  height: 17px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background: transparent;
}
.pdp-swatches-wrapper .out-of-stock .pdp-swatches-button::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 1px;
  height: 18px;
  background-color: #ffffff;
}
.pdp-swatches-wrapper .selected.out-of-stock .pdp-swatches-button::before {
  border-color: transparent;
}
.pdp-swatches-wrapper .pdp-swatches-link {
  display: block;
  margin: 6px auto 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
}
.pdp-swatches-wrapper .pdp-swatches-link:hover, .pdp-swatches-wrapper .pdp-swatches-link:active {
  text-decoration: underline;
}
@media (min-width: 1025px) {
  .pdp-swatches-wrapper .pdp-swatches-link:hover, .pdp-swatches-wrapper .pdp-swatches-link:active {
    text-decoration: none;
  }
}

.pdp-options-dropdown {
  width: 100%;
  margin: 0 auto 28px;
  max-width: 343px;
}
.pdp-options-dropdown .selectric-wrapper.selectric-inputfield-select .selectric {
  padding: 7px 21px 7px 5px;
}
.pdp-options-dropdown .selectric-wrapper.selectric-inputfield-select .selectric-items li {
  text-align: left;
}
.pdp-options-dropdown .selectric-wrapper.selectric-inputfield-select .selectric-items li[data-index="0"] {
  display: none;
}
.pdp-options-dropdown .selectric-wrapper.selectric-inputfield-select .selectric-items li.disabled, .pdp-options-dropdown .selectric-wrapper.selectric-inputfield-select .selectric-items li.out-of-stock {
  display: block;
  color: rgba(26, 25, 25, 0.3);
}
.pdp-options-dropdown .error {
  display: none;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 1px;
  margin: 2px 0;
  text-align: left;
}
.pdp-options-dropdown.error-field .error {
  display: block;
}
.pdp-options-dropdown.error-field .selectric,
.pdp-options-dropdown.error-field .inputfield-select {
  border-top: 1px solid #d6122d;
  margin-top: 3px;
}
.pdp-options-dropdown.out-of-stock .selectric-wrapper .selectric .label,
.pdp-options-dropdown.out-of-stock .inputfield-select {
  color: rgba(26, 25, 25, 0.3);
}

.pdp-options-explanation,
.pdp-options-explanation a {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.pdp-options-explanation {
  text-align: left;
  margin: 5px 0 0;
}

.outlet-pdp-login-header {
  background-color: #f6f4f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 22px 30px 30px;
  width: 100%;
}
.outlet-pdp-login-header .outlet-pdp-login-header-text {
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 20px;
  width: 100%;
}
.outlet-pdp-login-header .outlet-login-btn {
  width: 48%;
}
.outlet-pdp-login-header .btn {
  min-width: 10px;
  padding: 15px 5px 14px;
  width: 100%;
}
.outlet-pdp-login-header .form-row-button:not(.full-width-row) {
  margin-top: 0;
}
@media (min-width: 768px) {
  .outlet-pdp-login-header {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 40px 40px 40px 30px;
  }
  .outlet-pdp-login-header .outlet-pdp-login-header-text {
    display: inline-block;
    margin: 0 auto 0 0;
    width: auto;
  }
  .outlet-pdp-login-header .outlet-login-btn {
    margin-left: 20px;
    min-width: 200px;
    width: auto;
  }
  .outlet-pdp-login-header .outlet-login-btn:last-child {
    margin-left: 10px;
  }
}

.outlet-pdp-back {
  padding: 20px;
  width: 100%;
}
.outlet-pdp-back .pdp-backbutton {
  font-size: 12px;
  line-height: 14px;
}
.outlet-pdp-back .icon {
  line-height: 14px;
}
.outlet-pdp-back .button-text {
  color: #453f3f;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.product-options.hair-temple {
  max-width: 400px;
  margin: 0 auto 10px;
}

.pdp-elixir-product {
  margin-bottom: 10px;
  width: 100%;
}
.pdp-elixir-product:last-of-type {
  margin-bottom: 0;
}
.pdp-elixir-product .selectric-wrapper .selectric {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #d8d8d8;
  padding: 10px 35px 7px 19px;
}
.pdp-elixir-product .selectric-wrapper .selectric .label {
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  text-transform: none;
  padding-left: 0;
}
.pdp-elixir-product .selectric-wrapper .selectric .button {
  top: 10px;
}
.pdp-elixir-product .selectric-wrapper .selectric-items ul {
  text-align: left;
}
.pdp-elixir-product .selectric-wrapper.selectric-disabled {
  opacity: 0.7;
}
.pdp-elixir-product .selectric-wrapper.selectric-disabled .selectric {
  cursor: default;
}
.pdp-elixir-product .selectric-wrapper.selectric-disabled .selectric .label {
  opacity: 0.7;
}

.pdp-elixir-customer-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}
.pdp-elixir-customer-name .elixir-name-inner {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.pdp-elixir-customer-name .elixir-name-inner input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #d8d8d8;
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-top: 0;
  order: 2;
  padding: 13px 35px 10px 19px;
}
.pdp-elixir-customer-name .elixir-name-inner input.error {
  border-top-color: #d6122d;
}
.pdp-elixir-customer-name .elixir-name-inner input::placeholder {
  color: #aeaeae;
}
.pdp-elixir-customer-name .elixir-charnumber {
  bottom: 16px;
  color: #999999;
  font-size: 11px;
  letter-spacing: 3px;
  line-height: 13px;
  position: absolute;
  right: 18px;
}
.pdp-elixir-customer-name .elixir-note {
  color: #999999;
  display: block;
  font-size: 14px;
  letter-spacing: 1.27px;
  line-height: 17px;
  padding: 17px 20px 20px;
  text-align: left;
}
@media (min-width: 768px) {
  .pdp-elixir-customer-name .elixir-note {
    padding: 15px 20px 18px;
  }
}

.pdp-elixir-product span.error,
.pdp-elixir-customer-name span.error-text {
  color: #d6122d;
  display: block;
  padding: 9px 4px 4px 0;
  text-align: left;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .pdp-elixir-product span.error,
  .pdp-elixir-customer-name span.error-text {
    padding: 9px 4px 4px;
  }
}

.product-bundles-block {
  display: flex;
  min-height: 740px;
  padding: 40px 0;
  width: 100%;
}
@media (max-width: 767px) {
  .product-bundles-block {
    position: relative;
  }
}
@media (min-width: 768px) {
  .product-bundles-block .inner-wrapper {
    align-items: center;
    display: flex;
  }
}

.product-bundles-text-container {
  width: 100%;
}
.product-bundles-text-container .heading-h3 {
  margin-bottom: 30px;
}
.product-bundles-text-container .bundles-list {
  list-style: none;
  max-width: 600px;
  width: 100%;
}
.product-bundles-text-container .bundles-list li {
  border-bottom: 1px solid #cfcfcf;
  display: flex;
  margin-bottom: 12px;
  padding-bottom: 20px;
  width: 100%;
}
.product-bundles-text-container .image-holder {
  padding: 5px 8px 0 0;
  text-align: center;
  width: 82px;
}
.product-bundles-text-container .image-holder img {
  display: inline-block;
  max-width: 100%;
}
.product-bundles-text-container .text-holder {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  width: calc(100% - 82px);
}
.product-bundles-text-container .the-ritual-of-title {
  color: #886655;
  font-size: 11px;
  letter-spacing: 1.65px;
  line-height: 24px;
  margin-bottom: 0;
  width: 100%;
}
.product-bundles-text-container h3 {
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 20px;
  margin-bottom: 32px;
  width: calc(100% - 100px);
}
.product-bundles-text-container .read-more-link {
  border-width: 1px;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 10px;
  margin: 0;
  order: 2;
  text-transform: uppercase;
}
.product-bundles-text-container .price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 20px;
  margin-left: auto;
  text-align: right;
  width: 100px;
}
@media (min-width: 768px) {
  .product-bundles-text-container {
    margin-left: 50%;
    padding-left: 40px;
    width: 50%;
  }
  .product-bundles-text-container .heading-h3 {
    padding: 0 10px;
  }
  .product-bundles-text-container .image-holder {
    padding: 5px 8px 0 0;
  }
  .product-bundles-text-container .bundles-list {
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .zoomed-in .product-details-container .images-slider {
    max-width: 250px;
  }
  .zoomed-in .product-details-container .slider-bar {
    bottom: 0;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
  }
  .zoomed-in .product-details-container .slider-dots {
    bottom: 20px;
    position: fixed;
  }
  .zoomed-in .slider-dots {
    pointer-events: auto;
  }
}

@media (min-width: 768px) {
  .flyout-content.instore-availability-flyout-content,
  .product-ingredients-column .product-ingredients-content {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  body:not(.personalised-gifting-page) .product-ingredients-container {
    flex-direction: row;
    min-height: 760px;
  }
}

.countdown-block {
  display: flex;
  border: 1px solid #9a7b69;
  background-color: #fcfaf7;
  padding: 13px 26px 11px 13px;
  margin: 12px 0 35px;
  max-width: 400px;
}
@media (max-width: 767px) {
  .countdown-block {
    margin: 20px 0 45px;
  }
}
.countdown-block .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}
.countdown-block .text-wrapper {
  display: flex;
  flex-direction: column;
}
.countdown-block .ico {
  font-size: 42px;
  color: #9a7b69;
}
.countdown-block .countdown {
  color: #9a7b69;
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 1.7px;
  margin-bottom: 2px;
}
.countdown-block .info {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1a1919;
}

.btn-wrapper-send .btn {
  position: relative;
  background-color: transparent;
  border: 1px solid #b4b4b4;
  letter-spacing: 1.5px;
  color: #1a1919;
}
.btn-wrapper-send .btn.send-icon-visible {
  background-color: #1a1919;
  color: #ffffff;
  display: inline-block;
  position: relative;
  z-index: 10;
}
.btn-wrapper-send .btn.send-icon-visible::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .btn-wrapper-send .btn.send-icon-visible::before {
    font-size: 10px;
  }
}
.btn-wrapper-send .btn span {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 3px));
  right: 10px;
  font-size: 20px;
}
.btn-wrapper-send .btn.button-loading .icon-send {
  display: none;
}
.btn-wrapper-send .btn .spinner-visible :nth-child(1) {
  margin-right: 15px;
}
.btn-wrapper-send .btn .spinner-visible :nth-child(2) {
  margin-right: 0;
}
.btn-wrapper-send .btn .spinner-visible :nth-child(3) {
  margin-right: -15px;
}
.btn-wrapper-send .btn .spinner-visible span {
  right: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) and (max-width: 1023px) {
  .btn-wrapper-send .btn {
    min-width: 100%;
  }
}
@media (min-width: 1024px) {
  .btn-wrapper-send .btn:hover, .btn-wrapper-send .btn:active {
    border-color: #1a1919;
  }
}

.product-type-body-scrub-bar .pdp-inner-wrapper {
  max-width: none;
  padding: 0;
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .pdp-inner-wrapper {
    background-color: #1a1919;
    display: block;
  }
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .pdp-inner-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
}
.product-type-body-scrub-bar .pdp-breadcrumbs-wrapper {
  position: absolute;
}
@media (max-width: 767px) {
  .product-type-body-scrub-bar .pdp-images-holder {
    min-height: auto;
  }
}
.product-type-body-scrub-bar .product-details-container {
  padding: 0;
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .product-details-container {
    background: linear-gradient(90deg, #242222 0%, #242222 62.5%, #f6f4f2 62.5%, #f6f4f2 100%);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-type-body-scrub-bar .images-wrapper {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .product-type-body-scrub-bar .images-wrapper img {
    height: auto;
    width: auto;
  }
}
@media (max-width: 767px) {
  .product-type-body-scrub-bar .images-slider .slick-slide > div,
  .product-type-body-scrub-bar .images-slider-fullwidth .slick-slide > div {
    overflow: visible;
    height: auto;
    position: static;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-type-body-scrub-bar .images-slider img {
    max-height: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .product-type-body-scrub-bar .images-slider img {
    max-height: none;
  }
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .images-slider img {
    max-width: none;
  }
}
@media (min-width: 1440px) {
  .product-type-body-scrub-bar .images-slider img {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .product-type-body-scrub-bar .full-width-image {
    margin: 0;
    max-width: none;
    width: auto;
  }
}
@media (min-width: 768px) {
  .product-type-body-scrub-bar .product-details-column {
    width: auto;
  }
}
.product-type-body-scrub-bar .product-details-column.column-images {
  background-color: #242222;
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .product-details-column.column-images {
    max-height: 900px;
    width: 62.5%;
  }
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .product-details-column.column-details {
    align-items: flex-start;
    width: 37.5%;
  }
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .product-title {
    background-color: #efedea;
    padding: 40px 24px;
  }
}
.product-type-body-scrub-bar .product-title .ritual-icon {
  height: 38px;
}
.product-type-body-scrub-bar .product-details-content {
  max-width: none;
}
@media (min-width: 768px) {
  .product-type-body-scrub-bar .product-details-content {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .product-details-content {
    padding: 84px 30px 30px 30px;
  }
}
.product-type-body-scrub-bar .product-details-content .product-type-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 38px;
}
.product-type-body-scrub-bar .product-details-content .product-type-icon.scrub {
  background-image: url("../images/product-type-scrub.svg");
}
@media (max-width: 767px) {
  .product-type-body-scrub-bar .product-details-content .product-name {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .product-name {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .product-name span:last-child:not(:only-child) {
    display: none;
  }
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .product-content {
    background-color: #242222;
    padding: 20px 24px 40px 24px;
  }
}
.product-type-body-scrub-bar .product-details-content .product-content .check-availability-button-hor {
  display: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .product-short-info {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .shipping-info {
    color: #ffffff;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .product-details-content .shipping-info {
    color: #1a1919;
    margin: 30px auto 0;
  }
}
.product-type-body-scrub-bar .product-details-content .short-description,
.product-type-body-scrub-bar .product-details-content .previously-known-as {
  margin-bottom: 10px;
}
.product-type-body-scrub-bar .product-details-content .long-description {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .long-description {
    margin-left: auto;
    margin-right: auto;
  }
}
.product-type-body-scrub-bar .product-details-content .long-description .previously-known-as {
  margin-bottom: 10px;
}
.product-type-body-scrub-bar .product-details-content .product-price,
.product-type-body-scrub-bar .product-details-content .product-add-to-cart {
  margin-bottom: 30px;
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .product-price {
    color: #ffffff;
  }
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .product-details-content .product-add-to-cart .btn-wrapper .btn {
    background-color: #ffffff;
    color: #1a1919;
  }
}
.product-type-body-scrub-bar .pdp-swatches-wrapper {
  margin: 0;
}
.product-type-body-scrub-bar .pdp-swatches-wrapper .pdp-swatches-list {
  margin-bottom: 27px;
  max-width: 260px;
}
.product-type-body-scrub-bar .pdp-swatches-wrapper .pdp-swatches-list .last-two-items {
  display: inline-block;
}
.product-type-body-scrub-bar .pdp-swatches-wrapper .pdp-swatches-text {
  display: none;
}
.product-type-body-scrub-bar .pdp-swatches-wrapper .pdp-swatches-item {
  height: 32px;
  margin: 0 10px 10px 10px;
  width: 32px;
}
.product-type-body-scrub-bar .pdp-swatches-wrapper .pdp-swatches-button {
  border-width: 1px;
  height: 32px;
  width: 32px;
}
@media (max-width: 1023px) {
  .product-type-body-scrub-bar .pdp-swatches-wrapper .selected .pdp-swatches-button {
    border-color: #ffffff;
  }
}
@media (min-width: 1024px) {
  .product-type-body-scrub-bar .pdp-swatches-wrapper .selected .pdp-swatches-button {
    border-color: #1a1919;
  }
}

@media (max-width: 767px) {
  .easter-pdp .productthumbnail {
    margin: auto;
  }
}
@media (min-width: 768px) {
  .easter-pdp .pdp-breadcrumbs-wrapper .breadcrumb {
    display: none;
  }
  .easter-pdp .pdp-breadcrumbs-wrapper .breadcrumb + .pdp-back-button-wrapper {
    display: block;
  }
}
@media (max-width: 767px) {
  .easter-pdp .pdp-images-holder {
    margin-bottom: 30px;
    min-height: 300px !important;
  }
}
.easter-pdp.product-details-container {
  min-height: 800px;
}
.easter-pdp .price-sales {
  font-size: 40px;
  letter-spacing: 0.6px;
}
.easter-pdp.easter-bg-yellow {
  background: linear-gradient(to right, #f5e9d3 61%, transparent 61%);
}
@media (min-width: 2300px) {
  .easter-pdp.easter-bg-yellow {
    background: linear-gradient(to right, #f5e9d3 57%, transparent 57%);
  }
}
@media (max-width: 767px) {
  .easter-pdp.easter-bg-yellow {
    background: transparent;
  }
  .easter-pdp.easter-bg-yellow .images-slider {
    background-color: #f5e9d3;
  }
}
.easter-pdp.easter-bg-pink {
  background: linear-gradient(to right, #fae0dc 61%, transparent 61%);
}
@media (min-width: 2300px) {
  .easter-pdp.easter-bg-pink {
    background: linear-gradient(to right, #fae0dc 57%, transparent 57%);
  }
}
@media (max-width: 767px) {
  .easter-pdp.easter-bg-pink {
    background: transparent;
  }
  .easter-pdp.easter-bg-pink .images-slider {
    background-color: #fae0dc;
  }
}
.easter-pdp.easter-bg-blue {
  background: linear-gradient(to right, #e1f7fa 61%, transparent 61%);
}
@media (min-width: 2300px) {
  .easter-pdp.easter-bg-blue {
    background: linear-gradient(to right, #e1f7fa 57%, transparent 57%);
  }
}
@media (max-width: 767px) {
  .easter-pdp.easter-bg-blue {
    background: transparent;
  }
  .easter-pdp.easter-bg-blue .images-slider {
    background-color: #e1f7fa;
  }
}
.easter-pdp.easter-bg-grey {
  background: linear-gradient(to right, #e7e7e7 61%, transparent 61%);
}
@media (min-width: 2300px) {
  .easter-pdp.easter-bg-grey {
    background: linear-gradient(to right, #e7e7e7 57%, transparent 57%);
  }
}
@media (max-width: 767px) {
  .easter-pdp.easter-bg-grey {
    background: transparent;
  }
  .easter-pdp.easter-bg-grey .images-slider {
    background-color: #e7e7e7;
  }
}
.easter-pdp.easter-bg-solid-yellow {
  background-color: #f5e9d3;
}
.easter-pdp.easter-bg-solid-yellow .egg-model-viewer-size-s .btn:last-of-type {
  background-color: #ffffff;
}
.easter-pdp.easter-bg-solid-pink {
  background-color: #fae0dc;
}
.easter-pdp.easter-bg-solid-pink .egg-model-viewer-size-s .btn:last-of-type {
  background-color: #ffffff;
}
.easter-pdp.easter-bg-solid-blue {
  background-color: #e1f7fa;
}
.easter-pdp.easter-bg-solid-blue .egg-model-viewer-size-s .btn:last-of-type {
  background-color: #ffffff;
}
.easter-pdp.easter-bg-solid-grey {
  background-color: #e7e7e7;
}
.easter-pdp.easter-bg-solid-grey .egg-model-viewer-size-s .btn:last-of-type {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .easter-pdp {
    padding: 0;
  }
}
.easter-pdp .pdp-back-button-wrapper .back-button {
  color: #000000;
}
@media (max-width: 767px) {
  .easter-pdp .pdp-back-button-wrapper .back-button {
    color: #000000;
  }
}
.easter-pdp .pdp-inner-wrapper {
  align-items: center;
}
@media (max-width: 767px) {
  .easter-pdp .pdp-inner-wrapper {
    display: block;
  }
}
.easter-pdp .pdp-inner-wrapper .product-details-column .icon-AR {
  color: #886655;
  font-size: 30px;
  height: 30px;
  left: 50%;
  line-height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
}
.easter-pdp .pdp-inner-wrapper .product-details-column .icon-AR::before {
  vertical-align: sub;
}
.easter-pdp .pdp-inner-wrapper .product-details-column .easter-view-block {
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 10;
}
@media (max-width: 767px) {
  .easter-pdp .pdp-inner-wrapper .product-details-column .easter-view-block {
    right: 0;
  }
}
.easter-pdp .pdp-inner-wrapper .product-details-column .easter-view-block-ar {
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 10;
}
@media (max-width: 767px) {
  .easter-pdp .pdp-inner-wrapper .product-details-column .easter-view-block-ar {
    right: 0;
  }
}
.easter-pdp .pdp-inner-wrapper .product-details-column .egg-model-viewer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  --poster-color: transparent;
}
.easter-pdp .pdp-inner-wrapper .product-details-column .easter-icon-block {
  position: relative;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 2;
}
.easter-pdp .pdp-inner-wrapper .product-details-column .easter-icon-text {
  font-size: 12px;
  color: #1a1919;
}
.easter-pdp .pdp-inner-wrapper .product-details-column .easter-view-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}
.easter-pdp .pdp-inner-wrapper .product-details-column.column-images {
  position: relative;
  width: 64%;
}
@media (max-width: 767px) {
  .easter-pdp .pdp-inner-wrapper .product-details-column.column-images {
    width: 100%;
  }
}
.easter-pdp .pdp-inner-wrapper .product-details-column.column-details {
  margin: auto;
  padding: 0 50px;
  width: 36%;
}
@media (max-width: 767px) {
  .easter-pdp .pdp-inner-wrapper .product-details-column.column-details {
    width: 100%;
    padding: 0 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .easter-pdp .pdp-inner-wrapper .product-details-column.column-details {
    padding: 0;
  }
}
.easter-pdp .pdp-inner-wrapper .product-details-column.column-details .short-description {
  line-height: 24px;
  letter-spacing: 0.5px;
}
.easter-pdp .pdp-inner-wrapper .pdp-swatches-list {
  min-width: 200px;
}
@media (min-width: 768px) {
  .easter-pdp .pdp-inner-wrapper .images-slider.slick-initialized {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .easter-pdp .pdp-inner-wrapper .images-slider {
    padding: 100px 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body:not(.personalised-gifting-page) .easter-pdp .pdp-inner-wrapper .images-slider img {
    max-height: 588px;
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  body:not(.personalised-gifting-page) .easter-pdp .pdp-inner-wrapper .images-slider img {
    max-height: 588px;
    max-width: 100%;
  }
}
@media (min-width: 1025px) {
  body:not(.personalised-gifting-page) .easter-pdp .pdp-inner-wrapper .images-slider img {
    max-height: 588px;
    max-width: 100%;
  }
}

.easter-link {
  color: #b8a188;
}

.homme-pdp .pdp-inner-wrapper {
  padding-top: 100px;
}
.homme-pdp .images-slider.slick-initialized img,
.homme-pdp .images-slider-fullwidth.slick-initialized img {
  max-height: 440px;
}
.homme-pdp .product-price {
  margin-top: 20px;
}

.homme-bg-full-color {
  padding-bottom: 0;
}

.experience-assets-recommendedheroblock .horizontal-grid-block {
  padding: 0;
}
.experience-assets-recommendedheroblock .inner-wrapper {
  max-width: unset;
  padding: 0;
}
.experience-assets-recommendedheroblock .item {
  position: relative;
}
@media (max-width: 767px) {
  .experience-assets-recommendedheroblock .item .video-holder {
    height: 375px;
  }
}
.experience-assets-recommendedheroblock .item .image-holder {
  background-position: center;
  background-size: cover;
}
@media (max-width: 767px) {
  .experience-assets-recommendedheroblock .item .image-holder {
    height: 375px;
  }
}
@media (max-width: 767px) {
  .experience-assets-recommendedheroblock .cop-filters-wrapper {
    height: auto;
  }
}
@media (min-width: 768px) {
  .experience-assets-recommendedheroblock .item-text {
    padding: 100px 0;
  }
}
.experience-assets-recommendedheroblock .cop-filters-inner .cop-filters-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.experience-assets-recommendedheroblock .cop-filters-inner .cop-filters-list li {
  align-items: center;
  background-color: #f3f3f3;
  border: initial;
  display: flex;
  margin: 0 16px 16px 0;
  padding: 0 16px;
}
.experience-assets-recommendedheroblock .cop-filters-inner .cop-filters-list p {
  font-size: 14px;
  margin-bottom: 0;
}
.experience-assets-recommendedheroblock .category-pills {
  margin: 40px 0 24px;
}
.experience-assets-recommendedheroblock .category-pills .cop-filters-wrapper {
  background-color: initial;
}
.experience-assets-recommendedheroblock .content-banner-btn-wrapper {
  justify-content: center;
}

.header {
  background-color: #1a1919;
  height: 88px;
  left: 0;
  position: sticky;
  top: 0;
  transition: transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 981;
}
@media (min-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .header:not(.header-checkout-v3):not(.header--empty-checkout) {
    height: 112px;
  }
}
@media (min-width: 2300px) {
  .header:not(.header-checkout-v3):not(.header--empty-checkout) {
    height: 136px;
  }
}
.header.nav-active {
  z-index: 1099;
}
.header.nav-active .nav-toggle {
  height: 24px;
}
@media (max-width: 767px) {
  .header.nav-active .nav-toggle {
    margin-top: -2px;
  }
}
.header.nav-hide {
  transform: translateY(-100%);
}
@media (min-width: 768px) and (max-width: 1024px) {
  .header.fastlane-header {
    height: 64px;
  }
  .header.fastlane-header + .header-spacer {
    height: 64px;
  }
}
@media (max-width: 767px) {
  .header.personalised-gifting-header {
    height: 64px;
  }
  .header.personalised-gifting-header .header--bottom,
  .header.personalised-gifting-header .navigation-inner {
    height: 64px;
  }
  .header.personalised-gifting-header .header-logo {
    left: 24px;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .header.personalised-gifting-header .header-logo {
    margin-top: -6px;
  }
}
@media (min-width: 768px) {
  .header.personalised-gifting-header .header-logo {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
@media (min-width: 1024px) {
  .header.personalised-gifting-header {
    position: fixed;
  }
}
.header .header-inline-icon {
  height: 24px !important;
  vertical-align: middle;
  width: 24px !important;
}
@media (min-width: 2300px) {
  .header .header-inline-icon {
    height: 32px !important;
    width: 32px !important;
  }
}

.header-spacer {
  display: none;
}
@media (min-width: 1024px) {
  body:not(.personalised-gifting-page) .header-spacer {
    display: block;
    height: 95px;
  }
}

.skip-to-content {
  background: #242222;
  border: 0;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
}
.skip-to-content:focus {
  clip: auto;
  -webkit-clip-path: none;
  clip-path: none;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  letter-spacing: 2px;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  z-index: 52;
}

.header--top {
  background-color: #242222;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .header--top {
    position: relative;
    top: 0;
  }
}
.header--top.header--top-pg {
  display: none;
}
@media (min-width: 1025px) {
  .header--top.header--top-pg {
    display: block;
  }
}
.header--top .inner-wrapper {
  display: flex;
  height: 32px;
  justify-content: space-between;
  padding: 8px 30px;
}
@media (min-width: 1024px) {
  .header--top .inner-wrapper {
    height: 48px;
    max-width: 1512px;
    padding: 16px 96px;
  }
}
@media (min-width: 2300px) {
  .header--top .inner-wrapper {
    height: 56px;
  }
}
.header--top .menu-top {
  display: none;
  font-size: 0;
}
@media (min-width: 768px) {
  .header--top .menu-top {
    display: flex;
  }
}
.header--top .menu-top--item {
  display: inline-block;
  line-height: normal;
  list-style: none;
  margin-left: 8px;
  padding-left: 12px;
  position: relative;
}
.header--top .menu-top--item::before {
  background-color: #626262;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 2px;
  left: 2px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.header--top .menu-top--item:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header--top .menu-top--item:first-child::before {
  display: none;
}
@media (max-width: 1024px) {
  .header--top .menu-top--item.menu-top--item-desktop {
    display: none;
  }
  .header--top .menu-top--item.menu-top--item-desktop + .menu-top--item {
    margin-left: 0;
    padding-left: 0;
  }
  .header--top .menu-top--item.menu-top--item-desktop + .menu-top--item::before {
    display: none;
  }
}
.header--top .menu-top--item a {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-decoration: none;
}
.header--top .menu-top--item a:hover {
  text-decoration: underline;
}
@media (min-width: 2300px) {
  .header--top .menu-top--item a {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}

.header-exclude-content .inner-wrapper {
  align-items: center;
  height: 64px;
  padding: 0 24px;
}
@media (min-width: 768px) {
  .header-exclude-content .inner-wrapper {
    padding: 0 40px;
  }
}
@media (min-width: 1024px) {
  .header-exclude-content .inner-wrapper {
    height: 64px;
    padding: 0 40px;
  }
}
@media (min-width: 1440px) {
  .header-exclude-content .inner-wrapper {
    max-width: 1320px;
    padding: 0;
  }
}
.header-exclude-content .header-logo-desktop {
  display: block;
  left: 0;
  position: relative;
  top: 0;
  transform: none;
}

.header-usp {
  max-width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .header-usp {
    max-width: 30%;
    text-align: start;
  }
}

.header-usp--item {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  opacity: 0;
  transition: opacity 500ms linear;
  vertical-align: top;
}
@media (max-width: 767px) {
  .header-usp--item {
    -webkit-text-size-adjust: 100%;
  }
}
.slick-active .header-usp--item {
  opacity: 1;
  transition: opacity 500ms linear 500ms;
}
@media (min-width: 2300px) {
  .header-usp--item {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
}
.header-usp--item a {
  color: #b8a188;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.header--bottom {
  align-items: center;
  background-color: #1a1919;
  display: flex;
  height: 56px;
  justify-content: space-between;
  position: relative;
  top: 0;
  width: 100%;
}
@media (min-width: 1025px) {
  .header--bottom {
    height: 64px;
  }
}
@media (min-width: 2300px) {
  .header--bottom {
    height: 80px;
  }
}

.navigation-inner {
  display: flex;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}
.navigation-inner.active {
  z-index: 50;
}
@media (max-width: 767px) {
  .navigation-inner.active {
    background-color: #242222;
  }
}
@media (max-width: 767px) {
  .navigation-inner.active .header--logo {
    display: none;
  }
}
@media (max-width: 767px) {
  .navigation-inner.active .header--search {
    background-color: #242222;
  }
}
@media (max-width: 767px) {
  .navigation-inner.active .header--search .search-input-wrapper {
    margin-left: 50px;
    width: calc(100% - 50px);
  }
}
@media (max-width: 767px) {
  .navigation-inner.active .header--search .icon-close.search-icon-close {
    left: -54px;
  }
}
.navigation-inner .nav-inner-wrapper {
  display: flex;
}
@media (max-width: 767px) {
  .navigation-inner .nav-inner-wrapper {
    padding: 0 14px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .navigation-inner .nav-inner-wrapper {
    padding: 0 40px;
  }
}
@media (min-width: 1025px) {
  .navigation-inner .nav-inner-wrapper {
    align-items: center;
    max-width: 1512px;
    padding: 0 96px;
  }
}
.navigation-inner .nav-additional-text {
  color: #767676;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 1px;
  margin-left: 2px;
  text-transform: uppercase;
}

.nav-toggle {
  height: 13px;
  left: 27px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 51;
}
@media (max-width: 767px) {
  .nav-toggle {
    left: 17px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nav-toggle {
    left: 43px;
    margin-top: 0;
  }
}
@media (min-width: 1025px) {
  .nav-toggle {
    display: none;
  }
}
@media (max-width: 767px) {
  .nav-toggle.fade-out {
    opacity: 0;
    pointer-events: none;
  }
}
.nav-toggle.active .toggle-nav-bar {
  opacity: 0;
  visibility: hidden;
}
.nav-toggle.active .icon-close {
  opacity: 1;
  visibility: visible;
}
.nav-toggle .toggle-nav-bar {
  background-color: #ffffff;
  display: block;
  height: 1px;
  margin: 6px 0;
  width: 100%;
}
.nav-toggle .toggle-nav-bar::before, .nav-toggle .toggle-nav-bar::after {
  background-color: #ffffff;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
}
.nav-toggle .toggle-nav-bar::before {
  top: 0;
}
.nav-toggle .toggle-nav-bar::after {
  bottom: 0;
}
.nav-toggle .icon-close {
  color: #ffffff;
  display: inline-block;
  font-size: 22px;
  left: -2px;
  line-height: 1;
  opacity: 0;
  position: absolute;
  top: 3px;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: hidden;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nav-toggle .icon-close {
    top: 50%;
    transform: translateY(-50%);
  }
}

.header-logo {
  color: #ffffff;
  opacity: 1;
}
.header-logo-mobile-tablet {
  display: block;
}
.header-logo-desktop {
  display: none;
}
@media (max-width: 1024px) {
  .header-logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  .header-logo {
    left: 65px;
    width: 128px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .header-logo {
    left: 105px;
    width: 173px;
  }
}
@media (min-width: 1025px) {
  .header-logo {
    display: inline-block;
    flex-shrink: 0;
    width: 140px;
  }
  .header-logo-mobile-tablet {
    display: none;
  }
  .header-logo-desktop {
    display: block;
  }
}
@media (min-width: 1440px) {
  .header-logo {
    width: 173px;
  }
}
@media (min-width: 2300px) {
  .header-logo {
    width: 267px;
  }
}
@media (max-width: 767px) {
  .header-logo.fade-out {
    transition-delay: 0s;
    opacity: 0;
  }
}
@media (min-width: 768px) {
  .header-logo.header-custom-logo {
    height: 19px;
    width: auto;
  }
  .header-logo.header-custom-logo .custom-logo {
    height: 100%;
  }
  .header-logo.header-custom-logo img {
    height: 100%;
    width: auto;
  }
}
.header-logo svg {
  display: block;
  height: 16px;
  width: 100%;
}
.header-logo.header-logo-b2b {
  width: 235px;
}
@media (max-width: 767px) {
  .header-logo.header-logo-b2b {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: -44px;
    scale: 0.8;
  }
}

.header-action-links {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 15px 0;
  width: 100%;
}
@media (min-width: 768px) {
  .header-action-links {
    gap: 32px;
    height: 56px;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .header-action-links {
    gap: 32px;
  }
}
@media (min-width: 2300px) {
  .header-action-links {
    height: 80px;
  }
}
.header-action-links li {
  display: inline-block;
  list-style: none;
}
.header-action-links a {
  color: #ffffff;
  line-height: 28px;
  text-decoration: none;
}
.header-action-links a span {
  position: relative;
}
.header-action-links a .sr-only {
  position: absolute;
}

.header--search {
  flex: 0;
  width: auto;
  z-index: 2;
}
@media (min-width: 1025px) {
  .header--search {
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    min-width: 152px;
    position: relative;
    transition: flex 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
  }
}
@media (min-width: 1160px) {
  .header--search {
    min-width: 240px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header--search {
    flex-basis: 200px;
  }
}
.header--search .search-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .header--search .search-input-wrapper {
    padding: 8px;
  }
}
.header--search .search-input {
  background-color: transparent;
  border-color: transparent;
  color: #d8d8d8;
  font-family: "Scala Sans Pro Italic", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  margin: 0;
  overflow: auto;
  padding: 0;
  width: 100%;
}
.header--search .search-input::placeholder {
  color: #d8d8d8;
}
@media (min-width: 1025px) {
  .header--search .search-input {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-left: 16px;
  }
}
@media (min-width: 1025px) {
  .header--search .icon-search-magnifier svg {
    height: 16px !important;
    width: 16px !important;
  }
}
.header--search .icon-search-magnifier::before {
  display: none;
}
.header--search .search-clear {
  display: none;
  margin: 0;
  position: absolute;
  right: 15px;
  top: 7px;
}
.header--search .icon-close {
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  padding-right: 8px;
}

@media (max-width: 767px) {
  .search-expand {
    background-color: #242222;
    height: 56px;
    position: relative;
  }
  .search-expand::before, .search-expand::after {
    background-color: #242222;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    width: 24px;
  }
  .search-expand::before {
    right: 100%;
  }
  .search-expand::after {
    left: 100%;
  }
}
@media (max-width: 767px) {
  .search-expand .header--user-account,
  .search-expand .header--cart {
    display: none;
  }
}
.search-expand .header--search {
  border: none;
  flex: 1;
  margin-left: 0;
  z-index: 3;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-expand .header--search {
    max-width: 247px;
    position: relative;
  }
}
@media (min-width: 1025px) {
  .search-expand .header--search {
    max-width: 356px;
  }
}
.search-expand .header--search .search-input-wrapper {
  padding: 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .search-expand .header--search .search-input-wrapper {
    display: block;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 30px);
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-expand .header--search .search-input-wrapper {
    height: 40px;
    right: -5px;
  }
}
.search-expand .header--search .search-input {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .search-expand .header--search .search-input {
    background-color: #1a1919;
    border-radius: 24px;
    color: #b4b4b4;
    height: 48px;
    letter-spacing: 0.4px;
    line-height: 20px;
    padding: 3px 45px 0 22px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-expand .header--search .search-input {
    background-color: #2e2d2d;
    border-radius: 20px;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 3px 30px 0 20px;
  }
}
@media (min-width: 768px) {
  .search-expand .header--search .search-input {
    margin-right: 20px;
  }
}
@media (min-width: 1025px) {
  .search-expand .header--search .search-input {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .search-expand .header--search .icon-search-magnifier {
    position: absolute;
    transform: scale(0.9);
  }
}
@media (max-width: 767px) {
  .search-expand .header--search .icon-search-magnifier {
    right: 18px;
    top: 14px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-expand .header--search .icon-search-magnifier {
    right: 7px;
    top: 11px;
  }
}
@media (min-width: 1025px) {
  .search-expand .header--search .icon-search-magnifier {
    margin-right: 9px;
  }
}
@media (max-width: 767px) {
  .search-expand .header--search .icon-search-magnifier .header-inline-icon {
    display: block;
  }
}
@media (max-width: 1024px) {
  .search-expand .header--search .icon-close.search-icon-close {
    font-size: 12px;
    margin: 0;
    padding: 10px;
    position: absolute;
  }
}
@media (max-width: 767px) {
  .search-expand .header--search .icon-close.search-icon-close {
    left: -41px;
    top: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-expand .header--search .icon-close.search-icon-close {
    display: inline-block;
    left: -30px;
    top: 4px;
  }
}
.search-expand .header--search .icon-close.search-icon-close::before {
  content: "\ea36";
}
@media (max-width: 767px) {
  .search-expand .header--search .icon-close.search-icon-close::before {
    content: "\ea08";
  }
}
.search-expand .header--search .search-clear {
  font-size: 16px;
  right: 13px;
  top: 13px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-expand .search-suggestions-container {
    height: auto;
    left: 0;
    margin-top: 48px;
    max-height: calc(100vh - 130px);
    overflow: auto;
    right: -70px;
  }
}

.header--user-account {
  transition: opacity 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (min-width: 1025px) {
  .header--user-account .header--user-account-link {
    display: inline-block;
  }
}

.header--cart {
  transition: opacity 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.header--cart .header--cart-link {
  display: inline-block;
  position: relative;
}
@media (min-width: 1025px) {
  .header--cart .header--cart-link {
    top: -1px;
  }
}
.header--cart .cart-number {
  align-items: center;
  background-color: #9a7b69;
  border-radius: 50%;
  bottom: -7px;
  color: #ffffff;
  display: flex;
  font-size: 12px !important;
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-variant-numeric: lining-nums;
  height: 20px;
  justify-content: center;
  line-height: 16px !important;
  margin: 0;
  position: absolute;
  right: -5px;
  text-align: center;
  width: 20px;
}
.header--cart .cart-number-hidden {
  display: none;
}
.header--cart .cart-number-show {
  display: flex;
}

.mobile-cart-confirmation {
  background-color: #fcfaf7;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  color: #1a1919 !important;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  opacity: 0;
  padding: 40px 0;
  position: fixed;
  text-align: center;
  top: 0;
  transform: translateY(-110%);
  transition: transform 0.6s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  width: 100%;
  z-index: 999;
}
@media (min-width: 768px) {
  .mobile-cart-confirmation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 40px;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .mobile-cart-confirmation {
    display: none;
  }
}
.mobile-cart-confirmation.open {
  transform: translateY(0);
}
.mobile-cart-confirmation.page-loaded {
  opacity: 1;
}
.mobile-cart-confirmation .mobile-cart-confirmation-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 993;
}
.mobile-cart-confirmation .close-toaster {
  z-index: 994;
}
.mobile-cart-confirmation .text-container {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.mobile-cart-confirmation .icon-cart {
  margin-right: 18px;
}
.mobile-cart-confirmation .go-to-cart-link {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}
@media (max-width: 1023px) {
  .mobile-cart-confirmation .image-holder {
    max-width: 100px;
  }
}
.mobile-cart-confirmation .image-holder img {
  max-width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .mobile-cart-confirmation.checkout-mobile-cart {
    padding: 20px 40px;
  }
}
@media (max-width: 767px) {
  .mobile-cart-confirmation.checkout-mobile-cart {
    align-items: center;
    display: flex;
    height: auto;
    padding: 26px 20px 20px;
    top: 0;
    transform: translateY(-110%);
    transition: transform 0.6s cubic-bezier(0.18, 0.74, 0.36, 0.99);
    z-index: 994;
  }
  .mobile-cart-confirmation.checkout-mobile-cart.open {
    transform: translateY(0);
  }
  .mobile-cart-confirmation.checkout-mobile-cart .image-holder {
    flex-shrink: 0;
    margin-right: 20px;
    width: 100px;
  }
  .mobile-cart-confirmation.checkout-mobile-cart .image-holder img {
    display: block;
    max-width: 100%;
  }
  .mobile-cart-confirmation.checkout-mobile-cart .mini-cart-image img {
    width: 100%;
  }
  .mobile-cart-confirmation.checkout-mobile-cart .text-holder {
    text-align: left;
  }
  .mobile-cart-confirmation.checkout-mobile-cart .text-container {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 14px;
  }
  .mobile-cart-confirmation.checkout-mobile-cart .go-to-cart-link {
    font-size: 10px;
    line-height: 14px;
  }
}

.header.header--empty-checkout {
  background-color: #1a1919;
  height: 40px;
}
.header.header--empty-checkout .cs-number--top {
  display: none;
}
.header.header--empty-checkout .header-empty--logo {
  right: 16px;
  position: absolute;
  top: 9px;
  width: 84px;
}
.header.header--empty-checkout .header-empty--logo img {
  width: 100%;
}
.header.header--empty-checkout .header-empty--logo svg {
  height: 16px;
  width: 100%;
}
.header.header--empty-checkout .header-empty--secure-checkout {
  color: #ffffff;
  float: left;
  letter-spacing: 1px;
  margin: 13px 0 0 -3px;
  font-size: 14px;
  line-height: 20px;
}
.header.header--empty-checkout .header-empty--secure-checkout .icon-secure {
  display: inline-block;
  font-size: 20px;
  margin: -4px 6px 0 0;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .header.header--empty-checkout {
    height: 70px;
  }
  .header.header--empty-checkout .header-empty--secure-checkout {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.14px;
    margin: 26px 0 0;
  }
  .header.header--empty-checkout .header-empty--secure-checkout .icon-secure {
    margin-right: 10px;
  }
  .header.header--empty-checkout .header-empty--logo {
    display: block;
    left: 44%;
    top: 25px;
    transform: translateX(-50%);
    width: 120px;
  }
  .header.header--empty-checkout .cs-number--top {
    display: block;
  }
  .header.header--empty-checkout .cs-number--top a {
    color: #ffffff;
    float: right;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    margin: 31px 0 0;
    text-decoration: none;
  }
  .header.header--empty-checkout .cs-number--top strong {
    font-weight: bold;
  }
}
@media (min-width: 1024px) {
  .header.header--empty-checkout .inner-wrapper {
    padding: 0 40px 0 54px;
  }
  .header.header--empty-checkout .header-empty--logo {
    height: 26px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
  }
  .header.header--empty-checkout .header-empty--logo .custom-logo {
    height: 100%;
    transform: translateX(-50%);
  }
  .header.header--empty-checkout .header-empty--logo img {
    height: 100%;
    width: auto;
  }
}

.close-popup-text,
.filter-label-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.header-checkout-v3 {
  position: relative;
}
@media (max-width: 767px) {
  .header-checkout-v3 {
    height: 56px;
  }
}
@media (min-width: 768px) {
  .header-checkout-v3 {
    height: 80px;
  }
}
@media (min-width: 1024px) {
  .header-checkout-v3 .inner-wrapper {
    padding: 0 40px 0 54px;
    height: 100%;
  }
}
.header-checkout-v3 .header-empty--logo {
  display: block;
  position: absolute;
}
@media (max-width: 767px) {
  .header-checkout-v3 .header-empty--logo {
    top: 19px;
    width: 84px;
  }
}
@media (min-width: 768px) {
  .header-checkout-v3 .header-empty--logo {
    top: 30px;
    width: 120px;
  }
}
@media (min-width: 1024px) {
  .header-checkout-v3 .header-empty--logo {
    width: 160px;
  }
}
.header-checkout-v3 .header-empty--logo img {
  width: 100%;
}
.header-checkout-v3 .header-empty--logo svg {
  height: 16px;
  width: 100%;
}
.header-checkout-v3 .header-action-links {
  height: 100%;
}
.header-checkout-v3 .header--cart {
  height: 100%;
  margin: 0;
}
@media (max-width: 767px) {
  .header-checkout-v3 .header--cart {
    margin-left: 16px;
    padding: 14px;
  }
}
@media (min-width: 768px) {
  .header-checkout-v3 .header--cart {
    margin-left: 40px;
    padding: 24px;
  }
}
.header-checkout-v3 .header--cart .header--cart-link {
  height: 100%;
}
@media (max-width: 767px) {
  .header-checkout-v3 .header--cart .header-inline-icon {
    height: 16px !important;
    width: 16px !important;
  }
}
.header-checkout-v3 .header--cart .cart-number {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-align: left;
}
@media (max-width: 767px) {
  .header-checkout-v3 .header--cart .cart-number {
    bottom: 20px;
    left: 16px;
  }
}
@media (min-width: 1024px) {
  .header-checkout-v3 .header--cart .cart-number {
    bottom: 26px;
    left: 22px;
  }
}
.header-checkout-v3 .cs-number--top {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
.header-checkout-v3 .cs-number--top a {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}
@media (max-width: 767px) {
  .header-checkout-v3 .cs-number--top a {
    height: 30px;
    overflow: hidden;
    padding-left: 30px;
    white-space: nowrap;
    width: 30px;
  }
}
@media (min-width: 768px) {
  .header-checkout-v3 .cs-number--top a {
    padding-left: 38px;
  }
}
.header-checkout-v3 .cs-number--top a::before {
  color: #ffffff;
  content: "\ea3b";
  font-family: "iconfont";
  font-size: 24px;
  left: 0;
  line-height: 30px;
  position: absolute;
}
@media (max-width: 767px) {
  .header-checkout-v3 .cs-number--top a::before {
    top: 0;
  }
}
@media (min-width: 768px) {
  .header-checkout-v3 .cs-number--top a::before {
    top: -6px;
  }
}
.header-checkout-v3 .cs-number--top strong {
  font-weight: bold;
}

.header-maintenance {
  align-items: center;
  background-color: #1a1919;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
}
@media (min-width: 1024px) {
  .header-maintenance {
    height: 70px;
  }
}

@media (max-width: 767px) {
  .fastlane-header.header {
    height: 104px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .fastlane-header.header {
    height: 64px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .fastlane-header .navigation-inner .nav-inner-wrapper {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .fastlane-header .header-logo.header--logo-pg {
    height: 22px;
    left: 22px;
    margin-top: 0;
    top: 68px;
    transform: translateY(0);
    width: auto;
  }
}
@media (min-width: 768px) {
  .fastlane-header .header-logo.header--logo-pg {
    height: 26px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
@media (min-width: 1025px) {
  .fastlane-header .header-logo.header--logo-pg {
    margin-right: 0;
    position: absolute;
  }
}
.fastlane-header .header-logo.header--logo-pg svg {
  width: auto;
}
.fastlane-header .header-logo .custom-logo {
  height: 100%;
}
@media (min-width: 768px) {
  .fastlane-header .header-logo .custom-logo {
    display: inline-block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}
.fastlane-header .header-logo .custom-logo img {
  height: 100%;
  width: auto;
}
@media (max-width: 767px) {
  .fastlane-header .header--bottom {
    height: 104px;
  }
}

.pt_magazine {
  line-height: 28px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.pt_magazine .mag-landing .heading-block h1 {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}

.header-shop-overlay ~ .header .nav-desktop-layer {
  top: 136px;
}
.header-shop-overlay ~ .header .nav-mobile,
.header-shop-overlay ~ .header .nav-mobile-inner,
.header-shop-overlay ~ .header .nav-mobile-layer {
  top: 120px;
}
.header-shop-overlay ~ .header .nav-desktop-layer .nav-desktop-overlay {
  top: 136px;
}

.header-shop-overlay {
  align-items: center;
  background-color: #ad4141;
  display: flex;
  justify-content: center;
  min-height: 40px;
  position: relative;
  width: 100%;
  z-index: 999;
}
@media (max-width: 767px) {
  .header-shop-overlay {
    display: block;
    text-align: center;
  }
}

.header-shop-text {
  color: #ffffff;
  font-size: 14px;
  margin: 0;
}
@media (max-width: 767px) {
  .header-shop-text {
    padding: 10px;
  }
}

.header-shop-mail {
  font-weight: bold;
}

.header-gs .fastlane-backtomain a {
  color: #1a1919;
}
.header-gs .menu-top .menu-top--item a {
  color: #1a1919;
}
.header-gs .st0 {
  fill: #1a1919;
}

.nav-mobile {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 88px;
}
.nav-mobile .nav-mobile-inner {
  -ms-overflow-style: none;
  background-color: #1a1919;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  scrollbar-width: none;
  top: 88px;
}
.nav-mobile .nav-mobile-inner::-webkit-scrollbar {
  display: none;
}
.nav-mobile .nav-mobile-main-list,
.nav-mobile .nav-mobile-additional-list {
  list-style: none;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}
.nav-mobile .nav-item {
  display: block;
  position: relative;
  width: 100%;
}
.nav-mobile .nav-item-link {
  color: #d8d8d8;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  opacity: 0;
  padding: 5px 30px 5px 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  text-indent: 20px;
  transform: translateX(0);
  transition: text-indent 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
}
.nav-mobile .nav-item-link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  display: inline-block;
  font-size: 28px;
  line-height: 1;
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
}
.nav-mobile .flyin .nav-item-link {
  opacity: 1;
  text-indent: 0;
}
.nav-mobile .flyout .nav-item-link {
  opacity: 0;
  transform: translateX(-20px);
}
@media (min-width: 768px) {
  .nav-mobile {
    display: none;
  }
}

.nav-mobile-main-list {
  padding-top: 37px;
}
.nav-mobile-main-list .nav-item {
  margin-bottom: 22px;
}
.nav-mobile-main-list .nav-item-link {
  font-size: 24px;
  letter-spacing: 1.1px;
  line-height: 32px;
}

.nav-mobile-additional-list {
  margin-top: auto;
  padding-bottom: 39px;
}
.nav-mobile-additional-list .nav-item {
  margin-top: 14px;
}
.nav-mobile-additional-list .nav-item:first-child {
  margin-top: 31px;
}
.nav-mobile-additional-list .nav-item-link {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 32px;
}

.nav-mobile-layer {
  background-color: #1a1919;
  bottom: 0;
  display: none;
  left: 0;
  overflow: auto;
  padding: 30px 0 0;
  position: fixed;
  right: 0;
  top: 80px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-mobile-layer::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nav-mobile-layer {
    top: 96px;
  }
}
.nav-mobile-layer.visible {
  z-index: 3;
}
.nav-mobile-layer.nav-mobile-first-layer .nav-mobile-layer-inner {
  height: auto;
}
.nav-mobile-layer .nav-back {
  display: block;
  font-size: 16px;
  margin: 0 0 10px 24px;
  padding: 11px 4px;
  text-align: left;
  width: calc(100% - 48px);
}
.nav-mobile-layer .nav-back::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #d8d8d8;
  content: "\ea0f";
  display: inline-block;
  line-height: 1;
}
.nav-mobile-layer .nav-mobile-layer-inner {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 11px 24px 0;
  position: relative;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-mobile-layer .nav-mobile-layer-inner::-webkit-scrollbar {
  display: none;
}
.nav-mobile-layer .nav-mobile-subnav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  padding-top: 60px;
}
.nav-mobile-layer .nav-mobile-subnav li {
  width: 100%;
}
.nav-mobile-layer .nav-mobile-subnav .nav-mobile-image-item-narow {
  width: calc(50% - 12px);
}
.nav-mobile-layer .nav-mobile-subnav .nav-mobile-image-item-narow .nav-mobile-subnav-image {
  padding-bottom: 100%;
}
.nav-mobile-layer .nav-mobile-subnav .nav-mobile-image-item-narow .nav-mobile-subnav-title {
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  padding: 0 10px;
}
.nav-mobile-layer .nav-content-links + li:not(.nav-content-links) {
  margin-top: 40px;
}
.nav-mobile-layer .nav-mobile-subnav-item-shop-all {
  min-height: 24px;
}
@media (max-width: 767px) {
  .nav-mobile-layer .nav-mobile-subnav-item-shop-all {
    margin-top: -62px;
  }
}
.nav-mobile-layer .nav-mobile-subnav-item-shop-all .nav-mobile-section-title {
  margin-top: 15px;
}
.nav-mobile-layer .nav-mobile-subnav-link,
.nav-mobile-layer .mobile-third-level a {
  color: #d8d8d8;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  opacity: 0;
  padding: 18px 0 19px;
  text-align: left;
  text-decoration: none;
  text-indent: 20px;
  transition: text-indent 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  transform: translate3d(0, 0, 0);
  width: 100%;
}
.nav-mobile-layer .nav-mobile-subnav-link {
  position: relative;
}
.nav-mobile-layer .nav-mobile-subnav-link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #d8d8d8;
  content: "\ea1b";
  display: inline-block;
  font-size: 30px;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.nav-mobile-layer .nav-mobile-subnav-shop-all,
.nav-mobile-layer .nav-mobile-subnav-shop-link {
  color: #aeaeae;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 24px;
  padding: 0;
}
.nav-mobile-layer .nav-mobile-subnav-shop-all::after,
.nav-mobile-layer .nav-mobile-subnav-shop-link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  font-size: 17px;
  position: relative;
  top: auto;
  transform: none;
}
.nav-mobile-layer .nav-mobile-subnav-image-wrap {
  display: block;
  margin-bottom: 38px;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  transform: translate3d(0, 0, 0);
  width: 100%;
}
.nav-mobile-layer .flyin > .nav-mobile-subnav-link,
.nav-mobile-layer .flyin > .nav-mobile-subnav-image-wrap,
.nav-mobile-layer .mobile-third-level .flyin > a,
.nav-mobile-layer .flyin > .nav-mobile-section-title {
  opacity: 1;
  text-indent: 0;
}
.nav-mobile-layer .flyin > .nav-mobile-subnav-link .nav-mobile-subnav-image,
.nav-mobile-layer .flyin > .nav-mobile-subnav-link .nav-mobile-subnav-title,
.nav-mobile-layer .flyin > .nav-mobile-subnav-link .nav-mobile-subnav-shop-link,
.nav-mobile-layer .flyin > .nav-mobile-subnav-image-wrap .nav-mobile-subnav-image,
.nav-mobile-layer .flyin > .nav-mobile-subnav-image-wrap .nav-mobile-subnav-title,
.nav-mobile-layer .flyin > .nav-mobile-subnav-image-wrap .nav-mobile-subnav-shop-link,
.nav-mobile-layer .mobile-third-level .flyin > a .nav-mobile-subnav-image,
.nav-mobile-layer .mobile-third-level .flyin > a .nav-mobile-subnav-title,
.nav-mobile-layer .mobile-third-level .flyin > a .nav-mobile-subnav-shop-link,
.nav-mobile-layer .flyin > .nav-mobile-section-title .nav-mobile-subnav-image,
.nav-mobile-layer .flyin > .nav-mobile-section-title .nav-mobile-subnav-title,
.nav-mobile-layer .flyin > .nav-mobile-section-title .nav-mobile-subnav-shop-link {
  transform: translateX(0);
  visibility: visible;
}
.nav-mobile-layer .flyout > .nav-mobile-subnav-link,
.nav-mobile-layer .flyout > .nav-mobile-subnav-image-wrap,
.nav-mobile-layer .mobile-third-level .flyout > a,
.nav-mobile-layer .flyout > .nav-mobile-section-title {
  opacity: 0;
  transform: translateX(-20px);
}
.nav-mobile-layer .flyout > .nav-mobile-subnav-link .nav-mobile-subnav-image,
.nav-mobile-layer .flyout > .nav-mobile-subnav-image-wrap .nav-mobile-subnav-image,
.nav-mobile-layer .mobile-third-level .flyout > a .nav-mobile-subnav-image,
.nav-mobile-layer .flyout > .nav-mobile-section-title .nav-mobile-subnav-image {
  transform: translateX(20px);
}
.nav-mobile-layer .flyout > .nav-mobile-subnav-link .nav-mobile-subnav-title,
.nav-mobile-layer .flyout > .nav-mobile-subnav-link .nav-mobile-subnav-shop-link,
.nav-mobile-layer .flyout > .nav-mobile-subnav-image-wrap .nav-mobile-subnav-title,
.nav-mobile-layer .flyout > .nav-mobile-subnav-image-wrap .nav-mobile-subnav-shop-link,
.nav-mobile-layer .mobile-third-level .flyout > a .nav-mobile-subnav-title,
.nav-mobile-layer .mobile-third-level .flyout > a .nav-mobile-subnav-shop-link,
.nav-mobile-layer .flyout > .nav-mobile-section-title .nav-mobile-subnav-title,
.nav-mobile-layer .flyout > .nav-mobile-section-title .nav-mobile-subnav-shop-link {
  transform: translateX(-20px);
}
.nav-mobile-layer .nav-mobile-subnav-image {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 0;
  transform: translateX(20px);
  padding-bottom: 56.27%;
  position: relative;
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  visibility: hidden;
}
.nav-mobile-layer .nav-mobile-subnav-image::after {
  background: linear-gradient(180deg, rgba(26, 25, 25, 0) 0%, #1a1919 100%), linear-gradient(180deg, rgba(26, 25, 25, 0) 0%, #1a1919 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 39px;
  left: 0;
  position: absolute;
  right: 0;
}
.nav-mobile-layer .nav-mobile-subnav-title {
  color: #ffffff;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 32px;
  margin-bottom: 2px;
  text-decoration: none;
  transform: translateX(20px);
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: hidden;
}
.nav-mobile-layer .nav-mobile-subnav-shop-link {
  display: block;
  transform: translateX(20px);
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: hidden;
}
.nav-mobile-layer .nav-mobile-subnav-shop-link::after {
  margin-left: 5px;
}
.nav-mobile-layer .nav-mobile-section-title {
  color: #b8a188;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 11px;
  letter-spacing: 1.6px;
  line-height: 16px;
  margin: 27px 0 23px;
  opacity: 0;
  text-indent: 20px;
  text-transform: uppercase;
  transition: text-indent 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  transform: translate3d(0, 0, 0);
}
.nav-mobile-layer .nav-mobile-text-item .nav-mobile-subnav-link {
  padding: 16px 0;
}
.nav-mobile-layer .nav-mobile-text-item .nav-mobile-subnav-link::after {
  margin-top: 2px;
  right: -4px;
}
.nav-mobile-layer .nav-mobile-text-item + li .nav-mobile-section-title {
  margin: 54px 0 10px;
}
.nav-mobile-layer .nav-additional-text {
  color: #767676;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 1px;
  margin-left: 2px;
  text-transform: uppercase;
}
.nav-mobile-layer .mobile-third-level ul {
  list-style: none;
}
.nav-mobile-layer .navigation-banner-item {
  margin: 20px 0;
}
.nav-mobile-layer .navigation-banner-item .nav-content-banner {
  transform: translateX(20px);
}
.nav-mobile-layer .navigation-banner-item .nav-content-banner.nav-content-banner-cta .nav-content-banner-inner {
  padding: 22px 50px 20px 90px;
}
.nav-mobile-layer .navigation-banner-item .nav-content-banner.nav-content-banner-cta .icon {
  top: 30px;
}
.nav-mobile-layer .navigation-banner-item.flyin .nav-content-banner {
  opacity: 1;
  transform: translateX(0);
}
.nav-mobile-layer .nav-content-links button {
  display: none;
}
.nav-mobile-layer .nav-content-links .nav-desktop-subnav-wrap {
  display: none !important;
}

.nav-mobile-collections {
  list-style: none;
  padding-right: 10px;
  width: 100%;
}
.nav-mobile-collections li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}
.nav-mobile-collections li.flyin .nav-mobile-collections-link {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
.nav-mobile-collections .nav-mobile-collections-link {
  color: #ffffff;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateX(20px);
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: calc(50% - 10px);
}
.nav-mobile-collections .image-holder {
  display: block;
  margin-bottom: 12px;
}
.nav-mobile-collections img {
  display: block;
  width: 100%;
}
.nav-mobile-collections .nav-collection-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 0;
  padding-bottom: 100%;
}
.nav-mobile-collections .nav-mobile-ritual-title {
  display: block;
  font-size: 8px;
  letter-spacing: 2px;
  line-height: 10px;
  margin-bottom: 5px;
}
.nav-mobile-collections .nav-mobile-collection-title {
  display: block;
  font-size: 13px;
  letter-spacing: 2.8px;
  line-height: 16px;
  margin-bottom: 7px;
}
.nav-mobile-collections .nav-mobile-label {
  color: #666;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 12px;
}

.nav-desktop {
  flex-shrink: 0;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (max-width: 1024px) {
  .nav-desktop {
    display: none;
  }
}
.nav-desktop.fade-nav {
  opacity: 0;
  left: 190px;
  position: absolute;
}
@media (min-width: 1440px) {
  .nav-desktop.fade-nav {
    left: 340px;
  }
}
@media (min-width: 1440px) {
  .nav-desktop-list {
    display: flex;
  }
}
.nav-desktop .nav-item {
  display: inline-block;
  list-style-type: none;
}
.nav-desktop .nav-item:first-of-type .nav-item-link {
  padding-left: 0;
}
@media (min-width: 1440px) {
  .nav-desktop .nav-item:first-child .nav-item-link::after {
    left: 0;
    width: calc(100% - 15px);
  }
}
@media (min-width: 1440px) {
  .nav-desktop .nav-item:not(:first-child) .nav-item-link::after {
    left: 16px;
    width: calc(100% - 30px);
  }
}
.nav-desktop .nav-item-link {
  color: #d8d8d8;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  position: relative;
  padding: 0 12px;
  height: 100%;
  text-decoration: none;
  z-index: 4;
}
@media (min-width: 1440px) {
  .nav-desktop .nav-item-link {
    padding: 0 16px;
  }
}
.nav-desktop .nav-item-link.disabled {
  pointer-events: none;
}
.nav-desktop .nav-item-link-name {
  background-image: linear-gradient(transparent calc(100% - 1px), white 1px);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  display: block;
  height: 100%;
  padding: 20px 0;
  position: relative;
  transition: background-size 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (min-width: 1440px) {
  .nav-desktop .nav-item-link-name {
    padding: 20px 0;
  }
}
.nav-desktop .nav-item-link-name.selected, .nav-desktop .nav-item-link-name:hover {
  background-size: 100% 100%;
  color: #ffffff;
}
.nav-desktop .nav-item-link-name.selected::after, .nav-desktop .nav-item-link-name:hover::after {
  background-color: #ffffff;
  width: calc(100% - 19px);
}

.nav-desktop-layer {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  top: 112px;
  width: 100%;
  z-index: 3;
}
@media (min-width: 2300px) {
  .nav-desktop-layer {
    top: 136px;
  }
}
.nav-desktop-layer.visible .nav-first-layer {
  left: 0;
}
@media (min-width: 1440px) {
  .nav-desktop-layer.visible .nav-first-layer {
    left: auto;
    right: calc(50% + 413px);
  }
}
.nav-desktop-layer.opened .nav-first-layer {
  transition: left 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (min-width: 1440px) {
  .nav-desktop-layer.opened .nav-first-layer {
    transition: right 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
.nav-desktop-layer.top-gradient > .nav-first-layer::before, .nav-desktop-layer.bottom-gradient > .nav-first-layer > .bottom-gradient-el {
  opacity: 1;
  visibility: visible;
}
.nav-desktop-layer .nav-desktop-overlay {
  background-color: rgba(26, 25, 25, 0.6);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 112px;
  z-index: 1;
}
@media (min-width: 2300px) {
  .nav-desktop-layer .nav-desktop-overlay {
    top: 136px;
  }
}
.nav-desktop-layer .close-desktop-nav {
  color: #ffffff;
  display: none;
  font-size: 22px;
  position: absolute;
  right: 24px;
  top: 22px;
  z-index: 4;
}
.nav-desktop-layer .nav-first-layer {
  background-color: #1a1919;
  bottom: 0;
  left: -50%;
  position: absolute;
  top: 0;
  transition: left 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 307px;
  z-index: 2;
}
@media (min-width: 1440px) {
  .nav-desktop-layer .nav-first-layer {
    left: auto;
    right: 100%;
    transition: right 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}
.nav-desktop-layer .nav-first-layer::before,
.nav-desktop-layer .nav-first-layer .bottom-gradient-el {
  content: "";
  display: inline-block;
  height: 100px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: hidden;
  z-index: 2;
}
.nav-desktop-layer .nav-first-layer::before {
  background: linear-gradient(to top, rgba(26, 25, 25, 0) 0%, #1a1919 100%);
  top: 0;
}
.nav-desktop-layer .nav-first-layer .bottom-gradient-el {
  background: linear-gradient(to bottom, rgba(26, 25, 25, 0) 0%, #1a1919 100%);
  bottom: 0;
}
@media (min-width: 1440px) {
  .nav-desktop-layer .nav-first-layer::after {
    background-color: #1a1919;
    bottom: 0;
    content: "";
    display: inline-block;
    position: absolute;
    right: 100%;
    top: 0;
    width: 2000em;
  }
}
.nav-desktop-layer .nav-first-layer.first-opening .nav-desktop-subnav-slide-in {
  right: 100%;
  transition: right 1s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.nav-desktop-layer .nav-first-layer.first-opening .slide-in .nav-desktop-subnav-slide-in {
  right: 0;
}
.nav-desktop-layer .nav-first-layer.first-opening .nav-desktop-subnav-wrap.opened .nav-desktop-subnav-slide-in {
  transition: right 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.nav-desktop-layer .nav-desktop-layer-inner {
  max-height: 100%;
  overflow: auto;
  padding: 0 40px 0 11px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-desktop-layer .nav-desktop-layer-inner::-webkit-scrollbar {
  display: none;
}
.nav-desktop-layer .nav-inner-subnav,
.nav-desktop-layer .nav-mobile-subnav {
  padding: 124px 0 60px 20px;
}
.nav-desktop-layer .nav-inner-subnav.nav-inner-subnav-no-title,
.nav-desktop-layer .nav-mobile-subnav.nav-inner-subnav-no-title {
  padding-top: 60px;
}
.nav-desktop-layer .nav-inner-subnav li,
.nav-desktop-layer .nav-mobile-subnav li {
  display: block;
  list-style-type: none;
  margin-bottom: 8px;
}
.nav-desktop-layer .nav-inner-subnav li:first-child .nav-desktop-section-title,
.nav-desktop-layer .nav-mobile-subnav li:first-child .nav-desktop-section-title {
  margin-top: 0;
}
.nav-desktop-layer .nav-inner-subnav .nav-desktop-shopall-item,
.nav-desktop-layer .nav-mobile-subnav .nav-desktop-shopall-item {
  margin-top: -60px;
}
.nav-desktop-layer .nav-inner-subnav .nav-desktop-shopall-item + li .nav-desktop-section-title,
.nav-desktop-layer .nav-mobile-subnav .nav-desktop-shopall-item + li .nav-desktop-section-title {
  margin-top: 0;
}
.nav-desktop-layer .nav-content-links .nav-mobile-subnav-link::after {
  background-color: #ffffff;
  bottom: 3px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 0;
}
.nav-desktop-layer .nav-content-links .nav-mobile-subnav-link .nav-additional-text {
  bottom: 2px;
  left: 100%;
  margin-left: 8px;
  position: absolute;
}
.nav-desktop-layer .nav-content-links .nav-mobile-subnav-link .nav-additional-text::after {
  display: none;
}
.nav-desktop-layer .nav-content-links + li:not(.nav-content-links) {
  margin-top: 16px;
}
.nav-desktop-layer .nav-mobile-subnav-link {
  color: #aeaeae;
  display: inline-block;
  line-height: 24px;
  opacity: 0;
  text-align: left;
  text-decoration: none;
  transform: translateX(-20px);
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), color 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: hidden;
}
.nav-desktop-layer .nav-mobile-subnav-link:not(.nav-desktop-shopall-link) {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.3px;
}
.nav-desktop-layer .nav-mobile-subnav-link.nav-desktop-shopall-link {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.8px;
  margin-bottom: 29px;
}
.nav-desktop-layer .nav-mobile-subnav-link.nav-desktop-shopall-link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  margin: -1px 0 0 0;
}
.nav-desktop-layer .nav-mobile-subnav-link.nav-desktop-section-title {
  color: #b8a188;
  display: block;
  font-size: 11px;
  letter-spacing: 1.6px;
  line-height: 16px;
  margin: 40px 0 16px;
  text-transform: uppercase;
}
.nav-desktop-layer .nav-mobile-subnav-link.nav-desktop-section-title:hover {
  color: #b8a188;
}
.nav-desktop-layer .nav-mobile-subnav-link span {
  display: inline-block;
  position: relative;
}
.nav-desktop-layer .nav-mobile-subnav-link span::after {
  background-color: #ffffff;
  bottom: 3px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 0;
}
.nav-desktop-layer .nav-mobile-subnav-link.flyin {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
.nav-desktop-layer .nav-mobile-subnav-link.active {
  color: #ffffff;
}
.nav-desktop-layer .nav-mobile-subnav-link.active span::after {
  width: 100%;
}
.nav-desktop-layer .nav-mobile-subnav-link.disabled {
  pointer-events: none;
}
.nav-desktop-layer .nav-content-links .nav-mobile-subnav-link:hover,
.nav-desktop-layer .nav-mobile-subnav-link:hover {
  color: #ffffff;
}
.nav-desktop-layer .nav-content-links .nav-mobile-subnav-link:hover span::after,
.nav-desktop-layer .nav-mobile-subnav-link:hover span::after {
  width: 100%;
}
.nav-desktop-layer .nav-additional-text {
  line-height: 26px;
}

.nav-desktop-subnav-wrap {
  bottom: 0;
  display: none;
  left: 307px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 448px;
  z-index: 3;
}
.nav-desktop-subnav-wrap.opened .nav-desktop-subnav-slide-in {
  transition: right 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in {
  background-color: #222;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in .nav-desktop-subnav-arrow {
  bottom: 20px;
  font-size: 32px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: visible;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in .nav-desktop-subnav-arrow::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea19";
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in.bottom-gradient .nav-desktop-subnav-arrow {
  opacity: 1;
  visibility: visible;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in.sticky-content-banner .nav-desktop-subnav {
  padding-bottom: 116px;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in.sticky-content-banner .navigation-banner-item {
  bottom: 0;
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-slide-in.sticky-content-banner .nav-content-banner {
  margin-left: 0;
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-inner {
  height: 100%;
  overflow: auto;
  padding: 60px 60px 20px;
  position: relative;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-inner::-webkit-scrollbar {
  display: none;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 65px;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-details li {
  margin-bottom: 38px;
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-details .nav-desktop-shopall-item {
  left: auto;
  margin: -65px 0 8px 0;
  min-height: 56px;
  position: relative;
  top: auto;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-details .nav-desktop-subpanel-narow {
  margin-bottom: 38px;
  width: 46.4%;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-details .nav-desktop-subpanel-narow .nav-desktop-subpanel-image {
  padding-bottom: 100%;
}
@media (min-width: 768px) {
  .nav-desktop-subnav-wrap .nav-desktop-subpanel-details .nav-desktop-subpanel-narow .nav-desktop-subpanel-image {
    width: 152px;
  }
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-details .nav-desktop-subpanel-narow .nav-desktop-subpanel-title {
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all {
  color: #999999;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
  margin-bottom: 37px;
  text-decoration: none;
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1e";
  display: inline-block;
  font-size: 12px;
  margin: -2px 0 0 20px;
  transform: rotate(-180deg);
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all .lowercase {
  text-transform: lowercase;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-link {
  color: #999999;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 0.7px;
  line-height: 21px;
  margin-bottom: 14px;
  text-decoration: none;
}
.nav-desktop-subnav-wrap .nav-desktop-subtitle {
  color: #b4b4b4;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 10px;
  letter-spacing: 3px;
  line-height: 12px;
  margin: 38px 0 8px;
  text-transform: uppercase;
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all,
.nav-desktop-subnav-wrap .nav-desktop-subnav-link,
.nav-desktop-subnav-wrap .nav-desktop-subtitle {
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  visibility: hidden;
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all span:not(.lowercase),
.nav-desktop-subnav-wrap .nav-desktop-subnav-link span:not(.lowercase),
.nav-desktop-subnav-wrap .nav-desktop-subtitle span:not(.lowercase) {
  position: relative;
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all span:not(.lowercase)::after,
.nav-desktop-subnav-wrap .nav-desktop-subnav-link span:not(.lowercase)::after,
.nav-desktop-subnav-wrap .nav-desktop-subtitle span:not(.lowercase)::after {
  background-color: #ffffff;
  bottom: -6px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 0;
}
.nav-desktop-subnav-wrap .nav-desktop-shop-all.flyin,
.nav-desktop-subnav-wrap .nav-desktop-subnav-link.flyin,
.nav-desktop-subnav-wrap .nav-desktop-subtitle.flyin {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
.nav-desktop-subnav-wrap .navigation-banner-item {
  margin: 26px 0 -20px -40px;
  width: calc(100% + 70px);
}
.nav-desktop-subnav-wrap .navigation-banner-item.flyin .nav-content-banner {
  opacity: 1;
  transform: translateX(0);
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image-wrap {
  display: block;
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image-wrap:hover + .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link {
  color: #ffffff;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image-wrap:hover + .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link span::after {
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image-wrap:hover + .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title {
  color: #ffffff;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image-wrap:hover + .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title span {
  background-size: 100% 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 0;
  padding-bottom: 66.467%;
  position: relative;
}
@media (min-width: 768px) {
  .nav-desktop-subnav-wrap .nav-desktop-subpanel-image {
    width: 328px;
  }
}
.nav-desktop-subnav-wrap .nav-desktop-subpanel-image::after {
  background: linear-gradient(180deg, rgba(36, 34, 34, 0) 0.1%, #242222 100%), linear-gradient(180deg, rgba(36, 34, 34, 0) 0.1%, #242222 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 40px;
  left: 0;
  position: absolute;
  right: 0;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title {
  color: #aeaeae;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 32px;
  margin-bottom: 2px;
  text-decoration: none;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title span {
  background-image: linear-gradient(transparent calc(100% - 1px), #ffffff 1px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title:hover {
  color: #ffffff;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title:hover span {
  background-size: 100% 100%;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title:hover ~ .nav-desktop-subpanel-link {
  color: #ffffff;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-title:hover ~ .nav-desktop-subpanel-link span::after {
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-text {
  color: #aeaeae;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 16px;
  text-decoration: none;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-text:hover ~ .nav-desktop-subpanel-link {
  color: #ffffff;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-text:hover ~ .nav-desktop-subpanel-link span::after {
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link {
  color: #aeaeae;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 24px;
  text-decoration: none;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  font-size: 17px;
  margin-left: 3px;
  position: relative;
  top: auto;
  transform: none;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link span {
  display: inline-block;
  position: relative;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link span::after {
  background-color: #ffffff;
  bottom: 3px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 0;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link:hover {
  color: #ffffff;
}
.nav-desktop-subnav-wrap .nav-subpanel-flyin-wrap .nav-desktop-subpanel-link:hover span::after {
  width: 100%;
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-flyin {
  max-width: 100%;
  opacity: 0;
  transform: translateX(-20px);
  transition: color 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.nav-desktop-subnav-wrap .nav-desktop-subnav-flyin.flyin {
  opacity: 1;
  transform: translateX(0);
}
@media (min-width: 1025px) {
  .nav-desktop-subnav-wrap .nav-dekstop-subnav-link {
    margin-bottom: 18px;
  }
  .nav-desktop-subnav-wrap .nav-desktop-shop-all:hover span::after,
  .nav-desktop-subnav-wrap .nav-desktop-subnav-link:hover span::after {
    width: 100%;
  }
  .nav-desktop-subnav-wrap .navigation-banner-item {
    margin-left: 0;
    width: 100;
  }
}

.nav-content-banner-wrapper {
  display: flex;
  flex-direction: row;
}

.nav-content-banner {
  background-color: #292828;
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
}
@media (min-width: 1025px) {
  .nav-content-banner {
    margin-left: -60px;
    width: calc(100% + 120px);
  }
}
.nav-content-banner .nav-content-banner-inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 104px;
  padding: 25px 25px 22px 80px;
  position: relative;
  text-decoration: none;
  width: 100%;
}
.nav-content-banner .nav-content-banner-inner:hover .link::before {
  width: 100%;
}
.nav-content-banner .icon-holder {
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
}
.nav-content-banner .icon {
  display: inline-block;
  vertical-align: middle;
}
.nav-content-banner .text {
  color: #ffffff;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-bottom: 9px;
  width: 100%;
}
.nav-content-banner .link {
  color: #b8a188;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 24px;
  margin-right: 22px;
  position: relative;
  text-decoration: none;
}
.nav-content-banner .link::before {
  background-color: #b8a188;
  bottom: 3px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: width 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 0;
}
.nav-content-banner .link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  font-size: 20px;
  margin-left: 2px;
  position: absolute;
  right: -23px;
  top: 0;
}

.navigation-content-links-placeholder {
  display: none;
}

@media (max-width: 767px) {
  .fastlane-header .navigation-wrapper .navigation-inner {
    display: block;
    height: 104px;
    padding-top: 56px;
  }
}
@media (max-width: 767px) {
  .fastlane-header .navigation-wrapper .navigation-inner .nav-inner-wrapper {
    border-bottom: 1px solid #363636;
    border-top: 1px solid #363636;
    height: 48px;
    padding-left: 0;
  }
}

.fastlane-backtomain {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .fastlane-backtomain {
    height: 30px;
    margin-top: -31px;
    padding-left: 20px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .fastlane-backtomain {
    height: 80px;
  }
}
@media (min-width: 1025px) {
  .fastlane-backtomain {
    margin-right: 20px;
  }
}
.fastlane-backtomain a {
  color: #ffffff;
  font-size: 16px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .fastlane-backtomain a {
    font-size: 12px;
    letter-spacing: 0.4px;
  }
}
@media (min-width: 768px) {
  .fastlane-backtomain a {
    text-decoration: none;
  }
}
@media (min-width: 768px) {
  .fastlane-backtomain a::before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea1a";
    display: inline-block;
    font-size: 32px;
    line-height: 19px;
    width: 30px;
  }
}
.fastlane-backtomain em {
  font-style: italic;
}

.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.footer--empty .footer-bottom-elements .accessibility-statement-link {
  display: block;
}

.main-footer {
  position: relative;
  background-color: #1a1919;
}

@media (max-width: 767px) {
  .footer-small .footer-left-side {
    padding-top: 33px;
  }
}
@media (max-width: 767px) {
  .footer-small .accordion-item:first-of-type .accordion-head {
    border-top: 1px solid #999999;
  }
}
.footer-small .footer-middle-elements .inner-wrapper::before {
  display: none;
}

.footer-top-elements {
  background-color: #1a1919;
  padding: 33px 0 0;
}
@media (min-width: 768px) {
  .footer-top-elements {
    padding: 25px 40px 0;
  }
}
@media (min-width: 1024px) {
  .footer-top-elements {
    padding: 29px 96px 0;
  }
}
@media (min-width: 768px) {
  .footer-top-elements .footer-accordion {
    display: inline-block;
    width: auto;
  }
  .footer-top-elements .footer-accordion > .accordion-item {
    width: auto;
  }
  .footer-top-elements .footer-accordion > .accordion-item .accordion-content {
    display: block;
    max-width: 1000px;
  }
}
.footer-top-elements .accordion-head {
  display: none;
}
@media (max-width: 767px) {
  .footer-top-elements .accordion-head {
    display: block;
  }
}
@media (min-width: 768px) {
  .footer-top-elements .accordion-inner {
    width: 145px;
  }
}
@media (min-width: 1024px) {
  .footer-top-elements .accordion-inner {
    width: 195px;
  }
}

.footer-title {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1px;
  text-transform: none;
  color: #ffffff;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .footer-title {
    display: none;
  }
}

.footer-accordion::before {
  left: 15px;
  right: 15px;
}
@media (min-width: 768px) {
  .footer-accordion {
    padding-bottom: 12px;
  }
  .footer-accordion::before {
    display: none;
  }
}
@media (min-width: 768px) {
  .footer-accordion .accordion-item,
  .footer-accordion .accordion-inner {
    display: inline-block;
    padding: 0 20px 0 0;
    margin-bottom: 6px;
  }
}
@media (min-width: 1024px) {
  .footer-accordion .accordion-item,
  .footer-accordion .accordion-inner {
    padding: 0 40px 0 0;
  }
}
.footer-accordion .accordion-item {
  padding: 0 15px;
}
@media (min-width: 768px) {
  .footer-accordion .accordion-item {
    width: auto;
  }
}
@media (min-width: 768px) {
  .footer-accordion .accordion-inner {
    margin-top: 10px;
    vertical-align: top;
  }
  .footer-accordion .accordion-inner:nth-child(n+6) {
    max-height: 0;
    visibility: hidden;
    transition: 0.5s ease-in-out;
    margin: 0;
  }
  .footer-accordion .accordion-inner:nth-child(n+6).visible {
    max-height: 500px;
    visibility: visible;
    margin: 10px 0 6px;
  }
  .footer-accordion .accordion-inner:nth-child(n+6).visible .accordion-head-inner,
  .footer-accordion .accordion-inner:nth-child(n+6).visible .accordion-content-inner {
    opacity: 1;
  }
  .footer-accordion .accordion-inner:nth-child(n+6) .accordion-head-inner,
  .footer-accordion .accordion-inner:nth-child(n+6) .accordion-content-inner {
    opacity: 0;
    transition: 0.6s;
  }
}
.footer-accordion .accordion-head.open {
  border-bottom-color: #999999;
}
@media (min-width: 768px) {
  .footer-accordion .accordion-head {
    pointer-events: none;
    font-size: 15px;
    letter-spacing: 0.75px;
    line-height: 18px;
    color: #ffffff;
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    text-transform: none;
    border: 0;
    padding: 0;
    margin-bottom: 16px;
  }
  .footer-accordion .accordion-head::after {
    display: none;
  }
}
.footer-accordion .accordion-head-inner {
  margin: 15px 0 5px;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 18px;
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
@media (min-width: 768px) {
  .footer-accordion .accordion-head-inner {
    pointer-events: none;
    margin: 0 0 13px;
  }
}
.footer-accordion .accordion-content {
  padding: 21px 0 23px;
}
@media (min-width: 768px) {
  .footer-accordion .accordion-content {
    display: flex;
    border: 0;
    padding: 0;
  }
}
.footer-accordion .accordion-content li {
  padding: 6px 0;
}
.footer-accordion .accordion-content-inner {
  border: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .footer-accordion .accordion-content-inner {
    display: block;
    padding: 5px 0 25px;
  }
}
.footer-accordion .accordion-content-inner ul {
  list-style: none;
}
.footer-accordion .accordion-content-inner li:nth-child(n+4) {
  max-height: 0;
  visibility: hidden;
  transition: 0.5s ease-in-out;
  padding: 0;
}
.footer-accordion .accordion-content-inner li:nth-child(n+4).visible {
  max-height: 500px;
  visibility: visible;
  padding: 6px 0;
}
.footer-accordion .accordion-content-inner li:nth-child(n+4).visible a {
  opacity: 1;
}
.footer-accordion .accordion-content-inner li:nth-child(n+4) a {
  opacity: 0;
  transition: 0.6s ease;
}
.footer-accordion .accordion-link {
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 18px;
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-decoration: none;
}
.footer-accordion .accordion-link:hover, .footer-accordion .accordion-link:focus {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .footer-accordion .accordion-link {
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 15px;
    color: #a4a4a4;
  }
}
.footer-accordion .accordion-link-inner {
  display: block;
  color: #a4a4a4;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.7px;
  text-decoration: none;
}
.footer-accordion .accordion-link-inner:hover, .footer-accordion .accordion-link-inner:focus {
  text-decoration: underline;
}

.social--icons {
  z-index: 10;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
  display: none;
}
@media (min-width: 768px) {
  .social--icons {
    display: block;
  }
}
@media (max-width: 767px) {
  .social--icons {
    max-width: 255px;
    margin: 30px auto 0;
  }
}
.social--icons.social--icons-mobile {
  display: none;
}
@media (max-width: 767px) {
  .social--icons.social--icons-mobile {
    display: block;
  }
}
.social--icons.social--icons-mobile .social__icons {
  padding-top: 37px;
}

.social__icons {
  display: flex;
  flex-direction: row;
  padding-top: 22px;
  list-style: none;
  background-color: #1a1919;
}
.social__icons:has(.icon-enlarge) {
  padding-top: 36px;
}
@media (max-width: 767px) {
  .social__icons {
    justify-content: center;
  }
}
.social__icons li:not(:last-of-type) {
  margin-right: 29px;
}
.social__icons li:not(:last-of-type):has(.icon-enlarge) {
  margin-right: 10px;
}
.social__icons a {
  font-size: 14px;
  letter-spacing: 0.7px;
  text-decoration: none;
  color: #a4a4a4;
}
.social__icons a span {
  font-size: 15px;
  line-height: 15px;
}
.social__icons a span.icon-enlarge {
  font-size: 40px;
}

@media (min-width: 768px) {
  .contact-column-b2b {
    padding-top: 100px;
  }
}

.footer-middle-elements {
  background-color: #1a1919;
  padding: 0 0 41px;
  margin-top: -1px;
  transition: 0.6s ease;
}
@media (min-width: 768px) {
  .footer-middle-elements {
    padding: 0 40px 55px;
    margin-top: -29px;
  }
  .footer-middle-elements.slide {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .footer-middle-elements {
    padding: 0 96px 55px;
  }
}
@media (max-width: 767px) {
  .footer-middle-elements .accordion-wrapper::before {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer-middle-elements .onboarding-page-wrapper {
    padding-top: 48px;
  }
}
.footer-middle-elements .onboarding-page-wrapper .accordion-wrapper {
  display: none;
}
.footer-middle-elements .onboarding-page-wrapper .footer-contact {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .footer-middle-elements .onboarding-page-wrapper .footer-logo {
    align-items: flex-end;
    bottom: unset;
    justify-content: unset;
    margin-top: 28px;
    position: relative;
    right: unset;
  }
}
@media (min-width: 768px) {
  .footer-middle-elements .onboarding-page-wrapper .inner-wrapper::before {
    height: 0;
  }
}
.footer-middle-elements .accordion-content li {
  margin: 0;
  padding: 0;
}
@media (max-width: 767px) {
  .footer-middle-elements .accordion-content li {
    padding: 5px 0;
  }
}
@media (min-width: 768px) {
  .footer-middle-elements .accordion-item {
    width: 145px;
    vertical-align: top;
  }
}
@media (min-width: 1024px) {
  .footer-middle-elements .accordion-item {
    width: 195px;
  }
}
.footer-middle-elements .column-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .footer-middle-elements .column-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .footer-middle-elements .inner-wrapper {
    padding: 35px 20px 0;
    display: flex;
    position: relative;
  }
  .footer-middle-elements .inner-wrapper::before {
    content: "";
    width: calc(100% - 40px);
    height: 1px;
    position: absolute;
    left: 20px;
    top: 0;
    background-color: #979797;
    display: block;
  }
}
.footer-middle-elements .wrapper__country-selector {
  display: flex;
  flex-direction: column;
}
.footer-middle-elements .wrapper__country-selector label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 350;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: left;
  text-transform: lowercase;
}
.footer-middle-elements .wrapper__country-selector label::first-letter {
  text-transform: capitalize;
}
.footer-middle-elements .wrapper__country-selector .country-selector {
  position: relative;
}
.footer-middle-elements .wrapper__country-selector .country-selector .arrow-down {
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 12px;
}
.footer-middle-elements .wrapper__country-selector .country-selector .arrow-down i {
  color: #1a1919;
  font-size: 24px;
}
.footer-middle-elements .wrapper__country-selector .country-selector .arrow-down i:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
}
@media (min-width: 1024px) {
  .footer-middle-elements .wrapper__country-selector .country-selector .arrow-down {
    display: none;
  }
}
.footer-middle-elements .wrapper__country-selector a {
  color: #b8a188;
  display: inline-block;
  font-size: 12px;
  font-weight: 350;
  letter-spacing: 2px;
  line-height: 16px;
  margin-top: 32px;
  text-transform: uppercase;
}
.footer-middle-elements .wrapper__country-selector .select-box {
  appearance: none;
  background-color: #242222;
  background-position: 16px 15px;
  background-size: 24px 18px;
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0.6px;
  line-height: 24px;
  overflow: hidden;
  padding: 12px 48px 12px 56px;
  width: 100%;
}
.footer-middle-elements .wrapper__country-selector .select-box.language {
  padding-left: 16px;
}
.footer-middle-elements .wrapper__country-selector .select-box__container:first-of-type {
  margin-bottom: 16px;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper ~ .arrow-down {
  display: none;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric {
  background: #242222;
  border: 0;
  padding: 12px 48px 12px 56px;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric .label {
  color: #ffffff;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0.6px;
  line-height: 24px;
  padding: 0;
  text-transform: none;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric .button::before {
  display: none;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric .button::after {
  border-color: #1a1919;
  top: 12px;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-hide-select {
  height: auto;
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 15px;
  width: 24px;
  z-index: 1;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-hide-select .language {
  display: none;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-hide-select .select-box {
  background-position: left top;
  background-size: 24px auto;
  border-bottom: 0;
  float: left;
  font-size: 0;
  height: 18px;
  padding: 0;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-items ul li {
  padding: 17px 38px 17px 20px;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-items ul li.selected, .footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-items ul li:hover {
  position: relative;
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-items ul li.selected::after, .footer-middle-elements .wrapper__country-selector .selectric-wrapper .selectric-items ul li:hover::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #886655;
  content: "\ea2f";
  display: inline-block;
  font-size: 12px;
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
}
.footer-middle-elements .wrapper__country-selector .selectric-wrapper.selectric-language .selectric {
  padding-left: 16px;
}
.footer-middle-elements .footer-right-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 57px;
  position: relative;
}
@media (min-width: 768px) {
  .footer-middle-elements .footer-right-side {
    margin: 0 0 0 auto;
    max-width: 30%;
  }
}
@media (max-width: 767px) {
  .footer-middle-elements .footer-right-side .footer-column {
    margin: 0 15px;
  }
}
.footer-middle-elements .footer-logo {
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 28px;
}
@media (min-width: 768px) {
  .footer-middle-elements .footer-logo {
    justify-content: flex-end;
    bottom: 5px;
    margin-top: 0;
    position: absolute;
    right: 7px;
  }
}
.footer-middle-elements .footer-logo > img {
  height: 88px;
  width: 92px;
}
.footer-middle-elements .footer-logo .b-corp-logo {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}
.footer-middle-elements .footer-logo .b-corp-logo a {
  color: #ffffff;
  font-family: "Helvetica Neue, sans-serif";
  font-size: 6.5px;
  line-height: 8.45px;
  margin: 2px auto 0;
  text-align: center;
  text-decoration: underline;
  width: 64px;
}
.footer-middle-elements .footer-logo .b-corp-logo img {
  margin: 0 auto;
  width: 45px;
}
.footer-middle-elements .footer-contact {
  margin: 48px auto 0;
  max-width: 255px;
}
@media (max-width: 767px) {
  .footer-middle-elements .footer-contact {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .footer-middle-elements .footer-contact {
    margin: 10px 0 0;
    max-width: none;
  }
}
.footer-middle-elements .footer-contact h3 {
  margin-bottom: 22px;
}

.footer-column h3,
.footer-column .h3 {
  color: #ffffff;
  font-size: 15px;
  text-transform: none;
  letter-spacing: 0.75px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  line-height: 20px;
}
.footer-column .h3 {
  margin-bottom: 16px;
}
.footer-column li {
  list-style: none;
}
.footer-column li a {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  color: #a4a4a4;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 27px;
  text-decoration: none;
  text-transform: none;
}
@media (min-width: 768px) {
  .footer-column li a {
    line-height: 7.5px;
  }
  .footer-column li a:hover, .footer-column li a:active {
    text-decoration: none;
  }
}
.footer-column table td {
  color: #a4a4a4;
  font-size: 14px;
  letter-spacing: 0.7px;
}
.footer-column table .secondary {
  color: rgba(164, 164, 164, 0.8);
  padding-left: 23px;
}
.footer-column .contact-line {
  display: flex;
}
.footer-column .contact-line span {
  color: #a4a4a4;
  font-size: 14px;
  letter-spacing: 0.7px;
}
.footer-column .contact-line span:nth-child(even) {
  color: rgba(164, 164, 164, 0.8);
  padding-left: 23px;
}
.footer-column.two-columns {
  align-items: flex-start;
  display: flex;
}
@media (max-width: 767px) {
  .footer-column.two-columns {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .footer-column.two-columns {
    padding-top: 20px;
  }
}
.footer-column .footer-information {
  margin: 10px 0 0 auto;
}
@media (max-width: 767px) {
  .footer-column .footer-information {
    margin: 48px auto 0;
    max-width: 255px;
    width: 100%;
  }
}
.footer-column .footer-information h3 {
  margin-bottom: 22px;
}

.footer-bottom-elements {
  background-color: #ffffff;
  padding: 24px 0;
  text-align: center;
  width: 100%;
}
.footer-bottom-elements .inner-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.footer-bottom-elements .copyright {
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-bottom: 2px;
  color: #767676;
  order: 2;
}
@media (min-width: 768px) {
  .footer-bottom-elements {
    align-content: center;
    display: flex;
    padding: 20px 0;
  }
  .footer-bottom-elements .inner-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-bottom-elements .copyright {
    flex-shrink: 0;
    margin: 5px 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .footer-bottom-elements {
    min-height: 80px;
    padding: 20px 0;
  }
  .footer-bottom-elements .inner-wrapper {
    padding: 0 40px;
  }
  .footer-bottom-elements .copyright {
    margin: 0 42px 0 0;
    width: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .footer-bottom-elements .copyright {
    margin: 0 20px 0 0;
  }
}
.footer-bottom-elements .accessibility-statement-link {
  display: none;
}

.bottom-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  list-style: none;
  margin: 5px 0 10px;
  order: 3;
  width: 100%;
}
.bottom-links li {
  color: #9a7b69;
  display: inline-block;
  margin-right: 12px;
  position: relative;
}
.bottom-links li::after {
  content: "|";
  position: absolute;
  right: -11px;
  top: 0;
}
.bottom-links li:last-child {
  margin-right: 0;
}
.bottom-links li:last-child::after {
  display: none;
}
.bottom-links a {
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-decoration: none;
  color: #767676;
}
@media (min-width: 768px) {
  .bottom-links {
    width: auto;
    margin: 0;
  }
  .bottom-links li {
    margin-right: 15px;
  }
  .bottom-links a {
    letter-spacing: 0.5px;
  }
  .bottom-links a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 1024px) {
  .bottom-links {
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.payment-links {
  list-style: none;
  margin-bottom: -6px;
  order: 1;
  width: 100%;
  padding: 10px 0;
}
.payment-links li {
  display: inline-block;
  margin: 0 6px 10px;
  vertical-align: middle;
}
.payment-links li.big-ico-wrapper {
  display: none;
}
.payment-links li.small-ico-wrapper {
  display: inline-block;
}
.payment-links .pay-apple-pay-footer {
  display: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .payment-links _::-webkit-full-page-media, .payment-links _:future, .payment-links :root, .payment-links .pay-apple-pay-footer {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .payment-links {
    margin: 0 5px 0 0;
    width: 100%;
    text-align: center;
    padding: 22px 0;
  }
  .payment-links li:not(:first-child) {
    margin: 0 0 0 20px;
  }
  .payment-links li:not(:first-child).big-ico-wrapper {
    display: inline-block;
  }
  .payment-links li:not(:first-child).small-ico-wrapper {
    display: none;
  }
  .payment-links .ico {
    float: left;
  }
}
@media (min-width: 1024px) {
  .payment-links li:not(:first-child) {
    margin: 0 0 0 30px;
  }
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .payment-links li {
    margin: 0 0 0 15px;
  }
}

.footer--contact {
  display: flex;
  background-color: #1a1919;
  min-height: 88px;
  padding: 30px 0 50px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact {
    justify-content: space-between;
    padding: 20px 0;
  }
}
@media (min-width: 1024px) {
  .footer--contact {
    justify-content: space-between;
    padding: 20px 0;
  }
}
.footer--contact .footer-content-wrapper {
  width: 100%;
}
.footer--contact .inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .inner-wrapper {
    padding: 0 60px 0 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media (min-width: 1024px) {
  .footer--contact .inner-wrapper {
    padding: 0 60px 0 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.footer--contact .footer-contact-info-wrapper {
  margin-bottom: 41px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .footer-contact-info-wrapper {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .footer--contact .footer-contact-info-wrapper {
    margin-bottom: 0;
  }
}
.footer--contact .contact-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-time {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-time {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}
.footer--contact .contact-time img {
  max-width: 32px;
  display: inline-block;
  margin-bottom: 22px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-time img {
    margin: 0 24px 0 0;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-time img {
    margin: 0 24px 0 0;
  }
}
.footer--contact .contact-time .contact-message {
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-time .contact-message {
    margin-top: 2px;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-time .contact-message {
    margin-top: 2px;
  }
}
.footer--contact .contact-time .contact-message b {
  display: block;
}
.footer--contact .footer-contact-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer--contact .contact-links {
  display: flex;
  margin-top: -7px;
}
.footer--contact .contact-links .contact-link {
  width: 50%;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-links .contact-link {
    width: auto;
    text-align: left;
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-links .contact-link {
    width: auto;
    text-align: left;
    margin-right: 0;
  }
}
.footer--contact .contact-links .contact-link:first-of-type {
  margin-right: 16px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-links .contact-link:first-of-type {
    margin-right: 114px;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-links .contact-link:first-of-type {
    margin-right: 114px;
  }
}
.footer--contact .contact-links .contact-link a,
.footer--contact .contact-links .contact-link .contact-phone {
  display: flex;
  flex-direction: column;
  color: #a4a4a4;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-links .contact-link a,
  .footer--contact .contact-links .contact-link .contact-phone {
    display: inline-block;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-links .contact-link a,
  .footer--contact .contact-links .contact-link .contact-phone {
    display: inline-block;
  }
}
.footer--contact .contact-links .contact-link a .icon,
.footer--contact .contact-links .contact-link .contact-phone .icon {
  font-size: 30px;
  margin: 0 0 19px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer--contact .contact-links .contact-link a .icon,
  .footer--contact .contact-links .contact-link .contact-phone .icon {
    font-size: 20px;
    margin: 0 15px 0 0;
  }
}
@media (min-width: 1024px) {
  .footer--contact .contact-links .contact-link a .icon,
  .footer--contact .contact-links .contact-link .contact-phone .icon {
    font-size: 20px;
    margin: 0 15px 0 0;
  }
}

.footer--empty-position {
  padding-top: 50px;
}

.stay-informed {
  padding: 53px 40px 40px;
  position: relative;
  background-color: rgba(26, 25, 25, 0.96);
}
@media (min-width: 768px) {
  .stay-informed {
    padding: 48px 40px 53px;
  }
}
@media (min-width: 1024px) {
  .stay-informed {
    padding: 48px 96px 53px;
  }
}
.stay-informed .newsletter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper {
    align-items: stretch;
  }
  .stay-informed .newsletter-wrapper > div {
    flex-shrink: 1;
  }
}
@media (max-width: 767px) {
  .stay-informed .newsletter-wrapper {
    padding: 0;
  }
}
.stay-informed .newsletter-wrapper .text-form-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper .text-form-container {
    flex-direction: row;
  }
}
.stay-informed .newsletter-wrapper h3 {
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 36px;
  margin-bottom: 15px;
  text-transform: none;
  color: #ffffff;
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper h3 {
    margin-bottom: 8px;
    max-width: 350px;
  }
}
.stay-informed .newsletter-wrapper p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  color: #979797;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper p {
    max-width: 700px;
  }
}
.stay-informed .newsletter-wrapper a {
  color: #b8a188;
}
.stay-informed .newsletter-wrapper .emailform {
  width: 100%;
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper .emailform {
    flex-shrink: 0;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .stay-informed .newsletter-wrapper .emailform {
    margin-top: -7px;
  }
}
@media (min-width: 1024px) {
  .stay-informed .newsletter-wrapper .emailform {
    flex-shrink: 1;
    width: 100%;
  }
}
.stay-informed .newsletter-wrapper .emailform .input-row.floating-label {
  margin-top: 13px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .stay-informed .newsletter-wrapper .emailform .input-row.floating-label {
    margin-top: 0;
  }
}
.stay-informed .newsletter-wrapper .emailform input.error {
  border-color: #ef3d56;
}
.stay-informed .newsletter-wrapper .emailform form {
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper .emailform form {
    padding-left: 20px;
    margin-top: 0;
  }
}
.stay-informed .newsletter-wrapper .emailform form .form-row {
  display: flex;
  flex-direction: column;
  max-width: none;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .stay-informed .newsletter-wrapper .emailform form .form-row {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .stay-informed .newsletter-wrapper .emailform form .form-row {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .stay-informed .newsletter-wrapper .emailform form .form-row {
    flex-direction: row;
  }
}
.stay-informed .newsletter-wrapper .emailform form .form-row .form-row {
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .stay-informed .newsletter-wrapper .emailform form .form-row .form-row {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .stay-informed .newsletter-wrapper .emailform form .form-row .form-row {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .stay-informed .newsletter-wrapper .emailform form .form-row .form-row {
    width: 100%;
    max-width: 400px;
    min-width: 250px;
    margin-right: 20px;
  }
}
@media (min-width: 1024px) {
  .stay-informed .newsletter-wrapper .emailform form .form-row .form-row {
    margin-bottom: 0;
  }
}
.stay-informed .newsletter-wrapper .emailform form .form-row .field-wrapper {
  width: 100%;
}
.stay-informed .newsletter-wrapper .emailform form .form-row input {
  background-color: transparent;
  color: #ffffff;
}
@media (min-width: 1024px) {
  .stay-informed .newsletter-wrapper .emailform form .form-row input:not(.inputfield-text):hover, .stay-informed .newsletter-wrapper .emailform form .form-row input:not(.inputfield-text):focus {
    border-bottom: 1px solid #886655;
  }
}
.stay-informed .newsletter-wrapper .emailform form .form-row input:not(.inputfield-text).input-text {
  font-size: 14px;
  padding-right: 50px;
  width: 100%;
}
.stay-informed .newsletter-wrapper .emailform form .form-row .inputfield-row {
  position: relative;
}
.stay-informed .newsletter-wrapper .emailform form .form-row .inputfield-row span.error {
  top: 0;
}
@media (min-width: 1024px) {
  .stay-informed .newsletter-wrapper .emailform form .form-row .inputfield-row span.error {
    bottom: -16px;
    position: absolute;
    right: 0;
    top: auto;
  }
}
.stay-informed .newsletter-wrapper .emailform form .form-row .input-row span.error {
  position: absolute;
  color: #ef3d56;
}
.stay-informed .newsletter-wrapper .emailform form .inputfield-row label {
  color: #ffffff;
}
.stay-informed .newsletter-wrapper .emailform form .input-row.floating-label label {
  color: #a4a4a4;
}
.stay-informed .newsletter-wrapper .button-wrapper {
  display: flex;
  align-items: flex-end;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .stay-informed .newsletter-wrapper .button-wrapper {
    justify-content: flex-end;
  }
}
.stay-informed .newsletter-wrapper button.last {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 2px;
  width: auto;
}

.wrapper__inner.simple-page-include {
  padding: 85px 65px 91px;
  text-align: center;
}
@media (min-width: 768px) {
  .wrapper__inner.simple-page-include {
    padding: 107px 0;
  }
}
.wrapper__inner.simple-page-include h1 {
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 46px;
  color: #453f3f;
  text-transform: none;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .wrapper__inner.simple-page-include h1 {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 60px;
    margin-bottom: 6px;
  }
}
.wrapper__inner.simple-page-include span {
  display: block;
  margin: 0 auto;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
  color: #453f3f;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .wrapper__inner.simple-page-include span {
    max-width: 565px;
    font-size: 30px;
    letter-spacing: 0.9px;
    line-height: 45px;
  }
}

.country-selector-dialog {
  position: fixed !important;
  background-color: #ffffff;
  padding: 33px 15px 37px;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  text-align: center;
  max-height: 90%;
  overflow: auto;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .country-selector-dialog {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 90% !important;
    padding: 40px 35px 50px;
  }
}
@media (min-width: 1024px) {
  .country-selector-dialog {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50.1%);
    max-width: 600px;
    width: 90% !important;
    padding: 40px 35px 50px;
  }
}
.country-selector-dialog .store-selector-edit-address,
.country-selector-dialog .locale-store-selector__wrapper {
  opacity: 1;
}
.country-selector-dialog .ui-dialog-titlebar {
  padding: 0;
}
.country-selector-dialog .ui-dialog-title {
  width: 100%;
  float: none;
  white-space: normal;
}
.country-selector-dialog .ui-dialog-title .title {
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  max-width: 310px;
  color: #000000;
  text-transform: none;
  width: 100%;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin: 6px auto 13px;
}
@media (min-width: 768px) {
  .country-selector-dialog .ui-dialog-title .title {
    max-width: 400px;
    margin-top: 0;
  }
}
.country-selector-dialog .description {
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .country-selector-dialog .description {
    padding: 0 30px;
    margin-bottom: 40px;
  }
}
.country-selector-dialog .description p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
.country-selector-dialog.account-country-dialog .ui-dialog-title {
  display: none;
}
.country-selector-dialog.account-country-dialog .description {
  padding: 0;
  margin-bottom: 25px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .country-selector-dialog.account-country-dialog .description {
    padding: 32px 88px 0;
    margin-bottom: 41px;
  }
}
@media (min-width: 1024px) {
  .country-selector-dialog.account-country-dialog .description {
    padding: 32px 88px 0;
    margin-bottom: 41px;
  }
}
.country-selector-dialog.account-country-dialog .description p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
}
.country-selector-dialog .locale-links-container .row {
  display: flex;
  flex-direction: row;
  max-width: 282px;
  margin: 0 auto;
}
.country-selector-dialog .locale-links-container .row:first-of-type {
  margin-bottom: 27px;
}
@media (min-width: 768px) {
  .country-selector-dialog .locale-links-container .row {
    max-width: 268px;
  }
  .country-selector-dialog .locale-links-container .row:first-of-type {
    margin-bottom: 18px;
  }
}
.country-selector-dialog .link-to-locale {
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: none;
  line-height: 20px;
  text-align: left;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .country-selector-dialog .link-to-locale {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
}
@media (min-width: 1024px) {
  .country-selector-dialog .link-to-locale {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
}
.country-selector-dialog .flag {
  display: flex;
  align-items: flex-start;
  padding-top: 3px;
  margin-top: -2px;
  margin-right: 12px;
}
@media (min-width: 768px) {
  .country-selector-dialog .flag {
    align-items: center;
    margin-right: 11px;
    padding-top: 0;
  }
}
.country-selector-dialog .flag img {
  width: 24px;
  height: 18px;
}
.country-selector-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: -2px;
  top: -18px;
  margin: 0;
  width: auto;
  height: auto;
}
@media (min-width: 768px) {
  .country-selector-dialog .ui-dialog-titlebar-close {
    right: -2px;
    top: -20px;
  }
}
@media (min-width: 1024px) {
  .country-selector-dialog .ui-dialog-titlebar-close {
    right: -20px;
    top: -25px;
  }
}
.country-selector-dialog .ui-dialog-titlebar-close span {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}
.country-selector-dialog .ui-dialog-titlebar-close span:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea36";
}
.country-selector-dialog .title-placeholder-hidden {
  display: none;
}

.store-selector-edit-address {
  display: none;
}

.locale-store-selector__wrapper {
  opacity: 0;
}

.scroll-to-top-link {
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  text-align: right;
  z-index: 10;
  max-width: 1215px;
  width: 100%;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.scroll-to-top-link.not-fixed {
  position: absolute;
  bottom: 100%;
  z-index: 100;
}
.scroll-to-top-link.not-fixed:not(.shown), .scroll-to-top-link.shown {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .scroll-to-top-link.hide-on-mobile.shown:not(.not-fixed) {
    opacity: 0;
    visibility: hidden;
  }
}
.scroll-to-top-link .scroll-to-top-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  width: 101px;
  height: 60px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  margin-top: -59px;
}
.scroll-to-top-link .scroll-to-top-wrapper:hover {
  background-color: #ffffff;
  transition: 0.2s ease-in-out;
}
.scroll-to-top-link .scroll-to-top-wrapper a {
  display: inline-block;
  position: relative;
}
.scroll-to-top-link .scroll-to-top-wrapper a span {
  font-size: 13px;
  color: #626262;
}

.footer-maintenance {
  display: flex;
  align-items: center;
  padding: 50px 0 85px;
  background-color: #1a1919;
}
@media (min-width: 768px) {
  .footer-maintenance {
    padding: 26px 0 50px;
  }
}
.footer-maintenance .footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 1115px;
}
@media (min-width: 768px) {
  .footer-maintenance .footer-inner {
    flex-direction: row;
  }
}
.footer-maintenance .social-icons-wrapper .social-icons {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .footer-maintenance .social-icons-wrapper .social-icons {
    justify-content: space-between;
  }
}
.footer-maintenance .social-icons-wrapper .text {
  display: block;
  font-size: 15px;
  letter-spacing: 0.75px;
  color: #ffffff;
  margin-bottom: 17px;
  text-align: center;
}
@media (min-width: 768px) {
  .footer-maintenance .social-icons-wrapper .text {
    margin-bottom: 12px;
  }
}
.footer-maintenance .social-icons-wrapper a {
  text-decoration: none;
  margin: 0 14px;
}
@media (min-width: 768px) {
  .footer-maintenance .social-icons-wrapper a {
    margin: 0 -3px;
  }
}
.footer-maintenance .social-icons-wrapper a span {
  color: #737373;
  font-size: 15px;
}
.footer-maintenance .ico {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
}
@media (min-width: 768px) {
  .footer-maintenance .ico {
    position: absolute;
    top: 50%;
    right: 0;
    left: auto;
    transform: translate(0, -50%);
  }
}

.pt_cart .page-content {
  overflow: hidden;
  width: 100%;
}
.pt_cart .primary-content {
  position: relative;
  width: 100%;
}

.checkout-inner .checkout-header {
  border-bottom: 1px solid #d8d8d8;
  display: none;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 0;
  padding-bottom: 15px;
  text-align: left;
  text-transform: none;
}
.checkout-inner .checkout-header.b2b-checkout-header {
  display: block;
  margin-top: 60px;
}
.checkout-inner .checkout-content.open .checkout-header.step-3 {
  display: block;
}
@media (min-width: 768px) {
  .checkout-inner .checkout-header {
    border-bottom: 1px solid #d8d8d8;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    padding-bottom: 14px;
    text-transform: none;
  }
  .checkout-inner .checkout-content.checkout-step-1 .checkout-header.step-1 {
    display: block;
    margin-bottom: 19px;
  }
}

.send-icon-visible {
  background-color: #1a1919;
  color: #ffffff;
  display: inline-block;
  position: relative;
  z-index: 10;
}
.send-icon-visible::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .send-icon-visible::before {
    font-size: 10px;
  }
}

.send-checkout-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 610px;
  margin: 0 auto;
}
.send-checkout-boxes .btn:only-child {
  margin: 0 auto;
}
.send-checkout-boxes .btn-send-check-link {
  margin-top: 10px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .send-checkout-boxes {
    max-width: 100%;
    flex-direction: column;
    margin: 0 10px;
  }
  .send-checkout-boxes .btn-send-check-link {
    margin-left: 0;
  }
  .send-checkout-boxes .btn {
    min-width: 100%;
  }
}
@media (min-width: 768px) {
  .send-checkout-boxes .btn {
    flex-basis: 280px;
  }
}

.order-component-block {
  display: none;
  padding: 29px 39px 0;
  width: 100%;
}
.order-component-block .section-header {
  margin-bottom: 20px;
  padding-bottom: 11px;
}
.order-component-block .minishipments-method {
  margin-top: 22px;
}
@media (min-width: 768px) {
  .order-component-block {
    display: block;
  }
}

.checkout-holder {
  display: flex;
  padding: 22px 0 40px;
  width: 100%;
}
.checkout-holder .btn-loader {
  min-height: 10px;
}
.checkout-holder .cs-service-block {
  margin-top: -20px;
}
.checkout-holder .cs-service-block .customer-service-block {
  display: none;
  margin-top: 0;
  padding-bottom: 0;
}
@media (min-width: 1024px) {
  .checkout-holder .cs-service-block .customer-service-block {
    display: block;
  }
}
.checkout-holder .cs-service-block .customer-service-block a {
  text-decoration: none;
  pointer-events: none;
}
.checkout-holder .cs-service-block .customer-service-block .text-wrapper {
  text-align: center;
}
@media (max-width: 767px) {
  .checkout-holder .page-layout-left {
    width: 100%;
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .checkout-holder {
    padding: 80px 0;
  }
  .checkout-holder .btn-submit {
    margin-top: 10px;
  }
  .checkout-holder .page-layout-left {
    margin-bottom: 50px;
  }
}

.back-button-wrapper {
  width: 100%;
  margin: -7px auto 15px;
  max-width: 620px;
  text-align: left;
}
@media (min-width: 768px) {
  .back-button-wrapper {
    margin: -24px auto 35px;
  }
}
.back-button-wrapper .back-button {
  margin-left: -13px;
}
@media (min-width: 768px) {
  .back-button-wrapper .back-button {
    margin-left: -8px;
  }
}

.checkout-step-buttons-holder {
  display: flex;
  justify-content: center;
  margin-bottom: 53px;
  margin-top: 32px;
  width: 100%;
}
@media (min-width: 768px) {
  .checkout-step-buttons-holder {
    margin: 4px 0 60px;
  }
}

.checkout-steps-buttons-list {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 280px;
}
.checkout-steps-buttons-list::before {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  content: "";
  display: inline-block;
  height: 30px;
  left: 34px;
  position: absolute;
  right: 34px;
  top: 0;
}
.checkout-steps-buttons-list .checkout-step-btn {
  position: relative;
  width: 80px;
}
.checkout-steps-buttons-list .checkout-step-btn:not(.selected):not(.disabled) .circle {
  background-color: #ffffff;
  font-size: 0;
}
.checkout-steps-buttons-list .checkout-step-btn:not(.selected):not(.disabled) .circle::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #705849;
  content: "\ea2e";
  font-size: 10px;
  left: 2px;
  position: relative;
  top: -1px;
}
.checkout-steps-buttons-list .checkout-step-btn:first-child .background-line {
  left: 34px;
}
.checkout-steps-buttons-list .checkout-step-btn:last-child .background-line {
  right: 34px;
}
.checkout-steps-buttons-list .background-line {
  background-color: #ffffff;
  content: "";
  display: inline-block;
  height: 30px;
  left: -11px;
  position: absolute;
  right: 0;
  top: 1px;
  z-index: 1;
}
.checkout-steps-buttons-list .selected .circle {
  background-color: #9a7b69;
  color: #ffffff;
  font-size: 18px;
  pointer-events: none;
  position: relative;
}
.checkout-steps-buttons-list .selected .circle::before {
  background-color: transparent;
  border: 5px solid #9a7b69;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: inline-block;
  height: 34px;
  left: -3px;
  position: absolute;
  top: -2px;
  width: 34px;
}
.checkout-steps-buttons-list .selected .text {
  color: #886655;
  font-weight: bold;
}
.checkout-steps-buttons-list .selected .background-line {
  right: 40px;
}
.checkout-steps-buttons-list .disabled .circle {
  line-height: 30px;
}
.checkout-steps-buttons-list .disabled .background-line {
  display: none;
}
.checkout-steps-buttons-list .circle {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #626262;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  height: 30px;
  line-height: 32px;
  margin-bottom: 8px;
  position: relative;
  width: 30px;
  z-index: 2;
}
.checkout-steps-buttons-list .text {
  color: #626262;
  display: block;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 14px;
}
@media (min-width: 768px) {
  .checkout-steps-buttons-list {
    width: 470px;
  }
  .checkout-steps-buttons-list::before {
    left: 55px;
    right: 55px;
  }
  .checkout-steps-buttons-list .checkout-step-btn {
    width: 120px;
  }
  .checkout-steps-buttons-list .checkout-step-btn:first-child .background-line {
    left: 60px;
  }
  .checkout-steps-buttons-list .checkout-step-btn:last-child .background-line {
    right: 60px;
  }
  .checkout-steps-buttons-list .background-line {
    left: -1px;
    top: 2px;
  }
}
@media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  .checkout-steps-buttons-list .background-line {
    top: 0;
  }
}
@media (min-width: 768px) {
  .checkout-steps-buttons-list .cicrle {
    margin-bottom: 12px;
  }
  .checkout-steps-buttons-list .selected .background-line {
    right: 50px;
  }
  .checkout-steps-buttons-list .selected .circle::before {
    height: 36px;
    width: 36px;
  }
}

.checkout-step-title {
  margin-bottom: 28px;
  position: relative;
  text-align: center;
  width: 100%;
}
.checkout-step-title .number {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 130px;
  left: 50%;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -54%);
}
.checkout-step-title .text {
  color: #1a1919;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 0 auto;
  max-width: 200px;
  position: relative;
  text-transform: none;
}
@media (min-width: 768px) {
  .checkout-step-title {
    margin-bottom: 47px;
  }
  .checkout-step-title .number {
    font-size: 160px;
    transform: translate(-50%, -55%);
  }
  .checkout-step-title .text {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
    max-width: 280px;
  }
}

.checkout-summary-inner {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
}
.checkout-summary-inner .section-header {
  border-bottom: 1px solid #e8e8e8;
  color: #1a1919;
  display: flex;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-bottom: 18px;
  padding-bottom: 22px;
  text-transform: uppercase;
  width: 100%;
}
.checkout-summary-inner .section-header .section-header-note {
  color: #886655;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-left: auto;
}
.step3 .checkout-summary-inner .section-header .section-header-note {
  display: none;
}
.checkout-summary-inner .btn-send-check-link {
  margin-top: 20px;
  min-width: 100%;
}
@media (max-width: 767px) {
  .checkout-summary-inner {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}

.checkout-summary-minicart {
  margin-bottom: 23px;
  width: 100%;
}
.checkout-v3 .checkout-summary-minicart {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0;
}
.checkout-summary-minicart.section-row-wrapper {
  border-bottom: 0;
}
.checkout-summary-minicart .mini-cart-product {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  padding: 0 0 25px 10px;
  position: relative;
  width: 100%;
}
.checkout-section .checkout-summary-minicart .mini-cart-product {
  border-bottom: 0;
  border-top: 1px solid #eaeaea;
  padding: 24px 0 24px 0;
}
.checkout-v3 .checkout-summary-minicart .mini-cart-product {
  padding: 24px 0 24px 20px;
}
.checkout-summary-minicart .mini-cart-product:last-child {
  margin-bottom: 0;
}
.checkout-summary-minicart .mini-cart-image {
  flex-shrink: 0;
  padding-top: 4px;
  width: 60px;
}
.checkout-summary-minicart .mini-cart-image a {
  display: block;
}
.checkout-summary-minicart .mini-cart-image img {
  display: block;
  max-width: 100%;
}
.checkout-summary-minicart .mini-cart-info {
  padding-left: 10px;
  width: 100%;
}
.checkout-v3 .checkout-summary-minicart .mini-cart-info {
  padding-left: 20px;
}
@media (min-width: 768px) {
  .checkout-v3 .checkout-summary-minicart .mini-cart-info {
    padding-left: 30px;
  }
}
.checkout-summary-minicart .mini-cart-small-title {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.checkout-section .checkout-summary-minicart .mini-cart-small-title {
  padding-right: 90px;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  position: relative;
}
.checkout-summary-minicart .mini-cart-product-error {
  width: 100%;
}
.checkout-summary-minicart .mini-cart-product-error .remove-product {
  color: #886655;
  cursor: pointer;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-left: 65px;
  padding-left: 24px;
  position: relative;
  text-decoration: underline;
}
.checkout-summary-minicart .mini-cart-product-error .remove-product:hover {
  text-decoration: none;
}
.checkout-summary-minicart .mini-cart-product-error .remove-product::after {
  background-size: 100% 100%;
  background: transparent url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiM5QTdCNjk7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik04LDEuM2MzLjcsMCw2LjcsMyw2LjcsNi43cy0zLDYuNy02LjcsNi43cy02LjctMy02LjctNi43UzQuMywxLjMsOCwxLjN6IE04LDIuMUM0LjgsMi4xLDIuMSw0LjgsMi4xLDgNCglzMi42LDUuOSw1LjksNS45czUuOS0yLjYsNS45LTUuOVMxMS4yLDIuMSw4LDIuMXogTTguNCw1LjRsMCwyLjJsMi4yLDB2MC43bC0yLjIsMGwwLDIuMkg3LjZsMC0yLjJsLTIuMiwwVjcuNmwyLjIsMGwwLTIuMkg4LjR6Ii8+DQo8L3N2Zz4NCg==") no-repeat;
  content: "";
  height: 16px;
  left: 4px;
  position: absolute;
  top: 3px;
  transform: rotate(45deg);
  width: 16px;
}
.checkout-summary-minicart .mini-cart-product-error .inline-alert {
  margin: 10px 0 0 0;
}
.checkout-summary-minicart .mini-cart-name {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 5px;
  padding-right: 90px;
}
.checkout-summary-minicart .mini-cart-name a {
  color: #1a1919;
  line-height: 22px;
  text-decoration: none;
}
.checkout-v3 .checkout-summary-minicart .mini-cart-name a {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  line-height: 24px;
}
.checkout-section .checkout-summary-minicart .mini-cart-name {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  position: relative;
}
.checkout-section .checkout-summary-minicart .mini-cart-name a {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  line-height: 24px;
}
.checkout-summary-minicart .mini-cart-attributes {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.7px;
  line-height: 15px;
  padding-right: 90px;
}
.checkout-summary-minicart .mini-cart-details-bottom {
  padding: 7px 90px 0 0;
}
.checkout-summary-minicart .mini-cart-pricing {
  color: #626262;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 16px;
}
.checkout-section .checkout-summary-minicart .mini-cart-pricing {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.checkout-section .checkout-summary-minicart .mini-cart-pricing .value {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.checkout-summary-minicart .mini-cart-pricing .unit-base-price {
  display: block;
  padding-top: 7px;
  text-align: right;
  width: calc(100% + 90px);
}
.checkout-summary-minicart .mini-cart-price-wrapper {
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: right;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.checkout-v3 .checkout-summary-minicart .mini-cart-price-wrapper {
  right: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .checkout-v3 .checkout-summary-minicart .mini-cart-price-wrapper {
    right: 40px;
  }
}
.checkout-summary-minicart .mini-cart-price-wrapper .price-before-discount {
  color: #8c8c8c;
  display: block;
  font-size: 11px;
  letter-spacing: 1.6px;
  text-decoration: line-through;
}
.checkout-section .checkout-summary-minicart .mini-cart-price-wrapper .price-before-discount {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  position: absolute;
  right: 0;
}
.checkout-summary-minicart .mini-cart-price-wrapper .price-after-discount {
  color: #1a1919;
  display: block;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 22px;
}
.checkout-summary-minicart .mini-cart-price-wrapper .unit-base-price {
  display: none;
}
.checkout-summary-minicart .mini-cart-unit-base .unit-base-price {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .checkout-summary-minicart .mini-cart-unit-base .unit-base-price {
    padding-right: 10px;
  }
}
.checkout-summary-minicart .mini-cart-unit-base .unit-base-price.legal-note {
  color: #626262;
  margin-top: 0;
}
.checkout-summary-minicart .mini-cart-price,
.checkout-summary-minicart .free-label {
  color: #1a1919;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 22px;
}
.checkout-section .checkout-summary-minicart .mini-cart-price,
.checkout-section .checkout-summary-minicart .free-label {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.checkout-v3 .order-confirmation-summary .acc-content-item .acc-content-head::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
  color: #9a7b69;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.checkout-v3 .order-confirmation-summary .acc-content-item .acc-content-head.open {
  border-bottom-color: rgba(255, 255, 255, 0);
}
.checkout-v3 .order-confirmation-summary .acc-content-item .acc-content-head.open::after {
  transform: translateY(-50%) rotate(-180deg);
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart .giftcount-header {
  border-top: 1px solid #d8d8d8;
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart .mini-cart-price-wrapper {
  padding-top: 0 !important;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart .mini-cart-price-wrapper .mini-cart-price {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 32px;
}
@media (max-width: 767px) {
  .checkout-v3 .order-confirmation-summary .checkout-summary-minicart .mini-cart-price-wrapper .mini-cart-price {
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}
.checkout-v3 .order-confirmation-summary .checkout-summary-minicart .mini-cart-price-wrapper .mini-cart-b2b-taxes {
  color: #626262;
  font-size: 12px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}

.checkout-summary-order-totals {
  width: 100%;
}
.checkout-summary-order-totals .order-totals-table {
  width: 100%;
}
.checkout-summary-order-totals .order-subtotal,
.checkout-summary-order-totals .order-total {
  display: flex;
}
.checkout-summary-order-totals .order-subtotal .cart-footer__item--name,
.checkout-summary-order-totals .order-total .cart-footer__item--name {
  color: #1a1919;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  padding-top: 5px;
  text-transform: uppercase;
}
.checkout-summary-order-totals .order-subtotal {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 21px;
  padding-bottom: 25px;
  width: 100%;
}
.checkout-summary-order-totals .cart-footer__item--val {
  color: #1a1919;
  flex-shrink: 0;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-left: auto;
}
.checkout-summary-order-totals .shopping-cart-coupon-row {
  display: none;
}
.checkout-summary-order-totals .checkout-summary-price-row {
  display: flex;
  margin-bottom: 11px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row {
  padding-left: 24px;
  padding-right: 24px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row.checkout-summary-price-row-sub {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row.checkout-summary-price-row-sub .cart-footer__item--name {
  color: #1a1919;
  font-size: 16px;
  font-weight: unset;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row.checkout-summary-price-row-sub .cart-footer__item--val {
  color: #1a1919;
  font-size: 16px;
  font-weight: unset;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row.checkout-summary-price-shipping {
  margin-bottom: 2px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row.checkout-summary-price-shipping .cart-footer__item--name {
  color: #1a1919;
  font-size: 16px;
  font-weight: unset;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row.checkout-summary-price-shipping .cart-footer__item--val {
  color: #1a1919;
  font-size: 16px;
  font-weight: unset;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.checkout-summary-order-totals .checkout-summary-price-row .cart-footer__item--name {
  color: #626262;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding-top: 4px;
}
.checkout-v3 .checkout-summary-order-totals .checkout-summary-price-row .cart-footer__item--name {
  color: #000000;
  font-family: "ClassGarmnd BT Bold", "Times New Roman", serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding-top: 0;
  text-transform: none;
}
.checkout-summary-order-totals .order-total {
  border-top: 1px solid #453f3f;
  font-weight: bold;
  margin-top: 18px;
  padding-top: 26px;
}
.checkout-summary-order-totals .cart-footer-total {
  display: flex;
  width: 100%;
}

.checkout-shipping-form .form-divider {
  background-color: #d8d8d8;
  display: block;
  height: 1px;
  margin: 30px 0;
  width: 100%;
}
.checkout-shipping-form .form-subtitle {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 25px;
  text-transform: none;
}
.checkout-shipping-form .form-subtitle + .checkout-billing-fieldset {
  margin-top: -17px;
}
.checkout-shipping-form .full-width-row {
  margin-bottom: 15px;
}
.checkout-shipping-form .full-width-row + .full-width-row {
  margin-top: 0;
}
.checkout-shipping-form .full-width-row .error-message {
  color: #626262;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
}
.checkout-shipping-form .company-fields {
  width: 100%;
}
.checkout-shipping-form .checkout-shipping-fieldset {
  margin-bottom: 15px;
}
.checkout-shipping-form .checkout-shipping-fieldset .checkbox-input-row:last-child .full-width-row {
  margin-bottom: 0;
}
.checkout-shipping-form .checkout-shipping-fieldset-inner {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checkout-shipping-form h2, .checkout-shipping-form h3, .checkout-shipping-form h4 {
  width: 100%;
}
.checkout-shipping-form .single-delivery-date {
  cursor: not-allowed;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-top: 7px;
}
.checkout-shipping-form .checkout-readonly-summary {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  text-align: left;
  width: 100%;
}
.checkout-shipping-form .checkout-readonly-summary .address-holder {
  margin-bottom: 5px;
  width: 100%;
}
.checkout-shipping-form .checkout-readonly-summary .address-holder span {
  color: #1a1919;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 5px;
}
.checkout-shipping-form .checkout-readonly-summary .address-holder .pickup-in-store-address {
  display: inline;
}
.checkout-shipping-form .checkout-readonly-summary .address-holder .name,
.checkout-shipping-form .checkout-readonly-summary .address-holder .pickup-point-name,
.checkout-shipping-form .checkout-readonly-summary .address-holder .dhl-postnummer {
  font-style: italic;
}
.checkout-shipping-form .checkout-readonly-summary .dhlPostnummer {
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.checkout-shipping-form .checkout-readonly-summary.readonly-summary-top {
  margin-bottom: -10px;
  padding-top: 0;
}
.checkout-shipping-form .checkout-readonly-summary.readonly-summary-top .address-holder {
  margin-bottom: 0;
}
.checkout-shipping-form .inputfield-row .pac-container {
  left: 0 !important;
  top: 100% !important;
}
.checkout-shipping-form .checkout-personal-information {
  display: block;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: -3px 0 38px;
}
.checkout-shipping-form .checkout-personal-information strong {
  display: block;
}
@media (max-width: 767px) {
  .checkout-shipping-form {
    margin-top: 49px;
  }
}
@media (min-width: 768px) {
  .checkout-shipping-form {
    margin: 0 auto;
    max-width: 620px;
  }
  .checkout-shipping-form .form-divider {
    margin: 30px 0 40px;
  }
  .checkout-shipping-form .form-subtitle {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  .checkout-shipping-form .form-subtitle + .checkout-billing-fieldset {
    margin-top: -28px;
  }
  .checkout-shipping-form .checkout-personal-information {
    margin: -24px 0 18px;
  }
}

.checkbox-input-row label {
  color: #453f3f;
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding-left: 34px;
  position: relative;
}
.checkbox-input-row label::before {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  content: "";
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}
.checkbox-input-row label::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea2f";
  display: none;
  font-size: 12px;
  left: 4px;
  line-height: 24px;
  position: absolute;
  top: 0;
}
.checkbox-input-row label.checked::before {
  background-color: #886655;
  border-color: #886655;
}
.checkbox-input-row input {
  height: 1px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 1px;
}

.checkout-step-2 .checkout-step-title {
  margin-bottom: 52px;
}
.checkout-step-2 .checkout-step-title .text {
  max-width: 240px;
}
@media (min-width: 768px) {
  .checkout-step-2 .checkout-step-title {
    margin-bottom: 62px;
  }
  .checkout-step-2 .checkout-step-title .text {
    max-width: 350px;
  }
}

.checkout-review-box {
  background-color: #ffffff;
  color: #1a1919;
  margin-bottom: 20px;
  padding: 0 25px 1px;
  text-align: left;
  width: 100%;
}
.checkout-review-box.review-person-box {
  margin-bottom: 30px;
  padding: 0 20px 20px;
}
.checkout-review-box.review-person-box .read-only-summary {
  padding: 0;
  margin: 0;
  border: 0;
}
.checkout-review-box.review-billing-box .read-only-summary {
  border-top: 0;
  padding-top: 0;
}
.checkout-review-box.review-billing-box .email {
  margin-bottom: 24px;
}
.checkout-review-box.review-billing-box .checkout-review-box-footer {
  margin: 17px 0;
}
.checkout-review-box.review-pickup-box .checkout-opening-hours {
  border-bottom: 0;
}
.checkout-review-box.review-pickup-box .checkout-opening-hours-link {
  padding: 16px 0 15px;
}
.checkout-review-box.review-pickup-box .checkout-opening-hours-link::after {
  right: -12px;
  top: 16px;
}
.checkout-review-box.checkout-review-box-b2b .read-only-summary .vat-number {
  margin-top: 0;
}
.checkout-review-box .read-only-summary + .chosen-delivery-option,
.checkout-review-box .checkout-review-box-footer + .chosen-delivery-option {
  border-top: 1px solid #d8d8d8;
  padding-top: 21px;
}
.checkout-review-box .chosen-delivery-option {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .checkout-review-box .chosen-delivery-option {
    margin-bottom: 7px;
  }
}
.checkout-review-box .chosen-delivery-option .text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  max-width: calc(100% - 70px);
}
.checkout-review-box .chosen-delivery-option .time {
  display: block;
}
.checkout-review-box .chosen-delivery-option .text-pickup-point {
  font-weight: normal;
}
.checkout-review-box .chosen-delivery-option .text-pickup-point span {
  display: block;
}
.checkout-review-box .chosen-delivery-option .text-pickup-point .distance {
  color: #767676;
}
.checkout-review-box .chosen-delivery-option .pickup-point-name {
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  width: 100%;
  max-width: 220px;
}
@media (min-width: 768px) {
  .checkout-review-box .chosen-delivery-option .pickup-point-name {
    max-width: 265px;
  }
}
@media (min-width: 1024px) {
  .checkout-review-box .chosen-delivery-option .pickup-point-name {
    max-width: 400px;
  }
}
.checkout-review-box .chosen-delivery-option .pickup-point-address {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  width: 100%;
}
.checkout-review-box .chosen-delivery-option .price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-left: auto;
}
.checkout-review-box .chosen-delivery-option .dhlPostnummer {
  width: 100%;
  font-style: italic;
  margin-top: 4px;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.checkout-review-box .checkout-opening-hours {
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: 0;
}
.checkout-review-box .checkout-opening-hours + .read-only-summary {
  border-top: 0;
}
.checkout-review-box .checkout-opening-hours-link {
  padding: 19px 0 16px;
}
.checkout-review-box .checkout-opening-hours-link::after {
  right: -7px;
  top: 17px;
}
@media (min-width: 768px) {
  .checkout-review-box .checkout-opening-hours-link {
    padding: 19px 0 22px;
  }
  .checkout-review-box .checkout-opening-hours-link::after {
    right: -12px;
    top: 18px;
  }
}
.checkout-review-box .checkout-review-box-user-address {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .checkout-review-box .checkout-review-box-user-address {
    margin-bottom: 32px;
  }
}
.checkout-review-box .checkout-review-box-user-address .read-only-summary {
  border-top: 0;
  padding-top: 0;
}
.checkout-review-box .checkout-review-box-user-address .checkout-review-box-footer {
  margin: 0;
}
.checkout-review-box .read-only-summary {
  border-top: 1px solid #d8d8d8;
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 7px;
  padding-top: 21px;
}
.checkout-review-box .read-only-summary span {
  display: block;
}
.checkout-review-box .read-only-summary .name {
  font-style: italic;
}
.checkout-review-box .read-only-summary .vat-number {
  margin-top: 17px;
}
.checkout-review-box .shipping-options-container {
  padding-left: 6px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .checkout-review-box .shipping-options-container {
    padding-top: 13px;
  }
}
.checkout-review-box .shipping-options-container .dhlInput-button-wrapper {
  flex-direction: column-reverse;
}
.checkout-review-box .shipping-options-container .dhlInput-button-wrapper .postnummer-input-wrapper {
  width: calc(100% + 85px);
  margin-left: -40px;
}
@media (min-width: 768px) {
  .checkout-review-box .shipping-options-container .dhlInput-button-wrapper .postnummer-input-wrapper {
    width: calc(100% + 132px);
    margin-left: -48px;
  }
}
.checkout-review-box .shipping-options-container .delivery-optlist-change {
  color: #9a7b69;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: left;
  text-decoration: underline;
  margin-top: 6px;
}
.checkout-review-box .shipping-options-container .cart-delivery-option-label {
  margin-bottom: 24px;
  padding: 0 45px 0 40px;
}
@media (min-width: 768px) {
  .checkout-review-box .shipping-options-container .cart-delivery-option-label {
    padding: 0 85px 0 49px;
  }
}
.checkout-review-box .shipping-options-container .cart-delivery-options:only-child .cart-delivery-option-label {
  padding-left: 0;
}
.checkout-review-box .shipping-options-container .option-title {
  color: #1a1919;
  line-height: 24px;
}
.checkout-review-box .shipping-options-container .option-time {
  font-weight: normal;
  color: #1a1919;
}
.checkout-review-box .shipping-options-container .option-price {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
@media (min-width: 768px) {
  .checkout-review-box {
    margin: 0 auto 20px;
    max-width: 610px;
  }
}

.checkout-review-box-title {
  border-bottom: 1px solid #d8d8d8;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 20px;
  padding: 26px 0 14px;
  text-transform: none;
}
@media (min-width: 768px) {
  .checkout-review-box-title {
    margin-bottom: 25px;
  }
}

.checkout-review-box-footer {
  display: flex;
  margin: 0 0 17px;
  padding: 0;
  text-align: center;
  width: 100%;
}
.checkout-review-box-footer .link {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: left;
}
@media (max-width: 1023px) {
  .checkout-review-box-footer {
    flex-direction: column;
  }
}

.terms-and-conditions-info {
  margin: 0 auto;
  max-width: 610px;
  padding: 22px 0 20px 0;
  text-align: left;
  width: 100%;
}
.terms-and-conditions-info p,
.terms-and-conditions-info a {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 21px;
  margin: 0 auto 10px;
  max-width: 610px;
}
@media (min-width: 768px) {
  .terms-and-conditions-info p,
  .terms-and-conditions-info a {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}
.terms-and-conditions-info.txt--white > * {
  color: #ffffff;
}
.terms-and-conditions-info.txt--black > * {
  color: #1a1919;
}

.checkout-billing-fieldset {
  margin-bottom: 32px;
}
.checkout-billing-fieldset p {
  letter-spacing: 0.5px;
  margin-bottom: 17px;
}
@media (min-width: 768px) {
  .checkout-billing-fieldset {
    margin-bottom: 35px;
  }
  .checkout-billing-fieldset p {
    margin-bottom: 20px;
  }
}

.rituals-payment-options {
  margin-bottom: 40px;
  padding-top: 10px;
  width: 100%;
}
.rituals-payment-options .hpp {
  list-style: none;
  width: 100%;
}
.rituals-payment-options .hpp li {
  position: relative;
}
.rituals-payment-options .checkout-payment-radio {
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 13px 20px 7px;
  width: 100%;
}
.rituals-payment-options .icon-holder {
  display: block;
  height: 40px;
  position: relative;
  text-align: center;
  width: 40px;
}
.rituals-payment-options .icon-holder .ico {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  margin: 0;
}
.rituals-payment-options .payment-method-name {
  color: #453f3f;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding-left: 20px;
  position: relative;
  text-align: left;
  width: calc(100% - 40px);
}
.rituals-payment-options .payment-method-name::before, .rituals-payment-options .payment-method-name::after {
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-50%);
}
.rituals-payment-options .payment-method-name::before {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  display: inline-block;
  height: 24px;
  right: -3px;
  width: 24px;
}
.rituals-payment-options .payment-method-name::after {
  background-color: #ffffff;
  height: 6px;
  right: 6px;
  width: 6px;
}
.rituals-payment-options .input-radio {
  border: 0;
  height: 1px;
  left: 0;
  margin-bottom: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
.rituals-payment-options .input-radio:checked ~ .payment-method-name::before {
  background-color: #886655;
}
.rituals-payment-options .payment-method-extra {
  display: none;
}
.rituals-payment-options .hpp-expandable-option {
  background-color: #ffffff;
  display: none;
  margin-bottom: 20px;
  padding: 22px 21px 20px;
  text-align: left;
  width: 100%;
}
.rituals-payment-options .hpp-expandable-option label {
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 3px;
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric {
  border: 1px solid #d8d8d8;
  padding: 8px 40px 8px 10px;
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric .label {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric-items {
  top: calc(100% - 2px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric-items ul {
  padding: 0;
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric-items ul li {
  padding: 13px 20px;
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric-items ul li:hover, .rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric-items ul li.selected {
  background-color: rgba(245, 244, 242, 0.6);
}
.rituals-payment-options .hpp-expandable-option .selectric-wrapper .selectric-items ul li:not(.last) {
  border-bottom: 1px solid rgba(69, 63, 63, 0.1);
}
@media (max-width: 767px) {
  .rituals-payment-options .payment-method-bank {
    background: transparent url("../images/icons/arrow3--down.svg") no-repeat right 10px center;
    background-size: 28px 30px;
    border: 1px solid #d8d8d8;
    color: #1a1919;
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
    padding: 8px 40px 8px 18px;
    text-align: left;
    text-align-last: left;
    text-transform: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
  }
  .rituals-payment-options .payment-method-bank::-ms-expand {
    display: none;
  }
}
@media (min-width: 768px) {
  .rituals-payment-options {
    margin: 0 auto 50px;
    max-width: 620px;
    padding-top: 14px;
  }
  .rituals-payment-options .checkout-payment-radio {
    transition: background 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .rituals-payment-options .checkout-payment-radio:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .rituals-payment-options .payment-method-name::before {
    right: 4px;
  }
  .rituals-payment-options .payment-method-name::after {
    right: 13px;
  }
  .rituals-payment-options .hpp-expandable-option {
    padding: 22px 21px 31px;
  }
}

.checkout-payment-button {
  margin-bottom: 60px;
}
.checkout-payment-button .btn-submit {
  width: auto;
}

.checkout-eurobonus-wrapper {
  margin: -13px 0 39px;
  width: 100%;
}
.checkout-eurobonus-wrapper .sas-eurobonus {
  position: relative;
  width: 100%;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox {
  height: 1px;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox + label {
  padding-left: 34px;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox + label::before {
  top: 0;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox + label::after {
  top: 7px;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox:checked + label::before {
  background-color: #886655;
  border-color: #886655;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox:checked + label::after {
  display: inline-block;
}
.checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox:disabled + label {
  opacity: 0.5;
}
.checkout-eurobonus-wrapper .sas-eurobonus label {
  color: #453f3f;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: relative;
}
.checkout-eurobonus-wrapper .sas-eurobonus label::before {
  background-color: rgba(136, 102, 85, 0);
  border: 1px solid #ccc;
  content: "";
  display: block;
  height: 22px;
  left: 0;
  position: absolute;
  width: 22px;
}
.checkout-eurobonus-wrapper .sas-eurobonus label::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea2e";
  display: none;
  font-size: 10px;
  left: 6px;
  line-height: 1;
  position: absolute;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown {
  padding: 11px 0 0 34px;
  position: relative;
  width: 100%;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown.eurobonus-valid::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #649b26;
  content: "\ea2f";
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: -2px;
  top: 25px;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .input-wrap {
  padding-right: 26px;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .error-text,
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .error-eurobonus {
  color: #d6122d;
  display: block;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: left;
  width: 100%;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .error-eurobonus {
  margin: -7px 0 2px;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .error-text {
  margin: -10px 0 16px;
}
.checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .inputfield-text {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 13px;
  padding: 12px 19px;
}
.checkout-eurobonus-wrapper .eurobonus-hint {
  color: #767676;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: left;
  padding-left: 34px;
  margin-top: -1px;
}
.checkout-eurobonus-wrapper .eurobonus-hint span {
  color: #886655;
  display: block;
}
.checkout-eurobonus-wrapper .eurobonus-hint a {
  font-size: 12px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .checkout-eurobonus-wrapper {
    margin: -17px auto 0;
    max-width: 620px;
  }
  .checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox + label::before {
    top: -3px;
  }
  .checkout-eurobonus-wrapper .sas-eurobonus .input-checkbox:checked + label::after {
    top: 4px;
  }
  .checkout-eurobonus-wrapper .checkout-eurobonus-dropdown {
    padding-top: 13px;
    margin-bottom: -3px;
  }
  .checkout-eurobonus-wrapper .checkout-eurobonus-dropdown.eurobonus-valid::before {
    right: 7px;
    top: 27px;
  }
  .checkout-eurobonus-wrapper .checkout-eurobonus-dropdown .input-wrap {
    padding-right: 37px;
  }
  .checkout-eurobonus-wrapper .eurobonus-hint {
    margin-top: 2px;
  }
}

.billing-address-header .form-subtitle {
  margin-bottom: 11px;
}
.billing-address-header .checkout-personal-information {
  margin: 0 0 20px;
}
.billing-address-header .checkout-personal-information strong {
  display: inline;
}

.billing-address-form.pickup-point .billing-address-checkbox {
  display: none;
}

.billing-address-form-inner {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}
.billing-address-form-inner .full-width-row + input[type=hidden] + .full-width-row {
  margin-top: 15px;
}

.credit-card-flyin .spinner-visible {
  bottom: 0;
  display: block;
  height: 10px;
  margin: auto;
  position: absolute;
  top: 0;
  z-index: 2;
}

.pay-swish {
  background: url("../images/pay-swish.png") center center no-repeat;
  background-size: 35px 45px;
  display: inline-block;
  height: 50px;
  width: 45px;
}

.pay-swish-small {
  background: url("../images/pay-swish.png") center center no-repeat;
  background-size: 20px 25px;
  display: inline-block;
  height: 40px;
  width: 40px;
}

.pay-vvv,
.pay-vvvcadeaubon {
  background: url("../images/pay-vvv.png") center center no-repeat;
  background-size: 35px 50px;
  display: inline-block;
  height: 64px;
  width: 64px;
}

.pay-vvv-small,
.pay-vvvcadeaubon-small {
  background: url("../images/pay-vvv.png") center center no-repeat;
  background-size: 20px 30px;
  display: inline-block;
  height: 40px;
  width: 40px;
}

.pay-illicado,
.pay-prosodie_illicado {
  background: url("../images/pay-illicado.png") center center no-repeat;
  background-size: 80px 40px;
  display: inline-block;
  height: 45px;
  width: 84px;
}

.pay-illicado-small,
.pay-prosodie_illicado-small {
  background: url("../images/pay-illicado.png") center center no-repeat;
  background-size: 38px 17px;
  display: inline-block;
  height: 17px;
  width: 38px;
}

.pt_account_loggedin .page-content {
  left: 0;
  position: relative;
  transition: left 0.6s ease-in-out;
  width: 100%;
}
.pt_account_loggedin .page-content .inner-wrapper {
  max-width: 1180px;
}
@media (max-width: 767px) {
  .pt_account_loggedin .page-content .inner-wrapper {
    padding: 0;
  }
}

.address-flyout {
  background-color: #fcfaf7;
}
.address-flyout .address-flyout-inner {
  padding: 37px 23px 30px;
  position: relative;
}
.address-flyout > .side--close {
  display: none;
}
.address-flyout .side--close {
  top: 11px;
  left: 17px;
}
.address-flyout .edit-address-title {
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 30px;
  margin: 0 auto 40px;
  max-width: 240px;
  text-align: center;
  text-transform: none;
}
.address-flyout .account-address-list .addresslist-fieldset-inner {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.address-flyout .account-address-list .pac-container {
  top: 100% !important;
}
.address-flyout .form-row-button {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.address-flyout .form-row-button .btn {
  margin: 0 auto;
}
.address-flyout .selectric-wrapper {
  width: 100%;
}
.address-flyout .selectric-wrapper .selectric-items {
  width: 100% !important;
}
.address-flyout .error-message {
  color: #626262;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .address-flyout .form-row-button .cancel-button {
    margin-top: 20px;
    order: 2;
  }
  .address-flyout .form-row-button .delete-button {
    margin-top: 20px;
    order: 3;
  }
}
@media (min-width: 768px) {
  .address-flyout.generic-dialog-flyout {
    max-width: 720px;
  }
  .address-flyout .address-flyout-inner {
    padding: 60px 70px 30px;
  }
  .address-flyout .edit-address-title {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    max-width: 400px;
  }
  .address-flyout .full-width-row.three-inline {
    width: calc(33% - 10px);
  }
  .address-flyout .form-row-button {
    flex-wrap: nowrap;
    font-size: 0;
    justify-content: center;
    width: 100%;
  }
  .address-flyout .form-row-button .btn {
    margin: 0 10px;
    min-width: 10px;
    width: calc(34.2% - 20px);
  }
  .address-flyout .form-row-button .btn:first-child {
    margin-left: 0;
  }
  .address-flyout .form-row-button .btn:last-child {
    margin-right: 0;
  }
}

.account-wrapper {
  display: flex;
  padding: 44px 0 113px;
  width: 100%;
}
.account-wrapper .account-title {
  border-bottom: 1px solid #d8d8d8;
  font-size: 24px;
  letter-spacing: 6px;
  line-height: 30px;
  padding: 0 0 46px 60px;
  position: relative;
}
.account-wrapper .account-title .icon {
  font-size: 46px;
  left: -3px;
  line-height: 1;
  position: absolute;
  top: -7px;
}
.account-wrapper .account-title.account-title-mobile {
  display: none;
}
@media (min-width: 768px) {
  .account-wrapper {
    flex-wrap: wrap;
    padding-top: 60px;
  }
  .account-wrapper .account-title {
    border-bottom: 0;
    cursor: pointer;
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
    margin-bottom: 0;
    padding: 0 0 39px 70px;
    width: 100%;
  }
  .account-wrapper .account-title .icon {
    top: -3px;
  }
  .account-wrapper .account-title.account-title-mobile {
    display: block;
  }
}

.app-block a {
  text-decoration: none;
}
.app-block .image {
  width: 100%;
}
@media (min-width: 768px) {
  .app-block .image {
    max-width: 360px;
  }
}
.app-block img {
  display: block;
  margin-bottom: 40px;
  max-width: 100%;
}
.app-block .block-title {
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
  margin-bottom: 6px;
}
.app-block p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.app-block .store__btns {
  padding-top: 21px;
}
.app-block .store__btns a {
  float: left;
  margin-right: 20px;
}
.app-block .store__btns a:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .app-block img {
    margin-bottom: 36px;
  }
}

.account-left-content {
  width: 100%;
}
.account-left-content .account-menu-wrap {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 30px;
  padding: 3px 0 12px;
  width: 100%;
}
.account-left-content .account-menu-wrap .account-logout {
  color: #626262;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  padding: 10px 0 10px 45px;
  position: relative;
  text-decoration: none;
  text-transform: none;
}
.account-left-content .account-menu-wrap .account-logout:hover {
  color: #9a7b69;
}
.account-left-content .account-menu-wrap .account-logout.active {
  color: #1a1919;
}
.account-left-content .account-menu-wrap .account-logout.active .icon {
  color: #1a1919;
}
.account-left-content .account-menu-wrap .account-logout .icon-arrow5--left {
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 27px;
  left: 2px;
  margin-top: 1px;
}
@media (max-width: 767px) {
  .account-left-content {
    flex: 0 0 100%;
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .account-left-content {
    width: 31.6%;
  }
  .account-left-content .account-title {
    display: none;
  }
  .account-left-content .account-menu-wrap {
    border-top: 1px solid #d8d8d8;
    margin-bottom: 40px;
    padding: 33px 0 32px;
  }
  .account-left-content .account-membership-cart {
    display: none;
  }
}

.account-menu {
  list-style: none;
  width: 100%;
}
.account-menu li {
  display: block;
  margin-bottom: 15px;
}
.account-menu a {
  color: #626262;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  padding: 10px 0 10px 45px;
  position: relative;
  text-decoration: none;
  text-transform: none;
}
.account-menu a:hover {
  color: #9a7b69;
}
.account-menu a.active {
  color: #1a1919;
}
.account-menu a.active .icon {
  color: #1a1919;
}
.account-menu a .icon {
  color: rgba(0, 0, 0, 0.6);
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.account-menu a .icon-data {
  font-size: 18px;
  left: 3px;
}
.account-menu a .icon-cart {
  font-size: 19px;
  left: 2px;
  margin-top: 1px;
}
.account-menu a .icon-cities {
  font-size: 24px;
  margin-top: 1px;
}
.account-menu a .icon-newsletter {
  font-size: 22px;
  left: 2px;
  margin-top: 2px;
}

.create-account-wrapper {
  padding: 40px 0 0;
}
@media (min-width: 768px) {
  .create-account-wrapper {
    padding: 108px 0 80px;
  }
}
.create-account-wrapper .inner-wrapper {
  padding: 0;
  max-width: 1180px;
}
@media (min-width: 768px) {
  .create-account-wrapper .inner-wrapper {
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .create-account-wrapper .inner-wrapper {
    padding: 0 50px;
    max-width: 1440px;
  }
}
.create-account-wrapper .create-account-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-container {
    flex-direction: row;
  }
}
.create-account-wrapper .create-account-left-content {
  max-width: 653px;
  padding: 0 20px;
  flex: 1;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content {
    margin-right: 70px;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .create-account-wrapper .create-account-left-content {
    margin-right: 130px;
  }
}
.create-account-wrapper .create-account-left-content h2.h4 {
  border-bottom: 1px solid #d8d8d8;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: none;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.create-account-wrapper .create-account-left-content .form-row {
  position: relative;
}
.create-account-wrapper .create-account-left-content .form-caption {
  font-size: 12px;
  color: #767676;
}
.create-account-wrapper .create-account-left-content .label-inline {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content .label-inline {
    margin-top: 32px;
  }
}
.create-account-wrapper .create-account-left-content .label-inline.required {
  margin-top: 38px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content .label-inline.required {
    margin-top: 38px;
  }
}
.create-account-wrapper .create-account-left-content .label-inline.required:not(.hide) + .label-inline.required {
  margin-top: 15px;
}
.create-account-wrapper .create-account-left-content .label-inline .input-row input[type=checkbox].focus-visible + label.floating::before {
  top: 2px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content .label-inline .input-row input[type=checkbox].focus-visible + label.floating::before {
    top: -1px;
  }
}
.create-account-wrapper .create-account-left-content .label-inline .input-row label.floating {
  line-height: 28px;
  padding-left: 28px;
}
.create-account-wrapper .create-account-left-content .label-inline .input-row label.floating::before, .create-account-wrapper .create-account-left-content .label-inline .input-row label.floating::after {
  top: 5px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content .label-inline .input-row label.floating::before, .create-account-wrapper .create-account-left-content .label-inline .input-row label.floating::after {
    top: 2px;
  }
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content .label-inline .input-row label.floating {
    line-height: 26px;
  }
  .create-account-wrapper .create-account-left-content .label-inline .input-row label.floating a {
    line-height: 22px;
  }
}
.create-account-wrapper .create-account-left-content .btn-container,
.create-account-wrapper .create-account-left-content .form-row-button {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-left-content .btn-container,
  .create-account-wrapper .create-account-left-content .form-row-button {
    margin-bottom: 0;
  }
}
.create-account-wrapper .create-account-left-content .selectric-wrapper:not(.selectric-inputfield-select) .selectric .label {
  padding-left: 0;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.create-account-wrapper .create-account-left-content .selectric-wrapper:not(.selectric-inputfield-select) .selectric .button {
  top: 9px;
  right: -7px;
}
.create-account-wrapper .create-account-left-content .selectric-wrapper:not(.selectric-inputfield-select) .selectric-items li {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.create-account-wrapper .create-account-right-content .secondary-navigation .content-asset {
  background-color: #ffffff;
  padding: 37px 40px 57px 20px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-right-content .secondary-navigation .content-asset {
    max-width: 360px;
    padding: 37px 39px 9px 42px;
  }
}
.create-account-wrapper .create-account-right-content .secondary-navigation ul {
  list-style: none;
}
.create-account-wrapper .create-account-right-content .secondary-navigation ul li {
  margin-bottom: -5px;
}
.create-account-wrapper .create-account-right-content .secondary-navigation ul li a {
  color: #9a7b69;
}
.create-account-wrapper .create-account-right-content .secondary-navigation ul li:last-of-type {
  margin-bottom: 4px;
}
.create-account-wrapper .create-account-right-content .secondary-navigation h3 {
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 16px;
  color: #1a1919;
  text-transform: uppercase;
}
.create-account-wrapper .create-account-right-content .secondary-navigation h3:nth-of-type(3) {
  margin-top: 0;
  margin-bottom: 17px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-right-content .secondary-navigation h3:nth-of-type(3) {
    margin-top: -3px;
    margin-bottom: 16px;
  }
}
.create-account-wrapper .create-account-right-content .secondary-navigation p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .create-account-wrapper .create-account-right-content .secondary-navigation p {
    padding-right: 20px;
  }
}
.create-account-wrapper .create-account-right-content .secondary-navigation b {
  font-weight: 700;
}
.create-account-wrapper .create-account-right-content .secondary-navigation span {
  font-size: 14px;
  letter-spacing: 0.88px;
  line-height: 17px;
  display: inline-block;
  width: calc(100% - 30px);
  margin-bottom: 17px;
  vertical-align: top;
}
.create-account-wrapper .create-account-right-content .secondary-navigation .icon-check {
  font-size: 10px;
  color: #886655;
  margin-right: 15px;
  width: 14px;
}
.create-account-wrapper .label-inline .input-row,
.create-account-wrapper .label-inline .inputfield-row {
  position: relative;
  width: 100%;
  margin-top: 11px;
}
.create-account-wrapper .label-inline .input-row label.floating {
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  padding-left: 25px;
  position: relative;
  color: #453f3f;
  cursor: pointer;
}

.account-wrapper .account-right-content {
  width: 100%;
}
.account-wrapper .account-right-content .create-account-wrapper {
  background-image: none;
  padding: 0;
}
.account-wrapper .account-right-content .create-account-container {
  display: block;
}
.account-wrapper .account-right-content .create-account-container .create-account-left-content {
  max-width: none;
  width: 100%;
  padding: 0;
}
.account-wrapper .account-right-content .btn-container {
  margin-top: 30px;
  max-width: none;
}
.account-wrapper .account-right-content .block-member-card {
  display: none;
  margin-bottom: 44px;
}
.account-wrapper .account-right-content .block-member-card .icon-user {
  font-size: 28px;
  top: -11px;
  left: -1px;
}
.account-wrapper .account-right-content .block-member-card .account-membership-cart {
  margin: 0;
}
.account-wrapper .account-right-content .dashboard-row {
  width: 100%;
}
.account-wrapper .account-right-content .dashboard-row .dashboard-block.loyalty-card {
  margin-bottom: 44px;
}
@media (min-width: 768px) {
  .account-wrapper .account-right-content .dashboard-row .dashboard-block.loyalty-card {
    margin-bottom: 87px;
  }
}
.account-wrapper .account-right-content .dashboard-row .dashboard-block.loyalty-card .section-title {
  margin-bottom: 32px;
  padding-left: 47px;
}
.account-wrapper .account-right-content .dashboard-row .dashboard-block.loyalty-card .section-title .icon-user {
  font-size: 27px;
  left: 2px;
  top: -12px;
}
@media (max-width: 767px) {
  .account-wrapper .account-right-content .dashboard-row .dashboard-block.loyalty-card .account-membership-cart {
    display: none;
  }
}
.account-wrapper .account-right-content .section-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 2.6px;
  line-height: 1;
  margin-bottom: 22px;
  padding-left: 44px;
  position: relative;
  text-transform: uppercase;
}
.account-wrapper .account-right-content .section-title .icon {
  position: absolute;
}
.account-wrapper .account-right-content .section-title .icon-cart-basket {
  font-size: 18px;
  left: 2px;
  top: -1px;
}
.account-wrapper .account-right-content .section-title .icon-data {
  font-size: 18px;
  left: 1px;
  top: -2px;
}
.account-wrapper .account-right-content .section-title .icon-cities {
  font-size: 22px;
  left: 0;
  top: -4px;
}
.account-wrapper .account-right-content .section-title .icon-newsletter {
  font-size: 22px;
  left: 4px;
  top: -4px;
}
.account-wrapper .account-right-content .section-title h2 {
  font-size: 16px;
  letter-spacing: 2.6px;
  line-height: 16px;
  margin-bottom: 0;
}
.account-wrapper .account-right-content .newsletter-subsection h2 {
  font-size: 16px;
  letter-spacing: 2.6px;
  line-height: 16px;
  margin-bottom: 0;
}
.account-wrapper .account-right-content .newsletter-country-selector,
.account-wrapper .account-right-content .country-selector,
.account-wrapper .account-right-content .language-selector {
  margin-bottom: 20px;
}
.account-wrapper .account-right-content .newsletter-country-selector select,
.account-wrapper .account-right-content .country-selector select,
.account-wrapper .account-right-content .language-selector select {
  width: 100%;
  color: #453f3f;
  background-position: left;
  padding-left: 24px;
  border-bottom: 1px solid #453f3f;
}
.account-wrapper .account-right-content .newsletter-country-selector select.language,
.account-wrapper .account-right-content .country-selector select.language,
.account-wrapper .account-right-content .language-selector select.language {
  padding-left: 5px;
}
.account-wrapper .account-right-content .newsletter-country-selector .arrow-down,
.account-wrapper .account-right-content .country-selector .arrow-down,
.account-wrapper .account-right-content .language-selector .arrow-down {
  float: right;
  top: -30px;
  position: relative;
}
.account-wrapper .account-right-content .newsletter-country-selector .arrow-down i,
.account-wrapper .account-right-content .country-selector .arrow-down i,
.account-wrapper .account-right-content .language-selector .arrow-down i {
  color: #453f3f;
  font-size: 28px;
}
.account-wrapper .account-right-content .newsletter-country-selector .arrow-down i:before,
.account-wrapper .account-right-content .country-selector .arrow-down i:before,
.account-wrapper .account-right-content .language-selector .arrow-down i:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
}
.account-wrapper .account-right-content .policyCheckbox {
  display: none;
}
.account-wrapper .account-right-content .recent-order {
  margin-bottom: 45px;
}
.account-wrapper .account-right-content .order-block {
  background-color: #fcfaf7;
  position: relative;
  width: 100%;
}
.account-wrapper .account-right-content .order-block .block-inner-wrapper {
  display: flex;
  padding: 14px;
  position: relative;
}
.account-wrapper .account-right-content .order-block .block-inner-wrapper::before {
  background-color: #d6122d;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
.account-wrapper .account-right-content .order-block .order-image {
  text-align: center;
  width: 70px;
}
.account-wrapper .account-right-content .order-block .order-image img {
  max-width: 100%;
}
.account-wrapper .account-right-content .order-block .order-details {
  padding: 0 5px 0 15px;
  width: calc(100% - 114px);
}
.account-wrapper .account-right-content .order-block .order-detail-item {
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
}
.account-wrapper .account-right-content .order-block .date {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 29px;
  margin-bottom: 5px;
}
.account-wrapper .account-right-content .order-block .number {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-bottom: 18px;
}
.account-wrapper .account-right-content .order-block .order-arrow {
  border-left: 1px solid #d8d8d8;
  margin: 5px 0;
  position: relative;
  width: 44px;
}
.account-wrapper .account-right-content .order-block .order-arrow span {
  color: #1a1919;
  font-size: 42px;
  position: absolute;
  right: -7px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .account-wrapper .account-right-content .order-block .order-arrow span {
    right: -12px;
  }
}
.account-wrapper .account-right-content .personal-details {
  margin-bottom: 85px;
}
.account-wrapper .account-right-content .personal-details .block-inner-wrapper span {
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 12px;
}
.account-wrapper .account-right-content .address-book {
  margin-bottom: 70px;
}
.account-wrapper .account-right-content .address-book .mini-address-location {
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 32px;
  margin-top: -3px;
}
.account-wrapper .account-right-content .address-book a {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
.account-wrapper .account-right-content .back-button {
  position: absolute;
  top: -33px;
  left: 8px;
  display: none;
}
@media (max-width: 767px) {
  .account-wrapper .account-right-content .back-button {
    display: block;
  }
}
.account-wrapper .account-right-content .pt_recommended-products {
  overflow: hidden;
}
.account-wrapper .account-right-content .pt_recommended-products .recommendations-block-wrapper {
  width: 100%;
}
@media (max-width: 767px) {
  .account-wrapper .account-right-content {
    display: none;
    flex: 0 0 100%;
    padding: 34px 20px;
    position: relative;
  }
  .account-wrapper .account-right-content .mobile-back-link {
    display: block;
  }
}
@media (min-width: 768px) {
  .account-wrapper .account-right-content {
    border-top: 1px solid #d8d8d8;
    margin-left: auto;
    padding-top: 44px;
    width: 66%;
  }
  .account-wrapper .account-right-content .personal-details-container {
    margin-left: 23px;
  }
  .account-wrapper .account-right-content .personal-details-container .section-title {
    margin-left: -47px;
  }
  .account-wrapper .account-right-content .section-title {
    margin-bottom: 23px;
    padding-left: 47px;
  }
  .account-wrapper .account-right-content .section-title .icon-data {
    left: 6px;
    top: -1px;
  }
  .account-wrapper .account-right-content .section-title .icon-cities {
    font-size: 24px;
    left: 3px;
  }
  .account-wrapper .account-right-content .recent-order {
    margin-bottom: 84px;
  }
  .account-wrapper .account-right-content .recent-order .order-block .order-arrow {
    opacity: 0;
  }
  .account-wrapper .account-right-content .order-block {
    margin-left: 46px;
    width: calc(100% - 46px);
  }
  .account-wrapper .account-right-content .order-block .block-inner-wrapper {
    padding: 26px 24px;
  }
  .account-wrapper .account-right-content .order-block .order-image {
    width: 100px;
  }
  .account-wrapper .account-right-content .order-block .order-details {
    padding: 0 5px 0 36px;
    width: calc(100% - 144px);
  }
  .account-wrapper .account-right-content .order-block .date {
    margin-bottom: 10px;
  }
  .account-wrapper .account-right-content .order-block .number {
    margin-bottom: 17px;
  }
  .account-wrapper .account-right-content .order-block .size,
  .account-wrapper .account-right-content .order-block .price,
  .account-wrapper .account-right-content .order-block .status {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    vertical-align: top;
  }
  .account-wrapper .account-right-content .order-block .size::after,
  .account-wrapper .account-right-content .order-block .price::after,
  .account-wrapper .account-right-content .order-block .status::after {
    content: "|";
    margin-left: 5px;
  }
  .account-wrapper .account-right-content .order-block .size:last-child,
  .account-wrapper .account-right-content .order-block .price:last-child,
  .account-wrapper .account-right-content .order-block .status:last-child {
    margin-right: 0;
  }
  .account-wrapper .account-right-content .order-block .size:last-child::after,
  .account-wrapper .account-right-content .order-block .price:last-child::after,
  .account-wrapper .account-right-content .order-block .status:last-child::after {
    display: none;
  }
  .account-wrapper .account-right-content .personal-details,
  .account-wrapper .account-right-content .address-book {
    float: left;
    width: 50%;
  }
  .account-wrapper .account-right-content .personal-details .section-title,
  .account-wrapper .account-right-content .address-book .section-title {
    margin-bottom: 26px;
  }
  .account-wrapper .account-right-content .personal-details .block-inner-wrapper,
  .account-wrapper .account-right-content .address-book .block-inner-wrapper {
    padding-left: 47px;
  }
  .account-wrapper .account-right-content .address-book {
    padding-left: 13px;
  }
  .account-wrapper .account-right-content .account-usp {
    display: none;
  }
}

.personal-details-container {
  width: 100%;
}

.address-list-container {
  padding: 16px 0 80px;
  width: 100%;
}
.address-list-container p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.address-list-container .btn-container {
  padding-top: 24px;
}
.address-list-container .address-list {
  list-style: none;
  width: 100%;
}
.address-list-container .address-list .address-tile {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 38px;
  padding-bottom: 36px;
}
.address-list-container .address-list .address-tile.default {
  border-bottom: 0;
  margin-bottom: 37px;
  padding-bottom: 0;
}
.address-list-container .address-list .address-tile.default .address-wrapper {
  background-color: #fcfaf7;
  padding: 20px;
}
.address-list-container .address-list .address-tile.default:last-child .secondary-title {
  display: none;
}
.address-list-container .address-list .address-tile.default:last-child .address-wrapper {
  background-color: transparent;
  padding: 0;
}
.address-list-container .address-list .address-tile:last-child {
  border-bottom: 0;
  margin-bottom: 15px;
  padding-bottom: 0;
}
.address-list-container .address-list .secondary-title {
  color: #453f3f;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.address-list-container .address-list .address-wrapper {
  color: #453f3f;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
}
.address-list-container .address-list .address-wrapper .mini-address-name + .mini-address-name {
  display: none;
}
.address-list-container .address-list .mini-address-name {
  margin-bottom: 6px;
}
.address-list-container .address-list .mini-address-location {
  margin-bottom: 25px;
}
.address-list-container .address-list .mini-address-location address {
  font-size: 16px;
  line-height: 32px;
}
.address-list-container .address-list .address-actions a {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-right: 15px;
  position: relative;
  text-transform: uppercase;
}
.address-list-container .address-list .address-actions a::after {
  color: #d8d8d8;
  content: "|";
  font-size: 13px;
  position: absolute;
  right: -13px;
  top: 0;
  text-decoration: underline;
}
.address-list-container .address-list .address-actions a:last-child {
  margin-right: 0;
}
.address-list-container .address-list .address-actions a:last-child::after {
  display: none;
}
.address-list-container .address-list .address-actions a::after {
  text-decoration: none;
}
@media (min-width: 768px) {
  .address-list-container {
    padding: 17px 0 47px 47px;
  }
  .address-list-container .btn-container {
    padding-top: 20px;
  }
  .address-list-container .btn-container .btn {
    width: auto;
  }
  .address-list-container .address-list {
    padding-left: 47px;
  }
  .address-list-container .address-list.account-address-list {
    padding-left: 0;
  }
  .address-list-container .address-list .address-tile {
    padding-bottom: 28px;
  }
  .address-list-container .address-list .address-tile.default {
    padding-bottom: 0;
  }
  .address-list-container .address-list .address-tile.default .address-wrapper {
    padding: 42px 40px 28px;
  }
  .address-list-container .address-list .address-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .address-list-container .address-list .address-wrapper .mini-address-name {
    width: 45%;
  }
  .address-list-container .address-list .address-wrapper .mini-address-location {
    margin-bottom: 0;
    order: 3;
    width: 45%;
  }
  .address-list-container .address-list .address-wrapper .address-actions {
    margin-top: -4px;
    order: 2;
    text-align: right;
    width: 55%;
  }
  .address-list-container .address-list + .btn-container {
    padding: 53px 0 0 47px;
  }
}

.subscriptions .newsletter {
  padding: 9px 0 78px;
}
.subscriptions .newsletter p,
.subscriptions .newsletter .success {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.subscriptions .newsletter a {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.subscriptions .newsletter .newsletter-checkbox {
  left: 0;
  position: absolute;
  top: 1px;
}
.subscriptions .newsletter .newsletter-checkbox input.focus-visible + label::before {
  top: -3px;
}
.subscriptions .newsletter .newsletter-checkbox input.focus-visible + label::after {
  top: 0;
}
.subscriptions .newsletter .newsletter-checkbox label {
  display: block;
  height: 16px;
  position: relative;
  width: 16px;
}
.subscriptions .newsletter .newsletter-checkbox label::before, .subscriptions .newsletter .newsletter-checkbox label::after {
  top: 0;
}
.subscriptions .newsletter.optin-wrapper .signup-label {
  display: block;
  margin: 29px 0 34px;
  padding-left: 32px;
  position: relative;
}
.subscriptions .newsletter.optin-wrapper .newsletter-label {
  display: block;
  line-height: 20px;
  cursor: pointer;
}
.subscriptions .newsletter .btn-container .btn {
  margin-bottom: 20px;
}
.subscriptions .newsletter .btn-container .linked-email-container {
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .subscriptions .newsletter {
    margin-top: -4px;
    padding: 0 0 0 46px;
  }
  .subscriptions .newsletter .btn-container {
    align-items: center;
    display: flex;
  }
  .subscriptions .newsletter .btn-container .btn {
    margin: 0 40px 0 0;
    width: auto;
  }
}

.orders-container {
  padding: 15px 0 60px;
}
.orders-container .order-block {
  margin-bottom: 20px;
}
.orders-container .order-dropdown-details {
  display: none;
}
.orders-container .order-dropdown-details .product-row {
  align-items: flex-start;
  border-top: 1px solid #d8d8d8;
  display: flex;
  margin: 0 0 20px 20px;
  padding-top: 30px;
  width: calc(100% - 40px);
}
.orders-container .order-dropdown-details .product-row:first-child {
  border-top: 0;
  margin-top: 35px;
  padding-top: 0;
}
.orders-container .order-dropdown-details .product-row .product-image {
  text-align: center;
  width: 60px;
}
.orders-container .order-dropdown-details .product-row .product-image img {
  max-width: 100%;
}
.orders-container .order-dropdown-details .product-row .orders-details-right {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  width: calc(100% - 60px);
}
.orders-container .order-dropdown-details .product-row .the-ritual-of-title {
  color: #886655;
  font-size: 11px;
  letter-spacing: 1.65px;
  line-height: 24px;
  width: 100%;
}
.orders-container .order-dropdown-details .product-row .product-name {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 2.6px;
  line-height: 20px;
  margin-bottom: 5px;
  text-transform: uppercase;
  width: 100%;
}
.orders-container .order-dropdown-details .product-row .hairtemple-product-options {
  color: #453f3f;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 9px 0 0;
  text-transform: none;
}
.orders-container .order-dropdown-details .product-row .product-variation {
  color: #453f3f;
  display: none;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 5px;
  width: 100%;
}
.orders-container .order-dropdown-details .product-row .product-amount {
  font-size: 20px;
  line-height: 24px;
}
.orders-container .order-dropdown-details .product-row .product-price {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  margin-left: auto;
  text-align: right;
}
.orders-container .order-dropdown-details .product-row .product-price .price-regular {
  display: block;
}
.orders-container .order-dropdown-details .product-row .product-price .price-value-unadjusted {
  display: block;
  color: #8c8c8c;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: normal;
  text-decoration: line-through;
}
.orders-container .order-dropdown-details .totals-block {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
}
.orders-container .order-dropdown-details .totals-block .price-row {
  color: #886655;
  display: flex;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 10px;
  width: 100%;
}
.orders-container .order-dropdown-details .totals-block .price-row .value {
  margin-left: auto;
}
.orders-container .order-dropdown-details .totals-block .price-row .item {
  margin-right: 5px;
}
.orders-container .order-dropdown-details .totals-block .summary-row {
  border-bottom: 1px solid #d8d8d8;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  line-height: 30px;
  margin-bottom: 27px;
  padding-bottom: 21px;
  text-transform: uppercase;
}
.orders-container .order-dropdown-details .totals-block .total {
  border-top: 1px solid #453f3f;
  color: #1a1919;
  font-family: "ClassGarmnd BT Bold", "Times New Roman", serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin: 33px 0 20px;
  padding-top: 30px;
}
.orders-container .order-dropdown-details .totals-block .total .item {
  text-transform: uppercase;
}
.orders-container .order-dropdown-details .totals-block .download-invoice {
  margin: 23px 0 0 2px;
}
.orders-container .order-dropdown-details .totals-block .download-invoice a {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.orders-container .search-result-options {
  display: block;
  height: auto;
  margin-left: 0;
  padding-top: 14px;
  text-align: center;
  width: 100%;
}
.orders-container .search-result-options ul {
  width: 100%;
}
.orders-container .search-result-options ul li {
  color: #453f3f;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 0 -2px;
  padding: 0 12px;
  position: relative;
}
.orders-container .search-result-options ul li::after {
  background-color: #d8d8d8;
  content: "";
  height: 16px;
  position: absolute;
  right: 0;
  top: 6px;
  width: 1px;
}
.orders-container .search-result-options ul li:last-child::after {
  display: none;
}
.orders-container .search-result-options ul a {
  color: #9a7b69;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .orders-container {
    padding: 18px 35px 0 46px;
  }
  .orders-container .order-block .block-inner-wrapper {
    cursor: pointer;
  }
  .orders-container .order-block .order-arrow .icon {
    transition: transform 0.3s ease;
  }
  .orders-container .order-block.active .order-arrow .icon {
    transform: translateY(-50%) rotate(-180deg);
  }
  .orders-container .order-dropdown-details {
    padding: 40px 60px 0 40px;
  }
  .orders-container .order-dropdown-details .product-row {
    padding: 25px 0 15px 15px;
    margin: 0 0 20px;
    width: 100%;
  }
  .orders-container .order-dropdown-details .product-row:first-child {
    margin: 0;
  }
  .orders-container .order-dropdown-details .product-row .product-image {
    width: 70px;
  }
  .orders-container .order-dropdown-details .product-row .orders-details-right {
    padding: 10px 0 0 35px;
    width: calc(100% - 70px);
  }
  .orders-container .order-dropdown-details .product-row .product-name,
  .orders-container .order-dropdown-details .product-row .product-variation,
  .orders-container .order-dropdown-details .product-row .the-ritual-of-title {
    order: 1;
    width: calc(100% - 180px);
  }
  .orders-container .order-dropdown-details .product-row .product-amount {
    margin-left: auto;
    order: 2;
    text-align: center;
    width: 60px;
  }
  .orders-container .order-dropdown-details .product-row .product-price {
    margin-left: 0;
    order: 2;
    width: 90px;
  }
  .orders-container .order-dropdown-details .product-row .product-price .price-value-unadjusted {
    line-height: 24px;
  }
  .orders-container .order-dropdown-details .totals-block {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 -40px;
    padding: 40px 50px 30px;
    width: calc(100% + 100px);
  }
  .orders-container .order-dropdown-details .totals-block .price-row {
    max-width: 286px;
  }
  .orders-container .order-dropdown-details .totals-block .total {
    margin: 23px 0 20px;
  }
  .orders-container .order-dropdown-details .totals-block .download-invoice {
    align-self: flex-start;
    margin: -40px 0 0 -10px;
  }
  .orders-container .search-result-options {
    padding-left: 46px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .recommendations-block-wrapper .product-tile {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .recommendations-block-wrapper .product-image {
    width: 100%;
  }
  .recommendations-block-wrapper .product-image a {
    display: block;
    width: 100%;
  }
}

.newsletter-subscribe-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
@media (max-width: 767px) {
  .newsletter-subscribe-wrapper {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper {
    flex-direction: row;
  }
}
.newsletter-subscribe-wrapper .newsletter-left-content,
.newsletter-subscribe-wrapper .newsletter-right-content {
  width: 100%;
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper .newsletter-left-content,
  .newsletter-subscribe-wrapper .newsletter-right-content {
    width: 50%;
  }
}
.newsletter-subscribe-wrapper .newsletter-right-content {
  padding: 30px 30px 60px;
}
@media screen and (min-width: 376px) {
  .newsletter-subscribe-wrapper .newsletter-right-content {
    padding: 40px 50px;
  }
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper .newsletter-right-content {
    padding: 60px 30px;
  }
}
@media (min-width: 1024px) {
  .newsletter-subscribe-wrapper .newsletter-right-content {
    padding: 80px 60px;
  }
}
.newsletter-subscribe-wrapper .newsletter-right-content .inner-wrapper {
  max-width: 600px;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper .newsletter-left-content {
    flex: 1;
    display: flex;
    height: auto;
  }
}
.newsletter-subscribe-wrapper .newsletter-heading {
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  letter-spacing: 4px;
  margin-bottom: 11px;
  text-transform: none;
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper .newsletter-heading {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin-bottom: 18px;
  }
}
.newsletter-subscribe-wrapper p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: -4px;
}
@media screen and (min-width: 376px) {
  .newsletter-subscribe-wrapper p {
    margin-bottom: 9px;
    max-width: 525px;
  }
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper p {
    margin-bottom: 3px;
  }
}
@media (min-width: 1024px) {
  .newsletter-subscribe-wrapper p {
    margin-bottom: 25px;
  }
}
.newsletter-subscribe-wrapper .form-caption {
  font-size: 12px;
  color: #767676;
}
.newsletter-subscribe-wrapper .form-row .input-row {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .newsletter-subscribe-wrapper .form-row .input-row {
    margin-top: 24px;
  }
}
.newsletter-subscribe-wrapper .form-row .input-row input {
  background-color: transparent;
}
.newsletter-subscribe-wrapper .form-row:nth-of-type(7) {
  margin-bottom: 39px;
}
@media screen and (min-width: 376px) {
  .newsletter-subscribe-wrapper .form-row:nth-of-type(7) {
    margin-bottom: 21px;
  }
}
@media (min-width: 1024px) {
  .newsletter-subscribe-wrapper .form-row:nth-of-type(7) {
    margin-bottom: 32px;
  }
}
.newsletter-subscribe-wrapper .form-row.label-inline .input-row,
.newsletter-subscribe-wrapper .form-row.label-inline .inputfield-row {
  position: relative;
  width: 100%;
  margin-top: 11px;
}
.newsletter-subscribe-wrapper .form-row.label-inline .input-row .error {
  padding-left: 25px;
  display: block;
}
.newsletter-subscribe-wrapper .form-row-button {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .newsletter-subscribe-wrapper .form-row-button {
    text-align: center;
  }
}
.newsletter-subscribe-wrapper .form-row-button .btn {
  width: 100%;
}
@media screen and (min-width: 376px) {
  .newsletter-subscribe-wrapper .form-row-button .btn {
    width: auto;
  }
}
.newsletter-subscribe-wrapper .newsletter-error {
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}

.product-sample-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .product-sample-form {
    flex-direction: row;
  }
}
.product-sample-form .inner-wrapper {
  margin: 0;
  max-width: 600px;
  padding: 0;
}
.product-sample-form .newsletter-left-content,
.product-sample-form .newsletter-right-content {
  width: 100%;
}
@media (min-width: 768px) {
  .product-sample-form .newsletter-left-content,
  .product-sample-form .newsletter-right-content {
    width: 50%;
  }
}
.product-sample-form .newsletter-left-content {
  height: 180px;
}
@media (min-width: 768px) {
  .product-sample-form .newsletter-left-content {
    flex: 1;
    display: flex;
    height: auto;
  }
}
.product-sample-form .newsletter-right-content {
  padding: 30px 30px 60px;
}
@media (min-width: 768px) {
  .product-sample-form .newsletter-right-content {
    padding: 60px 30px;
  }
}
@media (min-width: 1024px) {
  .product-sample-form .newsletter-right-content {
    padding: 80px 60px;
  }
}
.product-sample-form h1.h3 {
  font-size: 20px;
  line-height: 1.3;
  color: #000000;
  letter-spacing: 3.3px;
  margin-bottom: 11px;
}
@media (min-width: 768px) {
  .product-sample-form h1.h3 {
    font-size: 28px;
    letter-spacing: 7.5px;
    margin-bottom: 5px;
  }
}
@media (min-width: 1024px) {
  .product-sample-form h1.h3 {
    font-size: 30px;
    letter-spacing: 8px;
    margin-bottom: 16px;
  }
}
.product-sample-form p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .product-sample-form p {
    margin-bottom: 3px;
  }
}
@media (min-width: 1024px) {
  .product-sample-form p {
    margin-bottom: 13px;
  }
}
.product-sample-form fieldset .form-row:first-child {
  margin: 20px 0 40px;
}
.product-sample-form fieldset .form-row label {
  cursor: pointer;
}
.product-sample-form .form-row.label-inline .error {
  padding-left: 25px;
  display: block;
}
.product-sample-form .form-row-button {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .product-sample-form .form-row-button .btn {
    width: 100%;
  }
}
.product-sample-form .newsletter-error {
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}

.checkout-register-wrapper .personal-details-container {
  text-align: left;
  margin-top: 27px;
}
.login-checkout-v3 .checkout-register-wrapper .personal-details-container {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .login-checkout-v3 .checkout-register-wrapper .personal-details-container {
    margin-top: 0;
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .checkout-register-wrapper .personal-details-container {
    margin-top: 0;
    padding: 40px;
  }
}
.checkout-register-wrapper .personal-details-container .rituals-form .form-row-button {
  margin-top: 42px;
  text-align: center;
}
.login-checkout-v3 .checkout-register-wrapper .personal-details-container .rituals-form .btn {
  max-width: none;
  width: 100%;
}
@media (max-width: 767px) {
  .login-checkout-v3 .checkout-register-wrapper .page-layout-right {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .checkout-register-wrapper .inner-wrapper-small {
    max-width: none;
  }
}
.checkout-register-wrapper .label-inline.required {
  margin-top: 40px;
}
.checkout-register-wrapper .label-inline.required:not(.hide) + .label-inline.required {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .checkout-register-wrapper {
    padding: 57px 20px 100px;
  }
  .login-checkout-v3 .checkout-register-wrapper {
    padding: 57px 0 0 0;
  }
  .checkout-register-wrapper .form-row-button {
    min-height: 45px;
  }
  .checkout-register-wrapper .btn-register-submit {
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;
  }
}
@media (min-width: 768px) {
  .checkout-register-wrapper {
    padding: 61px 0 80px;
  }
  .checkout-register-wrapper .personal-details-container {
    margin: 34px auto 0;
    max-width: 620px;
  }
  .login-checkout-v3 .checkout-register-wrapper .personal-details-container {
    max-width: none;
  }
}

.account-verification {
  text-align: center;
}
.account-verification p {
  font-size: 16px;
  line-height: 28px;
}
.account-verification .btn {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .account-verification {
    padding-bottom: 25px;
  }
}

.form-content,
.form-content-disclaimer {
  padding: 0 24px 0 33px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .form-content,
  .form-content-disclaimer {
    margin-top: 15px;
    padding-right: 33px;
  }
}
.form-content p, .form-content a,
.form-content-disclaimer p,
.form-content-disclaimer a {
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 0;
}

.privacy-policy {
  text-align: left;
}

.form-content-disclaimer + .form-content {
  margin-top: 20px;
}

.sms-thank-you-page-top {
  padding: 40px 0 15px;
  text-align: center;
}
@media (min-width: 768px) {
  .sms-thank-you-page-top {
    padding-top: 50px;
  }
}
.sms-thank-you-page-top .thank-you-heading {
  font-size: 25px;
  letter-spacing: 0.7px;
  line-height: 30px;
  max-width: 290px;
  margin: 0 auto;
  text-transform: none;
}
@media (min-width: 768px) {
  .sms-thank-you-page-top .thank-you-heading {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    max-width: 365px;
  }
}

.loyalty-card-wrapper {
  padding: 15px 0;
}
.loyalty-card-wrapper .loyalty-card-content {
  max-width: 315px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.loyalty-card-wrapper .loyalty-card-content .loyalty-card {
  padding-top: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}
.loyalty-card-wrapper .loyalty-card-content .image-holder {
  background-color: #ffffff;
  padding: 16px 0;
}
.loyalty-card-wrapper .loyalty-card-content img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.loyalty-card-wrapper .loyalty-card-content .loyalty-card-heading {
  display: block;
  text-align: right;
  padding-right: 34px;
  color: #9a7b69;
  font-size: 10px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.loyalty-card-wrapper .loyalty-card-content .text-holder {
  text-align: right;
  padding-right: 36px;
  margin-top: 7px;
  padding-bottom: 28px;
}
.loyalty-card-wrapper .loyalty-card-content .customer-name {
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 24px;
  display: block;
  font-family: "ClassGarmnd BT Bold", "Times New Roman", serif;
  margin-bottom: 1px;
  font-weight: bold;
}
.loyalty-card-wrapper .loyalty-card-content .customer-id {
  display: block;
  font-size: 12px;
  letter-spacing: 2px;
}

.account-membership-cart {
  background-color: #fcfaf7;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .account-membership-cart {
    align-items: flex-start;
    background-color: transparent;
    flex-direction: column;
    margin: 20px 0;
    padding: 15px 20px 20px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .account-membership-cart {
    align-items: center;
    flex-direction: row;
    margin-left: 46px;
    padding: 28px 40px 38px;
    width: calc(100% - 46px);
  }
}
.account-membership-cart .user-status {
  color: #000000;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 29px;
  margin-bottom: 10px;
}
.account-membership-cart .membercard-number {
  color: #453f3f;
  display: block;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
.account-membership-cart .account-membership-cart-right {
  display: flex;
  height: 48px;
}
@media (max-width: 767px) {
  .account-membership-cart .account-membership-cart-right {
    margin-top: 40px;
    max-width: 200px;
  }
}
@media (min-width: 768px) {
  .account-membership-cart .account-membership-cart-right {
    margin-top: 0;
    max-width: none;
  }
}
.account-membership-cart img {
  max-width: 100%;
}
.account-membership-cart .account-discount-banner {
  background-color: #ffffff;
  margin-top: 33px;
  padding: 28px 26px 24px;
}
.account-membership-cart .account-discount-banner::before {
  background-color: #d8d8d8;
  content: "";
  display: inline-block;
  height: 1px;
  left: -10px;
  position: absolute;
  right: -10px;
  top: -20px;
}
.account-membership-cart .account-discount-banner .heading-h10 {
  font-size: 9px;
  letter-spacing: 1px;
  line-height: 12px;
  margin-bottom: 0;
}
.account-membership-cart .account-discount-banner p {
  font-size: 11px;
  line-height: 19px;
}

.account-pagination {
  padding-top: 14px;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .account-pagination {
    padding-left: 46px;
  }
}
.account-pagination .account-pagination-numbers {
  display: inline-block;
}
.account-pagination .account-pagination-item {
  color: #9a7b69;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 0 12px;
  position: relative;
  text-decoration: underline;
}
.account-pagination .account-pagination-item.hide, .account-pagination .account-pagination-item.account-pagination-item-next::after {
  display: none;
}
.account-pagination .account-pagination-item::after {
  background-color: #d8d8d8;
  content: "";
  height: 16px;
  position: absolute;
  right: 0;
  top: 6px;
  width: 1px;
}
.account-pagination .account-pagination-item.selected {
  color: #453f3f;
  pointer-events: none;
  text-decoration: none;
}

.employee-discount {
  margin: -40px 0 40px;
  width: 100%;
}
.employee-discount .employee-discount-block {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .employee-discount .employee-discount-block {
    margin-left: 46px;
    width: calc(100% - 46px);
  }
}

.account-discount-banner {
  background-color: #f6f4f2;
  padding: 24px 26px 20px;
  position: relative;
  width: 100%;
}
.account-discount-banner .account-discount-progress-holder {
  background-color: #d8d8d8;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.account-discount-banner .account-discount-progress-holder .account-discount-progress-bar {
  background-color: #9a7b69;
  height: 2px;
  left: 0;
  position: absolute;
  width: 0;
}
.account-discount-banner .heading-h10 {
  font-size: 12px;
  letter-spacing: 1.9px;
}
.account-discount-banner p {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.account-discount-banner p:last-child {
  margin-bottom: 0;
}

.member-card-wrapper {
  background-color: #1a1919;
  padding: 40px 0 24px;
  position: relative;
}
.member-card-wrapper::after {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  height: 200px;
  position: absolute;
  width: 100%;
}
.member-card-wrapper .store-google-play {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.member-card-header {
  margin-bottom: 24px;
  padding: 0 24px;
  text-align: center;
}
.member-card-header .heading-h7 {
  color: #ffffff;
}
.member-card-header p {
  color: #ffffff;
  line-height: 24px;
}

.member-card {
  background-color: #453f3f;
  background-image: url("../images/my-rituals/my-rituals-member-card-mountains-bg@4x.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 200px;
  padding: 16px;
  position: relative;
  margin: 0 auto 24px;
  text-align: center;
  max-width: 327px;
  z-index: 1;
}
@media (min-width: 768px) {
  .member-card {
    margin: 0 0 24px;
  }
}
.member-card span {
  display: block;
}
.member-card span.member-phase {
  color: #9a7b69;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 10px;
  letter-spacing: 1.42px;
  line-height: 16px;
  margin-top: 4px;
  text-transform: uppercase;
}
.member-card span.member-name {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 24px;
  margin: 2px 0;
}
.member-card span.member-number {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 0.34px;
  line-height: 16px;
  margin-bottom: 10px;
}
.member-card span.member-barcode {
  background-color: #ffffff;
  height: 44px;
  padding: 4px;
}
.member-card span.member-barcode svg {
  max-width: 100%;
}

.personalised-promotions-wrapper .product-list-slider-intro {
  background-color: #f6f4f2;
  margin: 0;
}
@media (max-width: 767px) {
  .personalised-promotions-wrapper .product-list-slider-intro {
    background-image: url("../images/crm-envelope-top-inverted-dark.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 45%;
    padding: 64px 16px 20px 16px;
  }
}
@media (min-width: 768px) {
  .personalised-promotions-wrapper .product-list-slider-intro {
    padding: 64px 0 20px 0;
  }
}
.personalised-promotions-wrapper .product-list-slider-intro h1 {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.personalised-promotions-wrapper .product-list-slider-intro h2 {
  color: #1a1919;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 24px;
  text-transform: none;
}
@media (min-width: 1024px) {
  .personalised-promotions-wrapper .product-list-slider-intro h2 {
    font-size: 36px;
    line-height: 40px;
  }
}
.personalised-promotions-wrapper .product-list-slider-intro p {
  margin: 0;
}
@media (max-width: 767px) {
  .personalised-promotions-wrapper .product-list-slider-intro p {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 24px;
  }
}
.personalised-promotions-wrapper .product-list-slider .product-tile .promotional-labels,
.personalised-promotions-wrapper .product-list-slider .promotional-labels,
.personalised-promotions-wrapper .redeem-product .product-tile .promotional-labels,
.personalised-promotions-wrapper .redeem-product .promotional-labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  position: absolute;
  top: -150px;
  z-index: 1;
}
@media (min-width: 768px) {
  .personalised-promotions-wrapper .product-list-slider .product-tile .promotional-labels,
  .personalised-promotions-wrapper .product-list-slider .promotional-labels,
  .personalised-promotions-wrapper .redeem-product .product-tile .promotional-labels,
  .personalised-promotions-wrapper .redeem-product .promotional-labels {
    top: -326px;
  }
}
.personalised-promotions-wrapper .product-list-slider .product-tile .promotional-labels .promotional-label,
.personalised-promotions-wrapper .product-list-slider .promotional-labels .promotional-label,
.personalised-promotions-wrapper .redeem-product .product-tile .promotional-labels .promotional-label,
.personalised-promotions-wrapper .redeem-product .promotional-labels .promotional-label {
  background-color: #9a7b69;
  bottom: unset;
  font-size: 14px;
  left: unset;
  margin: 0;
  position: relative;
  width: fit-content;
}
.personalised-promotions-wrapper .product-list-slider .product-tile .promotional-labels .promotional-label:nth-of-type(2),
.personalised-promotions-wrapper .product-list-slider .promotional-labels .promotional-label:nth-of-type(2),
.personalised-promotions-wrapper .redeem-product .product-tile .promotional-labels .promotional-label:nth-of-type(2),
.personalised-promotions-wrapper .redeem-product .promotional-labels .promotional-label:nth-of-type(2) {
  background-color: #ffffff;
  font-size: 16px;
}
.personalised-promotions-wrapper .redeem-product .promotional-labels {
  top: 8px;
}
@media (min-width: 768px) {
  .personalised-promotions-wrapper .redeem-product .promotional-labels {
    top: 16px;
  }
}

.phase-icon {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  height: 48px;
  margin: 0 auto;
  width: 46px;
}
.phase-icon.phase-general {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-general.svg");
}
.phase-icon.phase-general-dark {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-general-dark.svg");
}
.phase-icon.phase-general-light {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-general-dark.svg");
}
.phase-icon.phase-gratitude {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-graditute.svg");
}
.phase-icon.phase-gratitude-dark {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-graditute-dark.svg");
}
.phase-icon.phase-gratitude-light {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-graditute-dark.svg");
}
.phase-icon.phase-joy {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-joy.svg");
}
.phase-icon.phase-joy-dark {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-joy-dark.svg");
}
.phase-icon.phase-joy-light {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-joy-dark.svg");
}
.phase-icon.phase-purpose {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-purpose.svg");
}
.phase-icon.phase-purpose-dark {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-purpose-dark.svg");
}
.phase-icon.phase-purpose-light {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-purpose-dark.svg");
}
.phase-icon.phase-secret {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-secret.svg");
}
.phase-icon.phase-secret-dark {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-secret-dark.svg");
}
.phase-icon.phase-secret-light {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-secret-dark.svg");
}
.phase-icon.phase-soulmate {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-soulmate.svg");
}
.phase-icon.phase-soulmate-dark {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-soulmate-dark.svg");
}
.phase-icon.phase-soulmate-light {
  background-image: url("../images/my-rituals/my-rituals-phase-logo-soulmate-dark.svg");
}

.search-suggestions-container {
  display: none;
  background-color: #ffffff;
  height: calc(100vh - 45px);
  left: -24px;
  margin-top: 43px;
  overflow-y: scroll;
  padding: 30px;
  position: absolute;
  right: -24px;
  min-width: 350px;
}
@supports (display: -ms-grid) {
  .search-suggestions-container {
    margin-top: 19px;
  }
}
@media (min-width: 768px) {
  .search-suggestions-container {
    height: auto;
    overflow-y: visible;
    margin-top: 11px;
  }
}
@media (max-width: 1024px) {
  .search-suggestions-container {
    height: calc(100vh - 56px);
    margin-top: 56px;
    padding: 28px 20px;
    top: 0;
  }
}
@media (min-width: 1025px) {
  .search-suggestions-container {
    left: 0;
    padding: 20px 20px 17px;
    right: 0;
    top: calc(100% + 4px);
    width: 350px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-suggestions-container {
    margin-top: 18px;
  }
}
.search-suggestions-container .quick-links .heading-h11 {
  display: block;
}
.search-suggestions-container .quick-links a {
  font-size: 15px;
  color: #453f3f;
  margin-top: 14px;
  display: block;
  line-height: normal;
}
.search-suggestions-container .quick-links a:hover {
  text-decoration: underline;
}
.search-suggestions-container .quick-links a:first-of-type {
  margin-top: 22px;
}
.search-suggestions-container .suggestions-title {
  margin-bottom: 17px;
}
@media (max-width: 1024px) {
  .search-suggestions-container .suggestions-title {
    margin-bottom: 8px;
  }
}
.search-suggestions-container .phrase-suggestions {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
}
@media (max-width: 1024px) {
  .search-suggestions-container .phrase-suggestions {
    margin-bottom: 23px;
    padding-bottom: 19px;
  }
}
.search-suggestions-container .hit {
  color: #453f3f;
  display: block;
  font-size: 15px;
  letter-spacing: 0.75px;
  margin-top: 14px;
  line-height: normal;
}
@media (max-width: 1024px) {
  .search-suggestions-container .hit {
    margin-top: 0;
  }
}
.search-suggestions-container .hit .child-category {
  font-weight: bold;
}
.search-suggestions-container .hit .hits-number {
  color: #886655;
  font-size: 12px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  letter-spacing: 0.75px;
}
.search-suggestions-container .hit:hover, .search-suggestions-container .hit:active {
  text-decoration: underline;
}
.search-suggestions-container .product-suggestions .product-link {
  display: block;
  color: #1a1919;
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .product-link {
    margin-bottom: 15px;
  }
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .suggestions-title {
    margin-bottom: 10px;
  }
}
.search-suggestions-container .product-suggestions .product-suggestion {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin: 10px 0;
}
.search-suggestions-container .product-suggestions .product-suggestion .product-add-to-cart-sugest {
  display: flex;
  justify-content: flex-end;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send {
  margin: 5px 0 0 5px;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn {
  display: block;
  margin-left: auto;
  min-width: 0;
  background-color: #ffffff;
  border: 1px solid #453f3f;
  border-radius: 50%;
  font-size: 0;
  letter-spacing: 1.5px;
  height: 36px;
  padding: 0;
  width: 36px;
  color: #1a1919;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn .icon-send {
  display: none;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn .spinner-visible {
  transform: translate(2px, 1px);
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn .spinner-visible :nth-child(1) {
  margin-right: 7px;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn .spinner-visible :nth-child(2) {
  margin-right: 0;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn .spinner-visible :nth-child(3) {
  margin-right: -7px;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn .spinner-visible span {
  width: 5px;
  height: 5px;
}
.search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn:not(.button-loading)::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eaaf";
  display: block;
  font-size: 18px;
  margin-top: 2px;
}
@media (min-width: 1024px) {
  .search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn:hover, .search-suggestions-container .product-suggestions .product-suggestion .btn-wrapper-send .btn:active {
    color: #ffffff;
    background-color: #1a1919;
  }
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .product-suggestion {
    margin: 0;
  }
}
.search-suggestions-container .product-suggestions .product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f4f2;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}
.search-suggestions-container .product-suggestions .product-image img {
  max-width: 50px;
  max-height: 50px;
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .product-image {
    height: 50px;
    width: 50px;
  }
  .search-suggestions-container .product-suggestions .product-image img {
    max-height: 40px;
    max-width: 40px;
  }
}
.search-suggestions-container .product-suggestions .product-details {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 100%;
}
.search-suggestions-container .product-suggestions .ritual-title,
.search-suggestions-container .product-suggestions .name {
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.search-suggestions-container .product-suggestions .ritual-title {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: #886655;
  line-height: 12px;
  margin-bottom: 2px;
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .ritual-title {
    margin-bottom: 4px;
  }
}
.search-suggestions-container .product-suggestions .name {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .name {
    line-height: 18px;
  }
}
.search-suggestions-container .product-suggestions .short-description {
  font-size: 14px;
  line-height: 20px;
  color: #1a1919;
  opacity: 0.5;
  margin-top: 2px;
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .short-description {
    letter-spacing: 0.5px;
    margin-top: 5px;
  }
}
.search-suggestions-container .product-suggestions .product-price {
  align-self: flex-start;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 18px;
  margin-left: auto;
  margin-top: 13px;
  padding-left: 10px;
  text-align: right;
  white-space: nowrap;
}
.search-suggestions-container .product-suggestions .product-price s {
  color: #1a1919;
  display: block;
  font-size: 13px;
  opacity: 0.5;
}
.search-suggestions-container .product-suggestions .product-price .product-discount-no-strikethrough {
  display: none;
}
@media (max-width: 1024px) {
  .search-suggestions-container .product-suggestions .product-price {
    margin-top: 17px;
    padding-left: 20px;
  }
}
.search-suggestions-container .search-view-all-link {
  color: #886655;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 12px;
  text-decoration: underline;
  text-transform: uppercase;
}
.search-suggestions-container .search-view-all-link:hover {
  text-decoration: none;
}

.search-overlay {
  z-index: 1;
  display: none;
  background-color: #1a1919;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 80px;
  left: 0;
  pointer-events: none;
}
@media (min-width: 768px) {
  .search-overlay {
    pointer-events: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-overlay.header-overlay {
    top: 88px;
  }
}

.pt_product-search-result {
  background-color: #efedea;
}
.pt_product-search-result .main {
  margin-bottom: 30px;
}
.pt_product-search-result .slot-grid-header {
  min-height: 180px;
  display: flex;
  align-items: center;
}
.pt_product-search-result .slot-grid-header.cop-banner-container {
  align-items: flex-start;
  min-height: 10px;
}
@media (min-width: 768px) {
  .pt_product-search-result .slot-grid-header .content-banner {
    margin-bottom: -110px;
  }
}
.pt_product-search-result .content-banner .text-holder {
  padding: 70px 0 120px;
}
@media (max-width: 767px) {
  .pt_product-search-result .content-banner {
    align-items: flex-end;
  }
  .pt_product-search-result .content-banner.banner-small {
    min-height: 180px;
    padding-bottom: 12px;
  }
  .pt_product-search-result .content-banner .image-holder::after {
    background: linear-gradient(to bottom, rgba(35, 31, 32, 0), rgba(35, 31, 32, 0.8));
    bottom: 0;
    content: "";
    display: inline-block;
    left: 0;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
  }
  .pt_product-search-result .content-banner .text-holder {
    margin: 0;
    padding: 0;
  }
  .pt_product-search-result .content-banner .heading-intro {
    display: none;
  }
  .pt_product-search-result .content-banner .heading-h1 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .pt_product-search-result .search-result-options .mobile-filter-trigger .filter-trigger-btn::after {
    display: none;
  }
  .pt_product-search-result .search-result-options .mobile-filter-trigger .filter-trigger-btn .filter-count {
    display: inline-block;
  }
  .pt_product-search-result .search-result-options.sticky-filters .filter-cart {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .pt_product-search-result .content-banner .text-holder {
    padding: 90px 0 120px;
  }
  .pt_product-search-result .search-result-options.sticky-filters .mobile-filter-trigger .filter-trigger-btn::after {
    display: none;
  }
  .pt_product-search-result .search-result-options.sticky-filters .mobile-filter-trigger .filter-trigger-btn .filter-count {
    display: inline-block;
  }
  .pt_product-search-result .simple-text-block.drag-list {
    margin-top: 110px;
  }
}

.search-grid-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.search-grid-wrapper .filter-trigger-btn {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  padding-right: 19px;
  position: relative;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .search-grid-wrapper .filter-trigger-btn {
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
.search-grid-wrapper .filter-trigger-btn::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea18";
  font-size: 4px;
  position: absolute;
  right: 0;
  top: -1px;
}
.search-grid-wrapper .filter-trigger-btn .filter-count {
  color: #886655;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  .search-grid-wrapper .filter-trigger-btn .filter-count {
    display: none;
  }
}
.search-grid-wrapper .search-result-content {
  margin-bottom: 30px;
  position: relative;
}
.search-grid-wrapper .grid-load-more {
  text-align: center;
  width: 100%;
}
.search-grid-wrapper .grid-product-number {
  align-items: center;
  color: #b4b4b4;
  display: flex;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 15px;
  justify-content: center;
  padding-bottom: 23px;
  position: relative;
}
@media (min-width: 768px) {
  .search-grid-wrapper .grid-product-number {
    padding-bottom: 30px;
  }
}
.search-grid-wrapper .grid-product-number::before, .search-grid-wrapper .grid-product-number::after {
  content: "";
  display: inline-block;
  height: 1px;
  margin-bottom: 1px;
  width: 100px;
}
@media (min-width: 768px) {
  .search-grid-wrapper .grid-product-number::before, .search-grid-wrapper .grid-product-number::after {
    margin-bottom: 3px;
    width: 130px;
  }
}
.search-grid-wrapper .grid-product-number::before {
  background: linear-gradient(to left, #b4b4b4, rgba(180, 180, 180, 0));
}
.search-grid-wrapper .grid-product-number::after {
  background: linear-gradient(to right, #b4b4b4, rgba(180, 180, 180, 0));
}
.search-grid-wrapper .grid-product-number .grid-product-number-inner {
  flex-shrink: 0;
  padding: 0 8px;
}
.search-grid-wrapper .grid-product-number.grid-number-hidden {
  visibility: hidden;
}
@media (max-width: 767px) {
  .search-grid-wrapper .inner-wrapper {
    padding: 0 10px;
  }
  .search-grid-wrapper .search-result-content {
    padding-top: 10px;
  }
}
@media (min-width: 768px) {
  .search-grid-wrapper .inner-wrapper {
    max-width: 1360px;
  }
}

.search-results-header {
  margin-left: -10px;
  width: calc(100% + 20px);
}
.search-results-header .search-result-inner {
  padding-top: 13px;
  width: 100%;
}
.search-results-header .search-result-inner .inner-wrapper {
  justify-content: flex-end;
  display: flex;
}
@media (min-width: 768px) {
  .search-results-header .search-result-inner .inner-wrapper {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .search-results-header .search-result-inner .inner-wrapper {
    padding: 0 10px;
  }
}
.search-results-header .filter-trigger {
  margin-right: auto;
}
.search-results-header .filter-trigger-btn {
  color: #1a1919;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
}
.search-results-header .filter-trigger-btn::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #1a1919;
  content: "\ea54";
  display: inline-block;
  font-size: 10px;
  margin: -3px 0 0 6px;
}
.search-results-header .filter-trigger-btn::after {
  display: none;
}
.search-results-header .custom-select {
  background-image: url("../images/icons/arrow3--down.svg");
  background-size: 18px auto;
  color: #1a1919;
  padding: 0 17px 0 0;
  text-align: right;
  text-align-last: right;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.search-results-header .search-summary-holder {
  color: #1a1919;
  display: none;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin: 0 5px 0 21px;
  padding: 0 20px;
  position: relative;
  text-transform: uppercase;
}
.search-results-header .search-summary-holder::before, .search-results-header .search-summary-holder::after {
  background-color: #1a1919;
  bottom: 11px;
  content: "";
  position: absolute;
  top: 10px;
  width: 1px;
}
.search-results-header .search-summary-holder::before {
  left: 0;
}
.search-results-header .search-summary-holder::after {
  right: 0;
}
.search-results-header .selectric-wrapper .selectric {
  background-color: #1a1919;
  border: 0;
  border-bottom: 0;
  height: 32px;
  padding: 0 34px 0 16px;
}
.search-results-header .selectric-wrapper .selectric .label {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  height: 32px;
  letter-spacing: 2px;
  line-height: 34px;
  padding: 0;
  text-transform: uppercase;
}
.search-results-header .selectric-wrapper .selectric .button {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  right: 11px;
  top: 1px;
}
.search-results-header .selectric-wrapper .selectric .button::before, .search-results-header .selectric-wrapper .selectric .button::after {
  border-color: #ffffff;
}
.search-results-header .selectric-wrapper .selectric .button::before {
  color: #ffffff;
  font-size: 18px;
  line-height: 1;
}
.search-results-header .selectric-wrapper .selectric-items {
  left: auto;
  right: 0;
  top: 100%;
  width: 320px !important;
}
.search-results-header .filter-cart {
  display: none;
  margin-left: 20px;
}
.search-results-header .filter-cart .header--cart-link {
  color: #ffffff;
  text-decoration: none;
}
.search-results-header .filter-cart .header-inline-icon {
  margin-bottom: -3px;
}
.search-results-header .filter-cart .cart-number {
  display: none;
  bottom: 1px;
}
.search-results-header.sticky-filters .search-result-inner {
  background-color: #1a1919;
  left: 0;
  max-height: 44px;
  min-height: 44px;
  position: fixed;
  transition: top 0.4s cubic-bezier(0.56, 0.88, 0.15, 1), transform 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  z-index: 980;
}
.search-results-header.sticky-filters .search-summary-holder,
.search-results-header.sticky-filters .filter-product-number {
  display: none;
}
.search-results-header.sticky-filters .filter-count {
  color: #807463;
}
@media (max-width: 767px) {
  .search-results-header .search-result-inner {
    top: 0;
    transition: top 0.6s ease-in-out, background 0.2s ease-in-out;
  }
  .search-results-header .search-result-inner .inner-wrapper {
    align-items: center;
    position: relative;
  }
  .search-results-header .filter-trigger-btn {
    color: #1a1919;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .search-results-header .filter-product-number {
    display: inline-block;
    font-size: 12px;
    left: 50%;
    letter-spacing: 1px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
  }
  .search-results-header .sorting-options {
    position: relative;
  }
  .search-results-header .sorting-options .mobile-sorting-label {
    color: #1a1919;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 20px;
    pointer-events: none;
    position: relative;
    text-transform: uppercase;
    z-index: 3;
  }
  .search-results-header .sorting-options .mobile-sorting-label::after {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea19";
    display: inline-block;
    font-size: 22px;
    margin: 0 -6px 0 -1px;
  }
  .search-results-header .sorting-options .sort-by {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  .search-results-header .sorting-options .custom-select {
    background: none;
    color: transparent;
    overflow: hidden;
    width: 100%;
  }
  .search-results-header.sticky-filters {
    height: 48px;
  }
  .nav-visible .search-results-header.sticky-filters .search-result-inner {
    padding: 11px 0 8px;
    top: 80px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.48, 0.12, 0.15, 1);
  }
  .search-results-header.sticky-filters .filter-trigger-btn,
  .search-results-header.sticky-filters .filter-trigger-btn::before,
  .search-results-header.sticky-filters .custom-select {
    color: #ffffff;
  }
  .search-results-header.sticky-filters .filter-cart {
    display: inline-block;
  }
  .search-results-header.sticky-filters .mobile-sorting-label {
    color: #ffffff;
  }
  .search-results-header.sticky-filters .custom-select {
    color: transparent;
  }
}
@media (min-width: 768px) {
  .search-results-header .search-result-inner {
    padding: 0 0 15px;
  }
  .search-results-header .search-result-inner .sorting-options .selectric-items ul .disabled {
    display: none;
  }
  .search-results-header .filter-trigger {
    margin-right: 0;
  }
  .search-results-header .filter-trigger-btn {
    background-color: #1a1919;
    color: #ffffff;
    display: inline-block;
    height: 32px;
    line-height: 34px;
    padding: 0 19px 0 14px;
    vertical-align: top;
  }
  .search-results-header .filter-trigger-btn::before {
    color: #ffffff;
  }
  .search-results-header .filter-product-number,
  .search-results-header .mobile-sorting-label {
    display: none;
  }
  .search-results-header .search-summary-holder {
    align-items: center;
    display: flex;
  }
  .search-results-header .sorting-options {
    margin-left: 16px;
  }
  .search-results-header .custom-select {
    background: #1a1919 url("../images/icons/arrow3--down-white.svg");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 10px auto;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 2px;
    height: 32px;
    padding: 0 19px;
    text-align: left;
    text-align-last: left;
    text-transform: uppercase;
  }
}
@media (min-width: 768px) and (min-width: 1025px) {
  .search-results-header .custom-select {
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 768px) {
  .search-results-header.sticky-filters .search-result-inner {
    top: -44px;
  }
  .nav-visible .search-results-header.sticky-filters .search-result-inner {
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.48, 0.12, 0.15, 1);
  }
}
@media (min-width: 768px) and (min-width: 768px) and (max-width: 1023px) {
  .nav-visible .search-results-header.sticky-filters .search-result-inner {
    top: 44px;
  }
}
@media (min-width: 768px) and (min-width: 1025px) {
  .nav-visible .search-results-header.sticky-filters .search-result-inner {
    top: 68px;
  }
}
@media (min-width: 768px) {
  .search-results-header.sticky-filters .search-result-inner-wrapper {
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 20px;
  }
  .search-results-header.sticky-filters .mobile-filter-trigger {
    display: block;
    float: left;
  }
  .search-results-header.sticky-filters .mobile-filter-trigger .filter-trigger-btn {
    display: inline-block;
    padding: 17px 19px 13px 0;
  }
  .search-results-header.sticky-filters .mobile-filter-trigger .filter-trigger-btn::after {
    top: 16px;
  }
  .search-results-header.sticky-filters .filter-trigger-btn {
    background-color: #ffffff;
    color: #1a1919;
    height: 44px;
    line-height: 46px;
    padding-left: 10px;
  }
  .search-results-header.sticky-filters .filter-trigger-btn::before {
    color: #1a1919;
  }
  .search-results-header.sticky-filters .sorting-options {
    margin: 0 -6px 0 2px;
  }
  .search-results-header.sticky-filters .selectric-wrapper .selectric {
    height: 44px;
  }
  .search-results-header.sticky-filters .selectric-wrapper .selectric .label {
    height: 44px;
    line-height: 46px;
  }
  .search-results-header.sticky-filters .selectric-wrapper .selectric .button {
    top: 7px;
  }
  .search-results-header.sticky-filters .custom-select {
    height: 44px;
  }
  .search-results-header.sticky-filters.sticky-filters-visible .search-result-inner {
    max-height: 44px;
    min-height: 44px;
    padding: 0;
    transform: translateY(44px);
  }
  .search-results-header .sorting-options.sorting-options-dark .custom-select {
    background-image: url("../images/icons/arrow3--down.svg");
    background-size: 18px auto;
    color: #1a1919;
  }
}

@media (min-width: 768px) {
  .search-filters-mobile {
    display: none;
  }
}

.cop-filters-wrapper .cop-filters-heading {
  display: none;
}
.cop-filters-wrapper .filter-show-more {
  color: #ffffff;
  display: none;
  flex-shrink: 0;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  margin-left: auto;
  position: relative;
  padding: 8px 18px 5px 0;
  text-transform: uppercase;
}
.cop-filters-wrapper .filter-show-more::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
  display: inline-block;
  font-size: 22px;
  line-height: 1;
  position: absolute;
  right: -4px;
  top: 5px;
}
.cop-filters-wrapper .filter-show-more .less {
  display: none;
}
.cop-filters-wrapper .cop-filters-inner {
  overflow: auto;
  position: relative;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.cop-filters-wrapper .cop-filters-inner::-webkit-scrollbar {
  display: none;
}
.cop-filters-wrapper .cop-filters-list li {
  border: 1px solid #ccc;
  border-radius: 16px;
  display: inline-block;
  height: 30px;
  margin: 0 8px 10px 0;
  vertical-align: top;
}
.cop-filters-wrapper .cop-filters-list li.selected {
  background-color: #453f3f;
  border-color: #453f3f;
}
.cop-filters-wrapper .cop-filters-list li.selected .text {
  color: #ffffff;
  pointer-events: none;
}
.cop-filters-wrapper .cop-filters-list li.selected .remove-filter {
  display: inline-block;
}
.cop-filters-wrapper .cop-filters-list .text {
  color: #1a1919;
  cursor: pointer;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  height: 28px;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 0 17px;
  text-decoration: none;
  vertical-align: middle;
  text-transform: none;
}
.cop-filters-wrapper .cop-filters-list .remove-filter {
  color: #ffffff;
  display: none;
  font-size: 10px;
  line-height: 28px;
  margin: 0 10px 0 -10px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cop-filters-wrapper {
    height: 50px;
    margin-left: -10px;
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    width: calc(100% + 20px);
  }
  .cop-filters-wrapper::before, .cop-filters-wrapper::after {
    bottom: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.4s cubic-bezier(0.33, 0.16, 0.15, 0.7);
    visibility: hidden;
    width: 34px;
    z-index: 5;
  }
  .cop-filters-wrapper.left-fade::before {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  .cop-filters-wrapper.right-fade::after {
    opacity: 1;
    right: 0;
    visibility: visible;
  }
  .cop-filters-wrapper .cop-filters-list {
    font-size: 0;
    padding: 0 10px;
    width: auto;
  }
  .cop-filters-wrapper .cop-filters-list.no-wrap {
    white-space: nowrap;
  }
  .cop-filters-wrapper .cop-filters-list sup {
    display: none;
  }
  .cop-filters-wrapper.cop-subcategories-wrapper {
    background-color: #1a1919;
  }
  .cop-filters-wrapper.cop-subcategories-wrapper::before {
    background: linear-gradient(to left, rgba(26, 25, 25, 0), #1a1919);
  }
  .cop-filters-wrapper.cop-subcategories-wrapper::after {
    background: linear-gradient(to right, rgba(26, 25, 25, 0), #1a1919);
  }
  .cop-filters-wrapper.cop-subcategories-wrapper .cop-filters-list {
    padding: 0 15px;
  }
  .cop-filters-wrapper.cop-subcategories-wrapper .cop-filters-list li {
    border: 0;
    border-radius: 0;
    margin: 0 5px 10px 0;
  }
  .cop-filters-wrapper.cop-subcategories-wrapper .cop-filters-list .text {
    color: #ffffff;
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    font-size: 14px;
    letter-spacing: 0.2px;
    padding: 0 10px;
  }
  .cop-filters-wrapper.cop-product-types-wrapper {
    background-color: #ffffff;
  }
  .cop-filters-wrapper.cop-product-types-wrapper::before {
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
  }
  .cop-filters-wrapper.cop-product-types-wrapper::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
  }
}
@media (min-width: 768px) {
  .cop-filters-wrapper .cop-filters-inner {
    overflow: visible;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories {
    list-style: none;
    margin-bottom: 25px;
    width: 100% !important;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories li {
    border: 0;
    border-radius: 0;
    display: flex;
    height: auto;
    margin: 0 0 25px;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories li:last-child {
    margin: 0;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories li sup {
    color: #968977;
    display: inline;
    font-size: 16px;
    opacity: 0;
    position: relative;
    top: -20px;
    transition: opacity 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  }
  .cop-filters-wrapper .cop-filters-list-subcategories .text {
    color: #ffffff;
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    font-size: 24px;
    letter-spacing: 0.9px;
    line-height: 30px;
    overflow: hidden;
    padding: 0 0 1px;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories .text::after {
    background-color: #ffffff;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: -1px;
    position: absolute;
    transition: width 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
    width: 0;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories .text:hover::after {
    width: 100%;
  }
  .cop-filters-wrapper .cop-filters-list-subcategories .text:hover + sup {
    opacity: 1;
  }
  .cop-filters-wrapper .cop-filters-heading {
    color: #ffffff;
    display: block;
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    margin: 6px 0 24px;
  }
  .cop-filters-wrapper .cop-filters-list-product-types {
    margin-bottom: 20px;
    width: 100% !important;
  }
  .cop-filters-wrapper .cop-filters-list-product-types li {
    border-color: #626262;
    height: 32px;
  }
  .cop-filters-wrapper .cop-filters-list-product-types li.selected {
    background-color: #353132;
    border-color: #353132;
  }
  .cop-filters-wrapper .cop-filters-list-product-types li.selected .remove-filter {
    opacity: 0.36;
  }
  .cop-filters-wrapper .cop-filters-list-product-types .text {
    color: #ffffff;
    height: 30px;
    letter-spacing: 0.75px;
    line-height: 32px;
  }
  .cop-filters-wrapper .filter-show-more {
    display: none !important;
  }
  .cop-filters-wrapper ~ .active-refinements-list .active-refinements-inner {
    margin: 20px 0;
  }
  .cop-filters-wrapper ~ .active-refinements-list .active-refinements-inner.empty {
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cop-filters-wrapper ul.cop-filters-list-subcategories li {
    margin-bottom: 15px;
  }
  .cop-filters-wrapper ul.cop-filters-list-subcategories .text {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .zoomed-in .cop-filters-wrapper {
    align-items: flex-start;
    display: flex;
    height: auto;
    padding: 10px;
  }
  .zoomed-in .cop-filters-wrapper::before, .zoomed-in .cop-filters-wrapper::after {
    display: none;
  }
  .zoomed-in .cop-filters-wrapper .cop-filters-inner {
    overflow: hidden;
  }
  .zoomed-in .cop-filters-wrapper .cop-filters-list {
    max-height: 30px;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }
  .zoomed-in .cop-filters-wrapper .cop-filters-list .hidden {
    display: none;
  }
  .zoomed-in .cop-filters-wrapper .filter-show-more {
    display: block;
  }
  .zoomed-in .cop-filters-wrapper.expanded {
    padding-bottom: 0;
  }
  .zoomed-in .cop-filters-wrapper.expanded .cop-filters-list {
    max-height: none;
  }
  .zoomed-in .cop-filters-wrapper.expanded .filter-show-more::after {
    top: 3px;
    transform: rotate(-180deg);
  }
  .zoomed-in .cop-filters-wrapper.expanded .filter-show-more .more {
    display: none;
  }
  .zoomed-in .cop-filters-wrapper.expanded .filter-show-more .less {
    display: block;
  }
  .zoomed-in .search-results-header .sorting-options .sort-by {
    overflow: visible;
  }
  .zoomed-in .search-results-header .selectric-wrapper {
    background-color: #f6f4f2;
  }
  .zoomed-in .search-results-header .selectric-wrapper .selectric {
    background-color: transparent;
    height: 26px;
  }
  .zoomed-in .search-results-header .selectric-wrapper .selectric .label {
    color: #1a1919;
    height: 26px;
    line-height: 28px;
  }
}

.search-result-items.grid-wrap {
  float: none;
  list-style: none;
  overflow: visible;
}
.search-result-items.grid-wrap .fixed-item {
  background-color: transparent;
  width: calc(33.3333% - 64px);
  margin-bottom: 20px;
}
.search-result-items.grid-wrap .product-title .the-rituals-of-title {
  margin-bottom: 21px;
}
.search-result-items.grid-wrap .filter-no-hits {
  background-color: #f6f4f2;
  overflow: hidden;
  padding: 51px 30px;
  text-align: center;
  width: 100%;
}
.search-result-items.grid-wrap .filter-no-hits h2 {
  margin-bottom: 4px;
  text-transform: none;
}
.search-result-items.grid-wrap .filter-no-hits p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  line-height: 32px;
}
@media (max-width: 767px) {
  .search-result-items.grid-wrap.no-hits-grid {
    background-color: #f6f4f2;
    margin-left: -10px;
    padding: 20px;
    width: calc(100% + 20px);
  }
  .search-result-items.grid-wrap .fixed-item {
    display: none;
  }
  .search-result-items.grid-wrap .item {
    background-color: transparent;
  }
  .search-result-items.grid-wrap .item .product-tile {
    background-color: #ffffff;
  }
}
@media (min-width: 768px) {
  .search-result-items.grid-wrap .filter-no-hits {
    background-color: transparent;
    float: right;
    margin-right: 64px;
    padding: 190px 30px;
    width: calc(66.6666% - 64px);
  }
  .search-result-items.grid-wrap .filter-no-hits h2 {
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 9px;
  }
  .search-result-items.grid-wrap .filter-no-hits p {
    font-size: 30px;
    line-height: 38px;
  }
}
@media (min-width: 1024px) {
  .search-result-items.grid-wrap {
    margin: -20px 0 0 -30px;
    padding: 20px 0 0 30px;
    width: calc(100% + 100px);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  ul.search-result-items.grid-wrap {
    margin: -20px 0 0 -10px;
    padding: 20px 0 0 10px;
    width: calc(100% + 20px);
  }
  ul.search-result-items.grid-wrap .item {
    width: calc(33.3333% - 20px);
  }
}
@media (max-width: 767px) {
  ul.search-result-items.grid-wrap .item {
    margin-bottom: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  ul.search-result-items.grid-wrap .item {
    margin-bottom: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ul.search-result-items.grid-wrap .item {
    margin-bottom: 18px;
  }
}

.search-filter-control-box {
  background-color: #231f20;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  display: none;
  min-height: 162px;
  padding: 32px 40px 30px;
  text-align: left;
  width: 100%;
}
.search-filter-control-box .breadcrumb {
  margin-bottom: 20px;
}
.search-filter-control-box .breadcrumb ol {
  margin: 0 0 10px;
  padding: 0;
  text-align: left;
}
.search-filter-control-box .breadcrumb .breadcrumb-list-item:last-child .breadcrumb-element::after {
  display: none;
}
.search-filter-control-box .breadcrumb .breadcrumb-element {
  color: #968977;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  letter-spacing: 0.9px;
  line-height: 16px;
  text-transform: none;
}
.search-filter-control-box .breadcrumb .breadcrumb-element::after {
  content: "/";
  padding: 0;
}
.search-filter-control-box .filter-box-categories {
  list-style: none;
}
.search-filter-control-box .filter-box-categories li {
  display: flex;
  margin-bottom: 15px;
}
.search-filter-control-box .filter-box-categories .category-title {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.9px;
  line-height: 26px;
  overflow: hidden;
  padding-bottom: 1px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
}
.search-filter-control-box .filter-box-categories .category-title::after {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: -1px;
  position: absolute;
  transition: width 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 0;
}
.search-filter-control-box .filter-box-categories .category-title:hover::after, .search-filter-control-box .filter-box-categories .category-title.selected::after {
  width: 100%;
}
.search-filter-control-box .filter-box-categories .category-title:hover + sup, .search-filter-control-box .filter-box-categories .category-title.selected + sup {
  opacity: 1;
}
.search-filter-control-box .filter-box-categories sup {
  color: #968977;
  display: inline;
  font-size: 16px;
  opacity: 0;
  position: relative;
  top: -20px;
  transition: opacity 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.search-filter-control-box .filter-box-popular {
  list-style: none;
  margin-bottom: 35px;
}
.search-filter-control-box .filter-box-popular li {
  line-height: 20px;
  margin-bottom: 5px;
  text-transform: none;
}
.search-filter-control-box .filter-box-popular .popular-title {
  color: #968977;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  letter-spacing: 0.9px;
  line-height: 16px;
  margin-bottom: 12px;
}
.search-filter-control-box .filter-box-popular .popular-item {
  color: #f7f4ef;
  font-size: 14px;
  letter-spacing: 1.7px;
  line-height: 20px;
  text-decoration: none;
  position: relative;
}
.search-filter-control-box .filter-box-popular .popular-item::after {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: -1px;
  overflow: hidden;
  padding-bottom: 1px;
  position: absolute;
  text-overflow: ellipsis;
  transition: width 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  white-space: nowrap;
  width: 0;
}
.search-filter-control-box .filter-box-popular .popular-item:hover::after {
  width: 100%;
}
.search-filter-control-box .active-refinements-list .active-refinements-inner {
  margin-bottom: 0;
  overflow: hidden;
}
.search-filter-control-box .active-refinements-list .active-refinements-inner .filter-tags-title {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin: 10px 0 24px;
  text-transform: uppercase;
}
.search-filter-control-box .active-refinements-list .filter-tag {
  padding: 10px 15px 8px 18px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .search-filter-control-box {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .search-filter-control-box {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .search-filter-control-box .breadcrumb {
    margin-bottom: 27px;
  }
  .search-filter-control-box .filter-box-categories li {
    margin-bottom: 25px;
  }
  .search-filter-control-box .filter-box-categories .category-title {
    font-size: 24px;
    line-height: 30px;
  }
  .search-filter-control-box .filter-box-popular {
    margin-bottom: 43px;
  }
}

.search-summary-mobile {
  margin: -7px 0 8px;
}
.search-summary-mobile span {
  display: inline-block;
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .search-summary-mobile {
    display: none;
  }
}

.search-slot-holder {
  padding-top: 20px;
  width: 100%;
}

.search-grid-asset {
  padding: 20px 0;
  text-align: center;
  width: 100%;
  text-transform: none;
}
.search-grid-asset .image-holder {
  margin-bottom: 0;
  padding-top: 10px;
}
.search-grid-asset img {
  max-width: 280px;
  width: 100%;
  margin: 0 auto 10px;
}
.search-grid-asset p {
  max-width: 265px;
  margin: 0 auto 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
}
.search-grid-asset .heading-h6 {
  font-size: 30px;
  line-height: 36px;
  max-width: none;
  letter-spacing: 1px;
  margin: -3px auto 23px;
}
.search-grid-asset .payment-icon {
  text-decoration: none;
  padding: 0 3px;
}
@media (min-width: 768px) {
  .search-grid-asset .payment-icon.i-minDesktop, .search-grid-asset .payment-icon.i-minTablet {
    display: inline-block !important;
  }
}
.search-grid-asset.cop-grid-asset {
  padding: 35px 10px 52px;
}
.search-grid-asset.cop-grid-asset .gwpbanner-img-desktop {
  display: none;
}
.search-grid-asset.cop-grid-asset .gwpbanner-img-desktop.desktop-only {
  display: block;
}
.search-grid-asset.cop-grid-asset img {
  margin: 0 auto 20px;
  max-width: 147px;
}
.search-grid-asset.cop-grid-asset .heading-h10 {
  font-size: 11px;
  letter-spacing: 1.5px;
  margin-bottom: 12px;
}
.search-grid-asset.cop-grid-asset i {
  font-style: italic;
}
.search-grid-asset.cop-grid-asset .promotion-alert {
  color: #1a1919;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: none;
  max-width: 200px;
  margin: 17px auto 0;
}
@media (max-width: 767px) {
  .search-grid-asset.cop-grid-asset .heading-h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
  }
  .search-grid-asset.cop-grid-asset .btn--secondary {
    border: 0;
    color: #9a7b69;
    padding: 0;
    text-decoration: underline;
    letter-spacing: 1.6px;
    line-height: 20px;
  }
}
@media (min-width: 768px) {
  .search-grid-asset.cop-grid-asset {
    padding: 40px 40px 60px;
  }
  .search-grid-asset.cop-grid-asset .heading-h10 {
    margin-bottom: 7px;
  }
  .search-grid-asset.cop-grid-asset .heading-h6 {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 22px;
  }
  .search-grid-asset.cop-grid-asset img {
    margin: 15px auto 20px;
    max-width: 250px;
  }
  .search-grid-asset.cop-grid-asset .promotion-alert {
    margin-top: 0;
  }
  .search-grid-asset.cop-grid-asset .btn--secondary {
    margin-bottom: 21px;
  }
  .search-grid-asset.cop-grid-asset .gwpbanner-img-desktop {
    display: block;
  }
  .search-grid-asset.cop-grid-asset .gwpbanner-img-mobile {
    display: none;
  }
}
@media (min-width: 1440px) {
  .search-grid-asset.cop-grid-asset .btn--secondary {
    min-width: 300px;
  }
}
@media (max-width: 767px) {
  .search-grid-asset img {
    max-width: 150px;
  }
  .search-grid-asset .heading-h10 {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 12px;
    letter-spacing: 1.5px;
  }
  .search-grid-asset .heading-h6 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    margin: 0 auto 9px;
  }
  .search-grid-asset p {
    font-size: 12px;
    max-width: 150px;
    letter-spacing: 1px;
    line-height: 18px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .search-grid-asset {
    padding: 0;
  }
}

.search-results-summary {
  padding: 20px 0;
  text-align: center;
  width: 100%;
}
.search-results-summary .heading-h1 {
  color: #453f3f;
  margin-bottom: 4px;
}
.search-results-summary p {
  color: #453f3f;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
}
.search-results-summary p strong {
  font-family: "ClassGarmnd BT Bold", "Times New Roman", serif;
  font-weight: bold;
}
@media (min-width: 768px) {
  .search-results-summary {
    padding: 108px 0 166px;
  }
  .search-results-summary .heading-h1 {
    margin-bottom: 7px;
  }
  .search-results-summary p {
    font-size: 30px;
    line-height: 45px;
  }
}

.no-hits-header {
  text-align: center;
  width: 100%;
}
.no-hits-header .inner-indented {
  margin: 0 auto;
  padding: 85px 20px 58px;
  width: 100%;
}
.no-hits-header h2 {
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 46px;
  margin-bottom: 0;
  text-transform: none;
}
.no-hits-header p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
}
.no-hits-header .no-hits-search-suggestion {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 28px;
  padding-top: 10px;
}
@media (min-width: 768px) {
  .no-hits-header .inner-wrapper {
    max-width: 1180px;
  }
  .no-hits-header .inner-indented {
    padding: 107px 0 55px;
  }
  .no-hits-header h2 {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 60px;
    margin-bottom: 6px;
  }
  .no-hits-header p {
    font-size: 30px;
    line-height: 45px;
    margin: 0 auto 10px;
    max-width: 570px;
  }
}

.no-hits-help {
  width: 100%;
}
.no-hits-help .ho-hits-asset-holder {
  padding: 56px 0 26px;
}
.no-hits-help .simple-text-block.simple-text-block-small {
  margin-bottom: 57px;
}
.no-hits-help .simple-text-block.simple-text-block-small .inner-wrapper {
  min-height: 10px;
  padding: 0 30px;
}
.no-hits-help .simple-text-block.simple-text-block-small .heading-h7 {
  margin-bottom: 21px;
}
.no-hits-help .simple-text-block.simple-text-block-small a {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .no-hits-help .inner-wrapper {
    max-width: 1180px;
  }
  .no-hits-help .ho-hits-asset-holder {
    display: flex;
    padding: 68px 0 80px;
  }
  .no-hits-help .content-asset {
    width: 33.333%;
  }
  .no-hits-help .simple-text-block.simple-text-block-small {
    margin-bottom: 0;
  }
  .no-hits-help .simple-text-block.simple-text-block-small .inner-wrapper {
    justify-content: space-between;
    min-height: 111px;
    padding: 0 10px;
  }
}

.no-hits-search {
  background-color: #ffffff;
  padding: 79px 0 48px;
  text-align: center;
  width: 100%;
}
.no-hits-search h3 {
  font-size: 24px;
  letter-spacing: 0.4px;
  line-height: 30px;
  margin-bottom: 30px;
  text-transform: none;
}
.no-hits-search .inner-wrapper .inner-indented h2 {
  color: #000000;
  font-size: 32px;
  line-height: 33px;
  letter-spacing: 1px;
  text-transform: initial;
  max-width: 600px;
  margin: 0 auto;
}
.no-hits-search .inner-wrapper .inner-indented h2:last-of-type {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .no-hits-search .inner-wrapper .inner-indented h2 {
    font-size: 20px;
    line-height: 20.78px;
    max-width: 310px;
  }
  .no-hits-search .inner-wrapper .inner-indented h2:last-of-type {
    margin-bottom: 20px;
  }
}
.no-hits-search .inner-wrapper h3 {
  color: #000000;
  font-size: 16px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  line-height: 16.62px;
  letter-spacing: 1px;
  max-width: 310px;
  margin: 0 auto 40px;
  text-transform: initial;
}
@media (max-width: 767px) {
  .no-hits-search .inner-wrapper h3 {
    font-size: 14px;
    line-height: 14.55px;
    margin: 0 auto 30px;
    max-width: 310px;
  }
}
.no-hits-search form {
  padding: 0 28px;
  width: 100%;
}
@media (max-width: 767px) {
  .no-hits-search form {
    padding: 0 8px;
  }
}
.no-hits-search form .form-inner {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .no-hits-search form .form-inner {
    padding: 0 8px;
  }
}
.no-hits-search form .btn {
  font-size: 20px;
  margin-top: 20px;
  position: relative;
}
.no-hits-search form .btn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .no-hits-search form .btn {
    border-radius: 0 3px 3px 0;
    font-size: 20px;
    height: 47px;
    min-width: 50px;
  }
}
.no-hits-search form .inputfield-row label {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  font-weight: bold;
}
.no-hits-search form .inputfield-row input {
  border: 1px solid #575757;
  border-radius: 3px;
  margin-top: 3px;
  padding: 13px 21px;
}
@media (max-width: 767px) {
  .no-hits-search form .inputfield-row input {
    border-radius: 3px 0 0 3px;
    height: 47px;
  }
}
.no-hits-search form .inputfield-row button {
  margin: 0;
}
@media (min-width: 768px) {
  .no-hits-search {
    padding: 147px 0 107px;
  }
  .no-hits-search .inner-wrapper {
    max-width: 1180px;
  }
  .no-hits-search form {
    margin: 0 auto;
    max-width: 560px;
    padding: 0;
  }
  .no-hits-search form .form-inner {
    display: flex;
    align-items: flex-end;
  }
  .no-hits-search form .inputfield-row {
    flex-grow: 1;
    width: auto;
  }
  .no-hits-search form .btn {
    flex-shrink: 0;
    margin: 0;
    min-width: 100px;
    width: auto;
    height: 45px;
    margin-bottom: 1px;
  }
}

.no-hits-footer {
  background-color: #ffffff;
  display: flex;
  padding: 33px 20px 70px;
  text-align: center;
  width: 100%;
}
.no-hits-footer p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .no-hits-footer {
    padding: 14px 0 70px;
  }
  .no-hits-footer .inner-wrapper {
    max-width: 560px;
  }
}

.btn-wrapper-agent {
  margin-top: 5px;
}
.btn-wrapper-agent .btn {
  display: block;
  margin-left: auto;
  min-width: 0;
  background-color: #1a1919;
  border: 1px solid #1a1919;
  border-radius: 50%;
  font-size: 0;
  letter-spacing: 1.5px;
  height: 36px;
  padding: 0;
  width: 36px;
  color: #ffffff;
}
.btn-wrapper-agent .btn::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2c";
  display: block;
  font-size: 16px;
  margin-left: 1px;
}
@media (min-width: 1024px) {
  .btn-wrapper-agent .btn:hover, .btn-wrapper-agent .btn:active {
    border: 1px solid #1a1919;
    color: #1a1919;
  }
}

.nohits-btn {
  display: block;
  margin: 0 auto 50px;
  max-width: 250px;
}
@media (max-width: 767px) {
  .nohits-btn {
    margin: 0 auto 30px;
  }
}

.social-commerce-product-info {
  padding: 30px 20px;
  max-width: 400px;
  text-align: center;
}
.social-commerce-product-info img {
  width: 100%;
}
.social-commerce-product-info .price-standard {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2.4px;
  text-decoration: line-through;
  color: #8c8c8c;
  display: block;
  margin-top: 5px;
}

.social-commerce-cart-info {
  width: 400px;
}
@media (max-width: 767px) {
  .social-commerce-cart-info .checkout-summary-inner {
    width: 100%;
    margin: 0;
  }
}
.social-commerce-cart-info .section-header {
  font-weight: bold;
}
.social-commerce-cart-info .order-subtotal .cart-footer__item--name {
  font-weight: bold;
}

.social-commerce-cart-info-v3 {
  width: 400px;
}
@media (max-width: 767px) {
  .social-commerce-cart-info-v3 {
    padding: 15px 40px;
  }
}

.body--no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.page-layout-left {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}
@media (min-width: 1024px) {
  .page-layout-left {
    width: calc(100% - 360px);
    padding-right: 22px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .page-layout-left {
    padding-right: 40px;
    text-align: left;
    width: 50%;
  }
}
.page-layout-left .page-layout-left-title {
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  max-width: 250px;
  margin: -16px auto 9px;
  text-transform: none;
}
@media (min-width: 768px) {
  .page-layout-left .page-layout-left-title {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
    margin: 18px auto 13px;
    max-width: 480px;
  }
}
.login-checkout-v3 .page-layout-left .page-layout-left-title {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .login-checkout-v3 .page-layout-left .page-layout-left-title {
    font-size: 32px;
    letter-spacing: 1.4px;
    width: calc(100% - 112px);
  }
}
.page-layout-left .page-layout-left-subtitle {
  color: #767676;
  max-width: 285px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  line-height: 22px;
}
@media (max-width: 767px) {
  .page-layout-left .page-layout-left-subtitle {
    margin: 0;
    max-width: none;
  }
}
@media (min-width: 768px) {
  .page-layout-left .page-layout-left-subtitle {
    max-width: none;
    letter-spacing: 1px;
  }
}
.login-checkout-v3 .page-layout-left .page-layout-left-subtitle {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-bottom: 24px;
  text-align: left;
}
@media (max-width: 767px) {
  .login-checkout-v3 .page-layout-left .page-layout-left-subtitle {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-top: 24px;
    padding: 0 24px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .page-layout-left .page-layout-left-subtitle {
    font-size: 32px;
    height: 121px;
    letter-spacing: 1.4px;
    line-height: 40px;
    margin-top: 44px;
    overflow: hidden;
  }
}
.page-layout-left .no-link-top-margin {
  margin-top: 35px;
}
.page-layout-left .page-layout-left-icon {
  margin-top: -17px;
}
@media (min-width: 768px) {
  .page-layout-left .page-layout-left-icon {
    margin-top: 28px;
  }
}
.page-layout-left .page-layout-left-icon + .page-layout-left-title {
  margin: 21px auto 9px;
}
@media (min-width: 768px) {
  .page-layout-left .page-layout-left-icon + .page-layout-left-title {
    margin: 8px auto 14px;
  }
}
.login-checkout-v3 .page-layout-left .page-layout-left-icon + .page-layout-left-title {
  display: none;
}
.login-checkout-v3 .page-layout-left .page-layout-left-icon {
  display: none;
}
.page-layout-left .page-layout-left-back-button {
  position: absolute;
  top: -48px;
  left: -9px;
}
@media (min-width: 768px) {
  .page-layout-left .page-layout-left-back-button {
    top: -21px;
    left: -6px;
  }
}
@media (max-width: 767px) {
  .login-checkout-v3 .page-layout-left .page-layout-left-back-button {
    left: 24px;
  }
}

@media (min-width: 768px) {
  .page-layout-right {
    width: 100%;
    max-width: 320px;
  }
}
@media (min-width: 1024px) {
  .page-layout-right {
    max-width: 360px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .page-layout-right {
    max-width: none;
    width: 50%;
  }
}

.account-benefits {
  background-color: #ffffff;
}
.login-checkout-v3 .account-benefits {
  background-color: transparent;
}

.benefits-wrapper {
  padding: 21px 0 19px;
  text-align: center;
  width: 100%;
}
@media (max-width: 767px) {
  .login-checkout-v3 .benefits-wrapper {
    padding: 0 24px;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .benefits-wrapper {
    padding: 188px 0 0 0;
    text-align: left;
  }
}
.login-checkout-v3 .benefits-wrapper > .benefits-dynamic-block {
  display: none;
}
@media (min-width: 768px) {
  .login-checkout-v3 .page-layout-right.has-title .benefits-wrapper {
    padding-top: 260px;
  }
}
.login-checkout-v3 .benefits-wrapper .content-asset {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .login-checkout-v3 .benefits-wrapper .content-asset {
    padding: 40px 24px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .benefits-wrapper .content-asset {
    padding: 40px;
  }
}

.benefits-subtitle {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  color: #886655;
  text-transform: uppercase;
  margin-bottom: 19px;
  display: block;
  text-align: center;
}
.login-checkout-v3 .benefits-subtitle {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 1.7px;
  line-height: 16px;
  text-align: left;
}

.benefits-title {
  color: #1a1919;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0 auto 19px;
  max-width: 205px;
  text-transform: none;
}
@media (max-width: 767px) {
  .login-checkout-v3 .benefits-title {
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 24px;
    max-width: none;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .benefits-title {
    font-size: 32px;
    letter-spacing: 1.4px;
    line-height: 40px;
    max-width: none;
  }
}

.benefits-list {
  list-style: none;
  text-align: left;
  max-width: 287px;
  margin: 0 auto;
}
.benefits-list + .btn {
  margin-top: 16px;
}
@media (max-width: 767px) {
  .checkout-v3 .benefits-list, .login-checkout-v3 .benefits-list {
    padding: 0 20px;
  }
}
.login-checkout-v3 .benefits-list {
  max-width: none;
}
.benefits-list li {
  margin-bottom: -1px;
}
@media (min-width: 768px) {
  .benefits-list li {
    margin-bottom: -3px;
  }
}
@media (max-width: 767px) {
  .checkout-v3 .benefits-list li, .login-checkout-v3 .benefits-list li {
    margin-bottom: 8px;
  }
}
@media (min-width: 768px) {
  .checkout-v3 .benefits-list li, .login-checkout-v3 .benefits-list li {
    margin-bottom: 8px;
  }
}
.benefits-list li span {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 25px;
  color: #626262;
  width: calc(100% - 40px);
  display: inline-block;
}
.login-checkout-v3 .benefits-list li span {
  color: #1a1919;
}
.benefits-list li .icon-check {
  font-size: 11px;
  color: #1a1919;
  margin-right: 9px;
  vertical-align: top;
  display: inline-block;
  width: 15px;
}
.checkout-v3 .benefits-list li .icon-check, .login-checkout-v3 .benefits-list li .icon-check {
  color: #649b26;
}

.benefits-dynamic-block {
  display: flex;
  padding: 0 16px 0 26px;
  margin-top: 27px;
}
@media (min-width: 768px) {
  .benefits-dynamic-block {
    padding: 0 30px 0 37px;
  }
}
.benefits-dynamic-block .flyout-scroll-progress {
  display: none;
}
.benefits-dynamic-block .image-holder img {
  display: block;
  max-width: 70px;
}
.benefits-dynamic-block .text-holder {
  display: flex;
  align-items: center;
}
.benefits-dynamic-block .text-holder p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-left: 10px;
  margin: 0;
}

.benefits-progress {
  position: relative;
}
.benefits-progress.product-gift-ribbon::after {
  opacity: 1;
  top: -40px;
}
@media (max-width: 767px) {
  .benefits-progress.product-gift-ribbon::after {
    right: -24px;
  }
}
@media (min-width: 768px) {
  .benefits-progress.product-gift-ribbon::after {
    right: -40px;
  }
}
.benefits-progress .benefits-dynamic-block {
  border: 1px solid #d8d8d8;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: 560px;
  padding: 16px;
}
.benefits-progress .benefits-dynamic-block .image-holder img {
  max-width: 56px;
}
.benefits-progress .benefits-dynamic-block .text-holder {
  display: block;
  margin-right: 24px;
  padding-bottom: 16px;
  position: relative;
}
.benefits-progress .benefits-dynamic-block .text-holder p {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 0;
}
.benefits-progress .benefits-dynamic-block .flyout-scroll-progress {
  bottom: 0;
  display: block;
  left: 0;
  width: 100%;
}
.benefits-progress h4 {
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  text-transform: none;
}

.login-wrapper {
  padding: 57px 0 80px;
}
@media (min-width: 768px) {
  .login-wrapper {
    padding: 61px 0 160px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper {
    padding: 40px 0 160px;
  }
}
.login-wrapper.outlet .login-panel__form {
  margin-top: -35px;
}
@media (min-width: 768px) {
  .login-wrapper.outlet .login-panel__form {
    margin-top: -19px;
  }
}
@media (max-width: 767px) {
  .login-checkout-v3 .login-wrapper .inner-wrapper {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper .inner-wrapper-small {
    max-width: none;
    padding: 0 30px;
  }
}
.login-wrapper .login-panel__form {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .login-wrapper .login-panel__form {
    max-width: 600px;
    margin: 44px auto 0;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper .login-panel__form {
    margin: 0;
    max-width: none;
  }
}
.login-checkout-v3 .login-wrapper .login-panel__form .full-width-row + .full-width-row {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper .login-panel__form .form-row-button:not(.full-width-row) {
    margin-top: 40px;
  }
}
.login-checkout-v3 .login-wrapper .rituals-form {
  background-color: #ffffff;
  margin: 0;
  max-width: none;
}
@media (max-width: 767px) {
  .login-checkout-v3 .login-wrapper .rituals-form {
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper .rituals-form {
    padding: 40px;
  }
}
.login-checkout-v3 .login-wrapper .rituals-form .btn {
  max-width: none;
  width: 100%;
}
.login-wrapper hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d8d8d8;
  padding: 0;
  margin: 28px 0 31px;
}
@media (min-width: 768px) {
  .login-wrapper hr {
    margin: 41px 0 36px;
  }
}
.login-checkout-v3 .login-wrapper hr {
  background-color: #ffffff;
  border: 0;
  position: relative;
}
@media (max-width: 767px) {
  .login-checkout-v3 .login-wrapper hr {
    margin: -24px 0 0 0;
    padding: 28px 40px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper hr {
    margin: -40px 0 0 0;
    padding: 38px 40px;
  }
}
.login-checkout-v3 .login-wrapper hr::before {
  background-image: linear-gradient(90deg, rgba(26, 25, 25, 0) 0%, rgba(26, 25, 25, 0.4) 50%, rgba(26, 25, 25, 0) 100%);
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: calc(100% - 80px);
  z-index: 1;
}
@media (max-width: 767px) {
  .login-checkout-v3 .login-wrapper hr::before {
    top: 28px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .login-wrapper hr::before {
    top: 38px;
  }
}
.login-checkout-v3 .login-wrapper hr::after {
  background-color: #ffffff;
  content: attr(data-text);
  font-size: 14px;
  left: 50%;
  letter-spacing: 0.4px;
  padding: 0 14px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.login-wrapper .account-usp {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 25px;
  margin: 25px auto 0;
  max-width: 285px;
}
.login-checkout-v3 .login-wrapper .account-usp {
  background-color: #ffffff;
  margin: 0;
  max-width: none;
  padding: 0 40px 40px 40px;
}
.login-wrapper .password-reset {
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  float: right;
  padding: 16px 4px 33px 0;
}
@media (min-width: 768px) {
  .login-wrapper .password-reset {
    padding-bottom: 20px;
  }
}
.login-checkout-v3 .login-wrapper .password-reset {
  float: left;
}
.login-wrapper .choose-account-left .form-row-button {
  padding: 0 10px;
}
.login-checkout-v3 .login-wrapper .choose-account-left .form-row-button {
  padding: 0;
}
.login-wrapper .choose-account-left .form-row-button .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .login-wrapper .choose-account-left .form-row-button .btn {
    width: auto;
  }
  .login-checkout-v3 .login-wrapper .choose-account-left .form-row-button .btn {
    width: 100%;
  }
}
.login-checkout-v3 .login-wrapper .choose-account-left .form-row-button .btn {
  max-width: none;
}

.login-overlay {
  background-color: #fcfaf7;
  bottom: -100%;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  visibility: visible;
  width: 100%;
  z-index: 980;
  -webkit-overflow-scrolling: touch;
}
.login-overlay.overlay-visible {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .login-overlay .checkout-register-wrapper {
    padding: 117px 20px 30px;
  }
  .login-overlay .login-wrapper {
    padding: 117px 0 30px;
  }
}

.pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .page-layout-left-title {
  display: block;
}
@media (max-width: 767px) {
  .pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .page-layout-left-title {
    font-size: 32px;
    letter-spacing: 1.2px;
    line-height: 40px;
    max-width: none;
    width: calc(100% - 48px);
  }
}
@media (min-width: 768px) {
  .pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .page-layout-left-title {
    font-size: 40px;
    letter-spacing: 1.8px;
    line-height: 48px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .page-layout-left-icon + .page-layout-left-title {
    margin: 24px;
  }
}
@media (min-width: 768px) {
  .pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .page-layout-left-icon + .page-layout-left-title {
    margin: 40px 0;
  }
}
.pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .page-layout-left-icon {
  display: none;
}
@media (min-width: 768px) {
  .pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) .benefits-wrapper {
    padding-top: 178px;
  }
}

.pt_recommended-products {
  width: 100%;
}
.pt_recommended-products.one-by-three-grid .grid-wrap {
  margin-left: 0;
}
.pt_recommended-products.one-by-three-grid .item {
  margin: 5px 5px 10px;
  width: calc(50% - 10px);
}
@media (min-width: 768px) {
  .pt_recommended-products.one-by-three-grid .item {
    margin-bottom: 20px;
    width: calc(50% - 20px);
  }
}
.pt_recommended-products.one-by-three-grid .recommendations-block-wrapper .section-title {
  padding-left: 5px;
}

[id^=cq_recomm] {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.pt_faq .faq-page-content {
  background-color: #f6f4f2;
  margin: 0 auto;
  padding-top: 39px;
}
@media (min-width: 768px) {
  .pt_faq .faq-page-content {
    padding-top: 79px;
  }
}
.pt_faq .faq-content .inner-wrapper {
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .pt_faq .faq-content .inner-wrapper {
    margin: 0 auto;
    padding: 0 20px;
  }
}
.pt_faq .faq-content .first-item {
  pointer-events: all;
  color: #1a1919;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 4px;
  cursor: pointer;
  white-space: normal;
  margin: 0 0 18px 20px;
  max-width: 310px;
  text-transform: none;
}
@media (min-width: 768px) {
  .pt_faq .faq-content .first-item {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin: 0 0 34px 15px;
    padding-bottom: 22px;
    border-bottom: 1px solid #d8d8d8;
    max-width: none;
  }
}
.pt_faq .faq-wrapper {
  display: flex;
  flex-direction: row;
}
.pt_faq .page-content {
  width: 100%;
  overflow: hidden;
}
.pt_faq .faq-back-button {
  position: absolute;
  top: 20px;
  font-size: 12px;
  letter-spacing: 2px;
  color: #626262;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
@media (min-width: 768px) {
  .pt_faq .faq-back-button {
    display: none;
  }
}
.pt_faq .faq-back-button span {
  vertical-align: middle;
  margin-right: 5px;
  font-size: 12px;
  line-height: 12px;
}
.pt_faq .faq-inner {
  display: flex;
  justify-content: stretch;
  width: 200%;
  flex-direction: column;
  padding-top: 30px;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner {
    width: 100%;
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  .pt_faq .faq-inner {
    padding: 24px 106px 0 115px;
  }
}
.pt_faq .faq-inner.fixed .faq-contact-headline {
  display: block;
  margin: -44px 0 25px;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner.fixed .faq-contact-headline {
    margin-top: -21px;
  }
}
.pt_faq .faq-inner .faq-left-content {
  flex: 1;
  margin: 0 20px;
}
@media (max-width: 767px) {
  .pt_faq .faq-inner .faq-left-content {
    flex: none;
    margin-right: auto;
    padding-bottom: 60px;
    width: calc(50% - 20px);
  }
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-left-content {
    max-width: 280px;
    border-top: 0;
    margin: 0;
    padding-bottom: 30px;
  }
}
.pt_faq .faq-inner .faq-contact-holder {
  width: 100%;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-contact-holder {
    display: none;
  }
}
.pt_faq .faq-inner .faq-contact-holder .back-button,
.pt_faq .faq-inner .faq-contact-holder .faq-contact-headline {
  display: none;
}
.pt_faq .faq-inner .faq-contact-holder .faq-contact {
  padding-top: 30px;
}
.pt_faq .faq-inner .faq-right-content {
  margin-left: 0;
  flex: 1;
}
@media (max-width: 767px) {
  .pt_faq .faq-inner .faq-right-content {
    flex: none;
    left: 100%;
    margin-left: auto;
    position: absolute;
    top: 0;
    width: calc(50% - 20px);
  }
  .pt_faq .faq-inner .faq-right-content .faq-back-button {
    top: -83px;
  }
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content {
    margin-left: 30px;
    margin-top: 7px;
    flex: 2;
  }
}
@media (min-width: 1024px) {
  .pt_faq .faq-inner .faq-right-content {
    margin-left: 76px;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .pt_faq .faq-inner .faq-right-content .contact-form-wrapper .contact-us-headline {
    margin-top: -33px;
  }
}
@media (max-width: 767px) {
  .pt_faq .faq-inner .faq-right-content .contact-form-wrapper:not(:empty) {
    margin-bottom: 80px;
  }
}
.pt_faq .faq-inner .faq-right-content .contact-form-wrapper:not(:empty) + .faq-contact .back-button,
.pt_faq .faq-inner .faq-right-content .contact-form-wrapper:not(:empty) + .faq-contact .faq-contact-headline {
  display: none;
}
.pt_faq .faq-inner .faq-right-content .back-button {
  position: absolute;
  top: -104px;
  left: -9px;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content .back-button {
    display: none;
  }
}
.pt_faq .faq-inner .faq-right-content .accordion {
  margin-top: 36px;
  padding-left: 0;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content .accordion {
    margin-top: 20px;
  }
}
.pt_faq .faq-inner .faq-right-content .accordion > li {
  margin-left: 0;
  margin-right: 26px;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content .accordion > li {
    margin: 0 21px;
  }
}
.pt_faq .faq-inner .faq-right-content .accordion > li:first-of-type {
  border-top: 1px solid rgba(136, 102, 85, 0.2);
}
.pt_faq .faq-inner .faq-right-content .accordion > li:empty {
  border: 0;
}
.pt_faq .faq-inner .faq-right-content h3 {
  color: #1a1919;
  font-size: 24px;
  letter-spacing: 1.2px;
  white-space: normal;
  line-height: 30px;
  margin: -45px 20px 25px 0;
  text-transform: none;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content h3 {
    margin: 0 0 0 18px;
    line-height: 44px;
    font-size: 24px;
    letter-spacing: 0.46px;
  }
}
.pt_faq .faq-inner .faq-right-content h3 span {
  vertical-align: middle;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content h3 span {
    display: none;
  }
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion,
.pt_faq .faq-inner .faq-right-content h3 + form {
  display: block;
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion h2,
.pt_faq .faq-inner .faq-right-content h3 + form h2 {
  text-transform: none;
  padding: 29px 30px 29px 0;
  font-size: 16px;
  color: #1a1919;
  letter-spacing: 1.07px;
  line-height: 20px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  border-bottom: 1px solid rgba(136, 102, 85, 0.2);
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion h2.ui-state-active,
.pt_faq .faq-inner .faq-right-content h3 + form h2.ui-state-active {
  border-color: transparent;
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion h2 .ui-accordion-header-icon,
.pt_faq .faq-inner .faq-right-content h3 + form h2 .ui-accordion-header-icon {
  top: 50%;
  left: auto;
  right: 0;
  position: absolute;
  margin-top: -15px;
  text-indent: 0;
  font-size: 25px;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content h3 + .accordion h2 .ui-accordion-header-icon,
  .pt_faq .faq-inner .faq-right-content h3 + form h2 .ui-accordion-header-icon {
    margin-top: -10px;
  }
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content {
  margin-bottom: 0;
  padding: 0 0 20px;
  border-bottom: 1px solid rgba(136, 102, 85, 0.2);
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content p,
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content ul,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content p,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content ul {
  font-size: 16px;
  color: #453f3f;
  letter-spacing: 1px;
  line-height: 28px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  margin-bottom: 10px;
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content ul,
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content table,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content ul,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content table {
  margin-bottom: 10px;
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content h3,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content h3 {
  margin: 0 0 10px;
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content th,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content th {
  text-align: left;
  padding-right: 10px;
}
.pt_faq .faq-inner .faq-right-content h3 + .accordion .ui-accordion-content td,
.pt_faq .faq-inner .faq-right-content h3 + form .ui-accordion-content td {
  padding-right: 10px;
}
.pt_faq .faq-inner .faq-right-content h3 + form {
  padding: 26px 2.2em;
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content .contact-us-headline {
    margin: 8px 0 16px 20px;
  }
}
@media (max-width: 767px) {
  .pt_faq .faq-inner .faq-right-content form {
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .pt_faq .faq-inner .faq-right-content form {
    padding-left: 20px;
    max-width: 620px;
  }
}
.pt_faq .faq-sidebar {
  list-style: none;
  padding: 0;
}
@media (min-width: 768px) {
  .pt_faq .faq-sidebar {
    margin-top: 18px;
  }
}
.pt_faq .faq-sidebar .faq-menu-option {
  margin: 20px 10px 20px 0;
  text-transform: none;
  cursor: pointer;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  color: #9a7b69;
  letter-spacing: 1px;
  text-decoration: underline;
  font-size: 16px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .pt_faq .faq-sidebar .faq-menu-option {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    margin: 10px 0 20px 16px;
    border: 0;
  }
}
.pt_faq .faq-sidebar .faq-menu-option:first-of-type {
  margin-right: 20px;
  padding-top: 33px;
  border-top: 1px solid #d8d8d8;
}
@media (min-width: 768px) {
  .pt_faq .faq-sidebar .faq-menu-option:first-of-type {
    margin-right: 0;
    padding-top: 0;
    border-top: 0;
  }
}
.pt_faq .faq-sidebar .faq-menu-option:hover {
  text-decoration: none;
}
.pt_faq .faq-sidebar .faq-menu-option.active {
  color: #453f3f;
  text-decoration: none;
}
.pt_faq .faq-view-mobile {
  transform: translateX(0);
  transition: all 0.3s ease;
}
.pt_faq .faq-view-mobile.active-content {
  transform: translateX(-50%);
}
.pt_faq .faq-view-mobile.active-content .faq-left-content {
  position: absolute;
  right: 100%;
  top: 0;
}
.pt_faq .faq-view-mobile.active-content .faq-right-content {
  left: auto;
  padding-bottom: 30px;
  position: relative;
  top: auto;
}
.pt_faq .faq-contact {
  padding: 70px 20px 0 0;
}
@media (min-width: 768px) {
  .pt_faq .faq-contact {
    padding: 20px 10px 20px 20px;
  }
}
.pt_faq .faq-contact h3 {
  display: none;
}
.pt_faq .faq-contact li {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  color: #453f3f;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 270px;
}
@media (min-width: 768px) {
  .pt_faq .faq-contact li {
    max-width: none;
  }
}
.pt_faq .faq-contact li:first-of-type {
  margin-top: -15px;
}
@media (min-width: 768px) {
  .pt_faq .faq-contact li:first-of-type {
    margin-top: -9px;
  }
}
.pt_faq .faq-contact li b {
  font-weight: 700;
}

.faq-questions {
  margin-top: 10px;
  padding-left: 0;
}
@media (min-width: 768px) {
  .faq-questions {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .faq-questions .icon-arrow3--left {
    display: none;
  }
}
.faq-questions li {
  list-style: none;
}
.faq-questions ul {
  padding-left: 0;
}
.faq-questions .faq-contact-headline {
  display: block;
  color: #1a1919;
  text-transform: none;
  line-height: 44px;
  font-size: 24px;
  letter-spacing: 0.46px;
  margin: -44px 0 25px;
}
@media (min-width: 768px) {
  .faq-questions .faq-contact-headline {
    margin-top: -21px;
  }
}
.faq-questions .content {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .faq-questions .content {
    padding: 40px 0 24px;
    max-width: 690px;
  }
}
@media (min-width: 1024px) {
  .faq-questions .content {
    padding: 51px 0 24px;
  }
}
.faq-questions .content .btn {
  margin-top: 0;
  margin-bottom: 30px;
}
.faq-questions p {
  font-size: 20px;
  color: #000000;
  line-height: 34px;
  letter-spacing: 1.67px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .faq-questions p {
    margin-bottom: 41px;
    max-width: 385px;
  }
}
.faq-questions .row {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .faq-questions .row {
    flex-direction: row;
  }
}
.faq-questions .row__item {
  flex: 1;
}
.faq-questions .row__item .row__item__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #9a7b69;
  line-height: 2px;
  margin-bottom: 31px;
}
@media (min-width: 768px) {
  .faq-questions .row__item .row__item__content {
    margin-bottom: 26px;
  }
}
.faq-questions .row__item .row__item__content.btn--service a {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
}
.faq-questions .row__item .row__item__content a {
  color: inherit;
}
.faq-questions .row__item .row__item__content a:hover {
  text-decoration: none;
}
.faq-questions .row__item .row__item__content em {
  display: block;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 12px;
}
.faq-questions .row__item .row__item__content em:hover {
  text-decoration: none;
}
.faq-questions .row__item .row__item__content .icon--medium {
  color: #000000;
  margin-right: 13px;
  font-size: 44px;
  line-height: 40px;
}
.faq-questions .row__item .row__item__content .icon--medium::before {
  vertical-align: middle;
}
.faq-questions .row__item .row__item__content .icon-mail,
.faq-questions .row__item .row__item__content .icon-email {
  font-size: 34px;
}
.faq-questions .row__item .row__item__content .icon-mail:before,
.faq-questions .row__item .row__item__content .icon-email:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4d";
}
.faq-questions .row__item .row__item__content .icon-service,
.faq-questions .row__item .row__item__content .icon-user {
  font-size: 36px;
}
.faq-questions .row__item .row__item__content .icon-service:before,
.faq-questions .row__item .row__item__content .icon-user:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\eadf";
}
.faq-questions .row__item .row__item__content .clickable {
  cursor: pointer;
}
.faq-questions .row__item .row__item__content .open-hours-content {
  line-height: 1;
  margin-bottom: 5px;
}
.faq-questions .row__item .row__item__content .open-hours-content span {
  font-size: 14px;
  color: #767676;
  letter-spacing: 0.7px;
}
.faq-questions .row__item .row__item__content .open-hours-content span.opening-days {
  margin-right: 13px;
  color: #453f3f;
}

.faq-page-title {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: none;
  margin: 0 auto 8px;
  max-width: 220px;
}
@media (min-width: 768px) {
  .faq-page-title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.8px;
    margin: 0 auto 16px;
    max-width: 400px;
  }
}

.faq-page-subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .faq-page-subtitle {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
    max-width: 615px;
    margin: 0 auto;
  }
}

.faq-text-container {
  background-color: #efedea;
  padding: 41px 0 28px;
}
@media (min-width: 768px) {
  .faq-text-container {
    padding: 60px 0 204px;
  }
}

.faq-question-form-blocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 960px;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);
}
@media (min-width: 768px) {
  .faq-question-form-blocks {
    flex-direction: row;
    width: 100%;
    margin: -144px auto 30px;
  }
}

.faq-question-block,
.faq-form-block {
  width: 100%;
}
@media (min-width: 768px) {
  .faq-question-block,
  .faq-form-block {
    width: 50%;
  }
}

.faq-form-block {
  background-color: #242222;
  padding: 41px 24px 19px;
}
@media (min-width: 768px) {
  .faq-form-block {
    padding: 22px 40px 40px;
  }
}
.faq-form-block .wimo-form-wrapper {
  text-align: left;
}
.faq-form-block .wimo-form-wrapper .wimo-form-title,
.faq-form-block .wimo-form-wrapper .wimo-form-subtitle {
  color: #ffffff;
}
.faq-form-block .wimo-form-wrapper .wimo-form-title {
  font-style: normal;
}
@media (min-width: 768px) {
  .faq-form-block .wimo-form-wrapper .wimo-form-title {
    margin: 18px auto 17px;
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .faq-form-block .wimo-form-wrapper .wimo-form-title {
    font-size: 20px;
    letter-spacing: 0.4px;
    margin: 0 auto 18px;
    max-width: none;
    text-align: center;
  }
}
.faq-form-block .wimo-form-wrapper .wimo-form-subtitle {
  letter-spacing: 0.4px;
  line-height: 32px;
}
@media (max-width: 767px) {
  .faq-form-block .wimo-form-wrapper .wimo-form-subtitle {
    font-style: 16px;
    max-width: none;
  }
}
.faq-form-block .wimo-form {
  margin-top: 27px;
}
.faq-form-block .wimo-form .error-message {
  color: #ffffff;
}
.faq-form-block .error-form {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
.faq-form-block .inputfield-row label {
  color: #ffffff;
}
.faq-form-block .inputfield-row input {
  background-color: #1a1919;
  border-color: #453f3f;
  color: #b4b4b4;
}
.faq-form-block .inputfield-row input::placeholder {
  color: #b4b4b4;
}
.faq-form-block .inputfield-row input.error {
  border-color: #ad4141;
}
@media (min-width: 768px) {
  .faq-form-block .form-row-button:not(.full-width-row) {
    margin-top: 42px;
  }
}
.faq-form-block .btn {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #1a1919;
}
.faq-form-block .btn:hover, .faq-form-block .btn:active {
  background-color: transparent;
  color: #ffffff;
}
@media (max-width: 767px) {
  .faq-form-block .btn {
    width: 100%;
    max-width: none;
  }
}
.faq-form-block .btn .spinner span {
  background-color: #453f3f;
}
.faq-form-block .wimo-login {
  color: #b8a188;
}
.faq-form-block .special-order-warning {
  text-align: left;
}

.faq-contact-block {
  width: 100%;
  text-align: center;
  padding: 45px 0 50px;
  background-color: #efedea;
  color: #1a1919;
}
@media (min-width: 768px) {
  .faq-contact-block {
    padding: 84px 0 80px;
  }
}
.flyin-component .faq-contact-block {
  background-color: #ffffff;
  text-align: left;
}
@media (max-width: 767px) {
  .flyin-component .faq-contact-block {
    padding: 48px 0 0 0;
  }
}
@media (min-width: 768px) {
  .flyin-component .faq-contact-block {
    padding: 64px 0 0 0;
  }
}
@media (max-width: 767px) {
  .flyin-component .faq-contact-block + .btn {
    margin-top: 16px;
  }
}
@media (min-width: 768px) {
  .flyin-component .faq-contact-block + .btn {
    margin-top: 24px;
  }
}
.flyin-component .faq-contact-block .inner-wrapper {
  margin: 0;
  max-width: none;
  padding: 0;
}
.faq-contact-block .title {
  font-size: 24px;
  letter-spacing: 0.5px;
  text-transform: none;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .faq-contact-block .title {
    font-size: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 22px;
  }
}
@media (min-width: 768px) {
  .flyin-component .faq-contact-block .title {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
  }
}
.faq-contact-block .description {
  font-size: 16px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  max-width: 345px;
  margin: 0 auto 15px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .faq-contact-block .description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 18px;
  }
}
.flyin-component .faq-contact-block .description {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  margin: 0;
  max-width: none;
}
@media (min-width: 768px) {
  .flyin-component .faq-contact-block .description {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}
.faq-contact-block .description .time {
  font-family: "Scala Sans Pro Bold", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
}
.faq-contact-block .divider {
  max-width: 329px;
  width: 100%;
  height: 1px;
  background-color: #b4b4b4;
  display: block;
  margin: 0 auto 16px;
}
@media (min-width: 768px) {
  .faq-contact-block .divider {
    margin-bottom: 23px;
  }
}
.faq-contact-block .opening-times {
  display: block;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .faq-contact-block .opening-times {
    padding: 0;
  }
}
.faq-contact-block .card-wrapper {
  width: 100%;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .faq-contact-block .card-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 26px;
  }
}
@media (min-width: 1024px) {
  .faq-contact-block .card-wrapper {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1024px) {
  .flyin-component .faq-contact-block .card-wrapper {
    flex-wrap: wrap;
  }
}
.faq-contact-block .card-item {
  width: 100%;
  background-color: #ffffff;
  position: relative;
  padding: 22px 20px 23px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .faq-contact-block .card-item {
    width: 45%;
    margin: 0 16px 24px;
    padding: 30px 20px 25px;
  }
}
@media (min-width: 1024px) {
  .faq-contact-block .card-item {
    width: 25%;
    margin: 0 16px;
  }
}
.flyin-component .faq-contact-block .card-item {
  border: 1px solid #b4b4b4;
  margin: 0;
}
@media (min-width: 1024px) {
  .flyin-component .faq-contact-block .card-item {
    width: 100%;
  }
}
.flyin-component .faq-contact-block .card-item + .card-item {
  margin-top: 16px;
}
@media (min-width: 1024px) {
  .flyin-component .faq-contact-block .card-item + .card-item {
    margin-top: 24px;
  }
}
.flyin-component .faq-contact-block .card-item:hover {
  background-color: #f6f4f2;
  border-color: #9a7b69;
}
.flyin-component .faq-contact-block .card-item.email {
  display: none;
}
.faq-contact-block .card-item .link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.faq-contact-block .card-item .icon {
  font-size: 50px;
  display: block;
  margin-bottom: 10px;
}
.flyin-component .faq-contact-block .card-item .icon {
  position: absolute;
  right: 24px;
}
.faq-contact-block .card-item .icon.icon-green {
  color: #25d366;
}
.faq-contact-block .card-item .icon.icon-blue {
  color: #1677ff;
}
.faq-contact-block .card-item .icon.icon-brown {
  color: #9a7b69;
}
.faq-contact-block .card-item .card-title {
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 35px;
  margin-bottom: 17px;
  text-transform: none;
}
@media (min-width: 768px) {
  .faq-contact-block .card-item .card-title {
    font-size: 28px;
  }
}
.faq-contact-block .card-item .card-instruction {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  margin-bottom: 12px;
}
.flyin-component .faq-contact-block .card-item .card-instruction {
  color: #9a7b69;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-decoration: underline;
  text-transform: none;
}
.faq-contact-block .card-item .card-description {
  display: block;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}
.flyin-component .faq-contact-block .card-item .card-description {
  font-style: normal;
}
.faq-contact-block .card-item .response-time {
  font-size: 14px;
  letter-spacing: 0.4px;
}
.faq-contact-block .card-item .response-time span {
  display: inline-block;
}
.faq-contact-block .card-item .response-time .response-time-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 13px;
}
.faq-contact-block .card-item .response-time.available .response-time-circle {
  background-color: #649b26;
}
.faq-contact-block .card-item .response-time.busy .response-time-circle {
  background-color: #ffb184;
}
.faq-contact-block .card-item .response-time.unavailable .response-time-circle {
  background-color: #ad4141;
}

.faq-question-block {
  background-color: #1a1919;
  padding: 41px 24px 33px;
}
@media (min-width: 768px) {
  .faq-question-block {
    padding: 40px;
  }
}
.faq-question-block .title {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.4px;
  color: #ffffff;
  text-transform: none;
  text-align: center;
  margin-bottom: 19px;
}
@media (min-width: 768px) {
  .faq-question-block .title {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.6px;
    text-align: left;
    margin-bottom: 17px;
  }
}
.faq-question-block .title span {
  font-style: italic;
}

.flyin-component .faq-question-blocks {
  margin-top: 40px;
}
.flyin-component .faq-question-blocks .title {
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  margin-bottom: 24px;
  text-transform: none;
}

.faq-questions-wrapper {
  background-color: #1a1919;
  list-style: none;
  counter-reset: item;
  padding: 0;
}
.faq-questions-wrapper li {
  margin-bottom: 4px;
  counter-increment: item;
}
@media (min-width: 768px) {
  .faq-questions-wrapper li {
    margin-bottom: 8px;
  }
}
.faq-questions-wrapper li::before {
  content: counter(item) ".";
  color: #b8a188;
  width: 24px;
  display: inline-block;
  vertical-align: top;
}
.faq-questions-wrapper a {
  text-decoration: none;
}
.faq-questions-wrapper span {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .faq-questions-wrapper span {
    font-size: 16px;
    line-height: 32px;
  }
}
.faq-questions-wrapper .text {
  color: #ffffff;
  width: calc(100% - 40px);
  display: inline-block;
}

.faq-question-tiles {
  margin: 41px 0 30px;
}
@media (min-width: 768px) {
  .faq-question-tiles {
    margin: 80px 0 48px;
  }
}
.faq-question-tiles .title {
  text-align: center;
  font-size: 20px;
  line-height: 20.78px;
  letter-spacing: 1px;
  text-transform: none;
  color: #1a1919;
  max-width: 300px;
  margin: 0 auto 23px;
}
@media (min-width: 768px) {
  .faq-question-tiles .title {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.6px;
    margin-bottom: 40px;
    max-width: none;
  }
}
.faq-question-tiles .tiles-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.faq-question-tiles .tile {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  margin: 0 0 16px;
  padding: 25px 40px 20px;
}
@media (min-width: 768px) {
  .faq-question-tiles .tile {
    width: 45%;
    margin: 0 2.5% 32px;
    padding: 30px 40px 37px;
  }
}
@media (min-width: 1024px) {
  .faq-question-tiles .tile {
    width: 30%;
    margin: 0 1.6% 32px;
  }
}
.faq-question-tiles .icon {
  display: block;
  text-align: center;
  color: #886655;
  font-size: 25px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .faq-question-tiles .icon {
    font-size: 30px;
    margin-bottom: 14px;
  }
}
.faq-question-tiles .tile-title {
  text-align: center;
  text-transform: none;
  margin-bottom: 17px;
}
.faq-question-tiles .tile-title a {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (min-width: 768px) {
  .faq-question-tiles .tile-title a {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.6px;
    margin-bottom: 24px;
  }
}
.faq-question-tiles .question {
  display: block;
  margin-bottom: 8px;
  text-decoration: none;
  color: #1a1919;
  line-height: 24px;
  align-self: flex-start;
  max-width: 100%;
  font-size: 14px;
  letter-spacing: 0.4px;
}
@media (min-width: 768px) {
  .faq-question-tiles .question {
    font-size: 16px;
    margin-bottom: 17px;
  }
}
.faq-question-tiles .link {
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-top: 6px;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .faq-question-tiles .link {
    margin-top: 24px;
  }
}
@media (min-width: 768px) {
  .faq-question-tiles .link {
    font-size: 16px;
  }
}

.faq-theme-wrapper .layout {
  padding: 30px 0 40px;
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 1025px) {
  .faq-theme-wrapper .layout {
    flex-direction: row;
    padding: 50px 20px 67px;
  }
}
.faq-theme-wrapper .layout-left {
  max-width: 350px;
}
@media (max-width: 1023px) {
  .faq-theme-wrapper .layout-left {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: none;
  }
}
.faq-theme-wrapper .layout-right {
  width: 100%;
}
@media (min-width: 1025px) {
  .faq-theme-wrapper .layout-right {
    width: calc(100% - 350px);
    padding-left: 112px;
  }
}

.btn-download-page {
  background-color: transparent;
  border-color: rgba(26, 25, 25, 0.16);
  color: #000000;
  margin: 30px auto;
  max-width: 350px;
  width: 100%;
}
@media (max-width: 1023px) {
  .btn-download-page {
    max-width: fit-content;
  }
}
@media (min-width: 1024px) {
  .btn-download-page.mobile {
    display: none;
  }
}
@media (max-width: 1023px) {
  .btn-download-page.desktop {
    display: none;
  }
}

.faq-theme-back-button {
  display: block;
  margin: 6px 0 13px -5px;
}
@media (min-width: 1025px) {
  .faq-theme-back-button {
    display: none;
  }
}

.faq-theme-mobile-dropdown-wrap {
  position: relative;
  width: 100%;
}
.faq-theme-mobile-dropdown-wrap .faq-theme-mobile-dropdown-placeholder {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.faq-theme-mobile-dropdown-wrap .faq-theme-mobile-dropdown-placeholder.in-place {
  left: auto;
  position: relative;
  top: auto;
}

.faq-theme-mobile-dropdown {
  display: none;
  background-color: #242222;
  padding: 24px 18px;
  width: 100%;
  position: relative;
  z-index: 992;
}
.faq-theme-mobile-dropdown.sticky {
  left: 0;
  position: fixed;
  top: 0;
}
@media (max-width: 1023px) {
  .faq-theme-mobile-dropdown {
    display: block;
  }
}
.faq-theme-mobile-dropdown select {
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 0;
}
.faq-theme-mobile-dropdown .mobile-title {
  color: #ffffff;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 21px;
  margin: 6px 22px 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .faq-theme-mobile-dropdown .mobile-title {
    display: none;
  }
}

.faq-nav {
  background-color: #1a1919;
  padding: 28px 40px 44px;
  text-align: left;
  width: 100%;
  display: none;
}
@media (min-width: 1025px) {
  .faq-nav {
    display: block;
  }
}

.breadcrumb.faq-theme-breadcrumb {
  padding: 0;
  margin-bottom: 33px;
}
.breadcrumb.faq-theme-breadcrumb .breadcrumb-item {
  display: inline-block;
  text-decoration: none;
}
.breadcrumb.faq-theme-breadcrumb .breadcrumb-element {
  color: #968977;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-transform: none;
}
.breadcrumb.faq-theme-breadcrumb .breadcrumb-element::after {
  content: "/";
  padding: 0 4px;
}

.nav-links {
  list-style: none;
  width: 100%;
}
.nav-links .nav-link {
  margin: 0 0 22px;
}
.nav-links .nav-link:last-child {
  margin: 0;
}
.nav-links .nav-link button {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 0.9px;
  line-height: 28px;
  padding: 0 0 1px;
  position: relative;
  text-decoration: none;
  text-transform: none;
  text-align: left;
}
.nav-links .nav-link button::after {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: -1px;
  position: absolute;
  transition: width 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 0;
}
.nav-links .nav-link button:hover::after, .nav-links .nav-link button.active::after {
  width: 100%;
}

.faq-block.faq-theme-block {
  padding: 0;
}
.faq-block.faq-theme-block .inner-wrapper {
  padding: 0;
}
.faq-block.faq-theme-block .faq-theme-title {
  color: #1a1919;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-transform: none;
  margin-bottom: 23px;
  text-align: left;
}
@media (min-width: 1025px) {
  .faq-block.faq-theme-block .faq-theme-title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.8px;
    margin-bottom: 40px;
  }
}
.faq-block.faq-theme-block .faq-theme-subtitle p {
  margin: -10px 0 30px;
  text-align: left;
}
.faq-block.faq-theme-block .faq-accordion-item,
.faq-block.faq-theme-block .faq-accordion {
  border-color: #b4b4b4;
}
.faq-block.faq-theme-block .faq-accordion-head {
  padding: 24px 110px 23px 0;
}
@media (min-width: 1025px) {
  .faq-block.faq-theme-block .faq-accordion-head .acc-head-title {
    font-size: 20px;
    letter-spacing: 0.4px;
  }
}
.faq-block.faq-theme-block .faq-accordion-content {
  padding: 0 42px 20px 0;
}
@media (min-width: 1025px) {
  .faq-block.faq-theme-block .faq-accordion-content p {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 32px;
  }
}
.faq-block.faq-theme-block .faq-accordion-content .btn {
  margin: 14px 11px 4px 0;
}
.faq-block.faq-theme-block em {
  font-style: italic;
}

.faq-diy-block {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1025px) {
  .faq-diy-block {
    margin-top: 50px;
  }
}
.faq-diy-block .faq-diy-title {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-transform: none;
  margin-bottom: 24px;
}
@media (min-width: 1025px) {
  .faq-diy-block .faq-diy-title {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.6px;
  }
}
.faq-diy-block .btn:first-of-type {
  margin-bottom: 16px;
}

@media (min-width: 1025px) {
  .navigation-option {
    display: none;
  }
}

.navigation-list-item {
  display: none;
}
@media (min-width: 1025px) {
  .navigation-list-item {
    display: block;
  }
}

.store-locator-search {
  text-align: center;
  padding: 40px 30px;
}
@media (min-width: 768px) {
  .store-locator-search {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .store-locator-search .store-light-background {
    background-color: #fcfaf7;
    padding: 61px 50px 56px;
  }
}
.store-locator-search .store-light-background .store-container {
  display: block;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
}
@media (min-width: 768px) {
  .store-locator-search .store-light-background .store-container {
    text-align: left;
  }
}
.store-locator-search .store-light-background .store-container .selectric-wrapper {
  margin-top: 3px;
}
.store-locator-search .store-header {
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  text-transform: none;
  color: #000000;
}
@media (min-width: 768px) {
  .store-locator-search .store-header {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin-bottom: 15px;
  }
}
.store-locator-search .store-locator-form {
  width: 100%;
  margin-top: 26px;
  display: inline-block;
  vertical-align: top;
}
@media (min-width: 768px) {
  .store-locator-search .store-locator-form {
    max-width: 600px;
    margin-top: 27px;
  }
}
.store-locator-search .store-locator-form .filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 26px;
  margin-left: 8px;
}
@media (min-width: 768px) {
  .store-locator-search .store-locator-form .filters {
    margin-top: 30px;
    margin-left: 4px;
  }
}
.store-locator-search .store-locator-form .filters .form-row {
  margin: 0;
}
.store-locator-search .store-locator-form .filters .form-row:not(:last-of-type) .label-container {
  margin-right: 40px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .store-locator-search .store-locator-form .filters .form-row:not(:last-of-type) .label-container {
    margin-bottom: 0;
  }
}
.store-locator-search .store-locator-form .filters .form-row label {
  width: 100%;
}
@media (min-width: 768px) {
  .store-locator-search .store-locator-form .filters .form-row label {
    line-height: 24px;
  }
}
.store-locator-search .store-locator-form .filters .form-row .label-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  width: auto;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #1a1919;
  line-height: 28px;
  text-align: left;
}
.store-locator-search .store-locator-form .filters .form-row .label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.store-locator-search .store-locator-form .filters .form-row .label-container input:checked ~ .checkmark {
  background-color: #886655;
}
.store-locator-search .store-locator-form .filters .form-row .label-container input:checked ~ .checkmark::after {
  display: block;
}
.store-locator-search .store-locator-form .filters .form-row .label-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
}
.store-locator-search .store-locator-form .filters .form-row .label-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  background-color: #ffffff;
  height: 6px;
  width: 6px;
}
.store-locator-search .store-locator-form .address-search-input {
  margin-bottom: 24px;
}
.store-locator-search .store-locator-form .address-search-input label {
  display: block;
}
@media (min-width: 768px) {
  .store-locator-search .store-locator-form .form-row .field-wrapper {
    margin-left: 0;
    max-width: 600px;
  }
}
.store-locator-search .icon-current-location-wrapper {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .store-locator-search .icon-current-location-wrapper {
    right: 21px;
    top: 50%;
  }
}
@media (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active) {
  .store-locator-search .icon-current-location-wrapper {
    top: 45%;
  }
}
.store-locator-search .icon-current-location-wrapper .icon-current-location {
  cursor: pointer;
  color: #886655;
  font-size: 17px;
}

.store-search-results {
  padding: 19px 30px 6px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
@media (min-width: 768px) {
  .store-search-results {
    padding: 20px 40px 25px;
  }
}
.store-search-results .store {
  display: block;
  margin: 0 auto;
  max-width: 570px;
  padding: 39px 0 31px;
  border-bottom: 1px solid #d8d8d8;
}
@media (min-width: 768px) {
  .store-search-results .store {
    padding: 28px 0 20px;
  }
}
.store-search-results .store h2 {
  text-transform: none;
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 11px;
}
@media (min-width: 768px) {
  .store-search-results .store h2 {
    line-height: 44px;
    margin-bottom: 1px;
    letter-spacing: 0.46px;
  }
}
.store-search-results .store .store-address {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #1a1919;
}
.store-search-results .store .store-address .phone-number {
  display: inline-block;
  margin-top: 22px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .store-search-results .store .store-address .phone-number {
    color: #1a1919;
    text-decoration: none;
    pointer-events: none;
  }
}
.store-search-results .store-info-link {
  display: inline-block;
  margin-top: 0;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.store-noresult-wrapper {
  padding: 0 30px;
}
.store-noresult-wrapper .store-noresult-container {
  display: block;
  margin: -15px auto 184px;
  max-width: 556px;
  text-align: left;
}

.show-more-wrapper {
  display: block;
  margin: 0 auto;
  max-width: 556px;
  padding: 35px 30px 40px;
}
@media (min-width: 768px) {
  .show-more-wrapper {
    padding: 35px 0;
  }
}
.show-more-wrapper .show-more-btn {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .show-more-wrapper .show-more-btn {
    margin: 0;
  }
}

.store-details {
  position: relative;
  padding: 61px 30px 0;
}
.store-details .inner-wrapper {
  padding: 0;
}
@media (min-width: 768px) {
  .store-details .inner-wrapper {
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .store-details .store-light-background {
    padding: 91px 33px 155px 30px;
    background-color: #fcfaf7;
  }
}
@media (min-width: 1024px) {
  .store-details .store-light-background {
    padding: 91px 33px 81px;
  }
}
.store-details .back-button {
  position: absolute;
  top: 10px;
  left: 17px;
}
@media (min-width: 768px) {
  .store-details .back-button {
    display: none;
  }
}

.store-details-address,
.store-location,
.store-hours-wrapper {
  width: 100%;
}

@media (min-width: 768px) {
  .store-location {
    width: 50%;
    margin-right: 32px;
    padding-left: 65px;
    max-width: none;
    order: 1;
  }
}
.store-location .store-map {
  position: relative;
  overflow: hidden;
}
.store-location .store-map.store-map--desktop {
  height: 175px;
  width: 100%;
}
@media (min-width: 768px) {
  .store-location .store-map.store-map--desktop {
    display: block;
    height: 227.5px;
  }
}

.store-hours-wrapper {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .store-hours-wrapper {
    order: 2;
    width: auto;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .store-hours-wrapper {
    width: 50%;
  }
}

.map-hours-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .map-hours-container {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .map-hours-container {
    flex-direction: row;
  }
}
.map-hours-container .info-mobile {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 25px;
  margin-top: 22px;
}
.map-hours-container .info-mobile a {
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 7px;
}
@media (min-width: 768px) {
  .map-hours-container .info-mobile {
    display: none;
  }
}

.store-hours ul {
  list-style: none;
  padding: 0;
}
.store-hours ul li {
  margin-bottom: 8px;
}
.store-hours h2 {
  font-size: 10px;
  letter-spacing: 2px;
  color: #626262;
  line-height: 12px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  margin-bottom: 18px;
}
.store-hours .day,
.store-hours .additional-day {
  text-transform: capitalize;
  display: inline-block;
  margin-right: 2px;
}
.store-hours .day,
.store-hours .additional-day,
.store-hours .time,
.store-hours .additional-time {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 18px;
  color: #453f3f;
}
.store-hours .store-hours-details {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 18px;
  padding-top: 5px;
}
@media (min-width: 768px) {
  .store-hours .store-hours-details {
    max-width: 300px;
  }
}
.store-hours .store-hours-details a {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 15px;
  text-transform: uppercase;
}

.store-details-address {
  margin-top: 33px;
  order: 3;
}
@media (min-width: 768px) {
  .store-details-address {
    margin-top: 20px;
  }
}
.store-details-address .paragraph {
  font-size: 16px;
  letter-spacing: 1px;
  color: #1a1919;
  line-height: 26px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-transform: none;
}

.store-info-wrapper h1 {
  font-size: 20px;
  letter-spacing: 5.33px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .store-info-wrapper h1 {
    line-height: 30px;
    margin: -3px 0 29px;
  }
}

.store-details-bottom-wrapper {
  display: block;
  margin: 31px auto 85px;
  max-width: 340px;
  text-align: center;
}
@media (min-width: 768px) {
  .store-details-bottom-wrapper {
    margin: 63px auto;
  }
}
.store-details-bottom-wrapper .wrapper__buttons {
  margin-top: 31px;
}
@media (min-width: 768px) {
  .store-details-bottom-wrapper .wrapper__buttons {
    margin-top: 31px;
  }
}
.store-details-bottom-wrapper h2 {
  font-size: 30px;
  letter-spacing: 2.5px;
  line-height: 36px;
  color: #000000;
  text-transform: none;
  margin-bottom: 7px;
}
@media (min-width: 768px) {
  .store-details-bottom-wrapper h2 {
    margin-bottom: 5px;
  }
}
.store-details-bottom-wrapper p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 3px;
}
@media (min-width: 768px) {
  .store-details-bottom-wrapper p {
    margin-bottom: 10px;
  }
}

.pt_customer-service .page-content {
  background-color: #fcfaf7;
  margin: 0 auto;
  padding-top: 39px;
}
@media (min-width: 768px) {
  .pt_customer-service .page-content {
    padding-top: 79px;
  }
}
.pt_customer-service .cs-content .contact-us-headline {
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-transform: none;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  padding-bottom: 15px;
  border-bottom: 0;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .pt_customer-service .cs-content .contact-us-headline {
    margin: 8px 0 16px;
  }
}
.pt_customer-service .cs-content .form-row {
  margin-bottom: 21px;
}
.pt_customer-service .cs-content .form-caption-hint {
  margin-bottom: 15px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #767676;
  line-height: 14px;
  margin-top: -4px;
}
@media (min-width: 768px) {
  .pt_customer-service .cs-content .form-caption-hint {
    margin-top: 0;
  }
}
.pt_customer-service .cs-content .selectric-wrapper {
  margin-top: 4px;
}
.pt_customer-service .cs-content .comment-wrapper {
  margin-top: 20px;
}
.pt_customer-service .cs-content .comment-wrapper .form-row {
  margin-bottom: 37px;
}
.pt_customer-service .cs-content .comment-wrapper .input-textarea {
  margin-top: 4px;
}
.pt_customer-service .cs-content .upload-input-label {
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 35px;
  display: block;
  color: #453f3f;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: none;
}
.pt_customer-service .cs-content .input-file-wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  margin-top: 8px;
}
.pt_customer-service .cs-content .input-file-wrapper .file-button-wrapper,
.pt_customer-service .cs-content .input-file-wrapper .file-name-wrapper {
  display: flex;
  flex-direction: column;
}
.pt_customer-service .cs-content .input-file-wrapper .file-name-wrapper {
  justify-content: center;
}
.pt_customer-service .cs-content .input-file-wrapper input[type=file] {
  display: none;
}
.pt_customer-service .cs-content .input-file-wrapper .custom-file-upload {
  z-index: 0;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 14px;
  min-width: 165px;
  line-height: 18px;
  letter-spacing: 2px;
  background-color: transparent;
  border: 1px solid #d8d8d8;
  color: #1a1919;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  width: auto;
  min-height: 36px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .pt_customer-service .cs-content .input-file-wrapper .custom-file-upload:hover, .pt_customer-service .cs-content .input-file-wrapper .custom-file-upload:active {
    background-color: #1a1919;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.pt_customer-service .cs-content .input-file-wrapper .contactus-filename,
.pt_customer-service .cs-content .input-file-wrapper .contactus-nofiles {
  margin-left: 21px;
  font-size: 14px;
  letter-spacing: 0.4px;
  display: inline-block;
  line-height: 30px;
  cursor: initial;
}
@media (min-width: 768px) {
  .pt_customer-service .cs-content .input-file-wrapper .contactus-filename,
  .pt_customer-service .cs-content .input-file-wrapper .contactus-nofiles {
    max-width: none;
  }
}
.pt_customer-service .cs-content .form-row.namerow {
  margin-bottom: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
  max-width: none;
}
@media (min-width: 768px) {
  .pt_customer-service .cs-content .form-row.namerow {
    margin-top: 10px;
  }
}
.pt_customer-service .cs-content .form-row.namerow input {
  border: 0;
}
.pt_customer-service .cs-content .form-row.namerow .label-visible {
  display: inline-block;
}
@media (min-width: 768px) {
  .pt_customer-service .cs-content .form-row.namerow .label-visible {
    cursor: pointer;
  }
}
.pt_customer-service .cs-content .form-row-button {
  margin-top: 39px;
}
.pt_customer-service .cs-content .form-row-button button {
  display: block;
  width: auto;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .pt_customer-service .cs-content .form-row-button button {
    float: right;
  }
}
.pt_customer-service .content-banner {
  min-height: 350px;
}
@media (min-width: 768px) {
  .pt_customer-service .content-banner {
    min-height: 450px;
    background-color: #f6f4f2;
  }
}
.pt_customer-service .content-banner .inner-wrapper {
  padding: 0;
}
@media (min-width: 768px) {
  .pt_customer-service .content-banner .inner-wrapper {
    padding: 0 20px;
  }
}
.pt_customer-service .content-banner .inner-indented {
  max-width: 650px;
}
.pt_customer-service .content-banner .text-holder {
  padding: 85px 20px 42px;
}
@media (min-width: 768px) {
  .pt_customer-service .content-banner .text-holder {
    padding: 107px 0 109px;
  }
}
.pt_customer-service .content-banner .text-holder .heading-h1 {
  letter-spacing: 1.5px;
  line-height: 46px;
  margin-bottom: 27px;
}
@media (min-width: 768px) {
  .pt_customer-service .content-banner .text-holder .heading-h1 {
    line-height: 60px;
    letter-spacing: 2px;
  }
}
.pt_customer-service .content-banner .text-holder p {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
}
.pt_customer-service .content-banner .text-holder p:first-of-type {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .pt_customer-service .content-banner .text-holder p {
    font-size: 24px;
    letter-spacing: 0.46px;
    line-height: 44px;
    margin-bottom: 0;
  }
  .pt_customer-service .content-banner .text-holder p:first-of-type {
    max-width: none;
  }
}
.pt_customer-service .secondary-navigation ul {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}
.pt_customer-service .contact-wrapper {
  max-width: 640px;
  display: block;
  margin: 0 auto;
  padding-bottom: 70px;
}
.pt_customer-service .contact-wrapper .contact-confirmation-message {
  text-align: center;
}
@media (min-width: 768px) {
  .pt_customer-service .contact-wrapper .contact-confirmation-message {
    padding-bottom: 38px;
  }
}
.pt_customer-service .contact-wrapper .contact-confirmation-message h1 {
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 35px;
  text-transform: none;
}
@media (min-width: 768px) {
  .pt_customer-service .contact-wrapper .contact-confirmation-message h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
.pt_customer-service .contact-wrapper .back-button {
  display: none;
}
.pt_customer-service .order-number .form-row {
  margin-bottom: 10px;
}
.pt_customer-service .contact-phone .form-row {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .pt_customer-service .contact-phone .form-row {
    margin-bottom: 8px;
  }
}
.pt_customer-service .confirmation-message {
  padding: 24px 30px 18px;
  margin-top: -10px;
  text-align: center;
  background-color: #fcfaf7;
}
@media (min-width: 768px) {
  .pt_customer-service .confirmation-message {
    padding: 0 30px 137px;
  }
}
.pt_customer-service .confirmation-message p:first-of-type {
  font-size: 20px;
  letter-spacing: 1.67px;
  line-height: 24px;
  margin-bottom: 11px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  max-width: 235px;
}
@media (min-width: 768px) {
  .pt_customer-service .confirmation-message p:first-of-type {
    font-size: 30px;
    letter-spacing: 0.9px;
    line-height: 45px;
    margin-bottom: 6px;
    max-width: none;
  }
}
.pt_customer-service .confirmation-message p:last-of-type {
  max-width: none;
}
.pt_customer-service .extensions-text {
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.pt_customer-service .returnform-wrapper {
  padding-bottom: 50px;
}
.pt_customer-service .returnform-wrapper .form-row {
  padding-top: 0;
}
.pt_customer-service .returnform-wrapper h1.h6 {
  margin-bottom: 30px;
  font-size: 25px;
  line-height: 38px;
  text-transform: none;
}
@media (min-width: 768px) {
  .pt_customer-service .returnform-wrapper h1.h6 {
    font-size: 40px;
    letter-spacing: 3.3px;
    line-height: 50px;
  }
}
.pt_customer-service .returnform-wrapper p {
  margin-bottom: 30px;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.pt_customer-service .returnform-wrapper h2.h6 {
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 2.4px;
  text-transform: none;
}
@media (min-width: 768px) {
  .pt_customer-service .returnform-wrapper h2.h6 {
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
  }
}
.pt_customer-service .returnform-wrapper .contactus-content.returnform .label-radio {
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .pt_customer-service .returnform-wrapper .contactus-content.returnform .label-radio {
    margin-bottom: 20px;
  }
}
.pt_customer-service .returnform-wrapper .contactus-content.returnform .headline-label {
  margin-bottom: 20px;
  padding-left: 0;
}
.pt_customer-service .returnform-wrapper .contactus-content.returnform .headline-label::before, .pt_customer-service .returnform-wrapper .contactus-content.returnform .headline-label::after {
  display: none;
}
.pt_customer-service .returnform-wrapper .contactus-content.returnform .radio-label {
  display: block;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .pt_customer-service .returnform-wrapper .contactus-content.returnform .return-form-fields {
    margin-top: 50px;
  }
}
.pt_customer-service .returnform-wrapper .contactus-content.returnform .returnform-button {
  margin: 65px 0;
}
.pt_customer-service .returnform-wrapper .contactus-content.returnform .content li {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-left: 15px;
}

.pt_splashpage {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 86px 20px 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .pt_splashpage {
    padding-top: 175px;
    padding-bottom: 0;
  }
}
.pt_splashpage .row {
  z-index: 2;
}
.pt_splashpage .fill-row {
  z-index: 1;
}
.pt_splashpage .select--custom.select-box {
  background-repeat: no-repeat;
}
.pt_splashpage .selectric-wrapper .selectric {
  padding: 5px 17px 2px;
  height: 37px;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  background-color: transparent;
}
.pt_splashpage .selectric-wrapper .selectric .button {
  right: -4px;
  top: 7px;
}
.pt_splashpage .selectric-wrapper .selectric .label {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  letter-spacing: 1px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.pt_splashpage .selectric-wrapper .selectric-hide-select {
  height: 14px;
  position: absolute;
  left: 0;
  top: 12px;
  width: 16px;
}
.pt_splashpage .selectric-wrapper .selectric-hide-select .select-box {
  background-position: left top;
  background-size: 16px auto;
  border-bottom: 0;
  float: left;
  font-size: 0;
  height: 16px;
  width: 16px;
  padding: 0;
}

.splashpage-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.splash-rituals-logo {
  font-size: 0;
  overflow: hidden;
  margin: auto;
  width: 60px;
  height: 56px;
  margin-bottom: 33px;
}
.splash-rituals-logo img {
  width: 100%;
}

.splash-box {
  padding: 40px 30px 60px;
  margin: auto;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3);
  background: #f6f4f2;
  max-width: 420px;
}
@media screen and (min-width: 321px) {
  .splash-box {
    padding: 40px 40px 60px;
  }
}
@media (min-width: 768px) {
  .splash-box {
    padding: 40px 60px 60px;
  }
}
.splash-box .content-asset {
  text-align: center;
}
.splash-box .content-asset h2.h6 {
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 27px;
}
@media (min-width: 768px) {
  .splash-box .content-asset h2.h6 {
    margin-bottom: 37px;
  }
}
.splash-box .wrapper__country-selector .country-selector {
  background-color: transparent;
  position: relative;
}
.splash-box .wrapper__country-selector .country-selector:first-of-type {
  margin-bottom: 17px;
}
.splash-box .wrapper__country-selector .country-selector em {
  position: absolute;
  right: -2px;
  top: 10px;
}
@media screen and (min-width: 1025px) {
  .splash-box .wrapper__country-selector .country-selector em {
    display: none;
  }
}
.splash-box .wrapper__country-selector .country-selector em i {
  font-size: 26px;
  color: #1a1919;
}
.splash-box .wrapper__country-selector .country-selector em i:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea19";
}
.splash-box .wrapper__country-selector label {
  font-size: 9px;
  letter-spacing: 1.8px;
  color: #453f3f;
  text-transform: uppercase;
  margin-bottom: -6px;
}
.splash-box .wrapper__country-selector .custom-select {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  background-position: 0% 50%;
  padding: 5px 35px 5px 24px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  background-size: 15px 16px;
  border-radius: 0;
}
.splash-box .btn--text {
  width: auto;
  margin: 39px auto 0;
  display: block;
}

.splashpage-locales {
  text-align: center;
  margin: 14px 0 20px;
}
@media (min-width: 768px) {
  .splashpage-locales {
    margin: 147px 20px 20px;
  }
}
.splashpage-locales .white-bg {
  background-color: transparent;
  padding: 5px 0;
  max-width: 280px;
  display: block;
  margin: 0 auto;
}
@media screen and (min-width: 375px) {
  .splashpage-locales .white-bg {
    max-width: 340px;
  }
}
@media (min-width: 768px) {
  .splashpage-locales .white-bg {
    max-width: 370px;
  }
}
.splashpage-locales .locale-link {
  display: inline-block;
  color: #9a7b69;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  vertical-align: middle;
}
.splashpage-locales .locale-link::before, .splashpage-locales .locale-link::after {
  content: "";
}
.splashpage-locales .locale-link::before {
  margin-left: 1px;
}
@media screen and (min-width: 375px) {
  .splashpage-locales .locale-link::before {
    margin-left: 4px;
  }
}
@media (min-width: 768px) {
  .splashpage-locales .locale-link::before {
    margin-left: 6px;
  }
}
.splashpage-locales .locale-link:not(:last-child)::after {
  border-right: 1px solid #d8d8d8;
  height: 100%;
  margin-left: 3px;
}
@media screen and (min-width: 375px) {
  .splashpage-locales .locale-link:not(:last-child)::after {
    margin-left: 8px;
  }
}
@media (min-width: 768px) {
  .splashpage-locales .locale-link:not(:last-child)::after {
    margin-left: 7px;
  }
}

.pt_error .main {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.pt_error .error-wrapper {
  display: block;
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .pt_error .error-wrapper {
    padding: 107px 0 106px;
    max-width: 600px;
  }
}
.pt_error .error-wrapper h1, .pt_error .error-wrapper h2 {
  color: #453f3f;
  text-transform: none;
}
.pt_error .error-wrapper h1 {
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 46px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .pt_error .error-wrapper h1 {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}
.pt_error .error-wrapper h2 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
  max-width: 245px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .pt_error .error-wrapper h2 {
    font-size: 30px;
    letter-spacing: 1.1px;
    line-height: 45px;
    max-width: none;
  }
}
.pt_error .error-wrapper .error-page-message p {
  max-width: 535px;
  display: block;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.pt_error .error-wrapper .error-page-message p a {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.pt_error .primary-content {
  width: 100%;
  background-color: #f6f4f2;
}
.pt_error .error-page-bottom .no-hits-search {
  padding: 73px 0 65px;
}
@media (min-width: 768px) {
  .pt_error .error-page-bottom .no-hits-search {
    padding-top: 76px;
  }
}
.pt_error .error-page-bottom .error-page-footer {
  margin-top: 69px;
  padding: 0 50px;
}
@media (min-width: 768px) {
  .pt_error .error-page-bottom .error-page-footer {
    margin-top: 35px;
    padding: 0;
  }
}
.pt_error .error-page-bottom .error-page-footer-wrapper {
  max-width: 495px;
  display: block;
  margin: 0 auto;
  text-align: center;
}
.pt_error .error-page-bottom .error-page-footer-wrapper p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.pt_error .error-page-bottom .error-page-footer-wrapper p:first-of-type {
  margin-bottom: 28px;
}
.pt_error .error-page-bottom .error-page-search h3 {
  display: block;
  font-size: 24px;
  letter-spacing: 0.46px;
  line-height: 44px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-bottom: 30px;
  text-transform: none;
}
.pt_error .error-page-bottom .error-page-search .btn {
  margin: 0 0 0 20px;
  width: auto;
  flex-shrink: 0;
}
.pt_error .error-page-search input {
  width: 100%;
  margin-right: 20px;
}
@media (min-width: 768px) {
  .pt_error .error-page-search input {
    width: 50%;
  }
}

.maintenance-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f6f4f2;
  padding: 0 20px;
}
.maintenance-page .wrapper-maintenance {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%, 0);
  text-align: center;
}
.maintenance-page .splash-rituals-logo {
  width: 48px;
  margin-bottom: 30px;
}
.maintenance-page .maintenance-text {
  max-width: 600px;
  min-width: 300px;
}
.maintenance-page p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #453f3f;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
@media (min-width: 768px) {
  .maintenance-page .splash-rituals-logo {
    width: 60px;
    margin-bottom: 70px;
  }
  .maintenance-page .wrapper-maintenance {
    top: 120px;
  }
}

.browser-error {
  margin-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  color: #d6122d;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .browser-error {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%);
    max-width: 600px;
  }
}

.pt_outlet .main {
  margin-bottom: 30px;
}
.pt_outlet .search-filter-box .breadcrumb {
  margin-bottom: 17px;
}
.pt_outlet .search-filter-box .filter-box-categories {
  margin-bottom: 25px;
}
.pt_outlet .search-filter-box .filter-box-categories sup {
  left: 5px;
  top: -15px;
}
.pt_outlet .item.no-results-item {
  margin: 80px 0 0;
  width: 100%;
}
.pt_outlet .no-results {
  padding: 30px;
  text-transform: none;
}
.pt_outlet .search-grid-wrapper .filter-trigger-btn.has-filters::before {
  background-color: #9a7b69;
  bottom: -3px;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  right: 21px;
}
.pt_outlet .refinements .filter-inner {
  padding-top: 61px;
}
@media (min-width: 768px) {
  .pt_outlet .item.no-results-item {
    margin: 90px 0 60px 60px;
    width: calc(66.666% - 68px);
  }
  .pt_outlet .refinements .filter-inner {
    padding-top: 90px;
  }
}

@media (min-width: 768px) {
  .outlet-hero-banner .content-banner {
    margin-bottom: -110px;
  }
}
.outlet-hero-banner .content-banner.banner-small {
  min-height: 180px;
}
.outlet-hero-banner .content-banner .image-holder::after {
  background: linear-gradient(to bottom, rgba(35, 31, 32, 0), rgba(35, 31, 32, 0.5));
  bottom: 0;
  content: "";
  display: inline-block;
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
}
.outlet-hero-banner .content-banner .text-holder {
  padding: 30px 0;
}
.outlet-hero-banner .content-banner .heading-h1 {
  line-height: 40px;
  margin-bottom: 3px;
}
.outlet-hero-banner .content-banner .heading-intro {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
}
.outlet-hero-banner .content-banner .outlet-instructions-link {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .outlet-hero-banner .heading-h1 {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (min-width: 768px) {
  .outlet-hero-banner .content-banner.banner-small {
    min-height: 505px;
  }
  .outlet-hero-banner .content-banner .image-holder::after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(26, 25, 25));
    opacity: 0.5;
  }
  .outlet-hero-banner .content-banner .text-holder {
    padding: 60px 0 70px;
  }
  .outlet-hero-banner .content-banner .heading-h1 {
    color: #ffffff;
    margin-bottom: 15px;
  }
  .outlet-hero-banner .content-banner .heading-intro {
    color: #ffffff;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 30px;
  }
}

.giftfinder-hero {
  bottom: 0;
  left: 0;
  height: calc(100vh + 60px);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}
.giftfinder-hero.blurred .image-holder,
.giftfinder-hero.blurred .responsive-bg {
  filter: blur(12px);
}
.giftfinder-hero.blurred .icon {
  opacity: 0;
}
.giftfinder-hero .image-holder,
.giftfinder-hero .responsive-bg {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 60px;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1.07);
  transition: filter 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 100%;
}
.giftfinder-hero .icon {
  color: #ffffff;
  display: inline-block;
  font-size: 15px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 57.2%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
}
@media (max-width: 767px) {
  .giftfinder-hero .image-holder,
  .giftfinder-hero .responsive-bg {
    filter: blur(12px);
  }
}
@media (min-width: 768px) {
  .giftfinder-hero {
    height: calc(100vh + 30px);
  }
  .giftfinder-hero .image-holder,
  .giftfinder-hero .responsive-bg {
    bottom: 30px;
    transform: scale(1.02);
  }
  .giftfinder-hero .icon {
    display: none;
  }
}

.giftfinder-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  opacity: 0;
  position: relative;
  transition: opacity 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  visibility: hidden;
}
.giftfinder-container.visible {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 768px) {
  .giftfinder-container {
    min-height: calc(100vh - 95px);
  }
}

.giftfinder-top-block {
  width: 100%;
}
.giftfinder-top-block .progress-bar {
  text-align: center;
  width: 100%;
}
.giftfinder-top-block .step {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  color: #1a1919;
  cursor: default;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 1.07px;
  line-height: 40px;
  height: 40px;
  margin: 0 3px;
  outline: none;
  text-decoration: none;
  width: 40px;
}
.giftfinder-top-block .completed,
.giftfinder-top-block .selected {
  background-color: #ffffff;
  transition: background 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.giftfinder-top-block .clickable-step {
  cursor: pointer;
}
.giftfinder-top-block .clickable-step:hover, .giftfinder-top-block .clickable-step:focus {
  background-color: rgba(255, 255, 255, 0.7);
}

.giftfinder-step-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}
.giftfinder-step-wrapper .inner-wrapper {
  align-items: center;
  display: flex;
}
@media (min-width: 768px) {
  .giftfinder-step-wrapper .inner-wrapper {
    padding-top: 35px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .giftfinder-step-wrapper .inner-wrapper {
    padding-bottom: 200px;
  }
}
@media (min-width: 1024px) {
  .giftfinder-step-wrapper .inner-wrapper {
    padding-bottom: 200px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .giftfinder-step-wrapper .inner-wrapper {
    align-items: flex-start;
  }
}

.giftfinder-step-inner-box {
  margin: 0 auto;
  max-width: 280px;
  padding: 30px 0;
  text-align: center;
  width: 100%;
}
.giftfinder-step-inner-box .section-title {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 5.3px;
  line-height: 30px;
  margin-bottom: 60px;
}
.giftfinder-step-inner-box .name-input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 20px;
  letter-spacing: 1.25px;
  line-height: 28px;
  margin-bottom: 5px;
  outline: none;
  text-align: center;
}
.giftfinder-step-inner-box .input-help-text {
  color: #ffffff;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.giftfinder-step-inner-box .btn-container {
  padding-top: 70px;
  width: 100%;
}
@media (max-width: 767px) {
  .giftfinder-step-inner-box .input-help-text {
    opacity: 1;
    transition: opacity 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  }
  .giftfinder-step-inner-box .input-help-text.hidden {
    opacity: 0;
  }
}
@media (min-width: 768px) {
  .giftfinder-step-inner-box {
    background-color: #f6f4f2;
    border-radius: 4px;
    max-width: 496px;
    padding: 60px 60px 80px;
  }
  .giftfinder-step-inner-box .section-title {
    color: #000000;
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
    margin: 0 auto 33px;
    max-width: 280px;
    text-transform: none;
  }
  .giftfinder-step-inner-box .name-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    color: #000000;
    font-size: 26px;
    line-height: 30px;
    margin: 0 auto 12px;
  }
  .giftfinder-step-inner-box .input-help-text {
    color: #453f3f;
  }
  .giftfinder-step-inner-box .btn-container {
    padding-top: 47px;
  }
}

.giftfinder-gender-page .gender-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
  width: 100%;
}
.giftfinder-gender-page .gender-option {
  background-color: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  outline: none;
  padding: 9px 5px 12px;
  transition: box-shadow 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 47%;
  min-height: 1px;
}
.giftfinder-gender-page .gender-option:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.giftfinder-gender-page .gender-option .category-image {
  flex-shrink: 0;
  margin: 0 auto;
  max-width: 100%;
}
.giftfinder-gender-page .gender-option .category-image img {
  display: block;
  width: 100%;
}
.giftfinder-gender-page .gender-option .category-description {
  margin-top: auto;
  width: 100%;
}
.giftfinder-gender-page .gender-option .category-name {
  color: #1a1919;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 1;
  text-transform: uppercase;
}
.giftfinder-gender-page .gender-option .product-count {
  color: #626262;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
}
@media (max-width: 767px) {
  .giftfinder-gender-page.giftfinder-top-block {
    padding: 41px 0 0;
  }
  .giftfinder-gender-page .giftfinder-step-inner-box {
    max-width: none;
    padding: 50px 0 70px;
  }
}
@media (min-width: 768px) {
  .giftfinder-gender-page.giftfinder-top-block {
    padding: 36px 0 0;
  }
  .giftfinder-gender-page .giftfinder-step-inner-box {
    max-width: 496px;
    padding: 70px 80px 80px;
  }
  .giftfinder-gender-page .giftfinder-step-inner-box .section-title {
    margin: 0 auto 26px;
  }
  .giftfinder-gender-page .gender-container {
    padding-top: 0;
  }
  .giftfinder-gender-page .gender-option {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    padding: 9px 5px 8px;
  }
  .giftfinder-gender-page .gender-option:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
  .giftfinder-gender-page .gender-option .category-name {
    letter-spacing: 4px;
  }
}

.giftfinder-category-page.giftfinder-step-wrapper .inner-wrapper {
  align-items: flex-start;
}
.giftfinder-category-page .giftfinder-pick-collection {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  display: flex;
  margin-bottom: 32px;
  outline: none;
  position: relative;
  transition: box-shadow 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 100%;
}
.giftfinder-category-page .giftfinder-pick-collection:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.giftfinder-category-page .giftfinder-pick-collection .image-holder {
  margin-bottom: 40px;
  width: 100%;
}
.giftfinder-category-page .giftfinder-pick-collection .image-holder img {
  width: 100%;
}
.giftfinder-category-page .giftfinder-pick-collection .image-holder .desktop-img {
  display: none;
}
.giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner {
  bottom: 8px;
  left: 0;
  padding: 0 30px;
  position: absolute;
  width: 100%;
}
.giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  display: inline-block;
  font-size: 36px;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-54%);
}
.giftfinder-category-page .giftfinder-pick-collection h3 {
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 16px;
  margin: 0 0 2px;
}
.giftfinder-category-page .giftfinder-pick-collection .product-count {
  color: #626262;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1;
}
.giftfinder-category-page .giftfinder-pick-collection .btn {
  display: none;
}
.giftfinder-category-page .secondary-title {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  margin-bottom: 22px;
  text-transform: uppercase;
}
.giftfinder-category-page .giftfinder-pick-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.giftfinder-category-page .giftfinder-category-option {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  outline: none;
  padding: 15px 5px 18px;
  transition: box-shadow 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 47.5%;
}
.giftfinder-category-page .giftfinder-category-option:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.giftfinder-category-page .giftfinder-category-option .category-image {
  flex-shrink: 0;
  width: 100%;
}
.giftfinder-category-page .giftfinder-category-option .category-image img {
  max-width: 100%;
}
.giftfinder-category-page .giftfinder-category-option .category-description {
  width: 100%;
}
.giftfinder-category-page .giftfinder-category-option .category-name {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 2.3px;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.giftfinder-category-page .giftfinder-category-option .product-count {
  color: #626262;
  display: block;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 14px;
}
@media (max-width: 767px) {
  .giftfinder-category-page.giftfinder-top-block {
    padding: 40px 0 20px;
  }
  .giftfinder-category-page.giftfinder-step-wrapper {
    padding-bottom: 55px;
  }
  .giftfinder-category-page .giftfinder-step-inner-box {
    max-width: none;
    padding: 12px 0 0;
  }
  .giftfinder-category-page .giftfinder-step-inner-box .section-title {
    font-size: 16px;
    letter-spacing: 2.63px;
    line-height: 24px;
    margin-bottom: 27px;
  }
}
@media (min-width: 768px) {
  .giftfinder-category-page.giftfinder-top-block {
    padding: 37px 0 40px;
  }
  .giftfinder-category-page.giftfinder-step-wrapper .inner-wrapper {
    padding-bottom: 100px;
    padding-top: 0;
  }
  .giftfinder-category-page .giftfinder-step-inner-box {
    max-width: 680px;
    padding: 62px 70px 80px;
  }
  .giftfinder-category-page .giftfinder-step-inner-box .section-title {
    margin: 0 auto 43px;
    max-width: none;
    text-transform: none;
  }
  .giftfinder-category-page .giftfinder-pick-collection {
    cursor: pointer;
    margin-bottom: 35px;
  }
  .giftfinder-category-page .giftfinder-pick-collection:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .giftfinder-category-page .giftfinder-pick-collection .image-holder .desktop-img {
    display: block;
  }
  .giftfinder-category-page .giftfinder-pick-collection .image-holder .mobile-img {
    display: none;
  }
  .giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner {
    bottom: 20px;
    left: auto;
    padding: 0 20px 0 0;
    right: 0;
    width: auto;
  }
  .giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner::after,
  .giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner .product-count {
    display: none;
  }
  .giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner h3 {
    font-size: 11px;
    letter-spacing: 1.65px;
    margin: 0 0 19px;
  }
  .giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner .btn {
    display: inline-block;
    min-width: 10px;
    padding: 13px 22px 11px;
  }
  .giftfinder-category-page .secondary-title {
    color: #1a1919;
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    font-size: 20px;
    letter-spacing: 1.67px;
    line-height: 24px;
    margin-bottom: 25px;
    text-transform: none;
  }
  .giftfinder-category-page .giftfinder-category-option {
    cursor: pointer;
    margin-bottom: 30px;
    padding: 15px 30px 19px;
    width: 30.3%;
  }
  .giftfinder-category-page .giftfinder-category-option:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .giftfinder-category-page .giftfinder-category-option .category-name {
    font-size: 11px;
    letter-spacing: 1.65px;
    margin-bottom: 3px;
  }
}
@media (min-width: 1024px) {
  .giftfinder-category-page .giftfinder-step-inner-box {
    max-width: 960px;
    padding: 62px 150px 80px;
  }
  .giftfinder-category-page .giftfinder-pick-collection .giftfinder-collection-inner {
    bottom: 50px;
    padding: 0 50px 0 0;
  }
}

.giftfinder-multiple-page.giftfinder-step-wrapper .inner-wrapper {
  align-items: flex-start;
}
.giftfinder-multiple-page .giftfinder-step-inner-box {
  max-width: none;
  padding: 66px 0;
}
.giftfinder-multiple-page .giftfinder-step-inner-box .section-title {
  color: #1a1919;
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 24px;
  margin-bottom: 35px;
}
.giftfinder-multiple-page .giftfinder-step-inner-box .section-sub-title {
  display: none;
}
.giftfinder-multiple-page .multiple-choice {
  margin-left: -20px;
  overflow: hidden;
  padding: 0 20px;
  width: calc(100% + 40px);
}
.giftfinder-multiple-page .multiple-choice .slick-list {
  overflow: visible;
}
.giftfinder-multiple-page .multiple-choice .slick-track {
  align-items: stretch;
  display: flex;
}
.giftfinder-multiple-page .multiple-choice .slick-slide {
  display: flex;
  height: auto;
  padding: 5px;
}
.giftfinder-multiple-page .multiple-choice .slick-slide > div {
  display: flex;
  width: 100%;
}
.giftfinder-multiple-page .multiple-option {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
  transition: box-shadow 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  position: relative;
}
.giftfinder-multiple-page .multiple-option:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.giftfinder-multiple-page .multiple-option .select-box {
  border: 1px solid #d6d6d6;
  border-radius: 50%;
  display: block;
  height: 40px;
  padding-left: 4px;
  position: absolute;
  right: 10px;
  text-align: center;
  transition: 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  top: 11px;
  width: 40px;
}
.giftfinder-multiple-page .multiple-option .select-box .checkmark {
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  line-height: 38px;
}
.giftfinder-multiple-page .multiple-option .select-box .checkmark::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
}
.giftfinder-multiple-page .multiple-option.selected .select-box {
  background-color: #886655;
  border-color: #886655;
}
.giftfinder-multiple-page .multiple-option.selected .select-box .checkmark::before {
  content: "\ea2f";
}
.giftfinder-multiple-page .multiple-option .category-image {
  flex-shrink: 0;
  width: 100%;
}
.giftfinder-multiple-page .multiple-option .category-image img {
  width: 100%;
}
.giftfinder-multiple-page .multiple-option .category-description {
  padding: 0 30px 4px;
  width: 100%;
}
.giftfinder-multiple-page .multiple-option .category-description .category-name {
  color: #886655;
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 23px;
  margin-bottom: 4px;
}
.giftfinder-multiple-page .multiple-option .category-description .category-subtitle {
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 1px;
}
.giftfinder-multiple-page .multiple-option .category-description .product-count {
  color: #626262;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 14px;
}
.giftfinder-multiple-page .btn-container {
  padding-top: 28px;
}
@media (max-width: 767px) {
  .giftfinder-multiple-page.giftfinder-top-block,
  .giftfinder-multiple-page .btn-container .btn-prev {
    display: none;
  }
  .giftfinder-multiple-page.giftfinder-step-wrapper {
    background-color: #f6f4f2;
  }
}
@media (min-width: 768px) {
  .giftfinder-multiple-page.giftfinder-top-block {
    padding: 37px 0 40px;
  }
  .giftfinder-multiple-page.giftfinder-step-wrapper .inner-wrapper {
    padding-top: 0;
  }
  .giftfinder-multiple-page .giftfinder-step-inner-box {
    max-width: 680px;
    padding: 62px 70px 80px;
  }
  .giftfinder-multiple-page .giftfinder-step-inner-box .section-title {
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
    margin: 0 auto 3px;
    max-width: none;
    text-transform: none;
  }
  .giftfinder-multiple-page .giftfinder-step-inner-box .section-sub-title {
    color: #453f3f;
    display: block;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin-bottom: 24px;
  }
  .giftfinder-multiple-page .multiple-choice {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 0;
    padding: 0;
    width: 100%;
  }
  .giftfinder-multiple-page .category-container {
    margin-top: 24px;
  }
  .giftfinder-multiple-page .multiple-option {
    cursor: pointer;
    margin-bottom: 20px;
    width: 48.5%;
  }
  .giftfinder-multiple-page .multiple-option:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .giftfinder-multiple-page .multiple-option .category-description {
    padding: 0 20px 9px;
  }
  .giftfinder-multiple-page .multiple-option .category-description .category-name {
    margin-bottom: 10px;
  }
  .giftfinder-multiple-page .multiple-option .category-description .category-subtitle {
    margin-bottom: 5px;
  }
  .giftfinder-multiple-page .multiple-option .category-description .product-count {
    display: block;
  }
  .giftfinder-multiple-page .btn-container {
    padding-top: 20px;
  }
  .giftfinder-multiple-page .btn-container .btn {
    margin: 0 8px;
  }
}
@media (min-width: 1024px) {
  .giftfinder-multiple-page .giftfinder-step-inner-box {
    max-width: 960px;
    padding: 62px 150px 80px;
  }
}

.giftfinder-ritual-category-page.giftfinder-step-wrapper .inner-wrapper {
  align-items: flex-start;
}
.giftfinder-ritual-category-page .ritual-category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.giftfinder-ritual-category-page .ritual-category-option {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px 10px 18px;
  outline: none;
  transition: box-shadow 0.4s cubic-bezier(0.05, 0.5, 0.3, 1);
  width: 47.5%;
}
.giftfinder-ritual-category-page .ritual-category-option:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.giftfinder-ritual-category-page .ritual-category-option .category-image {
  width: 100%;
}
.giftfinder-ritual-category-page .ritual-category-option .category-image img {
  width: 100%;
}
.giftfinder-ritual-category-page .ritual-category-option .category-name {
  color: #1a1919;
  font-size: 14px;
  letter-spacing: 2.3px;
  line-height: 16px;
  margin-bottom: 4px;
}
.giftfinder-ritual-category-page .ritual-category-option .product-count {
  color: #626262;
  display: block;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 14px;
}
.giftfinder-ritual-category-page .btn-container {
  padding-top: 3px;
}
.giftfinder-ritual-category-page .btn-container .btn--text {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .giftfinder-ritual-category-page.giftfinder-top-block {
    display: none;
  }
  .giftfinder-ritual-category-page.giftfinder-step-wrapper {
    background-color: #f6f4f2;
  }
  .giftfinder-ritual-category-page .giftfinder-step-inner-box {
    max-width: none;
    padding: 66px 0;
  }
  .giftfinder-ritual-category-page .giftfinder-step-inner-box .section-title {
    color: #1a1919;
    font-size: 16px;
    letter-spacing: 2.63px;
    line-height: 24px;
    margin-bottom: 22px;
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .giftfinder-ritual-category-page.giftfinder-top-block {
    padding: 36px 0 40px;
  }
  .giftfinder-ritual-category-page.giftfinder-step-wrapper .inner-wrapper {
    padding-bottom: 100px;
    padding-top: 0;
  }
  .giftfinder-ritual-category-page .giftfinder-step-inner-box {
    max-width: 680px;
    padding: 62px 70px 80px;
  }
  .giftfinder-ritual-category-page .giftfinder-step-inner-box .section-title {
    margin: 0 auto 43px;
    max-width: none;
    text-transform: none;
  }
  .giftfinder-ritual-category-page .ritual-category-option {
    cursor: pointer;
    margin-bottom: 20px;
    padding: 20px 20px 27px;
    width: 48.5%;
  }
  .giftfinder-ritual-category-page .ritual-category-option:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .giftfinder-ritual-category-page .ritual-category-option .category-name {
    font-size: 16px;
    letter-spacing: 2.63px;
    line-height: 16px;
    margin-bottom: 11px;
  }
  .giftfinder-ritual-category-page .btn-container {
    padding-top: 16px;
  }
}
@media (min-width: 1024px) {
  .giftfinder-ritual-category-page .giftfinder-step-inner-box {
    max-width: 960px;
    padding: 62px 130px 80px;
  }
}

.giftfinder-price-page.giftfinder-top-block {
  padding: 41px 0 0;
}
.giftfinder-price-page .giftfinder-step-inner-box {
  max-width: none;
}
.giftfinder-price-page .giftfinder-step-inner-box .section-title {
  font-size: 16px;
  letter-spacing: 2.63px;
  line-height: 24px;
  margin-bottom: 35px;
  padding: 0 30px;
  text-transform: none;
}
.giftfinder-price-page .price-box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 75px 50px 35px;
  width: 100%;
}
.giftfinder-price-page .price-range-title {
  display: none;
  font-size: 12px;
  letter-spacing: 2.47px;
  line-height: 15px;
  margin-bottom: 64px;
}
.giftfinder-price-page .price-holder {
  align-items: flex-end;
  display: flex;
  width: 100%;
}
.giftfinder-price-page .price-holder input {
  font-size: 20px;
  padding: 3px;
  width: 50px;
}
.giftfinder-price-page .price-holder input::placeholder {
  color: #999999;
}
.giftfinder-price-page .price-holder .price-divider {
  margin: 0 auto;
}
.giftfinder-price-page .price-holder label,
.giftfinder-price-page .price-holder .price-divider {
  color: #1a1919;
  font-size: 20px;
  letter-spacing: 0.36px;
  line-height: 31px;
  padding: 3px 0;
}
.giftfinder-price-page .price-range-explanation {
  color: #453f3f;
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
}
.giftfinder-price-page .btn-container {
  padding-top: 62px;
}
@media (max-width: 767px) {
  .giftfinder-price-page .price-holder {
    margin: 0 auto 33px;
    max-width: 235px;
  }
  .giftfinder-price-page .btn-container .btn-prev {
    display: none;
  }
  .giftfinder-price-page .btn-container .btn {
    min-width: 10px;
    padding: 13px 30px 11px;
  }
}
@media (min-width: 768px) {
  .giftfinder-price-page.giftfinder-top-block {
    padding: 37px 0 0;
  }
  .giftfinder-price-page .giftfinder-step-inner-box {
    max-width: 680px;
    padding: 62px 70px 80px;
  }
  .giftfinder-price-page .giftfinder-step-inner-box .section-title {
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
    margin-bottom: 46px;
    max-width: none;
    padding: 0;
  }
  .giftfinder-price-page .price-box {
    margin: 0 auto;
    max-width: 450px;
    padding: 46px 108px 108px;
  }
  .giftfinder-price-page .price-range-title {
    display: block;
  }
  .giftfinder-price-page .price-range-explanation {
    display: none;
  }
  .giftfinder-price-page .btn-container {
    padding-top: 51px;
  }
  .giftfinder-price-page .btn-container .btn {
    margin: 0 8px;
  }
}
@media (min-width: 1024px) {
  .giftfinder-price-page .giftfinder-step-inner-box {
    max-width: 960px;
    padding: 62px 120px 90px;
  }
}

.giftfinder-results {
  background-color: #f6f4f2;
  position: relative;
  width: 100%;
}
.giftfinder-results .giftfinder-start-over {
  display: none;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 1;
}
.giftfinder-results .giftfinder-start-over .btn {
  float: right;
}
.giftfinder-results .fop-banner {
  overflow: hidden;
}
.giftfinder-results .no-results p {
  padding: 20px;
}
@media (max-width: 767px) {
  .giftfinder-results .fop-banner .image-holder,
  .giftfinder-results .fop-banner .responsive-bg {
    bottom: -10px;
    filter: blur(12px);
    left: -10px;
    right: -10px;
    top: -10px;
  }
  .giftfinder-results .fop-banner .text-holder {
    padding: 30px 0;
  }
  .giftfinder-results .fop-banner .heading-h1 {
    font-size: 24px;
    letter-spacing: 1.5px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .giftfinder-results .fop-banner .heading-intro {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;
  }
  .giftfinder-results .fop-banner.content-banner.banner-small {
    min-height: 180px;
  }
  .giftfinder-results .search-result-content {
    margin-bottom: 85px;
  }
}
@media (min-width: 768px) {
  .giftfinder-results .giftfinder-start-over {
    display: block;
  }
  .giftfinder-results .giftfinder-start-over .inner-wrapper {
    max-width: 1360px;
  }
  .giftfinder-results .fop-banner .image-holder::before,
  .giftfinder-results .fop-banner .responsive-bg::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(26, 25, 25));
    bottom: 0;
    content: "";
    display: inline-block;
    left: 0;
    opacity: 0.4;
    position: absolute;
    right: 0;
    top: 0;
  }
  .giftfinder-results .fop-banner .text-holder {
    padding: 80px 0 110px;
  }
  .giftfinder-results .fop-banner .heading-intro {
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
  }
  .giftfinder-results .fop-banner.content-banner.banner-small {
    min-height: 387px;
  }
  .giftfinder-results .search-filter-box .breadcrumb {
    margin-bottom: 17px;
  }
  .giftfinder-results .search-filter-box .filter-box-categories {
    margin-bottom: 25px;
  }
  .giftfinder-results .search-filter-box .filter-box-categories sup {
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    letter-spacing: 0;
    margin-left: 4px;
    top: -11px;
  }
  .giftfinder-results .active-refinements-list {
    margin-bottom: 19px;
  }
}

.giftfinder-refinements {
  background-color: #ffffff;
  bottom: 0;
  display: none;
  left: -100%;
  position: fixed;
  top: 0;
  transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  z-index: 991;
}
.giftfinder-refinements .giftfinder-refinements {
  transition: none;
}
.giftfinder-refinements .filter-inner {
  height: 100%;
  overflow: auto;
  padding: 61px 0 48px;
  width: 100%;
}
.giftfinder-refinements.refinements-visible {
  left: 0;
}
.giftfinder-refinements.refinements-visible .giftfinder-filter-header,
.giftfinder-refinements.refinements-visible .giftfinder-filter-user-actions {
  left: 0;
}
.giftfinder-refinements.refinements-visible .giftfinder-filter-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .giftfinder-refinements.refinements-visible.moved-right {
    left: 100%;
  }
  .giftfinder-refinements.refinements-visible.moved-right .giftfinder-filter-header,
  .giftfinder-refinements.refinements-visible.moved-right .giftfinder-filter-user-actions {
    left: 100%;
  }
  .giftfinder-refinements.refinements-visible .giftfinder-filter-holder .filter-options.visible {
    left: 0;
  }
}
@media (min-width: 768px) {
  .giftfinder-refinements {
    width: 360px;
  }
  .giftfinder-refinements .filter-inner {
    padding-top: 100px;
  }
}

.giftfinder-filter-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  left: 360px;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ease-in-out;
  visibility: hidden;
}
@media (min-width: 768px) {
  .giftfinder-filter-overlay {
    display: block;
  }
}

.giftfinder-filter-header {
  background-color: #f6f4f2;
  left: -100%;
  position: fixed;
  text-align: center;
  top: 0;
  transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  z-index: 20;
}
.giftfinder-filter-header .filter-x-close {
  font-size: 22px;
  position: absolute;
  right: 16px;
  top: 19px;
}
.giftfinder-filter-header h3 {
  color: #1a1919;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 21px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-transform: none;
}
@media (min-width: 768px) {
  .giftfinder-filter-header {
    text-align: left;
    width: 360px;
  }
  .giftfinder-filter-header .filter-x-close {
    right: 56px;
    top: 39px;
  }
  .giftfinder-filter-header h3 {
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 40px;
    padding: 30px 60px;
  }
}

.giftfinder-filter-holder {
  overflow: hidden;
  width: 100%;
}
.giftfinder-filter-holder .filters-container {
  display: flex;
  flex-direction: column;
  padding: 22px 40px;
  width: 100%;
}
.giftfinder-filter-holder .filter-block {
  margin-bottom: 13px;
  float: left;
  width: 100%;
}
.giftfinder-filter-holder .filter-block-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  line-height: 15px;
  margin-bottom: 8px;
  padding: 13px 30px 13px 0;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
.giftfinder-filter-holder .filter-block-title::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea94";
  display: inline-block;
  font-size: 27px;
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
}
.giftfinder-filter-holder .filter-block-title.open::after {
  content: "\ea78";
}
.giftfinder-filter-holder .filter-options {
  display: none;
  float: left;
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container {
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container .filter-item {
  margin-bottom: 12px;
}
.giftfinder-filter-holder .filter-options-container .no-input {
  letter-spacing: 0.25px;
}
.giftfinder-filter-holder .filter-options-container giftfinder-gender-option {
  display: block;
}
.giftfinder-filter-holder .filter-options-container .giftfinder-gender-btn {
  display: inline-block;
  text-align: left;
}
.giftfinder-filter-holder .filter-options-container .giftfinder-gender-btn .text {
  color: #1a1919;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
}
.giftfinder-filter-holder .filter-options-container .giftfinder-gender-btn .text::before {
  background-color: #1a1919;
  border: 0;
  bottom: -2px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: auto;
  transition: width 0.3s ease;
  width: 0;
}
.giftfinder-filter-holder .filter-options-container .giftfinder-gender-btn sup {
  color: #807463;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-left: 5px;
}
.giftfinder-filter-holder .filter-options-container .giftfinder-gender-btn.selected .text::before {
  width: calc(100% - 3px);
}
.giftfinder-filter-holder .filter-options-container label {
  color: #1a1919;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container label input {
  height: 2px;
  left: 2px;
  opacity: 0;
  position: absolute;
  top: 2px;
  width: 2px;
}
.giftfinder-filter-holder .filter-options-container label input:checked ~ .text::before {
  background-color: #886655;
  border-color: #886655;
}
.giftfinder-filter-holder .filter-options-container label input:checked ~ .text::after {
  display: block;
}
.giftfinder-filter-holder .filter-options-container label input.focus-visible ~ .text::before {
  border-color: #886655;
  height: 20px;
  left: -2px;
  top: 0;
  width: 20px;
}
.giftfinder-filter-holder .filter-options-container label input.focus-visible ~ .text::after {
  font-size: 10px;
  left: 3px;
}
.giftfinder-filter-holder .filter-options-container label input:disabled ~ span, .giftfinder-filter-holder .filter-options-container label input[disabled=disabled] ~ span {
  cursor: not-allowed;
  opacity: 0.26;
}
.giftfinder-filter-holder .filter-options-container label .text {
  background-color: transparent;
  cursor: pointer;
  padding-left: 21px;
}
.giftfinder-filter-holder .filter-options-container label .text::before {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  content: "";
  display: inline-block;
  height: 14px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 14px;
}
.giftfinder-filter-holder .filter-options-container label .text::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  color: #ffffff;
  content: "\ea2e";
  display: none;
  font-size: 6px;
  left: 5px;
  position: absolute;
  top: 3px;
}
.giftfinder-filter-holder .filter-options-container label sup {
  color: #807463;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-left: 5px;
}
.giftfinder-filter-holder .filter-options-container label.label-title .text {
  padding-left: 0;
  position: relative;
}
.giftfinder-filter-holder .filter-options-container label.label-title .text::before {
  background-color: #1a1919;
  border: 0;
  bottom: -2px;
  height: 1px;
  left: 0;
  top: auto;
  transition: width 0.3s ease;
  width: 0;
}
.giftfinder-filter-holder .filter-options-container label.label-title:hover .text::before {
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container label.label-title input:checked ~ .text::before {
  background-color: #111;
  top: auto;
  height: 1px;
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container label.label-title input:checked ~ .text::after {
  display: none;
}
.giftfinder-filter-holder .filter-options-container label.label-title input:focus ~ .text::before {
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container .btn-label-title {
  color: #1a1919;
  display: inline-block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  outline: none;
}
.giftfinder-filter-holder .filter-options-container .btn-label-title .text {
  position: relative;
}
.giftfinder-filter-holder .filter-options-container .btn-label-title .text::before {
  background-color: #1a1919;
  bottom: -4px;
  content: "";
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: width 0.3s ease;
  width: 0;
}
.giftfinder-filter-holder .filter-options-container .btn-label-title:focus .text::before, .giftfinder-filter-holder .filter-options-container .btn-label-title:hover .text::before, .giftfinder-filter-holder .filter-options-container .btn-label-title.active .text::before {
  width: 100%;
}
.giftfinder-filter-holder .filter-options-container .btn-label-title sup {
  color: #807463;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-left: 5px;
}
.giftfinder-filter-holder .nested-options {
  display: none;
  padding: 2px 0 0 20px;
  width: 100%;
}
.giftfinder-filter-holder .nested-options.active {
  display: block;
}
.giftfinder-filter-holder .nested-options .filter-item label .color {
  height: 16px;
  margin: 3px -19px -3px 26px;
}
.giftfinder-filter-holder .nested-options .filter-item label .text {
  display: inline-block;
  letter-spacing: 0.25px;
  line-height: 16px;
  padding-left: 26px;
}
.giftfinder-filter-holder .fop-filter-categories-wrapper {
  padding-top: 13px;
  width: 100%;
}
.giftfinder-filter-holder .price-holder {
  display: flex;
  width: 100%;
}
.giftfinder-filter-holder .price-holder .price-item-input {
  margin-right: 9px;
}
.giftfinder-filter-holder .price-holder .price-item-btn {
  margin-left: 21px;
}
.giftfinder-filter-holder .price-holder label {
  color: #1a1919;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-right: 5px;
  width: auto;
}
.giftfinder-filter-holder .price-holder input {
  color: #1a1919;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 5px 10px 6px;
  width: 50px;
}
.giftfinder-filter-holder .price-holder input:focus, .giftfinder-filter-holder .price-holder input:active {
  border-color: #886655;
}
.giftfinder-filter-holder .price-holder input::-webkit-input-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.giftfinder-filter-holder .price-holder input::-moz-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.giftfinder-filter-holder .price-holder input:-ms-input-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.giftfinder-filter-holder .price-holder input:-moz-placeholder {
  color: rgba(26, 25, 25, 0.28);
}
.giftfinder-filter-holder .price-holder .btn {
  min-width: 63px;
  padding: 9px 20px 7px;
}
.giftfinder-filter-holder .price-holder .btn.btn--disabled {
  background-color: transparent;
  border-color: #d8d8d8;
  color: #d8d8d8;
}
@media (min-width: 768px) {
  .giftfinder-filter-holder .filters-container {
    padding: 27px 20px 27px 60px;
  }
  .giftfinder-filter-holder .filter-block {
    margin-bottom: 15px;
  }
  .giftfinder-filter-holder .filter-block-title {
    margin-bottom: 0;
    width: calc(100% - 40px);
  }
  .giftfinder-filter-holder .filter-options-container {
    margin-top: -5px;
  }
  .giftfinder-filter-holder .filter-options-container .filter-item {
    margin-bottom: 0;
  }
  .giftfinder-filter-holder .filter-options-container label {
    width: auto;
  }
  .giftfinder-filter-holder .filter-options-container .no-input {
    font-size: 14px;
  }
  .giftfinder-filter-holder .nested-options {
    padding: 10px 0 38px 19px;
  }
  .giftfinder-filter-holder .nested-options .filter-item label .text::before {
    height: 10px;
    left: 0;
    top: 4px;
    width: 10px;
  }
  .giftfinder-filter-holder .nested-options .filter-item label input:checked ~ .text::before {
    height: 10px;
    width: 10px;
  }
  .giftfinder-filter-holder .nested-options .filter-item label input:checked ~ .text::after {
    font-size: 5px;
    left: 3px;
  }
  .giftfinder-filter-holder .nested-options .filter-item label input.focus-visible ~ .text::before {
    height: 16px;
    left: -3px;
    top: 1px;
    width: 16px;
  }
  .giftfinder-filter-holder .nested-options .filter-item label input.focus-visible ~ .text::after {
    font-size: 8px;
    left: 1px;
  }
  .giftfinder-filter-holder .fop-filter-categories-wrapper {
    padding-top: 17px;
  }
}

.giftfinder-filter-user-actions {
  bottom: -2px;
  display: flex;
  left: -100%;
  position: fixed;
  transition: left 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.giftfinder-filter-user-actions .btn {
  min-width: 10px;
  padding: 17px 7px 15px;
  width: 50%;
}
.giftfinder-filter-user-actions .btn:hover {
  background-color: #ffffff;
}
.giftfinder-filter-user-actions .btn-clear-all {
  background-color: #f6f4f2;
  border-color: #f6f4f2;
  color: #111;
}
@media (min-width: 768px) {
  .giftfinder-filter-user-actions {
    width: 360px;
  }
}
@media (min-width: 1024px) {
  .giftfinder-filter-user-actions {
    bottom: 0;
  }
}

.cookie-bar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  z-index: 1002;
  position: fixed;
  left: 0 !important;
  top: 0 !important;
  max-height: 100%;
  overflow: auto;
  max-width: 100%;
}
@media (min-width: 768px) {
  .cookie-bar {
    padding: 30px;
  }
}
.cookie-bar .ui-dialog-titlebar {
  display: none;
}
.cookie-bar .cookie-bar-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  color: #1a1919;
}
@media (max-width: 767px) {
  .cookie-bar .cookie-bar-wrapper {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 1px;
  }
  .cookie-bar .cookie-bar-wrapper a {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 1px;
  }
}
@media (min-width: 768px) {
  .cookie-bar .cookie-bar-wrapper {
    padding-right: 10%;
  }
}
@media (min-width: 1024px) {
  .cookie-bar .cookie-bar-wrapper {
    padding-right: 20%;
  }
}

.cookie-bar-buttons {
  position: absolute;
  right: 0;
  top: 0;
}
.cookie-bar-buttons button {
  font-size: 20px;
}

.cookie-policy {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .cookie-policy {
    margin-top: 80px;
  }
}
.cookie-policy ul.harmonica {
  list-style: none;
}

.cookie-preference {
  background-color: #ffffff;
  padding: 30px;
  text-align: left;
}
.cookie-preference input[type=radio] {
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
.cookie-preference input[type=radio]:checked + .option-label::before {
  background-color: #886655;
}
.cookie-preference input[type=radio]:checked + .option-label::after {
  background-color: #ffffff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 6px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 6px;
}
.cookie-preference input[type=radio]:checked + .option-label span.body {
  display: block;
}
.cookie-preference button {
  margin-top: 25px;
  margin-left: 38px;
}
.cookie-preference label {
  display: block;
}
.cookie-preference .option-label {
  cursor: pointer;
  display: block;
  padding-left: 38px;
  position: relative;
}
.cookie-preference .option-label::before {
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}
.cookie-preference .option-label span.excerpt,
.cookie-preference .option-label span.body {
  display: block;
}
.cookie-preference .option-label span.title {
  font-weight: bold;
}
.cookie-preference .option-label span.subtitle {
  color: #626262;
}
.cookie-preference .preference-row {
  overflow: hidden;
  transition: height 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
}
.cookie-preference .preference-row:first-child:not(:only-child) {
  height: 56px;
  margin-bottom: 30px;
}
.cookie-preference .preference-row:not(:first-child) {
  border-top: 1px solid #d8d8d8;
  height: 87px;
  padding-top: 30px;
}
.cookie-preference .preference-row.preview span.excerpt,
.cookie-preference .preference-row.preview span.body {
  display: block;
}

.pt_privacy.disable-clicks header {
  pointer-events: none;
}
.pt_privacy p {
  word-wrap: break-word;
}

.disable-clicks footer {
  pointer-events: none;
}

.sticky-buttons-spacer {
  height: 500px;
}
@media (min-width: 1024px) {
  .sticky-buttons-spacer {
    height: 250px;
  }
}

@media (max-width: 767px) {
  .decline-page-content-wrapper .horizontal-blocks a {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 1px;
  }
}
.decline-page-content-wrapper .cookie-bar-buttons {
  margin-bottom: 60px;
  margin-top: 40px;
}

.giftcard-balance-checker {
  margin: 0 auto;
  max-width: 681px;
  padding: 34px 30px 85px;
  width: 100%;
}
.giftcard-balance-checker .page-title {
  color: #000000;
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: none;
}
.giftcard-balance-checker .page-description {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  text-align: center;
}
.giftcard-balance-checker .giftcard-checker-box {
  margin: 40px 0 0 -30px;
  width: calc(100% + 60px);
}
.giftcard-balance-checker .giftcard-checker-box-inner {
  background-color: #fcfaf7;
  margin-bottom: 40px;
  padding: 46px 36px 44px;
  position: relative;
  width: 100%;
}
.giftcard-balance-checker .giftcard-number-wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .giftcard-balance-checker .giftcard-number-wrapper {
    display: flex;
  }
}
.giftcard-balance-checker .giftcard-cardnumber,
.giftcard-balance-checker .giftcard-pinnumber {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.giftcard-balance-checker .giftcard-cardnumber span {
  width: 100%;
}
.giftcard-balance-checker .giftcard-cardnumber .full-width-row {
  margin: 0;
}
.giftcard-balance-checker .giftcard-cardnumber .optional-indicator {
  display: none;
}
@media (max-width: 767px) {
  .giftcard-balance-checker .giftcard-cardnumber span {
    position: absolute;
    bottom: -23px;
  }
}
.giftcard-balance-checker .giftcard-pinnumber {
  margin-top: 36px;
  width: 100%;
  max-width: 130px;
}
@media (min-width: 768px) {
  .giftcard-balance-checker .giftcard-pinnumber {
    margin-top: 0;
  }
}
.giftcard-balance-checker .giftcard-prefix-input {
  align-self: flex-end;
  width: 69px;
}
.giftcard-balance-checker .giftcard-prefix-input.full-width-row {
  margin-right: -1px;
}
.giftcard-balance-checker .giftcard-number-input {
  align-self: flex-end;
  flex: 1;
  margin: 0 -11px 0 10px;
  width: 100%;
}
.giftcard-balance-checker .giftcard-number-input label {
  display: none;
}
.giftcard-balance-checker .giftcard-number-input .input-row {
  margin-top: 0;
}
.giftcard-balance-checker .giftcard-number-input .input-row.floating-label .error-text {
  background-color: #fcfaf7;
  left: -80px;
  padding: 2px 0;
  right: 0;
  top: -15px;
  width: auto;
}
.giftcard-balance-checker .giftcard-pin-input {
  align-self: flex-end;
  width: 100%;
}
.giftcard-balance-checker .giftcard-pin-input label {
  display: none;
}
.giftcard-balance-checker .giftcard-number-error {
  background-color: #fcfaf7;
  display: block;
  font-size: 11px;
  font-weight: bold;
  left: 36px;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 2px 0;
  position: absolute;
  right: 30px;
  top: 17px;
}
.giftcard-balance-checker .btn-row {
  padding: 0 30px;
  text-align: center;
  width: 100%;
}
.giftcard-balance-checker .giftcard-balance-results {
  margin: 40px 0 14px -30px;
}
.giftcard-balance-checker .giftcard-balance-results .giftcard-checker-box-inner {
  padding: 38px 30px 45px;
}
.giftcard-balance-checker .giftcard-balance-results .rituals-form .giftcard-number-input input,
.giftcard-balance-checker .giftcard-balance-results .rituals-form .giftcard-pin-input input {
  background-color: transparent;
}
.giftcard-balance-checker .btn-row-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.giftcard-balance-checker .btn-row-center .btn--text {
  font-size: 12px;
  margin-top: 12px;
  text-transform: uppercase;
}
.giftcard-balance-checker form:not(.rituals-form) .input-row.floating-label {
  margin-top: 0;
}
.giftcard-balance-checker form:not(.rituals-form) .input-row.floating-label .error-text {
  margin-top: 0;
}
.giftcard-balance-checker .rituals-form .giftcard-number-wrapper input {
  padding: 13px 15px 10px;
}
.giftcard-balance-checker .rituals-form .giftcard-number-wrapper .giftcard-number-input input,
.giftcard-balance-checker .rituals-form .giftcard-number-wrapper .giftcard-pin-input input {
  background-color: #ffffff;
}
.giftcard-balance-checker .rituals-form .input-label {
  padding: 0;
}
.giftcard-balance-checker .balance-block {
  padding-top: 44px;
  width: 100%;
}
.giftcard-balance-checker .balance-block .balance-amount {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 2px;
  line-height: 1;
}
.giftcard-balance-checker .balance-block .input-label {
  margin-bottom: 13px;
}
@media (min-width: 768px) {
  .giftcard-balance-checker {
    max-width: 635px;
    padding: 114px 0 128px;
  }
  .giftcard-balance-checker .page-title {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .giftcard-balance-checker .giftcard-checker-box {
    margin: 41px 0 0 -10px;
    width: calc(100% + 20px);
  }
  .giftcard-balance-checker .input-label {
    padding: 0 0 0 3px;
  }
  .giftcard-balance-checker .giftcard-checker-box-inner {
    padding: 74px 50px 56px;
  }
  .giftcard-balance-checker .giftcard-number-input {
    margin-top: 0 !important;
    max-width: 234px;
  }
  .giftcard-balance-checker .giftcard-number-input .input-row.floating-label .error-text {
    left: -77px;
    top: -13px;
  }
  .giftcard-balance-checker .giftcard-number-error {
    left: 51px;
    right: 40px;
    top: 27px;
  }
  .giftcard-balance-checker .btn-row {
    padding: 0;
  }
  .giftcard-balance-checker .giftcard-balance-results {
    margin-top: 30px;
  }
  .giftcard-balance-checker .giftcard-balance-results .giftcard-checker-box-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 75px 50px 65px;
  }
  .giftcard-balance-checker .giftcard-balance-results .giftcard-number-wrapper {
    margin-bottom: 0;
  }
  .giftcard-balance-checker .balance-block {
    padding-top: 58px;
  }
  .giftcard-balance-checker .balance-block .input-label {
    margin-bottom: 6px;
  }
  .giftcard-balance-checker .btn-row-center .btn--text {
    margin-top: 20px;
  }
}

.giftcard-wrap {
  margin: 0 auto 70px;
  max-width: 681px;
  padding: 0 30px;
}
.giftcard-wrap .form-row-button {
  margin: 0;
}
.giftcard-wrap .success-msg {
  color: #649b26;
}
@media (min-width: 768px) {
  .giftcard-wrap {
    margin: 0 auto 100px;
  }
}

.request-password-wrapper .password-message-wrapper {
  padding: 57px 0 100px;
}
@media (min-width: 768px) {
  .request-password-wrapper .password-message-wrapper {
    padding: 61px 0 160px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .request-password-wrapper .password-message-wrapper {
    padding: 40px 0 160px;
  }
}
.login-checkout-v3 .request-password-wrapper .rituals-form {
  background-color: #ffffff;
  margin: 0;
  max-width: none;
}
@media (max-width: 767px) {
  .login-checkout-v3 .request-password-wrapper .rituals-form {
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .request-password-wrapper .rituals-form {
    padding: 40px;
  }
}
.login-checkout-v3 .request-password-wrapper .rituals-form .btn {
  max-width: none;
  width: 100%;
}
@media (min-width: 768px) {
  .login-checkout-v3 .request-password-wrapper .inner-wrapper-small {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .login-checkout-v3 .request-password-wrapper .inner-wrapper {
    padding: 0;
  }
}
.request-password-wrapper .page-layout-left .password-form {
  max-width: 616px;
  margin: 18px auto 0;
}
@media (min-width: 768px) {
  .request-password-wrapper .page-layout-left .password-form {
    margin-top: 51px;
  }
}
.login-checkout-v3 .request-password-wrapper .page-layout-left .password-form {
  margin: 0;
  max-width: none;
}
@media (min-width: 768px) {
  .login-checkout-v3 .request-password-wrapper .page-layout-left .password-form {
    margin: 0;
  }
}
.request-password-wrapper .page-layout-left .password-form .password-reset__btn {
  margin-top: 40px;
  width: 100%;
}
@media (min-width: 768px) {
  .request-password-wrapper .page-layout-left .password-form .password-reset__btn {
    margin-top: 50px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .login-checkout-v3 .request-password-wrapper .page-layout-left .password-form .password-reset__btn {
    margin-top: 40px;
    width: 100%;
  }
}

.reset-password-confirmation,
.new-password-confirmation {
  align-items: center;
  background: #fcfaf7;
  box-shadow: 0 4px 6px -3px #767676;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 24px;
  min-height: 149px;
  padding: 0 30px;
  position: fixed;
  text-align: center;
  transform: translateY(-110%);
  transition: transform 0.6s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  top: 0;
  width: 100%;
  z-index: 999;
  opacity: 0;
}
.reset-password-confirmation.page-loaded,
.new-password-confirmation.page-loaded {
  opacity: 1;
}
@media (min-width: 1024px) {
  .reset-password-confirmation,
  .new-password-confirmation {
    display: none;
    position: absolute;
    max-width: 480px;
    height: auto;
    padding: 60px 70px;
    top: 35%;
    left: 50%;
    transform: translate(-50%);
    transition: none;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    box-shadow: none;
  }
}
.reset-password-confirmation .password-close,
.new-password-confirmation .password-close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 16px;
  font-size: 16px;
}
.reset-password-confirmation .text-container,
.new-password-confirmation .text-container {
  display: block;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .reset-password-confirmation .text-container,
  .new-password-confirmation .text-container {
    margin-bottom: 19px;
  }
}
@media (min-width: 1024px) {
  .reset-password-confirmation .text-container,
  .new-password-confirmation .text-container {
    margin-bottom: 19px;
  }
}
.reset-password-confirmation .btn.btn--secondary,
.new-password-confirmation .btn.btn--secondary {
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .reset-password-confirmation .btn.btn--secondary,
  .new-password-confirmation .btn.btn--secondary {
    display: block;
    min-width: 180px;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .reset-password-confirmation .btn.btn--secondary,
  .new-password-confirmation .btn.btn--secondary {
    display: block;
    min-width: 180px;
    margin: 0 auto;
  }
}
.reset-password-confirmation.open,
.new-password-confirmation.open {
  display: flex;
  flex-direction: column;
  transform: translateY(0);
}
@media (min-width: 1024px) {
  .reset-password-confirmation.open,
  .new-password-confirmation.open {
    display: block;
    transform: translate(-50%);
  }
}

.new-password-confirmation {
  min-height: 225px;
  padding-top: 15px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .new-password-confirmation {
    padding-top: 60px;
  }
}
@media (min-width: 1024px) {
  .new-password-confirmation {
    padding-top: 60px;
  }
}
.new-password-confirmation .btn.btn--secondary {
  display: block;
  margin-top: 30px;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .new-password-confirmation .btn.btn--secondary {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .new-password-confirmation .btn.btn--secondary {
    margin-top: 0;
  }
}

.wimo-wrapper {
  padding: 50px 0 0;
}
@media (min-width: 768px) {
  .wimo-wrapper {
    padding: 60px 0 94px;
  }
}
@media (min-width: 1024px) {
  .wimo-wrapper .page-layout-right {
    max-width: 400px;
  }
}
.wimo-wrapper .faq-block {
  background-color: #efedea;
  width: calc(100% + 40px);
  margin-left: -20px;
  max-width: none;
  padding: 63px 5px 80px;
}
@media (min-width: 1024px) {
  .wimo-wrapper .faq-block {
    padding: 25px 0 23px;
    width: 100%;
    background-color: #ffffff;
    margin: 0;
  }
}
.wimo-wrapper .faq-block h2 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1.5px;
  text-align: left;
}
@media (min-width: 768px) {
  .wimo-wrapper .faq-block h2 {
    margin-bottom: 23px;
  }
}
@media (min-width: 768px) {
  .wimo-wrapper .faq-block .faq-accordion {
    margin: 0 auto 32px;
  }
}
.wimo-wrapper .faq-block .faq-accordion .faq-accordion-head {
  padding: 24px 84px 23px 0;
}
@media (min-width: 768px) {
  .wimo-wrapper .faq-block .faq-accordion .faq-accordion-content {
    padding: 0 50px 20px 0;
  }
}
.wimo-wrapper .faq-block .faq-accordion .acc-head-button .acc-head-circle {
  top: 27px;
  right: 0;
}

.wimo-form-wrapper {
  text-align: center;
}
.wimo-form-wrapper .wimo-form-title {
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  max-width: 250px;
  margin: -16px auto 9px;
  font-style: italic;
  color: #1a1919;
  text-transform: none;
}
@media (min-width: 768px) {
  .wimo-form-wrapper .wimo-form-title {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
    margin: 18px auto 13px;
    max-width: 480px;
  }
}
.wimo-form-wrapper .wimo-form-subtitle {
  color: #1a1919;
  max-width: 285px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .wimo-form-wrapper .wimo-form-subtitle {
    max-width: none;
    letter-spacing: 0.3px;
    line-height: 28px;
  }
}
.wimo-form-wrapper div.ordernumber {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .wimo-form-wrapper div.ordernumber {
    margin-top: 30px;
  }
}

.wimo-form {
  max-width: 566px;
  margin: 42px auto 0;
}
@media (min-width: 768px) {
  .wimo-form {
    margin-top: 38px;
  }
}
.wimo-form .error-message {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #1a1919;
}

.wimo-login {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 26px;
}
@media (min-width: 768px) {
  .wimo-login {
    margin-top: 20px;
  }
}

.special-order-warning,
.error-form {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.special-order-warning {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .special-order-warning {
    text-align: center;
  }
}

.error-form {
  margin-bottom: 20px;
}

.order-details-heading {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: none;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
  margin: 0;
}
@media (min-width: 768px) {
  .order-details-heading {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .wimo-order-wrapper {
    padding: 10px 0 70px;
  }
}
.wimo-order-wrapper .inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1360px;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .inner-wrapper {
    flex-direction: column-reverse;
  }
}
.wimo-order-wrapper .inner-wrapper-solo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .inner-wrapper-solo {
    flex-direction: column-reverse;
  }
}
.wimo-order-wrapper .order-info-block {
  background-color: #ffffff;
  padding: 41px 42px 40px;
  width: 48%;
  max-width: 630px;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .order-info-block {
    width: calc(100% + 40px);
    max-width: none;
    margin-left: -20px;
    padding: 24px 28px 23px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-product {
  display: flex;
  padding: 23px 10px;
  border-bottom: 1px solid #d8d8d8;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block .mini-cart-product {
    padding: 23px 10px 20px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-image {
  width: 70px;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block .mini-cart-image {
    width: 100px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-image img {
  display: block;
  max-width: 70px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block .mini-cart-image img {
    max-width: 100px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-info {
  padding: 0 0 0 16px;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block .mini-cart-info {
    padding: 0 20px 0 26px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-small-title {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  color: #886655;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block .mini-cart-small-title {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    margin-bottom: 2px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-name {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .order-info-block .mini-cart-name {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-attributes {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0 0 9px;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block .mini-cart-attributes {
    margin-top: -1px;
  }
}
.wimo-order-wrapper .order-info-block .mini-cart-details-bottom .mini-cart-pricing {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #959595;
}
.wimo-order-wrapper .order-info-block .mini-cart-details-bottom .error {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.wimo-order-wrapper .order-info-block-cancelled {
  order: 2;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .order-info-block-cancelled + .order-info-block {
    margin-bottom: 0;
  }
}
.wimo-order-wrapper .order-info-block-cancelled ~ .order-info-block {
  order: 1;
}
.wimo-order-wrapper .order-info-block-cancelled ~ .shipping-info-block {
  order: 3;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .order-info-block-cancelled {
    order: 1;
  }
  .wimo-order-wrapper .order-info-block-cancelled ~ .order-info-block {
    order: 3;
  }
  .wimo-order-wrapper .order-info-block-cancelled ~ .shipping-info-block {
    order: 2;
  }
}
.wimo-order-wrapper .shipping-info-block {
  background-color: #ffffff;
  padding: 41px 42px 25px;
  width: 48%;
  max-width: 630px;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .shipping-info-block {
    width: 100%;
    padding: 26px 25px 18px;
    margin: 0 0 24px;
    max-width: none;
  }
}
.wimo-order-wrapper .shipping-info-block .shipping-method {
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  color: #886655;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin: 16px 0 8px;
}
@media (min-width: 768px) {
  .wimo-order-wrapper .shipping-info-block .shipping-method {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    margin: 23px 0 11px;
  }
}
.wimo-order-wrapper .shipping-info-block .address {
  display: block;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .shipping-info-block .address {
    margin-bottom: -2px;
  }
}
.wimo-order-wrapper .order-info-block,
.wimo-order-wrapper .shipping-info-block {
  margin-bottom: 24px;
}
.wimo-order-wrapper .shipping-info-holder {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .shipping-info-holder {
    flex-direction: row;
  }
}
.wimo-order-wrapper .shipping-info-text,
.wimo-order-wrapper .wimo-map {
  width: 100%;
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .shipping-info-text,
  .wimo-order-wrapper .wimo-map {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .shipping-info-text {
    padding-right: 15px;
  }
}
.wimo-order-wrapper .wimo-map {
  min-height: 187px;
  margin: 16px 0 4px;
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .wimo-map {
    margin: 26px 0 0;
  }
}
.wimo-order-wrapper .pup-place {
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  margin: -3px 0 10px;
}
@media (max-width: 767px) {
  .wimo-order-wrapper .pup-place {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
    margin: 0 0 9px;
  }
}
.wimo-order-wrapper .opening-times {
  margin-top: 21px;
  padding-top: 11px;
  border-top: 1px solid #d8d8d8;
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .opening-times {
    max-width: 235px;
  }
}
.wimo-order-wrapper .opening-times + .note {
  border-top: 0;
  padding-top: 0;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-head {
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.8px;
  font-weight: bold;
  color: #1a1919;
  padding: 0;
  text-transform: none;
  text-decoration: none;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-head::before {
  font-size: 34px;
  left: auto;
  right: -10px;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-body {
  padding: 12px 0 0;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-body ul {
  list-style: none;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-body ul li {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-body ul li.active {
  font-weight: bold;
}
.wimo-order-wrapper .opening-times .accordion-content .acc-content-body ul li .day {
  min-width: 88px;
  display: inline-block;
}
.wimo-order-wrapper .note {
  border-top: 1px solid #d8d8d8;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #aeaeae;
  display: block;
  margin-top: 22px;
  padding-top: 22px;
}
@media (min-width: 1024px) {
  .wimo-order-wrapper .note {
    max-width: 235px;
  }
}

.wimo-canceled-order-wrapper {
  order: 1;
  padding-top: 18px;
  max-width: 100%;
}
@media (min-width: 768px) {
  .wimo-canceled-order-wrapper {
    order: 2;
    width: 48%;
    padding-top: 40px;
  }
}

.wimo-canceled-order {
  text-align: center;
}
@media (max-width: 767px) {
  .wimo-canceled-order {
    width: calc(100% + 40px);
    margin: 0 0 20px -20px;
  }
}
@media (max-width: 767px) {
  .wimo-canceled-order .wimo-canceled-order-slider {
    padding-bottom: 30px;
  }
}
.wimo-canceled-order .title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  display: block;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .wimo-canceled-order .title {
    text-align: left;
    padding-left: 20px;
  }
}
.wimo-canceled-order .wimo-drag-list-item {
  padding: 0 5px;
}
@media (max-width: 767px) {
  .wimo-canceled-order .drag-list-body {
    padding: 0 19px;
  }
}
.wimo-canceled-order .info-box-wrapper {
  display: flex;
}
.wimo-canceled-order .info-box {
  display: flex;
  align-items: center;
  background: #ffffff;
  margin: 0 auto 20px;
  padding: 33px 40px 30px;
  width: 100%;
  max-width: 90vw;
  min-height: 132px;
}
@media (max-width: 767px) {
  .wimo-canceled-order .info-box {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 768px) {
  .wimo-canceled-order .info-box {
    max-width: 400px;
    min-height: 192px;
  }
}
.wimo-canceled-order .info-box p,
.wimo-canceled-order .info-box a {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .wimo-canceled-order .info-box p,
  .wimo-canceled-order .info-box a {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1.2px;
  }
}

.wimo-faq {
  background-color: #efedea;
}

.wimo-news-banner-wrapper {
  background-color: #ffffff;
  border-bottom: 1px solid #886655;
  text-align: center;
  padding: 12px 10px 8px;
}
.wimo-news-banner-wrapper span,
.wimo-news-banner-wrapper a,
.wimo-news-banner-wrapper p,
.wimo-news-banner-wrapper div {
  display: inline;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0.27px;
  color: #1a1919;
}
.wimo-news-banner-wrapper .ico {
  color: #886655;
  font-size: 22px;
  margin-right: 2px;
}

.wimo-progress-wrapper {
  text-align: center;
  padding: 31px 0 40px;
  position: relative;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper {
    padding: 50px 0 60px;
  }
}
.wimo-progress-wrapper .responsive-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.wimo-progress-wrapper .responsive-bg::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(246, 244, 242, 0) 0%, rgb(246, 244, 242) 100%);
  height: 150px;
  width: 100%;
}
.wimo-progress-wrapper .wimo-order-title {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1px;
  text-transform: none;
  font-style: italic;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-title {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 1.5px;
    max-width: 580px;
    margin: 0 auto 28px;
  }
}
.wimo-progress-wrapper .wimo-order-subtitle {
  letter-spacing: 0.3px;
  max-width: 500px;
  margin: 0 auto;
  display: block;
}
.wimo-progress-wrapper .wimo-order-date {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #b4b4b4;
  display: block;
  margin: 20px 0 10px;
}
.wimo-progress-wrapper .wimo-order-date .refresh-time {
  display: inline;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-date {
    margin-top: 0;
  }
}
.wimo-progress-wrapper .wimo-order-step {
  background-color: #1a1919;
  position: relative;
  padding: 17px 28px 16px 60px;
  text-align: left;
  color: #ffffff;
  max-width: 580px;
  margin: 0 auto 8px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-step {
    padding: 21px 24px 21px 76px;
    margin-bottom: 9px;
  }
}
.wimo-progress-wrapper .wimo-order-step .status {
  position: absolute;
  top: 19px;
  left: 20px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-step .status {
    top: 21px;
    left: 24px;
  }
}
.wimo-progress-wrapper .wimo-order-step .status .circle {
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-step .status .circle {
    width: 32px;
    height: 32px;
  }
}
.wimo-progress-wrapper .wimo-order-step .status .circle .check {
  display: none;
}
.wimo-progress-wrapper .wimo-order-step .status .wimo-spinner-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
}
.wimo-progress-wrapper .wimo-order-step .status .wimo-spinner-wrapper::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #807463;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-step .status .wimo-spinner-wrapper::after {
    width: 16px;
    height: 16px;
  }
}
.wimo-progress-wrapper .wimo-order-step .status .wimo-spinner-wrapper .wimo-spinner {
  width: 32px;
  height: 32px;
  padding: 1px;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(63, 249, 220, 0.1) 33%, #ffffff 100%);
  animation: spin 1.5s linear 0s infinite;
}
.wimo-progress-wrapper .wimo-order-step .status .wimo-spinner-wrapper .wimo-spinner-core {
  width: 100%;
  height: 100%;
  background-color: #1a1919;
  border-radius: 50%;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-359deg);
  }
}
.wimo-progress-wrapper .wimo-order-step .status .wimo-loader {
  display: none;
  text-align: center;
  font-size: 0;
  flex-direction: column;
  align-items: center;
  margin-top: 9px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-step .status .wimo-loader {
    margin-top: 11px;
  }
}
.wimo-progress-wrapper .wimo-order-step .status .wimo-loader span {
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  height: 5px;
  width: 5px;
  margin-bottom: 4px;
}
.wimo-progress-wrapper .wimo-order-step .status.completed .circle {
  background-color: #ffffff;
}
.wimo-progress-wrapper .wimo-order-step .status.completed .circle .check {
  display: block;
  color: #1a1919;
  font-size: 11px;
}
.wimo-progress-wrapper .wimo-order-step .status.completed .circle .check:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2f";
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .wimo-order-step .status.completed .circle .check {
    font-size: 15px;
  }
}
.wimo-progress-wrapper .wimo-order-step .status.completed .wimo-loader {
  display: flex;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .wimo-spinner-wrapper {
  display: flex;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .circle {
  display: none;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .wimo-loader {
  display: flex;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .wimo-loader span {
  opacity: 0;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .wimo-loader span:nth-child(1) {
  animation: loaderPulse 1.6s infinite 0.2s;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .wimo-loader span:nth-child(2) {
  animation: loaderPulse 1.6s infinite 0.4s;
}
.wimo-progress-wrapper .wimo-order-step .status.in-progress .wimo-loader span:nth-child(3) {
  animation: loaderPulse 1.6s infinite 0.6s;
}
@keyframes loaderPulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}
.wimo-progress-wrapper .order-status {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: none;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .order-status {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
  }
}
.wimo-progress-wrapper .order-date {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #7f7f7f;
  margin-top: -7px;
  display: block;
}
.wimo-progress-wrapper .order-date + .track-and-trace-link-wrapper {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .order-date + .track-and-trace-link-wrapper {
    margin-top: 14px;
  }
}
.wimo-progress-wrapper .btn--white {
  display: block;
  margin-top: 15px;
  padding: 10px 20px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .btn--white {
    margin-top: 23px;
  }
}
.wimo-progress-wrapper .btn--white .ico {
  font-size: 18px;
  margin-left: 4px;
}
.wimo-progress-wrapper .track-and-trace-link-wrapper {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .track-and-trace-link-wrapper {
    margin-top: 8px;
  }
}
.wimo-progress-wrapper .track-and-trace-link-wrapper + .track-and-trace-link {
  margin-top: 11px;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .track-and-trace-link-wrapper + .track-and-trace-link {
    margin-top: 16px;
  }
}
.wimo-progress-wrapper .track-and-trace-link {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  color: #807463;
  display: inline-block;
}
@media (min-width: 768px) {
  .wimo-progress-wrapper .track-and-trace-link {
    font-size: 14px;
  }
}
.wimo-progress-wrapper .track-and-trace-link + .icon-arrow--link {
  font-size: 13px;
  margin-left: 4px;
  color: #807463;
}

.thank-you-page {
  background-color: #efedea;
  position: relative;
  width: 100%;
}
.thank-you-page h1 {
  text-align: center;
  text-transform: none;
}
@media (max-width: 767px) {
  .thank-you-page h1 {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 34px;
    margin: 40px 0;
  }
}
@media (min-width: 768px) {
  .thank-you-page h1 {
    letter-spacing: 0.75px;
    margin: 60px 0;
  }
}
.thank-you-page .thank-you-inner {
  background-color: #f6f4f2;
  width: 100%;
}
@media (min-width: 768px) {
  .thank-you-page .thank-you-inner {
    padding: 60px 0;
  }
}
.thank-you-page .thank-you-inner .inner-wrapper {
  display: flex;
}
@media (max-width: 767px) {
  .thank-you-page .thank-you-inner .inner-wrapper {
    flex-direction: column;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .thank-you-page .thank-you-inner .inner-wrapper {
    max-width: 1360px;
  }
}
.thank-you-page .thank-you-left-col {
  width: 100%;
}
@media (min-width: 768px) {
  .thank-you-page .thank-you-left-col {
    margin-right: 3%;
    width: 36.3%;
  }
}
.thank-you-page .thank-you-right-col {
  width: 100%;
}
@media (min-width: 768px) {
  .thank-you-page .thank-you-right-col {
    width: 60.7%;
  }
}

.sticky-top-message-wrap {
  position: relative;
  width: 100%;
}
.sticky-top-message-wrap .sticky-top-message-bar-placeholder {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.sticky-top-message-wrap .sticky-top-message-bar-placeholder.in-place {
  left: auto;
  position: relative;
  top: auto;
}

.static-info-banner {
  width: 100%;
}
@media (min-width: 1024px) {
  .static-info-banner {
    left: 0;
    position: fixed;
    z-index: 981;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .static-info-banner {
    top: 69px;
  }
}
@media (min-width: 1025px) {
  .static-info-banner {
    top: 95px;
  }
}
@media (min-width: 1024px) {
  .static-info-banner + .header-spacer {
    height: 144px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .static-info-banner + .header-spacer {
    height: 117px !important;
  }
}

.sticky-top-message-bar {
  background-color: #9a7b69;
  padding: 14px 0 10px;
  position: relative;
  transform: translateY(-100%);
  transition: 0.6s transform cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 10;
}
@media (min-width: 768px) {
  .sticky-top-message-bar {
    padding: 16px 0 12px;
  }
}
.sticky-top-message-bar.loaded {
  transform: translateY(0);
}
.sticky-top-message-bar.sticky {
  left: 0;
  position: fixed;
  top: 0;
}
.sticky-top-message-bar.not-animated {
  transform: translateY(0);
}
.sticky-top-message-bar .inner-wrapper {
  align-items: center;
  display: flex;
}
@media (max-width: 767px) {
  .sticky-top-message-bar .inner-wrapper {
    padding: 0 26px;
  }
}
@media (min-width: 768px) {
  .sticky-top-message-bar .inner-wrapper {
    justify-content: center;
  }
}
.sticky-top-message-bar .icon {
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 10px;
  margin: -2px 16px 0 0;
}
@media (max-width: 767px) {
  .sticky-top-message-bar .icon {
    margin: -3px 15px 0 0;
  }
}
.sticky-top-message-bar p {
  color: #ffffff;
}
@media (max-width: 767px) {
  .sticky-top-message-bar p {
    font-size: 11px;
    letter-spacing: 0.75px;
    line-height: 16px;
  }
}
@media (min-width: 768px) {
  .sticky-top-message-bar p {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 20px;
  }
}
.sticky-top-message-bar p:last-child {
  margin-bottom: 0;
}
.sticky-top-message-bar p a {
  color: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.steps-content-block {
  padding: 40px 24px 24px;
  width: 100%;
}
@media (min-width: 768px) {
  .steps-content-block {
    max-width: 450px;
    padding: 0;
  }
}
.steps-content-block h3 {
  letter-spacing: 0.6px;
  margin-bottom: 26px;
  text-transform: none;
}
@media (max-width: 767px) {
  .steps-content-block h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (min-width: 768px) {
  .steps-content-block h3 {
    font-size: 28px;
    line-height: 40px;
  }
}
.steps-content-block .items-list {
  margin-bottom: 38px;
  width: 100%;
}
.steps-content-block .item {
  margin-bottom: 24px;
  padding-left: 72px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .steps-content-block .item {
    padding-left: 74px;
  }
}
.steps-content-block .item:last-child {
  margin-bottom: 0;
}
.steps-content-block .icon {
  font-size: 56px;
  color: #9a7b69;
  left: -4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .steps-content-block .icon {
    left: -3px;
  }
}
.steps-content-block p {
  font-size: 13px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .steps-content-block p {
    letter-spacing: 0.8px;
  }
}
.steps-content-block .btn-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.steps-content-block .btn {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .steps-content-block .btn {
    max-width: 100%;
    width: 100%;
  }
}

.magazine-article-content-block {
  background-color: #ffffff;
  padding: 40px 24px 60px;
  width: 100%;
}
@media (min-width: 768px) {
  .magazine-article-content-block {
    padding: 65px 63px 64px;
  }
}
.magazine-article-content-block .mag-article-block-title {
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 34px;
  margin-bottom: 8px;
  text-transform: none;
}
@media (min-width: 768px) {
  .magazine-article-content-block .mag-article-block-title {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 19px;
  }
}
.magazine-article-content-block .mag-article-block-description {
  font-size: 13px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .magazine-article-content-block .mag-article-block-description {
    letter-spacing: 0.75px;
  }
}

.mag-article-preview {
  padding-top: 12px;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .mag-article-preview {
    padding-top: 20px;
  }
}
.mag-article-preview .image-holder {
  display: block;
  margin-bottom: 18px;
  width: 100%;
}
@media (min-width: 768px) {
  .mag-article-preview .image-holder {
    margin-bottom: 23px;
  }
}
.mag-article-preview .image-holder img {
  display: block;
  width: 100%;
}
.mag-article-preview .text-holder {
  width: 100%;
}
@media (min-width: 768px) {
  .mag-article-preview .text-holder {
    margin: 0 auto;
    max-width: 500px;
  }
}
.mag-article-preview .text-holder p {
  font-size: 16px;
  letter-spacing: 0.7px;
  line-height: 27px;
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  .mag-article-preview .text-holder p {
    font-size: 15px;
    letter-spacing: 0.75px;
    line-height: 26px;
    margin-bottom: 21px;
  }
}
.mag-article-preview .mag-article-categories {
  font-size: 0;
  margin-bottom: 4px;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-categories {
    margin-bottom: 12px;
  }
}
.mag-article-preview .mag-article-categories li {
  display: inline-block;
  list-style: none;
  margin-right: 15px;
  padding-right: 15px;
  position: relative;
}
.mag-article-preview .mag-article-categories li::after {
  background-color: #d8d8d8;
  content: "";
  display: inline-block;
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
}
.mag-article-preview .mag-article-categories li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.mag-article-preview .mag-article-categories li:last-child::after {
  display: none;
}
.mag-article-preview .mag-article-categories a {
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 4px;
  text-decoration: none;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-categories a {
    letter-spacing: 1.5px;
  }
  .mag-article-preview .mag-article-categories a:hover {
    color: #1a1919;
  }
}
.mag-article-preview .mag-article-title {
  margin-bottom: 18px;
  text-align: center;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-title {
    margin-bottom: 15px;
  }
}
.mag-article-preview .mag-article-title a {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  line-height: 32px;
  text-decoration: none;
  text-transform: none;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-title a {
    line-height: 37px;
    letter-spacing: 1.7px;
  }
}
.mag-article-preview .mag-article-reading-time {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin-bottom: 17px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-reading-time {
    font-size: 11px;
    margin-bottom: 15px;
  }
}
.mag-article-preview .mag-article-reading-time .icon-ico-clock::before {
  margin-right: 12px;
  position: relative;
  top: -1px;
}
.mag-article-preview .mag-article-read-more {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 11px;
  letter-spacing: 1.7px;
  text-decoration: underline;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-read-more:hover {
    text-decoration: none;
  }
}
.mag-article-preview .mag-article-read-all {
  padding-top: 30px;
}
@media (min-width: 768px) {
  .mag-article-preview .mag-article-read-all {
    padding-top: 23px;
  }
}
@media (max-width: 767px) {
  .mag-article-preview .mag-article-read-all .btn {
    max-width: 100%;
    width: 100%;
  }
}

.header-404 {
  background-color: #1a1919;
  color: #ffffff;
}
@media (min-width: 1024px) {
  .header-404 {
    padding: 20px 70px 20px;
    position: relative;
  }
}
.header-404 nav {
  width: 100%;
  display: flex;
}
.header-404 nav ul {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  justify-content: space-between;
  list-style-type: none;
}
@media (min-width: 1024px) {
  .header-404 nav ul {
    flex-direction: row;
  }
}
.header-404 nav ul li {
  padding: 30px 0 5px 10px;
}
@media (min-width: 1024px) {
  .header-404 nav ul li {
    padding: 0;
  }
}
.header-404 nav ul li:first-of-type {
  border-bottom: 1px solid #ffffff;
}
@media (min-width: 1024px) {
  .header-404 nav ul li:first-of-type {
    border-bottom: 0;
  }
}
.header-404 nav ul li a {
  color: #ffffff;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: 0.5px;
  text-underline-offset: 2px;
}
@media (min-width: 1024px) {
  .header-404 nav ul li a {
    align-items: center;
    display: flex;
    text-decoration: none;
  }
}
.header-404 nav ul li a span {
  display: none;
}
@media (min-width: 1024px) {
  .header-404 nav ul li a span {
    display: block;
    margin-right: 15px;
    margin-top: 5px;
  }
}
@media (min-width: 1024px) {
  .header-404 nav ul li:nth-child(2) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.header-404 nav ul li:nth-child(2) a {
  width: 140px;
}

.error-404-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 73px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.error-404 {
  align-items: center;
  color: #000000;
  display: flex;
  flex-direction: column;
}
.error-404 p,
.error-404 h3,
.error-404 h4,
.error-404 a {
  font-family: inherit;
  color: #000000;
}
.error-404--video {
  display: none;
  height: 100%;
  left: -1px;
  object-fit: fill;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
@media (min-width: 1024px) {
  .error-404--video.desktop {
    display: block;
  }
}
@media (max-width: 1023px) {
  .error-404--video.mobile {
    display: block;
  }
}
.error-404--img-container {
  max-width: 230px;
  margin-top: 100px;
}
@media (min-width: 1024px) {
  .error-404--img-container {
    max-width: 400px;
    max-height: 200px;
  }
}
.error-404--img-container img {
  width: 100%;
}
.error-404--message {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 25px 0;
}
.error-404--message p {
  font-family: "inherit";
  font-size: 14px;
  margin-bottom: 0;
  padding: 0 25px;
  text-align: center;
}
.error-404--message p:first-of-type {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 70px;
}
.error-404--links-container {
  margin-bottom: 50px;
}
@media (min-width: 1024px) {
  .error-404--links-container {
    margin-bottom: 170px;
  }
}
.error-404--links-container ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}
@media (min-width: 1024px) {
  .error-404--links-container ul {
    flex-direction: row;
  }
}
.error-404--links-container ul li {
  font-size: 24px;
  padding-bottom: 10px;
  height: 70px;
}
.error-404--links-container ul li:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .error-404--links-container ul li {
    padding: 0 10px 0 0;
  }
}
.error-404--links-container ul li .btn.error404-btn {
  color: #ffffff;
  display: block;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  padding: 16px 32px;
  text-decoration: none;
  line-height: unset;
  width: 350px;
  max-width: unset;
}
@media (min-width: 1160px) {
  .error-404--links-container ul li .btn.error404-btn {
    width: 300px;
  }
}
@media (min-width: 1440px) {
  .error-404--links-container ul li .btn.error404-btn {
    width: 350px;
  }
}
.error-404--links-container ul li .btn.error404-btn:hover {
  color: #000000;
}

.columnTwoBody {
  display: flex;
  justify-content: center;
}
.columnTwoBody--customer-service {
  font-size: 12px;
  max-width: 510px;
  text-align: center;
  padding: 0 15px;
}
.columnTwoBody--customer-service a {
  text-decoration: underline;
}

.pt_error .page-content.main {
  background-color: #f7f4f3;
}
.pt_error .page-content.main .primary-content {
  background-color: transparent;
}

.pdp-main-container.advent-main-pdp-block .product-name {
  letter-spacing: 1px;
  line-height: 48px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .product-name {
    margin-bottom: 0;
  }
}
.pdp-main-container.advent-main-pdp-block .product-name .the-ritual-of-title {
  display: block;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 5px;
  color: #9a7b69;
  -webkit-text-fill-color: #9a7b69;
}
.pdp-main-container.advent-main-pdp-block .product-details-container {
  padding: 10px 0 0;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .product-details-container {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .product-details-container {
    background: linear-gradient(to left, #ffffff 47%, transparent 47%);
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .pdp-main-container.advent-main-pdp-block .product-details-container {
    background: transparent;
  }
}
.pdp-main-container.advent-main-pdp-block .product-details-container .pdp-disclaimer {
  color: #626262;
  font-size: 12px;
  line-height: 16px;
  padding-top: 5px;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .product-details-container .pdp-disclaimer {
    padding: 5px 20px 0;
  }
}
.pdp-main-container.advent-main-pdp-block .product-details-container .inner-wrapper .product-details-column.column-images {
  padding-top: 0;
}
.pdp-main-container.advent-main-pdp-block .short-description {
  margin-bottom: 20px;
}
.pdp-main-container.advent-main-pdp-block .long-description {
  color: #1a1919;
  padding-top: 15px;
  position: relative;
  line-height: 24px;
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .long-description::before {
    content: "";
    background: linear-gradient(90deg, rgba(174, 174, 174, 0) 0%, rgba(26, 25, 25, 0.38) 50.46%, rgba(174, 174, 174, 0) 100%);
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .long-description {
    padding: 15px 20px 0;
  }
}
.pdp-main-container.advent-main-pdp-block .price-sales {
  font-size: 32px;
  letter-spacing: 0.6px;
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .discounted-price,
  .pdp-main-container.advent-main-pdp-block .price-standard {
    display: inline-block;
  }
}
.pdp-main-container.advent-main-pdp-block .value-price {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 1.8px;
  margin-bottom: 10px;
  padding-top: 12px;
  position: relative;
  text-transform: uppercase;
}
.pdp-main-container.advent-main-pdp-block .value-price::before {
  content: "";
  background: linear-gradient(90deg, rgba(174, 174, 174, 0) 0%, rgba(26, 25, 25, 0.38) 50.46%, rgba(174, 174, 174, 0) 100%);
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.pdp-main-container.advent-main-pdp-block .product-add-to-cart .btn-wrapper .btn {
  background-color: #0a152b;
  min-width: 300px;
  transition: background-color 0.1s cubic-bezier(0.33, 0.16, 0.15, 0.7), color 0.1s cubic-bezier(0.33, 0.16, 0.15, 0.7), border-color 0.1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
@media (min-width: 768px) and (max-width: 849px) {
  .pdp-main-container.advent-main-pdp-block .product-add-to-cart .btn-wrapper .btn {
    min-width: 380px;
  }
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .product-add-to-cart .btn-wrapper .btn:hover {
    background-color: transparent;
    color: #0a152b;
    border-color: #0a152b;
  }
}
.pdp-main-container.advent-main-pdp-block .read-more-link {
  display: inline-block;
}
.pdp-main-container.advent-main-pdp-block .check-availability-button {
  color: #a88b6b;
}
@media (min-width: 768px) and (max-width: 849px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper {
    display: block;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-details {
    background-color: #ffffff;
    padding: 64px 0;
    width: 100%;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-details .product-details-content {
    padding: 0;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .pdp-breadcrumbs-wrapper .breadcrumb {
    display: none;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-breadcrumbs-wrapper .pdp-back-button-wrapper {
    display: block;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-breadcrumbs-wrapper .pdp-back-button-wrapper .back-button {
    color: #000000;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper {
    margin-top: -50px;
    max-width: 100%;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .column-images {
    padding-top: 0;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images {
    width: 50%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images .productthumbnail {
    animation: scale-animation-advent 10s ease forwards;
  }
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-details {
    width: 50%;
    margin: auto;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-details .product-details-content {
    padding: 100px 20px 40px;
  }
}
@-webkit-keyframes scale-animation-advent {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes scale-animation-advent {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale-animation-advent {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-breadcrumbs-wrapper {
    left: 5px;
    top: 12px;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-breadcrumbs-wrapper .back-button {
    color: #000000;
  }
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper {
  align-items: flex-start;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper {
    padding: 20px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 849px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper {
    padding: 20px 0 0;
  }
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images {
  position: relative;
  width: 64%;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images {
    width: 100%;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images .easter-view-block-ar {
    right: 20px;
  }
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images .pdp-images-holder {
    max-width: 100%;
    margin-bottom: 0;
    width: 100%;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-images .pdp-images-holder {
    min-height: 200px !important;
  }
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .product-details-column.column-details {
    background-color: #ffffff;
    padding: 20px 0 0;
  }
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-block,
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-block-ar {
  position: absolute;
  top: 40px;
  right: 60px;
  z-index: 10;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-block,
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-block-ar {
    right: 0;
    top: 0;
  }
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-block .icon-text,
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-block-ar .icon-text {
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-icon-block {
  position: relative;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 2;
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-icon-block-ar {
  position: relative;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 2;
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .advent-view-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}
.pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .icon-AR {
  color: #153163;
  font-size: 30px;
  height: 30px;
  left: 50%;
  line-height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider.slick-initialized {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider .slick-slide > div {
    max-width: 300px;
    margin: 0 auto;
  }
}
body:not(.personalised-gifting-page) .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider img {
  padding: 40px 0 60px;
}
@media (max-width: 767px) {
  body:not(.personalised-gifting-page) .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider img {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body:not(.personalised-gifting-page) .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider img {
    max-height: 900px;
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  body:not(.personalised-gifting-page) .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider img {
    height: 1000px;
    max-width: 100%;
  }
}
@media (min-width: 1025px) {
  body:not(.personalised-gifting-page) .pdp-main-container.advent-main-pdp-block .pdp-inner-wrapper .images-slider img {
    max-height: 900px;
    max-width: 100%;
  }
}
.pdp-main-container.advent-main-pdp-block .advent-swatches {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .advent-swatches {
    padding-top: 0;
  }
}
.pdp-main-container.advent-main-pdp-block .advent-wrapper-bg {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item {
  width: 95px;
  height: 120px;
  border: 1px solid #d8d8d8;
  margin: 0 8px;
  position: relative;
  transition: border-color 0.1s cubic-bezier(0.33, 0.16, 0.15, 0.7);
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item iframe,
.pdp-main-container.advent-main-pdp-block .advent-swatch-item object,
.pdp-main-container.advent-main-pdp-block .advent-swatch-item embed,
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-element {
  mask-image: linear-gradient(transparent 18%, #000000 18%);
  z-index: 1;
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .advent-swatch-item:hover {
    border-color: #1a1919;
  }
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item.advent-premium-swatch .advent-wrapper-bg {
  background-color: transparent;
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item.advent-premium-swatch .video-holder .responsive-video::after {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  height: 102px;
  left: 0;
  position: absolute;
  width: 100%;
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .video-controls_bottom-right {
  display: none;
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video iframe,
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video object,
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video embed,
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video .video-element {
  max-height: 150px;
  transform: translate(-50%, calc(-50% + 5px));
}
@media (max-width: 767px) {
  .pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video iframe,
  .pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video object,
  .pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video embed,
  .pdp-main-container.advent-main-pdp-block .advent-swatch-item .video-holder .responsive-video .video-element {
    height: 18vh;
  }
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-item .dm-responsive-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: contain;
}
.pdp-main-container.advent-main-pdp-block .advent-swatch-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pdp-main-container.advent-main-pdp-block .advent-selected-swatch {
  border-color: #1a1919;
}
.pdp-main-container.advent-main-pdp-block .link--large {
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0.3px;
  color: #a88b6b;
}
@media (min-width: 768px) {
  .pdp-main-container.advent-main-pdp-block .link--large {
    letter-spacing: 0.4px;
    font-size: 14px;
    line-height: 24px;
  }
}

.b2b-contact-from {
  padding: 88px 0 56px;
  position: relative;
}
@media (min-width: 768px) {
  .b2b-contact-from.inner-wrapper-small {
    flex-direction: column;
  }
}
.b2b-contact-from .form-header {
  margin-bottom: 64px;
}
@media (max-width: 767px) {
  .b2b-contact-from .form-header {
    margin-bottom: 52px;
    padding: 0 24px;
  }
}
.b2b-contact-from .form-header h1 {
  color: #1a1919;
  letter-spacing: 0;
  line-height: 56px;
  text-transform: none;
}
@media (max-width: 767px) {
  .b2b-contact-from .form-header h1 {
    font-size: 32px;
    line-height: 40px;
    margin: 0 auto 16px auto;
    width: 75%;
  }
}
.b2b-contact-from .form-header p {
  color: #626262;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-bottom: 0;
}
.b2b-contact-from .b2b-inputs {
  display: flex;
  gap: 24px;
}
@media (max-width: 767px) {
  .b2b-contact-from .b2b-inputs {
    flex-direction: column;
    padding: 0 24px;
  }
}
.b2b-contact-from .b2b-inputs:not(:last-of-type) {
  margin-bottom: 24px;
}
.b2b-contact-from .b2b-inputs label {
  letter-spacing: 0.4px;
  line-height: 24px;
}
.b2b-contact-from .b2b-inputs input, .b2b-contact-from .b2b-inputs select {
  border-radius: 0;
  letter-spacing: 0.6;
  padding: 12px 16px;
}
.b2b-contact-from .b2b-inputs input::placeholder, .b2b-contact-from .b2b-inputs select::placeholder {
  color: #626262;
  font-style: italic;
  letter-spacing: 0.6px;
  line-height: 24px;
}
.b2b-contact-from .b2b-inputs input.error, .b2b-contact-from .b2b-inputs select.error {
  border-color: #ad4141 transparent transparent;
  width: calc(100% - 40px);
}
.b2b-contact-from .b2b-inputs input.focus::placeholder, .b2b-contact-from .b2b-inputs select.focus::placeholder {
  color: transparent;
}
.b2b-contact-from .b2b-inputs select {
  color: #626262;
}
.b2b-contact-from .b2b-inputs select.error {
  color: #626262;
}
.b2b-contact-from .b2b-inputs .input-textarea {
  height: 105px;
  order: 2;
}
@media (min-width: 1024px) {
  .b2b-contact-from .b2b-inputs .selectric-wrapper {
    max-width: calc(50% - 12px);
  }
}
.b2b-contact-from .b2b-inputs .selectric-wrapper:not(.selectric-wrapper.selectric-open) {
  z-index: 0;
}
.b2b-contact-from .b2b-inputs .selectric-wrapper .error ~ .selectric {
  border-color: #ad4141 transparent transparent;
  width: calc(100% - 40px);
}
.b2b-contact-from .b2b-inputs .selectric-wrapper .selectric span {
  color: #626262;
}
.b2b-contact-from .b2b-inputs .inputfield-row span {
  color: #1a1919;
}
.b2b-contact-from .b2b-inputs .inputfield-row span.error:not(.hide) {
  color: #ad4141;
  text-align: left;
}
.b2b-contact-from .b2b-inputs .inputfield-row span.error:not(.hide)::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea30";
  display: inline-block;
  font-size: 32px;
  position: absolute;
  right: -9px;
  top: 50%;
}
.b2b-contact-from .b2b-inputs .inputfield-row .selectric-wrapper span.error:not(.hide)::after {
  top: 30%;
}
.b2b-contact-from .b2b-inputs .button-wrapper.inputfield-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .b2b-contact-from .b2b-inputs .button-wrapper.inputfield-row {
    flex-direction: column;
    gap: 24px;
  }
}
.b2b-contact-from .b2b-inputs .button-wrapper.inputfield-row .btn {
  padding: 16px 64px;
}
@media (max-width: 767px) {
  .b2b-contact-from .b2b-inputs .button-wrapper.inputfield-row .btn {
    max-width: none;
  }
}
.b2b-contact-from .b2b-inputs .char-count {
  display: none;
}

.b2b-signup-complete {
  background-color: #efedea;
  bottom: 0;
  opacity: 0;
  left: 0;
  margin-top: 64px;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: -1;
}
.b2b-signup-complete.show {
  opacity: 1;
  z-index: 0;
}
@media (min-width: 768px) {
  .b2b-signup-complete {
    flex-direction: column;
  }
}
.b2b-signup-complete h1 {
  color: #1a1919;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.25px;
  text-transform: none;
}
@media (max-width: 767px) {
  .b2b-signup-complete h1 {
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .b2b-signup-complete h1 {
    font-size: 36px;
    letter-spacing: 1.8px;
    line-height: 48px;
  }
}
.b2b-signup-complete p {
  line-height: 24px;
}
.b2b-signup-complete p:last-child:not(:only-child) {
  margin-top: 60px;
}
.b2b-signup-complete .progress-steps {
  padding: 40px 0 64px 0;
}
@media (max-width: 767px) {
  .b2b-signup-complete .progress-steps .step-tile {
    width: 120px;
  }
}
@media (min-width: 768px) {
  .b2b-signup-complete .progress-steps .step-tile {
    width: 170px;
  }
}
.b2b-signup-complete .progress-steps .step-tile:nth-child(2) .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea72";
}
.b2b-signup-complete .progress-steps .step-tile:nth-child(3) .step__number .circle:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea4d";
}
.b2b-signup-complete .progress-steps .step-tile .step__number.active .circle {
  border-color: #886655;
}
.b2b-signup-complete .progress-steps .step-tile .step__number.active .circle::before {
  color: #ffffff;
}
.b2b-signup-complete .progress-steps .step-tile .step__number .circle {
  border-color: #7f7f7f;
  font-size: 12px;
  height: 36px;
  width: 36px;
}
.b2b-signup-complete .progress-steps .step-tile .step__number .circle::before {
  color: #7f7f7f;
}
.b2b-signup-complete .progress-steps .step-tile .step__text--title {
  color: #626262;
  font-size: 14px;
}
.b2b-signup-complete .confirmation-note,
.b2b-signup-complete .confirmation-note a {
  font-size: 14px;
}

.filter-module {
  background-color: #ffffff;
}
.filter-module__wrapper {
  margin: 0 auto;
  max-width: 1320px;
  padding: 48px 0;
}
@media (max-width: 1023px) {
  .filter-module__wrapper {
    padding: 42px 0;
  }
}
.filter-module__box {
  width: calc(33.33% - 16px);
}
.filter-module__container {
  display: flex;
  justify-content: space-between;
}
.filter-module__container.two-tiles .filter-module__box {
  width: calc(50% - 16px);
}
.filter-module__title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 48px;
  text-transform: none;
}
@media (max-width: 1023px) {
  .filter-module__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 22px;
    text-align: center;
  }
}
.filter-module__box-title {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 8px;
  text-transform: none;
}
@media (max-width: 1023px) {
  .filter-module__box-title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
  }
}
.filter-module__box-link {
  border: 1px solid rgba(26, 25, 25, 0.16);
  color: #1a1919;
  cursor: pointer;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  margin-top: 48px;
  padding: 16px 32px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .filter-module__box-link {
    border: none;
    margin-top: 0;
  }
}
.filter-module__box-link::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea1b";
  display: none;
  font-size: 26px;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1023px) {
  .filter-module__box-link::after {
    display: block;
  }
}
.filter-module__box-paragraph {
  color: #626262;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4;
  line-height: 24px;
  margin: 0 auto;
  width: 65%;
}
@media (max-width: 1023px) {
  .filter-module__box-paragraph {
    width: 100%;
  }
}
.filter-module__image {
  height: 480px;
}
@media (max-width: 1023px) {
  .filter-module__image {
    height: 200px;
  }
}
.filter-module__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.filter-module__box-content {
  margin-top: 22px;
  text-align: center;
}
@media (max-width: 1023px) {
  .filter-module__box-content {
    margin-top: 8px;
  }
}
@media (max-width: 1023px) {
  .filter-module .slick-list {
    margin: 0 -5px;
    padding: 0 25px 0 25px;
  }
}
@media (max-width: 1023px) {
  .filter-module .slick-slide {
    margin: 0 5px;
  }
}

.collection-cards-block {
  overflow: hidden;
  padding: 31px 5px 35px;
  position: relative;
}
.collection-cards-block .slick-arrow {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.collection-cards-block .slick-list {
  overflow: visible;
}
.collection-cards-block .slick-track {
  display: flex;
}
.collection-cards-block .slick-slide {
  display: flex;
  height: auto;
  padding: 0 5px;
}
.collection-cards-block .slick-slide > div {
  display: flex;
  width: 100%;
}
.collection-cards-block .collection-cards {
  width: calc(100% + 50px);
  margin: 0 -25px;
}
@media (max-width: 767px) {
  .collection-cards-block .collection-cards .card-item:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
.collection-cards-block .collection-cards-slider .card-item {
  box-shadow: 0 3px 25px 3px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .collection-cards-block .collection-cards-slider .card-item + .card-item {
    display: none;
  }
}
.collection-cards-block .card-item {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
}
.collection-cards-block .card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.collection-cards-block .text-holder {
  background-color: #ffffff;
  padding: 24px 25px 21px;
  position: relative;
}
.collection-cards-block .text-holder h3 {
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  margin-bottom: 12px;
  text-transform: none;
}
.collection-cards-block .text-holder p {
  line-height: 24px;
  margin-bottom: 11px;
  letter-spacing: 0.5px;
}
.collection-cards-block .image-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
}
@media (min-width: 768px) {
  .collection-cards-block {
    padding: 50px 0 0;
  }
  .collection-cards-block .inner-wrapper {
    max-width: 1360px;
  }
  .collection-cards-block .collection-cards,
  .collection-cards-block .collection-cards-slider,
  .collection-cards-block .simplebar-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .collection-cards-block .collection-cards .card-item,
  .collection-cards-block .collection-cards-slider .card-item,
  .collection-cards-block .simplebar-content .card-item {
    box-shadow: none;
    margin-bottom: 40px;
    width: 47.7%;
  }
  .collection-cards-block .collection-cards {
    width: auto;
    margin: 0;
  }
  .collection-cards-block .text-holder {
    padding: 41px 40px 31px;
    transition: padding 0.3s ease;
  }
  .collection-cards-block .text-holder h3 {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin-bottom: 21px;
  }
  .collection-cards-block .text-holder p {
    margin: 0 auto 16px;
    max-width: 388px;
    letter-spacing: 1px;
    line-height: 28px;
  }
  .collection-cards-block .text-holder .btn {
    letter-spacing: 2.6px;
    min-width: 10px;
  }
  .collection-cards-block .image-holder {
    height: 300px;
    transition: height 0.3s ease;
  }
  .collection-cards-block .slider-bar {
    display: none !important;
  }
  .collection-cards-block .slider-dots {
    display: none;
  }
}
@media (min-width: 1024px) {
  .collection-cards-block .image-holder {
    height: 420px;
  }
}

@media (max-width: 767px) {
  .zoomed-in .collection-cards-block .slider-dots {
    bottom: 55px;
    display: block;
  }
}

@media (max-width: 767px) {
  .collection-cards-block.drag-list {
    padding: 0;
  }
  .collection-cards-block.drag-list .inner-wrapper {
    padding: 0;
  }
  .collection-cards-block.drag-list .drag-list-body {
    padding: 31px 25px 35px;
  }
  .collection-cards-block.drag-list .drag-list-body .card-item {
    opacity: 1;
  }
  .collection-cards-block.drag-list .card-item {
    background-color: transparent;
    box-shadow: none;
    opacity: 0;
    padding: 0 5px;
    width: calc(100vw - 40px);
  }
  .collection-cards-block.drag-list .card-item + .card-item {
    display: block;
  }
  .collection-cards-block.drag-list .card-inner {
    background-color: #ffffff;
    box-shadow: 0 3px 25px 3px rgba(0, 0, 0, 0.1);
  }
}

.collection-description-block {
  background-color: #f6f4f2;
  padding: 60px 0 30px;
  text-align: center;
}
.collection-description-block img {
  margin-bottom: 25px;
}
.collection-description-block h2 {
  font-size: 30px;
  letter-spacing: 8px;
}
.collection-description-block p {
  line-height: 28px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .collection-description-block {
    padding: 101px 0 50px;
  }
  .collection-description-block .inner-wrapper {
    opacity: 0;
    transition: opacity 1s ease;
  }
  .collection-description-block h2 {
    margin: 0 auto 20px;
    max-width: 800px;
  }
  .collection-description-block p {
    margin: 0 auto 30px;
    max-width: 650px;
  }
  .collection-description-block.fadein .inner-wrapper {
    opacity: 1;
  }
}

.pt_collectionpage,
.pt_collectionpage_transparent {
  background-color: #efedea;
}

.collection-gallery {
  display: flex;
  padding-top: 40px;
  width: 100%;
}
.collection-gallery .inner-wrapper {
  max-width: 1320px;
}
.collection-gallery .item {
  float: left;
  margin-bottom: 55px;
  width: 100%;
}
.collection-gallery .item-small-size {
  width: 87.32%;
}
.collection-gallery .item-right-indented-aligned,
.collection-gallery .item-right-aligned {
  float: right;
}
.collection-gallery .image-holder {
  display: block;
  margin-bottom: 8px;
  width: 100%;
}
.collection-gallery .image-holder img {
  display: block;
  width: 100%;
}
.collection-gallery .text-holder {
  width: 100%;
}
.collection-gallery .text-holder.text-right-aligned {
  text-align: right;
}
.collection-gallery .text-holder.text-center-aligned {
  text-align: center;
}
.collection-gallery .subtitle {
  color: #af9854;
  font-size: 12px;
  letter-spacing: 0.7px;
  line-height: 15px;
  margin-bottom: 2px;
  text-transform: none;
}
.collection-gallery .title {
  font-style: italic;
  font-size: 12px;
  letter-spacing: 0.7px;
  line-height: 15px;
  margin-bottom: 0;
  text-transform: none;
}
.collection-gallery .title a {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
}
.collection-gallery .details {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  line-height: 15px;
}
@media (max-width: 767px) {
  .collection-gallery .inner-wrapper {
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  .collection-gallery {
    padding-top: 80px;
  }
  .collection-gallery .item {
    margin-bottom: 60px;
  }
  .collection-gallery .item-small-size {
    width: 29.296875%;
  }
  .collection-gallery .item-mid-size {
    width: 46.875%;
  }
  .collection-gallery .item-large-size {
    width: 62.5%;
  }
  .collection-gallery .item-right-indented-aligned {
    margin-right: 8.203125%;
  }
  .collection-gallery .item-right-mid-aligned {
    float: right;
    margin-right: 17.578125%;
  }
  .collection-gallery .image-holder {
    margin-bottom: 11px;
  }
  .collection-gallery .subtitle {
    font-size: 14px;
    letter-spacing: 0.9px;
    line-height: 17px;
  }
  .collection-gallery .title {
    font-size: 14px;
    letter-spacing: 0.9px;
    line-height: 18px;
    margin-bottom: 3px;
  }
  .collection-gallery .details {
    display: block;
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 17px;
  }
}

.collection-hero-banner {
  display: flex;
  position: relative;
  width: 100%;
  height: 70vh;
  max-height: 760px;
}
.collection-hero-banner .media-holder {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
}
.collection-hero-banner[class*=in-viewport] {
  overflow: hidden;
}
.collection-hero-banner.in-viewport .media-holder {
  transform: scale(1);
}
.collection-hero-banner.not-in-viewport .media-holder {
  transform: scale(1.2);
}
.collection-hero-banner.collection-hero-banner-small {
  height: 80vh;
}
.collection-hero-banner.collection-hero-banner-small .the-ritual-of-title {
  text-transform: none;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0;
  margin-bottom: 10px;
}
.collection-hero-banner.collection-hero-banner-small h2 {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1.1px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: none;
  margin-bottom: 30px;
}
.collection-hero-banner.txt--black .banner-content,
.collection-hero-banner.txt--black .the-ritual-of-title {
  color: #1a1919;
}
.collection-hero-banner .banner-content {
  align-items: center;
  color: #ffffff;
  display: flex;
  padding: 96px 0 83px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 3;
}
.collection-hero-banner .inner-indented {
  margin: 0 auto;
}
.collection-hero-banner .the-ritual-of-title {
  color: #ffffff;
  font-size: 37px;
  letter-spacing: 9px;
  line-height: 43px;
  margin-bottom: 7px;
}
.collection-hero-banner .the-ritual-of-title span {
  display: block;
  font-size: 18px;
  letter-spacing: 4.5px;
  line-height: 30px;
}
.collection-hero-banner h2 {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: 2.45px;
  line-height: 20px;
  margin: 0 auto 41px;
  max-width: 320px;
}
.collection-hero-banner .icon-holder {
  bottom: 100px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}
.collection-hero-banner .icon-holder img {
  max-width: 48px;
}
@media (max-width: 767px) {
  .collection-hero-banner.txt--black::after {
    background: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
    bottom: 0;
    content: "";
    height: 134px;
    left: 0;
    position: absolute;
    right: 0;
  }
  .collection-hero-banner.txt--black .the-ritual-of-title {
    font-size: 30px;
    letter-spacing: 9px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .collection-hero-banner {
    height: 75vh;
  }
  .collection-hero-banner .inner-indented {
    max-width: 40%;
  }
  .collection-hero-banner.collection-hero-banner-small {
    height: 75vh;
    max-height: 480px;
  }
  .collection-hero-banner.collection-hero-banner-small .the-ritual-of-title {
    font-size: 54px;
    line-height: 58px;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }
  .collection-hero-banner.collection-hero-banner-small h2 {
    font-size: 24px;
    line-height: 44px;
    letter-spacing: 0.46px;
    margin-bottom: 28px;
  }
  .collection-hero-banner .banner-content {
    padding: 210px 0;
  }
  .collection-hero-banner .the-ritual-of-title {
    font-size: 54px;
    line-height: 58px;
    margin: 0 0 22px;
  }
  .collection-hero-banner .the-ritual-of-title span {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
    margin-bottom: 12px;
  }
  .collection-hero-banner h2 {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 25px;
    margin: 0 auto 35px;
    max-width: none;
  }
  .collection-hero-banner .icon-holder {
    bottom: 30px;
  }
  .collection-hero-banner .icon-holder img {
    max-width: 60px;
  }
}
@media (min-width: 1024px) {
  .collection-hero-banner {
    max-height: 600px;
  }
}

.collection-hero-banner-limited h3 {
  font-size: 14px;
  letter-spacing: 3px;
  line-height: 18px;
}
.collection-hero-banner-limited .hero-video-holder {
  margin-top: 20px;
  width: 100%;
}
.collection-hero-banner-limited .btn-content-video {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .collection-hero-banner-limited .the-ritual-of-title {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .collection-hero-banner-limited h2 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .collection-hero-banner-limited .inner-wrapper {
    max-width: 1070px;
  }
  .collection-hero-banner-limited h3 {
    margin-bottom: 35px;
  }
  .collection-hero-banner-limited .the-ritual-of-title {
    letter-spacing: 8px;
    margin: 0 0 30px;
  }
  .collection-hero-banner-limited h2 {
    margin: 0 auto 30px;
  }
  .collection-hero-banner-limited .hero-video-holder {
    margin-top: 60px;
  }
}

@media (min-width: 768px) {
  .banner-left-aligned-desktop .inner-indented {
    float: left;
    padding: 0 0 0 10px;
  }
}

@media (min-width: 768px) {
  .banner-right-aligned-desktop .inner-indented {
    float: right;
    padding: 0 10px 0 0;
  }
}

@media (min-width: 768px) {
  .banner-text-left-aligned-desktop h2 {
    margin-left: 0;
  }
  .banner-text-left-aligned-desktop .inner-indented {
    float: left;
    text-align: left;
    padding: 0 0 0 10px;
  }
}

@media (min-width: 768px) {
  .banner-text-right-aligned-desktop .inner-indented {
    text-align: right;
    float: right;
    padding: 0 10px 0 0;
  }
}

@media (min-width: 768px) {
  .banner-top-aligned-desktop .banner-content {
    align-items: flex-start;
    padding: 85px 0;
  }
}

@media (min-width: 768px) {
  .banner-bottom-aligned-desktop .banner-content {
    align-items: flex-end;
    padding: 85px 0;
  }
}

@media (max-width: 767px) {
  .banner-text-left-aligned-mobile .inner-indented {
    float: left;
    text-align: left;
    padding: 0 0 0 10px;
  }
}

@media (max-width: 767px) {
  .banner-text-right-aligned-mobile .inner-indented {
    text-align: right;
    float: right;
    padding: 0 10px 0 0;
  }
}

@media (max-width: 767px) {
  .banner-top-aligned-mobile .banner-content {
    align-items: flex-start;
    padding: 60px 0;
  }
}

@media (max-width: 767px) {
  .banner-bottom-aligned-mobile .banner-content {
    align-items: flex-end;
    padding: 60px 0;
  }
}

.collection-fifth-template .collection-hero-banner.collection-hero-banner-limited .inner-indented {
  max-width: none;
}
.collection-fifth-template .collection-hero-banner.collection-hero-banner-limited .banner-content {
  padding: 100px 0;
}
@media (min-width: 768px) {
  .collection-fifth-template .collection-hero-banner.collection-hero-banner-limited h3 {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}
.collection-fifth-template .collection-hero-banner.collection-hero-banner-limited .hero-video-holder {
  margin-top: 30px;
}

.collection-highlight-block {
  padding: 90px 0 60px;
  text-align: center;
}
@media (min-width: 768px) {
  .collection-highlight-block {
    align-items: stretch;
    display: flex;
    padding: 0;
  }
}
.collection-highlight-block.txt--black .column-inner *:not(.btn) {
  color: #1a1919;
}
.collection-highlight-block.txt--white .column-inner *:not(.btn) {
  color: #ffffff;
}
.collection-highlight-block.txt--red .column-inner *:not(.btn) {
  color: #d6122d;
}

.collection-highlight-column {
  width: 100%;
}
.collection-highlight-column .image-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 76.27%;
  width: 100%;
}
.collection-highlight-column.image-column {
  display: none;
}
.collection-highlight-column.text-column {
  padding: 0 20px;
}
.collection-highlight-column h2 {
  color: #000000;
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  text-transform: none;
  margin-bottom: 22px;
}
.collection-highlight-column .read-time {
  color: #000000;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 13px;
  letter-spacing: 4px;
  line-height: 1.2;
  margin-bottom: 34px;
  padding-left: 28px;
  position: relative;
  text-transform: uppercase;
}
.collection-highlight-column .read-time::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea67";
  display: inline-block;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 2px;
}
.collection-highlight-column .mobile-image-placeholder {
  margin: 0 0 42px -20px;
  width: calc(100% + 40px);
}
.collection-highlight-column p {
  line-height: 28px;
  margin-bottom: 35px;
}
.collection-highlight-column .product-options .swatches-wrapper,
.collection-highlight-column .product-variations .swatches-wrapper {
  justify-content: center;
}
@media (min-width: 768px) {
  .collection-highlight-column {
    min-height: 80vh;
    width: 50%;
  }
  .collection-highlight-column .mobile-image-placeholder {
    display: none;
  }
  .collection-highlight-column.image-column {
    display: block;
    position: relative;
  }
  .collection-highlight-column.image-column .image-holder {
    bottom: 0;
    height: auto;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .collection-highlight-column.text-column {
    align-items: center;
    display: flex;
    max-width: 720px;
    padding: 0;
  }
  .collection-highlight-column.text-column.invert {
    margin-left: auto;
    order: -1;
  }
  .collection-highlight-column.text-column .column-inner {
    margin: 0 auto;
    max-width: 490px;
    padding: 0 20px;
    width: 100%;
  }
  .collection-highlight-column h2 {
    margin-bottom: 14px;
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
  }
  .collection-highlight-column .read-time {
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 26px;
    padding-left: 19px;
    text-transform: none;
  }
  .collection-highlight-column .read-time::before {
    font-size: 14px;
    top: 2px;
  }
  .collection-highlight-column p {
    margin-bottom: 30px;
  }
}
.collection-highlight-column .product-tile .product-details-content {
  margin: 0 auto;
  padding: 20px;
}
.collection-highlight-column .product-tile .the-ritual-of-title {
  display: none;
}
.collection-highlight-column .product-tile .product-name-link {
  display: block;
  pointer-events: none;
  text-decoration: none;
}
.collection-highlight-column .product-tile .product-name {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 2.63px;
  pointer-events: none;
}
.collection-highlight-column .product-tile .short-description {
  margin: -5px 0 10px;
}
@media (min-width: 768px) {
  .collection-highlight-column .product-tile .short-description {
    margin: -13px 0 15px;
  }
}

.collection-narrow-highlight-block {
  width: 100%;
}
.collection-narrow-highlight-block .media-column {
  position: relative;
  width: 100%;
}
.collection-narrow-highlight-block .image-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.collection-narrow-highlight-block .text-column {
  background-color: #ffffff;
  padding: 40px 30px;
  text-align: center;
  width: 100%;
}
.collection-narrow-highlight-block h3 {
  color: #886655;
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 18px;
  margin-bottom: 10px;
}
.collection-narrow-highlight-block h2 {
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 44px;
  margin-bottom: 22px;
  text-transform: none;
}
.collection-narrow-highlight-block p {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 20px;
}
.collection-narrow-highlight-block .buttons-wrap a {
  display: inline-block;
  font-size: 0;
  line-height: normal;
  vertical-align: top;
}
@media (max-width: 767px) {
  .collection-narrow-highlight-block {
    margin-bottom: 30px;
  }
  .collection-narrow-highlight-block.hide-mobile-image .media-column {
    display: none;
  }
  .collection-narrow-highlight-block .inner-wrapper {
    padding: 0;
  }
  .collection-narrow-highlight-block .media-column {
    height: 0;
    padding-bottom: 80%;
  }
}
@media (min-width: 768px) {
  .collection-narrow-highlight-block {
    margin: 30px 0 60px;
  }
  .collection-narrow-highlight-block .inner-wrapper {
    display: flex;
    max-width: 1360px;
  }
  .collection-narrow-highlight-block .media-column {
    width: 50%;
  }
  .collection-narrow-highlight-block .text-column {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 560px;
    padding: 30px;
    width: 50%;
  }
  .collection-narrow-highlight-block .column-inner {
    max-width: 380px;
    width: 100%;
  }
  .collection-narrow-highlight-block h3 {
    margin-bottom: 0;
  }
  .collection-narrow-highlight-block h2 {
    margin-bottom: 12px;
  }
  .collection-narrow-highlight-block .action-boxes {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding-top: 9px;
  }
  .collection-narrow-highlight-block .col {
    border-right: 1px solid #999999;
    padding: 10px 10px 5px;
    width: 50%;
  }
  .collection-narrow-highlight-block .col:nth-child(2n) {
    border-right: 0;
  }
  .collection-narrow-highlight-block .img-wrap {
    margin: 0 auto;
    max-width: 100px;
  }
  .collection-narrow-highlight-block .img-wrap img {
    display: block;
    max-width: 100%;
  }
  .collection-narrow-highlight-block .buttons-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .collection-narrow-highlight-block .buttons-wrap a {
    margin-bottom: 10px;
  }
  .collection-narrow-highlight-block .buttons-wrap a:last-child {
    margin-bottom: 0;
  }
  .collection-narrow-highlight-block .image-label {
    display: block;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1px;
    line-height: 18px;
    margin-top: 10px;
  }
}
@media (min-width: 1024px) {
  .collection-narrow-highlight-block .text-column {
    padding: 50px;
  }
  .collection-narrow-highlight-block .col {
    padding: 10px 30px 5px;
  }
}

.collection-ingredients {
  width: 100%;
}
.collection-ingredients .collection-ingredients-fullwidth,
.collection-ingredients .collection-ingredients-cols-holder {
  background-color: #ffffff;
  width: 100%;
}
.collection-ingredients img {
  max-width: 100%;
}
.collection-ingredients.txt--red h2,
.collection-ingredients.txt--red h3,
.collection-ingredients.txt--red p, .collection-ingredients.txt--white h2,
.collection-ingredients.txt--white h3,
.collection-ingredients.txt--white p {
  color: inherit !important;
}
.collection-ingredients .collection-ingredients-fullwidth {
  padding: 66px 17px 5px;
}
.collection-ingredients .collection-ingredients-fullwidth h2 {
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
  margin-bottom: 18px;
}
.collection-ingredients .collection-ingredients-fullwidth p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 30px;
}
.collection-ingredients.open .collection-ingredients-row.buttons-holder .btn-view-all {
  display: flex;
}
.collection-ingredients.open .collection-ingredients-row.buttons-holder .btn-expand .more {
  display: none;
}
.collection-ingredients.open .collection-ingredients-row.buttons-holder .btn-expand .less {
  display: inline-block;
}
@media (min-width: 768px) {
  .collection-ingredients .collection-ingredients-fullwidth,
  .collection-ingredients .collection-ingredients-cols-holder {
    background-color: transparent;
  }
  .collection-ingredients .collection-ingredients-fullwidth {
    padding: 101px 0 38px;
    text-align: center;
  }
  .collection-ingredients .collection-ingredients-fullwidth .inner-wrapper {
    max-width: 780px;
  }
  .collection-ingredients .collection-ingredients-fullwidth h2 {
    margin-bottom: 13px;
  }
}

.collection-ingredients-header {
  max-width: 760px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}
.collection-ingredients-header h3 {
  font-size: 15px;
  letter-spacing: 5px;
}
.collection-ingredients-header p {
  font-size: 18px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 767px) {
  .collection-ingredients-header {
    background-color: #ffffff;
  }
}

.collection-ingredients-image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection-ingredients-list-wrapper {
  padding: 20px 0;
  color: #ffffff;
  background-color: #1c0826;
}
@media (min-width: 768px) {
  .collection-ingredients-list-wrapper {
    width: 50%;
    padding: 60px 77px;
  }
}

.collection-ingredients-content h3 {
  margin-bottom: 40px;
  font-size: 32px;
  text-transform: initial;
}
@media (max-width: 767px) {
  .collection-ingredients-content h3 {
    font-size: 24px;
    text-align: center;
  }
  .collection-ingredients-content .drag-list {
    margin-left: 40px;
  }
  .collection-ingredients-content .drag-list-body {
    padding: 0 40px 0 0;
  }
  .collection-ingredients-content .simplebar-track {
    margin-left: 0;
    margin-right: 75px;
  }
}

.collection-ingr-col {
  width: 82vw;
  margin-right: 20px;
}

.collection-ingredients-list {
  list-style: none;
}
.collection-ingredients-list li {
  min-height: 87px;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.collection-ingredients-list h4 {
  font-size: 14px;
}
.collection-ingredients-list span {
  font-family: "ClassGarmnd BT Italic", "Times New Roman", serif;
  font-style: italic;
}
@media (max-width: 767px) {
  .collection-ingredients-list .collection-ingredients-li:last-child {
    border-bottom: initial;
  }
}
@media (min-width: 768px) {
  .collection-ingredients-list .simplebar-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .collection-ingredients-list .simplebar-content::before, .collection-ingredients-list .simplebar-content::after {
    display: none;
  }
  .collection-ingredients-list li {
    width: 45%;
  }
  .collection-ingredients-list li:last-child, .collection-ingredients-list li:nth-last-child(2) {
    border-bottom: initial;
  }
}

.collection-ingredients-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.collection-ingredients-row.image-holder .collection-ingredients-column {
  width: 50%;
}
.collection-ingredients-row.text-holder {
  padding: 19px 40px;
}
.collection-ingredients-row.text-holder .collection-ingredients-column {
  border-top: 1px solid #d8d8d8;
  display: none;
  margin-top: 20px;
  padding-top: 40px;
}
.collection-ingredients-row.text-holder .collection-ingredients-column:first-child {
  border-top: 0;
  display: block;
  margin-top: 0;
  padding-top: 0;
}
.collection-ingredients-row.text-holder h3 {
  color: #000000;
  font-size: 15px;
  letter-spacing: 5px;
  line-height: 23px;
  margin-bottom: 17px;
}
.collection-ingredients-row.text-holder p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 30px;
}
.collection-ingredients-row .collection-ingredients-column {
  width: 100%;
}
.collection-ingredients-row.buttons-holder {
  flex-direction: column;
  padding: 7px 40px 39px;
  text-align: center;
}
.collection-ingredients-row.buttons-holder .btn-view-all {
  display: none;
  margin: 0 auto 10px;
  text-align: center;
  text-decoration: none;
}
.collection-ingredients-row.buttons-holder .btn-expand {
  margin: 0 auto;
}
.collection-ingredients-row.buttons-holder .btn-expand .less {
  display: none;
}
@media (min-width: 768px) {
  .collection-ingredients-row.image-holder .collection-ingredients-column {
    align-items: center;
    display: flex;
    padding-top: 65px;
    text-align: center;
  }
  .collection-ingredients-row.text-holder {
    padding: 0;
  }
  .collection-ingredients-row.text-holder .collection-ingredients-column {
    border-top: 0;
    display: block;
    margin-top: 0;
    padding: 0 0 76px;
    width: 50%;
  }
  .collection-ingredients-row.text-holder .column-inner {
    padding: 0 20px;
  }
  .collection-ingredients-row.text-holder .column-inner-centered {
    max-width: 490px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .collection-ingredients-row .collection-ingredients-column {
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 1s ease;
  }
  .collection-ingredients-row .collection-ingredients-column.col-fadein {
    opacity: 1;
  }
  .collection-ingredients-row .collection-ingredients-column .column-inner {
    float: right;
    width: 100%;
    max-width: 720px;
  }
  .collection-ingredients-row .collection-ingredients-column .column-inner-centered {
    width: 100%;
    text-align: left;
  }
  .collection-ingredients-row .collection-ingredients-column .column-inner-centered img {
    max-width: 520px;
    width: 100%;
  }
  .collection-ingredients-row .collection-ingredients-column:nth-child(2n) {
    justify-content: flex-start;
  }
  .collection-ingredients-row .collection-ingredients-column:nth-child(2n) .column-inner {
    float: left;
  }
  .collection-ingredients-row.buttons-holder {
    display: none;
  }
}

@media (max-width: 767px) {
  .collection-ingredients-limited {
    padding-top: 0;
  }
  .collection-ingredients-limited.open .collection-ingredients-row.buttons-holder .btn-expand {
    border: 1px solid #b4b4b4;
  }
}
@media (min-width: 768px) {
  .collection-ingredients-limited .collection-ingredients-fullwidth {
    padding-top: 163px;
  }
  .collection-ingredients-limited .collection-ingredients-fullwidth .inner-wrapper {
    max-width: 1170px;
  }
  .collection-ingredients-limited .collection-ingredients-fullwidth h2 {
    margin-bottom: 18px;
  }
}

.concept-highlight-block {
  padding: 26px 0 48px;
  width: 100%;
}
.concept-highlight-block .slick-dots {
  font-size: 0;
  list-style: none;
  text-align: center;
}
.concept-highlight-block .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.concept-highlight-block .slick-dots button {
  background-color: #d1d0ce;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 4px;
  width: 4px;
}
.concept-highlight-block .slick-dots .slick-active button {
  background-color: #453f3f;
}
.concept-highlight-block .col {
  margin-bottom: 50px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
}
.concept-highlight-block .col:last-child {
  margin-bottom: 0;
}
.concept-highlight-block .col:last-child .text-holder {
  padding-bottom: 0;
}
.concept-highlight-block .col:last-child .text-holder::after {
  display: none;
}
.concept-highlight-block .concept-highlight-heading {
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 30px;
  text-align: center;
  text-transform: none;
  margin-bottom: 32px;
}
.concept-highlight-block .concept-highlight-heading + .col-wrapper img {
  margin-top: 0;
}
.concept-highlight-block .media-holder img,
.concept-highlight-block .media-holder .ico {
  display: block;
}
.concept-highlight-block .media-holder img {
  margin: 20px auto 35px;
  max-width: 250px;
}
.concept-highlight-block .media-holder .ico {
  font-size: 60px;
  line-height: 1;
  margin: 0 auto;
}
.concept-highlight-block .text-holder {
  padding-bottom: 8px;
  position: relative;
}
.concept-highlight-block h4 {
  text-align: center;
}
.concept-highlight-block h3 {
  font-size: 16px;
  letter-spacing: 5px;
  line-height: 25px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
}
.concept-highlight-block p {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #767676;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .concept-highlight-block .media-holder ~ .text-holder {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .concept-highlight-block {
    padding: 40px 0;
  }
  .concept-highlight-block .inner-wrapper {
    max-width: 1140px;
  }
  .concept-highlight-block .col-wrapper,
  .concept-highlight-block .simplebar-content {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .concept-highlight-block .col {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
    max-width: 333px;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 10px;
  }
  .concept-highlight-block .col.visible {
    opacity: 1;
  }
  .concept-highlight-block .concept-highlight-heading {
    font-size: 24px;
    letter-spacing: 0.46px;
    line-height: 44px;
    margin-bottom: 30px;
  }
  .concept-highlight-block .media-holder {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .concept-highlight-block .media-holder .media-inner {
    display: block;
  }
  .concept-highlight-block .media-holder .media-inner .ico {
    margin: 0 0 3px;
  }
  .concept-highlight-block .text-holder {
    padding-bottom: 0;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .concept-highlight-block .col {
    max-width: 29%;
  }
  .concept-highlight-block .text-holder::after {
    right: -18%;
  }
}

@media (max-width: 767px) {
  .concept-highlight-block.drag-list {
    margin-bottom: 0;
    padding: 26px 0 55px;
  }
  .concept-highlight-block.drag-list .inner-wrapper {
    padding: 0;
  }
  .concept-highlight-block.drag-list .concept-highlight-heading {
    padding: 0 20px;
  }
  .concept-highlight-block.drag-list .drag-list-body {
    padding: 0;
  }
  .concept-highlight-block.drag-list .col {
    flex-shrink: 0;
    margin-bottom: 20px;
    padding: 0 40px;
    width: 100vw;
  }
}

@media (max-width: 767px) {
  .concept-highlight-block-easter {
    padding: 30px 0;
  }
  .concept-highlight-block-easter .slick-slide {
    text-align: center;
  }
  .concept-highlight-block-easter .col {
    max-width: 240px;
  }
}
@media (min-width: 768px) {
  .concept-highlight-block-easter .inner-wrapper {
    max-width: 820px;
  }
  .concept-highlight-block-easter .col {
    max-width: 240px;
  }
  .concept-highlight-block-easter .media-holder .media-inner .ico {
    margin: 0 0 10px;
  }
}
.concept-highlight-block-easter .media-holder img {
  margin: 0 auto 10px;
  max-width: 70px;
}
.concept-highlight-block-easter .media-holder .ico {
  font-size: 50px;
  line-height: 1;
  margin: 0 auto 10px;
}
.concept-highlight-block-easter .text-holder p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}

.concept-highlight-block-advent {
  background-color: transparent;
}
@media (max-width: 767px) {
  .concept-highlight-block-advent {
    padding: 50px 0 30px;
  }
  .concept-highlight-block-advent .slick-slide {
    text-align: center;
  }
  .concept-highlight-block-advent .col {
    max-width: 240px;
  }
}
@media (min-width: 768px) {
  .concept-highlight-block-advent {
    opacity: 0;
    padding: 100px 0;
  }
  .concept-highlight-block-advent .inner-wrapper {
    max-width: 990px;
  }
  .concept-highlight-block-advent .col {
    max-width: 240px;
  }
  .concept-highlight-block-advent .media-holder .media-inner .ico {
    margin: 0 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .concept-highlight-block-advent .inner-wrapper {
    max-width: 830px;
  }
}
.concept-highlight-block-advent .media-holder img {
  margin: 0 auto 10px;
  max-width: 90px;
}
.concept-highlight-block-advent .media-holder .ico {
  font-size: 50px;
  line-height: 1;
  margin: 0 auto 10px;
}
.concept-highlight-block-advent .text-holder p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
}

.crm-in-store .concept-highlight-block {
  padding: 20px 0 40px;
}
.crm-in-store .concept-highlight-block .inner-wrapper {
  padding: 0 24px;
}
.crm-in-store .concept-highlight-block .col-wrapper {
  display: block;
}
.crm-in-store .concept-highlight-block .col {
  margin: 0;
  opacity: 1;
  padding: 0;
}
.crm-in-store .concept-highlight-block .col h1 {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 8px;
  text-transform: initial;
}
.crm-in-store .concept-highlight-block .col p {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 40px;
}
.crm-in-store .concept-highlight-block .media-inner img {
  margin: 16px auto 16px;
  max-width: 90px;
}

.concept-highlight-block-img-aside {
  background-color: #f6f4f2;
}
.concept-highlight-block-img-aside .media-holder .ico {
  font-size: 40px;
}
@media (max-width: 767px) {
  .concept-highlight-block-img-aside .media-holder {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .concept-highlight-block-img-aside .media-holder {
    margin-right: 20px;
  }
}
.concept-highlight-block-img-aside .text-holder p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
@media (max-width: 767px) {
  .concept-highlight-block-img-aside .text-holder {
    margin: 0 auto;
    width: 70%;
  }
}
@media (min-width: 768px) {
  .concept-highlight-block-img-aside .text-holder {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .concept-highlight-block-img-aside .col {
    align-items: center;
    display: flex;
    flex-wrap: initial;
    justify-content: initial;
    text-align: initial;
  }
}

.content-banner {
  color: #1a1919;
  display: flex;
  position: relative;
  text-align: center;
  width: 100%;
  height: 70vh;
}
.content-banner .image-holder,
.content-banner .responsive-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
}
.content-banner .image-holder.align-bg-top,
.content-banner .responsive-bg.align-bg-top {
  background-position: top center;
}
.content-banner[class*=in-viewport] {
  overflow: hidden;
}
.content-banner.in-viewport .image-holder {
  transform: scale(1);
}
.content-banner.not-in-viewport .image-holder {
  transform: scale(1.2);
}
.content-banner.samples-banner p {
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: -6px 0 18px;
}
.content-banner.banner-maintenance {
  max-height: 475px;
}
@media (max-width: 767px) {
  .content-banner.banner-maintenance {
    max-height: 464px;
  }
}
.content-banner.banner-teaser {
  height: auto;
}
@media (min-width: 768px) {
  .content-banner.banner-teaser {
    min-height: 805px;
    max-height: none;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-teaser .inner-indented {
    padding: 0 40px;
  }
}
.content-banner.banner-teaser .video-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .content-banner.banner-teaser .text-holder {
    padding: 105px 0 90px;
  }
}
.content-banner.banner-teaser .heading-h3 {
  color: #ffffff;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .content-banner.banner-teaser .heading-h3 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 5px;
    margin-bottom: 40px;
  }
}
.content-banner.banner-teaser .headline {
  color: #ffffff;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: 2.25px;
  text-transform: none;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .content-banner.banner-teaser .headline {
    font-size: 80px;
    line-height: 100px;
    letter-spacing: 5px;
    margin-bottom: 45px;
  }
}
.content-banner.banner-teaser p {
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 19px;
  max-width: 650px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .content-banner.banner-teaser p {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.7px;
    margin-bottom: 10px;
  }
}
.content-banner .text-holder {
  margin: 112px 0;
  position: relative;
  width: 100%;
}
@media (max-width: 767px) {
  .content-banner .text-holder {
    padding: 30px 0 0;
  }
}
.content-banner .inner-indented {
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner .inner-indented.has-logo {
    margin: 0;
    max-width: none;
    width: 65%;
  }
}
.content-banner p {
  color: #1a1919;
}
.content-banner .btn {
  margin-top: 9px;
}
.content-banner .btn:first-of-type {
  margin-top: 20px;
}
.content-banner .ico {
  display: none;
}
@media (min-width: 1025px) {
  .content-banner .ico {
    display: inline-block;
  }
}
.content-banner.banner-white-text {
  color: #ffffff;
}
.content-banner.banner-white-text p {
  color: #ffffff;
}
.content-banner.banner-small {
  min-height: 542px;
  height: auto;
}
.content-banner.banner-small .retake-button-holder {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.content-banner.banner-small .retake-button-holder .retake-quiz-button {
  margin: 0;
  float: right;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .content-banner.banner-small .retake-button-holder {
    top: 10px;
  }
  .content-banner.banner-small .retake-button-holder .retake-quiz-button {
    text-decoration: underline;
    border: 0;
    min-width: 0;
    margin-right: 0;
    padding: 15px 5px 14px;
    background-color: transparent;
  }
  .content-banner.banner-small .retake-button-holder .retake-quiz-button:hover, .content-banner.banner-small .retake-button-holder .retake-quiz-button:active {
    background-color: transparent;
    color: #ffffff;
  }
  .content-banner.banner-small .retake-button-holder .retake-quiz-button.mobile-link--copper {
    color: #886655;
  }
  .content-banner.banner-small .retake-button-holder .retake-quiz-button.mobile-link--copper:hover, .content-banner.banner-small .retake-button-holder .retake-quiz-button.mobile-link--copper:active {
    background-color: transparent;
    color: #886655;
    text-decoration: none;
  }
}
.content-banner.banner-small .additional-logo {
  display: flex;
}
@media (max-width: 767px) {
  .content-banner.banner-small .additional-logo {
    margin: 0 auto 30px;
    max-width: 100px;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-small .additional-logo {
    height: 50%;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 230px;
  }
}
@media (min-width: 1024px) {
  .content-banner.banner-small .additional-logo {
    height: 100%;
    right: 60px;
  }
}
@media (min-width: 1160px) {
  .content-banner.banner-small .additional-logo {
    right: 140px;
  }
}
@media (min-width: 1025px) {
  .content-banner.banner-small .ico {
    display: inline-block;
    line-height: normal;
    margin-bottom: 15px;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-title-on-top {
    align-items: flex-start;
  }
  .content-banner.banner-title-on-top .text-holder {
    padding: 80px 0;
  }
}
.content-banner.banner-left-aligned .inner-indented {
  float: left;
}
@media (max-width: 767px) {
  .content-banner.banner-left-aligned-mobile .inner-indented {
    float: left;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-left-aligned-mobile .additional-logo {
    margin: 0 0 30px 20px;
  }
}
.content-banner.banner-right-aligned .inner-indented {
  float: right;
}
@media (max-width: 767px) {
  .content-banner.banner-right-aligned-mobile .inner-indented {
    float: right;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-right-aligned-mobile .additional-logo {
    margin: 0 20px 30px auto;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-right-aligned-mobile .accordion {
    text-align: right;
  }
}
.content-banner.banner-text-left-aligned .inner-indented {
  float: left;
  text-align: left;
  padding-left: 10px;
}
.content-banner.banner-text-left-aligned .btn {
  margin-left: 0;
}
.content-banner.banner-text-right-aligned .inner-indented {
  text-align: right;
  float: right;
  padding-right: 10px;
}
.content-banner.banner-text-right-aligned .btn {
  margin-right: 0;
}
.content-banner.banner-top-aligned {
  align-items: flex-start;
}
.content-banner.banner-top-aligned .text-holder {
  padding: 30px 0;
}
@media (max-width: 767px) {
  .content-banner.banner-top-aligned-mobile {
    align-items: flex-start;
  }
}
.content-banner.banner-bottom-aligned {
  align-items: flex-end;
}
.content-banner.banner-bottom-aligned .text-holder {
  padding: 30px 0 40px;
}
@media (max-width: 767px) {
  .content-banner.banner-bottom-aligned-mobile {
    align-items: flex-end;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-bottom-aligned-mobile .text-holder {
    padding: 30px 0;
  }
}
.content-banner.banner-center-aligned-desktop.banner-pagedesigner .badge, .content-banner.banner-left-aligned-desktop.banner-pagedesigner .badge, .content-banner.banner-right-aligned-desktop.banner-pagedesigner .badge {
  height: 73px;
  right: 24px;
  position: absolute;
  top: 24px;
  width: 73px;
}
.content-banner.banner-center-aligned-desktop.banner-pagedesigner .badge img, .content-banner.banner-left-aligned-desktop.banner-pagedesigner .badge img, .content-banner.banner-right-aligned-desktop.banner-pagedesigner .badge img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .content-banner.banner-center-aligned-desktop.banner-pagedesigner .content-banner-btn-wrapper, .content-banner.banner-left-aligned-desktop.banner-pagedesigner .content-banner-btn-wrapper, .content-banner.banner-right-aligned-desktop.banner-pagedesigner .content-banner-btn-wrapper {
    position: relative;
    z-index: 2;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-center-aligned-desktop.banner-pagedesigner .inner-indented {
    float: none;
    padding: 0 20px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-center-aligned-desktop.banner-pagedesigner .content-banner-btn-wrapper {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-left-aligned-desktop.banner-pagedesigner .inner-indented {
    float: left;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-right-aligned-desktop.banner-pagedesigner .inner-indented {
    float: right;
    padding: 0;
    text-align: right;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-right-aligned-desktop.banner-pagedesigner .content-banner-btn-wrapper {
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-middle-aligned-mobile {
    align-items: center;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-pagedesigner .inner-indented {
    float: left;
    text-align: left;
    padding-left: 10px;
  }
}
@media (min-width: 1024px) {
  .content-banner.banner-pagedesigner .inner-indented {
    padding-left: 40px;
  }
}
@media (min-width: 768px) {
  .content-banner.banner-pagedesigner .btn:first-of-type {
    margin-left: 0;
  }
  .content-banner.banner-pagedesigner .btn:last-of-type {
    margin-right: 0;
  }
}
.content-banner.banner-pagedesigner .btn-content-video {
  color: #1a1919;
}
.content-banner.banner-pagedesigner .accordion {
  margin-top: 20px;
}
.content-banner.banner-pagedesigner.banner-small .mobile-ico {
  display: none;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .mobile-ico {
    display: block;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 2;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder {
    padding: 0;
    margin: 30px 0 52px;
  }
}
.content-banner.banner-pagedesigner.banner-small .text-holder .heading-h3 {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .heading-h3 {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 16px;
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .heading-h3 {
    margin-bottom: 16px;
  }
}
.content-banner.banner-pagedesigner.banner-small .text-holder .heading-h1 {
  font-size: 50px;
  letter-spacing: 1.4px;
  line-height: 56px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .heading-h1 {
    font-size: 32px;
    letter-spacing: 0.6px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .heading-h1 {
    letter-spacing: 0.6px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}
.content-banner.banner-pagedesigner.banner-small .text-holder p {
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder p {
    font-size: 14px;
    letter-spacing: 0.4px;
  }
}
.content-banner.banner-pagedesigner.banner-small .text-holder .btn {
  min-width: 220px;
  padding: 16px 20px;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .btn {
    font-size: 14px;
    letter-spacing: 0.4px;
    min-width: 210px;
  }
  .content-banner.banner-pagedesigner.banner-small .text-holder .btn:first-of-type {
    margin-top: 24px;
  }
}
.content-banner.banner-pagedesigner.banner-small .text-holder .ico {
  margin-bottom: 24px;
}
.content-banner.banner-pagedesigner.banner-small .text-holder .accordion {
  margin-top: 24px;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .accordion {
    margin: 24px auto 0;
    max-width: 270px;
  }
}
.content-banner.banner-pagedesigner.banner-small .text-holder .accordion .acc-content-item {
  line-height: 14px;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small .text-holder .accordion .acc-content-item {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small.banner-center-aligned-mobile .accordion {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner.banner-small.banner-center-aligned-mobile .inner-wrapper {
    padding: 0 24px;
  }
}
.content-banner.banner-pagedesigner .text-holder {
  z-index: 3;
}
.content-banner.banner-pagedesigner .text-holder .acc-content-body p {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 13px;
}
@media (max-width: 767px) {
  .content-banner.banner-pagedesigner .text-holder .acc-content-body p {
    font-size: 11px;
    letter-spacing: 0.2px;
  }
}
.content-banner.banner-pagedesigner .text-holder .acc-content-body p a {
  color: inherit;
  font-size: inherit;
}
.content-banner.banner-pagedesigner .text-holder .acc-content-body.txt--white p {
  color: #ffffff;
}
@media (min-width: 768px) {
  .content-banner {
    max-height: 760px;
    height: 75vh;
    align-items: center;
  }
  .content-banner .headline {
    letter-spacing: 9px;
  }
  .content-banner .btn {
    margin: 35px 8px 0;
  }
  .content-banner .btn:first-of-type {
    margin-top: 35px;
  }
  .content-banner.banner-small {
    min-height: 480px;
    height: auto;
    max-height: none;
  }
}
@media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  .content-banner.banner-small {
    height: 480px;
  }
}
@media (min-width: 768px) {
  .content-banner.samples-banner p {
    margin: 20px 0 0;
  }
  .content-banner.banner-left-aligned .inner-indented {
    padding-left: 10px;
  }
  .content-banner.banner-right-aligned .inner-indented {
    padding-right: 10px;
  }
  .content-banner.banner-top-aligned .text-holder, .content-banner.banner-bottom-aligned .text-holder {
    padding: 100px 0;
  }
}
@media (min-width: 1024px) {
  .content-banner .btn:last-of-type {
    margin-top: 35px;
  }
  .content-banner.banner-left-aligned .inner-indented, .content-banner.banner-text-left-aligned .inner-indented {
    padding-left: 40px;
  }
  .content-banner.banner-right-aligned .inner-indented, .content-banner.banner-text-right-aligned .inner-indented {
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .content-banner-btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .home-page .content-banner:last-child,
  .horizontal-blocks .content-banner:last-child {
    margin-top: 60px;
  }
  .home-page .content-banner:only-child,
  .horizontal-blocks .content-banner:only-child {
    margin-top: 0;
  }
}

.cop-banner-container .content-banner .inner-indented {
  float: left;
  text-align: left;
  padding-left: 10px;
}
.cop-banner-container .content-banner .image-holder::after,
.cop-banner-container .content-banner .responsive-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #000000 15%, transparent 50%);
}
@media (max-width: 1024px) {
  .cop-banner-container .content-banner .image-holder::after,
  .cop-banner-container .content-banner .responsive-bg::after {
    display: none;
  }
}
.cop-banner-container .content-banner .heading-intro {
  line-height: 30px;
  font-size: 20px;
}
@media (max-width: 1024px) {
  .cop-banner-container .content-banner.banner-small {
    min-height: 100px;
  }
  .cop-banner-container .content-banner .image-holder {
    background: #000000 !important;
  }
  .cop-banner-container .content-banner .heading-h1,
  .cop-banner-container .content-banner .heading-intro {
    color: #ffffff !important;
  }
  .cop-banner-container .content-banner .long-desc {
    display: none;
  }
  .cop-banner-container .content-banner .link {
    color: #b8a388;
  }
  .cop-banner-container .content-banner .link span {
    text-decoration: underline;
  }
  .cop-banner-container .content-banner .link .less {
    display: none;
  }
  .cop-banner-container .content-banner .link.open .more {
    display: none;
  }
  .cop-banner-container .content-banner .link.open .less {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .cop-banner-container .content-banner.banner-small {
    min-height: 10px;
    padding: 27px 0 22px;
  }
  .cop-banner-container .content-banner.banner-small.banner-text-left-aligned .inner-indented, .cop-banner-container .content-banner.banner-small.banner-text-right-aligned .inner-indented, .cop-banner-container .content-banner.banner-small.banner-left-aligned .inner-indented, .cop-banner-container .content-banner.banner-small.banner-right-aligned .inner-indented {
    float: none;
    padding: 0;
  }
  .cop-banner-container .content-banner.banner-small .link {
    font-size: 12px;
    letter-spacing: 1.6px;
    line-height: 20px;
  }
  .cop-banner-container .content-banner .inner-indented {
    text-align: center;
  }
  .cop-banner-container .content-banner .heading-h1 {
    letter-spacing: 0.8px;
    line-height: 29px;
    margin-bottom: 9px;
  }
  .cop-banner-container .content-banner .long-desc {
    display: none;
  }
  .cop-banner-container .content-banner .heading-intro {
    display: block;
    font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    margin-bottom: 0;
    padding: 0;
    max-width: 280px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cop-banner-container .content-banner .heading-h1,
  .cop-banner-container .content-banner .heading-intro {
    text-align: left;
  }
  .cop-banner-container .content-banner .inner-indented {
    max-width: 600px;
    padding: 0 10px;
  }
  .cop-banner-container .content-banner .text-holder {
    padding: 90px 0 140px;
  }
}
@media (min-width: 1024px) {
  .cop-banner-container .content-banner.banner-small {
    background-color: #000000;
  }
  .cop-banner-container .content-banner.banner-small .text-holder {
    padding: 90px 0;
  }
  .cop-banner-container .content-banner .short-desc,
  .cop-banner-container .content-banner .link {
    display: none;
  }
  .cop-banner-container .content-banner .heading-h7 {
    font-size: 30px;
    letter-spacing: 0.9px;
    line-height: 45px;
  }
  .cop-banner-container .content-banner .heading-h1,
  .cop-banner-container .content-banner .heading-intro {
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .content-banner-advent.content-banner {
    height: auto;
    min-height: calc(100vh - 150px);
  }
}
@media (min-width: 768px) {
  .content-banner-advent.content-banner {
    height: 90vh;
    max-height: none;
  }
}
.content-banner-advent.content-banner.banner-bottom-aligned .text-holder {
  padding: 0 0 100px 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner-advent.content-banner.banner-bottom-aligned .text-holder {
    padding-bottom: 120px;
  }
}
.content-banner-advent.content-banner .video-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  height: 100%;
  left: 0;
  mask-image: linear-gradient(#000000, transparent 97%);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner .s7container {
    height: 700px !important;
  }
}
.content-banner-advent.content-banner .text-holder {
  z-index: 10;
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner .text-holder {
    padding: 84px 0 76px;
  }
}
.content-banner-advent.content-banner .text-holder .btn {
  font-size: 13px;
  line-height: 18px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner-advent.content-banner .text-holder .btn {
    margin-top: 20px;
  }
}
.content-banner-advent.content-banner .dm-responsive-video {
  height: 100%;
}
.content-banner-advent.content-banner .advent-hero-icon {
  bottom: 0;
  color: #ffffff;
  font-size: 50px;
  left: 50%;
  line-height: 20px;
  position: absolute;
  z-index: 2;
  opacity: 0;
  transform: translateY(-50%, 30px);
}
.content-banner-advent.content-banner .scroll-icon {
  bottom: 10px;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner .scroll-icon {
    height: 45px;
    width: auto;
    bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner-advent.content-banner .scroll-icon {
    bottom: 20px;
  }
}
.content-banner-advent.content-banner .scroll-icon .mousePart {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scrollIndicator2;
  transform: translateY(25px);
}
@keyframes scrollIndicator2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-35%);
  }
}
.content-banner-advent.content-banner .inner-indented {
  max-width: 750px;
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner .inner-indented {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner p.heading-h7 {
    margin-bottom: 8px;
  }
}
.content-banner-advent.content-banner p.heading-h3 {
  font-size: 18px;
  letter-spacing: 0.3em;
  line-height: 26px;
  opacity: 0;
  transform: translateY(30px);
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner p.heading-h3 {
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 24px;
    margin-bottom: 15px;
  }
}
.content-banner-advent.content-banner h1.headline {
  font-size: 72px;
  line-height: 64px;
  letter-spacing: 1.2px;
  text-transform: initial;
  opacity: 0;
  transform: translateY(30px);
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner h1.headline {
    font-size: 44px;
    line-height: 44px;
    text-transform: initial;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-banner-advent.content-banner h1.headline {
    font-size: 44px;
  }
}
.content-banner-advent.content-banner .btn {
  opacity: 0;
  transform: translateY(30px);
  transition: none;
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner .btn {
    font-size: 14px;
    letter-spacing: 2px;
    min-height: 48px;
    margin-top: 24px;
    min-width: 240px;
    max-width: 100%;
    line-height: 18px;
    padding: 14px 20px;
    width: 100%;
  }
  .content-banner-advent.content-banner .btn:first-of-type {
    margin-top: 27px;
  }
}
.content-banner-advent.content-banner .advent-banner-overlay {
  background: linear-gradient(transparent 0, #122343 100%);
  bottom: 0;
  height: 200px;
  left: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
}
.content-banner-advent.content-banner .btn-content-video {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .content-banner-advent.content-banner .btn-content-video {
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .content-banner-advent.content-banner .btn-content-video::before {
    font-size: 18px;
    left: 2px;
  }
}

.content-banner-easter.content-banner .text-holder {
  padding: 60px 0 80px;
}
@media (max-width: 767px) {
  .content-banner-easter.content-banner .text-holder {
    padding: 40px 0 45px;
  }
}
.content-banner-easter.content-banner .inner-indented .btn {
  color: #000000;
  background-color: #ffffff;
  margin: 0;
}
.content-banner-easter.content-banner .inner-indented .btn span {
  font-size: 16px;
}
@media (min-width: 1025px) {
  .content-banner-easter.content-banner .inner-indented .btn:hover {
    background-color: #000000;
    color: #ffffff;
  }
}
.content-banner-easter.content-banner .egg-image-block {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .content-banner-easter.content-banner .egg-image-block {
    margin-bottom: 20px;
  }
}
.content-banner-easter.content-banner .egg-image-block img {
  max-width: 300px;
}
@media (max-width: 767px) {
  .content-banner-easter.content-banner .heading-h1 {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 1px;
  }
}
.content-banner-easter.content-banner .heading-intro {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .content-banner-easter.content-banner .heading-intro {
    max-width: 250px;
    margin: 0 auto 20px;
  }
}
.content-banner-easter.content-banner .egg-model-viewer-size-s {
  position: relative;
  height: 50px;
  width: 220px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .content-banner-easter.content-banner .egg-model-viewer-size-s .btn:last-of-type {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .content-banner-easter.content-banner .egg-model-viewer-size-s .btn:last-of-type {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .content-banner-easter.content-banner .egg-model-viewer-size-s .btn:last-of-type {
    margin-top: 0;
  }
}
.content-banner-easter.content-banner .egg-model-viewer-size-s .btn-easter-size-s {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 3;
}

.discover-block {
  background-color: #ffffff;
  padding: 20px 20px 36px;
  position: relative;
  text-align: center;
}
.discover-block .image-holder {
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 20px;
  height: 90px;
  width: 90px;
}
.discover-block h2 {
  font-size: 11px;
  letter-spacing: 1.6px;
  line-height: 14px;
  margin-bottom: 25px;
}
.discover-block.title-bigger h2 {
  font-size: 30px;
  line-height: 35px;
}
.discover-block p {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 44px;
}
.discover-block.p-scala p {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
}
@media (min-width: 768px) {
  .discover-block.p-scala p {
    font-size: 16px;
  }
}
.discover-block .short-desc {
  width: 100%;
}
.discover-block .long-desc {
  background-color: #ffffff;
  display: none;
  width: 100%;
}
.discover-block .btn {
  margin-top: 8px;
}
.discover-block .btn .less {
  display: none;
}
.discover-block .btn.open .more {
  display: none;
}
.discover-block .btn.open .less {
  display: inline-block;
}
.discover-block.secondary-text-styling .inner-wrapper {
  max-width: 860px;
}
.discover-block.secondary-text-styling img {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .discover-block.secondary-text-styling p {
    letter-spacing: 1.2px;
    line-height: 32px;
  }
}
@media (min-width: 768px) {
  .discover-block.secondary-text-styling p {
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
  }
}
.discover-block.txt--white p {
  color: #ffffff;
}
.discover-block.txt--white .btn {
  border-color: #ffffff;
  color: #ffffff;
}
@media (min-width: 1024px) {
  .discover-block.txt--white .btn:hover, .discover-block.txt--white .btn:active {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #453f3f;
  }
}
.discover-block.txt--black p {
  color: #1a1919 !important;
}
.discover-block.txt--red p {
  color: #d6122d !important;
}
.discover-block.bgd--black {
  background-color: #1a1919;
}
.discover-block.bgd--black .long-desc {
  background-color: #1a1919;
}
.discover-block.bgd--lightgrey {
  background-color: #edeae7;
}
.discover-block.bgd--lightgrey .long-desc {
  background-color: #edeae7;
}
@media (max-width: 767px) {
  .discover-block {
    padding: 45px 20px 40px;
  }
  .discover-block p {
    font-size: 20px;
    letter-spacing: 0.56px;
    line-height: 30px;
  }
}
@media (min-width: 768px) {
  .discover-block {
    padding: 60px 0;
  }
  .discover-block .inner-wrapper {
    max-width: 1080px;
    padding: 0 60px;
  }
  .discover-block h2 {
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 24px;
    margin-bottom: 21px;
  }
}
@media (min-width: 1024px) {
  .discover-block .inner-wrapper {
    padding: 0 20px;
  }
}

.discover-block-limited {
  padding: 42px 20px 29px;
}
.discover-block-limited p {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
}
.discover-block-limited .icon {
  font-size: 31px;
  margin-bottom: 17px;
}
@media (min-width: 768px) {
  .discover-block-limited {
    padding: 62px 0 70px;
  }
  .discover-block-limited p {
    font-size: 20px;
    line-height: 34px;
  }
  .discover-block-limited .inner-wrapper {
    max-width: 1150px;
    padding: 0 20px;
  }
  .discover-block-limited .icon {
    font-size: 52px;
    margin-bottom: 24px;
  }
}

.faq-block {
  padding: 68px 5px 64px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .faq-block {
    padding: 63px 5px 64px;
    text-align: center;
  }
}
.faq-block h2 {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 26px;
  text-transform: none;
}
@media (min-width: 768px) {
  .faq-block h2 {
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 50px;
    margin-bottom: 39px;
  }
}
.faq-block .faq-theme-subtitle.txt--white > * {
  color: #ffffff;
}
.faq-block .faq-theme-subtitle.txt--black > * {
  color: #1a1919;
}

.faq-accordion {
  border-top: 1px solid #b4b4b4;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .faq-accordion {
    border-color: #d8d8d8;
    margin: 0 auto 40px;
    max-width: 920px;
    text-align: left;
  }
}
.faq-accordion + .faq-contact-text {
  margin-top: -9px;
}
@media (min-width: 768px) {
  .faq-accordion + .faq-contact-text {
    margin-top: -18px;
  }
}
.faq-accordion .faq-accordion-item {
  border-bottom: 1px solid #b4b4b4;
  width: 100%;
}
@media (min-width: 768px) {
  .faq-accordion .faq-accordion-item {
    border-color: #d8d8d8;
  }
}
.faq-accordion .faq-accordion-head {
  align-items: center;
  display: flex;
  min-height: 87px;
  padding: 24px 50px 23px 0;
  position: relative;
  width: 100%;
}
.flyin-component .faq-accordion .faq-accordion-head {
  min-height: 0;
}
@media (max-width: 767px) {
  .flyin-component .faq-accordion .faq-accordion-head {
    padding: 16px 70px 16px 0;
  }
}
@media (min-width: 768px) {
  .faq-accordion .faq-accordion-head {
    padding: 24px 110px 23px 25px;
  }
  .flyin-component .faq-accordion .faq-accordion-head {
    padding: 16px 70px 16px 0;
  }
}
.faq-accordion .faq-accordion-head.open .acc-head-circle {
  transform: rotate(360deg);
}
.faq-accordion .faq-accordion-head.open .acc-head-circle::after {
  opacity: 0;
}
.faq-accordion .faq-accordion-head.open-acc .acc-head-circle {
  color: #000000;
}
.faq-accordion .faq-accordion-head.open-acc .acc-head-circle::before {
  content: "\ea68";
}
.faq-accordion .acc-head-title {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 0;
  text-transform: none;
}
.faq-accordion .acc-head-button {
  display: inline-block;
  position: absolute;
}
@media (max-width: 767px) {
  .faq-accordion .acc-head-button {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .faq-accordion .acc-head-button {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
}
.flyin-component .faq-accordion .acc-head-button {
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .flyin-component .faq-accordion .acc-head-button {
    bottom: auto;
    left: auto;
  }
}
.faq-accordion .acc-head-circle {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 40px;
  color: #767676;
  font-size: 24px;
  pointer-events: none;
  position: absolute;
  transition: transform 0.3s ease;
  width: 40px;
}
.flyin-component .faq-accordion .acc-head-circle {
  background: transparent;
}
@media (max-width: 767px) {
  .faq-accordion .acc-head-circle {
    top: 27px;
    right: -1px;
  }
  .flyin-component .faq-accordion .acc-head-circle {
    top: 15px;
    right: -1px;
  }
}
@media (min-width: 768px) {
  .faq-accordion .acc-head-circle {
    height: 48px;
    width: 48px;
    top: 30px;
    right: 24px;
  }
  .flyin-component .faq-accordion .acc-head-circle {
    height: 24px;
    width: 24px;
    top: 15px;
    right: 24px;
  }
}
.faq-accordion .acc-head-circle::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea69";
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
}
.flyin-component .faq-accordion .acc-head-circle::before {
  width: 10px;
}
.faq-accordion .faq-accordion-content {
  display: none;
  padding: 0 45px 12px 0;
  width: 100%;
}
@media (min-width: 768px) {
  .faq-accordion .faq-accordion-content {
    padding: 0 120px 20px 25px;
  }
}
.faq-accordion .faq-accordion-content.txt--white > * {
  color: #ffffff;
}
.faq-accordion .faq-accordion-content.txt--black > * {
  color: #1a1919;
}
.flyin-component .faq-accordion .faq-accordion-content {
  padding: 0 0 20px 16px;
}
.faq-accordion .faq-accordion-content p {
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .faq-accordion .faq-accordion-content p {
    font-size: 13px;
    letter-spacing: 0.9px;
  }
}
@media (min-width: 768px) {
  .flyin-component .faq-accordion .faq-accordion-content p {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}

.faq-contact-text,
.faq-contact-text a {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.faq-contact-text {
  display: block;
  text-align: left;
  margin: 24px auto 0;
  max-width: 920px;
  color: #9f9f9f;
}
@media (min-width: 768px) {
  .faq-contact-text {
    margin-top: 40px;
  }
}
.faq-contact-text a {
  color: #886655;
}

.faq-block-light h2,
.faq-block-light .faq-accordion .acc-head-title,
.faq-block-light .faq-accordion .faq-accordion-content p {
  color: #ffffff;
}
.faq-block-light .faq-accordion,
.faq-block-light .faq-accordion .faq-accordion-item {
  border-color: rgba(255, 255, 255, 0.15);
}
.faq-block-light .acc-head-circle {
  color: #767676;
}
.faq-block-light .faq-accordion-head.open-acc .acc-head-circle {
  color: #ffffff;
}
.faq-block-light .faq-button {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1a1919;
}
@media (min-width: 768px) {
  .faq-block-light .faq-button:hover {
    background-color: transparent;
    color: #ffffff;
  }
}

.flyin-component .faq-overview-wrapper .btn {
  background-color: transparent;
  border: 1px solid #b4b4b4;
  color: #1a1919;
  max-width: none;
  min-width: 0;
}
.faq-overview-wrapper .btn:hover {
  border: 1px solid #1a1919;
}
.flyin-component .faq-overview-wrapper .btn a {
  color: #1a1919;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
}

.fullwidth-slider-block {
  background-color: #ffffff;
  overflow: hidden;
  padding: 60px 25px;
  position: relative;
}
@media (max-width: 767px) {
  .fullwidth-slider-block {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block {
    display: flex;
    min-height: 660px;
    max-height: 760px;
    padding: 0;
    position: relative;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block.video-shown {
    z-index: 999;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block.reversed .text-holder {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .fullwidth-slider-holder {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slick-list {
    overflow: visible;
  }
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slick-slide {
    display: flex;
    height: auto;
  }
  .fullwidth-slider-block .slick-slide > div {
    width: 100%;
  }
}
.fullwidth-slider-block .slick-arrow {
  opacity: 0;
  position: absolute;
  visibility: visible;
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slick-arrow {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
    visibility: visible;
    z-index: 5;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slick-arrow.slick-prev {
    left: 20px;
  }
}
@media (min-width: 1024px) {
  .fullwidth-slider-block .slick-arrow.slick-prev {
    left: 60px;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slick-arrow.slick-next {
    right: 20px;
  }
}
@media (min-width: 1024px) {
  .fullwidth-slider-block .slick-arrow.slick-next {
    right: 50px;
  }
}
.fullwidth-slider-block .slide-item {
  text-align: center;
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slide-item {
    background-color: #ffffff;
  }
}
.fullwidth-slider-block .slide-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slide-inner {
    display: block;
    height: auto;
    padding-top: 288px;
    position: relative;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slide-inner {
    align-items: center;
    min-height: 660px;
    max-height: 760px;
    justify-content: space-around;
    position: relative;
    text-align: center;
  }
}
.fullwidth-slider-block .inner-wrapper {
  align-items: center;
  display: flex;
}
.fullwidth-slider-block .text-holder {
  background-color: #ffffff;
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .text-holder {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.35);
    margin-left: 10px;
    padding: 40px 20px;
    width: calc(100% - 20px);
    z-index: 3;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .text-holder {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 60%;
    min-height: 430px;
    padding: 30px;
    width: 480px;
    z-index: 5;
  }
}
@media (min-width: 1024px) {
  .fullwidth-slider-block .text-holder {
    min-height: 460px;
    max-width: none;
    padding: 60px 70px;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .fullwidth-slider-block .text-holder {
    height: 460px;
  }
}
.fullwidth-slider-block .text-holder h3, .fullwidth-slider-block .text-holder h2 {
  color: #1a1919;
  margin-bottom: 22px;
  text-transform: none;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .text-holder h3, .fullwidth-slider-block .text-holder h2 {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 24px;
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .text-holder h3, .fullwidth-slider-block .text-holder h2 {
    font-size: 24px;
    letter-spacing: 1.3px;
    line-height: 28px;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .fullwidth-slider-block .text-holder h3, .fullwidth-slider-block .text-holder h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
.fullwidth-slider-block .text-holder p {
  line-height: 24px;
}
.fullwidth-slider-block .text-holder p:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .text-holder p {
    color: #1a1919;
    font-size: 12px;
    margin-bottom: 18px;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .text-holder p {
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    max-width: 100%;
  }
}
.fullwidth-slider-block .product-price {
  display: block;
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .fullwidth-slider-block .product-price {
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 32px;
    padding-top: 8px;
  }
}
.fullwidth-slider-block .product-price .price-sales {
  display: block;
}
.fullwidth-slider-block .product-price .price-standard {
  color: #999999;
  display: block;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 20px;
  margin: 10px 0 0;
  text-decoration: line-through;
}
@media (min-width: 768px) {
  .fullwidth-slider-block .product-price .price-standard {
    font-size: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
  }
}
.fullwidth-slider-block .btn {
  margin-bottom: 15px;
}
.fullwidth-slider-block .btn:last-child {
  margin-bottom: 0;
}
.fullwidth-slider-block .media-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 231px;
  width: 100%;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .media-holder {
    height: 420px;
    left: 0;
    position: absolute;
    top: 0;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .media-holder {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
  }
}
.fullwidth-slider-block .slider-bar {
  margin: 40px auto 0;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slider-bar {
    background-color: #a39f9e;
    border-radius: 4px;
    height: 4px;
    margin: 20px auto;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slider-bar {
    background-color: rgba(255, 255, 255, 0.3);
    bottom: 48px;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 768px) and (max-height: 768px) {
  .fullwidth-slider-block .slider-bar {
    bottom: 35px !important;
  }
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slider-bar .indicator {
    background-color: #404040;
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slider-bar .indicator {
    background-color: #ffffff;
  }
}
.fullwidth-slider-block .slider-button {
  background-color: #ffffff;
  border: 2px solid #ffffff;
}
.fullwidth-slider-block .slider-button:hover {
  background-color: rgba(255, 255, 255, 0);
}
.fullwidth-slider-block .slider-button:hover::before {
  color: #ffffff;
}
.fullwidth-slider-block .slider-dots {
  bottom: 60px;
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slider-dots {
    bottom: 20px;
  }
}
@media (min-width: 768px) {
  .fullwidth-slider-block .slider-dots {
    bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-height: 768px) {
  .fullwidth-slider-block .slider-dots {
    bottom: 35px !important;
  }
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slider-dots .slick-dots {
    height: 4px;
  }
}
@media (max-width: 767px) {
  .fullwidth-slider-block .slider-dots .button {
    height: 4px;
  }
}

@media (min-width: 768px) {
  .fullwidth-slider-text {
    display: flex;
    padding: 70px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .fullwidth-slider-text {
    padding: 60px 150px;
  }
}
.fullwidth-slider-text .fullwidth-slider-text-box {
  width: 100%;
}

.product-list-slider-block {
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 0 4px;
  position: relative;
  width: 100%;
}
.product-list-slider-block .drag-list-scroll::after, .product-list-slider-block .drag-list-scroll::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 100%;
  left: 0;
  opacity: 0;
  top: 0;
  transition: opacity 0.6s cubic-bezier(0.05, 0.5, 0.3, 1);
  z-index: 22;
}
.product-list-slider-block .drag-list-scroll::after {
  left: unset;
  right: 0;
}
.product-list-slider-block .drag-list-scroll.left-gradient::before {
  opacity: 1;
}
.product-list-slider-block .drag-list-scroll.right-gradient::after {
  opacity: 1;
}
.product-list-slider-block:not(.hommes-bg) {
  background-color: #f6f4f2;
}
.product-list-slider-block:not(.hommes-bg) .product-list-slider .product-image {
  background-color: #faf9f7;
}
.product-list-slider-block:not(.hommes-bg) .product-list-slider-item.text-item h3,
.product-list-slider-block:not(.hommes-bg) .product-list-slider-item.text-item p {
  color: #1a1919;
}
.product-list-slider-block:not(.hommes-bg) .drag-list-scroll::before {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #f6f4f2 80%);
}
.product-list-slider-block:not(.hommes-bg) .drag-list-scroll::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f6f4f2 80%);
}
.product-list-slider-block.hommes-bg {
  background-color: #1d1c24;
}
.product-list-slider-block.hommes-bg .product-list-slider .product-image {
  background-color: #f6f4f2;
}
.product-list-slider-block.hommes-bg .product-list-slider-item.text-item h3,
.product-list-slider-block.hommes-bg .product-list-slider-item.text-item p {
  color: #ffffff;
}
.product-list-slider-block.hommes-bg .drag-list-controls button.slider-button {
  background-color: #1d1c24;
  color: #ffffff;
}
.product-list-slider-block.hommes-bg .drag-list-scroll::before {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #1d1c24 80%);
}
.product-list-slider-block.hommes-bg .drag-list-scroll::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #1d1c24 80%);
}
.shopping-cart-block .product-list-slider-block.personalised-promotions {
  padding: 0 0 24px;
}
@media (max-width: 767px) {
  .shopping-cart-block .product-list-slider-block.personalised-promotions {
    padding: 0 0 4px;
  }
}
.product-list-slider-block.personalised-promotions .product-list-slider-item-relative {
  position: relative;
  width: 100%;
}
.product-list-slider-block.personalised-promotions .product-picker-wrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.product-list-slider-block.personalised-promotions .product-picker-wrapper input[type=radio] + label {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.product-list-slider-block.personalised-promotions .product-picker-wrapper input[type=radio] + label::before {
  left: auto;
}
@media (max-width: 767px) {
  .product-list-slider-block.personalised-promotions .product-picker-wrapper input[type=radio] + label::before {
    right: 16px;
    top: 16px;
  }
}
@media (min-width: 768px) {
  .product-list-slider-block.personalised-promotions .product-picker-wrapper input[type=radio] + label::before {
    right: 24px;
    top: 24px;
  }
}
.product-list-slider-block.personalised-promotions .product-list-slider .product-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}
.product-list-slider-block.personalised-promotions .product-list-slider .product-price .product-sales-price {
  font-size: 20px;
}
@media (max-width: 767px) {
  .product-list-slider-block.personalised-promotions .product-list-slider .product-price .product-sales-price {
    font-size: 16px;
  }
}
.product-list-slider-block.personalised-promotions .product-list-slider .product-price .product-beforediscount-price {
  font-size: 14px;
  margin-left: 0;
}
.product-list-slider-block.personalised-promotions .drag-list .drag-list-body {
  margin-bottom: 88px;
}
.shopping-cart-block .product-list-slider-block.personalised-promotions .drag-list .drag-list-body {
  margin-bottom: 78px;
}
.product-list-slider-block .slider-bar {
  max-width: calc(100% - 20px);
}
.product-list-slider-block .btn-mobile {
  display: block;
  margin: 0 auto;
}
.product-list-slider-block .btn-customize .icon-pencil-configurable {
  background: url("../images/pencil-white-icon.svg") left top no-repeat;
  background-size: 15px 15px;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: middle;
  transition: 0.2s ease-in-out;
  margin: -2px 0 0 3px;
}
@media (max-width: 767px) {
  .product-list-slider-block .slider-dots {
    bottom: 89px;
    left: 20px;
    max-width: none;
    transform: none;
    width: calc(100% - 40px);
  }
  .product-list-slider-block .slider-bar {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .product-list-slider-block {
    padding: 58px 0 24px;
  }
  .product-list-slider-block .btn-mobile {
    display: none;
  }
  .product-list-slider-block .slider-bar {
    margin: 7px auto 36px;
    max-width: 100%;
  }
  .product-list-slider-block .slider-bar .indicator {
    cursor: pointer;
    z-index: 2;
  }
  .product-list-slider-block .slider-bar .indicator.is-dragging {
    transition: none;
  }
  .product-list-slider-block .slider-bar .indicator.cloned {
    opacity: 0;
    transition: none;
    z-index: 1;
  }
  .product-list-slider-block .slider-dots {
    bottom: 60px;
    max-width: calc(100% - 120px);
  }
}
@media (min-width: 1025px) {
  .product-list-slider-block .btn-customize:hover .icon-pencil-configurable {
    background: url("../images/pencil-black-icon.svg") left top no-repeat;
    background-size: 15px 15px;
    display: inline-block;
    height: 15px;
    width: 15px;
  }
}
.product-list-slider-block.pd-product-slider .product-list-slider-item .product-image,
.product-list-slider-block.pd-product-slider .product-list-slider-item .product-details-content {
  border: none;
}

.personalised-promotions-wrapper .promotional-label {
  background-color: #9a7b69;
  bottom: auto;
  color: #ffffff;
  left: 0;
}
.personalised-promotions-wrapper .promotional-label:nth-of-type(2) {
  color: #1a1919;
}

.product-list-slider-intro {
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
}
.product-list-slider-intro h2 {
  color: #9a7b69;
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 18px;
  margin-bottom: 0;
}
.product-list-slider-intro h3 {
  color: #1a1919;
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 32px;
  margin-bottom: 0;
  text-transform: none;
}
.hommes-bg .product-list-slider-intro h3 {
  color: #ffffff;
}
@media (min-width: 768px) {
  .product-list-slider-intro:not(.personalised-promotions) {
    display: none;
  }
}

.product-list-slider-footer {
  background-color: #f6f4f2;
  text-align: center;
}
@media (max-width: 767px) {
  .product-list-slider-footer {
    padding: 0 16px 40px 16px;
  }
}
@media (min-width: 768px) {
  .product-list-slider-footer {
    padding-bottom: 64px;
  }
}
@media (max-width: 767px) {
  .product-list-slider-footer a,
  .product-list-slider-footer p {
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 16px;
  }
}
@media (min-width: 768px) {
  .product-list-slider-footer a,
  .product-list-slider-footer p {
    font-size: 14px;
  }
}
.product-list-slider-footer p {
  font-style: normal;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 1024px) {
  .product-list-slider-footer p {
    max-width: 1140px;
    width: 80%;
  }
}

.product-list-slider .slick-list {
  overflow: visible;
}
.product-list-slider .product-name-link {
  text-decoration: none;
}
.product-list-slider .product-name {
  color: #1a1919;
  margin-bottom: 4px;
}
@media (max-width: 767px) {
  .product-list-slider .product-name {
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 20px;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-name {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
}
.product-list-slider .product-price {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 0;
  text-align: left;
}
@media (max-width: 767px) {
  .product-list-slider .product-price {
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 20px;
    position: relative;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-price {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
  }
}
.product-list-slider .product-price .product-beforediscount-price {
  letter-spacing: 0.3px;
  margin: 0 0 5px;
}
@media (min-width: 768px) {
  .product-list-slider .product-price .product-beforediscount-price {
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 20px;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  .product-list-slider .product-price .base-price-block {
    border-top: 1px solid #d8d8d8;
    margin-top: 10px;
    padding-top: 10px;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-price .base-price-block {
    margin-right: 60px;
    margin-top: 20px;
  }
}
.product-list-slider .product-price .base-price-block div {
  margin: 0;
}
.product-list-slider .product-price .base-price-block div:first-child {
  margin-right: 2px;
}
.product-list-slider .product-tile {
  flex-direction: column;
  flex-grow: 1;
  padding: 0 10px;
  position: relative;
  width: 100%;
}
.product-list-slider .product-tile .product-details-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .product-list-slider .product-tile {
    padding: 0 5px;
  }
}
.product-list-slider .product-tile.out-of-stock .out-of-stock-block,
.product-list-slider .product-tile.out-of-stock .product-price {
  display: none;
}
.product-list-slider .product-tile .product-image img {
  width: initial;
}
.product-list-slider .product-tile .promotional-label {
  left: 0;
  z-index: 1;
  background-color: #ffffff;
  position: absolute;
  width: auto;
}
.product-list-slider .product-tile .promotional-label:not(.callout-advent) {
  bottom: 40px;
}
@media (max-width: 767px) {
  .product-list-slider .product-tile .promotional-label.callout-advent {
    top: 24px;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-tile .promotional-label.callout-advent {
    top: 15px;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-tile .short-description {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 20px;
    min-height: 40px;
  }
}
.product-list-slider .product-image {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  position: relative;
}
.product-list-slider .product-image .thumb-link {
  width: 100%;
}
.product-list-slider .product-image img {
  display: inline-block;
  max-width: 100%;
}
.product-list-slider .product-image img:not([src]) {
  display: block;
  position: relative;
  width: 100%;
}
@media (max-width: 767px) {
  .product-list-slider .product-image img:not([src]) {
    height: 200px;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-image img:not([src]) {
    height: 100%;
  }
}
.product-list-slider .product-image img:not([src])::before {
  background-color: #d1d1d1;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}
.product-list-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.product-list-slider .slick-arrow.slick-prev {
  left: 0;
}
.product-list-slider .slick-arrow.slick-next {
  right: 0;
}
.product-list-slider .promotion-discount-text {
  display: none;
}
.product-list-slider .product-details-content {
  align-items: flex-start;
  background-color: #ffffff;
  position: relative;
}
@media (max-width: 767px) {
  .product-list-slider .product-details-content {
    margin-top: -1px;
    padding: 20px 16px;
    transition: opacity 0.4s ease;
  }
}
@media (min-width: 768px) {
  .product-list-slider .product-details-content {
    padding: 20px 24px;
  }
  .product-list-slider .product-details-content .product-name {
    margin-bottom: 10px;
  }
}
.product-list-slider .product-details-content .out-of-stock-label {
  bottom: 20px;
  color: #767676;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  position: absolute;
}
.product-list-slider .product-user-actions {
  bottom: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.product-list-slider .product-user-actions .rituals-form {
  align-items: center;
  display: flex;
}
@media (max-width: 767px) {
  .product-list-slider .slick-slide {
    background-color: #f6f4f2;
    padding: 0 10px;
  }
  .product-list-slider .product-image {
    height: 168px;
    padding: 20px 24px;
    text-align: center;
  }
  .product-list-slider .product-image .thumb-link {
    height: 90%;
  }
  .product-list-slider .product-image .thumb-link img {
    max-height: 100%;
    position: relative;
  }
  .product-list-slider .slick-active .product-details-content {
    opacity: 1;
  }
  .product-list-slider .product-list-slider-item {
    justify-content: center;
    width: 210px;
  }
  .product-list-slider .product-list-slider-item .product-tile {
    align-self: flex-end;
  }
  .product-list-slider .text-item {
    display: none;
    width: 0;
  }
}
@media (min-width: 768px) {
  .product-list-slider .slick-track {
    display: flex;
  }
  .product-list-slider .product-image {
    display: block;
    height: 350px;
    padding: 20px 24px;
    text-align: center;
  }
  .product-list-slider .product-image img {
    max-height: 100%;
  }
  .product-list-slider .product-image .thumb-link {
    align-items: flex-end;
    display: flex;
    height: 100%;
  }
  .product-list-slider .product-list-slider-item {
    width: 424px;
  }
  .product-list-slider .product-list-slider-item.text-item {
    flex-direction: unset;
  }
}

@media (min-width: 768px) {
  .product-list-slider.drag-list {
    max-width: 1360px;
    padding: 0;
    margin: 0 auto;
  }
}

.toggle-slider-promo {
  display: none;
}

.product-list-slider-item {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.product-list-slider-item.selected input[type=radio] + label::before {
  background-color: #9a7b69;
  border-color: #9a7b69;
  content: "\ea2f";
}
.product-list-slider-item:not(.selected) input[type=radio] + label::before {
  background-color: #ffffff;
  border-color: #d8d8d8;
  content: "''";
}
.product-list-slider-item .the-ritual-of-title,
.product-list-slider-item .view-full-details-link {
  display: none;
}
.product-list-slider-item .product-title,
.product-list-slider-item .short-description {
  text-align: left;
}
.product-list-slider-item .promotion-add-to-cart {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.product-list-slider-item .promotion-add-to-cart.btn--disabled .btn {
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
  color: #453f3f;
}
.product-list-slider-item .promotion-add-to-cart .btn {
  min-width: 0;
}
.product-list-slider-item .promotion-add-to-cart .check-promo-btn-text.hidden {
  visibility: hidden;
}
.product-list-slider-item .promotion-add-to-cart .spinner.visible {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product-list-slider-item .product-image {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}
.product-list-slider-item .product-details-content {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}
.product-list-slider-item.selected .product-image {
  border-left: 1px solid #9a7b69;
  border-right: 1px solid #9a7b69;
  border-top: 1px solid #9a7b69;
}
.product-list-slider-item.selected .product-details-content {
  border-bottom: 1px solid #9a7b69;
  border-left: 1px solid #9a7b69;
  border-right: 1px solid #9a7b69;
}
.product-list-slider-item.selected .promotion-add-to-cart, .product-list-slider-item.is-guest .promotion-add-to-cart {
  display: block;
}
.product-list-slider-item:not(.promotion-slider) .promotion-wrapper,
.product-list-slider-item:not(.promotion-slider) .the-ritual-of-title,
.product-list-slider-item:not(.promotion-slider) .view-full-details-link {
  display: none;
}
.product-list-slider-item:not(.promotion-slider).text-item h2 {
  color: #9a7b69;
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
  margin-bottom: 16px;
}
.product-list-slider-item:not(.promotion-slider).text-item h3 {
  font-size: 40px;
  letter-spacing: 1.5px;
  line-height: 50px;
  margin-bottom: 25px;
  text-transform: none;
}
.product-list-slider-item:not(.promotion-slider).text-item p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 38px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-list-slider-item:not(.promotion-slider).text-item .btn {
    min-width: 0;
    width: 100%;
  }
}
.product-list-slider-item:not(.promotion-slider).text-item .text-holder {
  padding-right: 24%;
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-flexed .product-add-to-cart {
  display: none;
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-inner {
  bottom: 0;
  display: flex;
  gap: 8px;
  right: 0;
}
@media (min-width: 768px) {
  .product-list-slider-item:not(.promotion-slider) .add-to-cart-inner {
    bottom: 0;
  }
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-inner .btn {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #b4b4b4;
  color: #000000;
  font-size: 0;
  height: 48px;
  letter-spacing: 0;
  line-height: 0;
  max-width: none;
  min-width: auto;
  padding: 0;
  text-transform: none;
  transition: border 0.2s ease-in-out;
  width: 48px;
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-inner .btn.btn-add-to-cart:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea2c";
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-inner .btn .icon-send {
  left: 50%;
  right: unset;
  transform: translate(-50%, -50%);
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-inner .btn::before {
  display: block;
  font-size: 20px;
  line-height: 20px;
  margin-top: 12px;
}
.product-list-slider-item:not(.promotion-slider) .add-to-cart-inner .btn:hover {
  border-color: #1a1919;
}
.product-list-slider-item:not(.promotion-slider) .product-add-to-cart {
  display: block;
  margin: 0;
}
.product-list-slider-item .promotion-wrapper {
  margin: 0 auto 12px auto;
}
.product-list-slider-item .promotion-wrapper .callout-message {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
}
.product-list-slider-item .promotion-wrapper .callout-message.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.product-list-slider-item .promotion-wrapper .callout-message.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.product-list-slider-item .promotion-wrapper .callout-message.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.product-list-slider-item .promotion-wrapper .callout-message.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.product-list-slider-item .promotion-wrapper .callout-message.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.product-list-slider-item .promotion-wrapper .callout-message.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}
.product-list-slider-item .product-tile-promotion {
  left: 10px;
  position: absolute;
  top: 20px;
  z-index: 1;
}
@media (max-width: 1023px) {
  .product-list-slider-item .product-tile-promotion {
    left: 5px;
  }
}
.product-list-slider-item .product-tile-promotion .callout-message {
  background-color: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;
}
.product-list-slider-item .product-tile-promotion .callout-message.callout-red {
  background-color: #ad4141;
  color: #ffffff;
}
.product-list-slider-item .product-tile-promotion .callout-message.callout-grey {
  background-color: #626262;
  color: #ffffff;
}
.product-list-slider-item .product-tile-promotion .callout-message.callout-green {
  background-color: #649b26;
  color: #ffffff;
}
.product-list-slider-item .product-tile-promotion .callout-message.callout-green-light {
  background-color: #e2ead7;
  color: #1a1919;
}
.product-list-slider-item .product-tile-promotion .callout-message.callout-gold {
  background-color: #9a7b69;
  color: #ffffff;
}
.product-list-slider-item .product-tile-promotion .callout-message.callout-white {
  background-color: #ffffff;
  color: #1a1919;
}

.product-slider-block {
  padding-bottom: 55px;
  width: 100%;
}
@media (min-width: 768px) {
  .product-slider-block:not(.collection-product-slider-block) {
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .product-slider-reverted .product-slider-steps {
    padding-left: 0;
    padding-right: 50%;
  }
  .product-slider-reverted .product-slider-media-holder {
    left: auto;
  }
  .product-slider-reverted .product-slider-step {
    float: right;
  }
}
@media (min-width: 1024px) {
  .product-slider-reverted .product-slider-holder .slick-arrow.slick-prev {
    left: 57px;
  }
  .product-slider-reverted .product-slider-holder .slick-arrow.slick-next {
    right: 66px;
  }
  .product-slider-reverted .product-slider-holder .slider-bar {
    transform: translateX(0);
  }
  .product-slider-reverted .product-slider-holder .slider-dots {
    margin-left: 0;
  }
}
@media (min-width: 1441px) {
  .product-slider-reverted .product-slider-holder div.slick-arrow.slick-prev {
    left: 5%;
  }
  .product-slider-reverted .product-slider-holder div.slick-arrow.slick-next {
    left: auto;
    right: 66px;
  }
  .product-slider-reverted .product-slider-holder .slider-bar {
    margin: 26px 210px 0 auto;
  }
  .product-slider-reverted .product-slider-holder .slider-dots {
    left: auto;
    right: 210px;
  }
}

.product-slider-intro {
  padding: 60px 10px 0;
  text-align: center;
  width: 100%;
}
.product-slider-intro h4 {
  color: #000000;
  margin-bottom: 12px;
  font-size: 14px;
  letter-spacing: 2.4px;
  line-height: 18px;
}
.product-slider-intro h2 {
  color: #1a1919;
  font-size: 30px;
  letter-spacing: 1.2px;
  line-height: 40px;
  margin-bottom: 23px;
  text-transform: none;
}
.product-slider-intro .read-time,
.product-slider-intro p {
  display: none;
}
@media (min-width: 768px) {
  .product-slider-intro {
    padding: 95px 20px 64px;
  }
  .product-slider-intro .inner-wrapper {
    max-width: 800px;
  }
  .product-slider-intro h4 {
    font-size: 15px;
    letter-spacing: 5px;
    margin-bottom: 20px;
  }
  .product-slider-intro h2 {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
    margin-bottom: 17px;
  }
  .product-slider-intro .read-time {
    color: #000000;
    display: inline-block;
    font-family: "ClassGarmnd BT", "Times New Roman", serif;
    font-size: 15px;
    letter-spacing: 5px;
    line-height: 23px;
    margin-bottom: 24px;
    padding-left: 20px;
    position: relative;
    text-transform: uppercase;
  }
  .product-slider-intro .read-time::before {
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    content: "\ea67";
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
  }
  .product-slider-intro p {
    display: block;
    line-height: 28px;
    margin: 0 auto;
    max-width: 500px;
  }
}
@media (min-width: 1024px) {
  .product-slider-intro {
    padding: 95px 0 64px;
  }
}

.product-slider-steps {
  width: 100%;
}
@media (min-width: 768px) {
  .product-slider-steps {
    padding-left: 50%;
    position: relative;
  }
}

.product-slider-media-holder {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}
.product-slider-media-holder .image-holder,
.product-slider-media-holder .background-video {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
  z-index: 2;
}
.product-slider-media-holder .image-holder.active,
.product-slider-media-holder .background-video.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 768px) {
  .product-slider-media-holder {
    display: block;
  }
}

.product-slider-holder {
  opacity: 0;
  padding-bottom: 16px;
  position: relative;
  transition: opacity 1s ease;
  width: 100%;
}
.product-slider-holder.fadein {
  opacity: 1;
}
.product-slider-holder .slider-inner {
  width: 100%;
}
.product-slider-holder .slider-bar {
  height: 4px;
  margin: 44px auto 0;
}
.product-slider-holder .slider-dots {
  bottom: 16px;
  height: 4px;
}
.product-slider-holder.show-steps {
  counter-reset: step-counter;
}
.product-slider-holder.show-steps .step-intro::before {
  color: #453f3f;
  content: counter(step-counter);
  counter-increment: step-counter;
  display: inline-block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 246px;
  left: 50%;
  line-height: 1;
  opacity: 0.11;
  position: absolute;
  top: -38px;
  transform: translateX(-50%);
  z-index: -1;
}
@media (min-width: 768px) {
  .product-slider-holder {
    padding-bottom: 30px;
  }
  .product-slider-holder .slider-bar {
    bottom: 30px;
    margin-top: 26px;
  }
  .product-slider-holder .slider-dots {
    bottom: 30px;
  }
  .product-slider-holder.show-steps {
    padding-bottom: 0;
  }
  .product-slider-holder.show-steps .slick-slide .step-intro {
    padding: 120px 0 1px;
  }
  .product-slider-holder.show-steps .slick-slide .step-intro::before {
    top: 28px;
    transform: translateX(-60%);
  }
  .product-slider-holder.show-steps .slick-slide .step-intro h3::before, .product-slider-holder.show-steps .slick-slide .step-intro h3::after {
    background-color: #453f3f;
    content: "";
    display: inline-block;
    height: 1px;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1000em;
  }
  .product-slider-holder.show-steps .slick-slide .step-intro h3::before {
    right: calc(100% + 20px);
  }
  .product-slider-holder.show-steps .slick-slide .step-intro h3::after {
    left: calc(100% + 20px);
  }
  .product-slider-holder.show-steps .slick-slide:first-child .step-intro h3::before, .product-slider-holder.show-steps .slick-slide:last-child .step-intro h3::after {
    display: none;
  }
}
@media (min-width: 1024px) {
  .product-slider-holder .slider-bar {
    transform: translateX(-10px);
  }
  .product-slider-holder .slider-dots {
    margin-left: -10px;
  }
}
@media (min-width: 1441px) {
  .product-slider-holder div.slider-bar {
    margin-left: 200px;
    transform: translateX(0);
  }
  .product-slider-holder div.slider-dots {
    left: 200px;
    margin-left: 0;
    transform: translateX(0);
  }
}

.product-main-slider {
  overflow: hidden;
  padding: 0 23px;
  width: 100%;
}
.product-main-slider .slick-arrow {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
}
.product-main-slider .slick-list {
  overflow: visible;
}
.product-main-slider .product-slider-step {
  padding: 0 4px;
  text-align: center;
  width: 100%;
}
.product-main-slider .product-slider-step + .product-slider-step {
  display: none;
}
.product-main-slider .step-intro {
  padding: 40px 0 31px;
  position: relative;
  width: 100%;
  min-height: 208px;
}
.product-main-slider .step-intro h3 {
  color: #000000;
  font-size: 22px;
  letter-spacing: 4px;
  line-height: 30px;
  margin-bottom: 13px;
  text-transform: uppercase;
}
.product-main-slider .step-intro .read-time {
  display: block;
  margin-bottom: 7px;
}
.product-main-slider .step-intro p,
.product-main-slider .step-intro .link {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: none;
}
.product-main-slider .step-intro .description-link {
  display: block;
  color: #453f3f;
  text-decoration: none;
}
@media (max-width: 767px) {
  .product-main-slider .step-intro .description-link {
    min-height: 48px;
  }
}
.product-main-slider .step-intro .btn-content-video {
  display: none;
}
@media (max-width: 767px) {
  .product-main-slider .step-intro .btn-content-video {
    display: inline-block;
    margin-top: 33px;
  }
}
.product-main-slider .step-intro .slider-text-short .description-link,
.product-main-slider .step-intro .slider-text-long .description-link {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  .product-main-slider .step-intro .slider-text-short .description-link,
  .product-main-slider .step-intro .slider-text-long .description-link {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
  }
}
.product-main-slider .step-intro .slider-text-short {
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .product-main-slider .step-intro .slider-text-short {
    display: none;
  }
}
.product-main-slider .step-intro .slider-text-long {
  display: none;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .product-main-slider .step-intro .slider-text-long {
    display: block;
  }
  .product-main-slider .step-intro .slider-text-long .link.link--large {
    display: none;
  }
}
@media (max-width: 767px) {
  .product-main-slider .step-intro {
    display: flex;
    flex-direction: column;
  }
}
.product-main-slider .product-tile {
  background-color: #ffffff;
  padding: 10px 20px 20px;
}
.product-main-slider .product-tile .thumb-link {
  max-width: 260px;
  display: inline-block;
}
.product-main-slider .product-tile img {
  display: inline-block;
  max-width: 100%;
}
.product-main-slider .product-tile .the-ritual-of-title {
  color: #9a7b69;
  font-size: 11px;
  letter-spacing: 1.6px;
  line-height: 24px;
  margin-bottom: 6px;
}
.product-main-slider .product-tile .product-name-link {
  text-decoration: none;
}
.product-main-slider .product-tile .product-name {
  font-size: 15px;
  letter-spacing: 3px;
  line-height: 23px;
  margin-bottom: 11px;
}
.product-main-slider .product-tile .short-description {
  margin: -10px 0 10px;
}
.product-main-slider .product-tile .product-price {
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 19px;
  margin-bottom: 26px;
}
.product-main-slider .product-tile .product-add-to-cart {
  margin-bottom: 12px;
}
.product-main-slider .product-tile .link--large {
  display: inline-block;
  letter-spacing: 1.3px;
}
@media (max-width: 767px) {
  .product-main-slider .product-tile {
    margin-top: auto;
    min-height: 465px;
  }
}
.product-main-slider .product-details-content .out-of-stock-block {
  margin-top: 0;
}
.product-main-slider .product-options .swatches-wrapper,
.product-main-slider .product-variations .swatches-wrapper {
  justify-content: center;
}
@media (max-width: 767px) {
  .product-main-slider.product-slider-stacked ~ .slider-dots {
    display: none;
  }
  .product-main-slider.product-slider-stacked .product-tile {
    background-color: transparent;
    padding-bottom: 36px;
  }
  .product-main-slider.product-slider-stacked .step-intro {
    padding-top: 64px;
    z-index: 1;
  }
  .product-main-slider.product-slider-stacked .step-intro::before {
    top: -2px;
  }
  .product-main-slider.product-slider-stacked .product-slider-step:not(:last-of-type)::after {
    height: 1px;
    width: calc(100% - 20px);
    margin: 0 auto;
    content: "";
    display: block;
    background-color: #767676;
  }
  .product-main-slider .slick-track {
    display: flex !important;
  }
  .product-main-slider .slick-slide {
    height: inherit !important;
  }
}
@media (min-width: 768px) {
  .product-main-slider {
    padding: 0;
  }
  .product-main-slider .slick-arrow {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
    visibility: visible;
    z-index: 3;
  }
  .product-main-slider .slick-arrow.slick-prev {
    left: 6px;
  }
  .product-main-slider .slick-arrow.slick-next {
    left: auto;
    right: 6px;
  }
  .product-main-slider .slick-list {
    overflow: hidden;
  }
  .product-main-slider .slick-slide {
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease;
  }
  .product-main-slider .slick-slide.slick-active {
    opacity: 1;
  }
  .product-main-slider .product-slider-step {
    max-width: 720px;
    padding: 0;
    position: relative;
    text-align: center;
  }
  .product-main-slider .slider-inner-wrap {
    margin: 0 auto;
    max-width: 570px;
    padding: 0 20px;
  }
  .product-main-slider .step-intro {
    padding: 50px 0 1px;
    min-height: 0;
  }
  .product-main-slider .step-intro h3 {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 50px;
    display: inline-block;
    margin-bottom: 22px;
    position: relative;
  }
  .product-main-slider .step-intro p {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
  }
  .product-main-slider .product-tile {
    background-color: transparent;
  }
  .product-main-slider .product-tile img {
    max-width: 260px;
  }
  .product-main-slider .product-tile .product-details-content {
    max-width: 100%;
    padding: 20px;
  }
  .product-main-slider .product-tile .short-description {
    margin: -10px 0 15px;
  }
  .product-main-slider .product-tile .product-price {
    letter-spacing: 1px;
  }
  .product-main-slider .product-tile .product-add-to-cart {
    margin-bottom: 17px;
  }
  .product-main-slider .product-tile .link--large {
    letter-spacing: 2px;
  }
}
@media (min-width: 1024px) {
  .product-main-slider .slick-arrow.slick-prev {
    left: 66px;
  }
  .product-main-slider .slick-arrow.slick-next {
    right: 57px;
  }
}
@media (min-width: 1441px) {
  .product-main-slider div.slick-arrow.slick-next {
    right: 5%;
  }
}

.exclusive-slider-block .step-intro h4 {
  color: #886655;
  font-size: 12px;
  letter-spacing: 0.9px;
  line-height: 16px;
}
.exclusive-slider-block .btn-personalize .icon-pencil-configurable {
  background: url("../images/pencil-white-icon.svg") left top no-repeat;
  background-size: 16px 16px;
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-bottom: -5px;
}
.exclusive-slider-block .btn {
  margin-top: 10px;
}
.exclusive-slider-block .ico {
  color: #9a7b69;
  margin: 0 auto 30px;
}
@media (max-width: 767px) {
  .exclusive-slider-block .ico {
    margin: 0 auto 20px;
  }
}
.exclusive-slider-block .ico:not(.icon--large, .icon--medium, .icon--small) {
  font-size: 24px;
}
@media (max-width: 767px) {
  .exclusive-slider-block {
    padding: 40px 0 24px;
  }
  .exclusive-slider-block .slick-list,
  .exclusive-slider-block .slick-track {
    -webkit-overflow-scrolling: touch;
  }
  .exclusive-slider-block .product-slider-media-holder {
    display: none;
  }
  .exclusive-slider-block .product-main-slider {
    padding: 0 25px;
  }
  .exclusive-slider-block .product-main-slider .image-holder {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 244px;
    margin-bottom: 20px;
    width: 100%;
  }
  .exclusive-slider-block .slick-slide {
    padding: 0 5px;
  }
  .exclusive-slider-block .product-slider-step {
    background-color: #ffffff;
    padding: 0;
  }
  .exclusive-slider-block .step-intro {
    padding: 23px 30px 30px;
  }
  .exclusive-slider-block .step-intro h4 {
    color: #886655;
    font-size: 12px;
    letter-spacing: 0.9px;
    line-height: 16px;
  }
  .exclusive-slider-block .step-intro h3 {
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 22px;
    text-transform: none;
  }
  .exclusive-slider-block .step-intro p {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
  }
  .exclusive-slider-block .step-intro .btn-content-video {
    margin: 8px 0 10px;
  }
  .exclusive-slider-block .slider-bar {
    height: 2px;
    margin: 20px auto 0;
    max-width: 320px;
  }
  .exclusive-slider-block .slider-dots {
    max-width: 320px;
  }
}
@media (min-width: 768px) {
  .exclusive-slider-block .product-main-slider {
    margin: 90px 0 130px;
  }
  .exclusive-slider-block .product-slider-steps {
    align-items: center;
    display: flex;
    min-height: 750px;
  }
  .exclusive-slider-block .product-slider-holder {
    padding-bottom: 0;
  }
  .exclusive-slider-block .product-main-slider .image-holder {
    display: none;
  }
  .exclusive-slider-block .slider-inner-wrap {
    max-width: 460px;
  }
  .exclusive-slider-block .step-intro {
    padding: 43px 0 0;
  }
  .exclusive-slider-block .step-intro h3 {
    font-size: 40px;
    letter-spacing: 1.7px;
    line-height: 50px;
    text-transform: none;
  }
  .exclusive-slider-block .step-intro p {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  .exclusive-slider-block .btn {
    margin-top: 30px;
  }
}

.product-exhibition-block {
  padding: 20px 4px;
  width: 100%;
}
@media (min-width: 768px) {
  .product-exhibition-block {
    padding: 20px 0;
  }
}
@media (min-width: 768px) {
  .product-exhibition-block .inner-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1360px;
  }
}

.product-exhibition-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 24px;
  position: relative;
}
@media (max-width: 767px) {
  .product-exhibition-col {
    height: 420px;
    margin-bottom: 64px;
    width: 100%;
  }
  .product-exhibition-col:last-child {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .product-exhibition-col {
    height: 628px;
    padding: 24px 24px 40px;
    width: calc(50% - 32px);
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .product-exhibition-col:hover .image-holder {
    transform: translate(-50%, -50%) scale(1.01);
  }
  .product-exhibition-col:hover .btn-white {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff;
  }
}
@media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  .product-exhibition-col:hover .image-holder {
    transform: translate(-50%, -50%) scale(1.02);
  }
}
.product-exhibition-col.product-exhibition-oos::before {
  background-color: #000000;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.product-exhibition-col.product-exhibition-oos .image-holder {
  opacity: 0.4;
}
.product-exhibition-col.product-exhibition-oos .btn-white {
  display: none;
}
.product-exhibition-col.product-exhibition-oos .btn-oos {
  display: inline-block;
}
.product-exhibition-col .image-holder {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
}
.product-exhibition-col .product-price {
  background-color: #041a2c;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  margin-right: auto;
  padding: 19px 17px 18px;
  position: relative;
}
.product-exhibition-col .product-exhibition-button {
  margin-top: auto;
  position: relative;
  text-align: center;
  width: 100%;
}
.product-exhibition-col .product-exhibition-button .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .product-exhibition-col .product-exhibition-button .btn {
    max-width: 320px;
  }
}
.product-exhibition-col .btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1a1919;
}
.product-exhibition-col .btn-oos {
  background-color: #7f7f7f;
  border-color: #7f7f7f;
  display: none;
}

.product-exhibition-link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

@media (max-width: 767px) {
  .product-explanation-slider {
    background-color: transparent !important;
    padding-bottom: 5px;
  }
}
.product-explanation-slider .product-slider-holder {
  padding-bottom: 37px;
}
@media (min-width: 768px) {
  .product-explanation-slider .product-slider-holder {
    padding: 37px 0;
  }
}
@media (max-width: 767px) {
  .product-explanation-slider .product-main-slider {
    padding: 20px 16px;
  }
}
@media (max-width: 767px) {
  .product-explanation-slider .product-main-slider .slider-inner-wrap {
    background-color: #ffffff;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .slider-inner-wrap {
    max-width: 480px;
  }
}
.product-explanation-slider .product-main-slider .slider-inner-wrap .image-holder {
  background-position: center top;
  background-size: cover;
  height: 375px;
  width: 100%;
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .slider-inner-wrap .image-holder {
    display: none;
  }
}
@media (max-width: 767px) {
  .product-explanation-slider .product-main-slider .step-intro {
    padding: 33px 24px 15px;
  }
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .step-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0 40px;
  }
}
.product-explanation-slider .product-main-slider .step-intro h3 {
  color: #1a1919;
  font-size: 24px;
  letter-spacing: 1.5px;
  text-transform: none;
  margin-bottom: 18px;
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .step-intro h3 {
    font-size: 40px;
    margin-bottom: 18px;
  }
}
.product-explanation-slider .product-main-slider .step-intro p {
  color: #1a1919;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .step-intro p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 23px;
  }
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .step-intro .disclaimer {
    margin: 0 auto;
    max-width: 270px;
  }
}
.product-explanation-slider .product-main-slider .btn-content-video {
  font-size: 10px;
  line-height: 16px;
  padding-left: 0;
  text-transform: uppercase;
}
.product-explanation-slider .product-main-slider .btn-content-video::before {
  display: none;
}
@media (max-width: 767px) {
  .product-explanation-slider .product-main-slider .btn-content-video {
    display: block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .product-explanation-slider .product-main-slider .btn-content-video {
    display: block;
    letter-spacing: 1.7px;
  }
}
@media (max-width: 767px) {
  .product-explanation-slider .product-slider-step {
    padding: 0 8px;
  }
}
.product-explanation-slider h4 {
  color: #886655;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}
@media (min-width: 768px) {
  .product-explanation-slider h4 {
    font-size: 12px;
    letter-spacing: 2px;
    margin-bottom: 19px;
  }
}
.product-explanation-slider .product-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  .product-explanation-slider .product-info {
    margin-bottom: 6px;
  }
}
.product-explanation-slider .product-info-row {
  margin-bottom: 16px;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 768px) {
  .product-explanation-slider .product-info-row {
    margin-bottom: 24px;
  }
}
.product-explanation-slider .product-price {
  color: #1a1919;
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 26px;
  line-height: 30px;
}
@media (max-width: 767px) {
  .product-explanation-slider .btn {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .product-explanation-slider .btn--white {
    border-color: #a4a4a4;
    color: #1a1919;
  }
}
.product-explanation-slider .usp-checkbox-list {
  margin-bottom: 18px;
}
.product-explanation-slider .shipping-info-point {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 17px;
  list-style: none;
  margin-bottom: 12px;
}
.product-explanation-slider .shipping-info-point:last-child {
  margin-bottom: 0;
}
.product-explanation-slider .shipping-info-point .icon-check {
  color: #886655;
  font-size: 10px;
  line-height: 1;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.product-explanation-slider .slider-bar {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .product-explanation-slider .slider-bar {
    margin-top: 20px;
  }
}
.product-explanation-slider .slider-bar .indicator {
  background-color: #aeaeae;
}
@media (max-width: 767px) {
  .product-explanation-slider .slider-bar .indicator {
    background-color: #626262;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-explanation-slider .slick-arrow.slick-prev {
    left: 5px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-explanation-slider .slick-arrow.slick-next {
    right: 5px !important;
  }
}
@media (min-width: 768px) {
  .product-explanation-slider .slider-dots {
    bottom: 67px;
  }
}
.product-explanation-slider.has-dark-bg .slider-bar {
  background-color: #626262;
}
.product-explanation-slider.has-dark-bg .slider-bar .indicator {
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .product-explanation-slider.has-dark-bg .slick-arrow .slider-button {
    background-color: #ffffff;
  }
}
.product-explanation-slider.has-dark-bg .slick-arrow.slick-disabled .slider-button {
  opacity: 0.2;
  visibility: visible;
}
@media (min-width: 768px) {
  .product-explanation-slider.has-dark-bg h1,
  .product-explanation-slider.has-dark-bg h4,
  .product-explanation-slider.has-dark-bg ol,
  .product-explanation-slider.has-dark-bg .step-intro h3,
  .product-explanation-slider.has-dark-bg .step-intro p,
  .product-explanation-slider.has-dark-bg ul,
  .product-explanation-slider.has-dark-bg .product-price,
  .product-explanation-slider.has-dark-bg .btn--secondary {
    color: #ffffff;
  }
  .product-explanation-slider.has-dark-bg .btn-add-to-cart {
    background-color: #ffffff;
    color: #1a1919;
  }
  .product-explanation-slider.has-dark-bg .btn-add-to-cart:hover {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff;
  }
  .product-explanation-slider.has-dark-bg .btn--secondary:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #1a1919;
  }
  .product-explanation-slider.has-dark-bg .explanation-list li {
    background-color: #1c2c4c;
    border: 0;
  }
  .product-explanation-slider.has-dark-bg .explanation-list .key-copy {
    color: #ffffff;
  }
  .product-explanation-slider.has-dark-bg .explanation-list .explanation-copy {
    color: #d0a880;
  }
}

.product-explanation-slider-event h4 {
  font-size: 11px;
  letter-spacing: 1.5px;
  color: #1a1919;
}
.product-explanation-slider-event .explanation-list {
  margin-bottom: 20px;
  border: 0;
}
.product-explanation-slider-event .explanation-list li {
  width: calc(50% - 5px);
  margin-bottom: 10px;
  border: initial;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .product-explanation-slider-event .product-main-slider .slider-inner-wrap {
    background-color: inherit;
  }
  .product-explanation-slider-event .explanation-list {
    justify-content: space-between;
  }
  .product-explanation-slider-event .explanation-list li {
    min-height: initial;
  }
}
@media (min-width: 768px) {
  .product-explanation-slider-event.product-slider-block {
    padding: 0 0 31px;
  }
  .product-explanation-slider-event .product-slider-media-holder {
    width: 60%;
  }
  .product-explanation-slider-event.product-slider-reverted .product-slider-steps {
    padding-right: 60%;
  }
  .product-explanation-slider-event:not(.product-slider-reverted) .product-slider-steps {
    padding-left: 60%;
  }
  .product-explanation-slider-event .product-main-slider .step-intro {
    min-height: initial;
    padding-top: 0;
  }
  .product-explanation-slider-event .product-main-slider .slick-arrow.slick-prev {
    left: 30px;
  }
}

.explanation-list {
  border-left: 1px solid #cabab0;
  border-top: 1px solid #cabab0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 768px) {
  .explanation-list {
    border: 0;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 390px;
    padding-top: 20px;
  }
}
.explanation-list li {
  align-items: center;
  border-bottom: 1px solid #cabab0;
  border-right: 1px solid #cabab0;
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  min-height: 146px;
  padding: 20px 15px;
  width: 50%;
}
@media (min-width: 768px) {
  .explanation-list li {
    border: 1px solid #cabab0;
    margin-bottom: 24px;
    min-height: 80px;
    padding: 15px 20px;
    width: calc(50% - 13px);
  }
}
.explanation-list span {
  display: block;
  margin-bottom: 10px;
}
.explanation-list span:last-child {
  margin-bottom: 0;
}
.explanation-list .key-copy {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .explanation-list .key-copy {
    letter-spacing: 0;
  }
}
.explanation-list .explanation-copy {
  color: #9a7b69;
  font-size: 10px;
  letter-spacing: 1.7px;
  line-height: 16px;
  text-transform: uppercase;
}

.simple-text-block {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 0;
}
.simple-text-block.text-block-maintenance {
  padding: 32px 0 37px;
}
@media (max-width: 767px) {
  .simple-text-block.text-block-maintenance {
    padding-top: 30px;
  }
}
.simple-text-block.text-block-maintenance .inner-wrapper {
  max-width: 735px;
}
.simple-text-block.text-block-maintenance .title {
  font-size: 11px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: uppercase;
  color: #886655;
  letter-spacing: 1.5px;
  line-height: 18px;
  display: block;
  margin-bottom: 19px;
}
@media (max-width: 767px) {
  .simple-text-block.text-block-maintenance .title {
    margin-bottom: 14px;
  }
}
.simple-text-block.text-block-maintenance p {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.8px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  width: 100%;
}
.simple-text-block.text-block-maintenance p:first-of-type {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .simple-text-block.text-block-maintenance p:first-of-type {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .simple-text-block.text-block-maintenance p {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.6px;
  }
}
@media (min-width: 768px) {
  .simple-text-block {
    padding: 30px 0;
  }
}
.simple-text-block .inner-wrapper {
  max-width: 560px;
  min-height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}
@media (max-width: 767px) {
  .simple-text-block .inner-wrapper {
    min-height: 50px;
  }
}
@media (min-width: 768px) {
  .simple-text-block .inner-wrapper {
    padding: 0 40px;
    max-width: 1140px;
    min-height: 50px;
  }
}
@media (min-width: 1024px) {
  .simple-text-block .inner-wrapper {
    padding: 0 20px;
    max-width: 740px;
    min-height: 50px;
  }
}
.simple-text-block .inner-wrapper p,
.simple-text-block .inner-wrapper span {
  max-width: 100%;
}
.simple-text-block .inner-wrapper .sub-heading {
  margin-top: 8px;
}
.simple-text-block .inner-wrapper .sub-heading,
.simple-text-block .inner-wrapper .sub-heading a {
  color: #9a7b69;
  font-size: 12px;
}
.simple-text-block .link-wrapper {
  width: 100%;
  padding: 0 20px;
  margin-top: 38px;
}
@media (min-width: 768px) {
  .simple-text-block .link-wrapper {
    padding: 0 20px 30px;
    margin-top: 0;
  }
}
.simple-text-block .link-wrapper .link, .simple-text-block .link-wrapper a {
  margin: 0 14px 20px;
  display: inline-block;
}
@media (min-width: 768px) {
  .simple-text-block .link-wrapper .link, .simple-text-block .link-wrapper a {
    margin: 0 22px 20px;
  }
}
@media (min-width: 1024px) {
  .simple-text-block .link-wrapper .link, .simple-text-block .link-wrapper a {
    margin-bottom: 0;
  }
}
.simple-text-block.simple-text-block-small .inner-wrapper {
  min-height: 280px;
  padding: 20px;
  max-width: 340px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .simple-text-block.simple-text-block-small .inner-wrapper {
    padding: 20px 40px;
    max-width: 1140px;
  }
}
@media (min-width: 1024px) {
  .simple-text-block.simple-text-block-small .inner-wrapper {
    padding: 20px;
    max-width: 340px;
  }
}
@media (max-width: 767px) {
  .simple-text-block .long-description {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .home-page .simple-text-block {
    padding: 32px 0;
  }
  .home-page .simple-text-block .inner-wrapper {
    min-height: 120px;
  }
  .home-page .simple-text-block .inner-wrapper p {
    margin-bottom: 0;
  }
  .home-page .simple-text-block.simple-text-block-slider .inner-wrapper {
    min-height: 10px;
  }
  .home-page .simple-text-block.simple-text-block-slider .inner-wrapper p {
    margin-bottom: 10px;
  }
  .home-page .simple-text-block.drag-list {
    padding: 30px 0 10px;
  }
}

@media (min-width: 768px) {
  .home-page .advent-gradient .simple-text-block {
    padding: 30px 0;
  }
}
.home-page .advent-gradient .simple-text-block .inner-wrapper .heading-intro {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}
.home-page .advent-gradient .simple-text-block .inner-wrapper p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 10px;
}
.home-page .advent-gradient .simple-text-block .inner-wrapper .acc-content-head {
  padding-left: 0;
}
.home-page .advent-gradient .simple-text-block .inner-wrapper .acc-content-head::before {
  left: unset;
  right: -40px;
}
.home-page .advent-gradient .simple-text-block .inner-wrapper br {
  display: none;
}

.simple-text-block-slider .inner-wrapper {
  display: block;
  min-height: 10px;
}
.simple-text-block-slider .slick-slider::before, .simple-text-block-slider .slick-slider::after {
  bottom: 0;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 50px;
  z-index: 3;
}
.simple-text-block-slider .slick-slider::before {
  background: linear-gradient(to left, rgba(246, 244, 242, 0), #f6f4f2);
  left: 0;
}
.simple-text-block-slider .slick-slider::after {
  background: linear-gradient(to right, rgba(246, 244, 242, 0), #f6f4f2);
  right: 0;
}
.simple-text-block-slider .slider-item + .slider-item {
  display: none;
}
.simple-text-block-slider .slider-item a {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
.simple-text-block-slider .slick-dots {
  font-size: 0;
  list-style: none;
  text-align: center;
}
.simple-text-block-slider .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.simple-text-block-slider .slick-dots button {
  background-color: #d1d0ce;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 4px;
  width: 4px;
}
.simple-text-block-slider .slick-dots .slick-active button {
  background-color: #453f3f;
}
.simple-text-block-slider.white-bg-color .slick-slider::before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}
.simple-text-block-slider.white-bg-color .slick-slider::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
.simple-text-block-slider.black-bg-color .slick-slider::before {
  background: linear-gradient(to left, rgba(26, 25, 25, 0), #1a1919);
}
.simple-text-block-slider.black-bg-color .slick-slider::after {
  background: linear-gradient(to right, rgba(26, 25, 25, 0), #1a1919);
}
.simple-text-block-slider.simple-text-block-pd {
  background-color: #f6f4f2;
}
.simple-text-block-slider.simple-text-block-pd p {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 33px;
  margin: 0 auto 10px;
  max-width: 400px;
}
.simple-text-block-slider.simple-text-block-pd p a {
  font: inherit;
}
@media (max-width: 767px) {
  .simple-text-block-slider .text-block-slider {
    padding: 15px 0 10px;
    width: 100%;
    -webkit-text-size-adjust: 100%;
  }
  .simple-text-block-slider .text-block-slider::before, .simple-text-block-slider .text-block-slider::after {
    width: 30px;
  }
  .simple-text-block-slider .slider-item {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .simple-text-block-slider .text-block-slider {
    padding: 5px 0 0;
    width: 100%;
  }
}

.shipping-info-giftsets-slider .inner-wrapper {
  display: block;
  min-height: 10px;
  max-width: 280px;
}
.shipping-info-giftsets-slider .slider-item + .slider-item {
  display: none;
}
.shipping-info-giftsets-slider .slider-item > div {
  position: relative;
  text-align: center;
  font-size: 31px;
  width: 30px;
  margin: 0 auto 20px;
  height: 31px;
  color: #9a7b69;
}
.shipping-info-giftsets-slider .slider-item > div::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea28";
  display: inline-block;
  line-height: 1;
}
.shipping-info-giftsets-slider .slider-item p {
  text-align: center;
  color: #1a1919;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.shipping-info-giftsets-slider .slider-item a {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.shipping-info-giftsets-slider .slick-dots {
  font-size: 0;
  list-style: none;
  text-align: center;
}
.shipping-info-giftsets-slider .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.shipping-info-giftsets-slider .slick-dots button {
  background-color: #d1d0ce;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 4px;
  width: 4px;
}
.shipping-info-giftsets-slider .slick-dots .slick-active button {
  background-color: #453f3f;
}
.shipping-info-giftsets-slider.simple-text-block-pd p {
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 33px;
  margin: 0 auto 10px;
  max-width: 400px;
}
.shipping-info-giftsets-slider.simple-text-block-pd p a {
  font: inherit;
}
@media (max-width: 767px) {
  .shipping-info-giftsets-slider .slider-item {
    padding: 0 10px;
  }
}

.simple-text-block.drag-list .inner-wrapper {
  display: block;
  min-height: 10px;
}
@media (max-width: 767px) {
  .simple-text-block.drag-list .inner-wrapper {
    max-width: 100%;
    padding: 0;
  }
}
.simple-text-block.drag-list .text-block-slider {
  width: 100%;
}
@media (max-width: 767px) {
  .simple-text-block.drag-list .text-block-slider {
    padding: 15px 0 10px;
  }
}
@media (min-width: 768px) {
  .simple-text-block.drag-list .text-block-slider {
    padding: 5px 0 26px;
  }
}
@media (max-width: 767px) {
  .simple-text-block.drag-list .slider-item {
    width: calc(100vw - 30px);
  }
}
.simple-text-block.drag-list .slider-item a {
  font-size: inherit;
  letter-spacing: 0.5px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
}
@media (max-width: 767px) {
  .simple-text-block.drag-list .drag-list-body {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .simple-text-block.drag-list .drag-list-body {
    padding: 0;
  }
}

.three-tile-block {
  background-color: #f6f4f2;
  display: flex;
  padding: 30px 0;
}
.three-tile-block .inner-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.three-tile-block.invert .image-holder {
  order: 2;
}
@media (min-width: 768px) {
  .three-tile-block .inner-wrapper {
    flex-direction: row;
    max-width: 1360px;
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .three-tile-block-phone-slider .simplebar-content {
    display: flex;
  }
}
@media (max-width: 767px) {
  .three-tile-block-phone-slider .drag-list-body {
    padding: 0 20px;
  }
  .three-tile-block-phone-slider .three-tile-box {
    width: 95%;
    margin-right: 10px;
  }
}

.three-tile-box {
  background-color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}
.three-tile-box .image-holder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 80.3%;
  width: 100%;
}
.three-tile-box a.image-holder {
  display: block;
}
.three-tile-box .text-holder {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 20px 40px;
  width: 100%;
}
.three-tile-box .text-holder .tile-heading {
  font-size: 24px;
  letter-spacing: 0.46px;
  line-height: 34px;
  text-transform: none;
  margin-bottom: 14px;
}
.three-tile-box .text-holder p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}
.three-tile-box .text-holder .btn {
  margin-top: 9px;
  min-width: 0;
}
@media (min-width: 768px) {
  .three-tile-box {
    display: flex;
    flex-direction: column;
    width: 33.333%;
    margin-right: 20px;
  }
  .three-tile-box .image-holder {
    padding-bottom: 96.78%;
  }
  .three-tile-box .text-holder {
    height: 100%;
    padding: 28px 20px 40px;
  }
  .three-tile-box .text-holder .tile-heading {
    margin-bottom: 10px;
  }
  .three-tile-box:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .three-tile-box {
    margin-right: 40px;
  }
  .three-tile-box .text-holder .tile-heading {
    line-height: 44px;
  }
  .three-tile-box .text-holder .btn {
    margin-top: 3px;
    min-width: 220px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .three-tile-box .text-holder * {
    max-width: 100%;
  }
}

.three-tile-horizontal {
  height: auto;
  min-height: 520px;
  padding: 80px 0 30px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .three-tile-horizontal {
    padding: 30px 0;
  }
}
.three-tile-horizontal .inner-wrapper {
  max-width: 1440px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .three-tile-horizontal .inner-wrapper {
    flex-direction: column;
  }
}

.three-tile-pretitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.8px;
}
@media (max-width: 767px) {
  .three-tile-pretitle {
    font-size: 10px;
    letter-spacing: 1.6px;
  }
}

.three-tile-title {
  font-size: 30px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: initial;
  letter-spacing: 1.2px;
  color: #1a1919;
}
@media (max-width: 767px) {
  .three-tile-title {
    font-size: 20px;
  }
}

.three-tile-discount {
  font-size: 24px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-weight: 600;
  color: #1a1919;
}
@media (max-width: 767px) {
  .three-tile-discount {
    font-size: 18px;
  }
}

.three-tile-price {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  color: #7f7f7f;
  text-decoration: line-through;
}
@media (max-width: 767px) {
  .three-tile-price {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .three-tile-title--big {
    font-size: 28px;
    line-height: 38px;
  }
}

.inner-wrapper-block {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .inner-wrapper-block {
    margin-bottom: 50px;
  }
}

.inner-wrapper-block-content {
  text-align: center;
}
.inner-wrapper-block-content p,
.inner-wrapper-block-content span {
  max-width: 650px;
  margin: 0 auto;
}
.inner-wrapper-block-content .em {
  font-style: italic;
}

.text-holder-column {
  display: none;
  flex: 0.9;
  text-align: center;
}
.text-holder-column .text-inner {
  max-width: 332px;
  margin: 0 auto 130px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in 0.4s;
}
@media (min-width: 768px) {
  .text-holder-column .text-inner {
    margin-bottom: 50px;
    min-height: 360px;
  }
}
.text-holder-column .text-inner--anim {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 768px) {
  .text-holder-column {
    display: block;
  }
}

.environment-icon {
  display: block;
  margin: 0 auto 10px;
}

.environment-water {
  background: url("../images/environment-water.svg") center top no-repeat;
  background-size: 70px 70px;
  height: 70px;
  width: 70px;
}

.environment-energy {
  background: url("../images/environment-energy.svg") center top no-repeat;
  background-size: 70px 70px;
  height: 70px;
  width: 70px;
}

.environment-waste {
  background: url("../images/environment-waste.svg") center top no-repeat;
  background-size: 70px 70px;
  height: 70px;
  width: 70px;
}

.environment-co2 {
  background: url("../images/environment-co2.svg") center top no-repeat;
  background-size: 70px 70px;
  height: 70px;
  width: 70px;
}

.text-pretitle {
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 12px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.text-holder-slider {
  width: 100%;
  margin-bottom: 50px;
}
.text-holder-slider .text-block-slider::after {
  display: none;
}
.text-holder-slider .text-block-slider::before {
  display: none;
}
@media (min-width: 768px) {
  .text-holder-slider {
    display: none;
  }
}
@media (max-width: 767px) {
  .text-holder-slider .text-block-slider {
    margin-left: initial;
  }
  .text-holder-slider .product-name {
    letter-spacing: 0.6px;
  }
  .text-holder-slider .long-description {
    font-size: 16px;
  }
}

.image-holder-column {
  flex-shrink: 0;
  flex: 1.1;
}
@media (min-width: 768px) {
  .image-holder-column .refill-jar-wrapper .refill-jar-lid img,
  .image-holder-column .refill-jar-wrapper .refill-jar-content img,
  .image-holder-column .refill-jar-wrapper .refill-jar-container img {
    max-width: none;
    width: 396px;
  }
}
@media (min-width: 768px) {
  .image-holder-column .refill-jar-wrapper .refill-jar-lid {
    margin-bottom: -75px;
  }
}

.gift-benefits-block {
  background-color: #242222 !important;
}

.two-tile-horizontal {
  width: 100%;
}
.two-tile-horizontal.white {
  background-color: #ffffff;
}
.two-tile-horizontal.two-tile-pagedesigner .image-holder {
  position: relative;
}
.two-tile-horizontal.two-tile-pagedesigner .image-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.two-tile-horizontal .inner-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.two-tile-horizontal .image-holder {
  width: 100%;
}
.two-tile-horizontal .image-holder img {
  width: 100%;
}
.two-tile-horizontal .text-holder {
  padding: 35px 20px 40px;
  text-align: center;
  width: 100%;
}
.two-tile-horizontal .video-wrapper {
  margin-top: 25px;
}
.two-tile-horizontal .video-holder {
  display: none;
}
.two-tile-horizontal .video-image-holder {
  width: 100%;
}
.two-tile-horizontal .long-description {
  margin: 0 auto 30px;
}
.two-tile-horizontal .long-description:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .two-tile-horizontal .btn-wrapper {
    margin: 24px 0;
  }
}
@media (min-width: 768px) {
  .two-tile-horizontal .btn-wrapper {
    margin: 30px 0;
  }
}
@media (min-width: 768px) {
  .two-tile-horizontal {
    align-items: center;
    display: flex;
    min-height: 520px;
    padding: 30px 0;
    height: auto;
  }
  .two-tile-horizontal .inner-wrapper {
    align-items: center;
    flex-direction: row;
    max-width: 1480px;
    padding: 0 20px;
  }
  .two-tile-horizontal .video-image-holder {
    width: 50%;
  }
  .two-tile-horizontal .video-image-holder .image-holder {
    display: none;
  }
  .two-tile-horizontal .image-holder {
    flex-shrink: 0;
    width: 50%;
  }
  .two-tile-horizontal .image-holder img {
    display: block;
    margin: 0 auto;
    width: 72%;
    max-width: 520px;
  }
  .two-tile-horizontal .video-holder {
    display: block;
    flex-shrink: 0;
    height: 0;
    position: relative;
    padding-bottom: 72%;
    width: 72%;
    margin: 0 auto;
  }
  .two-tile-horizontal .text-holder {
    width: 50%;
    padding: 30px;
  }
  .two-tile-horizontal .text-inner:not(.wide) {
    margin: 0 auto;
    max-width: 450px;
  }
  .two-tile-horizontal .text-inner:not(.wide) .long-description {
    max-width: 380px;
    width: 100%;
  }
  .two-tile-horizontal .long-description {
    line-height: 28px;
  }
  .two-tile-horizontal.invert .text-holder {
    order: -1;
    padding: 30px 70px 30px 20px;
  }
  .two-tile-horizontal.two-tile-horizontal-medium {
    min-height: 540px;
  }
  .two-tile-horizontal.two-tile-horizontal-medium .inner-wrapper {
    max-width: 1440px;
  }
  .two-tile-horizontal.two-tile-horizontal-medium .two-tile-narrow-col {
    flex-shrink: 0;
    width: 27.4%;
  }
  .two-tile-horizontal.two-tile-horizontal-medium .image-holder {
    width: 34.3%;
  }
  .two-tile-horizontal.two-tile-horizontal-medium .text-holder {
    flex-shrink: 0;
    width: 38%;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .two-tile-horizontal {
    height: 760px;
    padding: 120px 0;
  }
  .two-tile-horizontal.two-tile-horizontal-medium {
    height: 540px;
    padding: 80px 0;
  }
}

.two-tile-vertical {
  display: flex;
  padding: 100px 0;
}
.two-tile-vertical .inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.two-tile-vertical .inner-wrapper > div:not([class]) {
  max-width: 100%;
}
.two-tile-vertical .video-holder {
  display: none;
}
.two-tile-vertical .text-holder {
  margin: 20px 0;
  max-width: 100%;
}
.two-tile-vertical .text-holder h1 {
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  text-transform: none;
}
.two-tile-vertical .text-holder p {
  max-width: 455px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.two-tile-vertical .image-holder {
  margin: 20px 0;
  max-width: 100%;
  transition: transform 1.3s cubic-bezier(0.18, 0.74, 0.36, 0.99);
  width: 100%;
}
.two-tile-vertical .image-holder img {
  width: 100%;
}
.two-tile-vertical[class*=in-viewport] {
  overflow: hidden;
}
.two-tile-vertical.in-viewport .image-holder {
  transform: scale(1);
}
.two-tile-vertical.not-in-viewport .image-holder {
  transform: scale(1.2);
}
.two-tile-vertical .link-wrapper .link, .two-tile-vertical .link-wrapper a {
  margin: 0 14px 20px;
  display: inline-block;
}
.two-tile-vertical img {
  max-width: 100%;
}
@media (min-width: 768px) {
  .two-tile-vertical {
    min-height: 900px;
    padding: 0 0 30px;
  }
  .two-tile-vertical .video-holder {
    display: block;
    flex-shrink: 0;
    height: 0;
    padding-bottom: 44.183%;
    position: relative;
    width: 100%;
  }
  .two-tile-vertical .link-wrapper + .video-image-holder {
    margin-top: 60px;
  }
  .two-tile-vertical .image-holder {
    margin: 60px 0;
  }
  .two-tile-vertical .text-holder {
    margin: 30px 0;
  }
  .two-tile-vertical .text-holder h1 {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
  }
  .two-tile-vertical .video-image-holder {
    width: 100%;
  }
  .two-tile-vertical .video-image-holder .image-holder {
    display: none;
  }
  .two-tile-vertical .link-wrapper {
    margin-bottom: -7px;
  }
  .two-tile-vertical .link-wrapper .link, .two-tile-vertical .link-wrapper a {
    margin: 0 22px 20px;
  }
}
@media (min-width: 1024px) {
  .two-tile-vertical .link-wrapper .link, .two-tile-vertical .link-wrapper a {
    margin-bottom: 0;
  }
}
.two-tile-vertical.two-tile-vertical-small {
  max-width: 360px;
  min-height: 480px;
  padding: 30px 0;
}
.two-tile-vertical.two-tile-vertical-small .image-holder,
.two-tile-vertical.two-tile-vertical-small .text-holder {
  margin: 10px 0;
}
.two-tile-vertical.two-tile-vertical-small .image-holder {
  min-height: 1px;
}
.two-tile-vertical.two-tile-vertical-small .btn {
  margin: 10px 0;
}
.two-tile-vertical.invert .inner-wrapper {
  flex-direction: column-reverse;
}

.usp-text-two-cols {
  display: flex;
  text-align: center;
  width: 100%;
}
.usp-text-two-cols .text-col {
  width: 100%;
}
.usp-text-two-cols .link {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .usp-text-two-cols .inner-wrapper {
    padding: 0;
  }
  .usp-text-two-cols .text-col {
    background-color: #fcfaf7;
    margin-bottom: 1px;
    padding: 33px 20px;
  }
  .usp-text-two-cols .text-col:last-child {
    margin-bottom: 0;
  }
  .usp-text-two-cols .heading-h10 {
    margin-bottom: 14px;
  }
  .usp-text-two-cols p {
    margin-bottom: 14px;
  }
}
@media (min-width: 768px) {
  .usp-text-two-cols {
    background-color: #fcfaf7;
    padding: 28px 0 32px;
  }
  .usp-text-two-cols .text-col {
    float: left;
    width: 50%;
  }
  .usp-text-two-cols .heading-h10 {
    margin-bottom: 2px;
  }
  .usp-text-two-cols p {
    margin-bottom: 10px;
  }
}

.usp-text-slider {
  position: relative;
  width: 100%;
  text-align: center;
}
.usp-text-slider:not(.drag-list) .item + .item {
  display: none;
}
.usp-text-slider .item {
  padding: 0 15px;
  position: relative;
}
.usp-text-slider p {
  color: #453f3f;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 18px;
}
.usp-text-slider .highlighted {
  display: block;
  font-weight: bold;
}
.usp-text-slider .slick-dots {
  display: flex;
  font-size: 0;
  list-style: none;
  justify-content: center;
  margin: 8px 0 0;
  text-align: center;
  width: 100%;
}
.usp-text-slider .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.usp-text-slider .slick-dots button {
  background-color: #d8d8d8;
  border-radius: 50%;
  display: block;
  font-size: 0;
  height: 4px;
  width: 4px;
}
.usp-text-slider .slick-dots .slick-active button {
  background-color: #453f3f;
}
@media (min-width: 1024px) {
  .usp-text-slider::before, .usp-text-slider::after {
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    width: 15px;
    z-index: 3;
  }
  .usp-text-slider::before {
    background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    left: 0;
  }
  .usp-text-slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    right: 0;
  }
}

@media (max-width: 767px) {
  .usp-text-slider.drag-list::before, .usp-text-slider.drag-list::after {
    bottom: 4px;
    content: "";
    position: absolute;
    top: 0;
    width: 15px;
    z-index: 3;
  }
  .usp-text-slider.drag-list::before {
    background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    left: 0;
  }
  .usp-text-slider.drag-list::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    right: 0;
  }
}
.usp-text-slider.drag-list .drag-list-body {
  padding: 0;
}
.usp-text-slider.drag-list .item {
  padding: 0 0 8px;
}
@media (max-width: 767px) {
  .usp-text-slider.drag-list .item {
    width: calc(100vw - 80px);
  }
}
.usp-text-slider.drag-list p {
  padding: 0 15px;
}

.product-highlight-block {
  width: 100%;
  padding: 30px 0;
}
.product-highlight-block .inner-wrapper {
  max-width: none;
  padding: 0;
}
@media (min-width: 768px) {
  .product-highlight-block .inner-wrapper {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
  }
}
.product-highlight-block .image-holder {
  width: 100%;
}
.product-highlight-block .image-holder img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.question-block {
  padding: 63px 20px 78px;
  text-align: center;
  color: #453f3f;
  background-color: #f5f4f2;
}
@media (min-width: 768px) {
  .question-block {
    padding: 122px 20px 116px;
  }
}
.question-block .text-container {
  color: #453f3f;
}
.question-block .text-container .icon-search {
  font-size: 27px;
}
.question-block .text-container .icon-search-magnifier {
  font-size: 25px;
}
.question-block .text-container span {
  font-size: 14px;
  letter-spacing: 1px;
}
.question-block .question {
  font-size: 24px;
  text-transform: none;
  margin-bottom: 6px;
}
.question-block .contact {
  display: block;
  margin-bottom: -8px;
}
.question-block a {
  color: #453f3f;
  cursor: pointer;
  font-size: 14px;
}

.two-tile-text-block {
  width: 100%;
  padding: 20px 0;
}
@media (min-width: 768px) {
  .two-tile-text-block.invert .text-holder {
    order: -1;
  }
}
.two-tile-text-block.adjacent-blocks + .two-tile-text-block {
  margin-top: -40px;
}
.two-tile-text-block > .inner-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}
@media (min-width: 768px) {
  .two-tile-text-block > .inner-wrapper {
    flex-direction: row;
    max-width: 1480px;
    padding: 0 20px;
  }
}
.two-tile-text-block.full-width {
  display: flex;
  flex-direction: column;
  padding: 0;
}
@media (max-width: 767px) {
  .two-tile-text-block.full-width {
    background-color: transparent !important;
  }
}
@media (min-width: 768px) {
  .two-tile-text-block.full-width {
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .two-tile-text-block.full-width.reverse-order {
    flex-direction: column-reverse;
  }
}
.two-tile-text-block.full-width .image-holder {
  position: relative;
}
@media (max-width: 767px) {
  .two-tile-text-block.full-width .image-holder {
    min-height: 288px;
  }
}
.two-tile-text-block.full-width .responsive-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.two-tile-text-block.full-width .text-holder {
  height: 100%;
  text-align: center;
  min-height: 0;
  padding: 25px 20px 20px;
}
@media (min-width: 768px) {
  .two-tile-text-block.full-width .text-holder {
    min-height: 700px;
    max-width: 720px;
    padding: 40px 30px;
  }
}
@media (min-width: 1024px) {
  .two-tile-text-block.full-width .text-holder {
    padding: 100px 145px;
  }
}
@media (min-width: 1440px) {
  .two-tile-text-block.full-width .text-holder {
    align-items: center;
    display: flex;
    max-width: 50%;
  }
}
@media (min-width: 1440px) {
  .two-tile-text-block.full-width .text-holder h3,
  .two-tile-text-block.full-width .text-holder p {
    max-width: 430px;
    width: 100%;
  }
}
.two-tile-text-block.full-width .headline {
  font-size: 24px;
  letter-spacing: 1.5px;
  line-height: 32px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .two-tile-text-block.full-width .headline {
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) {
  .two-tile-text-block.secondary-text-styling .headline {
    color: #453f3f;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}
.two-tile-text-block.secondary-text-styling p {
  color: #453f3f;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
}
.two-tile-text-block.value-packs {
  background-color: #efedea;
}
@media (max-width: 767px) {
  .two-tile-text-block.value-packs > .inner-wrapper {
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) {
  .two-tile-text-block.value-packs .headline {
    color: #453f3f;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}
.two-tile-text-block.value-packs .image-holder {
  background-size: cover;
}
.two-tile-text-block.value-packs .content-list li {
  margin-bottom: 20px;
}
.two-tile-text-block.value-packs .icon-check,
.two-tile-text-block.value-packs .icon-close-thick {
  color: #886655 !important;
}
.two-tile-text-block.value-packs span:not(.ico) {
  color: #453f3f;
}
@media (max-width: 767px) {
  .two-tile-text-block.value-packs span:not(.ico) {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  .two-tile-text-block.value-packs span:not(.ico) {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
  }
}
.two-tile-text-block.green-friday {
  background-color: #efedea;
}
.two-tile-text-block.green-friday > .inner-wrapper {
  max-width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  .two-tile-text-block.green-friday .headline {
    color: #453f3f;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}
.two-tile-text-block.green-friday .image-holder {
  background-size: cover;
}
.two-tile-text-block.green-friday .text-holder {
  max-width: 100%;
}
@media (min-width: 768px) {
  .two-tile-text-block.green-friday .text-holder {
    max-width: 100%;
  }
}
.two-tile-text-block.green-friday .center-list {
  text-align: center;
}
.two-tile-text-block.green-friday .center-list .content-list {
  display: inline-block;
  text-align: left;
  margin-left: 30px;
}
.two-tile-text-block.green-friday .center-list .content-list li {
  margin-bottom: 20px;
}
.two-tile-text-block.green-friday .icon-check,
.two-tile-text-block.green-friday .icon-close-thick {
  color: #886655 !important;
}
.two-tile-text-block.green-friday span:not(.ico) {
  color: #453f3f;
}
@media (max-width: 767px) {
  .two-tile-text-block.green-friday span:not(.ico) {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  .two-tile-text-block.green-friday span:not(.ico) {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
  }
}
.two-tile-text-block.transparent-bg .text-holder {
  background-color: transparent;
}
.two-tile-text-block.sandy-light-bg .text-holder {
  background-color: #fcfaf7;
}
.two-tile-text-block .text-holder {
  background-color: #ffffff;
  padding: 40px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 330px;
}
@media (min-width: 768px) {
  .two-tile-text-block .text-holder {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .two-tile-text-block .text-holder {
    padding: 100px;
  }
}
.two-tile-text-block .image-holder {
  width: 100%;
}
@media (min-width: 768px) {
  .two-tile-text-block .image-holder {
    width: 50%;
  }
}
.two-tile-text-block .two-tile-text-block-slider {
  position: relative;
  height: 330px;
}
@media (min-width: 768px) {
  .two-tile-text-block .two-tile-text-block-slider {
    height: 100%;
  }
}
.two-tile-text-block .two-tile-text-block-slider .responsive-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.two-tile-text-block .two-tile-text-block-slider .item {
  min-height: 330px;
  height: 100%;
}
@media (min-width: 768px) {
  .two-tile-text-block .two-tile-text-block-slider .item {
    min-height: 642px;
  }
}
.two-tile-text-block .two-tile-text-block-slider .slick-list,
.two-tile-text-block .two-tile-text-block-slider .slick-track {
  height: 100%;
}
.two-tile-text-block .two-tile-text-block-slider .slick-slide {
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
}
.two-tile-text-block .two-tile-text-block-slider .slick-slide.slick-active {
  opacity: 1;
}
.two-tile-text-block .two-tile-text-block-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.two-tile-text-block .two-tile-text-block-slider .slick-prev {
  left: 10px;
}
@media (min-width: 768px) {
  .two-tile-text-block .two-tile-text-block-slider .slick-prev {
    left: 48px;
  }
}
.two-tile-text-block .two-tile-text-block-slider .slick-next {
  right: 10px;
}
@media (min-width: 768px) {
  .two-tile-text-block .two-tile-text-block-slider .slick-next {
    right: 48px;
  }
}
.two-tile-text-block .two-tile-text-block-slider:not(.drag-list-scroll) {
  overflow: hidden;
}
.two-tile-text-block .two-tile-text-block-slider:not(.drag-list-scroll) .item + .item {
  display: none;
}
.two-tile-text-block .two-tile-text-block-slider:not(.drag-list-scroll) .slick-arrow .slider-button {
  height: 30px;
  width: 30px;
  font-size: 32px;
  background-color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 768px) {
  .two-tile-text-block .two-tile-text-block-slider:not(.drag-list-scroll) .slick-arrow .slider-button {
    height: 50px;
    width: 50px;
    font-size: 44px;
  }
  .two-tile-text-block .two-tile-text-block-slider:not(.drag-list-scroll) .slick-arrow .slider-button:hover {
    background-color: white;
  }
}
.two-tile-text-block .headline {
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  text-transform: none;
  margin-bottom: 23px;
}
@media (min-width: 768px) {
  .two-tile-text-block .headline {
    margin-bottom: 32px;
  }
}
.two-tile-text-block .headline .ico {
  font-size: 40px;
  margin-right: 11px;
  color: #58595b;
}
@media (max-width: 767px) {
  .two-tile-text-block .headline .ico {
    display: block;
    margin-bottom: 25px;
  }
}
.two-tile-text-block p,
.two-tile-text-block a,
.two-tile-text-block button:not(.slider-button) {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.two-tile-text-block button:not(.slider-button) {
  width: auto;
  margin-bottom: 10px;
  text-align: left;
}
.two-tile-text-block p.small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
}
.two-tile-text-block p button {
  margin: 0;
}
.two-tile-text-block .content-list {
  list-style-type: none;
  text-align: left;
}
@media (min-width: 1024px) {
  .two-tile-text-block .content-list {
    margin-left: -31px;
  }
}
.two-tile-text-block .content-list li {
  margin-bottom: 10px;
}
.two-tile-text-block .content-list li span {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  width: calc(100% - 40px);
  display: inline-block;
}
.two-tile-text-block .content-list li .ico {
  font-size: 14px;
  margin-right: 9px;
  vertical-align: top;
  display: inline-block;
  width: 15px;
}
.two-tile-text-block .content-list li .icon-check {
  color: #3bd321;
}
.two-tile-text-block .content-list li .icon-close-thick {
  color: #d0051a;
}

.two-tile-text-block .drag-list {
  margin-bottom: 0;
}
.two-tile-text-block .drag-list .simplebar-content {
  min-height: 100%;
}
.two-tile-text-block .drag-list .simplebar-content .drag-list-body {
  min-height: 100%;
}
.two-tile-text-block .drag-list .drag-list-body {
  padding: 0;
}
@media (max-width: 767px) {
  .two-tile-text-block .drag-list .item {
    width: 100vw;
  }
}
.two-tile-text-block .drag-list .slider-button {
  background-color: rgba(255, 255, 255, 0.5);
}
.two-tile-text-block .drag-list .slider-button:hover {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .two-tile-text-block .drag-list .drag-list-controls {
    display: block;
  }
  .two-tile-text-block .drag-list .drag-list-controls .slider-button {
    font-size: 32px;
    height: 30px;
    width: 30px;
  }
  .two-tile-text-block .drag-list .drag-list-controls .icon-arrow3--left {
    left: 10px;
  }
  .two-tile-text-block .drag-list .drag-list-controls .icon-arrow3--right {
    right: 10px;
  }
}

.three-tile-magazine-block {
  padding: 66px 0 36px;
}
.three-tile-magazine-block .inner-wrapper {
  max-width: 1360px;
}
.three-tile-magazine-block .subtitle {
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 18px;
  color: #886655;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .three-tile-magazine-block .subtitle {
    margin-bottom: 1px;
  }
}
.three-tile-magazine-block .title {
  font-size: 24px;
  letter-spacing: 1.2px;
  line-height: 30px;
  text-transform: none;
  text-align: center;
  color: #1a1919;
  max-width: 165px;
  margin: 0 auto 40px;
}
@media (min-width: 768px) {
  .three-tile-magazine-block .title {
    letter-spacing: 0.46px;
    line-height: 44px;
    margin-bottom: 29px;
    max-width: none;
  }
}
.three-tile-magazine-block .simplebar-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .three-tile-magazine-block .simplebar-content {
    flex-direction: row;
    justify-content: space-between;
  }
}

.magazine-tile-wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .magazine-tile-wrapper {
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .magazine-tile-wrapper.magazine-tile-slider:not(.drag-list-scroll) {
    display: block;
  }
  .magazine-tile-wrapper.magazine-tile-slider:not(.drag-list-scroll) .magazine-tile + .magazine-tile {
    display: none;
  }
  .magazine-tile-wrapper.magazine-tile-slider .magazine-tile {
    margin-bottom: 0;
  }
  .magazine-tile-wrapper.magazine-tile-slider .magazine-tile .text-holder {
    vertical-align: top;
  }
  .magazine-tile-wrapper.magazine-tile-slider .slick-dots {
    font-size: 0;
    list-style: none;
    text-align: center;
    margin-top: -4px;
  }
  .magazine-tile-wrapper.magazine-tile-slider .slick-dots li {
    display: inline-block;
    margin: 0 2px;
  }
  .magazine-tile-wrapper.magazine-tile-slider .slick-dots button {
    background-color: #aeaeae;
    border-radius: 50%;
    display: inline-block;
    font-size: 0;
    height: 5px;
    width: 5px;
  }
  .magazine-tile-wrapper.magazine-tile-slider .slick-dots .slick-active button {
    background-color: #453f3f;
  }
}

.magazine-tile {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.magazine-tile:not(:last-of-type) {
  margin-bottom: 37px;
}
@media (min-width: 768px) {
  .magazine-tile {
    flex-direction: column;
    margin-bottom: 0;
    margin: 0 10px;
    max-width: 315px;
  }
}
.magazine-tile .image-holder {
  display: inline-block;
  position: relative;
}
@media (max-width: 767px) {
  .magazine-tile .image-holder {
    width: 49%;
  }
}
.magazine-tile .image-holder a {
  display: block;
  width: 100%;
}
.magazine-tile .image-holder a .image {
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 130px;
}
@media (min-width: 768px) {
  .magazine-tile .image-holder a .image {
    min-height: 245px;
  }
}
.magazine-tile .text-holder {
  text-align: center;
  padding: 20px;
  display: inline-block;
}
@media (max-width: 767px) {
  .magazine-tile .text-holder {
    width: 49%;
    text-align: left;
    padding: 5px 10px 5px 19px;
  }
}
.magazine-tile .ritual-name {
  color: #886655;
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 18px;
  text-transform: uppercase;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  display: block;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .magazine-tile .ritual-name {
    margin-bottom: 10px;
  }
}
.magazine-tile .description {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  color: #1a1919;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin: 0 auto 7px;
}
@media (min-width: 768px) {
  .magazine-tile .description {
    max-width: 250px;
    margin-bottom: 17px;
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
}
.magazine-tile .link {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .magazine-tile .link {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.6px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .three-tile-magazine-block .drag-list {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}
.three-tile-magazine-block .drag-list .drag-list-body {
  padding: 0;
}
@media (max-width: 767px) {
  .three-tile-magazine-block .drag-list .magazine-tile {
    padding: 0 20px 10px;
    width: calc(100vw + 40px);
  }
}

.animated-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.animated-banner .fade-in-element {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1), transform 1s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.animated-banner .fade-in-element.visible {
  opacity: 1;
  transform: translateY(0);
}
.animated-banner .fade-in-element.video-holder {
  transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1), width 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (min-width: 768px) {
  .animated-banner .fade-in-element.video-wrapper {
    inset: 0 auto auto 0 !important;
  }
}
.animated-banner .text-holder {
  left: 0;
  position: absolute;
  text-align: center;
  transition: transform 0.3s linear;
  width: 100%;
  will-change: transform;
  z-index: 10;
}
@media (max-width: 767px) {
  .animated-banner .text-holder {
    padding: 40px 0;
  }
}
@media (min-width: 768px) {
  .animated-banner .text-holder {
    padding: 100px 0 50px 0;
  }
}
.animated-banner .text-holder.visible .hidden-content {
  opacity: 1;
}
.animated-banner .text-holder.visible .visible-content {
  opacity: 0;
}
.animated-banner .text-holder .toggle-content {
  position: relative;
}
.animated-banner .text-holder .toggle-content:first-of-type {
  margin-bottom: 28px;
}
@media (max-width: 767px) {
  .animated-banner .text-holder .toggle-content:first-of-type {
    margin-bottom: 10px;
  }
}
.animated-banner .text-holder .visible-content,
.animated-banner .text-holder .hidden-content {
  transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.animated-banner .text-holder .hidden-content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.animated-banner .subheading {
  font-size: 22px;
  letter-spacing: 5px;
  line-height: 32px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: uppercase;
  display: block;
}
@media (max-width: 767px) {
  .animated-banner .subheading {
    font-size: 12px;
    letter-spacing: 2.3px;
    line-height: 32px;
  }
}
.animated-banner .heading {
  font-size: 80px;
  letter-spacing: 4.3px;
  line-height: 90px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: none;
  margin-bottom: 35px;
}
@media (max-width: 320px) {
  .animated-banner .heading {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .animated-banner .heading {
    font-size: 42px;
    letter-spacing: 2.25px;
    line-height: 52px;
    margin-bottom: 22px;
  }
}
.animated-banner .countdown-line {
  display: block;
  color: #ffffff;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.7px;
  margin: -8px 0 40px 0;
}
.animated-banner .scroll-icon-wrapper {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding-bottom: 50px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.animated-banner .scroll-icon {
  position: sticky;
}
@media (max-width: 767px) {
  .animated-banner .scroll-icon {
    height: 45px;
    width: auto;
  }
}
.animated-banner .scroll-icon .mousePart {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scrollIndicator;
}
@keyframes scrollIndicator {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(75%);
  }
}
.animated-banner .scroll-animation-wrapper {
  margin-top: 335px;
  min-height: 1px;
  padding: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .animated-banner .scroll-animation-wrapper {
    margin-top: 250px;
  }
}
@media (min-width: 768px) {
  .animated-banner .scroll-animation-wrapper {
    margin-top: 500px;
  }
}
@media (min-width: 1024px) {
  .animated-banner .scroll-animation-wrapper {
    margin-top: 400px;
  }
}
.animated-banner .video-wrapper {
  position: relative;
  width: 100%;
}
.animated-banner .video-wrapper .s7videoplayer video {
  opacity: 0.4;
}
.animated-banner .video-holder {
  position: relative;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  transform: translateZ(0);
}
.animated-banner .countdown {
  display: block;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  font-size: 20px;
  letter-spacing: 0.7px;
  margin: -10px 0 36px;
}
.animated-banner .btn {
  margin-bottom: 30px;
}
@media (max-width: 320px) {
  .animated-banner .btn {
    margin-bottom: 25px;
  }
}
.animated-banner .scroll-magic-trigger {
  position: absolute;
  top: 40vh;
}

.second-scroll-magic-trigger {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
}

.animated-banner-giftsets .subheading,
.animated-banner-giftsets .heading {
  color: #1a1919;
  transition: color 0.2s ease-in-out;
}
.animated-banner-giftsets .subheading-inner {
  color: #ffffff;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1px;
  margin-bottom: 40px;
}
@media (max-width: 320px) {
  .animated-banner-giftsets .subheading-inner {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.animated-banner-giftsets .visible .subheading,
.animated-banner-giftsets .visible .heading {
  color: #ffffff;
}
@media (max-width: 767px) {
  .animated-banner-giftsets .scroll-animation-wrapper {
    margin-top: 300px;
  }
}
.animated-banner-giftsets .video-wrapper .video-holder {
  clip-path: inset(0 13%);
  transition: clip-path 0.1s linear;
}
.animated-banner-giftsets .video-wrapper .video-holder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1919;
  opacity: 0.4;
}
.animated-banner-giftsets .video-wrapper img {
  width: 100%;
}
.animated-banner-giftsets .video-wrapper .s7videoplayer video {
  opacity: 1;
}
.animated-banner-giftsets .giftset-banner-icon {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 10px;
}
.animated-banner-giftsets .giftset-banner-icon::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  content: "\ea28";
  display: inline-block;
  line-height: 1;
}

.product-experience-block {
  width: 100%;
}
@media (min-width: 1024px) {
  .product-experience-block {
    min-height: 700px;
    padding: 30px 0;
  }
}
.product-experience-block.reverse .inner-wrapper {
  justify-content: flex-start;
  padding: 0 0 0 20px;
}
@media (max-width: 767px) {
  .product-experience-block.reverse .inner-wrapper {
    flex-direction: column;
    padding: 0;
  }
}
.product-experience-block.reverse .product-experience-media {
  left: auto;
  right: 0;
}
.product-experience-block.full-width .product-experience-media {
  width: 100%;
}
@media (max-width: 767px) {
  .product-experience-block.full-width .product-experience-media {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}
.product-experience-block.full-width .product-experience-media .video-holder::after {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 767px) {
  .product-experience-block.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 990;
  }
}
.product-experience-block .inner-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 0 0;
}
@media (max-width: 767px) {
  .product-experience-block .inner-wrapper {
    flex-direction: column;
    padding: 0;
  }
}
.product-experience-block .close-video-overlay {
  top: 40px;
  left: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  color: #ffffff;
  width: 48px;
  height: 48px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
  opacity: 0;
  pointer-events: none;
  z-index: 3;
}
@media (max-width: 767px) {
  .product-experience-block .close-video-overlay {
    position: fixed;
    transform: translateZ(0);
  }
}
@media (min-width: 768px) {
  .product-experience-block .close-video-overlay {
    position: absolute;
  }
}
.product-experience-block .close-video-overlay.visible {
  opacity: 1;
  pointer-events: all;
}
.product-experience-block .popup-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  z-index: 4;
  pointer-events: none;
  box-shadow: 4px 6px 12px 3px rgba(0, 0, 0, 0.5);
}
.product-experience-block .popup-wrapper.open {
  opacity: 1;
  pointer-events: all;
}
@media (min-width: 1024px) {
  .product-experience-block .popup-wrapper.left {
    left: 11%;
    transform: translateX(0) translateY(-50%);
  }
  .product-experience-block .popup-wrapper.right {
    left: auto;
    right: 11%;
    transform: translateX(0) translateY(-50%);
  }
}
@media (max-width: 374px) {
  .product-experience-block .popup-wrapper {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .product-experience-block .popup-wrapper {
    width: 100%;
    max-width: 335px;
    position: fixed;
    transform: translate3d(-50%, -50%, 0);
    max-height: 80vh;
  }
}
@media (min-width: 768px) {
  .product-experience-block .popup-wrapper {
    min-width: 355px;
    position: absolute;
  }
}
.product-experience-block .popup-wrapper .popup {
  max-width: 355px;
  padding: 15px 20px 40px;
  text-align: center;
}
@media (max-width: 767px) {
  .product-experience-block .popup-wrapper .popup {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px 20px 35px;
  }
}
.product-experience-block .popup-wrapper .ico {
  font-size: 17px;
  color: #dbc48f;
}
@media (min-width: 768px) {
  .product-experience-block .popup-wrapper .ico {
    font-size: 20px;
  }
}
.product-experience-block .popup-wrapper .subtitle {
  display: block;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 3px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  text-transform: uppercase;
  margin: 6px 0 5px;
}
.product-experience-block .popup-wrapper .dividers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-experience-block .popup-wrapper .dividers:first-of-type span:nth-child(2) {
  margin-top: 5px;
}
.product-experience-block .popup-wrapper .dividers:nth-of-type(2) {
  flex-direction: column-reverse;
}
.product-experience-block .popup-wrapper .dividers:nth-of-type(2) span:first-child {
  margin-top: 5px;
}
.product-experience-block .popup-wrapper .dividers span {
  display: block;
  background-color: #dbc48f;
  height: 1px;
}
.product-experience-block .popup-wrapper .dividers span:first-child {
  width: 50px;
}
.product-experience-block .popup-wrapper .dividers span:nth-child(2) {
  width: 100px;
}
.product-experience-block .popup-wrapper .title {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-transform: none;
  margin: 20px 0;
}
.product-experience-block .popup-wrapper .image-wrapper {
  position: relative;
}
.product-experience-block .popup-wrapper .image-wrapper img {
  width: 100%;
}
.product-experience-block .popup-wrapper .image-wrapper .product-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.product-experience-block .popup-wrapper .description {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-family: "ClassGarmnd BT", "Times New Roman", serif;
  margin: 16px 0 0;
}
@media (max-width: 767px) {
  .product-experience-block .popup-wrapper .description {
    margin-top: 10px;
  }
}
.product-experience-block .popup-wrapper .close-popup {
  position: absolute;
  top: 5px;
  left: 7px;
  border-radius: 50%;
  color: #1a1919;
  width: 48px;
  height: 48px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .product-experience-block .popup-wrapper .close-popup {
    background-color: #1a1919;
    color: #ffffff;
    position: absolute;
    top: auto;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.product-experience-block .icon-tap-mobile {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 4.2px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  text-align: center;
  opacity: 0;
  pointer-events: none;
  z-index: 3;
}
@media (min-width: 1024px) {
  .product-experience-block .icon-tap-mobile {
    display: none;
  }
}
.product-experience-block .icon-tap-mobile.visible {
  opacity: 1;
  pointer-events: all;
}
.product-experience-block .icon-tap-mobile .icon-text {
  max-width: 165px;
}
.product-experience-block .icon-tap-mobile .svg-wrapper {
  position: relative;
  margin-bottom: 10px;
}
.product-experience-block .icon-tap-mobile .dashed-circle {
  position: absolute;
  top: 0;
  left: 0;
  animation: circle-spin 6s linear infinite;
  z-index: -1;
}

.product-experience-media {
  z-index: 2;
  transition: 1.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (max-width: 767px) {
  .product-experience-media {
    height: 75vh;
    overflow-x: auto;
    overflow-y: hidden;
    transform: translateZ(0);
    transition: height 1s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 100%;
  }
}
@media (min-width: 768px) {
  .product-experience-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 62%;
    height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-experience-media {
    width: 48%;
  }
}
.product-experience-media .product-experience-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 4.2px;
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  transition: 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  opacity: 0;
  pointer-events: none;
}
.product-experience-media .product-experience-button.fade-in {
  opacity: 1;
  pointer-events: all;
}
.product-experience-media .product-experience-button.fade-in .svg-wrapper {
  transform: scale(1);
}
.product-experience-media .product-experience-button.fade-in .svg-wrapper svg {
  opacity: 1;
}
.product-experience-media .product-experience-button.fade-in .svg-wrapper .arrow {
  transform: rotate(0) translate(20px, 17px);
}
@media (max-width: 767px) {
  .product-experience-media .product-experience-button {
    display: none;
  }
}
@keyframes circle-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.product-experience-media .product-experience-button .svg-wrapper {
  position: relative;
  transition: transform 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
  margin-bottom: 10px;
  transform: scale(0.8);
}
.product-experience-media .product-experience-button .svg-wrapper:hover {
  transform: scale(1.1);
}
.product-experience-media .product-experience-button .svg-wrapper:hover svg .arrow {
  transform: translate(34px, 12px) rotate(50deg);
}
.product-experience-media .product-experience-button svg {
  opacity: 0;
  transition: transform 0.8s cubic-bezier(0.32, 0.24, 0.15, 1), opacity 0.8s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.product-experience-media .product-experience-button svg .arrow {
  transform: translate(35px, 14px) rotate(60deg);
  transition: transform 1.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}
.product-experience-media .product-experience-button .dashed-circle {
  position: absolute;
  top: 0;
  left: 0;
  animation: circle-spin 6s linear infinite;
  z-index: -1;
}
.product-experience-media .product-experience-button .btn-text-desktop {
  display: none;
}
@media (min-width: 1025px) {
  .product-experience-media .product-experience-button .btn-text-desktop {
    display: block;
  }
}
.product-experience-media .product-experience-button .btn-text-mobile {
  display: none;
}
@media (max-width: 1023px) {
  .product-experience-media .product-experience-button .btn-text-mobile {
    display: block;
  }
}
.product-experience-media .video-holder {
  position: relative;
  height: 100%;
}
.product-experience-media .video-holder::after {
  content: "";
  position: absolute;
  height: 200px;
  width: 100%;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(56, 54, 54, 0), #1a1919);
  z-index: 1;
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
}
@media (max-width: 767px) {
  .product-experience-media .video-holder::after {
    display: none;
  }
}
.product-experience-media .media-holder {
  position: relative;
  height: 100%;
}
.product-experience-media .media-holder .experience-video-container {
  position: relative;
  z-index: 1;
}
.product-experience-media .media-holder .s7container {
  height: 100%;
  overflow: hidden;
}
.product-experience-media .media-holder .s7container video {
  height: 130% !important;
  width: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .product-experience-media .media-holder .s7container video {
    object-fit: cover;
    height: 100% !important;
  }
}
@media (max-width: 767px) {
  .product-experience-media .media-holder .s7videoplayer {
    transform: translateZ(0);
  }
}
.product-experience-media .media-holder .hidden-images-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;
}
.product-experience-media .media-holder .hidden-images-container img {
  position: absolute;
  height: 130% !important;
  width: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .product-experience-media .media-holder .hidden-images-container img {
    height: 100% !important;
  }
}
.product-experience-media .media-holder .hidden-images-container img:first-child {
  z-index: 1;
}

.product-experience-slides {
  position: relative;
  width: 35%;
  border: 1px solid #ffffff;
  padding: 74px 20px 70px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .product-experience-slides {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .product-experience-slides {
    width: 100%;
    padding: 0 20px 55px;
    border: 0;
  }
}
.product-experience-slides .drag-list-body {
  padding: 0 0 19px;
}
@media (max-width: 767px) {
  .product-experience-slides .drag-list-body {
    padding-top: 30px;
  }
}
.product-experience-slides .product-experience-slide {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .product-experience-slides .product-experience-slide {
    width: calc(100vw - 40px);
  }
}
.product-experience-slides .product-experience-slide h3 {
  font-size: 40px;
  text-transform: none;
  letter-spacing: 2px;
  margin-bottom: 26px;
  text-align: center;
}
@media (max-width: 767px) {
  .product-experience-slides .product-experience-slide h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }
}
.product-experience-slides .product-experience-slide p {
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  max-width: 310px;
  letter-spacing: 0.5px;
  text-align: center;
}
.product-experience-slides .product-experience-slide p.description {
  margin-bottom: 47px;
}
@media (max-width: 767px) {
  .product-experience-slides .product-experience-slide p.description {
    margin-bottom: 30px;
  }
}
.product-experience-slides .product-experience-slide .product-price {
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 1.5px;
  display: block;
  margin-bottom: 26px;
}
@media (max-width: 767px) {
  .product-experience-slides .product-experience-slide .product-price {
    margin-bottom: 10px;
  }
}
.product-experience-slides .product-experience-slide ul {
  list-style: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 26px;
}
@media (max-width: 767px) {
  .product-experience-slides .product-experience-slide ul {
    margin-bottom: 32px;
  }
}
.product-experience-slides .product-experience-slide ul .icon-check {
  font-size: 9px;
  color: #e4d6b5;
}
.product-experience-slides .product-experience-slide .btn {
  margin-bottom: 28px;
}
.product-experience-slides .product-experience-slide .btn.btn-add-to-cart {
  margin-bottom: 12px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #1a1919;
}
.product-experience-slides .product-experience-slide .btn.btn-add-to-cart:hover, .product-experience-slides .product-experience-slide .btn.btn-add-to-cart:active {
  background-color: transparent;
  color: #ffffff;
}
@media (max-width: 767px) {
  .product-experience-slides .product-experience-slide .btn {
    margin-bottom: 30px;
  }
}
.product-experience-slides .product-experience-slide .disclaimer {
  line-height: 18px;
}
.product-experience-slides .product-experience-slide .explanation-list {
  max-width: 85vw;
  padding-top: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  border: 0;
}
@media (min-width: 768px) {
  .product-experience-slides .product-experience-slide .explanation-list {
    max-width: 314px;
  }
}
.product-experience-slides .product-experience-slide .explanation-list span {
  margin-bottom: 5px;
}
.product-experience-slides .product-experience-slide .explanation-list li {
  background-color: #1c2c4c;
  border: 0;
  min-height: 76px;
  padding: 8px 12px;
  width: calc(50% - 5px);
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .product-experience-slides .product-experience-slide .explanation-list li {
    padding: 5px 20px;
  }
}
.product-experience-slides .product-experience-slide .explanation-list .key-copy {
  color: #ffffff;
}
.product-experience-slides .product-experience-slide .explanation-list .explanation-copy {
  color: #d0a880;
}
.product-experience-slides .product-experience-slide .explanation-list .btn-content-video {
  padding: 0;
}
.product-experience-slides .product-experience-slide .explanation-list .btn-content-video::before {
  display: none;
}
.product-experience-slides .simplebar-track.simplebar-horizontal {
  background-color: #626262;
}
.product-experience-slides .simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  background-color: #ffffff;
}
.product-experience-slides .slider-button {
  color: #ffffff;
  border: 1px solid #ffffff;
}
.product-experience-slides .slider-button:hover {
  color: #1a1919;
}
.product-experience-slides .slider-button:first-child {
  left: 20px;
}
.product-experience-slides .slider-button:last-child:not(:only-child) {
  right: 20px;
}

.hotspot-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@media (max-width: 767px) {
  .hotspot-container {
    display: none;
  }
}
.hotspot-container.fade-in {
  z-index: 1;
}
.hotspot-container.fade-in .hotspot-button-wrapper .btn {
  opacity: 1;
}
.hotspot-container.hidden {
  opacity: 0;
}
.hotspot-container .hotspot-button-wrapper {
  display: flex;
  margin-bottom: 40px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .hotspot-container .hotspot-button-wrapper {
    position: fixed;
    bottom: 0;
    width: 100vw;
  }
}
.hotspot-container .hotspot-button-wrapper .btn {
  color: #ffffff;
  opacity: 0;
  transition: 0.2s ease-in-out, opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  min-height: 60px;
  background-color: #031a2b;
  margin: 0 15px;
  min-width: 225px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hotspot-container .hotspot-button-wrapper .btn:hover, .hotspot-container .hotspot-button-wrapper .btn:active {
  background-color: #ffffff;
  color: #031a2b;
  border-color: #031a2b;
}
@media (max-width: 767px) {
  .hotspot-container .hotspot-button-wrapper .btn {
    min-width: 0;
    width: 30vw;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 10px;
    padding: 18px 16px 9px;
  }
}
.hotspot-container .hotspot-button-wrapper .btn .ico {
  font-size: 23px;
  margin-right: 24px;
}
@media (max-width: 767px) {
  .hotspot-container .hotspot-button-wrapper .btn .ico {
    margin: 0 0 10px 0;
  }
}
.hotspot-container .hotspot-button-wrapper .btn .text {
  letter-spacing: 4.2px;
}
@media (max-width: 767px) {
  .hotspot-container .hotspot-button-wrapper .btn .text {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 3px;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.show-on-mobile {
  display: block;
}
@media (min-width: 768px) {
  .show-on-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-on-tablet {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .show-on-mobile-tablet {
    display: block;
  }
}
@media (min-width: 1024px) {
  .show-on-mobile-tablet {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hide-on-mobile-tablet {
    display: none !important;
  }
}

.i-minMobile {
  display: none !important;
}
@media (max-width: 767px) {
  .i-minMobile {
    display: block !important;
  }
}

.i-minTablet {
  display: none !important;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .i-minTablet {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .i-minTablet {
    display: block !important;
  }
}

.i-minDesktop {
  display: none !important;
}
@media (min-width: 1024px) {
  .i-minDesktop {
    display: block !important;
  }
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.flyout-sr-only {
  left: -10000px;
}

.align-center {
  text-align: center;
}

.txt--bold,
.bold-text {
  font-weight: 700;
}

.txt--italic {
  font-style: italic;
}

.txt--white {
  color: #ffffff !important;
}

.txt--black {
  color: #1a1919 !important;
}

.txt--rit-dark {
  color: #453f3f !important;
}

.txt--gray-medium {
  color: #767676 !important;
}

.txt--reset-color {
  color: currentColor !important;
}

.txt--rit-copper {
  color: #9a7b69 !important;
}

.txt--rit-copper-light {
  color: #b8a188 !important;
}

.txt--red {
  color: #d6122d !important;
}

.txt--gray {
  color: #767676;
}

.txt--large {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 20px;
  letter-spacing: 1.7px;
}

.txt--small {
  font-family: "Scala Sans Pro", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.31px;
}

.error {
  color: #d6122d;
}

.st0 {
  fill: #ffffff;
}

.pp-out-of-stock::after {
  background-color: #faf9f7;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.pp-out-of-stock .oos-icon {
  border-radius: 50px;
  border: 2px solid #ffffff;
  display: block;
  height: 68px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  z-index: 1;
}
.pp-out-of-stock .oos-icon::after {
  background-color: #ffffff;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 31px;
  transform: rotate(45deg);
  width: 100%;
}

.ritual-bg-full-color {
  background-color: #453f3f;
}

.ritual-bg-color {
  background-color: #efedea;
}

.white-bg-color {
  background-color: #ffffff;
}

.black-bg-color {
  background-color: #1a1919;
}

.cardinal-bg-color {
  background-color: #ba0c2f;
}

.yellowshade-bg-color {
  background-color: #d29f13;
}

.pinklight-bg-color {
  background-color: #cca1a6;
}

.dark-blue-bg-color {
  background-color: #1c2c4c;
}

.sandy-bg-color {
  background-color: #f6f4f2;
}

.sandy-light-bg-color {
  background-color: #fcfaf7;
}

.red-bg-color {
  background-color: #ad4141;
}

.dark-grey-bg-color {
  background-color: #262424;
}

.hommes-bg-color {
  background-color: #1d1c24;
}

.hommes-bg-light-color {
  background-color: #24232b;
}

.dao-bg-color {
  background-color: #eff2e9;
}

.hammam-bg-color {
  background-color: #e4e9eb;
}

.karma-bg-color {
  background-color: #e6f1ee;
}

.sakura-bg-color {
  background-color: #fbf3f4;
}

.ayurveda-bg-color {
  background-color: #f6f4f2;
}

.primary-content > .mehr-bg-full-color {
  background-color: #bd7a09;
}

.primary-content > .karma-bg-full-color {
  background-color: #226e75;
}

.primary-content > .sakura-bg-full-color {
  background-color: #c75f5f;
}

.flower-bg {
  background-image: url("../images/flower-bg-mobile.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
@media (min-width: 768px) {
  .flower-bg {
    background-image: url("../images/flower-bg-desktop.png");
  }
}

.responsive-bg {
  flex: 1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 768px) {
  .responsive-bg {
    height: auto;
  }
}

@media (min-width: 768px) {
  .gradient-overlay-subtle::before,
  .gradient-overlay-intense::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: #000000 !important;
    z-index: 2;
  }
}

@media (min-width: 768px) {
  .gradient-overlay-subtle::before {
    opacity: 0.3 !important;
  }
}

@media (min-width: 768px) {
  .gradient-overlay-intense::before {
    opacity: 0.5 !important;
  }
}

@media (max-width: 767px) {
  .gradient-overlay-mobile::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 370px;
    background: linear-gradient(180.08deg, rgba(26, 24, 25, 0) 13.97%, rgba(26, 24, 25, 0.43) 28.74%, rgba(26, 24, 25, 0.71) 43.52%, rgba(26, 24, 25, 0.92) 60.53%, #1a1819 79.78%);
    z-index: 2;
  }
}

.experience-assets-gridblockitem .image-holder,
.experience-assets-categorytile .image-holder,
.experience-assets-headlinebanner .image-holder,
.experience-assets-magazinerelatedtile .image-holder {
  position: relative;
}
.experience-assets-gridblockitem .image-holder img,
.experience-assets-categorytile .image-holder img,
.experience-assets-headlinebanner .image-holder img,
.experience-assets-magazinerelatedtile .image-holder img {
  max-width: 100%;
}

.experience-assets-fullwidthslideritem .media-holder {
  position: relative;
}