// main block
.pdp-main-container.giftset-pdp-main-container {
  background-color: $white;
}

.giftset-pdp-main-container {
  .giftset-details-container {
    min-height: calc(100vh - 60px);
    padding: 0;

    @include desktop {
      padding-top: 95px;
      min-height: calc(100vh - 95px);
    }

    .pdp-back-button-wrapper {
      left: 20px;
      position: absolute;
      top: 11px;
      z-index: $modal-close-index;

      @include desktop {
        position: fixed;
        top: 108px;
      }

      .back-button {
        color: $rit-dark;
      }
    }

    .pdp-inner-wrapper {
      @include desktop {
        padding: 0;
      }
    }

    .scrollmagic-pin-spacer {
      @include mobile-and-tablet {
        width: 100% !important;
      }
    }
  }

  .images-wrapper {
    .images-slider {
      img {
        max-height: none;
        max-width: none;
        opacity: 1;
        width: 100% !important;
      }
    }
  }

  @include mobile-and-tablet {
    .pdp-inner-wrapper {
      padding-top: 0;
    }

    .images-wrapper .product-images {
      margin-left: -20px;
      width: calc(100% + 40px);

      img {
        height: 100%;
      }
    }
  }

  @include desktop {
    .giftset-column-images {
      height: calc(100vh - 95px);
      text-align: center;
      width: 50%;
      z-index: 2;
    }

    .images-wrapper {
      width: 100%;

      .images-slider {
        height: calc(100vh - 95px);
        max-width: 100%;
        width: 100%;

        img {
          height: 100%;
          max-height: none;
          object-fit: cover;
        }
      }
    }
  }
}

// text column
.giftset-pdp-main-container .giftset-column-details {
  @include mobile-only {
    padding: 31px 0 520px;

    .product-details-content {
      padding: 0 20px;
    }

    .product-title {
      margin-bottom: 14px;
      padding: 0 5px;

      .the-ritual-of-title {
        font-size: 10px;
        letter-spacing: 1.5px;
        line-height: 14px;
        margin-bottom: 5px;
      }

      .product-name {
        letter-spacing: 1.2px;
      }
    }

    .product-content {
      .long-description {
        color: $rit-black;
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }

      .read-more-link {
        font-size: 16px;
      }

      .product-price {
        margin-bottom: 29px;
      }

      .btn {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }

  @include tablet {
    padding-bottom: 922px;
  }

  @include desktop {
    height: calc(100vh - 95px);
    padding: 0;

    .product-details-content {
      max-width: 460px;
      padding: 50px 20px;
    }

    .product-title {
      .the-ritual-of-title {
        font-size: 16px;
        letter-spacing: 5px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .product-name {
        margin-bottom: 12px;
      }
    }

    .product-content {
      .long-description {
        margin-bottom: 21px;
        max-width: none;
      }

      .btn {
        margin-bottom: 11px;
        min-width: 256px;
      }
    }

    .personalised-gifting-shipping-info {
      margin: 20px auto 0;
      max-width: 335px;
      padding-top: 26px;
    }
  }

  .personalised-gifting-shipping-info {
    border-top: 1px solid $white-smoke;
    margin-top: 29px;
    padding-top: 36px;
    width: 100%;
  }
}
