.b2b-contact-from {
  padding: 88px 0 56px;
  position: relative;

  &.inner-wrapper-small {
    @include tablet {
      flex-direction: column;
    }
  }

  .form-header {
    margin-bottom: 64px;

    @include mobile-only {
      margin-bottom: 52px;
      padding: 0 24px;
    }

    h1 {
      color: $rit-black;
      letter-spacing: 0;
      line-height: 56px;
      text-transform: none;

      @include mobile-only {
        font-size: 32px;
        line-height: 40px;
        margin: 0 auto 16px auto;
        width: 75%;
      }
    }

    p {
      color: $gray-dark;
      letter-spacing: 0.6px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .b2b-inputs {
    display: flex;
    gap: 24px;

    @include mobile-only {
      flex-direction: column;
      padding: 0 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    label {
      letter-spacing: 0.4px;
      line-height: 24px;
    }

    input, select {
      border-radius: 0;
      letter-spacing: .6;
      padding: 12px 16px;

      &::placeholder {
        color: $gray-dark;
        font-style: italic;
        letter-spacing: 0.6px;
        line-height: 24px;
      }

      &.error {
        border-color: $red-warning transparent transparent;
        width: calc(100% - 40px)
      }

      &.focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    select {
      color: $gray-dark;

      &.error {
        color: $gray-dark;
      }
    }

    .input-textarea {
      height: 105px;
      order: 2;
    }

    .selectric-wrapper {
      @include desktop {
        max-width: calc(50% - 12px);
      }

      &:not(.selectric-wrapper.selectric-open) {
        z-index: 0;
      }

      .error~.selectric {
        border-color: $red-warning transparent transparent;
        width: calc(100% - 40px)
      }

      .selectric {
        span {
          color: $gray-dark;
        }
      }
    }

    .inputfield-row {
      span {
        color: $rit-black;
      }

      span.error:not(.hide) {
        color: $red-warning;
        text-align: left;

        &::after {
          @include icon-styles;
          content: icon-char(circle-exclamation);
          display: inline-block;
          font-size: 32px;
          position: absolute;
          right: -9px;
          top: 50%;
        }
      }

      .selectric-wrapper span.error:not(.hide) {
        &::after {
          top: 30%;
        }
      }
    }

    .button-wrapper.inputfield-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include mobile-only {
        flex-direction: column;
        gap: 24px;
      }

      .btn {
        padding: 16px 64px;

        @include mobile-only {
          max-width: none;
        }
      }
    }

    .char-count {
      display: none;
    }
  }
}

.b2b-signup-complete {
  background-color: $rit-sandy-dark;
  bottom: 0;
  opacity: 0;
  left: 0;
  margin-top: 64px;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 0;
  }

  @include tablet {
    flex-direction: column;
  }

  h1 {
    color: $rit-black;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-transform: none;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 1.2px;
      line-height: 40px;
    }

    @include tablet {
      font-size: 36px;
      letter-spacing: 1.8px;
      line-height: 48px;
    }
  }

  p {
    line-height: 24px;

    &:last-child:not(:only-child) {
      margin-top: 60px;
    }
  }

  .progress-steps {
    padding: 40px 0 64px 0;

    .step-tile {
      @include mobile-only {
        width: 120px;
      }

      @include tablet {
        width: 170px;
      }

      &:nth-child(2) .step__number .circle {
        @include icon(magnifying-glass);
      }

      &:nth-child(3) .step__number .circle {
        @include icon(email);
      }

      .step__number {
        &.active {
          .circle {
            border-color: $rit-copper-dark;

            &::before {
              color: $white;
            }
          }
        }
        .circle {
          border-color: $grey-medium-light;
          font-size: 12px;
          height: 36px;
          width: 36px;

          &::before {
            color: $grey-medium-light;
          }
        }
      }

      .step__text--title {
        color: $gray-dark;
        font-size: 14px;
      }
    }
  }

  .confirmation-note,
  .confirmation-note a {
    font-size: 14px;
  }
}
