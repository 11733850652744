// Styling for the 'new' formfields
input:not(.vue-input) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  border-radius: 2px;
  border: 1px solid $gray-gainsboro;
  color: $rit-black;
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-top: 8px;
  order: 2;
  padding: 12px 21px;
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #fcf8de inset;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type=text]::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: $gray-silver;
  }

  &:read-only,
  &:disabled {
    background-color: $rit-sandy-dark;
    cursor: not-allowed;

    & + .icon-read-only {
      position: absolute;
      transform: translateY(36px);
      width: 100%;

      .giftcard-number-wrapper & {
        display: none;
      }

      &::before {
        @include icon-styles;
        color: $rit-dark;
        content: icon-char(system-lock);
        display: inline-block;
        position: absolute;
        right: 16px;
      }
    }
  }

  &.error {
    border: 1px solid $red-error;

    & ~ .password-reveal {
      bottom: 35px;
    }

    & ~ .selectric-items {
      top: calc(100% - 22px);
    }
  }

  &.postal-code-change {
    border-top: 1px solid $rit-copper-dark;
  }
}

input[type=radio],
input[type=checkbox] {
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked {
    & + label::before {
      background-color: $copper;
      border-color: $copper;
      content: $icon-check;
    }
  }

  & + label {
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding: 4px 0 0 36px;
    position: relative;

    &::before {
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $gray-gainsboro;
      color: $white;
      content: '';
      font-family: 'iconfont';
      font-size: 8px;
      height: 24px;
      left: 0;
      letter-spacing: -1px;
      line-height: 26px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 24px;
    }
  }
}

.postal-code-warning-message {
  font-size: 12px;
  letter-spacing: 0.4px;
  margin: 2px 0 5px;
  line-height: 19px;
  color: $gray-medium;

  @include tablet {
    margin: -2px 0 2px;
    line-height: 18px;
  }

  & + .error ~ .inputfield-text {
    border-top: 1px solid $red-error;
  }
}

.generic-dialog-flyout .postal-code-warning-message {
  margin: -2px 0 4px;
  line-height: 18px;
}

.inputfield-row {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  text-align: left;
  width: 100%;

  .password-reveal {
    bottom: 14px;
    position: absolute;
    right: 18px;

    &.visible {
      .password-visible {
        display: block;
      }

      .password-not-visible {
        display: none;
      }
    }

    .password-reveal-icon {
      font-size: 20px;
    }

    .password-visible {
      display: none;
      position: relative;
      top: -2px;
      font-size: 11px;
    }

    .password-not-visible {
      font-size: 14px;
    }
  }

  .error-message {
    order: 2;
  }

  .selectric-inputfield-select {
    display: flex;
    flex-direction: column;
    order: 2;
  }

  label {
    color: $rit-black;
    display: flex;
    font-family: $font-body;
    justify-content: space-between;
    letter-spacing: 0.5px;
    line-height: 20px;
    width: 100%;

    @include tablet {
      order: 1;
    }

    .optional-indicator {
      color: $gray-medium;
      font-size: 12px;
      letter-spacing: .4px;
      line-height: 16px;
      margin-left: 5px;
    }

    .field-help {
      margin-left: 8px;
      position: relative;

      &::before {
        background-color: $black;
        border-radius: 50%;
        color: $white;
        content: 'i';
        font-size: 11px;
        height: 12px;
        left: 0;
        line-height: 15px;
        position: absolute;
        text-align: center;
        top: -1px;
        width: 12px;
      }

      &:hover {
        span {
          opacity: 1;
          transition-delay: 0s;
        }
      }

      span {
        background-color: $rit-black;
        bottom: 34px;
        color: $white;
        left: -95px;
        opacity: 0;
        padding: 10px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        transition: opacity 0.6s $default-ease-out 1.5s;
        width: 210px;
        z-index: 11;

        &::before {
          background-color: $rit-black;
          bottom: -17px;
          content: '';
          height: 20px;
          left: 98px;
          position: absolute;
          transform-origin: center;
          transform: rotate(45deg) translateX(-50%);
          width: 20px;
          z-index: 10;
        }
      }
    }
  }

  .label-disabled {
    color: rgba($rit-black, 0.5);
  }

  span.error:not(.hide) {
    display: block;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 13px;
    margin-top: 8px;
    order: 4;
    text-align: right;

    a {
      color: $red-error;
      font-size: 11px;
      letter-spacing: 1px;
      line-height: 13px;
    }

    & ~ .selectric-items {
      top: calc(100% - 22px);
    }
  }

  .ssn-loading-indicator:not(.hide) {
    display: block;
    width: 100%;
  }
}

.select-row {
  &.floating-label {
    .error-text {
      ~ label {
        position: absolute;
        visibility: hidden;
      }
    }
  }
}
