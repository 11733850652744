.gradient-overlay-subtle,
.gradient-overlay-intense {
  @include tablet {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      background-color: $black !important;
      z-index: 2;
    }
  }
}

.gradient-overlay-subtle {
  @include tablet {
    &::before {
      opacity: 0.3 !important;
    }
  }
}

.gradient-overlay-intense {
  @include tablet {
    &::before {
      opacity: 0.5 !important;
    }
  }
}

.gradient-overlay-mobile {
  @include mobile-only {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100% !important;
      height: 370px;
      background: linear-gradient(180.08deg, rgba($color: $rit-black-medium, $alpha: 0) 13.97%, rgba($color: $rit-black-medium, $alpha: 0.43) 28.74%, rgba($color: $rit-black-medium, $alpha: 0.71) 43.52%, rgba($color: $rit-black-medium, $alpha: 0.92) 60.53%, $rit-black-medium 79.78%);
      z-index: 2;
    }
  }
}

.experience-assets-gridblockitem,
.experience-assets-categorytile,
.experience-assets-headlinebanner,
.experience-assets-magazinerelatedtile {
  .image-holder {
    position: relative;

    img {
      max-width: 100%;
    }
  }
}

.experience-assets-fullwidthslideritem {
  .media-holder {
    position: relative;
  }
}
