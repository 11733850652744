.sustainability-counter {
  padding: 20px;

  @include desktop {
    margin: 0 auto;
    max-width: 1440px;
  }

  .sustainability-tile {
    &.hero {
      display: flex;

      @include mobile-only {
        flex-direction: column;
      }

      @include tablet {
        height: 352px;
      }

      .cover-image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        z-index: -1;

        &.darken {
          filter: brightness(70%);
        }
      }

      h3 {
        color: $white;
        font-family: $font-header;
        font-size: 20px;
        letter-spacing: 0.4px;
        line-height: 32px;
        text-transform: none;
      }

      p {
        color: $white;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
        margin-top: 0;
        text-align: left;
      }

      .icon {
        &.icon-arrow-back {
          margin-left: 20px;
          transform: rotate(180deg);
        }

        &:not(.icon-arrow-back) {
          background-color: rgba($rit-black-lighter, 0.3);
          -webkit-backdrop-filter: blur(10px) saturate(50%);
          backdrop-filter: blur(10px) saturate(50%);
          border-radius: 50%;
          color: $white;
          font-size: 40px;
          height: 64px;
          line-height: 64px;
          margin-bottom: 5px;
          text-align: center;
          width: 64px;
        }
      }

      .blurred-overlay {
        background-color: rgba($rit-black-lighter, 0.3);
        -webkit-backdrop-filter: blur(10px) saturate(50%);
        backdrop-filter: blur(10px) saturate(50%);
        bottom: 0;
        left: 0;
        padding: 26px 10px 20px 10px;
        position: absolute;
        text-align: center;
        width: 100%;

        span {
          color: $white;
          font-family: $font-body;
          font-size: 16px;
          letter-spacing: 0.46px;
          line-height: 24px;
        }
      }
    }

    .animated-counter {
      color: $rit-black;
      font-family: $font-header;
      font-size: 24px;
      letter-spacing: 0.2px;
      line-height: 32px;
      position: relative;
      text-align: center;
      width: 80%;

      &.large {
        color: $white;
        font-size: 56px;
        letter-spacing: 1px;
        line-height: 64px;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0.6px;
      line-height: 24px;
      margin-top: 16px;
      text-align: center;
      width: 90%;
    }

    .learn-more {
      color: $white;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 24px;
      text-decoration: none;
      text-transform: uppercase;
    }

    .cover-image {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .sustainability-column {
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        _::-webkit-full-page-media, _:future, :root, .safari-only {
          line-height: 60px;
          padding-left: 2px;
        }
      }
    }
  }

  .sustainability-column {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &:first-child:not(:only-child) {
      align-items: flex-start;
      background-color: $sustainability-green;
      padding: 0 70px;

      @include mobile-only {
        padding: 38px 45px 34px;
      }

      @include tablet-only {
        width: 50%;
      }

      @include desktop {
        width: 492px;
      }

      p {
        margin-bottom: 50px;
      }
    }

    &:last-child:not(:only-child) {
      @include mobile-only {
        height: 372px;
      }

      @include tablet-only {
        width: 50%;
      }

      @include desktop {
        width: calc(100% - 492px);
      }

      p {
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin: 0 20px 74px;
        text-align: center;
      }
    }
  }

  .sustainability-list {
    display: flex;
    padding-bottom: 40px;

    @include mobile-only {
      justify-content: center;
      margin: 0 -20px;
    }

    @include mobile-and-tablet {
      flex-wrap: wrap;
    }

    @include tablet {
      justify-content: space-between;
    }

    .sustainability-tile {
      @include mobile-only {
        margin-bottom: 20px;
      }

      @include tablet-only {
        margin-bottom: 20px;
        width: calc(50% - 10px);
      }

      @include desktop {
        width: calc(25% - 20px);
      }

      .sustainability-column {

        .animated-counter {
          margin-top: 40px;
          padding-top: 16px;

          &::before {
            content: '';
            background: $rit-copper;
            display: inline-block;
            height: 1px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 16px;
          }
        }

        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          _::-webkit-full-page-media, _:future, :root, .safari-only {
            line-height: 74px;
          }
        }
      }
    }
  }

  .sustainability-achievement-title {
    color: $copper;
    display: block;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 16px;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
  }

  .slider-bar,
  .slider-dots {
    @include desktop {
      display: none !important;
    }
  }

  .slider-bar {
    margin: -10px auto 20px auto;
  }

  .slick-slide {
    margin: 0 10px;
    width: 240px;

    .sustainability-column {
      @include mobile-and-tablet {
        margin: auto;
      }
    }
  }
}
