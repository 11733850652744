.herobanner-wrapper {
  display: flex;
  flex-direction: row;

  @include mobile-and-tablet {
    flex-direction: column;
  }

  .back-button {
    filter: invert(1);
    left: 24px;
    mix-blend-mode: difference;
    position: absolute;
    text-decoration: none;
    top: 24px;
  }

  .carousel-images-wrapper {
    background-color: $white;
    flex: 60%;
    overflow: hidden;
    position: relative;
    width: 100%;

    &:hover > .slick-arrow {
      display: block !important;
    }

    .slick-list {
      align-items: center;
      display: flex;
      height: 100%;
    }

    .slick-track {
      display: flex;
      flex-direction: row;
    }

    .carousel-image {
      cursor: pointer;
      display: block !important;
      height: 100vh;
      margin: 0 auto;
      width: auto !important;

      @include mobile-only {
        height: auto;
      }

      @include tablet-only {
        height: 600px;
      }
    }

    .slick-arrow {
      border-radius: 50%;
      display: none !important;
      font-size: 30px;
      position: absolute;
      top: 50%;
      transition: 300ms;
      width: 35px;
      z-index: 1;

      &:hover {
        background-color: $gray-very-light;
      }

      &:active {
        background-color: $gray-light;
      }
    }

    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }

    .slick-dots {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-left: 20px;
      margin-top: -30px;

      li {
        color: $gray-light;
        margin: 0 4px;
        width: 4px;
      }

      .slick-active {
        color: $rit-black;
      }

      button {
        font-size: 0;
      }
    }
  }

  .carousel-zoom {
    background-color: $white;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .close-btn {
    color: $rit-black;
    display: none;
    font-size: 30px;
    padding: 6px;
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 3;

    &:hover {
      background-color: $rit-black;
      color: $white;
    }
  }

  .show {
    display: block;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    flex: 40%;
    justify-content: center;
    max-height: 100vh;

    .packshot-image {
      margin: 0 auto;
      max-height: 584px;

      @include mobile-and-tablet {
        display: none;
      }

      @include desktop-large {
        height: 584px;
      }
    }

    .product-properties {
      margin: 0 40px;

      @include mobile-only {
        margin: 0 24px;
      }

      @include desktop-large {
        margin: 0 64px;
      }
    }

    .product-description-wrapper {
      margin: 24px auto;
    }

    .product-ancient-ritual {
      color: $rit-copper;
      font-size: 12px;
      letter-spacing: 1.8px;
      line-height: 16px;
    }

    .product-title {
      font-size: 32px;
      letter-spacing: 0.6px;
      line-height: 40px;

      @include mobile-only {
        font-size: 24px;
      }

      @include tablet-only {
        font-size: 28px;
      }
    }

    .product-sale-price {
      color: $rit-black;
      font-family: $font-header;
      font-size: 24px;
      letter-spacing: 0.2px;
      line-height: 32px;

      @include mobile-and-tablet {
        font-size: 20px;
      }
    }

    .product-more-information {
      color: $rit-black;
      font-size: 16px;
      letter-spacing: 0.6px;
      line-height: 24px;
    }

    .button-wrapper {
      display: flex;
      flex-direction: row;
      margin: 24px auto;

      @include mobile-only {
        flex-direction: column;
      }

      .availability {
        color: $rit-black;
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 18px;
        margin: auto 32px;

        @include mobile-only {
          margin: 16px 0;
        }
      }
    }
  }
}
