.store-locator-search {
  text-align: center;
  padding: 40px 30px;

  @include tablet {
    padding: 0;
  }

  .store-light-background {
    @include tablet {
      background-color: $rit-sandy-light;
      padding: 61px 50px 56px;
    }

    .store-container {
      display: block;
      margin: 0 auto;
      max-width: 600px;
      text-align: center;

      @include tablet {
        text-align: left;
      }

      .selectric-wrapper {
        margin-top: 3px;
      }
    }
  }

  .store-header {
    font-size: 30px;
    letter-spacing: 4px;
    line-height: 40px;
    text-transform: none;
    color: $black;

    @include tablet {
      font-size: 40px;
      letter-spacing: 1.6px;
      line-height: 50px;
      margin-bottom: 15px;
    }
  }

  .store-locator-form {
    width: 100%;
    margin-top: 26px;
    display: inline-block;
    vertical-align: top;

    @include tablet {
      max-width: 600px;
      margin-top: 27px;
    }

    .filters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 26px;
      margin-left: 8px;

      @include tablet {
        margin-top: 30px;
        margin-left: 4px;
      }

      .form-row {
        margin: 0;

        &:not(:last-of-type) {
          .label-container {
            margin-right: 40px;
            margin-bottom: 16px;

            @include tablet {
              margin-bottom: 0;
            }
          }
        }

        label {
          width: 100%;

          @include tablet {
            line-height: 24px;
          }
        }

        .label-container {
          display: inline-block;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          width: auto;
          font-size: 14px;
          letter-spacing: 0.25px;
          color: $rit-black;
          line-height: 28px;
          text-align: left;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark {
              background-color: $rit-copper-dark;
            }

            &:checked ~ .checkmark::after {
              display: block;
            }
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: $white;
            border: 1px solid $gray-lines;
            border-radius: 50%;

            &::after {
              content: '';
              position: absolute;
              display: none;
              top: 8px;
              left: 8px;
              border-radius: 50%;
              background-color: $white;
              height: 6px;
              width: 6px;
            }
          }
        }
      }
    }

    .address-search-input {
      margin-bottom: 24px;

      label {
        display: block;
      }
    }

    .form-row .field-wrapper {
      @include tablet {
        margin-left: 0;
        max-width: 600px;
      }
    }
  }

  .icon-current-location-wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    @include tablet {
      right: 21px;
      top: 50%;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 45%;
      }
    }

    .icon-current-location {
      cursor: pointer;
      color: $rit-copper-dark;
      font-size: 17px;
    }
  }
}

.store-search-results {
  padding: 19px 30px 6px;
  width: 100%;
  display: inline-block;
  vertical-align: top;

  @include tablet {
    padding: 20px 40px 25px;
  }

  .store {
    display: block;
    margin: 0 auto;
    max-width: 570px;
    padding: 39px 0 31px;
    border-bottom: 1px solid $gray-gainsboro;

    @include tablet {
      padding: 28px 0 20px;
    }

    h2 {
      text-transform: none;
      font-size: 24px;
      letter-spacing: 1.2px;
      line-height: 30px;
      color: $black;
      margin-bottom: 11px;

      @include tablet {
        line-height: 44px;
        margin-bottom: 1px;
        letter-spacing: 0.46px;
      }
    }

    .store-address {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      color: $rit-black;

      .phone-number {
        display: inline-block;
        margin-top: 22px;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 1px;

        @include tablet {
          color: $rit-black;
          text-decoration: none;
          pointer-events: none;
        }
      }
    }
  }

  .store-info-link {
    display: inline-block;
    margin-top: 0;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.store-noresult-wrapper {
  padding: 0 30px;

  .store-noresult-container {
    display: block;
    margin: -15px auto 184px;
    max-width: 556px;
    text-align: left;
  }
}

.show-more-wrapper {
  display: block;
  margin: 0 auto;
  max-width: 556px;
  padding: 35px 30px 40px;

  @include tablet {
    padding: 35px 0;
  }

  .show-more-btn {
    margin: 0 auto;

    @include tablet {
      margin: 0;
    }
  }

}

// Store Details Page
.store-details {
  position: relative;
  padding: 61px 30px 0;

  .inner-wrapper {
    padding: 0;

    @include tablet {
      padding: 0 20px;
    }
  }

  .store-light-background {
    @include tablet {
      padding: 91px 33px 155px 30px;
      background-color: $rit-sandy-light;
    }

    @include desktop {
      padding: 91px 33px 81px;
    }
  }

  .back-button {
    position: absolute;
    top: 10px;
    left: 17px;

    @include tablet {
      display: none;
    }
  }
}

.store-details-address,
.store-location,
.store-hours-wrapper {
  width: 100%;
}

.store-location {
  @include tablet {
    width: 50%;
    margin-right: 32px;
    padding-left: 65px;
    max-width: none;
    order: 1;
  }

  .store-map {
    position: relative;
    overflow: hidden;

    &.store-map--desktop {
      height: 175px;
      width: 100%;

      @include tablet {
        display: block;
        height: 227.5px;
      }
    }
  }
}

.store-hours-wrapper {
  margin-bottom: 25px;

  @include tablet {
    order: 2;
    width: auto;
    margin-bottom: 0;
  }

  @include desktop {
    width: 50%;
  }
}

.map-hours-container {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    flex-direction: row;
  }

  .info-mobile {
    border-bottom: 1px solid $gray-gainsboro;
    padding-bottom: 25px;
    margin-top: 22px;

    a {
      display: block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      margin-bottom: 7px;
    }

    @include tablet {
      display: none;
    }
  }
}

.store-hours {
  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
    }
  }

  h2 {
    font-size: 10px;
    letter-spacing: 2px;
    color: $gray-dark;
    line-height: 12px;
    font-family: $font-body;
    margin-bottom: 18px;
  }

  .day,
  .additional-day {
    text-transform: capitalize;
    display: inline-block;
    margin-right: 2px;
  }

  .day,
  .additional-day,
  .time,
  .additional-time {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 18px;
    color: $rit-dark;
  }

  .store-hours-details {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 18px;
    padding-top: 5px;

    @include tablet {
      max-width: 300px;
    }

    a {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 15px;
      text-transform: uppercase;
    }
  }
}

.store-details-address {
  margin-top: 33px;
  order: 3;

  @include tablet {
    margin-top: 20px;
  }

  .paragraph {
    font-size: 16px;
    letter-spacing: 1px;
    color: $rit-black;
    line-height: 26px;
    font-family: $font-body;
    text-transform: none;
  }
}

.store-info-wrapper {
  h1 {
    font-size: 20px;
    letter-spacing: 5.33px;
    line-height: 30px;
    color: $black;
    margin-bottom: 30px;

    @include tablet {
      line-height: 30px;
      margin: -3px 0 29px;
    }
  }
}

.store-details-bottom-wrapper {
  display: block;
  margin: 31px auto 85px;
  max-width: 340px;
  text-align: center;

  @include tablet {
    margin: 63px auto;
  }

  .wrapper__buttons {
    margin-top: 31px;

    @include tablet {
      margin-top: 31px;
    }
  }

  h2 {
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
    color: $black;
    text-transform: none;
    margin-bottom: 7px;

    @include tablet {
      margin-bottom: 5px;
    }
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 3px;

    @include tablet {
      margin-bottom: 10px;
    }
  }
}
