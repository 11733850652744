.simple-text-block {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 0;

  &.text-block-maintenance {
    padding: 32px 0 37px;

    @include mobile-only {
      padding-top: 30px;
    }

    .inner-wrapper {
      max-width: 735px;
    }

    .title {
      font-size: 11px;
      font-family: $font-header;
      text-transform: uppercase;
      color: $rit-copper-dark;
      letter-spacing: 1.5px;
      line-height: 18px;
      display: block;
      margin-bottom: 19px;

      @include mobile-only {
        margin-bottom: 14px;
      }
    }

    p {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.8px;
      font-family: $font-header;
      width: 100%;

      &:first-of-type {
        margin-bottom: 40px;

        @include mobile-only {
          margin-bottom: 15px;
        }
      }

      @include tablet {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.6px;
      }
    }
  }

  @include tablet {
    padding: 30px 0;
  }

  .inner-wrapper {
    max-width: 560px;
    min-height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;

    @include mobile-only {
      min-height: 50px;
    }

    @include tablet {
      padding: 0 40px;
      max-width: 1140px;
      min-height: 50px;
    }

    @include desktop {
      padding: 0 20px;
      max-width: 740px;
      min-height: 50px;
    }

    p,
    span {
      max-width: 100%;
    }

    .sub-heading {
      margin-top: 8px;
    }

    .sub-heading,
    .sub-heading a {
      color: $rit-copper;
      font-size: 12px;
    }
  }

  .link-wrapper {
    width: 100%;
    padding: 0 20px;
    margin-top: 38px;

    @include tablet {
      padding: 0 20px 30px;
      margin-top: 0;
    }

    .link, a {
      margin: 0 14px 20px;
      display: inline-block;

      @include tablet {
        margin: 0 22px 20px;
      }

      @include desktop {
        margin-bottom: 0;
      }
    }
  }

  &.simple-text-block-small {
    .inner-wrapper {
      min-height: 280px;
      padding: 20px;
      max-width: 340px;
      margin: 0 auto;

      @include tablet {
        padding: 20px 40px;
        max-width: 1140px;
      }

      @include desktop {
        padding: 20px;
        max-width: 340px;
      }
    }
  }

  @include mobile-only {
    .long-description {
      margin-bottom: 10px;
    }
  }
}

.home-page .simple-text-block {
  @include tablet {
    padding: 32px 0;

    .inner-wrapper {
      min-height: 120px;

      p {
        margin-bottom: 0;
      }
    }

    &.simple-text-block-slider .inner-wrapper {
      min-height: 10px;

      p {
        margin-bottom: 10px;
      }
    }

    &.drag-list {
      padding: 30px 0 10px;
    }
  }
}

.home-page {
  .advent-gradient {
    .simple-text-block {
      @include tablet {
        padding: 30px 0;
      }

      .inner-wrapper {
        .heading-intro {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 10px;
        }

        .acc-content-head {
          padding-left: 0;

          &::before {
            left: unset;
            right: -40px;
          }
        }

        br {
          display: none;
        }
      }
    }
  }
}

.simple-text-block-slider {
  .inner-wrapper {
    display: block;
    min-height: 10px;
  }

  .slick-slider {
    &::before,
    &::after {
      bottom: 0;
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      width: 50px;
      z-index: 3;
    }

    &::before {
      background: linear-gradient(to left, rgba($rit-sandy-bg, 0), rgba($rit-sandy-bg, 1));
      left: 0;
    }

    &::after {
      background: linear-gradient(to right, rgba($rit-sandy-bg, 0), rgba($rit-sandy-bg, 1));
      right: 0;
    }
  }

  .slider-item {
    + .slider-item {
      display: none;
    }

    a {
      font-size: 14px;
      letter-spacing: .5px;
      font-family: $font-header;
    }
  }

  .slick-dots {
    font-size: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 3px;
    }

    button {
      background-color: $gray-concrete;
      border-radius: 50%;
      display: inline-block;
      font-size: 0;
      height: 4px;
      width: 4px;
    }

    .slick-active button {
      background-color: $rit-dark;
    }
  }

  &.white-bg-color .slick-slider {
    &::before {
      background: linear-gradient(to left, rgba($white, 0), rgba($white, 1));
    }

    &::after {
      background: linear-gradient(to right, rgba($white, 0), rgba($white, 1));
    }
  }

  &.black-bg-color .slick-slider {
    &::before {
      background: linear-gradient(to left, rgba($rit-black, 0), rgba($rit-black, 1));
    }

    &::after {
      background: linear-gradient(to right, rgba($rit-black, 0), rgba($rit-black, 1));
    }
  }

  &.simple-text-block-pd {
    background-color: $rit-sandy-bg;

    p {
      color: $rit-black;
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: 0.4px;
      line-height: 33px;
      margin: 0 auto 10px;
      max-width: 400px;

      a {
        font: inherit;
      }
    }
  }

  @include mobile-only {
    .text-block-slider {
      padding: 15px 0 10px;
      width: 100%;
      -webkit-text-size-adjust: 100%;

      &::before,
      &::after {
        width: 30px;
      }
    }

    .slider-item {
      padding: 0 30px;
    }
  }

  @include tablet {
    .text-block-slider {
      padding: 5px 0 0;
      width: 100%;
    }
  }
}

.shipping-info-giftsets-slider {
  .inner-wrapper {
    display: block;
    min-height: 10px;
    max-width: 280px;
  }

  .slider-item {
    + .slider-item {
      display: none;
    }

    & > div {
      position: relative;
      text-align: center;
      font-size: 31px;
      width: 30px;
      margin: 0 auto 20px;
      height: 31px;
      color: $rit-copper;

      &::before {
        @include icon-styles;
        content: icon-char(candle);
        display: inline-block;
        line-height: 1;
      }
    }

    p {
      text-align: center;
      color: $rit-black;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
    }

    a {
      font-size: 14px;
      letter-spacing: .5px;
    }
  }

  .slick-dots {
    font-size: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 3px;
    }

    button {
      background-color: $gray-concrete;
      border-radius: 50%;
      display: inline-block;
      font-size: 0;
      height: 4px;
      width: 4px;
    }

    .slick-active button {
      background-color: $rit-dark;
    }
  }

  &.simple-text-block-pd {
    p {
      color: $rit-black;
      font-family: $font-header;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 33px;
      margin: 0 auto 10px;
      max-width: 400px;

      a {
        font: inherit;
      }
    }
  }

  @include mobile-only {
    .slider-item {
      padding: 0 10px;
    }
  }
}

.simple-text-block.drag-list {
  .inner-wrapper {
    display: block;
    min-height: 10px;

    @include mobile-only {
      max-width: 100%;
      padding: 0;
    }
  }

  .text-block-slider {
    width: 100%;

    @include mobile-only {
      padding: 15px 0 10px;
    }

    @include tablet {
      padding: 5px 0 26px;
    }
  }

  .slider-item {
    @include mobile-only {
      width: calc(100vw - 30px);
    }

    a {
      font-size: inherit;
      letter-spacing: .5px;
      font-family: $font-header;
    }
  }

  .drag-list-body {
    @include mobile-only {
      padding: 0 30px;
    }

    @include tablet {
      padding: 0;
    }
  }
}
