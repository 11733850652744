.pt_customer-service {
  .page-content {
    background-color: $rit-sandy-light;
    margin: 0 auto;
    padding-top: 39px;

    @include tablet {
      padding-top: 79px;
    }
  }

  .cs-content {
    .contact-us-headline {
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 28px;
      text-transform: none;
      font-family: $font-header;
      padding-bottom: 15px;
      border-bottom: 0;
      margin-bottom: 16px;

      @include tablet {
        margin: 8px 0 16px;
      }
    }

    .form-row {
      margin-bottom: 21px;
    }

    .form-caption-hint {
      margin-bottom: 15px;
      font-size: 12px;
      letter-spacing: 1px;
      color: $gray-medium;
      line-height: 14px;
      margin-top: -4px;

      @include tablet {
        margin-top: 0;
      }
    }

    .selectric-wrapper {
      margin-top: 4px;
    }

    .comment-wrapper {
      margin-top: 20px;

      .form-row {
        margin-bottom: 37px;
      }

      .input-textarea {
        margin-top: 4px;
      }
    }

    .upload-input-label {
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 35px;
      display: block;
      color: $rit-dark;
      font-family: $font-header;
      text-transform: none;
    }

    .input-file-wrapper {
      position: relative;
      cursor: pointer;
      display: flex;
      margin-top: 8px;

      .file-button-wrapper,
      .file-name-wrapper {
        display: flex;
        flex-direction: column;
      }

      .file-name-wrapper {
        justify-content: center;
      }

      input[type='file'] {
        display: none;
      }

      .custom-file-upload {
        z-index: 0;
        text-transform: uppercase;
        font-size: 12px;
        padding: 12px 14px;
        min-width: 165px;
        line-height: 18px;
        letter-spacing: 2px;
        background-color: transparent;
        border: 1px solid $gray-gainsboro;
        color: $rit-black;
        display: inline-block;
        font-family: $font-body;
        width: auto;
        min-height: 36px;
        text-align: center;
        text-decoration: none;
        transition: .2s ease-in-out;
        cursor: pointer;

        @include desktop {
          &:hover,
          &:active {
            background-color: $rit-black;
            border: 1px solid $white;
            color: $white;
          }
        }
      }

      .contactus-filename,
      .contactus-nofiles {
        margin-left: 21px;
        font-size: 14px;
        letter-spacing: 0.4px;
        display: inline-block;
        line-height: 30px;
        cursor: initial;

        @include tablet {
          max-width: none;
        }
      }
    }

    .form-row.namerow {
      margin-bottom: 15px;
      padding-bottom: 30px;
      border-bottom: 1px solid $gray-gainsboro;
      max-width: none;

      @include tablet {
        margin-top: 10px;
      }

      input {
        border: 0;
      }

      .label-visible {
        display: inline-block;

        @include tablet {
          cursor: pointer;
        }
      }
    }

    .form-row-button {
      margin-top: 39px;

      button {
        display: block;
        width: auto;
        margin: 0 auto;

        @include tablet {
          float: right;
        }
      }
    }
  }

  .content-banner {
    min-height: 350px;

    @include tablet {
      min-height: 450px;
      background-color: $rit-sandy-bg;
    }

    .inner-wrapper {
      padding: 0;

      @include tablet {
        padding: 0 20px;
      }
    }

    .inner-indented {
      max-width: 650px;
    }

    .text-holder {
      padding: 85px 20px 42px;

      @include tablet {
        padding: 107px 0 109px;
      }

      .heading-h1 {
        letter-spacing: 1.5px;
        line-height: 46px;
        margin-bottom: 27px;

        @include tablet {
          line-height: 60px;
          letter-spacing: 2px;
        }
      }

      p {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 32px;

        &:first-of-type {
          max-width: 200px;
          display: block;
          margin: 0 auto;
        }

        @include tablet {
          font-size: 24px;
          letter-spacing: .46px;
          line-height: 44px;
          margin-bottom: 0;

          &:first-of-type {
            max-width: none;
          }
        }
      }
    }
  }

  .secondary-navigation ul {
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
  }

  .contact-wrapper {
    max-width: 640px;
    display: block;
    margin: 0 auto;
    padding-bottom: 70px;

    .contact-confirmation-message {
      text-align: center;

      @include tablet {
        padding-bottom: 38px;
      }

      h1 {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 35px;
        text-transform: none;

        @include tablet {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }

    .back-button {
      display: none;
    }
  }

  .order-number .form-row {
    margin-bottom: 10px;
  }

  .contact-phone .form-row {
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  .confirmation-message {
    padding: 24px 30px 18px;
    margin-top: -10px;
    text-align: center;
    background-color: $rit-sandy-light;

    @include tablet {
      padding: 0 30px 137px;
    }

    p:first-of-type {
      font-size: 20px;
      letter-spacing: 1.67px;
      line-height: 24px;
      margin-bottom: 11px;
      font-family: $font-header;
      max-width: 235px;

      @include tablet {
        font-size: 30px;
        letter-spacing: 0.9px;
        line-height: 45px;
        margin-bottom: 6px;
        max-width: none;
      }
    }

    p:last-of-type {
      max-width: none;
    }
  }

  .extensions-text {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $font-body;
  }

  // styles for return form (No, Se)
  .returnform-wrapper {
    padding-bottom: 50px;

    .form-row {
      padding-top: 0;
    }

    h1.h6 {
      margin-bottom: 30px;
      font-size: 25px;
      line-height: 38px;
      text-transform: none;

      @include tablet {
        font-size: 40px;
        letter-spacing: 3.3px;
        line-height: 50px;
      }
    }

    p {
      margin-bottom: 30px;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }

    h2.h6 {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 2.4px;
      text-transform: none;

      @include tablet {
        font-size: 30px;
        letter-spacing: 2.5px;
        line-height: 36px;
      }
    }

    .contactus-content.returnform {
      .label-radio {
        margin-bottom: 8px;

        @include tablet {
          margin-bottom: 20px;
        }
      }

      .headline-label {
        margin-bottom: 20px;
        padding-left: 0;

        &::before,
        &::after {
          display: none;
        }
      }

      .radio-label {
        display: block;
        margin-bottom: 16px;
      }

      .return-form-fields {
        @include tablet {
          margin-top: 50px;
        }
      }

      .returnform-button {
        margin: 65px 0;
      }

      .content li {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
        margin-left: 15px;
      }
    }
  }
}
