.two-tile-vertical {
  display: flex;
  padding: 100px 0;

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;

    > div:not([class]) {
      max-width: 100%;
    }
  }

  .video-holder {
    display: none;
  }

  .text-holder {
    margin: 20px 0;
    max-width: 100%;

    h1 {
      font-size: 30px;
      letter-spacing: 4px;
      line-height: 40px;
      text-transform: none;
    }

    p {
      max-width: 455px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  .image-holder {
    margin: 20px 0;
    max-width: 100%;
    transition: transform $secondary-duration $default-ease-out-fast;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &[class*='in-viewport'] {
    overflow: hidden;
  }

  &.in-viewport .image-holder {
    transform: scale(1);
  }

  &.not-in-viewport .image-holder {
    transform: scale(1.2);
  }

  .link-wrapper {

    .link, a {
      margin: 0 14px 20px;
      display: inline-block;
    }
  }

  img {
    max-width: 100%;
  }

  @include tablet {
    min-height: 900px;
    padding: 0 0 30px;

    .video-holder {
      display: block;
      flex-shrink: 0;
      height: 0;
      padding-bottom: 44.183%;
      position: relative;
      width: 100%;
    }

    .link-wrapper + .video-image-holder {
      margin-top: 60px;
    }

    .image-holder {
      margin: 60px 0;
    }

    .text-holder {
      margin: 30px 0;

      h1 {
        font-size: 40px;
        letter-spacing: 1.6px;
        line-height: 50px;
      }
    }

    .video-image-holder {
      width: 100%;

      .image-holder {
        display: none;
      }
    }

    .link-wrapper {
      margin-bottom: -7px;

      .link, a {
        margin: 0 22px 20px;
      }
    }
  }

  @include desktop {
    .link-wrapper {
      .link, a {
        margin-bottom: 0;
      }
    }
  }

  &.two-tile-vertical-small {
    max-width: 360px;
    min-height: 480px;
    padding: 30px 0;

    .image-holder,
    .text-holder {
      margin: 10px 0;
    }

    //fix for IE
    .image-holder {
      min-height: 1px;
    }

    .btn {
      margin: 10px 0;
    }
  }

  &.invert {
    .inner-wrapper {
      flex-direction: column-reverse;
    }
  }
}
