.product-highlight-block {
  width: 100%;
  padding: 30px 0;

  .inner-wrapper {
    max-width: none;
    padding: 0;

    @include tablet {
      width: 100%;
      max-width: 1320px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  .image-holder {
    width: 100%;

    img {
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }
}
