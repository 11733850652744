.request-password-wrapper {
  .password-message-wrapper {
    padding: 57px 0 100px;

    @include tablet {
      padding: 61px 0 160px;
    }

     // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include tablet {
        padding: 40px 0 160px;
      }
    }
  }

  .rituals-form {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      background-color: $white;
      margin: 0;
      max-width: none;

      @include mobile-only {
        padding: 24px;
      }

      @include tablet {
        padding: 40px;
      }

      .btn {
        max-width: none;
        width: 100%;
      }
    }
  }

  .inner-wrapper-small {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include tablet {
        max-width: none;
      }
    }
  }

  .inner-wrapper {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include mobile-only {
        padding: 0;
      }
    }
  }

  .page-layout-left {
    .password-form {
      max-width: 616px;
      margin: 18px auto 0;

      @include tablet {
        margin-top: 51px;
      }

      // Checkout V3 Overrides
      .login-checkout-v3 & {
        margin: 0;
        max-width: none;

        @include tablet {
          margin: 0;
        }
      }

      .password-reset__btn {
        margin-top: 40px;
        width: 100%;

        @include tablet {
          margin-top: 50px;
          width: auto;
        }

        // Checkout V3 Overrides
        .login-checkout-v3 & {
          @include tablet {
            margin-top: 40px;
            width: 100%;
          }
        }
      }
    }
  }
}

.reset-password-confirmation,
.new-password-confirmation {
  align-items: center;
  background: $rit-sandy-light;
  box-shadow: 0 4px 6px -3px $gray-medium;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 24px;
  min-height: 149px;
  padding: 0 30px;
  position: fixed;
  text-align: center;
  transform: translateY(-110%);
  transition: transform $tertiary-duration $default-ease-out-fast;
  top: 0;
  width: 100%;
  z-index: $modal-window-index;
  opacity: 0;

  // To prevent the element visibilty while the page is loading we initially
  // set the opacity 0. We set it to 1 by adding this class on page load.
  &.page-loaded {
    opacity: 1;
  }

  @include desktop {
    display: none;
    position: absolute;
    max-width: 480px;
    height: auto;
    padding: 60px 70px;
    top: 35%;
    left: 50%;
    transform: translate(-50%);
    transition: none;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    box-shadow: none;
  }

  .password-close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 16px;
    font-size: 16px;
  }

  .text-container {
    display: block;

    @include tablet-landscape {
      margin-bottom: 19px;
    }

    @include desktop {
      margin-bottom: 19px;
    }
  }

  .btn.btn--secondary {
    display: none;

    @include tablet-landscape {
      display: block;
      min-width: 180px;
      margin: 0 auto;
    }

    @include desktop {
      display: block;
      min-width: 180px;
      margin: 0 auto;
    }
  }

  &.open {
    display: flex;
    flex-direction: column;
    transform: translateY(0);

    @include desktop {
      display: block;
      transform: translate(-50%);
    }
  }
}

.new-password-confirmation {
  min-height: 225px;
  padding-top: 15px;

  @include tablet-landscape {
    padding-top: 60px;
  }

  @include desktop {
    padding-top: 60px;
  }

  .btn.btn--secondary {
    display: block;
    margin-top: 30px;

    @include tablet-landscape {
      margin-top: 0;
    }

    @include desktop {
      margin-top: 0;
    }
  }
}
