.collection-cards-block {
  overflow: hidden;
  padding: 31px 5px 35px;
  position: relative;

  .slick-arrow {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;
    padding: 0 5px;

    > div {
      display: flex;
      width: 100%;
    }
  }

  .collection-cards {
    width: calc(100% + 50px);
    margin: 0 -25px;

    @include mobile-only {
      .card-item:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }
  }

  .collection-cards-slider {
    .card-item {
      box-shadow: 0 3px 25px 3px rgba($black, .1);

      @include mobile-only {
        & + .card-item {
          display: none;
        }
      }
    }
  }

  .card-item {
    background-color: $white;
    text-align: center;
    width: 100%;
  }

  .card-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .text-holder {
    background-color: $white;
    padding: 24px 25px 21px;
    position: relative;

    h3 {
      font-size: 24px;
      letter-spacing: 1.2px;
      line-height: 30px;
      margin-bottom: 12px;
      text-transform: none;
    }

    p {
      line-height: 24px;
      margin-bottom: 11px;
      letter-spacing: 0.5px;
    }
  }

  .image-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 230px;
  }

  @include tablet {
    padding: 50px 0 0;

    .inner-wrapper {
      max-width: 1360px;
    }

    .collection-cards,
    .collection-cards-slider,
    .simplebar-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .card-item {
        box-shadow: none;
        margin-bottom: 40px;
        width: 47.7%;
      }
    }

    .collection-cards {
      width: auto;
      margin: 0;
    }

    .text-holder {
      padding: 41px 40px 31px;
      transition: padding .3s ease;

      h3 {
        font-size: 40px;
        letter-spacing: 1.6px;
        line-height: 50px;
        margin-bottom: 21px;
      }

      p {
        margin: 0 auto 16px;
        max-width: 388px;
        letter-spacing: 1px;
        line-height: 28px;
      }

      .btn {
        letter-spacing: 2.6px;
        min-width: 10px;
      }
    }

    .image-holder {
      height: 300px;
      transition: height .3s ease;
    }

    .slider-bar {
      display: none !important;
    }

    .slider-dots {
      display: none;
    }
  }

  @include desktop {
    .image-holder {
      height: 420px;
    }
  }
}

.zoomed-in {
  @include mobile-only {
    .collection-cards-block .slider-dots {
      bottom: 55px;
      display: block;
    }
  }
}

.collection-cards-block.drag-list {
  @include mobile-only {
    padding: 0;

    .inner-wrapper {
      padding: 0;
    }

    .drag-list-body {
      padding: 31px 25px 35px;

      .card-item {
        opacity: 1;
      }
    }

    .card-item {
      background-color: transparent;
      box-shadow: none;
      opacity: 0;
      padding: 0 5px;
      width: calc(100vw - 40px);

      + .card-item {
        display: block;
      }
    }

    .card-inner {
      background-color: $white;
      box-shadow: 0 3px 25px 3px rgba($black, 0.1);
    }
  }
}
