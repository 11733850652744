.ui-tooltip {
  background-color: $white;
  color: $black;
  outline: 1px solid $gray-gainsboro;
  border: 0;
  font-size: 14px;
  line-height: normal;
  padding: 5px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    transform: rotate(45deg) translateX(-50%);
    left: 50%;
    bottom: -7px;
    background-color: $white;
    transform-origin: center;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0 , 0.14);
  }
}
.tooltip-content {
  display: none;
}
