.pt_outlet {
  .main {
    margin-bottom: 30px;
  }

  .search-filter-box {
    .breadcrumb {
      margin-bottom: 17px;
    }

    .filter-box-categories {
      margin-bottom: 25px;

      sup {
        left: 5px;
        top: -15px;
      }
    }
  }

  .item.no-results-item {
    margin: 80px 0 0;
    width: 100%;
  }
  .no-results {
    padding: 30px;
    text-transform: none;
  }

  .search-grid-wrapper .filter-trigger-btn.has-filters::before {
    background-color: $rit-copper;
    bottom: -3px;
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    right: 21px;
  }

  .refinements .filter-inner {
    padding-top: 61px;
  }

  @include tablet {
    .item.no-results-item {
      margin: 90px 0 60px 60px;
      width: calc(66.666% - 68px);
    }

    .refinements .filter-inner {
      padding-top: 90px;
    }
  }
}

.outlet-hero-banner {
  .content-banner {
    @include tablet {
      margin-bottom: -110px;
    }

    &.banner-small {
      min-height: 180px;
    }

    .image-holder::after {
      background: linear-gradient(to bottom, rgba(35, 31, 32, 0), rgba(35, 31, 32, .5));
      bottom: 0;
      content: '';
      display: inline-block;
      left: 0;
      opacity: .8;
      position: absolute;
      right: 0;
      top: 0;
    }

    .text-holder {
      padding: 30px 0;
    }

    .heading-h1 {
      line-height: 40px;
      margin-bottom: 3px;
    }

    .heading-intro {
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 32px;
    }

    .outlet-instructions-link {
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  @include mobile-only {
    .heading-h1 {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @include tablet {
    .content-banner {
      &.banner-small {
        min-height: 505px;
      }

      .image-holder::after {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(26, 25, 25, 1));
        opacity: .5;
      }

      .text-holder {
        padding: 60px 0 70px;
      }

      .heading-h1 {
        color: $white;
        margin-bottom: 15px;
      }

      .heading-intro {
        color: $white;
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 30px;
      }
    }
  }
}
