//
// Default Draglist Style Definitions
// ----------------------------------------------------------------------------
//

.drag-list {
  position: relative;
  width: 100%;

  @include mobile-only {
    margin-bottom: 20px;
  }

  .drag-list-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;

    // sass-lint:disable-block no-misspelled-properties
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.init {
      visibility: visible;
    }

    &.effect-magnify {
      .drag-list-body > * {
        @include mobile-only {
          margin-top: 20px !important;
        }
      }
    }
  }

  .drag-list-controls {
    display: none;

    @include tablet {
      display: block;
    }

    button {
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity $tertiary-duration $default-ease-out;
      z-index: 1;

      &:first-child {
        left: 60px;
      }

      &:last-child:not(:only-child) {
        right: 60px;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .drag-list-body {
    display: flex;

    @include mobile-only {
      padding: 0 25px;
    }

    @include tablet {
      padding: 0 60px;
    }
  }
}
