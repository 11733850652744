.product-hero-container {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;

  .product-hero-content.product-hero-box-list {
    justify-content: center;
  }

  @include tablet {
    min-height: 660px;
    max-height: 760px;
  }
}

.product-hero-media {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform $secondary-duration $default-ease-out-fast;
  width: 100%;

  @include tablet {
    height: 100%;
  }

  .in-viewport & {
    transform: scale(1);
  }

  .not-in-viewport & {
    transform: scale(1.2);
  }
}

.product-hero-content {
  margin: 332px 0 30px;
  padding: 0 20px;
  position: relative;
  width: 100%;

  .product-hero-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: $white;
    min-height: 370px;
    padding: 40px 20px 44px;
    text-align: center;

    .ritual-icon {
      color: $copper;
      font-size: 25px;
      line-height: 1;
      margin-bottom: 20px;
    }

    .giftset-valueprice {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      font-weight: 600;
      color: $rit-black;
    }

    h3 {
      font-size: 22px;
      letter-spacing: 1.2px;
      line-height: 30px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }

    p {
      line-height: 28px;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.product-hero-box-list {
    .product-hero-box {
      width: 100%;

      .product-hero-box-title {
        margin-bottom: 50px;
      }
    }

    .heading-h9 {
      margin-bottom: 25px;
      color: $copper-medium;
    }
  }

  .product-hero-list {
    font-family: $font-body;
    list-style: none;
    color: $rit-black;

    li {
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 70px;
        background-color: $gray-gainsboro;
      }

      &.no-divider {
        margin: 0;
        padding: 0;

        &::after {
          content: none;
          background-color: transparent;
        }
      }
    }

    ul {
      list-style: none;
    }

    span {
      display: block;
    }
  }

  // IE10+ specific styles - Flexbox fixes
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .product-hero-box {
      height: 460px;

      * {
        max-width: 100%;
      }
    }
  }

  @include desktop {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;

    .product-hero-box {
      min-height: 460px;
      margin-right: 10%;
      max-width: 100%;
      padding: 80px 60px;
      transition: padding .3s ease;
      width: 480px;

      h3 {
        font-size: 30px;
        letter-spacing: 1.2px;
        line-height: 40px;
        margin-bottom: 20px;
        text-transform: none;
      }
    }
  }
}

.product-hero-container-table {
  @include mobile-only {
    flex-direction: column-reverse;
  }
}

.product-hero-box-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: $ritual-army;
  color: $white;
  font-family: $font-header;

  .product-hero-box {
    max-width: 400px;
  }

  h3 {
    margin-bottom: 20px;
    text-transform: initial;
  }

  p {
    color: $white;
  }

  @include mobile-only {
    width: 88%;
    margin: -40px auto 0;
    z-index: 1;
  }

  @include tablet {
    width: 50%;
  }
}

.product-hero-table {
  display: flex;
  justify-content: space-around;
  margin-top: 48px;
  list-style: none;
}

.product-hero-table-col {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: $gray-silver;
  }

  &:last-child::after {
    display: none;
  }

  .ico {
    font-size: 20px;
  }

  .product-hero-table-type {
    margin-bottom: 10px;
    font-size: 15px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

	.product-hero-table-perc {
		font-size: 24px;
	}

  @include mobile-only {
    .product-hero-table-type {
      font-size: 10px;
      letter-spacing: 1.6px;
    }

    .product-hero-table-perc {
      font-size: 18px;
    }
  }
}

.product-disclaimer {
  max-width: 480px;
  margin: 30px auto;
  padding: 0 20px;

  p {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }
}

.content-right-aligned {
  @include tablet {
    flex-direction: row !important;
  }
}

.content-left-aligned {
  @include tablet {
    flex-direction: row-reverse !important;
  }
}

.product-hero-container-customisable {
  flex-direction: column;

  @include tablet {
    min-height: 405px;
    max-height: none;
    flex-direction: row;
  }

  @include desktop {
    min-height: 760px;
  }

  .product-hero-media {
    position: relative;
    width: 100%;
    height: 395px;

    @include tablet {
      width: 50%;
      min-height: 405px;
      height: auto;
    }

    @include desktop {
      min-height: 760px;
    }
  }

  .product-hero-content {
    position: relative;
    transform: translateY(0);
    padding: 0;
    justify-content: flex-start;
    background-color: $white;
    margin: 0;
    width: 100%;
    display: flex;

    @include tablet {
      width: 50%;
    }

    .product-hero-box {
      width: auto;
      max-width: 720px;
      margin-right: 0;
      min-height: 0;
      padding: 60px 25px 59px;

      @include tablet {
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
      }

      @include desktop {
        padding: 80px 135px;
      }

      h3 {
        text-transform: none;
        letter-spacing: 1.2px;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;

        @include tablet {
          letter-spacing: 0.46px;
          line-height: 44px;
          margin-bottom: 12px;
        }

        @include desktop {
          font-size: 30px;
          letter-spacing: 1.2px;
          line-height: 40px;
          margin-bottom: 22px;
        }
      }

      p {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 1px;

        @include tablet {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
        }

        @include desktop {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 1px;
        }
      }
    }
  }

  &.how-to-use-block-amsterdam .product-hero-content {
    margin: 0;
  }

  &.how-to-use-block-reversed {
    .product-hero-media {
      @include mobile-only {
        order: -1;
      }
    }
  }
}

.product-hero-container-giftset {
  flex-direction: column;
  padding-bottom: 30px;

  @include mobile-only {
    min-height: 524px;
    padding-bottom: 20px;
  }

  @include tablet {
    min-height: 722px;
    padding-bottom: 30px;
    max-height: none;
    flex-direction: column;
  }

  @include desktop {
    min-height: 760px;
  }

  .product-hero-media {
    position: relative;
    max-width: 900px;
    min-height: 280px;
    margin: 0 auto;

    @include mobile-only {
      height: 300px;
    }

    @include tablet {
      min-height: 405px;
    }

    @include desktop {
      min-height: 522px;
    }
  }

  .product-hero-media-zoomed {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image-zoomed {
    display: block;
  }

  .product-hero-media-close {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $black;
    top: 10px;
    right: 10px;

    span {
      color: $white;
      padding: 6px;
      font-size: 19px;
    }

    &:hover {
      background-color: $white;

      span {
        color: $black;
      }
    }

    @include mobile-only {
      width: 40px;
      height: 40px;
      top: 20px;
      right: 20px;

      span {
        font-size: 16px;
        padding-bottom: 7px;
      }
    }
  }

  .product-hero-media-zoom {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .product-hero-media-text {
    position: absolute;
    right: 20%;
    top: 11%;
    color: $copper-medium-darker;
    max-width: 80px;
    text-align: center;

    p {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 1.08px;
    }

    @include mobile-extra-small {
      max-width: 60px;
    }

    @include mobile-only {
      right: 0;
      top: 2%;
    }
  }

  .zoom {
    background: url('../images/zoom.svg') center top no-repeat;
    background-size: 49px 49px;
    height: 49px;
    width: 49px;
  }

  .product-hero-content {
    position: relative;
    transform: translateY(0);
    padding: 0;
    justify-content: center;
    background-color: $rit-sandy-light;
    margin: 0;
    width: 100%;
    display: flex;

    .product-hero-box {
      width: auto;
      background-color: $rit-sandy-light;
      max-width: 720px;
      margin-right: 0;
      min-height: 0;
      padding: 66.5px 160px;

      @include mobile-only {
        padding: 60px 0 20px;
      }

      h4 {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 16px;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $copper-medium-darker;

        @include mobile-only {
          font-size: 12px;
          letter-spacing: 1.7px;
          margin-bottom: 25px;
        }
      }

      h3 {
        text-transform: none;
        letter-spacing: 1.2px;
        color: $rit-black;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;

        @include mobile-only {
          max-width: 300px;
        }

        @include tablet {
          letter-spacing: 0.46px;
          line-height: 44px;
          margin-bottom: 12px;
        }

        @include desktop {
          font-size: 32px;
          letter-spacing: 1.4px;
          line-height: 40px;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: $rit-black;
      }
    }
  }
}

.how-to-use-block-amsterdam {
  .product-hero-content {
    margin: 218px 0 100px;

    @include tablet {
      margin: 0;
    }
  }

  .product-hero-box {
    padding: 43px 30px 30px;

    @include tablet {
      padding: 20px 30px;
      height: 480px;
    }

    @include desktop {
      padding: 20px 50px;
    }

    h3 {
      text-transform: none;
      font-size: 30px;
      letter-spacing: 4px;
      line-height: 40px;
      margin-bottom: 16px;

      @include tablet {
        font-size: 40px;
        letter-spacing: 1.6px;
        line-height: 50px;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }
  }
}

.how-to-use-luxury {
  @include mobile-only {
    flex-direction: column;
  }

  @include tablet {
    min-height: 700px;
  }

  .product-hero-media {
    @include mobile-only {
      height: 288px;
      left: auto;
      order: 2;
      position: relative;
      top: auto;
    }

    @include tablet {
      width: 50%;
    }
  }

  .product-hero-content {
    @include mobile-only {
      margin: 0;
      order: 1;
    }

    @include tablet {
      left: auto;
      justify-content: flex-start;
      margin-left: 50%;
      padding: 0;
      position: relative;
      top: auto;
      transform: none;
      width: 50%;
    }

    .product-hero-box {
      background-color: transparent;
      min-height: 256px;
      padding: 65px 5px 37px;

      @include tablet {
        margin-right: 0;
        max-width: 720px;
        min-height: 0;
        padding: 60px;
        width: 100%;
      }

      h3 {
        text-transform: none;

        @include mobile-only {
          font-size: 24px;
          letter-spacing: 1.5px;
          line-height: 32px;
          margin-bottom: 18px;
        }

        @include tablet {
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 2px;
          margin-bottom: 18px;
          max-width: 500px;
        }
      }

      p {
        letter-spacing: 0.5px;
        line-height: 24px;

        @include tablet {
          font-size: 14px;
          max-width: 500px;
        }
      }
    }
  }

  &.how-to-use-easter {
    .product-hero-media {
      @include mobile-only {
        order: 1;
      }
    }

    .product-hero-box {
      h3 {
        @include tablet {
          font-size: 32px;
          line-height: 42px;
          letter-spacing: 0.2px;
          margin-bottom: 32px;
        }
      }

      p {
        @include tablet {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
}
