//
// Product Choice List
// ----------------------------------------------------------------------------
//

.product-choice-list {
  height: 100%;
  padding: 60px 0 90px 0;
  position: relative;

  .product-choice-scroll {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .product-choice-header {
    @include mobile-only {
      padding: 4px 24px 24px 24px;
    }

    @include tablet {
      padding: 40px 24px 24px 24px;
    }

    h2 {
      margin-bottom: 8px;
      text-transform: none;

      @include mobile-only {
        font-size: 20px;
        letter-spacing: 0.8px;
        line-height: 24px;
      }

      @include tablet {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 32px;
      }
    }

    span {
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }

  .product-choice-options {
    border-top: 1px solid $gray-gainsboro;

    .input-type-radio {
      border-bottom: 1px solid $gray-gainsboro;
      position: relative;

      @include mobile-only {
        padding: 24px;
      }

      @include tablet {
        padding: 24px 40px;
      }

      &.chosen {
        background-color: $rit-sandy-dark;
      }

      label::before {
        top: calc(50% - 12px);
      }

      img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include mobile-only {
          width: 56px;
        }

        @include tablet {
          width: 64px;
        }
      }
    }

    .option-ritual,
    .option-title {
      display: block;
      font-family: $font-header;

      @include mobile-only {
        max-width: calc(100% - 80px);
      }

      @include tablet {
        max-width: calc(100% - 88px);
      }
    }

    .option-ritual {
      color: $copper;
      font-size: 12px;
      letter-spacing: 1.7px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .option-title {
      @include mobile-only {
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 24px;
      }

      @include tablet {
        font-size: 20px;
        letter-spacing: 0.7px;
        line-height: 32px;
      }
    }
  }

  .product-choice-footer {
    background-color: $white;
    bottom: 0;
    padding: 24px;
    position: absolute;
    width: 100%;
    z-index: 1;

    &::before {
      background-color: $white;
      box-shadow: 0 -8px 5px -2px $white;
      content: '';
      height: 14px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    .btn {
      max-width: none;
      width: 100%;
    }
  }
}
