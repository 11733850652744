.pt_faq {
  .faq-page-content {
    background-color: $rit-sandy-bg;
    margin: 0 auto;
    padding-top: 39px;

    @include tablet {
      padding-top: 79px;
    }
  }

  .faq-content {
    .inner-wrapper {
      margin: 0;
      padding: 0;

      @include tablet {
        margin: 0 auto;
        padding: 0 20px;
      }
    }

    .first-item {
      pointer-events: all;
      color: $rit-black;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 4px;
      cursor: pointer;
      white-space: normal;
      margin: 0 0 18px 20px;
      max-width: 310px;
      text-transform: none;

      @include tablet {
        font-size: 40px;
        letter-spacing: 1.6px;
        line-height: 50px;
        margin: 0 0 34px 15px;
        padding-bottom: 22px;
        border-bottom: 1px solid $gray-gainsboro;
        max-width: none;
      }
    }
  }

  .faq-wrapper {
    display: flex;
    flex-direction: row;
  }

  .page-content {
    width: 100%;
    overflow: hidden;
  }

  .faq-back-button {
    position: absolute;
    top: 20px;
    font-size: 12px;
    letter-spacing: 2px;
    color: $gray-dark;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $font-body;

    @include tablet {
      display: none;
    }

    span {
      vertical-align: middle;
      margin-right: 5px;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .faq-inner {
    display: flex;
    justify-content: stretch;
    width: 200%;
    flex-direction: column;
    padding-top: 30px;

    @include tablet {
      width: 100%;
      padding: 20px;
    }

    @include desktop {
      padding: 24px 106px 0 115px;
    }

    &.fixed {
      .faq-contact-headline {
        display: block;
        margin: -44px 0 25px;

        @include tablet {
          margin-top: -21px;
        }
      }
    }

    .faq-left-content {
      flex: 1;
      margin: 0 20px;

      @include mobile-only {
        flex: none;
        margin-right: auto;
        padding-bottom: 60px;
        width: calc(50% - 20px);
      }

      @include tablet {
        max-width: 280px;
        border-top: 0;
        margin: 0;
        padding-bottom: 30px;
      }
    }

    .faq-contact-holder {
      width: 100%;

      @include tablet {
        display: none;
      }

      .back-button,
      .faq-contact-headline {
        display: none;
      }

      .faq-contact {
        padding-top: 30px;
      }
    }

    .faq-right-content {
      margin-left: 0;
      flex: 1;

      @include mobile-only {
        flex: none;
        left: 100%;
        margin-left: auto;
        position: absolute;
        top: 0;
        width: calc(50% - 20px);

        .faq-back-button {
          top: -83px;
        }
      }

      @include tablet {
        margin-left: 30px;
        margin-top: 7px;
        flex: 2;
      }

      @include desktop {
        margin-left: 76px;
        margin-bottom: 50px;
      }

      .contact-form-wrapper {
        .contact-us-headline {
          @include mobile-only {
            margin-top: -33px;
          }
        }
      }

      .contact-form-wrapper:not(:empty) {
        @include mobile-only {
          margin-bottom: 80px;
        }
      }

      .contact-form-wrapper:not(:empty) + .faq-contact {

        .back-button,
        .faq-contact-headline {
          display: none;
        }
      }

      .back-button {
        position: absolute;
        top: -104px;
        left: -9px;

        @include tablet {
          display: none;
        }
      }

      .accordion {
        margin-top: 36px;
        padding-left: 0;

        @include tablet {
          margin-top: 20px;
        }

        > li {
          margin-left: 0;
          margin-right: 26px;

          @include tablet {
            margin: 0 21px;
          }

          &:first-of-type {
            border-top: 1px solid rgba($rit-copper-dark, 0.2);
          }

          &:empty {
            border: 0;
          }
        }
      }

      h3 {
        color: $rit-black;
        font-size: 24px;
        letter-spacing: 1.2px;
        white-space: normal;
        line-height: 30px;
        margin: -45px 20px 25px 0;
        text-transform: none;

        @include tablet {
          margin: 0 0 0 18px;
          line-height: 44px;
          font-size: 24px;
          letter-spacing: 0.46px;
        }

        span {
          vertical-align: middle;

          @include tablet {
            display: none;
          }
        }

        + .accordion,
        + form {
          display: block;

          h2 {
            text-transform: none;
            padding: 29px 30px 29px 0;
            font-size: 16px;
            color: $rit-black;
            letter-spacing: 1.07px;
            line-height: 20px;
            font-family: $font-header;
            border-bottom: 1px solid rgba($rit-copper-dark, 0.2);

            &.ui-state-active {
              border-color: transparent;
            }

            .ui-accordion-header-icon {
              top: 50%;
              left: auto;
              right: 0;
              position: absolute;
              margin-top: -15px;
              text-indent: 0;
              font-size: 25px;

              @include tablet {
                margin-top: -10px;
              }
            }
          }

          .ui-accordion-content {
            margin-bottom: 0;
            padding: 0 0 20px;
            border-bottom: 1px solid rgba($rit-copper-dark, 0.2);

            p,
            ul {
              font-size: 16px;
              color: $rit-dark;
              letter-spacing: 1px;
              line-height: 28px;
              font-family: $font-body;
              margin-bottom: 10px;
            }

            ul,
            table {
              margin-bottom: 10px;
            }

            h3 {
              margin: 0 0 10px;
            }

            th {
              text-align: left;
              padding-right: 10px;
            }

            td {
              padding-right: 10px;
            }
          }
        }

        + form {
          padding: 26px 2.2em;
        }
      }

      .contact-us-headline {
        @include tablet {
          margin: 8px 0 16px 20px;
        }
      }

      form {
        @include mobile-only {
          padding-right: 20px;
        }

        @include tablet {
          padding-left: 20px;
          max-width: 620px;
        }
      }
    }
  }

  .faq-sidebar {
    list-style: none;
    padding: 0;

    @include tablet {
      margin-top: 18px;
    }

    .faq-menu-option {
      margin: 20px 10px 20px 0;
      text-transform: none;
      cursor: pointer;
      font-family: $font-body;
      color: $rit-copper;
      letter-spacing: 1px;
      text-decoration: underline;
      font-size: 16px;
      line-height: 28px;

      @include tablet {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
        margin: 10px 0 20px 16px;
        border: 0;
      }

      &:first-of-type {
        margin-right: 20px;
        padding-top: 33px;
        border-top: 1px solid $gray-gainsboro;

        @include tablet {
          margin-right: 0;
          padding-top: 0;
          border-top: 0;
        }
      }


      &:hover {
        text-decoration: none;
      }

      &.active {
        color: $rit-dark;
        text-decoration: none;
      }
    }
  }

  .faq-view-mobile {
    transform: translateX(0);
    transition: all .3s ease;

    &.active-content {
      transform: translateX(-50%);

      .faq-left-content {
        position: absolute;
        right: 100%;
        top: 0;
      }

      .faq-right-content {
        left: auto;
        padding-bottom: 30px;
        position: relative;
        top: auto;
      }
    }
  }

  .faq-contact {
    padding: 70px 20px 0 0;

    @include tablet {
      padding: 20px 10px 20px 20px;
    }

    h3 {
      display: none;
    }

    li {
      font-family: $font-body;
      letter-spacing: 1px;
      color: $rit-dark;
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 10px;
      max-width: 270px;

      @include tablet {
        max-width: none;
      }

      &:first-of-type {
        margin-top: -15px;

        @include tablet {
          margin-top: -9px;
        }
      }

      b {
        font-weight: 700;
      }
    }
  }
}

.faq-questions {
  margin-top: 10px;
  padding-left: 0;

  @include tablet {
    margin-top: 0;
  }

  .icon-arrow3--left {
    @include tablet {
      display: none;
    }
  }

  li {
    list-style: none;
  }

  ul {
    padding-left: 0;
  }

  .faq-contact-headline {
    display: block;
    color: $rit-black;
    text-transform: none;
    line-height: 44px;
    font-size: 24px;
    letter-spacing: 0.46px;
    margin: -44px 0 25px;

    @include tablet {
      margin-top: -21px;
    }
  }

  .content {
    margin-bottom: 30px;

    @include tablet {
      padding: 40px 0 24px;
      max-width: 690px;
    }

    @include desktop {
      padding: 51px 0 24px;
    }

    .btn {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 20px;
    color: $black;
    line-height: 34px;
    letter-spacing: 1.67px;
    font-family: $font-header;

    @include tablet {
      margin-bottom: 41px;
      max-width: 385px;
    }
  }

  .row {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  .row__item {
    flex: 1;

    .row__item__content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $rit-copper;
      line-height: 2px;
      margin-bottom: 31px;

      @include tablet {
        margin-bottom: 26px;
      }

      &.btn--service a {
        font-family: $font-header;
        font-size: 12px;
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      em {
        display: block;
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 12px;

        &:hover {
          text-decoration: none;
        }
      }

      .icon--medium {
        color: $black;
        margin-right: 13px;
        font-size: 44px;
        line-height: 40px;

        &::before {
          vertical-align: middle;
        }
      }

      .icon-mail,
      .icon-email {
        @include icon(email);
        font-size: 34px;
      }

      .icon-service,
      .icon-user {
        @include icon(user);
        font-size: 36px;
      }

      .clickable {
        cursor: pointer;
      }

      .open-hours-content {
        line-height: 1;
        margin-bottom: 5px;

        span {
          font-size: 14px;
          color: $gray-medium;
          letter-spacing: 0.7px;

          &.opening-days {
            margin-right: 13px;
            color: $rit-dark;
          }
        }
      }
    }
  }
}

// FAQ redesign

.faq-page-title {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: none;
  margin: 0 auto 8px;
  max-width: 220px;

  @include tablet {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.8px;
    margin: 0 auto 16px;
    max-width: 400px;
  }
}

.faq-page-subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-family: $font-header;

  @include tablet {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
    max-width: 615px;
    margin: 0 auto;
  }
}

.faq-text-container {
  background-color: $rit-sandy-dark;
  padding: 41px 0 28px;

  @include tablet {
    padding: 60px 0 204px;
  }
}

.faq-question-form-blocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 960px;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);

  @include tablet {
    flex-direction: row;
    width: 100%;
    margin: -144px auto 30px;
  }
}

.faq-question-block,
.faq-form-block {
  width: 100%;

  @include tablet {
    width: 50%;
  }
}

.faq-form-block {
  background-color: $rit-black-lighter;
  padding: 41px 24px 19px;

  @include tablet {
    padding: 22px 40px 40px;
  }

  .wimo-form-wrapper {
    text-align: left;

    .wimo-form-title,
    .wimo-form-subtitle {
      color: $white;
    }

    .wimo-form-title {
      font-style: normal;

      @include tablet {
        margin: 18px auto 17px;
        font-size: 28px;
      }

      @include mobile-only {
        font-size: 20px;
        letter-spacing: 0.4px;
        margin: 0 auto 18px;
        max-width: none;
        text-align: center;
      }
    }

    .wimo-form-subtitle {
      letter-spacing: 0.4px;
      line-height: 32px;

      @include mobile-only {
        font-style: 16px;
        max-width: none;
      }
    }
  }

  .wimo-form {
    margin-top: 27px;

    .error-message {
      color: $white;
    }
  }

  .error-form {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .inputfield-row {
    label {
      color: $white;
    }

    input {
      background-color: $rit-black;
      border-color: $rit-dark;
      color: $gray-disabled;

      &::placeholder {
        color: $gray-disabled;
      }

      &.error {
        border-color: $red-warning;
      }
    }
  }

  .form-row-button:not(.full-width-row) {
    @include tablet {
      margin-top: 42px;
    }
  }

  .btn {
    background-color: $white;
    border: 1px solid $white;
    color: $rit-black;

    &:hover,
    &:active {
      background-color: transparent;
      color: $white;
    }

    @include mobile-only {
      width: 100%;
      max-width: none;
    }

    .spinner span {
      background-color: $rit-dark;
    }
  }

  .wimo-login {
    color: $rit-copper-lighter;
  }

  .special-order-warning {
    text-align: left;
  }
}

.faq-contact-block {
  width: 100%;
  text-align: center;
  padding: 45px 0 50px;
  background-color: $rit-sandy-dark;
  color: $rit-black;

  @include tablet {
    padding: 84px 0 80px;
  }

  .flyin-component & {
    background-color: $white;
    text-align: left;

    @include mobile-only {
      padding: 48px 0 0 0;
    }

    @include tablet {
      padding: 64px 0 0 0;
    }
  }

  & + .btn {
    .flyin-component & {
      @include mobile-only {
        margin-top: 16px;
      }

      @include tablet {
        margin-top: 24px;
      }
    }
  }

  .inner-wrapper {
    .flyin-component & {
      margin: 0;
      max-width: none;
      padding: 0;
    }
  }

  .title {
    font-size: 24px;
    letter-spacing: 0.5px;
    text-transform: none;
    margin-bottom: 5px;

    @include tablet {
      font-size: 40px;
      letter-spacing: 0.8px;
      margin-bottom: 22px;
    }

    .flyin-component & {
      @include tablet {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 32px;
      }
    }
  }

  .description {
    font-size: 16px;
    font-family: $font-header;
    max-width: 345px;
    margin: 0 auto 15px;
    letter-spacing: 0.4px;
    line-height: 24px;

    @include tablet {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 18px;
    }

    .flyin-component & {
      font-family: $font-body;
      margin: 0;
      max-width: none;

      @include tablet {
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
      }
    }

    .time {
      font-family: $font-body-bold;
      font-weight: bold;
    }
  }

  .divider {
    max-width: 329px;
    width: 100%;
    height: 1px;
    background-color: $gray-disabled;
    display: block;
    margin: 0 auto 16px;

    @include tablet {
      margin-bottom: 23px;
    }
  }

  .opening-times {
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
    padding: 0 20px;

    @include tablet {
      padding: 0;
    }
  }

  .card-wrapper {
    width: 100%;
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 26px;
    }

    @include desktop {
      flex-wrap: nowrap;
    }

    .flyin-component & {
      @include desktop {
        flex-wrap: wrap;
      }
    }
  }

  .card-item {
    width: 100%;
    background-color: $white;
    position: relative;
    padding: 22px 20px 23px;
    margin-bottom: 24px;

    @include tablet {
      width: 45%;
      margin: 0 16px 24px;
      padding: 30px 20px 25px;
    }

    @include desktop {
      width: 25%;
      margin: 0 16px;
    }

    .flyin-component & {
      border: 1px solid $gray-disabled;
      margin: 0;

      @include desktop {
        width: 100%;
      }
    }

    & + .card-item {
      .flyin-component & {
        margin-top: 16px;

        @include desktop {
          margin-top: 24px;
        }
      }
    }

    &:hover {
      .flyin-component & {
        background-color: $rit-sandy-bg;
        border-color: $copper;
      }
    }

    &.email {
      .flyin-component & {
        display: none;
      }
    }

    .link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .icon {
      font-size: 50px;
      display: block;
      margin-bottom: 10px;

      .flyin-component & {
        position: absolute;
        right: 24px;
      }

      &.icon-green {
        color: #25d366;
      }

      &.icon-blue {
        color: #1677ff;
      }

      &.icon-brown {
        color: $rit-copper;
      }
    }

    .card-title {
      font-size: 24px;
      letter-spacing: 0.6px;
      line-height: 35px;
      margin-bottom: 17px;
      text-transform: none;

      @include tablet {
        font-size: 28px;
      }
    }

    .card-instruction {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      margin-bottom: 12px;

      .flyin-component & {
        color: $copper;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        text-decoration: underline;
        text-transform: none;
      }
    }

    .card-description {
      display: block;
      font-style: italic;
      font-size: 14px;
      letter-spacing: 0.4px;
      margin-bottom: 5px;

      .flyin-component & {
        font-style: normal;
      }
    }

    .response-time {
      font-size: 14px;
      letter-spacing: 0.4px;

      span {
        display: inline-block;
      }

      .response-time-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 13px;
      }

      &.available {
        .response-time-circle {
          background-color: $green;
        }
      }

      &.busy {
        .response-time-circle {
          background-color: #ffb184;
        }
      }

      &.unavailable {
        .response-time-circle {
          background-color: $red-warning;
        }
      }
    }
  }
}

.faq-question-block {
  background-color: $rit-black;
  padding: 41px 24px 33px;

  @include tablet {
    padding: 40px;
  }

  .title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
    color: $white;
    text-transform: none;
    text-align: center;
    margin-bottom: 19px;

    @include tablet {
      font-size: 28px;
      line-height: 40px;
      letter-spacing: 0.6px;
      text-align: left;
      margin-bottom: 17px;
    }

    span {
      font-style: italic;
    }
  }
}

.faq-question-blocks {
  .flyin-component & {
    margin-top: 40px;
  }

  .title {
    .flyin-component & {
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 32px;
      margin-bottom: 24px;
      text-transform: none;
    }
  }
}

.faq-questions-wrapper {
  background-color: $rit-black;
  list-style: none;
  counter-reset: item;
  padding: 0;

  li {
    margin-bottom: 4px;
    counter-increment: item;

    @include tablet {
      margin-bottom: 8px;
    }

    &::before {
      content: counter(item) '.';
      color: $rit-copper-lighter;
      width: 24px;
      display: inline-block;
      vertical-align: top;
    }
  }

  a {
    text-decoration: none;
  }

  span {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;

    @include tablet {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .text {
    color: $white;
    width: calc(100% - 40px);
    display: inline-block;
  }
}

.faq-question-tiles {
  margin: 41px 0 30px;

  @include tablet {
    margin: 80px 0 48px;
  }

  .title {
    text-align: center;
    font-size: 20px;
    line-height: 20.78px;
    letter-spacing: 1px;
    text-transform: none;
    color: $rit-black;
    max-width: 300px;
    margin: 0 auto 23px;

    @include tablet {
      font-size: 28px;
      line-height: 40px;
      letter-spacing: 0.6px;
      margin-bottom: 40px;
      max-width: none;
    }
  }

  .tiles-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .tile {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    margin: 0 0 16px;
    padding: 25px 40px 20px;

    @include tablet {
      width: 45%;
      margin: 0 2.5% 32px;
      padding: 30px 40px 37px;
    }

    @include desktop {
      width: 30%;
      margin: 0 1.6% 32px;
    }
  }

  .icon {
    display: block;
    text-align: center;
    color: $rit-copper-dark;
    font-size: 25px;
    margin-bottom: 10px;

    @include tablet {
      font-size: 30px;
      margin-bottom: 14px;
    }
  }

  .tile-title {
    text-align: center;
    text-transform: none;
    margin-bottom: 17px;

    a {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.5px;
      text-decoration: none;
      color: $rit-black;
      font-family: $font-header;

      @include tablet {
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 0.6px;
        margin-bottom: 24px;
      }
    }
  }

  .question {
    display: block;
    margin-bottom: 8px;
    text-decoration: none;
    color: $rit-black;
    line-height: 24px;
    align-self: flex-start;
    max-width: 100%;
    font-size: 14px;
    letter-spacing: 0.4px;

    @include tablet {
      font-size: 16px;
      margin-bottom: 17px;
    }
  }

  .link {
    display: flex;
    align-items: flex-end;
    flex: 1;
    margin-top: 6px;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      margin-top: 24px;
    }

    @include tablet {
      font-size: 16px;
    }
  }
}

.faq-theme-wrapper {
  .layout {
    padding: 30px 0 40px;
    display: flex;
    flex-direction: column-reverse;

    @include desktop-only {
      flex-direction: row;
      padding: 50px 20px 67px;
    }
  }

  .layout-left {
    max-width: 350px;

    @include mobile-and-tablet {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: none;
    }
  }

  .layout-right {
    width: 100%;

    @include desktop-only {
      width: calc(100% - 350px);
      padding-left: 112px;
    }
  }
}

.btn-download-page {
  background-color: transparent;
  border-color: rgba($rit-black, 0.16);
  color: $black;
  margin: 30px auto;
  max-width: 350px;
  width: 100%;

  @include mobile-and-tablet {
    max-width: fit-content;
  }

  &.mobile {
    @include desktop {
      display: none;
    }
  }

  &.desktop {
    @include mobile-and-tablet {
      display: none;
    }
  }
}

.faq-theme-back-button {
  display: block;
  margin: 6px 0 13px -5px;

  @include desktop-only {
    display: none;
  }
}

.faq-theme-mobile-dropdown-wrap {
  position: relative;
  width: 100%;

  .faq-theme-mobile-dropdown-placeholder {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.in-place {
      left: auto;
      position: relative;
      top: auto;
    }
  }
}

.faq-theme-mobile-dropdown {
  display: none;
  background-color: $rit-black-lighter;
  padding: 24px 18px;
  width: 100%;
  position: relative;
  z-index: $modal-overlay-index + 1;

  &.sticky {
    left: 0;
    position: fixed;
    top: 0;
  }

  @include mobile-and-tablet {
    display: block;
  }

  select {
    border-radius: 0;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0;
  }

  .mobile-title {
    color: $white;
    display: block;
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 21px;
    margin: 6px 22px 30px;
    text-align: center;

    @include tablet {
      display: none;
    }
  }
}

.faq-nav {
  background-color: $rit-black;
  padding: 28px 40px 44px;
  text-align: left;
  width: 100%;
  display: none;

  @include desktop-only {
    display: block;
  }
}

.breadcrumb.faq-theme-breadcrumb {
  padding: 0;
  margin-bottom: 33px;

  .breadcrumb-item {
    display: inline-block;
    text-decoration: none;
  }

  .breadcrumb-element {
    color: $rit-copper-light;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 15px;
    text-transform: none;

    &::after {
      content: '/';
      padding: 0 4px;
    }
  }
}

.nav-links {
  list-style: none;
  width: 100%;

  .nav-link {
    margin: 0 0 22px;

    &:last-child {
      margin: 0;
    }

    button {
      color: $white;
      font-family: $font-header;
      font-size: 24px;
      letter-spacing: 0.9px;
      line-height: 28px;
      padding: 0 0 1px;
      position: relative;
      text-decoration: none;
      text-transform: none;
      text-align: left;

      &::after {
        background-color: $white;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: -1px;
        position: absolute;
        transition: width $tertiary-duration $default-ease-out;
        width: 0;
      }

      &:hover,
      &.active {
        &::after {
          width: 100%;
        }
      }
    }
  }
}

.faq-block.faq-theme-block {
  padding: 0;

  .inner-wrapper {
    padding: 0;
  }

  .faq-theme-title {
    color: $rit-black;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-transform: none;
    margin-bottom: 23px;
    text-align: left;

    @include desktop-only {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.8px;
      margin-bottom: 40px;
    }
  }

  .faq-theme-subtitle p {
    margin: -10px 0 30px;
    text-align: left;
  }

  .faq-accordion-item,
  .faq-accordion {
    border-color: $gray-disabled;
  }

  .faq-accordion-head {
    padding: 24px 110px 23px 0;

    .acc-head-title {
      @include desktop-only {
        font-size: 20px;
        letter-spacing: 0.4px;
      }
    }
  }

  .faq-accordion-content {
    padding: 0 42px 20px 0;

    p {
      @include desktop-only {
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 32px;
      }
    }

    .btn {
      margin: 14px 11px 4px 0;
    }
  }

  em {
    font-style: italic;
  }
}

.faq-diy-block {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @include desktop-only {
    margin-top: 50px;
  }

  .faq-diy-title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-transform: none;
    margin-bottom: 24px;

    @include desktop-only {
      font-size: 28px;
      line-height: 40px;
      letter-spacing: 0.6px;
    }
  }

  .btn:first-of-type {
    margin-bottom: 16px;
  }
}

.navigation-option {
  @include desktop-only {
    display: none;
  }
}

.navigation-list-item {
  display: none;

  @include desktop-only {
    display: block;
  }
}
