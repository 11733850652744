.world {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: $white;

  .gradient-background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.sakura {
      background: linear-gradient(0deg, #f5c7c2, #f7d0ce 50%, #fae3de 80%);
      opacity: 0;
    }

    &.mehr {
      background: linear-gradient(0deg, #fae2ba, #fae5c5 50%, #fefada 80%);
      opacity: 0;
    }

    &.karma {
      background: linear-gradient(0deg, #d4e5df, #c6e8ed 50%, #e9fcfc 80%);
      opacity: 0;
    }
  }

  #app { // sass-lint:disable-line no-ids
    position: relative;
    transition: background-color 3s ease;
    z-index: 1;
  }

  canvas {
    position: relative;
  }

  .preloader {
    background-color: $ritual-easter-grey;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: opacity 2s ease;
    width: 100%;
    z-index: 13;

    @include mobile-only {
      height: calc(100% + 80px);
    }
  }

  .new {
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .egg-num {
    height: 208px;
    margin: 0 auto 40px;
    position: relative;
    width: 167px;
  }

  .counter {
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: -50px;
    transform: translateX(-50%);
    transition: opacity 1s ease;
    z-index: 21;

    span {
      color: $white;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: 1px;

      &::after {
        content: '%';
      }
    }
  }

  .egg-loader-mask {
    background-color: $ritual-easter-grey;
    height: 220px;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.5s ease-in-out;
    width: 200px;
    z-index: 20;
  }

  .egg-loader {
    background-color: $ritual-easter-grey;
    border-radius: 50% 50% 30% 30% / 70% 70% 28% 28%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    position: relative;
    transform: scale(1);
    transition: transform 2s ease, opacity 2s ease;
    width: 100%;
    z-index: 12;

    span {
      background-repeat: no-repeat;
      bottom: -30%;
      display: block;
      height: 80px;
      opacity: 1;
      position: absolute;
      transition: bottom 1s $navigation-ease;
      width: 400px;

      &::after {
        content: '';
        height: 210px;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
      }
    }

    span:nth-child(1) {
      &::after {
        background-color: #81c2d9;
      }
    }

    span:nth-child(2) {
      &::after {
        background-color: #f8a2a1;
      }
    }

    span:nth-child(3) {
      &::after {
        background-color: #ffd252;
      }
    }
  }

  .text-content {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    @include mobile-and-tablet {
      pointer-events: none;
    }
  }

  .overlay-to-pdp {
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 12;

    &.sakura {
      background-color: $sakura;
    }

    &.mehr {
      background-color: $mehr;
    }

    &.karma {
      background-color: $karma;
    }
  }

  .text-box {
    font-family: $font-header;
    opacity: 1;
    text-align: center;
    transition: opacity 0.5s ease;
  }

  .egg-header {
    font-size: 54px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 30px;
    opacity: 0;
    text-transform: initial;

    @include mobile-only {
      font-size: 30px;
      margin-bottom: 20px;
    }

    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(6px);
      transition: transform 0.5s ease, opacity 0.5s ease;

      &.fade {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .egg-text {
    font-family: $font-header;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 3px;
    margin-bottom: 20px;
    opacity: 0;
    text-transform: uppercase;

    @include mobile-only {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(6px);
      transition: transform 0.5s ease, opacity 0.5s ease;

      &.fade {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .intro-box {
    text-align: center;
  }

  .egg-intro {
    color: $copper;
    font-family: $font-header;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 2.42px;
    margin-bottom: 20px;
    opacity: 0;
    text-transform: uppercase;
    transform: translateY(10px);
    transition: all 1s ease;

    @include mobile-only {
      font-size: 14px;
    }

    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(6px);
      transition: transform 0.5s ease, opacity 0.5s ease;

      &.fade {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .egg-title {
    color: #ffffff;
    font-family: $font-header;
    font-size: 58px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 20px;
    opacity: 0;
    text-transform: initial;
    transform: translateY(10px);
    transition: all 1s ease;

    @include mobile-only {
      font-size: 30px;
    }

    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(6px);
      transition: transform 0.5s ease, opacity 0.5s ease;

      &.fade {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .swipe-container {
    height: 85%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    @include mobile-and-tablet {
      display: none;
    }
  }

  .container {
    bottom: 0;
    height: 200px;
    left: 50%;
    perspective: 500px;
    position: absolute;
    transform: translateX(-50%);
    min-width: 1000px;

    @include mobile-only {
      perspective: 300px;
      min-width: 300px;
      height: 170px;
    }
  }

  .egg-button {
    background-color: #ffffff;
    border: 0;
    color: $black;
    cursor: pointer;
    font-family: $font-body;
    font-size: 14.4px;
    letter-spacing: 2.4px;
    line-height: 19.2px;
    min-width: 262px;
    opacity: 0;
    padding: 18px 28px;
    text-transform: uppercase;
  }

  .open-button {
    position: absolute;
    left: 50%;
    top: 40%;
    background-color: transparent;
    cursor: pointer;
    height: 650px;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 11;

    @include mobile-and-tablet {
      display: none;
    }
  }

  .move-btn {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 100%;
    outline: 0;
    position: absolute;
    top: 0;
    width: 15%;
    z-index: 11;
  }

  .btn-prev {
    left: 0;

    @include desktop {
      &:hover {
        .circle {
          transform: scale(1.2) translateY(-50%);
        }
      }
    }
  }

  .btn-next {
    right: 0;

    @include desktop {
      &:hover {
        .circle {
          transform: scale(1.2) translateY(-50%);
        }
      }
    }
  }

  .circle {
    background-color: $white;
    border-radius: 50%;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.5s ease;
    width: 50px;

    &::after {
      content: '';
      border: solid $black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 5px;
      position: relative;
      top: 19px;
    }
  }

  .circle-prev {
    left: 30px;

    &::after {
      right: -1px;
      transform: rotate(135deg);
    }

    @include mobile-only {
      left: 20px;
    }
  }

  .circle-next {
    right: 30px;

    &::after {
      right: 1px;
      transform: rotate(-45deg);
    }

    @include mobile-only {
      right: 20px;
    }
  }
}


model-viewer {
  height: 100%;
  width: 100%;
}
