.collection-highlight-block {
  padding: 90px 0 60px;
  text-align: center;

  @include tablet {
    align-items: stretch;
    display: flex;
    padding: 0;
  }

  &.txt--black {
    .column-inner *:not(.btn) {
      color: $rit-black;
    }
  }

  &.txt--white {
    .column-inner *:not(.btn) {
      color: $white;
    }
  }

  &.txt--red {
    .column-inner *:not(.btn) {
      color: $crimson;
    }
  }
}

.collection-highlight-column {
  width: 100%;

  .image-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 76.27%;
    width: 100%;
  }

  &.image-column {
      display: none;
  }

  &.text-column {
    padding: 0 20px;
  }

  h2 {
    color: $black;
    font-size: 30px;
    letter-spacing: 4px;
    line-height: 40px;
    text-transform: none;
    margin-bottom: 22px;
  }

  .read-time {
    color: $black;
    display: inline-block;
    font-family: $font-header;
    font-size: 13px;
    letter-spacing: 4px;
    line-height: 1.2;
    margin-bottom: 34px;
    padding-left: 28px;
    position: relative;
    text-transform: uppercase;

    &::before {
      @include icon-styles;
      content: icon-char(ico-clock);
      display: inline-block;
      left: 0;
      line-height: 1;
      position: absolute;
      top: 2px;
    }
  }

  .mobile-image-placeholder {
    margin: 0 0 42px -20px;
    width: calc(100% + 40px);
  }

  p {
    line-height: 28px;
    margin-bottom: 35px;
  }

  .product-options .swatches-wrapper,
  .product-variations .swatches-wrapper {
    justify-content: center;
  }

  @include tablet {
    min-height: 80vh;
    width: 50%;

    .mobile-image-placeholder {
      display: none;
    }

    &.image-column {
      display: block;
      position: relative;

      .image-holder {
        bottom: 0;
        height: auto;
        left: 0;
        padding-bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &.text-column {
      align-items: center;
      display: flex;
      max-width: 720px;
      padding: 0;

      &.invert {
        margin-left: auto;
        order: -1;
      }

      .column-inner {
        margin: 0 auto;
        max-width: 490px;
        padding: 0 20px;
        width: 100%;
      }
    }

    h2 {
      margin-bottom: 14px;
      font-size: 40px;
      letter-spacing: 1.6px;
      line-height: 50px;
    }

    .read-time {
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 26px;
      padding-left: 19px;
      text-transform: none;

      &::before {
        font-size: 14px;
        top: 2px;
      }
    }

    p {
      margin-bottom: 30px;
    }
  }

  .product-tile {
    .product-details-content {
      margin: 0 auto;
      padding: 20px;
    }

    .the-ritual-of-title {
      display: none;
    }

    .product-name-link {
      display: block;
      pointer-events: none;
      text-decoration: none;
    }

    .product-name {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 2.63px;
      pointer-events: none;
    }

    .short-description {
      margin: -5px 0 10px;
    }
  }

  @include tablet {
    .product-tile .short-description {
      margin: -13px 0 15px;
    }
  }
}

.collection-narrow-highlight-block {
  width: 100%;

  .media-column {
    position: relative;
    width: 100%;
  }

  .image-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .text-column {
    background-color: $white;
    padding: 40px 30px;
    text-align: center;
    width: 100%;
  }

  h3 {
    color: $rit-copper-dark;
    font-size: 11px;
    letter-spacing: 1.5px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 44px;
    margin-bottom: 22px;
    text-transform: none;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .buttons-wrap {
    a {
      display: inline-block;
      font-size: 0;
      line-height: normal;
      vertical-align: top;
    }
  }

  @include mobile-only {
    margin-bottom: 30px;

    &.hide-mobile-image {
      .media-column {
        display: none;
      }
    }

    .inner-wrapper {
      padding: 0;
    }

    .media-column {
      height: 0;
      padding-bottom: 80%;
    }
  }

  @include tablet {
    margin: 30px 0 60px;

    .inner-wrapper {
      display: flex;
      max-width: 1360px;
    }

    .media-column {
      width: 50%;
    }

    .text-column {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 560px;
      padding: 30px;
      width: 50%;
    }

    .column-inner {
      max-width: 380px;
      width: 100%;
    }

    h3 {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 12px;
    }

    .action-boxes {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      padding-top: 9px;
    }

    .col {
      border-right: 1px solid $gray-light;
      padding: 10px 10px 5px;
      width: 50%;

      &:nth-child(2n) {
        border-right: 0;
      }
    }

    .img-wrap {
      margin: 0 auto;
      max-width: 100px;

      img {
        display: block;
        max-width: 100%;
      }
    }

    .buttons-wrap {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      a {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .image-label {
      display: block;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 1px;
      line-height: 18px;
      margin-top: 10px;
    }
  }

  @include desktop {
    .text-column {
      padding: 50px;
    }

    .col {
      padding: 10px 30px 5px;
    }
  }
}
