.ui-autocomplete.ui-menu {
  background-color: $white;
  border: 1px solid $gray-gainsboro;
  box-shadow: 0 2px 4px rgba($black, .15);

  .ui-menu-item {
    color: $rit-black;
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    list-style: none;
    padding: 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-lines;
    }
  }

  .ui-menu-item-wrapper {
    padding: 12px 17px 8px;

    &.ui-state-active {
      margin: 0;
    }

    &:hover,
    &.ui-state-focus {
      background-color: $rit-sandy-bg;
    }
  }
}
