// Large Price - PDP
.product-price {
  @include price-text('l');
}

// Small Price - Sticky add to cart
.small-price {
  .product-price {
    @include price-text('s');
  }
}

// Base price
.unit-base-price {
  @include text-size('s');
  color: $gray-medium;

  &.legal-note {
    margin-top: 13px;
    text-transform: none;
  }
}
