// Vue Form fields
.vue-form {
  & + & {
    margin-top: 40px;
  }

  label {
    color: $rit-black;
    font-family: $font-body;

    &:not(.vue-form-group-label) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .vue-form-group-lebel {
      cursor: pointer;
    }

    .field-label {
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;

      &.has-focus {
        color: $gray-dark;
      }

      &.disabled {
        color: $gray-disabled;
      }
    }

    .field-info {
      color: $gray-dark;
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 16px;
    }

    .field-help {
      margin-left: 8px;
      position: relative;

      &:hover {
        span {
          opacity: 1;
          transition-delay: 0s;
        }
      }

      &::before {
        background-color: $black;
        border-radius: 50%;
        color: $white;
        content: 'i';
        font-size: 11px;
        height: 12px;
        left: 0;
        line-height: 15px;
        position: absolute;
        text-align: center;
        top: -1px;
        width: 12px;
      }

      span {
        background-color: $rit-black;
        bottom: 34px;
        color: $white;
        left: -95px;
        opacity: 0;
        padding: 10px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        transition: opacity 0.6s $default-ease-out 1.5s;
        width: 210px;
        z-index: 11;

        &::before {
          background-color: $rit-black;
          bottom: -17px;
          content: '';
          height: 20px;
          left: 98px;
          position: absolute;
          transform-origin: center;
          transform: rotate(45deg) translateX(-50%);
          width: 20px;
          z-index: 10;
        }
      }
    }

    & ~ .vue-input,
    & ~ .vue-input-select {
      margin-top: 8px;
    }
  }

  .vue-input {
    color: $rit-black;
    font-family: $font-body;

    &[type='text'],
    &[type='tel'],
    &[type='email'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 0;
      background-color: $white;
      border: 1px solid $gray-disabled;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 24px;
      padding: 11px 40px 11px 16px;
      width: 100%;
    }

    &[disabled],
    &[readonly] {
      background-color: $rit-sandy-dark;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .vue-input-select {
    position: relative;

    button {
      align-items: flex-end;
      background-color: $white;
      border: 1px solid $gray-disabled;
      display: flex;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 24px;
      padding: 12px 40px 12px 16px;
      width: 100%;

      span.icon {
        margin-right: 5px;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        right: 18px;
        transform: rotateZ(45deg);
        transition: top $quaternary-duration $default-ease-out;
        width: 6px;
      }

      &::before {
        border-left: 1px solid $rit-dark;
        border-top: 1px solid $rit-dark;
        top: 19px;
      }

      &::after {
        border-bottom: 1px solid $rit-dark;
        border-right: 1px solid $rit-dark;
        top: 25px;
      }

      &.open {
        &::before {
          top: 26px;
        }

        &::after {
          top: 17px;
        }
      }
    }

    ul {
      background-color: $white;
      border-bottom: 1px solid $gray-disabled;
      border-left: 1px solid $gray-disabled;
      border-right: 1px solid $gray-disabled;
      left: 0;
      list-style: none;
      margin: 0;
      padding: 0 16px;
      position: absolute;
      top: 49px;
      width: 100%;
      z-index: 1;

      li {
        align-items: center;
        background-color: $white;
        border-top: 1px solid $gray-disabled;
        cursor: pointer;
        display: flex;
        padding: 12px 16px;

        .icon {
          margin-right: 18px;
        }
      }
    }
  }

  .vue-input-toggle {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked {
      & + label span.toggle {
        background-color: $copper;

        &::after {
          transform: translate3d(26px, 4px, 0);
        }
      }
    }

    & + label {
      display: block;
      font-size: 14px;
      height: 32px;
      letter-spacing: 0.4px;
      line-height: 24px;
      padding: 3px 0 0 80px;
      position: relative;

      span.toggle {
        background-color: $gray-disabled;
        border-radius: 28px;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: -3px;
        transition: background-color 0.3s linear;
        vertical-align: text-bottom;
        width: 56px;

        &::after {
          background-color: $white;
          border-radius: 12px;
          content: '';
          height: 24px;
          left: 0;
          position: absolute;
          transform: translate3d(5px, 4px, 0);
          transition: transform 0.2s ease-in-out;
          width: 24px;
        }
      }
    }
  }

  .vue-input-radio,
  .vue-input-checkbox {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked {
      & + label::before {
        background-color: $copper;
        border-color: $copper;
        content: $icon-check;
      }
    }

    & + label {
      display: block;
      font-size: 14px;
      height: 32px;
      letter-spacing: 0.4px;
      line-height: 24px;
      padding: 4px 0 0 36px;
      position: relative;

      &::before {
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $gray-gainsboro;
        color: $white;
        content: '';
        font-family: 'iconfont';
        font-size: 8px;
        height: 24px;
        left: 0;
        letter-spacing: -1px;
        line-height: 26px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 24px;
      }
    }
  }

  .vue-input-error {
    color: $red-warning;
    display: block;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 16px;
    margin-top: 8px;
    text-align: right;
  }

  .vue-input-warning {
    color: $orange-warning;
    display: block;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 16px;
    margin-top: 8px;
    text-align: right;
  }

  .reveal-hidden {
    color: $rit-copper-dark;
    font-family: $font-body;
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin: 10px 0 0;
    padding-top: 5px;
    position: relative;
    text-align: left;
  }

  .vue-button {
    &.reveal-hidden {
      padding-left: 20px;

      &::before {
        background-size: 100% 100%;
        background: transparent
          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiM5QTdCNjk7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik04LDEuM2MzLjcsMCw2LjcsMyw2LjcsNi43cy0zLDYuNy02LjcsNi43cy02LjctMy02LjctNi43UzQuMywxLjMsOCwxLjN6IE04LDIuMUM0LjgsMi4xLDIuMSw0LjgsMi4xLDgNCglzMi42LDUuOSw1LjksNS45czUuOS0yLjYsNS45LTUuOVMxMS4yLDIuMSw4LDIuMXogTTguNCw1LjRsMCwyLjJsMi4yLDB2MC43bC0yLjIsMGwwLDIuMkg3LjZsMC0yLjJsLTIuMiwwVjcuNmwyLjIsMGwwLTIuMkg4LjR6Ii8+DQo8L3N2Zz4NCg==')
          no-repeat;
        content: '';
        height: 16px;
        left: 0;
        position: absolute;
        top: 4px;
        width: 16px;
      }
    }
  }
}

// Vue Form Row
.vue-form-row {
  & + & {
    margin-top: 16px;
  }

  &.has-multiple-elements {
    display: flex;
    justify-content: space-between;

    @include mobile-and-tablet {
      flex-direction: column;
    }

    .vue-form-element.no-label {
      @include tablet {
        padding-top: 32px;
      }
    }
  }

  .vue-form-element:nth-last-child(n + 2),
  .vue-form-element:nth-last-child(n + 2) ~ .vue-form-element {
    width: calc(50% - 20px);

    @include mobile-and-tablet {
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .vue-form-element:nth-last-child(n + 3),
  .vue-form-element:nth-last-child(n + 3) ~ .vue-form-element {
    width: calc(33% - 20px);

    @include mobile-and-tablet {
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}

// Vue Form Elements
.vue-form-element {
  position: relative;

  &.has-error {
    .vue-input {
      border-color: $red-warning;
    }
  }

  &.has-warning {
    .vue-input {
      border-color: $orange-warning;
    }
  }


  &.half-width {
    .vue-form-row:not(.has-multiple-elements) & {
      @include tablet {
        max-width: 50%;
        width: calc(50% - 20px);
      }
    }
  }

  &.hide-field {
    display: none;
  }

  &.at-end,
  &.icon-read-only {
    margin-left: auto;
  }

  .icon {
    line-height: 24px;
  }

  .icon-before,
  .icon-after,
  .icon-read-only {
    position: absolute;
    width: 100%;
    z-index: 1;

    &::before {
      position: absolute;
    }
  }

  &:not(.no-label) {
    .icon-before,
    .icon-after,
    .icon-read-only {
      transform: translateY(20px);
    }
  }

  &.no-label {
    .icon-before,
    .icon-after,
    .icon-read-only {
      transform: translateY(12px);
    }
  }

  .icon-before {
    &::before {
      left: 16px;
    }

    & + .vue-input,
    & + .vue-input-select button {
      padding-left: 48px;
    }
  }

  .icon-after,
  .icon-read-only {
    &::before {
      right: 16px;
    }

    & + .vue-input {
      padding-right: 48px;
    }
  }

  .icon-read-only {
    &::before {
      @include icon-styles;
      color: $rit-dark;
      content: icon-char(system-lock);
      display: inline-block;
    }

    & + .vue-input {
      padding-right: 48px;
    }
  }
}
