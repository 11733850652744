// Easter 3D PDP overrides
.easter-pdp {
  .productthumbnail {
    @include mobile-only {
      margin: auto;
    }
  }

  .pdp-breadcrumbs-wrapper {
    .breadcrumb {
      @include tablet {
        display: none;
        & + .pdp-back-button-wrapper {
          display: block;
        }
      }
    }
  }

  .pdp-images-holder {
    @include mobile-only {
      margin-bottom: 30px;
      min-height: 300px !important;
    }
  }

  &.product-details-container {
    min-height: 800px;
  }

  .price-sales {
    font-size: 40px;
    letter-spacing: 0.6px;
  }

  &.easter-bg-yellow {
    background: linear-gradient(to right, $ritual-easter-yellow 61%, transparent 61%);

    @include desktop-ultra {
      background: linear-gradient(to right, $ritual-easter-yellow 57%, transparent 57%);
    }

    @include mobile-only {
      background: transparent;

      .images-slider {
        background-color: $ritual-easter-yellow;
      }
    }
  }

  &.easter-bg-pink {
    background: linear-gradient(to right, $ritual-easter-pink 61%, transparent 61%);

    @include desktop-ultra {
      background: linear-gradient(to right, $ritual-easter-pink 57%, transparent 57%);
    }

    @include mobile-only {
      background: transparent;

      .images-slider {
        background-color: $ritual-easter-pink;
      }
    }
  }

  &.easter-bg-blue {
    background: linear-gradient(to right, $ritual-easter-blue 61%, transparent 61%);

    @include desktop-ultra {
      background: linear-gradient(to right, $ritual-easter-blue 57%, transparent 57%);
    }

    @include mobile-only {
      background: transparent;

      .images-slider {
        background-color: $ritual-easter-blue;
      }
    }
  }

  &.easter-bg-grey {
    background: linear-gradient(to right, $ritual-easter-grey-sport 61%, transparent 61%);

    @include desktop-ultra {
      background: linear-gradient(to right, $ritual-easter-grey-sport 57%, transparent 57%);
    }

    @include mobile-only {
      background: transparent;

      .images-slider {
        background-color: $ritual-easter-grey-sport;
      }
    }
  }

  &.easter-bg-solid-yellow {
    background-color: $ritual-easter-yellow;

    .egg-model-viewer-size-s {
      .btn:last-of-type {
        background-color: $white;
      }
    }
  }

  &.easter-bg-solid-pink {
    background-color: $ritual-easter-pink;

    .egg-model-viewer-size-s {
      .btn:last-of-type {
        background-color: $white;
      }
    }
  }

  &.easter-bg-solid-blue {
    background-color: $ritual-easter-blue;

    .egg-model-viewer-size-s {
      .btn:last-of-type {
        background-color: $white;
      }
    }
  }

  &.easter-bg-solid-grey {
    background-color: $ritual-easter-grey-sport;

    .egg-model-viewer-size-s {
      .btn:last-of-type {
        background-color: $white;
      }
    }
  }

  @include mobile-only {
    padding: 0;
  }

  .pdp-back-button-wrapper {
    .back-button {
      color: $black;

      @include mobile-only {
        color: $black;
      }
    }
  }

  .pdp-inner-wrapper {
    align-items: center;

    @include mobile-only {
      display: block;
    }

    .product-details-column {
      .icon-AR {
        color: $rit-copper-dark;
        font-size: 30px;
        height: 30px;
        left: 50%;
        line-height: 30px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;

        &::before {
          vertical-align: sub;
        }
      }

      .easter-view-block {
        position: absolute;
        top: 0;
        right: 60px;
        z-index: 10;

        @include mobile-only {
          right: 0;
        }
      }

      .easter-view-block-ar {
        position: absolute;
        top: 0;
        right: 60px;
        z-index: 10;

        @include mobile-only {
          right: 0;
        }
      }

      .egg-model-viewer {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto;
        --poster-color: transparent;
      }

      .easter-icon-block {
        position: relative;
        margin: 0 auto;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: $white;
        z-index: 2;
      }

      .easter-icon-text {
        font-size: 12px;
        color: $rit-black;
      }

      .easter-view-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
      }
    }

    .product-details-column.column-images {
      position: relative;
      width: 64%;

      @include mobile-only {
        width: 100%;
      }
    }

    .product-details-column.column-details {
      margin: auto;
      padding: 0 50px;
      width: 36%;

      @include mobile-only {
        width: 100%;
        padding: 0 20px;
      }

      @include tablet-only {
        padding: 0;
      }

      .short-description {
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .pdp-swatches-list {
      min-width: 200px;
    }

    .images-slider {
      &.slick-initialized {
        @include tablet {
          max-width: 100%;
        }
      }

      @include mobile-only {
        padding: 100px 0 45px;
      }

      img {
        body:not(.personalised-gifting-page) & {
          @include tablet-landscape {
            max-height: 588px;
            max-width: 100%;
          }

          @include tablet-portrait {
            max-height: 588px;
            max-width: 100%;
          }

          @include desktop-only {
            max-height: 588px;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.easter-link {
  color: $rit-copper-lighter;
}
