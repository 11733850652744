.three-tile-block {
  background-color: $rit-sandy-bg;
  display: flex;
  padding: 30px 0;

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &.invert {
    .image-holder {
      order: 2;
    }
  }

  @include tablet {
    .inner-wrapper {
      flex-direction: row;
      max-width: 1360px;
      padding: 0 20px;
    }
  }
}

.three-tile-block-phone-slider {
  @include tablet {
    .simplebar-content {
      display: flex;
    }
  }

  @include mobile-only {
    .drag-list-body {
      padding: 0 20px;
    }

    .three-tile-box {
      width: 95%;
      margin-right: 10px;
    }
  }
}

.three-tile-box {
  background-color: $white;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;

  .image-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 80.3%;
    width: 100%;
  }

  a.image-holder {
    display: block;
  }

  .text-holder {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px 20px 40px;
    width: 100%;

    .tile-heading {
      font-size: 24px;
      letter-spacing: 0.46px;
      line-height: 34px;
      text-transform: none;
      margin-bottom: 14px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
    }

    .btn {
      margin-top: 9px;
      min-width: 0;
    }
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    width: 33.333%;
    margin-right: 20px;

    .image-holder {
      padding-bottom: 96.78%;
    }

    .text-holder {
      height: 100%;
      padding: 28px 20px 40px;

      .tile-heading {
        margin-bottom: 10px;
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include desktop {
    margin-right: 40px;

    .text-holder {
      .tile-heading {
        line-height: 44px;
      }

      .btn {
        margin-top: 3px;
        min-width: 220px;
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .text-holder * {
      max-width: 100%;
    }
  }
}

.three-tile-horizontal {
  height: auto;
  min-height: 520px;
  padding: 80px 0 30px;
  background-color: $white;

  @include mobile-only {
    padding: 30px 0;
  }

  .inner-wrapper {
    max-width: 1440px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 20px;

    @include mobile-only {
      flex-direction: column;
    }
  }
}

.three-tile-pretitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.8px;

  @include mobile-only {
    font-size: 10px;
    letter-spacing: 1.6px;
  }
}

.three-tile-title {
  font-size: 30px;
  font-family: $font-header;
  text-transform: initial;
  letter-spacing: 1.2px;
  color: $rit-black;

  @include mobile-only {
    font-size: 20px;
  }
}

.three-tile-discount {
  font-size: 24px;
  font-family: $font-header;
  font-weight: 600;
  color: $rit-black;

  @include mobile-only {
    font-size: 18px;
  }
}

.three-tile-price {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: $font-header;
  color: $grey-medium-light;
  text-decoration: line-through;

  @include mobile-only {
    font-size: 14px;
  }
}

.three-tile-title--big {
  @include mobile-only {
    font-size: 28px;
    line-height: 38px;
  }
}

.inner-wrapper-block {
  @include inner-wrapper;
  margin-bottom: 80px;

  @include mobile-only {
    margin-bottom: 50px;
  }
}

.inner-wrapper-block-content {
  text-align: center;

  p,
  span {
    max-width: 650px;
    margin: 0 auto;
  }

  .em {
    font-style: italic;
  }
}

.text-holder-column {
  display: none;
  flex: 0.9;
  text-align: center;

  .text-inner {
    max-width: 332px;
    margin: 0 auto 130px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease-in 0.4s;

    @include tablet {
      margin-bottom: 50px;
      min-height: 360px;
    }
  }

  .text-inner--anim {
    visibility: visible;
    opacity: 1;
  }

  @include tablet {
    display: block;
  }
}

.environment-icon {
  display: block;
  margin: 0 auto 10px;
}

.environment-water {
  @include environment-water (70px, 70px);
}

.environment-energy {
  @include environment-energy (70px, 70px);
}

.environment-waste {
  @include environment-waste (70px, 70px);
}

.environment-co2 {
  @include environment-co2 (70px, 70px);
}

.text-pretitle {
  font-family: $font-header;
  font-size: 12px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.text-holder-slider {
  width: 100%;
  margin-bottom: 50px;

  .text-block-slider {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  @include tablet {
    display: none;
  }

  @include mobile-only {
    .text-block-slider {
      margin-left: initial;
    }

    .product-name {
      letter-spacing: 0.6px;
    }

    .long-description {
      font-size: 16px;
    }
  }
}

.image-holder-column {
  flex-shrink: 0;
  flex: 1.1;

  .refill-jar-wrapper .refill-jar-lid,
  .refill-jar-wrapper .refill-jar-content,
  .refill-jar-wrapper .refill-jar-container {
    @include tablet {
      img {
        max-width: none;
        width: 396px;
      }
    }
  }

  .refill-jar-wrapper .refill-jar-lid {
    @include tablet {
      margin-bottom: -75px;
    }
  }
}
.gift-benefits-block {
  background-color: $rit-black-lighter !important;
}
