// Order Confirmation Defaults

.order-confirmation-wrapper {
  margin-bottom: 60px;
  margin-top: 47px;

  @include tablet {
    margin-top: 60px;
  }

  @include tablet-portrait {
    .inner-wrapper-small {
      flex-direction: column;
    }

    .page-layout-left {
      width: 100%;
      padding-right: 0;
    }

    .page-layout-right {
      margin: 40px auto 0;
    }
  }
}

.confirmation {
  z-index: 60;
}

.confirmation-message {
  background-color: $gray-sand;
  text-align: center;
  padding: 50px 20px 53px;

  @include tablet {
    padding: 120px 20px 115px;
  }

  h3 {
    font-size: 16px;
    color: $rit-dark;
    letter-spacing: 2.67px;
    line-height: 14px;
  }

  h1 {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
    color: $rit-black;
    margin-bottom: 45px;

    @include tablet {
      font-size: 54px;
      line-height: 58px;
    }
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    color: $rit-dark;
    max-width: 380px;
    display: block;
    margin: 0 auto;
  }
}

.delivery-info-top {
  margin-top: 25px;

  span {
    font-weight: 700;
  }
}

.order-confirmation {
  max-width: 616px;
  margin: 0 auto;

  // Checkout V3 overrides
  .checkout-v3 & {
    max-width: 856px;

    @include mobile-only {
      margin: 0 -20px;
    }
  }

  h1 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-transform: none;

    @include tablet {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 2px;
    }

    // Checkout V3 overrides
    text-align: center;

    .checkout-v3 & {
      @include mobile-only {
        font-size: 32px;
        letter-spacing: 1.2px;
        line-height: 40px;
      }

      @include tablet {
        font-size: 40px;
        letter-spacing: 1.8px;
        line-height: 48px;
      }
    }
  }

  h2 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 8px;
    text-transform: none;
  }

  // Map PUP styles
  .pup-map-wrapper {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 20px rgba($black, 0.1);

    @include tablet {
      flex-direction: row;
      margin-bottom: 30px;
    }
  }

  .pup-info {
    width: 100%;
    background: $white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include tablet {
      width: 50%;
    }

    .pup-address {
      padding: 20px 30px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border-bottom: 1px solid $white-smoke;
      text-align: left;
    }

    .pup-place {
      font-size: 12px;
      letter-spacing: 1.6px;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      margin-bottom: 3px;
    }

    .address {
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 20px;

      span {
        display: block;
      }
    }

    .opening-times {
      padding: 12px 0;

      @include tablet {
        padding: 21px 0;
      }
    }
  }

  .opening-times {
    .accordion-content {
      .acc-content-item {
        position: relative;
        margin-bottom: 0;
      }

      .acc-content-head {
        padding: 0 30px;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-transform: none;
        font-weight: bold;
        z-index: 3;

        &::before {
          right: 24px;
        }
      }

      .acc-content-body {
        position: absolute;
        top: 16px;
        left: 0;
        padding: 20px 20px 63px 30px;
        background: $white;
        z-index: 2;
        box-shadow: 0 5px 6px rgba($black, 0.1);

        @include tablet {
          padding-bottom: 20px;
        }

        ul {
          list-style: none;
        }

        li {
          width: 100%;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 20px;
          }

          .time {
            margin-left: auto;
          }
        }
      }
    }
  }

  .google-map {
    height: 192px;
    width: 100%;

    @include tablet {
      height: 207px;
      width: 50%;
    }

    // Checkout V3 overrides
    .checkout-v3 & {
      @include mobile-only {
        height: 280px;
      }

      @include tablet {
        height: 100%;
        min-height: 220px;
        width: 100%;
      }
    }
  }

  // override for checkout summary minicart

  .checkout-summary-minicart {
    text-align: left;

    .minicart-image {
      padding-right: 10px;
    }

    .mini-cart-price {
      font-weight: bold;
    }
  }

  .checkout-summary-order-totals {
    .cart-footer__item--val {
      font-weight: bold;
    }
  }

  // override for accordion

  .accordion-content {
    .acc-content-head {
      color: $ritual-private;
      display: block;
      padding-left: 0;
      text-decoration: none;
      width: 100%;

      // Checkout V3 overrides
      .checkout-v3 & {
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        letter-spacing: 0.4px;
        line-height: 24px;
        padding: 0 35px 0 24px;
        text-transform: none;
      }

      &::before {
        left: auto;
        right: -6px;

        // Checkout V3 overrides
        .checkout-v3 & {
          content: '';
          display: none;
        }
      }

      span:last-child:not(:only-child) {
        color: $copper;
        text-decoration: underline;
      }
    }

    .giftcount-header {
      display: none;

      // Checkout V3 overrides
      .checkout-v3 & {
        border-bottom: 1px solid $white-smoke;
        display: block;
        font-size: 14px;

        @include mobile-only {
          padding: 16px 24px 24px 24px;
        }

        @include tablet {
          padding: 24px;
        }
      }
    }

    .acc-content-body {
      padding-left: 0;

      // Checkout V3 overrides
      .checkout-v3 & {
        @include mobile-only {
          padding-top: 16px;
        }
      }
    }
  }

}

.order-steps {
  background-color: transparent;
  padding: 40px 0;
  text-align: center;

  // Checkout V3 overrides
  .checkout-v3 & {
    padding: 40px 0 64px 0;
  }

  .txt--center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &.order-steps-collect {
    padding: 30px 0 45px;

    .step-tile {
      width: 135px;

      &:not(:last-of-type)::before {
        font-size: 42px;
        letter-spacing: -1.5px;
      }

      &:first-child .step__number .circle::before {
        font-size: 10px;
      }

      &:nth-child(2) {
        .step__number .circle {
          @include icon(box);
          &::before {
            font-size: 18px;
          }
        }
      }

      &:last-child {
        .step__number .circle {
          @include icon(shop);
          &::before {
            font-size: 18px;
            margin: 0;
          }
        }
      }

      .step__number .circle {
        height: 40px;
        width: 40px;
        border-color: $gray-gainsboro;
      }

      .step__text p {
        margin: 15px 0 0;
      }
    }
  }
}

.step-tile {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  outline: none;
  position: relative;
  margin-bottom: 0;
  width: 120px;

  // Checkout V3 overrides
  .checkout-v3 & {
    @include mobile-only {
      width: 120px;
    }

    @include tablet {
      width: 170px;
    }
  }

  &:not(:last-of-type)::before {
    background-color: transparent;
    color: $gray-gainsboro;
    content: '...';
    font-size: 45px;
    height: auto;
    left: auto;
    position: absolute;
    right: -17px;
    top: -3px;
    transform: none;
    width: auto;
    z-index: 0;

    // Checkout V3 overrides
    .checkout-v3 & {
      letter-spacing: -2px;
      top: 2px;
    }
  }

  &:first-child {
    .step__number .circle {
      @include icon(check-thin);
      &::before {
        font-size: 12px;

        // Checkout V3 overrides
        .checkout-v3 & {
          font-size: 10px;
          margin-left: 3px;
        }
      }
    }
  }

  &:nth-child(2) {
    .step__number .circle {
      @include icon(delivery-fast);

      // Checkout V3 overrides
      .checkout-v3 & {
        @include icon(delivery);
      }

      &::before {
        font-size: 14px;

        // Checkout V3 overrides
        .checkout-v3 & {
          font-size: 18px;
          margin-left: 3px;
        }
      }
    }
  }

  &:last-child {
    .step__number .circle {
      @include icon(home);

      // Checkout V3 overrides
      .checkout-v3 & {
        @include icon(package);
      }

      &::before {
        font-size: 16px;
        margin-left: 2px;
        margin-bottom: 2px;

        // Checkout V3 overrides
        .checkout-v3 & {
          margin-bottom: 0;
        }
      }
    }
  }

  .step__number {
    &.active {
      & + .step__text {
        p {
          color: $rit-copper-dark;
          font-weight: bold;

          // Checkout V3 overrides
          .checkout-v3 & {
            font-weight: normal;
          }
        }
      }

      .circle {
        background-color: $rit-copper-dark;
        border-color: $rit-copper-dark;

        &::before {
          color: $white;
        }
      }
    }

    &:not(.active) {
      .circle {
        // Checkout V3 overrides
        .checkout-v3 & {
          background-color: $white;
          border-color: $white;
        }
      }
    }

    span {
      display: none;
    }

    .circle {
      align-items: center;
      border-radius: 50%;
      border: 1px solid $gray-dark;
      display: flex;
      height: 37px;
      justify-content: center;
      margin: 0 auto;
      width: 37px;

      // Checkout V3 overrides
      .checkout-v3 & {
        height: 48px;
        width: 48px;
      }

      &::before {
        color: $gray-dark;
        font-weight: bold;
      }
    }
  }

  .step__text {
    max-width: 100%;

    p {
      font-size: 12px;
      line-height: 14px;
      margin: 10px 0 0;

      // Checkout V3 overrides
      .checkout-v3 & {
        font-size: 14px;
        letter-spacing: 0.4px;
        margin-top: 16px;
      }
    }
  }
}

// Order Confirmation Details

.order-confirmation-details {
  margin-bottom: 32px;
  padding-bottom: 5px;
  position: relative;
  width: 100%;

  &::after {
    background-color: $gray-lines;
    bottom: 0;
    content: '';
    display: inline-block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 305px;
  }

  p {
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
    margin: 0 auto 24px;
    max-width: 80%;
  }

  &.order-confirmation-pickup-point {
    margin-bottom: 15px;
    padding-bottom: 0;

    &::after {
      display: none;
    }

    p {
      margin: 0 auto;
    }
  }

  @include tablet {
    padding-bottom: 2px;

    &::after {
      left: 50%;
      transform: translateX(-50%);
      width: 540px;
    }

    p {
      margin-bottom: 10px;
    }
  }
}

.order-confirmation-email {
  margin: 0 auto 28px;
  max-width: 90%;
  width: 100%;

  p {
    letter-spacing: .5px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .read-only-billing-address {
    span {
      display: block;
    }
  }

  @include tablet {
    margin-bottom: 20px;
    max-width: 320px;

    p {
      font-size: 14px;
    }
  }
}

.order-confirmation-info {
  margin: 31px 20px 40px;
  padding: 33px 0 0;
  position: relative;

  @include tablet {
    padding: 30px 15px 0 43px;
    margin-bottom: 45px;
  }

  &::before {
    content: '';
    height: 1px;
    width: 305px;
    display: block;
    background-color: $gray-gainsboro;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @include tablet {
      width: 540px;
    }
  }

  &.order-collect {
    margin: 15px 20px 20px;
    padding: 26px 0 0;

    @include tablet {
      padding: 40px 15px 0 34px;
      margin: 39px 20px 36px;
    }

    &::before {
      @include tablet {
        width: 760px;
      }
    }

    .icon {
      @include tablet {
        margin: 0 26px 0 0;
        font-size: 19px;

        &::before {
          width: 24px;
          height: 24px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 17px;
    text-align: left;

    @include tablet {
      margin-bottom: 10px;
      align-items: center;
    }
  }

  li,
  li a {
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .text {
    @include mobile-only {
      line-height: 24px;
    }
  }

  .icon {
    color: $white;
    text-align: center;
    margin: 5px 14px 0 0;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: $rit-copper;
      border-radius: 50%;
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    @include tablet {
      margin-top: 0;
    }
  }
}

// Order Confirmation Footer
.order-confirmation-footer {
  .get-directions-button {
    display: none;

    @include mobile-only {
      display: inline-block;
      margin-bottom: 23px;
      width: auto;

      & + .btn {
        line-height: normal;
        color: $rit-copper-dark;
        text-decoration: underline;
        cursor: pointer;
        border: 0;
      }
    }
  }
}

// Order Summary
.order-confirmation-summary {
  background-color: $white;
  margin: 0 0 40px -20px;
  padding: 20px;
  width: calc(100% + 40px);

  @include mobile-only {
    margin: 0 0 24px 0;
    width: 100%;
  }

  @include tablet {
    padding: 20px 30px;
    margin-left: 0;
    width: 100%;
  }

  // Checkout V3 overrides
  .checkout-v3 & {
    @include mobile-only {
      padding: 20px 0;
    }

    @include tablet {
      margin: 0 0 24px 0;
      padding: 24px 0;
    }

    h2 {
      padding: 0 24px;
    }

    .checkout-summary-minicart {
      border-top: 0;
      margin-bottom: 16px;

      .unit-base-price {
        display: block;
      }

      .mini-cart-inner-container {
        flex-wrap: nowrap;
        width: 100%;
      }
    }
  }

  &__footer {
    border-top: 1px solid $ritual-private;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 15px;

    // Checkout V3 overrides
    .checkout-v3 & {
      border-color: $gray-gainsboro;
      margin-top: 0;
      padding-top: 16px;
      padding-left: 24px;
      padding-right: 24px;
    }

    span {
      font-weight: bold;

      &:first-child {
        font-size: 12px;
        letter-spacing: 2.5px;
        text-transform: uppercase;

        // Checkout V3 overrides
        .checkout-v3 & {
          font-family: $font-header;
          font-size: 16px;
          letter-spacing: 0.4px;
          text-transform: none;
        }
      }

      &:last-child {
        font-family: $font-header;
        font-size: 16px;

         // Checkout V3 overrides
         .checkout-v3 & {
          color: $rit-black;
          font-size: 16px;
          font-weight: unset;
          letter-spacing: 0.4px;
          line-height: 24px;
        }
      }

      span {
        // Checkout V3 overrides
        .checkout-v3 &:first-child {
          color: $gray-dark;
          font-family: $font-body;
          font-weight: normal;
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
          text-transform: none;
        }
      }
    }
  }

  .mini-cart-details-bottom .mini-cart-pricing .unit-base-price {
    display: none;
  }
}

.order-confirmation-aside {
  width: 100%;
  text-align: center;
  padding: 32px 20px 20px;
  background-color: $white;

  .order-confirmation-subtitle {
    text-transform: uppercase;
    color: $rit-copper-dark;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    display: block;
    margin-bottom: 12px;
  }

  .order-confirmation-title {
    text-transform: none;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .image-holder {
    width: 100%;
    margin-bottom: 10px;

    img {
      max-width: 100%;
    }
  }

  .btn {
    &.btn-mobile {
      padding: 9px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }

    &:not(:first-of-type) {
      margin-top: 10px;
    }

    span {
      margin-right: 10px;
    }
  }
}

// Refer a friend block on confirmation page

.order-confirmation-refer-a-friend {
  background-color: $white;
  margin-top: 24px;
  padding: 40px;
  text-align: left;
  width: 49%;

  @include mobile-only {
    width: 100%;
  }

  .refer-a-friend-heading {
    font-family: $font-header;
    color: $rit-black;
    max-width: 200px;
    font-size: 28px;
    letter-spacing: 0.6px;
    line-height: 40px;
  }

  .refer-a-friend-text,
  .refer-a-friend-subtext {
    font-family: $font-header;
    color: $rit-black;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
  }

  .refer-a-friend-text {
    margin-bottom: 20px;
  }

  .refer-a-friend-subtext {
    margin-bottom: 10px;
  }

  .refer-a-friend-text-small {
    font-family: $font-header;
    color: $rit-black;
    font-size: 14px;
    margin-top: 5px;
    letter-spacing: 0.3px;
    line-height: 24px;
  }

  .benefits-list {
    max-width: 100%;
    margin-bottom: 20px;

    li {
      span {
        color: $rit-black;
      }

      .icon-check {
        font-size: 9px;
        color: $green;
      }
    }
  }

  .refer-a-friend-image-block {
    display: flex;
  }

  .image-holders {
    margin-right: 16px;

    img {
      max-width: 80px;
    }
  }

  .btn {
    min-width: 180px;
    padding: 16px 20px;

    &.btn-mobile {
      padding: 9px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }

    &:not(:first-of-type) {
      margin-top: 10px;
    }

    span {
      margin-right: 10px;
    }
  }
}

// Order Notifications
.order-confirmation-notifications {
  margin-bottom: 24px;

  @include mobile-only {
    padding: 0 20px;
  }
}

// Order Confirmation Section
.order-confirmation-section {
  background-color: $white;
  display: flex;
  justify-content: space-between;

  @include mobile-only {
    flex-flow: column;
    padding: 24px;
  }

  @include tablet {
    padding: 40px;
  }

  & + & {
    @include mobile-only {
      margin-top: 24px;
    }

    @include tablet {
      margin-top: 40px;
    }
  }

  .content-asset & {
    margin-top: 24px;
  }

  .authenticated & {
    @include mobile-only {
      width: 100%;
    }
  }

  &.order-confirmation-section-float {
    float: right;
    width: 49%;

    @include mobile-only {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 24px;
  }

  p {
    color: $rit-black;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 0;

    & + p {
      margin-top: 16px;
    }
  }

  .btn {
    margin-top: 24px;
    max-width: none;
    width: 100%;
  }

  .order-confirmation-section-col {
    &:not(:only-child) {
      width: calc(50% - 40px);

      @include mobile-only {
        width: 100%;
      }
    }

    &:only-child {
      width: 100%;
    }

    & + .order-confirmation-section-col {
      display: flex;

      @include mobile-only {
        margin-top: 24px;
      }
    }

    figure {
      img {
        width: 100%;
      }
    }
  }

  .download-app-tile {
    background-color: $rit-sandy-bg;
    text-align: center;

    @include mobile-only {
      padding: 24px;
    }

    @include tablet {
      padding: 40px;
    }

    a {
      display: inline-block;
      margin: 24px 12px 0 12px;

      & + a {
        margin-top: 0;
      }
    }
  }

  .vertical-tile-list {
    margin-top: 24px;

    label {
      height: auto;
    }
  }
}
