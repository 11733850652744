button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.btn {
  background-color: $rit-black;
  border: 1px solid $rit-black;
  color: $white;
  display: inline-block;
  font-family: $font-body;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 2px;
  line-height: 1.2;
  min-width: 220px;
  max-width: 280px;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: .2s ease-in-out;

  &.btn--primary,
  .btn--primary & {
    background-color: $rit-black;
    border: 1px solid $rit-black;
  }

  &.btn--secondary,
  .btn--secondary & {
    background-color: transparent;
    border: 1px solid $gray-disabled;
    color: $rit-black;
  }

  &.btn--tertiary,
  .btn--tertiary & {
    background-color: transparent;
    border: 1px solid $gray-disabled;
    color: $rit-black;
  }

  &.btn--cancel-order {
    color: $white;
    margin-right: 60px;
    max-height: 30px;
    min-width: 180px;
    padding: 8px;
    position: absolute;
    right: 0;

    @include mobile-only {
      margin: 0 0 20px 0;
      position: relative;
      width: 90%;
    }
  }

  &.btn--white,
  .btn--white & {
    background-color: transparent;
    border: 1px solid $white;
  }

  &.btn--white-filled {
    background-color: $white;
    border: 1px solid $white;
    color: $rit-black;
  }

  &.btn--white-black {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }

  &.btn--banner {
    background-color: $rit-black;
    border: 1px solid $rit-black;
  }

  &.btn--disabled,
  .btn--disabled &,
  &[disabled] {
    background-color: $gray-disabled;
    border: 1px solid $gray-disabled;
    color: $white;
    cursor: default;
    pointer-events: none;
  }

  &.btn--stripped {
    background-color: transparent;
    border: 0;
    color: $rit-dark;
    text-transform: none;
    min-width: 1px;
    min-height: 1px;
    padding: 0;
  }

  &.btn-secondary-on-mobile {
    @include mobile-only {
      background-color: transparent;
      border-color: $gray-disabled;
      color: $rit-black;
    }
  }

  &.btn-full-wdth {
    max-width: none;
    padding: 20px 20px;
    width: 100%;
  }

  &.btn-add {
    span {
      padding-left: 22px;
      position: relative;

      &::before {
        color: $white;
        content: $icon-plus3;
        font-family: 'iconfont';
        font-size: 14px;
        height: 16px;
        left: 0;
        position: absolute;
        top: -3px;
        transition: color .2s ease-in-out;
        width: 16px;
      }
    }
  }

  &.btn--sandy {
    background-color: $white;
    border: 1px solid $rit-sandy-dark;
    color: $rit-sandy-dark;
  }

  // styles for touchscreens - prevents sticky hover
  @include desktop {
    &:hover,
    &:active {
      background-color: transparent;
      color: $rit-dark;

      &.btn-add {
        span::before {
          color: $rit-dark;
        }
      }
    }

    &.btn--secondary,
    .btn--secondary & {
      &:hover,
      &:active {
        border: 1px solid $rit-black;
      }

      &.btn--secondary-hover {
        &:hover,
        &:active {
          background-color: $rit-black;
        }
      }
    }

    &.btn--tertiary,
    .btn--tertiary & {
      &:hover,
      &:active {
        background-color: $rit-black;
        color: $white;
      }
    }

    &.btn--banner {
      &:hover,
      &:active {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
      }
    }

    &.btn--white,
    .btn--white & {
      &:hover,
      &:active {
        background-color: $white;
        color: $rit-dark;
      }
    }

    &.btn--white-filled {
      &:hover,
      &:active {
        background-color: transparent;
        color: $white;
      }
    }

    &.btn--white-black {
      &:hover,
      &:active {
        border: 1px solid $rit-black;
        color: $white;
      }
    }

    &.btn--sandy {
      &:hover,
      &:active {
        border: 1px solid $rit-black;
        color: $rit-black;
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: 17px 20px 12px;
  }

  .send-icon-visible {
    background-color: $rit-black;
    color: $white;
    display: inline-block;
    position: relative;
    z-index: 10;

    &::before {
      @include icon-styles;
      content: icon-char(check);
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include mobile-only {
        font-size: 10px;
      }
    }
  }
}

// --------------------------------
//          PULSATING BUTTON
// --------------------------------

.pulsating-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  bottom: 100px;
  height: 32px;
  padding-right: 10px;
  text-decoration: none;
  background-color: rgba($white, 0.6);
  border-radius: 16px;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s $default-ease-out-fast;
  }

  .pulsating-button-label {
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 28px;
    position: relative;
  }
}

.pulsating-button {
  display: inline-block;
  position: relative;
  bottom: 0;
  left: -5px;
  width: 32px;
  height: 32px;
  background-color: $white;
  padding: 3px;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba($black, 0.4);
  opacity: 1;
  visibility: visible;

  animation: pulse 2s infinite;
  transform-origin: center;
  transition: transform $quaternary-duration $default-ease-out-fast,
          opacity $tertiary-duration $default-ease-in-out,
          visibility $tertiary-duration $default-ease-in-out;

  // gradient circle
  &::before {
    content: '';
    border-radius: 100%;
    background-image: url('../images/pulsating-button-gradient.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &::after {
    @include icon-styles;
    content: icon-char(arrow--copper);
    font-size: 8px;
    color: #a68674;
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.2);
  }

  body.video-fullscreen & {
    animation: none;
    box-shadow: none;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

// --------------------------------
//          BACK BUTTON
// --------------------------------

.back-button {
  font-size: 12px;
  letter-spacing: 1.5px;
  position: relative;
  text-transform: uppercase;
  color: $rit-copper;
  text-decoration: underline;
  font-family: $font-body;

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    color: $black;
    text-decoration: none;
  }

  &:hover,
  &:active {
    text-decoration: none;

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      text-decoration: underline;
    }
  }

  .icon {
    font-size: 26px;
    position: relative;
    top: 3px;
    left: 0;
  }
}

.btn-configure {
  background-color: $white;
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
  z-index: 10;
}
