.show-notification-sandy,
.show-notification-black {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $red-notification;
    border: 2px solid $rit-sandy-dark;
  }
}
.show-notification-sandy {
  &::after {
    border: 2px solid $rit-sandy-dark;
  }
}

.show-notification-black {
  &::after {
    border: 2px solid $black;
  }
}
