.tree-counter {
  .tree-count-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 0 60px;
    text-align: center;

    @include mobile-only {
      padding: 20px 0 40px;
    }
  }

  .tree-count-num {
    color: $black;
    font-family: $font-header;
    font-size: 50px;
    letter-spacing: 1.88px;
    line-height: 50px;
    margin-bottom: 15px;
  }

  .tree-count-text {
    color: $gray-dark;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    max-width: 240px;
  }

  .icon {
    color: $rit-copper-lighter;
    font-size: 50px;
    margin-bottom: 15px;
  }
}
