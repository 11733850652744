.pt_cart {
  .page-content {
    overflow: hidden;
    width: 100%;
  }

  .primary-content {
    position: relative;
    width: 100%;
  }
}
