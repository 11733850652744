// spinner
.spinner {
  display: none;
  text-align: center;
  width: 100%;
  font-size: 0;

  &.spinner-visible {
    display: block;
  }

  &.black-spinner {
    span {
      background-color: $rit-dark;
    }
  }

  &.white-spinner {
    span {
      background-color: $white;
    }
  }

  &.grid-spinner {
    margin-top: -23px;
  }

  span {
    background-color: $rit-black;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-top: -1px;
    opacity: 0;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: -3px;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: loaderPulse 1.6s infinite $i*0.2s;
      }
    }
  }

  &.grid-loading-spinner {
    position: absolute;
    top: 170px;

    span {
      background-color: $rit-dark;
    }
  }

  &.flyout-spinner {
    margin-top: 20px;
  }

  &.stores-results-spinner {
    margin-top: 40px;
  }

  &.more-stores-spinner {
    margin-top: 30px;
  }

  &.step-1-spinner,
  &.eurobonus-spinner {
    span {
      transform: none;
    }
  }

  &.update-delivery-time-spinner span {
    top: auto;
    transform: translate(0, 0);
  }
}

@keyframes loaderPulse {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
