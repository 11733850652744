.product-explanation-slider {
  @include mobile-only {
    background-color: transparent !important;
    padding-bottom: 5px;
  }

  .product-slider-holder {
    padding-bottom: 37px;

    @include tablet {
      padding: 37px 0;
    }
  }

  .product-main-slider {
    @include mobile-only {
      padding: 20px 16px;
    }

    .slider-inner-wrap {
      @include mobile-only {
        background-color: $white;
        width: 100%;
      }

      @include tablet {
        max-width: 480px;
      }

      .image-holder {
        background-position: center top;
        background-size: cover;
        height: 375px;
        width: 100%;

        @include tablet {
          display: none;
        }
      }
    }

    .step-intro {
      @include mobile-only {
        padding: 33px 24px 15px;
      }

      @include tablet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 80px 0 40px;
      }

      h3 {
        color: $rit-black;
        font-size: 24px;
        letter-spacing: 1.5px;
        text-transform: none;
        margin-bottom: 18px;

        @include tablet {
          font-size: 40px;
          margin-bottom: 18px;
        }
      }

      p {
        color: $rit-black;
        margin-bottom: 15px;

        @include tablet {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          margin-bottom: 23px;
        }
      }

      .disclaimer {
        @include tablet {
          margin: 0 auto;
          max-width: 270px;
        }
      }
    }

    .btn-content-video {
      font-size: 10px;
      line-height: 16px;
      padding-left: 0;
      text-transform: uppercase;

      &::before {
        display: none;
      }

      @include mobile-only {
        display: block;
        margin-top: 0;
      }

      @include tablet {
        display: block;
        letter-spacing: 1.7px;
      }
    }
  }

  .product-slider-step {
    @include mobile-only {
      padding: 0 8px;
    }
  }

  h4 {
    color: $rit-copper-dark;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;

    @include tablet {
      font-size: 12px;
      letter-spacing: 2px;
      margin-bottom: 19px;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;

    @include tablet {
      margin-bottom: 6px;
    }
  }

  .product-info-row {
    margin-bottom: 16px;
    overflow: hidden;
    width: 100%;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  .product-price {
    color: $rit-black;
    display: block;
    font-family: $font-header;
    font-size: 26px;
    line-height: 30px;
  }

  .btn {
    @include mobile-only {
      width: 100%;
    }
  }

  .btn--white {
    @include mobile-only {
      border-color: $gray-lighter;
      color: $rit-black;
    }
  }

  .usp-checkbox-list {
    margin-bottom: 18px;
  }

  .shipping-info-point {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    list-style: none;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon-check {
      color: $rit-copper-dark;
      font-size: 10px;
      line-height: 1;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }

  .slider-bar {
    background-color: $white;

    @include mobile-only {
      margin-top: 20px;
    }

    .indicator {
      background-color: $gray-silver;

      @include mobile-only {
        background-color: $gray-dark;
      }
    }
  }

  .slick-arrow {
    &.slick-prev {
      @include tablet-landscape {
        left: 5px !important;
      }
    }

    &.slick-next {
      @include tablet-landscape {
        right: 5px !important;
      }
    }
  }

  .slider-dots {
    @include tablet {
      bottom: 67px;
    }
  }

  &.has-dark-bg {
    .slider-bar {
      background-color: $gray-dark;

      .indicator {
        background-color: $white;
      }
    }

    .slick-arrow {
      .slider-button {
        @include tablet {
          background-color: $white;
        }
      }

      &.slick-disabled .slider-button {
        opacity: .2;
        visibility: visible;
      }
    }

    @include tablet {
      h1,
      h4,
      ol,
      .step-intro h3,
      .step-intro p,
      ul,
      .product-price,
      .btn--secondary {
        color: $white;
      }

      .btn-add-to-cart {
        background-color: $white;
        color: $rit-black;

        &:hover {
          background-color: transparent;
          border-color: $white;
          color: $white;
        }
      }

      .btn--secondary:hover {
        background-color: $white;
        border-color: $white;
        color: $rit-black;
      }

      .explanation-list {
        li {
          background-color: $dark-blue;
          border: 0;
        }

        .key-copy {
          color: $white;
        }

        .explanation-copy {
          color: #d0a880;
        }
      }
    }
  }
}

.product-explanation-slider-event {
  h4 {
    font-size: 11px;
    letter-spacing: 1.5px;
    color: $rit-black;
  }

  .explanation-list {
    margin-bottom: 20px;
    border: 0;

    li {
      width: calc(50% - 5px);
      margin-bottom: 10px;
      border: initial;
      background-color: $white;
    }
  }

  @include mobile-only {
    .product-main-slider {
      .slider-inner-wrap {
        background-color: inherit;
      }
    }

    .explanation-list {
      justify-content: space-between;

      li {
        min-height: initial;
      }
    }
  }

  @include tablet {
    &.product-slider-block {
      padding: 0 0 31px;
    }

    .product-slider-media-holder {
      width: 60%;
    }

    &.product-slider-reverted {
      .product-slider-steps {
        padding-right: 60%;
      }
    }

    &:not(.product-slider-reverted) {
      .product-slider-steps {
        padding-left: 60%;
      }
    }

    .product-main-slider {
      .step-intro {
        min-height: initial;
        padding-top: 0;
      }

      .slick-arrow.slick-prev {
        left: 30px;
      }
    }
  }
}

.explanation-list {
  border-left: 1px solid $gray-amber;
  border-top: 1px solid $gray-amber;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include tablet {
    border: 0;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 390px;
    padding-top: 20px;
  }

  li {
    align-items: center;
    border-bottom: 1px solid $gray-amber;
    border-right: 1px solid $gray-amber;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    min-height: 146px;
    padding: 20px 15px;
    width: 50%;

    @include tablet {
      border: 1px solid $gray-amber;
      margin-bottom: 24px;
      min-height: 80px;
      padding: 15px 20px;
      width: calc(50% - 13px);
    }
  }

  span {
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .key-copy {
    color: $rit-black;
    font-family: $font-header;
    font-size: 16px;
    line-height: 24px;

    @include tablet {
      letter-spacing: 0;
    }
  }

  .explanation-copy {
    color: $copper;
    font-size: 10px;
    letter-spacing: 1.7px;
    line-height: 16px;
    text-transform: uppercase;
  }
}
