.customer-service-block {
  padding: 0 20px 38px;
  margin-top: -45px;

  .text-wrapper {
    text-align: center;

    span,
    a {
      display: block;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.46px;
      color: $gray-dark;
    }
  }

  @include tablet {
    display: none;
  }
}

.cs-service-block {
  display: none;
  margin-bottom: 64px;

  @include desktop {
    display: block;
  }

  .customer-service-block {
    display: block;
    margin: 0;
    padding: 0;

    .text-wrapper {
      text-align: right;
      padding-right: 4px;

      a,
      span {
        color: $rit-copper-dark;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
      }

      span {
        font-weight: 700;
      }

      a {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}

.pt_checkout ~ .customer-service-block,
.pt_privacy ~ .disable-clicks .customer-service-block {
  margin-top: 0;
}

.customer-service-element {
  margin-top: 40px;

  p, a {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  p {
    max-width: 610px;
    margin: 0 auto 10px;
    color: $gray-medium;

    @include mobile-only {
      padding: 0 20px;
    }
  }
}
