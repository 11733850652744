// ***************** ICON FONT CONFIG ***************** //
// Description:
// General settings for the iconfont
// --------------------------------------------------

// here we can import the generated base 64 css file, instead of the real fonts if need be.
// we must append the name or else we have two files in the same dir with the same name

// please comment out when not using base64
//@import 'iconfont64';

//normal non base64 font call

// $rand : random(300); // use this to generate number to prevent font caching
@font-face {
	font-family: "iconfont";
  src: url('../fonts/iconfont.woff2') format('woff2'),
       url('../fonts/iconfont.woff') format('woff'),
       url('../fonts/iconfont.ttf') format('truetype');
}

@mixin icon-styles {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == AR {
		$char: "\EA01";
	}
	@if $filename == account {
		$char: "\EA02";
	}
	@if $filename == activated-charcoal {
		$char: "\EA03";
	}
	@if $filename == advent-calendar {
		$char: "\EA04";
	}
	@if $filename == advent-key {
		$char: "\EA05";
	}
	@if $filename == advent-tree {
		$char: "\EA06";
	}
	@if $filename == ar-button {
		$char: "\EA07";
	}
	@if $filename == arrow--back {
		$char: "\EA08";
	}
	@if $filename == arrow--copper {
		$char: "\EA09";
	}
	@if $filename == arrow--down {
		$char: "\EA0A";
	}
	@if $filename == arrow--left {
		$char: "\EA0B";
	}
	@if $filename == arrow--link {
		$char: "\EA0C";
	}
	@if $filename == arrow--right {
		$char: "\EA0D";
	}
	@if $filename == arrow--up {
		$char: "\EA0E";
	}
	@if $filename == arrow-back {
		$char: "\EA0F";
	}
	@if $filename == arrow-diap {
		$char: "\EA10";
	}
	@if $filename == arrow-track-and-trace {
		$char: "\EA11";
	}
	@if $filename == arrow-wider-down {
		$char: "\EA12";
	}
	@if $filename == arrow2--down {
		$char: "\EA13";
	}
	@if $filename == arrow2--left {
		$char: "\EA14";
	}
	@if $filename == arrow2--right {
		$char: "\EA15";
	}
	@if $filename == arrow2--up {
		$char: "\EA16";
	}
	@if $filename == arrow3--down-rit-dark {
		$char: "\EA17";
	}
	@if $filename == arrow3--down-white {
		$char: "\EA18";
	}
	@if $filename == arrow3--down {
		$char: "\EA19";
	}
	@if $filename == arrow3--left {
		$char: "\EA1A";
	}
	@if $filename == arrow3--right {
		$char: "\EA1B";
	}
	@if $filename == arrow3--up {
		$char: "\EA1C";
	}
	@if $filename == arrow4--down {
		$char: "\EA1D";
	}
	@if $filename == arrow4--left {
		$char: "\EA1E";
	}
	@if $filename == arrow4--right {
		$char: "\EA1F";
	}
	@if $filename == arrow4--up {
		$char: "\EA20";
	}
	@if $filename == arrow5--left {
		$char: "\EA21";
	}
	@if $filename == arrow5--up {
		$char: "\EA22";
	}
	@if $filename == bell {
		$char: "\EA23";
	}
	@if $filename == box {
		$char: "\EA24";
	}
	@if $filename == bring-delivery {
		$char: "\EA25";
	}
	@if $filename == business-related-questions {
		$char: "\EA26";
	}
	@if $filename == call {
		$char: "\EA27";
	}
	@if $filename == candle {
		$char: "\EA28";
	}
	@if $filename == card-payment {
		$char: "\EA29";
	}
	@if $filename == cart--plus {
		$char: "\EA2A";
	}
	@if $filename == cart-basket {
		$char: "\EA2B";
	}
	@if $filename == cart-plus {
		$char: "\EA2C";
	}
	@if $filename == cart {
		$char: "\EA2D";
	}
	@if $filename == check-thin {
		$char: "\EA2E";
	}
	@if $filename == check {
		$char: "\EA2F";
	}
	@if $filename == circle-exclamation {
		$char: "\EA30";
	}
	@if $filename == circle-info {
		$char: "\EA31";
	}
	@if $filename == cities {
		$char: "\EA32";
	}
	@if $filename == clean-safe {
		$char: "\EA33";
	}
	@if $filename == click-and-collect {
		$char: "\EA34";
	}
	@if $filename == close-thick {
		$char: "\EA35";
	}
	@if $filename == close {
		$char: "\EA36";
	}
	@if $filename == close2 {
		$char: "\EA37";
	}
	@if $filename == cloud {
		$char: "\EA38";
	}
	@if $filename == credit-card-payment {
		$char: "\EA39";
	}
	@if $filename == cruelty-free {
		$char: "\EA3A";
	}
	@if $filename == cs-info-icon {
		$char: "\EA3B";
	}
	@if $filename == current-location-outline {
		$char: "\EA3C";
	}
	@if $filename == current-location {
		$char: "\EA3D";
	}
	@if $filename == data {
		$char: "\EA3E";
	}
	@if $filename == deliveries {
		$char: "\EA3F";
	}
	@if $filename == delivery-day {
		$char: "\EA40";
	}
	@if $filename == delivery-fast {
		$char: "\EA41";
	}
	@if $filename == delivery-time {
		$char: "\EA42";
	}
	@if $filename == delivery-truck-full {
		$char: "\EA43";
	}
	@if $filename == delivery {
		$char: "\EA44";
	}
	@if $filename == dots {
		$char: "\EA45";
	}
	@if $filename == download-report {
		$char: "\EA46";
	}
	@if $filename == dropdown {
		$char: "\EA47";
	}
	@if $filename == duration {
		$char: "\EA48";
	}
	@if $filename == earthweek-logo {
		$char: "\EA49";
	}
	@if $filename == easter-bow {
		$char: "\EA4A";
	}
	@if $filename == edp-tool {
		$char: "\EA4B";
	}
	@if $filename == email-circle {
		$char: "\EA4C";
	}
	@if $filename == email {
		$char: "\EA4D";
	}
	@if $filename == env-leaf {
		$char: "\EA4E";
	}
	@if $filename == env-waste {
		$char: "\EA4F";
	}
	@if $filename == env-water {
		$char: "\EA50";
	}
	@if $filename == exclusive-partnership {
		$char: "\EA51";
	}
	@if $filename == fabric {
		$char: "\EA52";
	}
	@if $filename == favourite {
		$char: "\EA53";
	}
	@if $filename == filter-icon {
		$char: "\EA54";
	}
	@if $filename == foam {
		$char: "\EA55";
	}
	@if $filename == gift-filled {
		$char: "\EA56";
	}
	@if $filename == gift {
		$char: "\EA57";
	}
	@if $filename == giftcard-promotions {
		$char: "\EA58";
	}
	@if $filename == giftcard {
		$char: "\EA59";
	}
	@if $filename == giving-back {
		$char: "\EA5A";
	}
	@if $filename == hair-test {
		$char: "\EA5B";
	}
	@if $filename == hand-gift {
		$char: "\EA5C";
	}
	@if $filename == hand-heart {
		$char: "\EA5D";
	}
	@if $filename == heart {
		$char: "\EA5E";
	}
	@if $filename == home {
		$char: "\EA5F";
	}
	@if $filename == hor-bottle {
		$char: "\EA60";
	}
	@if $filename == hor-candleholder {
		$char: "\EA61";
	}
	@if $filename == hor-fragrance {
		$char: "\EA62";
	}
	@if $filename == hor-sticks {
		$char: "\EA63";
	}
	@if $filename == hor-wax {
		$char: "\EA64";
	}
	@if $filename == hydra-boost-complex-logo-white {
		$char: "\EA65";
	}
	@if $filename == hydra-boost-icon {
		$char: "\EA66";
	}
	@if $filename == ico-clock {
		$char: "\EA67";
	}
	@if $filename == icon_system_min_circle {
		$char: "\EA68";
	}
	@if $filename == icon_system_plus_circle {
		$char: "\EA69";
	}
	@if $filename == key-skeleton-thin {
		$char: "\EA6A";
	}
	@if $filename == key {
		$char: "\EA6B";
	}
	@if $filename == leaf {
		$char: "\EA6C";
	}
	@if $filename == lightning {
		$char: "\EA6D";
	}
	@if $filename == limiting-waste {
		$char: "\EA6E";
	}
	@if $filename == location-alt {
		$char: "\EA6F";
	}
	@if $filename == location {
		$char: "\EA70";
	}
	@if $filename == magazine {
		$char: "\EA71";
	}
	@if $filename == magnifying-glass {
		$char: "\EA72";
	}
	@if $filename == mail {
		$char: "\EA73";
	}
	@if $filename == man {
		$char: "\EA74";
	}
	@if $filename == menu {
		$char: "\EA75";
	}
	@if $filename == menu2 {
		$char: "\EA76";
	}
	@if $filename == minus {
		$char: "\EA77";
	}
	@if $filename == minus2 {
		$char: "\EA78";
	}
	@if $filename == minus3 {
		$char: "\EA79";
	}
	@if $filename == minus3invert {
		$char: "\EA7A";
	}
	@if $filename == my-account {
		$char: "\EA7B";
	}
	@if $filename == my-order {
		$char: "\EA7C";
	}
	@if $filename == my-rituals-dark {
		$char: "\EA7D";
	}
	@if $filename == natural-origin-90 {
		$char: "\EA7E";
	}
	@if $filename == natural-origin-95-fr {
		$char: "\EA7F";
	}
	@if $filename == natural-origin-95 {
		$char: "\EA80";
	}
	@if $filename == natural-origin-97 {
		$char: "\EA81";
	}
	@if $filename == newsletter {
		$char: "\EA82";
	}
	@if $filename == online-advice {
		$char: "\EA83";
	}
	@if $filename == online {
		$char: "\EA84";
	}
	@if $filename == our-stores {
		$char: "\EA85";
	}
	@if $filename == package {
		$char: "\EA86";
	}
	@if $filename == password-not-visible {
		$char: "\EA87";
	}
	@if $filename == password-visible {
		$char: "\EA88";
	}
	@if $filename == pause-large {
		$char: "\EA89";
	}
	@if $filename == pause {
		$char: "\EA8A";
	}
	@if $filename == payment {
		$char: "\EA8B";
	}
	@if $filename == pencil {
		$char: "\EA8C";
	}
	@if $filename == perfume-genie-bottle {
		$char: "\EA8D";
	}
	@if $filename == perfume-genie {
		$char: "\EA8E";
	}
	@if $filename == plant-a-tree {
		$char: "\EA8F";
	}
	@if $filename == play-advent {
		$char: "\EA90";
	}
	@if $filename == play-large {
		$char: "\EA91";
	}
	@if $filename == play {
		$char: "\EA92";
	}
	@if $filename == plus {
		$char: "\EA93";
	}
	@if $filename == plus2 {
		$char: "\EA94";
	}
	@if $filename == plus3 {
		$char: "\EA95";
	}
	@if $filename == plus3invert {
		$char: "\EA96";
	}
	@if $filename == powerrechargeformula {
		$char: "\EA97";
	}
	@if $filename == product-info {
		$char: "\EA98";
	}
	@if $filename == product-quality-feather {
		$char: "\EA99";
	}
	@if $filename == product-quality-lotus {
		$char: "\EA9A";
	}
	@if $filename == product-quality-sparkle {
		$char: "\EA9B";
	}
	@if $filename == product-quality-sun {
		$char: "\EA9C";
	}
	@if $filename == product-quality-water-drop {
		$char: "\EA9D";
	}
	@if $filename == product-quality-waves {
		$char: "\EA9E";
	}
	@if $filename == rating {
		$char: "\EA9F";
	}
	@if $filename == refill-tree-counter {
		$char: "\EAA0";
	}
	@if $filename == refill-trees-protected {
		$char: "\EAA1";
	}
	@if $filename == restart {
		$char: "\EAA2";
	}
	@if $filename == returns {
		$char: "\EAA3";
	}
	@if $filename == ritual-quality {
		$char: "\EAA4";
	}
	@if $filename == rituals-easter-egg {
		$char: "\EAA5";
	}
	@if $filename == rituals-logo {
		$char: "\EAA6";
	}
	@if $filename == ruler {
		$char: "\EAA7";
	}
	@if $filename == sale {
		$char: "\EAA8";
	}
	@if $filename == scan {
		$char: "\EAA9";
	}
	@if $filename == search-dotless {
		$char: "\EAAA";
	}
	@if $filename == search-magnifier {
		$char: "\EAAB";
	}
	@if $filename == search {
		$char: "\EAAC";
	}
	@if $filename == secure-dotless {
		$char: "\EAAD";
	}
	@if $filename == secure {
		$char: "\EAAE";
	}
	@if $filename == send {
		$char: "\EAAF";
	}
	@if $filename == service {
		$char: "\EAB0";
	}
	@if $filename == share {
		$char: "\EAB1";
	}
	@if $filename == shop {
		$char: "\EAB2";
	}
	@if $filename == shopping {
		$char: "\EAB3";
	}
	@if $filename == size {
		$char: "\EAB4";
	}
	@if $filename == skincare-test {
		$char: "\EAB5";
	}
	@if $filename == social--facebook-large {
		$char: "\EAB6";
	}
	@if $filename == social--facebook {
		$char: "\EAB7";
	}
	@if $filename == social--facebook2 {
		$char: "\EAB8";
	}
	@if $filename == social--fbmessenger {
		$char: "\EAB9";
	}
	@if $filename == social--google {
		$char: "\EABA";
	}
	@if $filename == social--instagram-large {
		$char: "\EABB";
	}
	@if $filename == social--instagram {
		$char: "\EABC";
	}
	@if $filename == social--instagram2 {
		$char: "\EABD";
	}
	@if $filename == social--linkedin-large {
		$char: "\EABE";
	}
	@if $filename == social--messenger {
		$char: "\EABF";
	}
	@if $filename == social--pinterest-large {
		$char: "\EAC0";
	}
	@if $filename == social--pinterest {
		$char: "\EAC1";
	}
	@if $filename == social--pinterest2 {
		$char: "\EAC2";
	}
	@if $filename == social--snapchat-large {
		$char: "\EAC3";
	}
	@if $filename == social--tiktok-large {
		$char: "\EAC4";
	}
	@if $filename == social--twitter-large {
		$char: "\EAC5";
	}
	@if $filename == social--twitter {
		$char: "\EAC6";
	}
	@if $filename == social--twitter2 {
		$char: "\EAC7";
	}
	@if $filename == social--whatsapp-filled {
		$char: "\EAC8";
	}
	@if $filename == social--whatsapp {
		$char: "\EAC9";
	}
	@if $filename == social--youtube-large {
		$char: "\EACA";
	}
	@if $filename == social--youtube {
		$char: "\EACB";
	}
	@if $filename == social--youtube2 {
		$char: "\EACC";
	}
	@if $filename == sos {
		$char: "\EACD";
	}
	@if $filename == star {
		$char: "\EACE";
	}
	@if $filename == store {
		$char: "\EACF";
	}
	@if $filename == super-soft {
		$char: "\EAD0";
	}
	@if $filename == supplements {
		$char: "\EAD1";
	}
	@if $filename == sustainability-report {
		$char: "\EAD2";
	}
	@if $filename == sustainability {
		$char: "\EAD3";
	}
	@if $filename == system-danger {
		$char: "\EAD4";
	}
	@if $filename == system-genie-larger-outline-dark {
		$char: "\EAD5";
	}
	@if $filename == system-lock {
		$char: "\EAD6";
	}
	@if $filename == talisman-bottle {
		$char: "\EAD7";
	}
	@if $filename == talisman-cap {
		$char: "\EAD8";
	}
	@if $filename == talisman-talisman {
		$char: "\EAD9";
	}
	@if $filename == tao-logo {
		$char: "\EADA";
	}
	@if $filename == thread-count {
		$char: "\EADB";
	}
	@if $filename == thread {
		$char: "\EADC";
	}
	@if $filename == timer {
		$char: "\EADD";
	}
	@if $filename == tranquility {
		$char: "\EADE";
	}
	@if $filename == user {
		$char: "\EADF";
	}
	@if $filename == vegan {
		$char: "\EAE0";
	}
	@if $filename == video {
		$char: "\EAE1";
	}
	@if $filename == view--list {
		$char: "\EAE2";
	}
	@if $filename == view--tiles {
		$char: "\EAE3";
	}
	@if $filename == waste {
		$char: "\EAE4";
	}
	@if $filename == water-and-drop {
		$char: "\EAE5";
	}
	@if $filename == water-drop {
		$char: "\EAE6";
	}
	@if $filename == water {
		$char: "\EAE7";
	}
	@if $filename == weight {
		$char: "\EAE8";
	}
	@if $filename == well-being {
		$char: "\EAE9";
	}
	@if $filename == woman {
		$char: "\EAEA";
	}
	@if $filename == yoga-fill {
		$char: "\EAEB";
	}

	@return $char;
}


@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@include icon-styles;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

// Classes for each font icon
.icon-AR {
	@include icon(AR);
}.icon-account {
	@include icon(account);
}.icon-activated-charcoal {
	@include icon(activated-charcoal);
}.icon-advent-calendar {
	@include icon(advent-calendar);
}.icon-advent-key {
	@include icon(advent-key);
}.icon-advent-tree {
	@include icon(advent-tree);
}.icon-ar-button {
	@include icon(ar-button);
}.icon-arrow--back {
	@include icon(arrow--back);
}.icon-arrow--copper {
	@include icon(arrow--copper);
}.icon-arrow--down {
	@include icon(arrow--down);
}.icon-arrow--left {
	@include icon(arrow--left);
}.icon-arrow--link {
	@include icon(arrow--link);
}.icon-arrow--right {
	@include icon(arrow--right);
}.icon-arrow--up {
	@include icon(arrow--up);
}.icon-arrow-back {
	@include icon(arrow-back);
}.icon-arrow-diap {
	@include icon(arrow-diap);
}.icon-arrow-track-and-trace {
	@include icon(arrow-track-and-trace);
}.icon-arrow-wider-down {
	@include icon(arrow-wider-down);
}.icon-arrow2--down {
	@include icon(arrow2--down);
}.icon-arrow2--left {
	@include icon(arrow2--left);
}.icon-arrow2--right {
	@include icon(arrow2--right);
}.icon-arrow2--up {
	@include icon(arrow2--up);
}.icon-arrow3--down-rit-dark {
	@include icon(arrow3--down-rit-dark);
}.icon-arrow3--down-white {
	@include icon(arrow3--down-white);
}.icon-arrow3--down {
	@include icon(arrow3--down);
}.icon-arrow3--left {
	@include icon(arrow3--left);
}.icon-arrow3--right {
	@include icon(arrow3--right);
}.icon-arrow3--up {
	@include icon(arrow3--up);
}.icon-arrow4--down {
	@include icon(arrow4--down);
}.icon-arrow4--left {
	@include icon(arrow4--left);
}.icon-arrow4--right {
	@include icon(arrow4--right);
}.icon-arrow4--up {
	@include icon(arrow4--up);
}.icon-arrow5--left {
	@include icon(arrow5--left);
}.icon-arrow5--up {
	@include icon(arrow5--up);
}.icon-bell {
	@include icon(bell);
}.icon-box {
	@include icon(box);
}.icon-bring-delivery {
	@include icon(bring-delivery);
}.icon-business-related-questions {
	@include icon(business-related-questions);
}.icon-call {
	@include icon(call);
}.icon-candle {
	@include icon(candle);
}.icon-card-payment {
	@include icon(card-payment);
}.icon-cart--plus {
	@include icon(cart--plus);
}.icon-cart-basket {
	@include icon(cart-basket);
}.icon-cart-plus {
	@include icon(cart-plus);
}.icon-cart {
	@include icon(cart);
}.icon-check-thin {
	@include icon(check-thin);
}.icon-check {
	@include icon(check);
}.icon-circle-exclamation {
	@include icon(circle-exclamation);
}.icon-circle-info {
	@include icon(circle-info);
}.icon-cities {
	@include icon(cities);
}.icon-clean-safe {
	@include icon(clean-safe);
}.icon-click-and-collect {
	@include icon(click-and-collect);
}.icon-close-thick {
	@include icon(close-thick);
}.icon-close {
	@include icon(close);
}.icon-close2 {
	@include icon(close2);
}.icon-cloud {
	@include icon(cloud);
}.icon-credit-card-payment {
	@include icon(credit-card-payment);
}.icon-cruelty-free {
	@include icon(cruelty-free);
}.icon-cs-info-icon {
	@include icon(cs-info-icon);
}.icon-current-location-outline {
	@include icon(current-location-outline);
}.icon-current-location {
	@include icon(current-location);
}.icon-data {
	@include icon(data);
}.icon-deliveries {
	@include icon(deliveries);
}.icon-delivery-day {
	@include icon(delivery-day);
}.icon-delivery-fast {
	@include icon(delivery-fast);
}.icon-delivery-time {
	@include icon(delivery-time);
}.icon-delivery-truck-full {
	@include icon(delivery-truck-full);
}.icon-delivery {
	@include icon(delivery);
}.icon-dots {
	@include icon(dots);
}.icon-download-report {
	@include icon(download-report);
}.icon-dropdown {
	@include icon(dropdown);
}.icon-duration {
	@include icon(duration);
}.icon-earthweek-logo {
	@include icon(earthweek-logo);
}.icon-easter-bow {
	@include icon(easter-bow);
}.icon-edp-tool {
	@include icon(edp-tool);
}.icon-email-circle {
	@include icon(email-circle);
}.icon-email {
	@include icon(email);
}.icon-env-leaf {
	@include icon(env-leaf);
}.icon-env-waste {
	@include icon(env-waste);
}.icon-env-water {
	@include icon(env-water);
}.icon-exclusive-partnership {
	@include icon(exclusive-partnership);
}.icon-fabric {
	@include icon(fabric);
}.icon-favourite {
	@include icon(favourite);
}.icon-filter-icon {
	@include icon(filter-icon);
}.icon-foam {
	@include icon(foam);
}.icon-gift-filled {
	@include icon(gift-filled);
}.icon-gift {
	@include icon(gift);
}.icon-giftcard-promotions {
	@include icon(giftcard-promotions);
}.icon-giftcard {
	@include icon(giftcard);
}.icon-giving-back {
	@include icon(giving-back);
}.icon-hair-test {
	@include icon(hair-test);
}.icon-hand-gift {
	@include icon(hand-gift);
}.icon-hand-heart {
	@include icon(hand-heart);
}.icon-heart {
	@include icon(heart);
}.icon-home {
	@include icon(home);
}.icon-hor-bottle {
	@include icon(hor-bottle);
}.icon-hor-candleholder {
	@include icon(hor-candleholder);
}.icon-hor-fragrance {
	@include icon(hor-fragrance);
}.icon-hor-sticks {
	@include icon(hor-sticks);
}.icon-hor-wax {
	@include icon(hor-wax);
}.icon-hydra-boost-complex-logo-white {
	@include icon(hydra-boost-complex-logo-white);
}.icon-hydra-boost-icon {
	@include icon(hydra-boost-icon);
}.icon-ico-clock {
	@include icon(ico-clock);
}.icon-icon_system_min_circle {
	@include icon(icon_system_min_circle);
}.icon-icon_system_plus_circle {
	@include icon(icon_system_plus_circle);
}.icon-key-skeleton-thin {
	@include icon(key-skeleton-thin);
}.icon-key {
	@include icon(key);
}.icon-leaf {
	@include icon(leaf);
}.icon-lightning {
	@include icon(lightning);
}.icon-limiting-waste {
	@include icon(limiting-waste);
}.icon-location-alt {
	@include icon(location-alt);
}.icon-location {
	@include icon(location);
}.icon-magazine {
	@include icon(magazine);
}.icon-magnifying-glass {
	@include icon(magnifying-glass);
}.icon-mail {
	@include icon(mail);
}.icon-man {
	@include icon(man);
}.icon-menu {
	@include icon(menu);
}.icon-menu2 {
	@include icon(menu2);
}.icon-minus {
	@include icon(minus);
}.icon-minus2 {
	@include icon(minus2);
}.icon-minus3 {
	@include icon(minus3);
}.icon-minus3invert {
	@include icon(minus3invert);
}.icon-my-account {
	@include icon(my-account);
}.icon-my-order {
	@include icon(my-order);
}.icon-my-rituals-dark {
	@include icon(my-rituals-dark);
}.icon-natural-origin-90 {
	@include icon(natural-origin-90);
}.icon-natural-origin-95-fr {
	@include icon(natural-origin-95-fr);
}.icon-natural-origin-95 {
	@include icon(natural-origin-95);
}.icon-natural-origin-97 {
	@include icon(natural-origin-97);
}.icon-newsletter {
	@include icon(newsletter);
}.icon-online-advice {
	@include icon(online-advice);
}.icon-online {
	@include icon(online);
}.icon-our-stores {
	@include icon(our-stores);
}.icon-package {
	@include icon(package);
}.icon-password-not-visible {
	@include icon(password-not-visible);
}.icon-password-visible {
	@include icon(password-visible);
}.icon-pause-large {
	@include icon(pause-large);
}.icon-pause {
	@include icon(pause);
}.icon-payment {
	@include icon(payment);
}.icon-pencil {
	@include icon(pencil);
}.icon-perfume-genie-bottle {
	@include icon(perfume-genie-bottle);
}.icon-perfume-genie {
	@include icon(perfume-genie);
}.icon-plant-a-tree {
	@include icon(plant-a-tree);
}.icon-play-advent {
	@include icon(play-advent);
}.icon-play-large {
	@include icon(play-large);
}.icon-play {
	@include icon(play);
}.icon-plus {
	@include icon(plus);
}.icon-plus2 {
	@include icon(plus2);
}.icon-plus3 {
	@include icon(plus3);
}.icon-plus3invert {
	@include icon(plus3invert);
}.icon-powerrechargeformula {
	@include icon(powerrechargeformula);
}.icon-product-info {
	@include icon(product-info);
}.icon-product-quality-feather {
	@include icon(product-quality-feather);
}.icon-product-quality-lotus {
	@include icon(product-quality-lotus);
}.icon-product-quality-sparkle {
	@include icon(product-quality-sparkle);
}.icon-product-quality-sun {
	@include icon(product-quality-sun);
}.icon-product-quality-water-drop {
	@include icon(product-quality-water-drop);
}.icon-product-quality-waves {
	@include icon(product-quality-waves);
}.icon-rating {
	@include icon(rating);
}.icon-refill-tree-counter {
	@include icon(refill-tree-counter);
}.icon-refill-trees-protected {
	@include icon(refill-trees-protected);
}.icon-restart {
	@include icon(restart);
}.icon-returns {
	@include icon(returns);
}.icon-ritual-quality {
	@include icon(ritual-quality);
}.icon-rituals-easter-egg {
	@include icon(rituals-easter-egg);
}.icon-rituals-logo {
	@include icon(rituals-logo);
}.icon-ruler {
	@include icon(ruler);
}.icon-sale {
	@include icon(sale);
}.icon-scan {
	@include icon(scan);
}.icon-search-dotless {
	@include icon(search-dotless);
}.icon-search-magnifier {
	@include icon(search-magnifier);
}.icon-search {
	@include icon(search);
}.icon-secure-dotless {
	@include icon(secure-dotless);
}.icon-secure {
	@include icon(secure);
}.icon-send {
	@include icon(send);
}.icon-service {
	@include icon(service);
}.icon-share {
	@include icon(share);
}.icon-shop {
	@include icon(shop);
}.icon-shopping {
	@include icon(shopping);
}.icon-size {
	@include icon(size);
}.icon-skincare-test {
	@include icon(skincare-test);
}.icon-social--facebook-large {
	@include icon(social--facebook-large);
}.icon-social--facebook {
	@include icon(social--facebook);
}.icon-social--facebook2 {
	@include icon(social--facebook2);
}.icon-social--fbmessenger {
	@include icon(social--fbmessenger);
}.icon-social--google {
	@include icon(social--google);
}.icon-social--instagram-large {
	@include icon(social--instagram-large);
}.icon-social--instagram {
	@include icon(social--instagram);
}.icon-social--instagram2 {
	@include icon(social--instagram2);
}.icon-social--linkedin-large {
	@include icon(social--linkedin-large);
}.icon-social--messenger {
	@include icon(social--messenger);
}.icon-social--pinterest-large {
	@include icon(social--pinterest-large);
}.icon-social--pinterest {
	@include icon(social--pinterest);
}.icon-social--pinterest2 {
	@include icon(social--pinterest2);
}.icon-social--snapchat-large {
	@include icon(social--snapchat-large);
}.icon-social--tiktok-large {
	@include icon(social--tiktok-large);
}.icon-social--twitter-large {
	@include icon(social--twitter-large);
}.icon-social--twitter {
	@include icon(social--twitter);
}.icon-social--twitter2 {
	@include icon(social--twitter2);
}.icon-social--whatsapp-filled {
	@include icon(social--whatsapp-filled);
}.icon-social--whatsapp {
	@include icon(social--whatsapp);
}.icon-social--youtube-large {
	@include icon(social--youtube-large);
}.icon-social--youtube {
	@include icon(social--youtube);
}.icon-social--youtube2 {
	@include icon(social--youtube2);
}.icon-sos {
	@include icon(sos);
}.icon-star {
	@include icon(star);
}.icon-store {
	@include icon(store);
}.icon-super-soft {
	@include icon(super-soft);
}.icon-supplements {
	@include icon(supplements);
}.icon-sustainability-report {
	@include icon(sustainability-report);
}.icon-sustainability {
	@include icon(sustainability);
}.icon-system-danger {
	@include icon(system-danger);
}.icon-system-genie-larger-outline-dark {
	@include icon(system-genie-larger-outline-dark);
}.icon-system-lock {
	@include icon(system-lock);
}.icon-talisman-bottle {
	@include icon(talisman-bottle);
}.icon-talisman-cap {
	@include icon(talisman-cap);
}.icon-talisman-talisman {
	@include icon(talisman-talisman);
}.icon-tao-logo {
	@include icon(tao-logo);
}.icon-thread-count {
	@include icon(thread-count);
}.icon-thread {
	@include icon(thread);
}.icon-timer {
	@include icon(timer);
}.icon-tranquility {
	@include icon(tranquility);
}.icon-user {
	@include icon(user);
}.icon-vegan {
	@include icon(vegan);
}.icon-video {
	@include icon(video);
}.icon-view--list {
	@include icon(view--list);
}.icon-view--tiles {
	@include icon(view--tiles);
}.icon-waste {
	@include icon(waste);
}.icon-water-and-drop {
	@include icon(water-and-drop);
}.icon-water-drop {
	@include icon(water-drop);
}.icon-water {
	@include icon(water);
}.icon-weight {
	@include icon(weight);
}.icon-well-being {
	@include icon(well-being);
}.icon-woman {
	@include icon(woman);
}.icon-yoga-fill {
	@include icon(yoga-fill);
}

// means you can also call it separatly like this:

// .someclass {@include iconfont(basket, after, false);}
// .someclass:after {
//  content: 'character';
//  all styles included in stead of extended
// }

//Variable for each font icon
$icon-AR: "\EA01";
$icon-account: "\EA02";
$icon-activated-charcoal: "\EA03";
$icon-advent-calendar: "\EA04";
$icon-advent-key: "\EA05";
$icon-advent-tree: "\EA06";
$icon-ar-button: "\EA07";
$icon-arrow--back: "\EA08";
$icon-arrow--copper: "\EA09";
$icon-arrow--down: "\EA0A";
$icon-arrow--left: "\EA0B";
$icon-arrow--link: "\EA0C";
$icon-arrow--right: "\EA0D";
$icon-arrow--up: "\EA0E";
$icon-arrow-back: "\EA0F";
$icon-arrow-diap: "\EA10";
$icon-arrow-track-and-trace: "\EA11";
$icon-arrow-wider-down: "\EA12";
$icon-arrow2--down: "\EA13";
$icon-arrow2--left: "\EA14";
$icon-arrow2--right: "\EA15";
$icon-arrow2--up: "\EA16";
$icon-arrow3--down-rit-dark: "\EA17";
$icon-arrow3--down-white: "\EA18";
$icon-arrow3--down: "\EA19";
$icon-arrow3--left: "\EA1A";
$icon-arrow3--right: "\EA1B";
$icon-arrow3--up: "\EA1C";
$icon-arrow4--down: "\EA1D";
$icon-arrow4--left: "\EA1E";
$icon-arrow4--right: "\EA1F";
$icon-arrow4--up: "\EA20";
$icon-arrow5--left: "\EA21";
$icon-arrow5--up: "\EA22";
$icon-bell: "\EA23";
$icon-box: "\EA24";
$icon-bring-delivery: "\EA25";
$icon-business-related-questions: "\EA26";
$icon-call: "\EA27";
$icon-candle: "\EA28";
$icon-card-payment: "\EA29";
$icon-cart--plus: "\EA2A";
$icon-cart-basket: "\EA2B";
$icon-cart-plus: "\EA2C";
$icon-cart: "\EA2D";
$icon-check-thin: "\EA2E";
$icon-check: "\EA2F";
$icon-circle-exclamation: "\EA30";
$icon-circle-info: "\EA31";
$icon-cities: "\EA32";
$icon-clean-safe: "\EA33";
$icon-click-and-collect: "\EA34";
$icon-close-thick: "\EA35";
$icon-close: "\EA36";
$icon-close2: "\EA37";
$icon-cloud: "\EA38";
$icon-credit-card-payment: "\EA39";
$icon-cruelty-free: "\EA3A";
$icon-cs-info-icon: "\EA3B";
$icon-current-location-outline: "\EA3C";
$icon-current-location: "\EA3D";
$icon-data: "\EA3E";
$icon-deliveries: "\EA3F";
$icon-delivery-day: "\EA40";
$icon-delivery-fast: "\EA41";
$icon-delivery-time: "\EA42";
$icon-delivery-truck-full: "\EA43";
$icon-delivery: "\EA44";
$icon-dots: "\EA45";
$icon-download-report: "\EA46";
$icon-dropdown: "\EA47";
$icon-duration: "\EA48";
$icon-earthweek-logo: "\EA49";
$icon-easter-bow: "\EA4A";
$icon-edp-tool: "\EA4B";
$icon-email-circle: "\EA4C";
$icon-email: "\EA4D";
$icon-env-leaf: "\EA4E";
$icon-env-waste: "\EA4F";
$icon-env-water: "\EA50";
$icon-exclusive-partnership: "\EA51";
$icon-fabric: "\EA52";
$icon-favourite: "\EA53";
$icon-filter-icon: "\EA54";
$icon-foam: "\EA55";
$icon-gift-filled: "\EA56";
$icon-gift: "\EA57";
$icon-giftcard-promotions: "\EA58";
$icon-giftcard: "\EA59";
$icon-giving-back: "\EA5A";
$icon-hair-test: "\EA5B";
$icon-hand-gift: "\EA5C";
$icon-hand-heart: "\EA5D";
$icon-heart: "\EA5E";
$icon-home: "\EA5F";
$icon-hor-bottle: "\EA60";
$icon-hor-candleholder: "\EA61";
$icon-hor-fragrance: "\EA62";
$icon-hor-sticks: "\EA63";
$icon-hor-wax: "\EA64";
$icon-hydra-boost-complex-logo-white: "\EA65";
$icon-hydra-boost-icon: "\EA66";
$icon-ico-clock: "\EA67";
$icon-icon_system_min_circle: "\EA68";
$icon-icon_system_plus_circle: "\EA69";
$icon-key-skeleton-thin: "\EA6A";
$icon-key: "\EA6B";
$icon-leaf: "\EA6C";
$icon-lightning: "\EA6D";
$icon-limiting-waste: "\EA6E";
$icon-location-alt: "\EA6F";
$icon-location: "\EA70";
$icon-magazine: "\EA71";
$icon-magnifying-glass: "\EA72";
$icon-mail: "\EA73";
$icon-man: "\EA74";
$icon-menu: "\EA75";
$icon-menu2: "\EA76";
$icon-minus: "\EA77";
$icon-minus2: "\EA78";
$icon-minus3: "\EA79";
$icon-minus3invert: "\EA7A";
$icon-my-account: "\EA7B";
$icon-my-order: "\EA7C";
$icon-my-rituals-dark: "\EA7D";
$icon-natural-origin-90: "\EA7E";
$icon-natural-origin-95-fr: "\EA7F";
$icon-natural-origin-95: "\EA80";
$icon-natural-origin-97: "\EA81";
$icon-newsletter: "\EA82";
$icon-online-advice: "\EA83";
$icon-online: "\EA84";
$icon-our-stores: "\EA85";
$icon-package: "\EA86";
$icon-password-not-visible: "\EA87";
$icon-password-visible: "\EA88";
$icon-pause-large: "\EA89";
$icon-pause: "\EA8A";
$icon-payment: "\EA8B";
$icon-pencil: "\EA8C";
$icon-perfume-genie-bottle: "\EA8D";
$icon-perfume-genie: "\EA8E";
$icon-plant-a-tree: "\EA8F";
$icon-play-advent: "\EA90";
$icon-play-large: "\EA91";
$icon-play: "\EA92";
$icon-plus: "\EA93";
$icon-plus2: "\EA94";
$icon-plus3: "\EA95";
$icon-plus3invert: "\EA96";
$icon-powerrechargeformula: "\EA97";
$icon-product-info: "\EA98";
$icon-product-quality-feather: "\EA99";
$icon-product-quality-lotus: "\EA9A";
$icon-product-quality-sparkle: "\EA9B";
$icon-product-quality-sun: "\EA9C";
$icon-product-quality-water-drop: "\EA9D";
$icon-product-quality-waves: "\EA9E";
$icon-rating: "\EA9F";
$icon-refill-tree-counter: "\EAA0";
$icon-refill-trees-protected: "\EAA1";
$icon-restart: "\EAA2";
$icon-returns: "\EAA3";
$icon-ritual-quality: "\EAA4";
$icon-rituals-easter-egg: "\EAA5";
$icon-rituals-logo: "\EAA6";
$icon-ruler: "\EAA7";
$icon-sale: "\EAA8";
$icon-scan: "\EAA9";
$icon-search-dotless: "\EAAA";
$icon-search-magnifier: "\EAAB";
$icon-search: "\EAAC";
$icon-secure-dotless: "\EAAD";
$icon-secure: "\EAAE";
$icon-send: "\EAAF";
$icon-service: "\EAB0";
$icon-share: "\EAB1";
$icon-shop: "\EAB2";
$icon-shopping: "\EAB3";
$icon-size: "\EAB4";
$icon-skincare-test: "\EAB5";
$icon-social--facebook-large: "\EAB6";
$icon-social--facebook: "\EAB7";
$icon-social--facebook2: "\EAB8";
$icon-social--fbmessenger: "\EAB9";
$icon-social--google: "\EABA";
$icon-social--instagram-large: "\EABB";
$icon-social--instagram: "\EABC";
$icon-social--instagram2: "\EABD";
$icon-social--linkedin-large: "\EABE";
$icon-social--messenger: "\EABF";
$icon-social--pinterest-large: "\EAC0";
$icon-social--pinterest: "\EAC1";
$icon-social--pinterest2: "\EAC2";
$icon-social--snapchat-large: "\EAC3";
$icon-social--tiktok-large: "\EAC4";
$icon-social--twitter-large: "\EAC5";
$icon-social--twitter: "\EAC6";
$icon-social--twitter2: "\EAC7";
$icon-social--whatsapp-filled: "\EAC8";
$icon-social--whatsapp: "\EAC9";
$icon-social--youtube-large: "\EACA";
$icon-social--youtube: "\EACB";
$icon-social--youtube2: "\EACC";
$icon-sos: "\EACD";
$icon-star: "\EACE";
$icon-store: "\EACF";
$icon-super-soft: "\EAD0";
$icon-supplements: "\EAD1";
$icon-sustainability-report: "\EAD2";
$icon-sustainability: "\EAD3";
$icon-system-danger: "\EAD4";
$icon-system-genie-larger-outline-dark: "\EAD5";
$icon-system-lock: "\EAD6";
$icon-talisman-bottle: "\EAD7";
$icon-talisman-cap: "\EAD8";
$icon-talisman-talisman: "\EAD9";
$icon-tao-logo: "\EADA";
$icon-thread-count: "\EADB";
$icon-thread: "\EADC";
$icon-timer: "\EADD";
$icon-tranquility: "\EADE";
$icon-user: "\EADF";
$icon-vegan: "\EAE0";
$icon-video: "\EAE1";
$icon-view--list: "\EAE2";
$icon-view--tiles: "\EAE3";
$icon-waste: "\EAE4";
$icon-water-and-drop: "\EAE5";
$icon-water-drop: "\EAE6";
$icon-water: "\EAE7";
$icon-weight: "\EAE8";
$icon-well-being: "\EAE9";
$icon-woman: "\EAEA";
$icon-yoga-fill: "\EAEB";
