// custom select
.custom-select {
  background: transparent url('../images/icons/arrow3--down.svg') no-repeat right center;
  background-size: 18px 20px;
  border: 0;
  color: $rit-black;
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 5px 17px 5px 0;
  text-align: left;
  text-align-last: left;
  text-transform: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &.language {
    background: none;
  }
}

.selectric-wrapper {
  position: relative;
  z-index: 15;

  .selectric-hide-select {
    height: 0;
    overflow: hidden;
    position: relative;
    width: 0;
  }

  .selectric {
    background-color: $white;
    border-radius: 2px;
    border: 1px solid $gray-gainsboro;
    cursor: pointer;
    padding: 11px 21px 7px 10px;
    position: relative;
    text-align: left;

    .label {
      color: $rit-dark;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      overflow: hidden;
      padding-left: 10px;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
      width: 100%;
    }

    .button {
      font-size: 0;
      position: absolute;
      right: -1px;
      top: 9px;

      @include tablet {
        right: 19px;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        right: 0;
        transform: rotateZ(45deg);
        transition: top $quaternary-duration $default-ease-out;
        width: 6px;
      }

      &::before {
        border-left: 1px solid $rit-dark;
        border-top: 1px solid $rit-dark;
        top: 9px;
      }

      &::after {
        border-bottom: 1px solid $rit-dark;
        border-right: 1px solid $rit-dark;
        top: 14px;
      }
    }
  }

  .selectric-items {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    display: none;
    left: 0;
    position: absolute;
    top: calc(100% - 2px);
    width: 100%;

    .selectric-scroll {
      height: 100%;
      overflow: auto;
      border: 1px solid $gray-gainsboro;
    }

    ul {
      background-color: $white;
      list-style: none;
      padding: 0;

      li {
        color: $rit-black;
        cursor: pointer;
        font-size: 14px;
        line-height: 17px;
        padding: 17px 20px;

        &:hover,
        &.selected {
          background-color: rgba($gray-sand, 0.6);
        }

        &.disabled {
          display: none;
        }
      }
    }
  }

  .selectric-input {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    outline: none;
    border: 0;
    background: none;
  }

  &.selectric-open {
    z-index: 16;

    .selectric .button {
      &::before {
        top: 16px;
      }

      &::after {
        top: 7px;
      }
    }

    .selectric-items {
      display: block;
    }
  }

  .error ~ .selectric {
    border: 1px solid $red-error;
  }
}
