
//page layout for login and reset password pages
.page-layout-left {
  position: relative;
  margin-bottom: 30px;
  text-align: center;

  @include desktop {
    width: calc(100% - 360px);
    padding-right: 22px;
    margin-bottom: 0;
  }

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    @include tablet {
      padding-right: 40px;
      text-align: left;
      width: 50%;
    }
  }

  .page-layout-left-title {
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
    max-width: 250px;
    margin: -16px auto 9px;
    text-transform: none;

    @include tablet {
      font-size: 30px;
      letter-spacing: 0.6px;
      line-height: 40px;
      margin: 18px auto 13px;
      max-width: 480px;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      display: inline-block;
      text-align: left;
      vertical-align: middle;

      @include tablet {
        font-size: 32px;
        letter-spacing: 1.4px;
        width: calc(100% - 112px);
      }
    }
  }

  .page-layout-left-subtitle {
    color: $gray-medium;
    max-width: 285px;
    margin: 0 auto;
    letter-spacing: 0.5px;
    line-height: 22px;

    @include mobile-only {
      margin: 0;
      max-width: none;
    }

    @include tablet {
      max-width: none;
      letter-spacing: 1px;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      color: $rit-black;
      font-family: $font-header;
      margin-bottom: 24px;
      text-align: left;

      @include mobile-only {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 32px;
        margin-top: 24px;
        padding: 0 24px;
      }

      @include tablet {
        font-size: 32px;
        height: 121px;
        letter-spacing: 1.4px;
        line-height: 40px;
        margin-top: 44px;
        overflow: hidden;
      }
    }
  }

  .no-link-top-margin {
    margin-top: 35px;
  }

  .page-layout-left-icon {
    margin-top: -17px;

    @include tablet {
      margin-top: 28px;
    }

    & + .page-layout-left-title {
      margin: 21px auto 9px;

      @include tablet {
        margin: 8px auto 14px;
      }

      // Checkout V3 Overrides
      .login-checkout-v3 & {
        display: none;
      }
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      display: none;
    }
  }

  .page-layout-left-back-button {
    position: absolute;
    top: -48px;
    left: -9px;

    @include tablet {
      top: -21px;
      left: -6px;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include mobile-only {
        left: 24px;
      }
    }
  }
}

.page-layout-right {
  @include tablet {
    width: 100%;
    max-width: 320px;
  }

  @include desktop {
    max-width: 360px;
  }

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    @include tablet {
      max-width: none;
      width: 50%;
    }
  }
}

//account benefits content asset
.account-benefits {
  background-color: $white;

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    background-color: transparent;
  }
}

.benefits-wrapper {
  padding: 21px 0 19px;
  text-align: center;
  width: 100%;

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    @include mobile-only {
      padding: 0 24px;
      text-align: left;
    }

    @include tablet {
      padding: 188px 0 0 0;
      text-align: left;
    }

    // Hiding it because of new layout
    // https://rituals.atlassian.net/browse/DL-24733
    & > .benefits-dynamic-block {
      display: none;
    }
  }

  // Checkout V3 Overrides
  .login-checkout-v3 .page-layout-right.has-title & {
    @include tablet {
      padding-top: 260px;
    }
  }

  .content-asset {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      background-color: $white;

      @include mobile-only {
        padding: 40px 24px;
      }

      @include tablet {
        padding: 40px;
      }
    }
  }
}

.benefits-subtitle {
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  color: $rit-copper-dark;
  text-transform: uppercase;
  margin-bottom: 19px;
  display: block;
  text-align: center;

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 1.7px;
    line-height: 16px;
    text-align: left;
  }
}

.benefits-title {
  color: $rit-black;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0 auto 19px;
  max-width: 205px;
  text-transform: none;

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    @include mobile-only {
      font-size: 20px;
      letter-spacing: 0.8px;
      line-height: 24px;
      max-width: none;
    }

    @include tablet {
      font-size: 32px;
      letter-spacing: 1.4px;
      line-height: 40px;
      max-width: none;
    }
  }
}

.benefits-list {
  list-style: none;
  text-align: left;
  max-width: 287px;
  margin: 0 auto;

  & + .btn {
    margin-top: 16px;
  }

  // Checkout V3 Overrides
  .checkout-v3 &,
  .login-checkout-v3 & {
    @include mobile-only {
      padding: 0 20px;
    }
  }

  .login-checkout-v3 & {
    max-width: none;
  }

  li {
    margin-bottom: -1px;

    @include tablet {
      margin-bottom: -3px;
    }

    // Checkout V3 Overrides
    .checkout-v3 &,
    .login-checkout-v3 & {
      @include mobile-only {
        margin-bottom: 8px;
      }

      @include tablet {
        margin-bottom: 8px;
      }
    }

    span {
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 25px;
      color: $gray-dark;
      width: calc(100% - 40px);
      display: inline-block;

      // Checkout V3 Overrides
      .login-checkout-v3 & {
        color: $rit-black;
      }
    }

    .icon-check {
      font-size: 11px;
      color: $rit-black;
      margin-right: 9px;
      vertical-align: top;
      display: inline-block;
      width: 15px;

      // Checkout V3 Overrides
      .checkout-v3 &,
      .login-checkout-v3 & {
        color: $green;
      }
    }
  }
}

.benefits-dynamic-block {
  display: flex;
  padding: 0 16px 0 26px;
  margin-top: 27px;

  @include tablet {
    padding: 0 30px 0 37px;
  }

  .flyout-scroll-progress {
    display: none;
  }

  .image-holder {
    img {
      display: block;
      max-width: 70px;
    }
  }

  .text-holder {
    display: flex;
    align-items: center;

    p {
      font-family: $font-header;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: left;
      padding-left: 10px;
      margin: 0;
    }
  }
}

// Benefits Progress Overrides
.benefits-progress {
  position: relative;

  &.product-gift-ribbon::after {
    opacity: 1;
    top: -40px;

    @include mobile-only {
      right: -24px;
    }

    @include tablet {
      right: -40px;
    }
  }

  .benefits-dynamic-block {
    border: 1px solid $gray-gainsboro;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 24px;
    max-width: 560px;
    padding: 16px;

    .image-holder {
      img {
        max-width: 56px;
      }
    }

    .text-holder {
      display: block;
      margin-right: 24px;
      padding-bottom: 16px;
      position: relative;

      p {
        color: $rit-black;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        padding: 0;
      }
    }

    .flyout-scroll-progress {
      bottom: 0;
      display: block;
      left: 0;
      width: 100%;
    }
  }

  h4 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    text-transform: none;
  }
}

//login and checkout login pages
.login-wrapper {
  padding: 57px 0 80px;

  @include tablet {
    padding: 61px 0 160px;
  }

  // Checkout V3 Overrides
  .login-checkout-v3 & {
    @include tablet {
      padding: 40px 0 160px;
    }
  }

  &.outlet {
    .login-panel__form {
      margin-top: -35px;

      @include tablet {
        margin-top: -19px;
      }
    }
  }

  .inner-wrapper {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include mobile-only {
        padding: 0;
      }
    }
  }

  .inner-wrapper-small {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include tablet {
        max-width: none;
        padding: 0 30px;
      }
    }
  }

  .login-panel__form {
    margin-top: 35px;

    @include tablet {
      max-width: 600px;
      margin: 44px auto 0;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      @include tablet {
        margin: 0;
        max-width: none;
      }
    }

    .full-width-row + .full-width-row {
      // Checkout V3 Overrides
      .login-checkout-v3 & {
        margin-top: 32px;
      }
    }

    .form-row-button:not(.full-width-row) {
      @include tablet {
        // Checkout V3 Overrides
        .login-checkout-v3 & {
          margin-top: 40px;
        }
      }
    }
  }

  .rituals-form {
    // Checkout V3 Overrides
    .login-checkout-v3 & {
      background-color: $white;
      margin: 0;
      max-width: none;

      @include mobile-only {
        padding: 24px;
      }

      @include tablet {
        padding: 40px;
      }

      .btn {
        max-width: none;
        width: 100%;
      }
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $gray-gainsboro;
    padding: 0;
    margin: 28px 0 31px;

    @include tablet {
      margin: 41px 0 36px;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      background-color: $white;
      border: 0;
      position: relative;

      @include mobile-only {
        margin: -24px 0 0 0;
        padding: 28px 40px;
      }

      @include tablet {
        margin: -40px 0 0 0;
        padding: 38px 40px;
      }

      &::before {
        background-image: linear-gradient(90deg,
          rgba($rit-black, 0) 0%,
          rgba($rit-black, 0.4) 50%,
          rgba($rit-black, 0) 100%);
        content: '';
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: calc(100% - 80px);
        z-index: 1;

        @include mobile-only {
          top: 28px;
        }

        @include tablet {
          top: 38px;
        }
      }

      &::after {
        background-color: $white;
        content: attr(data-text);
        font-size: 14px;
        left: 50%;
        letter-spacing: 0.4px;
        padding: 0 14px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }
  }

  .account-usp {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 25px;
    margin: 25px auto 0;
    max-width: 285px;

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      background-color: $white;
      margin: 0;
      max-width: none;
      padding: 0 40px 40px 40px;
    }
  }

  .password-reset {
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    float: right;
    padding: 16px 4px 33px 0;

    @include tablet {
      padding-bottom: 20px;
    }

    // Checkout V3 Overrides
    .login-checkout-v3 & {
      float: left;
    }
  }

  .choose-account-left {
    .form-row-button {
      padding: 0 10px;

      // Checkout V3 Overrides
      .login-checkout-v3 & {
        padding: 0;
      }

      .btn {
        width: 100%;

        @include tablet {
          width: auto;

          // Checkout V3 Overrides
          .login-checkout-v3 & {
            width: 100%;
          }
        }

        // Checkout V3 Overrides
        .login-checkout-v3 & {
          max-width: none;
        }
      }
    }
  }
}

//overlay login
.login-overlay {
  background-color: $rit-sandy-light;
  bottom: -100%;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  visibility: visible;
  width: 100%;
  z-index: $modal-overlay-index - 11; //let header to cover it
  -webkit-overflow-scrolling: touch;

  &.overlay-visible {
    opacity: 1;
    visibility: visible;
  }

  @include mobile-only {
    .checkout-register-wrapper {
      padding: 117px 20px 30px;
    }

    .login-wrapper {
      padding: 117px 0 30px;
    }
  }
}

// Checkout V3 Overrides (Checkout-Login page only)
.pt_cart_login.login-checkout-v3 .page-layout-left:not(.choose-account-left) {
  .page-layout-left-title {
    display: block;

    @include mobile-only {
      font-size: 32px;
      letter-spacing: 1.2px;
      line-height: 40px;
      max-width: none;
      width: calc(100% - 48px);
    }

    @include tablet {
      font-size: 40px;
      letter-spacing: 1.8px;
      line-height: 48px;
      width: 100%;
    }
  }

  .page-layout-left-icon + .page-layout-left-title {
    @include mobile-only {
      margin: 24px;
    }

    @include tablet {
      margin: 40px 0;
    }
  }

  .page-layout-left-icon {
    display: none;
  }

  .benefits-wrapper {
    @include tablet {
      padding-top: 178px;
    }
  }
}
