.checkout-inner {
  .checkout-header {
    border-bottom: 1px solid $gray-lines;
    display: none;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 0;
    padding-bottom: 15px;
    text-align: left;
    text-transform: none;

    &.b2b-checkout-header {
      display: block;
      margin-top: 60px;
    }
  }

  .checkout-content.open {
    .checkout-header.step-3 {
      display: block;
    }
  }

  @include tablet {
    .checkout-header {
      border-bottom: 1px solid $gray-lines;
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 24px;
      padding-bottom: 14px;
      text-transform: none;
    }

    .checkout-content {
      &.checkout-step-1 .checkout-header.step-1 {
        display: block;
        margin-bottom: 19px;
      }
    }
  }
}

.send-icon-visible {
  background-color: $rit-black;
  color: $white;
  display: inline-block;
  position: relative;
  z-index: 10;

  &::before {
    @include icon-styles;
    content: icon-char(check);
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mobile-only {
      font-size: 10px;
    }
  }
}

.send-checkout-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 610px;
  margin: 0 auto;

  .btn:only-child {
    margin: 0 auto;
  }

  .btn-send-check-link {
    margin-top: 10px;
    margin-left: 20px;
  }

  @include mobile-only {
    max-width: 100%;
    flex-direction: column;
    margin: 0 10px;

    .btn-send-check-link {
      margin-left: 0;
    }

    .btn {
      min-width: 100%;
    }
  }

  @include tablet {
    .btn {
      flex-basis: 280px;
    }
  }
}

.order-component-block {
  display: none;
  padding: 29px 39px 0;
  width: 100%;

  .section-header {
    margin-bottom: 20px;
    padding-bottom: 11px;
  }

  .minishipments-method {
    margin-top: 22px;
  }

  @include tablet {
    display: block;
  }
}

//new styles for redesign checkout
.checkout-holder {
  display: flex;
  padding: 22px 0 40px;
  width: 100%;

  .btn-loader {
    min-height: 10px;
  }

  .cs-service-block {
    margin-top: -20px;

    .customer-service-block {
      display: none;
      margin-top: 0;
      padding-bottom: 0;

      @include desktop {
        display: block;
      }

      a {
        text-decoration: none;
        pointer-events: none;
      }

      .text-wrapper {
        text-align: center;
      }
    }
  }

  @include mobile-only {
    .page-layout-left {
      width: 100%;
      margin-bottom: 60px;
    }
  }

  @include tablet {
    padding: 80px 0;

    .btn-submit {
      margin-top: 10px;
    }

    .page-layout-left {
      margin-bottom: 50px;
    }
  }
}

.back-button-wrapper {
  width: 100%;
  margin: -7px auto 15px;
  max-width: 620px;
  text-align: left;

  @include tablet {
    margin: -24px auto 35px;
  }

  .back-button {
    margin-left: -13px;

    @include tablet {
      margin-left: -8px;
    }
  }
}

.checkout-step-buttons-holder {
  display: flex;
  justify-content: center;
  margin-bottom: 53px;
  margin-top: 32px;
  width: 100%;

  @include tablet {
    margin: 4px 0 60px;
  }
}

.checkout-steps-buttons-list {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 280px;

  &::before {
    background-color: rgba($white, .1);
    border-radius: 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    content: '';
    display: inline-block;
    height: 30px;
    left: 34px;
    position: absolute;
    right: 34px;
    top: 0;
  }

  .checkout-step-btn {
    position: relative;
    width: 80px;

    &:not(.selected):not(.disabled) .circle {
      background-color: $white;
      font-size: 0;

      &::before {
        @include icon-styles;
        color: #705849;
        content: icon-char(check-thin);
        font-size: 10px;
        left: 2px;
        position: relative;
        top: -1px;
      }
    }

    &:first-child .background-line {
      left: 34px;
    }

    &:last-child .background-line {
      right: 34px;
    }
  }

  .background-line {
    background-color: $white;
    content: '';
    display: inline-block;
    height: 30px;
    left: -11px;
    position: absolute;
    right: 0;
    top: 1px;
    z-index: 1;
  }

  .selected {
    .circle {
      background-color: $rit-copper;
      color: $white;
      font-size: 18px;
      pointer-events: none;
      position: relative;

      &::before {
        background-color: transparent;
        border: 5px solid $rit-copper;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: 34px;
        left: -3px;
        position: absolute;
        top: -2px;
        width: 34px;
      }
    }

    .text {
      color: $rit-copper-dark;
      font-weight: bold;
    }

    .background-line {
      right: 40px;
    }
  }

  .disabled {
    .circle {
      line-height: 30px;
    }

    .background-line {
      display: none;
    }
  }

  .circle {
    background-color: rgba($white, .5);
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: $gray-dark;
    display: inline-block;
    font-family: $font-body;
    height: 30px;
    line-height: 32px;
    margin-bottom: 8px;
    position: relative;
    width: 30px;
    z-index: 2;
  }

  .text {
    color: $gray-dark;
    display: block;
    font-size: 11px;
    letter-spacing: .5px;
    line-height: 14px;
  }

  @include tablet {
    width: 470px;

    &::before {
      left: 55px;
      right: 55px;
    }

    .checkout-step-btn {
      width: 120px;

      &:first-child .background-line {
        left: 60px;
      }

      &:last-child .background-line {
        right: 60px;
      }
    }

    .background-line {
      left: -1px;
      top: 2px;

      @include ie-styles {
        top: 0;
      }
    }

    .cicrle {
      margin-bottom: 12px;
    }

    .selected {
      .background-line {
        right: 50px;
      }

      .circle::before {
        height: 36px;
        width: 36px;
      }
    }
  }
}

.checkout-step-title {
  margin-bottom: 28px;
  position: relative;
  text-align: center;
  width: 100%;

  .number {
    color: $white;
    font-family: $font-header;
    font-size: 130px;
    left: 50%;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -54%);
  }

  .text {
    color: $rit-black;
    display: block;
    font-family: $font-header;
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 28px;
    margin: 0 auto;
    max-width: 200px;
    position: relative;
    text-transform: none;
  }

  @include tablet {
    margin-bottom: 47px;

    .number {
      font-size: 160px;
      transform: translate(-50%, -55%);
    }

    .text {
      font-size: 30px;
      letter-spacing: .6px;
      line-height: 40px;
      max-width: 280px;
    }
  }
}

.checkout-summary-inner {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;

  .section-header {
    border-bottom: 1px solid $gray-gainsboro-light;
    color: $rit-black;
    display: flex;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    margin-bottom: 18px;
    padding-bottom: 22px;
    text-transform: uppercase;
    width: 100%;

    .section-header-note {
      color: $rit-copper-dark;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      margin-left: auto;

      .step3 & {
        display: none;
      }
    }
  }

  .btn-send-check-link {
    margin-top: 20px;
    min-width: 100%;
  }

  @include mobile-only {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}

.checkout-summary-minicart {
  margin-bottom: 23px;
  width: 100%;

  // Checkout V3 overrides
  .checkout-v3 & {
    border-bottom: 1px solid $gray-gainsboro;
    margin-bottom: 0;
  }

  &.section-row-wrapper {
    border-bottom: 0;
  }

  .mini-cart-product {
    border-bottom: 1px solid $white-smoke;
    display: flex;
    padding: 0 0 25px 10px;
    position: relative;
    width: 100%;

    .checkout-section & {
      border-bottom: 0;
      border-top: 1px solid $white-smoke;
      padding: 24px 0 24px 0;
    }

    // Checkout V3 override
    .checkout-v3 & {
      padding: 24px 0 24px 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mini-cart-image {
    flex-shrink: 0;
    padding-top: 4px;
    width: 60px;

    a {
      display: block;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  .mini-cart-info {
    padding-left: 10px;
    width: 100%;

    // Checkout V3 override
    .checkout-v3 & {
      padding-left: 20px;

      @include tablet {
        padding-left: 30px;
      }
    }
  }

  .mini-cart-small-title {
    color: $copper;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;

    // Checkout V3 override
    .checkout-section & {
      padding-right: 90px;
      font-size: 12px;
      letter-spacing: 1.7px;
      line-height: 16px;
      position: relative;
    }
  }

  .mini-cart-product-error {
    width: 100%;

    .remove-product {
      color: $rit-copper-dark;
      cursor: pointer;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: .4px;
      line-height: 24px;
      margin-left: 65px;
      padding-left: 24px;
      position: relative;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      &::after {
        background-size: 100% 100%;
        background: transparent url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiM5QTdCNjk7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik04LDEuM2MzLjcsMCw2LjcsMyw2LjcsNi43cy0zLDYuNy02LjcsNi43cy02LjctMy02LjctNi43UzQuMywxLjMsOCwxLjN6IE04LDIuMUM0LjgsMi4xLDIuMSw0LjgsMi4xLDgNCglzMi42LDUuOSw1LjksNS45czUuOS0yLjYsNS45LTUuOVMxMS4yLDIuMSw4LDIuMXogTTguNCw1LjRsMCwyLjJsMi4yLDB2MC43bC0yLjIsMGwwLDIuMkg3LjZsMC0yLjJsLTIuMiwwVjcuNmwyLjIsMGwwLTIuMkg4LjR6Ii8+DQo8L3N2Zz4NCg==') no-repeat;
        content: '';
        height: 16px;
        left: 4px;
        position: absolute;
        top: 3px;
        transform: rotate(45deg);
        width: 16px;
      }
    }

    .inline-alert {
      margin: 10px 0 0 0;
    }
  }

  .mini-cart-name {
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 22px;
    margin-bottom: 5px;
    padding-right: 90px;

    a {
      color: $rit-black;
      line-height: 22px;
      text-decoration: none;
    }

    // Checkout V3 override
    .checkout-v3 & {
      a {
        font-family: $font-header;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .checkout-section & {
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 24px;
      position: relative;

      a {
        font-family: $font-header;
        line-height: 24px;
      }
    }
  }

  .mini-cart-attributes {
    color: $rit-black;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: .7px;
    line-height: 15px;
    padding-right: 90px;
  }

  .mini-cart-details-bottom {
    padding: 7px 90px 0 0;
  }

  .mini-cart-pricing {
    color: $gray-dark;
    font-size: 14px;
    letter-spacing: .4px;
    line-height: 16px;

    // Checkout V3 override
    .checkout-section & {
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;

      .value {
        color: $rit-black;
        font-family: $font-body;
      }
    }

    .unit-base-price {
      display: block;
      padding-top: 7px;
      text-align: right;
      width: calc(100% + 90px);
    }
  }

  .mini-cart-price-wrapper {
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $primary-delay $filters-ease;
    text-align: right;
    font-family: $font-header;

    // Checkout V3 override
    .checkout-v3 & {
      right: 20px;
      width: 100%;

      @include tablet {
        right: 40px;
      }
    }

    .price-before-discount {
      color: $gray-suva;
      display: block;
      font-size: 11px;
      letter-spacing: 1.6px;
      text-decoration: line-through;

      // Checkout V3 override
      .checkout-section & {
        font-family: $font-body;
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 16px;
        position: absolute;
        right: 0;
      }
    }

    .price-after-discount {
      color: $rit-black;
      display: block;
      font-size: 15px;
      letter-spacing: .5px;
      line-height: 22px;
    }

    .unit-base-price {
      display: none;
    }
  }

  .mini-cart-unit-base {
    .unit-base-price {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;

      @include mobile-only {
        padding-right: 10px;
      }

      &.legal-note {
        color: $gray-dark;
        margin-top: 0;
      }
    }
  }

  .mini-cart-price,
  .free-label {
    color: $rit-black;
    display: block;
    font-family: $font-header;
    font-size: 15px;
    letter-spacing: .5px;
    line-height: 22px;

    // Checkout V3 override
    .checkout-section & {
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }
}

.order-confirmation-summary {
  .acc-content-item {
    .acc-content-head {
      .checkout-v3 & {
        &::after {
          @include icon-styles;
          content: icon-char(arrow3--down);
          color: $rit-copper;
          display: inline-block;
          font-size: 20px;
          font-weight: bold;
          position: absolute;
          right: 13px;
          top: 50%;
          transform: translateY(-50%);
          transition: transform $quaternary-duration $default-ease-out;
        }

        &.open {
          border-bottom-color: rgba($white, 0);

          &::after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }
    }
  }

  .checkout-summary-minicart {
    .giftcount-header {
      .checkout-v3 & {
        border-top: 1px solid $gray-gainsboro;
      }
    }

    .mini-cart-price-wrapper {
      .checkout-v3 & {
        padding-top: 0 !important;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);

        .mini-cart-price {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.7px;
          line-height: 32px;

          @include mobile-only {
            letter-spacing: 0.4px;
            line-height: 24px;
          }
        }

        .mini-cart-b2b-taxes {
          color: $gray-dark;
          font-size: 12px;
          font-family: $font-body;
        }
      }
    }
  }
}

.checkout-summary-order-totals {
  width: 100%;

  .order-totals-table {
    width: 100%;
  }

  .order-subtotal,
  .order-total {
    display: flex;

    .cart-footer__item--name {
      color: $rit-black;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      padding-top: 5px;
      text-transform: uppercase;
    }
  }

  .order-subtotal {
    border-bottom: 1px solid $gray-gainsboro-light;
    margin-bottom: 21px;
    padding-bottom: 25px;
    width: 100%;
  }

  .cart-footer__item--val {
    color: $rit-black;
    flex-shrink: 0;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 22px;
    margin-left: auto;
  }

  .shopping-cart-coupon-row {
    display: none;
  }

  .checkout-summary-price-row {
    display: flex;
    margin-bottom: 11px;

    // Checkout V3 overrides
    .checkout-v3 & {
      padding-left: 24px;
      padding-right: 24px;

      &.checkout-summary-price-row-sub {
        border-bottom: 1px solid $gray-gainsboro;
        margin-bottom: 16px;
        padding-bottom: 16px;

        .cart-footer__item--name {
          color: $rit-black;
          font-size: 16px;
          font-weight: unset;
          letter-spacing: 0.4px;
          line-height: 24px;
        }

        .cart-footer__item--val {
          color: $rit-black;
          font-size: 16px;
          font-weight: unset;
          letter-spacing: 0.4px;
          line-height: 24px;
        }
      }

      &.checkout-summary-price-shipping {
        margin-bottom: 2px;

        .cart-footer__item--name {
          color: $rit-black;
          font-size: 16px;
          font-weight: unset;
          letter-spacing: 0.4px;
          line-height: 24px;
        }

        .cart-footer__item--val {
          color: $rit-black;
          font-size: 16px;
          font-weight: unset;
          letter-spacing: 0.4px;
          line-height: 24px;
        }
      }
    }

    .cart-footer__item--name { // sass-lint:disable-line no-mergeable-selectors
      color: $gray-dark;
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 20px;
      padding-top: 4px;
    }

    // Checkout V3 overrides
    .checkout-v3 & { // sass-lint:disable-line no-mergeable-selectors
      .cart-footer__item--name {
        color: $black;
        font-family: $font-header-bold;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 20px;
        padding-top: 0;
        text-transform: none;
      }
    }
  }

  .order-total {
    border-top: 1px solid $rit-dark;
    font-weight: bold;
    margin-top: 18px;
    padding-top: 26px;
  }

  .cart-footer-total {
    display: flex;
    width: 100%;
  }
}

.checkout-shipping-form {
  .form-divider {
    background-color: $gray-lines;
    display: block;
    height: 1px;
    margin: 30px 0;
    width: 100%;
  }

  .form-subtitle {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 25px;
    text-transform: none;

    + .checkout-billing-fieldset {
      margin-top: -17px;
    }
  }

  .full-width-row {
    margin-bottom: 15px;

    & + .full-width-row {
      margin-top: 0;
    }

    .error-message {
      color: $gray-dark;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 20px;
    }
  }

  .company-fields {
    width: 100%;
  }

  .checkout-shipping-fieldset {
    margin-bottom: 15px;

    .checkbox-input-row:last-child .full-width-row {
      margin-bottom: 0;
    }
  }

  .checkout-shipping-fieldset-inner {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h2, h3, h4 {
    width: 100%;
  }

  .single-delivery-date {
    cursor: not-allowed;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-top: 7px;
  }

  .checkout-readonly-summary {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    text-align: left;
    width: 100%;

    .address-holder {
      margin-bottom: 5px;
      width: 100%;

      span {
        color: $rit-black;
        display: block;
        font-size: 14px;
        letter-spacing: .5px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      .pickup-in-store-address {
        display: inline;
      }

      .name,
      .pickup-point-name,
      .dhl-postnummer {
        font-style: italic;
      }
    }

    .dhlPostnummer {
      font-style: italic;
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 20px;
    }

    &.readonly-summary-top {
      margin-bottom: -10px;
      padding-top: 0;

      .address-holder {
        margin-bottom: 0;
      }
    }
  }

  .inputfield-row .pac-container {
    left: 0 !important;
    top: 100% !important;
  }

  .checkout-personal-information {
    display: block;
    letter-spacing: .5px;
    line-height: 20px;
    margin: -3px 0 38px;

    strong {
      display: block;
    }
  }

  @include mobile-only {
    margin-top: 49px;
  }

  @include tablet {
    margin: 0 auto;
    max-width: 620px;

    .form-divider {
      margin: 30px 0 40px;
    }

    .form-subtitle {
      font-size: 24px;
      letter-spacing: .5px;
      line-height: 32px;
      margin-bottom: 40px;

      + .checkout-billing-fieldset {
        margin-top: -28px;
      }
    }

    .checkout-personal-information {
      margin: -24px 0 18px;
    }
  }
}

.checkbox-input-row {
  label {
    color: $rit-dark;
    cursor: pointer;
    display: block;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
    padding-left: 34px;
    position: relative;

    &::before {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 24px;
    }

    &::after {
      @include icon-styles;
      color: $white;
      content: icon-char(check);
      display: none;
      font-size: 12px;
      left: 4px;
      line-height: 24px;
      position: absolute;
      top: 0;
    }

    &.checked {
      &::before {
        background-color: $rit-copper-dark;
        border-color: $rit-copper-dark;
      }
    }
  }

  input {
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 1px;
  }
}

.checkout-step-2 {
  .checkout-step-title {
    margin-bottom: 52px;

    .text {
      max-width: 240px;
    }
  }

  @include tablet {
    .checkout-step-title {
      margin-bottom: 62px;

      .text {
        max-width: 350px;
      }
    }
  }
}

.checkout-review-box {
  background-color: $white;
  color: $rit-black;
  margin-bottom: 20px;
  padding: 0 25px 1px;
  text-align: left;
  width: 100%;

  &.review-person-box {
    margin-bottom: 30px;
    padding: 0 20px 20px;

    .read-only-summary {
      padding: 0;
      margin: 0;
      border: 0;
    }
  }

  &.review-billing-box {
    .read-only-summary {
      border-top: 0;
      padding-top: 0;
    }

    .email {
      margin-bottom: 24px;
    }

    .checkout-review-box-footer {
      margin: 17px 0;
    }
  }

  &.review-pickup-box {
    .checkout-opening-hours {
      border-bottom: 0;
    }

    .checkout-opening-hours-link {
      padding: 16px 0 15px;

      &::after {
        right: -12px;
        top: 16px;
      }
    }
  }

  &.checkout-review-box-b2b {
    .read-only-summary {
      .vat-number {
        margin-top: 0;
      }
    }
  }

  .read-only-summary + .chosen-delivery-option,
  .checkout-review-box-footer + .chosen-delivery-option {
    border-top: 1px solid $gray-lines;
    padding-top: 21px;
  }

  .chosen-delivery-option {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 7px;
    }

    .text {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: .5px;
      line-height: 24px;
      max-width: calc(100% - 70px);
    }

    .time {
      display: block;
    }

    .text-pickup-point {
      font-weight: normal;

      span {
        display: block;
      }

      .distance {
        color: $gray-medium;
      }
    }

    .pickup-point-name {
      font-style: italic;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .5px;
      line-height: 24px;
      width: 100%;
      max-width: 220px;

      @include tablet {
        max-width: 265px;
      }

      @include desktop {
        max-width: 400px;
      }
    }

    .pickup-point-address {
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 24px;
      width: 100%;
    }

    .price {
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: .5px;
      line-height: 22px;
      margin-left: auto;
    }

    .dhlPostnummer {
      width: 100%;
      font-style: italic;
      margin-top: 4px;
      font-size: 14px;
      letter-spacing: .5px;
    }
  }

  .checkout-opening-hours {
    margin-bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 0;

    + .read-only-summary {
      border-top: 0;
    }
  }

  .checkout-opening-hours-link {
    padding: 19px 0 16px;

    &::after {
      right: -7px;
      top: 17px;
    }

    @include tablet {
      padding: 19px 0 22px;

      &::after {
        right: -12px;
        top: 18px;
      }
    }
  }

  .checkout-review-box-user-address {
    border-bottom: 1px solid $gray-lines;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @include tablet {
      margin-bottom: 32px;
    }

    .read-only-summary {
      border-top: 0;
      padding-top: 0;
    }

    .checkout-review-box-footer {
      margin: 0;
    }
  }

  .read-only-summary {
    border-top: 1px solid $gray-lines;
    color: $rit-black;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
    margin-bottom: 7px;
    padding-top: 21px;

    span {
      display: block;
    }

    .name {
      font-style: italic;
    }

    .vat-number {
      margin-top: 17px;
    }
  }

  .shipping-options-container {
    padding-left: 6px;
    margin-bottom: 0;

    @include mobile-only {
      padding-top: 13px;
    }

    .dhlInput-button-wrapper {
      flex-direction: column-reverse;

      .postnummer-input-wrapper {
        width: calc(100% + 85px);
        margin-left: -40px;

        @include tablet {
          width: calc(100% + 132px);
          margin-left: -48px;
        }
      }
    }

    .delivery-optlist-change {
      color: $rit-copper;
      display: block;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 28px;
      text-align: left;
      text-decoration: underline;
      margin-top: 6px;
    }

    .cart-delivery-option-label {
      margin-bottom: 24px;
      padding: 0 45px 0 40px;

      @include tablet {
        padding: 0 85px 0 49px;
      }
    }

    .cart-delivery-options:only-child .cart-delivery-option-label {
      padding-left: 0;
    }

    .option-title {
      color: $rit-black;
      line-height: 24px;
    }

    .option-time {
      font-weight: normal;
      color: $rit-black;
    }

    .option-price {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-family: $font-body;
    }
  }

  @include tablet {
    margin: 0 auto 20px;
    max-width: 610px;
  }
}

.checkout-review-box-title {
  border-bottom: 1px solid $gray-lines;
  font-family: $font-body;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 20px;
  padding: 26px 0 14px;
  text-transform: none;

  @include tablet {
    margin-bottom: 25px;
  }
}

.checkout-review-box-footer {
  display: flex;
  margin: 0 0 17px;
  padding: 0;
  text-align: center;
  width: 100%;

  .link {
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 28px;
    text-align: left;
  }

  @include mobile-and-tablet {
    flex-direction: column;
  }
}

.terms-and-conditions-info {
  margin: 0 auto;
  max-width: 610px;
  padding: 22px 0 20px 0;
  text-align: left;
  width: 100%;

  p,
  a {
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 21px;
    margin: 0 auto 10px;
    max-width: 610px;

    @include tablet {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  &.txt--white {
    & > * {
      color: $white;
    }
  }

  &.txt--black {
    & > * {
      color: $rit-black;
    }
  }
}

.checkout-billing-fieldset {
  margin-bottom: 32px;

  p {
    letter-spacing: .5px;
    margin-bottom: 17px;
  }

  @include tablet {
    margin-bottom: 35px;

    p {
      margin-bottom: 20px;
    }
  }
}

.rituals-payment-options {
  margin-bottom: 40px;
  padding-top: 10px;
  width: 100%;

  .hpp {
    list-style: none;
    width: 100%;

    li {
      position: relative;
    }
  }

  .checkout-payment-radio {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $gray-gainsboro-light;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    padding: 13px 20px 7px;
    width: 100%;
  }

  .icon-holder {
    display: block;
    height: 40px;
    position: relative;
    text-align: center;
    width: 40px;

    .ico {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(.5);
      margin: 0;
    }
  }

  .payment-method-name {
    color: $rit-dark;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 20px;
    padding-left: 20px;
    position: relative;
    text-align: left;
    width: calc(100% - 40px);

    &::before,
    &::after {
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      display: inline-block;
      position: absolute;
      top: calc(50% - 2px);
      transform: translateY(-50%);
    }

    &::before {
      background-color: $white;
      border: 1px solid $gray-lines;
      display: inline-block;
      height: 24px;
      right: -3px;
      width: 24px;
    }

    &::after {
      background-color: $white;
      height: 6px;
      right: 6px;
      width: 6px;
    }
  }

  .input-radio {
    border: 0;
    height: 1px;
    left: 0;
    margin-bottom: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 1px;

    &:checked ~ .payment-method-name {
      &::before {
        background-color: $rit-copper-dark;
      }
    }
  }

  .payment-method-extra {
    display: none;
  }

  .hpp-expandable-option {
    background-color: $white;
    display: none;
    margin-bottom: 20px;
    padding: 22px 21px 20px;
    text-align: left;
    width: 100%;

    label {
      display: block;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 3px;
    }

    .selectric-wrapper {
      .selectric {
        border: 1px solid $gray-lines;
        padding: 8px 40px 8px 10px;

        .label {
          color: $rit-black;
          font-size: 14px;
          letter-spacing: .5px;
          line-height: 20px;
        }
      }

      .selectric-items {
        top: calc(100% - 2px);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

        ul {
          padding: 0;

          li {
            padding: 13px 20px;

            &:hover,
            &.selected {
              background-color: rgba($gray-sand, 0.6);
            }

            &:not(.last) {
              border-bottom: 1px solid rgba(69, 63, 63, 0.1);
            }
          }
        }
      }
    }
  }

  @include mobile-only {
    .payment-method-bank {
      background: transparent url('../images/icons/arrow3--down.svg') no-repeat right 10px center;
      background-size: 28px 30px;
      border: 1px solid $gray-lines;
      color: $rit-black;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: .5px;
      line-height: 28px;
      padding: 8px 40px 8px 18px;
      text-align: left;
      text-align-last: left;
      text-transform: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;

      &::-ms-expand {
        display: none;
      }
    }
  }

  @include tablet {
    margin: 0 auto 50px;
    max-width: 620px;
    padding-top: 14px;

    .checkout-payment-radio {
      transition: background $quaternary-duration $filters-ease;
      &:hover {
        background-color: rgba($white, .7);
      }
    }

    .payment-method-name {
      &::before {
        right: 4px;
      }

      &::after {
        right: 13px;
      }
    }

    .hpp-expandable-option {
      padding: 22px 21px 31px;
    }
  }
}

.checkout-payment-button {
  margin-bottom: 60px;

  .btn-submit {
    width: auto;
  }
}

.checkout-eurobonus-wrapper {
  margin: -13px 0 39px;
  width: 100%;

  .sas-eurobonus {
    position: relative;
    width: 100%;

    .input-checkbox {
      height: 1px;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 1px;

      + label {
        padding-left: 34px;

        &::before {
          top: 0;
        }

        &::after {
          top: 7px;
        }
      }

      &:checked + label {
        &::before {
          background-color: $rit-copper-dark;
          border-color: $rit-copper-dark;
        }

        &::after {
          display: inline-block;
        }
      }

      &:disabled + label {
        opacity: .5;
      }
    }

    label {
      color: $rit-dark;
      display: block;
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 20px;
      position: relative;

      &::before {
        background-color: rgba($rit-copper-dark, 0);
        border: 1px solid #ccc;
        content: '';
        display: block;
        height: 22px;
        left: 0;
        position: absolute;
        width: 22px;
      }

      &::after {
        @include icon-styles;
        color: $white;
        content: icon-char(check-thin);
        display: none;
        font-size: 10px;
        left: 6px;
        line-height: 1;
        position: absolute;
      }
    }
  }

  .checkout-eurobonus-dropdown {
    padding: 11px 0 0 34px;
    position: relative;
    width: 100%;

    &.eurobonus-valid::before {
      @include icon-styles;
      color: $green;
      content: icon-char(check);
      font-size: 14px;
      line-height: 1;
      position: absolute;
      right: -2px;
      top: 25px;
    }

    .input-wrap {
      padding-right: 26px;
    }

    .error-text,
    .error-eurobonus {
      color: $red-error;
      display: block;
      font-size: 11px;
      font-weight: bold;
      line-height: 13px;
      text-align: left;
      width: 100%;
    }

    .error-eurobonus {
      margin: -7px 0 2px;
    }

    .error-text {
      margin: -10px 0 16px;
    }

    .inputfield-text {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 13px;
      padding: 12px 19px;
    }
  }

  .eurobonus-hint {
    color: $gray-medium;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: left;
    padding-left: 34px;
    margin-top: -1px;

    span {
      color: $rit-copper-dark;
      display: block;
    }

    a {
      font-size: 12px;
      line-height: 18px;
    }
  }

  @include tablet {
    margin: -17px auto 0;
    max-width: 620px;

    .sas-eurobonus .input-checkbox {
      + label::before {
        top: -3px;
      }

      &:checked + label::after {
        top: 4px;
      }
    }

    .checkout-eurobonus-dropdown {
      padding-top: 13px;
      margin-bottom: -3px;

      &.eurobonus-valid::before {
        right: 7px;
        top: 27px;
      }

      .input-wrap {
        padding-right: 37px;
      }
    }

    .eurobonus-hint {
      margin-top: 2px;
    }
  }
}

.billing-address-header {
  .form-subtitle {
    margin-bottom: 11px;
  }

  .checkout-personal-information {
    margin: 0 0 20px;

    strong {
      display: inline;
    }
  }
}

.billing-address-form.pickup-point {
  .billing-address-checkbox {
    display: none;
  }
}

.billing-address-form-inner {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;

  .full-width-row + input[type='hidden'] + .full-width-row {
    margin-top: 15px;
  }
}

.credit-card-flyin {
  .spinner-visible {
      bottom: 0;
      display: block;
      height: 10px;
      margin: auto;
      position: absolute;
      top: 0;
      z-index: 2;
  }
}

// payment icons

.pay-swish {
  @include pay-swish(45px, 50px, 35px, 45px);
}

.pay-swish-small {
  @include pay-swish(40px, 40px, 20px, 25px);
}

.pay-vvv,
.pay-vvvcadeaubon {
  @include pay-vvv(64px, 64px, 35px, 50px);
}

.pay-vvv-small,
.pay-vvvcadeaubon-small {
  @include pay-vvv(40px, 40px, 20px, 30px);
}

.pay-illicado,
.pay-prosodie_illicado {
  @include pay-illicado(84px, 45px, 80px, 40px);
}

.pay-illicado-small,
.pay-prosodie_illicado-small {
  @include pay-illicado(38px, 17px, 38px, 17px);
}
