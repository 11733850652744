.three-tile-magazine-block {
  padding: 66px 0 36px;

  .inner-wrapper {
    max-width: 1360px;
  }

  .subtitle {
    font-size: 11px;
    letter-spacing: 1.5px;
    line-height: 18px;
    color: $rit-copper-dark;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 1px;
    }
  }

  .title {
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
    text-transform: none;
    text-align: center;
    color: $rit-black;
    max-width: 165px;
    margin: 0 auto 40px;

    @include tablet {
      letter-spacing: 0.46px;
      line-height: 44px;
      margin-bottom: 29px;
      max-width: none;
    }
  }

  .simplebar-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.magazine-tile-wrapper {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    justify-content: center;
  }

  &.magazine-tile-slider {
    @include mobile-only {
      &:not(.drag-list-scroll) {
        display: block;

        .magazine-tile + .magazine-tile {
          display: none;
        }
      }

      .magazine-tile {
        margin-bottom: 0;

        .text-holder {
          vertical-align: top;
        }
      }

      .slick-dots {
        font-size: 0;
        list-style: none;
        text-align: center;
        margin-top: -4px;

        li {
          display: inline-block;
          margin: 0 2px;
        }

        button {
          background-color: $gray-silver;
          border-radius: 50%;
          display: inline-block;
          font-size: 0;
          height: 5px;
          width: 5px;
        }

        .slick-active button {
          background-color: $rit-dark;
        }
      }
    }
  }
}

.magazine-tile {
  display: flex;
  flex-direction: row;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 37px;
  }

  @include tablet {
    flex-direction: column;
    margin-bottom: 0;
    margin: 0 10px;
    max-width: 315px;
  }

  .image-holder {
    display: inline-block;
    position: relative;

    @include mobile-only {
      width: 49%;
    }

    a {
      display: block;
      width: 100%;

      .image {
        height: 100%;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 130px;

        @include tablet {
          min-height: 245px;
        }
      }
    }
  }

  .text-holder {
    text-align: center;
    padding: 20px;
    display: inline-block;

    @include mobile-only {
      width: 49%;
      text-align: left;
      padding: 5px 10px 5px 19px;
    }
  }

  .ritual-name {
    color: $rit-copper-dark;
    font-size: 11px;
    letter-spacing: 1.5px;
    line-height: 18px;
    text-transform: uppercase;
    font-family: $font-header;
    display: block;
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 10px;
    }
  }

  .description {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    color: $rit-black;
    font-family: $font-header;
    margin: 0 auto 7px;

    @include tablet {
      max-width: 250px;
      margin-bottom: 17px;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 24px;
    }
  }

  .link {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 18px;

    @include tablet {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1.6px;
      line-height: 20px;
    }
  }
}

.three-tile-magazine-block .drag-list {
  @include mobile-only {
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  .drag-list-body {
    padding: 0;
  }

  .magazine-tile {
    @include mobile-only {
      padding: 0 20px 10px;
      width: calc(100vw + 40px);
    }
  }
}
