// Template styling
.pt_collectionpage,
.pt_collectionpage_transparent {
  background-color: $rit-sandy-dark;
}

// Global collection styling
.collection-gallery {
  display: flex;
  padding-top: 40px;
  width: 100%;

  .inner-wrapper {
    max-width: 1320px;
  }

  .item {
    float: left;
    margin-bottom: 55px;
    width: 100%;
  }

  .item-small-size {
    width: 87.32%;
  }

  .item-right-indented-aligned,
  .item-right-aligned {
    float: right;
  }

  .image-holder {
    display: block;
    margin-bottom: 8px;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  .text-holder {
    width: 100%;

    &.text-right-aligned {
      text-align: right;
    }

    &.text-center-aligned {
      text-align: center;
    }
  }

  .subtitle {
    color: #af9854;
    font-size: 12px;
    letter-spacing: .7px;
    line-height: 15px;
    margin-bottom: 2px;
    text-transform: none;
  }

  .title {
    font-style: italic;
    font-size: 12px;
    letter-spacing: .7px;
    line-height: 15px;
    margin-bottom: 0;
    text-transform: none;

    a {
      color: inherit;
      font: inherit;
      letter-spacing: inherit;
    }
  }

  .details {
    font-family: $font-header;
    font-size: 12px;
    line-height: 15px;
  }

  @include mobile-only {
    .inner-wrapper {
      padding: 0 10px;
    }
  }

  @include tablet {
    padding-top: 80px;

    .item {
      margin-bottom: 60px;
    }

    .item-small-size {
      width: 29.296875%;
    }

    .item-mid-size {
      width: 46.875%;
    }

    .item-large-size {
      width: 62.5%;
    }

    .item-right-indented-aligned {
      margin-right: 8.203125%;
    }

    .item-right-mid-aligned {
      float: right;
      margin-right: 17.578125%;
    }

    .image-holder {
      margin-bottom: 11px;
    }

    .subtitle {
      font-size: 14px;
      letter-spacing: .9px;
      line-height: 17px;
    }

    .title {
      font-size: 14px;
      letter-spacing: .9px;
      line-height: 18px;
      margin-bottom: 3px;
    }

    .details {
      display: block;
      font-size: 14px;
      letter-spacing: 1.2px;
      line-height: 17px;
    }
  }
}
