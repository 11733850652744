//
// CSS for:
// cookie popup
// privacy policy page
// decline cookies page
//

.cookie-bar {
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  z-index: $modal-window-index + 3;
  position: fixed;
  left: 0 !important;
  top: 0 !important; // override jqueri ui default styles
  max-height: 100%;
  overflow: auto;
  max-width: 100%;

  @include tablet {
    padding: 30px;
  }

  .ui-dialog-titlebar {
    display: none;
  }

  .cookie-bar-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    color: $rit-black;

    @include mobile-only {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 1px;

      a {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 1px;
      }
    }

    @include tablet {
      padding-right: 10%;
    }

    @include desktop {
      padding-right: 20%;
    }
  }
}

.cookie-bar-buttons {
  position: absolute;
  right: 0;
  top: 0;

  button {
    font-size: 20px;
  }
}

.cookie-policy {
  margin-top: 40px;

  @include tablet {
    margin-top: 80px;
  }

  ul.harmonica {
    list-style: none;
  }
}

.cookie-preference {
  background-color: $white;
  padding: 30px;
  text-align: left;

  input[type='radio'] {
    height: 1px;
    opacity: 0;
    position: absolute;
    width: 1px;

    &:checked + .option-label {
      &::before {
        background-color: $rit-copper-dark;
      }

      &::after {
        background-color: $white;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 6px;
        left: 10px;
        position: absolute;
        top: 10px;
        width: 6px;
      }

      span.body {
        display: block;
      }
    }
  }

  button {
    margin-top: 25px;
    margin-left: 38px;
  }

  label {
    display: block;
  }

  .option-label {
    cursor: pointer;
    display: block;
    padding-left: 38px;
    position: relative;

    &::before {
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $gray-lines;
      content: '';
      display: inline-block;
      height: 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 24px;
    }

    span.excerpt,
    span.body {
      display: block;
    }

    span.title {
      font-weight: bold;
    }

    span.subtitle {
      color: $gray-dark;
    }
  }

  .preference-row {
    overflow: hidden;
    transition: height $tertiary-duration $default-ease-out;

    &:first-child:not(:only-child) {
      height: 56px;
      margin-bottom: 30px;
    }

    &:not(:first-child) {
      border-top: 1px solid $gray-gainsboro;
      height: 87px;
      padding-top: 30px;
    }

    &.preview {
      span.excerpt,
      span.body {
        display: block;
      }
    }
  }
}

// ------------------------------------------ //
// ********* BEGIN : PRIVACY PAGES ********** //
// ------------------------------------------ //
.pt_privacy {
  // if cookies not accepted do not listen to clicks in header or footer links
  &.disable-clicks {
    header {
      pointer-events: none;
    }
  }

  p {
    word-wrap: break-word;
  }
}

.disable-clicks {
  footer {
    pointer-events: none;
  }
}

.sticky-buttons-spacer {
  height: 500px;

  @include desktop {
    height: 250px;
  }
}

.decline-page-content-wrapper {
  .horizontal-blocks {
    @include mobile-only {
      a {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 1px;
      }
    }
  }

  .cookie-bar-buttons {
    margin-bottom: 60px;
    margin-top: 40px;
  }
}