// giftfinder hero banner
.giftfinder-hero {
  bottom: 0;
  left: 0;
  height: calc(100vh + 60px);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;

  &.blurred {
    .image-holder,
    .responsive-bg {
      filter: blur(12px);
    }

    .icon {
      opacity: 0;
    }
  }

  .image-holder,
  .responsive-bg {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 60px;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(1.07);
    transition: filter $quaternary-duration $default-ease-out;
    width: 100%;
  }

  .icon {
    color: $white;
    display: inline-block;
    font-size: 15px;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 57.2%;
    transform: translate(-50%, -50%);
    transition: opacity $quaternary-duration $default-ease-out;
  }

  @include mobile-only {
    .image-holder,
    .responsive-bg {
      filter: blur(12px);
    }
  }

  @include tablet {
    height: calc(100vh + 30px);

    .image-holder,
    .responsive-bg {
      bottom: 30px;
      transform: scale(1.02);
    }

    .icon {
      display: none;
    }
  }
}

// (ajax) content container
.giftfinder-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  opacity: 0;
  position: relative;
  transition: opacity $quaternary-duration $default-ease-out;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  @include tablet {
    min-height: calc(100vh - 95px);
  }
}

// giftfinder step indicators
.giftfinder-top-block {
  width: 100%;

  .progress-bar {
    text-align: center;
    width: 100%;
  }

  .step {
    background-color: rgba($white, .5);
    border-radius: 50%;
    color: $rit-black;
    cursor: default;
    display: inline-block;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 1.07px;
    line-height: 40px;
    height: 40px;
    margin: 0 3px;
    outline: none;
    text-decoration: none;
    width: 40px;
  }

  .completed,
  .selected {
    background-color: $white;
    transition: background $quaternary-duration $default-ease-out;
  }

  .clickable-step {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: rgba($white, .7);
    }
  }
}

// giftfinder step holder
.giftfinder-step-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  .inner-wrapper {
    align-items: center;
    display: flex;
  }

  @include tablet {
    .inner-wrapper {
      padding-top: 35px;
    }
  }

  @include tablet-portrait {
    .inner-wrapper {
      padding-bottom: 200px;
    }
  }

  @include desktop {
    .inner-wrapper {
      padding-bottom: 200px;
    }
  }

  @include tablet-landscape {
    .inner-wrapper {
      align-items: flex-start;
    }
  }
}

// giftfinder step question box
.giftfinder-step-inner-box {
  margin: 0 auto;
  max-width: 280px;
  padding: 30px 0;
  text-align: center;
  width: 100%;

  .section-title {
    color: $white;
    font-size: 20px;
    letter-spacing: 5.3px;
    line-height: 30px;
    margin-bottom: 60px;
  }

  .name-input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid rgba($white, .3);
    border-radius: 0;
    box-shadow: none;
    color: $white;
    font-family: $font-body;
    font-size: 20px;
    letter-spacing: 1.25px;
    line-height: 28px;
    margin-bottom: 5px;
    outline: none;
    text-align: center;
  }

  .input-help-text {
    color: $white;
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .btn-container {
    padding-top: 70px;
    width: 100%;
  }

  @include mobile-only {
    .input-help-text {
      opacity: 1;
      transition: opacity $quaternary-duration $default-ease-out;

      &.hidden {
        opacity: 0;
      }
    }
  }

  @include tablet {
    background-color: $rit-sandy-bg;
    border-radius: 4px;
    max-width: 496px;
    padding: 60px 60px 80px;

    .section-title {
      color: $black;
      font-size: 30px;
      letter-spacing: 2.5px;
      line-height: 36px;
      margin: 0 auto 33px;
      max-width: 280px;
      text-transform: none;
    }

    .name-input {
      border-bottom: 1px solid rgba($black, .3);
      color: $black;
      font-size: 26px;
      line-height: 30px;
      margin: 0 auto 12px;
    }

    .input-help-text {
      color: $rit-dark;
    }

    .btn-container {
      padding-top: 47px;
    }
  }
}

// gender page
.giftfinder-gender-page {
  .gender-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 7px;
    width: 100%;
  }

  .gender-option {
    background-color: $white;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    outline: none;
    padding: 9px 5px 12px;
    transition: box-shadow $quaternary-duration $default-ease-out;
    width: 47%;
    min-height: 1px;

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    }

    .category-image {
      flex-shrink: 0;
      margin: 0 auto;
      max-width: 100%;

      img {
        display: block;
        width: 100%;
      }
    }

    .category-description {
      margin-top: auto;
      width: 100%;
    }

    .category-name {
      color: $rit-black;
      display: block;
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: 2.63px;
      line-height: 1;
      text-transform: uppercase;
    }

    .product-count {
      color: $gray-dark;
      font-size: 12px;
      letter-spacing: .5px;
      line-height: 16px;
    }
  }

  @include mobile-only {
    &.giftfinder-top-block {
      padding: 41px 0 0;
    }

    .giftfinder-step-inner-box {
      max-width: none;
      padding: 50px 0 70px;
    }
  }

  @include tablet {
    &.giftfinder-top-block {
      padding: 36px 0 0;
    }

    .giftfinder-step-inner-box {
      max-width: 496px;
      padding: 70px 80px 80px;

      .section-title {
        margin: 0 auto 26px;
      }
    }

    .gender-container {
      padding-top: 0;
    }

    .gender-option {
      box-shadow: 0 2px 4px rgba(0, 0, 0, .14);
      padding: 9px 5px 8px;

      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
      }

      .category-name {
        letter-spacing: 4px;
      }
    }
  }
}

// category page
.giftfinder-category-page {
  &.giftfinder-step-wrapper {
    .inner-wrapper {
      align-items: flex-start;
    }
  }

  .giftfinder-pick-collection {
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .14);
    display: flex;
    margin-bottom: 32px;
    outline: none;
    position: relative;
    transition: box-shadow $quaternary-duration $default-ease-out;
    width: 100%;

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    }

    .image-holder {
      margin-bottom: 40px;
      width: 100%;

      img {
        width: 100%;
      }

      .desktop-img {
        display: none;
      }
    }

    .giftfinder-collection-inner {
      bottom: 8px;
      left: 0;
      padding: 0 30px;
      position: absolute;
      width: 100%;

      &::after {
        @include icon-styles;
        content: icon-char(arrow3--right);
        display: inline-block;
        font-size: 36px;
        position: absolute;
        right: 1px;
        top: 50%;
        transform: translateY(-54%);
      }
    }

    h3 {
      color: $rit-black;
      font-size: 16px;
      letter-spacing: 2.63px;
      line-height: 16px;
      margin: 0 0 2px;
    }

    .product-count {
      color: $gray-dark;
      font-size: 12px;
      letter-spacing: .5px;
      line-height: 1;
    }

    .btn {
      display: none;
    }
  }

  .secondary-title {
    color: $white;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  .giftfinder-pick-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .giftfinder-category-option {
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .14);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    outline: none;
    padding: 15px 5px 18px;
    transition: box-shadow $quaternary-duration $default-ease-out;
    width: 47.5%;

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    }

    .category-image {
      flex-shrink: 0;
      width: 100%;

      img {
        max-width: 100%;
      }
    }

    .category-description {
      width: 100%;
    }

    .category-name {
      color: $rit-black;
      font-size: 14px;
      letter-spacing: 2.3px;
      line-height: 16px;
      margin-bottom: 4px;
      text-transform: uppercase;
    }

    .product-count {
      color: $gray-dark;
      display: block;
      font-size: 12px;
      letter-spacing: .5px;
      line-height: 14px;
    }
  }

  @include mobile-only {
    &.giftfinder-top-block {
      padding: 40px 0 20px;
    }

    &.giftfinder-step-wrapper {
      padding-bottom: 55px;
    }

    .giftfinder-step-inner-box {
      max-width: none;
      padding: 12px 0 0;

      .section-title {
        font-size: 16px;
        letter-spacing: 2.63px;
        line-height: 24px;
        margin-bottom: 27px;
      }
    }
  }

  @include tablet {
    &.giftfinder-top-block {
      padding: 37px 0 40px;
    }

    &.giftfinder-step-wrapper .inner-wrapper {
      padding-bottom: 100px;
      padding-top: 0;
    }

    .giftfinder-step-inner-box {
      max-width: 680px;
      padding: 62px 70px 80px;

      .section-title {
        margin: 0 auto 43px;
        max-width: none;
        text-transform: none;
      }
    }

    .giftfinder-pick-collection {
      cursor: pointer;
      margin-bottom: 35px;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
      }

      .image-holder {
        .desktop-img {
          display: block;
        }

        .mobile-img {
          display: none;
        }
      }

      .giftfinder-collection-inner {
        bottom: 20px;
        left: auto;
        padding: 0 20px 0 0;
        right: 0;
        width: auto;

        &::after,
        .product-count {
          display: none;
        }

        h3 {
          font-size: 11px;
          letter-spacing: 1.65px;
          margin: 0 0 19px;
        }

        .btn {
          display: inline-block;
          min-width: 10px;
          padding: 13px 22px 11px;
        }
      }
    }

    .secondary-title {
      color: $rit-black;
      font-family: $font-header;
      font-size: 20px;
      letter-spacing: 1.67px;
      line-height: 24px;
      margin-bottom: 25px;
      text-transform: none;
    }

    .giftfinder-category-option {
      cursor: pointer;
      margin-bottom: 30px;
      padding: 15px 30px 19px;
      width: 30.3%;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
      }

      .category-name {
        font-size: 11px;
        letter-spacing: 1.65px;
        margin-bottom: 3px;
      }
    }
  }

  @include desktop {
    .giftfinder-step-inner-box {
      max-width: 960px;
      padding: 62px 150px 80px;
    }

    .giftfinder-pick-collection {
      .giftfinder-collection-inner {
        bottom: 50px;
        padding: 0 50px 0 0;
      }
    }
  }
}

// multiple choice page
.giftfinder-multiple-page {
  &.giftfinder-step-wrapper .inner-wrapper {
    align-items: flex-start;
  }

  .giftfinder-step-inner-box {
    max-width: none;
    padding: 66px 0;

    .section-title {
      color: $rit-black;
      font-size: 16px;
      letter-spacing: 2.63px;
      line-height: 24px;
      margin-bottom: 35px;
    }

    .section-sub-title {
      display: none;
    }
  }

  .multiple-choice {
    margin-left: -20px;
    overflow: hidden;
    padding: 0 20px;
    width: calc(100% + 40px);

    .slick-list {
      overflow: visible;
    }

    .slick-track {
      align-items: stretch;
      display: flex;
    }

    .slick-slide {
      display: flex;
      height: auto;
      padding: 5px;

      > div {
        display: flex;
        width: 100%;
      }
    }
  }

  .multiple-option {
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .14);
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    outline: none;
    transition: box-shadow $quaternary-duration $default-ease-out;
    position: relative;

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    }

    .select-box {
      border: 1px solid #d6d6d6;
      border-radius: 50%;
      display: block;
      height: 40px;
      padding-left: 4px;
      position: absolute;
      right: 10px;
      text-align: center;
      transition: $quaternary-duration $default-ease-out;
      top: 11px;
      width: 40px;

      .checkmark {
        color: $white;
        display: inline-block;
        font-size: 14px;
        line-height: 38px;

        &::before {
          @include icon-styles;
        }
      }
    }

    &.selected .select-box {
      background-color: $rit-copper-dark;
      border-color: $rit-copper-dark;

      .checkmark::before {
        content: icon-char(check);
      }
    }

    .category-image {
      flex-shrink: 0;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .category-description {
      padding: 0 30px 4px;
      width: 100%;

      .category-name {
        color: $rit-copper-dark;
        font-size: 16px;
        letter-spacing: 2.63px;
        line-height: 23px;
        margin-bottom: 4px;
      }

      .category-subtitle {
        color: $rit-dark;
        display: block;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 1px;
      }

      .product-count {
        color: $gray-dark;
        font-size: 12px;
        letter-spacing: .5px;
        line-height: 14px;
      }
    }
  }

  .btn-container {
    padding-top: 28px;
  }

  @include mobile-only {
    &.giftfinder-top-block,
    .btn-container .btn-prev {
      display: none;
    }

    &.giftfinder-step-wrapper {
      background-color: $rit-sandy-bg;
    }
  }

  @include tablet {
    &.giftfinder-top-block {
      padding: 37px 0 40px;
    }

    &.giftfinder-step-wrapper {
      .inner-wrapper {
        padding-top: 0;
      }
    }

    .giftfinder-step-inner-box {
      max-width: 680px;
      padding: 62px 70px 80px;

      .section-title {
        font-size: 30px;
        letter-spacing: 2.5px;
        line-height: 36px;
        margin: 0 auto 3px;
        max-width: none;
        text-transform: none;
      }

      .section-sub-title {
        color: $rit-dark;
        display: block;
        font-size: 12px;
        letter-spacing: .5px;
        line-height: 16px;
        margin-bottom: 24px;
      }
    }

    .multiple-choice {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 0;
      padding: 0;
      width: 100%;
    }

    .category-container {
      margin-top: 24px;
    }

    .multiple-option {
      cursor: pointer;
      margin-bottom: 20px;
      width: 48.5%;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
      }

      .category-description {
        padding: 0 20px 9px;

        .category-name {
          margin-bottom: 10px;
        }

        .category-subtitle {
          margin-bottom: 5px;
        }

        .product-count {
          display: block;
        }
      }
    }

    .btn-container {
      padding-top: 20px;

      .btn {
        margin: 0 8px;
      }
    }
  }

  @include desktop {
    .giftfinder-step-inner-box {
      max-width: 960px;
      padding: 62px 150px 80px;
    }
  }
}

// collection category page
.giftfinder-ritual-category-page {
  &.giftfinder-step-wrapper .inner-wrapper {
    align-items: flex-start;
  }

  .ritual-category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .ritual-category-option {
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .14);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 10px 18px;
    outline: none;
    transition: box-shadow $quaternary-duration $default-ease-out;
    width: 47.5%;

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    }

    .category-image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .category-name {
      color: $rit-black;
      font-size: 14px;
      letter-spacing: 2.3px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    .product-count {
      color: $gray-dark;
      display: block;
      font-size: 12px;
      letter-spacing: .5px;
      line-height: 14px;
    }
  }

  .btn-container {
    padding-top: 3px;
    .btn--text {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  @include mobile-only {
    &.giftfinder-top-block {
      display: none;
    }

    &.giftfinder-step-wrapper {
      background-color: $rit-sandy-bg;
    }

    .giftfinder-step-inner-box {
      max-width: none;
      padding: 66px 0;

      .section-title {
        color: $rit-black;
        font-size: 16px;
        letter-spacing: 2.63px;
        line-height: 24px;
        margin-bottom: 22px;
        padding: 0 30px;
      }
    }
  }

  @include tablet {
    &.giftfinder-top-block {
      padding: 36px 0 40px;
    }

    &.giftfinder-step-wrapper .inner-wrapper {
      padding-bottom: 100px;
      padding-top: 0;
    }

    .giftfinder-step-inner-box {
      max-width: 680px;
      padding: 62px 70px 80px;

      .section-title {
        margin: 0 auto 43px;
        max-width: none;
        text-transform: none;
      }
    }

    .ritual-category-option {
      cursor: pointer;
      margin-bottom: 20px;
      padding: 20px 20px 27px;
      width: 48.5%;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
      }

      .category-name {
        font-size: 16px;
        letter-spacing: 2.63px;
        line-height: 16px;
        margin-bottom: 11px;
      }
    }

    .btn-container {
      padding-top: 16px;
    }
  }

  @include desktop {
    .giftfinder-step-inner-box {
      max-width: 960px;
      padding: 62px 130px 80px;
    }
  }
}

// price range page
.giftfinder-price-page {
  &.giftfinder-top-block {
    padding: 41px 0 0;
  }

  .giftfinder-step-inner-box {
    max-width: none;
    .section-title {
      font-size: 16px;
      letter-spacing: 2.63px;
      line-height: 24px;
      margin-bottom: 35px;
      padding: 0 30px;
      text-transform: none;
    }
  }

  .price-box {
    background-color: $white;
    border-radius: 4px;
    padding: 75px 50px 35px;
    width: 100%;
  }

  .price-range-title {
    display: none;
    font-size: 12px;
    letter-spacing: 2.47px;
    line-height: 15px;
    margin-bottom: 64px;
  }

  .price-holder {
    align-items: flex-end;
    display: flex;
    width: 100%;

    input {
      font-size: 20px;
      padding: 3px;
      width: 50px;

      &::placeholder {
        color: $gray-light;
      }
    }

    .price-divider {
      margin: 0 auto;
    }

    label,
    .price-divider {
      color: $rit-black;
      font-size: 20px;
      letter-spacing: .36px;
      line-height: 31px;
      padding: 3px 0;
    }
  }

  .price-range-explanation {
    color: $rit-dark;
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
  }

  .btn-container {
    padding-top: 62px;
  }

  @include mobile-only {
    .price-holder {
      margin: 0 auto 33px;
      max-width: 235px;
    }

    .btn-container {
      .btn-prev {
        display: none;
      }

      .btn {
        min-width: 10px;
        padding: 13px 30px 11px;
      }
    }
  }

  @include tablet {
    &.giftfinder-top-block {
      padding: 37px 0 0;
    }

    .giftfinder-step-inner-box {
      max-width: 680px;
      padding: 62px 70px 80px;

      .section-title {
        font-size: 30px;
        letter-spacing: 2.5px;
        line-height: 36px;
        margin-bottom: 46px;
        max-width: none;
        padding: 0;
      }
    }

    .price-box {
      margin: 0 auto;
      max-width: 450px;
      padding: 46px 108px 108px;
    }

    .price-range-title {
      display: block;
    }

    .price-range-explanation {
      display: none;
    }

    .btn-container {
      padding-top: 51px;

      .btn {
        margin: 0 8px;
      }
    }
  }

  @include desktop {
    .giftfinder-step-inner-box {
      max-width: 960px;
      padding: 62px 120px 90px;
    }
  }
}

// giftfinder results - FOP
.giftfinder-results {
  background-color: $rit-sandy-bg;
  position: relative;
  width: 100%;

  .giftfinder-start-over {
    display: none;
    left: 0;
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 1;

    .btn {
      float: right;
    }
  }

  .fop-banner {
    overflow: hidden;
  }

  .no-results {
    p {
      padding: 20px;
    }
  }

  @include mobile-only {
    .fop-banner {
      .image-holder,
      .responsive-bg {
        bottom: -10px;
        filter: blur(12px);
        left: -10px;
        right: -10px;
        top: -10px;
      }

      .text-holder {
        padding: 30px 0;
      }

      .heading-h1 {
        font-size: 24px;
        letter-spacing: 1.5px;
        line-height: 28px;
        margin-bottom: 0;
      }

      .heading-intro {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 32px;
      }

      &.content-banner.banner-small {
        min-height: 180px;
      }
    }

    .search-result-content {
      margin-bottom: 85px;
    }
  }

  @include tablet {
    .giftfinder-start-over {
      display: block;

      .inner-wrapper {
        max-width: 1360px;
      }
    }

    .fop-banner {
      .image-holder,
      .responsive-bg {
        &::before {
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(26, 25, 25, 1));
          bottom: 0;
          content: '';
          display: inline-block;
          left: 0;
          opacity: .4;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .text-holder {
        padding: 80px 0 110px;
      }

      .heading-intro {
        font-size: 30px;
        letter-spacing: 2.5px;
        line-height: 36px;
      }

      &.content-banner.banner-small {
        min-height: 387px;
      }
    }

    .search-filter-box {
      .breadcrumb {
        margin-bottom: 17px;
      }

      .filter-box-categories {
        margin-bottom: 25px;

        sup {
          font-family: $font-header;
          letter-spacing: 0;
          margin-left: 4px;
          top: -11px;
        }
      }
    }

    .active-refinements-list {
      margin-bottom: 19px;
    }
  }
}

//giftfinder filters
.giftfinder-refinements {
  background-color: $white;
  bottom: 0;
  display: none;
  left: -100%;
  position: fixed;
  top: 0;
  transition: left $tertiary-duration $filters-ease;
  width: 100%;
  z-index: $modal-overlay-index;

  .giftfinder-refinements {
    transition: none;
  }

  .filter-inner {
    height: 100%;
    overflow: auto;
    padding: 61px 0 48px;
    width: 100%;
  }

  &.refinements-visible {
    left: 0;

    .giftfinder-filter-header,
    .giftfinder-filter-user-actions {
      left: 0;
    }

    .giftfinder-filter-overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  @include mobile-only {

    &.refinements-visible {
      &.moved-right {
        left: 100%;

        .giftfinder-filter-header,
        .giftfinder-filter-user-actions {
          left: 100%;
        }
      }

      .giftfinder-filter-holder .filter-options.visible {
        left: 0;
      }
    }
  }

  @include tablet {
    width: 360px;

    .filter-inner {
      padding-top: 100px;
    }
  }
}

.giftfinder-filter-overlay {
  background-color: rgba(0, 0, 0, .6);
  bottom: 0;
  display: none;
  left: 360px;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ease-in-out;
  visibility: hidden;

  @include tablet {
    display: block;
  }
}

.giftfinder-filter-header {
  background-color: $rit-sandy-bg;
  left: -100%;
  position: fixed;
  text-align: center;
  top: 0;
  transition: left $tertiary-duration $filters-ease;
  width: 100%;
  z-index: 20;

  .filter-x-close {
    font-size: 22px;
    position: absolute;
    right: 16px;
    top: 19px;
  }

  h3 {
    color: $rit-black;
    font-size: 18px;
    letter-spacing: .6px;
    line-height: 21px;
    margin-bottom: 0;
    padding: 20px 40px;
    text-transform: none;
  }

  @include tablet {
    text-align: left;
    width: 360px;

    .filter-x-close {
      right: 56px;
      top: 39px;
    }

    h3 {
      font-size: 28px;
      letter-spacing: 1px;
      line-height: 40px;
      padding: 30px 60px;
    }
  }
}

.giftfinder-filter-holder {
  overflow: hidden;
  width: 100%;

  .filters-container {
    display: flex;
    flex-direction: column;
    padding: 22px 40px;
    width: 100%;
  }

  .filter-block {
    margin-bottom: 13px;
    float: left;
    width: 100%;
  }

  .filter-block-title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 2.5px;
    line-height: 15px;
    margin-bottom: 8px;
    padding: 13px 30px 13px 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    &::after {
      @include icon-styles;
      content: icon-char(plus2);
      display: inline-block;
      font-size: 27px;
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.open::after {
      content: icon-char(minus2);
    }
  }

  .filter-options {
    display: none;
    float: left;
    width: 100%;
  }

  .filter-options-container {
    width: 100%;

    .filter-item {
      margin-bottom: 12px;
    }

    .no-input {
      letter-spacing: .25px;
    }

    giftfinder-gender-option {
      display: block;
    }

    .giftfinder-gender-btn {
      display: inline-block;
      text-align: left;

      .text {
        color: $rit-black;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: .25px;
        line-height: 20px;
        position: relative;

        &::before {
          background-color: $rit-black;
          border: 0;
          bottom: -2px;
          content: '';
          height: 1px;
          left: 0;
          position: absolute;
          top: auto;
          transition: width .3s ease;
          width: 0;
        }
      }

      sup {
        color: $rit-copper-on-black;
        font-family: $font-header;
        margin-left: 5px;
      }

      &.selected .text::before {
        width: calc(100% - 3px);
      }
    }

    label {
      color: $rit-black;
      display: inline-block;
      font-size: 14px;
      letter-spacing: .25px;
      line-height: 20px;
      position: relative;
      width: 100%;

      input {
        height: 2px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 2px;
        width: 2px;

        &:checked ~ .text {
          &::before {
            background-color: $rit-copper-dark;
            border-color: $rit-copper-dark;
          }

          &::after {
            display: block;
          }
        }

        &.focus-visible ~ .text {
          &::before {
            border-color: $rit-copper-dark;
            height: 20px;
            left: -2px;
            top: 0;
            width: 20px;
          }

          &::after {
            font-size: 10px;
            left: 3px;
          }
        }

        &:disabled ~ span,
        &[disabled=disabled] ~ span {
          cursor: not-allowed;
          opacity: .26;
        }
      }

      .text {
        background-color: transparent;
        cursor: pointer;
        padding-left: 21px;

        &::before {
          background-color: transparent;
          border: 1px solid $gray-lines;
          content: '';
          display: inline-block;
          height: 14px;
          left: 1px;
          position: absolute;
          top: 3px;
          width: 14px;
        }

        &::after {
          @include icon-styles;
          color: $white;
          content: icon-char(check-thin);
          display: none;
          font-size: 6px;
          left: 5px;
          position: absolute;
          top: 3px;
        }
      }

      sup {
        color: $rit-copper-on-black;
        font-family: $font-header;
        margin-left: 5px;
      }

      &.label-title {
        .text {
          padding-left: 0;
          position: relative;

          &::before {
            background-color: $rit-black;
            border: 0;
            bottom: -2px;
            height: 1px;
            left: 0;
            top: auto;
            transition: width .3s ease;
            width: 0;
          }
        }

        &:hover .text::before {
          width: 100%;
        }

        input {
          &:checked~.text {
            &::before {
              background-color: #111;
              top: auto;
              height: 1px;
              width: 100%;
            }
            &::after {
              display: none;
            }
          }

          &:focus ~ .text {
            &::before {
              width: 100%;
            }
          }
        }
      }
    }

    .btn-label-title {
      color: $rit-black;
      display: inline-block;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: .25px;
      line-height: 20px;
      outline: none;

      .text {
        position: relative;

        &::before {
          background-color: $rit-black;
          bottom: -4px;
          content: '';
          display: inline-block;
          height: 1px;
          left: 0;
          position: absolute;
          transition: width .3s ease;
          width: 0;
        }
      }

      &:focus .text::before,
      &:hover .text::before,
      &.active .text::before {
        width: 100%;
      }

      sup {
        color: $rit-copper-on-black;
        font-family: $font-header;
        margin-left: 5px;
      }
    }
  }

  .nested-options {
    display: none;
    padding: 2px 0 0 20px;
    width: 100%;

    &.active {
      display: block;
    }

    .filter-item label {
      .color {
        height: 16px;
        margin: 3px -19px -3px 26px;
      }

      .text {
        display: inline-block;
        letter-spacing: .25px;
        line-height: 16px;
        padding-left: 26px;
      }
    }
  }

  .fop-filter-categories-wrapper {
    padding-top: 13px;
    width: 100%;
  }

  .price-holder {
    display: flex;
    width: 100%;

    .price-item-input {
      margin-right: 9px;
    }

    .price-item-btn {
      margin-left: 21px;
    }

    label {
      color: $rit-black;
      display: inline-block;
      font-size: 14px;
      letter-spacing: .25px;
      line-height: 20px;
      margin-right: 5px;
      width: auto;
    }

    input {
      color: $rit-black;
      line-height: 20px;
      margin: 0;
      outline: none;
      padding: 5px 10px 6px;
      width: 50px;

      &:focus,
      &:active {
        border-color: $rit-copper-dark;
      }

      //keeping this separated, it doesn't work when it's merged
      &::-webkit-input-placeholder {
        color: rgba($rit-black, .28);
      }
      &::-moz-placeholder {
        color: rgba($rit-black, .28);
      }
      &:-ms-input-placeholder {
        color: rgba($rit-black, .28);
      }
      &:-moz-placeholder {
        color: rgba($rit-black, .28);
      }
    }


    .btn {
      min-width: 63px;
      padding: 9px 20px 7px;

      &.btn--disabled {
        background-color: transparent;
        border-color: $gray-lines;
        color: $gray-lines;
      }
    }
  }

  @include tablet {
    .filters-container {
      padding: 27px 20px 27px 60px;
    }

    .filter-block {
      margin-bottom: 15px;
    }

    .filter-block-title {
      margin-bottom: 0;
      width: calc(100% - 40px);
    }

    .filter-options-container {
      margin-top: -5px;

      .filter-item {
        margin-bottom: 0;
      }

      label {
        width: auto;
      }

      .no-input {
        font-size: 14px;
      }
    }

    .nested-options {
      padding: 10px 0 38px 19px;

      .filter-item {
        label {
          .text {
            &::before {
              height: 10px;
              left: 0;
              top: 4px;
              width: 10px;
            }
          }

          input {
            &:checked ~ .text {
              &::before {
                height: 10px;
                width: 10px;
              }

              &::after {
                font-size: 5px;
                left: 3px;
              }
            }

            &.focus-visible ~ .text {
              &::before {
                height: 16px;
                left: -3px;
                top: 1px;
                width: 16px;
              }

              &::after {
                font-size: 8px;
                left: 1px;
              }
            }
          }
        }
      }
    }

    .fop-filter-categories-wrapper {
      padding-top: 17px;
    }
  }
}

.giftfinder-filter-user-actions {
  bottom: -2px;
  display: flex;
  left: -100%;
  position: fixed;
  transition: left $tertiary-duration cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  .btn {
    min-width: 10px;
    padding: 17px 7px 15px;
    width: 50%;

    &:hover {
      background-color: $white;
    }
  }

  .btn-clear-all {
    background-color: $rit-sandy-bg;
    border-color: $rit-sandy-bg;
    color: #111;
  }

  @include tablet {
    width: 360px;
  }

  @include desktop {
    bottom: 0;
  }
}
