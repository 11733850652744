// GUIDED SELLING TOOL
.gst-wrapper {
  display: flex;
  flex-wrap: wrap;

  .quiz-container {
    width: 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 543px;
    display: flex;
    flex-direction: column;
    position: relative;

    @include tablet {
      flex-direction: row;
    }

    &.question {
      text-align: center;
    }
  }

  .row {
    width: 100%;
  }

  .btn {
    cursor: pointer;
    @include tablet {
      margin: 20px 20px 15px;
      min-width: 220px;
    }
  }

  .btn-small {
    min-width: 150px;

    @media screen and (max-width: 350px) {
      min-width: 100px;
    }

    @include tablet {
      min-width: 220px;
    }
  }

  .btn--secondary {
    margin-right: 15px;

    @include tablet {
      margin-right: 0;
    }
  }

  .content-banner-start,
  .content-banner {
    min-height: calc(100vh - 60px);
    height: auto;
    max-height: none;

    @include desktop {
      min-height: calc(100vh - 95px);
    }

    .text-holder {
      @include mobile-only {
        padding: 50px 0 0;
      }
    }
  }

  .content-banner {
    overflow: hidden;

    .image-holder {
      .icon {
        color: $rit-black;
        display: inline-block;
        font-size: 15px;
        left: 50%;
        opacity: 1;
        position: absolute;
        top: 42%;
        transform: translate(-50%, -50%);
        transition: opacity $quaternary-duration $filters-ease;

        @include tablet {
          display: none;
        }
      }
    }

    &.showing-more-info {
      .more-info-content {
        display: flex;
      }

      .quiz-main-content {
        display: none;
      }
    }
    .quiz-main-content {
      width: 100%;
    }
  }

  //when a start page has 2 tile question
  .content-banner-start {
    .content-inner-wrapper {
      min-height: 0;
    }

    .options-wrapper.questions-wrapper {
      @include tablet {
        margin: 32px auto 5px;
      }
    }
  }

  .content-banner .image-holder,
  .question-image-holder .image-holder {
    bottom: -20px;
    left: -20px;
    right: -20px;
    top: -20px;
    transform: scale(1.02);
    transition: filter $quaternary-duration $filters-ease;

    &.blurred {
      filter: blur(12px);

      .icon {
        opacity: 0;
      }
    }
  }

  .quiz-main-content {
    opacity: 0;
    transition: opacity $quaternary-duration $default-ease-out;
    visibility: hidden;

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .more-info-content {
    display: none;
    color: $white;
    letter-spacing: 0.8px;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    padding: 50px 0;
    align-items: center;

    .text {
      display: flex;
      align-items: center;
      padding: 0 10%;
      max-width: 100%;
    }

    .back-to-question-btn {
      cursor: pointer;
      margin-top: auto;
      display: inline-block;
      text-decoration: underline;
      font-size: 12px;
      letter-spacing: 2px;

      @include desktop {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .content-inner-wrapper {
    align-self: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    padding-bottom: 70px;

    @include tablet {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include desktop {
      min-height: calc(100vh - 135px);
    }
  }

  .icon {
    vertical-align: middle;
  }

  .estimated-time {
    color: $white;
    font-size: 16px;
    margin-top: 20px;
  }

  .heading-h3,
  .description,
  .heading-subtitle {
    z-index: 2;
  }

  .heading-h3 {
    margin-top: 81px;
    margin-bottom: -2px;
    padding: 0 10px;
    font-size: 20px;
    letter-spacing: 5.33px;
    line-height: 40px;

    @include tablet {
      font-size: 30px;
      letter-spacing: 8px;
      margin: 0;
    }
  }

  .heading-mobile {
    font-size: 18px;
    @include tablet {
    font-size: 30px;
    }
  }

  .headline {
    margin-bottom: 7px;
    color: $white;
    letter-spacing: 5.04px;
    font-size: 34px;
    line-height: 38px;

    @include tablet {
      margin-bottom: 26px;
      letter-spacing: 8px;
      font-size: 54px;
      line-height: 58px;
    }
  }

  .heading-subtitle {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 22px;
    font-family: $font-body;
    text-transform: none;
    margin: 15px 0 0;

    @include tablet {
      margin: -15px 0 16px;
    }
  }

  .subtitle {
    font-size: 18px;
    letter-spacing: 4.8px;
    line-height: 40px;
    color: $white;
    margin-bottom: 3px;
    margin-top: 96px;

    @include tablet {
      font-size: 30px;
      letter-spacing: 8px;
      line-height: 40px;
      margin-bottom: 15px;
      margin-top: 0;
    }
  }

  .description {
    font-size: 20px;
    line-height: 24px;
    color: $rit-black;
    letter-spacing: 2.5px;
    font-family: $font-header;

    @include tablet {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 3px;
      max-width: 685px;
      margin: 0 auto;
      margin-bottom: 59px;
    }
  }

  // question block

  .question-image-holder {
    bottom: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;

    .image-holder {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 100%;
      min-width: 100%;
      position: absolute;

      @include tablet {
        background-position: center center;
      }
    }

    & ~ .progress-bar-container {
      background-color: rgba($white, 0.1);
      position: relative;

      .active,
      .step.completed {
        color: $rit-dark;
      }

      .step {
        color: rgba($rit-dark, 0.2);
      }
    }
  }

  .top-wrapper {
    position: relative;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 15px;

    @include tablet {
      padding-top: 82px;
      margin-bottom: 0;
    }
  }

  .bottom-wrapper {
    margin-top: 30px;
    max-width: 100%;

    @include tablet {
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .image-text-wrapper {
      display: flex;
      flex-direction: column;

      @include tablet {
        flex-direction: row;
        max-width: 900px;
        margin: 0 auto;
      }
    }
  }

  .main-image-wrapper {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    padding: 0 30px;
    margin-top: 25px;

    img {
      width: 100%;
    }

    @include tablet {
      max-width: 33.3333%;
      min-width: 300px;
      margin-top: 20px;
      padding-left: 10px;
    }

    // if there is a question main image, adjust options width
    ~ .options-wrapper {
      padding: 0 30px;

      .option-block {
        width: 100%;
        padding: 10px 0;
      }

      @include tablet {
        flex-basis: 66.666%;
        width: 66.666%;
        margin-top: 10px;
        padding: 0 10px;

        .option-block {
          width: 50%;
          padding: 10px;
        }
      }
    }
  }

  .more-info {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    text-decoration: underline;

    @include tablet {
      padding-top: 20px;
    }

    @include desktop {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .options-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 100%;
    padding: 0 30px;
    max-width: 640px;
    margin: 40px auto 0;

    @include tablet {
      margin-top: 10px;
      padding: 0;
    }

    &.questions-wrapper {
      max-width: 1020px;
      margin: 0 auto;

      @include tablet {
        margin: 9px auto 65px;
        padding: 0 20px;
      }

      @include desktop {
        padding: 0;
      }
    }
  }

  .option-block {
    width: 147px;
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    &:focus {
      outline: none;
      .option-content-wrapper {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
        border: 1px solid $rit-copper-dark;
        border-radius: 2px;
      }
    }

    @media screen and (min-width: 376px) {
      width: 167px;
    }

    @include tablet {
      width: 200px;
    }

    .option-content-wrapper,
    .option-text {
      border-radius: 2px;
    }

    .option-content-wrapper {
      text-align: center;
      transition: all 0.15s ease-in-out;
      width: 100%;
      background-color: $white;
      border: 1px solid transparent;
    }

    .option-text {
      background-color: $white;
      color: $rit-black;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: 1.6px;
      line-height: 18px;
      padding: 30px;
      transition: all 0.15s ease-in-out;
      width: 100%;

      @include tablet {
        letter-spacing: 2px;
      }
    }

    .ritual-scent-text {
      padding: 40px 27px 20px;

      h3 {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        line-height: 20px;
        margin-bottom: 7px;
      }

      p {
        font-size: 13px;
        letter-spacing: 1.62px;
        line-height: 16px;
        color: $gray-dark;
      }
    }

    .select-box-row {
      width: 100%;
      position: relative;
      top: -16px; // to overlap image
    }

    .text-only-select-row + .option-text {
      padding: 22px 20px;
      text-align: left;
    }

    .text-only-wrapper {
      flex-direction: column-reverse;
      justify-content: flex-start;
      min-height: 71px;

      @include tablet {
        min-height: 150px;
        height: 100%;
      }

      .select-box-row {
        width: auto;
        position: static;
        margin-bottom: 20px;

        @include tablet {
          margin-bottom: 25px;
        }

        .round-select-box {
          position: static;
          transform: none;
        }
      }
    }

    .round-select-box {
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      left: 50%;
      transform: translate(-50%);
      position: absolute;
      border: 1px solid $rit-copper-dark;
      transition: all 0.15s ease-in-out;
      background-color: $white;

      @include tablet {
        height: 25px;
        margin-top: 0;
        width: 25px;
      }

      .icon-check {
        display: none;
      }
    }

    .image-holder {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    //styles for selected state
    &.selected {
      .option-content-wrapper {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
        border: 1px solid $rit-copper-dark;
      }

      .round-select-box {
        background-color: $rit-copper-dark;

        .icon-check {
          display: inline-block;
          color: $white;
          font-size: 12px;
          vertical-align: bottom;
        }
      }
    }

    &.option-rituals .option-content-wrapper {
      position: relative;

      &::after {
        bottom: -1px;
        content: '';
        height: 4px;
        left: -1px;
        position: absolute;
        right: -1px;
        transition: height $quaternary-duration $default-ease-out, bottom $quaternary-duration $default-ease-out;
      }

      &.selected .option-content-wrapper::after {
        bottom: -2px;
        height: 5px;
      }
    }
  }

  .option-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .option-extra-info {
    display: none;
  }

  .option-extra-info-target {
    text-align: center;
    padding: 0 33px;
    display: block;
    margin: 0 auto;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px;
    max-width: 640px;

    strong {
      font-weight: 600;
    }
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    padding-top: 19px;

    .nav-container-inner {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.fullwidth-nav-container {
      @include mobile-only {
        flex-wrap: wrap;
        margin: 0 auto 40px;
        max-width: 268px;

        .btn {
          margin: 0 0 10px;
          width: 100%;
        }
      }
    }

    @include mobile-only {
      .btn {
        transition: none;
      }

      .btn--secondary {
        background-color: $white;
        border-color: $white;
      }

      &.sticky-buttons {
        height: 75px;

        .nav-container-inner {
          box-shadow: 0 0 15px rgba($rit-black, .2);
          bottom: 0;
          left: 0;
          margin-bottom: 0;
          padding: 0;
          position: fixed;
          z-index: $modal-window-index;

          .btn {
            margin: 0;
            padding: 20px 10px;
            width: 50%;

            &.btn--secondary {
              background-color: $white;
              border-color: rgba($white, 0);
            }
          }
        }
      }
    }

    @include tablet {
      padding: 0 20px;

      .btn--secondary {
        background-color: $white;
        border-color: $white;

        &:hover, &:active {
          background-color: rgba($white, 0);
        }
      }

      .btn:not(.btn-hover-light) {
        &:hover, &:active {
          color: $rit-black;
        }
      }

      .btn.btn-hover-light {
        &:hover, &:active {
          border-color: $white;
          color: $white;
        }
      }
    }
  }

  .retake-quiz-link {
    text-decoration: none;
  }

  .progress-bar-container {
    padding: 0 20px;
    display: none; // js will show it when it's done
    width: 100%;
    background-color: $rit-sandy-light;
    margin: 0 auto;

    &.progress-bar-hairtemple {
      background-color: rgba($white, 0.1);

      .active,
      .step.completed {
        color: $rit-dark;
      }

      .step {
        color: rgba($rit-dark, 0.2);
      }
    }

    .progress-bar {
      width: 100%;
      margin: 10px auto 13px;
      display: flex;
      justify-content: center;
    }

    .start,
    .finish,
    .steps-container {
      align-self: center;
      display: flex;
    }

    .start,
    .finish {
      display: flex;
      flex: 0;
      margin: 0 8px;
      font-size: 10px;
      letter-spacing: 1.67px;
      text-transform: uppercase;
      color: $rit-dark;

      @include tablet {
        margin: 0 20px;
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      flex: 0 1 auto;
      }
    }

    .active {
      text-decoration: underline;

      @include desktop {
        &:hover {
          text-decoration: none;
        }
      }
    }

    .finish {
      margin-left: 0;
    }

    .steps-container {
      align-items: center;
      flex: 1;
    }

    .step {
      color: rgba($rit-dark, 0.2);
      position: relative;

      &:not(:last-of-type) {
        margin-right: 8px;

        @include tablet {
          margin-right: 20px;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        margin: 0 5px;
        width: 4px;
        height: 4px;
        background-color: currentColor;
        border-radius: 50%;

        @include tablet {
          width: 5px;
          height: 5px;
        }
      }

      &.completed {
        color: $rit-dark;
      }

      .current-step-label {
        display: none;
      }
    }
  }

  .question-number {
    top: 92px;
    left: 50%;
    transform: translate(-50%);
    font-size: 160px;
    color: rgba($rit-copper-dark, 0.15);
    font-family: $font-header;
    position: absolute;

    @include tablet {
      top: 90px;
    }
  }

  .option-block.option-text-image {
    width: 315px;
    display: flex;
    margin: 0 auto;
    padding: 10px 0;

    //if there are 2 options
    &:nth-last-of-type(2):first-of-type,
    &:nth-last-of-type(2):first-of-type ~ .option-block.option-text-image {
      width: 50%;
      padding: 5px;

      .option-text {
        padding: 30px 15px 25px;
      }
    }

    @include tablet {
      width: 33.333%;
      padding: 20px;

      //if there are 2 options
      &:nth-last-of-type(2):first-of-type,
      &:nth-last-of-type(2):first-of-type ~ .option-block.option-text-image {
        width: 33.333%;
        padding: 20px;
        margin: 0;
      }

      //if there are 4 or 5 options
      &:nth-last-of-type(4):first-of-type,
      &:nth-last-of-type(4):first-of-type ~ .option-block.option-text-image,
      &:nth-last-of-type(5):first-of-type,
      &:nth-last-of-type(5):first-of-type ~ .option-block.option-text-image {
        width: 25%;
        padding: 10px 10px 20px;
      }
    }

    .option-content-wrapper.text-and-image-wrapper {
      .option-image {
        min-height: 0;
        background-color: transparent;
      }
    }

    .option-content-wrapper {
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      background-color: transparent;

      .option-image {
        overflow: hidden;
        position: relative;
        width: 100%;
        max-width: 100%;
        flex-shrink: 0;
        background-color: $rit-sandy-light;
        min-height: 200px;

        @include tablet {
          min-height: 240px;
        }

        &.ritual-scent-image {
          min-height: 262px;
          display: flex;
          align-items: center;
          justify-content: center;

          .top-text {
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 10px;
            letter-spacing: 1.25px;
            line-height: 28px;
            display: block;
            color: $gray-dark;
            text-transform: uppercase;
          }

          img {
            margin-top: 10px;
          }
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .option-text {
        padding: 40px 27px 35px;
        max-width: 100%;
        height: 100%;

        @include tablet {
          padding: 40px 27px;
        }
      }
    }

    .extra-info-text {
      color: $rit-copper-dark;
      height: 0;
      opacity: 0; // only show if block is .selected
      padding-top: 4px;
      transition: opacity 0.15s ease-in-out;
      visibility: hidden;
    }

    &.selected {
      .extra-info-text {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }

    .text-over-image {
      font-size: 30px;
      letter-spacing: 8px;
      font-family: $font-header;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: auto;
      left: auto;
      background-color: $rit-sandy-light;
      width: 100%;
      height: 95%;
      padding: 20px;

      &::after {
        content: '';
        padding-bottom: 80%;
        display: inline-block;
      }
    }
  }

  .option-block.option-text-only {
    display: block;
    min-height: 150px;

    .option-text {
      padding: 20px 15px;
      text-transform: none;
    }

    @include mobile-only {
      margin-bottom: 1px;
      min-height: 10px;
      padding: 0;
      width: 100%;

      .text-only-wrapper {
        box-shadow: 0 0 15px 0 rgba($black, .23);
        flex-direction: row;
        min-height: 10px;
        padding: 0 17px;
        text-align: left;
      }

      .select-box-row {
        margin-bottom: 0;
      }

      .option-text {
        background-color: rgba($white, 0);
        font-size: 14px;
        padding: 21px 17px 19px;
      }

      &.selected .text-only-wrapper {
        border-color: transparent;
      }
    }
  }

  .option-block.option-name-block {
    cursor: default;
    margin-bottom: 65px;
    padding: 0;
    width: 100%;

    @include tablet {
      margin-bottom: -40px;
      width: 584px;

      &.selected .option-content-wrapper,
      &:focus .option-content-wrapper {
        box-shadow: none;
        border: 1px solid $rit-sandy-bg;
      }
    }

    .option-content-wrapper {
      flex-wrap: wrap;

      @include mobile-only {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        margin: 0 auto;
        max-width: 315px;
      }
    }

    .option-image {
      display: none;

      @include tablet {
        display: block;
        padding-top: 36px;
        width: 100%;
      }

      img {
        max-width: 100%;
      }
    }

    .option-name-wrapper {
      padding: 17px 20px 5px;
      width: 100%;

      @include tablet {
        padding: 6px 20px 28px;
        max-width: 320px;
      }

      .input-holder {
        width: 100%;
      }

      input {
        background-color: transparent;
        border-bottom-color: rgba($rit-black, .4);
        color: $rit-black;
        font-size: 20px;
        letter-spacing: 1.25px;
        line-height: 28px;
        margin-bottom: 7px;
        text-align: center;

        //keeping this separated, it doesn't work when they are merged
        &::-webkit-input-placeholder {
          color: $rit-black;
        }
        &::-moz-placeholder {
          color: $rit-black;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: $rit-black;
        }
        &:-moz-placeholder {
          color: $rit-black;
          opacity: 1;
        }

        &.error {
          border-color: $red-error;
          color: $red-error;
        }

        &:focus::-webkit-input-placeholder {
          opacity: 0;
        }
        &:focus::-moz-placeholder {
          opacity: 0;
        }
        &:focus:-ms-input-placeholder {
          opacity: 0;
        }
        &:focus:-moz-placeholder {
          opacity: 0;
        }

        &::-ms-clear {
          display: none;
        }

        @include tablet {
          font-size: 24px;
          letter-spacing: .4px;
          line-height: 44px;
          margin-bottom: 9px;
        }
      }

      span {
        color: #717171;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 17px;
      }

      label.error,
      span.error {
        color: $red-error;
        display: block;
        margin-left: -40px;
        width: calc(100% + 80px);
      }
    }
  }

  .quiz-container-result {
    flex-direction: column !important;
    min-height: calc(100vh - 60px);

    @include desktop {
      min-height: calc(100vh - 95px);
    }

    .content-left {
      padding: 50px;
      height: auto;
      min-height: 200px;

      @include tablet {
        height: 480px;
        min-height: 480px;
      }

      .result-heading {
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 15px;

        @include tablet {
          line-height: 40px;
        }
      }
    }
  }

  .result-container {
    text-align: center;
    background-color: $rit-sandy-bg;
    padding: 50px 30px;
    height: 100%;

    .result-description {
      max-width: 800px;
      display: block;
      margin: 0 auto;
    }

    h3 {
      margin-bottom: 20px;
      margin-top: 0;
    }

    .row {
      margin-top: 12px;
    }

    .btn--secondary {
      margin-bottom: 30px;
      margin-top: 15px;

      @include tablet {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .result-extended-heading {
    display: block;
    max-width: 400px;
    margin: 0 auto;
  }
}

.hairtemple-quiz.gst-wrapper {
  position: relative;

  @media screen and (min-width: 768px) and (max-height: 900px) {
    .option-block.option-text-image .option-content-wrapper .option-image {
      min-height: 150px;
    }

    .options-wrapper.questions-wrapper {
      margin-bottom: 0;
    }

    .btn {
      margin-top: 0;
    }

    .top-wrapper {
      padding-top: 70px;
    }

    .question-number {
      top: 76px;
    }

    .option-block.option-name-block .option-content-wrapper {
      margin-bottom: 80px;
    }
  }


  .content-banner {
    overflow: hidden;

    .quiz-main-content {
      margin-top: 40px;
      opacity: 0;
      transition: opacity $quaternary-duration $default-ease-out;
      visibility: hidden;

      &.visible {
        opacity: 1;
        visibility: visible;
      }
    }

    .headline {
      color: $rit-black;
      font-size: 30px;
      letter-spacing: 4.4px;
      line-height: 40px;

      @include tablet {
        font-size: 40px;
        letter-spacing: 10.6px;
        line-height: 50px;
      }
    }

    .heading-subtitle {
      color: $rit-black;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;

      @include tablet {
        font-size: 16px;
        letter-spacing: 5px;
        line-height: 25px;
      }
    }

    .btn {
      margin-top: 20px;
    }

    .estimated-time {
      color: $rit-black;
    }
  }

  .progress-bar-container {
    position: relative;
  }

  .quiz-container {
    position: relative;

    @include tablet {
      padding-bottom: 0;
    }
  }

  .quiz-loader {
    .image-holder {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;

      &.blurred {
        filter: blur(0);
      }
    }
  }

  .option-block.option-text-image.option-hairtemple-text-image {
    .option-content-wrapper {
      background-color: transparent;
      border: 1px solid transparent;
    }

    .option-image {
      background-color: transparent;
      min-height: 0 !important;

      img {
        display: block;
      }
    }

    .option-text {
      background: $white;
      width: 100%;
    }

    &.selected .option-content-wrapper {
      border: 1px solid #865;
    }
  }
}

.quiz-landing-page {
  position: relative;

  .quiz-start-over {
    left: 0;
    position: absolute;
    top: 13px;
    width: 100%;
    z-index: 1;

    @include tablet {
      top: 30px;
    }

    .link {
      float: right;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
    }
  }

  .product-wrapper-hairtemple .product-image img {
    width: 100%;
  }

  .content-banner.banner-small.hairtemple-banner {
    min-height: 320px;
    overflow: hidden;

    @include tablet {
      min-height: 480px;
    }

    .responsive-bg {
      filter: blur(12px);
      transform: scale(1.02);
    }

    .text-holder {
      padding: 53px 0 37px;

      @include tablet {
        padding: 129px 0 87px;
      }

      h1 {
        margin-bottom: 12px;

        @include tablet {
          margin-bottom: 20px;
        }
      }

      .inner-wrapper {
        padding: 0 40px;

        @include tablet {
          padding: 0 20px;
        }
      }
    }

    .inner-indented {
      max-width: 940px;
    }

    .heading-intro {
      font-size: 16px;
      letter-spacing: 1.33px;
      line-height: 23px;

      &:first-of-type {
        margin-bottom: 24px;
      }

      @include tablet {
        font-size: 24px;
        letter-spacing: 0.46px;
        line-height: 44px;

        &:first-of-type {
          margin-bottom: 45px;
        }
      }
    }
  }

  .product-wrapper-hairtemple {
    padding-bottom: 114px;

    @include tablet {
      padding-bottom: 65px;
    }
  }

  .product-cointainer-hairtemple {
    padding-top: 45px;

    &:nth-of-type(2) {
      padding-top: 80px;
    }

    @include tablet {
      padding-top: 90px;
      max-width: 1045px;
      margin: 0 auto;

      &:nth-of-type(2) {
        padding-top: 90px;
      }
    }

    .product-image {
      text-align: center;
      @include tablet {
        width: 50%;
      }
    }

    .product-details-content {
      width: 100%;
      text-align: center;

      @include tablet {
        width: 50%;
      }

      .product-name-link {
        text-decoration: none;
      }

      .product-name {
        font-size: 20px;
        letter-spacing: 3px;
        line-height: 28px;
        margin-bottom: 8px;

        @include tablet {
          font-size: 30px;
          letter-spacing: 4.5px;
          line-height: 36px;
          margin-bottom: 10px;
        }
      }

      .the-ritual-of-title {
        font-size: 11px;
        letter-spacing: 1.65px;
        line-height: 24px;
        color: $rit-copper-dark;
        margin-bottom: 5px;

        @include tablet {
          margin-bottom: 14px;
        }
      }
    }

    .product-tile {
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;

      @include tablet {
        flex-direction: row;
      }
    }

    .long-description {
      margin-bottom: 24px;

      @include tablet {
        margin-bottom: 25px;
      }
    }

    .product-price {
      margin-bottom: 21px;

      @include tablet {
        margin-bottom: 26px;
      }
    }

    .product-add-to-cart {
      margin-bottom: 18px;
    }
  }
}

.elixirs-list {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 15px 0 1px;

  @include tablet {
    margin: 21px 0 0;
  }
}

.quiz-results-grid {
  overflow: hidden;
  text-align: center;
  padding-bottom: 80px;

  @include tablet {
    padding-bottom: 40px;
  }

  .grid-heading {
    font-size: 24px;
    letter-spacing: 1.6px;
    line-height: 30px;
    text-transform: none;
    margin-bottom: 24px;
    padding: 0 40px;

    @include tablet {
      font-size: 30px;
      letter-spacing: 0.58px;
      line-height: 44px;
      margin-bottom: 32px;
      padding: 0;
    }
  }
}
