// ***************** CORE *****************
//
// Description:
// Core HTML element styling. e.g. img, p, ul, a, headings font-face.
// --------------------------------------------------

html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  background-color: $rit-sandy-dark;
  @include body-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    width: 100%;

    @include mobile-only {
      .sticky-bar {
        opacity: 0;
        transition: opacity 1s ease-in-out;
      }
    }
  }

  &.personalised-gifting-bg {
    background-color: $rit-sandy-dark;
  }

  &.personalised-gifting-page {
    background-color: $white;

    .pt_product-details {
      overflow: hidden;
      position: relative;
      width: 100%;
    }
  }
}

p {
  @include body-text;
  margin-bottom: 10px;

  a {
    @include mobile-only {
      font-size: 14px;
      letter-spacing: .8px;
      line-height: 26px;
    }
  }
}

a,
.link {
  font-family: $font-body;
  font-style: normal;
  font-size: 16px;
  color: $rit-copper-dark;
  line-height: 28px;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;

  &.link--large {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none;
  }
}

.content {
  ul {
    list-style-type: disc;
  }
}
