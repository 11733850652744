h1 {
  @include headings(l);
}

h2,
h3 {
  @include headings(m);
}

h4,
h5,
h6 {
  @include headings(s);
}

.headline {
  color: $rit-black;
  font-family: $font-header;
  font-size: 54px;
  letter-spacing: 8px;
  line-height: 58px;
  text-transform: uppercase;

  @include mobile-only {
    font-size: 30px;
    letter-spacing: 8px;
    line-height: 40px;
  }
}

.heading-h1 {
  @include section-header(h1);
}

.heading-h2 {
  @include section-header(h2);
}

.heading-h3 {
  @include section-header(h3);
}

.heading-intro {
  @include section-header(intro);
}

.heading-subtitle {
  @include section-header(subtitle);
}

.heading-h4 {
  @include section-header(h4);
}

.heading-h5 {
  @include section-header(h5);
}

.heading-h6 {
  @include section-header(h6);
}

.heading-h7 {
  @include section-header(h7);
}

.heading-h8 {
  @include section-header(h8);
}

.heading-h9 {
  @include section-header(h9);
}

.heading-h10 {
  @include section-header(h10);
}

.heading-h11 {
  @include section-header(h11);
}

.promotional-label {
  color: $rit-black;
  font-family: $font-body;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding: 8px 16px;

  &::first-letter {
    text-transform: capitalize;
  }
}

.the-ritual-of-title {
  font-family: $font-header;
  font-size: 15px;
  color: $black;
  display: block;
  line-height: 23px;
  letter-spacing: 5px;
  text-transform: uppercase;

  &.small {
    font-size: 11px;
    line-height: 18px;
    color: $copper;
    letter-spacing: 1.5px;
  }
}

.product-name {
  display: block;
  font-family: $font-header;
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 40px;
  color: $black;
  text-transform: none;

  @include tablet {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 50px;
  }

  &.small {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
}

.body-copy-medium {
  color: $rit-black;
  font-family: $font-header;
  font-size: 20px;
  letter-spacing: .4px;
  line-height: 33px;

  a {
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: .4px;
    line-height: 33px;
  }
}

.body-copy-large {
  color: $rit-black;
  font-family: $font-header;
  font-size: 30px;
  letter-spacing: .6px;
  line-height: 40px;

  a {
    font-family: $font-header;
    font-size: 30px;
    letter-spacing: .6px;
    line-height: 40px;
  }
}

.body-copy-tiles {
  color: $rit-black;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: .2px;
  line-height: 13px;
}

.body-copy-desc-info {
  font-family: $font-body;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;

  a {
    font-size: 12px;
  }
}

.body-copy-desc {
  color: rgba($rit-black, .5);
  font-size: 11px;
  font-weight: bold;
  letter-spacing: .2px;
  line-height: 13px;
}
