// CRM 2.0 Reveal open / close elements
.phase-section-wrapper {
  background-image: url('../images/my-rituals/envelope-gift-reveal-bckg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    background: linear-gradient(to bottom, rgba($rit-dark-crm, 1) 1%, rgba($rit-dark-crm, 1) 90%, rgba($rit-dark-crm, 0) 100%);
    content: '';
    height: 330px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .pt_order-confirmation & {
    &::before {
      background: linear-gradient(to bottom, rgba($rit-dark-crm, 1) 1%, rgba($rit-dark-crm, 1) 70%, rgba($rit-dark-crm, 0) 100%);
      height: 70%;
    }
  }

  .product-grid-block {
    background-color: $rit-sandy-dark;

    .inner-wrapper {
      h1 {
        font-size: 12px;
        letter-spacing: 1.7px;
        color: $rit-copper;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include desktop {
          margin-bottom: 20px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
        color: $rit-black;
        letter-spacing: 0.5px;

        @include desktop {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 20px;
        }
      }

      .simple-text-block {
        .btn {
          background-color: transparent;
          border: 1px solid $gray-disabled;
          color: $rit-black;
          padding: 16px 40px;

          @include mobile-and-tablet {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.phase-bck-button {
  margin: 0 auto -20px auto;
  max-width: 1400px;
  padding-top: 20px;

  .back-button {
    color: $white;
    text-decoration: initial;
    z-index: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  @include mobile-only {
    margin-bottom: -10px;
    padding: 10px 20px;
    position: absolute;
  }
}

.phase-section {
  position: relative;
  padding: 88px 0 40px;
  overflow: hidden;

  @include mobile-only {
    padding-top: 41px;
    transition: padding-top 0.8s $navigation-ease;
  }

  .pt_order-confirmation & {
    padding: 40px 0;
  }

  &.is-loading {
    & > div,
    & > header {
      visibility: hidden;
    }
  }

  h2 {
    color: $white;
    font-size: 40px;
    letter-spacing: 1.8px;
    line-height: 48px;
    margin-top: 10px;
    text-transform: initial;
    padding: 0 20px;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 32px;
    }
  }

  p {
    color: $white;
  }

  .heading-h9 {
    color: $rit-copper;
    display: block;
  }

  .reveal-base {
    opacity: 0;
    transform: translate3d(0, 20px, 0);

    &.reveal {
      opacity: 1 !important;
      transform: translate3d(0, 0, 0) !important;
      transition: opacity 2s $navigation-ease,
        transform 2s $navigation-ease;
    }
  }

  // Specific reveal overrides
  .giftopen-image.reveal-base {
    transform: translate3d(0, 20px, 0);

    &.reveal {
      transform: translate3d(0, 0, 0) !important;
    }
  }
}

.phase-header {
  text-align: center;

  .phase-video-mask {
    height: 176px;
    margin: auto;
    position: relative;
    width: 176px;

    &::after {
      background: radial-gradient(circle, rgba($rit-dark-crm, 0) 0%, rgba($rit-dark-crm, 0) 50%, rgba($rit-dark-crm, 1) 70%, rgba($rit-dark-crm, 1) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .pt_order-confirmation & {
      margin: -20px auto 0 auto;
    }
  }

  .phase-icon {
    height: 176px;
    width: 176px;
  }

  .phase-video {
    height: 100%;
    width: 100%;
  }
}

.phase-section-open {
  z-index: 2;

  .phase-header,
  .phase-journey {
    filter: blur(6px);
  }

  &:not(.phase-section-double) {
    .slick-list {
      @include mobile-only {
        padding: 0 !important;
      }
    }

    .giftopen-boxes {
      .slick-list,
      .slick-track,
      .slick-slide,
      .giftopen-single-box {
        @include mobile-large {
          max-width: 420px;
          width: 100% !important;
        }
      }
    }
  }

  &.phase-section-double {
    .slick-list {
      @include mobile-only {
        left: -10% !important;
      }
    }
  }

  .giftopen-boxes {
    @include mobile-large {
      padding-top: 113px;
    }

    @include mobile-only {
      padding-top: 191px;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide:not(.slick-current) .giftopen-single-box {
    @include mobile-only {
      opacity: 0;
    }
  }

  @include mobile-only {
    padding-top: 0;
  }
}

.phase-section-double {
  .phase-header {
    @include mobile-only {
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  .giftopen-boxes {
    @include mobile-only {
      padding-top: 190px;
    }
  }

  .giftopen-boxes-inner {
    @include mobile-only {
      display: initial !important;
    }

    & > div {
      @include tablet {
        margin: 0 20px;
        width: calc(50% - 40px);
      }
    }
  }

  .slick-slide {
    margin: 0 8px;
  }

  .slick-list {
    @include mobile-only {
      left: 0;
      transition: left 1.2s $navigation-ease;
    }
  }

  &.phase-section-open {
    @include mobile-large {
      .giftopen-boxes {
        padding-top: 289px;
      }
    }

    @include mobile-only {
      .giftopen-boxes {
        padding-top: 367px;
      }

      .phase-journey {
        margin-top: 570px;
      }
    }

    .slick-track {
      transform: initial !important;
    }
  }

  .giftopen-single-box {
    @include mobile-only {
      height: 100%;
      margin-right: 16px;
      max-width: initial;
      transition: width 0.8s $navigation-ease;
      width: 100%;
    }
  }

  .giftopen-single-box-open {
    @include mobile-large {
      max-width: 100vw;
    }

    @include mobile-only {
      margin-left: 0;
      position: absolute;
      top: 0;
      transform: translate3d(-50%, 0, 0);
      width: 100%;
      z-index: 5;
    }
  }

  .giftopen-boxes-line {
    @include tablet {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate3d(-50%, 0, 0);
        height: 94%;
        width: 1px;
        background-color: $rit-gold;
      }
    }
  }
}

// Gift reveal elements
.giftopen-boxes {
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .giftopen-single-box {
    display: flex !important;
    flex-direction: column;
    height: auto;

    @include mobile-only {
      max-width: 327px;
    }

    @include tablet {
      flex: 1;
      height: 550px;
      transition: height 0.5s ease-in-out;
    }

    &.giftopen-single-box-open {
      @include tablet {
        height: 811px;
      }
    }
  }

  .giftopen-single-box-open {
    .giftopen-heading {
      filter: blur(6px);

      @include mobile-only {
        position: absolute;
      }
    }

    .giftopen-box {
      transform: scale(1.1);

      @include mobile-large {
        margin-top: 0;
        max-width: 420px;
        transform: scale(1.01);
      }
    }

    .giftopen-image {
      transform: translate3d(-5%, -265px, 0);
      width: 100%;
    }

    .giftopen-envelope-border {
      opacity: 0;
      visibility: hidden;
    }

    .giftopen-icon.icon-gift-filled {
      transform: translate3d(-50%, -150px, 0);
      visibility: hidden;
      opacity: 0;
    }

    .giftopen-ribbon-single-1 {
      transform: translate3d(50%, 0, 0);
    }

    .giftopen-ribbon-single-2 {
      transform: translate3d(-150%, 0, 0) rotate(-6deg);
    }

    .giftopen-ribbon-single-3 {
      transform: translate3d(50%, 0, 0) rotate(6deg);
    }

    .giftopen-envelope-image {
      filter: brightness(0) invert(1);
    }

    .gift-reveal-body {
      @include tablet {
        height: unset;
        z-index: 1;
      }
    }
  }

  .giftopen-boxes-inner {
    display: flex;
    justify-content: center;

    @include mobile-only {
      .slick-track {
        display: flex !important;
      }

      .slick-slide {
        height: inherit !important;

        & > div {
          height: 100%;
        }
      }
    }
  }

  .giftopen-heading {
    max-width: 640px;
    margin: 0 auto 47px;
    text-align: center;

    .heading-h9 {
      color: $rit-gold;
    }
  }

  .giftopen-box {
    position: relative;

    @include mobile-only {
      background-color: $white;
      margin: 0;
      transition: transform 0.8s $navigation-ease,
        margin-top 0.8s $navigation-ease;
      width: 100%;
    }

    @include tablet {
      margin: auto auto 0;
      transition: transform 0.8s $navigation-ease;
      width: 327px;
    }

    .giftopen-icon {
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      padding: 18px 8.1px;
      font-size: 23px;
      color: $rit-gold-dark;
      border-radius: 50%;
      background-color: $white;
      z-index: 4;
      width: 64px;
      height: 64px;
      transition: visibility 1.5s $navigation-ease,
        opacity 1.5s $navigation-ease,
        transform 1.5s $navigation-ease;

      &::before {
        border: 1px solid $rit-gold-dark;
        border-radius: 50%;
        padding: 11px 12px;
      }
    }

    .giftopen-btn {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      transition:
        visibility 1.2s $navigation-ease,
        opacity 1.2s $navigation-ease;
      z-index: 1;
    }
  }

  .giftopen-envelope {
    position: relative;
    min-height: 344px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .giftopen-envelope-image {
      width: 435px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      clip-path: inset(0 54px 0 54px);

      @include mobile-large {
        width: 100%;
        clip-path: initial;
        object-fit: cover;
      }
    }

    @media screen and (max-width: 350px) {
      min-height: 310px;
    }
  }

  .giftopen-envelope-border {
    transition: visibility 1s $navigation-ease,
      opacity 1s $navigation-ease;
  }

  .giftopen-image {
    position: absolute;
    top: -16px;
    left: 5%;
    width: 90%;
    height: 100%;
    overflow: hidden;
    background-color: $rit-dark;
    transition: transform 1.2s $navigation-ease,
      width 1.2s $navigation-ease;

    img {
      object-fit: cover;
    }
  }

  .giftopen-close-btn {
    position: absolute;
    left: 24px;
    top: 24px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    background-color: $white;
    transition:
      visibility 1.2s $navigation-ease,
      opacity 1.2s $navigation-ease;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    width: 43.65px;
    height: 43.65px;

    &.icon-close {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 34px;
        width: 20px;
      }
    }
  }

  .giftopen-btn-show {
    visibility: visible;
    opacity: 1;
  }

  .giftopen-btn-hide {
    visibility: hidden;
    opacity: 0;
  }

  .giftopen-timer {
    position: absolute;
    top: 69%;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    padding: 8px 10px;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 1;
    background-color: $white;
    z-index: 1;
    transition: transform 1.2s $navigation-ease;

    .icon-duration {
      margin-right: 8px;
      font-size: 10.61px;
      width: 12px;
      height: 12px;
    }
  }

  .giftopen-timer-show {
    transform: translate3d(0, 0, 0);
  }

  .giftopen-no-timer {
    display: none;
  }

  .giftopen-ribbon {
    position: absolute;
    top: 81px;
    width: 100%;
    height: 200px;
    overflow: hidden;
    z-index: 1;
  }

  .giftopen-ribbon-single {
    position: absolute;
    height: 24px;
    width: 408px;
    left: 50%;
    background-image: url('../images/my-rituals/envelope-track.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 1px 8px -4px rgba(0, 0, 0, 0.5);
    transition: transform 1.7s $navigation-ease;
  }

  .giftopen-ribbon-single-1 {
    transform: translate3d(-50%, 0, 0);
    z-index: 3;
  }

  .giftopen-ribbon-single-2 {
    top: 25px;
    transform: translate3d(-50%, 0, 0) rotate(-6deg);
    z-index: 2;
  }

  .giftopen-ribbon-single-3 {
    top: 32px;
    transform: translate3d(-50%, 0, 0) rotate(6deg);
    z-index: 1;
  }
}

.gift-reveal-body {
  display: none;
  background-color: $white;
  margin-bottom: -300px;
  opacity: 0;
  padding: 0 24px 24px;
  position: relative;
  text-align: center;
  top: 20px;
  transform: translate3d(0, -300px, 0);
  z-index: 1;

  @include mobile-large {
    transform: translate3d(0, -270px, 0);
    margin-bottom: -270px;
  }

  @include tablet {
    display: block;
    height: 300px;
    margin-bottom: unset;
    transition: height 0.5s ease-in-out;
    z-index: -1;
  }

  .gift-reveal-inner {
    p {
      color: $rit-dark;
    }
  }

  .gift-reveal-text {
    margin-bottom: 40px;
  }

  .gift-reveal-title {
    margin: 5px 0;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .gift-reveal-product-info {
    display: block;
    margin-bottom: 20px;
    font-size: 12px;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  }

  hr {
    position: relative;
    width: 144px;
    height: 1px;
    display: block;
    margin: -12px auto 24px;
    padding: 12px 0;
    border: 0;
    background-color: $white;

    &::before {
      background-image: linear-gradient(90deg,
          rgba($rit-black, 0) 0%,
          rgba($rit-black, 0.4) 50%,
          rgba($rit-black, 0) 100%);
      content: '';
      height: 1px;
      left: 50%;
      top: 11px;
      position: absolute;
      transform: translate3d(-50%, 0, 0);
      width: 100%;
      z-index: 1;
    }

    &::after {
      background-color: $white;
      content: attr(data-text);
      font-size: 14px;
      left: 50%;
      letter-spacing: 0.4px;
      padding: 0 14px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .member-card {
    width: initial;
  }

  .store-google-play {
    margin: 0 auto 24px auto;
  }

  .inline-alert {
    text-align: left;
  }
}

// Phase Journey
.phase-journey {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include mobile-only {
    padding: 24px 0;
    text-align: center;
  }

  .giftopen-boxes + & {
    margin-top: 40px;
  }

  .phase-timeline {
    height: 128px;
    position: relative;
    width: 100%;

    .timeline-markers {
      position: relative;

      &.phase-gratitude {
        span.prev-phase {
          display: none;
        }
      }

      &.phase-soulmate {
        span.next-phase {
          display: none;
        }
      }

      span {
        border-radius: 50%;
        height: 18px;
        width: 18px;
        position: absolute;
        z-index: 10;

        &.current-phase {
          background-color: $rit-gold;
          left: 50%;
          transform: translateX(-50%);
          top: 50px;

          @include mobile-only {
            top: 78px;
          }

          &::before,
          &::after {
            border-radius: 50%;
            border: 1px solid $rit-gold;
            content: '';
            height: 18px;
            left: -1px;
            position: absolute;
            top: -1px;
            transform-origin: center;
            width: 18px;
          }

          &::before {
            animation: pulsating-border 3s linear infinite;
          }

          &::after {
            animation: pulsating-border 3s linear infinite .8s;
          }
        }

        &.next-phase {
          background-color: $rit-black;
          border: 1px solid $gray-disabled;
          left: calc(50% + 300px);
          top: 14px;

          @include mobile-only {
            display: none;
          }

          @include desktop-large {
            left: calc(50% + 440px);
            top: 88px;
          }
        }

        &.prev-phase {
          background-color: $rit-gold;
          left: calc(50% - 330px);
          top: 40px;

          @include mobile-only {
            display: none;
          }

          @include desktop-large {
            left: calc(50% - 440px);
            top: -2px;
          }
        }
      }
    }

    .timeline-progress,
    .timeline-path {
      background-position: center bottom;
      background-repeat: repeat-x;
      display: block;
      height: 100%;
      margin: 0 auto;
      position: absolute;
      width: 100%;
    }

    .timeline-progress-mask {
      height: 100%;
      overflow: hidden;
      position: absolute;
      width: 50%;

      .timeline-progress {
        z-index: 2;
        width: 200%;

        @include mobile-only {
          background-image: url('../images/my-rituals/my-rituals-phase-progress-line-solid-mobile.svg');
        }

        @include tablet {
          background-image: url('../images/my-rituals/my-rituals-phase-progress-line-solid-desktop.svg');
        }
      }
    }

    .timeline-path {
      z-index: 1;

      @include mobile-only {
        background-image: url('../images/my-rituals/my-rituals-phase-progress-line-dashed-mobile.svg');
      }

      @include tablet {
        background-image: url('../images/my-rituals/my-rituals-phase-progress-line-dashed-desktop.svg');
      }
    }
  }

  .heading-h9 {
    color: $rit-gold;
  }

  .heading-h9,
  h2,
  p {
    @include mobile-only {
      padding: 0 24px;
    }
  }

  p + .btn {
    margin-top: 14px;
  }

  .heading-h9 + h2 {
    @include mobile-only {
      margin-top: 8px;
    }
  }
}

@keyframes pulsating-border {
  0% {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }

  35% {
    opacity: 0.6;
    transform: scale3d(1.6, 1.6, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(2.5, 2.5, 1);
  }
}

// Three tile (benefits) block
.three-tile-phase.three-tile-block {
  display: block;
  margin-bottom: 0;
  background-color: initial;

  @include mobile-only {
    padding: 40px 0;
  }

  @include tablet {
    padding: 50px 0 80px;
  }

  h2 {
    margin-bottom: 24px;
    padding: 0 20px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: initial;
    color: $white;

    @include mobile-only {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.8px;
    }
  }

  .inner-wrapper {
    @include tablet {
      justify-content: center;
    }
  }

  .three-tile-box {
    max-width: 274px;
    margin-right: 16px;
    margin-bottom: 0;

    @include mobile-only {
      height: 100%;
    }

    .text-holder {
      justify-content: unset;
    }
  }

  .image-holder {
    padding-bottom: 54.75%;
  }

  .text-holder {
    padding: 24px;

    .heading-h8 {
      letter-spacing: 0.4px;

      @include mobile-only {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
    }

    a {
      font-size: 14px;
    }
  }

  .slick-list {
    padding: 0 20% 0 24px !important;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    margin-right: 16px;

    & > div {
      height: 100%;
    }
  }

  &.three-tile-phase-bckg {
    background-color: $rit-black-lighter;
  }
}

.redeem-wrapper {
  align-items: center;
  background-color: $rit-sandy-bg;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .redeem-product {
    align-items: center;
    border: 1px solid $gray-gainsboro;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto 24px;
    padding: 56px 24px 24px;
    position: relative;
    width: 780px;

    &.pp-out-of-stock {
      .oos-icon {
        height: 32px;
        width: 32px;

        &::after {
          top: 13px;
        }
      }
    }

    @include mobile-and-tablet {
      align-items: flex-end;
      border-left-color: transparent;
      border-right-color: transparent;
      padding: 16px;
      width: auto;
    }

    .product-info {
      align-items: center;
      display: flex;
      flex: 1;
      margin-left: 24px;

      @include mobile-and-tablet {
        align-items: flex-start;
        flex-direction: column;
        margin-left: 16px;
        max-width: 70%;
      }

      .product-info-inner {
        margin-right: 24px;
        text-align: left;

        @include mobile-and-tablet {
          margin-right: 0;
        }
      }
    }

    .product-name {
      color: $rit-black;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;
      pointer-events: none;

      a {
        text-decoration: none;
      }
    }

    .product-collection {
      color: $rit-copper;
      font-size: 12px;
      letter-spacing: 1.8px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .short-description {
      color: $gray-dark;
      font-size: 14px;
      margin-bottom: 0;
    }

    .product-price {
      display: flex;
      flex-direction: column;
      margin-left: auto;

      @include mobile-and-tablet {
        margin: 0;
      }

      .product-sales-price {
        color: $rit-black;
        font-size: 18px;
      }

      .product-beforediscount-price {
        color: $grey-medium-light;
        font-size: 14px;
        text-decoration: line-through;
      }

      .base-price-block {
        margin-top: 8px
      }
    }

    .product-image {
      height: 64px;
      margin-right: 16px;
      position: relative;
      width: 64px;

      @include mobile-and-tablet {
        margin-right: 16px;
      }

      img {
        width: 100%;
      }
    }

    .shopping-cart-block & {
      border-left: none;
      border-right: none;
      padding: 56px 40px 16px;
      width: 100%;

      @include mobile-and-tablet {
        padding: 16px;
      }
    }
  }

  .btn {
    min-width: 0;
    padding: 16px 32px;
  }

  .btn-out-of-stock {
    background-color: $gray-gainsboro;
    border: none;
  }
}

.personal-promotion-content {
  padding: 48px 0 24px 0;

  .ui-dialog-titlebar {
    .ui-dialog-titlebar-close {
      right: 60px;
      top: -18px;
    }

    .ui-icon-closethick {
      border-radius: 50px;
      border: 1px solid rgba($rit-black, 0.16);
      padding: 10px 13px;

      &::before {
        color: $rit-black;
        display: block;
        margin: -3px 0 0 1px;
      }
    }
  }

  .redeem-wrapper {
    background-color: transparent;
    padding-bottom: 16px;

    .redeem-product {
      margin-bottom: 0;
      padding: 21px 24px 15px;
      width: 100%;
    }

    .product-collection {
      margin-bottom: 6px;
    }

    .product-image {
      height: 64px;
      margin-right: 15px;
      width: 64px;
    }

    .product-name {
      font-size: 20px;
      margin-bottom: 2px;
    }

    .product-price {
      align-items: flex-end;
      flex-direction: column-reverse;

      .product-sales-price,
      .product-beforediscount-price {
        font-family: $font-header;
      }

      .product-beforediscount-price {
        font-size: 16px;
      }

      .product-sales-price {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .short-description {
      line-height: 24px;
      margin-bottom: 0;
    }

    .mini-cart-pricing {
      color: $gray-dark;
      font-family: $font-body;
      font-size: 14px;
      font-weight: lighter;
      line-height: 24px;
    }
  }

  .popup-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;

    .remove-gwp-button {
      line-height: 1.5;
    }

    .keep-gwp-button {
      color: $rit-copper;
      font-family: $font-body;
      font-weight: lighter;
      margin-top: 30px;
      text-decoration: underline;
    }
  }

  .popup-text {
    text-align: center;
  }

  .popup-text-main,
  .popup-text-description {
    color: $rit-black;
  }

  .popup-text-main {
    font-family: $font-header;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .popup-text-description {
    font-family: $font-body;
    font-size: 14px;
    font-weight: lighter;
    line-height: 24px;
    margin: 0 auto;
    padding-bottom: 38px;
    width: 75%;
  }
}

.personal-promotion-content {
  padding: 48px 0 24px 0;

  @include mobile-and-tablet {
    padding: 40px 0 16px 0;
  }

  .close-toaster {
    &::before {
      color: $rit-black;
    }
  }

  .toaster-content {
    flex-direction: column;
  }

  .ui-dialog-titlebar {
    .ui-dialog-titlebar-close {
      right: 60px;
      top: -18px;

      @include mobile-and-tablet {
        right: 35px;
        top: -25px;
      }
    }

    .ui-icon-closethick {
      border-radius: 50px;
      border: 1px solid rgba($rit-black, 0.16);
      height: 48px;
      padding: 16px 12px;
      width: 48px;

      @include mobile-and-tablet {
        border: none;
      }
    }
  }

  .redeem-wrapper {
    background-color: transparent;
    padding-bottom: 16px;

    .redeem-product {
      margin-bottom: 0;
      padding: 21px 24px 15px;
      width: 100%;
    }

    .product-info {
      align-items: flex-start;

      @include mobile-and-tablet {
        flex-direction: row;
      }
    }

    .product-collection {
      margin-bottom: 6px;
    }

    .product-image {
      height: 64px;
      margin-right: 15px;
      width: 64px;
    }

    .product-name {
      font-size: 20px;
      margin-bottom: 2px;

      @include mobile-and-tablet {
        font-size: 18px;
      }
    }

    .product-price {
      align-items: flex-end;
      flex-direction: column-reverse;

      .product-sales-price,
      .product-beforediscount-price {
        font-family: $font-header;
      }

      .product-beforediscount-price {
        font-size: 16px;

        @include mobile-and-tablet {
          font-size: 14px;
        }
      }

      .product-sales-price {
        font-size: 20px;
        line-height: 24px;

        @include mobile-and-tablet {
          font-size: 18px;
        }
      }
    }

    .short-description {
      line-height: 24px;
      margin-bottom: 0;
    }

    .mini-cart-pricing {
      color: $gray-dark;
      font-family: $font-body;
      font-size: 14px;
      font-weight: lighter;
      line-height: 24px;

      @include mobile-and-tablet {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .popup-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include mobile-and-tablet {
      margin: 0 auto;
    }

    .remove-gwp-button {
      line-height: 1.5;
      padding: 16px 32px;
    }

    .keep-gwp-button {
      color: $rit-copper;
      font-family: $font-body;
      font-size: 14px;
      font-weight: lighter;
      line-height: 24px;
      margin-top: 30px;
      text-decoration: underline;

      @include mobile-and-tablet {
        line-height: 16px;
        margin-top: 16px;
      }
    }
  }

  .popup-text {
    text-align: center;

    @include mobile-and-tablet {
      margin: 0 auto;
    }
  }

  .popup-text-main,
  .popup-text-description {
    color: $rit-black;
  }

  .popup-text-main {
    font-family: $font-header;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;

    @include mobile-and-tablet {
      font-size: 18px;
    }
  }

  .popup-text-description {
    font-family: $font-body;
    font-size: 14px;
    font-weight: lighter;
    line-height: 24px;
    margin: 0 auto;
    padding-bottom: 38px;
    width: 75%;

    @include mobile-and-tablet {
      font-weight: normal;
      padding-bottom: 8px;
    }
  }
}
