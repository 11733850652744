.two-tile-text-block {
  width: 100%;
  padding: 20px 0;

  &.invert {
    @include tablet {
      .text-holder {
        order: -1;
      }
    }
  }

  &.adjacent-blocks + & {
    margin-top: -40px;
  }

  & > .inner-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include tablet {
      flex-direction: row;
      max-width: 1480px;
      padding: 0 20px;
    }
  }

  &.full-width {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include mobile-only {
      background-color: transparent !important;
    }

    @include tablet {
      flex-direction: row;
    }

    &.reverse-order {
      @include mobile-only {
        flex-direction: column-reverse;
      }
    }

    .image-holder {
      position: relative;

      @include mobile-only {
        min-height: 288px;
      }
    }

    .responsive-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .text-holder {
      height: 100%;
      text-align: center;
      min-height: 0;
      padding: 25px 20px 20px;

      @include tablet {
        min-height: 700px;
        max-width: 720px;
        padding: 40px 30px;
      }

      @include desktop {
        padding: 100px 145px;
      }

      @include desktop-large {
        align-items: center;
        display: flex;
        max-width: 50%;
      }

      h3,
      p {
        @include desktop-large {
          max-width: 430px;
          width: 100%;
        }
      }
    }

    .headline {
      font-size: 24px;
      letter-spacing: 1.5px;
      line-height: 32px;
      margin-bottom: 20px;

      @include tablet {
        font-size: 40px;
        letter-spacing: 2px;
        line-height: 48px;
        margin-bottom: 16px;
      }
    }
  }

  &.secondary-text-styling {
    .headline {
      @include tablet {
        color: $rit-dark;
        font-size: 30px;
        letter-spacing: 1.2px;
        line-height: 40px;
        margin-bottom: 30px;
      }
    }

    p {
      color: $rit-dark;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
    }
  }

  &.value-packs {
    background-color: $rit-sandy-dark;

    & > .inner-wrapper {
      @include mobile-only {
        flex-direction: column-reverse;
      }
    }

    .headline {
      @include tablet {
        color: $rit-dark;
        font-size: 30px;
        letter-spacing: 1.2px;
        line-height: 40px;
        margin-bottom: 30px;
      }
    }

    .image-holder {
      background-size: cover;
    }

    .content-list {
      li {
        margin-bottom: 20px;
      }
    }

    .icon-check,
    .icon-close-thick {
      color: $rit-copper-dark !important;
    }

    span:not(.ico) {
      color: $rit-dark;

      @include mobile-only {
        font-size: 14px;
        letter-spacing: 0.8px;
        line-height: 24px;
      }

      @include tablet {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
      }
    }
  }

  &.green-friday {
    background-color: $rit-sandy-dark;

    & > .inner-wrapper {
      max-width: 100%;
      padding: 0;
    }

    .headline {
      @include tablet {
        color: $rit-dark;
        font-size: 30px;
        letter-spacing: 1.2px;
        line-height: 40px;
        margin-bottom: 30px;
      }
    }

    .image-holder {
      background-size: cover;
    }

    .text-holder {
      max-width: 100%;

      @include tablet {
        max-width: 100%;
      }
    }

    .center-list {
      text-align: center;

      .content-list {
        display: inline-block;
        text-align: left;
        margin-left: 30px;

        li {
          margin-bottom: 20px;
        }
      }
    }

    .icon-check,
    .icon-close-thick {
      color: $rit-copper-dark !important;
    }

    span:not(.ico) {
      color: $rit-dark;

      @include mobile-only {
        font-size: 14px;
        letter-spacing: 0.8px;
        line-height: 24px;
      }

      @include tablet {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
      }
    }
  }

  &.transparent-bg {
    .text-holder {
      background-color: transparent;
    }
  }

  &.sandy-light-bg {
    .text-holder {
      background-color: $rit-sandy-light;
    }
  }

  .text-holder {
    background-color: $white;
    padding: 40px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 330px;

    @include tablet {
      width: 50%;
    }

    @include desktop {
      padding: 100px;
    }
  }

  .image-holder {
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }

  .two-tile-text-block-slider {
    position: relative;
    height: 330px;

    @include tablet {
      height: 100%;
    }

    .responsive-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .item {
      min-height: 330px;
      height: 100%;

      @include tablet {
        min-height: 642px;
      }
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.3s ease;

      &.slick-active {
        opacity: 1;
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .slick-prev {
      left: 10px;

      @include tablet {
        left: 48px;
      }
    }

    .slick-next {
      right: 10px;

      @include tablet {
        right: 48px;
      }
    }

    &:not(.drag-list-scroll) {
      overflow: hidden;

      .item + .item {
        display: none;
      }

      .slick-arrow .slider-button {
        height: 30px;
        width: 30px;
        font-size: 32px;
        background-color: rgba($white, 0.5);

        @include tablet {
          height: 50px;
          width: 50px;
          font-size: 44px;

          &:hover {
            background-color: rgba($white, 1);
          }
        }
      }
    }
  }

  .headline {
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
    text-transform: none;
    margin-bottom: 23px;

    @include tablet {
      margin-bottom: 32px;
    }

    .ico {
      font-size: 40px;
      margin-right: 11px;
      color: #58595b;

      @include mobile-only {
        display: block;
        margin-bottom: 25px;
      }
    }
  }

  p,
  a,
  button:not(.slider-button) {
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
  }

  button:not(.slider-button) {
    width: auto;
    margin-bottom: 10px;
    text-align: left;
  }

  p {
    &.small {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1px;
    }

    button {
      margin: 0;
    }
  }

  .content-list {
    list-style-type: none;
    text-align: left;

    @include desktop {
      margin-left: -31px;
    }

    li {
      margin-bottom: 10px;

      span {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
        width: calc(100% - 40px);
        display: inline-block;
      }

      .ico {
        font-size: 14px;
        margin-right: 9px;
        vertical-align: top;
        display: inline-block;
        width: 15px;
      }

      .icon-check {
        color: #3bd321;
      }

      .icon-close-thick {
        color: #d0051a;
      }
    }
  }
}

.two-tile-text-block .drag-list {
  margin-bottom: 0;

  .simplebar-content {
    min-height: 100%;

    .drag-list-body {
      min-height: 100%;
    }
  }

  .drag-list-body {
    padding: 0;
  }

  .item {
    @include mobile-only {
      width: 100vw;
    }
  }

  .slider-button {
    background-color: rgba($white, 0.5);

    &:hover {
      background-color: $white;
    }
  }

  .drag-list-controls {
    @include mobile-only {
      display: block;

      .slider-button {
        font-size: 32px;
        height: 30px;
        width: 30px;
      }

      .icon-arrow3--left {
        left: 10px;
      }

      .icon-arrow3--right {
        right: 10px;
      }
    }
  }
}
