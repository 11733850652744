.animated-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .fade-in-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s $navigation-ease, transform 1s $navigation-ease;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &.video-holder {
      transition: opacity 1s $navigation-ease, width 0.3s $navigation-ease;
    }

    &.video-wrapper {
      @include tablet {
        inset: 0 auto auto 0 !important;
      }
    }
  }

  .text-holder {
    left: 0;
    position: absolute;
    text-align: center;
    transition: transform 0.3s linear;
    width: 100%;
    will-change: transform;
    z-index: 10;

    @include mobile-only {
      padding: 40px 0;
    }

    @include tablet {
      padding: 100px 0 50px 0;
    }

    &.visible {
      .hidden-content {
        opacity: 1;
      }

      .visible-content {
        opacity: 0;
      }
    }

    .toggle-content {
      position: relative;

      &:first-of-type {
        margin-bottom: 28px;

        @include mobile-only {
          margin-bottom: 10px;
        }
      }
    }

    .visible-content,
    .hidden-content {
      transition: opacity 1s $navigation-ease;
    }

    .hidden-content {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  .subheading {
    font-size: 22px;
    letter-spacing: 5px;
    line-height: 32px;
    font-family: $font-header;
    text-transform: uppercase;
    display: block;

    @include mobile-only {
      font-size: 12px;
      letter-spacing: 2.3px;
      line-height: 32px;
    }
  }

  .heading {
    font-size: 80px;
    letter-spacing: 4.3px;
    line-height: 90px;
    font-family: $font-header;
    text-transform: none;
    margin-bottom: 35px;

    @include mobile-extra-small {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 15px;
    }

    @include mobile-only {
      font-size: 42px;
      letter-spacing: 2.25px;
      line-height: 52px;
      margin-bottom: 22px;
    }
  }

  .countdown-line {
    display: block;
    color: $white;
    font-family: $font-header;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.7px;
    margin: -8px 0 40px 0;
  }

  .scroll-icon-wrapper {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding-bottom: 50px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .scroll-icon {
    position: sticky;

    @include mobile-only {
      height: 45px;
      width: auto;
    }

    .mousePart {
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: scrollIndicator;
    }

    @keyframes scrollIndicator {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translateY(75%);
      }
    }
  }

  .scroll-animation-wrapper {
    margin-top: 335px;
    min-height: 1px;
    padding: 0;
    width: 100%;

    @include mobile-only {
      margin-top: 250px;
    }

    @include tablet {
      margin-top: 500px;
    }

    @include desktop {
      margin-top: 400px;
    }
  }

  .video-wrapper {
    position: relative;
    width: 100%;

    .s7videoplayer video {
      opacity: 0.4;
    }
  }

  .video-holder {
    position: relative;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    transform: translateZ(0);
  }

  .countdown {
    display: block;
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 0.7px;
    margin: -10px 0 36px;
  }

  .btn {
    margin-bottom: 30px;

    @include mobile-extra-small {
      margin-bottom: 25px;
    }
  }

  .scroll-magic-trigger {
    position: absolute;
    top: 40vh;
  }
}

.second-scroll-magic-trigger {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
}

.animated-banner-giftsets {
  .subheading,
  .heading {
    color: $rit-black;
    transition: color 0.2s ease-in-out;
  }

  .subheading-inner {
    color: $white;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
    margin-bottom: 40px;

    @include mobile-extra-small {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .visible {
    .subheading,
    .heading {
      color: $white;
    }
  }

  .scroll-animation-wrapper {
    @include mobile-only {
      margin-top: 300px;
    }
  }

  .video-wrapper {
    .video-holder {
      clip-path: inset(0 13%);
      transition: clip-path 0.1s linear;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $rit-black;
        opacity: 0.4;
      }
    }

    img {
      width: 100%;
    }

    .s7videoplayer video {
      opacity: 1;
    }
  }

  .giftset-banner-icon {
    color: $white;
    font-size: 40px;
    margin-bottom: 10px;

    &::before {
      @include icon-styles;
      content: icon-char(candle);
      display: inline-block;
      line-height: 1;
    }
  }
}
