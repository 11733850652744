.form {
  width: 100%;

  .form-row {
    padding-top: 20px;
    position: relative;

    &.label-moved label {
      top: 24px;
    }

    &.label-visible label {
      opacity: 1;
    }
  }

  .input-select {
    background: url('../images/icons/arrow3--down.svg') no-repeat right 7px center;
    background-size: 30px 30px;
    padding: 5px 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  .error {
    font-size: 13px;
  }

  input {
    &.error {
      font-size: 16px;
      border-color: $red-error;
    }
  }

  .btn-submit {
    width: 100%;
  }

  .label-inline,
  .label-radio {
    padding-top: 16px;
    position: relative;

    label {
      color: $rit-dark;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      left: auto;
      letter-spacing: 1px;
      line-height: 28px;
      opacity: 1;
      padding-left: 37px;
      position: relative;
      text-transform: none;
      top: auto;
    }
  }

  .label-inline {
    .field-wrapper {
      left: 0;
      position: absolute;
      top: 0;
    }

    label {
      &::before {
        background-color: $white;
        border: 1px solid $gray-lines;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 4px;
        width: 16px;
      }

      &::after {
        @include icon-styles;
        color: $white;
        content: icon-char(check);
        display: none;
        font-size: 8px;
        left: 3px;
        line-height: 16px;
        position: absolute;
        top: 4px;
      }

      &.checked {
        &::before {
          background-color: $rit-copper-dark;
          border-color: $rit-copper-dark;
        }

        &::after {
          display: inline-block;
        }
      }
    }
  }

  .form-caption {
    font-size: 12px;
    color: $gray-medium;
  }

  @include tablet {
    .form-row {
      padding-top: 0;
      position: relative;
    }

    .btn-submit {
      padding: 15px 34px 14px;
      width: auto;
    }

    .label-inline {
      padding-top: 25px;
    }
  }
}

fieldset {
  min-width: 0;

  & + fieldset {
    margin-top: 40px;
  }
}

.recaptcha-container {
  div {
    margin: 0 auto;
    z-index: 1;
  }
}

//styles for textarea in redesign
textarea {
  background-color: $white;
  border: 1px solid $gray-lines;
  border-radius: 0;
  color: $rit-black;
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  padding: 12px 19px;
  width: 100%;
  resize: none;
  overflow: auto;

  &::placeholder {
    color: $gray-silver;
  }
}

.input-textarea {
  resize: none;
  overflow: auto;

  &::placeholder {
    color: $gray-silver;
  }
}

.error-form {
  color: $red-error;
  text-align: left;
}

.in-form-button {
  margin-top: 10px;
}

.in-form-error {
  font-size: 13px;
}

.avatax-address-validation-error {
  width: 100%;
  margin-top: 15px;

  @include tablet {
    text-align: center;
    margin-top: 26px;
  }

  .error {
    line-height: 17px;
  }
}

.register-snn-wrapper {
  margin-bottom: 15px;

  .field-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-caption,
  .error {
    width: 100%;
  }

  .inputfield-text {
    flex-grow: 1;
    height: 45px;
    width: auto;

    @include mobile-only {
      padding: 12px 15px;
    }
  }

  .in-form-button {
    margin: 4px 0 0 10px;
    width: auto;

    .btn {
      border-color: $gray-gainsboro;
      min-width: 160px;

      @include mobile-only {
        min-width: 10px;
      }
    }
  }
}

.inputfield-select {
  background: $white url('../images/icons/arrow3--down.svg') no-repeat right 10px center;
  background-size: 30px 30px;
  border-radius: 2px;
  border: 1px solid $gray-gainsboro;
  color: $rit-black;
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-top: 4px;
  padding: 12px 35px 12px 21px;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  option:disabled {
    display: none;
  }
}

.input-character-counter {
  bottom: 0;
  color: $gray-light;
  display: inline-block;
  line-height: 45px;
  position: absolute;
  right: 18px;
}

.places-autocomplete.pac-container {
  box-shadow: 0 4px 15px rgba($black, .2);
  font-family: $font-body;

  &::after {
    border-top: 1px solid $gray-lines;
  }

  .pac-item {
    border-color: $gray-lines;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 12px 17px 8px;

    .pac-icon-marker {
      display: none;
    }

    .pac-item-query {
      font-size: 15px;
    }
  }
}

.rituals-form {
  .inputfield-select {
    &.select-readonly {
      background: transparent;
      border: 0;
      font-family: $font-body-bold;
      font-weight: bold;
      line-height: 19px;
      padding: 5px 0 7px;
      pointer-events: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: rgba($gray-silver, 0.5);
      border: 1px solid rgba($gray-gainsboro, 0.5);
    }
  }

  .selectric-wrapper {
    .selectric-items ul li {
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    &.selectric-readonly,
    &.selectric-custom-disabled {
      cursor: not-allowed;

      .button {
        display: none;
      }
    }

    &.selectric-readonly {
      .selectric {
        background-color: transparent;
        border: 0;
        padding: 5px 0 7px;
        pointer-events: none;

        .label {
          font-family: $font-body-bold;
          font-weight: bold;
          line-height: 19px;
          padding-left: 0;
        }
      }
    }

    &.selectric-custom-disabled {
      .selectric {
        background-color: $rit-sandy-dark;
        border: 1px solid $gray-disabled;
        color: $rit-black;
        pointer-events: none;

        &::after {
          @include icon-styles;
          color: $rit-dark;
          content: icon-char(system-lock);
          font-size: 16px;
          position: absolute;
          right: 16px;
        }
      }
    }
  }

  select.custom-disabled {
    background-color: $rit-sandy-dark;
    border: 1px solid $gray-disabled;
    color: $rit-black;
    pointer-events: none;
    touch-action: none;
  }
}

.form-readonly-info {
  cursor: not-allowed;
  font-family: $font-body-bold;
  font-weight: bold;
  margin-bottom: -10px;
  padding-top: 4px;
}

//Generic styles for a button while loading (no pointer events, no text)
.button-loading {
  pointer-events: none;
  font-size: 0;
  height: 44px;
}

.form-row {
  max-width: 600px;
  margin: 0 auto;
}

.full-width-row {
  height: 100%;
  max-width: none;
  width: 100%;

  &:not(.label-inline) + &:not(.label-inline) {
    margin-top: 32px;
  }

  &.inline-row {
    width: calc(50% - 10px);
  }
}

.full-width-row.three-inline {
  margin-bottom: 20px;

  span.error:not(.hide) {
    bottom: -30px;
    position: absolute;
  }
}

.form-row-button {
  width: 100%;
  max-width: none;

  &:not(.full-width-row) {
    margin-top: 30px;

    @include tablet {
      margin-top: 83px;

      &.password-btn {
        margin-top: 40px;
      }
    }

    &.checkout-guest {
      margin-top: 0;
    }
  }
}

//quote component styles
.quote-heading {
  padding: 100px 0 10px;
}

.quote-link {
  padding: 60px 0 60px;
}
