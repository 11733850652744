.faq-block {
  padding: 68px 5px 64px;
  position: relative;
  width: 100%;

  @include tablet {
    padding: 63px 5px 64px;
    text-align: center;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 26px;
    text-transform: none;

    @include tablet {
      font-size: 40px;
      letter-spacing: 2px;
      line-height: 50px;
      margin-bottom: 39px;
    }
  }

  .faq-theme-subtitle {
    &.txt--white {
      & > * {
        color: $white;
      }
    }

    &.txt--black {
      & > * {
        color: $rit-black;
      }
    }
  }
}

.faq-accordion {
  border-top: 1px solid $gray-disabled;
  margin-bottom: 24px;
  position: relative;
  width: 100%;

  @include tablet {
    border-color: $gray-gainsboro;
    margin: 0 auto 40px;
    max-width: 920px;
    text-align: left;
  }

  & + .faq-contact-text {
    margin-top: -9px;

    @include tablet {
      margin-top: -18px;
    }
  }

  .faq-accordion-item {
    border-bottom: 1px solid $gray-disabled;
    width: 100%;

    @include tablet {
      border-color: $gray-gainsboro;
    }
  }

  .faq-accordion-head {
    align-items: center;
    display: flex;
    min-height: 87px;
    padding: 24px 50px 23px 0;
    position: relative;
    width: 100%;

    .flyin-component & {
      min-height: 0;
    }

    @include mobile-only {
      .flyin-component & {
        padding: 16px 70px 16px 0;
      }
    }

    @include tablet {
      padding: 24px 110px 23px 25px;

      .flyin-component & {
        padding: 16px 70px 16px 0;
      }
    }

    &.open .acc-head-circle {
      transform: rotate(360deg);

      &::after {
        opacity: 0;
      }
    }

    &.open-acc .acc-head-circle {
      color: $black;

      &::before {
        content: icon-char(icon_system_min_circle);
      }
    }
  }

  .acc-head-title {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 0;
    text-transform: none;
  }

  .acc-head-button {
    display: inline-block;
    position: absolute;

    @include mobile-only {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
    }

    @include tablet {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
    }

    .flyin-component & {
      height: 100%;
      top: 0;
      right: 0;
      width: 100%;

      @include mobile-only {
        bottom: auto;
        left: auto;
      }
    }
  }

  .acc-head-circle {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    height: 40px;
    color: $gray-medium;
    font-size: 24px;
    pointer-events: none;
    position: absolute;
    transition: transform .3s ease;
    width: 40px;

    .flyin-component & {
      background: transparent;
    }

    @include mobile-only {
      top: 27px;
      right: -1px;

      .flyin-component & {
        top: 15px;
        right: -1px;
      }
    }

    @include tablet {
      height: 48px;
      width: 48px;
      top: 30px;
      right: 24px;

      .flyin-component & {
        height: 24px;
        width: 24px;
        top: 15px;
        right: 24px;
      }
    }

    &::before {
      @include icon-styles;
      content: icon-char(icon_system_plus_circle);
      display: inline-block;
      line-height: 1;
      margin-top: -3px;

      .flyin-component & {
        width: 10px;
      }
    }
  }

  .faq-accordion-content {
    display: none;
    padding: 0 45px 12px 0;
    width: 100%;

    @include tablet {
      padding: 0 120px 20px 25px;
    }

    &.txt--white {
      & > * {
        color: $white;
      }
    }

    &.txt--black {
      & > * {
        color: $rit-black;
      }
    }


    .flyin-component & {
      padding: 0 0 20px 16px;
    }

    p {
      letter-spacing: 0.5px;
      line-height: 24px;

      @include tablet {
        font-size: 13px;
        letter-spacing: 0.9px;
      }

      .flyin-component & {
        @include tablet {
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
        }
      }
    }
  }
}

.faq-contact-text,
.faq-contact-text a {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.faq-contact-text {
  display: block;
  text-align: left;
  margin: 24px auto 0;
  max-width: 920px;
  color: #9f9f9f;

  @include tablet {
    margin-top: 40px;
  }

  a {
    color: $rit-copper-dark;
  }
}

.faq-block-light {

  h2,
  .faq-accordion .acc-head-title,
  .faq-accordion .faq-accordion-content p {
    color: $white;
  }

  .faq-accordion,
  .faq-accordion .faq-accordion-item {
    border-color: rgba($white, 0.15);
  }

  .acc-head-circle {
    color: $gray-medium;
  }

  .faq-accordion-head {
    &.open-acc .acc-head-circle {
      color: $white;
    }
  }

  .faq-button {
    background-color: $white;
    border-color: $white;
    color: $rit-black;

    @include tablet {
      &:hover {
        background-color: transparent;
        color: $white;
      }
    }
  }
}

.faq-overview-wrapper {
  .btn {
    .flyin-component & {
      background-color: transparent;
      border: 1px solid $gray-disabled;
      color: $rit-black;
      max-width: none;
      min-width: 0;
    }

    &:hover {
      border: 1px solid $rit-black;
    }

    a {
      .flyin-component & {
        color: $rit-black;
        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
      }
    }
  }
}
