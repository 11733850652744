.mini-cart-product,
.mobile-cart-confirmation {

  .mini-cart-inner-container,
  .mobile-cart-confirmation-inner {
    display: flex;
    gap: 8px;
  }

  .mini-cart-inner-container {
    align-items: center;
    display: flex;
  }

}

.hide {
  display: none;
}

.refill-container {
  background-color: $white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-top: 28px;
  max-width: 315px;
  min-height: 41px;

  &.refill-cart-item {
    margin-bottom: 7.5px;
    margin-top: 10px;
    max-width: 100%;

    .refill-inner {
      margin: 0;
    }
  }

  &.refill-cart {
    background-color: $refill-white;
    bottom: 0;
    margin-right: 40px;
    position: absolute;
    right: 0;

    @include desktop-small {
      margin-top: 13px;
      margin: 0;
      order: 99;
      position: relative;
    }

    @include mobile-only {
      margin-top: 13px;
      margin: 0;
      order: 99;
      position: relative;
    }
  }

  &.refill-desktop {
    @include desktop-small {
      display: none;
    }

    @include mobile-only {
      display: none;
    }
  }

  &.refill-mobile {
    display: none;

    @include desktop-small {
      display: flex;
      height: auto;
      max-width: 100%;
      text-align: flex-start;
      width: 100%;

      .refill-inner {
        margin: 0;
      }
    }

    @include mobile-only {
      display: flex;
      height: auto;
      max-width: 100%;
      text-align: flex-start;
      width: 100%;

      .refill-inner {
        margin: 0;
      }
    }
  }

  &.refill-mobile-popup {
    background-color: $refill-white;
    margin-bottom: 0;
    margin-top: 10px;
    width: 100%;
  }

  &.refill-gift-selection {
    margin-top: 16px;
  }

  .plant-a-tree {
    height: 24px;
    margin: 5px 8px 8px 16px;
    width: 24px;

    &::before {
      @include icon-styles;
      color: $refill-green;
      content: icon-char(plant-a-tree);
      font-size: 24px;
    }
  }

  .refill-inner {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .for-every-refill {
      color: $refill-green;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin: 9px 21.5px 8px 0;
    }
  }
}

.refill-container-large {
  background-color: $refill-white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 74px;
  margin: 25px 25px 0 25px;
  position: relative;
  width: 808px;

  @include mobile-only {
    height: auto;
    width: auto;
  }

  .plant-a-tree {
    height: 35px;
    margin: 22px 24px;
    width: 32px;

    @include mobile-only {
      height: 24px;
      margin: 18px 20px auto 24px;
      width: 21.94px;
    }

    &::before {
      @include icon-styles;
      color: $refill-green;
      content: icon-char(plant-a-tree);
      font-size: 32px;

      @include mobile-only {
        font-size: 24px;
      }
    }
  }

  .refill-inner {
    display: flex;
    flex-direction: column;
    margin: 14px 0 13px 0;

    .refill-thank-you {
      color: $refill-green;
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin: 0;
    }

    .refill-large-inner {
      display: flex;
      flex-direction: row;

      @include mobile-only {
        display: block;
      }

      .for-every-refill {
        color: $refill-green;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin: 0;
        text-align: center;

        @include mobile-only {
          text-align: unset;
        }
      }

      .refill-learn-more {
        bottom: 0;
        color: $copper;
        font-family: $font-body;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin: 35px 23px 15px 0;
        position: absolute;
        right: 0;
        text-align: right;

        @include mobile-only {
          position: relative;
        }
      }
    }
  }
}

