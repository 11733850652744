.product-tile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .product-details-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .short-description {
      color: $rit-black;
      opacity: 0.5;
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 14px;
      text-transform: none;
      margin: 0;

      @include tablet {
        font-size: 14px;
        letter-spacing: 0.36px;
        line-height: 17px;
      }
    }

    .out-of-stock-block {
      margin-top: 0;
    }
  }

  .promotional-label {
    max-width: 100%;
    width: 100%;

    @include tablet {
      margin-bottom: 10px;
    }
  }

  .product-image {
    width: 100%;
    margin: 0;

    img {
      margin-left: auto;
      margin-right: auto;
    }

    .thumb-link {
      img {
        width: 100%;

        @include mobile-only {
          max-height: 265px;
        }
      }
    }
  }

  .stock-indicator-wrapper {
    background-color: $gray-gainsboro;
    border-radius: 24px;
    min-height: 24px;
    min-width: 84px;
    margin: 10px auto;
    padding: 0 12px;
    text-align: center;

    @include tablet {
      border-radius: 32px;
      min-height: 32px;
      min-width: 105px;
      margin: 12px auto;
    }

    .stock-indicator-label {
      font-size: 8px;
      line-height: 24px;
      letter-spacing: 1.2px;

      @include tablet {
        font-size: 10px;
        line-height: 32px;
        letter-spacing: 1.5px;
      }
    }
  }

  .base-price-block {
    display: none;
  }

  .product-price {
    text-align: center;
    display: flex;
    flex-direction: column;

    .product-beforediscount-price {
      color: $gray-suva;
      font-size: 12px;
      letter-spacing: 1.8px;
      line-height: normal;
      text-decoration: line-through;

      @include tablet {
        line-height: 24px;
      }
    }

    .product-discount-no-strikethrough {
      color: $rit-black;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
      text-transform: none;
    }

    .base-price-block {
      display: block;
    }
  }

  .availability-web {
    flex-shrink: 1;
    width: 100%;
  }

  &.promotion-product-tile {
    position: relative;

    .thumb-link,
    .product-name-link {
      pointer-events: none;
    }

    .product-name-link {
      .product-name {
        font-size: 24px;

        @include mobile-only {
          font-size: 18px;
        }
      }
    }

    &.out-of-stock {
      &::after {
        left: 10px;
        width: calc(100% - 20px);

        @include mobile-and-tablet {
          left: 5px;
          width: calc(100% - 10px);
        }
      }
    }
  }
}

.shopping-cart-block {
  .product-tile {
    &.promotion-product-tile {
      .product-name-link {
        .product-name {
          font-size: 18px;
        }
      }
      &.out-of-stock {
        &::after {
          left: 4px;
          width: calc(100% - 8px);
        }
      }
    }
  }

  .product-list-slider-block {
    &.personalised-promotions {
      .product-list-slider {
        .product-price {
          .product-sales-price {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.easter-eggs {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  @include mobile-only {
    padding: 100px 0;
    flex-direction: column;
  }

  @include tablet-only {
    padding: 138px 0;
  }

  @include desktop-small {
    padding: 180px 0;
  }

  @include desktop-medium {
    padding: 180px 0;
    max-width: $desktop-large-width;
  }

  .easter-egg-tile {
    background-color: $rit-sandy-bg;
    position: relative;

    @include mobile-only {
      margin: 0 20px;
      width: calc(100% - 40px);
    }

    @include tablet-only {
      width: 308px;
    }

    @include desktop-small {
      width: 396px;
    }

    @include desktop-medium {
      width: 440px;
    }

    &:nth-child(even) {
      @include mobile-only {
        margin-top: 150px;
      }
    }

    figure {
      @include mobile-and-tablet {
        height: 350px;
      }

      @include desktop-small {
        height: 428px;
      }

      @include desktop-medium {
        height: 475px;
      }

      .egg-top,
      .egg-bottom {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }

      .egg-top {
        top: 30px;
        z-index: 3;

        @include mobile-and-tablet {
          width: 222px;
        }

        @include desktop-small {
          width: 288px;
        }

        @include desktop-medium {
          width: 320px;
        }
      }

      .egg-bottom {
        top: 72px;
        z-index: 2;

        @include mobile-and-tablet {
          width: 204px;
        }

        @include desktop-small {
          width: 262px;
        }

        @include desktop-medium {
          width: 290px;
        }
      }

      .ribbon1,
      .ribbon2,
      .ribbon3,
      .ribbon4,
      .ribbon5,
      .ribbon6,
      .ribbon7,
      .ribbon8 {
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: block;
        position: absolute;
        z-index: 1;
      }

      .ribbon1 {
        background-image: url('../images/ribbons/ribbon1.svg');

        @include mobile-only {
          height: 254px;
          left: -20px;
          top: -46px;
          width: 76px;
        }

        @include tablet-only {
          height: 266px;
          left: -50px;
          top: -30px;
          width: 80px;
        }

        @include desktop {
          height: 380px;
          left: -70px;
          top: -30px;
          width: 114px;
        }
      }

      .ribbon2 {
        background-image: url('../images/ribbons/ribbon2.svg');

        @include mobile-only {
          height: 254px;
          right: -20px;
          top: -46px;
          width: 54px;
        }

        @include tablet-only {
          height: 268px;
          right: -26px;
          top: -98px;
          width: 56px;
        }

        @include desktop {
          height: 382px;
          right: -36px;
          top: -98px;
          width: 80px;
        }
      }

      .ribbon3 {
        background-image: url('../images/ribbons/ribbon3.svg');

        @include mobile-only {
          bottom: 52px;
          height: 118px;
          left: -20px;
          width: 52px;
        }

        @include tablet-only {
          bottom: 54px;
          height: 124px;
          left: -28px;
          width: 54px;
        }

        @include desktop {
          bottom: 54px;
          height: 176px;
          left: -40px;
          width: 76px;
        }
      }

      .ribbon4 {
        background-image: url('../images/ribbons/ribbon4.svg');

        @include mobile-only {
          bottom: 52px;
          height: 102px;
          right: -20px;
          width: 50px;
        }

        @include tablet-only {
          bottom: 56px;
          height: 108px;
          right: -26px;
          width: 52px;
        }

        @include desktop {
          bottom: 56px;
          height: 152px;
          right: -38px;
          width: 74px;
        }
      }

      .ribbon5 {
        background-image: url('../images/ribbons/ribbon5.svg');

        @include mobile-only {
          height: 240px;
          left: -20px;
          top: 26px;
          width: 62px;
        }

        @include tablet-only {
          height: 252px;
          left: -38px;
          top: -8px;
          width: 66px;
        }

        @include desktop {
          height: 358px;
          left: -56px;
          top: -8px;
          width: 92px;
        }
      }

      .ribbon6 {
        background-image: url('../images/ribbons/ribbon6.svg');

        @include mobile-only {
          height: 264px;
          right: -20px;
          top: -46px;
          width: 66px;
        }

        @include tablet-only {
          height: 382px;
          right: -36px;
          top: -102px;
          width: 70px;
        }

        @include desktop {
          height: 544px;
          right: -50px;
          top: -102px;
          width: 100px;
        }
      }

      .ribbon7 {
        background-image: url('../images/ribbons/ribbon7.svg');

        @include mobile-only {
          bottom: 44px;
          height: 96px;
          left: -20px;
          width: 54px;
        }

        @include tablet-only {
          bottom: 56px;
          height: 100px;
          left: -28px;
          width: 56px;
        }

        @include desktop {
          bottom: 56px;
          height: 142px;
          left: -40px;
          width: 80px;
        }
      }

      .ribbon8 {
        background-image: url('../images/ribbons/ribbon8.svg');

        @include mobile-only {
          bottom: 54px;
          height: 128px;
          right: -20px;
          width: 52px;
        }

        @include tablet {
          bottom: 56px;
          height: 90px;
          right: -36px;
          width: 54px;
        }

        @include desktop {
          bottom: 56px;
          height: 128px;
          right: -50px;
          width: 76px;
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      text-align: center;

      @include mobile-only {
        padding: 0 30px 55px 30px;
      }

      @include tablet-only {
        padding: 0 20px 55px 20px;
      }

      @include desktop {
        padding: 0 30px 55px 30px;
      }

      .btn {
        margin: 0 auto;
      }

      .the-ritual-of-title {
        color: $rit-copper-dark;
        font-size: 12px;
        letter-spacing: 1.8px;
        line-height: 16px;
      }

      .product-title {
        font-family: $font-header;
        font-size: 30px;
        letter-spacing: 1.2px;
        line-height: 40px;
        margin: 10px 0 20px 0;
      }
    }
  }
}

.product-swatches-list {
  font-size: 0;
  padding-top: 4px;
  width: 100%;

  .product-swatches-item {
    display: inline-block;
    height: 20px;
    vertical-align: top;
    width: 20px;
    margin-bottom: 18px;
  }

  .product-swatches-button {
    height: 22px;
    overflow: hidden;
    width: 22px;
  }

  .product-swatch-wrapper {
    width: 100%;
    max-width: 22px;
    height: 22px;
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
  }

  .product-swatches-image {
    width: 100%;
  }

  .product-swatches-all-items {
    font-size: 14px;
    display: inline-block;
    height: 20px;
    line-height: 24px;
    margin-left: 2px;
    vertical-align: top;
  }

  @include mobile-only {
    .product-swatches-item:not(:first-child) {
      margin-left: -8px;
    }
    .product-swatches-button {
      pointer-events: none;
    }
  }

  @include tablet {
    margin: -2px 0 0;
    padding: 0;

    .product-swatches-item {
      height: 40px;
      margin: 0 0 12px;
      width: 40px;
    }

    .product-swatches-button {
      height: 40px;
      width: 40px;
    }

    .product-swatch-wrapper {
      max-width: 32px;
      height: 32px;
    }

    .selected .product-swatch-wrapper {
      border-color: $rit-copper-dark;
      padding: 2px;
    }

    .product-swatches-all-items {
      height: 32px;
      line-height: 36px;
      margin-left: 5px;
    }
  }
}
