.homme-pdp {
  .pdp-inner-wrapper {
    padding-top: 100px;
  }

  .images-slider,
  .images-slider-fullwidth {
    &.slick-initialized {
      img {
        max-height: 440px;
      }
    }
  }

  .product-price {
    margin-top: 20px;
  }
}

.homme-bg-full-color {
  padding-bottom: 0;
}

.experience-assets-recommendedheroblock {
  .horizontal-grid-block {
    padding: 0;
  }

  .inner-wrapper {
    max-width: unset;
    padding: 0;
  }

  .item {
    position: relative;

    .video-holder {
      @include mobile-only {
        height: 375px;
      }
    }

    .image-holder {
      background-position: center;
      background-size: cover;

      @include mobile-only {
        height: 375px;
      }
    }
  }

  .cop-filters-wrapper {
    @include mobile-only {
      height: auto;
    }
  }

  .item-text {
    @include tablet {
      padding: 100px 0;
    }
  }

  .cop-filters-inner {
    .cop-filters-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        align-items: center;
        background-color: $gray-pills;
        border: initial;
        display: flex;
        margin: 0 16px 16px 0;
        padding: 0 16px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  .category-pills {
    margin: 40px 0 24px;

    .cop-filters-wrapper {
      background-color: initial;
    }
  }

  .content-banner-btn-wrapper {
    justify-content: center;
  }
}
