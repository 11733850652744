//
// Advent Reveal Block
// ----------------------------------------------------------------------------
//

.advent-reveal {
  counter-reset: reveal-counter;
  overflow: hidden;
  position: relative;

  @include mobile-only {
    max-height: 576px;
  }

  @include tablet {
    height: 528px;
  }

  &.reveal {
    @include mobile-extra-small {
      height: 1280px;
    }

    @include mobile-only {
      max-height: 3000px;
    }

    @include tablet {
      height: 700px;
    }

    .reveal-intro {
      opacity: 0;
      pointer-events: none;

      @include mobile-only {
        height: 375px;
      }

      @include tablet {
        width: 50%;
      }

      &::after {
        @include mobile-only {
          height: 715px;
        }
      }

      p,
      button {
        opacity: 0;
      }
    }

    .reveal-content-slides {
      @include mobile-only {
        margin-top: -48px;
      }
    }
  }

  &:not(.revealed) {
    @include mobile-only {
      transition: max-height 0.6s $navigation-ease 0.6s;
    }

    @include tablet {
      transition: height 0.6s $navigation-ease 1.2s;
    }

    .reveal-intro {
      @include mobile-only {
        transition: height 0.6s $navigation-ease 0.4s,
          opacity 1.2s $navigation-ease 0.8s;
      }

      @include tablet {
        transition: width 0.6s $navigation-ease 0.6s,
          opacity 0.6s $navigation-ease 1.8s;
      }

      p,
      .btn {
        transition: 0.6s $navigation-ease;
      }
    }

    .reveal-content-slides {
      @include mobile-only {
        transition: margin 1.2s $navigation-ease 0.8s;
      }
    }
  }

  &.revealed {
    @include mobile-only {
      transition: max-height 0.6s $navigation-ease 0.6s;
    }

    @include tablet {
      transition: height 0.6s $navigation-ease 0.6s;
    }

    .reveal-intro {
      @include mobile-only {
        transition: opacity 0.6s $navigation-ease,
          height 0.6s $navigation-ease 1s;
      }

      @include tablet {
        transition: opacity 0.6s $navigation-ease,
          width 0.6s $navigation-ease 1.2s;
      }

      &::after {
        @include mobile-only {
          transition: height 0.6s $navigation-ease 1s;
        }
      }

      p,
      .btn {
        transition: 0.6s $navigation-ease 1.8s;
      }
    }

    .reveal-content-slides {
      @include mobile-only {
        transition: margin 0.6s $navigation-ease;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
    margin-top: 5px;
    text-transform: none;

    @include mobile-only {
      font-size: 24px;
      letter-spacing: 1.5px;
      line-height: 32px;
    }
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      counter-increment: reveal-counter;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 24px;
      position: relative;
      text-align: left;

      @include mobile-only {
        padding-left: 30px;
      }

      @include tablet {
        padding-left: 40px;
      }

      &::before {
        content: counter(reveal-counter) '. ';
        left: 0;
        position: absolute;
      }
    }

    & + span {
      color: $rit-copper-dark;
      display: block;
      font-family: $font-header;
      font-size: 14px;
      margin-top: 24px;
    }
  }

  .reveal-intro {
    align-items: center;

    display: flex;
    flex-direction: column;
    font-family: $font-header;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;

    &::after {
      background-color: $ritual-ayurveda-bg;
      content: '';
      position: absolute;
      width: 100%;

      @include mobile-only {
        height: 0;
        left: 0;
        top: 100%;
      }

      @include tablet {
        height: 100%;
        left: 100%;
        width: 100%;
      }
    }

    p,
    .btn {
      transition: 0.6s $navigation-ease;
    }

    p {
      color: $white;
      font-family: $font-header;
      margin: 0;

      @include mobile-only {
        font-size: 20px;
        line-height: 24px;
        padding-top: 60px;
      }

      @include tablet {
        font-size: 24px;
        line-height: 32px;
        padding-top: 70px;
      }

      &::before {
        background-image: url('../images/icons/system-lock.svg');
        background-size: 40px 40px;
        content: '';
        height: 40px;
        position: absolute;
        left: calc(50% - 20px);
        width: 40px;

        @include mobile-only {
          top: 190px;
        }

        @include tablet {
          top: 150px;
        }
      }

      &::after {
        animation: linear rotation 15s infinite;
        backface-visibility: hidden;
        content: '';
        perspective: 1000;
        position: absolute;
        transform: translateZ(0);
        z-index: -1;

        @include mobile-only {
          background-image: url('../images/oval-gradient-327x327.png');
          background-size: 327px 327px;
          height: 327px;
          left: calc(50% - 164px);
          top: calc(50% - 164px);
          width: 327px;
        }

        @include tablet {
          background-image: url('../images/oval-gradient-400x400.png');
          background-size: 400px 400px;
          height: 400px;
          left: calc(50% - 200px);
          top: calc(50% - 200px);
          width: 400px;
        }
      }
    }

    .btn {
      margin-top: 24px;
    }

    .snow {
      pointer-events: none;
    }

    .reveal-intro-image {
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      @include  mobile-only {
        background-position: top center;
      }

      @include tablet {
        background-position: center center;
      }

      &.mobile {
        @include tablet {
          display: none;
        }
      }

      &.tablet {
        @include mobile-only {
          display: none;
        }
      }
    }
  }

  .reveal-content {
    @include tablet {
      display: flex;
      height: 100%;
    }

    .btn {
      bottom: 40px;
      position: absolute;

      @include mobile-only {
        background-color: transparent;
        border: 1px solid $gray-disabled;
        color: $rit-black;
        left: 50vw;
        transform: translateX(-50%);
      }

      @include tablet {
        right: calc(50% + 40px);
      }
    }
  }

  .reveal-content-image,
  .reveal-content-slides {
    @include tablet {
      width: 50%;
    }
  }

  .reveal-content-image {
    background-repeat: no-repeat;
    background-size: cover;

    @include mobile-only {
      background-position: top center;
      height: 375px;
    }

    &.mobile {
      @include tablet {
        background-position: center center;
        display: none;
      }
    }

    &.tablet {
      @include mobile-only {
        display: none;
      }
    }
  }

  .reveal-content-slides {
    @include mobile-only {
      margin: 0 24px 110px;
    }

    @include tablet {
      padding: 126px 120px 64px;
    }

    & > span:first-child {
      color: $rit-copper-on-black;
      font-family: $font-header;
      text-transform: uppercase;

      @include mobile-only {
        font-size: 10px;
      }

      @include tablet {
        font-size: 12px;
      }
    }

    .reveal-content-slide {
      @include mobile-only {
        background-color: $white;
        padding: 24px;
        text-align: center;
        width: calc(100vw + 12px);
      }

      & + .reveal-content-slide {
        @include mobile-only {
          margin-left: 24px;
        }
      }
    }

    .drag-list-body {
      @include mobile-only {
        padding: 0 0 40px;
      }

      @include tablet {
        padding: 0 0 62px;
      }
    }

    .drag-list-controls {
      button:first-child {
        left: calc(50% + 40px);
      }

      button:last-child:not(:only-child) {
        right: 40px;
      }
    }
  }
}

// summer box of joy additional styling

.product-type-adventcalendar {
  background-color: $advent-gray;

  .product-details-container {
    .inner-wrapper {
      .product-details-column {
        &.column-details {
          padding-top: 20px;
        }

        &.column-images {
          padding-top: 80px;
        }
      }
    }
  }

  .product-details-content {
    .product-price {
      margin: 20px 0;
    }
  }

  .product-info-container {
    .product-info-column-details {
      justify-content: center;
      order: 2;
      padding: 60px 40px;

      .product-info-content {
        h3 {
          font-size: 40px;
          letter-spacing: 1.8px;
          line-height: 48px;

          @include mobile-only {
            font-size: 24px;
            letter-spacing: 1px;
            line-height: 32px;
          }
        }

        div {
          color: $rit-black;
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
        }

        .product-info-icons {
          justify-content: center;
          margin: 32px -50px 0;

          @include mobile-only {
            margin: 40px -20px 0;
          }

          .item {
            width: 35%;

            @include mobile-only {
              align-items: baseline;
              display: flex;
              justify-content: center;
              width: 100%;
            }

            .product-info-icon {
              height: 48px;
              width: 48px;

              @include mobile-only {
                margin: 0 12px;
              }
            }
          }
        }
      }
    }

    .product-info-column-image {
      order: 1;
    }
  }

  .product-hero-container {
    .product-hero-media {
      left: auto;
      right: 0;

      @include mobile-only {
        order: 1;
      }
    }

    .product-hero-content {
      justify-content: center;
      margin-left: 0;

      @include mobile-only {
        order: 2;
      }

      .product-hero-box {
        padding: 60px 5px;

        h3 {
          color: $rit-black;
          font-size: 40px;
          letter-spacing: 1.8px;
          line-height: 48px;

          @include mobile-only {
            font-size: 24px;
            letter-spacing: 1px;
            line-height: 32px;
          }
        }

        p {
          color: $rit-black;
          font-size: 14px;
          letter-spacing: 0.4px;
        }
      }
    }
  }
}

//
// Extended Reveal block, with background video
// ----------------------------------------------------------------------------
//

.advent-reveal-with-video {
  overflow: hidden;
  position: relative;
  background-color: $navy-blue;

  &.reveal {
    .text-holder {
      transform: translateX(24px);
    }

    .reveal-intro {
      opacity: 0;
      pointer-events: none;
    }

    .reveal-content {
      opacity: 1;
    }

    .product-slider-block {
      transform: translateX(0);
    }
  }

  &.revealed {
    .text-holder {
      transition: transform 0.5s $navigation-ease 0.5s;
    }

    .reveal-intro {
      transition: opacity 0.5s $navigation-ease 0.5s;
    }

    .reveal-content {
      transition: opacity 0.5s $navigation-ease;
    }

    .product-slider-block {
      transition: transform 0.5s $navigation-ease;
    }
  }

  .video-holder {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .reveal-intro {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 5;
    transition: opacity 0.5s $navigation-ease 0.5s;

    @include mobile-only {
      justify-content: flex-start;
      padding: 90px 0 40px;
    }
  }

  .text-holder {
    padding: 0 20px;
    text-align: left;
    color: $white;
    transition: transform 0.5s $navigation-ease;

    @include mobile-only {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include desktop {
      padding: 0 100px;
    }
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 2px;
    margin-bottom: 31px;
    text-transform: none;

    @include mobile-only {
      display: none;
    }
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 1.8px;
    text-transform: none;

    @include mobile-only {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
      text-align: center;
    }
  }

  ol {
    max-width: 300px;
    padding: 0;
    margin: 0 auto;
    text-align: left;
    color: $white;
    list-style: none;

    @include mobile-only {
      padding-left: 20px;
    }

    li {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      padding-left: 25px;
      position: relative;

      .order-number {
        left: 0;
        position: absolute;
      }
    }
  }

  .reveal-content-btn {
    background-color: rgba($white, 0.1);
    min-height: 60px;
    margin-top: 35px;
    letter-spacing: 4.2px;
    padding: 14px 31px 14px 20px;

    @include mobile-only {
      margin: 0 auto;
    }

    .ico {
      font-size: 23px;
      margin-right: 9px;
    }
  }

  .reveal-content {
    opacity: 0;
    transition: opacity 0.5s $navigation-ease 0.5s;

    @include tablet {
      display: flex;
      height: 100%;
      transition-delay: 0.7s;
    }

    .hide-content-btn {
      position: static;
      margin: 31px auto 0;
      display: block;
      width: auto;
      min-height: 48px;
      min-width: 1px;
      color: $white;
    }
  }

  .product-slider-block {
    height: 100%;
    transform: translateX(-30px);
    transition: transform 0.5s $navigation-ease 0.5s;

    @include tablet {
      transition-delay: 0.7s;
    }
  }

  .product-explanation-slider  {
    .product-slider-steps {
      height: 100%;
    }

    .product-slider-step {
      @include mobile-only {
        padding: 0 25px;
      }
    }

    .product-slider-holder {
      @include tablet {
        max-width: 720px;
      }

      .slider-bar {
        transform: translate(0);

        @include mobile-only {
          margin: 20px auto 0;
          width: calc(100% - 60px);
        }

        @include tablet {
          margin: 19px auto 0;
        }
      }
    }

    .product-main-slider {
      @include mobile-only {
        padding: 0;
      }

      .slider-inner-wrap {
        background-color: transparent;

        .image-holder {
          @include mobile-only {
            height: 276px;
            width: calc(100% + 50px);
            margin-left: -25px;
          }
        }
      }

      .step-intro {
        align-items: center;

        @include mobile-only {
          padding: 10px 0 0;
        }

        @include tablet {
          padding-top: 88px;
        }
      }

      .slick-arrow {
        transform: translate(0);
        top: 250px;

        &.slick-next {
          right: 10px;
          left: auto;

          @include desktop-only {
            right: 97px;
          }
        }

        &.slick-prev {
          left: 10px;

          @include desktop-only {
            left: 97px;
          }
        }

        .slider-button {
          background-color: transparent;
          color: $white;

          &:hover {
            background-color: $white;
            color: $black;
          }
        }
      }
    }
  }
}

//
// Animations
// ----------------------------------------------------------------------------
//

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(-359deg);
  }
}
