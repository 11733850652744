.collection-hero-banner {
  display: flex;
  position: relative;
  width: 100%;
  height: 70vh;
  max-height: 760px;

  .media-holder {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform $secondary-duration $default-ease-out-fast;
  }

  &[class*='in-viewport'] {
    overflow: hidden;
  }

  &.in-viewport .media-holder {
    transform: scale(1);
  }

  &.not-in-viewport .media-holder {
    transform: scale(1.2);
  }

  &.collection-hero-banner-small {
    height: 80vh;

    .the-ritual-of-title {
      text-transform: none;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: 0;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 1.1px;
      font-family: $font-header;
      text-transform: none;
      margin-bottom: 30px;
    }
  }

  &.txt--black {
    .banner-content,
    .the-ritual-of-title {
      color: $rit-black;
    }
  }

  .banner-content {
    align-items: center;
    color: $white;
    display: flex;
    padding: 96px 0 83px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 3;
  }

  .inner-indented {
    margin: 0 auto;
  }

  .the-ritual-of-title {
    color: $white;
    font-size: 37px;
    letter-spacing: 9px;
    line-height: 43px;
    margin-bottom: 7px;

    span {
      display: block;
      font-size: 18px;
      letter-spacing: 4.5px;
      line-height: 30px;
    }
  }

  h2 {
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2.45px;
    line-height: 20px;
    margin: 0 auto 41px;
    max-width: 320px;
  }

  .icon-holder {
    bottom: 100px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 2;

    img {
      max-width: 48px;
    }
  }

  @include mobile-only {
    &.txt--black {
      &::after {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 100%));
        bottom: 0;
        content: '';
        height: 134px;
        left: 0;
        position: absolute;
        right: 0;
      }

      .the-ritual-of-title {
        font-size: 30px;
        letter-spacing: 9px;
        line-height: 40px;
      }
    }
  }

  @include tablet {
    height: 75vh;

    .inner-indented {
      max-width: 40%;
    }

    &.collection-hero-banner-small {
      height: 75vh;
      max-height: 480px;

      .the-ritual-of-title {
        font-size: 54px;
        line-height: 58px;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }

      h2 {
        font-size: 24px;
        line-height: 44px;
        letter-spacing: 0.46px;
        margin-bottom: 28px;
      }
    }

    .banner-content {
      padding: 210px 0;
    }

    .the-ritual-of-title {
      font-size: 54px;
      line-height: 58px;
      margin: 0 0 22px;

      span {
        font-size: 30px;
        letter-spacing: 8px;
        line-height: 40px;
        margin-bottom: 12px;
      }
    }

    h2 {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 25px;
      margin: 0 auto 35px;
      max-width: none;
    }

    .icon-holder {
      bottom: 30px;

      img {
        max-width: 60px;
      }
    }
  }

  @include desktop {
    max-height: 600px;
  }
}

.collection-hero-banner-limited {
  h3 {
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 18px;
  }

  .hero-video-holder {
    margin-top: 20px;
    width: 100%;
  }

  .btn-content-video {
    color: $white;
    font-size: 12px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  @include mobile-only {
    .the-ritual-of-title {
      font-size: 30px;
      letter-spacing: 8px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  @include tablet {
    .inner-wrapper {
      max-width: 1070px;
    }

    h3 {
      margin-bottom: 35px;
    }

    .the-ritual-of-title {
      letter-spacing: 8px;
      margin: 0 0 30px;
    }

    h2 {
      margin: 0 auto 30px;
    }

    .hero-video-holder {
      margin-top: 60px;
    }
  }
}

// classes for alignment on tablet and desktop

.banner-left-aligned-desktop {
  @include tablet {
    .inner-indented {
      float: left;
      padding: 0 0 0 10px;
    }
  }
}

.banner-right-aligned-desktop {
  @include tablet {
    .inner-indented {
      float: right;
      padding: 0 10px 0 0;
    }
  }
}

.banner-text-left-aligned-desktop {
  @include tablet {
    h2 {
      margin-left: 0;
    }

    .inner-indented {
      float: left;
      text-align: left;
      padding: 0 0 0 10px;
    }
  }
}

.banner-text-right-aligned-desktop {
  @include tablet {
    .inner-indented {
      text-align: right;
      float: right;
      padding: 0 10px 0 0;
    }
  }
}

.banner-top-aligned-desktop {
  @include tablet {
    .banner-content {
      align-items: flex-start;
      padding: 85px 0;
    }
  }
}

.banner-bottom-aligned-desktop {
  @include tablet {
    .banner-content {
      align-items: flex-end;
      padding: 85px 0;
    }
  }
}

// classes for alignment on mobile

.banner-text-left-aligned-mobile {
  @include mobile-only {
    .inner-indented {
      float: left;
      text-align: left;
      padding: 0 0 0 10px;
    }
  }
}

.banner-text-right-aligned-mobile {
  @include mobile-only {
    .inner-indented {
      text-align: right;
      float: right;
      padding: 0 10px 0 0;
    }
  }
}

.banner-top-aligned-mobile {
  @include mobile-only {
    .banner-content {
      align-items: flex-start;
      padding: 60px 0;
    }
  }
}

.banner-bottom-aligned-mobile {
  @include mobile-only {
    .banner-content {
      align-items: flex-end;
      padding: 60px 0;
    }
  }
}

// fifth collection template
.collection-fifth-template .collection-hero-banner {
  &.collection-hero-banner-limited {
    .inner-indented {
      max-width: none;
    }

    .banner-content {
      padding: 100px 0;
    }

    h3 {
      @include tablet {
        font-size: 30px;
        letter-spacing: 8px;
        line-height: 40px;
        margin-bottom: 10px;
      }
    }

    .hero-video-holder {
      margin-top: 30px;
    }
  }
}
