//
// Product Configurator
//

.product-configurator {
  flex-wrap: wrap;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  .intro {
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;

    p {
      color: $rit-black;
      font-family: $font-header;
      margin-bottom: 0;
      max-width: 350px;
      margin: 0 auto;

      @include mobile-and-tablet {
        font-size: 21px;
        line-height: 30px;
      }

      @include desktop {
        font-size: 28px;
        line-height: 40px;
      }
    }

    em {
      font-style: italic;
    }

    .intro-text {
      padding: 0 50px;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: 2;

      @include mobile-and-tablet {
        transform: translateY(-50%);
      }

      @include desktop {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .backdrop {
    background-color: $rit-sandy-dark;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .scrim {
    background-color: rgba($black, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    @include desktop {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: 40px;
    display: flex !important;
    left: 50%;
    list-style: none;
    position: absolute;
    transform: translateX(-50%);
    width: 400px;
    z-index: 10;

    @include mobile-and-tablet {
      bottom: -20px;
    }

    @include mobile-only {
      width: 280px;
    }

    @include tablet-only {
      width: 440px;
    }

    @include desktop {
      bottom: 40px;
      width: 400px;
    }

    li {
      background-color: $slider-bar-gray;
      display: flex;

      @include mobile-and-tablet {
        height: 2px;
      }

      @include desktop {
        height: 4px;
      }

      &:first-child {
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border-radius: 0 2px 2px 0;
      }

      &:nth-last-child(n+2), &:nth-last-child(n+2) ~ li { width: 50%; }
      &:nth-last-child(n+3), &:nth-last-child(n+3) ~ li { width: 33.33%; }
      &:nth-last-child(n+4), &:nth-last-child(n+4) ~ li { width: 25%; }
      &:nth-last-child(n+5), &:nth-last-child(n+5) ~ li { width: 20%; }
      &:nth-last-child(n+6), &:nth-last-child(n+6) ~ li { width: 16.67%; }
      &:nth-last-child(n+7), &:nth-last-child(n+7) ~ li { width: 14.29%; }
      &:nth-last-child(n+8), &:nth-last-child(n+8) ~ li { width: 12.5%; }
      &:nth-last-child(n+9), &:nth-last-child(n+9) ~ li { width: 11.11%; }
      &:nth-last-child(n+10), &:nth-last-child(n+10) ~ li { width: 10%; }

      button {
        height: 100%;
        text-indent: -9999px;
        width: 100%;
      }
    }
  }
}


//
// Product Configurator Transitions
// --------------------------------------------------
//

.product-configurator-transition-leave-to {
  overflow: visible;

  &:not(.has-dialog) {
    @include mobile-and-tablet {
      transform: translateY(100vh);
    }

    @include desktop {
      transform: translateX(100%);
    }
  }
}

.product-configurator-transition-leave-active {
  overflow: visible;
  transition: transform 0.8s $navigation-ease;
}

.scrim-transition-enter {
  opacity: 0;
}

.scrim-transition-leave-to {
  opacity: 0;

  @include mobile-and-tablet {
    transform: translateY(-100vh) !important;
  }

  @include desktop {
    left: -100% !important;
  }

  // This is specific for IE11.
  // Other browsers will ignore this and use the -100% defined above.
  _:-ms-fullscreen, :root & {
    @include desktop {
      left: 0 !important;
    }
  }
}

.scrim-transition-leave-active {
  @include mobile-and-tablet {
    transition: opacity 0.8s $navigation-ease 0.4s;
  }

  @include desktop {
    transition: opacity 0.8s $navigation-ease 0.4s,
      left 0.8s $navigation-ease;
  }
}

.scrim-transition-enter-active {
  transition: opacity 0.8s $navigation-ease;
}

.intro-transition-enter-active,
.intro-transition-leave-active {
  z-index: 1;
}

.backdrop-transition-enter,
.intro-backdrop-transition-enter {
  opacity: 0;

  @include mobile-and-tablet {
    top: 100vh !important;
  }

  @include desktop {
    left: 100% !important;
  }
}

.backdrop-transition-leave-to {
  opacity: 0;

  @include mobile-and-tablet {
    top: 100vh !important;
  }
}

.intro-backdrop-transition-leave-to {
  opacity: 0;

  @include mobile-and-tablet {
    top: 0 !important;
  }

  @include desktop {
    left: 0 !important;
  }
}

.backdrop-transition-enter-active,
.backdrop-transition-leave-active,
.intro-backdrop-transition-enter-active {
  @include mobile-and-tablet {
    transition: opacity 0.8s $navigation-ease 0.3s,
      top 0.8s $navigation-ease 0.3s;
  }

  @include desktop {
    transition: opacity 0.8s $navigation-ease 0.3s,
      left 0.8s $navigation-ease 0.3s;
  }
}

.intro-backdrop-transition-leave-active {
  transition: opacity 0.8s $navigation-ease 0.3s;
}

.intro-text-transition-enter {
  opacity: 0;

  @include mobile-and-tablet {
    top: 150vh !important;
  }

  @include desktop {
    left: 150% !important;
  }
}

.intro-text-transition-leave-to {
  opacity: 0;

  @include mobile-and-tablet {
    top: 50vh !important;
  }

  @include desktop {
    left: 50% !important;
  }
}

.intro-text-transition-enter-active {
  @include mobile-and-tablet {
    transition: opacity 0.8s $navigation-ease 0.3s,
      top 0.8s $navigation-ease 0.3s;
  }

  @include desktop {
    transition: opacity 0.8s $navigation-ease 0.3s,
      left 0.8s $navigation-ease 0.3s;
  }
}

.intro-text-transition-leave-active {
  transition: opacity 0.6s $navigation-ease 0.3s;
}


//
// Assembled Product
// --------------------------------------------------
//

.assembled-product {
  &.zoom-top,
  &.start-with-zoom {
    .layer {
      img {
        @include mobile-and-tablet {
          // When zoomin or before zooming but on amulet or bottlecap screen
          // the padding needs to be 0 to sync the zoom image and the image
          // in the other screens
          padding: 0;
        }
      }
    }
  }

  &.zoom-top {
    transform: scale3d(2, 2, 1)
      translate3d(0, -10%, 0);

    @include tablet {
      transform: scale3d(1.8, 1.8, 1)
        translate3d(0, -2%, 0);
    }

    @include desktop {
      transform: scale3d(2, 2, 1)
        translate3d(0, -15%, 0);
    }
  }

  &.zoom-middle {
    transform: scale3d(2, 2, 1)
      translate3d(0, -27%, 0);

    @include tablet {
      transform: scale3d(1.3, 1.3, 1)
        translate3d(0, 1%, 0);
    }

    @include desktop {
      transform: scale3d(2, 2, 1)
        translate3d(0, -15%, 0);
    }
  }

  .product-configurator & {
    height: 100%;
    transform-origin: top;

    @include mobile-and-tablet {
      position: relative;
      transition: height 0.5s $navigation-ease,
        margin 0.5s $navigation-ease,
        transform 0.6s $navigation-ease 0.3s;
    }

    &.is-product-zoom {
      position: absolute;
      top: 0;
      transition: transform 0.4s $navigation-ease,
        top 0.4s $navigation-ease;
      width: 100%;
      z-index: 2;
    }

    &:not(.is-product-zoom) {
      @include desktop {
        position: relative;
        transition: transform 0.6s $navigation-ease 0.3s;
        width: 50%;
      }
    }

    &.move-active {
      // This removes the top transform set by ".is-product-zoom" making the
      // dragging of the product image more smooth.
      transition: transform 0.4s $navigation-ease;
    }
  }

  .layer {
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    align-items: center;

    img {
      left: 50%;
      margin-top: auto;
      max-width: 100%;
      position: absolute;
      transition: padding 0.6s $navigation-ease;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      max-height: 80%;

      @include desktop {
        max-height: 75vh;
      }
    }
  }

  .fragrance-label {
    bottom: 10px;
    color: $rit-dark;
    font-family: $font-header-italic;
    font-size: 14px;
    font-style: italic;
    position: absolute;
    text-align: center;
    transition: opacity 0.3s $navigation-ease;
    width: 100%;

    @include mobile-and-tablet {
      bottom: 20px;
    }

    @include desktop {
      bottom: 65px;
    }

    &::before {
      background-color: $rit-dark;
      content: '';
      height: 1px;
      left: calc(50% - 14px);
      position: absolute;
      top: -6px;
      width: 28px;
    }
  }
}

.assembled-product-zoom {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;

  @include mobile-and-tablet {
    overflow: hidden;
    top: 0;
    transition: height 0.8s $navigation-ease,
      margin 0.4s $navigation-ease;
    width: 100%;
  }

  @include desktop {
    height: 100%;
    transition: width 0.6s $navigation-ease;
    width: 50%;
  }

  &.active {
    @include mobile-and-tablet {
      height: 100%;
      margin-top: 0;
    }

    @include desktop {
      width: 100%;
    }

    .zoom-close {
      opacity: 1;
    }

    .fragrance-label {
      opacity: 0;
    }
  }

  .zoom-trigger {
    height: 33.33%;
    position: relative;
    width: 100%;
    z-index: 3;
  }

  .zoom-close {
    background-color: $black;
    border-radius: 50%;
    color: $white;
    height: 50px;
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: opacity 0.3s $navigation-ease;
    width: 50px;
    z-index: 4;
  }

  .zoom-backdrop {
    background-color: $rit-sandy-dark;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

//
// Assembled Product Transition
// --------------------------------------------------
//

.assembled-product-transition-enter,
.assembled-product-transition-leave-to {
  opacity: 0;
}

.assembled-product-transition-enter-active,
.assembled-product-transition-leave-active {
  transition: opacity 0.8s $navigation-ease;
}

//
// Result Slider
// --------------------------------------------------
//

.product-configurator { // sass-lint:disable-line no-mergeable-selectors
  .result-slider {
    position: absolute;
    z-index: 3;

    @include mobile-and-tablet {
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    @include desktop {
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .configured-product {
      height: 100%;
    }

    .result-slider-image {
      @include mobile-only {
        height: 280px;
        width: 280px;
      }

      @include tablet-only {
        height: 500px;
        width: 500px;
      }

      @include desktop {
        height: 100%;
        width: 100%;
      }

      &.selected {
        img {
          @include mobile-and-tablet {
            opacity: 1;
          }
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;

        @include mobile-and-tablet {
          margin: 0 auto;
          opacity: 0.3;
          transition: opacity 0.8s $navigation-ease;
          width: calc(100% - 20px);
        }
      }
    }
  }

  .restart-button {
    align-items: center;
    background-color: $black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: 10;
    color: $white;
    font-size: 44px;

    &:not(.fixed) {
      position: absolute;
    }

    &.fixed {
      position: fixed;
      z-index: 110;
    }

    @include mobile-only {
      height: 32px;
      left: 20px;
      top: 20px;
      width: 32px;
      font-size: 26px;
    }

    @include tablet-only {
      height: 48px;
      left: 40px;
      top: 40px;
      width: 48px;
    }

    @include desktop {
      height: 50px;
      left: 40px;
      top: 40px;
      width: 50px;
    }
  }
}

//
// Parts / Options Footer
// --------------------------------------------------
//

.parts-footer,
.options-footer,
.result-footer {
  position: absolute;
  z-index: 2;

  @include mobile-and-tablet {
    bottom: 0;
    width: 100%;
  }

  @include desktop {
    display: flex;
    flex-flow: column;
    height: 100%;
    right: 0;
    top: 0;
    width: 50%;
  }
}

.parts-footer,
.options-footer {
  @include mobile-and-tablet {
    background-color: $white;
  }

  &.upsell-footer {
    .footer-header {
      @include mobile-only {
        padding-top: 40px;
      }

      @include tablet-only {
        padding: 95px 100px 40px 100px;
      }

      h3 {
        @include mobile-only {
          font-size: 20px;
          letter-spacing: 0.57px;
          line-height: 24px;
          margin: 0 60px;
        }
      }
    }
  }

  .error-badge {
    background: url('../images/error-badge.svg') left top no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  .footer-header {
    text-align: center;

    @include tablet-only {
      opacity: 1;
      padding-bottom: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }

    h3 {
      font-size: 30px;
      letter-spacing: 1.2px;
      text-transform: none;
    }

    p {
      color: $rit-black;
    }

    .icon {
      color: $rit-copper-dark;
      font-size: 30px;
      left: 50%;
      line-height: 1;
      position: absolute;
      top: 55px;
      transform: translateX(-50%);

      @media (min-width: $desktop-width) and (max-height: 730px) {
        top: 20px;
      }
    }
  }

  .button-row {
    @include mobile-only {
      display: flex;
      flex-flow: row;
      height: 65px;
      justify-content: center;
    }

    @include tablet-only {
      height: 84px;
      padding-top: 20px;
      text-align: center;
    }

    &.single-button {
      display: flex;
      justify-content: center;
    }

    .btn {
      min-width: 0;

      @include mobile-and-tablet {
        width: 50%;
      }

      @include tablet-only {
        max-width: 190px;
      }

      @include desktop {
        display: inline-block;
        height: 44px;
        width: calc(50% - 10px);
      }

      & + .btn {
        @include tablet {
          margin-left: 15px;
        }
      }
    }
  }
}

.parts-footer {
  & > div:not(.upsell-options) {
    @include desktop {
      width: 400px;
    }

    &:first-child {
      @include desktop {
        margin: 95px auto 65px;
      }

      @media (min-width: $desktop-width) and (max-height: 768px) {
        margin: 47px auto 30px;
      }
    }

    &:not(:first-child) {
      @include desktop {
        margin: 0 auto;
      }
    }
  }

  .upsell-options {
    padding: 0 65px;
    width: 100%;
  }

  .status-bar {
    p {
      color: $rit-black;

      @include mobile-and-tablet {
        background-color: $rit-sandy-bg;
      }
    }
  }

  .button-row {
    @include desktop {
      height: 156px;
      padding-top: 20px;
    }

    @media (min-width: $desktop-width) and (max-height: 682px) {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      height: auto !important;
      padding: 0 !important;
      background-color: $white;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 100%;
        height: 50px;
        width: 100%;
        background: linear-gradient(to top, $white, rgba($white, 0));
      }
    }

    .btn {
      @include mobile-only {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
      }
    }
  }
}

.options-footer {
  opacity: 1;

  @include mobile-and-tablet {
    z-index: 9;
  }

  &.has-overlay,
  &.is-zoom-image {
    @include mobile-and-tablet {
      z-index: 100;
    }
  }

  &.full-height-footer {
    .overlay p {
      max-width: 335px;
    }

    &:not(.upsell-footer) {
      @include mobile-and-tablet {
        top: 0;
      }

      .button-row {
        @include mobile-and-tablet {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }

    &.upsell-footer {
      @media only screen and (max-height: 580px) {
        .footer-header {
          padding: 20px 0;
        }
      }

      @include mobile-only {
        top: 0;
      }

      .button-row {
        @include mobile-only {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  & > div {
    &:first-child {
      @include desktop {
        margin: 0 auto;
        width: 400px;
      }
    }

    &:not(:first-child):not(.overlay) {
      margin: 0 auto;

      @include desktop-only {
        width: 470px;
      }

      @include desktop-medium {
        width: 560px;
      }

      &.part-options-fragrance-slider {
        @include tablet-only {
          margin-top: 240px;
        }

        @include desktop-medium {
          width: 540px;
        }
      }
    }
  }

  .footer-header {
    &:not(.has-categories) {
      padding: 95px 0 40px;

      @media (min-width: $desktop-width) and (max-height: 730px) {
        padding-top: 55px;
      }
    }

    &.has-categories {
      padding: 95px 0 90px;

      @media (min-width: $desktop-width) and (max-height: 730px) {
        padding-top: 55px;
      }
    }
  }

  .status-bar {
    bottom: 71px;
    position: absolute;

    @include tablet-only {
      bottom: 82px;
    }

    @include mobile-and-tablet {
      width: 100%;
    }

    @include desktop {
      left: 50%;
      transform: translateX(-50%);
    }

    & + .button-row {
      .btn {
        @include desktop {
          margin-top: 35px;
          margin-bottom: 20px;
        }
      }
    }

    p {
      color: $red-error;
    }
  }

  .button-row {
    @include desktop {
      align-items: center;
      flex-grow: 1;
      max-height: 101px;
    }

    .btn {
      @include mobile-only {
        border-color: $gray-gainsboro-light;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
      }
    }
  }
}

.upsell-footer {
  @include mobile-only {
    background-color: $rit-sandy-bg;
  }

  @include desktop {
    justify-content: center;
  }

  .button-row {
    @include mobile-only {
      height: 54px;
      padding-bottom: 10px;
    }

    @include tablet-only {
      padding-bottom: 30px;
      padding-top: 10px;
    }
  }
}

.result-footer {
  @include mobile-and-tablet {
    height: 100%;
  }

  .product-description {
    @include desktop {
      height: 100%;
      width: 100%;
    }

    .product-info-column-details {
      justify-content: center;

      @include tablet-only {
        padding: 20px 15px 20px 15px;
      }

      .product-info-content {
        @include desktop {
          padding: 0 42px;
        }

        @include desktop-medium {
          padding: 0 48px;
        }

        @include desktop-large {
          padding: 0 146px;
        }

        h3 {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1.28px;

          @include tablet {
            font-size: 30px;
            letter-spacing: 1.2px;
            line-height: 40px;
            max-width: 350px;
            margin: 0 auto 10px;
          }
        }

        p {
          font-family: $font-header;

          @include tablet {
            letter-spacing: 0.5px;
            line-height: 24px;
            max-width: 400px;
            margin: 0 auto;
          }
        }

        .result-label {
          @include tablet-only {
            font-size: 15px;
          }

          @include tablet {
            margin: 63px 0 10px;
          }
        }

        .product-price {
          @include mobile-only {
            font-size: 18px;
            line-height: 21px;
            margin: 15px 0 30px 0;
            width: 100%;
          }

          @include mobile-small {
            margin: 15px 0 12px 0;
          }

          @include tablet {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0;
            margin: 30px 0;
            width: 100%;
          }

          @include desktop {
            margin: 50px 0 10px 0;
          }
        }

        .price-sales {
          display: block;
        }
      }
    }

    .btn {
      @include mobile-and-tablet {
        min-width: calc(50% - 5px);
        transform: translate3d(0, 60px, 0);
        transition: transform 0.6s $navigation-ease;
      }

      &.active {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .product-info-footer {
    @include mobile-and-tablet {
      flex-direction: row-reverse;
      flex-wrap: wrap;
      margin-top: auto;
    }

    @include tablet-only {
      margin: 0 auto;
      width: 440px;
    }
  }
}

.product-parts {
  @include desktop {
    flex-grow: 1;
  }

  @media (min-width: $desktop-width) and (max-height: 682px) {
    position: relative;
    height: 59vh;
    overflow: hidden;
    flex-grow: 0 !important;
  }

  ul {
    list-style: none;
    margin: 0;
    position: relative;

    @include mobile-and-tablet {
      display: flex;
      min-height: 97px;
      justify-content: center;
    }

    @include tablet-only {
      max-width: 400px;
      margin: 0 auto;
    }

    @media (min-width: $desktop-width) and (max-height: 682px) {
      overflow-y: auto;
      height: 100%;
      padding-bottom: 80px;
    }
  }

  li {
    @include mobile-and-tablet {
      display: flex;
      text-align: center;
      width: 33.33%;
    }

    @include desktop {
      border-top: 1px solid $gray-gainsboro;
      height: 99px;
    }

    &:last-child {
      @include desktop {
        border-bottom: 1px solid $gray-gainsboro;
      }
    }

    a {
      position: relative;
      text-decoration: none;
      width: 100%;

      @include mobile-and-tablet {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        padding: 20px 0;
        text-align: center;
      }

      @include desktop {
        display: flex;
        flex-flow: column;
        min-height: 97px;
        padding: 24px 45px 10px 94px;
      }

      &.icon-selected {
        @include mobile-and-tablet {
          border-top: 1px solid $rit-copper-dark;
        }

        .icon {
          color: $rit-copper-dark;
        }
      }

      &::after {
        @include desktop {
          border-right: 1px solid $gray-gainsboro;
          border-top: 1px solid $gray-gainsboro;
          content: '';
          height: 15px;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: rotate(45deg) translateY(-10px);
          width: 15px;
        }
      }
    }

    span {
      &.part-name {
        color: $rit-black;
        font-family: $font-header;
        letter-spacing: 0.4px;

        @include mobile-and-tablet {
          font-size: 12px;
          line-height: 15px;
        }

        @include desktop {
          font-size: 18px;
          line-height: 21px;
        }
      }

      &.error-badge {
        bottom: 9px;
        left: calc(50% + 3px);
        position: absolute;
      }

      &.error-message {
        color: $red-error;
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 13px;
      }

      &.selected-part {
        color: $gray-medium;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
    }

    .icon-holder {
      display: block;

      @include mobile-and-tablet {
        position: relative;
      }

      @include desktop {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .icon {
      margin-bottom: 5px;
      color: $rit-black;

      @include mobile-and-tablet {
        font-size: 32px;
      }

      @include desktop {
        font-size: 28px;
        left: 27px;
        line-height: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.part-options {
  position: relative;

  @include desktop {
    border-top: 1px solid $gray-gainsboro;
    border-bottom: 1px solid $gray-gainsboro;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    transition: border-color 0.8s $navigation-ease !important;
  }

  &::before,
  & ~ .button-row::before {
    @include desktop {
      content: '';
      height: 57px;
      opacity: 0;
      width: 540px;
      transition: opacity 0.6s $navigation-ease;
      z-index: 1;
    }
  }

  &::before {
    @include desktop {
      background: linear-gradient(to bottom, $white, rgba($white, 0));
      position: fixed;
    }
  }

  & ~ .button-row {
    @include desktop {
      position: relative;
    }

    &::before {
      @include desktop {
        background: linear-gradient(to top, $white, rgba($white, 0));
        left: 0;
        position: absolute;
        top: -58px;
        z-index: -1;
      }
    }
  }

  &.top-gradient::before,
  &.bottom-gradient ~ .button-row::before {
    @include desktop {
      opacity: 1;
      z-index: 0;
    }
  }

  &.part-options-fragrance-slider {
    overflow: visible;

    @include mobile-only {
      height: 100%;
    }

    @include tablet-only {
      width: 540px;
      height: calc(100% - 240px);
    }

    .categories {
      overflow: auto;
      white-space: nowrap;
      margin-top: 40px;
      justify-content: flex-start;
      border: 0;

      @include mobile-only {
        padding-left: 40px;
      }

      @include desktop {
        margin-top: -36px;
        -ms-overflow-style: none;
        scrollbar-width: none; // sass-lint:disable-line no-misspelled-properties

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @include desktop-medium {
        width: 540px;
      }

      li {
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @include tablet {
          margin: 0 7px;
        }

        @include desktop {
          margin: 0 16px;
          padding: 0;
        }
      }
    }

    .category-indicator-scrim {
      background-color: $gray-gainsboro;
      height: 1px;
      overflow: hidden;

      @include desktop-only {
        margin-top: 11px;
      }

      @include desktop-medium {
        width: 540px;
      }

      .category-indicator {
        margin-top: 0;
      }
    }

    .categories,
    .category-indicator-scrim {
      @include mobile-and-tablet {
        transition: opacity 0.3s $navigation-ease 0.2s;
      }
    }

    & ~ .button-row {
      @include mobile-and-tablet {
        bottom: 0;
        opacity: 1;
        transition: bottom 0.6s $navigation-ease;
      }
    }
  }

  ul.categories {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    @include mobile-and-tablet {
      border-bottom: 1px solid $gray-gainsboro-light;
      justify-content: space-evenly;
    }

    @include desktop {
      background-color: $white;
      justify-content: center;
      margin-top: -75px;
      position: fixed;
      z-index: 1;
    }

    @include desktop-only {
      width: 470px;
    }

    @include desktop-medium {
      width: 560px;
    }

    li {
      display: flex;
      padding: 0 6px;
      text-align: center;

      @include desktop {
        margin: 0 20px;
      }

      &.selected span.category-name {
        color: $rit-copper-dark;
      }

      a {
        letter-spacing: normal;
        padding: 10px 0;
        text-align: center;
        text-decoration: none;
        width: 100%;
      }

      span {
        &.category-name {
          color: $rit-black;
          font-family: $font-header;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  ul.options {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    @include desktop {
      flex-flow: wrap;
    }

    &:first-child li:first-child {
      @include mobile-and-tablet {
        margin-left: 30px;
      }
    }

    &:last-child li:last-child {
      @include mobile-and-tablet {
        margin-right: 30px;
      }
    }

    li {
      border: 1px solid $white-smoke;
      position: relative;
      text-align: center;

      @include mobile-and-tablet {
        margin: 10px 5px;
      }

      @include desktop {
        margin: 8px;
      }

      &:not(.has-thumb) {
        a {
          @include mobile-and-tablet {
            padding: 25px 15px;
          }

          @include desktop {
            padding: 35px 15px;
          }
        }
      }

      &.has-thumb {
        a {
          padding: 33px 10px 28px 10px;

          @include desktop {
            padding: 37px 15px 28px 15px;
          }
        }
      }

      &::before {
        background-color: $rit-copper-dark;
        content: '';
        display: inline-block;
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s $navigation-ease;
        width: 100%;
        z-index: 2;
      }

      &:hover,
      &.selected {
        box-shadow: 2px 4px 10px 0 rgba($black, 0.06);
      }

      &.selected::before {
        opacity: 1;
      }

      a {
        display: flex;
        flex-flow: column;
        height: 100%;
        letter-spacing: normal;
        text-decoration: none;

        @include mobile-and-tablet {
          justify-content: space-between;
        }

        &.bottle-wrapper {
          img {
            width: 40px;

            @include desktop {
              width: 50px;
            }
          }
        }

        &.bottle-cap-wrapper {
          img {
            width: 70px;

            @include mobile-and-tablet {
              margin: -5px 0 0;
            }

            @include desktop {
              margin-bottom: 4px;
            }
          }
        }
      }

      span {
        &.error-badge {
          left: calc(50% + 35px);
          position: absolute;
          top: calc(50% + 15px);

          @include mobile-and-tablet {
            height: 18px;
            width: 18px;
          }

          @include desktop {
            height: 20px;
            width: 20px;
          }
        }

        &.error-message {
          background-color: $rit-sandy-bg;
          color: $rit-black;
          font-size: 11px;
          left: 0;
          letter-spacing: 1px;
          line-height: 13px;
          padding: 4px 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1;

          .error-badge {
            display: inline-block;
            height: 12px;
            left: auto;
            position: relative;
            top: 2px;
            width: 12px;

            &::before {
              bottom: 1px;
            }

            &::after {
              top: 1px;
            }
          }
        }

        &.option-name {
          color: $rit-black;
          font-family: $font-header;
          font-size: 18px;
          line-height: 21px;
        }

        &.option-excerpt {
          color: rgba($rit-black, .5);
          font-family: $font-header;
          font-size: 15px;
          line-height: 20px;

          @include desktop {
            flex-grow: 1;
            margin-top: 20px;
          }
        }

        &.option-more {
          font-size: 12px;
          letter-spacing: 2px;
          line-height: normal;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }

      .option-thumb {
        height: 100%;
        width: 100%;

        img {
          width: 70px;
          margin-bottom: 8px;

          @include tablet {
            margin-bottom: 4px;
          }

          @include desktop {
            margin-bottom: 22px;
          }
        }

        & + .option-name {
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 19px;
        }
      }
    }
  }

  .category-indicator-scrim {
    @include desktop {
      height: 2px;
      margin-top: -25px;
      position: fixed;
      z-index: 2;
    }

    @include desktop-only {
      width: 470px;
    }

    @include desktop-medium {
      width: 560px;
    }
  }

  .category-indicator {
    background-color: $rit-copper-dark;
    left: 0;

    @include mobile-and-tablet {
      height: 1px;
      position: absolute;
      top: 48px;
    }

    @include desktop {
      height: 100%;
      position: absolute;
    }

    &.allow-animation {
      transition: left 0.3s $navigation-ease;
    }
  }

  .part-options-slider {
    position: relative;
    scroll-behavior: smooth;

    @include mobile-and-tablet {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
    }

    &.has-statusbar {
      @include mobile-and-tablet {
        padding-bottom: 25px;
      }
    }

    &.has-wide-tiles {
      ul.options li:not(.has-thumb) a {
        padding: 25px 15px;
      }

      .option-thumb {
        height: auto;

        & + .option-name {
          font-size: 18px;
          line-height: 21px;
          letter-spacing: normal;
        }

        img {
          max-width: 41px;
          padding-bottom: 9px;
          margin-bottom: 0;

          @include mobile-and-tablet {
            max-width: 30px;
            padding-bottom: 0;
          }
        }
      }

      .option-name {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: normal;

        @include mobile-and-tablet {
          letter-spacing: 0.5px;
        }
      }

      .option-excerpt {
        margin-top: 10px;

        @include mobile-and-tablet {
          margin-top: 2px;
        }
      }

      .option-more {
        margin-top: 20px;

        @include mobile-and-tablet {
          margin-top: 12px;
        }
      }
    }

    h4.category-name {
      color: $rit-black;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 14px;
      margin: 0 6px;
      padding-top: 20px;
    }
  }

  .fragrance-options-slider {
    position: relative;
    height: calc(100% - 11px);
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mobile-only {
      height: calc(100% - 154px);
    }

    @include mobile-and-tablet {
      left: 0;
      opacity: 1;
      transition: left 0.6s $navigation-ease,
        opacity 0.6s $navigation-ease;
    }

    @include tablet-only {
      width: 120%;
      margin-left: -10%;
      height: calc(100% - 114px);
    }

    @include desktop-large {
      width: 120%;
      margin-left: -10%;
    }

    .fragrance-slider-body {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 50px;
        z-index: 1;
        display: none;

        @include desktop-only {
          display: block;
        }
      }

      &::before {
        left: 0;
        background: linear-gradient(to right, $white, rgba($white, 0));
      }

      &::after {
        right: 0;
        background: linear-gradient(to left, $white, rgba($white, 0));
      }
    }

    .slick-slide {
      padding: 0 5px;
    }

    .slider-gap {
      display: none;

      @include tablet {
        display: block;
      }
    }

    .configurator-slider-controls {
      display: block;

      @include mobile-only {
        display: none;
      }

      button {
        z-index: 2;

        &:first-child {
          @include tablet {
            left: -4px;
          }
        }

        &:last-child:not(:only-child) {
          @include tablet {
            right: -4px;
          }
        }
      }
    }

    .slider-item {
      border: 1px solid $white-smoke;
      opacity: 0.2;
      pointer-events: none;
      transition: opacity 0.8s $navigation-ease;

      &.selected {
        opacity: 1;
        pointer-events: auto;
      }

      .content-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        text-decoration: none;
        padding: 24px 27px 18px;
        cursor: pointer;
      }

      .error-message {
        color: $red-error;
        font-size: 11px;
        left: 0;
        line-height: 13px;
        padding: 4px 0;
        position: absolute;
        top: -4px;
        width: 100%;
        z-index: 1;
        text-align: center;

        .error-badge {
          background: url('../images/error-badge.svg') left top no-repeat;
          background-size: 100% 100%;
          border-radius: 50%;
          display: inline-block;
          height: 16px;
          left: auto;
          position: relative;
          top: 4px;
          width: 16px;
          margin-right: 6px;
        }
      }

      .option-image {
        margin-bottom: 18px;
        max-width: 100%;

        img {
          display: block;
          max-width: 100%;
          max-height: 160px;
          margin: 0 auto;
        }
      }

      .option-category {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1px;
        color: $rit-black;
        display: block;
        font-family: $font-header;
        margin-bottom: 5px;
        text-align: center;
      }

      .option-name {
        display: block;
        font-family: $font-header;
        font-size: 32px;
        line-height: 41px;
        color: $rit-black;
        text-align: center;
        margin-bottom: 21px;
        letter-spacing: 0.5px;
        max-width: 100%;

        @include mobile-only {
          max-width: 150px;
        }
      }

      .option-more-wrapper {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
      }

      .option-more {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        color: $rit-copper-dark;
        text-decoration: underline;
      }
    }
  }
}

.upsell-options {
  @include mobile-only {
    overflow-x: auto;
    overflow-y: hidden;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    @include tablet-only {
      justify-content: center;
    }

    li {
      @include mobile-only {
        height: 73vh;
        flex: 0 0 80%;
        padding-bottom: 10px;
      }

      @include tablet {
        min-height: 255px;
        width: 40%;
      }

      @include desktop {
        width: 50%;
      }

      &:hover,
      &.selected {
        .upsell-option {
          box-shadow: 2px 4px 10px 0 rgba($black, 0.06);
        }
      }

      &:first-child {
        .upsell-option {
          @include mobile-only {
            margin-left: 45px;
          }
        }
      }

      &:last-child {
        .upsell-option {
          @include mobile-only {
            margin-right: 45px;
          }
        }
      }
    }

    span {
      &.error-message {
        bottom: 5px;
        color: $red-error;
        font-size: 11px;
        left: 0;
        line-height: 13px;
        padding: 4px 0;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 1;
      }
    }

    figure {
      @include mobile-only {
        height: calc(50% + 30px);
        background-color: $rit-sandy-bg;
        margin: -30px 0 20px -20px;
        padding: 10px 0;
        width: calc(100% + 40px);
      }

      @include tablet {
        height: 220px;
        width: 100%;
      }

      img {
        max-height: 100%;
      }
    }
  }

  .upsell-option {
    border: 1px solid $white-smoke;
    padding: 30px 20px;
    position: relative;
    text-align: center;

    @include mobile-only {
      background-color: $white;
      height: calc(100% - 20px);
      margin: 10px 5px;
    }

    @include tablet-only {
      height: calc(100% - 60px);
      margin: 20px 8px;
    }

    @include desktop {
      height: calc(100% - 40px);
      margin: 20px 8px;
    }

    input[type=radio] {
      opacity: 0;
    }

    label {
      @include tablet {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &.selected {
        &::before {
          background-color: $copper;
          border-color: $copper;
          content: $icon-check;
        }
      }

      &::before {
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $gray-gainsboro;
        bottom: 30px;
        color: $white;
        content: '';
        font-family: iconfont;
        font-size: 8px;
        height: 24px;
        left: 50%;
        letter-spacing: -1px;
        line-height: 26px;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        width: 24px;
      }
    }
  }

  .upsell-product-title {
    font-family: $font-header;

    @include mobile-only {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 19px;
    }

    @include tablet {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 21px;
    }

    @include desktop {
      font-size: 22px;
      letter-spacing: 0.6px;
      line-height: 30px;
    }

    & + .upsell-product-description {
      margin-top: 10px;
    }
  }

  .upsell-product-description {
    display: block;

    @include mobile-only {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 17px;
    }

    @include tablet {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 28px;
    }

    @include desktop {
      font-size: 16px;
      letter-spacing: 1.14px;
      line-height: 28px;
    }
  }

  .upsell-product-price {
    font-family: $font-header;

    @include mobile-only {
      align-items: center;
      bottom: 70px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      height: 50px;
      justify-content: flex-end;
      letter-spacing: 2px;
      line-height: 21px;
      position: absolute;
      width: calc(100% - 40px);
    }

    @include tablet {
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 21px;
      margin-bottom: 40px;
      margin-top: auto;
    }

    @include desktop {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      margin-bottom: 40px;
      margin-top: auto;
    }
  }
}

.status-bar {
  opacity: 1;
  text-align: center;

  p {
    font-size: 11px;
    line-height: 13px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 4px 8px;
    max-width: 400px;
    margin: 0 auto;

    @include mobile-and-tablet {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.overlay {
  background-color: $white;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  @include mobile-and-tablet {
    left: 0;
    width: 100%;
  }

  @include desktop {
    left: 50%;
    width: 50%;
  }

  h1,
  p {
    color: $rit-black;
  }

  h1 {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 32px;
    text-transform: none;
  }

  p {
    font-family: $font-header;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .overlay-slot {
    height: 100%;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    text-align: center;

    &::before,
    &::after {
      content: '';
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.6s $navigation-ease;
      width: 100%;
      z-index: 1;

      @include mobile-and-tablet {
        position: fixed;
      }

      @include desktop {
        position: absolute;
      }
    }

    &::before {
      height: 60px;
      top: 0;
    }

    &::after {
      bottom: 0;
      height: 180px;
    }

    &.top-gradient::before,
    &.bottom-gradient::after {
      opacity: 1;
    }

    &.top-gradient::before {
      background: linear-gradient(to bottom, $white, rgba($white, 0));
    }

    &.bottom-gradient::after {
      background: linear-gradient(to top, $white, $white 68%, rgba($white, 0));
    }

    .simplebar-offset {
      -webkit-overflow-scrolling: auto;
    }
  }

  .overlay-body {
    margin: 0 auto;

    @include mobile-only {
      min-height: calc(100% - 135px);
      padding: 20px 0 115px;
    }

    @include mobile-and-tablet {
      width: 75%;
    }

    @include tablet-landscape {
      p {
        margin-bottom: 110px;
      }
    }

    @include desktop {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      min-height: 100vh;
      width: 66.67%;
    }

    &.fragrance-overlay {
      .fragrance-image {
        max-width: 135px;
      }
    }

    &.has-scrollbar {
      @include mobile-and-tablet {
        min-height: calc(100% - 215px);
        padding: 100px 0 115px;
      }
    }

    &:not(.has-scrollbar) {
      @include mobile-and-tablet {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100%;
      }
    }
  }

  .overlay-close {
    background-color: $rit-black;
    border-radius: 50%;
    bottom: 40px;
    color: $white;
    height: 64px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 64px;
    z-index: 2;
  }

  .simplebar-track {
    z-index: 3;
  }

  .fragrance-image {
    max-width: 50px;
    margin: 0 auto;

    img {
      max-width: 100%;
    }

    & + h1 {
      padding-top: 9px;
      margin-bottom: 20px;
      max-width: 335px;
    }

    & ~ p {
      margin-bottom: 20px;

      @include mobile-only {
        font-size: 16px;
      }
    }
  }

  .fragrance-note {
    position: relative;
    max-width: 335px;
    margin: 0 auto 24px;

    &:not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 80px;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white-smoke;
      }
    }

    .fragrance-title,
    .fragrance-text {
      display: block;
      color: $rit-black;
      font-family: $font-header;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .fragrance-title {
      font-size: 18px;
    }

    .fragrance-text {
      font-size: 16px;
    }
  }
}

//
// Parts / Options Transitions
// --------------------------------------------------
//

.footer-header-transition-enter,
.footer-header-transition-leave-to {
  @include tablet-only {
    opacity: 0 !important;
  }
}

.footer-header-transition-enter-active {
  @include tablet-only {
    transition: opacity 0.6s $navigation-ease 0.6s;
  }
}

.footer-header-transition-leave-active {
  @include tablet-only {
    transition: opacity 0.6s $navigation-ease;
  }
}

.full-height-footer-transition-enter-active {
  &:not(.upsell-footer) {
    @include mobile-and-tablet {
      transition: opacity 0.3s $navigation-ease;
    }
  }

  &.upsell-footer {
    @include mobile-only {
      transition: opacity 0.3s $navigation-ease;
    }
  }
}

.full-height-footer-transition-leave-active {
  &:not(.upsell-footer) {
    @include mobile-and-tablet {
      transition: opacity 0.3s $navigation-ease 0.2s;
    }
  }

  &.upsell-footer {
    @include mobile-only {
      transition: opacity 0.3s $navigation-ease 0.2s;
    }
  }
}

.full-height-footer-transition-enter,
.full-height-footer-transition-leave-to {
  &:not(.upsell-footer) {
    @include mobile-and-tablet {
      opacity: 0;

      .categories,
      .category-indicator-scrim {
        opacity: 0;
      }

      .fragrance-options-slider {
        left: 50px;
        opacity: 0;
      }

      .button-row {
        bottom: -100px !important;
      }

      &.parts-footer:not(.invert-direction),
      &.parts-footer.invert-direction {
        bottom: -415px;
        opacity: 1;
      }

      &.options-footer {
        opacity: 0;
      }
    }
  }

  &.upsell-footer {
    @include mobile-only {
      opacity: 0;

      .button-row {
        bottom: -100px !important;
      }

      &.options-footer {
        opacity: 0;
      }
    }

    &.options-footer:not(.invert-direction),
    &.options-footer.invert-direction {
      @include tablet-only {
        bottom: -415px;
        opacity: 1;
      }
    }
  }
}

.full-height-footer-transition-enter-active,
.full-height-footer-transition-leave-active {
  &.parts-footer:not(.invert-direction),
  &.parts-footer.invert-direction {
    @include mobile-and-tablet {
      transition: bottom 0.8s $navigation-ease;
    }
  }

  &.options-footer {
    &:not(.upsell-footer) {
      @include mobile-and-tablet {
        transition: opacity 0.6s $navigation-ease;
      }
    }

    &.upsell-footer {
      @include mobile-only {
        transition: opacity 0.6s $navigation-ease;
      }

      @include tablet-only {
        transition: bottom 0.8s $navigation-ease;
      }
    }
  }
}

.switch-footers-transition-enter,
.switch-footers-transition-leave-to {
  &.parts-footer,
  &.options-footer {
    @include mobile-and-tablet {
      bottom: -415px;
    }
  }

  // &.upsell-footer {
  //   @include tablet-only {
  //     bottom: -415px;
  //   }
  // }

  &.result-footer {
    @include mobile-and-tablet {
      opacity: 0;
    }
  }
}

.switch-footers-transition-enter {
  &.parts-footer:not(.invert-direction) {
    @include desktop {
      opacity: 0;
      right: -200px;
      z-index: 3 !important;
    }
  }

  &.parts-footer.invert-direction {
    @include desktop {
      opacity: 0;
      right: 100px;
      z-index: 3 !important;
    }
  }

  &.options-footer,
  &.result-footer {
    @include desktop {
      opacity: 0;
      right: -100px;
      z-index: 3 !important;
    }
  }
}

.switch-footers-transition-leave-to {
  &.parts-footer:not(.invert-direction),
  &.parts-footer.invert-direction {
    @include desktop {
      opacity: 0;
      right: 100px;
      z-index: 3 !important;
    }
  }

  &.options-footer,
  &.result-footer {
    @include desktop {
      opacity: 0;
      right: -100px;
      z-index: 3 !important;
    }
  }
}

.switch-footers-transition-enter-active,
.switch-footers-transition-leave-active {
  &.parts-footer,
  &.options-footer {
    @include mobile-and-tablet {
      transition: bottom 0.6s $navigation-ease;
    }
  }

  // &.upsell-footer {
  //   @include tablet-only {
  //     transition: bottom 0.6s $navigation-ease;
  //   }
  // }

  &.result-footer {
    @include mobile-and-tablet {
      transition: opacity 0.6s $navigation-ease;
    }
  }
}

.switch-footers-transition-enter-active {
  &.parts-footer:not(.invert-direction) {
    @include desktop {
      transition: right 0.6s $navigation-ease,
        opacity 0.6s $navigation-ease;
    }
  }

  &.options-footer,
  &.result-footer,
  &.parts-footer.invert-direction {
    @include desktop {
      transition: right 0.6s $navigation-ease 0.4s,
          opacity 0.6s $navigation-ease 0.4s;
    }
  }
}

.switch-footers-transition-leave-active {
  @include desktop {
    transition: right 0.6s $navigation-ease,
      opacity 0.6s $navigation-ease;
  }
}

.footer-backdrop-transition-enter,
.footer-backdrop-transition-leave-to {
  @include desktop {
    margin-left: 200px;
  }
}

.footer-backdrop-transition-enter-active,
.footer-backdrop-transition-leave-active {
  @include desktop {
    transition: margin 0.6s $navigation-ease;
  }
}

.part-options-transition-enter,
.part-options-transition-leave-to {
  @include mobile-and-tablet {
    margin-left: 200px;
  }
}

.part-options-transition-enter-active,
.part-options-transition-leave-active {
  @include mobile-and-tablet {
    transition: margin 0.8s $navigation-ease;
  }
}

.overlay-transition-enter,
.overlay-transition-leave-to {
  opacity: 0;

  .overlay-slot {
    opacity: 0 !important;
  }
}

.overlay-transition-enter {
  .overlay-slot {
    @include mobile-and-tablet {
      margin-top: 190px !important;
    }

    @include desktop {
      margin-top: 250px !important;
    }
  }
}

.overlay-transition-enter-active,
.overlay-transition-leave-active {
  transition: opacity 0.8s $navigation-ease;
}

.overlay-transition-enter-active {
  .overlay-slot {
    transition: margin 0.8s $navigation-ease,
      opacity 0.8s $navigation-ease;
  }
}

.status-bar-transition-enter,
.status-bar-transition-leave-to {
  p {
    opacity: 0;
  }
}

.status-bar-transition-enter-active,
.status-bar-transition-leave-active {
  p {
    transition: opacity 0.6s $navigation-ease;
  }
}


//
// Scenes / dialog
// --------------------------------------------------
//

.scene {
  height: 100%;

  @include desktop {
    position: relative;
  }

  .backdrop {
    @include desktop {
      background-color: $white;
      left: 50%;
      z-index: 1;
    }
  }
}

.dialog {
  background: $rit-sandy-light;
  letter-spacing: 1px;
  line-height: 28px;
  text-align: center;
  z-index: $modal-window-index;

  @include mobile-and-tablet {
    box-shadow: 0 4px 6px -3px $gray-medium;
    min-height: 149px;
    padding: 30px;
    position: fixed;
    top: 0;
    width: 101%;
  }

  @include desktop {
    left: 50%;
    max-width: 480px;
    padding: 60px 70px;
    position: absolute;
    top: 35%;
    transform: translate(-50%);
  }

  a {
    @include mobile-and-tablet {
      font-size: 14px;
    }
  }

  .icon-close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 16px;
    font-size: 16px;
  }
}


//
// Scenes / dialog transitions
// --------------------------------------------------
//

.dialog-transition-enter,
.dialog-transition-leave-to {
  @include mobile-and-tablet {
    transform: translateY(-110%);
  }
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  @include mobile-and-tablet {
    transition: transform 0.6s $navigation-ease;
  }
}

.scrim-dialog-transition-enter,
.scrim-dialog-transition-leave-to {
  opacity: 0;
}

.scrim-dialog-transition-leave-active {
  @include mobile-and-tablet {
    transition: opacity 0.8s $navigation-ease 0.4s;
  }

  @include desktop {
    transition: opacity 0.8s $navigation-ease;
  }
}

.scrim-dialog-transition-enter-active {
  transition: opacity 0.8s $navigation-ease;
}
