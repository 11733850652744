.pt_splashpage {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 86px 20px 0;
  display: flex;
  flex-direction: column;

  @include tablet {
    padding-top: 175px;
    padding-bottom: 0;
  }

  .row {
    z-index: 2;
  }

  .fill-row {
    z-index: 1;
  }

  .select--custom.select-box {
    background-repeat: no-repeat;
  }

  .selectric-wrapper {
    .selectric {
      padding: 5px 17px 2px;
      height: 37px;
      border: 0;
      border-bottom: 1px solid $gray-gainsboro;
      background-color: transparent;

      .button {
        right: -4px;
        top: 7px;
      }

      .label {
        font-family: $font-header;
        letter-spacing: 1px;
        line-height: 28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }

    .selectric-hide-select {
      height: 14px;
      position: absolute;
      left: 0;
      top: 12px;
      width: 16px;

      .select-box {
        background-position: left top;
        background-size: 16px auto;
        border-bottom: 0;
        float: left;
        font-size: 0;
        height: 16px;
        width: 16px;
        padding: 0;
      }
    }
  }
}

.splashpage-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.splash-rituals-logo {
  font-size: 0;
  overflow: hidden;
  margin: auto;
  width: 60px;
  height: 56px;
  margin-bottom: 33px;

  img {
    width: 100%;
  }
}

.splash-box {
  padding: 40px 30px 60px;
  margin: auto;
  box-shadow: 0 2px 9px 0 rgba($black, 0.3);
  background: $rit-sandy-bg;
  max-width: 420px;

  @media screen and (min-width: 321px) {
    padding: 40px 40px 60px;
  }

  @include tablet {
    padding: 40px 60px 60px;
  }

  .content-asset {
    text-align: center;

    h2.h6 {
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 30px;
      color: $black;
      margin-bottom: 27px;

      @include tablet {
        margin-bottom: 37px;
      }
    }
  }

  .wrapper__country-selector {
    .country-selector {
      background-color: transparent;
      position: relative;

      &:first-of-type {
        margin-bottom: 17px;
      }

      em {
        position: absolute;
        right: -2px;
        top: 10px;

        @media screen and (min-width: 1025px) {
          display: none;
        }

        i {
          font-size: 26px;
          color: $rit-black;
          @include icon(arrow3--down);
        }
      }
    }

    label {
      font-size: 9px;
      letter-spacing: 1.8px;
      color: $rit-dark;
      text-transform: uppercase;
      margin-bottom: -6px;
    }

    .custom-select {
      width: 100%;
      border-bottom: 1px solid $gray-gainsboro;
      background-position: 0% 50%;
      padding: 5px 35px 5px 24px;
      font-family: $font-header;
      background-size: 15px 16px;
      border-radius: 0;
    }
  }

  .btn--text {
    width: auto;
    margin: 39px auto 0;
    display: block;
  }
}

.splashpage-locales {
  text-align: center;
  margin: 14px 0 20px;

  @include tablet {
    margin: 147px 20px 20px;
  }

  .white-bg {
    background-color: transparent;
    padding: 5px 0;
    max-width: 280px;
    display: block;
    margin: 0 auto;

    @media screen and (min-width: 375px) {
      max-width: 340px;
    }

    @include tablet {
      max-width: 370px;
    }
  }

  .locale-link {
    display: inline-block;
    color: $rit-copper;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    vertical-align: middle;

    &::before,
    &::after {
      content: '';
    }

    &::before {
      margin-left: 1px;

      @media screen and (min-width: 375px) {
        margin-left: 4px;
      }

      @include tablet {
        margin-left: 6px;
      }
    }

    &:not(:last-child)::after {
      border-right: 1px solid $gray-gainsboro;
      height: 100%;
      margin-left: 3px;

      @media screen and (min-width: 375px) {
        margin-left: 8px;
      }

      @include tablet {
        margin-left: 7px;
      }
    }
  }
}
