.discover-block {
  background-color: $white;
  padding: 20px 20px 36px;
  position: relative;
  text-align: center;

  .image-holder {
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto 20px;
    height: 90px;
    width: 90px;
  }

  h2 {
    font-size: 11px;
    letter-spacing: 1.6px;
    line-height: 14px;
    margin-bottom: 25px;
  }

  &.title-bigger {
    h2 {
      font-size: 30px;
      line-height: 35px;
    }
  }

  p {
    font-family: $font-header;
    font-size: 24px;
    letter-spacing: .6px;
    line-height: 44px;
  }

  &.p-scala {
    p {
      font-family: $font-body;
      font-size: 14px;

      @include tablet {
        font-size: 16px;
      }
    }
  }

  .short-desc {
    width: 100%;
  }

  .long-desc {
    background-color: $white;
    display: none;
    width: 100%;
  }

  .btn {
    margin-top: 8px;

    .less {
      display: none;
    }

    &.open {
      .more {
        display: none;
      }

      .less {
        display: inline-block;
      }
    }
  }

  &.secondary-text-styling {
    .inner-wrapper {
      max-width: 860px;
    }

    img {
      margin-bottom: 20px;
    }

    p {
      @include mobile-only {
        letter-spacing: 1.2px;
        line-height: 32px;
      }

      @include tablet {
        font-size: 30px;
        letter-spacing: 1.2px;
        line-height: 40px;
      }
    }
  }

  &.txt--white {
    p {
      color: $white;
    }

    .btn {
      border-color: $white;
      color: $white;

      @include desktop {
        &:hover,
        &:active {
          background-color: $white;
          border-color: $white;
          color: $rit-dark;
        }
      }
    }
  }

  &.txt--black {
    p {
      color: $rit-black !important;
    }
  }

  &.txt--red {
    p {
      color: $crimson !important;
    }
  }

  &.bgd--black {
    background-color: $rit-black;

    .long-desc {
      background-color: $rit-black;
    }
  }

  &.bgd--lightgrey {
    background-color: $grey-lightest;

    .long-desc {
      background-color: $grey-lightest;
    }
  }

  @include mobile-only {
    padding: 45px 20px 40px;

    p {
      font-size: 20px;
      letter-spacing: 0.56px;
      line-height: 30px;
    }
  }

  @include tablet {
    padding: 60px 0;

    .inner-wrapper {
      max-width: 1080px;
      padding: 0 60px;
    }

    h2 {
      font-size: 16px;
      letter-spacing: 5px;
      line-height: 24px;
      margin-bottom: 21px;
    }
  }

  @include desktop {
    .inner-wrapper {
      padding: 0 20px;
    }
  }
}

.discover-block-limited {
  padding: 42px 20px 29px;

  p {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
  }

  .icon {
    font-size: 31px;
    margin-bottom: 17px;
  }

  @include tablet {
    padding: 62px 0 70px;

    p {
      font-size: 20px;
      line-height: 34px;
    }

    .inner-wrapper {
      max-width: 1150px;
      padding: 0 20px;
    }

    .icon {
      font-size: 52px;
      margin-bottom: 24px;
    }
  }
}
