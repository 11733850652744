.pt_recommended-products {
  width: 100%;

  &.one-by-three-grid {
    .grid-wrap {
      margin-left: 0;
    }
    .item {
      margin: 5px 5px 10px;
      width: calc(50% - 10px);

      @include tablet {
        margin-bottom: 20px;
        width: calc(50% - 20px);
      }
    }
    .recommendations-block-wrapper .section-title {
      padding-left: 5px;
    }
  }
}

// style dynamic Einstein div
[id^=cq_recomm] {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}
