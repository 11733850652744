//
// This styles the flyout menu on the right side of the site.
// It contains a head, body and footer part.
//

.flyout-content {
  position: fixed;
  display: inline-block;
  width: 100%;
  top: 0;
  left: auto;
  bottom: 0;
  background-color: $white;
  z-index: $modal-window-index;

  &.minicart-flyout-container {
    width: 100%;
    max-width: 100%;
    right: -100%;
    color: $black;
    transform: translateZ(0);
    transition: right .5s ease-in-out;

    &.open {
      right: 0;
    }

    @include tablet {
      width: 480px;
    }

    @include tablet-landscape {
      width: 400px
    }

    @include desktop {
      max-width: 520px;
      width: 50%;
      right: -50%;
    }

    .checkout-flyout-inner-content {
      background-color: $rit-sandy-bg;
      overflow: hidden;
      padding-bottom: 206px;
      height: 100%;
      display: flex;
      flex-direction: column;
      -webkit-overflow-scrolling: touch;

      &.mini-cart-flyout-empty {
        padding-bottom: 0;
      }

      .minicart-flyout-wrapper {
        overflow: hidden;
        padding: 40px;

        @media screen and (max-width: 520px) {
          padding: 40px 20px;
        }

        .minicart-flyout-header {
          padding: 23px 0 16px;

          @include tablet {
            padding-bottom: 10px;
            padding-left: 40px;
          }
        }

        .flyout-title {
          color: $rit-black;
          font-size: 30px;
          letter-spacing: .7px;
          line-height: 34px;
          text-transform: none;
          margin-bottom: 0;
        }

        .flyout-title-overview {
          color: $rit-dark;
          display: block;
          font-size: 14px;
          letter-spacing: .5px;
          line-height: 1.3;
          margin: 16px 0 27px;
        }

        .flyout-progress-bar {
          @include tablet {
            align-items: unset;
            margin: 40px;
           }
        }

        .mini-cart-empty-text {
          color: $gray-medium;
          font-size: 14px;
          letter-spacing: .5px;
          margin-bottom: 10px;
          padding: 0 0 53px;
          border-bottom: 1px solid $gray-gainsboro;

          @include tablet {
            padding-left: 40px;
          }
        }

        .empty-minicart-bottom {
          text-align: center;
          margin-top: 98px;

          a {
            margin-bottom: 20px;
            background-color: $rit-black;
            border: 1px solid $rit-black;
            min-width: 280px;

            @include tablet {
              margin-bottom: 34px;
              min-width: 200px;
            }

            &:hover,
            &:active {
              background-color: transparent;
              color: $rit-dark;
            }
          }

          p {
            font-size: 14px;
            color: $gray-medium;
            letter-spacing: 0.88px;
          }
        }

        .mini-cart-pricing .link {
          margin-right: 23px;
        }

        .product-availability-list {
          list-style: none;

          .notavailable {
            margin: 19px 0 0;
            text-transform: none;
            color: $red-error;
            font-family: $font-body-bold;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1px;
          }
        }
      }

      .flyout-section {
        position: relative;
        height: 100%;
        overflow-y: auto;
      }

      .mini-cart-product {
        border-bottom: 1px solid $gray-gainsboro;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        @include desktop {
          padding: 16px 24px;
        }

        &:first-of-type {
          border-top: 1px solid $gray-gainsboro;

          @include desktop {
            margin-top: unset;
          }
        }

        .mini-cart-image {
          flex-shrink: 0;
          margin-right: 10px;
          padding-top: 2px;
          width: 100px;

          @include tablet {
            min-height: 118px;
            padding-top: 8px;
          }

          a {
            display: block;
            text-align: center;
          }

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .mini-cart-info {
          color: $rit-copper;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          font-family: $font-header;
          font-size: 10px;
          letter-spacing: 1.5px;
          line-height: 12px;
          max-width: calc(100% - 120px);
          text-transform: uppercase;

          @include mobile-only {
            justify-content: center;
          }

          @include tablet {
            color: $rit-black;
            font-size: 16px;
            letter-spacing: 1.07px;
            line-height: 24px;
            margin: 24px 0;
            text-transform: none;
          }

          .mini-cart-small-title {
            color: $copper;
            font-family: $font-header;
            font-size: 12px;
            letter-spacing: 1.7px;
            line-height: 16px;
            margin-bottom: 5px;
            text-transform: uppercase;
          }

          .mini-cart-name {
            padding-right: 70px;

            @include tablet {
              padding-right: 100px;
            }

            a {
              display: inline-block;
              font-size: 12px;
              letter-spacing: 1.6px;
              line-height: 16px;
              color: $rit-black;
              text-decoration: none;
              font-family: $font-header;
              max-width: 180px;
              padding-top: 2px;
              text-transform: none;

              @include tablet {
                max-width: 250px;
                font-size: 20px;
                letter-spacing: 0.5px;
                line-height: 24px;
                padding-top: 0;
              }
            }
          }

          .mini-cart-pricing {
            font-size: 14px;
            letter-spacing: 0.4px;
            line-height: 24px;
            color: $gray-dark;
            font-family: $font-body;
            margin-top: 5px;

            .unit-base-price {
              display: none;
            }
          }
        }

        .mini-cart-price-wrapper {
          position: absolute;
          right: 0;
          text-align: right;

          @include mobile-only {
            top: 27px;
          }

          @include tablet {
            top: 56px;
          }

          &.no-ancient-ritual {
            @include tablet {
              top: 36px;
            }
          }

          &.is-multiline {
            @include tablet-only {
              top: 73px;
            }
          }

          .mini-cart-price {
            font-size: 12px;
            letter-spacing: 0.86px;
            color: $rit-black;
            font-family: $font-header;

            @include tablet {
              font-size: 15px;
              letter-spacing: 1.88px;
            }
          }

          .mini-cart-b2b-taxes {
            span {
              color: $ritual-samurai;
              display: block;
              font-family: $font-body;
              font-size: 14px;
              letter-spacing: 0.4px;
              line-height: 24px;
              width: 13ch;
            }
          }

          .price-after-discount {
            font-size: 12px;
            letter-spacing: 0.86px;
            color: $rit-black;
            font-family: $font-header;
            display: block;

            @include tablet {
              font-size: 15px;
              letter-spacing: 1.88px;
              line-height: 24px;
            }
          }

          .price-before-discount {
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 1.65px;
            color: $gray-suva;
            font-family: $font-header;
            text-decoration: line-through;
            display: block;
          }

          .product-discount-no-strikethrough {
            display: none;
          }

          .unit-base-price {
            display: none;
          }
        }

        .mini-cart-attributes {
          color: $rit-black;
          font-family: $font-body;
          font-size: 12px;
          letter-spacing: 1px;
        }

        .promotion-wrapper {
          left: 0;
          position: absolute;
          top: 16px;

          .callout-message {
            @include callout;
            border-radius: 0 2px 2px 0;
            display: block;
            max-width: 140px;
          }
        }

        .refill-container {
          margin-bottom: 24px;
          margin-top: 10px;
          max-width: none;
          width: 100%;

          @include desktop {
            border-radius: unset;
            margin-bottom: 0;
            margin-top: -8px;
            padding: 16px;
            gap: 16px;
          }
        }
      }

      .centered {
        text-align: center;
        max-width: 380px;
        margin: 0 auto;
      }

      .minicart-flyout-checkout {
        position: fixed;
        bottom: 0;
        padding-bottom: 30px;
        background-color: $white;
        max-width: 100%;
        width: 100%;

        &.has-shadow {
          box-shadow: 0 0 8px rgba($black, 0);
        }

        @include tablet {
          max-width: 520px;
        }

        .total {
          padding-bottom: 27px;

          .minicart-slot {
            font-size: 14px;
            letter-spacing: 1px;
            color: $rit-dark;
          }
        }

        .total-line,
        form {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          @include tablet {
            padding: 0 40px;
          }
        }

        .total-line {
          align-items: flex-start;
          flex-direction: column;

          @include tablet {
            padding: 0;
          }

          .total-label {
            align-items: center;
            border-bottom: 1px solid $gray-gainsboro;
            border-top: 1px solid $gray-gainsboro;
            display: flex;
            font-family: $font-header;
            font-size: 16px;
            justify-content: space-between;
            letter-spacing: 0.4px;
            line-height: 1;
            padding: 15px 20px;
            text-transform: capitalize;
            width: 100%;

            @include tablet {
              padding: 16px 40px;
            }
            }

          .total-value {
            font-family: $font-header;
            font-size: 16px;
            line-height: 1;
          }
        }

        .free-delivery-message {
          font-size: 14px;
          max-width: 300px;
          display: block;
          margin-top: 20px;
          letter-spacing: 0.5px;
          line-height: 20px;
          color: $rit-dark;
          text-transform: none;
          padding-left: 20px;

          @include tablet {
            padding-left: 40px;
          }
        }

        .minicart-continueshopping {
          display: inline-block;
          width: 47%;
          height: auto;

          .continue-shopping-btn {
            color: $rit-black;
            min-width: 0;
            text-decoration: none;
            width: 100%;
            height: 100%;
          }
        }

        .minicart-shopmore {
          margin-top: 27px;

          .link {
            font-size: 14px;
            letter-spacing: 0.5px;
          }
        }

        form {
          align-items: stretch;
          padding-top: 30px;

          .btn--submit {
            width: 47%;
            min-width: 0;
            z-index: 1;
          }
        }

        &.b2b-minicart {
          .total-value {
            display: flex;
            gap: 8px;

            .unit-base-price {
              margin-top: 0;
            }
          }
        }

        .spinner-custom-desktop {
          align-items: center;
          background-color: transparent;
          display: flex;
          height: 100%;
          justify-content: center;
          min-width: 0;
          position: absolute;
          right: 40px;
          width: 40%;

          span {
            background-color: transparent;
          }

          &.show {
            background-color: $rit-black;
            display: flex;

            span {
              background-color: $white;
            }
          }
        }
      }

      .wrapper--minicart__list {
        width: 100%;

        .premium-gift {
          border-top: 0;
        }
      }

      .gwp-welcome-banner-title {
        display: none;
      }

      .show-more-btn {
        margin-top: 20px;
        padding: 0 20px;

        @include tablet {
          padding: 0 40px;
        }

        @include desktop {
          padding: 0 78px;
        }
      }

      .product-name {
        font-size: 15px;
        color: $black;
        font-family: $font-header;
        letter-spacing: 5px;
        line-height: 23px;
      }

      .side--close {
        position: absolute;
        cursor: pointer;
        top: 34px;
        left: 27px;
        font-size: 22px;

        @include tablet {
          left: 36px;
        }

        &::before {
          color: $rit-black;
        }
      }
    }

    .rituals-form {
      position: relative;
    }
  }

  .side--close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    left: 20px;
    font-size: 25px;
  }

  .flyout-inner-content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .flyout-header {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    text-align: center;
    padding: 20px;

    @include tablet {
      padding: 30px;
    }

    span[class^='icon-'] {
      display: inline-block;
      margin-bottom: 15px;
      font-size: 44px;
    }

    p {
      width: 60%;
      margin: 0 auto;
    }

    .flyout-header-body {
      max-width: 380px;
      padding-top: 40px;
      text-align: left;
    }
  }

  .flyout-body {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    position: relative;
  }

  .error,
  .size-not-selected-message {
    display: block;
    padding: 0 20px;

    @include tablet {
      padding: 0 35px;
    }

    @include desktop {
      padding: 0 68px;
    }
  }

  .size-not-selected-message {
    color: $gray-dark;
  }

  .availability-disclaimer {
    margin-top: auto;
    width: 100%;
    text-align: center;
    padding: 20px;
    color: $gray-medium;
    font-size: 12px;
    line-height: normal;
  }
}

// Checkout 3 CRM mini cart
.flyout-section-crm {
  background-color: $white;

  .minicart-flyout-container.flyout-content & {
    .minicart-flyout-wrapper {
      padding: 40px 0;
    }

    .product-gift-ribbon {
      &::after {
        opacity: 1;
      }
    }

    .mini-cart-price-wrapper {
      right: 20px !important;

      @include tablet {
        right: 40px !important;
      }
    }

    .approaching-header,
    .mini-cart-product {
      padding: 16px 24px;
    }

    .approaching-header {
      border-bottom: 1px solid $gray-gainsboro;

      @include tablet {
        display: none;
        margin-bottom: 0;
      }
    }

    .has-promotions {
      .approaching-header {
        @include tablet {
          display: block;
        }
      }
    }

    .gwp-cart-banner {
      @include mobile-only {
        margin-left: 20px;
        margin-right: 20px;
      }

      @include tablet {
        margin-left: 40px;
        margin-right: 40px;
      }
    }

    .gwp-minicart-wrapper .approaching-promo.approaching-free-gift {
      margin: 0;

      .promo-product-details {
        @include mobile-only {
          padding-left: 20px;
          padding-right: 20px;
        }

        @include tablet {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .gwp-minicart-wrapper .approaching-promo.approaching-free-gift:last-of-type {
      border-bottom: 1px solid $gray-gainsboro;
      margin-top: 0;
    }

    .wrapper--minicart__list {
      & > span {
        @include mobile-and-tablet {
          padding-left: 24px;
        }

        @include desktop {
          padding-left: 40px;
        }
      }
    }
  }
}

.flyout-progress-bar {
  border: 1px solid $gray-gainsboro;
  display: flex;
  justify-content: space-between;
  max-width: 560px;
  padding: 24px;
  position: relative;

  @include mobile-only {
    margin: 0 24px 40px;
  }

  @include tablet {
   align-items: center;
   margin: 0 40px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 0;

    @include tablet-only {
      margin-bottom: 24px;
    }
  }

  .gwp-shoppingcart-wrapper & {
    .flyout-scroll-progress {
      position: initial;
      width: 100%;
    }

    @include mobile-only {
      padding-bottom: 84px;

      .btn {
        bottom: 16px;
        left: 23px;
        position: absolute;
        width: calc(100% - 46px);
      }
    }
  }
}

.flyout-scroll-progress {
  appearance: none;
  border-radius: 2px;
  border: 0;
  height: 8px;
  position: absolute;
  width: 100%;

  @include mobile-only {
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
  }

  @include tablet {
    bottom: 24px;
    left: 24px;
    width: calc(100% - 148px);
  }

  &::-webkit-progress-bar {
    background: $rit-sandy-dark;
    border-radius: 4px;
  }

  &::-webkit-progress-value {
    background-image: linear-gradient(to right, $rit-copper, $rit-gold);
    border-radius: 4px;
  }

  &::-moz-progress-bar {
    background-image: linear-gradient(to right, $rit-copper, $rit-gold);
    border-radius: 4px;
  }
}

.flyout-progress-text {
  padding-right: 20px;
  text-align: left;

  .btn {
    margin-top: 9px;
  }
}

.flyout-progress-img {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  width: 72px;

  img {
    max-width: 100%;
  }

  @include tablet {
    height: 100%;
  }
}
// Checkout 3 CRM mini cart end

.generic-dialog-flyout {
  position: fixed;
  display: inline-block;
  top: 0;
  left: auto;
  bottom: 0;
  box-shadow: 0 0 25px -5px rgba($black, 0.14);
  background-color: $white;
  z-index: $modal-window-index;
  width: 100%;
  max-width: 95%;
  right: -100%;
  color: $black;
  transition: right .5s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 2;
  }

  &::before {
    top: 0;
    height: 60px;
    box-shadow: 0 8px 5px -2px $white;
  }

  &::after {
    bottom: 0;
    height: 14px;
    box-shadow: 0 -8px 5px -2px $white;
  }

  @include tablet {
    max-width: 520px;
    width: 80%;
    right: -80%;
  }

  @include desktop {
    max-width: 520px;
    width: 50%;
    right: -50%;
  }

  &.open {
    right: 0;
  }

  .side--close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    left: 15px;
    font-size: 25px;
    z-index: $modal-close-index;
    color: $black;

    @include tablet {
      top: 33px;
      left: 36px;
      font-size: 23px;
    }
  }

  .generic-flyout-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.flyin-component {
      @include mobile-only {
        padding: 40px 4px;

        .section-header,
        .section-row {
          padding: 0 20px;
        }
      }

      @include tablet {
        padding: 40px 0;

        .section-header,
        .section-row {
          padding: 0 20px 0 40px;
        }
      }
    }

    .vue-form-row {
      .vue-form-element.half-width {
        width: 100%;
        max-width: initial;
      }
    }
  }

  .generic-flyout-header {
    @include mobile-only {
      padding-top: 25px;
    }

    @include tablet {
      padding-top: 35px;
    }
  }

  // Flyin specific overrides
  #flyinbonusProductChoice & { // sass-lint:disable-line no-ids
    .flyin-component {
      @include mobile-only {
        padding: 0;
      }

      @include tablet {
        padding: 0;
      }
    }
  }
}

.inputfield-row.instore-address .pac-container {
  top: 68px !important;
  left: 0 !important;

  @include tablet {
    top: 68px !important;
  }
}

.account-address-list .pac-container {
  top: 40px !important;
  left: 0 !important;
}

.shopping-cart-info-banner {
  padding-top: 40px;
  text-align: left;
  width: 100%;

  .cart-info-banner-title {
    color: $rit-black;
    display: block;
    font-family: $font-header-bold;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 24px;
    margin-bottom: 16px;
    text-transform: none;
  }
}

.cart-info-banner-inline {
  display: inline-flex;
  align-items: center;
  padding: 14px 20px;
  background-color: $white;

  span {
    font-size: 28px;
    color: $rit-copper;
  }

  @include mobile-only {
    span {
      min-width: 34px;
    }
  }
}

.cart-info-banner-inline-text {
  margin-left: 20px;

  h3 {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.7px;
    line-height: 16px;
    color: $rit-copper;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
}

.free-gift-banner {
  align-items: center;
  border-bottom: 1px solid $gray-lines;
  border-top: 1px solid $gray-lines;
  display: flex;
  padding: 15px 0;
  width: 100%;

  @include tablet {
    padding: 15px 7px;
  }

  .free-gift-image {
    flex-shrink: 0;
    width: 75px;

    @include tablet {
      width: 95px;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  .free-gift-text {
    padding-left: 20px;

    @include tablet {
      padding: 13px 0 0 35px;
    }
  }

  h3 {
    color: $rit-copper-dark;
    font-size: 10px;
    letter-spacing: 1.8px;
    line-height: 16px;

    @include tablet {
      font-size: 12px;
    }
  }

  p {
    color: $rit-black;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 0.5px;

    &:last-child {
      margin-bottom: 0;
    }

    @include tablet {
      letter-spacing: 0.3px;
    }
  }
}

.payment-wait-flyin {
  height: calc(100vh - 80px);
  position: relative;

  h3 {
    font-family: $font-header;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    padding-left: 15px;
    padding-top: 55px;
    text-transform: capitalize;

    @include tablet {
      padding-left: 40px;
    }
  }

  .payment-wait-text {
    font-family: $font-body;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding: 20px 15px;

    @include tablet {
      padding: 20px 40px;
    }

    span {
      display: block;
      margin-top: 50px;
    }
  }

  .payment-wait-footer {
    bottom: 0;
    left: 0;
    padding: 0 15px;
    position: absolute;
    width: 100%;

    @include tablet {
      padding: 0 40px;
    }

    .btn {
      max-width: none;
      width: 100%;
    }
  }
}

.refill-container.personalised-promotions-label {
  background-color: $rit-sandy-bg;
  border-radius: unset;
  padding: 16px;
  gap: 16px;

  @include mobile-only {
    margin: 8px 0 0 0;
    order: 99;
    position: relative;
  }

  .refill-inner {
    @include desktop {
      margin-left: 0;
    }

    .for-every-refill {
      color: $rit-black;

      @include desktop {
        margin: 0;
      }
    }
  }

  .personalised-promotions-label-icon {
    color: $rit-gold-dark;
    font-size: 24px;
    margin: auto 16px;
    position: relative;
    width: 24px;

    @include desktop {
      margin: unset
    }

    &::before {
      background-image: url('../images/icons/icon-members-rewards.svg');
      background-size: 24px 24px;
      content: '';
      height: 24px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
    }
  }
}

.refill-container.personalised-promotions-label {
  &.member-only {
    background-color: $rit-sandy-bg;

    @include mobile-and-tablet {
      gap: 6px;
    }

    .refill-inner {
      margin-left: 0;
      text-align: left;

      .for-every-refill, a {
        color: $rit-black;
        font-family: $font-body;
        font-size: 14px;
        line-height: 24px;

        span {
          font-weight: bold;
        }
      }
    }

    .personalised-promotions-label-icon {
      @include mobile-and-tablet {
        margin: 0 4px;
      }

      &::before {
        @include icon-styles;
        background-image: none;
        color: $rit-black;
        content: icon-char(circle-info);
        display: block;
        font-size: 24px;
        margin-right: 16px;
      }
    }

    &.order {
      max-width: 100%;
      order: 2;
      width: 100%;
    }
  }
}
