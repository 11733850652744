.collection-ingredients {
  width: 100%;

  .collection-ingredients-fullwidth,
  .collection-ingredients-cols-holder {
    background-color: $white;
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  &.txt--red,
  &.txt--white {
    h2,
    h3,
    p {
      color: inherit !important;
    }
  }

  .collection-ingredients-fullwidth {
    padding: 66px 17px 5px;

    h2 {
      font-size: 15px;
      letter-spacing: 5px;
      line-height: 23px;
      margin-bottom: 18px;
    }

    p {
      font-family: $font-header;
      font-size: 18px;
      letter-spacing: .6px;
      line-height: 30px;
    }
  }

  &.open {
    .collection-ingredients-row {
      &.buttons-holder {
        .btn-view-all {
          display: flex;
        }

        .btn-expand {
          .more {
            display: none;
          }

          .less {
            display: inline-block;
          }
        }
      }
    }
  }

  @include tablet {
    .collection-ingredients-fullwidth,
    .collection-ingredients-cols-holder {
      background-color: transparent;
    }

    .collection-ingredients-fullwidth {
      padding: 101px 0 38px;
      text-align: center;

      .inner-wrapper {
        max-width: 780px;
      }

      h2 {
        margin-bottom: 13px;
      }
    }
  }
}

.collection-ingredients-header {
  max-width: 760px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;

  h3 {
    font-size: 15px;
    letter-spacing: 5px;
  }

  p {
    font-size: 18px;
    font-family: $font-header;
  }

  @include mobile-only {
    background-color: $white;
  }
}

.collection-ingredients-image {
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.collection-ingredients-list-wrapper {
  padding: 20px 0;
  color: $white;
  background-color: $dark-purple;

  @include tablet {
    width: 50%;
    padding: 60px 77px;
  }
}

.collection-ingredients-content {
  h3 {
    margin-bottom: 40px;
    font-size: 32px;
    text-transform: initial;
  }

  @include mobile-only {
    h3 {
      font-size: 24px;
      text-align: center;
    }

    .drag-list {
      margin-left: 40px;
    }

    .drag-list-body {
      padding: 0 40px 0 0;
    }

    .simplebar-track {
      margin-left: 0;
      margin-right: 75px;
    }
  }
}

.collection-ingr-col {
  width: 82vw;
  margin-right: 20px;
}

.collection-ingredients-list {
  list-style: none;

  li {
    min-height: 87px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  h4 {
    font-size: 14px;
  }

  span {
    font-family: $font-header-italic;
    font-style: italic;
  }

  @include mobile-only {
    .collection-ingredients-li:last-child {
      border-bottom: initial;
    }
  }

  @include tablet {
    .simplebar-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &::before, &::after {
        display: none;
      }
    }

    li {
      width: 45%;

      &:last-child, &:nth-last-child(2) {
        border-bottom: initial;
      }
    }
  }
}

.collection-ingredients-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.image-holder {
    .collection-ingredients-column {
      width: 50%;
    }
  }

  &.text-holder {
    padding: 19px 40px;

    .collection-ingredients-column {
      border-top: 1px solid $gray-lines;
      display: none;
      margin-top: 20px;
      padding-top: 40px;

      &:first-child {
        border-top: 0;
        display: block;
        margin-top: 0;
        padding-top: 0;
      }
    }

    h3 {
      color: $black;
      font-size: 15px;
      letter-spacing: 5px;
      line-height: 23px;
      margin-bottom: 17px;
    }

    p {
      font-family: $font-header;
      font-size: 18px;
      letter-spacing: .6px;
      line-height: 30px;
    }
  }

  .collection-ingredients-column {
    width: 100%;
  }

  &.buttons-holder {
    flex-direction: column;
    padding: 7px 40px 39px;
    text-align: center;

    .btn-view-all {
      display: none;
      margin: 0 auto 10px;
      text-align: center;
      text-decoration: none;
    }

    .btn-expand {
      margin: 0 auto;

      .less {
        display: none;
      }
    }
  }

  @include tablet {
    &.image-holder {
      .collection-ingredients-column {
        align-items: center;
        display: flex;
        padding-top: 65px;
        text-align: center;
      }
    }

    &.text-holder {
      padding: 0;

      .collection-ingredients-column {
        border-top: 0;
        display: block;
        margin-top: 0;
        padding: 0 0 76px;
        width: 50%;
      }

      .column-inner {
        padding: 0 20px;
      }

      .column-inner-centered {
        max-width: 490px;
        padding: 0 20px;
        margin: 0 auto;
      }
    }

    .collection-ingredients-column {
      justify-content: flex-end;
      opacity: 0;
      transition: opacity 1s ease;

      &.col-fadein {
        opacity: 1;
      }

      .column-inner {
        float: right;
        width: 100%;
        max-width: 720px;
      }

      .column-inner-centered {
        width: 100%;
        text-align: left;

        img {
          max-width: 520px;
          width: 100%;
        }
      }
    }

    .collection-ingredients-column:nth-child(2n) {
      justify-content: flex-start;

      .column-inner {
        float: left;
      }
    }

    &.buttons-holder {
      display: none;
    }
  }
}

.collection-ingredients-limited {
  @include mobile-only {
    padding-top: 0;

    &.open {
      .collection-ingredients-row {
        &.buttons-holder {
          .btn-expand {
            border: 1px solid $gray-disabled;
          }
        }
      }
    }
  }

  @include tablet {
    .collection-ingredients-fullwidth {
      padding-top: 163px;
      .inner-wrapper {
        max-width: 1170px;
      }

      h2 {
        margin-bottom: 18px;
      }
    }
  }
}
