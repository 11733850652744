.content-banner {
  color: $rit-black;
  display: flex;
  position: relative;
  text-align: center;
  width: 100%;
  height: 70vh;

  .image-holder,
  .responsive-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform $secondary-duration $default-ease-out-fast;

    &.align-bg-top {
      background-position: top center;
    }
  }

  &[class*='in-viewport'] {
    overflow: hidden;
  }

  &.in-viewport .image-holder {
    transform: scale(1);
  }

  &.not-in-viewport .image-holder {
    transform: scale(1.2);
  }

  &.samples-banner {
    p {
      text-transform: uppercase;
      letter-spacing: 5px;
      margin: -6px 0 18px;
    }
  }

  &.banner-maintenance {
    max-height: 475px;

    @include mobile-only {
      max-height: 464px;
    }
  }

  &.banner-teaser {
    height: auto;

    @include tablet {
      min-height: 805px;
      max-height: none;
    }

    .inner-indented {
      @include mobile-only {
        padding: 0 40px;
      }
    }

    .video-holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .text-holder {
      @include mobile-only {
        padding: 105px 0 90px;
      }
    }

    .heading-h3 {
      color: $white;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: 2.3px;
      text-transform: uppercase;
      margin-bottom: 30px;

      @include tablet {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 5px;
        margin-bottom: 40px;
      }
    }

    .headline {
      color: $white;
      font-size: 42px;
      line-height: 52px;
      letter-spacing: 2.25px;
      text-transform: none;
      margin-bottom: 32px;

      @include tablet {
        font-size: 80px;
        line-height: 100px;
        letter-spacing: 5px;
        margin-bottom: 45px;
      }
    }

    p {
      color: $white;
      font-family: $font-header;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 19px;
      max-width: 650px;
      margin: 0 auto;

      @include tablet {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.7px;
        margin-bottom: 10px;
      }
    }
  }

  .text-holder {
    margin: 112px 0;
    position: relative;
    width: 100%;

    @include mobile-only {
      padding: 30px 0 0;
    }
  }

  .inner-indented {
    margin: 0 auto;
    max-width: 700px;
    width: 100%;

    &.has-logo {
      @include tablet-only {
        margin: 0;
        max-width: none;
        width: 65%;
      }
    }
  }

  p {
    color: $rit-black;
  }

  .btn {
    margin-top: 9px;

    &:first-of-type {
      margin-top: 20px;
    }
  }

  .ico {
    display: none;

    @include desktop-only {
      display: inline-block;
    }
  }

  &.banner-white-text {
    color: $white;

    p {
      color: $white;
    }
  }

  &.banner-small {
    min-height: 542px;
    height: auto;

    .retake-button-holder {
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
      z-index: 1;

      .retake-quiz-button {
        margin: 0;
        float: right;
        margin-right: 40px;
      }

      @include mobile-only {
        top: 10px;

        .retake-quiz-button {
          text-decoration: underline;
          border: 0;
          min-width: 0;
          margin-right: 0;
          padding: 15px 5px 14px;
          background-color: transparent;

          &:hover,
          &:active {
            background-color: transparent;
            color: $white;
          }

          &.mobile-link--copper {
            color: $rit-copper-dark;

            &:hover,
            &:active {
              background-color: transparent;
              color: $rit-copper-dark;
              text-decoration: none;
            }
          }
        }
      }
    }

    .additional-logo {
      display: flex;

      @include mobile-only {
        margin: 0 auto 30px;
        max-width: 100px;
      }

      @include tablet {
        height: 50%;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 230px;
      }

      @include desktop {
        height: 100%;
        right: 60px;
      }

      @include desktop-medium {
        right: 140px;
      }
    }

    .ico {
      @include desktop-only {
        display: inline-block;
        line-height: normal;
        margin-bottom: 15px;
        vertical-align: middle;
      }
    }
  }

  &.banner-title-on-top {
    @include mobile-only {
      align-items: flex-start;

      .text-holder {
        padding: 80px 0;
      }
    }
  }

  &.banner-left-aligned {
    .inner-indented {
      float: left;
    }
  }

  &.banner-left-aligned-mobile {
    .inner-indented {
      @include mobile-only {
        float: left;
        text-align: left;
      }
    }

    .additional-logo {
      @include mobile-only {
        margin: 0 0 30px 20px;
      }
    }
  }

  &.banner-right-aligned {
    .inner-indented {
      float: right;
    }
  }

  &.banner-right-aligned-mobile {
    .inner-indented {
      @include mobile-only {
        float: right;
        text-align: right;
      }
    }

    .additional-logo {
      @include mobile-only {
        margin: 0 20px 30px auto;
      }
    }

    .accordion {
      @include mobile-only {
        text-align: right;
      }
    }
  }

  &.banner-text-left-aligned {
    .inner-indented {
      float: left;
      text-align: left;
      padding-left: 10px;
    }

    .btn {
      margin-left: 0;
    }
  }

  &.banner-text-right-aligned {
    .inner-indented {
      text-align: right;
      float: right;
      padding-right: 10px;
    }

    .btn {
      margin-right: 0;
    }
  }

  &.banner-top-aligned {
    align-items: flex-start;

    .text-holder {
      padding: 30px 0;
    }
  }

  &.banner-top-aligned-mobile {
    @include mobile-only {
      align-items: flex-start;
    }
  }

  &.banner-bottom-aligned {
    align-items: flex-end;

    .text-holder {
      padding: 30px 0 40px;
    }
  }

  &.banner-bottom-aligned-mobile {
    @include mobile-only {
      align-items: flex-end;
    }

    .text-holder {
      @include mobile-only {
        padding: 30px 0;
      }
    }
  }

  &.banner-center-aligned-desktop.banner-pagedesigner,
  &.banner-left-aligned-desktop.banner-pagedesigner,
  &.banner-right-aligned-desktop.banner-pagedesigner {
    .badge {
      height: 73px;
      right: 24px;
      position: absolute;
      top: 24px;
      width: 73px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    @include tablet {
      .content-banner-btn-wrapper {
        position: relative;
        z-index: 2;
      }
    }
  }

  &.banner-center-aligned-desktop.banner-pagedesigner {
    .inner-indented {
      @include tablet {
        float: none;
        padding: 0 20px;
        text-align: center;
      }
    }

    .content-banner-btn-wrapper {
      @include tablet {
        justify-content: center;
      }
    }
  }

  &.banner-left-aligned-desktop.banner-pagedesigner {
    @include tablet {
      .inner-indented {
        float: left;
        text-align: left;
      }
    }
  }

  &.banner-right-aligned-desktop.banner-pagedesigner {
    @include tablet {
      .inner-indented {
        float: right;
        padding: 0;
        text-align: right;
      }
    }

    .content-banner-btn-wrapper {
      @include tablet {
        flex-direction: row-reverse;
      }
    }
  }

  &.banner-middle-aligned-mobile {
    @include mobile-only {
      align-items: center;
    }
  }

  &.banner-pagedesigner {
    .inner-indented {
      @include tablet {
        float: left;
        text-align: left;
        padding-left: 10px;
      }

      @include desktop {
        padding-left: 40px;
      }
    }

    .btn {
      @include tablet {
        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .btn-content-video {
      color: $rit-black;
    }

    .accordion {
      margin-top: 20px;
    }

    &.banner-small {
      .mobile-ico {
        display: none;

        @include mobile-only {
          display: block;
          position: absolute;
          right: 24px;
          top: 24px;
          z-index: 2;
        }
      }

      .text-holder {
        @include mobile-only {
          padding: 0;
          margin: 30px 0 52px;
        }

        .heading-h3 {
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 16px;
          margin-bottom: 24px;

          @include mobile-only {
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 16px;
            margin-bottom: 16px;
          }

          @include tablet-only {
            margin-bottom: 16px;
          }
        }

        .heading-h1 {
          font-size: 50px;
          letter-spacing: 1.4px;
          line-height: 56px;
          margin-bottom: 24px;

          @include mobile-only {
            font-size: 32px;
            letter-spacing: 0.6px;
            line-height: 40px;
            margin-bottom: 16px;
          }

          @include tablet-only {
            letter-spacing: 0.6px;
            line-height: 40px;
            margin-bottom: 16px;
          }
        }

        p {
          font-size: 16px;
          letter-spacing: 0.6px;
          line-height: 24px;
          margin-bottom: 0;

          @include mobile-only {
            font-size: 14px;
            letter-spacing: 0.4px;
          }
        }

        .btn {
          min-width: 220px;
          padding: 16px 20px;

          @include mobile-only {
            font-size: 14px;
            letter-spacing: 0.4px;
            min-width: 210px;

            &:first-of-type {
              margin-top: 24px;
            }
          }
        }

        .ico {
          margin-bottom: 24px;
        }

        .accordion {
          margin-top: 24px;

          @include mobile-only {
            margin: 24px auto 0;
            max-width: 270px;
          }

          .acc-content-item {
            line-height: 14px;

            @include mobile-only {
              margin-bottom: 0;
            }
          }
        }
      }

      &.banner-center-aligned-mobile {
        .accordion {
          @include mobile-only {
            text-align: left;
          }
        }

        .inner-wrapper {
          @include mobile-only {
            padding: 0 24px;
          }
        }
      }
    }

    .text-holder {
      z-index: 3;

      .acc-content-body {
        p {
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 13px;

          @include mobile-only {
            font-size: 11px;
            letter-spacing: 0.2px;
          }

          a {
            color: inherit;
            font-size: inherit;
          }
        }

        &.txt--white {
          p {
            color: $white;
          }
        }
      }
    }
  }

  @include tablet {
    max-height: 760px;
    height: 75vh;
    align-items: center;

    .headline {
      letter-spacing: 9px;
    }

    .btn {
      margin: 35px 8px 0;

      &:first-of-type {
        margin-top: 35px;
      }
    }

    &.banner-small {
      min-height: 480px;
      height: auto;
      max-height: none;

      @include ie-styles {
        height: 480px;
      }
    }

    &.samples-banner {
      p {
        margin: 20px 0 0;
      }
    }

    &.banner-left-aligned {
      .inner-indented {
        padding-left: 10px;
      }
    }

    &.banner-right-aligned {
      .inner-indented {
        padding-right: 10px;
      }
    }

    &.banner-top-aligned,
    &.banner-bottom-aligned {
      .text-holder {
        padding: 100px 0;
      }
    }
  }

  @include desktop {
    .btn:last-of-type {
      margin-top: 35px;
    }

    &.banner-left-aligned,
    &.banner-text-left-aligned {
      .inner-indented {
        padding-left: 40px;
      }
    }

    &.banner-right-aligned,
    &.banner-text-right-aligned {
      .inner-indented {
        padding-right: 40px;
      }
    }
  }
}

.content-banner-btn-wrapper {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}

.home-page,
.horizontal-blocks {
  @include tablet {
    .content-banner:last-child {
      margin-top: 60px;
    }

    .content-banner:only-child {
      margin-top: 0;
    }
  }
}

// COP banner
.cop-banner-container .content-banner {
  .inner-indented {
    float: left;
    text-align: left;
    padding-left: 10px;
  }

  .image-holder,
  .responsive-bg {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, $black 15%, transparent 50%);

      @include nav-mobile-tablet {
        display: none;
      }
    }
  }

  .heading-intro {
    line-height: 30px;
    font-size: 20px;
  }

  @include nav-mobile-tablet {
    &.banner-small {
      min-height: 100px;
    }

    .image-holder {
      background: $black !important;
    }

    .heading-h1,
    .heading-intro {
      color: $white !important;
    }

    .long-desc {
      display: none;
    }

    .link {
      color: $rit-copper-medium;

      span {
        text-decoration: underline;
      }

      .less {
        display: none;
      }

      &.open {
        .more {
          display: none;
        }

        .less {
          display: inline-block;
        }
      }
    }
  }

  @include mobile-only {
    &.banner-small {
      min-height: 10px;
      padding: 27px 0 22px;

      &.banner-text-left-aligned,
      &.banner-text-right-aligned,
      &.banner-left-aligned,
      &.banner-right-aligned {
        .inner-indented {
          float: none;
          padding: 0;
        }
      }

      .link {
        font-size: 12px;
        letter-spacing: 1.6px;
        line-height: 20px;
      }
    }

    .inner-indented {
      text-align: center;
    }

    .heading-h1 {
      letter-spacing: 0.8px;
      line-height: 29px;
      margin-bottom: 9px;
    }

    .long-desc {
      display: none;
    }

    .heading-intro {
      display: block;
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 17px;
      margin-bottom: 0;
      padding: 0;
      max-width: 280px;
      margin: 0 auto;
    }
  }

  @include nav-tablet {
    .heading-h1,
    .heading-intro {
      text-align: left;
    }

    .inner-indented {
      max-width: 600px;
      padding: 0 10px;
    }

    .text-holder {
      padding: 90px 0 140px;
    }
  }

  @include desktop {
    &.banner-small {
      background-color: $black;

      .text-holder {
        padding: 90px 0;
      }
    }

    .short-desc,
    .link {
      display: none;
    }

    .heading-h7 {
      font-size: 30px;
      letter-spacing: 0.9px;
      line-height: 45px;
    }

    .heading-h1,
    .heading-intro {
      color: $white;
    }
  }
}

// advent hero banner
.content-banner-advent.content-banner {
  @include mobile-only {
    height: auto;
    min-height: calc(100vh - 150px);
  }

  @include tablet {
    height: 90vh;
    max-height: none;
  }

  &.banner-bottom-aligned {
    .text-holder {
      padding: 0 0 100px 0;

      @include tablet-only {
        padding-bottom: 120px;
      }
    }
  }

  .video-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 100%;
    left: 0;
    mask-image: linear-gradient($black, transparent 97%);
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .s7container {
    @include mobile-only {
      height: 700px !important;
    }
  }

  .text-holder {
    z-index: 10;

    @include mobile-only {
      padding: 84px 0 76px;
    }

    .btn {
      font-size: 13px;
      line-height: 18px;

      @include tablet-only {
        margin-top: 20px;
      }
    }
  }

  .dm-responsive-video {
    height: 100%;
  }

  .advent-hero-icon {
    bottom: 0;
    color: $white;
    font-size: 50px;
    left: 50%;
    line-height: 20px;
    position: absolute;
    z-index: 2;
    opacity: 0;
    transform: translateY(-50%, 30px);
  }

  .scroll-icon {
    bottom: 10px;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    z-index: 2;

    @include mobile-only {
      height: 45px;
      width: auto;
      bottom: 20px;
    }

    @include tablet-only {
      bottom: 20px;
    }

    .mousePart {
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: scrollIndicator2;
      transform: translateY(25px);
    }

    @keyframes scrollIndicator2 {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translateY(-35%);
      }
    }
  }

  .inner-indented {
    max-width: 750px;

    @include mobile-only {
      max-width: 350px;
    }
  }

  p.heading-h7 {
    @include mobile-only {
      margin-bottom: 8px;
    }
  }

  p.heading-h3 {
    font-size: 18px;
    letter-spacing: 0.3em;
    line-height: 26px;
    opacity: 0;
    transform: translateY(30px);

    @include mobile-only {
      font-size: 14px;
      letter-spacing: 0.3em;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }

  h1.headline {
    font-size: 72px;
    line-height: 64px;
    letter-spacing: 1.2px;
    text-transform: initial;
    opacity: 0;
    transform: translateY(30px);

    @include mobile-only {
      font-size: 44px;
      line-height: 44px;
      text-transform: initial;
    }

    @include tablet-only {
      font-size: 44px;
    }
  }

  .btn {
    opacity: 0;
    transform: translateY(30px);
    transition: none;

    @include mobile-only {
      font-size: 14px;
      letter-spacing: 2px;
      min-height: 48px;
      margin-top: 24px;
      min-width: 240px;
      max-width: 100%;
      line-height: 18px;
      padding: 14px 20px;
      width: 100%;

      &:first-of-type {
        margin-top: 27px;
      }
    }
  }

  .advent-banner-overlay {
    background: linear-gradient(transparent 0, $advent-bg 100%);
    bottom: 0;
    height: 200px;
    left: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
  }

  .btn-content-video {
    color: $white;
    font-size: 12px;
    letter-spacing: 1.6px;
    text-transform: uppercase;

    @include mobile-only {
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;

      &::before {
        font-size: 18px;
        left: 2px;
      }
    }
  }
}

.content-banner-easter.content-banner {
  .text-holder {
    padding: 60px 0 80px;
    @include mobile-only {
      padding: 40px 0 45px;
    }
  }

  .inner-indented {
    .btn {
      color: $black;
      background-color: $white;
      margin: 0;

      span {
        font-size: 16px;
      }

      @include desktop-only {
        &:hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  .egg-image-block {
    margin-bottom: 35px;

    @include mobile-only {
      margin-bottom: 20px;
    }

    img {
      max-width: 300px;
    }
  }

  .heading-h1 {
    @include mobile-only {
      font-size: 30px;
      line-height: 38px;
      letter-spacing: 1px;
    }
  }

  .heading-intro {
    font-family: $font-body;
    font-size: 16px;
    margin-bottom: 35px;

    @include mobile-only {
      max-width: 250px;
      margin: 0 auto 20px;
    }
  }

  .egg-model-viewer-size-s {
    position: relative;
    height: 50px;
    width: 220px;
    margin: 0 auto;

    @include mobile-only {
      .btn:last-of-type {
        margin-top: 0;
      }
    }

    @include tablet {
      .btn:last-of-type {
        margin-top: 0;
      }
    }

    @include desktop {
      .btn:last-of-type {
        margin-top: 0;
      }
    }

    .btn-easter-size-s {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      z-index: 3;
    }
  }
}
