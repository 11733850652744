// product ingredients
.product-ingredients-container {
  display: flex;
  flex-direction: column;

  &.product-ingredients-configurable {
    @include mobile-only {
      position: relative;
    }

    .column-image {
      position: relative;
    }

    .product-ingredients-background {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .product-ingredients-content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.product-ingredients-talisman {
    .product-ingredients-column {
      &.column-image {
        display: block;
      }

      .ingredient-item {
        display: block;
        border: 0;
        position: relative;

        &::before {
          display: block;
          content: '';
          height: 1px;
          width: 152px;
          background-color: rgba($white, .15);
          position: absolute;
          top: 0;
          left: 0;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        @include tablet {
          padding-top: 32px;
        }

        h3 {
          font-size: 14px;
          line-height: 23px;
          letter-spacing: 4.6px;
          margin-bottom: 0;
        }

        p {
          font-size: 18px;
          line-height: 41px;
          letter-spacing: 0.6px;
          font-style: italic;

          @include tablet {
            font-size: 22px;
            line-height: 40px;
            letter-spacing: 0.7px;
          }
        }
      }
    }
  }

  body:not(.personalised-gifting-page) & {
    @include tablet {
      flex-direction: row;
      min-height: 760px;
    }
  }

  body.personalised-gifting-page & {
    @include tablet-only {
      min-height: 0;
    }
  }
}

.product-ingredients-column {
  width: 100%;

  &.column-image {
    background-color: $white;
    text-align: center;

    img {
      max-width: 100%;
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  &.column-details {
    padding: 42px 30px 40px;
    transition: height .3s ease;
  }

  .product-ingredients-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    opacity: 1;
    overflow: auto;
    text-align: center;
    width: 100%;
  }

  .ingredient-item {
    border-top: 1px solid rgba($white, .15);
    display: none;
    padding-top: 32px;
    text-align: left;

    &:first-child {
      border-top: 0;
      display: block;
      margin: 0 0 13px;
      padding-top: 0;
    }

    h3 {
      color: $white;
      font-size: 15px;
      letter-spacing: 5px;
      line-height: 23px;
      margin-bottom: 17px;
    }

    p {
      color: $white;
      font-family: $font-header;
      font-size: 18px;
      letter-spacing: .6px;
      line-height: 30px;
      margin-bottom: 14px;
    }
  }

  .ingredient-item-exposed {
    display: block;
  }

  .btn-show-more {
    display: none;
    margin: 14px 0;
    text-decoration: none;
  }

  button.btn {
    span {
      text-align: center;
      width: 100%;
    }

    .less {
      display: none;
    }
  }

  &.open {
    height: auto;

    .btn-show-more {
      display: inline-block;
    }

    button.btn {
      &:hover {
        background-color: transparent;
        color: $white;
        text-decoration: none;
      }

      .more {
        display: none;
      }

      .less {
        display: inline-block;
      }
    }
  }

  @include tablet {
    width: 50%;

    &.column-image {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 760px;
    }

    &.column-details {
      padding: 80px 50px;
      transition: padding .3s ease;
    }

    .product-ingredients-content {
      display: block;
      max-width: 480px;
      text-align: left;
      opacity: 0;
    }

    .ingredient-item {
      display: block;
      width: 100%;
      padding-top: 51px;

      h3 {
        letter-spacing: 2px;
        line-height: 14px;
        margin-bottom: 22px;
      }

      p {
        letter-spacing: .4px;
        line-height: 43px;
        margin-bottom: 26px;
      }
    }

    .btn-show-more {
      display: inline-block;
      margin: 22px 0 0;
    }

    button.btn {
      display: none;
    }
  }

  @include desktop {
    &.column-details {
      padding: 130px 90px 130px 150px;
    }
  }
}

//single ingredient
.single-ingredient {
  .product-ingredients-column {
    .product-ingredients-content {
      h3 {
        font-size: 15px;
        letter-spacing: 5px;
        line-height: 23px;
      }

      p {
        font-size: 18px;
        letter-spacing: 0.6px;
        line-height: 40px;
      }
    }
  }

  @include mobile-only {
    .product-ingredients-column {
      &.column-details {
        padding: 40px 30px 16px;
      }

      .product-ingredients-content {
        h3 {
          margin-bottom: 10px;
        }
      }
    }
  }

  @include tablet {
    .product-ingredients-column {
      &.column-details {
        align-items: center;
        display: flex;
      }

      .product-ingredients-content {
        margin-top: -45px;
        opacity: 1;
      }
    }
  }
}
