.product-list-slider-block {
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 0 4px;
  position: relative;
  width: 100%;

  .drag-list-scroll {
    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 60px;
      height: 100%;
      left: 0;
      opacity: 0;
      top: 0;
      transition: opacity $tertiary-duration $default-ease-out;
      z-index: 22;
    }

    &::after {
      left: unset;
      right: 0;
    }

    &.left-gradient {
      &::before {
        opacity: 1;
      }
    }

    &.right-gradient {
      &::after {
        opacity: 1;
      }
    }
  }

  &:not(.hommes-bg) {
    background-color: $rit-sandy-bg;

    .product-list-slider {
      .product-image {
        background-color: $snow-white;
      }
    }

    .product-list-slider-item {
      &.text-item {
        h3,
        p {
          color: $rit-black;
        }
      }
    }

    .drag-list-scroll {
      &::before {
        background: linear-gradient(
          270deg,
          rgba($white, 0) 0%,
          $rit-sandy-bg 80%
        );
      }

      &::after {
        background: linear-gradient(
          90deg,
          rgba($white, 0) 0%,
          $rit-sandy-bg 80%
        );
      }
    }
  }

  &.hommes-bg {
    background-color: $ritual-hommes-bg;

    .product-list-slider {
      .product-image {
        background-color: $rit-sandy-bg;
      }
    }

    .product-list-slider-item {
      &.text-item {
        h3,
        p {
          color: $white;
        }
      }
    }

    .drag-list-controls {
      button.slider-button {
        background-color: $ritual-hommes-bg;
        color: $white;
      }
    }

    .drag-list-scroll {
      &::before {
        background: linear-gradient(
          270deg,
          rgba($white, 0) 0%,
          $ritual-hommes-bg 80%
        );
      }

      &::after {
        background: linear-gradient(
          90deg,
          rgba($white, 0) 0%,
          $ritual-hommes-bg 80%
        );
      }
    }
  }

  &.personalised-promotions {
    .shopping-cart-block & {
      padding: 0 0 24px;

      @include mobile-only {
        padding: 0 0 4px;
      }
    }

    .product-list-slider-item-relative {
      position: relative;
      width: 100%;
    }

    .product-picker-wrapper {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      input[type=radio] + label {
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        &::before {
          left: auto;

          @include mobile-only {
            right: 16px;
            top: 16px;
          }

          @include tablet {
            right: 24px;
            top: 24px;
          }
        }
      }
    }

    .product-list-slider {
      .product-price {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        .product-sales-price {
          font-size: 20px;

          @include mobile-only {
            font-size: 16px;
          }
        }

        .product-beforediscount-price {
          font-size: 14px;
          margin-left: 0;
        }
      }
    }

    .drag-list .drag-list-body {
      margin-bottom: 88px;

      .shopping-cart-block & {
        margin-bottom: 78px;
      }
    }
  }

  .slider-bar {
    max-width: calc(100% - 20px);
  }

  .btn-mobile {
    display: block;
    margin: 0 auto;
  }

  .btn-customize {
    .icon-pencil-configurable {
      @include pencil-white-icon(15px, 15px);
      vertical-align: middle;
      transition: 0.2s ease-in-out;
      margin: -2px 0 0 3px;
    }
  }

  @include mobile-only {
    .slider-dots {
      bottom: 89px;
      left: 20px;
      max-width: none;
      transform: none;
      width: calc(100% - 40px);
    }

    .slider-bar {
      max-width: 100%;
    }
  }

  @include tablet {
    padding: 58px 0 24px;

    .btn-mobile {
      display: none;
    }

    .slider-bar {
      margin: 7px auto 36px;
      max-width: 100%;

      .indicator {
        cursor: pointer;
        z-index: 2;
      }

      .indicator.is-dragging {
        transition: none;
      }

      .indicator.cloned {
        opacity: 0;
        transition: none;
        z-index: 1;
      }
    }

    .slider-dots {
      bottom: 60px;
      max-width: calc(100% - 120px);
    }
  }

  @include desktop-only {
    .btn-customize {
      &:hover {
        .icon-pencil-configurable {
          @include pencil-black-icon(15px, 15px);
        }
      }
    }
  }

  &.pd-product-slider {
    .product-list-slider-item {
      .product-image,
      .product-details-content {
        border: none;
      }
    }
  }
}

.personalised-promotions-wrapper {
  .promotional-label {
    background-color: $copper;
    bottom: auto;
    color: $white;
    left: 0;

    &:nth-of-type(2) {
      color: $rit-black;
    }
  }
}

.product-list-slider-intro {
  margin-bottom: 50px;
  text-align: center;
  width: 100%;

  h2 {
    color: $rit-copper;
    font-size: 11px;
    letter-spacing: 1.5px;
    line-height: 18px;
    margin-bottom: 0;
  }

  h3 {
    color: $rit-black;
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 32px;
    margin-bottom: 0;
    text-transform: none;

    .hommes-bg & {
      color: $white;
    }
  }

  &:not(.personalised-promotions) {
    @include tablet {
      display: none;
    }
  }
}

.product-list-slider-footer {
  background-color: $rit-sandy-bg;
  text-align: center;

  @include mobile-only {
    padding: 0 16px 40px 16px;
  }

  @include tablet {
    padding-bottom: 64px;
  }

  a,
  p {
    @include mobile-only {
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 16px;
    }

    @include tablet {
      font-size: 14px;
    }
  }

  p {
    font-style: normal;
    line-height: 24px;
    margin: 0 auto;
    text-align: center;

    @include desktop {
      max-width: 1140px;
      width: 80%;
    }
  }
}

.product-list-slider {
  .slick-list {
    overflow: visible;
  }

  .product-name-link {
    text-decoration: none;
  }

  .product-name {
    color: $rit-black;
    margin-bottom: 4px;

    @include mobile-only {
      font-size: 16px;
      letter-spacing: 0.8px;
      line-height: 20px;
    }

    @include tablet {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .product-price {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    text-align: left;

    @include mobile-only {
      font-size: 16px;
      letter-spacing: 0.8px;
      line-height: 20px;
      position: relative;
    }

    @include tablet {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
    }

    .product-beforediscount-price {
      letter-spacing: 0.3px;
      margin: 0 0 5px;

      @include tablet {
        display: inline-block;
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 20px;
        vertical-align: middle;
      }
    }

    .base-price-block {
      @include mobile-only {
        border-top: 1px solid $gray-gainsboro;
        margin-top: 10px;
        padding-top: 10px;
      }

      @include tablet {
        margin-right: 60px;
        margin-top: 20px;
      }

      div {
        margin: 0;

        &:first-child {
          margin-right: 2px;
        }
      }
    }
  }

  .product-tile {
    flex-direction: column;
    flex-grow: 1;
    padding: 0 10px;
    position: relative;
    width: 100%;

    .product-details-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 16px;
      justify-content: space-between;
    }

    @include mobile-and-tablet {
      padding: 0 5px;
    }

    &.out-of-stock {
      .out-of-stock-block,
      .product-price {
        display: none;
      }
    }

    .product-image {
      img {
        width: initial;
      }
    }

    .promotional-label {
      left: 0;
      z-index: 1;
      background-color: $white;
      position: absolute;
      width: auto;

      &:not(.callout-advent) {
        bottom: 40px;
      }

      &.callout-advent {
        @include mobile-only {
          top: 24px;
        }

        @include tablet {
          top: 15px;
        }
      }
    }

    .short-description {
      @include tablet {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 20px;
        min-height: 40px;
      }
    }
  }

  .product-image {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    position: relative;

    .thumb-link {
      width: 100%;
    }

    img {
      display: inline-block;
      max-width: 100%;

      &:not([src]) {
        display: block;
        position: relative;
        width: 100%;

        @include mobile-only {
          height: 200px;
        }

        @include tablet {
          height: 100%;
        }

        &::before {
          background-color: $slider-bar-gray;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }
  }

  .promotion-discount-text {
    display: none;
  }

  .product-details-content {
    align-items: flex-start;
    background-color: $white;
    position: relative;

    @include mobile-only {
      margin-top: -1px;
      padding: 20px 16px;
      transition: opacity 0.4s ease;
    }

    @include tablet {
      padding: 20px 24px;

      .product-name {
        margin-bottom: 10px;
      }
    }

    .out-of-stock-label {
      bottom: 20px;
      color: $gray-medium;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 24px;
      position: absolute;
    }
  }

  .product-user-actions {
    bottom: 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .rituals-form {
      align-items: center;
      display: flex;
    }
  }

  @include mobile-only {
    .slick-slide {
      background-color: $rit-sandy-bg;
      padding: 0 10px;
    }

    .product-image {
      height: 168px;
      padding: 20px 24px;
      text-align: center;

      .thumb-link {
        height: 90%;

        img {
          max-height: 100%;
          position: relative;
        }
      }
    }

    .slick-active .product-details-content {
      opacity: 1;
    }

    .product-list-slider-item {
      justify-content: center;
      width: 210px;

      .product-tile {
        align-self: flex-end;
      }
    }

    .text-item {
      display: none;
      width: 0;
    }
  }

  @include tablet {
    .slick-track {
      display: flex;
    }

    .product-image {
      display: block;
      height: 350px;
      padding: 20px 24px;
      text-align: center;

      img {
        max-height: 100%;
      }

      .thumb-link {
        align-items: flex-end;
        display: flex;
        height: 100%;
      }
    }

    .product-list-slider-item {
      width: 424px;

      &.text-item {
        flex-direction: unset;
      }
    }
  }
}

.product-list-slider.drag-list {
  @include tablet {
    max-width: 1360px;
    padding: 0;
    margin: 0 auto;
  }
}

.toggle-slider-promo {
  display: none;
}

.product-list-slider-item {
  align-items: center;
  display: flex;
  flex-direction: column;

  &.selected {
    input[type=radio] + label::before {
      background-color: $copper;
      border-color: $copper;
      content: $icon-check;
    }
  }

  &:not(.selected) {
    input[type=radio] + label::before {
      background-color: $white;
      border-color: $gray-gainsboro;
      content: "''";
    }
  }

  .the-ritual-of-title,
  .view-full-details-link {
    display: none;
  }

  .product-title,
  .short-description {
    text-align: left;
  }

  .promotion-add-to-cart {
    bottom: 0;
    display: none;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    &.btn--disabled {
      .btn {
        background-color: $gray-gainsboro;
        border: 1px solid $gray-gainsboro;
        color: $rit-dark;
      }
    }

    .btn {
      min-width: 0;
    }

    .check-promo-btn-text {
      &.hidden {
        visibility: hidden;
      }
    }

    .spinner {
      &.visible {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .product-image {
    border-left: 1px solid $gray-gainsboro;
    border-right: 1px solid $gray-gainsboro;
    border-top: 1px solid $gray-gainsboro;
  }

  .product-details-content {
    border-bottom: 1px solid $gray-gainsboro;
    border-left: 1px solid $gray-gainsboro;
    border-right: 1px solid $gray-gainsboro;
  }

  &.selected {
    .product-image {
      border-left: 1px solid $copper;
      border-right: 1px solid $copper;
      border-top: 1px solid $copper;
    }

    .product-details-content {
      border-bottom: 1px solid $copper;
      border-left: 1px solid $copper;
      border-right: 1px solid $copper;
    }
  }

  &.selected,
  &.is-guest {
    .promotion-add-to-cart {
      display: block;
    }
  }

  &:not(.promotion-slider) {
    .promotion-wrapper,
    .the-ritual-of-title,
    .view-full-details-link {
      display: none;
    }

    &.text-item {
      h2 {
        color: $rit-copper;
        font-size: 12px;
        letter-spacing: 1.8px;
        line-height: 16px;
        margin-bottom: 16px;
      }

      h3 {
        font-size: 40px;
        letter-spacing: 1.5px;
        line-height: 50px;
        margin-bottom: 25px;
        text-transform: none;
      }

      p {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 38px;
      }

      .btn {
        @include tablet-only {
          min-width: 0;
          width: 100%;
        }
      }

      .text-holder {
        padding-right: 24%;
      }
    }

    .add-to-cart-flexed {
      .product-add-to-cart {
        display: none;
      }
    }

    .add-to-cart-inner {
      bottom: 0;
      display: flex;
      gap: 8px;
      right: 0;

      @include tablet {
        bottom: 0;
      }

      .btn {
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $gray-disabled;
        color: $black;
        font-size: 0;
        height: 48px;
        letter-spacing: 0;
        line-height: 0;
        max-width: none;
        min-width: auto;
        padding: 0;
        text-transform: none;
        transition: border 0.2s ease-in-out;
        width: 48px;

        &.btn-add-to-cart {
          @include icon(cart-plus);
        }

        .icon-send {
          left: 50%;
          right: unset;
          transform: translate(-50%, -50%);
        }

        &::before {
          display: block;
          font-size: 20px;
          line-height: 20px;
          margin-top: 12px;
        }

        &:hover {
          border-color: $rit-black;
        }
      }
    }

    .product-add-to-cart {
      display: block;
      margin: 0;
    }
  }

  .promotion-wrapper {
    margin: 0 auto 12px auto;

    .callout-message {
      @include callout;
    }
  }

  .product-tile-promotion {
    left: 10px;
    position: absolute;
    top: 20px;
    z-index: 1;

    @include mobile-and-tablet {
      left: 5px;
    }

    .callout-message {
      @include callout;
    }
  }
}

