.fullwidth-slider-block {
  background-color: $white;
  overflow: hidden;
  padding: 60px 25px;
  position: relative;

  @include mobile-only {
    padding: 0;
  }

  @include tablet {
    display: flex;
    min-height: 660px;
    max-height: 760px;
    padding: 0;
    position: relative;
  }

  &.video-shown {
    @include tablet {
      z-index: $modal-window-index;
    }
  }

  &.reversed .text-holder {
    @include tablet {
      margin-left: auto;
    }
  }

  .fullwidth-slider-holder {
    @include tablet {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .slick-list {
    @include mobile-only {
      overflow: visible;
    }
  }

  .slick-slide {
    @include mobile-only {
      display: flex;
      height: auto;

      > div {
        width: 100%;
      }
    }
  }

  .slick-arrow {
    opacity: 0;
    position: absolute;
    visibility: visible;

    @include tablet {
      opacity: 1;
      top: 50%;
      transform: translateY(-50%);
      visibility: visible;
      z-index: 5;
    }

    &.slick-prev {
      @include tablet {
        left: 20px;
      }

      @include desktop {
        left: 60px;
      }
    }

    &.slick-next {
      @include tablet {
        right: 20px;
      }

      @include desktop {
        right: 50px;
      }
    }
  }

  .slide-item {
    text-align: center;

    @include tablet {
      background-color: $white;
    }
  }

  .slide-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @include mobile-only {
      display: block;
      height: auto;
      padding-top: 288px;
      position: relative;
    }

    @include tablet {
      align-items: center;
      min-height: 660px;
      max-height: 760px;
      justify-content: space-around;
      position: relative;
      text-align: center;
    }
  }

  .inner-wrapper {
    align-items: center;
    display: flex;
  }

  .text-holder {
    background-color: $white;
    position: relative;
    text-align: center;

    @include mobile-only {
      box-shadow: 0 1px 6px rgba($black, .35);
      margin-left: 10px;
      padding: 40px 20px;
      width: calc(100% - 20px);
      z-index: 3;
    }

    @include tablet {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 60%;
      min-height: 430px;
      padding: 30px;
      width: 480px;
      z-index: 5;
    }

    @include desktop {
      min-height: 460px;
      max-width: none;
      padding: 60px 70px;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      height: 460px;
    }

    h3, h2 {
      color: $rit-black;
      margin-bottom: 22px;
      text-transform: none;

      @include mobile-only {

        font-size: 20px;
        letter-spacing: .5px;
        line-height: 24px;
        padding: 0 20px;
      }

      @include tablet {
        font-size: 24px;
        letter-spacing: 1.3px;
        line-height: 28px;
        max-width: 100%;
      }

      @include desktop {
        font-size: 30px;
        line-height: 40px;
      }
    }

    p {
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @include mobile-only {
        color: $rit-black;
        font-size: 12px;
        margin-bottom: 18px;
      }

      @include tablet {
        font-size: 14px;
        letter-spacing: .5px;
        margin-bottom: 20px;
        max-width: 100%;
      }
    }
  }

  .product-price {
    display: block;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 30px;

    @include tablet {
      font-size: 30px;
      line-height: 20px;
      margin-bottom: 32px;
      padding-top: 8px;
    }

    .price-sales {
      display: block;
    }

    .price-standard {
      color: $gray-light;
      display: block;
      font-size: 16px;
      letter-spacing: 1.6px;
      line-height: 20px;
      margin: 10px 0 0;
      text-decoration: line-through;

      @include tablet {
        font-size: 20px;
        margin-top: 20px;
        padding-bottom: 10px;
      }
    }
  }

  .btn {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .media-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 231px;
    width: 100%;

    @include mobile-only {
      height: 420px;
      left: 0;
      position: absolute;
      top: 0;
    }

    @include tablet {
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .slider-bar {
    margin: 40px auto 0;

    @include mobile-only {
      background-color: #a39f9e;
      border-radius: 4px;
      height: 4px;
      margin: 20px auto;
    }

    @include tablet {
      background-color: rgba($white, .3);
      bottom: 48px;
      left: 50%;
      margin: 0;
      position: absolute;
      transform: translateX(-50%);
    }

    @media screen and (min-width: 768px) and (max-height: 768px) {
      bottom: 35px !important;
    }

    .indicator {
      @include mobile-only {
        background-color: #404040;
        border-radius: 4px;
      }

      @include tablet {
        background-color: $white;
      }
    }
  }

  .slider-button {
    background-color: $white;
    border: 2px solid $white;

    &:hover {
      background-color: rgba($white, 0);

      &::before {
        color: $white;
      }
    }
  }

  .slider-dots {
    bottom: 60px;

    @include mobile-only {
      bottom: 20px;
    }

    @include tablet {
      bottom: 48px;
    }

    @media screen and (min-width: 768px) and (max-height: 768px) {
      bottom: 35px !important;
    }

    .slick-dots {
      @include mobile-only {
        height: 4px;
      }
    }

    .button {
      @include mobile-only {
        height: 4px;
      }
    }
  }
}

.fullwidth-slider-text {
  @include tablet {
    display: flex;
    padding: 70px;
    width: 100%;
  }

  @include desktop {
    padding: 60px 150px;
  }

  .fullwidth-slider-text-box {
    width: 100%;
  }
}
