// giftwrap block
.giftwrap-block {
  background-color: $rit-sandy-bg;
  margin-top: 24px;
  padding: 18px 15px 14px 20px;
  text-align: left;
  position: relative;

  // Checkout V3 Overrides
  .checkout-v3 & {
    background-color: transparent;
    border: 1px solid $gray-disabled;
    font-family: $font-header;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 16px;

    @include tablet {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-bottom: 24px;
    }

    .giftwrap-block-toggle {
      label {
        color: $rit-black;
        font-family: $font-body;

        &::before {
          border-radius: 0;
        }
      }
    }
  }

  @include tablet {
    padding-right: 23px;
  }

  &.checked,
  &.enabled,
  &:hover {
    // Checkout V3 Overrides
    .checkout-v3 & {
      background-color: $rit-sandy-dark;
      border-color: $copper;
    }
  }

  &:not(.enabled):hover {
    input[type=radio],
    input[type=checkbox] + label::before {
      background-color: $rit-sandy-dark;
      border-color: $copper;
    }
  }

  &.enabled {
    .edit-products-link,
    .giftwrap-block-options {
      // Checkout V3 Overrides
      .checkout-v3 & {
        display: block;
      }
    }
  }

  .gift-wrap-price-wrapper {
    position: absolute;
    top: 15px;
    right: 18px;
    z-index: 2;

    // Checkout V3 Overrides
    .checkout-v3 & {
      @include mobile-only {
        top: 23px;
      }

      @include tablet {
        top: 26px;
        right: 24px;
      }
    }

    a {
      padding: 0 5px;
    }
  }

  .gift-wrap-price {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-left: auto;

    &.disabled {
      color: $gray-disabled;
    }
  }

  .edit-products-link {
    display: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-left: 36px;
    margin-top: 5px;
  }
}

.checkout-v3 {
  .shopping-cart-block {
    .giftwrap-block-greeting {
      margin-bottom: 40px;
      margin-top: 16px;
    }
  }
}

// toggle checkbox
.giftwrap-block-toggle {
  position: relative;
  width: 100%;
  z-index: 1;

  input {
    margin: 0;
  }

  label {
    color: $rit-dark;
    cursor: pointer;
    display: block;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    padding-left: 36px;
    position: relative;

    // Checkout V3 Overrides
    .checkout-v3 & {
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 24px;
      padding-top: 2px;
    }

    &::before,
    &::after {
      top: 1px;
    }
  }
}

// dropdown block
.giftwrap-block-options {
  display: none;
  width: 100%;

  &.enabled {
    display: block;
  }

  .gift-set-message {
    margin-top: 20px;

    & + .giftwrap-message-sibling {
      .wrap-single-item {
        margin: 0;

        @include tablet {
          margin: -104px 0 57px;
        }
      }

      .adjust-position {
        margin: 15px 0 -67px;

        @include tablet {
          margin: 45px 0 -67px;
        }
      }
    }

    .error {
      font-size: 11px;
      font-weight: bold;
      line-height: 13px;
      letter-spacing: 1px;
    }
  }
}

.giftwrap-block-radio-wrapper:not(.giftwrap-message-sibling) {
  margin-top: 14px;
  border-top: 1px solid $gray-gainsboro;

  // Checkout V3 Overrides
  .checkout-v3 & {
    margin-top: 24px;
  }
}

.giftwrap-message-sibling {
  margin-top: -34px;

  @include tablet {
    margin-top: -24px;
  }

  &:only-child {
    .wrap-single-item {
      padding-top: 10px;
      margin-bottom: 0;
      margin: -20px 0 0;

      @include tablet {
        padding-top: 0;
      }
    }

    .gift-wrap-price {
      margin-left: auto;
    }
  }

  & + .giftwrap-block-personal-message {
    padding-top: 48px;
  }
}

// radio button element
.giftwrap-block-radio {
  position: relative;
  margin-bottom: 25px;

  &:first-of-type:not(:only-child) {
    padding-top: 25px;
  }

  &:only-child {
    margin: 20px 0 -67px;

    label {
      padding-left: 0;

      .gift-wrap-text {
        display: none;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    input:checked + label {
      &::after {
        display: none;
      }
    }
  }

  &:last-of-type:not(:only-child) {
    margin-bottom: 5px;

    @include tablet {
      margin-bottom: 15px;
    }
  }

  &.wrap-single-item {
    label {
      cursor: default;
    }
  }

  input {
    height: 1px;
    left: 2px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 2px;
    width: 1px;
  }

  label {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    padding-left: 45px;
    position: relative;

    // Checkout V3 Overrides
    .checkout-v3 & {
      font-family: $font-body;
      padding-top: 2px;

      &::after {
        background-color: transparent;
      }
    }

    &::before,
    &::after {
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      position: absolute;
    }

    &::before {
      background-color: $white;
      border: 1px solid $gray-lines;
      display: inline-block;
      height: 24px;
      left: 4px;
      top: -2px;
      width: 24px;
    }

    &::after {
      background-color: $white;
      display: none;
      height: 6px;
      left: 13px;
      top: 7px;
      width: 6px;
    }
  }

  .label-disabled {
    color: $gray-disabled;
    cursor: not-allowed;
  }

  .gift-wrap-price {
    color: $rit-black;
    padding-left: 5px;
  }

  @include tablet {
    &:first-of-type:not(:only-child) {
      padding-top: 34px;

      // Checkout V3 Overrides
      .checkout-v3 & {
        padding-top: 24px;
      }
    }

    &:only-child {
      margin: 45px 0 -67px;
    }
  }
}

// personal message
.giftwrap-block-personal-message {
  border-top: 1px solid $gray-gainsboro;
  margin-top: 14px;

  // Checkout V3 Overrides
  .checkout-v3 & {
    margin-top: 24px;
  }

  .giftwrap-block-label {
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    margin-bottom: 4px;

    .optional-text {
      color: $gray-medium;
      letter-spacing: .5px;
    }

    &.label-width {
      @include mobile-only {
        max-width: 150px;
      }
    }
  }

  span.error {
    display: block;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    letter-spacing: 1px;
    margin: 10px 0 8px;
  }

  .giftwrap-block-textarea {
    height: 215px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;

    .lines-wrap {
      bottom: 32px;
      left: 20px;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      right: 20px;
      top: 50px;
      z-index: 1;

      .line {
        background-color: $gray-lines;
        float: left;
        height: 1px;
        margin: 32px 0 31px;
        position: relative;
        width: 100%;

        &::before,
        &::after {
          background-color: $gray-lines;
          display: inline-block;
          content: '';
          height: 1px;
          left: 0;
          position: absolute;
          width: 100%;
        }

        &::before {
          top: -32px;
        }

        &::after {
          bottom: -32px;
        }
      }
    }

    textarea {
      background-color: $white;
      border: 0;
      font-family: $font-header;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 32px;
      height: 215px;
      padding: 24px 20px 32px;
      position: relative;
      resize: none;
      overflow: auto;
      width: 100%;

      &::placeholder {
        color: $gray-disabled;
        font-family: $font-header-italic;
        font-style: italic;
        font-size: 16px;
      }
    }
  }

  .character-counter {
    color: $grey-medium-light;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: right;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 17px;

    // Checkout V3 Overrides
    .checkout-v3 & {
      @include mobile-only {
        flex-direction: column-reverse;
      }
    }

    .btn {
      width: 48%;
      max-width: 292px;

      @include mobile-only {
        min-width: 0;

        // Checkout V3 Overrides
        .checkout-v3 & {
          max-width: none;
          width: 100%;
        }
      }
    }

    .btn--secondary {
      @include mobile-only {
        border: 0;
        color: $copper;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        text-decoration: underline;
        text-transform: none;
      }
    }
  }

  .gift-wrapping-card-title {
    color: $rit-black;
    font-family: $font-header;
    font-size: 24px;
    letter-spacing: 1.5px;
    line-height: 32px;
    margin-bottom: 24px;
    width: 100%;
    text-transform: none;
    display: none;

    // Checkout V3 Overrides
    .checkout-v3 & {
      font-family: $font-body;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }

  @include tablet {
    padding: 24px 12px 0;

    .giftwrap-block-label {
      margin-bottom: 8px;
    }

    .giftwrap-block-textarea {
      textarea {
        padding: 24px 34px 32px;
      }

      .lines-wrap {
        left: 30px;
        right: 30px;
      }
    }

    .gift-wrapping-card-title {
      display: block;
    }
  }
}

// card selection
.gift-wrapping-card-selection {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;
  padding-top: 17px;
  width: 100%;

  .content-asset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .gift-wrapping-card-option {
    margin-bottom: 10px;
    max-width: none;
    width: 47.5%;

    .gift-wrapping-card-image {
      min-height: 30px;
    }

    .gift-wrapping-card-name {
      color: $black;
    }
  }

  @include tablet {
    margin-bottom: 0;
    padding-top: 9px;

    .content-asset {
      justify-content: flex-start;
    }

    .gift-wrapping-card-option {
      margin: 0 2.6% 25px 0;
      width: 23%;
    }
  }
}

// card item
.gift-wrapping-card-option {
  margin-bottom: 15px;
  max-width: 45%;

  &:last-of-type {
    margin-right: 0;
  }

  &:focus .gift-wrapping-card-image {
    box-shadow: 0 2px 8px rgba($black, .3);
  }

  .gift-wrapping-card-image {
    cursor: pointer;
    position: relative;
    transition: box-shadow $quaternary-duration $default-ease-out;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  .select-box {
    background-color: transparent;
    border: 0;
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding: 4px 0 0 36px;
    border-radius: 50%;
    left: 50%;
    margin-left: -12px;
    position: absolute;
    top: calc(100% - 12px);
    transition: $quaternary-duration $default-ease-out;

    &::before {
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $gray-gainsboro;
      color: $white;
      content: '';
      font-family: 'iconfont';
      font-size: 8px;
      height: 24px;
      left: 0;
      letter-spacing: -1px;
      line-height: 26px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 24px;
    }
  }

  .gift-wrapping-card-description {
    padding: 26px 10px 0;
    text-align: center;
    width: 100%;
  }

  .gift-wrapping-card-name {
    color: $rit-dark;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    margin-bottom: 0;
    text-transform: none;
  }

  &.selected {
    .select-box {
      background-color: transparent;
      border-color: transparent;

      &::before {
        background-color: $copper;
        border-color: $copper;
        content: $icon-check;
      }

      .checkmark {
        opacity: 1;
      }
    }
  }

  @include tablet {
    margin: 0 16px 15px 0;
    max-width: 22%;

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }

  @include desktop {
    max-width: 23%;
  }
}

// flyout content
.generic-dialog-flyout.giftwrap-flyout {
  max-width: 87%;

  @include tablet {
    max-width: 720px;

    // Checkout V3 Overrides
    &.checkout-v3 {
      max-width: 504px;
      width: auto;
    }
  }
}

.giftwrap-container {
  background-color: $white;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 96px 24px 30px;
  text-align: left;
  width: 100%;

  @include tablet {
    text-align: center;
    padding: 74px 40px 30px;

    // Checkout V3 Overrides
    .checkout-v3 & {
      text-align: left;

      .giftwrap-title {
        margin-bottom: 24px;

        h2 {
          font-size: 24px;
          letter-spacing: 1px;
          line-height: 32px;
        }
      }
    }

    .giftwrap-title {
      margin-bottom: 40px;

      .side--close {
        left: -4px;
        top: -41px;
      }

      h2 {
        font-size: 32px;
        letter-spacing: 2px;
        line-height: 40px;
        margin-bottom: 26px;
      }
    }

    .warning-message {
      padding: 16px 16px 16px 55px;
    }

    .warning-message-icon {
      right: auto;
      left: 14px;
      top: 16px;
      width: 24px;
      height: 24px;

      &::before {
        width: 24px;
        height: 24px;
      }

      &::after {
        font-size: 20px;
      }
    }

    .checkall {
      margin-bottom: 38px;
    }

    .giftwrap-products {
      margin: 0 -5px 10px;
      padding-bottom: 80px;

      li {
        margin-bottom: 40px;
        width: 33.333%;
        padding: 0 20px;
        max-width: 227px;

        label {
          padding-top: 15px;

          img {
            max-width: 100px;
          }
        }
      }
    }

    .giftwrap-bottom-controls {
      left: auto;
      margin-left: -40px;
      padding: 20px 40px 40px;

      .btn {
        width: 49%;
        max-width: none;
      }
    }
  }

  .giftwrap-title {
    margin-bottom: 24px;
    position: relative;
    width: 100%;

    .side--close {
      left: 0;
      top: -53px;
    }

    h2 {
      font-size: 20px;
      letter-spacing: 1.3px;
      line-height: 24px;
      text-transform: none;
      margin-bottom: 18px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .warning-message {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $rit-black;
    background-color: $rit-sandy-bg;
    padding: 20px 36px 16px 23px;
    text-align: left;
    position: relative;
    margin-top: 19px;

    .product-list {
      font-weight: bold;
    }
  }

  .warning-message-icon {
    position: absolute;
    left: auto;
    right: 15px;
    top: 19px;
    width: 16px;
    height: 16px;

    &::before {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: block;
      content: '';
      background-color: $grey-medium-light;
    }

    &::after {
      content: 'i';
      display: block;
      color: $white;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      left: 50%;
      top: 54%;
      transform: translate(-50%, -50%);
    }
  }

  .checkall {
    margin-bottom: 28px;
    position: relative;
    width: 100%;

    input {
      &:focus + label::before {
        box-shadow: none;
      }
    }

    label {
      display: inline-block;
      cursor: pointer;

      // Checkout V3 Overrides
      .checkout-v3 & {
        padding-left: 42px;
      }
    }
  }

  .giftwrap-products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0 -8px;
    padding-bottom: 50px;

    li {
      margin-bottom: 16px;
      position: relative;
      width: 50%;
      text-align: center;
      padding: 0 7px;

      input {
        &.focus-visible + label {
          &::before,
          &::after {
            left: 50%;
            top: auto;
          }

          &::before {
            bottom: calc(20px - 3px);
          }
        }
      }

      label {
        background-color: $rit-sandy-bg;
        display: block;
        padding: 20px 0 0;
        position: relative;
        cursor: pointer;

        img {
          max-width: 80px;
        }

        &::before {
          bottom: 20px;
          left: 50%;
          top: auto;
          transform: translateX(-50%);
        }

        .checkmark-bg {
          display: block;
          background-color: $rit-sandy-dark;
          height: 56px;
          width: 100%;
        }
      }
    }
  }

  .giftwrap-bottom-controls {
    bottom: 0;
    left: 0;
    display: flex;
    background-color: $white;
    justify-content: space-between;
    padding: 20px 24px;
    position: absolute;
    width: 100%;

    // Checkout V3 Overrides
    .checkout-v3 & {
      background-color: $white;
    }

    @include mobile-only {
      // Checkout V3 Overrides
      .checkout-v3 & {
        padding-top: 0;
      }
    }

    @include tablet {
      // Checkout V3 Overrides
      .checkout-v3 & {
        margin: 0;
        padding: 0 0 40px 0;
        width: calc(100% - 80px);
      }
    }

    &::before {
      // Checkout V3 Overrides
      .checkout-v3 & {
        background: linear-gradient(to bottom, rgba($white, 0) 0%, $white 100%);
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
      }

      @include mobile-only {
        // Checkout V3 Overrides
        .checkout-v3 & {
          height: 40px;
          top: -40px;
        }
      }

      @include tablet {
        // Checkout V3 Overrides
        .checkout-v3 & {
          height: 40px;
          top: -40px;
        }
      }
    }

    .btn {
      min-width: 10px;
      width: 47.5%;
    }

    .btn:not(.btn--secondary) {
      @include mobile-only {
        // Checkout V3 Overrides
        .checkout-v3 & {
          flex-grow: 1;
        }
      }
    }

    .btn--secondary {
      @include mobile-only {
        // Checkout V3 Overrides
        .checkout-v3 & {
            border: 0;
            color: $copper;
            font-size: 14px;
            letter-spacing: 0.4px;
            line-height: 24px;
            text-decoration: underline;
            text-transform: none;
            width: auto;
        }
      }
    }
  }

  .vertical-tile-list {
    margin-bottom: 90px;
  }

  .checkout-tile {

    @include mobile-only {
      width: 100%;
    }

    @include tablet {
      height: 120px;
      width: 424px;
    }

    & + .checkout-tile {
      margin-top: 16px;
    }

    input:checked + label,
    label:hover {
      background-color: $rit-sandy-dark;
      border-color: $copper;
    }

    label {
      @include mobile-only {
        padding: 16px !important;
      }
    }

    figure {
      position: absolute;
      text-align: center;
      left: 0;

      @include mobile-only {
        height: calc(100% - 32px);
        width: 88px;
      }

      @include tablet {
        height: calc(100% - 48px);
        width: 96px;
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .tile-title {
      align-items: flex-start;
      flex-direction: column;
      margin-left: 72px;
      margin-right: 36px;
    }

    .product-ritual {
      color: $copper;
      font-size: 12px;
      letter-spacing: 1.7px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .product-title {
      @include mobile-only {
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 24px;
      }

      @include tablet {
        font-size: 20px;
        letter-spacing: 0.7px;
        line-height: 32px;
      }
    }
  }
}

.giftwrapping-disabled {
  background-color: $rit-sandy-bg;
  padding: 15px 20px 13px;
  text-align: left;

  @include mobile-only {
    line-height: 25px;
  }
}

.giftwrapping-unavailable {
  margin: -26px 0 -40px -16px;
  padding: 18px 50px 5px 24px;
  position: relative;
  width: calc(100% + 32px);

  @include tablet {
    align-items: center;
    display: flex;
    margin: -18px 0 -30px -22px;
    min-height: 84px;
    padding: 18px 20px 18px 71px;
    width: calc(100% + 40px);
  }

  .info-icon {
    position: absolute;
    top: 16px;
    right: 15px;

    @include tablet {
      left: 27px;
      right: auto;
      top: 33px;
      transform: scale(1.5);
    }
  }

  p {
    letter-spacing: 0.5px;
    line-height: 24px;

    @include tablet {
      font-size: 14px;
      max-width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
