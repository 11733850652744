.filter-page {
  @include mobile-only {
    background-color: $rit-sandy-bg;
  }
}

// navigation 2.0 - new styles for "refinements"
.filter-flyin {
  background-color: $white;
  bottom: 0;
  display: none;
  left: -100%;
  position: fixed;
  top: 0;
  transition: left $tertiary-duration $filters-ease;
  width: 100%;
  z-index: 982;

  .filter-inner {
    height: 100%;
    overflow: auto;
    padding: 104px 0 48px;
    width: 100%;
  }

  .filter-header {
    background-color: $white;
    border-bottom-width: 2px;
    padding: 42px 20px 37px;

    .filter-x-close {
      right: 23px;
      top: 43px;
    }

    h3 {
      font-size: 20px;
      letter-spacing: 3.4px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  &.refinements-visible {
    left: 0;

    .filter-header,
    .filter-user-actions {
      left: 0;
    }

    .filter-overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  @include mobile-only {
    .filter-holder {
      .filter-block-title {
        border-bottom: 2px solid $slider-bar-gray;
        min-height: 77px;

        &::after {
          font-size: 30px;
          right: -5px;
        }
      }
    }

    &.refinements-visible {
      .filter-holder .filter-options.visible {
        right: 0;
      }

      &.moved-right {
        left: 100%;

        .filter-header,
        .filter-user-actions {
          left: 100%;
        }
      }

      &.moved-left {
        left: -100%;

        .filter-header {
          left: -100%;
        }
      }
    }
  }

  @include tablet {
    width: 360px;

    .filter-header {
      padding: 31px 80px 30px 60px;

      .filter-x-close {
        right: 56px;
        top: 39px;
      }

      h3 {
        font-size: 28px;
        letter-spacing: 1px;
        line-height: 38px;
      }
    }

    .filter-inner {
      padding: 100px 0 48px;
    }

    .filters-container {
      padding: 4px 60px;
    }
  }
}

.filter-overlay {
  background-color: rgba(0, 0, 0, .6);
  bottom: 0;
  display: none;
  left: 360px;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ease-in-out;
  visibility: hidden;

  @include tablet {
    display: block;
  }
}

.filter-header {
  background-color: $rit-sandy-bg;
  border-bottom: 1px solid $gray-gainsboro;
  left: -100%;
  padding: 25px 20px 33px;
  position: fixed;
  top: 0;
  transition: left $tertiary-duration $filters-ease;
  width: 100%;
  z-index: 20;

  .filter-x-close {
    font-size: 22px;
    position: absolute;
    right: 16px;
    top: 19px;
  }

  h3 {
    color: $rit-black;
    font-size: 24px;
    letter-spacing: .6px;
    line-height: 1.2;
    margin-bottom: 21px;
    text-transform: none;
  }

  @include tablet {
    border-bottom: 0;
    padding: 30px 60px 33px;
    text-align: left;
    width: 360px;

    .filter-x-close {
      right: 56px;
      top: 39px;
    }

    h3 {
      font-size: 28px;
      letter-spacing: 1px;
      line-height: 40px;
      margin-bottom: 35px;
      padding-right: 20px;
    }
  }
}

.filter-categories {
  position: relative;
  width: 100%;

  .filter-cateogry-title {
    display: none;
    font-size: 12px;
    letter-spacing: 2.5px;
    line-height: 15px;
    margin-bottom: 9px;
  }

  .filter-box-categories {
    position: relative;
    width: 100%;

    li {
      display: block;
      margin-bottom: 3px;
      position: relative;
    }

    a {
      color: $rit-black;
      display: inline-block;
      text-decoration: none;
    }

    span {
      display: inline-block;
      position: relative;

      &::after {
        background-color: $rit-black;
        bottom: 4px;
        content: '';
        display: inline-block;
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        transition: width .3s ease;
        width: 0;
        z-index: 2;
      }
    }

    sup {
      color: $rit-copper-dark;
      font-family: $font-header;
      left: 2px;
      position: relative;
    }

    .selected span {
      &::after {
        width: 100%;
      }
    }
  }

  @include tablet {
    .filter-cateogry-title {
      display: block;
    }

    .filter-box-categories {
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        width: 100%;

        &::after {
          display: none;
        }

        &:first-child,
        &:last-child {
          padding: 0;
        }
      }

      a {
        font-size: 14px;
        letter-spacing: .25px;
        line-height: 26px;

        &:hover span::after {
          width: 100%;
        }
      }

      sup {
        margin-left: 4px;
      }
    }
  }
}

.filter-holder {
  overflow: hidden;
  width: 100%;

  .filters-container {
    padding: 0 20px 20px;
    width: 100%;
  }

  .filter-block {
    width: 100%;

    &.filter-block-refinement {
      @include tablet {
        input {
          padding: 0;
          position: relative;
        }

        .text {
          padding-left: 11px;
        }
      }
    }
  }

  .filter-block-title {
    border-bottom: 1px solid $gray-gainsboro;
    color: $rit-black;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 2.5px;
    line-height: 15px;
    padding: 21px 30px 23px 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    &::after {
      @include icon-styles;
      display: inline-block;
      font-size: 40px;
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
    }

    .filter-selected-item {
      color: $rit-copper-on-black;
      display: block;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: .5px;
      line-height: 14px;
      margin: 6px 0 -3px;
      text-transform: none;
    }
  }

  .filter-option-title {
    background-color: $rit-sandy-bg;
    left: 0;
    padding: 20px 40px 13px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 5;

    .filter-option-back {
      font-size: 40px;
      left: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-45%);
    }

    .text {
      color: $rit-dark;
      font-family: $font-header;
      font-size: 22px;
      letter-spacing: .6px;
      line-height: 21px;
    }

    .filter-option-close {
      font-size: 22px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-45%);
    }
  }

  .filter-options-container {
    max-height: 100%;
    overflow: auto;
    padding: 80px 0 30px;
    width: 100%;
    -webkit-overflow-scrolling: touch;

    .filter-item {
      padding: 0 20px;
    }

    label {
      border-bottom: 1px solid $gray-lines;
      color: $rit-dark;
      display: block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      padding: 16px 0 14px;
      position: relative;
      width: 100%;

      input {
        height: 2px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 22px;
        width: 2px;

        &.focus-visible + .text {
          &::before {
            border-color: $rit-copper-dark;
            height: 28px;
            left: -3px;
            top: 13px;
            width: 28px;
          }

          &::after {
            font-size: 14px;
            left: 3px;
          }
        }

        &:checked + .text {
          &::before {
            background-color: $rit-copper-dark;
            border-color: $rit-copper-dark;
          }

          &::after {
            display: block;
          }
        }

        &:disabled + .text,
        &[disabled=disabled] + .text {
          cursor: not-allowed;
          opacity: .26;
        }
      }

      sup {
        color: $rit-copper-on-black;
        font-family: $font-header;
        margin-left: 5px;
      }

      .text {
        background: transparent;
        cursor: pointer;
        padding-left: 43px;

        &::before {
          background-color: transparent;
          border: 1px solid $gray-lines;
          content: '';
          display: inline-block;
          height: 22px;
          left: 0;
          position: absolute;
          top: 16px;
          width: 22px;
        }

        &::after {
          @include icon-styles;
          color: $white;
          content: icon-char(check);
          display: none;
          font-size: 10px;
          left: 5px;
          position: absolute;
          top: 15px;
        }
      }
    }

    .filter-item-circle label {
      .text {
        color: $gray-dark;

        @include tablet {
          padding-left: 26px;
        }

        &::before {
          border-radius: 50%;

          @include tablet {
            height: 12px;
            width: 12px;
          }
        }

        &:not(.white-product-color)::before {
          border-color: transparent;
        }

        &.white-product-color::before {
          background-color: #ededed;
          border-color: $gray-disabled;
        }

        &.multi-color-product-color::before {
          background: url('../images/filter-multicolor.png') left top no-repeat;
          background-size: 100% 100%;
          border: 0;

          @include mobile-only {
            height: 24px;
            width: 24px;
          }

          @include tablet {
            height: 14px;
            width: 14px;
          }
        }
      }

      sup {
        color: $rit-copper-dark;
      }

      .text-checkbox {
        color: $gray-dark;
        display: none;
        font-size: 8px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          @include icon-styles;
          content: icon-char(check);
        }
      }

      input:checked + .text {
        font-weight: bold;

        @include mobile-only {
          .text-checkbox {
            display: inline-block;
          }
        }

        &:not(.white-product-color)::before {
          border-color: transparent;
        }

        &:not(.multi-color-product-color)::before {
          @include tablet {
            height: 12px;
            width: 12px;
          }
        }

        &::after {
          border: 2px solid $white;
          border-radius: 50%;
          content: '';
          left: 1px;

          @include mobile-only {
            height: 18px;
            top: 17px;
            width: 18px;
          }

          @include tablet {
            height: 8px;
            top: 3px;
            width: 8px;
          }
        }

        sup {
          font-weight: normal;
        }
      }
    }
  }

  .price-filter-wrapper {
    padding: 80px 0 20px;

    .price-holder {
      display: flex;
      padding: 0 20px;
      width: 100%;

      .price-item-input {
        margin-right: 9px;
      }

      .price-item-btn {
        margin-left: 21px;
      }

      label {
        color: $rit-black;
        display: inline-block;
        font-size: 14px;
        letter-spacing: .25px;
        line-height: 20px;
        margin-right: 5px;
      }

      input {
        color: $rit-black;
        line-height: 20px;
        margin: 0;
        outline: none;
        padding: 5px 10px 6px;
        width: 50px;

        &:focus,
        &:active {
          border-color: $rit-copper-dark;
        }

        //keeping this separated, it doesn't work when it's merged
        &::-webkit-input-placeholder {
          color: rgba($rit-black, .28);
        }
        &::-moz-placeholder {
          color: rgba($rit-black, .28);
        }
        &:-ms-input-placeholder {
          color: rgba($rit-black, .28);
        }
        &:-moz-placeholder {
          color: rgba($rit-black, .28);
        }
      }


      .btn {
        min-width: 63px;
        padding: 9px 20px 7px;

        &.btn--disabled {
          background-color: transparent;
          border-color: $gray-lines;
          color: $gray-lines;
        }
      }
    }
  }

  @include mobile-only {
    .filter-block-title {
      &::after {
        content: icon-char(arrow3--right);
      }
    }
    .filter-options {
      background-color: $white;
      bottom: 46px;
      display: none;
      position: fixed;
      right: -100%;
      top: 0;
      transition: right $tertiary-duration $filters-ease;
      width: 100%;
      z-index: 21;

      &.visible.moved-left {
        right: 100%;
      }
    }
  }

  @include tablet {
    .filters-container {
      padding: 27px 60px 27px 57px;
    }

    .filter-block {
      margin-bottom: 18px;
    }

    .filter-block-title {
      border-bottom: 0;
      padding: 13px 30px 13px 0;

      .filter-selected-item {
        display: none;
      }

      &::after {
        content: icon-char(plus2);
        font-size: 27px;
      }

      &.open::after {
        content: icon-char(minus2);
      }
    }

    .filter-options {
      display: none;
      width: 100%;
    }

    .filter-options-container {
      margin-bottom: -11px;
      max-height: none;
      overflow: hidden;
      padding: 1px 0 0 3px;
      width: calc(100% + 20px);

      .filter-item {
        margin-bottom: 8px;
        padding: 0;
      }

      label {
        border-bottom: 0;
        font-size: 14px;
        letter-spacing: .25px;
        line-height: 18px;
        padding: 0;

        input {
          top: 6px;

          &.focus-visible + .text {
            &::before {
              height: 16px;
              left: -3px;
              top: -1px;
              width: 16px;
            }

            &::after {
              font-size: 8px;
              left: 1px;
            }
          }
        }

        .text {
          padding-left: 23px;
          line-height: 16px;

          &::before {
            height: 10px;
            top: 2px;
            width: 10px;
          }

          &::after {
            font-size: 6px;
            left: 2px;
            top: 0;
          }
        }
      }
    }

    .filter-option-title {
      display: none;
    }

    .price-filter-wrapper {
      padding: 0;

      .price-holder {
        padding: 0;

        .price-item-btn {
          margin-left: auto;
        }
      }
    }
  }
}

.filter-user-actions {
  bottom: -2px;
  display: flex;
  left: -100%;
  position: fixed;
  transition: left $tertiary-duration $filters-ease;
  width: 100%;

  .btn {
    min-width: 10px;
    padding: 17px 7px 15px;
    width: 50%;

    &:not(.btn-clear-all):hover {
      background: $gray-dark;
      border-color: $gray-dark;
      color: $white;
    }
  }

  .btn-clear-all {
    background-color: $rit-sandy-bg;
    border-color: $rit-sandy-bg;
    color: #111;

    &:hover {
      background-color: $white;
      border-color: $rit-black;
      color: $rit-dark;
    }
  }

  @include tablet {
    width: 360px;
  }

  @include desktop {
    bottom: 0;
  }
}

.active-refinements-list {
  width: 100%;

  .active-refinements-inner {
    margin-bottom: 21px;
    text-transform: none;
  }

  .filter-tags-title {
    color: $rit-copper-light;
    display: block;
    font-family: $font-header;
    font-size: 13px;
    letter-spacing: .9px;
    line-height: 16px;
    margin-bottom: 21px;
  }

  .filter-tag {
    background-color: rgba(216, 216, 216, .1);
    border-radius: 16px;
    color: $white;
    float: left;
    font-size: 12px;
    letter-spacing: .75px;
    line-height: 14px;
    margin: 0 10px 10px 0;
    padding: 10px 9px 8px 12px;
  }

  .remove-filter-button {
    color: rgba($white, .36);
    display: inline-block;
    margin: -1px 0 0 6px;
    text-decoration: none;
    vertical-align: middle;

    .icon {
      line-height: 1;
    }
  }
}
