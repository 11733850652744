.wimo-wrapper {
  padding: 50px 0 0;

  @include tablet {
    padding: 60px 0 94px;
  }

  .page-layout-right {
    @include desktop {
      max-width: 400px;
    }
  }

  .faq-block {
    background-color: $rit-sandy-dark;
    width: calc(100% + 40px);
    margin-left: -20px;
    max-width: none;
    padding: 63px 5px 80px;

    @include desktop {
      padding: 25px 0 23px;
      width: 100%;
      background-color: $white;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1.5px;
      text-align: left;

      @include tablet {
        margin-bottom: 23px;
      }
    }

    .faq-accordion {
      @include tablet {
        margin: 0 auto 32px;
      }

      .faq-accordion-head {
        padding: 24px 84px 23px 0;
      }

      .faq-accordion-content {
        @include tablet {
          padding: 0 50px 20px 0;
        }
      }

      .acc-head-button {
        .acc-head-circle {
          top: 27px;
          right: 0;
        }
      }
    }
  }
}

.wimo-form-wrapper {
  text-align: center;

  .wimo-form-title {
    font-size: 24px;
    letter-spacing: 1.2px;
    line-height: 30px;
    max-width: 250px;
    margin: -16px auto 9px;
    font-style: italic;
    color: $rit-black;
    text-transform: none;

    @include tablet {
      font-size: 30px;
      letter-spacing: 0.6px;
      line-height: 40px;
      margin: 18px auto 13px;
      max-width: 480px;
    }
  }

  .wimo-form-subtitle {
    color: $rit-black;
    max-width: 285px;
    margin: 0 auto;
    letter-spacing: 0.5px;
    line-height: 18px;

    @include tablet {
      max-width: none;
      letter-spacing: 0.3px;
      line-height: 28px;
    }
  }

  div.ordernumber {
    margin-top: 25px;

    @include tablet {
      margin-top: 30px;
    }
  }
}

.wimo-form {
  max-width: 566px;
  margin: 42px auto 0;

  @include tablet {
    margin-top: 38px;
  }

  .error-message {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
    color: $rit-black;
  }
}

.wimo-login {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 26px;

  @include tablet {
    margin-top: 20px;
  }
}

.special-order-warning,
.error-form {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.special-order-warning {
  margin-top: 20px;

  @include tablet {
    text-align: center;
  }
}

.error-form {
  margin-bottom: 20px;
}

.order-details-heading {
  font-family: $font-header;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: none;
  border-bottom: 1px solid $gray-gainsboro;
  padding-bottom: 15px;
  margin: 0;

  @include tablet {
    font-size: 16px;
  }
}

.wimo-order-wrapper {
  @include tablet {
    padding: 10px 0 70px;
  }

  .inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1360px;

    @include mobile-only {
      flex-direction: column-reverse;
    }
  }

  .inner-wrapper-solo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;

    @include mobile-only {
      flex-direction: column-reverse;
    }
  }

  .order-info-block {
    background-color: $white;
    padding: 41px 42px 40px;
    width: 48%;
    max-width: 630px;

    @include mobile-only {
      width: calc(100% + 40px);
      max-width: none;
      margin-left: -20px;
      padding: 24px 28px 23px;
    }

    .mini-cart-product {
      display: flex;
      padding: 23px 10px;
      border-bottom: 1px solid $gray-gainsboro;

      @include tablet {
        padding: 23px 10px 20px;
      }
    }

    .mini-cart-image {
      width: 70px;

      @include tablet {
        width: 100px;
      }

      img {
        display: block;
        max-width: 70px;
        margin: 0 auto;

        @include tablet {
          max-width: 100px;
        }
      }
    }

    .mini-cart-info {
      padding: 0 0 0 16px;

      @include tablet {
        padding: 0 20px 0 26px;
      }
    }

    .mini-cart-small-title {
      font-family: $font-header;
      color: $rit-copper-dark;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      margin-bottom: 10px;

      @include tablet {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2px;
        margin-bottom: 2px;
      }
    }

    .mini-cart-name {
      font-family: $font-header;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 8px;

      @include desktop {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
      }
    }

    .mini-cart-attributes {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin: 0 0 9px;

      @include tablet {
        margin-top: -1px;
      }
    }

    .mini-cart-details-bottom {
      .mini-cart-pricing {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #959595;
      }

      .error {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
    }
  }

  .order-info-block-cancelled {
    order: 2;

    @include mobile-only {
      & + .order-info-block {
        margin-bottom: 0;
      }
    }

    & ~ .order-info-block {
      order: 1;
    }

    & ~ .shipping-info-block {
      order: 3;
    }

    @include tablet {
      order: 1;

      & ~ .order-info-block {
        order: 3;
      }

      & ~ .shipping-info-block {
        order: 2;
      }
    }
  }

  .shipping-info-block {
    background-color: $white;
    padding: 41px 42px 25px;
    width: 48%;
    max-width: 630px;

    @include mobile-only {
      width: 100%;
      padding: 26px 25px 18px;
      margin: 0 0 24px;
      max-width: none;
    }

    .shipping-method {
      display: block;
      font-family: $font-header;
      color: $rit-copper-dark;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      margin: 16px 0 8px;

      @include tablet {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2px;
        margin: 23px 0 11px;
      }
    }

    .address {
      display: block;

      @include mobile-only {
        margin-bottom: -2px;
      }
    }
  }

  .order-info-block,
  .shipping-info-block {
    margin-bottom: 24px;
  }

  .shipping-info-holder {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;

    @include desktop {
      flex-direction: row;
    }
  }

  .shipping-info-text,
  .wimo-map {
    width: 100%;

    @include desktop {
      width: 50%;
    }
  }

  .shipping-info-text {
    @include desktop {
      padding-right: 15px;
    }
  }

  .wimo-map {
    min-height: 187px;
    margin: 16px 0 4px;

    @include desktop {
      margin: 26px 0 0;
    }
  }

  .pup-place {
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin: -3px 0 10px;

    @include mobile-only {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 1.6px;
      margin: 0 0 9px;
    }
  }

  .opening-times {
    margin-top: 21px;
    padding-top: 11px;
    border-top: 1px solid $gray-gainsboro;

    @include desktop {
      max-width: 235px;
    }

    + .note {
      border-top: 0;
      padding-top: 0;
    }

    .accordion-content {
      .acc-content-head {
        width: 100%;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.8px;
        font-weight: bold;
        color: $rit-black;
        padding: 0;
        text-transform: none;
        text-decoration: none;

        &::before {
          font-size: 34px;
          left: auto;
          right: -10px;
        }
      }

      .acc-content-body {
        padding: 12px 0 0;

        ul {
          list-style: none;

          li {
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 24px;

            &.active {
              font-weight: bold;
            }

            .day {
              min-width: 88px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .note {
    border-top: 1px solid $gray-gainsboro;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: $gray-silver;
    display: block;
    margin-top: 22px;
    padding-top: 22px;

    @include desktop {
      max-width: 235px;
    }
  }
}

.wimo-canceled-order-wrapper {
  order: 1;
  padding-top: 18px;
  max-width: 100%;

  @include tablet {
    order: 2;
    width: 48%;
    padding-top: 40px;
  }
}

.wimo-canceled-order {
  text-align: center;

  @include mobile-only {
    width: calc(100% + 40px);
    margin: 0 0 20px -20px;
  }

  .wimo-canceled-order-slider {
    @include mobile-only {
      padding-bottom: 30px;
    }
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: $font-header;
    display: block;
    margin-bottom: 12px;

    @include mobile-only {
      text-align: left;
      padding-left: 20px;
    }
  }

  .wimo-drag-list-item {
    padding: 0 5px;
  }

  .drag-list-body {
    @include mobile-only {
      padding: 0 19px;
    }
  }

  .info-box-wrapper {
    display: flex;
  }

  .info-box {
    display: flex;
    align-items: center;
    background: $white;
    margin: 0 auto 20px;
    padding: 33px 40px 30px;
    width: 100%;
    max-width: 90vw;
    min-height: 132px;

    @include mobile-only {
      box-shadow: 1px 2px 5px rgba($black, 0.25);
    }

    @include tablet {
      max-width: 400px;
      min-height: 192px;
    }

    p,
    a {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-family: $font-header;
      margin-bottom: 0;

      @include tablet {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1.2px;
      }
    }
  }
}

.wimo-faq {
  background-color: $rit-sandy-dark;
}

.wimo-news-banner-wrapper {
  background-color: $white;
  border-bottom: 1px solid $rit-copper-dark;
  text-align: center;
  padding: 12px 10px 8px;

  span,
  a,
  p,
  div {
    display: inline;
    font-style: italic;
    font-size: 14px;
    letter-spacing: 0.27px;
    color: $rit-black;
  }

  .ico {
    color: $rit-copper-dark;
    font-size: 22px;
    margin-right: 2px;
  }
}

.wimo-progress-wrapper {
  text-align: center;
  padding: 31px 0 40px;
  position: relative;

  @include tablet {
    padding: 50px 0 60px;
  }

  .responsive-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(246, 244, 242, 0) 0%, rgba(246, 244, 242, 1) 100%);
      height: 150px;
      width: 100%;
    }
  }

  .wimo-order-title {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 1px;
    text-transform: none;
    font-style: italic;

    @include tablet {
      font-size: 40px;
      line-height: 50px;
      letter-spacing: 1.5px;
      max-width: 580px;
      margin: 0 auto 28px;
    }
  }

  .wimo-order-subtitle {
    letter-spacing: 0.3px;
    max-width: 500px;
    margin: 0 auto;
    display: block;
  }

  .wimo-order-date {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: $gray-disabled;
    display: block;
    margin: 20px 0 10px;
    .refresh-time {
      display: inline;
    }

    @include tablet {
      margin-top: 0;
    }
  }

  .wimo-order-step {
    background-color: $rit-black;
    position: relative;
    padding: 17px 28px 16px 60px;
    text-align: left;
    color: $white;
    max-width: 580px;
    margin: 0 auto 8px;

    @include tablet {
      padding: 21px 24px 21px 76px;
      margin-bottom: 9px;
    }

    .status {
      position: absolute;
      top: 19px;
      left: 20px;

      @include tablet {
        top: 21px;
        left: 24px;
      }

      .circle {
        width: 24px;
        height: 24px;
        border: 1px solid $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet {
          width: 32px;
          height: 32px;
        }

        .check {
          display: none;
        }
      }

      .wimo-spinner-wrapper {
        display: none;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        position: relative;

        &::after {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $rit-copper-on-black;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include tablet {
            width: 16px;
            height: 16px;
          }
        }

        .wimo-spinner {
          width: 32px;
          height: 32px;
          padding: 1px;
          border-radius: 50%;
          background: linear-gradient(0deg, rgba(63, 249, 220, 0.1) 33%, $white 100%);
          animation: spin 1.5s linear 0s infinite;
        }

        .wimo-spinner-core {
          width: 100%;
          height: 100%;
          background-color: $rit-black;
          border-radius: 50%;
        }

        @keyframes spin {
          from {
            transform: rotate(0);
          } to {
            transform: rotate(-359deg);
          }
        }
      }

      .wimo-loader {
        display: none;
        text-align: center;
        font-size: 0;
        flex-direction: column;
        align-items: center;
        margin-top: 9px;

        @include tablet {
          margin-top: 11px;
        }

        span {
          background-color: $white;
          border-radius: 50%;
          display: block;
          height: 5px;
          width: 5px;
          margin-bottom: 4px;
        }
      }

      &.completed {
        .circle {
          background-color: $white;

          .check {
            @include icon(check);
            display: block;
            color: $rit-black;
            font-size: 11px;

            @include tablet {
              font-size: 15px;
            }
          }
        }

        .wimo-loader {
          display: flex;
        }
      }

      &.in-progress {
        .wimo-spinner-wrapper {
          display: flex;
        }

        .circle {
          display: none;
        }

        .wimo-loader {
          display: flex;

          span {
            opacity: 0;

            @for $i from 1 through 3 {
              &:nth-child(#{$i}) {
                animation: loaderPulse 1.6s infinite $i*0.2s;
              }
            }

            @keyframes loaderPulse {
              0%, 100% { opacity: 0.2; }
              50% { opacity: 1; }
            }
          }
        }
      }
    }
  }

  .order-status {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    text-transform: none;

    @include tablet {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }

  .order-date {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: $grey-medium-light;
    margin-top: -7px;
    display: block;

    & + .track-and-trace-link-wrapper {
      margin-top: 10px;

      @include tablet {
        margin-top: 14px;
      }
    }
  }

  .btn--white {
    display: block;
    margin-top: 15px;
    padding: 10px 20px;

    @include tablet {
      margin-top: 23px;
    }

    .ico {
      font-size: 18px;
      margin-left: 4px;
    }
  }

  .track-and-trace-link-wrapper {
    margin-top: 10px;

    @include tablet {
      margin-top: 8px;
    }

    & + .track-and-trace-link {
      margin-top: 11px;

      @include tablet {
        margin-top: 16px;
      }
    }
  }

  .track-and-trace-link {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    color: $rit-copper-on-black;
    display: inline-block;

    @include tablet {
      font-size: 14px;
    }

    & + .icon-arrow--link {
      font-size: 13px;
      margin-left: 4px;
      color: $rit-copper-on-black;
    }
  }
}
