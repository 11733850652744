.usp-text-two-cols {
  display: flex;
  text-align: center;
  width: 100%;

  .text-col {
    width: 100%;
  }

  .link {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
  }

  @include mobile-only {
    .inner-wrapper {
      padding: 0;
    }

    .text-col {
      background-color: $rit-sandy-light;
      margin-bottom: 1px;
      padding: 33px 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .heading-h10 {
      margin-bottom: 14px;
    }

    p {
      margin-bottom: 14px;
    }
  }

  @include tablet {
    background-color: $rit-sandy-light;
    padding: 28px 0 32px;

    .text-col {
      float: left;
      width: 50%;
    }

    .heading-h10 {
      margin-bottom: 2px;
    }

    p {
      margin-bottom: 10px;
    }
  }
}

.usp-text-slider {
  position: relative;
  width: 100%;
  text-align: center;

  &:not(.drag-list) {
    .item + .item {
      display: none;
    }
  }

  .item {
    padding: 0 15px;
    position: relative;
  }

  p {
    color: $rit-dark;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 18px;
  }

  .highlighted {
    display: block;
    font-weight: bold;
  }

  .slick-dots {
    display: flex;
    font-size: 0;
    list-style: none;
    justify-content: center;
    margin: 8px 0 0;
    text-align: center;
    width: 100%;

    li {
      display: inline-block;
      margin: 0 3px;
    }

    button {
      background-color: $gray-lines;
      border-radius: 50%;
      display: block;
      font-size: 0;
      height: 4px;
      width: 4px;
    }

    .slick-active button {
      background-color: $rit-dark;
    }
  }

  @include desktop {
    &::before,
    &::after {
      bottom: 0;
      content: '';
      position: absolute;
      top: 0;
      width: 15px;
      z-index: 3;
    }

    &::before {
      background: linear-gradient(to right, $white 0%, rgba($white, 0) 100%);
      left: 0;
    }

    &::after {
      background: linear-gradient(to right, rgba($white, 0) 0%, $white 100%);
      right: 0;
    }
  }
}

.usp-text-slider.drag-list {
  @include mobile-only {
    &::before,
    &::after {
      bottom: 4px;
      content: '';
      position: absolute;
      top: 0;
      width: 15px;
      z-index: 3;
    }

    &::before {
      background: linear-gradient(to right, $white 0%, rgba($white, 0) 100%);
      left: 0;
    }

    &::after {
      background: linear-gradient(to right, rgba($white, 0) 0%, $white 100%);
      right: 0;
    }
  }

  .drag-list-body {
    padding: 0;
  }

  .item {
    padding: 0 0 8px;

    @include mobile-only {
      width: calc(100vw - 80px);
    }
  }

  p {
    padding: 0 15px;
  }
}