.video-fullscreen-container {
  bottom: 0;
  visibility: hidden;
  left: 0;
  padding: 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  @include tablet {
    padding: 30px;
  }

  &.visible {
    visibility: visible;
    z-index: $modal-window-index;
    transform: translateZ(0);
  }

  &.active {
    .fullscreen-video-popup {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }

    .product-experience-backdrop {
      opacity: 1;
    }

    .video-controls_centered {
      display: block;
    }
  }

  .fullscreen-video-popup {
    width: 100%;
    height: 60vw;
    max-height: calc(100% - 100px);

    overflow: hidden;
    max-width: 1400px;
    margin: 0 auto;
    top: 50%;
    position: relative;

    opacity: 0;
    transform: translateY(-50%) scale(0.95);
    transition: transform $tertiary-duration $default-ease-out-fast,
      opacity $tertiary-duration $default-ease-out-fast;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .video-controls_bottom-right {
      bottom: 20px;
      position: absolute;
      right: 20px;
      z-index: 1;

      &.is-playing {
        a {
          transform: translateX(-28px);
        }
      }

      a {
        font-size: 26px;
        text-decoration: none;
      }

      li {
        display: flex;
        height: 27px;
        overflow: hidden;
        padding: 1px;
        white-space: nowrap;
        width: 26px;
      }
    }

    .video-background {
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .product-experience-backdrop {
    background-color: rgba($black, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity $quaternary-duration $default-ease-out-fast;
    z-index: -1;
  }
}

.background-video {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity $tertiary-duration $default-ease-in-out;
  display: none;

  @include tablet {
    display: block;
  }

  &.show,
  &.is-dam {
    opacity: 1;
    display: block;
  }

  &.is-dam {
    video::-webkit-media-controls {
      display: none !important;
    }
  }

  .responsive-video {
    max-width: 100%;

    iframe,
    object,
    embed,
    .video-element {
      box-sizing: border-box;
      height: 58vw;
      left: 50%;
      min-height: calc(100% + 5px); // fix weird bg peekthrough
      min-width: calc(100% + 5px); // fix weird bg peekthrough
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: 177.8vh;
    }
  }
}

// --------------------------------
//            VIDEO CONTROLS
// --------------------------------
.video-controls_centered {
  bottom: 30px;
  display: none;
  opacity: 1;
  position: absolute;
  text-align: center;
  transition: opacity $primary-duration $default-ease-out-fast;
  white-space: nowrap;
  width: calc(100% - 20px);
  z-index: 1;

  @include tablet {
    bottom: 0;
    left: 50%;
    margin: 0 auto;
    opacity: 0;
    transform: translate(-50%, -50%);
    width: auto;
  }

  .video-close {
    color: $white;
    font-family: $font-body;
    text-decoration: none;
    transition: transform $quaternary-duration $default-ease-out-fast;

    .icon {
      font-size: 26px;
    }
  }

  &.active {
    @include tablet {
      transition: opacity $tertiary-duration $default-ease-out-fast;
      opacity: 1;
    }

    .video-close {
      @include tablet {
        cursor: pointer;
        transform: translateY(0);
      }
    }
  }
}

.video-controls_bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  overflow: hidden;
  z-index: 5;
  opacity: 0.6;

  .buttons-wrapper {
    position: relative;
  }

  .video-control-button {
    font-size: 26px;
    margin: 5px;
    display: inline-block;
    color: $white;

    span {
      &::before {
        display: block;
      }
    }
  }

  .icon-play,
  .icon-play-large {
    display: block;
  }
  .icon-pause,
  .icon-pause-large {
    display: none;
  }

  &.is-playing {
    .icon-play,
    .icon-play-large {
      display: none;
    }
    .icon-pause,
    .icon-pause-large {
      display: block;
    }
  }
}

.btn-content-video {
  color: $rit-copper;
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  padding-left: 36px;
  position: relative;
  text-decoration: underline;

  &::before {
    @include icon-styles;
    content: icon-char(play);
    font-size: 25px;
    left: 0;
    position: absolute;
    text-decoration: underline;
    top: 0;
  }

  &:hover {
    text-decoration: none;
  }

  & ~ .btn-content-video {
    margin-left: 15px;
  }
}

.btn-content-video::before {
  text-decoration: none;
}
