.phase-page-gwp {
  align-items: center;
  background-color: $rit-black-lighter;
  display: flex;
  flex-direction: column;
  padding: 45px 24px;
  width: 100%;

  @include desktop-medium {
    padding: 65px 60px;
  }

  &__text {
    margin-bottom: 24px;
    text-align: center;
    width: 100%;

    h2,
    p {
      color: $white;
      margin: 0 auto;
      text-align: center;

      @include tablet {
        width: 60%;
      }

      @include desktop-medium {
        width: 90%;
      }
    }

    h2 {
      font-family: $font-header;
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 32px;
      margin-bottom: 10px;
      text-transform: none;

      @include desktop-medium {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
      }
    }

    p {
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }

  &__gifts.gwp-shoppingcart-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    max-width: 1340px;
    width: 100%;


    @include desktop {
      flex-direction: row;
      justify-content: center;
    }

    .approaching-free-gift {
      background-color: $white;
      width: 100%;
      padding: 0;

      @include desktop {
        margin: 0 10px 0 10px;
        max-width: 315px;
      }

      @include desktop-medium {
        max-width: 350px;
      }

      @include desktop-large {
        max-width: 420px;
      }

      &:first-child {
        @include desktop {
          margin-left: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
        @include desktop {
          margin-right: 0;
        }
      }

      .product-image {
        align-items: center;
        display: flex;
        height: 150px;
        justify-content: center;
        width: 120px;

        img {
          width: 100%;
        }
      }

      .promo-product-details {

        .col-text {
          @include desktop-small {
            padding-left: 15px;
          }
        }

        .product-name {
          font-family: $font-header;

          .ecom-title {
            color: $rit-copper;
            font-size: 12px;
            letter-spacing: 1.7px;
            padding-right: 37px;
          }

          .gift-label {
            color: $rit-black;
            font-size: 16px;
            line-height: 24px;
            padding-right: 37px;

            @include desktop {
              font-size: 20px;
            }
          }

          .gift-price {
            color: $gray-dark;
            font-size: 14px;
            line-height: 24px;
            font-family: $font-body;

            @include desktop-large {
              width: 80%;
            }
          }
        }
      }
    }

    .product-gift-ribbon {
      &::after {
        opacity: 1;
      }
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: column;

    &--terms {
      color: $rit-copper-lighter;
      margin-top: 16px;
    }
  }
}
