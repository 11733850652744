.breadcrumb {
  display: block;
  width: 100%;

  ol {
    margin: 0 auto;
    padding: 2px 0;

    @include desktop {
      padding: 2px 40px;
    }
  }

  .breadcrumb-list-item {
    display: inline-block;

    &.back-button-home-wrapper {
      @include mobile-only {

        .breadcrumb-element span {
          text-decoration: underline;
        }
      }

      &:nth-last-child(2) {
        @include icon(arrow3--left);
      }

      &::before {
        font-size: 23px;
        margin: 0 -4px 0 -10px;

        @include tablet {
          display: none;
        }
      }
    }

    // hide all breadcrumbs but the last in mobile
    @include mobile-only {
      &:not(:nth-last-child(2)) {
        display: none;
      }
    }

    // add arrow in the remaining breadcrumb
    &:nth-last-child(2) {
      @include icon(arrow4--left);
    }

    &::before {
      font-size: 9px;
      margin-right: 0;

      @include tablet {
        display: none; // hide all arrows if not mobile
      }
    }

    &:last-child {
      .breadcrumb-element {
        @include icon(arrow4--left);

        &::after {
          content: '';
        }

        &::before {
          @include tablet {
            display: none;
          }
        }

        &.last {
          pointer-events: none;
        }
      }
    }

    a {
      span {
        display: inline-block;
        position: relative;
        &::after {
          content: '';
          background-color: $rit-copper-light;
          bottom: 0;
          display: inline-block;
          height: 1px;
          left: 0;
          position: absolute;
          transition: width .3s ease;
          width: 0;
        }
      }
    }

    a:hover {
      text-decoration: none;

      span {
        &::after {
          width: 100%;
        }
      }
    }
  }

  .breadcrumb-element {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    color: $rit-dark;
    text-transform: uppercase;
    text-decoration: none;

    @include tablet {
      &::after {
        content: '-';
        display: inline-block;
        padding: 0;
        line-height: normal;
      }
    }
  }
}

div.breadcrumb ol {
  @media (min-width: 1024px) and (max-width: 1249px) {
    padding: 2px 7px;
  }
}

.breadcrumb-collection-page {
  position: absolute;
  top: 91px;
  left: 0;
  z-index: 4;

  @include tablet {
    top: 105px;
  }

  @include desktop {
    top: 120px;
  }

  .breadcrumb-list-item,
  .breadcrumb-element {
    color: $white;
  }

  .breadcrumb-list-item a span::after {
    background-color: $white;
  }

  &.breadcrumb-subcollection .breadcrumb-element span.breadcrumb-mobile-label {
    display: none;
  }

  @include mobile-only {
    &.breadcrumb-subcollection {
      .breadcrumb-element span {
        display: none;

        &.breadcrumb-mobile-label {
          display: inline-block;
        }
      }
    }
  }
}

#wrapper .header-shop-overlay ~ .full-width .primary-content .breadcrumb-collection-page { // sass-lint:disable-line no-mergeable-selectors no-ids
  top: 140px;

  @include desktop {
    top: 160px;
  }
}
