.question-block {
  padding: 63px 20px 78px;
  text-align: center;
  color: $rit-dark;
  background-color: $gray-sand;

  @include tablet {
    padding: 122px 20px 116px;
  }

  .text-container {
    color: $rit-dark;

    //TO DO: can be removed when redesign is applied to b2b
    .icon-search {
      font-size: 27px;
    }

    .icon-search-magnifier {
      font-size: 25px;
    }

    span {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
  .question {
    font-size: 24px;
    text-transform: none;
    margin-bottom: 6px;
  }

  .contact {
    display: block;
    margin-bottom: -8px;
  }

  a {
    color: $rit-dark;
    cursor: pointer;
    font-size: 14px;
  }
}
