.refill-impact-container {
  background-color: $rit-sandy-bg;
  min-height: 723px;
  padding: 80px 0;
  width: 100%;

  .refill-impact-top {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    text-align: center;

    .refill-imapct-main-title {
      color: $rit-black;
      font-family: $font-header;
      font-size: 30px;
      letter-spacing: 1.2px;
      line-height: 40px;
      margin: 0 auto 16px;
      max-width: 858px;
      min-height: 40px;
      text-align: center;
      text-transform: none;
    }

    .refill-impact-main-description {
      color: $rit-black;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      margin: auto;
      max-width: 666px;
      min-height: 65px;
      text-align: center;

      @include mobile-only {
        padding: 0 18px;
      }
    }
  }

  .refill-inner-wrapper {
    min-height: 354px;
    position: relative;
  }

  .slick-prev,
  .slick-next {
    align-items: center;
    display: none;
    height: 100%;
    position: absolute;
    z-index: 1;

    @include mobile-only {
      display: flex;
    }
  }

  .slick-prev {
    background: linear-gradient(to right, $rit-sandy-bg 0%, rgba(255, 255, 255, 0) 50%);
    left: 0;
    padding-left: 20px;
  }

  .slick-next {
    background: linear-gradient(to left, $rit-sandy-bg 0%, rgba(255, 255, 255, 0) 50%);
    padding-right: 20px;
    right: 0;
  }

  .refill-impact-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    min-height: 354px;

    @include mobile-only {
      flex-wrap: nowrap;
    }

    .slick-list {
      padding-left: 50px !important;
    }

    .refill-impact-slider-item {
      min-height: 354px;
      min-width: 178px;
    }

    .refill-impact-img-box {
      background-color: $rit-sandy-dark;
      border-radius: 50%;
      height: 130px;
      margin: 20px auto;
      overflow: hidden;
      width: 130px;

      .refill-impact-img {
        background-position: center;
        background-size: cover;
        height: 80px;
        margin: 25px 28px;
        width: 73.3px;
      }
    }

    .refill-impact-item-name {
      color: $rit-black;
      font-family: $font-header;
      font-size: 16px;
      height: 24px;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin: 0 auto 17px;
      text-align: center;
      max-width: 183.86px;
    }

    .refill-imapct-items-group {
      display: flex;
      flex-direction: column;
      min-height: 72px;
      margin: auto;
      width: 150.59px;

      .refill-impact-group-description {
        display: flex;
        flex-direction: row;
        height: 24px;
        width: 150.59px;

        .refill-impact-group-left {
          color: $gray-dark;
          font-family: $font-body;
          font-size: 14px;
          height: 24px;
          letter-spacing: 0.4px;
          line-height: 24px;
          text-align: right;
          width: 64.79px;
        }

        .refill-impact-group-middle {
          border-left: 1px solid $gray-dark;
          box-sizing: border-box;
          height: 13.86px;
          margin: 5px 10px;
          width: 0.88px;
        }

        .refill-impact-group-right {
          color: $gray-dark;
          font-family: $font-body;
          font-size: 14px;
          height: 24px;
          letter-spacing: 0.4px;
          line-height: 24px;
          width: 64.79px;
        }
      }
    }
  }

  .refill-impact-progress {
    background-color: $slider-bar-gray;
    background-image: linear-gradient(to right, $black, $black);
    background-repeat: no-repeat;
    background-size: 0 100%;
    border-radius: 4px;
    display: none;
    height: 4px;
    margin: 0 auto 40px;
    overflow: hidden;
    transition: background-size .4s ease-in-out;
    width: 80%;

    @include mobile-only {
      display: block;
    }
  }

  .refill-impact-btn-wrapper {
    display: flex;
    justify-content: center;

    .refill-impact-btn {
      border: 1px solid $gray-disabled;
      box-sizing: border-box;
      margin: auto;
      min-height: 48px;
      padding: 9px 40px;
      text-decoration: none;

      .refill-impact-btn-text {
        color: $rit-black;
        font-family: $font-body;
        font-size: 12px;
        height: 16px;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        width: 189px;
      }
    }
  }
}
