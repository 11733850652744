.product-slider-block {
  padding-bottom: 55px;
  width: 100%;

  @include tablet {
    &:not(.collection-product-slider-block) {
      padding-bottom: 0;
    }
  }
}

.product-slider-reverted {
  @include tablet {
    .product-slider-steps {
      padding-left: 0;
      padding-right: 50%;
    }

    .product-slider-media-holder {
      left: auto;
    }

    .product-slider-step {
      float: right;
    }
  }

  @include desktop {
    .product-slider-holder {
      .slick-arrow {
        &.slick-prev {
          left: 57px;
        }

        &.slick-next {
          right: 66px;
        }
      }

      .slider-bar {
        transform: translateX(0);
      }

      .slider-dots {
        margin-left: 0;
      }
    }
  }

  @media (min-width: 1441px) {
    .product-slider-holder {
      div.slick-arrow {
        &.slick-prev {
          left: 5%;
        }

        &.slick-next {
          left: auto;
          right: 66px;
        }
      }

      .slider-bar {
        margin: 26px 210px 0 auto;
      }

      .slider-dots {
        left: auto;
        right: 210px;
      }
    }
  }
}

.product-slider-intro {
  padding: 60px 10px 0;
  text-align: center;
  width: 100%;

  h4 {
    color: $black;
    margin-bottom: 12px;
    font-size: 14px;
    letter-spacing: 2.4px;
    line-height: 18px;
  }

  h2 {
    color: $rit-black;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 40px;
    margin-bottom: 23px;
    text-transform: none;
  }

  .read-time,
  p {
    display: none;
  }

  @include tablet {
    padding: 95px 20px 64px;

    .inner-wrapper {
      max-width: 800px;
    }

    h4 {
      font-size: 15px;
      letter-spacing: 5px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 40px;
      letter-spacing: 1.6px;
      line-height: 50px;
      margin-bottom: 17px;
    }

    .read-time {
      color: $black;
      display: inline-block;
      font-family: $font-header;
      font-size: 15px;
      letter-spacing: 5px;
      line-height: 23px;
      margin-bottom: 24px;
      padding-left: 20px;
      position: relative;
      text-transform: uppercase;

      &::before {
        @include icon-styles;
        content: icon-char(ico-clock);
        display: inline-block;
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    p {
      display: block;
      line-height: 28px;
      margin: 0 auto;
      max-width: 500px;
    }
  }

  @include desktop {
    padding: 95px 0 64px;
  }
}

.product-slider-steps {
  width: 100%;

  @include tablet {
    padding-left: 50%;
    position: relative;
  }
}

.product-slider-media-holder {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;

  .image-holder,
  .background-video {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.4s ease-out,
                visibility 0.4s ease-out;
    z-index: 2;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  @include tablet {
    display: block;
  }
}

.product-slider-holder {
  opacity: 0;
  padding-bottom: 16px;
  position: relative;
  transition: opacity 1s ease;
  width: 100%;

  &.fadein {
    opacity: 1;
  }

  .slider-inner {
    width: 100%;
  }

  .slider-bar {
    height: 4px;
    margin: 44px auto 0;
  }

  .slider-dots {
    bottom: 16px;
    height: 4px;
  }

  &.show-steps {
    counter-reset: step-counter;

    .step-intro {
      &::before {
        color: $rit-dark;
        content: counter(step-counter);
        counter-increment: step-counter;
        display: inline-block;
        font-family: $font-header;
        font-size: 246px;
        left: 50%;
        line-height: 1;
        opacity: .11;
        position: absolute;
        top: -38px;
        transform: translateX(-50%);
        z-index: -1;
      }
    }
  }

  @include tablet {
    padding-bottom: 30px;

    .slider-bar {
      bottom: 30px;
      margin-top: 26px;
    }

    .slider-dots {
      bottom: 30px;
    }

    &.show-steps {
      padding-bottom: 0;

      .slick-slide {
        .step-intro {
          padding: 120px 0 1px;

          &::before {
            top: 28px;
            transform: translateX(-60%);
          }

          h3 {
            &::before,
            &::after {
              background-color: $rit-dark;
              content: '';
              display: inline-block;
              height: 1px;
              opacity: .2;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 1000em;
            }

            &::before {
              right: calc(100% + 20px);
            }

            &::after {
              left: calc(100% + 20px);
            }
          }
        }

        &:first-child .step-intro h3::before,
        &:last-child .step-intro h3::after {
          display: none;
        }
      }
    }
  }

  @include desktop {
    .slider-bar {
      transform: translateX(-10px);
    }

    .slider-dots {
      margin-left: -10px;
    }
  }

  @media (min-width: 1441px) {
    div.slider-bar {
      margin-left: 200px;
      transform: translateX(0);
    }

    div.slider-dots {
      left: 200px;
      margin-left: 0;
      transform: translateX(0);
    }
  }
}

.product-main-slider {
  overflow: hidden;
  padding: 0 23px;
  width: 100%;

  .slick-arrow {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
  }

  .slick-list {
    overflow: visible;
  }

  .product-slider-step {
    padding: 0 4px;
    text-align: center;
    width: 100%;

    & + .product-slider-step {
      display: none;
    }
  }

  .step-intro {
    padding: 40px 0 31px;
    position: relative;
    width: 100%;
    min-height: 208px;

    h3 {
      color: $black;
      font-size: 22px;
      letter-spacing: 4px;
      line-height: 30px;
      margin-bottom: 13px;
      text-transform: uppercase;
    }

    .read-time {
      display: block;
      margin-bottom: 7px;
    }

    p,
    .link {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: none;
    }

    .description-link {
      display: block;
      color: $rit-dark;
      text-decoration: none;

      @include mobile-only {
        min-height: 48px;
      }
    }

    .btn-content-video {
      display: none;

      @include mobile-only {
        display: inline-block;
        margin-top: 33px;
      }
    }

    .slider-text-short,
    .slider-text-long {
      .description-link {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.5px;

        @include tablet {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 1px;
        }
      }
    }

    .slider-text-short {
      width: 100%;
      position: relative;

      @include tablet {
        display: none;
      }
    }

    .slider-text-long {
      display: none;
      width: 100%;
      position: relative;

      @include tablet {
        display: block;

        .link.link--large {
          display: none;
        }
      }
    }

    @include mobile-only {
      display: flex;
      flex-direction: column;
    }
  }

  .product-tile {
    background-color: $white;
    padding: 10px 20px 20px;

    .thumb-link {
      max-width: 260px;
      display: inline-block;
    }

    img {
      display: inline-block;
      max-width: 100%;
    }

    .the-ritual-of-title {
      color: $rit-copper;
      font-size: 11px;
      letter-spacing: 1.6px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    .product-name-link {
      text-decoration: none;
    }

    .product-name {
      font-size: 15px;
      letter-spacing: 3px;
      line-height: 23px;
      margin-bottom: 11px;
    }

    .short-description {
      margin: -10px 0 10px;
    }

    .product-price {
      font-size: 16px;
      letter-spacing: 2.4px;
      line-height: 19px;
      margin-bottom: 26px;
    }

    .product-add-to-cart {
      margin-bottom: 12px;
    }

    .link--large {
      display: inline-block;
      letter-spacing: 1.3px;
    }

    @include mobile-only {
      margin-top: auto;
      min-height: 465px;
    }
  }

  .product-details-content .out-of-stock-block {
    margin-top: 0;
  }

  .product-options .swatches-wrapper,
  .product-variations .swatches-wrapper {
    justify-content: center;
  }

  @include mobile-only {
    &.product-slider-stacked {

      & ~ .slider-dots {
        display: none;
      }

      .product-tile {
        background-color: transparent;
        padding-bottom: 36px;
      }

      .step-intro {
        padding-top: 64px;
        z-index: 1;

        &::before {
          top: -2px;
        }
      }

      .product-slider-step:not(:last-of-type) {
        &::after {
          height: 1px;
          width: calc(100% - 20px);
          margin: 0 auto;
          content: '';
          display: block;
          background-color: $gray-medium;
        }
      }
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
    }
  }

  @include tablet {
    padding: 0;

    .slick-arrow {
      opacity: 1;
      top: 50%;
      transform: translateY(-50%);
      visibility: visible;
      z-index: 3;

      &.slick-prev {
        left: 6px;
      }

      &.slick-next {
        left: auto;
        right: 6px;
      }
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-slide {
      opacity: 0;
      overflow: hidden;
      transition: opacity .3s ease;

      &.slick-active {
        opacity: 1;
      }
    }

    .product-slider-step {
      max-width: 720px;
      padding: 0;
      position: relative;
      text-align: center;
    }

    .slider-inner-wrap {
      margin: 0 auto;
      max-width: 570px;
      padding: 0 20px;
    }

    .step-intro {
      padding: 50px 0 1px;
      min-height: 0;

      h3 {
        font-size: 30px;
        letter-spacing: 8px;
        line-height: 50px;
        display: inline-block;
        margin-bottom: 22px;
        position: relative;
      }

      p {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 1px;
      }
    }

    .product-tile  {
      background-color: transparent;

      img {
        max-width: 260px;
      }

      .product-details-content {
        max-width: 100%;
        padding: 20px;
      }

      .short-description {
        margin: -10px 0 15px;
      }

      .product-price {
        letter-spacing: 1px;
      }

      .product-add-to-cart {
        margin-bottom: 17px;
      }

      .link--large {
        letter-spacing: 2px;
      }
    }
  }

  @include desktop {
    .slick-arrow {
      &.slick-prev {
        left: 66px;
      }

      &.slick-next {
        right: 57px;
      }
    }
  }

  @media (min-width: 1441px) {
    div.slick-arrow.slick-next {
      right: 5%;
    }
  }
}

.exclusive-slider-block {
  .step-intro {
    h4 {
      color: $rit-copper-dark;
      font-size: 12px;
      letter-spacing: 0.9px;
      line-height: 16px;
    }
  }

  .btn-personalize {
    .icon-pencil-configurable {
      @include pencil-white-icon(16px, 16px);
      margin-bottom: -5px;
    }
  }

  .btn {
    margin-top: 10px;
  }

  .ico {
    color: $rit-copper;
    margin: 0 auto 30px;

    @include mobile-only {
      margin: 0 auto 20px;
    }

    &:not(.icon--large, .icon--medium, .icon--small) {
      font-size: 24px;
    }
  }

  @include mobile-only {
    padding: 40px 0 24px;

    .slick-list,
    .slick-track {
      -webkit-overflow-scrolling: touch;
    }

    .product-slider-media-holder {
      display: none;
    }

    .product-main-slider {
      padding: 0 25px;

      .image-holder {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 244px;
        margin-bottom: 20px;
        width: 100%;
      }
    }

    .slick-slide {
      padding: 0 5px;
    }

    .product-slider-step {
      background-color: $white;
      padding: 0;
    }

    .step-intro {
      padding: 23px 30px 30px;

      h4 {
        color: $rit-copper-dark;
        font-size: 12px;
        letter-spacing: 0.9px;
        line-height: 16px;
      }

      h3 {
        letter-spacing: 0;
        line-height: 26px;
        margin-bottom: 22px;
        text-transform: none;
      }

      p {
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 18px;
      }

      .btn-content-video {
        margin: 8px 0 10px;
      }
    }

    .slider-bar {
      height: 2px;
      margin: 20px auto 0;
      max-width: 320px;
    }

    .slider-dots {
      max-width: 320px;
    }
  }

  @include tablet {
    .product-main-slider {
      margin: 90px 0 130px;
    }

    .product-slider-steps {
      align-items: center;
      display: flex;
      min-height: 750px;

    }
    .product-slider-holder {
      padding-bottom: 0;
    }

    .product-main-slider {
      .image-holder {
        display: none;
      }
    }

    .slider-inner-wrap {
      max-width: 460px;
    }

    .step-intro {
      padding: 43px 0 0;

      h3 {
        font-size: 40px;
        letter-spacing: 1.7px;
        line-height: 50px;
        text-transform: none;
      }

      p {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
    }

    .btn {
      margin-top: 30px;
    }
  }
}
