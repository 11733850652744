.tabs-holder {
  text-align: center;
  width: 100%;

  @include mobile-only {
    display: none;
  }

  .tabs-items-holder {
    width: 100%;
  }

  .tabs {
    font-size: 0;
    list-style: none;
    margin-bottom: 9px;
    width: 100%;

    li {
      display: inline-block;
      padding: 0 25px;
      position: relative;
    }

    .tab-item {
      color: $gray-dark;
      font-family: $font-body;
      font-size: 12px;
      letter-spacing: 2.08px;
      line-height: 14px;
      padding: 14px 0 7px;
      position: relative;
      text-transform: uppercase;

      &::after {
        background-color: $rit-dark;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        transition: width .3s cubic-bezier(.05, .5, .3, 1);
        width: 0;
      }

      &.selected,
      &:focus,
      &:hover {
        color: $rit-black;

        &::after {
          width: 100%;
        }
      }
    }
  }

  .tabs-content {
    width: 100%;
  }

  .tab-content {
    position: absolute;
    opacity: 0;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    max-width: 640px;
    z-index: -1;
    color: $rit-dark;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 33px 0 0;
    width: 100%;

    &.active {
      position: static;
      opacity: 1;
      transform: none;
      transition: opacity $tertiary-duration $default-ease-in-out;
      z-index: 0;
    }

    p, h4 {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    h4 {
      font-family: $font-body-bold;
      font-weight: bold;
      margin-bottom: 0;
      text-transform: none;
    }
  }

  .tab-content-formatted {
    white-space: pre-line;
  }

  .shippingPromotionTable {
    margin: 0 auto 10px;

    p {
      margin-bottom: 16px;
    }
  }

  .ingredients-tab {
    .tab-content-inner {
      margin-bottom: 36px;
      max-height: 170px;
      overflow: hidden;
      transition: .8s ease;
      width: 100%;
    }

    .btn {
      display: none;
      min-width: 200px;

      .less {
        display: none;
      }

      &.active {
        .more {
          display: none;
        }

        .less {
          display: block;
        }
      }
    }

    &.expanded .tab-content-inner {
      max-height: 5000px;
    }

    &.hidden-content .btn {
      display: inline-block;
    }
  }

  @include desktop {
    .tab-content {
      padding: 30px 0;
    }

    .shippingPromotionTable {
      margin-bottom: 15px;
    }
  }
}
