.pdp-main-container {
  background-color: $rit-sandy-dark;
  position: relative;

  @include mobile-only {
    &.no-ingredients:not(.product-configurable) {
      margin-bottom: 520px;
    }
  }
}

.pdp-hospitality-exclusive-banner {
  align-items: center;
  background-color: $red-warning;
  color: $white;
  display: flex;
  font-family: $font-body;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0.6px;
  line-height: 24px;
  padding: 14px 24px;

  @include mobile-only {
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  span {
    position: relative;
  }

  a {
    color: $white;

    @include mobile-only {
      font-size: 14px;
    }
  }
}

.pdp-hospitality-exclusive-banner-circle {
  &::after {
    @include icon-styles;
    content: icon-char(circle-exclamation);
    display: inline-block;
    font-size: 24px;
    margin-right: 18px;
  }
}

.pdp-inner-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  .product-details-content {
    .availability-web {
      .btn {
        background-color: $rit-black;
        color: $white;

        &:hover {
          background-color: $voodoo;
        }
      }
    }
  }
}

.pdp-breadcrumbs-wrapper {
  padding: 1px 0 0;

  @include tablet {
    z-index: 1;
  }

  .breadcrumb {
    display: none;

    @include tablet {
      display: block;

      & + .pdp-back-button-wrapper {
        display: none;
      }
    }
  }

  .pdp-back-button-wrapper {
    padding: 2px 0;

    @include tablet {
      padding: 7px 0 0;
    }

    .back-button {
      margin-left: -8px;
    }
  }

  .pdp-back-button {
    cursor: pointer;
    position: relative;

    .pdp-backbutton::after {
      content: '';
      background-color: $rit-copper-light;
      bottom: 0;
      display: inline-block;
      height: 1px;
      left: 17px;
      position: absolute;
      transition: width .3s ease;
      width: 0;
    }

    &:hover {
      .pdp-backbutton::after {
        width: 70%;
      }
    }

    .icon {
      font-size: 9px;
      margin-right: 0;
      vertical-align: middle;
    }

    .text {
      font-size: 12px;
      letter-spacing: 2px;
      color: $rit-dark;
      text-transform: uppercase;
    }
  }
}

.pdp-images-holder {
  max-width: 100%;
  transition: opacity 0.9s ease-in-out;
  opacity: 1;

  @include mobile-only {
    min-height: 400px;
  }

  &.fade-out {
    opacity: 0;
  }
}

.product-details-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  @include tablet {
    .breadcrumb {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }

  @include mobile-only {
    .breadcrumb {
      display: none;
    }
  }

  &.is-asset {
    .column-images {
      @include mobile-only {
        display: none;
      }
    }
  }

  .images-wrapper {
    max-width: 100%;

    @include mobile-and-tablet {
      width: 100%;
    }

    .slider-bar {
      margin: 30px auto;
    }

    .slider-dots {
      bottom: 60px;
    }

    .promotional-label {
      background: $white;
      margin: 20px auto;
      width: fit-content;
    }
  }

  .pulsating-button-wrapper {
    margin-bottom: 20px;
    position: fixed;

    @include device-height-normal {
      position: static;
    }
  }

  .images-slider,
  .images-slider-fullwidth {
    display: flex;
    overflow: hidden;

    &.slick-initialized {
      img {
        opacity: 1;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .slick-slide {
      > div {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @include mobile-only {
          overflow: hidden;
          height: calc(100vw - 40px);
          position: relative;
          width: 100%;
        }
      }
    }
  }

  .images-slider {
    .slick-arrow {
      position: absolute;
      z-index: 1;

      &.slick-next {
        right: 0;
      }
    }

    img {
      opacity: 0;
      width: auto !important;

      @include device-height-small {
        height: auto;
      }

      @include mobile-only {
        height: calc(100vw - 40px);
        max-height: 100%;
        width: auto !important;
      }

      @include tablet {
        align-self: flex-start;
      }

      @include tablet-landscape {
        max-height: 340px;
      }

      @include tablet-portrait {
        max-height: 264px;
      }

      @include desktop {
        max-width: 400px;
      }

      @include desktop-large {
        max-width: 440px;
      }
    }

    &.slick-initialized {
      @include tablet {
        max-width: 540px;
      }
    }
  }

  .images-slider-fullwidth {
    position: relative;

    &.slick-initialized {
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-slide {
      opacity: 0.35;
      transition: opacity 0.5s $navigation-ease;

      &:not(.slick-current) {
        &:hover {
          opacity: 0.55;
        }
      }

      &.slick-current {
        opacity: 1;
        transition-duration: 1.2s;

        img {
          transition: transform 0.4s $navigation-ease;
        }

        &:hover {
          img {
            @include desktop {
              transform: scale(1.2);
              transition-duration: 0.8s;
            }
          }
        }
      }

      > div {
        align-items: center;
        overflow: hidden;

        @include mobile-only {
          height: 340px;
          margin: 0 8px;
        }

        @include tablet {
          height: 500px;
          margin: 0 30px;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      z-index: 1;

      &.slick-prev {
        @include mobile-only {
          left: 4px;
        }

        @include tablet {
          left: 58px;
        }
      }

      &.slick-next {
        @include mobile-only {
          right: 4px;
        }

        @include tablet {
          right: 58px;
        }
      }

      .slider-button {
        @include mobile-only {
          background-color: rgba($white, 0.7);
          font-size: 36px;
          height: 36px;
          width: 36px;
        }

        @include tablet {
          color: $white;
          transition: none;
        }

        &:hover {
          @include tablet {
            background-color: transparent;
          }
        }
      }
    }
  }

  .images-slider-header,
  .images-slider-footer {
    span {
      color: $white;
      display: block;
    }
  }

  .images-slider-header {
    align-items: flex-end;
    display: flex;

    @include mobile-and-tablet {
      padding: 50px 0 30px 0;
    }

    @include desktop {
      height: 160px;
      padding-bottom: 40px;
    }

    .scrub-collection {
      font-family: $font-header;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  .images-slider-footer {
    @include mobile-and-tablet {
      padding: 30px 40px 20px 40px;
    }

    @include desktop {
      height: 240px;
      padding-top: 40px;
    }

    .scrub-variant {
      font-family: $font-header;
      font-size: 18px;
      letter-spacing: 3px;
      line-height: 19px;
      text-transform: uppercase;

      @include mobile-and-tablet {
        margin-bottom: 16px;
      }

      @include desktop {
        margin-bottom: 30px;
      }
    }

    .scrub-variant-number {
      font-family: $font-header;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 19px;
      margin-bottom: 8px;
    }

    .scrub-variant-props {
      display: flex;
      list-style: none;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        color: $white;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 28px;


        @include mobile-only {
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
          width: 100px;
        }

        @include tablet {
          width: 140px;
        }

        & + li {
          margin-left: 10px;
        }

        .icon {
          font-size: 22px;
        }
      }
    }
  }

  .product-images {
    position: relative;
  }

  .product-ingredients-images {
    display: none;

    img {
      max-height: 100%;
    }
  }

  .pdp-images-spinner {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.full-width-image {
  @include mobile-only {
    .pdp-breadcrumbs-wrapper {
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 1;
    }

    .product-details-container {
      padding-top: 0;
    }

    .pdp-images-holder {
      margin: -20px -20px 0;
      max-width: calc(100% + 40px);
      width: calc(100% + 40px);
    }

    .images-slider {
      display: block;
      width: 100%;

      .slick-slide > div {
        height: auto;
      }
    }

    .images-wrapper img {
      height: auto;
      width: 100% !important;
    }
  }
}

// configurable products
.configurable-details-container {
  .btn-configure {
    position: absolute;
    right: 0;
    top: -4px;

    .icon-pencil-configurable {
      @include pencil-black-icon(34px, 34px);
      vertical-align: middle;
    }
  }

  .check-availability-button {
    display: none;
  }

  .product-details-content {
    .product-add-to-cart {
      margin-bottom: 10px !important;
    }
  }

  @include mobile-only {
    padding-top: 0;

    .column-images {
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0 !important;
      position: relative;
    }

    .pdp-images-holder {
      margin: -20px 0 0 -20px;
    }
  }

  @include tablet {
    .pdp-inner-wrapper {
      position: relative;
    }

    .btn-configure {
      top: 30px;
      left: 30px;
      right: auto;
    }
  }
}

.column-images {
  body:not(.personalised-gifting-page) {
    @include tablet {
      padding-top: 120px;
    }

    .promotional-label {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    img {
      max-width: 100%;
    }
  }
}

.product-details-column {
  width: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  body:not(.personalised-gifting-page) & {
    @include tablet {
      width: 50%;
    }
  }

  body.personalised-gifting-page & {
    @include desktop {
      width: 50%;
    }
  }

  .column-images {
    @include tablet {
      z-index: 2; // necessary only for video on desktop in PDP
    }
  }
}

.product-title {
  .ritual-icon {
    color: $copper;
    font-size: 25px;
    height: 44px;
    margin-bottom: 10px;

    @include tablet {
      font-size: 35px;
      margin-bottom: 16px;
    }
  }

  .product-name {
    span:last-child:not(:only-child) {
      @include mobile-and-tablet {
        display: none;
      }
    }
  }

  .the-ritual-of-title {
    margin-bottom: 10px;
  }
}

.product-details-content {
  max-width: 520px;
  width: 100%;

  @include tablet {
    padding: 20px;
  }

  .product-preview-image {
    height: 180px;
    margin: 10px auto;
    width: 180px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .product-name {
    margin-bottom: 25px;

    @include mobile-only {
      letter-spacing: 0.6px;
    }

    @include tablet {
      margin-bottom: 18px;
    }
  }

  .product-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .promotion-wrapper {
      margin: 32px 0 32px 0;

      .callout-message {
        @include callout;

        border-radius: 0;
      }
    }

    .btn-wrapper-send {
      margin-bottom: 20px;

      .btn {
        @include tablet-only {
          min-width: 220px;
        }
      }
    }
  }

  .product-short-info {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.36px;
    color: $gray-medium;
  }

  .shipping-info {
    font-size: 14px;
    letter-spacing: 0.36px;
    line-height: 24px;
    margin-top: 22px;
    max-width: 100%;

    ul {
      list-style: none;
      text-align: center;

      .shipping-info-point {
        color: $rit-black;
        display: block;
        margin-bottom: 4px;
        max-width: 400px;

        strong {
          font-weight: 700;
        }

        span {
          display: inline-block;
        }

        .icon-check {
          font-size: 10px;
          margin-right: 10px;
          color: $rit-copper-dark;
          vertical-align: top;
          width: 15px;
        }
      }

      .shipping-info-point,
      a {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.36px;
      }
    }

    .shipping-info-slider {
      .slick-dots {
        font-size: 0;
        list-style: none;
        text-align: center;

        li {
          display: inline-block;
          margin: 0 3px;
        }

        button {
          background-color: $gray-concrete;
          border-radius: 50%;
          display: inline-block;
          font-size: 0;
          height: 4px;
          width: 4px;
        }

        .slick-active button {
          background-color: $rit-dark;
        }
      }
    }

    .pdp-shipping-info-container {
      margin-top: -8px;

      .ico {
        font-size: 32px;
        margin-bottom: 24px;
        color: $rit-copper-lighter;
      }

      .usp-text-slider {
        max-width: 250px;

        &::before {
          background: linear-gradient(to right, $rit-sandy-dark 0%, rgba($rit-sandy-dark, 0) 100%);
        }

        &::after {
          background: linear-gradient(to right, rgba($rit-sandy-dark, 0) 0%, $rit-sandy-dark 100%);
        }

        .highlighted {
          display: inline-block;
        }

        p {
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
        }

        .slick-dots {
          margin: 0;
        }
      }
    }
  }

  .pdp-disclaimer {
    color: $rit-black;
    display: block;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-bottom: 30px;
    padding-top: 13px;

    @include tablet {
      margin: 0 auto 30px;
      max-width: 300px;
    }
  }

  .short-description,
  .previously-known-as {
    margin-bottom: 27px;
  }

  .long-description {
    line-height: 28px;
    max-width: 380px;
    width: 100%;

    .previously-known-as {
      margin-bottom: 30px;
    }
  }

  .value-price {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: $grey-medium-light;
    margin: -7px 0 19px 0;
    width: 100%;
    max-width: 380px;
  }

  .giftset-valueprice {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    font-weight: 600;
    color: $rit-black;
    margin: 3px 0 20px;
  }

  .unit-base-price {
    margin-top: 10px;

    &.legal-note {
      margin-top: 13px;
    }
  }

  .product-price,
  .product-add-to-cart {
    margin-bottom: 20px;
  }

  .product-price {
    &.b2b-price {
      display: flex;
      gap: 8px;
      margin: 16px 0;

      @include mobile-only {
        align-items: center;
      }

      .unit-base-price {
        color: $ritual-samurai;

        &.legal-note {
          @include mobile-only {
            margin-top: 0;
          }
        }

        span {
          @include mobile-only {
            display: flex;
            text-align: left;
            width: 10ch;
          }
        }
      }
    }

    .base-price-block {
      @include desktop {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 4px;
      }
    }
  }

  .price-standard {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-decoration: line-through;
    color: $gray-suva;
    display: block;
    margin-top: 5px;
  }

  .product-discount-no-strikethrough {
    color: $black;
    display: block;
    font-family: $font-body-bold;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-top: 5px;

    @include mobile-only {
      font-size: 14px;
    }
  }

  .product-add-to-cart {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .btn-select-option {
       ~ .availability-web {
        display: none;
      }
    }

    .btn-wrapper {
      &:not(.hide) {
        display: flex;
        flex-direction: column;
      }

      .btn-customize {
        margin-bottom: 10px;
        padding: 13px 20px;

        .text {
          display: inline-block;
        }

        .icon-pencil-configurable {
          @include pencil-black-icon(16px, 16px);
          transition: .2s ease-in-out;
          margin: 0 -24px -4px 2px;
        }

        &.btn--primary {
          .icon-pencil-configurable {
            @include pencil-white-icon(16px, 16px);
          }

          @include desktop-only {
            &:hover {
              .icon-pencil-configurable {
                @include pencil-black-icon(16px, 16px);
              }
            }
          }
        }
      }
    }
  }

  .product-variations {
    margin-bottom: 30px;

    ~ form {
      .btn-add-to-cart,
      .btn-select-option {
        margin-top: 20px;
      }
    }
  }

  .out-of-stock-text {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: $black;
    margin: -8px auto 10px;

    @include tablet {
      max-width: 384px;
    }
  }

  .read-more-link {
    border-width: 1px;
  }

  .pdpForm {
    width: 100%;
  }

  .product-info-warning-message {
    display: block;
    letter-spacing: 0.5px;
    line-height: 28px;
    margin: 6px auto 25px;
    max-width: 315px;
    padding-left: 23px;
    position: relative;
    text-align: left;

    @include tablet {
      letter-spacing: 0.6px;
      max-width: 380px;
    }

    .info-icon {
      display: inline-block;
      left: 0;
      position: absolute;
      top: 5px;
    }
  }

  &.b2b-price {
    display: flex;
  }
}

.read-more-link {
  display: inline-block;
  margin: 5px 0;
  line-height: 13px;
  text-decoration: none;
  border-bottom: 2px solid currentColor;
  &:hover {
    border-color: transparent;
  }

  @include mobile-only {
    font-size: 14px;
  }
}

// box contains block
.box-contains-block {
  position: relative;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $rit-sandy-dark;

  @include tablet {
    min-height: 740px;
    position: static;
  }

  .box-contains-heading {
    margin-bottom: 30px;
    letter-spacing: 1.2px;
    text-transform: none;

    @include tablet {
      letter-spacing: 0.6px;
    }
  }

  .box-content-wrapper {
    margin-left: 0;
    max-width: 100%;

    @include tablet {
      margin-left: 50%;
      max-width: 50%;
      padding-right: 40px;
    }

    .box-item {
      padding: 20px 0;
      border-bottom: 1px solid $gray-very-light;

      &:last-child {
        border-bottom: 0;
      }
    }

    .item-ritual,
    .item-text {
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}

// product tabs block
.product-tabs-container {
  background-color: $rit-sandy-dark;
  position: relative;

  @include mobile-only {
    padding: 36px 0 60px;

    .inner-wrapper {
      padding: 0;
    }
  }

  @include tablet {
    padding: 30px 0;
  }

  @include desktop {
    padding: 57px 0 80px;

    .inner-wrapper {
      max-width: 1200px;
    }
  }

  &.product-advent-tabs {
    background-color: transparent;
    border-top: 1px solid rgba($color: $white, $alpha: 0.1);
    padding: 90px 0 80px;

    @include mobile-only {
      padding: 70px 0 60px;
    }

    .btn--secondary {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;

      @include tablet {
        &:hover {
          background-color: $white;
          color: $rit-black;
        }
      }
    }

    .accordion-wrapper {
      &::before {
        left: 25px;
        right: 25px;
      }

      .accordion-head {
        @include mobile-only {
          color: $white;
        }
      }

      .accordion-content {
        @include mobile-only {
          color: $white;
        }

        a {
          @include mobile-only {
            color: $white;
          }
        }

        .shippingPromotionTable {
          p {
            @include mobile-only {
              color: $white;
            }
          }
        }
      }
    }

    .tab-content .tab-content-inner {
      a {
        color: $white;
      }
    }

    .accordion-item {
      padding: 0 25px;
    }

    .tabs-holder .tabs .tab-item {
      color: $white;

      &:hover {
        color: $white;
      }

      &::after {
        background-color: $white;
      }
    }

    .tabs-holder .tabs-content .tab-content {
      color: $white;
    }
  }
}

// quickview styles
.pdp-main-quickview {
  height: 100%;

  .product-details-container,
  .product-details-column {
    body:not(.personalised-gifting-page) & {
      width: 100%;
    }
  }

  .product-details-container {
    height: 100%;
    justify-content: center;
  }

  .product-details-column {
    .ritual-icon {
      display: none;
    }
  }

  .product-variations {
      .unselectable {
        a {
          pointer-events: none;
          cursor: default;
        }
      }
  }
}

// styles for videos
.product-background-video {
  &.show {
    opacity: 0;

    @include device-height-normal {
      opacity: 0.4;
    }
  }
}

.how-to-use-block {
  background-color: $rit-sandy-dark;

  .video-controls_bottom-right {
    opacity: 1;
    margin: 28px;

    .video-control-button {
      font-size: 65px;
    }
  }
}

.how-to-use-block-homme {
  @include tablet {
    flex-direction: row-reverse;

    .product-hero-box {
      margin: 0 auto;
    }
  }
}

.how-to-use-block-hydra {
  .product-hero-media {
		@include mobile-only {
			order: -1;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
  }

  .product-hero-content {
    justify-content: flex-end;

    @include mobile-only {
      justify-content: center;
    }
  }

  &.product-hero-container-customisable {
    .product-hero-content {
      .product-hero-box {
				@include mobile-only {
					padding: 24px 40px;
				}

        .hydra-title {
          color: $rit-copper;
          font-family: $font-header;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 2px;
          margin-bottom: 40px;
					text-transform: uppercase;
        }
      }
    }
  }

  .hydra-logo {
    margin-bottom: 16px;
		width: 50px;
		height: 50px;
  }
}

.pdp-swatches-wrapper {
  margin: 20px auto 10px;

  &.wide {
    max-width: 250px;
  }

  .pdp-swatches-list {
    font-size: 0;
    max-width: 205px;
    margin: 0 auto;
  }

  .pdp-swatches-text {
    color: $rit-black;
    display: block;
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;

    .pdp-swatches-name {
      font-weight: 400;
    }
  }

  .pdp-swatches-item {
    display: inline-block;
    height: 28px;
    width: 28px;
    vertical-align: top;
    margin: 0 7px 13px;

    &.hidden {
      display: none;
    }

    &.visible {
      display: inline-block;
    }
  }

  .pdp-swatches-button {
    position: relative;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    overflow: hidden;
    border: 0.5px solid transparent;
  }

  .pdp-swatches-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .selected .pdp-swatches-button {
    border-color: $rit-black;
    padding: 4px;
  }

  .out-of-stock .pdp-swatches-button {
    opacity: 0.8;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 17px;
      border: 1px solid $white;
      border-radius: 50%;
      background: transparent;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 1px;
      height: 18px;
      background-color: $white;
    }
  }

  .selected.out-of-stock .pdp-swatches-button {
    &::before {
      border-color: transparent;
    }
  }

  .pdp-swatches-link {
    display: block;
    margin: 6px auto 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;

    &:hover,
    &:active {
      text-decoration: underline;

      @include desktop-only {
        text-decoration: none;
      }
    }
  }
}

.pdp-options-dropdown {
  width: 100%;
  margin: 0 auto 28px;
  max-width: 343px;

  .selectric-wrapper.selectric-inputfield-select {
    .selectric {
      padding: 7px 21px 7px 5px;
    }

    .selectric-items {
      li {
        text-align: left;

        &[data-index='0'] {
          display: none;
        }

        &.disabled,
        &.out-of-stock {
          display: block;
          color: rgba($rit-black, 0.3);
        }
      }
    }
  }

  .error {
    display: none;
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 1px;
    margin: 2px 0;
    text-align: left;
  }

  &.error-field {
    .error {
      display: block;
    }

    .selectric,
    .inputfield-select {
      border-top: 1px solid $red-error;
      margin-top: 3px;
    }
  }

  &.out-of-stock {
    .selectric-wrapper .selectric .label,
    .inputfield-select {
      color: rgba($rit-black, 0.3);
    }
  }
}

.pdp-options-explanation,
.pdp-options-explanation a {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.pdp-options-explanation {
  text-align: left;
  margin: 5px 0 0;
}

// outlet login buttons
.outlet-pdp-login-header {
  background-color: $rit-sandy-bg;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 22px 30px 30px;
  width: 100%;

  .outlet-pdp-login-header-text {
    color: $rit-dark;
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 20px;
    width: 100%;
  }

  .outlet-login-btn {
    width: 48%;
  }

  .btn {
    min-width: 10px;
    padding: 15px 5px 14px;
    width: 100%;
  }

  .form-row-button:not(.full-width-row) {
    margin-top: 0;
  }

  @include tablet {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 40px 40px 40px 30px;

    .outlet-pdp-login-header-text {
      display: inline-block;
      margin: 0 auto 0 0;
      width: auto;
    }

    .outlet-login-btn {
      margin-left: 20px;
      min-width: 200px;
      width: auto;

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

.outlet-pdp-back {
  padding: 20px;
  width: 100%;

  .pdp-backbutton {
    font-size: 12px;
    line-height: 14px;
  }

  .icon {
    line-height: 14px;
  }

  .button-text {
    color: $rit-dark;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

// hair temple
.product-options.hair-temple {
  max-width: 400px;
  margin: 0 auto 10px;
}

.pdp-elixir-product {
  margin-bottom: 10px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  .selectric-wrapper {
    .selectric {
      background-color: rgba($white, 0.5);
      border: 1px solid $gray-lines;
      padding: 10px 35px 7px 19px;

      .label {
        color: $rit-dark;
        font-family: $font-body;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
        text-transform: none;
        padding-left: 0;
      }

      .button {
        top: 10px;
      }
    }

    .selectric-items ul {
      text-align: left;
    }

    &.selectric-disabled {
      opacity: .7;

      .selectric {
        cursor: default;

        .label {
          opacity: .7;
        }
      }
    }
  }
}

.pdp-elixir-customer-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;

  .elixir-name-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;

    input {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: rgba($white, 0.5);
      border: 1px solid $gray-lines;
      color: $rit-dark;
      font-family: $font-body;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 22px;
      margin-top: 0;
      order: 2;
      padding: 13px 35px 10px 19px;

      &.error {
        border-top-color: $red-error;
      }

      &::placeholder {
        color: $gray-silver;
      }
    }
  }

  .elixir-charnumber {
    bottom: 16px;
    color: $gray-light;
    font-size: 11px;
    letter-spacing: 3px;
    line-height: 13px;
    position: absolute;
    right: 18px;
  }

  .elixir-note {
    color: $gray-light;
    display: block;
    font-size: 14px;
    letter-spacing: 1.27px;
    line-height: 17px;
    padding: 17px 20px 20px;
    text-align: left;

    @include tablet {
      padding: 15px 20px 18px;
    }
  }
}

.pdp-elixir-product span.error,
.pdp-elixir-customer-name span.error-text {
  color: $red-error;
  display: block;
  padding: 9px 4px 4px 0;
  text-align: left;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  letter-spacing: 1px;

  @include tablet {
    padding: 9px 4px 4px;
  }
}

// product bundles
.product-bundles-block {
  display: flex;
  min-height: 740px;
  padding: 40px 0;
  width: 100%;

  @include mobile-only {
    position: relative;
  }

  @include tablet {
    .inner-wrapper {
      align-items: center;
      display: flex;
    }
  }
}

.product-bundles-text-container {
  width: 100%;

  .heading-h3 {
    margin-bottom: 30px;
  }

  .bundles-list {
    list-style: none;
    max-width: 600px;
    width: 100%;

    li {
      border-bottom: 1px solid $gray-very-light;
      display: flex;
      margin-bottom: 12px;
      padding-bottom: 20px;
      width: 100%;
    }
  }

  .image-holder {
    padding: 5px 8px 0 0;
    text-align: center;
    width: 82px;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  .text-holder {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
    width: calc(100% - 82px);
  }

  .the-ritual-of-title {
    color: $rit-copper-dark;
    font-size: 11px;
    letter-spacing: 1.65px;
    line-height: 24px;
    margin-bottom: 0;
    width: 100%;
  }

  h3 {
    font-size: 16px;
    letter-spacing: 2.63px;
    line-height: 20px;
    margin-bottom: 32px;
    width: calc(100% - 100px);
  }

  .read-more-link {
    border-width: 1px;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 10px;
    margin: 0;
    order: 2;
    text-transform: uppercase;
  }

  .price {
    font-family: $font-header;
    font-size: 16px;
    line-height: 20px;
    margin-left: auto;
    text-align: right;
    width: 100px;
  }

  @include tablet {
    margin-left: 50%;
    padding-left: 40px;
    width: 50%;

    .heading-h3 {
      padding: 0 10px;
    }

    .image-holder {
      padding: 5px 8px 0 0;
    }

    .bundles-list {
      padding-right: 40px;
    }
  }
}

// product zoom
.zoomed-in {
  @include mobile-only {
    .product-details-container {
      .images-slider {
        max-width: 250px;
      }

      .slider-bar {
        bottom: 0;
        left: 50%;
        position: fixed;
        transform: translateX(-50%);
      }

      .slider-dots  {
        bottom: 20px;
        position: fixed;
      }
    }

    .slider-dots {
      pointer-events: auto;
    }
  }
}

// fix layout until PDP css is loaded
.flyout-content.instore-availability-flyout-content,
.product-ingredients-column .product-ingredients-content {
  @include tablet {
    opacity: 0;
  }
}

body:not(.personalised-gifting-page) .product-ingredients-container {
  @include tablet {
    flex-direction: row;
    min-height: 760px;
  }
}

.countdown-block {
  display: flex;
  border: 1px solid $rit-copper;
  background-color: $rit-sandy-light;
  padding: 13px 26px 11px 13px;
  margin: 12px 0 35px;
  max-width: 400px;

  @include mobile-only {
    margin: 20px 0 45px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .ico {
    font-size: 42px;
    color: $rit-copper;
  }

  .countdown {
    color: $rit-copper;
    text-transform: uppercase;
    font-family: $font-header;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 1.7px;
    margin-bottom: 2px;
  }

  .info {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $rit-black;
  }
}

.btn-wrapper-send {
  .btn {
    position: relative;
    background-color: transparent;
    border: 1px solid $gray-disabled;
    letter-spacing: 1.5px;
    color: $rit-black;

    &.send-icon-visible {
      background-color: $rit-black;
      color: $white;
      display: inline-block;
      position: relative;
      z-index: 10;

      &::before {
        @include icon-styles;
        content: icon-char(check);
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include mobile-only {
          font-size: 10px;
        }
      }
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - 3px));
      right: 10px;
      font-size: 20px;
    }

    &.button-loading {
      .icon-send {
        display: none;
      }
    }

    .spinner-visible {
      :nth-child(1) {
        margin-right: 15px;
      }

      :nth-child(2) {
        margin-right: 0;
      }

      :nth-child(3) {
        margin-right: -15px;
      }

      span {
        right: 50%;
        transform: translateY(-50%);
      }
    }

    @include tablet-only {
      min-width: 100%;
    }

    @include desktop {
      &:hover,
      &:active {
        border-color: $rit-black;
      }
    }
  }
}
