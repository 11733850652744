.accordion {
  width: 100%;
  .accordion--item {
    border-bottom: 1px solid $gray-gainsboro;
    width: 100%;
  }
}

.accordion--head {
  color: $black;
  display: block;
  font-family: $font-header;
  font-size: 18px;
  letter-spacing: 3.3px;
  line-height: 24px;
  margin: 0;
  padding: 34px 55px 34px 30px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  &::after {
    @include icon-styles;
    color: $black;
    content: icon-char(plus2);
    display: inline-block;
    font-size: 25px;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.open::after {
    content: icon-char(minus2);
  }

  &:hover {
    background-color: transparent;
    color: $black;
  }

  @include tablet {
    padding: 30px 55px 30px 5px;
  }

  @include desktop {
    &:hover {
      background-color: transparent;
      color: $rit-copper;

      &::after {
        color: $rit-copper;
      }
    }
  }
}

.accordion--content {
  display: none;
  padding: 0 30px 30px;
  width: 100%;

  ul {
    padding-left: 15px;

    li {
      margin-bottom: 5px;
      padding-left: 10px;
    }
  }

  @include tablet {
    padding: 0 70px 33px 5px;
  }
}

// accordion content asset
.accordion-content {
  &.tab-content-formatted {
    white-space: pre-line;
  }

  .acc-content-item {
    margin-bottom: 14px;
    width: 100%;
  }

  .acc-content-head {
    color: $rit-copper;
    display: inline-block;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    padding-left: 30px;
    position: relative;
    text-align: left;
    text-decoration: underline;
    text-transform: uppercase;

    &::before {
      @include icon-styles;
      content: icon-char(arrow3--down);
      display: inline-block;
      font-size: 28px;
      left: -6px;
      position: absolute;
      transition: transform $quaternary-duration $default-ease-out;
      top: 0;
    }

    &:hover {
      text-decoration: none;
    }

    &.open {
      &::before {
        transform: rotate(-180deg);
      }
    }
  }

  .acc-content-body {
    display: none;
    overflow: hidden;
    padding: 25px 0 0 30px;
    width: 100%;

    &.disclaimer {
      color: $white;
      font-family: $font-body-bold;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: .2px;
      line-height: 13px;
    }
  }
}

// new footer and PDP accordion
.accordion-wrapper {
  position: relative;
  width: 100%;

  &.accordion-light {
    &::before {
      background-color: $gray-light;
    }
    .accordion-head,
    .accordion-content {
      color: $white;
      border-color: $gray-light;
    }

    .accordion-head::after {
      color: $gray-lighter;
    }
  }

  &.pdp-accordion-wrapper {
    margin-top: -6px;

    @include tablet {
      display: none;
    }
  }

  &::before {
    background-color: $gray-lines;
    content: '';
    display: inline-block;
    height: 1px;
    left: 35px;
    position: absolute;
    right: 35px;
    top: 0;

    @include tablet {
      left: 0;
      right: 0;
    }
  }

  .accordion-item {
    padding: 0 35px;
    width: 100%;

    @include tablet {
      padding: 0;
    }
  }

  .accordion-head {
    border-bottom: 1px solid $gray-lines;
    color: $rit-black;
    display: block;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 15px;
    padding: 23px 0 17px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    &::after {
      @include icon-styles;
      content: icon-char(arrow3--down);
      display: inline-block;
      font-size: 32px;
      position: absolute;
      right: -9px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform $quaternary-duration $default-ease-out;
    }

    &.open {
      border-bottom-color: rgba($white, 0);

      &::after {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }

  .accordion-content {
    border-bottom: 1px solid $gray-lines;
    color: $rit-black;
    display: none;
    font-size: 16px;
    line-height: 28px;
    padding: 10px 0 30px;
    width: 100%;

    p {
      color: $rit-black;
      font-size: 16px;
      line-height: 28px;

      a {
        text-decoration: none;
      }
    }

    ul {
      list-style: none;
    }
  }
}
