/* ******************************
* ======== INNER WRAPPER ========
******************************* */
// adds max width to content
@mixin inner-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}


/* ***************************
* ======== HEADERS ========
**************************** */
// headings
@mixin headings($size: '') {
  font-family: $font-header;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 10px;

  @if $size == 'l'  {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 3.3px;
  } @else if $size == 'm' {
    font-size: 29px;
    line-height: 40px;
    letter-spacing: 2.4px;
  } @else {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 4px;
  }
}

// produces header styles
// options are xl, l, m or s
@mixin section-header($size: '') {

  @if $size == 'h1' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 58px;
    text-transform: none;
  } @else if $size == 'h2' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 40px;
    letter-spacing: 10.7px;
    line-height: 50px;
    text-transform: uppercase;
  } @else if $size == 'h3' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 8px;
    text-transform: uppercase;
  } @else if $size == 'intro' {
    color: $rit-dark;
    font-family: $font-header;
    font-size: 28px;
    letter-spacing: .5px;
    line-height: 40px;
    text-transform: none;
  } @else if $size == 'subtitle' {
    color: $rit-dark;
    font-family: $font-body;
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 25px;
    text-transform: uppercase;
  } @else if $size == 'h4' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 2.6px;
    line-height: 20px;
    text-transform: uppercase;
  } @else if $size == 'h5' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 15px;
    letter-spacing: 5px;
    line-height: 23px;
    text-transform: uppercase;
  } @else if $size == 'h6' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 30px;
    letter-spacing: 2.5px;
    line-height: 36px;
    text-transform: none;
  } @else if $size == 'h7' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 20px;
    letter-spacing: 1.6px;
    line-height: 24px;
    text-transform: none;
  } @else if $size == 'h8' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-transform: none;
  } @else if $size == 'h9' {
    color: $rit-black;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
  } @else if $size == 'h10' {
    color: $rit-copper;
    font-family: $font-header;
    font-size: 11px;
    letter-spacing: 1.6px;
    line-height: 24px;
    text-transform: uppercase;
  } @else if $size == 'h11' {
    color: $rit-dark;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 2.8px;
    line-height: 14px;
    text-transform: uppercase;
  } @else {
    color: $rit-black;
    font-family: $font-header;
    font-size: 17px;
    letter-spacing: 2px;
    line-height: 22px;
    text-transform: uppercase;
  }

  @include mobile-only {
    @if $size == 'h1' {
      font-size: 30px;
      letter-spacing: 1.2px;
      line-height: 46px;
    } @else if $size == 'subtitle' {
      font-size: 12px;
      letter-spacing: 3.75px;
      line-height: 20px;
    } @else if $size == 'h4' {
      font-size: 12px;
      letter-spacing: 1.9px;
      line-height: 18px;
    } @else if $size == 'h5' {
      font-size: 11px;
      letter-spacing: 3.6px;
      line-height: 24px;
      text-transform: uppercase;
    } @else if $size == 'h8' {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
    }
  }
}

/* ******************************
* ======== MEDIA QUERIES ========
******************************* */
@mixin mobile-extra-small {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin mobile-small {
  @media (max-width: 374px) {
    @content;
  }
}

@mixin mobile-large {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: ($tablet-width - 1)) {
    @content;
  }
}

@mixin tablet-small {
  @media (min-width: $tablet-width)
    and (max-width: ($tablet-small - 1)) {
      @content;
    }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: $tablet-width)
    and (max-width: ($desktop-width - 1)) {
      @content;
    }
}

@mixin tablet-portrait {
  @media (min-width: $tablet-width)
    and (max-width: $desktop-width)
    and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: $tablet-width)
    and (max-width: $desktop-width)
    and (orientation: landscape) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media (max-width: ($desktop-width - 1)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin desktop-small-height {
  @media (min-width: $desktop-medium-width)
  and (max-height: $desktop-height-small) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: ($desktop-width + 1)) {
    @content;
  }
}

@mixin desktop-small {
  @media (min-width: $desktop-width)
    and (max-width: ($desktop-medium-width - 1)) {
    @content;
  }
}

@mixin desktop-medium {
  @media (min-width: $desktop-medium-width) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: $desktop-large-width) {
    @content;
  }
}

@mixin desktop-extra-large {
  @media (min-width: $desktop-extra-large-width) {
    @content;
  }
}

@mixin desktop-ultra {
  @media (min-width: $desktop-ultra-wide) {
    @content;
  }
}

@mixin device-height-normal {
  @media (min-height: $device-height-normal) {
    @content;
  }
}

@mixin device-height-small {
  @media (max-height: ($device-height-normal - 1)) {
    @content;
  }
}

@mixin nav-tablet {
  @media (min-width: $tablet-width) and (max-width: $desktop-width) {
    @content;
  }
}

@mixin nav-mobile-tablet {
  @media (max-width: $desktop-width) {
    @content;
  }
}

/* *********************************
* ======== BROWSER SPECIFIC ========
********************************** */

/* IE10+ specific styles */
@mixin ie-styles {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

/* ******************************
* ======== TEXT MIXINS ========
******************************* */
@mixin callout {
  background-color: $white;
  border-radius: 2px;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  text-transform: none;
  width: auto;

  &.callout-red {
    background-color: $red-warning;
    color: $white;
  }

  &.callout-grey {
    background-color: $gray-dark;
    color: $white;
  }

  &.callout-green {
    background-color: $green;
    color: $white;
  }

  &.callout-green-light {
    background-color: $light-green;
    color: $rit-black;
  }

  &.callout-gold {
    background-color: $copper;
    color: $white;
  }

  &.callout-white {
    background-color: $white;
    color: $rit-black;
  }
}

@mixin text-size($size: '') {
  font-family: $font-body;

  @if $size == 's'  {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.31px;
  } @else if $size == 'l' {
    font-size: 20px;
    letter-spacing: 1.7px;
  } @else {
    font-size: 16px;
    letter-spacing: 1px;
  }
}

@mixin body-text {
  font-family: $font-body;
  font-style: normal;
  color: $rit-dark;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;

  @include mobile-only {
    font-size: 14px;
    letter-spacing: .8px;
    line-height: 26px;
  }
}

@mixin price-text($size: 'l') {
  font-family: $font-header;
  font-style: normal;
  line-height: normal;
  color: $black;

  @if $size == 'l'  {
    font-size: 30px;
    letter-spacing: 2.5px;
  } @else if $size == 'm' {
    font-size: 20px;
    letter-spacing: 1.7px;
  } @else {
    font-size: 16px;
    letter-spacing: 1px;
  }
}

//resizable font
@mixin responsive-font($minS, $maxS, $minW, $maxW) {
  font-size: #{$minS}px;
  @media all and (min-width: #{$minW}px) and (max-width: #{$maxW}px) {
    font-size: calc(#{$minS}px + (#{$maxS} - #{$minS}) * (100vw - #{$minW}px) / (#{$maxW} - #{$minW})) !important;
  }
  @media all and (min-width: #{$maxW}px) {
    font-size: #{$maxS}px !important;
  }
}

@mixin private-collection-seal-icon($iconW, $iconH) {
  background: url('../images/private-collection-seal.svg') left top no-repeat;
  background-size: $iconW $iconH;
  display: inline-block;
  height: $iconH;
  width: $iconW;
}

@mixin pencil-black-icon($iconW, $iconH) {
  background: url('../images/pencil-black-icon.svg') left top no-repeat;
  background-size: $iconW $iconH;
  display: inline-block;
  height: $iconH;
  width: $iconW;
}

@mixin pencil-white-icon($iconW, $iconH) {
  background: url('../images/pencil-white-icon.svg') left top no-repeat;
  background-size: $iconW $iconH;
  display: inline-block;
  height: $iconH;
  width: $iconW;
}

@mixin pay-swish($iconW, $iconH, $bgW, $bgH) {
  background: url('../images/pay-swish.png') center center no-repeat;
  background-size: $bgW $bgH;
  display: inline-block;
  height: $iconH;
  width: $iconW;
}

@mixin pay-vvv($iconW, $iconH, $bgW, $bgH) {
  background: url('../images/pay-vvv.png') center center no-repeat;
  background-size: $bgW $bgH;
  display: inline-block;
  height: $iconH;
  width: $iconW;
}

@mixin pay-illicado($iconW, $iconH, $bgW, $bgH) {
  background: url('../images/pay-illicado.png') center center no-repeat;
  background-size: $bgW $bgH;
  display: inline-block;
  height: $iconH;
  width: $iconW;
}

@mixin environment-water($iconW, $iconH) {
  background: url('../images/environment-water.svg') center top no-repeat;
  background-size: $iconW $iconH;
  height: $iconH;
  width: $iconW;
}

@mixin environment-energy($iconW, $iconH) {
  background: url('../images/environment-energy.svg') center top no-repeat;
  background-size: $iconW $iconH;
  height: $iconH;
  width: $iconW;
}

@mixin environment-waste($iconW, $iconH) {
  background: url('../images/environment-waste.svg') center top no-repeat;
  background-size: $iconW $iconH;
  height: $iconH;
  width: $iconW;
}

@mixin environment-co2($iconW, $iconH) {
  background: url('../images/environment-co2.svg') center top no-repeat;
  background-size: $iconW $iconH;
  height: $iconH;
  width: $iconW;
}
