.membership {
  background-color: transparent;
  width: 100%;

  @include desktop {
    display: flex;
    justify-content: center;
  }

  &__inner-wrapper {
    width: 100%;

    @include desktop {
      display: flex;
    }
  }

  .membership__inner-wrapper {
    max-width: 1320px;
    background-color: $white;
  }

  .membership__image-holder {
    height: 40vh;
    width: 100%;

    @include desktop {
      height: 100%;
      width: 50%;
      flex: 1;
    }

    .image-link {
      display: block;
      width: 100%;
      height: 100%;
    }

    &>a:not(.image-link) {
      display: block;
      width: 320px;
      margin: 0 auto;
    }

    .image-holder {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }

  .membership__content-wrapper {
    position: relative;

    @include desktop {
      flex: 1;
    }

    .membership__text-holder {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $rit-black;

      @include desktop {
        align-items: flex-start;
        padding-left: 40px;
      }

      @include mobile-and-tablet {
        text-align: center;
      }

      span {
        text-transform: uppercase;
        color: $rit-copper;
        font-size: 12px;
        letter-spacing: 1.7px;
        line-height: 16px;
        font-family: $font-header;
      }

      h3 {
        text-transform: none;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1px;

        @include desktop {
          font-size: 32px;
          line-height: 40px;
        }
      }

      p {
        color: $rit-black;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin-bottom: 0;
        text-align: center;
        width: 65%;

        @include desktop {
          text-align: left;
        }
      }
    }

    .membership__gwp {
      display: flex;
      justify-content: center;

      @include desktop-medium {
        justify-content: flex-start;
      }

      .gwp-minicart-wrapper {
        @include mobile-and-tablet {
          display: flex;
          justify-content: center;
        }
      }

      .flyout-progress-bar {
        margin-bottom: 0;
        margin-top: 0;

        @include mobile-and-tablet {
          margin: 0 24px;
        }
      }
    }

    .membership__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;

      @include desktop {
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
      }

      .btn {
        @include mobile-and-tablet {
          max-width: 560px;
          width: 100%;
        }
      }

      .learn-more {
        color: $rit-copper;
        margin-top: 24px;
        font-size: 14px;

        @include desktop {
          margin-top: 0;
          margin-left: 40px;
        }
      }
    }
  }

  .product-gift-ribbon {

    &::after {
      @include desktop {
        opacity: 1;
      }
    }
  }
}

.crm-in-store-phara {
  color: $white;
  font-family: $font-body;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.crm-in-store {
  background-color: $rit-black;
  padding: 0 0 24px;

  .member-card-wrapper {
    padding-top: 0;

    &::after {
      display: none;
    }

    .member-phase {
      color: $rit-gold;
    }

  }

  .apple-add-to-wallet-link {
    display: block;
    margin: 24px auto 0;
  }

  .member-cart-add {
    color: $white;
    display: inline;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 24px;
    position: relative;

    &::before {
      content: '';
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, $white 100%);
      height: 1px;
      position: absolute;
      right: -60px;
      top: 50%;
      transform: translateY(-50%);
      width: 48px;
    }

    &::after {
      content: '';
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, $white 100%);
      height: 1px;
      left: -60px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: 48px;
    }
  }

  .reveal-base {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
    transition: opacity 2s $navigation-ease,
      transform 2s $navigation-ease;
  }

  .phase-section-wrapper {
    .phase-section {
      @include mobile-only {
        padding-bottom: 0;
      }
    }

    .gift-benefits-block {
      background-color: transparent !important;

      @include mobile-only {
        padding: 0;
      }

      .three-tile-block-phone-slider {
        @include mobile-only {
          padding: 0;
        }
      }
    }

    .three-tile-phase.three-tile-block {
      .three-tile-text {
        p {
          color: $rit-black;
        }
      }
    }
  }
}

.crm-in-store-register {
  padding: 80px 0 40px;

  .crm-in-store-wrapper {
    .field-help {
      display: none;
    }

    .label-birthday {
      width: 100%;
    }

    .additional-text-birthday {
      float: right;
    }

    .icon-read-only {
      &::before {
        color: $white !important;
        top: 8px;
      }
    }

    input[type=checkbox]:checked + label::before {
      background-color: $white;
      color: $rit-black;
      font-size: 10px;
      font-weight: 600;
      line-height: 24px;
    }

    .form-content {
      margin-top: 24px;
      padding: 0;

      p {
        color: $white;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;

        a {
          color: $rit-copper-lighter;
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 24px;
        }
      }
    }

    .form-row-button {
      margin-top: 24px;

      button {
        background-color: $white;
        border: 1px solid $white;
        color: $rit-black;
      }

      .btn {
        min-width: 100%;
      }
    }

    .crm-in-store-header {
      margin-bottom: 40px;
    }

    .inputfield-row {
      label {
        align-items: center;
      }
    }

    .inputfield-select {
      background: transparent url('../images/icons/arrow3--down-white.svg') no-repeat right 16px center;
      background-size: 10px 10px;
      background-color: transparent;
      color: $white;
      padding: 16px 24px;
      border: 1px solid $gray-disabled;
    }

    .optional-indicator {
      color: $gray-disabled;
    }

    fieldset > .form-indent {
      margin-top: 48px;
    }

    fieldset > .form-indent ~ .form-indent {
      margin-top: 16px;
    }

    .label-inline.form-indent {
      border: 1px solid $gray-disabled;
      padding: 16px 16px 16px 24px;

      .required-indicator {
        display: none;
      }

      label {
        padding: 0 25px 0 0;

        &::before {
          background-color: unset;
          border-radius: unset;
          border: 1px solid $gray-disabled;
          left: unset;
          right: 0;
          top: 0;
        }
      }

      span {
        color: $white;
        display: inline-block;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 24px;
        max-width: 250px;
      }

      .error {
        color: $red-error;
      }
    }

    .selectric-wrapper {
      .selectric {
        background-color: unset;
        border: 1px solid $gray-disabled;
        display: flex;
        margin-top: 8px;
        padding: 16px 24px;

        .label {
          color: $white;
          padding: 0;
          line-height: 16px;
        }

        .button {
          &::before {
            border-left: 1px solid $white;
            border-top: 1px solid $white;
          }

          &::after {
            border-bottom: 1px solid $white;
            border-right: 1px solid $white;
          }
        }
      }

      .error ~ .selectric {
        border: 1px solid $red-error;
      }
    }
  }

  .form-number-visible {
    margin-top: 24px;
  }
}

.crm-last-nudge {
  background-color: $rit-black-lighter;
  padding: 40px 0;

  .crm-in-store-wrapper .crm-in-store-header {
    margin-bottom: 16px;
  }
}

.crm-in-store-pad {
  padding: 0 24px;
}

.crm-in-store-wrapper {
  margin: 0 auto;
  position: relative;
  text-align: center;

  @include tablet {
    max-width: 375px;
  }

  .ico {
    margin-bottom: 14px;

    &.apple-add-to-wallet {
      transform: scale(1.45);
    }
  }

  .back-button {
    color: $white;
    font-size: 12px;
    left: 24px;
    letter-spacing: 2px;
    line-height: 16px;
    position: absolute;
    text-decoration: none;
    top: 20px;
  }

  .image-holder {
    height: 100%;
    position: relative;
    width: 100%;

    .responsive-bg {
      height: 400px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: radial-gradient(circle, transparent 0%, transparent 80%, $rit-black 100%),
      linear-gradient(0deg, rgba($color: $rit-black, $alpha: 1) 0%, rgba($color: $rit-black, $alpha: 1) 20%, rgba($color: $rit-black, $alpha: 0.8) 40%,  rgba($color: $rit-black, $alpha: 0.5) 60%,  rgba($color: $rit-black, $alpha: 0) 100%);
      z-index: 1;
    }
  }

  .crm-in-store-block {
    margin-top: -260px;
    position: relative;
    z-index: 2;

    .member-card {
      .member-phase {
        color: $rit-gold;
      }
    }
  }

  .crm-in-store-subhead {
    color: $rit-gold;
    font-family: $font-header;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .crm-in-store-header {
    color: $white;
    font-size: 24px;
    letter-spacing: 1.1px;
    line-height: 32px;
    margin-bottom: 8px;
    text-transform: unset;
  }

  .crm-in-store-text {
    color: $white;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-bottom: 24px;

    a {
      color: $rit-copper-lighter;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
    }

    &.crm-in-store-link {
      margin: 0;
    }
  }

  .inputfield-row {
    label {
      color: $gray-disabled;
      letter-spacing: 0.4px;
      line-height: 24px;
    }

    .inputfield-text {
      background-color: transparent;
      border: 1px solid $gray-disabled;
      color: $white;
      padding: 16px 24px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px $rit-black inset;
        -webkit-text-fill-color: $white;
      }

      &.error {
        border: 1px solid $red-error;
      }
    }
  }

  .button-wrapper {
    margin: 32px 0 24px 0;

    .btn {
      min-width: 100%;
      padding: 16px 20px;
    }
  }

  .phase-page-gwp {
    margin: 0 auto;
    padding: 0 24px;
    position: relative;
    text-align: center;

    @include tablet {
      max-width: 375px;
    }

    .phase-page-gwp__gifts {
      @include tablet {
        flex-direction: column;
      }

      &.gwp-shoppingcart-wrapper {
        margin-bottom: 0;
      }
    }

    .product-gift-ribbon {
      &::after {
        @include tablet {
          background-image: url('../images/crm/ribbon-gold-corner-mobile.svg');
          height: 72px;
          width: 64px;
        }
      }
    }

    .phase-page-gwp__gifts.gwp-shoppingcart-wrapper {
      @include tablet {
        margin: 0;
      }

      .approaching-free-gift {
        @include tablet {
          margin: 0 0 20px;
          padding: 0;
        }

        &:last-child {
          @include tablet {
            margin: 0;
          }
        }

        .promo-product-details .product-name {
          .gift-label {
            @include tablet {
              font-size: 16px;
            }
          }

          .gift-price {
            padding-right: 20px;

            @include tablet {
              width: 100%;
            }
          }
        }

        .promo-product-details .col-text {
          @include tablet {
            padding-left: 16px;
          }
        }
      }
    }

    .phase-page-gwp__text {
      h2 {
        @include desktop-medium {
          font-size: 24px;
          line-height: 32px;
          margin: 0 0 10px;
          width: 100%;
        }
      }

      p {
        @include tablet {
          font-size: 14px;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

.onboarding-section-wrapper {
  .phase-page-gwp {
    .phase-page-gwp__gifts.gwp-shoppingcart-wrapper {
      .approaching-free-gift {
        padding: 10px 0;

        .gift-price {
          padding-right: 32px;
        }
      }
    }
  }
}

.crm-in-store-login {
  padding: 80px 0 40px;

  .crm-in-store-wrapper {
    .icon-read-only {
      &::before {
        color: $white !important;
        top: 8px;
      }
    }

    .crm-in-store-forgot {
      margin-bottom: 24px;
    }

    .crm-in-store-header {
      margin-bottom: 24px;
    }

    .button-wrapper-send-mail {
      margin: 16px 0 0;
    }

    .button-wrapper {
      .btn--primary.btn {
        border: 1px solid $white;
      }
    }

    .password-reveal {
      color: $rit-copper-lighter;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 24px;
      text-decoration: underline;
    }

    .password-visible {
      font-size: 14px;
      top: 0;
    }

    .password-not-visible {
      position: relative;
      top: 0;
    }
  }
}
