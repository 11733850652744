.giftcard-balance-checker {
  margin: 0 auto;
  max-width: 681px;
  padding: 34px 30px 85px;
  width: 100%;

  .page-title {
    color: $black;
    font-size: 30px;
    letter-spacing: 4px;
    line-height: 40px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: none;
  }

  .page-description {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    text-align: center;
  }

  .giftcard-checker-box {
    margin: 40px 0 0 -30px;
    width: calc(100% + 60px);
  }

  .giftcard-checker-box-inner {
    background-color: $rit-sandy-light;
    margin-bottom: 40px;
    padding: 46px 36px 44px;
    position: relative;
    width: 100%;
  }

  .giftcard-number-wrapper {
    width: 100%;

    @include tablet {
      display: flex;
    }
  }

  .giftcard-cardnumber,
  .giftcard-pinnumber {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .giftcard-cardnumber {
    span {
      width: 100%;
    }

    .full-width-row {
      margin: 0;
    }

    .optional-indicator {
      display: none;
    }

    @include mobile-only {
      span {
        position: absolute;
        bottom: -23px;
      }
    }
  }

  .giftcard-pinnumber {
    margin-top: 36px;
    width: 100%;
    max-width: 130px;

    @include tablet {
      margin-top: 0;
    }
  }

  .giftcard-prefix-input {
    align-self: flex-end;
    width: 69px;

    &.full-width-row {
      margin-right: -1px;
    }
  }

  .giftcard-number-input {
    align-self: flex-end;
    flex: 1;
    margin: 0 -11px 0 10px;
    width: 100%;

    label {
      display: none;
    }

    .input-row {
      margin-top: 0;

      &.floating-label .error-text {
        background-color: $rit-sandy-light;
        left: -80px;
        padding: 2px 0;
        right: 0;
        top: -15px;
        width: auto;
      }
    }
  }

  .giftcard-pin-input {
    align-self: flex-end;
    width: 100%;

    label {
      display: none;
    }
  }

  .giftcard-number-error {
    background-color: $rit-sandy-light;
    display: block;
    font-size: 11px;
    font-weight: bold;
    left: 36px;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 2px 0;
    position: absolute;
    right: 30px;
    top: 17px;
  }

  .btn-row {
    padding: 0 30px;
    text-align: center;
    width: 100%;
  }

  .giftcard-balance-results {
    margin: 40px 0 14px -30px;

    .giftcard-checker-box-inner {
      padding: 38px 30px 45px;
    }

    .rituals-form {
      .giftcard-number-input input,
      .giftcard-pin-input input {
        background-color: transparent;
      }
    }
  }

  .btn-row-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn--text {
      font-size: 12px;
      margin-top: 12px;
      text-transform: uppercase;
    }
  }

  form:not(.rituals-form) {
    .input-row.floating-label {
      margin-top: 0;

      .error-text {
        margin-top: 0;
      }
    }
  }

  .rituals-form {
    .giftcard-number-wrapper  {
      input {
        padding: 13px 15px 10px;
      }

      .giftcard-number-input input,
      .giftcard-pin-input input {
        background-color: $white;
      }
    }

    .input-label {
      padding: 0;
    }
  }

  .balance-block {
    padding-top: 44px;
    width: 100%;

    .balance-amount {
      font-family: $font-header;
      font-size: 30px;
      letter-spacing: 2px;
      line-height: 1;
    }

    .input-label {
      margin-bottom: 13px;
    }
  }

  @include tablet {
    max-width: 635px;
    padding: 114px 0 128px;

    .page-title {
      font-size: 40px;
      letter-spacing: 1.6px;
      line-height: 50px;
      margin-bottom: 20px;
    }

    .giftcard-checker-box {
      margin: 41px 0 0 -10px;
      width: calc(100% + 20px);
    }

    .input-label {
      padding: 0 0 0 3px;
    }

    .giftcard-checker-box-inner {
      padding: 74px 50px 56px;
    }

    .giftcard-number-input {
      margin-top: 0 !important;
      max-width: 234px;

      .input-row.floating-label .error-text {
        left: -77px;
        top: -13px;
      }
    }

    .giftcard-number-error {
      left: 51px;
      right: 40px;
      top: 27px;
    }

    .btn-row {
      padding: 0;
    }

    .giftcard-balance-results {
      margin-top: 30px;

      .giftcard-checker-box-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 75px 50px 65px;
      }

      .giftcard-number-wrapper {
        margin-bottom: 0;
      }
    }

    .balance-block {
      padding-top: 58px;

      .input-label {
        margin-bottom: 6px;
      }
    }

    .btn-row-center {
      .btn--text {
        margin-top: 20px;
      }
    }
  }
}

.giftcard-wrap {
  margin: 0 auto 70px;
  max-width: 681px;
  padding: 0 30px;

  .form-row-button {
    margin: 0;
  }

  .success-msg {
    color: $green;
  }

  @include tablet {
    margin: 0 auto 100px;
  }
}
