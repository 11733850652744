.pdp-main-container.advent-main-pdp-block {
  .product-name {
    letter-spacing: 1px;
    line-height: 48px;
    margin-bottom: 10px;

    @include mobile-only {
      margin-bottom: 0;
    }

    .the-ritual-of-title {
      display: block;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 5px;
      color: $rit-copper;
      -webkit-text-fill-color: $rit-copper;
    }
  }

  .product-details-container {
    padding: 10px 0 0;

    @include mobile-only {
      padding: 0;
    }

    @include tablet {
      background: linear-gradient(to left, $white 47%, transparent 47%);
    }

    @include tablet-small {
      background: transparent;
    }

    .pdp-disclaimer {
      color: $gray-dark;
      font-size: 12px;
      line-height: 16px;
      padding-top: 5px;

      @include mobile-only {
        padding: 5px 20px 0;
      }
    }

    .inner-wrapper {
      .product-details-column {
        &.column-images {
          padding-top: 0;
        }
      }
    }
  }

  .short-description {
    margin-bottom: 20px;
  }

  .long-description  {
    color: $rit-black;
    padding-top: 15px;
    position: relative;
    line-height: 24px;

    &::before {
      @include tablet {
        content: '';
        background: linear-gradient(90deg, rgba(174, 174, 174, 0) 0%, rgba(26, 25, 25, 0.38) 50.46%, rgba(174, 174, 174, 0) 100%);
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    @include mobile-only {
      padding: 15px 20px 0;
    }
  }

  .price-sales {
    font-size: 32px;
    letter-spacing: 0.6px;
  }

  .discounted-price,
  .price-standard {
    @include tablet {
      display: inline-block;
    }
  }

  .value-price {
    color: $rit-black;
    font-family: $font-header;
    font-size: 12px;
    letter-spacing: 1.8px;
    margin-bottom: 10px;
    padding-top: 12px;
    position: relative;
    text-transform: uppercase;

    &::before {
      content: '';
      background: linear-gradient(90deg, rgba(174, 174, 174, 0) 0%, rgba(26, 25, 25, 0.38) 50.46%, rgba(174, 174, 174, 0) 100%);
      height: 1px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .product-add-to-cart {
    .btn-wrapper {
      .btn {
        background-color: $advent-dark-blue;
        min-width: 300px;
        transition: background-color .1s $default-ease-in-out, color .1s $default-ease-in-out, border-color .1s $default-ease-in-out;

        @include tablet-small {
          min-width: 380px;
        }

        @include tablet {
          &:hover {
            background-color: transparent;
            color: $advent-dark-blue;
            border-color: $advent-dark-blue;
          }
        }
      }
    }
  }

  .read-more-link {
    display: inline-block;
  }

  .check-availability-button {
    color: $advent-light-gold;
  }

  @include tablet-small {
    .pdp-inner-wrapper {
      display: block;

      .product-details-column.column-details {
        background-color: $white;
        padding: 64px 0;
        width: 100%;

        .product-details-content {
          padding: 0;
        }
      }

      .product-details-column.column-images {
        width: 100%;
      }
    }
  }

  @include tablet {
    .pdp-breadcrumbs-wrapper {
      .breadcrumb {
        display: none;
      }

      .pdp-back-button-wrapper {
        display: block;

        .back-button {
          color: $black;
        }
      }
    }

    .pdp-inner-wrapper {
      margin-top: -50px;
      max-width: 100%;

      .column-images {
        padding-top: 0;
      }

      .product-details-column.column-images {
        width: 50%;

        @include tablet {
          .productthumbnail {
            animation:  scale-animation-advent 10s ease forwards;
          }
        }
      }

      .product-details-column.column-details {
        width: 50%;
        margin: auto;

        .product-details-content {
          padding: 100px 20px 40px;
        }
      }
    }
  }

  @-webkit-keyframes scale-animation-advent {
    0% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @-moz-keyframes scale-animation-advent {
    0% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale-animation-advent {
    0% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @include mobile-only {
    .pdp-breadcrumbs-wrapper {
      left: 5px;
      top: 12px;

      .back-button {
        color: $black;
      }
    }
  }

  .pdp-inner-wrapper {
    align-items: flex-start;

    @include mobile-only {
      padding: 20px 0 0;
    }

    @include tablet-small {
      padding: 20px 0 0;
    }

    .product-details-column.column-images {
      position: relative;
      width: 64%;

      @include mobile-only {
        width: 100%;

        .easter-view-block-ar {
          right: 20px;
        }

        .pdp-images-holder {
          max-width: 100%;
          margin-bottom: 0;
          width: 100%;

          @include mobile-only {
            min-height: 200px !important;
          }
        }
      }
    }

    .product-details-column.column-details {
      @include mobile-only {
        background-color: $white;
        padding: 20px 0 0;
      }
    }

    .advent-view-block,
    .advent-view-block-ar  {
      position: absolute;
      top: 40px;
      right: 60px;
      z-index: 10;

      @include mobile-only {
        right: 0;
        top: 0;
      }

      .icon-text {
        color: $white;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .advent-icon-block {
      position: relative;
      margin: 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: $white;
      z-index: 2;
    }

    .advent-icon-block-ar {
      position: relative;
      margin: 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: $white;
      z-index: 2;
    }

    .advent-view-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 11;
    }

    .icon-AR {
      color: $advent-lighter-blue;
      font-size: 30px;
      height: 30px;
      left: 50%;
      line-height: 30px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
    }

    .images-slider {
      &.slick-initialized {
        @include tablet {
          max-width: 100%;
        }
      }

      @include mobile-only {
        padding: 0;
      }

      .slick-slide {

        &>div {
          @include mobile-only {
            max-width: 300px;
            margin: 0 auto;
          }
        }
       }

      img {
        body:not(.personalised-gifting-page) & {
          padding: 40px 0 60px;

          @include mobile-only {
            padding: 0;
          }

          @include tablet-landscape {
            max-height: 900px;
            max-width: 100%;
          }

          @include tablet-portrait {
            height: 1000px;
            max-width: 100%;
          }

          @include desktop-only {
            max-height: 900px;
            max-width: 100%;
          }
        }
      }
    }
  }

  .advent-swatches {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 20px;

    @include mobile-only {
      padding-top: 0;
    }
  }

  .advent-wrapper-bg {
    background-color: $white;
    height: 100%;
    width: 100%;
  }

  .advent-swatch-item {
    width: 95px;
    height: 120px;
    border: 1px solid $gray-gainsboro;
    margin: 0 8px;
    position: relative;
    transition: border-color .1s $default-ease-in-out;

    iframe,
    object,
    embed,
    .video-element {
      mask-image: linear-gradient(transparent 18%, $black 18%);
      z-index: 1;
    }

    @include tablet {
      &:hover {
        border-color: $rit-black;
      }
    }

    &.advent-premium-swatch {
      .advent-wrapper-bg {
        background-color: transparent;
      }

      .video-holder {
        .responsive-video {
          &::after {
            background-color: $white;
            bottom: 0;
            content: '';
            height: 102px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }
    }

    .video-holder {
      .video-controls_bottom-right {
        display: none;
      }

      .responsive-video {
        iframe,
        object,
        embed,
        .video-element {
          max-height: 150px;
          transform: translate(-50%, calc(-50% + 5px));

          @include mobile-only {
            height: 18vh;
          }
        }
      }
    }

    .dm-responsive-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      object-fit: contain;
    }
  }

  .advent-swatch-click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .advent-selected-swatch {
    border-color: $rit-black;
  }

  .link--large {
    text-transform: none;
    text-decoration: none;
    letter-spacing: 0.3px;
    color: $advent-light-gold;

    @include tablet {
      letter-spacing: 0.4px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
